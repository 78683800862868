import Ember from 'ember';
import d3 from "d3";

export default Ember.Component.extend({
    data: null,
    tagName: "g",
    contentArea: null,

    width: Ember.computed.alias("contentArea.width"),
    height: Ember.computed.alias("contentArea.height"),

    hoveredInterest: null,
    selectedInterest: null,
    activeInterest: Ember.computed.or("hoveredInterest", "selectedInterest"),
    activeInterestTiers: Ember.computed("activeInterest.tiers", "activeInterest.tiers.[]",
        "activeInterest.tiers.length", function() {
        var tiers = this.get("activeInterest.tiers");

        return tiers && tiers.join(" &blacktriangleright; ");
    }),

    showTooltip: Ember.computed.notEmpty("activeInterest"),

    colorClass: "atlas-chart-neutral",
    pointRadius: 10,
    xMargin: 40,
    yMargin: 20,
    xColumn: "distribution",
    yColumn: "ratio",
    tooltipWidth: 250,

    extents: null,
    xExtent: Ember.computed.alias("extents.distribution"),
    yExtent: Ember.computed.alias("extents.ratio"),

    xOffset: Ember.computed("xMargin", "pointRadius", function(key) {
        return this.xMargin + (this.pointRadius * 2);
    }),

    yOffset: Ember.computed("yMargin", "pointRadius", function(key) {
        return this.yMargin + (this.pointRadius * 2);
    }),

    innerHeight: Ember.computed("height", "yOffset", function(key) {
        var yOffset = this.yOffset;
        return (this.height || 0) - yOffset;
    }),

    innerWidth: Ember.computed("width", "xOffset", function(key) {
        var xOffset = this.xOffset;
        return (this.width || 0) - xOffset;
    }),

    graphTransform: Ember.computed("xOffset", "pointRadius", function() {
        var xOffset = this.xOffset || 0,
            radius = this.pointRadius || 0;
        return new Ember.String.htmlSafe("translate(" + xOffset + "," + radius + ")");
    }),

    computedData: Ember.computed("data", "xScale", "yScale", "xColumn", "yColumn",
        "width", "height", "yExtent", "xExtent",
        function(key) {
            var data = Ember.assign([], this.data) || [],
                xScale = this.xScale,
                yScale = this.yScale,
                colorScale = d3.scaleLinear().domain([0, 1, 2]).range(["#9fd5f0", "#4fa4d0", "#266382"]);
            return data.map((item, index) => {
                Ember.setProperties(item, {
                    x: xScale(item.distribution),
                    y: yScale(item.ratio),
                    style: new Ember.String.htmlSafe("fill:" + colorScale(item.score))
                });
                return item;
            });
        }),

    xAxis: Ember.computed("height", "xScale", "innerWidth", "height", "xOffset",
        "pointRadius",
        function(key) {
            var x = this.xScale,
                extent = this.xExtent,
                innerWidth = this.innerWidth,
                height = this.innerHeight,
                xOffset = this.xOffset,
                radius = this.pointRadius,
                halfWidth = innerWidth / 2,
                top = height + radius;
            return {
                min: 0,
                mid: extent[1] / 2,
                max: extent[1],
                half: halfWidth,
                transform: new Ember.String.htmlSafe("translate(" + xOffset + "," + top + ")")
            };
        }),

    yAxis: Ember.computed("yScale", "yExtent", "innerHeight", "xOffset", function(key) {
        var y = this.yScale,
            extent = this.yExtent,
            innerHeight = this.innerHeight,
            radius = this.pointRadius,
            xOffset = this.xMargin + radius,
            halfHeight = innerHeight / 2;
        return {
            min: 0,
            mid: extent[1] / 2,
            max: extent[1],
            half: halfHeight,
            transform: new Ember.String.htmlSafe("translate(" + xOffset + ",0)")
        };
    }),

    tooltipProperties: Ember.computed("activeInterest", "width", "tooltipWidth", function(key) {
        var activeInterest = this.activeInterest || {},
            width = this.width,
            tooltipWidth = this.tooltipWidth,
            xOffset = this.xOffset,
            radius = this.pointRadius,
            buffer = 10,
            xValue = activeInterest.x + radius + xOffset + buffer || 0,
            yValue = activeInterest.y + radius || 0,
            textAnchor = "start",
            textPosition = 5;
        if (activeInterest.x > (width / 2)) {
            xValue = activeInterest.x - tooltipWidth - radius - buffer + xOffset;
            textAnchor = "end";
            textPosition = tooltipWidth - 5;
        }
        return {
            textAnchor: new Ember.String.htmlSafe(textAnchor),
            transform: new Ember.String.htmlSafe("translate(" + xValue + "," + yValue + ")"),
            textPosition: textPosition
        };
    }),

    xScale: Ember.computed("innerWidth", "xExtent", function(key) {
        var width = this.innerWidth || 0,
            extent = this.xExtent;
        return d3.scaleLinear()
            .domain([0, extent[1]])
            .range([0, width]);
    }),

    yScale: Ember.computed("innerHeight", "yExtent", function(key) {
        var height = this.innerHeight || 0,
            extent = this.yExtent,
            radius = this.pointRadius;
        return d3.scaleLinear()
            .domain([0, extent[1]])
            .range([height - radius, 0]);
    }),

    hoveredInterestChanged: () => {},
    selectedInterestChanged: () => {},
});
