import Ember from "ember";
import Component from '@ember/component';
import { computed, action } from '@ember/object';

export default class FieldsSelectorExamples extends Component {
    tagName = "tr";
    classNameBindings = [":data-table-row"];

    field = null;

    @action
    logField() {
        console.debug("Field", this.field);
    };
};
