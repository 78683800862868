export default [{
                label: "Household Income",
                icon: "cash-usd",
                type: "dollar",
                value: "medianHouseholdIncome"
            }, {
                label: "Disposable Income",
                icon: "cash-multiple",
                type: "dollar",
                value: "medianDisposableIncome"
            }, {
                label: "House Size",
                icon: "people",
                type: "",
                value: "averageHouseholdSize"
            }, {
                label: "Home Value",
                icon: "home",
                type: "dollar",
                value: "medianHouseValue"
            }, {
                label: "Higher Education",
                icon: "school",
                type: "percent",
                value: "educationAnyCollege"
            }];
