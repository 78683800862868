import Ember from "ember";
import config from "infegy-frontend/config/environment";
import * as Sentry from "@sentry/browser";

class Router extends Ember.Router {
    location = config.locationType;
    init(){
        this._super(...arguments);
        this.on("routeWillChange", (transition)=>{
            ga('send', 'pageview', {
                'page': this.url,
                'title': this.url
            });
            transition.then((transition) => {
                // Ensure the Transition exists before sending the event.  When 
                // the Transition doesn't exist, Emberjs sends a TransitionState 
                // instead which doesn't have the route information.
                if (!Ember.isEmpty(transition.routeName)) {
                    this.send("updateDocumentTitle", {});
                }
            });
            Sentry.addBreadcrumb({
                category: "ui",
                level: "info",
                message: `URL Change: ${this.currentPath}`
            });
        });
    }
}
Ember.Route.reopen({
    title: null,

    actions:{
        /**
         * Constructs a title based on the current route
         * Can return either `[query] | [title] | Infegy Atlas`
         *  or `[title] | Infegy Atlas` if not under the dashboard/content/ route
         */
        updateDocumentTitle(titleData={}){
            if (this.routeName === "application") {
                let finalTitle = [this.title];
                if (titleData.title) {
                    finalTitle.unshift(titleData.title);
                }
                if (titleData.query) {
                    finalTitle.unshift(titleData.query);
                }
                document.title = finalTitle.join(" · ");
            } else {
                // if this route has a title, construct the document title
                if(!titleData.title && this.title){
                    if(typeof this.title === "function") {
                        titleData.title = this.title();
                    } else {
                        titleData.title = this.title;
                    }
                }
                if (!titleData.query && this.queryTitle){
                    titleData.query = this.queryTitle;
                }
                // if this route doesn't have a title, bubble to a route that does
                return true;
            }
        }
    }

})

Router.map(function() {
    this.route("atlas", function() {
        this.route("dashboard", { path: "dashboard" }, function(){
            this.route("content", { path: ":query_url/*path" });
            this.route("content",{ path: ":query_url" },function(){
                this.route("overview");
                this.route("linguistics");
                this.route("subquery-sets");
                this.route("tags");
                this.route("sentiment");
                this.route("trends");
                this.route("influencers");
                this.route("posts");
                this.route("interests");
                this.route("export");
                this.route("demographics");
                this.route("geography");
                this.route("entities");
                this.route("narratives");
            });
        });
        this.route("query-editor", { path: "query-editor/:query_url/*wildcard" });
        this.route("admin", { path: "/admin" }, function() {
            this.route("biglist");
            this.route("demos", function(){
                this.route("live-linguistics");
                this.route("ad-targeting");
            });
            this.route("linkadder");
            this.route("designguide", function() {
                this.route("flags");
                this.route("colors");
            });
        });
        this.route("user-preferences");
        this.route("tools", function() {
            this.route("dataprocessor");
            this.route("custom-datasets", function(){
                this.route("create", function() {
                    this.route("index", { path: "/" });
                    this.route("verify-columns");
                    this.route("qualtrics-import");
                });
                this.route("append", { path: ":dataset_id" }, function() {
                    this.route("index", { path: "/" });
                    this.route("verify-columns");
                });
            });
            this.route("dictionaries", function(){
                this.route("edit", { path: ":dictionary_id" });
            });
            this.route("alerts", function() {
                this.route("new");
            });
            this.route("workspace-management", function() {
                this.route("list");
                this.route("editor", { path: "editor/:workspace_id" });
            });
            this.route("qualtrics", function() {
                this.route("data-pushers", function() {
                    this.route("list");
                    this.route("edit", { path: ":qualtrics_data_pusher_id"});
                });
            });
            this.route("query-sets", function() {
                this.route("index", { path: "/" });
                this.route("edit", { path: ":query_set_id" });
            });
            this.route("snippets", function() {
                this.route("index", { path: "/" });
                this.route("edit", { path: ":snippet_id" });
            });
            this.route("filtersets", function() {
                this.route("index", { path: "/" });
            });
        });
    });
    this.route("login");
    this.route("catch-all", { path: "*:" });
});

export default Router;
