import Component from "@ember/component";
import { computed } from '@ember/object';
import classic from "ember-classic-decorator";
import DateProcessing from "infegy-frontend/utils/date-processing";

@classic
export default class extends Component{
    fieldValue = '';

    @computed('fieldValue')
    get computedValue() {
        let value = this.fieldValue;

        if(isNaN(value) && typeof value != 'undefined') {
            // Date Parsing
            if(DateProcessing.getUTCDate(value) != "Invalid Date") {
                return DateProcessing.getUTCDate(value);
            }
            // URL Parsing
            if(value.indexOf('http://') === 0 || value.indexOf('https://') === 0) {
                return `<a href="${value}" target="_blank">${value}</a>`;
            }
        }

        return value;
    }
}