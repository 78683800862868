import Component from '@glimmer/component';
import { argument, actionArgument } from "infegy-frontend/decorators/arguments";
import { computed } from '@ember/object';
import AtlasAuth from "infegy-frontend/utils/atlas-auth";

export default class extends Component {
    @argument(false) enableSaveSnippet;
    @actionArgument saveSnippet;
    @actionArgument updateSnippet;
    @actionArgument applySnippet;

    showSaveSnippet = false;
    showSelectSnippet = false;

    atlasAuth = AtlasAuth;
    @computed.oneWay("atlasAuth.user") user;
    @computed.oneWay("user.savedQueryies.querySnippets") snippets;

    @computed("user.savedQueries.querySnippets.[]")
    get snippetOptions(){
        return this.user?.snippets;
    }
}