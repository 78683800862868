import Ember from "ember";

export default Ember.Component.extend({
    searchString: "",

    sortingType: "auto",
    filterType: "all",
    sortDirection: "",

    sortingTypeOptions: [{
        label: "Recently Used",
        value: "lastUsed"
    }, {
        label: "Created Date",
        value: "created"
    }, {
        label: "Title",
        value: "title"
    }],

    sortDirectionOptions: [
        {
            label: "Asc.",
            value: "ASC"
        },
        {
            label: "Desc.",
            value: "DESC"
        }
    ],

    filterTypeChanged: () => {},
    sortingTypeChanged: () => {},
    sortDirectionChanged: () => {}
});
