import Ember from "ember";
import SelfContainedTrends from "infegy-frontend/components/trends/self-contained-trends/component";

export default SelfContainedTrends.extend({
    segmentTypes: "themes",
    selectedTrendsSegment: "Themes",

    themeData: null,

    queries: null,

    themeSelected: () => {},
    actions: {
        fieldSelected: function (field) {
            if (!field || !field.get("fieldName")) {
                this.themeSelected(null);
                return;
            }
            var themeData = this.themeData,
                fieldTheme = /[a-z]+/.exec(field.get("fieldName"));
            if (!themeData || !fieldTheme) {
                this.themeSelected(null);
                return;
            }
            fieldTheme = fieldTheme && fieldTheme[0];
            var selectedTheme = themeData.find(emo => {
                    return Ember.get(emo, "theme") === fieldTheme;
                });
            this.themeSelected(selectedTheme);
        }
    }
});
