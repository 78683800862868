import Ember from 'ember';

export default Ember.Component.extend({
    user: null,
    isShowingApiKey: false,

    usageLeft: Ember.computed("user.usage", {
        get: function(key) {
            return this.get('user.usage.monthlyLimit') - this.get('user.usage.monthlyUsed');
        },
    }),

    hourlyUsed: Ember.computed("user.usage.hourlyUsed", {
        get: function(key) {
            return this.get('user.usage.hourlyUsed') / 600;
        },
    }),

    monthlyUsed: Ember.computed("user.usage.monthlyUsed", {
        get: function(key) {
            return this.get('user.usage.monthlyUsed') / this.get('user.usage.monthlyLimit');
        },
    }),
    actions: {
      toggleApiKeyDisplay: function() {
          this.toggleProperty("isShowingApiKey");
      }
    }
});
