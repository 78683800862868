import Ember from "ember";
import TrendsContainer from "infegy-frontend/components/trends/trends-container/component";

import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import QueryVolume from "infegy-frontend/models/query-api/volume";
import TrendableApiField from "infegy-frontend/models/fields/trendable-api-field";

export default TrendsContainer.extend({
    query: null,
    isCustomTrends: false,

    atlasEvents: Ember.computed.oneWay("query.events"),

    showOptions: true,
    eventHovered: false,
    showEventIcons: true,

    eventChartGroupBy: "_weeksSinceEpoch",
    onEventChartGroupByChanged: Ember.observer("eventChartGroupBy", function() {
        this.getEventVolume(this.eventChartGroupBy);
    }),

    displayedEvents: Ember.computed("atlasEvents.data.dataSeries", function() {
        const events = this.get("atlasEvents.data") || [];
        const displayedEvents = events.filter((event) => {
            return event.attributeType == "share" || event.attributeType == "hashtag";
        });

        return displayedEvents;
    }),

    queryGroupBy: Ember.computed("innerGroupByField", function () {
        var groupBy = null,
            field = this.get("innerGroupByField");
        if (groupBy == "_daysSinceEpoch") {
            groupBy = "day";
        }
        else if (groupBy == "_weeksSinceEpoch") {
            groupBy = "week";
        }
        else if (groupBy == "_monthsSinceZero") {
            groupBy = "month";
        }
        return groupBy;
    }),

    selectedViewOption: "Field Stats",
    viewOptions: ["Field Stats", "Filtered Posts"],

    fieldStatsViewSelected: Ember.computed.equal("selectedViewOption", "Field Stats"),

    selectedEvent: null,
    clickedEvent: null,
    isLoadingQueryVolume: false,

    shouldDisplayEventsData: Ember.computed("isCustomTrends", function() {
        return !this.isCustomTrends;
    }),

    filterableQueries: Ember.computed("query", function () {
        return [this.query.copy()];
    }),

    filteredPostsViewSelected: Ember.computed.equal("selectedViewOption", "Filtered Posts"),

    eventsDisplayed: Ember.computed.or("eventHovered"),
    eventsLoadedAndDisplayed: Ember.computed("isCustomTrends", "allEventsAreLoaded", "eventsDisplayed", function() {
        return !this.isCustomTrends && this.allEventsAreLoaded && this.eventsDisplayed;
    }),

    eventOverlayDisplayed: Ember.computed("selectedViewOption",
            "firstField.apiName", "allEventsAreLoaded", {
        get: function(key) {
            if (this.allEventsAreLoaded) {
                return this.get("firstField.apiName") === "volume";
            }
        },
    }),

    allEventsAreLoaded: Ember.computed("query.events.dataSeries", {
        get: function(key) {
            return this.get("query.events.dataSeries");
        },
    }),

    allEventData: Ember.computed("atlasEvents.data", function () {
        return Ember.Object.create({
            events: this.get("atlasEvents.data") || [],
        });
    }),

    getEventDataRange: function(data, field, min, max) {
        if (Ember.isEmpty(min) || Ember.isEmpty(max) || Ember.isEmpty(field) || max < min) {
            return data || [];
        }
        var rangeValue;
        var eventDataRange = (data || []).filter(function(topic) {
            rangeValue = Ember.get(topic, field);
            return rangeValue <= max && rangeValue >= min;
        }).sortBy("score").reverse();

        return eventDataRange;
    },

    filterAllEvents: function(data, filterField, min, max) {
        data = data || {};
        return Ember.Object.create({
            events: this.getEventDataRange(Ember.get(data, "events"), filterField, min, max),
        });
    },

    interactedEventData: Ember.computed("innerGroupByField", "allEventData",
            "selectionFilter", "selectionFilter.args",
            "interactionInfo.isSelected", "interactionInfo.selectedXValue",
            "interactionInfo.isHovered", "interactionInfo.hoveredXValue", {
        get: function(key) {
            var data = this.allEventData,
                field = this.innerGroupByField,
                filterArgs = this.get("selectionFilter.args"),
                val;
            if (this.get("interactionInfo.isHovered")) {
                val = this.get("interactionInfo.hoveredXValue");
                return this.filterAllEvents(data, field, val, val);
            } else if (this.get("interactionInfo.isSelected")) {
                val = this.get("interactionInfo.selectedXValue");
                return this.filterAllEvents(data, field, val, val);
            } else if (filterArgs) {
                return this.filterAllEvents(data, field, filterArgs[1], filterArgs[2]);
            }

            return data;
        }
    }),

    interactedHashtags: Ember.computed("interactedEventData", function () {
        let eventData = this.interactedEventData.events || [];
        return eventData.filter(row => row.attributeType === "hashtag");
    }),

    interactedShares: Ember.computed("interactedEventData", function () {
        let eventData = this.interactedEventData.events || [];
        return eventData.filter(row => row.attributeType === "share");
    }),

    hasEvents: Ember.computed("interactedHashtags", "interactedShares", function () {
        return (this.interactedHashtags && this.interactedHashtags.length) ||
            (this.interactedShares && this.interactedShares.length);
    }),

    queryVolumeTrendableField: null,

    actions: {
        fieldSelectionChanged(field) {
            // when the selected region on the trend-chart changes, clear the selected hashtag/share
            this.set("selectedEvent", null);
        },
        eventClicked: function(state) {
            this.set("selectedViewOption", "Stories and Events");
        },
        eventHovered: function(state) {
            this.set("eventHovered", state);
        },
        groupKeyChangedChild(value) {
            // pass the Action up to the base parent class
            this.send("groupKeyChanged", value);

            this.setProperties({
                clickedEvent: null,
                queryVolumeTrendableField: null
            });
        },
    },
});
