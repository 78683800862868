import Component from '@ember/component';
import classic from "ember-classic-decorator";

@classic
export default class FieldSparkline extends Component {
    field = null;

    // Display Options
    height = 60;
    showArea = false;
    showLine = true;

    // Data Options
    averagePoints = true;
    yAtZero = false;
    // Null or 0 uses supplied data length
    numberOfDisplayPoints = null;

    // Interactivity
    interactive = false;
    hoveredIndex = null;
    // Null or 0 first uses supplied numberOfDisplayPoints, then falls back to supplied data length
    numberOfInteractionPoints = null;

    hoveredIndexChanged() { /* action */ };
};
