import Ember from "ember";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import CustomDataset from "infegy-frontend/models/users/custom-dataset";

var CustomDatasets = Ember.ArrayProxy.extend({
    content: Ember.A(),
    setup: Ember.on("init",function() {
        AtlasAuth.Get("api/v3/custom-dataset/list").then((data) => {
            if (data.output) {
                var customDatasets = [];

                data.output.map((item) => {
                    var customDataset = CustomDataset.create();
                    customDataset.loadJSON(item);
                    customDatasets.push(customDataset);
                });

                this.set("content", customDatasets);
            }
        });
    })
});

export default CustomDatasets;
