import Ember from "ember";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";

export default class extends Ember.Controller {
    atlasAuth = AtlasAuth;
    @Ember.computed.readOnly("atlasAuth.user") user;

    isApiDown = null;
    connectionLost = null

    currentYear = (new Date()).getFullYear()
}
