import Ember from "ember";
import Component from '@ember/component';
import { computed } from '@ember/object';

import classic from "ember-classic-decorator";

@classic
export default class IconCheckbox extends Component {
    tagName = "span";
    classNameBindings = [":icon-checkbox", ":no-print", "isChecked:icon-checkbox-checked"];

    isChecked = false;

    label = "Check";

    click() {
        this.wasToggled();
    };

    wasToggled() { /* Action Callback */ };
};
