import Component from '@ember/component';
import { computed } from '@ember/object';
import classic from "ember-classic-decorator";

@classic
export default class FieldHorizontalBar extends Component {
    tagName = "div";
    classNames = ["data-table-bar-fluid"];

    field = null;

    @computed("field.portionPercentage")
    get barWidthStyle() {
        if (this.field) {
            let percentage = ((this.field.portionPercentage || 0.0) * 100.0).toFixed(1),
                color = this.field.colorString;
            return new Ember.String.htmlSafe(`width:${percentage}%;background-color:${color};`);
        }
    }
};
