import Ember from "ember";
import Component from '@ember/component';
import { action } from '@ember/object';
import classic from "ember-classic-decorator";

@classic
export default class NetTrendChart extends Component {
    dataSeries = null;

    xAttr = "timestamp";
    upperField = null;
    lowerField = null;

    upperColorClass = "chart-positive";
    lowerColorClass = "chart-negative";

    selectedIndex = null;
    hoveredIndex = null;

    drawArea = true;
    drawLine = true;
    drawCircles = true;

    height = 150;

    // Sends up detailed interaction information object including index, value, and dominantField
    wasHovered() { /* action placeholder */ };
    wasSelected() { /* action placeholder */ };

    // Sends only interacted index information
    indexWasHovered() { /* action placeholder */ };
    indexWasSelected() { /* action placeholder */ };

    @action
    chartWasSelected(interactionInfo) {
        this.wasSelected(interactionInfo);
        this.indexWasSelected(interactionInfo ? interactionInfo.index : null);
    };

    @action
    chartWasHovered(interactionInfo) {
        this.wasHovered(interactionInfo);
        this.indexWasHovered(interactionInfo ? interactionInfo.index : null);
    };
};
