import Ember from "ember";
import Countries from "infegy-frontend/static_data/geography/countries_json";
import UsStatesJSON from "infegy-frontend/static_data/geography/us_states_json";
import { CAProvinces } from "infegy-frontend/static_data/geography/ca_provinces";
import DMANames from "infegy-frontend/static_data/geography/dma_names";

var geoFilterOptions = {
    'states': {
        title: 'US States',
        baseIconClass: 'state-flag'
    },
    'countries': {
        title: 'Countries',
        baseIconClass: 'country-flag'
    },
    'caProvinces': {
        title: 'CA Provinces'
    },
    'dmas': {
        title: 'Marketing Areas'
    }
};

var noneOption = { title: "None", code:"__null__", hideDescription:true };

function buildOptionsProperty(options, hasNoneOption=true){
    return Ember.computed({
        get(){
            if (hasNoneOption) {
                return [noneOption].concat(options.map(item => Object.assign({}, item)));
            }
            else {
                return options;
            }
        },
        set(_, value){ return value; }
    });
}
Ember.defineProperty(geoFilterOptions.states, 'options', buildOptionsProperty(UsStatesJSON, false));
Ember.defineProperty(geoFilterOptions.caProvinces, 'options', buildOptionsProperty(CAProvinces, false));
Ember.defineProperty(geoFilterOptions.countries, 'options', buildOptionsProperty(Countries));
Ember.defineProperty(geoFilterOptions.dmas, 'options', Ember.computed(function() {
    return Object.keys(DMANames).map((key) => ({
            title: DMANames[key],
            code: key
        }));
}));

export default geoFilterOptions;