import Ember from "ember";

export default Ember.Route.extend({
    title: "Workspaces",
    afterModel: function(model, transition) {
        if (transition.targetName === "atlas.tools.workspace-management.index") {
            this.transitionTo("atlas.tools.workspace-management.list");
        }
    },
});
