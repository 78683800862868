import Ember from 'ember';
import jsonTools from "infegy-frontend/utils/json-tools";
import ArrayBase from "infegy-frontend/models/array_compat";

export default Ember.Mixin.create({
    parentObject: null,
    _dirtiesParent: true,

    _changeLog: [],
    _dirty: false,
    _lastDirtied: 0,

    // Static fields should be serialized even if they don't exist in the "_data"
    // storage. Example use would be for a className property that should be output
    // but does not change from the default, so it would not be registered in "_data":
    //
    // _staticProperties: ["className"],
    // className: "MyClass",
    //
    // Additionally, staticFields are ignored when de-serializing json data to
    // prevent overwriting static information
    _staticProperties: null,

    // FieldGroups provide a means for alternate serialization output for different purposes
    // You can pass one or more field group names with toJSON's options to either include (default)
    // Or exclude certain fields from serialization.
    //
    // Example Declaration:
    //  _fieldGroups: {
    //      api: {
    //          excludes: ["color", "name"]
    //      },
    //      dateTimeOnly: {
    //          includes: ["startDate", "endDate"]
    //      },
    //      labelsOnly: {
    //          include: ["title", "label"]
    //      },
    //      shorterDateTimeOnly: ["startDate", "endDate"]
    //  }
    //
    //
    // Usage Examples:
    //   myObj.toJSON({fieldGroups: "dateTimeOnly"});
    //      might return:
    //   {startDate: 123456789, endDate: 987654321};
    //
    //   myObj.toJSON({fieldGroups: ["dateTimeOnly", "labelsOnly"]});
    //      might return:
    //   {startDate: 123456789, endDate: 987654321, title:"hi", "label": "Hello!"};
    _fieldGroups: null,

    _dataCache: null,
    _data: Ember.computed({
        get: function() {
            var data = this._dataCache;
            if (!data) {
                data = {};
                this.set("_dataCache", {});
            }
        },
        set: function (key, value) {
            var existingData = this._dataCache;
            if (Ember.isEmpty(value)) {
                console.error("tried to set dataCache to empty", value);
                value = {};
            }
            if (Ember.typeOf(value) !== "object") {
                console.error("tried to set dataCache to non object:", value);
                value = existingData || {};
            }
            if (value !== existingData) {
                this.set("_dataCache", value);
            }

            return value;
        },
    }),

    clear: function () {
        this.set("_data", {});
    },

    isDirty: Ember.computed("parentObject", {
        get: function() {
            var isDirty = this._dirty;
            return isDirty;
        },
        set: function(key, value) {
            var currentStatus = !!this._dirty,
                dirtiesParent = this._dirtiesParent,
                parentObject = this.parentObject;

            if (value && parentObject && dirtiesParent && Ember.typeOf(parentObject) === "instance") {
                parentObject.set("isDirty", true);
            }

            if (value) {
                this.set("_lastDirtied", Date.now());
            }
            if (value !== currentStatus) {
                this.set("_dirty", value);
                if (value) {
                    this.trigger("dirtyWasSet", value);
                }
            }

            return value;
        }
    }),

    toJSON: function (options) {
        options = Object.assign({
            clearDirty: false,
            shallow: false,
            ignoreUnknownProperties: false,
            decamelizeKeys: true,
            fieldGroups: null, // Array of optional keys to _fieldGroups serialization lists
        }, options);

        var includeFields = [], excludeFields = [],
            selectedFieldGroups = options.fieldGroups,
            fieldGroups = this._fieldGroups;
        if (selectedFieldGroups && fieldGroups) {
            if (typeof(selectedFieldGroups) === "string") {
                selectedFieldGroups = selectedFieldGroups.split(",");
            }
            if (Ember.isArray(selectedFieldGroups)) {
                selectedFieldGroups.map(groupName => {
                    return (groupName && Ember.get(fieldGroups, groupName)) || null;
                }).filter(group => group).forEach(group => {
                    if (typeof(group) === "string") {
                        group = group.split(",");
                    }
                    if (Ember.isArray(group)) {
                        includeFields = includeFields.concat(fieldGroups.name);
                    } else if (typeof(group) === "object") {
                        if (Ember.isArray(group.excludes)) {
                            excludeFields = excludeFields.concat(group.excludes);
                        }
                        if (Ember.isArray(group.includes)) {
                            includeFields = includeFields.concat(group.includes);
                        }
                    }
                });
            }
        }

        var jsonData = null;
        if (this._isCollection) {
            jsonData = this.map(row => {
                return row && row.toJSON && row.toJSON(options);
            });
        } else {
            var numProps = 0,
                data = this._data,
                _staticProperties = this._staticProperties || [];
            jsonData = {};
            _staticProperties.forEach(propertyName => {
                if (propertyName && this[propertyName]) {
                    jsonData[propertyName] = this[propertyName];
                }
            });
            for (var key in data) {
                if (data.hasOwnProperty(key) && !Ember.isEmpty(data[key])) {
                    if ((!Ember.isEmpty(includeFields) && !includeFields.includes(key)) ||
                            (!Ember.isEmpty(excludeFields) && excludeFields.includes(key)) ||
                            key === "parentObject") {
                        continue;
                    }
                    var newProp = jsonTools.makeJsonProp(this.get(key), options),
                        newKey = options.decamelizeKeys ? key.decamelize() : key;
                    if (newProp !== null) {
                        jsonData[newKey] = newProp;
                        numProps++;
                    }
                }
            }

            if (!numProps) {
                return null;
            }
        }
        if (options.clearDirty) {
            this.set("isDirty", false);
        }
        return jsonData;
    },

    toJSONString: function (options) {
        return JSON.stringify(this.toJSON(options));
    },

    jsonData: Ember.computed("_lastDirtied", function () {
        return this.toJSON();
    }),

    jsonString: Ember.computed("jsonData", function() {
        var data = this.jsonData;
        if (!data || typeof(data) !== "object") {
            return "{}";
        }
        if (typeof(data) === "string") {
            return data;
        }
        return JSON.stringify(data);
    }),

    jsonPrettyString: Ember.computed("jsonData", function () {
        var data = this.jsonData;
        if (!data || typeof(data) !== "object") {
            return "{}";
        }
        if (typeof(data) === "string") {
            return data;
        }
        return JSON.stringify(data, null, 2);
    }),

    setup: Ember.on("init",function() {
        this.set("_data", {});
        this.set("_changeLog", ArrayBase.create());
    }),
});
