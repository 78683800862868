import JsonStore from "infegy-frontend/json-store/model";
import Prop from "infegy-frontend/json-store/properties/model";

export default class extends JsonStore {
    @Prop.String() name;
    @Prop.Attr() possibleTypes;
    @Prop.String() probableType;
    @Prop.Attr() probableTraits;
    @Prop.Attr() samples;
    @Prop.Int() size;

    // dynamic content descriptor data
    customName = "";
    exclude = false;
    locked = false;
    type = "";
    traits = [];

    loadJSON(json) {
        super.loadJSON(json);
        // If a column is completely empty of data the API will not be able to detect any 
        // `possibleTypes`.  In this case the column should be excluded without the 
        // ability to add it back in through the UI.
        if (!this.possibleTypes || this.possibleTypes.length === 0) {
            this.setProperties({
                exclude: true,
                locked: true
            });
        }
        if (this.probableType) {
            // Default the initial type to the most likely probable type.
            this.set("type", this.probableType);
        }
    }

    toJSON() {
        if (this.exclude) {
            return null;
        }
        
        let contentDescriptor = {
            name: this.customName ? this.customName : this.name,
            type: this.type
        };
        for (let trait of this.traits) {
            // jjw TODO: Should the trait definitions be moved to this class?
            // Example trait from content-descriptor/item/component.js
            // { label: "Index", name: "index", type: "boolean", value: true, options: [{label: "True", value: true}, {label: "False", value: false}] },
            contentDescriptor[trait.name] = trait.value;
        }
        return contentDescriptor;
    }
}
