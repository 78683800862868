import Ember from "ember";
import PostFilters from "infegy-frontend/static_data/post_filters";
import { titleCase } from "infegy-frontend/helpers/title-case";

function filterActive(_fieldName) {
    return Ember.computed("activeFilters", {
        get() {
            return this.activeFilters.includes(_fieldName);
        }, set(key,value) {
            this.activeFiltersChanged(key,value);
        }
    });
}

export default Ember.Component.extend({
    queries:null,

    filterOptions: PostFilters,
    activeFilters: [],
    postFilters: {},

    entities: [],
    entitiesActive: filterActive("sub_entity_id"),

    filtersChanged: function() { /* default action handler */ },

    getFilters(filterID) {
        let queries = this.queries || [],
            filters = queries[0].queryInfo.datasetQueryInfo.filters.find(filter => filter.id === filterID).options || [],
            options = [];

        filters.forEach(filter => {
            options.pushObject({
                label: filter.name,
                value: filter.id
            });
        });

        return options;
    },

    interests: [],
    interestsActive: filterActive("sub_post_interests"),
    interestsOptions: Ember.computed('queries', function() {
        return this.getFilters("taxonomies");
    }),

    emotions: [],
    emotionsActive: filterActive("sub_emotions"),
    emotionOptions: Ember.computed('queries', function() {
        return this.getFilters("emotions");
    }),

    themes: [],
    themesActive: filterActive("sub_themes"),
    themeOptions: Ember.computed('queries', 'queries.@each.updated', 'themes', function() {
        let themeOptions = this.getFilters("themes"),
            customThemeOptions = [];

        this.queries.forEach(query => {
            let customThemes = query.themes?.themeTypes?.filter(theme => theme.customTheme) || [];
            customThemes.forEach(customTheme => {
                if (!customThemeOptions.includes(customTheme.theme)) {
                    customThemeOptions.push(customTheme.theme);
                }
            });
        });

        customThemeOptions.forEach(customTheme => {
            themeOptions.pushObject({
                label: titleCase(customTheme),
                value: customTheme
            });
        });

        return themeOptions;
    }),

    sentiment: [],
    sentimentActive: filterActive("sub_sentiment"),
    sentimentOptions: Ember.computed('queries', function() {
        return this.getFilters("sentiment");
    }),

    channels: [],
    channelsActive: filterActive("sub_channels"),
    channelsOptions: Ember.computed('queries', function() {
        return this.getFilters("channels");
    }),

    updateFilters: function() {
        let activeFilters = this.activeFilters,
            newFilters = this.postFilters,
            filterArray = Object.keys(newFilters).reduce((pv,filter) => {
                if (newFilters[filter].length > 0 && activeFilters.includes(filter)) {
                    pv.push(Ember.Object.create({
                        name: filter,
                        selectedValues: newFilters[filter],
                        rawValue: ["sub_entity_id","sub_post_interests"].includes(filter)
                    }));
                }
                return pv;
            },[]);

        this.filtersChanged(filterArray);
    },

    actions: {
        filtersChanged(key,val) {
            var cachedFilters = this.postFilters,
                newFilters = Ember.assign({}, cachedFilters);

            newFilters[key] = val;

            if (JSON.stringify(cachedFilters) !== JSON.stringify(newFilters)) {
                this.set("postFilters",newFilters);
                this.updateFilters();
            }
        },
        activeFiltersChanged(activeFilters) {
            this.set("activeFilters",activeFilters);
            this.updateFilters();
        },
        selectedEntitiesChanged(selectedValues) {
            this.set("entities", selectedValues);
            this.send("filtersChanged", "sub_entity_id", selectedValues.map((item) => item.value));
        },
        selectedInterestsChanged(selectedValue) {
            this.set("interests", selectedValue);
            this.send("filtersChanged", "sub_post_interests", selectedValue);
        },
        selectedEmotionsChanged(selectedValue) {
            this.set("emotions", selectedValue);
            this.send("filtersChanged", "sub_emotions", selectedValue);
        },
        selectedThemesChanged(selectedValue) {
            this.set("themes", selectedValue);
            this.send("filtersChanged", "sub_themes", selectedValue);
        },
        selectedSentimentChanged(selectedValue) {
            this.set("sentiment", selectedValue);
            this.send("filtersChanged", "sub_sentiment", selectedValue);
        },
        selectedChannelsChanged(selectedValue) {
            this.set("channels", selectedValue);
            this.send("filtersChanged", "sub_channels", selectedValue);
        }
    }
});
