export default {
    // Slightly modified from : https://stackoverflow.com/questions/1497481/javascript-password-generator
    generateRandomString: function(len, specialCharacters=true){
        let length = len ? len : 10,
            lowerString = "abcdefghijklmnopqrstuvwxyz",
            upperString = "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
            numeric = '0123456789',
            punctuation = '-_!@#$',
            password = "",
            character = "",
            crunch = true;

        while( password.length < length ) {
            let entity1 = Math.ceil(lowerString.length * Math.random() * Math.random()),
                entity2 = Math.ceil(upperString.length * Math.random() * Math.random()),
                entity3 = Math.ceil(numeric.length * Math.random() * Math.random()),
                entity4 = Math.ceil(punctuation.length * Math.random() * Math.random());
            character += lowerString.charAt(entity1);
            character += upperString.charAt(entity2);
            character += numeric.charAt(entity3);
            if (specialCharacters) {
                character += punctuation.charAt(entity4);
            }
            password = character;
        }
        password = password.split('').sort(() => { return 0.5 - Math.random() }).join('');
        return password.substr(0,len);
    }
}
