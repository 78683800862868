import Ember from "ember";
import TopicsAPI from "infegy-frontend/models/query-api/topics";

var computedSortField = function(_fieldName) {
    var fieldName = _fieldName;
    return Ember.computed("sortAscending", "sortField", function () {
        if (this.sortField === fieldName) {
            return this.sortAscending ? "sorted-asc" : "sorted-desc";
        }
    });
};

export default Ember.Component.extend({
    classNames: ["table-overflow-scroll"],
    topics: [],
    sortAscending: true,
    sortField: "topic",

    selectedTopic: null,
    selectedField: null,

    hasQueryField: false,

    collate: true,
    expandable: true,

    paginated: true,
    topicsPerPage: 50,

    currentPage: 0,
    topicsType: null,

    hasPages: Ember.computed("paginated", "sortedTopics.length", "topicsPerPage", function () {
        return this.paginated && this.get("sortedTopics.length") > this.topicsPerPage;
    }),

    sortedTopics: Ember.computed("collatedMatchingTopics", "sortAscending", "sortField", function () {
        var topics = this.collatedMatchingTopics || [],
            asc = this.sortAscending,
            sortField = this.sortField;
        return topics.sort((topicA, topicB) => {
            var a = asc ? Ember.get(topicA, sortField) : Ember.get(topicB, sortField),
                b = asc ? Ember.get(topicB, sortField) : Ember.get(topicA, sortField);
            if (typeof(a) === "string") {
                return a.localeCompare(b);
            }
            return a - b;
        });
    }),

    collatedMatchingTopics: Ember.computed("topics", "hasQueryField", "collate", function () {
        var topics = this.topics ?? [],
            keyMap, collatedTopics = [];

        if (!this.hasQueryField || Ember.isEmpty(topics) || !this.collate) {
            return topics;
        }
        keyMap = topics.reduce((map, topic, idx) => {
            var key = topic.key || idx;
            if (!map[key]) {
                map[key] = [];
            }
            map[key].push(topic);
            return map;
        }, {});
        for (let key in keyMap) {
            if (keyMap.hasOwnProperty(key)) {
                var collatedTopic = TopicsAPI.combineMatchingTopics(keyMap[key]);
                collatedTopics.push(collatedTopic);
            }
        }
        return collatedTopics;
    }),

    pagedTopics: Ember.computed("sortedTopics", "currentPage", "topicsPerPage", "hasPages", function () {
        var topics = this.sortedTopics || [],
            currentPage = this.currentPage || 0,
            topicsPerPage = this.topicsPerPage,
            offset = currentPage * topicsPerPage;
        if (!this.hasPages) {
            return topics;
        }
        return topics.slice(offset, offset + topicsPerPage);
    }),

    isTopicTypeSourceBio: Ember.computed.equal("topicType", "sourceBioTopics"),

    topicSort: computedSortField("topic"),
    querySort: computedSortField("_originalQuery.title"),
    trendSort: computedSortField("documentsGrowthPct"),
    sentimentSort: computedSortField("positiveAppearancesDistribution"),
    passionSort: computedSortField("passion"),
    documentsSort: computedSortField("documents"),
    universeSort: computedSortField("universe"),
    appearancesSort: computedSortField("appearancesPerPost"),

    topicSelected: () => {},
    removeTopic: ()=>{},
    actions: {
        sortBy: function(newSortField) {
            var sortField = this.sortField;
            if (newSortField === sortField) {
                this.toggleProperty("sortAscending");
                this.set("currentPage", 0);
            } else {
                this.setProperties({
                    sortField: newSortField,
                    sortAscending: newSortField === "topic" || newSortField === "_originalQuery.title",
                    currentPage: 0
                });
            }
        },
    }
});
