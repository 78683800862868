import Ember from "ember";
import { isEmpty, isNone } from "@ember/utils";
import JsonStore from "infegy-frontend/json-store/model";
import CustomDatasetFields from "infegy-frontend/static_data/custom-dataset-fields";

import Prop from "infegy-frontend/json-store/properties/model";
import Column from "infegy-frontend/models/custom-datasets/column";
import Binding from "infegy-frontend/models/custom-datasets/binding";

export default JsonStore.extend({
    columns: Prop.Collection(Column),
    name: Prop.String(),
    rowCount: Prop.Int(),
    value: null, // this is actually the sheet index

    title: Ember.computed.alias("name"),

    // dynamic content descriptor data
    exclude: false,

    get hasColumn() {
        const found = (this.columns || []).find((column) => !column.exclude);
        return !isEmpty(found);
    },

    headers: Ember.computed("columns", "columns.[]", function() {
        const columns = this.columns;
        return ["None"].concat(columns.mapBy("name"));
    }),

    bindings: Ember.computed("columns", "columns.[]", function() {
        const headers = this.headers;
        let sheetBindings = [];
        
        CustomDatasetFields.forEach((item, index)=>{
            let value = "None";
            let headerBinding = headers.find((header) => {
                if (Ember.isNone(header)) {
                    header = '';
                }
                
                let splitHeader = header.includes(" ") ? header.split(" ") : [header],
                    hasHeader = splitHeader.filter((headerPart) => {
                        return headerPart.toLowerCase().startsWith(item.apiField.toLowerCase());
                    });

                if (hasHeader.length) {
                    value = header;
                    return true;
                }
                return false;
            });

            const newBindingConfig = Object.assign(item, {
                columns: this.columns,
                isActive: !Ember.isEmpty(headerBinding),
                rowCount: this.rowCount,
                value: [value]
            });

            let newBinding = Binding.create(newBindingConfig);
            sheetBindings.push(newBinding);
        });

        return sheetBindings;
    }),

    activeBindings: Ember.computed("bindings", "bindings.[]", function() {
        const bindings = this.bindings;
        const activeBindings = bindings.filter(binding => {
            return binding.isRequired || binding.isActive;
        });
        return activeBindings;
    }),

    activeBindingsSize: Ember.computed("activeBindings", "activeBindings.[]", "bindings.@each.size", function() {
        const activeBindings = this.activeBindings;
        let activeBindingsSize = activeBindings.reduce((total, item) => (item.size || 0) + total, 0);
        return activeBindingsSize;
    }),

    toJSON() {
        if (this.exclude) {
            return null;
        }
        const columns = this.columns.map((column) => {
            return column.toJSON();
        });
        return columns;
    }
});
