import Ember from 'ember';
import JsonStore from 'infegy-frontend/json-store/model';
import Base from '../base/model';
import ArrayBase from "infegy-frontend/models/array_compat";

export default ArrayBase.extend(Base, {
    _isCollection: true,

    itemClass: JsonStore,

    setup: Ember.on("init",function() {
        if (!this.content) {
            this.set("content", Ember.A());
        }
    }),

    clear: function () {
        this.set("content", Ember.A());
        this.setProperties({
            "_lastDirtied": Date.now(),
            "isDirty": true
        });
        this.trigger("dirtyWasSet", true);
    },

    enumerableContentDidChange: function () {
        this._super();
        this.setProperties({
            "_lastDirtied": Date.now(),
            "isDirty": true
        });
        this.trigger("dirtyWasSet", true);
    },

    loadJSON: function(jsonArray, options) {
        options = Object.assign({
            clearDirty: false,
            ignoreUnknownProperties: false
        }, options);

        var itemClass = this.itemClass || JsonStore;

        if (!Ember.isArray(jsonArray)) {
            jsonArray = [jsonArray];
        }

        var itemArray = jsonArray.filter(row => {
            return !!row;
        }).map(row => {
            var newItem = itemClass.create();
            newItem.set("parentObject", this);
            newItem.loadJSON(row, options);
            return newItem;
        });

        this.pushObjects(itemArray);
        if (options.clearDirty) {
            this.set("isDirty", false);
        }
    },

    loadJSONRow: function(jsonObj, options) {
        var itemClass = this.itemClass || JsonStore;

        if (jsonObj && typeof(jsonObj) === "object") {
            let newItem = itemClass.create();
            newItem.set("parentObject", this);
            newItem.loadJSON(jsonObj, options);
            this.pushObject(newItem);
        }
    },
});

