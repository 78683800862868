import Ember from "ember";
import d3 from "d3";

export default Ember.Component.extend({
    contentArea: null,

    attributeBindings: ["x", "y", "safeWidth", "safeHeight", "viewBox"],
    x: Ember.computed.alias("contentArea.x"),
    y: Ember.computed.alias("contentArea.y"),
    width: Ember.computed.alias("contentArea.width"),

    viewBox: null,

    safeWidth: Ember.computed("width", {
        get: function(key) {
            return this.width > 0 ? this.width : 1;
        }
    }),
    safeHeight: Ember.computed("height", {
        get: function(key) {
            return this.height > 0 ? this.height : 1;
        }
    }),

    offsetWidth: Ember.computed("width", "x", {
        get: function(key) {
            return this.width + this.x;
        },
    }),

    offsetHeight: Ember.computed("height", "x", {
        get: function(key) {
            return this.height + this.y;
        },
    }),

    height: Ember.computed.alias("contentArea.height"),
    tagName: "svg",

    didInsertElement: function() {
        this._super();
        this.set("el", d3.select("#" + this.element.id));
    }
});