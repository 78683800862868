import Ember from "ember";
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import ChartColors from "infegy-frontend/static_data/fixtures/chart_colors";

export default class SentimentDetailsNetTrend extends Component {
    dataSeries = null;
    sentimentField = "documents";

    groupKey = "timestamp";

    isSelected = false;
    selectedIndex = null;
    hoveredIndex = null;

    positiveField = "positiveDocumentsDistribution";
    negativeField = "negativeDocumentsDistribution";

    chartHeight = 150;

    interactionLabel = "overall";

    activeValues = null;

    @computed("activeValues")
    get netSentiment() {
        if (!this.activeValues) {
            return 0;
        }
        let positive = this.activeValues.positive || 0,
            negative = this.activeValues.negative || 0;
        return positive - negative;
    };

    @computed("netSentiment")
    get netSentimentAbs() {
        return Math.abs(this.netSentiment);
    };

    @computed("netSentiment")
    get netSentimentLabel() {
        return (this.netSentiment >= 0) ? "positive" : "negative";
    };

    @computed("netSentiment")
    get netSentimentColorSafe() {
        let color = ChartColors.sentimentGradientD3()(this.netSentiment);
        return new Ember.String.htmlSafe(`color:${color};`);
    };

    hoveredIndexChanged() { /* action placeholder */ };
    selectedIndexChanged() { /* action placeholder */ };

    @action
    wasSelected(interactionInfo) {
        this.selectedIndexChanged(interactionInfo && interactionInfo.index);
    };

    @action
    wasHovered(interactionInfo) {
        this.hoveredIndexChanged(interactionInfo && interactionInfo.index);
    };
};
