import Ember from "ember";
import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";
import fieldTimelineGrowth from "infegy-frontend/models/data_series/data_series_computed_field_growth";

var dmaResults = QueryAPIResults.extend({
    title: "dma",
    dateField: null,
    description: DataSeriesDescription.load({
        "id": "string",
        "name": "string",
        "key": "string",

        "matches": "number",
        "sources": "number",

        "positiveMatches": "number",
        "negativeMatches": "number",
        "neutralMatches": "number",
        "positivity": "number",
        "timeline": "array"
    }, [
        fieldTimelineGrowth.fieldSetup("documentsGrowthPct", "timeline")
    ]),
    preload: function(){
        var dmas = this.get("rawResults");
        dmas.forEach((dma) => {
            dma.key = dma.name.toLowerCase().replace(/[^a-z0-9\s\-]/g,'').replace('-',' ').replace(/[\s]{2,}/g, ' ');
        });
    },
    customEmptyCheckFunction: function(dataSeries){
        var data = Ember.get(dataSeries, "data");
        return data.reduce((sum, current) => Ember.get(current,"sources"), 0) === 0;
    }
});

export default dmaResults;
