import Ember from "ember";

// Idea taken from https://github.com/thefrontside/emberx-file-input
export default Ember.Component.extend({
    attributeBindings: ["disabled","accept"],
    name: "",
    disabled: false,
    accept: null,

    fieldId: Ember.computed("id",function(){
        var id = this.id;
        return `${id}-upload-input`;
    }),

    change(e) {
        var files = Ember.get(e, "target.files");
        this.filesChanged(files);
    }
});