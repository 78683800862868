import CountriesJSON from "infegy-frontend/static_data/geography/countries_json";

// TODO: Remove below, redundant with global geo json
var CountriesUncategorized = {
    "label": "Uncategorized",
    "title": "Uncategorized",
    "value": "00",
    "alternates": ["Uncategorized"],
};

var Countries = CountriesJSON.map(function(row) {
    return {
        title: row.title || row.label,
        label: row.label || row.title,
        alternates: row.alternates,
        value: row.code,
        region: row.region
    };
});

Countries.push(CountriesUncategorized);

export default Countries;
