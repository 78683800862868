import { tracked } from '@glimmer/tracking';

/**
 * Defines a property that gets its value from a property of the same name passed to `args`
 * Can define a defaultValue to be used when the value passed in is null
 * @param {*} defaultValue 
 */
export function argument(defaultValue=null){
    return function(object, propertyName, descriptor){
        return {
            get() {
                return (this?.args && this.args[propertyName]) ?? defaultValue;
            },
            set(_) {
                console.error(`Attempted to set "${propertyName}" which is a parameter. You can no longer set paramters from within components, you must call an action which sets the parameter in the container context.`)
            }
        };
    };
}

/**
 * Defines a property on the class that links to the corresponding action passed in via `args` 
 * Same as Ember.computed.oneWay("args.{propertyName}")
 */
export function actionArgument(object, propertyName, descriptor){
    return {
        get(){
            return this?.args && this.args[propertyName] ;
        },
        set(){
            console.error(`Cannot overwrite action argument ${propertyName}`);
        }
    };
}