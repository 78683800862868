import Ember from "ember";
import Component from '@ember/component';
import {computed, action} from "@ember/object";

var computedSortField = function(_fieldName) {
    var fieldName = _fieldName;

    return Ember.computed("sortAscending", "sortField", function () {
        if (this.get("sortField") === fieldName) {
            return this.get("sortAscending") ? "sorted-asc" : "sorted-desc";
        }
    });
};

export default class SubquerySetsTable extends Component {
    classNames = ["entities-table"];

    originalQueries = [];
    subquerySetsQueries = [];

    querySets = {};

    sortAscending = false;
    sortField = "universe";

    selectedTag = null;

    expandable = true;

    includeEmptySubquerySets = false;

    isPaginated = true;
    subquerySetsPerPage = 30;
    currentPage = 0;

    @computed("isPaginated", "sortedSubquerySets.length", "subquerySetsPerPage")
    get hasPages() {
        return this.isPaginated && this.sortedSubquerySets?.length > this.subquerySetsPerPage;
    }

    @computed("subquerySetsQueries.@each.updated", "includeEmptySubquerySets")
    get subquerySetsData() {
        if (this.subquerySetsQueries?.length === 1) {
            return this.subquerySetsQueries?.firstObject?.subquerySets?.tagData;
        }
    }

    @computed("originalQueries", "originalQueries.[]", "originalQueries.@each.updated")
    get linguisticsUniverse() {
        let queries = this.originalQueries || [],
            linguisticsUniverse = queries.reduce((memo, query) => {
                return memo + query?.subquerySets?.meta?.linguisticsUniverse || 0;
            }, 0);
        return linguisticsUniverse;
    }

    @computed("subquerySetsData", "sortAscending", "sortField")
    get sortedSubquerySets() {
        var rows = this.subquerySetsData || [],
            asc = this.sortAscending,
            sortField = this.sortField;
        if (Ember.isEmpty(rows) || !rows.sort) {
            return [];
        }
        return rows.sort((itemA, itemB) => {
            var a = asc ? Ember.get(itemA, sortField) : Ember.get(itemB, sortField),
                b = asc ? Ember.get(itemB, sortField) : Ember.get(itemA, sortField);

            if (typeof(a) === "string") {
                return a.localeCompare(b);
            }

            return a - b;
        });
    }

    @computed("sortedSubquerySets", "currentPage", "subquerySetsPerPage", "hasPages")
    get paginatedSubquerySets() {
        var sortedSubquerySets = this.sortedSubquerySets || [],
            currentPage = this.currentPage || 0,
            subquerySetsPerPage = this.subquerySetsPerPage || 100,
            currentIndex = currentPage * subquerySetsPerPage;

        if (!this.hasPages) {
            return sortedSubquerySets;
        }

        if (currentIndex > sortedSubquerySets.length) {
            currentIndex = 0;
        }

        return sortedSubquerySets.slice(currentIndex, currentIndex + subquerySetsPerPage);
    }

    @computedSortField("tagName") nameSort;
    @computedSortField("universe") trendSort;
    @computedSortField("positiveDocumentsDistribution") sentimentSort;
    @computedSortField("universe") universeSort;
    @computedSortField("reach") reachSort;
    @computedSortField("age") ageSort;
    @computedSortField("gender") genderSort;

    @action
    tagSelected(tag) {
        if (this.selectedTag !== tag) {
            this.set("selectedTag", tag);
        } else {
            this.set("selectedTag", null);
        }
    }

    @action
    sortBy(newSortField) {
        var sortField = this.sortField;
        if (newSortField === sortField) {
            this.toggleProperty("sortAscending");
        } else {
            this.setProperties({
                currentPage: 0,
                sortField: newSortField,
                sortAscending: newSortField === "topic" || newSortField === "_originalQuery.title"
            });
        }
    }
}
