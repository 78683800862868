import Ember from "ember";

export default Ember.Component.extend({
    classNames: ["query-details-filter"],

    filter: null,

    viewAll: false,

    viewAllThreshold: 5,
    viewAllAllowance: 2,

    audienceSegments: null,
    dictionaries: null,

    options: Ember.computed("filter.name", "filter.options", "dictionaries", "audienceSegments", function () {
        var name = this.get("filter.name"),
            options = this.get("filter.values"),
            dictionaries = this.dictionaries,
            audienceSegments = this.audienceSegments;

        if (name === "Dictionaries" && dictionaries) {
            return dictionaries;
        } else if (name === "AudienceSegments" && audienceSegments) {
            return audienceSegments;
        }
        return options;
    }),

    values: Ember.computed("filter.value", "filter.value.[]", "options", function () {
        var vals = this.get("filter.value"),
            options = this.options;

        if (typeof(vals) === "string") {
            vals = vals.split(",");
        }

        // can't map objects...
        if (typeof(vals) === "object") {
            vals = Object.values(vals);
        }

        return vals.map(val => {
            var opt = options && options.find(opt => {
                return Ember.get(opt, "value") + "" === val + "";
            });
            return opt && Ember.get(opt, "label") || val;
        });
    }),

    shouldTruncate: Ember.computed("viewAllThreshold", "viewAllAllowance",
        "values", "values.length", function () {
            var cutoff = this.viewAllThreshold + this.viewAllAllowance;
            return this.get("values.length") > cutoff;
    }),

    showLink: Ember.computed("shouldTruncate", "viewAll", function () {
        return this.shouldTruncate && !this.viewAll;
    }),

    displayValues: Ember.computed("viewAll", "values", "values.length",
            "viewAllThreshold", "shouldTruncate", function () {
        var values = this.values || [];
        if (this.shouldTruncate && !this.viewAll) {
            values = values.slice(0, this.viewAllThreshold);
        }
        return values.join(", ");
    }),

    numMore: Ember.computed("viewAllThreshold", "values.length", function () {
        return this.get("values.length") - this.viewAllThreshold;
    }),

    actions: {
        toggleViewAll: function() {
            this.toggleProperty("viewAll");
        }
    }
});
