export default {
    all: {
        segmentOptions: [
            { value: "Posts" },
            { value: "Engagements" },
            { value: "Sentiment"},
            { value: "Passion" },
            { value: "Gender" },
            { value: "Channels"},
            { value: "Emotions" },
            { value: "Themes" },
            { value: "Languages"}],
        subSegmentOptions: {
            "Posts" : [
                "Posts.Universe",
                "Posts.PostsNormalizedUniverse",
                "Posts.Count",
                "Posts.WordNormalized",
                "Posts.PostsPerBillion",
            ],
            "Engagements" : [
                "Engagements.EngagementsUniverse",
                "Engagements.LikesUniverse",
                "Engagements.CommentsUniverse",
                "Engagements.SharesUniverse",
                "Engagements.Engagements",
                "Engagements.Likes",
                "Engagements.Comments",
                "Engagements.Shares",
            ],
            "Sentiment" : [
                "Sentiment.DocumentsDistribution",
                "Sentiment.SubjectSentencesDistribution",
                "Sentiment.SentencesDistribution",
                "Sentiment.SubjectSentences",
                "Sentiment.Sentences",
                "Sentiment.Documents",
                "Sentiment.Universe"
            ],
            "Passion" : [
                "Passion.Passion",
                "Passion.Intensity",
                "Passion.SentimentalSentences",
                "Passion.SentimentalSubjectSentences"
            ],
            "Gender" : [
                "Gender.UniverseDistribution",
                "Gender.Universe",
                "Gender.CountDistribution",
                "Gender.Count"
            ],
            "Channels" : [
                "Channels.UniverseDistribution",
                "Channels.CountDistribution",
                "Channels.Universe",
                "Channels.Count"
            ],
            "Emotions" : [
                "Emotions.DocumentsDistribution",
                "Emotions.Appearances",
                "Emotions.Documents"
            ],
            "Themes" : [
                "Themes.DocumentsDistribution",
                "Themes.PositiveDocuments",
                "Themes.NegativeDocuments",
                "Themes.PositiveDocumentsDistribution",
                "Themes.NegativeDocumentsDistribution",
                "Themes.PositiveSentimentalDocumentsDistribution",
                "Themes.NegativeSentimentalDocumentsDistribution",
                "Themes.Documents",
                "Themes.PositiveAppearances",
                "Themes.NegativeAppearances",
                "Themes.Appearances"
            ],
            "Languages" : [
                "Languages.DocumentsDistribution",
                "Languages.Documents"
            ]
        },
    },
    emotions: {
        segmentOptions: [
            { value: "Emotions" },
        ],
        subSegmentOptions: {
            "Emotions" : [
                "Emotions.Documents",
                "Emotions.DocumentsDistribution",
                "Emotions.Appearances",
            ]
        },
    },
    themes: {
        segmentOptions: [
            { value: "Themes" },
        ],
        subSegmentOptions: {
            "Themes" : [
                "Themes.DocumentsDistribution",
                "Themes.PositiveDocuments",
                "Themes.NegativeDocuments",
                "Themes.PositiveDocumentsDistribution",
                "Themes.NegativeDocumentsDistribution",
                "Themes.SentimentalDocumentsDistribution",
                "Themes.PositiveSentimentalDocumentsDistribution",
                "Themes.NegativeSentimentalDocumentsDistribution",
                "Themes.Documents",
                "Themes.PositiveAppearances",
                "Themes.NegativeAppearances",
                "Themes.Appearances"
            ]
        },
    },
    drilldown: {
        segmentOptions: [
            { value: "Posts" },
            { value: "Sentiment"},
            { value: "Gender" }
        ],
        subSegmentOptions: {
            "Posts" : [
                "Posts.WordNormalized"
            ],
            "Sentiment" : [
                "Sentiment.SubjectSentencesDistribution"
            ],
            "Gender" : [
                "Gender.CountDistribution"
            ],
        },
    },
    defaultFieldValues: {
        "Posts.WordNormalized": {
            value: "Posts.WordNormalized",
            title: "Normalized",
            axisGroup: "Posts.WordNormalized",
            units: "Normalized Posts"
        },
        "Posts.PostsPerBillion": {
            value: "Posts.PostsPerBillion",
            title: "Per Billion",
            axisGroup: "Posts.PostsPerBillion",
            units: "Posts Per Billion"
        },
        "Posts.PostsNormalizedUniverse": {
            value: "Posts.PostsNormalizedUniverse",
            title: "Universe",
            axisGroup: "Posts.PostsNormalizedUniverse",
            units: "Posts"
        },
        "Posts.Count": {
            value: "Posts.Count",
            title: "Count",
            axisGroup: "Posts.Count",
            units: "Posts"
        },
        "Engagements.Likes": {
            value: "Engagements.Likes",
            title: "Likes",
            axisGroup: "Engagements.Likes",
            units: "Likes"
        },
        "Engagements.Comments": {
            value: "Engagements.Comments",
            title: "Comments",
            axisGroup: "Engagements.Comments",
            units: "Comments"
        },
        "Engagements.Shares": {
            value: "Engagements.Shares",
            title: "Shares",
            axisGroup: "Engagements.Shares",
            units: "Shares"
        },
        "Engagements.LikesUniverse": {
            value: "Engagements.LikesUniverse",
            title: "Likes Universe",
            axisGroup: "Engagements.LikesUniverse",
            units: "Likes"
        },
        "Engagements.CommentsUniverse": {
            value: "Engagements.CommentsUniverse",
            title: "Comments Universe",
            axisGroup: "Engagements.CommentsUniverse",
            units: "Comments"
        },
        "Engagements.SharesUniverse": {
            value: "Engagements.SharesUniverse",
            title: "Shares Universe",
            axisGroup: "Engagements.SharesUniverse",
            units: "Shares"
        },
        "Engagements.Engagements": {
            value: "Engagements.Engagements",
            title: "Engagements",
            axisGroup: "Engagements.Engagements",
            units: "Engagements"
        },
        "Engagements.EngagementsUniverse": {
            value: "Engagements.EngagementsUniverse",
            title: "Universe",
            axisGroup: "Engagements.EngagementsUniverse",
            units: "Engagements"
        },
        "Sentiment.SubjectSentencesDistribution": {
            value: "Sentiment.SubjectSentencesDistribution",
            title: "Subject Sentences Distribution",
            axisGroup: "Percentage",
            fixedMaxY: 1.0,
            units: "Distribution"
        },
        "Sentiment.SentencesDistribution": {
            value: "Sentiment.SentencesDistribution",
            title: "Sentences Distribution",
            axisGroup: "Percentage",
            fixedMaxY: 1.0,
            units: "Distribution"
        },
        "Sentiment.DocumentsDistribution": {
            value: "Sentiment.DocumentsDistribution",
            title: "Posts Distribution",
            axisGroup: "Percentage",
            fixedMaxY: 1.0,
            units: "Distribution"
        },
        "Sentiment.SubjectSentences": {
            value: "Sentiment.SubjectSentences",
            title: "Subject Sentences",
            axisGroup: "Sentiment.SubjectSentences",
            units: "Subject Sentences"
        },
        "Sentiment.Sentences": {
            value: "Sentiment.Sentences",
            title: "Sentences",
            axisGroup: "Sentiment.Sentences",
            units: "Sentences"
        },
        "Sentiment.Documents": {
            value: "Sentiment.Documents",
            title: "Posts",
            axisGroup: "Sentiment.Documents",
            units: "Posts"
        },
        "Sentiment.Universe": {
            value: "Sentiment.Universe",
            title: "Universe",
            axisGroup: "Sentiment.Universe",
            units: "Posts"
        },
        "Passion.Passion": {
            value: "Passion.Passion",
            title: "Passion",
            fixedMaxY: 100,
            axisGroup: "Passion",
            units: "score"
        },
        "Passion.Intensity": {
            value: "Passion.Intensity",
            title: "Intensity",
            fixedMaxY: 100,
            axisGroup: "Passion",
            units: "score",
            chartTitle: "Intensity"
        },
        "Passion.SentimentalSentences": {
            value: "Passion.SentimentalSentences",
            title: "Sentimental Sentences",
            axisGroup: "Passion.SentimentalSentences",
            units: "Sentences",
            chartTitle: "Sentimental Sentences Distribution"
        },
        "Passion.SentimentalSubjectSentences": {
            value: "Passion.SentimentalSubjectSentences",
            title: "Sentimental Subject Sentences",
            axisGroup: "Passion.SentimentalSubjectSentences",
            units: "Sentences",
            chartTitle: "Sentimental Subject Sentences Distribution"
        },
        "Gender.UniverseDistribution": {
            value: "Gender.UniverseDistribution",
            title: "Universe Distribution",
            fixedMaxY: 1.0,
            axisGroup: "Percentage",
            units: "Distribution"
        },
        "Gender.Universe": {
            value: "Gender.Universe",
            title: "Universe",
            axisGroup: "Gender.Universe",
            units: "Count"
        },
        "Gender.CountDistribution": {
            value: "Gender.CountDistribution",
            title: "Count Distribution",
            fixedMaxY: 1.0,
            axisGroup: "Percentage",
            units: "Distribution"
        },
        "Gender.Count": {
            value: "Gender.Count",
            title: "Count",
            axisGroup: "Gender.Count",
            units: "Count"
        },
        "Channels.UniverseDistribution": {
            value: "Channels.UniverseDistribution",
            title: "Universe Distribution",
            fixedMaxY: 1.0,
            axisGroup: "Percentage",
            units: "Distribution"
        },
        "Channels.CountDistribution": {
            value: "Channels.CountDistribution",
            title: "Count Distribution",
            fixedMaxY: 1.0,
            axisGroup: "Percentage",
            units: "Distribution"
        },
        "Channels.Universe": {
            value: "Channels.Universe",
            title: "Universe",
            axisGroup: "Channels.Universe",
            units: "Universe Posts"
        },
        "Channels.Count": {
            value: "Channels.Count",
            title: "Count",
            axisGroup: "Channels.Count",
            units: "Posts"
        },
        "Emotions.DocumentsDistribution": {
            value: "Emotions.DocumentsDistribution",
            title: "Posts Distribution",
            axisGroup: "Emotions.DocumentsDistribution",
            units: "Distribution"
        },
        "Emotions.Appearances": {
            value: "Emotions.Appearances",
            title: "Appearances",
            axisGroup: "Emotions.Appearances",
            units: "Appearances"
        },
        "Emotions.Documents": {
            value: "Emotions.Documents",
            title: "Posts",
            axisGroup: "Emotions.Documents",
            units: "Posts"
        },
        "Themes.PositiveDocuments": {
            value: "Themes.PositiveDocuments",
            title: "Positive Posts",
            axisGroup: "Themes.PositiveDocuments",
            units: "Posts"
        },
        "Themes.NegativeDocuments": {
            value: "Themes.NegativeDocuments",
            title: "Negative Posts",
            axisGroup: "Themes.NegativeDocuments",
            units: "Posts"
        },
        "Themes.PositiveDocumentsDistribution": {
            value: "Themes.PositiveDocumentsDistribution",
            title: "Positive Post Distribution",
            axisGroup: "Themes.PositiveDocumentsDistribution",
            units: "Positive Posts"
        },
        "Themes.NegativeDocumentsDistribution": {
            value: "Themes.NegativeDocumentsDistribution",
            title: "Negative Post Distribution",
            axisGroup: "Themes.NegativeDocumentsDistribution",
            units: "Negative Posts"
        },
        "Themes.PositiveSentimentalDocumentsDistribution": {
            value: "Themes.PositiveSentimentalDocumentsDistribution",
            title: "Positive Sentimental Post Distribution",
            axisGroup: "Themes.PositiveSentimentalDocumentsDistribution",
            units: "Positive Sentimental Posts"
        },
        "Themes.NegativeSentimentalDocumentsDistribution": {
            value: "Themes.NegativeSentimentalDocumentsDistribution",
            title: "Negative Sentimental Post Distribution",
            axisGroup: "Themes.NegativeSentimentalDocumentsDistribution",
            units: "Negative Sentimental Posts"
        },
        "Themes.DocumentsDistribution": {
            value: "Themes.DocumentsDistribution",
            title: "Posts Distribution",
            axisGroup: "Themes.DocumentsDistribution",
            units: "Distribution"
        },
        "Themes.Documents": {
            value: "Themes.Documents",
            title: "Posts",
            axisGroup: "Themes.Documents",
            units: "Posts"
        },
        "Themes.PositiveAppearancesDistribution": {
            value: "Themes.PositiveAppearancesDistribution",
            title: "Positive Appearances Distribution",
            axisGroup: "Themes.PositiveAppearancesDistribution",
            units: "Distribution"
        },
        "Themes.NegativeAppearancesDistribution": {
            value: "Themes.NegativeAppearancesDistribution",
            title: "Negative Appearances Distribution",
            axisGroup: "Themes.NegativeAppearancesDistribution",
            units: "Distribution"
        },
        "Themes.PositiveAppearances": {
            value: "Themes.PositiveAppearances",
            title: "Positive Appearances",
            axisGroup: "Themes.PositiveAppearances",
            units: "Positive Appearances"
        },
        "Themes.NegativeAppearances": {
            value: "Themes.NegativeAppearances",
            title: "Negative Appearances",
            axisGroup: "Themes.NegativeAppearances",
            units: "Negative Appearances"
        },
        "Themes.Appearances": {
            value: "Themes.Appearances",
            title: "Appearances",
            axisGroup: "Themes.Appearances",
            units: "Appearances"
        },
        "Languages.DocumentsDistribution": {
            value: "Languages.DocumentsDistribution",
            title: "Posts Distribution",
            axisGroup: "Languages.DocumentsDistribution",
            units: "Distribution"
        },
        "Languages.Documents": {
            value: "Languages.Documents",
            title: "Posts",
            axisGroup: "Languages.Documents",
            units: "Posts"
        }
    }
};
