import Ember from "ember";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import AtlasConfig from "infegy-frontend/config/infegy-app-config";

export default {
    queriesFetchRequest: function(queryOptions) {
        var endpoint = [AtlasConfig.baseDomain, "api/v3/user_queries.json"].join(""),
            options = Object.assign({
                url: endpoint,
                type: "GET"
            }, queryOptions);
        return endpoint && AtlasAuth.ImmediateAjax(options);
    },

    fetchFavorites: function() {
        var request = this.queriesFetchRequest({
            data: {
                "unsaved": false
            }
        });
        if (request) {
            request.catch(function(jqXHR, textStatus, errorThrown) {
                console.error("FAVORITES FAIL: ", jqXHR.status, jqXHR.responseText, jqXHR.statusText);
            });
            return request;
        }
    },

    fetchUnfavorites: function(limit) {
        var request = this.queriesFetchRequest({
                data: {
                    "unsaved": true,
                    "limit": limit || 0
                }
        });

        if (request) {
            request.catch(function(jqXHR, textStatus, errorThrown) {
                console.error("UNFAVORITES FAIL: ", jqXHR.status, jqXHR.responseText, jqXHR.statusText);
            });
            return request;
        }
    },

    fetchSnippets: function(limit) {
        var request = this.queriesFetchRequest({
                data: {
                    "snippet": true,
                    "limit": limit || 0
                }
        });

        if (request) {
            request.catch(function(jqXHR, textStatus, errorThrown) {
                console.error("SNIPPETS FAIL: ", jqXHR.status, jqXHR.responseText, jqXHR.statusText);
            });
            return request;
        }
    },

    saveQueryJSONs: function(queryJSONs) {
        if (!queryJSONs || !queryJSONs.length) {
            return false;
        }
        var requestString = JSON.stringify({
            "queries": queryJSONs
        });

        var request = AtlasAuth.Post({
            url: "api/v3/user_queries.json",
            data: requestString
        }).catch(function(jqXHR, textStatus, errorThrown) {
            console.error("SAVE FAIL: ", jqXHR.status, jqXHR.responseText, jqXHR.statusText);
        });
        return request;
    }
};
