import Ember from "ember";

export default Ember.Component.extend({
    query:[],

    allEntities: Ember.computed("query","query.updated","query.entities",function(){
        var data = this.get("query.entities.data") || [],
            total = this.get("query.entities.meta.linguisticsCount") || 0;
        return data && data.map((entity)=>{
            Ember.set(entity, "distribution", entity.documents / total);
            return entity;
        }).sortBy("distribution").reverse().splice(0,5);
    }),
});
