import Ember from "ember";
import { action, computed } from "@ember/object";
import { isEmpty, isNone } from "@ember/utils";

import BrowserStore from "infegy-frontend/utils/browser-store";
import ControllerBase from "infegy-frontend/atlas/dashboard/content/base/controller";
import Maps from "infegy-frontend/static_data/geography/maps";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";

export default class extends ControllerBase {
    apis = ["states", "countries", "dmas"];
    geographyField = "universe";
    geographyFieldOptions = [{label: "Posts", value: "matches"}, {label: "Universe", value: "universe"}];

    @computed.gt("queries.length", 1) hasMoreThanOneQuery;

    @computed("content.availableQueryFields.geography")
    get queryHasGeography() {
        return !!this.content.availableQueryFields.geography;
    }

    @BrowserStore.Attr("geography-activepage", { defaultValue: "countries" }) activeMapName;

    @computed("activeMapName")
    get activeMap() {
        var map = Maps.findBy("value",this.activeMapName);
        if(!map.svgData){
            Ember.set(map, "isLoading", true);
            AtlasAuth.Get({ url: `/assets/maps/${map.value}.json`, allowRelativeURL: true }).then((res) => {
                Ember.setProperties(map, {
                    "isLoading":false,
                    "svgData": res.svgData,
                    "projectionData": res.projection
                });
            }).catch(() => {
                Ember.set(map, "isLoading", false);
                console.error("Map does not exist");
            });
        }
        return map;
    }

    @computed.alias("activeMap.api") activeApi;

    get mapOptions() {
        const results = Maps.filter((option) => {
            const hidden = this.content.availableQueryFields.hasOwnProperty(option.id)
                && this.content.availableQueryFields[option.id] === false;
            return !hidden;
        });
        return results;
    }

    @BrowserStore.Attr("geography-viewtype", { defaultValue: "map" }) viewType;
    @BrowserStore.Attr("geography-activemetric", { defaultValue: "max" }) activeMetric;

    @computed("activeField")
    get metricOptions() {
        if (this.activeField == "positivity") {
            return [
                { title: "Highest", value: "max" },
                { title: "Lowest", value: "min" },
                { title: "Average", value: "avg" }
            ];
        }
        else {
            return [
                { title: "Highest", value: "max" },
                { title: "Lowest", value: "min" },
                { title: "Total", value: "sum" }
            ];
        }
    }

    @computed("combinedData", "activeMap.isLoading")
    get noData() {
        const combinedData = this.combinedData;
        const isLoading = this.get("activeMap.isLoading");

        if (!combinedData || isLoading) {
            return false;
        }

        return !combinedData.filter((area) => area.matches && area.matches.sum > 0).length;
    }

    @BrowserStore.Attr("geography-activefield", { defaultValue: "score" }) activeField;

    @computed("queries.length", "activeMapName", "viewType")
    get fieldOptions() {
        var activeMap = this.activeMapName,
            viewType = this.viewType,
            options = [{ title: "Posts", value: "matches" },
                       { title: "Sources", value: "sources" },
                       { title: "Sentiment", value: "positivity"},
                       { title: "Universe", value: "universe"}];

            if (this.get("queries.length") < 2) {
                if (viewType !== "map") {
                    options = options.concat([
                        { title: "Timeline", value: "timeline"},
                        { title: "Change", value: "documentsGrowthPct"}]);
                }

                if (activeMap !== "dma") {
                    options.push({ title: "Score", value: "score" });

                    if (activeMap !== "caProvinces") {
                        options.push({ title: "Population", value: "population" });
                    }

                    options = options.concat([
                        { title: "Atlas Sources", value: "totalSources" },
                        { title: "Broadband Users", value: "broadbandUsers" }]);
                }
            }

        return options;
    }

    @computed("activeMap.api")
    get apiDisplayName() {
        // Convert states, countries, dmas to state, country, DMA
        var api = (this.get("activeMap.api") || "")
        return api.replace(/ies$/, "y").replace(/s$/, "").replace("dma", "DMA");
    }

    @computed("queries.[]", "queries.@each,updated", "activeMap.api")
    get combinedPostsTotal() {
        var queries = this.queries,
            api = this.get("activeMap.api");

        return api && queries.reduce((total, query) => {
            return total + (query.get(`${api}.dataSeries.stats.matches.sum`) || 0);
        }, 0);
    }

    @computed("queries", "queries.length", "activeMapName")
    get currentApis() {
        let result = [];
        this.queries.forEach((query) => {
            result.push(query[this.activeApi]);
        });
        return result;
    }

    @computed("currentApis", "currentApis.@each.isLoading", "currentApis.@each.dataSeries")
    get isLoadingApis() {
        const found = this.currentApis.find((api) => {
            return api.isLoading;
        })
        return !isNone(found);
    }

    @computed("queries.[]", "activeMap.svgData.[]", "isLoadingApis", "viewType")
    get combinedData() {
        var queries = this.queries,
            activeMap = this.activeMap,
            api = this.get("activeMap.api"),
            fieldOptions = this.fieldOptions,
            layoutData = this.get("activeMap.svgData");

        if(!activeMap || activeMap.isLoading || !activeMap.svgData || this.isLoadingApis){
            return [];
        }

        var allAreas = {};
        queries.forEach((query) => {
            var areas = query.get(`${api}.dataSeries.data`);

            layoutData.forEach((layoutArea) => {
                var areaKey = Ember.get(layoutArea, "key"),
                    original_area = areas && areas.findBy("key", areaKey),
                    activeFixture = activeMap.fixtureByKey(areaKey);

                var area = Object.assign({
                    _originalQuery: query,
                    name: activeFixture && activeFixture.name
                }, original_area || {}, layoutArea);
                if(!allAreas[areaKey]){
                    allAreas[areaKey] = Object.assign({
                        _sourceGeos: [area]
                    },layoutArea);
                } else {
                    allAreas[areaKey]._sourceGeos.push(area);
                }
                var statFields = ["matches","sources","score","positivity","universe","timeline","documentsGrowthPct"];

                allAreas[areaKey].id = area.id;

                fieldOptions.forEach((fieldOption) => {

                    let field = fieldOption.value;
                    if(!statFields.includes(field)){
                        return;
                    }
                    if (!isNone(allAreas[areaKey][field])) {
                        if (original_area) {
                            if (field === "positivity" && (original_area.positiveMatches + original_area.negativeMatches < 3)) {
                                // not a large enough sample size to be considered
                                return
                            }
                            allAreas[areaKey][field].sum += area[field];
                            allAreas[areaKey][field].count += 1;
                            var currentMax = -Infinity,
                                currentMin = Infinity;

                            if (!isEmpty(allAreas[areaKey][field]["min"]) && !isEmpty(allAreas[areaKey][field]["max"])){
                                currentMin = allAreas[areaKey][field]["min"][0][field];
                                currentMax = allAreas[areaKey][field]["max"][0][field];
                            }

                            if (currentMin > area[field]) {
                                allAreas[areaKey][field].min = [area];
                            } else if (currentMin === area[field]) {
                                allAreas[areaKey][field]["min"].push(area);
                            }

                            if (currentMax < area[field]) {
                                allAreas[areaKey][field].max = [area];
                            } else if (currentMax === area[field]) {
                                allAreas[areaKey][field]["max"].push(area);
                            }
                        }
                    } else {
                        allAreas[areaKey] = Object.assign({
                            population: area.population,
                            broadbandUsers: area.broadbandUsers,
                            totalSources: area.totalSources,
                            name: area.name
                        },allAreas[areaKey]);

                        if(!isNone(area[field])){
                            allAreas[areaKey][field] = {
                                sum: area[field],
                                min: [area],
                                max: [area],
                                count: 1,
                                avg: area[field]
                            }
                        } else {
                            allAreas[areaKey][field] = {
                                sum: 0,
                                min: [],
                                max: [],
                                count: 0,
                                avg: 0
                            }
                        }
                    }
                });
                fieldOptions.forEach((fieldOption) => {
                    // sum averages
                    let field = fieldOption.value;
                    if(!statFields.includes(field)){
                        return;
                    }
                    allAreas[areaKey][field].avg = allAreas[areaKey][field].sum / (allAreas[areaKey][field].count || 1);
                });
            });
        });
        return Object.values(allAreas);
    }

    @action
    activeMapChanged(newPage) {
        this.set("activeMapName", newPage);
    }

    @action
    viewTypeChanged(view) {
        this.set("viewType", view);
    }

    @action
    activeFieldChanged(field) {
        this.set("activeField", field);
    }

    @action
    activeMetricChanged(value) {
        this.set("activeMetric", value);
    }

    @action
    geographyFieldChanged(geographyField) {
        this.set("geographyField", geographyField);
    }
}
