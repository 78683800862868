import Ember from "ember";
import Actions from "infegy-frontend/utils/common-actions";

export default Ember.Component.extend({
    queries: null,
    emotionData: null,

    selectedEmotion: null,
    availableEmotions: [],

    viewField: "appearances",

    sortedEmotionData: Ember.computed("emotionData", function () {
        var emotionData = this.emotionData || [];
        return emotionData.sortBy("combined.Documents").reverse();
    }),

    arcsClass: Ember.computed("sortedEmotionData", "sortedEmotionData.[]", "sortedEmotionData.length", function() {
        var sortedEmotionDataLength = this.get("sortedEmotionData.length");

        return `even-columns-${Math.ceil(sortedEmotionDataLength / 2)}`;
    }),

    subEmotionQuery: Ember.computed("queries", "selectedEmotion", function() {
        const query = this.get("queries.firstObject");
        if (query) {
            const selectedEmotion = this.selectedEmotion;
            let subEmotionQuery = query.copy();
            let queryInfo = subEmotionQuery.get("queryInfo");
            queryInfo.addFilter({
                id: "emotions",
                values: [selectedEmotion.emotion]
            });
            return subEmotionQuery;
        }
        return null;
    }),

    actions: {
        emotionSelected: Actions.passUp("emotionSelected"),
    }
});
