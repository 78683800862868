import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import Ember from "ember";
export default Ember.Component.extend(Ember.TargetActionSupport, {
    target: Ember.computed.alias('controller'),
    classNames: ["support-request-wrapper"],

    user: null,

    isSent: false,
    isSending: false,

    whatHappened: "",
    currentPath: "",

    displaySupportRequest: () => {},
    actions: {
        sendSupportRequest: function () {
            this.set("isSending", true);

            AtlasAuth.Post({
                data: {
                    "current_url": window.location.href,
                    "ember_path": this.currentPath,
                    "what_happened": this.whatHappened,
                    "reply_to": this.get("user.email"),
                    "name": this.get("user.name")
                },
                url: "api/v3/support_request.json"
            })
                .then(() => {
                    this.set("isSent", true);
                    this.set("isSending", false);

                    setTimeout(() => {
                        this.displaySupportRequest();
                    }, 5000);
                });
        },
        displaySupportRequest: function() {
            this.displaySupportRequest();
        }
    }
});
