import Ember from "ember";
import loader from "infegy-frontend/models/field-definitions/loader";

export default {
    Field: function (options) {
        var _options = Object.assign({
            defaultField: null,
        }, options);
        return Ember.computed({
            get: function(key) {
                let data = this._data,
                    object = data[key],
                    field = object;

                if (!field) {
                    if (_options.defaultField) {
                        field = loader.loadField(_options.defaultField);
                        field.set("parentObject", this);
                        data[key] = field;
                    }
                    return field;
                }

                if (Ember.typeOf(object) !== "instance") {
                    let json = {};
                    if (Ember.typeOf(object) === "object") {
                        json = object;
                    } else if (_options.defaultField) {
                        json = _options.defaultField;
                    }
                    field = loader.loadField(json);
                    field.set("parentObject", this);
                    data[key] = field;
                }
                return field;
            },
            set: function (key, value) {
                var data = this._data,
                    field = data[key];

                if (!value) {
                    data[key] = null;
                    return null;
                }

                if (Ember.typeOf(value) === "instance") {
                    if (value !== field) {
                        data[key] = value;
                        value.set("parentObject", this);
                        this.set("isDirty", true);
                    }
                    field = value;
                } else {
                    let json = {};
                    if (value) {
                        json = value;
                    } else {
                        json = _options.defaultField || {};
                    }

                    field = loader.loadField(json);
                    field.set("parentObject", this);
                    this.set("isDirty", true);
                    data[key] = field;
                }
                return field;
            },
        });
    }
};
