export default function virtualProperty(innerPropertyName) {
    return function(object, propertyName, descriptor){
        let innerName = innerPropertyName || `__${propertyName}`;
        return {
            get() {
                return this[innerName];
            },
            set(value) {
                if (this.set) {
                    this.set(innerName, value);
                } else {
                    this[innerName] = value;
                }
                this.notifyPropertyChange && this.notifyPropertyChange(propertyName);
            }
        }
    };
};
