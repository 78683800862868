import Component from "@ember/component";
import { action, computed } from "@ember/object";
import { isEmpty } from "@ember/utils";
import classic from "ember-classic-decorator";

@classic
export default class extends Component {
    classNameBindings = ["hoverStyle"];
    
    queryFormQueries = [];
    queryGroup = [];

    isHovered = false;
    hoveredQuery = null;

    didInsertElement() {
        super.didInsertElement(...arguments);
        // https://deprecations.emberjs.com/v3.x/#toc_component-mouseenter-leave-move
        this.element.addEventListener("mouseenter", this.onMouseEnter.bind(this));
        this.element.addEventListener("mouseleave", this.onMouseLeave.bind(this));
    }

    @computed("queryGroup.length")
    get isSingleQuery() {
        return this.queryGroup.length === 1;
    }

    @computed("isHovered", "hoveredQuery")
    get hoverStyle() {
        if (this.isHovered === true && isEmpty(this.hoveredQuery)) {
            return "recent-queries-list-query-group-hovered";
        }
        return "recent-queries-list-query-group";
    }

    @computed("queryGroup", "queryGroup.length")
    get lastUsedOn() {
        const value = this.get("queryGroup.firstObject.lastUsedOn") || 1;
        return value;
    }

    onMouseEnter(event) {
        this.set("isHovered", true);
    }

    onMouseLeave(event) {
        this.set("isHovered", false);
    }
}