import Ember from "ember";
import {computed, action} from "@ember/object";
import CSSTools from "infegy-frontend/utils/css-tools";
import SVGInnerWrapper from "infegy-frontend/components/charting/svg/svg-inner-wrapper/component";
import d3 from "d3";

export default class extends SVGInnerWrapper {
    classNameBindings = ["isFaded:trend-chart-field-faded",
        "isHighlighted:dark", "isHighlighted:trend-chart-field-highlighted"];
    attributeBindings = ["style"];

    trendField = null;
    trendChartMeta = null;
    showEventIcons = true;

    trendFields = null;
    xRange = null;

    isActive = true;

    displayAsArea = false;

    displayedEvents = [];

    hideDots = false;

    isCanvasChart = false;

    @computed.alias("trendField.field") field;

    @computed("trendChartMeta.hoveredField", "trendChartMeta.selectedField", "field")
    get isHighlighted() {
        return (this.trendChartMeta?.hoveredField === this.trendField) ||
            (this.trendChartMeta?.selectedField === this.trendField);
    }

    @computed("trendChartMeta.hoveredField", "trendChartMeta.selectedField", "isHighlighted")
    get isFaded() {
        return (this.trendChartMeta?.hoveredField || this.trendChartMeta?.selectedField) && !this.isHighlighted;
    }

    @computed("field.colorValues", "isHighlighted")
    get style() {
        var strokeColor = this.get("field.color.colorString");
        if(this.isHighlighted){
            strokeColor = this.get("field.color.darkColorString");
        }
        strokeColor = strokeColor || "#00f";
        return CSSTools.buildStringFromObjectSafe({
           stroke: strokeColor,
           fill: strokeColor
        });
    }

    @computed.oneWay("field.stats") yStats;
    @computed.oneWay("field.xFieldStats") xStats;

    @computed("chartType")
    get dotPadding() {
        if (this.chartType === "bars") {
            return 0;
        }
        let padding = this.isCanvasChart ? 5 : 0;
        return padding;
    }

    @computed("xRange", "width")
    get xScale() {
        let start = this.xRange?.start || 0,
            end = this.xRange?.end || 1;
        return d3.scaleLinear()
            .domain([start, end])
            .range([this.dotPadding, this.width - this.dotPadding || 1]);
    }

    @computed("field", "field.maxY", "height")
    get yScale() {
        return d3.scaleLinear()
            .domain([0, this.field?.chartMaxY || 10])
            .range([this.height - this.dotPadding || 1, this.dotPadding]);
    }

    @computed("trendChartMeta.isHovered", "trendChartMeta.isSelected",
            "trendChartMeta.hoveredXPct", "trendChartMeta.hoveredYPct",
            "trendFields.@each.selectedInfo")
    get highlightedDot() {
        if (this.trendChartMeta?.isHovered) {
            return {
                x: this.trendChartMeta?.hoveredXPctWhole,
                y: this.trendChartMeta?.hoveredYPctWholeInv
            };
        }
    }

    @computed("xScale", "yScale", "filteredEventPoints", "filteredEventPoints.length")
    get displayedEventsPoints() {
        if (!this.field) {
            return;
        }

        const xField = this.field?.xAxisField;
        const yField = this.field?.fieldName;

        return this.filteredEventPoints.map((item) => {
            const test = this.field?.data?.find((item2) => {
                return item2[xField] == item[xField];
            });

            if (Ember.isNone(test)) {
                return;
            }

            return {
                x: this.xScale(Ember.get(item, xField) || 0),
                y: this.yScale(Ember.get(test, yField) || 0)
            }
        });
    }

    @computed("displayedEvents", "displayedEvents.length", "trendChartMeta.isBrushed",
        "trendChartMeta.brushStartXIdx", "trendChartMeta.brushEndXIdx")
    get filteredEventPoints() {
        if (this.trendChartMeta?.isBrushed) {
            const xField = this.field?.xAxisField;
            const valueMin = this.trendChartMeta?.brushStartXValue;
            const valueMax = this.trendChartMeta?.brushEndXValue;
            let filteredEvents = this.displayedEvents?.filter((event) => {
                const value = event[xField];
                return valueMin <= value && value <= valueMax;
            });

            return filteredEvents;
        }
        else {
            return this.displayedEvents;
        }
    }

    @computed("trendField", "trendField.chartData", "xScale", "yScale")
    get dataPoints() {
        let data = this.trendField?.chartData || [],
            xScale = this.xScale,
            yScale = this.yScale;

        if (!data || !xScale || !yScale) {
            return [];
        }

        let dataPoints = data.map(function(row) {
            return {
                x: xScale(row.x),
                y: yScale(row.y)
            };
        });
        return dataPoints;
    }

    @computed("dataPoints")
    get activePath() {
        var path = d3.line()
                .x(function(d) { return d.x || 0; })
                .y(function(d) { return d.y || 0; })
                .curve(d3.curveMonotoneX);
        return path(this.dataPoints || []);
    }

    @computed("trendField.brushedTrendField", "trendChartMeta.isBrushed", "xScale", "yScale")
    get brushedDataPoints() {
        let data = this.trendField?.brushedTrendField?.chartData,
            xScale = this.xScale,
            yScale = this.yScale;

        if (!this.trendChartMeta.isBrushed || !data || !xScale || !yScale) {
            return [];
        }

        let dataPoints = data.map(function(row) {
            return {
                x: xScale(row.x),
                y: yScale(row.y)
            };
        });
        return dataPoints;
    }

    @computed("width", "height", "trendChartMeta.brushStartXPct", "trendChartMeta.brushEndXPct")
    get brushMask() {
        var width = Math.abs(this.width),
            height = Math.abs(this.height),
            startPct = this.trendChartMeta?.brushStartXPct || 0,
            endPct = this.trendChartMeta?.brushEndXPct || 0,
            startX = startPct * width,
            endX = (endPct * width) - startX;
        endX = endX > 0 ? endX : 1;
        return {x: startX, width: endX, y: -10, height: height + 10};
    }
}
