import Ember from "ember";
import CM from "infegy-frontend/utils/codemirror_query"; // jshint ignore:line

export default Ember.Component.extend({
    mode: "",


    _innerValue: null,
    value: Ember.computed("_innerValue", {
        get: function(key) {
            return this._innerValue;
        },
        set: function(key, value) {
            this.set("_innerValue", value);
            Ember.run.schedule('afterRender',() => this.updateInstance() );
            return value;
        }
    }),

    delayInstanceTime: 5,

    codeMirrorOptions: {},
    codeMirrorDefaultOptions: {
        theme: "atlas",
        autoCloseBrackets: '()""'
    },

    codeMirrorInstance: null,
    emptyCodeMirrorInstance: Ember.computed.empty("codeMirrorInstance"),
    textArea: null,

    createInstance: function() {
        if (this.element) {
            return;
        }
        var options = Object.assign({},
            this.codeMirrorDefaultOptions || {},
            this.codeMirrorOptions || {}, {
                value: this.value || ""
            }
        );

        if (this.mode) {
            options.mode = this.mode;
        }


        var inputTextAreas = this.element.querySelectorAll(".codemirror-input"),
            textArea = inputTextAreas && inputTextAreas[0];

        if (!textArea) {
            return;
        }

        var codeMirrorInstance = CodeMirror.fromTextArea(this.element.querySelector(".codemirror-input"), options),
            that = this;

        this.set("codeMirrorInstance", codeMirrorInstance);
        codeMirrorInstance.on("change", function(inst, chng) {
            that.set("_innerValue", inst.getValue());
        });
        this.updateInstance();
        this.setFocus();
    },

    runCreateInstance: Ember.on("init",function() {
        Ember.run.schedule('afterRender', this, function() {
            var delayTime = this.delayInstanceTime;
            if (delayTime) {
                Ember.run.later(this, "createInstance", delayTime);
            } else {
                Ember.run.once(this.createInstance());
            }
        });
    }),

    updateInstance: function() {
        var cm = this.codeMirrorInstance,
            value = this.value || "";
        if (cm && cm.getValue() !== value) {
            cm.setValue(value);
        }
    },
    click: function() {
        this.setFocus();
    },

    setFocus: function() {
        var that = this;
        setTimeout(function() {
            var cm = that.get("codeMirrorInstance");
            if (cm) {
                cm.focus();
            }
        }, 100);
    }
});
