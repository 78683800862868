import Ember from "ember";
import { computed } from '@ember/object';
import { oneWay, alias, readOnly } from '@ember/object/computed';
import JsonStore from 'infegy-frontend/json-store/model';
import ArrayBase from "infegy-frontend/models/array_compat";
import QueryAPIs from "infegy-frontend/models/queries/query_apis";
import QueryStrings from "infegy-frontend/models/queries/query_strings";
import QueryFilters from "infegy-frontend/models/queries/query_filters";
import QueryUtil from "infegy-frontend/models/queries/query_util";
import QueryAPIUtils from "infegy-frontend/models/queries/query_api_utils";
import QueryApiRequestString from "infegy-frontend/models/queries/query_api_request_string";
import AtlasConfig from "infegy-frontend/config/infegy-app-config";
// import QueryAPILinguisticsPostsController from "infegy-frontend/models/query-api/linguistics_posts_controller";
import classic from "ember-classic-decorator";

@classic
export default class Query extends JsonStore {
    constructor(){
        console.warn("The deprecated version of `Query` is being used.");
        super(...arguments);
        this.addQueryFilters();
        QueryUtil.addApiControllers(this);
    }

    id =  0;
    @alias("id") index;

    updated =  0;

    errors =  null;
    @computed.notEmpty("errors") hasErrors;

    /** @type {QueryFilters} */
    queryInfo =  null;
    @alias('queryInfo') queryFilters;
    @alias('queryInfo') filters;

    @alias('queryInfo.title') title;
    @alias('queryInfo.queryString') queryString;
    @alias('queryInfo.entityQuery') entityQuery;
    @alias("queryInfo.isBlank") isBlank;
    @computed.not("isBlank") isNotBlank;
    @alias("queryInfo.isUsingQueryBuilder") isUsingQueryBuilder;

    @alias("queryInfo.color") color;
    @oneWay("queryInfo.color") colors;
    @oneWay("colors.base") colorValue;
    @oneWay("colors.class") colorClass;
    @alias("filters.savedQueryJSON") savedQueryJSON;
    @oneWay("queryInfo.activeGrouping") activeGrouping;

    @readOnly("queryInfo.earliestTimestamp") earliestTimestamp;
    @readOnly("queryInfo.latestTimestamp") latestTimestamp;
    @readOnly("queryInfo.sourceQueryString") sourceQuery;

    copy() {
        var newQuery = Query.create();
        newQuery.set("queryInfo", this.queryInfo.copy());
        return newQuery;
    }

    addError(errorObj) {
        var errors = this.errors;
        if (!errors) {
            errors = ArrayBase.create();
            this.set("errors", errors);
        }

        if (errorObj) {
            errors.pushObject(errorObj);
        }
    }

    clearErrors(code_or_codes) {
        var errors = this.errors;
        if (typeof(code_or_codes) === "string") {
            code_or_codes = [code_or_codes];
        }

        if (errors && code_or_codes) {
            errors = errors.filter(function(errorInfo) {
                return !code_or_codes.includes(errorInfo.get("code"));
            });
            this.set("errors", (errors.length) ? errors : null );
        } else {
            this.set("errors", null);
        }
    }

    clearAPIResults() {
        QueryAPIs.forEach(function(apiInfo) {
            this.set("_" + apiInfo.name, null);
        }, this);
        this.clearErrors();
        this.incrementProperty("updated");
    }

    clearErroredAPIResults(code_or_codes) {
        var errors = this.errors;
        if (!errors || !errors.length) {
            return;
        }
        if (typeof(code_or_codes) === "string") {
            code_or_codes = [code_or_codes];
        }

        errors.forEach(function(errorInfo) {
            if (!code_or_codes || code_or_codes.includes(errorInfo.get("code"))) {
                this.set("_" + errorInfo.get("apiName"), null);
            }
        }, this);
        this.clearErrors(code_or_codes);
    }

    toAPIQueryString(queryInfo, additionalAPIParameters, apiName) {
        queryInfo = queryInfo || this.queryInfo;
        additionalAPIParameters = Object.assign({},
            this.get("filters.additionalAPIParameters") || {},
            additionalAPIParameters || {});
        var apiString = QueryApiRequestString.build(this, queryInfo, additionalAPIParameters, apiName);
        return apiString;
    }

    toAPIRequestURL(apiName, options) {
        options = Object.assign({
            format: 'json',
            queryInfo: null,
            additionalAPIParameters: {}
        }, options);

        var apiPath = QueryAPIUtils.pathByName(apiName),
            baseURL = [AtlasConfig.baseAPIDomain, apiPath, '.', options.format, '?'].join(""),
            apiString = this.toAPIQueryString(options.queryInfo, options.additionalAPIParameters, apiName);
        return [baseURL, apiString].join("");
    }

    checkIfOutdated(queryInfo, additionalAPIParameters) {
        var requestString = this.toAPIQueryString(queryInfo, additionalAPIParameters);
        var lastRequestString = this.lastRequestString;
        return (requestString !== lastRequestString);
    }

    cleanQueryString() {
        var queryString = QueryStrings.clean(this.queryString || "");
        this.set("queryString", queryString);
        return queryString;
    }

    validateQueryString() {
        var queryString = this.cleanQueryString();
        return QueryStrings.validate(queryString);
    }

    /**
     * Prepares the query as a drill-down query.  For example: a user searches
     * for "Tesla" and then wants to drill-down into the "Model 3" topic for
     * more specific information.  In this case, the "Tesla" queryString will
     * be moved to the queryWithin and "Model 3" will become the new queryString.
     *
     * @param {string} newQueryString The drill-down query string.
     */
    prepareAsDrilldownQuery(newQueryString) {
        let queryString = this.queryString || "";
        let queryWithin = this.get("queryInfo.queryWithin");

        const cleanedString = queryString.split("(").join("");
        const isOperatorQuery = cleanedString.substr(0, 1) === "-"
            || cleanedString.substr(0, 3) === "NOT";

        if (!isOperatorQuery) {
            if (!Ember.isEmpty(queryString)) {
                // Move the queryString to the queryWithin
                if (!Ember.isEmpty(queryWithin)) {
                    queryWithin = `(${queryString}) AND ${queryWithin}`;
                }
                else {
                    queryWithin = queryString;
                }
                this.set("queryInfo.queryWithin", queryWithin);
            }
            queryString = newQueryString;
        }
        else {
            // eslint-disable-next-line no-lonely-if
            if (!Ember.isEmpty(queryString)) {
                queryString = [`(${newQueryString}) AND ${queryString}`];
            }
            else {
                queryString = newQueryString;
            }
        }

        this.set("queryString", queryString);
    }

    addStringToQuery(queryString, optionalLabel) {
        optionalLabel = optionalLabel || queryString;
        if (this.isUsingQueryBuilder) {
            this.filters.queryBuilderDetail.andItems.loadJSON({
                iconType: "text",
                label: optionalLabel,
                type: "text",
                value: queryString
            });
        } else {
            let newQueryString = this.queryString;
            if (!newQueryString) {
                newQueryString = queryString;
            } else {
                newQueryString = `(${newQueryString}) AND ${queryString}`;
            }
            this.set("queryString", newQueryString);
        }
    }

    removeStringFromQuery(queryString, optionalLabel) {
        optionalLabel = optionalLabel || queryString;
        if (this.isUsingQueryBuilder) {
            this.filters.queryBuilderDetail.notItems.loadJSON({
                iconType: "text",
                label: optionalLabel,
                type: "text",
                value: queryString
            });
        } else {
            let newQueryString = this.queryString;
            if (!newQueryString) {
                newQueryString = queryString;
            } else {
                newQueryString = `(${newQueryString}) NOT ${queryString}`;
            }
            this.set("queryString", newQueryString);
        }
    }

    @computed("queryInfo.lookupId")
    get lookupId() {
        return this.queryInfo.lookupId;
    }
    set lookupId(value) {
        this.queryInfo.set("lookupId", value);
    }

    createFromFilters(queryInfo) {
        var newQuery = Query.create();
        if (queryInfo) {
            newQuery.set("queryInfo", queryInfo.copy());
        }
        return newQuery;
    }
}
