import Ember from "ember";
import DataSeriesFieldIndex from "infegy-frontend/models/data_series/data_series_field_index";

export default Ember.Object.extend({
    _dataSeries: false,

    unknownProperty: function(field) {
        var dataSeries = this._dataSeries;
        if (!dataSeries) {
            return;
        }

        var newIndex = DataSeriesFieldIndex.buildFrom(dataSeries.get("data"), field);
        if (newIndex) {
            this.field = newIndex;
            return newIndex;
        }
    }
});
