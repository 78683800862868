import Ember from "ember";
import Component from "@ember/component";
import { action, computed } from "@ember/object";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";

import SharedResources from "infegy-frontend/models/users/shared_resources";

export default class CustomDatasetListItem extends Component {
    classNameBindings = [":custom-dataset-list-item", "editingItem:is-editing", "showStatus:custom-dataset-list-item-processing"];
    dataset = null;
    editingItem = false;
    isExpanded = false;
    user = null;
    showDeleteConfirmation = false;
    sharedResources = SharedResources.create();

    showSharingDetails = false;

    isExportingDataset = false;
    exportOption = {name: 'CSV', value: 'csv'};

    @computed.equal("dataset.type", "qualtrics_import") isQualtricsImport;

    @computed("dataset.lastIndexDate",)
    get hasNeverPushed() {
        return this.dataset.lastIndexDate.toLowerCase() === "never";
    }

    @computed("dataset")
    get isShared() {
        if (Ember.isNone(this.dataset)) return true;
        return !this.dataset.writeAccess;
    }

    companyUsers = [];

    @computed("dataset.dataFullySearchable")
    get showStatus() {
        return !this.get("dataset.dataFullySearchable");
    }

    @computed("dataset.createdDate", "user")
    get canDeleteDataset() {
        const user = this.user,
              dataset = this.dataset,
              now = new Date(),
              date = new Date(this.get("dataset.createdDate")),
              deletionDelay = this.get("user.permissions.customDatasetDeletionDelay") * 1000;

            if (Ember.isEmpty(user)) {
                return false;
            }
            if (user.isAdmin) {
                return true;
            }
            if (!dataset.writeAccess) {
                return false;
            }

        return (now - date) > deletionDelay;
    }

    @computed('dataset')
    get exportOptions() {
        let datasetRecords = this.dataset.postsIndexed,
            excelMax = 1048576,
            exportOptions = [
                {title: "Excel", value: "xlsx"},
                {title: "CSV", value: "csv"},
                {title: "JSON", value: "json"},
            ];

        return (datasetRecords <= excelMax) ? exportOptions : exportOptions.filter(item => item.value !== 'xlsx');
    }

    @computed('dataset', 'user', 'filename', 'exportOption')
    get href() {
        let datasetID = this.dataset.id,
            apiKey = this.user.apiKey,
            filename = this.filename,
            requestURL = `${AtlasAuth.baseUri}api/v3/custom-dataset/export.${this.exportOption}`;

        return `${requestURL}?id=${datasetID}&api_key=${apiKey}&filename=${filename}`;
    }

    @computed('dataset')
    get filename() {
        return this.dataset.title.replaceAll(' ', '_');
    }

    saveEditingItem() {};
    cancelEditingItem() {};
    appendToDataset() {};
    deleteCustomDataset(dataset) {};
    useCustomDataset(dataset){};
    sharingDeclined(dataset) {};

    @action
    onToggleEditingItem(){
        this.toggleEditingItem(this.dataset);
    }

    @action
    onSaveEditingItem() {
        this.saveEditingItem();
    }

    @action
    onCancelEditingItem() {
        this.cancelEditingItem();
    }

    @action
    onToggleShowDeleteConfirmation(){
        this.toggleProperty("showDeleteConfirmation");
    }

    @action
    onAppendToDataset(){
        this.appendToDataset(this.dataset);
    }

    @action
    onDeleteCustomDataset(){
        this.deleteCustomDataset(this.dataset);
    }

    @action
    onExpand() {
        this.toggleProperty("isExpanded");

        // share-options is only shown for users that own the custom-dataset
        if (this.isShared) return;

        if (Ember.isEmpty(this.sharedResources)) {
            this.sharedResources.getForResource("custom-dataset", this.dataset.id);
        }
    }

    @action
    onUseCustomDataset() {
        const dataset = this.dataset;
        this.useCustomDataset(dataset);
    }

    @action
    onToggleShareDetails() {
        if (Ember.isEmpty(this.sharedResources)) {
            this.sharedResources.getForResource("custom-dataset", this.dataset.id);
        }

        this.toggleProperty("showSharingDetails");
    }

    @action
    onSharingDeclined() {
        this.sharingDeclined(this.dataset);
    }

    @action
    onDatasetExport() {
        this.toggleProperty('isExportingDataset');
    }

    @action
    onExportOptionSelect(option) {
        this.set('exportOption', option);
    }
}
