import Component from "@ember/component";
import { action, computed, set } from "@ember/object";
import classic from "ember-classic-decorator";

@classic
export default class extends Component {
    // Input
    column = null;
    trait = null;

    classNames = ["custom-datasets-trait"];

    @action onWidgetSelection(trait, value) {
        set(trait, "value", value);
    }

    @action onToggleHelp() {
        this.toggleProperty("showHelp");
    }
}
