import Ember from 'ember';

export default {
    cleanObject: function(obj, recursive) {
        if (obj === null || obj === undefined || typeof obj !== "object") {
            return obj;
        }
        var cleanObj = {};
        for (var name in obj) {
            if (obj.hasOwnProperty(name) && !Ember.isEmpty(obj[name])) {
                var val = obj[name];
                if (typeof val === "object" && recursive) {
                    val = this.cleanObject(val, recursive);
                }
                if (!Ember.isEmpty(val) && !Ember.isArray(obj)) {
                    cleanObj[name] = val;
                }
            }
        }
        return cleanObj;
    },

    camelizeObject: function(obj, recursive) {
        if (obj === null || obj === undefined || typeof obj !== "object") {
            return obj;
        }
        var camelObj = {};
        for (var name in obj) {
            if (obj.hasOwnProperty(name)) {
                var val = obj[name];
                if (typeof name === "string") {
                    name = name.camelize();
                }
                if (val !== null && val !== undefined &&
                    typeof val === "object" && recursive) {
                    val = this.camelizeObject(val, recursive);
                }

                camelObj[name] = val;
            }
        }
        return camelObj;
    },
    anyAreEmpty: function(args) {
        var isEmpty = false;
        for (var i = 0; i < arguments.length; i++) {
            if (Ember.isEmpty(arguments[i])) {
                isEmpty = true;
                break;
            }
        }
        return isEmpty;
    },

    // TODO: doesn't apply to objects, move somewhere appropriate for strings.
    camelCaseToTitleCase: function(camelCase) {
        if (camelCase === null || camelCase === "") {
            return camelCase;
        }

        camelCase = camelCase.trim();
        var newText = "";
        for (var i = 0; i < camelCase.length; i++) {
            if (/[A-Z]/.test(camelCase[i]) && i !== 0 && /[a-z]/.test(camelCase[i - 1])) {
                newText += " ";
            }
            if (i === 0 && /[a-z]/.test(camelCase[i])) {
                newText += camelCase[i].toUpperCase();
            } else {
                newText += camelCase[i];
            }
        }

        return newText;
    }
};
