import Ember from "ember";
import DataSeriesComputedFieldBase from "infegy-frontend/models/data_series/data_series_computed_fields";
import NumberStats from "infegy-frontend/models/data_series/number_stats";

// Creates a computed percentage field based on two source fields:
//      A "totalField" which represents a summed total
//      A "portionField" which represents a portion of that summed total
//
// The output is calculated by "portionField" / "totalField"
//
// If isInverted is true, the portion is calculated by (total - portion)
// The output is then calculated by ("totalField" - "portionField") / "totalField"
//
// Output is groupable using weighted averages. (Recomputed from grouped data).
var DataSeriesComputedFieldPercentage = DataSeriesComputedFieldBase.extend({
    destinationField: "",
    totalFields: [],
    portionFields: [],
    isInverted: false,
    type: "distribution",

    addFieldsTo: function(fields) {
        var destinationField = this.destinationField,
        type = this.type;

        if (destinationField && type) {
            fields[destinationField] = type;
        }
        return fields;
    },
    rowCompute: function(row) {
        var destinationField = this.destinationField,
            totalFields = this.totalFields,
            portionFields = this.portionFields,
            isInverted = this.isInverted,
            total, portion;

        if (totalFields && Ember.isArray(totalFields)) {
            total = totalFields.reduce((memo, field) => { return memo + (row[field] || 0);}, 0); 
        } else if (totalFields && row[totalFields]) {
            total = row[totalFields];
        } else {
            row[destinationField] = 0.0;
            return row;
        }

        if (portionFields && Ember.isArray(portionFields)) {
            portion = portionFields.reduce((memo, field) => { return memo + (row[field] || 0);}, 0); 
        } else if (portionFields && row[portionFields]) {
            portion = row[portionFields];
        } else {
            row[destinationField] = 0.0;
            return row;
        }

        if (Ember.isEmpty(portion)) {
            row[destinationField] = 0.0;
            return row;
        }

        total = (+total || 0.0);
        portion = (+portion || 0.0);

        if (isInverted) {
            portion = total - portion;
        }

        if (total === 0 || portion === 0) {
            row[destinationField] = 0.0;
        } else {
            row[destinationField] = portion / total;
        }

        return row;
    },
    statsCompute: function(stats, data) {
        var destinationField = this.destinationField,
            totalFields = this.totalFields,
            portionFields = this.portionFields,
            isInverted = this.isInverted,
            type = this.type,
            totalStats, portionStats,
            destinationStats = NumberStats.create();

        if (totalFields && Ember.isArray(totalFields)) {
            totalStats = totalFields.reduce((memo, field) => {
                if (!stats[field]) {
                    return memo;
                }
                var fieldStats = stats[field];
                memo["sum"] += fieldStats.sum || 0;
                memo["firstHalfSum"] += fieldStats.firstHalfSum || 0;
                memo["secondHalfSum"] += fieldStats.secondHalfSum || 0;
                return memo;
            }, { sum: 0, firstHalfSum: 0, secondHalfSum: 0 }); 
        } else if (typeof(totalFields) === "string"  && stats[totalFields]) {
            totalStats = stats[totalFields];
        }

        if (portionFields && Ember.isArray(portionFields)) {
            portionStats = portionFields.reduce((memo, field) => {
                if (!stats[field]) {
                    return memo;
                }
                var fieldStats = stats[field];
                memo["sum"] += fieldStats.sum || 0;
                memo["firstHalfSum"] += fieldStats.firstHalfSum || 0;
                memo["secondHalfSum"] += fieldStats.secondHalfSum || 0;
                return memo;
            }, { sum: 0, firstHalfSum: 0, secondHalfSum: 0 }); 
        } else if (typeof(portionFields) === "string" && stats[portionFields]) {
            portionStats = stats[portionFields];
        }

        if (!totalStats || !portionStats) {
            return stats;
        }
        
        stats[destinationField] = destinationStats;

        var total = (+totalStats.sum || 0.0),
            portion = (+portionStats.sum || 0.0);

        if (isInverted) {
            portion = total - portion;
        }

        var maxIndex = 0,
            minIndex = 0,
            max = -Infinity,
            min = Infinity,
            dataIdx = data && data.length;

        while (dataIdx--) {
            var val = (data && data[dataIdx] && data[dataIdx][destinationField]) || 0;

            if (max <= val) {
                max = val;
                maxIndex = dataIdx;
            }
            if (min >= val) {
                min = val;
                minIndex = dataIdx;
            }
        }

        destinationStats.max = max;
        destinationStats.min = min;
        destinationStats.maxIndex = maxIndex;
        destinationStats.minIndex = minIndex;

        var totalFirstHalfSum = +(totalStats.firstHalfSum) || 0.0,
            totalSecondHalfSum = +(totalStats.secondHalfSum) || 0.0,
            portionFirstHalfSum = +(portionStats.firstHalfSum) || 0.0,
            portionSecondHalfSum = +(portionStats.secondHalfSum) || 0.0;

        if (isInverted) {
            portionFirstHalfSum = totalFirstHalfSum - portionFirstHalfSum;
            portionSecondHalfSum = totalSecondHalfSum - portionSecondHalfSum;
        }

        if (total === 0) {
            destinationStats.sum = 0.0;
            destinationStats.average = 0.0;
            destinationStats.percentChange = 0;
        } else {
            destinationStats.sum = portion / total;
            destinationStats.average = portion / total;
            destinationStats.firstHalfSum = totalFirstHalfSum && portionFirstHalfSum / totalFirstHalfSum;
            destinationStats.secondHalfSum = totalSecondHalfSum && portionSecondHalfSum / totalSecondHalfSum;
            if (destinationStats.firstHalfSum) {
                destinationStats.percentChange = ((destinationStats.secondHalfSum - destinationStats.firstHalfSum) /
                    destinationStats.firstHalfSum) * 100.0;
            } else {
                destinationStats.percentChange = 0;
            }
        }
        destinationStats.type = type;

        return stats;
    }
});

DataSeriesComputedFieldPercentage.reopenClass({
    fieldSetup: function(destinationField, totalField, portionField, isInverted) {
        if (typeof(totalField) === "string") {
            totalField = [totalField];
        }
        if (typeof(portionField) === "string") {
            portionField = [portionField];
        }
        
        return DataSeriesComputedFieldPercentage.create({
            destinationField: destinationField,
            totalFields: totalField,
            portionFields: portionField,
            isInverted: !!isInverted
        });
    },
    multiFieldSetup: function(destinationField, totalFields, portionFields, isInverted) {
        return DataSeriesComputedFieldPercentage.create({
            destinationField: destinationField,
            totalFields: totalFields,
            portionFields: portionFields,
            isInverted: !!isInverted
        });
    }
});

export default DataSeriesComputedFieldPercentage;
