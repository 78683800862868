import Ember from "ember";
import SVGInnerWrapper from "infegy-frontend/components/charting/svg/svg-inner-wrapper/component";
import TrendInteraction from "infegy-frontend/models/fields/trend-chart-interaction";
import { computed } from '@ember/object';
import { MouseInteractionWrapper } from "infegy-frontend/components/charting/mouse-interaction-mixin/component";

var last = 0;

export default class extends MouseInteractionWrapper(SVGInnerWrapper) {
    title = "";
    classNameBindings = [":trend-chart", "isHovered:trend-chart-hovered"];

    fields = [];
    hoveredField = null;
    selectedField = null;

    groupByField = "timestamp";

    eventData = null;
    showEvents = true;

    mouseIsDown = false;
    mouseStartXPct = 0;
    interactionInfo = null;

    marginX = 60;
    marginY = 20;
    brushStartTime = 0;

    displayedEvents = [];

    @computed("showEvents", "eventData")
    get includeEvents() {
        return this.showEvents && this.eventData;
    }

    @Ember.computed.alias("fields.firstObject") firstField;

    @computed("firstField", "firstField.data")
    get numIndicies() {
        var data = this.get("firstField.data") || [];
        return data.length;
    }

    @computed("interactionInfo")
    get innerInteractionInfo() {
        var interactionInfo = this.interactionInfo;
        if (interactionInfo && interactionInfo.setProperties) {
            return interactionInfo;
        } else {
            return TrendInteraction.create();
        }
    }

    onMouseEnter() {
        // do nothing.
    }

    @computed("innerInteractionInfo.brushEndXPct")
    get brushEndXWidth() {
        return 100.0 - (this.get("innerInteractionInfo.brushEndXPct") || 0);
    }

    mouseDown(event) {
        var interaction = this.innerInteractionInfo;

        var xPct = (event.offsetX / this.width) * 100,
            numIndicies = this.numIndicies,
            data = this.get("firstField.data"),
            xAxisField = this.get("firstField.xAxisField"),
            xIdx = Math.round((xPct * 0.01) * (numIndicies - 1));
        this.set("mouseIsDown", true);
        this.set("mouseStartXPct", xPct);
        this.set("brushStartTime", Date.now());
        if (interaction.get("isBrushed")) {
            interaction.setProperties({
                "isSelected": false,
                "isBrushed": false,
            });
            this.interactionUpdated(interaction);
            return;
        }
        interaction.setProperties({
            "isSelected": !interaction.get("isSelected"),
            "isBrushed": false,
            "selectedXPct": (xIdx / (numIndicies - 1)) * 100,
            "selectedXIdx": xIdx,
            "selectedXValue": Ember.get(data, [xIdx, xAxisField].join(".")) || 1.0,
            "brushStartXIdx": xIdx,
            "brushEndXIdx": xIdx,
        });
        this.interactionUpdated(interaction);
    }

    mouseUp() {
        this.set("mouseIsDown", false);
        this.fieldSelected(this.interactionInfo);
        if (!this.get("innerInteractionInfo.isBrushed")) {
            return;
        }
        var interaction = this.innerInteractionInfo,
            numIndicies = this.numIndicies,
            startXIdx = interaction.get("brushStartXIdx"),
            endXIdx = interaction.get("brushEndXIdx");
        if (startXIdx > endXIdx) {
            interaction.setProperties({
                "isSelected": false,
                "isBrushed": false
            });
        } else if (startXIdx === endXIdx) {
            interaction.setProperties({
                "isSelected": true,
                "isBrushed": false,
                "selectedXPct": (startXIdx / (numIndicies - 1)) * 100,
                "selectedXIdx": startXIdx,
                "selectedXValue": interaction.get("brushStartXValue"),
            });
        }
        this.interactionUpdated(interaction);
    }

    findHoveredField(fields, mouseYPct, hoveredXIdx) {
        if (Ember.isEmpty(hoveredXIdx) || Ember.isEmpty(fields) || Ember.isEmpty(mouseYPct)) {
            return;
        }
        var hoveredYPctDiff = 1000.0,
            hoveredField;
        fields.forEach(function(field) {
            var maxY = field.get("maxY"),
                data = field.get("data"),
                yAxisField = field.get("fieldName"),
                row = data && data[hoveredXIdx],
                yPct = row && (row[yAxisField] / maxY) || 0.0,
                yPctDiff = Math.abs(yPct - mouseYPct);
            if (yPctDiff < hoveredYPctDiff) {
                hoveredYPctDiff = yPctDiff;
                hoveredField = field;
            }
        });
        return hoveredField;
    }

    onMouseMove(event) {
        var interaction = this.innerInteractionInfo,
            firstField = this.firstField;
        if (!interaction.setProperties || !firstField) {
            return;
        }

        var pctX = event.offsetX / this.width;
        pctX = Math.max(0, pctX);
        pctX = Math.min(1.0, pctX);

        var numIndicies = this.numIndicies,
            pctY = 1.0 - (event.offsetY / this.height),
            hoveredXIdx = Math.round(pctX * (numIndicies - 1)),
            hoveredXPct = (hoveredXIdx / (numIndicies - 1)) * 100.0,
            fields = this.fields || [],
            hoveredField, hoveredYPct, hoveredYValue, hoveredXValue;

        if (!this.mouseIsDown) {
            if (interaction.get("isBrushed") && (hoveredXIdx < interaction.get("brushStartXIdx") ||
                hoveredXIdx > interaction.get("brushEndXIdx"))) {
                interaction.setProperties({
                    "isHovered": false,
                    "hoveredField": null
                });
                return;
            }


            hoveredField = this.findHoveredField(fields, pctY, hoveredXIdx);
            if (hoveredField) {
                let maxY = hoveredField.get("maxY"),
                    data = hoveredField.get("data"),
                    xAxisField = hoveredField.get("xAxisField"),
                    yAxisField = hoveredField.get("fieldName"),
                    row = data && data[hoveredXIdx],
                    yPct = row && (row[yAxisField] / maxY) || 0.0;
                hoveredYValue = row && row[yAxisField] || 0.0;
                hoveredXValue = row && row[xAxisField] || 0.0;
                hoveredYPct = yPct;

                if (hoveredXIdx === 6 && last !== hoveredYValue) {
                    last = hoveredYValue;
                }
                interaction.setProperties({
                    "hoveredYValue": hoveredYValue,
                    "hoveredYPct": (1.0 - hoveredYPct) * 100.0,
                });

            } else if (fields[0]) {
                let data = fields[0].get("data"),
                    xAxisField = fields[0].get("xAxisField"),
                    row = data && data[hoveredXIdx];
                hoveredXValue = row && row[xAxisField] || 0.0;
                interaction.setProperties({
                    "hoveredYValue": null,
                    "hoveredYPct": null,
                });
            }

            interaction.setProperties({
                "isHovered": true,
                "hoveredField": hoveredField,
                "hoveredXPct": hoveredXPct,
                "hoveredXIdx": hoveredXIdx,
                "hoveredXValue": hoveredXValue,
            });
        } else {
            if (interaction.get("isSelected") && this.brushStartTime + 100 > Date.now()) {
                return;
            }
            var startX = interaction.get("brushStartXPct"),
                endX = interaction.get("brushEndXPct"),
                firstX = this.mouseStartXPct,
                data = firstField.get("data") || [],
                xAxisField = firstField.get("xAxisField"),
                newPctX = pctX * 100;

            if (newPctX < firstX) {
                endX = firstX;
                startX = newPctX;
            } else {
                startX = firstX;
                endX = newPctX;
            }
            var startXIdx = Math.ceil((startX * 0.01) * (numIndicies - 1)),
                endXIdx = Math.floor((endX * 0.01) * (numIndicies - 1));
            startXIdx = startXIdx >= 0 ? startXIdx : 0;
            endXIdx = endXIdx <= numIndicies-1 ? endXIdx : numIndicies -1;

            interaction.setProperties({
                "isSelected": false,
                "isHovered": false,
                "hoveredField": null,
                "isBrushed": true,
                "brushStartXPct": (startX < 0) ? 0 : startX,
                "brushStartXIdx": startXIdx,
                "brushStartXValue": data && data[startXIdx][xAxisField] || 1.0,
                "brushEndXPct": (endX > 100) ? 100 : endX,
                "brushEndXIdx": endXIdx,
                "brushEndXValue": data && data[endXIdx][xAxisField] || 1.0,
            });
        }

        this.interactionUpdated(interaction);
        return false;
    }

    @computed("width", "height", "mouseIsDown")
    get overlayRect() {
        var marginX = this.marginX || 0,
            marginY = this.marginY || 0;
        return {
            x: -marginX, y: -marginY,
            w: (this.width || 0) + (2 * marginX),
            h: (this.height || 0) + (2 * marginY) };
    }

    onMouseLeave() {
        this.set("innerInteractionInfo.isHovered", false);
        this.set("innerInteractionInfo.hoveredField", null);
        this.set("mouseIsDown", false);
        this.interactionUpdated(this.innerInteractionInfo);
    }

    eventClicked = () => {};
    eventHovered = () => {};
    fieldSelected = () => {};
    interactionUpdated = () => {};
}
