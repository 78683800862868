import Ember from "ember";
export default Ember.Component.extend({
    errorInfo: null,

    uniqueErrors: Ember.computed("errorInfo", {
        get: function(key) {
            var self = this,
                errors = [],
                uniqueErrors = [];

            if (this.errorInfo) {
                errors = this.errorInfo.getEach('response').uniq();
            }
            errors.forEach(function(error) {
                uniqueErrors.push(self.get('errorInfo').findBy('response', error));
            });
            return uniqueErrors;
        },
    }),

    displaySupportRequest: () => {},
    redirectToLogin: () => {},
    actions: {
        displaySupportRequest: function() {
            this.displaySupportRequest();
        },
        redirectToLogin: function() {
            this.redirectToLogin();
        }
    }
});
