import Ember from "ember";
import QueriesComputed from "infegy-frontend/utils/queries-computed";
import Actions from "infegy-frontend/utils/common-actions";
import QueryAPILinguisticsPosts from "infegy-frontend/models/query-api/linguistics_posts";
import DataSeries from "infegy-frontend/models/data_series/data_series";

var fieldSuffixes = ["Documents", "DocumentsDistribution", "Appearances", "AppearancesPerPost", "Universe"];

export default Ember.Component.extend({
    classNames: ["table-overflow-scroll"],
    emotionsViewType: "arcs",

    queries: null,
    selectedEmotion: null,

    isEmpty: QueriesComputed.apiEmptyForAllQueries("emotions"),
    allLoaded: QueriesComputed.apiLoadedForAllQueries("emotions"),
    isLoading: QueriesComputed.apiLoadingForAnyQuery("emotions"),

    combineEmotions: true,
    multiQueryViewType: Ember.computed("combineEmotions", function () {
        return this.combineEmotions ? "combine" : "compare";
    }),
    hasMoreThanOneQuery: Ember.computed.gt("queries.length", 1),

    currentMultiQueryViewType: Ember.computed("multiQueryViewType", "hasMoreThanOneQuery", function () {
        var multiQueryViewType = this.multiQueryViewType || "combined";
        return this.hasMoreThanOneQuery ? multiQueryViewType : "combined";
    }),

    firstQuery: Ember.computed("queries", "queries.[]", function () {
        return this.get("queries.length") ? this.queries[0] : null;
    }),

    isCompareView: Ember.computed("currentMultiQueryViewType", function() {
        return this.currentMultiQueryViewType === "compare";
    }),

    combinedQueryEmotions: Ember.computed("allAvailableEmotions", "hasMoreThanOneQuery",
            "queries.[]", "queries.@each.updated", function () {
        if (this.get("queries.length") < 2) {
            return this.get("firstQuery.emotions.dataSeries");
        }

        var queries = this.queries || [],
            allData = queries.mapBy("emotions.dataSeries.data") || [],
            combinedData = [].concat.apply([], allData),
            description = queries[0].get("emotions.dataSeries.description");

        if (description && combinedData) {
            var dataSeries = DataSeries.load(combinedData, description);
            return dataSeries;
        }
    }),

    allAvailableEmotions: Ember.computed("queries.[]", "queries.@each.updated", function () {
        var queries = this.queries || [],
            queryEmotions = queries.reduce((memo, query) => {
                var emotions = Ember.get(query, "emotions.availableEmotions") || [];
                return memo.concat(emotions);
            }, []).uniq();
        return queryEmotions;
    }),

    drilldownPostsByQuery: Ember.computed("selectedEmotion", "queries", function () {
        var selectedEmotion = this.get("selectedEmotion.emotion"),
            queries = this.queries || [];
        if (selectedEmotion) {
            return queries.map(query => {
                return {
                    posts: QueryAPILinguisticsPosts.loadEmotionPosts(query, selectedEmotion),
                    query: query
                };
            });
        }
    }),

    emotionData: Ember.computed("allAvailableEmotions", "queries", "queries.[]", "queries.@each.updated", function () {
        var emotions = this.allAvailableEmotions,
            queries = this.queries;
        if (!Ember.isArray(emotions) || !Ember.isArray(queries)) {
            return [];
        }

        var out = emotions.map(eName => {
            var emotionQueries = queries.map(q => {
                return fieldSuffixes.reduce((memo, suffix) => {
                    memo[suffix] = Ember.get(q, "emotions.stats." + eName + suffix + ".sum");
                    memo["totalDocuments"] = Ember.get(q, "emotions.stats.documents.sum");
                    return memo;
                }, {query: q});
            });
            var combined = emotionQueries.reduce((memo, q) => {
                memo.totalDocuments += q.totalDocuments || 0;
                memo.Documents += q.Documents || 0;
                memo.Appearances += q.Appearances || 0;
                memo.AppearancesPerPost += q.AppearancesPerPost || 0;
                memo.Universe += q.Universe || 0;
                return memo;
            }, { totalDocuments: 0, Documents: 0, Appearances: 0, AppearancesPerPost: 0, Universe: 0});

            combined.DocumentsDistribution = combined.Documents / combined.totalDocuments;

            return {
                emotion: eName,
                queriesData: emotionQueries,
                combined: combined
            };
        });
        return out;
    }),

    combineEmotionsChanged: () => {},
    emotionsViewTypeChanged: () => {},
    emotionSelected: () => {},
    selectedFieldChanged: () => {},
    actions: {
        combineEmotionsChanged: function (type) {
            this.combineEmotionsChanged(type === "combine");
        }
    }
});
