import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";

export default QueryAPIResults.extend({
    title : "education",
    description: DataSeriesDescription.load({
        "id": "string",
        "probability": "percent",
        "vsExpected": "percent",
        "dbDistribution": "percent",
        "displayName": "string"
    })
});
