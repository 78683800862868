import TrendsFieldsSelectorBase from "infegy-frontend/components/trends/fields/segment-fields/fields-selector-base";

export default TrendsFieldsSelectorBase.extend({
    classNames: ["trends-fields-dropdown-selector"],
    query: null,

    selectedFieldValue: null,

    fieldChanged: () => {},
    actions: {
        selectedFieldValueChanged: function(newValue) {
            if (newValue === this.selectedFieldValue) {
                return;
            }

            var availableFields = this.availableFields || [],
                field = availableFields.findBy("value", newValue);

            if (!field && availableFields.length) {
                field = availableFields[0];
            }

            this.fieldChanged(field);
        },
    }
});
