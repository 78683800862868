import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";

export default QueryAPIResults.extend({
    title : "stories",
    description: DataSeriesDescription.load({
        "date": "datestring",
        "title": "string",
        "score": "number",
        "topics": "raw",
        "posts": "raw",
        "triggers": "raw",

        "sentiment": "number",
        "projectedVolume": "number",
        "postPercentage": "percent",
        "assembledFrom": "number",
        "sentimentChange": "percent",
    })
});
