import Ember from "ember";
import ChartColors from "infegy-frontend/static_data/fixtures/chart_colors";
import Actions from "infegy-frontend/utils/common-actions";

export default Ember.Component.extend({
    queries: null,
    queriesCombined: null,
    selectedTheme: null,
    subThemeData: null,
    selectedSubTheme: null,

    hasSubThemes: Ember.computed.notEmpty("subThemeData"),
    queriesThemes: Ember.computed.mapBy("queries", "themes"),

    sentimentValue: Ember.computed("queriesThemes.@each.dataSeries", "selectedTheme", function() {
        var queriesThemes = this.queriesThemes,
            selectedTheme = this.get("selectedTheme.theme"),
            sentimentValue = queriesThemes.map(item => {
                return item.get(`dataSeries.stats.${selectedTheme}PositiveAppearancesDistribution.sum`);
            });

        if (sentimentValue.length === 1) {
            return sentimentValue || {}; // Single Query
        } else {
            return this.queriesCombined.get(`stats.${selectedTheme}PositiveAppearancesDistribution.sum`); // Multi-Query
        }
    }),

    sentimentColorStyleSafe: Ember.computed("sentimentValue", function() {
        var sentimentValue = this.sentimentValue,
            colorFunction = ChartColors.sentimentZeroToOneGradientD3(),
            sentimentColor = colorFunction(sentimentValue),
            style = `color:${sentimentColor};`;

        return new Ember.String.htmlSafe(style);
    }),

    // Used to get Sample Posts to populate in UI
    drilldownQueries: Ember.computed("queries", "selectedTheme", "selectedSubTheme", function() {
        const drilldownQueries = this.queries.map((query) => {
            let drilldownQuery = query.copy();
            const searchTheme = this.selectedSubTheme ? this.selectedSubTheme : this.selectedTheme
            drilldownQuery.queryInfo.addAdditionalAPIParameters({
                sub_themes: searchTheme.rawName
            });
            return drilldownQuery;
        });
        return drilldownQueries;
    }),

    // Used to transfer the entire query and selected theme to a new tab
    drilldownButtonQueries: Ember.computed("queries", "selectedTheme", "selectedSubTheme", function() {
        const drilldownQueries = this.queries.map((query) => {
            let drilldownQuery = query.copy();
            const searchTheme = this.selectedSubTheme ? this.selectedSubTheme : this.selectedTheme
            drilldownQuery.queryInfo.addFilter({
                id: "themes",
                values: [searchTheme.theme]
            });
            return drilldownQuery;
        });
        return drilldownQueries;
    }),

    subThemeSentimentValue: Ember.computed("queriesThemes.@each.dataSeries", "selectedSubTheme", function() {
        var queriesThemes = this.queriesThemes,
            selectedTheme = this.get("selectedSubTheme.theme"),
            sentimentValue = queriesThemes.map(item => {
                return item.get(`dataSeries.stats.${selectedTheme}PositiveAppearancesDistribution.sum`);
            });

        if (sentimentValue.length === 1) {
            return sentimentValue || {}; // Single Query
        } else {
            return this.queriesCombined.get(`stats.${selectedTheme}PositiveAppearancesDistribution.sum`); // Multi-Query
        }
    }),

    subThemeSentimentColorStyleSafe: Ember.computed("subThemeSentimentValue", function() {
        var sentimentValue = this.subThemeSentimentValue,
            colorFunction = ChartColors.sentimentZeroToOneGradientD3(),
            sentimentColor = colorFunction(sentimentValue),
            style = `color:${sentimentColor};`;

        return new Ember.String.htmlSafe(style);
    }),

    subThemeSelected: () => { /* action */ },

    actions: {
        subThemeSelected: Actions.passUp("subThemeSelected"),
    }
});
