import Ember from 'ember';
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import InfegyAppConfig from "infegy-frontend/config/infegy-app-config";

export default Ember.Controller.extend({
    application: Ember.inject.controller(),
    user: Ember.computed.alias("application.user"),
    dictionaries: Ember.computed.alias("user.dictionaries"),

    languagesOptions: [{
        "value": "00",
        "label": "Automatically Detect"
    }, {
        "value": "en",
        "label": "English"
    }, {
        "value": "fr",
        "label": "French"
    }, {
        "value": "de",
        "label": "German"
    }, {
        "value": "pt",
        "label": "Portuguese"
    }, {
        "value": "es",
        "label": "Spanish"
    }],

    subject: '',
    language: '00',
    hasHeader: true,
    selectedDictionaries: [],

    showDetails: false,

    fileInfo: null,

    invalidFileSize: Ember.computed.gt("fileInfo.size", 10 * 1024 * 1024),
    errorMessage: null,

    hasError: Ember.computed.or("invalidFileSize", "errorMessage"),

    actions: {
        selectFile: function(event) {
            var files = event && event.target && event.target.files,
                firstFile = files && files[0];
            if (firstFile) {
                this.set("fileInfo", firstFile);
            }
        },
        toggleDetails: function() {
            this.toggleProperty("showDetails");
        },
        analyzeAndDownloadFile: function() {
            var file = this.fileInfo,
                url = `${InfegyAppConfig.baseAPIDomain}linguistics_processing?`;

            var params = {
                "subject": this.subject,
                "language": this.language,
                "has_header": this.hasHeader,
                "dictionary": (this.selectedDictionaries || []).join(","),
                "file_name": file.name
            };

            for (var key in params) {
                if (params.hasOwnProperty(key)) {
                    url = url.concat(`${key}=${params[key]}`);

                    if (key !== Object.keys(params)[Object.keys(params).length - 1]) {
                        url = url.concat("&");
                    }
                }
            }

            AtlasAuth.downloadPOST(url, file, undefined, () => {
                this.set('errorMessage', null);
            }, (error) => {
                console.warn('Unable to utilize linguistics processing: ', error);
                this.set('errorMessage', `${error.status}: ${error.statusText}`);
            });
        },
        languageChanged: function(value) {
            this.set('language', value);
        },
        hasHeaderChanged: function(value) {
            this.set('hasHeader', value);
        },
        clearAllFields: function() {
            this.setProperties({
                'fileInfo': null,
                'errorMessage': null,
                'subject': '',
                'language': '00',
                'hasHeader': true,
            });
        }
    },
});
