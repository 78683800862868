import Component from '@ember/component';
import { computed, action } from '@ember/object';
import AxisChartFields from "infegy-frontend/components/field-components/axis-comparison-chart/axis-chart-fields";
import d3 from "d3";
import classic from "ember-classic-decorator";

@classic
export default class AxisComparisonChart extends Component {
    user = null;
    queries = null;

    axisChartFields = null;

    svgSize = 600;
    svgMarginPortion = 0.15;

    minRadius = 3;
    maxRadius = 12;

    axisLabelOffset = 10;
    targetTicks = 4;

    editingField = null;

    showButtons = true;
    showOptions = false;

    hoveredBubble = null;

    @computed("queries", "queries.[]")
    get firstQuery() {
        return this.get("queries.length") ? this.queries[0] : null;
    }

    @computed("queries", "firstQuery")
    get innerAxisChartFields() {
        let fields = this.axisChartFields || AxisChartFields.create();
        Ember.set(fields, "x.query", this.firstQuery);
        Ember.set(fields, "y.query", this.firstQuery);
        Ember.set(fields, "radius.query", this.firstQuery);
        return fields;
    }

    @computed("innerAxisChartFields.x.apiName", "innerAxisChartFields.y.apiName", "innerAxisChartFields.radius.apiName")
    get apiModels() {
        return [
            this.innerAxisChartFields?.x?.queryApi,
            this.innerAxisChartFields?.y?.queryApi,
            this.innerAxisChartFields?.radius?.queryApi ];
    }

    @computed("innerAxisChartFields.x.apiName", "innerAxisChartFields.y.apiName", "innerAxisChartFields.radius.apiName")
    get loaderAPIs() {
        let apis = [];
        if (this.innerAxisChartFields.x && this.innerAxisChartFields.x.apiName) {
            apis.push(this.innerAxisChartFields.x.apiName);
        }
        if (this.innerAxisChartFields.y && this.innerAxisChartFields.y.apiName) {
            apis.push(this.innerAxisChartFields.y.apiName);
        }
        if (this.innerAxisChartFields.radius && this.innerAxisChartFields.radius.apiName) {
            apis.push(this.innerAxisChartFields.radius.apiName);
        }
        return apis;
    }

    @computed("queries", "innerAxisChartFields.x", "innerAxisChartFields.y", "innerAxisChartFields.radius")
    get queryFields() {
        if (!this.queries || !this.innerAxisChartFields || !this.innerAxisChartFields.x ||
                !this.innerAxisChartFields.y || !this.innerAxisChartFields.radius) {
            return [];
        }

        let xField = this.innerAxisChartFields.x,
            yField = this.innerAxisChartFields.y,
            radiusField = this.innerAxisChartFields.radius;

        return this.queries.map(query => {
            return {
                query: query,
                xField: xField.copy(query),
                yField: yField.copy(query),
                radiusField: radiusField.copy(query)
            };
        });
    }

    @computed("queries.@each.updated", "queryFields")
    get isLoaded() {
        return !this.queryFields.find(qf => {
            return !qf.xField.isLoaded || !qf.yField.isLoaded || !qf.radiusField.isLoaded;
        });
    }

    scalesForField(field, min, max) {
        let maxValues = this.queryFields.map(qf => Ember.get(qf, `${field}.maxScalarValue`)),
            minValues = this.queryFields.map(qf => Ember.get(qf, `${field}.minScalarValue`)),
            values = this.queryFields.map(qf => Ember.get(qf, `${field}.scalarValue`));
        min = Ember.isEmpty(min) ? 0 : min;
        max = Ember.isEmpty(max) ? 100 : max;
        return {
            default: {
                max: Math.max(...maxValues),
                min: Math.min(...minValues),
                scale: d3.scaleLinear()
                    .domain([Math.min(...minValues) || 0, Math.max(...maxValues) || 10])
                    .range([min, max]).nice()
            },
            fitted: {
                max: Math.max(...values),
                min: Math.min(...values),
                scale: d3.scaleLinear()
                    .domain([Math.min(...values) || 0, Math.max(...values) || 10])
                    .range([min, max]).nice()
            }
        };
    }

    @computed("svgSize", "svgMarginPortion")
    get svgMarginSize() {
        return Math.round(this.svgSize * this.svgMarginPortion);
    }

    @computed("svgSize", "svgMarginSize")
    get innerSVGSize() {
        return Math.round(this.svgSize - (2 * this.svgMarginSize));
    }


    @computed("innerAxisChartFields.x", "innerAxisChartFields.y", "maxRadius",
        "minRadius", "innerAxisChartFields.radius", "isLoaded", "queryFields", "innerSVGSize")
    get allAxisScales() {
        let rangeMax = this.innerSVGSize,
            isLoaded = this.isLoaded,
            x = this.scalesForField("xField", 0, rangeMax),
            y = this.scalesForField("yField", rangeMax, 0),
            radius = this.scalesForField("radiusField", this.minRadius, this.maxRadius),
            // percentage ranges
            xPct = this.scalesForField("xField", 0, 1.0),
            yPct = this.scalesForField("yField", 0, 1.0);

        return {
            default: { x: x.default, y: y.default, xPct: xPct.default, yPct: yPct.default, radius: radius.default },
            fitted: { x: x.fitted, y: y.fitted, xPct: xPct.fitted, yPct: yPct.fitted, radius: radius.fitted },
        };
    }

    @computed("allAxisScales", "innerAxisChartFields.useDefaultFieldRangesX", "innerAxisChartFields.useDefaultFieldRangesY")
    get axisScales() {
        let defaultScales = this.allAxisScales.default,
            xScales = this.innerAxisChartFields.useDefaultFieldRangesX ? defaultScales : this.allAxisScales.fitted,
            yScales = this.innerAxisChartFields.useDefaultFieldRangesY ? defaultScales : this.allAxisScales.fitted;
        return {
            x: xScales.x,
            y: yScales.y,
            xPct: xScales.xPct,
            yPct: yScales.yPct,
            radius: defaultScales.radius
        };
    }

    @computed("queryFields", "axisScales", "isLoaded", "innerSVGSize")
    get queryRows() {
        if (!this.isLoaded || Ember.isEmpty(this.queryFields)) {
            return [];
        }

        let scales = this.axisScales;

        let queryRows = this.queryFields.map(qf => {
            let x = Math.round(scales.x.scale(qf.xField.scalarValue)),
                y = Math.round(scales.y.scale(qf.yField.scalarValue)),
                radiusPct = Math.round(scales.radius.scale(qf.radiusField.scalarValue)),
                xPct = scales.xPct.scale(qf.xField.scalarValue),
                yPct = scales.yPct.scale(qf.yField.scalarValue);

            return {
                x: x,
                y: y,
                xPct: xPct,
                yPct: yPct,
                useLeftLabel: xPct > 0.2,
                useRightLabel: xPct < 0.8,
                useTopLabel: yPct < 0.8,
                useBottomLabel: yPct > 0.2,
                invY: this.innerSVGSize - y,
                radiusPct: `${radiusPct}%`,
                radius: radiusPct,
                colorStyle: qf.query.color.fillStyle,
                title: qf.query.title,
                fields: qf
            }
        });

        // draw the biggest bubbles first, to avoid covering smaller ones
        queryRows.sort((qra, qrb) => qrb.radius - qra.radius);

        return queryRows;
    }

    @computed("axisScales", "targetTicks")
    get xAxis() {
        let scale = this.axisScales.x.scale,
            domain = scale.domain() || [],
            axisTicks = scale.ticks(this.targetTicks) || [];
        let ticks = axisTicks.map(tick => {
                return { pos: Math.round(scale(tick)), val: tick };
            });
        return {
            axisVal: scale.domain()[0],
            axisPos: 0,
            ticks: ticks
        };
    }

    @computed("axisLabelOffset", "innerSVGSize")
    get axisLabelOffsets() {
        return {
            y: this.axisLabelOffset + this.innerSVGSize,
            inv: -this.axisLabelOffset
        }
    }

    @computed("axisScales", "innerSVGSize", "isLoaded", "targetTicks")
    get yAxis() {
        let scale = this.axisScales.y.scale,
            domain = scale.domain() || [],
            axisTicks = scale.ticks(this.targetTicks) || [];
        let ticks = axisTicks.map(tick => {
                let pos = Math.round(scale(tick));
                return {
                    pos: pos,
                    invPos: this.innerSVGSize - pos,
                    val: tick
                };
            });
        return {
            axisVal: scale.domain()[0],
            axisPos: this.innerSVGSize,
            ticks: ticks
        };
    }

    @computed("editingField", "innerAxisChartFields", "innerAxisChartFields.y",
        "innerAxisChartFields.x", "innerAxisChartFields.radius")
    get selectedField() {
        if (this.innerAxisChartFields && this.editingField) {
            return this.innerAxisChartFields.get(this.editingField);
        }
    }

    @computed("svgSize")
    get chartStyle() {
        return new Ember.String.htmlSafe(`height:${this.svgSize}px; width: ${this.svgSize}px;`);
    }

    @computed("editingField")
    get editingFieldLabel() {
        if (this.editingField === "radius") {
            return "Radius";
        }
        return `${this.editingField} Axis`;
    }

    axisChartFieldsChanged() { /* action */ };

    @action
    selectedFieldChanged(field) {
        if (this.editingField && this.innerAxisChartFields) {
            this.innerAxisChartFields.set(this.editingField, field);
            this.axisChartFieldsChanged(this.innerAxisChartFields);
        }
    }

    @action
    bubbleHovered(queryRow) {
        if (queryRow !== this.hoveredBubble) {
            this.set("hoveredBubble", queryRow);
        }
    }

    @action
    editField(field) {
        this.set("editingField", this.editingField === field ? null : field);
    }

    @action
    toggleUseDefaultXFieldRanges() {
        this.toggleProperty("innerAxisChartFields.useDefaultFieldRangesX");
        this.axisChartFieldsChanged(this.innerAxisChartFields);
    }

    @action
    toggleUseDefaultYFieldRanges() {
        this.toggleProperty("innerAxisChartFields.useDefaultFieldRangesY");
        this.axisChartFieldsChanged(this.innerAxisChartFields);
    }

    @action
    toggleOptions(field) {
        this.toggleProperty("showOptions");
    }
};
