import Ember from "ember";
var autoNameCount = 0;
export default Ember.Component.extend({
    tagName: "span",
    classNameBindings: ["unstyled:toggle-link:toggle-button", "sizeClass"],

    // Main Options
    isActive: false,
    activeText: "On",
    inactiveText: "Off",

    // Form Element Name, optional
    name: "",

    // Styling Options
    unstyled: false,
    size: "medium", // small, medium, large

    // Computed properties, not for outside use.
    sizeClass: Ember.computed("size", {
        get: function(key) {
            var size = (this.size || "").toLowerCase();
            return ["toggle-button", size].join("-");
        },
    }),

    autoName: Ember.computed("name", {
        get: function(key) {
            return this.name || this.id || ["checkbox", autoNameCount++].join("-");
        },
    }),

    click: function () {
        this.toggleProperty("isActive");
        this.send("wasToggled");
    },

    toggled: () => {},
    actions: {
        wasToggled: function() {
            if (this.toggled) {
                this.toggled(this.isActive);
            }
        },
    },
});
