import Ember from "ember";
import { computed } from "@ember/object";
import { alias, readOnly } from "@ember/object/computed";
import { isEmpty, isNone } from "@ember/utils";
import { debounce } from "@ember/runloop";

import JsonStore from 'infegy-frontend/json-store/model';
import Prop from 'infegy-frontend/json-store/properties/model';
import QueryFilters from "infegy-frontend/models/queries/query_filters";
import QueryApiRequestString from "infegy-frontend/models/queries/query_api_request_string";
import ArrayBase from "infegy-frontend/models/array_compat";
import QueryAPILoader from "infegy-frontend/models/queries/query_api_loader";
import APIFilter from "infegy-frontend/models/queries/filters/api_filter";

import QueryAPIUtils from "infegy-frontend/models/queries/query_api_utils";
import AtlasConfig from "infegy-frontend/config/infegy-app-config";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";

import QueryAPINumericStats from "infegy-frontend/models/query-api/numericStats";
import QueryAPITopKeywords from "infegy-frontend/models/query-api/topKeywords";
import QueryAPIs from "infegy-frontend/models/queries/query_apis";

export default class Query extends JsonStore {
    @Prop.Int() id;
    @Prop.Int() userId;
    // @Prop.String() title;
    @Prop.Object(QueryFilters) queryInfo;
    @Prop.Int() runCount
    @Prop.Boolean() isSaved;
    @Prop.Attr() lastUsedOn;
    @Prop.Attr() createdOn;
    @Prop.Attr() updatedOn;
    @Prop.Attr() deletedOn;
    @Prop.Boolean() isShared;

    // Special rules for exluding / including info in serialization - see JSONStore
    _fieldGroups = {
        api: {
           includes: ["id", "isSaved", "queryInfo"]
        }
    }

    url = "api/v3/queries";
    updated =  0;
    hasValidated = false;

    @readOnly("queryInfo.activeGrouping") activeGrouping;
    @readOnly("queryInfo.color") color;
    @readOnly("queryInfo.color.base") colorValue;
    @readOnly("queryInfo.color.class") colorClass;
    @alias("queryInfo.earliestTimestamp") earliestTimestamp;
    @alias("queryInfo.latestTimestamp") latestTimestamp;
    @alias("queryInfo.entityQuery") entityQuery;

    @alias("queryInfo.isBlank") isBlank;
    @alias("queryInfo.isUsingQueryBuilder") isUsingQueryBuilder;
    @alias("queryInfo.isBlank") isEmpty;
    @alias("queryInfo") filters;
    @alias("queryInfo") queryFilters;
    @alias("queryInfo.queryString") queryString;
    @alias("queryInfo.queryType") queryType;
    @alias("queryInfo.savedQueryJSON") savedQueryJSON;
    @readOnly("queryInfo.sourceQueryString") sourceQueryString;
    @alias("queryInfo.title") title;

    @readOnly("queryInfo.datasetQueryInfo") datasetQueryInfo;

    @computed.notEmpty("queryInfo.hasCustomDataset") hasCustomDataset;
    @computed("queryInfo.activeDataset.queryInfo", "queryInfo.startDate.isDirty", "queryInfo.endDate.isDirty")
    get isDatasetLoaded() {
        const hasLoaded = !!this.queryInfo.activeDataset?.queryInfo;
        const datesUpdated = !this.queryInfo.startDate.isDirty && !this.queryInfo.endDate.isDirty;
        return hasLoaded && datesUpdated;
    }
    @computed.notEmpty("queryString") hasBooleanQuery;
    @computed.notEmpty("queryInfo.entityQuery") hasEntityQuery;
    @computed.not("queryInfo.queryBuilderDetail.isBlank") hasBuilderQuery;
    @computed.notEmpty("queryInfo.filters") hasFilters;

    @computed("hasErrors", "hasCustomDataset", "hasBooleanQuery", "hasEntityQuery", "hasBuilderQuery", 
        "hasFilters", "queryInfo.filters.@each.hasValue", "queryInfo.filters.@each.isExcluded", 
        "queryInfo.startDate.timestamp", "queryInfo.endDate.timestamp", "hasValidated")
    get isRunnable() {
        const filterInfo = this.queryInfo.datasetQueryInfo?.filters || [];
        let hasExecutableFilter = this.hasFilters && !isNone(this.queryInfo.filters.content.find((filter) => {
            const info = filterInfo.find((fi) => fi.id === filter.id);
            if (info) {
                return info.executable && !filter.isExcluded && filter.hasValue;
            } else {
                return false;
            }
        }));
        // Pulling out these checks forces Ember to calculate and update their associated bindings.
        const hasContent = (this.hasCustomDataset || this.hasBooleanQuery || this.hasEntityQuery || this.hasBuilderQuery || hasExecutableFilter);
        return (this.hasValidated && !this.hasErrors) && hasContent;
    }

    errors =  null;
    @computed.notEmpty("errors") hasErrors;

    loadJSON(jsonData, options) {
        if (jsonData.hasOwnProperty("query_info") && typeof(jsonData.query_info) === "string") {
            jsonData.query_info = JSON.parse(jsonData.query_info);
        }
        super.loadJSON(jsonData, options);
    }

    toJSON(options) {
        let jsonData = super.toJSON(options);
        if (!jsonData.hasOwnProperty("is_saved")) {
            jsonData.is_saved = 0;
        }
        else {
            jsonData.is_saved = jsonData.is_saved ? 1 : 0;
        }

        return jsonData;
    }

    @computed("queryInfo", "id", "index", "queryInfo.lookupId")
    get lookupId() {
        if (!Ember.isEmpty(this.queryInfo.lookupId)) {
            return this.queryInfo.lookupId;
        }
        return this.id;
    }

    async save() {
        return AtlasAuth.Post({
            url: this.url,
            headers: {
                "Content-Type": "application/json"
            },
            data: this.toJSON({fieldGroups: "api"})
        });
    }

    copy() {
        var newQuery = Query.create();
        newQuery.set("queryInfo", this.queryInfo.copy());
        newQuery.queryInfo.setProperties({
            startRangeTimestamp: this.queryInfo.startRangeTimestamp,
            endRangeTimestamp: this.queryInfo.endRangeTimestamp
        });
        return newQuery;
    }

    addError(errorObj) {
        var errors = this.errors;
        if (!errors) {
            errors = ArrayBase.create();
            this.set("errors", errors);
        }

        if (errorObj) {
            errors.pushObject(errorObj);
        }
    }

    clearErrors(code_or_codes) {
        var errors = this.errors;
        if (typeof(code_or_codes) === "string") {
            code_or_codes = [code_or_codes];
        }

        if (errors && code_or_codes) {
            errors = errors.filter(function(errorInfo) {
                return !code_or_codes.includes(errorInfo.get("code"));
            });
            this.set("errors", (errors.length) ? errors : null );
        } else {
            this.set("errors", null);
        }
    }

    clearAPIResults() {
        QueryAPIs.forEach(function(apiInfo) {
            this.set("_" + apiInfo.name, null);
        }, this);
        this.clearErrors();
        this.incrementProperty("updated");
    }

    clearErroredAPIResults(code_or_codes) {
        var errors = this.errors;
        if (!errors || !errors.length) {
            return;
        }
        if (typeof(code_or_codes) === "string") {
            code_or_codes = [code_or_codes];
        }

        errors.forEach(function(errorInfo) {
            if (!code_or_codes || code_or_codes.includes(errorInfo.get("code"))) {
                this.set("_" + errorInfo.get("apiName"), null);
            }
        }, this);
        this.clearErrors(code_or_codes);
    }

    toAPIQueryString(queryInfo, additionalAPIParameters, apiName, encode_all) {
        queryInfo = queryInfo || this.queryInfo;
        additionalAPIParameters = Object.assign({},
            this.get("queryInfo.additionalAPIParameters") || {},
            additionalAPIParameters || {});
        var apiString = QueryApiRequestString.build(this, apiName, additionalAPIParameters, encode_all);
        return apiString;
    }

    toAPIRequestURL(apiName, options, encode_all) {
        options = Object.assign({
            format: 'json',
            queryInfo: this.queryInfo,
            additionalAPIParameters: {}
        }, options);

        var apiPath = QueryAPIUtils.pathByName(apiName),
            baseURL = [AtlasConfig.baseAPIDomain, apiPath, '.', options.format, '?'].join(""),
            apiString = this.toAPIQueryString(options.queryInfo, options.additionalAPIParameters, apiName, encode_all);
        return [baseURL, apiString].join("");
    }

    checkIfOutdated(queryInfo, additionalAPIParameters) {
        var requestString = this.toAPIQueryString(queryInfo, additionalAPIParameters);
        var lastRequestString = this.lastRequestString;
        return (requestString !== lastRequestString);
    }

    cleanQueryString() {
        var queryString = QueryStrings.clean(this.queryString || "");
        this.set("queryString", queryString);
        return queryString;
    }

    validateQueryString() {
        var queryString = this.cleanQueryString();
        return QueryStrings.validate(queryString);
    }

    lastValidatedURL = "";

    validate(immediate=false) {
        debounce(this, this._doValidate, 350, immediate);
    }

    async _doValidate() {
        const params = QueryApiRequestString.build(this, "query-test");
        if (this.lastValidatedURL !== params) {
            this.set("hasValidated", false);
            let queryURL = `api/v3/query-test?${params}`;
            try {
                await AtlasAuth.Get(queryURL);
                this.set("errors", "");
            } catch (error) {
                const isUserError = ("" + error.status).startsWith("4");
                if (isUserError) {
                    this.set("errors", error.atlasErrorText);
                } else {
                    console.error(error);
                }
            } finally {
                this.setProperties({
                    lastValidatedURL: params,
                    hasValidated: true
                });
            }
        } else {
            this.set("hasValidated", true);
        }
    }

    /**
     * Creates a new drill-down query using this query as a source and some configuration options.
     * @param {Object} options 
     * @param {String} options.queryString - The `queryString` value.
     * @param {String} options.queryLabel - The display text used to represent the `queryString`.
     * @param {Boolean} [options.isSourceBio=false] - Determines if this is this a "Source Bio" drilldown.
     * @returns {Query} The new drill-down query.
     */
    toDrilldownQuery(options={}) {
        if (!options?.queryString || !options?.queryLabel)
            return null;

        let drilldownQuery = this.copy();
        if (this.queryType === "builder") {
            if (options.isSourceBio) {
                let bioFilter = APIFilter.create();
                bioFilter.loadJSON({
                    id: "bio",
                    name: "Bio",
                    type: "text",
                    textValue: options.queryString
                });
                drilldownQuery.queryInfo.filters.pushObject(bioFilter);
            } else {
                drilldownQuery.get("queryInfo.queryBuilderDetail.drillInItems").loadJSONRow({
                    label: options.queryLabel,
                    query: options.queryString,
                    type: "topic"
                });
            }
        } else {
            drilldownQuery.set("title", [options.queryLabel, "within", this.get("title")].join(" "));
            // Move the current query string to the `queryWithin`.
            const queryWithin = this.queryInfo.queryWithin ? `(${this.queryInfo.queryWithin}) AND ${this.queryInfo.queryString}` : this.queryInfo.queryString;
            drilldownQuery.queryInfo.set("queryWithin", queryWithin);
            // Build the new query string: drilling into a Source Bio query only uses the "bio" filter field.
            const filterFields = options.isSourceBio ? ["bio"] : this.queryInfo.analyzeFields || [];
            const queryString = filterFields.map((field) => {
                return `(${field}: ${options.queryString})`;
            }).join(" OR ");
            // If there are no specified `analyzeFields` the built queryString could be empty.  If this 
            // happens just use the `queryString` option.
            drilldownQuery.set("queryString", queryString || options.queryString);
        }
        return drilldownQuery;
    }

    addStringToQuery(queryString, optionalLabel) {
        optionalLabel = optionalLabel || queryString;
        if (this.isUsingQueryBuilder) {
            this.queryInfo.queryBuilderDetail.andItems.loadJSON({
                iconType: "text",
                label: optionalLabel,
                type: "text",
                value: queryString
            });
        } else {
            let newQueryString = this.queryString;
            if (!newQueryString) {
                newQueryString = queryString;
            } else {
                newQueryString += ` ${queryString}`;
            }
            this.set("queryString", newQueryString);
        }
    }

    removeStringFromQuery(queryString, optionalLabel) {
        optionalLabel = optionalLabel || queryString;
        if (this.isUsingQueryBuilder) {
            this.queryInfo.queryBuilderDetail.notItems.loadJSON({
                iconType: "text",
                label: optionalLabel,
                type: "text",
                value: queryString
            });
        } else {
            let newQueryString = this.queryString;
            if (!newQueryString) {
                newQueryString = queryString;
            } else {
                newQueryString += ` NOT ${queryString}`;
            }
            this.set("queryString", newQueryString);
        }
    }

    createFromFilters(queryInfo) {
        var newQuery = Query.create();
        if (queryInfo) {
            newQuery.set("queryInfo", queryInfo.copy());
        }
        return newQuery;
    }

    fetchAPI(apiName, apiModelClass, apiPath, additionalParameters) {
        let apiInfo = QueryAPIUtils.byName(apiName);
        if (!apiPath && apiInfo && apiInfo.apiPath) {
            apiPath = apiInfo.apiPath;
        }
        if (!apiModelClass && apiInfo && apiInfo.model) {
            apiModelClass = apiInfo.model;
        }

        if (!Ember.isEmpty(this.qid) && !Ember.isEmpty(this.parentDocument)) {
            // This is a Canvas query that requires some additional API configuration:
            // Ensure the Canvas query has the needed parameters to handle caching.
            this.queryInfo.addAdditionalAPIParameters({
                canvas_id: this.parentDocument.id,
                canvas_request_hash: `${this.qid}-${apiName}`
            });
            // A query can persist the `additionalAPIParameters.canvas_reload` which causes the 
            // canvas document to continually refresh queries.  If the user doesn't own the canvas 
            // document this parameter needs to be removed to prevent a read-only viewer from 
            // inadvertently refreshing the queries.
            const isActiveUserDocumentOwner = this.get("parentDocument.isActiveUserDocumentOwner");
            if (!isActiveUserDocumentOwner) {
                delete this.queryInfo.additionalAPIParameters.canvas_reload;
            }
        }
        let apiString = this.toAPIQueryString(this.queryInfo, additionalParameters, apiName);

        let requestString = `${AtlasConfig.baseAPIDomain}${apiPath}.json?${apiString}`;
        let loaderResults = QueryAPILoader.fetchQueryAPIResponse(requestString, apiModelClass);

        return loaderResults;
    }

    apiResponseObjects = null;

    loadOwnQueryAPI(apiName) {
        let response = this.fetchAPI(apiName);

        if (!response || !response.promise) {
            return {};
        }
        if (!this.apiResponseObjects) {
            this.apiResponseObjects = {};
        }
        if (!this.apiResponseObjects[apiName]) {
            this.apiResponseObjects[apiName] = response;
            response.promise.catch(error => {
                if (typeof(error) === "string"){
                    throw error;
                } else {
                    this.addError(error);
                }
            });
            response.promise.then(response => {
                this.set("lastRequestString", this.toAPIQueryString(this.queryInfo));
            });
            response.promise.finally(() => {
                response.apiModel.query = this;
                this.incrementProperty("updated");
            });
        }
        return response;
    }

    loadOwnQueryAPIModel(apiName) {
        let loaderResults = this.loadOwnQueryAPI(apiName);
        return loaderResults && loaderResults.apiModel;
    }

    fetchNumericStats(field) {
        let fieldIds = this.availableDynamicTrendsFields?.mapBy("id");
        if (field && fieldIds && fieldIds.includes(field)) {
            return this.fetchAPI("numericStats", QueryAPINumericStats, "numeric-stats", {field: field});
        }
    }

    fetchTopKeywords(field, subField) {
        let additionalParameters = {field: field},
            fieldIds = this.availableDynamicTrendsFields?.mapBy("id");
        if (subField) {
            additionalParameters.subField = subField;
        }
        if (field && fieldIds && fieldIds.includes(field)) {
            return this.fetchAPI("topKeywords", QueryAPITopKeywords, "top-keywords", {field: field});
        }
    }

    @computed("queryInfo.datasetQueryInfo", "queryInfo.activeDataset.queryInfo", "queryInfo.user.socialQueryInfo")
    get availableDynamicTrendsFields() {
        let queryInfo = this.queryInfo?.datasetQueryInfo?.filters?.filter(filter => {
            return ["keyword", "keywords", "integer", "decimal"].includes(filter.type);
        }) || [];
        return queryInfo;
    }

    @computed("queryInfo.jsonData") get ages() { return this.loadOwnQueryAPIModel("ages"); }
    @computed("queryInfo.jsonData") get caProvinces() { return this.loadOwnQueryAPIModel("caProvinces"); }
    @computed("queryInfo.jsonData") get channels() { return this.loadOwnQueryAPIModel("channels"); }
    @computed("queryInfo.jsonData") get countries() { return this.loadOwnQueryAPIModel("countries"); }
    @computed("queryInfo.jsonData") get demographicsMeta() { return this.loadOwnQueryAPIModel("demographicsMeta"); }
    @computed("queryInfo.jsonData") get dmas() { return this.loadOwnQueryAPIModel("dmas"); }
    @computed("queryInfo.jsonData") get domains() { return this.loadOwnQueryAPIModel("domains"); }
    @computed("queryInfo.jsonData") get links() { return this.loadOwnQueryAPIModel("links"); }
    @computed("queryInfo.jsonData") get education() { return this.loadOwnQueryAPIModel("education"); }
    @computed("queryInfo.jsonData") get embeds() { return this.loadOwnQueryAPIModel("embeds"); }
    @computed("queryInfo.jsonData") get emoji() { return this.loadOwnQueryAPIModel("emoji"); }
    @computed("queryInfo.jsonData") get emotions() { return this.loadOwnQueryAPIModel("emotions"); }
    @computed("queryInfo.jsonData") get engagements() { return this.loadOwnQueryAPIModel("engagements"); }
    @computed("queryInfo.jsonData") get entities() { return this.loadOwnQueryAPIModel("entities"); }
    @computed("queryInfo.jsonData") get events() { return this.loadOwnQueryAPIModel("events"); }
    @computed("queryInfo.jsonData") get gender() { return this.loadOwnQueryAPIModel("gender"); }
    @computed("queryInfo.jsonData") get hashtags() { return this.loadOwnQueryAPIModel("hashtags"); }
    @computed("queryInfo.jsonData") get headlines() { return this.loadOwnQueryAPIModel("headlines"); }
    @computed("queryInfo.jsonData") get homeOwnership() { return this.loadOwnQueryAPIModel("homeOwnership"); }
    @computed("queryInfo.jsonData") get householdValue() { return this.loadOwnQueryAPIModel("householdValue"); }
    @computed("queryInfo.jsonData") get income() { return this.loadOwnQueryAPIModel("income"); }
    @computed("queryInfo.jsonData") get influenceDistribution() { return this.loadOwnQueryAPIModel("influenceDistribution"); }
    @computed("queryInfo.jsonData") get influencers() { return this.loadOwnQueryAPIModel("influencers"); }
    @computed("queryInfo.jsonData") get interests() { return this.loadOwnQueryAPIModel("interests"); }
    @computed("queryInfo.jsonData") get languages() { return this.loadOwnQueryAPIModel("languages"); }
    @computed("queryInfo.jsonData") get linguisticsStats() { return this.loadOwnQueryAPIModel("linguisticsStats"); }
    @computed("queryInfo.jsonData") get mentions() { return this.loadOwnQueryAPIModel("mentions"); }
    @computed("queryInfo.jsonData") get narratives() { return this.loadOwnQueryAPIModel("narratives"); }
    @computed("queryInfo.jsonData") get negativeKeywords() { return this.loadOwnQueryAPIModel("negativeKeywords"); }
    @computed("queryInfo.jsonData") get negativeTopics() { return this.loadOwnQueryAPIModel("negativeTopics"); }
    @computed("queryInfo.jsonData") get positiveKeywords() { return this.loadOwnQueryAPIModel("positiveKeywords"); }
    @computed("queryInfo.jsonData") get positiveTopics() { return this.loadOwnQueryAPIModel("positiveTopics"); }
    @computed("queryInfo.jsonData") get postInterests() { return this.loadOwnQueryAPIModel("postInterests"); }
    @computed("queryInfo.jsonData") get posts() { return this.loadOwnQueryAPIModel("posts"); }
    @computed("queryInfo.jsonData") get race() { return this.loadOwnQueryAPIModel("race"); }
    @computed("queryInfo.jsonData") get ratings() { return this.loadOwnQueryAPIModel("ratings"); }
    @computed("queryInfo.jsonData") get sentiment() { return this.loadOwnQueryAPIModel("sentiment"); }
    @computed("queryInfo.jsonData") get sourceBioTopics() { return this.loadOwnQueryAPIModel("sourceBioTopics"); }
    @computed("queryInfo.jsonData") get states() { return this.loadOwnQueryAPIModel("states"); }
    @computed("queryInfo.jsonData") get stories() { return this.loadOwnQueryAPIModel("stories"); }
    @computed("queryInfo.jsonData") get subquerySets() { return this.loadOwnQueryAPIModel("subquerySets"); }
    @computed("queryInfo.jsonData") get themes() { return this.loadOwnQueryAPIModel("themes"); }
    @computed("queryInfo.jsonData") get timeOfDay() { return this.loadOwnQueryAPIModel("timeOfDay"); }
    @computed("queryInfo.jsonData") get topics() { return this.loadOwnQueryAPIModel("topics"); }
    @computed("queryInfo.jsonData") get volume() { return this.loadOwnQueryAPIModel("volume"); }
    @computed("queryInfo.jsonData") get postClusterNodes() { return this.loadOwnQueryAPIModel("postClusterNodes"); }
}
