import Ember from 'ember';
import DateFilters from "infegy-frontend/static_data/query/date_filters";
import AtlasConfig from "infegy-frontend/config/infegy-app-config";

export default Ember.Component.extend({
    classNames: ["even-columns-4"],

    date: null,
    allowFutureDates: false,

    earliestTimestamp: AtlasConfig.EarliestAtlasTimestamp,
    latestTimestamp: null, // if no latest date is set, the current date should be generated as late as possible

    monthOptions: Ember.computed("earliestTimestamp", "latestTimestamp", "date.year", function () {
        var earliestTimestamp = this.earliestTimestamp,
            latestTimestamp = this.latestTimestamp || new Date(),
            year = parseInt(this.get("date.year")),
            unitOptions = [].concat(DateFilters.Months);
        if (earliestTimestamp && latestTimestamp) {
            unitOptions = unitOptions.filter(option => {
                let earlyDate = new Date(year, option.value, 1);
                const lateDate = new Date(year, option.value - 1, 1);
                return earliestTimestamp < earlyDate && latestTimestamp >= lateDate;
            });
        }
        return unitOptions;
    }),

    yearOptions: Ember.computed("earliestTimestamp", "latestTimestamp", function () {
        var earliestTimestamp = new Date(this.earliestTimestamp) || AtlasConfig.EarliestAtlasTimestamp,
            earliestYear = earliestTimestamp && earliestTimestamp.getUTCFullYear(),
            latestTimestamp = this.latestTimestamp ? new Date(this.latestTimestamp) : new Date(),
            latestYear = latestTimestamp && latestTimestamp.getUTCFullYear(),
            years = [];

        for (var year = latestYear; year >= earliestYear; year--) {
            years.push({
                "label": year,
                "value": year,
                "alternates": [year % 100, year % 1000],
                "hideDescription": true
            });
        }

        return years;
    }),

    daysInMonthOptions: Ember.computed("date.year", "date.month", "earliestTimestamp", "latestTimestamp", {
        get: function(key) {
            var earliest = new Date(this.earliestTimestamp),
                latest = new Date(this.latestTimestamp) || new Date(),
                year = this.get("date.year"),
                month = this.get("date.month"),
                days = DateFilters.DaysInMonthOptions(this.get("date.month") + 1, this.get("date.year"));

            if (earliest && latest) {
                if ( (year == earliest.getUTCFullYear() && month == earliest.getUTCMonth()) ||
                     (year == latest.getUTCFullYear() && month == latest.getUTCMonth()) ) {

                    days = days.filter(option => {
                        var date = new Date(year, month, option.value);
                        date = date.valueOf();
                        if (earliest && date <= earliest.valueOf() &&
                                option.value != earliest.getUTCDate()) {
                            return false;
                        }
                        if (latest && date >= latest.valueOf() &&
                                option.value != latest.getUTCDate()) {
                            return false;
                        }
                        return true;
                    });
                }
            }

            return days.map((cv) => {
                cv.sortValue = cv.value;
                return cv;
            });
        },
    }),

    month: Ember.computed("date.month", "date.year", {
        get: function(key) {
            var curMonth = this.date && this.get("date.month") + 1,
                days = DateFilters.DaysInMonth(curMonth, this.get("date.year"));

            if (this.get("date.day") && this.get("date.day") > days) {
                this.set("date.day", days);
            }

            return curMonth;
        },
        set: function(key, value) {
            var curMonth = this.date && this.get("date.month") + 1;
            if (Ember.isEmpty(value)) {
                return;
            }
            value = Math.min(value, 12);
            value = Math.max(value, 1);
            if (value !== curMonth) {
                this.set("date.month", value - 1);
            }
            curMonth = value;

            var days = DateFilters.DaysInMonth(curMonth, this.get("date.year"));
            if (this.get("date.day") && this.get("date.day") > days) {
                this.set("date.day", days);
            }

            return curMonth;
        }
    }),

    futureCheck: Ember.computed("date.month", "date.day", "date.year", {
        get: function(key) {
            var date = this.date,
                selected = date && date.year +'-'+ this.month +'-'+ date.day;

            if(Date.now() < (new Date(selected))){
                return true;
            }
            return false;
        },
    }),

    limitValue: function(earliestDate) {
        var earliestDate = this.earliestTimestamp || AtlasConfig.EarliestAtlasTimestamp,
            latestDate = this.latestTimestamp || (new Date()).valueOf();

        earliestDate = new Date(earliestDate);
        latestDate = new Date(latestDate);

        var year = this.get("date.year") || 0,
            month = this.get("date.month") || 0,
            day = this.get("date.day") || 0;

        if (new Date(year, month, day).valueOf() <  earliestDate.valueOf()) {
            if (year < earliestDate.getUTCFullYear()) {
                this.set("date.year", earliestDate.getUTCFullYear());
            }
            if (month < earliestDate.getUTCMonth()) {
                this.set("date.month", earliestDate.getUTCMonth());
            }
            if (day < earliestDate.getUTCDate()) {
                this.set("date.day", earliestDate.getUTCDate());
            }
            return -1;
        } else if (new Date(year, month, day).valueOf() > latestDate.valueOf()) {
            if (year > latestDate.getUTCFullYear()) {
                this.set("date.year", latestDate.getUTCFullYear());
            }
            if (month > latestDate.getUTCMonth()) {
                this.set("date.month", latestDate.getUTCMonth());
            }
            if (day > latestDate.getUTCDate()) {
                this.set("date.day", latestDate.getUTCDate());
            }
            return true;
        }
        return false;
    },

    dateChanged: () => {},
    actions: {
        valueChanged: function() {
            var date = this.date;
            date = date && this.limitValue();
            this.dateChanged(date);
            return date;
        },
        yearChanged: function(year) {
            this.set("date.year", year);
            this.send("valueChanged");
        },
        monthChanged: function(month) {
            this.set("month", month);
            this.send("valueChanged");
        },
        dayChanged: function(day) {
            this.set("date.day", day);
            this.send("valueChanged");
        },
        updateDate({ date, month, year }) {
            this.setProperties({
                "date.day": date,
                "month": month,
                "date.year": year
            });
            this.send("valueChanged");
        },
        toggleShowCalendar(){
            this.toggleProperty("showCalendar");
        }
    },

});
