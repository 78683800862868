import Ember from 'ember';
import Component from "@ember/component";
import { computed, action } from '@ember/object';
import APIFilter from "infegy-frontend/models/queries/filters/api_filter";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import HelpStrings from "infegy-frontend/static_data/help-strings";

export default class extends Component {
    classNameBindings = [":query-filters"];

    _query = null;
    get query() {
        return this._query;
    }
    set query(value) {
        // The in-app-query-editor reuses the same query editor component when
        // switching between queries.  In this case, the active modifiers needs to
        // be reset to make sure they don't hang around when switching queries.
        this.setProperties({
            dictionariesAdded: false,
            subquerySetsAdded: false,
            analyzeFieldsAdded: false,
            timestampFieldAdded: false
        });
        this.set("_query", value);
    }

    queries = null;

    atlasAuth = AtlasAuth;

    isCompact = false;

    descriptionKey = "";

    dictionariesAdded = false;
    subquerySetsAdded = false;
    analyzeFieldsAdded = false;
    timestampFieldAdded = false;

    @computed.alias("query.queryFilters") queryFilters;
    @computed.oneWay("atlasAuth.user") user;
    @computed.oneWay("user.customDatasets") customDatasets;
    @computed.oneWay("query.queryFilters.activeDataset") activeDataset;
    @computed.oneWay("query.queryFilters.datasetQueryInfo") datasetQueryInfo;
    @computed.oneWay("user.filtersets") filtersets;
    @computed.alias("query.queryFilters.filters") appliedFilters

    @computed("queries.length")
    get hasMultipleQueries() {
        return this.queries?.length > 1;
    }

    @computed("query.queryFilters.datasetQueryInfo", "user.socialQueryInfo")
    get allFilterOptions() {
        let queryFilters = this.query?.queryFilters?.datasetQueryInfo?.filters || [];
        let filters = queryFilters.map(column => {
                let newAPIFilter = Object.assign({}, column);
                return newAPIFilter;
            }).filter(filter => {
                return filter && filter.type !== "timestamp";
            });

        return filters;
    }

    @computed("allFilterOptions", "allFilterOptions.[]")
    get filterSelectorOptions() {
        let filterOptions = this.allFilterOptions || [];
        filterOptions = filterOptions.map(filterOption => {
            return {
                title: filterOption.name,
                value: filterOption.id,
                description: filterOption.description,
                originalOption: filterOption
            };
        }).sortBy("title");

        return filterOptions;
    }

    @computed.or("dictionariesAdded", "queryFilters.dictionaries") dictionariesActive;
    @computed.or("subquerySetsAdded", "queryFilters.subquerySets") subquerySetsActive;
    @computed.or("analyzeFieldsAdded", "queryFilters.analyzeFields") analyzeFieldsActive;

    @computed("timestampFieldAdded", "queryFilters.timestampField")
    get timestampFieldActive() {
        let tsField = this.queryFilters?.timestampField;
        return this.timestampFieldAdded || (tsField && tsField !== "ts-default");
    }

    @computed("queryFilters", "dictionariesActive", "subquerySetsActive", "analyzeFieldsActive",
        "timestampFieldActive", "query.queryFilters.datasetQueryInfo", "user.socialQueryInfo")
    get modifierOptions() {
        let modifierOptions = [],
            filters = this.queryFilters,
            queryInfo = filters?.datasetQueryInfo;

        if (!this.dictionariesActive && queryInfo.available_dictionaries) {
            modifierOptions.push({
                title: "Dictionaries",
                value: "Dictionaries",
                description: HelpStrings.filters["dictionaries"].text
            });
        }
        if (!this.subquerySetsActive && queryInfo.available_query_sets) {
            modifierOptions.push({
                title: "Subquery Sets",
                value: "Subquery Sets",
                description: HelpStrings.filters["subquerySets"].text
            });
        }
        if (!this.analyzeFieldsActive && queryInfo.available_analyze_fields) {
            modifierOptions.push({
                title: "Analysis Fields",
                value: "Analysis Fields",
                description: "Fields that should be included in Infegy's Content Analysis"
            });
        }
        if (!this.timestampFieldActive && queryInfo.available_timestamp_fields) {
            modifierOptions.push({
                title: "Timestamp Field",
                value: "Timestamp Field",
                description: "Field that should be used for the date range selection for this query"
            });
        }

        // available_dictionaries
        // available_query_sets
        // available_analyze_fields
        // default_analyze_fields
        // available_timestamp_fields

        return Ember.isEmpty(modifierOptions) ? null : modifierOptions;
    }

    @computed("datasetQueryInfo", "datasetQueryInfo.available_dictionaries")
    get dictionaryOptions() {
        let opts = this.datasetQueryInfo?.available_dictionaries || [];
        return opts.map(opt => {
            return {label: opt.name, value: opt.id}
        });
    }

    @computed("datasetQueryInfo", "datasetQueryInfo.available_query_sets")
    get subquerySetsOptions() {
        let opts = this.datasetQueryInfo?.available_query_sets || [];
        return opts.map(opt => {
            return {label: opt.name, value: opt.id}
        });
    }

    @computed("datasetQueryInfo", "datasetQueryInfo.available_analyze_fields")
    get analyzeFieldsOptions() {
        let opts = this.datasetQueryInfo?.available_analyze_fields || [];
        return opts.map(opt => {
            return {label: opt.name, value: opt.id}
        });
    }

    @computed("datasetQueryInfo", "datasetQueryInfo.available_timestamp_fields")
    get timestampFieldOptions() {
        let options = [];
        if (this.datasetQueryInfo) {
            let opts = this.datasetQueryInfo.available_timestamp_fields || [];
            options = opts.map(opt => {
                return {label: opt.name, value: opt.id}
            });
            options.unshift({label: "Default", value: "ts-default"});
        }
        return options;
    }

    @computed("query.filters.filters", "allFilterOptions")
    get activeFilters() {
        this.query?.filters?.clearInvalidFilters();
        return this.query?.filters;
    }

    @computed("query.queryFilters.activeFormFilterNames.[]")
    get showDictionaryWarning() {
        const activeFilters = this.query?.queryFilters?.activeFormFilterNames ?? [];
        const linguisticFilters = ["themes","emotions","sentiment"]
        if (activeFilters.includes("dictionaries")) {
            if (activeFilters.find((filter) => linguisticFilters.includes(filter))){
                return true;
            }
        }
        return false;
    }

    @computed("query.filters.filters", "allFilterOptions")
    get activeFilterInfo() {
        var activeFormFilterNames = this.get("query.queryFilters.activeFormFilterNames"),
            allFilterOptions = this.allFilterOptions,
            usedFilters = activeFormFilterNames.filter((name) => {
                return allFilterOptions.includes(name);
            });

        return usedFilters;
    }

    loadFilterset() { /* action */ };
    applyModifierToAll(modifier, value, sourceQuery) { /* action */ }
    applyFilterToAll(filter, sourceQuery) { /* action */ };

    @action
    appendQueryFilter(value) {
        let filters = this.allFilterOptions || [],
            filterBase = filters.findBy("id", value),
            queryFilters = this.query.filters.filters;

        if (filterBase && queryFilters) {
            let filterJSON = (filterBase?.toJSON && filterBase.toJSON()) || Object.assign({}, filterBase || {}),
                newFilter = APIFilter.create();
            delete filterJSON.options;
            newFilter.loadJSON(filterJSON);
            queryFilters.pushObject(newFilter);
        }
    }

    @action
    appendModifier(modifier) {
        if (modifier === "Dictionaries") {
            this.set("dictionariesAdded", true);
        }
        if (modifier === "Subquery Sets") {
            this.set("subquerySetsAdded", true);
        }
        if (modifier === "Analysis Fields") {
            this.set("analyzeFieldsAdded", true);
        }
        if (modifier === "Timestamp Field") {
            this.set("timestampFieldAdded", true);
        }
    }

    @action
    removeModifier(modifier) {
        if (modifier === "Dictionaries") {
            this.set("dictionariesAdded", false);
            this.set("queryFilters.dictionaries", []);
        }
        if (modifier === "Subquery Sets") {
            this.set("subquerySetsAdded", false);
            this.set("queryFilters.subquerySets", []);
        }
        if (modifier === "Analysis Fields") {
            this.set("analyzeFieldsAdded", false);
            this.set("queryFilters.analyzeFields", []);
        }
        if (modifier === "Timestamp Field") {
            this.set("timestampFieldAdded", false);
            this.set("queryFilters.timestampField", "ts-default");
        }
    }

    @action
    removeFilter(filter) {
        let filters = this.query?.filters?.filters;
        if (filters) {
            filters.removeObject(filter);
        }
    }

    @action
    removeAllFilters() {
        this.query?.filters?.filters?.clear();
    }
}
