import Ember from "ember";
import Controller, { inject as controller } from "@ember/controller";
import { action, computed } from "@ember/object";
import { isEmpty } from "@ember/utils";

import Workspace from "infegy-frontend/models/users/workspace2";
import GlobalFilters from "infegy-frontend/models/queries/query_global_filters";

export default class WorkspaceEditorController extends Controller {
    @controller application;
    @computed.alias("application.user") user;
    @computed.alias("user.companyUsers") companyUsers;
    @computed.readOnly("user.permissions.maxQueries") maxQueries;

    @controller("atlas/tools/workspace-management/list") workspaceListController;
    @computed.readOnly("workspaceListController.workspaces") workspaces;
    @computed.readOnly("workspaceListController.shared") sharedWorkspaces;

    workspace = Workspace.create();
    @computed.readOnly("workspace.isShared") isShared;
    @computed.alias("workspace.title") workspaceEditorTitle;
    @computed.readOnly("workspace.queries") queries;

    /*
    Set to true when transitioning to this page by copying an existing
    workspace, otherwise false.
    */
    didTransitionToCopiedWorkspace = false;
    lastSaved = 0;
    wasSaved = false;

    @computed("isExistingWorkspace", "isShared")
    get isWorkspaceSharable() {
        if (this.isShared) return false;
        return !isEmpty(this.workspace.id);
    }

    @computed("workspaceEditorTitle")
    get hasBlankTitle() {
        return !this.workspaceEditorTitle || this.workspaceEditorTitle < 1;
    }

    @computed("queries", "queries.[]", "queries.@each.isBlank")
    get queriesAreBlank() {
        var queries = this.queries.filter((query) => {
            return !query.get("isBlank");
        });
        return !queries.length;
    }

    @computed("wasSaved", "hasBlankTitle", "queriesAreBlank")
    get saveDisabled() {
        return this.wasSaved || this.queriesAreBlank || this.hasBlankTitle;
    }

    @computed("firstQuery", "singleQuery")
    get canRemove() {
        return !this.singleQuery;
    }

    @computed("queries")
    get workspaceType() {
        var isBuilderWorkspace = (this.queries || []).find((query) => {
            return query.get("queryInfo.isUsingQueryBuilder");
        });
        return isBuilderWorkspace ? "builder" : "advanced";
    }

    @computed("user.workspaceFolders.length", "user.workspaceFolders.[]")
    get folderOptions() {
        var folders = (this.user?.workspaceFolders ?? []).toArray();
        folders.unshift({ label: "No Folder", value: 0 });
        return folders;
    }

    workspaceSaveSuccess() {
        this.set("lastSaved", Date.now());
        this.set("wasSaved", true);

        Ember.run.later(this, () => {
            if ((this.lastSaved + 2000) < Date.now()) {
                this.set("wasSaved", false);
            }
        }, 3000);
    }

    @action onAddBlankQuery(queryType) {
        this.workspace.addBlankQuery(queryType);
    }

    @action async onCloneWorkspace() {
        const workspaceCopy = this.workspace.copy();
        workspaceCopy.id = 0;
        await workspaceCopy.save();
        this.workspaces.getPage();
        this.transitionToRoute("atlas.tools.workspace-management.editor", workspaceCopy.id).then((transition) => {
            const controller = transition.controller;
            controller.set("didTransitionToCopiedWorkspace", true);
            controller.workspaceSaveSuccess();
        });
    }

    @action async onUpdateWorkspace() {
        let originalId = this.workspace.id;
        this.workspace.isSaved = true;
        if (!originalId) {
            // create a new workspace
            await this.workspace.save();
        }
        else {
            // update an existing workspace
            await this.workspace.update();
        }
        this.workspaces.getPage();
        if (isEmpty(originalId)) {
            // saving a new workspace
            this.transitionToRoute("atlas.tools.workspace-management.editor", this.workspace.id).then((transition) => {
                transition.controller.workspaceSaveSuccess();
            });
        }
        else {
            // updating an existing workspace
            this.workspaceSaveSuccess();
        }
    }

    @action hideTransitionMessage() {
        this.set("didTransitionToCopiedWorkspace", false);
    }

    @action changedFolder(newFolderId){
        if(this.workspace){
            this.set("workspace.workspaceFolderId", newFolderId);
        }
    }

    @action onCopyQuery(query){
        let newQuery = query.copy();
        newQuery.filters.color.colorString = null;
        newQuery.filters.color.loadDefaultColorForIndex(this.workspace.queries.length-1, this.workspace.queries);
        this.workspace.addQueries([newQuery]);
    }

    @action onClearWorkspace() {
        const newWorkspace = Workspace.create();
        newWorkspace.addBlankQuery();
        this.set("workspace", newWorkspace);
    }

    @action onStartNewWorkspace(queryType) {
        const newWorkspace = Workspace.create();
        newWorkspace.addBlankQuery(queryType);
        this.set("workspace", newWorkspace);
        GlobalFilters.setDefaultSocialDateRange(this.queries, true);
    }
}
