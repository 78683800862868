import Component from "@glimmer/component";
import { action } from "@ember/object";
import { actionArgument, argument } from "infegy-frontend/decorators/arguments";
import { tracked } from "@glimmer/tracking";

export default class SnippetSaver extends Component {
    @argument([]) snippetOptions

    @actionArgument return;
    @actionArgument saveSnippet;
    @actionArgument updateSnippet;

    @tracked updatingSnippet = false;
    @tracked saveSnippetName = "";
    @tracked selectedSnippet = null;

    @action
    onCancel(){
        this.return();
    }

    @action
    onSaveSnippet(){
        if(this.updatingSnippet) {
            const found = this.snippetOptions.find((option) => option.id === this.selectedSnippet);
            this.updateSnippet(found);
        } else {
            this.saveSnippet(this.saveSnippetName);
        }
        this.return();
    }
}