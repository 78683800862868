import Ember from "ember";
import { action, computed } from '@ember/object';

import DataSeries from "infegy-frontend/models/data_series/data_series";
import QueryAPILinguisticsPosts from "infegy-frontend/models/query-api/linguistics_posts";
import SentimentCombinedBase from "infegy-frontend/components/sentiment/combined-base/component";

export default class SentimentDetails extends SentimentCombinedBase {
    selectedSentiment = null;
    user = null;

    @computed("isSelected")
    get showDrilldownPosts() {
        return this.get("isSelected");
    };

    @computed("combinedDataSeries", "hoveredIndex", "selectedIndex")
    get activeValues() {
        let data = this.combinedDataSeries.data,
            interactedIndex = !Ember.isEmpty(this.hoveredIndex) ? this.hoveredIndex : this.selectedIndex,
            result = { passion: 0, sentimentalContent: 0, timestamp: null};

        if (!Ember.isEmpty(interactedIndex) && data.hasOwnProperty(interactedIndex)) {
            let row = data[interactedIndex],
                sentimentalDocuments = Ember.get(row, "sentimentalDocuments"),
                totalDocuments = Ember.get(row, "documents");
            result.passion = Ember.get(row, "passion");
            result.sentimentalContent = sentimentalDocuments / totalDocuments;
            result.timestamp = Ember.get(row, "timestamp");
        } else {
            result.passion = this.combinedDataSeries.stats.passion.average;
            result.sentimentalContent = this.combinedDataSeries.stats.sentimentalDocumentsPercent.average;
        }
        return result;
    };

    @action
    wasSelected(wasSelected) {
        this.set("isSelected", wasSelected);
    };

    @action
    selectedIndexChanged(selectedIndex) {
        this.set("selectedIndex", selectedIndex);
    };

    @action
    hoveredIndexChanged(hoveredIndex) {
        this.set("hoveredIndex", hoveredIndex);
    };

    @action
    sentimentFieldChanged(sentimentField) {
        this.set("sentimentField", sentimentField);
    };
};
