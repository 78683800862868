import Component from '@ember/component';
import { computed } from '@ember/object';
import { action } from '@ember/object';

export default class Slider extends Component {
    attributeBindings = ["style"];

    style = "display: flex".htmlSafe();

    // args
    value = 0;
    minValue=0;
    minLabel="Low"
    maxValue=1;
    maxLabel="High"
    step=1;
    mode="standard";
    onValueChanged=()=>{/* action */}

    // properties
    constructor(){
        super(...arguments);
    }

    classNameBindings=[":slider-input-wrapper", "modeClass"];
    sliderEl = null;

    @computed("mode")
    get modeClass(){
        return `mode-${this.mode}`
    }

    @computed("value")
    get innerValue(){
        return this.value;
    }

    @action
    handleChange(){
        this.onValueChanged(parseFloat(this.sliderEl.value));
    }
}