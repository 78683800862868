import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import fieldPortion from "infegy-frontend/models/data_series/data_series_computed_field_portion";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";

export default QueryAPIResults.extend({
    title : "timeOfDay",
    dateField: null,
    preload:function(){
        var data = this.get("rawResults"),
            totalUniverse = this.get("rawResponseRow.query_meta.total_universe"),
            totalCount = this.get("rawResponseRow.query_meta.total_results");
        var totalSum = data.reduce((memo, row) => {memo += row.count; return memo;}, 0);
        data.forEach((row)=>{
            row.portionOfTotal = row.count / totalSum;
            row.posts = totalCount * row.portionOfTotal;
            row.universe = totalUniverse * row.portionOfTotal;
        });
    },
    description: DataSeriesDescription.load({
        "hour": "number",
        "count": "number",

        // From PreLoad
        "posts": "number",
        "universe": "number",
        "portionOfTotal": "portion",

    }, [
        fieldPortion.fieldSetup("countPortion", "count")
    ])
});
