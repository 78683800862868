import Ember from "ember";
export default Ember.Component.extend({
    classNameBindings: ["loaderClass"],

    // Available Sizes: small, medium, large, huge
    size: "large",

    loaderClass: Ember.computed("size", {
        get: function(key) {
            var size = this.size || "small";
            return "infegy-bulb-loader-" + size;
        },
    }),
});
