/* jshint bitwise: false */
import Ember from "ember";
import NumberStats from "infegy-frontend/models/data_series/number_stats";
import DataSeriesIndexController from "infegy-frontend/models/data_series/data_series_index_controller";
import DateProcessing from "infegy-frontend/utils/date-processing";
// import DataSeriesComputedFieldPercentage from "infegy-frontend/models/data_series/data_series_computed_field_percentage";
// import DataSeriesComputedFieldDifference from "infegy-frontend/models/data_series/data_series_computed_field_difference";
// import DataSeriesComputedFieldDistribution from "infegy-frontend/models/data_series/data_series_computed_field_distribution";

var _counter = 0;
var DataSeries = Ember.Object.extend({
    counter: 0,
    data: null,
    stats: null,
    statsBuilt: false,

    checkIfEmpty: function(dataSeries) {
        return !dataSeries || !dataSeries.get("data") || !dataSeries.get("data").length;
    },
    isEmpty: Ember.computed("data", "data.[]", "stats", "statsBuilt", "checkIfEmpty", {
        get: function(key) {
            return this.checkIfEmpty(this);
        },
    }),

    description: null,
    availableStats: null,
    indexes: null,

    init: function() {
        this._super();
        this.set("counter", _counter++);
        this.set("availableStats", []);
        this.set("indexes", DataSeriesIndexController.create({
            "_dataSeries": this
        }));
    },
    hasData: Ember.computed("data", {
        get: function(key) {
            var data = this.data;
            return Ember.isArray(data) && !Ember.isEmpty(data);
        },
    }),
    buildComputed: function(_data, _description) {
        var data = _data || this.data || [],
            description = _description || this.description || _description,
            computedFields = description && description.get("computedFields") || [];

        if (!computedFields || !computedFields.length || !data) {
            return;
        }

        var computedFieldsLen = computedFields.length,
            row, fieldIdx, computedField;

        for (fieldIdx = 0; fieldIdx < computedFieldsLen; fieldIdx++) {
            let preComputeResult = null,
                dataLen = data.length;
            computedField = computedFields[fieldIdx];
            if (computedField.preCompute) {
                preComputeResult = computedField.preCompute(data);
            }
            while (dataLen--) {
                row = data[dataLen];
                if (computedField.rowCompute) {
                    computedField.rowCompute(row, preComputeResult);
                }
            }
        }
    },
    buildComputedStats: function() {
        var data = this.data || [],
            stats = this.stats,
            description = this.description,
            computedFields = description && description.get("computedFields") || [];

        if (!computedFields || !computedFields.length || !stats) {
            return;
        }

        var computedFieldsLen = computedFields.length,
            fieldIdx, computedField;

        for (fieldIdx = 0; fieldIdx < computedFieldsLen; fieldIdx++) {
            computedField = computedFields[fieldIdx];
            if (computedField.statsCompute) {
                computedField.statsCompute(stats, data);
            }
        }
    },
    buildStats: function() {
        var data = this.data,
            description = this.description,
            statFields = description && description.get("statFields") || [],
            fields = description && description.get("fields"),
            statName,
            statIdx = statFields.length,
            stats = {},
            halfCount = data && (data.length / 2) | 0;

        this.set("statsBuilt", false);
        if (!description || !data || !data.length) {
            return;
        }
        Ember.beginPropertyChanges();

        while (statIdx--) {
            statName = statFields[statIdx];
            stats[statName] = {
                "field": statName,
                "type": fields[statName],

                "emptyRows": 0,

                "halfCount": halfCount,
                "sum": 0,
                "max": -Infinity,
                "min": Infinity,
                "maxIndex": 0,
                "minIndex": 0,
                "count": data.length,
                "firstHalfSum": 0,
                "secondHalfSum": 0,
            };
        }
        var row, value, statObj = {},
            isFirstHalf,
            dataIdx = data.length;

        var fhCount = 0,
            shCount = 0;

        while (dataIdx--) {
            row = data[dataIdx];
            statIdx = statFields.length;
            isFirstHalf = dataIdx < halfCount;
            if (isFirstHalf) {
                fhCount++;
            } else {
                shCount++;
            }
            while (statIdx--) {
                statName = statFields[statIdx];
                statObj = stats[statName];

                if (row[statName] === null || row[statName] === undefined) {
                    statObj.emptyRows++;
                    continue;
                }

                value = +row[statName] || 0;
                statObj.sum += value;

                if (value > statObj.max) {
                    statObj.max = value;
                    statObj.maxIndex = dataIdx;
                }
                if (value < statObj.min) {
                    statObj.min = value;
                    statObj.minIndex = dataIdx;
                }

                if (isFirstHalf) {
                    statObj.firstHalfSum += value;
                } else {
                    statObj.secondHalfSum += value;
                }
            }
        }

        var finalStats = {};

        statIdx = statFields.length;
        while (statIdx--) {
            statName = statFields[statIdx];
            statObj = stats[statName];
            if (statObj.emptyRows === statObj.count) {
                continue;
            }

            statObj.average = statObj.sum / statObj.count;

            var shPctAvg = (statObj.secondHalfSum * 1.0) / (shCount * 1.0),
                fhPctAvg = (statObj.firstHalfSum * 1.0) / (fhCount * 1.0);

            statObj.percentChange = ((shPctAvg - fhPctAvg) / fhPctAvg) * 100.0;

            finalStats[statName] = NumberStats.create(statObj);
            this.availableStats.push(statName);
        }

        this.set("stats", Ember.Object.create(finalStats));

        this.buildComputedStats();
        this.set("statsBuilt", true);
        Ember.endPropertyChanges();
    },
    closestHigherIndex: function(field, value) {
        return this.closestIndex(field, value, {
            greaterThan: true
        });
    },
    closestLowerIndex: function(field, value) {
        return this.closestIndex(field, value, {
            lessThan: true
        });
    },
    closestIndex: function(field, referenceValue, options) {
        var data = this.data || [],
            value = parseFloat(referenceValue) || 0,
            greaterThan = options && options.greaterThan,
            lessThan = options && options.lessThan;

        var closest = Infinity,
            closestIdx = null;

        data.forEach(function(row, index) {
            var rowVal = row[field] || 0;
            if ((greaterThan && rowVal < value) ||
                (lessThan && rowVal > value)) {
                return;
            }
            if (Math.abs(rowVal - value) < closest) {
                closestIdx = index;
                closest = Math.abs(rowVal - value);
            }
        });

        return closestIdx;
    },
    filterByRange: function(field, min, max, filterOptions) {
        var options = Object.assign({
            leaveDates: false,
            skipStats: false
        }, filterOptions || {});

        if (min > max) {
            var temp = max;
            max = min;
            min = temp;
        }

        var val;
        return this.filter(function(row) {
            val = row[field];
            if (val !== null && val !== undefined) {
                return val >= min && val <= max;
            }
        }, options);
    },
    filterByList: function(filterDefs, filterOptions) {
        // filter def example :
        // [
        //  {type: range, args: ["timestamp", 12354, 3456]}
        //  {type: greaterThan, args: ["timestamp", 12354]}
        // ]
        var options = Object.assign({
            leaveDates: false,
            skipStats: false
        }, filterOptions || {});

        filterDefs = filterDefs || [];
        var dataSeries = this;
        filterDefs.forEach(function(filterDef, index) {
            var filterName = ["filterBy", filterDef.type].join("_").camelize();
            if (dataSeries[filterName]) {
                var opts = {
                    leaveDates: options.leaveDates,
                    skipStats: true
                };
                var args = filterDef.args.concat([opts]);
                dataSeries = dataSeries[filterName].apply(dataSeries, args);
            }
        }, this);

        if (Ember.isNone(dataSeries)) {
            return this;
        }

        if (!options.skipStats) {
            dataSeries.buildComputed();
            dataSeries.buildStats();
        }
        return dataSeries;
    },
    filteredData: function(callback, filterOptions) {
        var options = Object.assign({
            data: null,
            leaveDates: false
        }, filterOptions || {});

        var filteredData = options.data;
        if (Ember.isNone(filteredData)) {
            filteredData = this.data;
        }
        if (options.leaveDates) {
            filteredData = filteredData.map(function(row) {
                return (callback(row)) ? row : DateProcessing.copyDatesFromObject(row);
            });
        } else {
            filteredData = this.data.filter(callback);
        }
        return filteredData;
    },
    filter: function(callback, filterOptions) {
        var options = Object.assign({
            data: null,
            leaveDates: false,
            skipStats: false
        }, filterOptions || {});
        var filteredData = this.filteredData(callback, options);
        return DataSeries.load(filteredData, this.description, options.skipStats);
    },
    flattened: Ember.computed("data", {
        get: function(key) {
            return this.groupBy(null);
        },
    }),
    groupBy: function(field, groupingOptions) {
        var options = Object.assign({
            groupKey: field,
            sortBy: null,
            sortDescending: false,
            skipStats: false,
            valuesStart: null,
            valuesEnd: null,
            requiredValues: null
        }, groupingOptions || {});
        return this.groupByIndex(this.indexes.get(field), options);
    },
    filterAndGroup: function(groupingOptions) {
        // filter def example :
        // [{type: range, args: ["timestamp", 12354, 3456]}, ...]
        var options = Object.assign({
            groupKey: null,
            filters: [],
            leaveDates: true,
            sortBy: null,
            sortDescending: false,
            skipStats: false,
            valuesStart: null,
            valuesEnd: null,
            requiredValues: null
        }, groupingOptions || {});
        var newDataSeries = this.filterByList(options.filters, options);
        if (options.groupKey) {
            newDataSeries = newDataSeries.groupBy(options.groupKey, options);
        }
        return newDataSeries;
    },
    groupByIndex: function(index, groupingOptions) {
        var options = Object.assign({
            sortBy: null,
            sortDescending: false,
            skipStats: false,
            data: this.data || [],
            valuesStart: null,
            valuesEnd: null,
            requiredValues: null,
            maintainSources: true
        }, groupingOptions || {});

        if (!index) {
            return;
        }

        var data = options.data,
            requiredValues = options.requiredValues,
            field = index.get("field"),
            values = null;

        if (data === null || data === undefined) {
            data = this.data || [];
        }

        if (options.sortBy && options.sortBy === field) {
            values = (options.sortDescending) ? index.get("valuesDesc") : index.get("valuesAsc");
            options.sortBy = null;
        } else {
            values = index.get("values") || [];
        }

        var lookup = index.get("lookup") || {},
            indexedData = index.get("indexedData"),
            fields = this.get("description.fields"),
            results = [],
            lookupArr, lookupArrLen,
            statPath,
            destRow, sourceRow, type,
            maintainSources = options.maintainSources;

        if (options.valuesStart && options.valuesEnd) {
            requiredValues = [];
            for (var val = options.valuesStart; val <= options.valuesEnd; val++) {
                requiredValues[requiredValues.length] = val;
            }
        }

        if (requiredValues) {
            var isDateField = DateProcessing.isConvertableDateFieldValue(field);
            requiredValues.forEach(function(val) {
                if (!lookup[val]) {
                    var newRow = {};
                    if (isDateField) {
                        newRow = DateProcessing.dateFieldValueToDateObject(field, val);
                    } else {
                        newRow[field] = val;
                    }
                    var valIdx = values.length;
                    values[valIdx] = val;
                    indexedData[val] = [newRow];
                }
            });
            if (options.sortDescending) {
                values = values.sort(function(b, a) {
                    return a - b;
                });
            } else {
                values = values.sort(function(a, b) {
                    return a - b;
                });
            }
        }

        fields = Object.assign({
            "_count": "number",
            "_sources": "array"
        }, fields);

        for (var valIdx = 0, valLen = values.length; valIdx < valLen; valIdx++) {
            lookupArr = indexedData[values[valIdx]] || [];
            lookupArrLen = lookupArr.length;
            results[results.length] = destRow = {
                "_count": lookupArrLen,
                "_sources": maintainSources ? lookupArr : null
            };
            if (lookupArr.length === 1) {
                Object.assign(destRow, lookupArr[0]);
                lookupArrLen = 0;
            } else {
                while (lookupArrLen--) {
                    sourceRow = lookupArr[lookupArrLen];

                    for (statPath in sourceRow) {
                        if (sourceRow.hasOwnProperty(statPath)) {
                            type = fields[statPath];
                            if (statPath === "_sources" || statPath === "_count") {
                                continue;
                            } else if (type === "number") {
                                destRow[statPath] = sourceRow[statPath] + (destRow[statPath] || 0.0);
                            } else if (type === "percent" || type === "average") {
                                destRow[statPath] = sourceRow[statPath] + (destRow[statPath] || 0.0);
                                if (!lookupArrLen) {
                                    destRow[statPath] = destRow[statPath] / destRow._count;
                                }
                            } else if (type === "timestamp" || type === "index") {
                                if (!destRow[statPath] || destRow[statPath] > sourceRow[statPath]) {
                                    destRow[statPath] = sourceRow[statPath];
                                }
                            } else if (type === "array") {
                                if (sourceRow[statPath]) {
                                    destRow[statPath] = (destRow[statPath] || []).concat(sourceRow[statPath]).compact();
                                }
                            } else if (!destRow[statPath]) {
                                destRow[statPath] = sourceRow[statPath];
                            }
                        }
                    }
                }
            }
        }
        return DataSeries.load(results, this.description, options.skipStats);
    },
    groupByWithRangeFilters: function(options) {
        options = Object.assign({
            groupKey: null,
            sortBy: null,
            sortDescending: false,
            skipStats: false,
            filters: [],
            maintainSources: true,
            removeEmptyGroupedRows: false
        }, options || {});

        var index = this.indexes.get(options.groupKey);
        if (!index) {
            return;
        }

        var rangeFilters = (options.filters || []).filter(function(filterRow) {
            return filterRow && ((filterRow.type === "range") || !Ember.isEmpty(filterRow.rangeKey));
        }).map(function(filterRow) {
            if (!Ember.isEmpty(filterRow.rangeKey)) {
                return filterRow;
            }
            var args = filterRow.args;
            return {
                key: args[0],
                min: args[1],
                max: args[2]
            };
        });

        var filterIdx, filter, filterVal, isFiltered,
            field = index.get("field"),
            values = null,
            indexedData = index.get("indexedData"),
            description = this.description,
            results = [],
            lookupArr, lookupArrLen,
            statPath,
            destRow, sourceRow;

        var numberTypeFields = description.get("numberTypeFields"),
            averagedTypeFields = description.get("averagedTypeFields"),
            indexTypeFields = description.get("indexTypeFields"),
            arrayTypeFields = description.get("arrayTypeFields");

        values = index.get("values");

        var valIdx = values.length;
        while (valIdx--) {
            lookupArr = indexedData[values[valIdx]] || [];
            lookupArrLen = lookupArr.length;
            destRow = {
                "_count": lookupArrLen
            };
            while (lookupArrLen--) {
                sourceRow = lookupArr[lookupArrLen];
                if (!destRow.timestamp || sourceRow.timestamp < destRow.timestamp) {
                    DateProcessing.copyDatesIntoObject(sourceRow, destRow);
                }
                filterIdx = rangeFilters.length;
                isFiltered = false;
                while (filterIdx--) {
                    filter = rangeFilters[filterIdx];
                    filterVal = sourceRow[filter.key];
                    if (filterVal === undefined || filterVal < filter.min || filterVal > filter.max) {
                        isFiltered = true;
                        destRow._count--;
                        break;
                    }
                }
                if (isFiltered) {
                    continue;
                }
                for (statPath in sourceRow) {
                    if (sourceRow.hasOwnProperty(statPath)) {
                        if (!isFiltered) {
                            if (numberTypeFields[statPath]) {
                                destRow[statPath] = (sourceRow[statPath] || 0) + (destRow[statPath] || 0);
                            } else if (indexTypeFields[statPath] && destRow[statPath] > sourceRow[statPath]) {
                                destRow[statPath] = sourceRow[statPath] | 0;
                            } else if (arrayTypeFields[statPath]) {
                                destRow[statPath] = (destRow[statPath] || []).concat(sourceRow[statPath] || []);
                            }
                        }
                    }
                }
            }
            for (statPath in destRow) {
                if (destRow.hasOwnProperty(statPath)) {
                    if (averagedTypeFields[statPath]) {
                        destRow[statPath] /= destRow._count;
                    }
                }
            }
            if (!options.removeEmptyGroupedRows || destRow._count) {
                results[results.length] = destRow;
            }
        }
        if (options.sortBy) {
            field = options.sortBy;
            if(options.sortDescending){
                results.sort((a,b) => { return b[field] - a[field]; });
            } else {
                results.sort((a,b) => { return a[field] - b[field]; });
            }
        }
        return DataSeries.load(results, this.description, options.skipStats);
    },
    statsGroups: function(statsFieldPrefixes, statsFieldSuffixes) {
        var fieldPrefixes = statsFieldPrefixes || [];
        var fieldSuffixes = statsFieldSuffixes || [];
        var stats = this.stats;

        if (!stats || !fieldPrefixes || !fieldSuffixes) {
            return;
        }

        var statsGroups = fieldPrefixes.map(function(statsFieldPrefix) {
            var fieldPrefix = statsFieldPrefix.camelize();
            var missingData = false;
            var statsGroup = fieldSuffixes.reduce(function(memo, fieldSuffixRow) {
                var fieldSuffix = fieldSuffixRow.camelize();
                var fieldStats = stats.get([fieldPrefix, fieldSuffix].join("_").camelize());

                if (fieldStats) {
                    memo.set(fieldSuffix, fieldStats);
                } else {
                    missingData = true;
                }
                return memo;
            }, Ember.Object.create({}));

            if (missingData) {
                return false;
            }

            statsGroup.set("name", fieldPrefix);
            return statsGroup;
        }).filter(function(statsGroup) {
            return !!statsGroup;
        });
        return statsGroups;
    }
});


DataSeries.reopenClass({
    load: function(data, description, skipStats) {
        Ember.beginPropertyChanges();
        var new_data_series = DataSeries.create({
            data: data,
            description: description
        });
        if (!skipStats) {
            new_data_series.buildComputed();
            new_data_series.buildStats();
        }

        Ember.endPropertyChanges();
        return new_data_series;
    },
    stackTimeseries: function(dataSeriesList, groupByField = "_daysSinceEpoch", groupingOptions = {sortBy: "_daysSinceEpoch"}) {
        var dataSeriesArray = dataSeriesList || [];

        var catted = [],
            description;

        if (Ember.get(dataSeriesArray, "length") === 1 && dataSeriesArray[0]) {
            return dataSeriesArray[0].groupBy(groupByField, groupingOptions);
        } else {
            dataSeriesArray.forEach(function(dataSeries) {
                if (dataSeries && dataSeries.get("data") && dataSeries.get("description")) {
                    description = dataSeries.get("description");
                    catted = catted.concat(dataSeries.get("data") || []);
                }
            });
        }
        if (catted && description) {
            return DataSeries.load(catted, description, true).groupBy(groupByField, groupingOptions);
        }
    },
    stackBy: function(dataSeriesArray, groupKey, description) {
        var catted = dataSeriesArray.mapBy("data").map(function(data, index) {
            return (data || []).map(function(row) {
                return Object.assign({
                    "dataSeriesIndex": [index]
                }, row);
            });
        });

        catted = Array.prototype.concat.apply([], catted);

        if (!description) {
            var first = dataSeriesArray.objectAt(0);
            description = first && first.get("description");
        }

        if (catted && description) {
            description.addFields({
                "dataSeriesIndex": "array"
            });
            var newDataSeries = DataSeries.load(catted, description, true).groupBy(groupKey, {
                "sortBy": groupKey
            });
            return newDataSeries;
        }
    },

});

export default DataSeries;
