import Ember from "ember";
import Component from '@ember/component';
import { computed } from '@ember/object';
import FieldSelectorBase from "infegy-frontend/components/field-selector/parts/fields/trendable-field-base/component";
import layout from "infegy-frontend/components/field-selector/parts/fields/trendable-selector-layout/template";

export default class FieldSelectorPosts extends FieldSelectorBase {
    layout = layout;
    apiName = "ratings";

    @computed("fieldType", "selectedSegment", "selectedSubsegment", "isLoaded")
    get allAvailableFields() {
        return {
            "Rating" : [this.makeFieldDefinition({
                fieldName: "ratingAverage",
                title: "Rating",
                fixedPortionWhole: 5,
                isRangelessPercentage: true,
                axisGroup: "datasetRating"
            })],
            "NPS" : [this.makeFieldDefinition({
                fieldName: "npsAverage",
                title: "NPS",
                fixedPortionWhole: 10,
                isRangelessPercentage: true,
                axisGroup: "datasetNPS"
            })]
        };
    };

};
