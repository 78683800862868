import Component from '@ember/component';
import {computed, action} from "@ember/object";
import ChartColors from "infegy-frontend/static_data/fixtures/chart_colors";
import classic from "ember-classic-decorator";

@classic
export default class extends Component {
    tagName = "tr";
    classNameBindings = [":data-table-row", ":ages-table-expandable"];

    age = null;
    ageSelected = () => {};

    @computed("age")
    get changePercentage() {
        let pct = (this.get("age.documentsGrowthPct") || 0) * 100;
        return pct.toFixed(0);
    }

    @computed("age")
    get vsExpectedPercentage() {
        let pct = (this.get("age.vsExpected") || 0) * 100;
        return pct.toFixed(0);
    }

    @computed("age")
    get distributionPercentage() {
        let pct = (this.get("age.probability") || 0) * 100;
        return pct.toFixed(1);
    }

    @computed("distributionPercentage")
    get distributionBarStyle() {
        let pct = this.distributionPercentage || 0;
        return `width: ${pct}%;`.htmlSafe();
    }

    @computed("age")
    get sentimentPercentage() {
        let pct = (this.get("age.positivity") || 0) * 100;
        return pct.toFixed(0);
    }

    @computed("sentimentPercentage")
    get sentimentBarStyle() {
        let pct = this.sentimentPercentage || 0;
        return `width: ${pct}%;`.htmlSafe();
    }

    @computed("age")
    get sentimentColorStyle() {
        let sentiment = this.get("age.positivity") || 0.5,
            colorValues = ChartColors.sentimentZeroToOneGradientD3();
        return `color: ${colorValues(sentiment)};`.htmlSafe();
    }

    click() {
        this.send("selectingAge");
    }

    @action
    selectingAge() {
        if (this.ageSelected) {
            this.ageSelected(this.age);
        }
    }
}
