import Ember from "ember";
import Query from "infegy-frontend/models/users/query";
import QueryFilters from "infegy-frontend/models/queries/query_filters";
import QueryArray from "infegy-frontend/models/queries/query_array";
import classic from "ember-classic-decorator";

@classic
export default class SingleRequestQuery extends Query {
    static createFromQuery(query) {
        var reqQuery = SingleRequestQuery.create();
        if (query) {
            reqQuery.set("filters", query.get("filters").copy());
        }
        return reqQuery;
    }

    static createFromFilters(queryFilters) {
        var reqQuery = SingleRequestQuery.create(),
            queryFiltersNew = queryFilters;
        if (queryFilters) {
            if (Ember.typeOf(queryFilters) === "instance" && queryFilters.copy) {
                queryFiltersNew = queryFilters.copy();
            } else if (Ember.typeOf(queryFilters) === "object") {
                queryFiltersNew = QueryFilters.create();
                queryFiltersNew.loadJSON(queryFilters);
            }
        } else {
            queryFiltersNew = QueryFilters.create();
        }
        reqQuery.set("queryFilters", queryFiltersNew);
        return reqQuery;
    }

    copy() {
        return SingleRequestQuery.createFromQuery(this, this.queryInfo);
    }
}
