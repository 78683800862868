import { titleCase } from "infegy-frontend/helpers/title-case";
var Themes = {
  universalThemes: [
        "availability",
        "availability|selection",
        "availability|stock",
        "convenience",
        "convenience|delivery",
        "convenience|location",
        "convenience|transaction",
        "expectation",
        "expectation|experience",
        "expectation|fit",
        "intent",
        "intent|churn",
        "intent|loyalty",
        "intent|purchase",
        "place",
        "place|access",
        "place|ambiance",
        "place|cleanliness",
        "place|comfort",
        "place|restrooms",
        "promotion",
        "promotion|ads",
        "promotion|cost",
        "promotion|discounts",
        "quality",
        "quality|appearance",
        "quality|craftmanship",
        "quality|durability",
        "quality|taste",
        "quality|temperature",
        "risk",
        "risk|criminal",
        "risk|illness",
        "risk|injury",
        "service",
        "service|friendliness",
        "service|knowledge",
        "service|leadership",
        "service|satisfaction",
        "service|speed",
        "service|wait times",
    ],
    subfields: [
        "positiveAppearances",
        "appearances",
        "documents",
        "negativeAppearances"
    ]
};

Themes.themeOptions = Themes.universalThemes.map((theme) => {
    return {
        label: titleCase(theme),
        value: theme
    }
});

export default Themes;
