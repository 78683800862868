import Ember from "ember";
import Resolver from "ember-resolver";
import loadInitializers from "ember-load-initializers";
import config from "infegy-frontend/config/environment";
import * as Sentry from "@sentry/browser";
import { Ember as EmberIntegration } from "@sentry/integrations";

/* Analytics setup, property ID set by environment in config/environment.js */
/* eslint-disable */
var _gaq = _gaq || [];
_gaq.push(['_setAccount', config.APP.analytics_id]);
(function() {
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
 (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
 m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
 })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
    ga('create', config.APP.analytics_id, 'auto');
    ga('require', 'displayfeatures');
})();
/* eslint-enable */


export default class App extends Ember.Application {
    modulePrefix = config.modulePrefix;
    podModulePrefix = config.podModulePrefix;
    Resolver = Resolver;
}

Sentry.init({
    dsn: config.sentry.dsn,
    release: config.sentry.release,
    environment: config.sentry.environment,
    enabled: config.sentry.enabled,
    debug: config.sentry.debug,
    integrations: [new EmberIntegration()],
    ignoreErrors: ["TransitionAborted"]
});

loadInitializers(App, config.modulePrefix);
