import Component from "@ember/component";
import { computed } from '@ember/object';
import classic from "ember-classic-decorator";

@classic
export default class extends Component{
    classNames = ["content-custom-data"];
    post = null;
    columns = null;

    @computed('post', 'columns')
    get postData() {
        let columns = this.columns,
            fields = this.post.fields,
            data = [];

        columns.forEach(column => {
            if(column.id.includes('-')) {
                data.pushObject({
                    id: column.id,
                    name: column.name.replaceAll('_', ' ').capitalize(),
                    data: ''
                });
            }
        });

        data.forEach(item => {
            for (const field in fields) {
                if(field.includes('-')) {
                    if(item.id === field) {
                        item.data = fields[field];
                    }
                }
            }
        });

        return data;
    }
}
