import Ember from "ember";
import TrendsSegments from "infegy-frontend/static_data/trends-segments";

export default Ember.Component.extend({
    tagName: "span",
    classNames: ["trends-custom-field-selector"],

    query: Ember.computed.alias("queries.firstObject"),
    queries: null,

    fieldIndex: null,

    field: null,
    canRemove: false,

    colorClass: Ember.computed.oneWay("field.colorClass"),
    segment: Ember.computed.oneWay("field.segment"),
    subSegment: Ember.computed.oneWay("field.subSegment"),
    fieldQuery: Ember.computed.oneWay("field.query"),

    loading: true,

    segmentDefinition: TrendsSegments.all,

    selectedQueryIndexValue: Ember.computed("field", "field.queryIndex", {
        get: function(key) {
            return "query" + this.get("field.queryIndex") || 0;
        }
    }),

    selectedQuery: Ember.computed("query", "queries", "queries.[]", "field", "field.queryIndex", {
        get: function(key) {
            return this.getQueryAt(this.get("field.queryIndex") || 0);
        }
    }),

    availableQueryOptions: Ember.computed("query", "queries", "field", "field.queryIndex", {
        get: function(key) {
            var queries = this.queries;
            if (queries) {
                return queries.map((query, index) => {
                    return {
                        title: query.get("title"),
                        value: "query" + index
                    };
                });
            }
        }
    }),

    hasMultipleQueryOptions: Ember.computed.gt("availableQueryOptions.length", 1),

    segmentOptions: Ember.computed("segmentDefinition", {
        get: function(key) {
            return this.segmentDefinition.segmentOptions || [];
        }
    }),

    subSegmentOptions: Ember.computed("field.segment", "segmentDefinition", {
        get: function(key) {
            var segment = this.get("field.segment"),
                segmentDefinition = this.segmentDefinition,
                subSegmentOptionTypes = segmentDefinition && segmentDefinition.subSegmentOptions,
                subSegmentOptions = (subSegmentOptionTypes && subSegmentOptionTypes[segment]) || [],
                defaultFieldValues = TrendsSegments.defaultFieldValues;
            if (segment && subSegmentOptions) {
                subSegmentOptions = subSegmentOptions.map(option => defaultFieldValues[option]);
                return subSegmentOptions;
            }
        }
    }),

    hasMultipleSubSegmentOptions: Ember.computed.gt("subSegmentOptions.length", 1),

    fieldOptionsReady: Ember.computed("selectedQuery", "field.segment", "field.subSegment", {
        get: function(key) {
            return this.selectedQuery && this.get("field.segment") && this.get("field.subSegment");
        }
    }),

    getQueryAt: function(queryIndex) {
        var query = this.query,
            queries = this.queries;
        if (queries && queryIndex < Ember.get(queries, "length")) {
            return queries.objectAt(queryIndex);
        } else if (query) {
            return query;
        }
    },

    removeField: () => {},
    fieldChanged: () => {},
    
    actions: {
        removeField: function () {
            this.removeField(this.field);
        },
        queryIndexChanged: function (queryIndexValue) {
            var queryIndex = (queryIndexValue || "query0").replace(/[^0-9]+/gi, "");
            queryIndex = parseInt(queryIndex || "0") || 0;

            var query = this.getQueryAt(queryIndex);
            if (this.get("field.queryIndex") !== queryIndex || this.get("field.query") !== query) {
                this.set("field.queryIndex", queryIndex);
                this.set("field.query", this.getQueryAt(queryIndex));
                this.fieldChanged(null);
            }
        },
        colorChanged: function(colorClass) {
            if (colorClass !== this.get("field.colorClass")) {
                this.set("field.colorClass", colorClass);
                this.fieldChanged(null);
            }
        },
        segmentChanged: function(segment) {
            if (segment) {
                Ember.run.schedule("afterRender", () => {
                    this.set("field.segment", segment);
                });
            }
        },
        subSegmentChanged: function(subSegment) {
            if (subSegment) {
                Ember.run.schedule("afterRender", () => {
                    this.set("field.subSegment", subSegment);
                });
            }
        },
        fieldChanged: function(newField) {
            var field = this.field,
                colorClass = this.get("field.colorClass"),
                segment = this.get("field.segment"),
                subSegment = this.get("field.subSegment"),
                queryIndex = this.get("field.queryIndex") || 0,
                newFieldJSON = newField && newField.toJSON(),
                changed = false;

            if (newField && field && (newField.get("apiName") !== field.get("apiName") ||
                    newField.get("fieldName") !== field.get("fieldName") ||
                    newField.get("value") !== field.get("value"))) {
                delete newFieldJSON.query;
                delete newFieldJSON.queries;
                field.clear();

                Ember.run.schedule("afterRender", () => {
                    field.loadJSON(Object.assign(newFieldJSON, {
                        colorClass: colorClass,
                        queryIndex: queryIndex,
                        segment: segment,
                        subSegment: subSegment,
                    }));
                });

                changed = true;
            }

            if (!field.get("query")) {
                field.set("query", this.getQueryAt(queryIndex));
                changed = true;
            }

            if (changed) {
                this.fieldChanged(field);
            }
        }
    }
});
