import Component from "@ember/component";
import { action, computed } from "@ember/object";
import { isNone } from "@ember/utils";;

import BrowserStore from "infegy-frontend/utils/browser-store";
import QualtricsSurveys from "infegy-frontend/models/qualtrics/surveys";

export default class QualtricsQuestionSelector extends Component {
    @BrowserStore.Attr("qualtricsToken", { defaultValue: "" }) qualtricsToken;

    qualtricsSurveyId = 0;
    qualtricsQuestionId = 0;
    surveys = QualtricsSurveys.create();

    @computed("surveys.hasLoaded", "surveys.length", "surveys.isLoading")
    get noSurveysLoaded() {
        return this.surveys.hasLoaded && !this.surveys.isLoading && this.surveys.length == 0;
    }

    @computed.alias("surveys.isLoading") isLoadingSurveys;

    @computed.gt("surveys.length", 0) hasSurveys;

    @computed("surveys", "surveys.length")
    get surveyOptions() {
        return (this.surveys ?? []).map((survey) => {
            return {
                label: survey.name,
                value: survey.id
            };
        });
    }

    @computed("surveys", "surveys.length", "qualtricsSurveyId")
    get selectedSurvey() {
        const survey = this.surveys.findBy("id", this.qualtricsSurveyId);
        if (!isNone(survey)) {
            survey.loadTextEntryQuestions(this.qualtricsToken);
        }
        return survey;
    }

    @computed.alias("selectedSurvey.questions") questions;

    @computed("questions", "questions.length")
    get questionOptions() {
        return (this.questions ?? []).map((question) => {
            return {
                label: `${question.name} - ${question.text}`,
                value: question.id
            };
        });
    }

    @action
    onGetSurveys() { 
        this.surveys.loadSurveyInfos(this.qualtricsToken);
    }

    @action
    async onSurveyOptionChange(surveyId) {
        if (this.qualtricsSurveyId !== surveyId) {
            this.set("qualtricsSurveyId", surveyId);
        }
    }

    @action
    onQuestionOptionChange(questionId) {
        if (this.qualtricsQuestionId !== questionId) {
            this.set("qualtricsQuestionId", questionId);
        }
    }
}