import Ember from "ember";
import Component from "@ember/component";
import { action, computed } from "@ember/object";

import SharedResources from "infegy-frontend/models/users/shared_resources";
import { isEmpty } from "@ember/utils";

// import AdminUsers from "infegy-frontend/models/admin/admin_users";

export default class QuerySetsListItem extends Component {
    querySet = null;
    user = null;

    isDeleting = false;
    showDetails = false;
    showSharingDetails = false;

    copyingQuerySet = null;
    newQuerySetName = "";

    // loading entries
    entriesLoaded = false;
    isLoadingEntries = false;

    deletingQuerySet = null;

    sharedResources = SharedResources.create();

    @computed.alias("querySet.entries") entries;

    @computed.alias("querySet.isShared") isShared;

    @computed.notEmpty("entries") hasEntries;
    
    @computed.empty("entries") isEntriesEmpty;
    
    @computed.and("entriesLoaded", "isEntriesEmpty") showEntriesEmpty;

    @computed("copyingQuerySet")
    get isCopying() {
        return this.copyingQuerySet === this.querySet;
    }

    @computed("newQuerySetName")
    get isNewQuerySetNameBlank() {
        return Ember.isEmpty(this.newQuerySetName);
    }

    // events
    copyQuerySet = (querySet, newName) => {};
    declineSharing = (querySet) => {};
    deleteQuerySet = (querySet) => {};
    editQuerySet = (querySet) => {};
    sharingDeclined = (querySet) => {};

    @action
    onBeginCopyQuerySet() {
        this.setProperties({
            copyingQuerySet: this.querySet,
            newQuerySetName: ""
        });
    }

    @action
    onCancelCopyQuerySet() {
        this.set("copyingQuerySet", null);
    }

    @action
    onConfirmCopyQuerySet() {
        this.copyQuerySet(this.querySet, this.newQuerySetName);
    }

    @action
    onDeleteQuerySet() {
        this.deleteQuerySet(this.querySet);
    }

    @action
    onEditQuerySet() {
        this.editQuerySet(this.querySet);
    }

    @action
    onToggleShowDetails() {
        this.toggleProperty("showDetails");

        if (this.showDetails && !this.entriesLoaded) {
            this.set("isLoadingEntries", true);
            this.querySet.loadEntries().then((response) => {
                this.setProperties({
                    entriesLoaded: true,
                    isLoadingEntries: false
                })
            });
        }
    }

    @action
    async onToggleShareDetails() {
        if (Ember.isEmpty(this.sharedResources)) {
            this.sharedResources.getForResource("query-set", this.querySet.id);
        }

        this.toggleProperty("showSharingDetails");
    }

    @action
    onSharingDeclinedAction() {
        this.sharingDeclined(this.querySet);
    }
};