import Ember from "ember";
import { computed, action } from '@ember/object';
import SentimentCombinedBase from "infegy-frontend/components/sentiment/combined-base/component";

export default class SentimentComparisonTableRow extends SentimentCombinedBase {
    tagName = "tr";
    classNameBindings = [":data-table-row", "isEven:data-table-row-even"];

    query = null;
    sentimentField = "documents";
    viewField = "DocumentsDistribution";

    hoveredIndex = null;

    @computed("query", "query.sentiment")
    get groupedDataSeries() {
        let sentiment = this.query.sentiment;
        return sentiment.groupBy(this.groupKey || "timestamp");
    }

    @computed("activeFields", "hoveredIndex", "groupedDataSeries")
    get activeValues() {
        let stats = this.get("query.sentiment.dataSeries.stats"),
            data = this.get("groupedDataSeries.data") || [],
            positivePercentage = stats.get(`${this.activeFields.positive}Distribution.average`),
            passion = stats.get(`passion.average`),
            sentimentalContent = stats.get(`sentimentalDocumentsPercent.average`);

        if (!Ember.isEmpty(this.hoveredIndex) && data.hasOwnProperty(this.hoveredIndex)) {
            let row = data[this.hoveredIndex];
            positivePercentage = Ember.get(row, `${this.activeFields.positive}Distribution`);
            passion = Ember.get(row, "passion");
            sentimentalContent = Ember.get(row, "sentimentalDocumentsPercent");
        }
        return {
            positive: ((positivePercentage || 0) * 100.0).toFixed(0),
            passion: (passion || 0).toFixed(0),
            sentimentalContent: ((sentimentalContent || 0) * 100.0).toFixed(0)
        };
    };

    @computed("activeValues")
    get sentimentWidthStyleSafe() {
        var percentage = this.get("activeValues.positive") || 50;
        return new Ember.String.htmlSafe(`width:${percentage}%;`);
    };

    @computed("activeValues")
    get passionWidthStyleSafe() {
        var percentage = this.get("activeValues.passion") || 50;
        return new Ember.String.htmlSafe(`width:${percentage}%;`);
    };

    @computed("activeValues")
    get sentimentalContentWidthStyleSafe() {
        var percentage = this.get("activeValues.sentimentalContent") || 50;
        return new Ember.String.htmlSafe(`width:${percentage}%;`);
    };

    hoveredIndexChanged() { /* action placeholder */ };

    querySelected() { /* action placeholder */ };

    click() {
        this.querySelected(this.query);
    };

    @action
    wasHovered(interactionInfo) {
        this.hoveredIndexChanged(interactionInfo && interactionInfo.index);
    };
};
