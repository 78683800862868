import Ember from "ember";
import {computed, action} from "@ember/object";
import CSSTools from "infegy-frontend/utils/css-tools";
import TrendChartField from "infegy-frontend/components/charting/trend-chart/trend-chart-stacked-svg/trend-chart-field/component";
import d3 from "d3";

export default class extends TrendChartField {
    @computed("field.colorValues", "isHighlighted")
    get areaStyle() {
        let fillColor = this.get("field.color.colorString");
        return CSSTools.buildStringFromObjectSafe({
           stroke: "transparent",
           fill: fillColor,
           opacity: 0.4
        });
    }

    @computed("dataPoints")
    get areaPath() {
        let path = d3.area()
                .x(d => d.x)
                .y0(d => d.y0)
                .y1(d => d.y)
                .defined(d => { return d.y >= 0; })
                .curve(d3.curveMonotoneX);
        return path(this.dataPoints || []);
    }
}
