import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import { titleCase } from "infegy-frontend/helpers/title-case"
// Get info from /taxonomy-list

var Interests = Ember.ArrayProxy.extend({
    content: Ember.A(),

    init() {
        this._super(...arguments);
        AtlasAuth.Get('api/v3/taxonomy-list').then((json) => {
            if (json.output) {
                var data = Ember.A();        
                json.output.forEach((item) => {
                        var parts = item.split("|");
                        
                        data.push({
                            "label": parts[parts.length - 1],
                            "value": item,
                            "tier": (item.match(/\|/g) || '').length + 1,
                            "description": titleCase(item)
                        });
                        
                    });
                this.set("content",data);
            }
        });
    }
});

export default Interests;