import Ember from "ember";

export default Ember.Object.extend({
    isHovered: false,
    isBrushed: false,
    isSelected: false,
    eventsSelected: false,

    // Hovers
    hoveredField: null,

    hoveredXPct: 0,
    hoveredXIdx: 0,
    hoveredXValue: 0,

    hoveredYPct: 0,
    hoveredYValue: 0,

    // Selections
    selectedField: null,

    selectedXPct: 0,
    selectedXIdx: 0,
    selectedXValue: 0,

    // Brushing
    brushStartXPct: 0,
    brushStartXIdx: 0,
    brushStartXValue: 0,

    brushEndXPct: 0,
    brushEndXIdx: 0,
    brushEndXValue: 0,
});
