import Ember from "ember";

export default {

    apiLoadedForAllQueries: function(apiName, queriesPropertyName) {
        queriesPropertyName = queriesPropertyName || "queries";
        return Ember.computed(queriesPropertyName + ".[]", queriesPropertyName + ".@each.updated", function () {
            return !(this.get(queriesPropertyName) || []).find(query => {
                return !Ember.get(query, apiName + ".isLoaded");
            });
        });
    },
    apiLoadingForAnyQuery: function(apiName, queriesPropertyName) {
        queriesPropertyName = queriesPropertyName || "queries";
        return Ember.computed(queriesPropertyName + ".[]", queriesPropertyName + ".@each.updated", function () {
            return (this.get(queriesPropertyName) || []).find(query => {
                return Ember.get(query, apiName + ".isLoading");
            });
        });
    },
    apiEmptyForAnyQuery: function(apiName, queriesPropertyName) {
        queriesPropertyName = queriesPropertyName || "queries";
        return Ember.computed(queriesPropertyName + ".[]", queriesPropertyName + ".@each.updated", function () {
            return (this.get(queriesPropertyName) || []).find(query => {
                return Ember.get(query, apiName + ".isEmpty");
            });
        });
    },
    apiEmptyForAllQueries: function(apiName, queriesPropertyName) {
        queriesPropertyName = queriesPropertyName || "queries";
        return Ember.computed(queriesPropertyName + ".[]", queriesPropertyName + ".@each.updated", function () {
            return !(this.get(queriesPropertyName) || []).find(query => {
                return !Ember.get(query, apiName + ".isEmpty");
            });
        });
    },
};
