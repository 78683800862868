import Ember from "ember";

export default Ember.Component.extend({
    classNames: ["icon-view-selector", "no-print"],

    hasMoreThanOneQuery: false,
    viewType: "map",

    viewTypeChanged: () => {},
    actions: {
        viewTypeChanged: function (viewType) {
            this.viewTypeChanged(viewType);
        }
    }
});
