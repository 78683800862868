import Ember from "ember";
import QueryDataGrouping from "infegy-frontend/models/queries/query_data_grouping";
import QueryAPIUtils from "infegy-frontend/models/queries/query_api_utils";
import JsonTools from "infegy-frontend/utils/json-tools";
import DateProcessing from "infegy-frontend/utils/date-processing";
import DataSeries from "infegy-frontend/models/data_series/data_series";
import jsonTools from "infegy-frontend/utils/json-tools";

var QueryAPIResults = Ember.Object.extend({
    title: "",
    query: null,
    isNew: true,
    isLoading: false,
    isLoaded: false,
    isEmpty: false,

    hasError: false,
    exportFormats: ["json", "xml", "xls"],

    updated: 0,
    params: null,

    dateField: "date",

    description: {},
    meta:{},

    rawResults: null,

    dataSeries: null,
    all: Ember.computed.alias("dataSeries"),

    data: Ember.computed.alias("dataSeries.data"),
    stats: Ember.computed.alias("dataSeries.stats"),
    dataLength: Ember.computed.alias("dataSeries.data.length"),

    timestamps: null,

    errorInfo: null,

    grouping: Ember.computed("dataLength", {
        get: function(key) {
            return QueryDataGrouping.findGrouping(this.dataLength);
        },
    }),

    groupKey: Ember.computed("grouping", {
        get: function(key) {
            return QueryDataGrouping.findGroupingKey(this.grouping);
        },
    }),

    daily: Ember.computed("dataSeries", {
        get: function(key) {
            return this.group("daily");
        },
    }),

    weekly: Ember.computed("dataSeries", {
        get: function(key) {
            return this.group("weekly");
        },
    }),

    monthly: Ember.computed("dataSeries", {
        get: function(key) {
            return this.group("monthly");
        },
    }),

    yearly: Ember.computed("dataSeries", {
        get: function(key) {
            return this.group("yearly");
        },
    }),

    dayOfWeek: Ember.computed("dataSeries", {
        get: function(key) {
            return this.group("dayOfWeek");
        },
    }),

    group: function(grouping) {
        var groupKey = QueryDataGrouping.findGroupingKey(grouping),
            groupValues = this.groupValues(groupKey),
            dataSeries = this.dataSeries;

        if (dataSeries && groupKey && groupKey !== "dataSeries") {
            var newDataSeries = dataSeries.groupBy(groupKey, {
                    groupKey: groupKey,
                    sortBy: groupKey,
                    leaveDates: false,
                    requiredValues: groupValues,
                });
            newDataSeries.set("checkIfEmpty", this.customEmptyCheckFunction);
            return newDataSeries;
        } else {
            return dataSeries;
        }
    },

    groupBy: function(groupKey) {
        groupKey = groupKey || this.groupKey;
        var groupValues = this.groupValues(groupKey),
            dataSeries = this.dataSeries;

        if (dataSeries && groupKey && groupKey !== "dataSeries") {
            var newDataSeries = dataSeries.groupBy(groupKey, {
                    groupKey: groupKey,
                    sortBy: groupKey,
                    leaveDates: false,
                    requiredValues: groupValues,
                });
            newDataSeries.set("checkIfEmpty", this.customEmptyCheckFunction);
            return newDataSeries;
        } else {
            return dataSeries;
        }
    },

    groupValues: function(groupKey) {
        var groupValues = QueryDataGrouping.groupingValues[groupKey],
            dataSeries = this.dataSeries,
            stats = dataSeries && dataSeries.get("stats"),
            groupStats = groupKey && stats && stats.get(groupKey);

        if (!groupValues && groupStats) {
            groupValues = [];
            if (groupStats) {
                var min = groupStats.get("min"),
                    max = groupStats.get("max");
                for (var val = min; val <= max; val++) {
                    groupValues[groupValues.length] = val;
                }
            }
        }
        return groupValues;
    },

    filter: function(filterList) {
        return this.dataSeries && this.dataSeries.filterByList(filterList);
    },

    filterAndGroup: function(filterList, grouping) {
        var dataSeries = this.dataSeries;
        if (!dataSeries) {
            return;
        }

        if (!grouping || grouping === "grouped") {
            grouping = this.grouping;
        }
        if (!filterList || !filterList.length) {
            return  this.get(grouping);
        }

        var groupKey = QueryDataGrouping.findGroupingKey(grouping);
        var groupValues = this.groupValues(groupKey);

        var newDataSeries;
        if (groupKey && groupKey !== "dataSeries") {
            newDataSeries = dataSeries.filterAndGroup({
                    groupKey: groupKey,
                    filters: filterList,
                    sortBy: groupKey,
                    leaveDates: false,
                    requiredValues: groupValues,
                });
        } else {
            newDataSeries = dataSeries.filterByList(filterList);
        }
        newDataSeries.set("checkIfEmpty", this.customEmptyCheckFunction);
        return newDataSeries;
    },

    flattened: Ember.computed("all", {
        get: function(key) {
            return this.all && this.all.flattenRows();
        },
    }),

    grouped: Ember.computed("all", "grouping", {
        get: function(key) {
            var grouped = this.group(this.grouping || "all");
            return grouped;
        },
    }),

    getGrouped: function(groupName) {
        if (!groupName) {
            return this.grouped;
        }
        return this.group(groupName);
    },

    calculateComputedFields: function(data) {
        data = data || [];
        var computedFields = this.computedFields || [];
        var fieldProc, dataIdx, dataRow;
        for (var fieldKey in computedFields) {
            if (computedFields.hasOwnProperty(fieldKey)) {
                dataIdx = data.length;
                fieldProc = computedFields[fieldKey];

                while (dataIdx--) {
                    dataRow = data[dataIdx];
                    dataRow[fieldKey] = fieldProc(dataRow);
                }
            }
        }
    },

    customEmptyCheckFunction: function(dataSeries) {
        return !dataSeries || !dataSeries.get("data") || !dataSeries.get("data").length;
    },

    build: function() {
        var data = JsonTools.flattenJSONArray(this.rawResults),
            description = this.description,
            meta = this.meta;

        var verificationFields = Object.assign({}, description.get("originalFields"));
        if (this.dateField) {
            verificationFields[this.dateField] = "datestring";
            delete verificationFields.timestamp;
        }
        QueryAPIUtils.verifyAPIFields(this.title, data, verificationFields);

        var newDateFields;
        if (this.dateField) {
            newDateFields = DateProcessing.fixApiDates(data, this.dateField);
        }
        this.calculateComputedFields(data);
        var timestamps = data.mapBy("timestamp");
        this.set("timestamps", timestamps);

        if (newDateFields) {
            this.description.addFields(newDateFields);
        }

        var dataSeries = DataSeries.load(data, this.description);

        dataSeries.set("checkIfEmpty", this.customEmptyCheckFunction);
        this.set("isEmpty", dataSeries.get("isEmpty") || (meta && meta.totalResults <= 0));
        this.set("all", dataSeries);
    },

    loadResponse: function(responseRow, fullResponse) {
        this.set("rawResponse", fullResponse);
        this.set("rawResponseRow", responseRow);
        this.set("rawResults", JSON.parse(JSON.stringify(responseRow.output)));
        this.set("meta", jsonTools.flattenJSON(responseRow.query_meta));

        Ember.beginPropertyChanges();
        this.set("isNew", false);
        this.set("isLoading", false);

        if (this.preload) {
            this.preload();
        }

        if (this.build) {
            this.build();
        }

        if (this.postload) {
            this.postload();
        }
        this.set("isLoaded", true);
        this.incrementProperty("updated");
        Ember.endPropertyChanges();
    },

    beginLoading: function() {
        this.set("isNew", false);
        this.set("isLoaded", false);
        this.set("isLoading", true);
        this.set("isEmpty", false);
    }
});

QueryAPIResults.reopenClass({
    createStubFromDataseries: function(dataSeries, title, query) {
        var newQueryAPIResults = QueryAPIResults.create({
            dataSeries: dataSeries,
            title: title || "query",
            query: query || null,
            isNew: false,
            isLoaded: true,
        });

        return newQueryAPIResults;
    },
});

export default QueryAPIResults;
