import Ember from "ember";
import Component from '@ember/component';
import { isEmpty, isNone } from '@ember/utils';
import { computed } from '@ember/object';
import { action } from '@ember/object';
import ChartColors from 'infegy-frontend/static_data/fixtures/chart_colors';

/**
 * @typedef ClusterTableRowArgs
 * @property {object} cluster
 */

export default class NarrativesTableRow extends Component{
    tagName="tr";
    classNames = ["data-table-row"];

    // arguments
    queries = [];

    clusterData;
    isInChart;
    selectedCluster;

    selectedClusterKeys;

    @computed.alias("clusterData.source") cluster;
    
    onRenameCluster() { /* action */ }
    onRemoveCluster() { /* action */ }
    onSelectCluster() { /* action */ };

    //properties
    showConfirmRemoveWarning = false;
    isEditingClusterName = false;
    _editingClusterName = null;

    @computed("selectedCluster", "clusterData.key")
    get isSelected(){
        return this.selectedCluster.key === this.clusterData?.key;
    }

    @computed("_editingClusterName", "cluster")
    get editingClusterName (){
        return isNone(this._editingClusterName) ? this.cluster.name : this._editingClusterName;
    }
    set editingClusterName(value){
       this.set("_editingClusterName",value);
    }

    @computed("selectedClusterKeys", "selectedClusterKeys.[]", "clusterData", "clusterData.key", "isInChart")
    get canRemove() {
        let clusterKey = this.clusterData?.key,
            clusterKeys = this.selectedClusterKeys || [];
        return this.isInChart || clusterKeys.includes(clusterKey);
    }

    @computed("cluster")
    get sparklineData(){
        return this.cluster.dataSeries && this.cluster.dataSeries.data.map(d=>d.universe);
    }    

    @computed("cluster")
    get stats(){
        return this.cluster.dataSeries && this.cluster.dataSeries.stats;
    }

    @computed("stats")
    get change(){
        return this.stats.universe.percentChange.toFixed(0);
    }

    @computed("cluster", "hoveredIndex")
    get activeData(){
        if(isEmpty(this.hoveredIndex)){
            return this.cluster;
        }
        return this.cluster.dataSeries.data[this.hoveredIndex];
    }

    @computed.mapBy("tag.dataSeries.data", "universe") documentsTimeline;

    @computed("cluster")
    get sentimentStyleSafe() {
        var sentiment = this.cluster?.dataSeries?.stats?.clusterPositiveDocumentsDistribution?.average || 0.5,
            colorValues = ChartColors.sentimentZeroToOneGradientD3();
        var style = ["color:", colorValues(sentiment), ";"].join("");

        return new Ember.String.htmlSafe(style);
    }

    click(){
        this.onSelectCluster(this.clusterData);
    }


    @action
    clusterNameChanged(cluster,name){
        this.onRenameCluster(cluster,name);
        this.setProperties({
            isEditingClusterName: false,
        });
    }

    @action
    removeCluster(cluster){
        this.onRemoveCluster(cluster);
        this.setProperties({
            isEditingClusterName: false,
            showConfirmRemoveWarning: false
        });
    }

    onRemoveFromChart;
    
    onAddToChart;
    canAddToChart;

    @action
    removeFromChart() {
        if (this.onRemoveFromChart) {
            this.onRemoveFromChart(this.clusterData?.key);
        }
    }
    
    @action
    addToChart() {
        if (this.onAddToChart) {
            this.onAddToChart(this.clusterData?.key);
        }
    }
}