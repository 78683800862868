import Route from "@ember/routing/route";
import { action } from "@ember/object";
import SharedResources from "infegy-frontend/models/users/shared_resources";
import * as Sentry from "@sentry/browser";

export default class EditDictionaryRoute extends Route {
    title = "Editing Dictionary";

    model(queryParams){
        return queryParams.dictionary_id;
    }

    setupController(controller, dictionary_id) {
        try {
            controller.set("dictionary_id", parseInt(dictionary_id));
        } catch(e) {
            this.transitionTo("atlas.tools.dictionaries.index");
            Sentry.captureMessage(`Invalid dictionary ID '${dictionary_id}' in URL`);
        }

        const sharedResources = SharedResources.create();
        sharedResources.getForResource("dictionary", dictionary_id);
        controller.set("sharedResources", sharedResources);
    }

    @action
    didTransition() {
        /*
        When a dictionary is copied, this page is reloaded by transitioning
        to the new dictionary id.  After the transition is complete, the 
        "didTransitionToCopiedDictionary" flag is set to true.  This flag 
        needs to be reset on subsequent visits to this page.
        */
        const controller = this.controller;
        controller.set("didTransitionToCopiedDictionary", false);
    }
}