import JsonStore from 'infegy-frontend/json-store/model';
import Prop from 'infegy-frontend/json-store/properties/model';

export default JsonStore.extend({
    entities: Prop.Attr(),
    taxonomies: Prop.Attr(),
    emotions: Prop.Attr(),
    themes: Prop.Attr(),
    postChannels: Prop.Attr(),
    sentiment: Prop.Attr(),
    subjectSentiment: Prop.Attr(),
});
