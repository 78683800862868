import Ember from "ember";
import { computed } from '@ember/object';

import CSSTools from "infegy-frontend/utils/css-tools";
import { MouseInteractionWrapper } from "infegy-frontend/components/charting/mouse-interaction-mixin/component";

var minimalPercentChangeThreshold = 3.0;

export default class extends MouseInteractionWrapper(Ember.Component) {
    classNameBindings = [":even-column", ":trends_field_information"];
    attributeBindings = ["style"];

    trendChartMeta = null;

    size = null;
    trendField = null;

    maxUnfilteredPrimaryValue = 0;

    @computed.oneWay("trendChartMeta.isHovered") isHovered;
    @computed.oneWay("trendChartMeta.isSelected") isSelected;
    @computed.oneWay("trendChartMeta.isBrushed") isBrushed;
    @computed.oneWay("trendField.hoveredInfo") hoveredInfo;
    @computed.or("isHovered", "isSelected") isHoveredOrSelected;

    @computed("field", "isHoveredOrSelected", "isBrushed")
    get isHidden() {
        return !(this.isHoveredOrSelected ||  this.isBrushed);
    }

    @computed("hoveredInfo", "isHovered", "isSelected")
    get isOutOfBounds() {
        let selectedInfo = this.trendField.selectedInfo;
        return (this.isHovered && !this.hoveredInfo?.rowInfo?.isInBounds) ||
          (this.isSelected && !selectedInfo?.rowInfo?.isInBounds);
    }

    @computed("isHovered", "isSelected", "isBrushed")
    get showSummaryValue() {
        return this.isHovered || this.isSelected ||
            this.isBrushed;
    }

    @computed.oneWay("field.summaryValue") summaryValue;

    @computed("isHovered", "isSelected", "isBrushed", "trendField",
        "trendField.hoveredInfo", "trendField.selectedInfo", "trendField.brushedField")
    get highlightValue() {
        var trendChartMeta = this.trendChartMeta;
        if (this.isHovered) {
            let hoveredInfo = this.trendField.hoveredInfo;
            return hoveredInfo && hoveredInfo.yValue;
        } else if (this.isSelected) {
            let selectedInfo = this.trendField.selectedInfo;
            return selectedInfo && selectedInfo.yValue;
        } else if (this.isBrushed && this.trendField.brushedField) {
            let field = this.trendField.brushedField;
            return field.summaryValue;
        }
    }

    @computed("trendField.field", "trendField.brushedField", "isBrushed")
    get field() {
        if (this.isBrushed && this.trendField.brushedField) {
            return this.trendField.brushedField;
        }
        return this.trendField?.field;
    }

    @computed("averagesOnly", "isHoveredOrSelected", "highlightValue", "summaryValue")
    get primaryValue() {
        if (this.isHoveredOrSelected) {
            return this.highlightValue;
        }
        return this.summaryValue;
    }

    @computed("isHovered", "isSelected", "isBrushed")
    get interactedValueType() {
        if (this.isHovered) {
            return "Hovered";
        } else if (this.isSelected) {
            return "Selected";
        } else if (this.isBrushed) {
            return "Highlighted";
        }
    }

    @computed("averagesOnly", "interactedValueType", "isHovered", "isSelected", "isBrushed")
    get primaryValueType() {
        if (this.interactedValueType) {
            return this.interactedValueType;
        } else if (this.averagesOnly) {
            return "Average";
        }
        return "Total";
    }

    @computed("averagesOnly")
    get summaryValueType() {
        return this.averagesOnly ? "Average" : "Total";
    }

    @computed("field.percentChange")
    get percentChange() {
        return (this.field?.fieldStats?.percentChange || 0.0) / 100.0;
    }

    @computed.gt("percentChange", minimalPercentChangeThreshold) isPositivePercentChange;
    @computed.lt("percentChange", minimalPercentChangeThreshold) isNegativePercentChange;

    @computed("percentChange")
    get isMinimalPercentChange() {
        return Math.abs(this.percentChange) < this.minimalPercentChangeThreshold;
    }

    @computed.equal("percentChange", Infinity) isPositiveInfinityPercentChange;
    @computed.equal("percentChange", -Infinity) isNegativeInfinityPercentChange;
    @computed.or("isPositiveInfinityPercentChange", "isNegativeInfinityPercentChange") isInfinityPercentChange;

    @computed.alias("field.statsType") statsType;
    @computed("field.fixedMaxY", "isPercentage")
    get fieldMaxY() {
        var fixedMaxY = this.field?.fixedMaxY || this.field?.portionWhole;
        if (!fixedMaxY && this.isPercentage) {
            fixedMaxY = 1.0;
        }
        return fixedMaxY;
    }

    @computed.alias("field.isPercentageType") isPercentage;
    @computed.alias("field.isAveragedType") isAverage;
    @computed.alias("field.isAveragedType") averagesOnly;

    @computed.not("field.isPercentageType") hidePercentage;

    @computed("averagesOnly", "field.average", "fieldMaxY")
    get showArcs() {
        var isRangelessPercentage = this.field?.isRangelessPercentage,
            fieldMaxY = this.fieldMaxY;
        return fieldMaxY && this.averagesOnly && !isRangelessPercentage;
    }

    @computed("isPercentage")
    get arcWhole() {
        return this.isPercentage ? 1.0 : this.fieldMaxY;
    }

    @computed.alias("trendChartMeta.selectedField") selectedField;

    @computed("trendChartMeta.hoveredField", "trendChartMeta.selectedField")
    get hasHighlight() {
        return this.get("trendChartMeta.hoveredField") || this.get("trendChartMeta.selectedField");
    }

    @computed("trendChartMeta.hoveredField", "trendChartMeta.selectedField", "field")
    get isHighlighted() {
        if (this.hasHighlight) {
            return this.get("trendChartMeta.hoveredField") === this.trendField ||
                this.get("trendChartMeta.selectedField") === this.trendField;
        }
    }

    @computed("hasHighlight","isHighlighted","trendField.field.color", "field.colorString", "field.color.darkColorString")
    get style(){
        let color = this.trendField.field.color,
            textColor;
        if (!this.hasHighlight) {
            textColor = color.colorString;
        } else {
            textColor = this.isHighlighted ?  color.darkColorString : color.lightColorString;
        }
        return CSSTools.buildStringFromObjectSafe({
            color: textColor
        });
    }

    @computed("hasHighlight","isHighlighted","trendField.field.color", "trendField.field.lightColorString", "trendField.darkColorString")
    get color() {
        let color = this.trendField.field.color;
        if (!this.hasHighlight) {
            return color.colorString;
        } else {
            return this.isHighlighted ?  color.darkColorString : color.lightColorString;
        }
    }

    @computed("isHighlighted", "hasHighlight")
    get highlightedColorClass() {
        if (!this.hasHighlight) {
            return "";
        } else if (this.isHighlighted) {
            return " trends-field-information-highlighted";
        }
        return " trends-field-information-faded";
    }

    onMouseEnter() {
        this.fieldHovered(this.trendField);
    }

    onMouseLeave() {
        this.fieldHovered(null);
    }

    click() {
        this.fieldClicked(this.trendField);
    }

}
