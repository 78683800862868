import Component from '@ember/component';
import {computed, action} from "@ember/object";
import FieldDefinitionLoader from "infegy-frontend/models/field-definitions/loader";
import TrendChartMeta from "infegy-frontend/models/trends/trend-chart-meta";
import classic from "ember-classic-decorator";

@classic
export default class TimeOfDay extends Component {
    queries = null;

    combineQueries = false;
    isCollated = true;
    
    activeField = "universe";
    trendChartMeta = null;

    @computed("trendChartMeta")
    get innerTrendChartMeta() {
        var trendChartMeta = this.trendChartMeta;
        if (!trendChartMeta) {
            trendChartMeta = TrendChartMeta.create();
        }
        trendChartMeta.groupingOverride = "timeOfDay";
        return trendChartMeta;
    }

    @computed("queries", "queries.[]")
    get isMultiQuery() {
        return this.queries?.length > 1;
    }

    @computed("isMultiQuery")
    get fieldOptions() {
        if (this.isMultiQuery) {
            return ["count", "posts", "universe"];
        }
        return ["count", "posts", "universe", "portionOfTotal"];
    }

    @computed("isMultiQuery", "combineQueries")
    get isCombined() {
        return this.isMultiQuery ? this.combineQueries : false;
    }

    // timeField : hour
    @computed("queries", "queries.[]", "isMultiQuery", "activeField",
        "queries.@each.updated", "queries.@each.timeOfDay", "isCombined")
    get timeOfDayFields() {
        let queries = this.queries || [],
            isMultiQuery = this.isMultiQuery,
            isCombined = this.isCombined;

        let fieldSetup = {
            "fieldClass": "trend-field",
            "apiName": "timeOfDay",
            "fieldType": "trend",
            "xAxisField": "hour",
            "fieldName": this.activeField,
            "title": this.activeField
        };

        let fields = queries.map(query => {
            let newField = FieldDefinitionLoader.loadField(fieldSetup, [query]);
            if (isMultiQuery && !isCombined) {
                newField.set("title", query.title);
            }
            return newField;
        });

        if (this.isCombined) {
            fields = [combineMultiQueryFields(fields)];
        }
        return fields;
    }
};
