import Ember from 'ember';
import d3 from "d3";
import defaultColorDefinitions from "infegy-frontend/static_data/fixtures/default_color_definitions";
import CSSTools from "infegy-frontend/utils/css-tools";

var DefaultColorDefinitions = defaultColorDefinitions;

// var colorInfoSample = {
//     indexName: {
//         class: "chart-1",
//         baseRGB: {r: 0, g: 0, b:0},
//         lightRGB: {r: 0, g: 0, b:0},
//         darkRGB: {r: 0, g: 0, b:0},
//         base: "#fff",
//         light: "#fff",
//         dark: "#fff",
//         aliases: [2, "2", "passion", "blogs", "facebook"]
//     }
// };

var loadColorDefinitions = function(atlasColorsInstance, colorDefs) {
    var colorMap = {},
        baseColorClasses = [],
        indexedColors = [];

    for (var colorIdx = 0; colorIdx < colorDefs.length; colorIdx++) {
        var colorInfo = colorDefs[colorIdx],
            allColorClassNames = (colorInfo.alternateClasses || []).slice();

        allColorClassNames.push(colorInfo.className);
        for (var classIdx = 0; classIdx < allColorClassNames.length; classIdx++) {
            var className = allColorClassNames[classIdx],
                colorDef = atlasColorsInstance.findColorsByClassName(className);

            colorMap[className] = Object.assign(colorDef, {
                    index: colorIdx,
                    class: className,
                    classAliases: allColorClassNames
                });
        }

        indexedColors[colorIdx] = colorMap[colorInfo.className];
        baseColorClasses = baseColorClasses.concat(allColorClassNames);
    }
    atlasColorsInstance.set("colorMap", colorMap);
    atlasColorsInstance.set("baseColorClasses", baseColorClasses.uniq());
    atlasColorsInstance.set("indexedColors", indexedColors);
    atlasColorsInstance.set("indexedColorClasses", indexedColors.mapBy("className"));
    atlasColorsInstance.set("indexedBaseColors", indexedColors.mapBy("base"));
    atlasColorsInstance.set("colorPalette", indexedColors.reduce(function (memo, col) {
        memo.push(col.light);
        memo.push(col.base);
        memo.push(col.dark);
        return memo;
    }, []));
    atlasColorsInstance.addOldColorAttrs();
};

var ChartColors = Ember.Object.extend({
    colorMap: {},
    baseColorClasses: [],
    indexedColors: [],
    indexedColorClasses: [],

    "1" : {},
    "2" : {},
    "3" : {},
    "4" : {},
    "5" : {},
    "6" : {},
    "gray": {},
    "positive": {},
    "negative": {},
    "ui": {},

    addOldColorAttrs: function() {
        var oldColorAttrs = ["1", "2", "3", "4", "5", "6", "gray", "positive", "negative", "ui"],
            indexedColors = this.indexedColors;

        for (var colorIdx = 0; colorIdx < oldColorAttrs.length; colorIdx++) {
            if (indexedColors[colorIdx]) {
                this.set(oldColorAttrs[colorIdx], indexedColors[colorIdx]);
            }
        }
    },

    getComputedStyleFromClassName: CSSTools.getComputedStyleFromClassName,

    findColorsByClassName: function(className) {
        var elem = document.createElement("div");
        document.body.appendChild(elem);

        var colorDef = {
            className: className,
            lightClassName: [className, "light"].join("-"),
            darkClassName: [className, "dark"].join("-"),
            bgClassName: [className, "bg"].join("-"),
            bgLightClassName: [className, "light", "bg"].join("-"),
            bgDarkClassName: [className, "dark", "bg"].join("-")
        };

        colorDef.base = this.getComputedStyleFromClassName(colorDef.className, elem).color;
        colorDef.light = this.getComputedStyleFromClassName(colorDef.lightClassName, elem).color;
        colorDef.dark = this.getComputedStyleFromClassName(colorDef.darkClassName, elem).color;

        document.body.removeChild(elem);
        return colorDef;
    },

    getColorfromClassName: function(colorClass) {
        let color = this.colorMap[colorClass];
        return (color && color.base) || this.getComputedStyleFromClassName(colorClass).color;
    },

    sentimentGradientClasses: ["atlas-negative", "atlas-mixed", "atlas-positive"],
    // TODO valueForClass(class, field), colorValueForClass(class),
    // valueForClasses(class, field), colorValuesForClasses(array)
    // makes a lot of conversions easier
    sentimentGradientCSSColors: Ember.computed("colorMap", {
        get: function(key) {
            var colorMap = this.colorMap,
                classes = this.sentimentGradientClasses || [];

            return classes.filter(function(className) {
                return !!colorMap[className];
            }).map(function(className) {
                return colorMap[className].base;
            });
        }
    }),
    sentimentGradientD3: function(domain) {
        domain = domain || [-1.0, 0.0, 1.0];
        return d3.scaleLinear()
            .domain(domain)
            .range(this.get("sentimentGradientCSSColors"));
    },
    sentimentOf100GradientD3: function() {
        return this.sentimentGradientD3([-100.0, 0.0, 100.0]);
    },
    sentimentZeroToOneGradientD3: function() {
        return this.sentimentGradientD3([0.0, 0.5, 1.0]);
    },
    ordinalColorsD3Named: function(fieldName) {
        fieldName = fieldName || "className";
        var colorOptions = (this.get("indexedColors") || []).mapBy(fieldName);

        return d3.scaleOrdinal()
            .domain(d3.range(colorOptions.length))
            .range(colorOptions);
    },
    ordinalColorsD3: function() {
        return this.ordinalColorsD3Named("base");
    },
    ordinalColorsD3Light: function() {
        return this.ordinalColorsD3Named("light");
    },
    colorsForIndex: function(index) {
        var indexedColors = this.indexedColors || [];
        index = (parseInt(index) || 0) % indexedColors.length;
        return indexedColors[index];
    },
    colorPalette: [],

    // Compare colors based on rgb vector distance to make sure we aren't selecting too close of colors
    compareTinyColor: function(tinyColor1, tinyColor2, readabilityThresholdSq) {
        readabilityThresholdSq = readabilityThresholdSq || 20;
        let c1 = tinyColor1.toRgb(),
            c2 = tinyColor2.toRgb(),
            distance = (c1.r-c2.r)*(c1.r-c2.r) + (c1.g-c2.g)*(c1.g-c2.g) + (c1.b-c2.b)*(c1.b-c2.b);
        return distance < readabilityThresholdSq;
    }
});

var colorsInstance = ChartColors.create();
loadColorDefinitions(colorsInstance, DefaultColorDefinitions);

window.col = colorsInstance;
export default colorsInstance;
