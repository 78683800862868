import Ember from 'ember';
import AtlasAuth from "infegy-frontend/utils/atlas-auth";

export default Ember.Controller.extend({
    application: Ember.inject.controller(),

    user: Ember.computed.alias("application.user"),
    isApiDown: Ember.computed.alias('application.isApiDown'),
    currentYear: Ember.computed.alias('application.currentYear'),

    previousPage: null,

    isLoading: false,
    loginError: null,
    username: "",
    password: "",

    setHash: Ember.on("init",function(){
        Ember.run.schedule('afterRender',this,function(){
            var previousPage = this.previousPage || '';
            if(previousPage.length > 0){
                window.location.hash = previousPage;
            }
        });
    }),

    actions: {
        async login(username, password) {
            var previousPage = this.previousPage || "";

            if (previousPage.indexOf("previousLocation=") > -1) {
                previousPage = previousPage.replace("previousLocation=", "");
            }

            if (!username || username.length < 3 || !password || password.length < 3) {
                this.set("isLoading", false);
                this.set("loginError", "Please enter a valid username or password");
                return;
            }

            this.set("isLoading", true);
            try {
                await AtlasAuth.login(username, password);
            } catch (errorResponse) {
                this.set("loginError", errorResponse && errorResponse.atlasErrorText || "Error logging in");
                this.set("isLoading", false);
                document.querySelector('#atlas_username').focus();
                return;
            }
            this.set("isLoading", false);
            if (AtlasAuth.user) {
                if (previousPage.indexOf('dashboard') > -1 || previousPage.indexOf('admin') > -1) {
                    window.location.href = window.location.protocol + "//" + window.location.host + previousPage;
                } else {
                    AtlasAuth.user.loadAll();
                    this.transitionToRoute("atlas.dashboard");
                }
            }
        },
    }
});
