import Ember from "ember";
import TrendableApiField from "infegy-frontend/models/fields/trendable-api-field";
import QueryDataGrouping from "infegy-frontend/models/queries/query_data_grouping";
import calculateFilterRange from "infegy-frontend/models/fields/trend-filter-range-calculator";

export default Ember.Component.extend({
    // Passed In
    fields: [],
    activeFilters: [],

    chartHeight: 200,
    chartType: "line",
    isCustomTrends: false,

    queries: null,

    availableFields: [],

    groupByOptions: [
            {title: "Day", value:"timestamp"}
        ],

    eventsData: [],

    showOptions: false,

    interactionInfo: {},

    weekFilter: null,
    timeOfDayFilter: null,

    // Computed or Internal

    selectedRangeMin: null,
    selectedRangeMax: null,

    selectableFields: false,

    groupByField: null,
    isFilterable: Ember.computed('groupByField', function() {
        var groupByField = this.groupByField;
        return groupByField === "_dayOfWeek" || groupByField === "hour";
    }),

    innerChartHeight: Ember.computed("chartHeight", function () {
        return this.chartHeight > 10? this.chartHeight : 200;
    }),

    availableGroupKeyOptions: Ember.computed("groupByField", "firstField.originalQueryApi",
            "firstField.originalQueryApi.isLoaded", function () {
        var groupByField = this.groupByField,
            groupKeyOptions;
        if (!this._selectedGroupKey || !groupByField || groupByField === "auto" || groupByField === "timestamp") {
            var queryApi = this.get("firstField.originalQueryApi"),
                numDays = 0;
            if (queryApi) {
                let endDate = this.firstField.query.queryInfo.endDate.timestamp;
                let startDate = this.firstField.query.queryInfo.startDate.timestamp;
                numDays = Math.ceil((new Date(endDate).getTime() - new Date(startDate)) / (1000 * 3600 * 24));
            }
            groupKeyOptions = numDays && QueryDataGrouping.availableGroupingOptions(numDays);
        }

        return groupKeyOptions;
    }),

    _selectedGroupKey: null,

    firstField: Ember.computed.alias("fields.firstObject"),

    innerGroupByField: Ember.computed("groupByField", "firstField",
            "_selectedGroupKey", "firstField.originalQueryApi",
            "firstField.originalQueryApi.isLoaded", function () {
        if (this._selectedGroupKey) {
            return this._selectedGroupKey;
        } else if (this.groupByField) {
            return this.groupByField;
        }
        var queryApi = this.get("firstField.originalQueryApi");
        return queryApi && queryApi.get("groupKey");
    }),

    selectionFilter: null,

    filteredFields: Ember.computed("fields", "fields.@each.apiName", "fields.@each.fieldName",
            "fields.@each.queryIndex", "fields.@each.colorClass", "fields.[]", "activeFilters", "innerGroupByField",
            "query", "query.updated", "queries", "queries.@each.updated", function () {
        var fields = this.fields || [],
            activeFilters = this.activeFilters,
            groupByField = this.innerGroupByField,
            cachedResults = [],
            axisMaxYs = {};
        var filteredFields = fields.map(function(field) {
            var query = field.get("query"),
                apiName = field.get("apiName"),
                api = query && apiName && query.get(apiName),
                dataSeries = api && api.get("dataSeries"),
                fieldJSON = field.toJSON(),
                maxY = field.get("fixedMaxY"),
                newDataSeries = dataSeries,
                cachedResult = cachedResults.find((cachedResultRow) => {
                        return cachedResultRow.source === dataSeries;
                    });
            if (cachedResult) {
                newDataSeries = cachedResult.result;
            } else if (groupByField && activeFilters && activeFilters.length) {
                newDataSeries = dataSeries && dataSeries.groupByWithRangeFilters({
                        groupKey: groupByField,
                        sortBy: groupByField,
                        filters: activeFilters,
                        requiredValues: QueryDataGrouping.groupingValues[groupByField] || null
                    });
                cachedResults.push({ source: dataSeries, result: newDataSeries});
            } else if (groupByField) {
                newDataSeries = api && api.groupBy(groupByField);
                cachedResults.push({ source: dataSeries, result: newDataSeries});
            }
            var trendableField = TrendableApiField.create();
            trendableField.loadJSON(fieldJSON);
            trendableField.set("xAxisField", groupByField);
            trendableField.set("query", query);
            trendableField.set("dataSeries", newDataSeries);
            trendableField.set("unfilteredDataSeries", dataSeries);
            if (maxY) {
                trendableField.set("maxY", maxY);
            } else if (newDataSeries) {
                var newMaxY = newDataSeries.get("stats." + field.get("fieldName") + ".max"),
                    axisGroup = trendableField.get("axisGroup") || trendableField.get("value");
                if (!axisMaxYs[axisGroup] || newMaxY > axisMaxYs[axisGroup]) {
                    axisMaxYs[axisGroup] = newMaxY;
                }
            }
            return trendableField;
        });
        filteredFields.forEach(function(field) {
            if (!field.get("maxY")) {
                field.set("maxY", axisMaxYs[field.get("axisGroup") || field.get("value")]);
            }
        });
        return filteredFields;
    }),

    firstFilteredField: Ember.computed.alias("filteredFields.firstObject"),
    isSingleDayQuery: Ember.computed.equal("firstFilteredField.data.length", 1),

    ownFilteredFields: Ember.computed("filteredFields", "selectionFilter",
        "innerGroupByField", function () {
        var groupByField = this.innerGroupByField,
            filteredFields = this.filteredFields || [],
            filter = this.selectionFilter;

        if (!filter) {
            return filteredFields;
        }
        var filterArgs = filter && filter.args,
            filterMin = filterArgs && filterArgs[1],
            filterMax = filterArgs && filterArgs[2],
            newFields = filteredFields.map(function(field) {
                var dataSeries = field.get("dataSeries"),
                    fieldJSON = field.toJSON(),
                    newField = TrendableApiField.create();
                newField.loadJSON(fieldJSON);
                newField.set("sourceField", field);
                newField.set("query", field.get("query"));
                newField.set("dataSeries", dataSeries.filterByRange(groupByField, filterMin, filterMax));
                newField.set("unfilteredDataSeries", field.get("unfilteredDataSeries") || dataSeries);
                return newField;
            });

        return newFields;
    }),

    filterRange: Ember.computed("interactionInfo.isBrushed", "interactionInfo.isSelected",
            "interactionInfo.brushStartXIdx", "interactionInfo.brushEndXIdx",
            "interactionInfo.selectedXIdx", "ownFilteredFields.firstObject",
            "ownFilteredFields.firstObject.data", "innerGroupByField", function() {
        var data = this.get("ownFilteredFields.firstObject.data"),
        interactionInfo = this.interactionInfo,
        groupByField = this.innerGroupByField;

        return calculateFilterRange.fromInteractionInfo(data, interactionInfo, groupByField);
    }),

    activeApis: Ember.computed("fields", "fields.[]",
            "fields.@each.apiName", function () {
        return (this.fields || []).mapBy("apiName").uniq();
    }),

    activeFieldsChanged: () => {},
    fieldSelected: () => {},
    interactionUpdated: () => {},
    fieldHovered: () => {},
    filterChanged: () => {},
    actions: {
        activeFieldsChanged: function (activeFields) {
            this.activeFieldsChanged(activeFields);
        },
        groupKeyChanged: function(selectedGroupKey) {
            this.setProperties({
                _selectedGroupKey: selectedGroupKey,
                "interactionInfo.selectedField": null,
                "interactionInfo.isSelected": false
            });
        },
        setSelectedRangeMin: function(value) {
            this.set("selectedRangeMin", value);
        },
        setSelectedRangeMax: function(value) {
            this.set("selectedRangeMax", value);
        },
        fieldSelected: function(field) {
            if (!this.selectableFields || !this.interactionInfo) {
                return;
            }
            if (this.get("interactionInfo.selectedField") === field) {
                field = null;
            }
            this.set("interactionInfo.selectedField", field);
            this.set("interactionInfo.isHovered", false);
            this.fieldSelected(field);
            this.interactionUpdated(this.interactionInfo);
        },

        fieldHovered: function (field) {
            this.fieldHovered(field);
        },
        interactionUpdated: function(interactionInfo) {
            var groupByField = this.innerGroupByField || "timestamp",
                filter = this.selectionFilter,
                isBrushed = interactionInfo && interactionInfo.get("isBrushed"),
                min = interactionInfo && interactionInfo.get("brushStartXValue"),
                max = interactionInfo && interactionInfo.get("brushEndXValue"),
                filterArgs = filter && filter.args;
            if (isBrushed && !Ember.isEmpty(min) && !Ember.isEmpty(max) && max >= min) {
                var filterMin = filterArgs && filterArgs[1],
                    filterMax = filterArgs && filterArgs[2];
                if (!filter || !filterArgs || filterMin !== min || filterMax !== max) {
                    filter = {type: "range", args: [groupByField, min, max]};
                    this.set("selectionFilter", filter);
                    this.filterChanged(filter);
                }
            } else if (this.selectionFilter) {
                this.set("selectionFilter", null);
                this.filterChanged(null);
            }
            this.set("interactionInfo", interactionInfo);
            this.interactionUpdated(interactionInfo);
        },
    }
});
