import Ember from "ember";
import QueryAPIUtils from "infegy-frontend/models/queries/query_api_utils";

const subAggregateAPIs = ["subquerySets","narratives"];
const attributes = ["volume","sentiment","social","words","sentences","gender","age"];

export default {
    buildObject(query, apiName, additionalAPIParameters) {
        let queryInfo = query?.queryInfo;
        let filters = queryInfo?.filters || [];

        if (!query || !queryInfo || !filters) {
            return;
        }
        let queryJSON = {};

        queryInfo.clearInvalidFilters();

        // Standard Query Filters
        let includedFilterAPIJSON = filters.filter(filter => !filter.isExcluded).map(filter => filter.toAPIJSON()),
            excludedFilterAPIJSON = filters.filter(filter => filter.isExcluded).map(filter => filter.toAPIJSON());

        // Date Range
        includedFilterAPIJSON.push({
            id: queryInfo.timestampField,
            min: queryInfo.startDate.toAPIString(),
            max: queryInfo.endDate.toAPIString(true)
        });

        if (!Ember.isEmpty(includedFilterAPIJSON)) {
            queryJSON.filter = includedFilterAPIJSON;
        }
        if (!Ember.isEmpty(excludedFilterAPIJSON)) {
            queryJSON.exclude = excludedFilterAPIJSON;
        }

        // Other Query Properties
        this.transforms.forEach(transform => {
            let fieldName = transform.fieldName,
                itemValue = transform.makeValue(query, queryInfo);
            if (!Ember.isEmpty(itemValue) && !Ember.isEmpty(fieldName)) {
                queryJSON[fieldName] = itemValue;
            }
        });

        return queryJSON;
    },
    build(query, apiName, additionalAPIParameters, encode_all) {
        let queryInfo = query?.queryInfo;
        let filters = queryInfo?.filters || [];

        if (!query || !queryInfo || !filters) {
            return;
        }

        let queryJSON = this.buildObject(query, apiName, additionalAPIParameters);

        additionalAPIParameters = Object.assign({},
            this.getDefaultApiParameters(apiName),
            (queryInfo && queryInfo.get("additionalAPIParameters")) || {},
            additionalAPIParameters || {});

        var additionalAPIParameterStrings = this.buildAdditionalAPIParameterParts(additionalAPIParameters).map((itemValue)=>{
            var value = (itemValue.value ?? "").trim();
            return `${itemValue.fieldName}=${value}`;
        }) || [];

        if (subAggregateAPIs.includes(apiName)){
            additionalAPIParameterStrings.push(`attributes=${attributes.join(",")}`);
        }

        let queryJSONString = JSON.stringify(queryJSON);

        if (encode_all) {
            queryJSONString = encodeURIComponent(queryJSONString);
        } else {
            queryJSONString = queryJSONString.replaceAll("#", "%23");
            queryJSONString = queryJSONString.replaceAll("&", "%26");
        }
        
        let response = `q=${queryJSONString}`;

        if (additionalAPIParameterStrings && additionalAPIParameterStrings.length) {
            response += "&" + additionalAPIParameterStrings.join("&")
        }

        return response;
    },
    getDefaultApiParameters(apiName) {
        if (!apiName) {
            return {};
        }
        var apiDefinition = QueryAPIUtils.byName(apiName) || {},
            apiOptions = apiDefinition.apiOptions || [];

        var options = apiOptions.reduce(function(memo, option) {
            if (option.parameter && option.defaultValue) {
                memo[option.parameter] = option.defaultValue;
            }
            return memo;
        }, {});
        return options;
    },
    buildAdditionalAPIParameterParts(additionalAPIParameters) {
        var filters = additionalAPIParameters || {},
            vals = [];

        for (var key in filters) {
            if (filters.hasOwnProperty(key)) {
                var value = encodeURIComponent(filters[key]).trim();
                value = value.replace(/%2C/g, ",");
                if (!Ember.isEmpty(value)) {
                    vals.push({ fieldName: key, value: value });
                }
            }
        }
        return vals;
    },
    transforms: [{
            fieldName: "query",
            makeValue(query, filters) {
                if (filters.queryType === "builder") {
                    return filters.queryBuilderDetail?.drillInQuery;
                }
                return filters.queryString;
            }
        }, {
            fieldName: "query_builder",
            makeValue(query, filters) {
                return filters?.queryBuilderDetail?.toJSON({ fieldGroups: "api" });
            }
        }, {
            fieldName: "entity_query",
            makeValue(query, filters) {
                return filters.entityQuery;
            }
        }, {
            fieldName: "query_within",
            makeValue(query, filters) {
                return filters.queryWithin;
            }
        }, {
            fieldName: "sources",
            makeValue(query, filters) {
                return filters.sources?.toJSON();
            }
        }, {
            fieldName: "dictionary_ids",
            makeValue(query, filters) {
                return filters.dictionaries;
            }
        }, {
            fieldName: "dataset_id",
            makeValue(query, filters) {
                return filters.customDatasetId;
            }
        },{
            fieldName: 'query_fields',
            makeValue(query,filters){
                return filters.queryFields;
            }
        },{
            fieldName: 'analyze_fields',
            makeValue(query,filters){
                return filters.analyzeFields;
            }
        }, {
            fieldName: "query_set_ids",
            makeValue(query, filters) {
                return filters.subquerySets;
            }
        }, {
            fieldName: "group_by",
            makeValue(query, filters) {
                return filters.activeGrouping;
            }
        },

    ]
};
