import EmberObject from "@ember/object";

import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";

export default QueryAPIResults.extend({
    title : "influencers",

    dateField: "found",

    description: DataSeriesDescription.load({
        "id": "string",
        "rank": "number",
        "link": "string",
        "dataUri": "string",
        "title": "string",
        "description": "string",
        "birthYear": "number",
        "gender": "string",
        "linksIn": "number",
        "linksOut": "number",
        "totalPosts": "number",
        "country": "string",
        "usState": "string",
        "dma": "string",
        "found": "datestring",
        "lastUpdate": "string",
        "score": "number",
        "influence": "number",
        "matches": "number",
        "positiveMatches": "number",
        "negativeMatches": "number",
        "followers": "number",
        "following": "number",
        "reach": "number",
        "totalLikes": "number",
        "totalShares": "number",
        "totalComments": "number",
        "matches": "number",
        "channel": "string",
        "latitude": "number",
        "longitude": "number",
        "geohash": "string"
    }),
    postload: function() {
        if (this.stats) {
            this.set("stats.metaFollowers", EmberObject.create({
                isPercentage: false,
                sum: this.meta.totalFollowers,
                type: "number"
            }));
            this.set("stats.metaFollowing", EmberObject.create({
                isPercentage: false,
                sum: this.meta.totalFollowing,
                type: "number"
            }));
        }
    }
});
