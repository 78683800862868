import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import QueryAPIResult from "infegy-frontend/models/queries/query_api_result";

export default QueryAPIResult.extend({
    title : "demographicsMeta",
    description: DataSeriesDescription.load({
        "sampleSize": "number",

        "averageHouseholdSize": "number",
        "medianHouseholdIncome": "number",
        "medianDisposableIncome": "number",
        "medianHouseValue": "number",
        "educationAnyCollege": "number",

        "dbAverageHouseholdSize": "number",
        "dbMedianHouseholdIncome": "number",
        "dbMedianDisposableIncome": "number",
        "dbMedianHouseValue": "number",
        "dbEducationAnyCollege": "number",

        "averageHouseholdSizeDeviation": "number",
        "medianHouseholdIncomeDeviation": "number",
        "medianDisposableIncomeDeviation": "number",
        "medianHouseValueDeviation": "number",
        "educationAnyCollegeDeviation": "number",
    })
});
