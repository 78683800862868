import Ember from "ember";
import Component from "@ember/component";
import { action, computed } from '@ember/object';

import SharedResource from "infegy-frontend/models/users/shared_resource";
import SharedResources from "infegy-frontend/models/users/shared_resources";

export default class ShareOptions extends Component {
    /**
     * input parameters
     */
    // The active user
    user = null;
    // A list of company_user models from the active user's company
    companyUsers = null;
    // The id of the resource being viewed/edited
    resourceId = 0;
    // The type of resource (e.g. workspace) being viewed/edited
    resourceType = null;

    /**
     * UI parameters
     */
    selectedCompanyUserId = null;
    showAddUserComponent = false;

    /**
     * A list of shared_resource models for the specified resourceType.
     */
    sharedResources = SharedResources.create();

    isCompanyShareDisabled = false;

    @computed("user.company.id", "sharedResources.isLoading")
    get isLoading() {
        const id = Ember.get(this, "user.company.id");
        return Ember.isEmpty(id) || this.sharedResources?.isLoading;
    }

    /**
     * A list of company_user models formatted for the
     * forms/selectize/selectize-single component.
     */
    @computed.alias("companyUsers.listOptions") companyUserOptions;

    /**
     * A list of shared_resource models for the specified resourceType that are
     * shared with another user.
     */
    @computed("sharedResources.content", "sharedResources.content.length")
    get userSharedResources() {
        let sharedUsers = this.sharedResources.content.filter((sharedResource) => {
            // shared with a user, not a company
            const username = sharedResource.userIdTo;
            return username != 0;
        });
        sharedUsers = sharedUsers.sort((a, b) => {
            if (a.nameTo > b.nameTo) {
                return 1;
            }
            else if (a.nameTo < b.nameTo) {
                return -1;
            }
            else {
                return 0;
            }
        });

        return sharedUsers;
    }

    /**
     * A shared_resource model shared with the user's company, or undefined.
     */
    @computed("sharedResources.content", "sharedResources.content.length")
    get companySharedResource() {
        let found = this.sharedResources.content.find((sharedResource) => {
            // not shared with a user, therefore it must be shared with the company
            return sharedResource.userIdTo == 0;
        });

        return found;
    }

    /**
     * True if the resource is shared with the user's company, otherwise False.
     */
    _isSharedWithCompany = undefined;
    @computed("companySharedResource")
    get isSharedWithCompany() {
        if (!Ember.isEmpty(this._isSharedWithCompany)) {
            return this._isSharedWithCompany;
        }
        return !Ember.isEmpty(this.companySharedResource);
    }
    set isSharedWithCompany(value) {
        return this._isSharedWithCompany = value;
    }

    resetAddUserComponent() {
        this.setProperties({
            selectedCompanyUserId: null,
            showAddUserComponent: false
        });
    }

    @action
    onAddUser() {
        this.set("showAddUserComponent", true);
    }

    @action
    onCancelShare() {
        this.resetAddUserComponent();
    }

    @action
    async onShareWith() {
        let sharedResource = SharedResource.create();
        try {
            await sharedResource.saveUserShare(this.resourceType, this.resourceId, this.selectedCompanyUserId);
        }
        catch(error) {
            this.set("showShareError", true);
            Ember.run.later(()=>{
                this.set("showShareError", false);
            }, 5000);
            return;
        }

        this.sharedResources.addObject(sharedResource);
        this.resetAddUserComponent();
    }

    @action
    async onShareWithCompany(selected) {
        this.set("isCompanyShareDisabled", true);
        if (selected) {
            let sharedResource = SharedResource.create();
            try {
                const company_id_to = this.get("user.company.id");
                await sharedResource.saveCompanyShare(this.resourceType, this.resourceId, company_id_to);
            }
            catch(error) {
                this.set("showShareError", true);
                Ember.run.later(()=>{
                    this.set("showShareError", false);
                }, 5000);
                return;
            }

            this.sharedResources.addObject(sharedResource);
        }
        else {
            let sharedResource = this.companySharedResource;
            try {
                await sharedResource.delete();
            }
            catch(error) {
                this.set("showRemoveShareError", true);
                Ember.run.later(()=>{
                    this.set("showRemoveShareError", false);
                }, 5000);
                return;
            }

            this.sharedResources.removeObject(sharedResource);
        }
        this.set("isCompanyShareDisabled", false);
    }

    @action
    async onUnshareWith(sharedResource) {
        try {
            await sharedResource.delete();
        }
        catch(error) {
            this.set("showRemoveShareError", true);
            Ember.run.later(()=>{
                this.set("showRemoveShareError", false);
            }, 5000);
            return;
        }

        this.sharedResources.removeObject(sharedResource);
    }
}
