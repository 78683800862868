import Route from "@ember/routing/route";

import AdminUsers from "infegy-frontend/models/admin/admin_users";

export default class QuerySetsListRoute extends Route {
    title = "Subquery Sets";
    setupController(controller, model) {
        const adminUsers = AdminUsers.create();
        controller.set("adminUsers", adminUsers);
    }
}
