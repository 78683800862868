import Ember from "ember";

export default {
    Color: function (options) {
        // var _options = Object.assign({
        //     default: null,
        //     canAlpha: true
        // }, options);
        return Ember.computed({
            get: function(key) {
                var data = this._data,
                    value = data[key];

                return value;
            },
            set: function(key, value) {
                var data = this._data,
                    existingValue = data[key];

                return existingValue;
            }
        });
    },
};
