import Ember from "ember";
export default Ember.Route.extend({
    title: "Posts",
    setupController: function(controller) {
        controller.setProperties({
            lastUpdateQueries: null,
            lastUpdateFilters: null,
            lastFetchedPosts: null,
            // The Posts page holds its own filter values so that it can make a request 
            // directly via the PostsAPI without having to modify the query's filters 
            // directly.  These filters need to be manually reset when the user navigates 
            // to this page, otherwise they will persist across multiple query runs.  a 
            // more ideal solution will require more structural changes to the query and 
            // how this page is currently built.
            postFilterQuery: "",
            lastPostFilterQuery: "",
            postFilters: {},
            channels: [],
            emotions: [],
            interests: [],
            sentiment: [],
            subjectSentiment: [],
            themes: []
        });
    }
});
