import Ember from "ember";
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import classic from "ember-classic-decorator";

@classic
export default class SentimentKeywordsContainer extends Component {
    queries = null;
    filterDateRange = null;

    // positive, negative, or neutral
    sentimentType = "positive";

    @computed("queries", "filterDateRange")
    get filteredQueries() {
        var queries = this.queries || [];

        if (!Ember.isEmpty(this.filterDateRange) && this.filterDateRange.start && this.filterDateRange.end) {
            let newQueries = queries.map((query) => {
                let newQuery = query.copy(),
                    queryInfo = newQuery && newQuery.get("queryInfo"),
                    gDate = queryInfo.get("startDate");

                gDate.set("isRelative", false);
                gDate.get("fixed").fromTimestamp(this.filterDateRange.start);

                gDate = queryInfo.get("endDate");
                gDate.set("isRelative", false);
                gDate.get("fixed").fromTimestamp(this.filterDateRange.end);

                return newQuery;
            });
            queries = newQueries;
        }

        return queries;
    };

    @computed("filteredQueries", "sentimentType")
    get drilldownQueries() {
        return this.filteredQueries.map((query) => {
            let drilldownQuery = query.copy();
            drilldownQuery.queryInfo.addAdditionalAPIParameters({
                sub_sentiment: this.sentimentType
            });
            return drilldownQuery;
        });
    }
};
