import Ember from "ember";
import ComputedSortField from "infegy-frontend/utils/computed-sort-field";
import CustomDataset from "infegy-frontend/models/users/custom-dataset";

export default Ember.Component.extend({
    datasets: [],
    refreshDatasest:null,
    sortAscending: true,
    sortField: "title",
    currentPage: 0,
    datasetsPerPage: 20,

    selectedItemId:null,
    checkedItemIds: [],
    editingItem: false,

    titleSort: ComputedSortField("title"),
    totalPostsSort: ComputedSortField("totalPosts"),
    totalBytesSort: ComputedSortField("totalBytes"),
    createdDateSort: ComputedSortField("createdDate"),
    updatedDateSort: ComputedSortField("updatedDate"),
    statusSort: ComputedSortField("pctContentAvailable"),

    sortedDatasets: Ember.computed("datasets","sortField", "sortAscending", "currentPage", "datasetsPerPage", function(){
        var datasets = this.datasets,
            sortField = this.sortField,
            sortAscending = this.sortAscending;
        const currentPage = this.currentPage;
        const datasetsPerPage = this.datasetsPerPage;
        const startIndex = currentPage * datasetsPerPage;
        if(sortAscending){
            return datasets.sortBy(sortField).slice(startIndex, startIndex + datasetsPerPage);
        } else {
            return datasets.sortBy(sortField).reverse().slice(startIndex, startIndex + datasetsPerPage);
        }
    }),

    paginatedDatasets: Ember.computed("sortedDatasets", "currentPage","datasetsPerPage", function(){
        var datasets = this.sortedDatasets,
            currentPage = this.currentPage,
            datasetsPerPage = this.datasetsPerPage,
            currentIndex = currentPage * datasetsPerPage;
        return datasets.slice(currentIndex, currentIndex + datasetsPerPage);
    }),

    selectedDatasets: Ember.computed.filterBy("datasets","isSelected"),

    saveCustomDataset: () => {},
    deleteCustomDataset: () => {},
    appendToDataset: () => {},
    actions: {
        sortBy: function(newSortField) {
            var sortField = this.sortField;
            if (newSortField === sortField) {
                this.toggleProperty("sortAscending");
            } else {
                this.setProperties({
                    sortField: newSortField,
                    sortAscending: newSortField === "title"
                });
            }
        },
        toggleEditingItem(dataset){
            var newDataset = CustomDataset.create();
            newDataset.loadJSON(dataset.toJSON());
            this.set("editingItem", newDataset);
        },
        saveItem(){
            var newDataset = this.editingItem;
            this.saveCustomDataset(newDataset.toJSON());
            this.set("editingItem", null);
        },
        cancelEditingItem(){
            this.set("editingItem",null);
        },
        deleteCustomDataset(dataset){
            this.deleteCustomDataset(dataset.toJSON());
            this.set("editingItem", null);
        },
    }
});
