import Ember from "ember";
import JsonStore from 'infegy-frontend/json-store/model';
import Prop from 'infegy-frontend/json-store/properties/model';

import AtlasMath from "infegy-frontend/utils/atlas-math";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import QueryUtil from "infegy-frontend/models/queries/query_util";
import URLBase64 from "infegy-frontend/utils/encoding";
import SavedQueriesApi from "infegy-frontend/models/saved_queries/saved_queries_api";
import EntityObject from "infegy-frontend/models/entities/entity-item";
import CustomDataset from "infegy-frontend/models/users/custom-dataset";

var atlasApiFields = ["query", "entity_query_parts", "custom_dataset", "source_query", "title", "deleted", "is_saved", "is_snippet"];


var SavedQuery = JsonStore.extend({
    id: Prop.Int(),
    hashKey: Prop.String(),

    userId: Prop.Int(),

    title: Prop.String(),
    query: Prop.String(),
    sourceQuery: Prop.String(),
    customDatasetId: Prop.String(),
    entityQueryParts: Prop.Collection(EntityObject),
    isShared: Prop.Boolean(),
    queryString: Ember.computed.alias("query"),

    sourceQueryString: Ember.computed.alias("sourceQuery"),
    isSharing: Prop.Boolean(),

    entityQuery: Ember.computed("entityQueryParts","entityQueryParts.length",function(){
        var parts = this.entityQueryParts;
        return parts.mapBy("value").join(" ");
    }),

    isSaved: Prop.Boolean(),
    isSnippet: Prop.Boolean(),
    deleted: Prop.Int(),


    runCount: 0,
    lastDate: 0,
    createdDate: 0,

    displayTitle: Ember.computed("title", "query", function() {
        if (this.title) {
            return this.title;
        }
        return QueryUtil.queryToTitle(this.query, this.entityQueryParts);
    }),

    isAudienceQuery: Ember.computed("queryString", "entityQuery", "customDatasetId", "sourceQuery", function(){
        return Ember.isEmpty(this.queryString) &&
                Ember.isEmpty(this.entityQuery) &&
                Ember.isEmpty(this.customDatasetId) &&
                !Ember.isEmpty(this.sourceQuery);
    }),

    hashId: Ember.computed("title", "query", {
        get: function() {
            this.updateFromObj();
            var hashKey = this.query || "";
            if (!hashKey) {
                return null;
            }
            if (this.title) {
                hashKey = [hashKey, this.title].join("~~");
            }
            hashKey = URLBase64.encode(hashKey);
            return hashKey;
        },
        set: function(key, value) {
            this.updateFromObj();
            this.set("savedHash", value);
            var hashKey = this.query || "";
            if (!hashKey) {
                return null;
            }
            if (this.title) {
                hashKey = [hashKey, this.title].join("~~");
            }
            hashKey = URLBase64.encode(hashKey);
            return hashKey;
        }
    }),

    createdTimestamp: Ember.computed("createdDate", {
        get: function() {
            return (this.createdDate || 0) * 1000;
        },
        set: function(key, value) {
            this.set("createdDate", AtlasMath.toInt(value/1000));
            return value;
        }
    }),

    copy: function() {
        var savedQuery = SavedQuery.create();
        savedQuery.loadJSON(this.toJSON());
        return savedQuery;
    },

    atlasQueryJSON: Ember.computed("query", "entityQueryParts", "sourceQuery", "customDatasetId", "title", "deleted", "isSaved", {
        get: function() {
            var queryJSON = this.toJSON();
            queryJSON = atlasApiFields.reduce((memo, apiField) => {
                if (!Ember.isEmpty(queryJSON[apiField])) {
                    memo[apiField] = queryJSON[apiField];
                }
                return memo;
            }, {});
            return queryJSON;
        }
    }),

    save: async function() {
        var queryJSON = this.atlasQueryJSON;
        var request = SavedQueriesApi.saveQueryJSONs([queryJSON]);
        return request.then(response => {
            var queries = response && response.output,
                newQueryJSON = queries && queries[0];
            this.clear();
            this.loadJSON(newQueryJSON);
        });
    },

    async update() {
        return AtlasAuth.Put({
            url: "api/v3/user_queries.json",
            data: {
                id: this.id,
                title: this.title,
                query: this.query
            }
        }).then((response) => {
            this.loadJSON(response.output[0]);
        });
    },

    async delete() {
        return AtlasAuth.Delete({
            url: "api/v3/user_queries",
            data: { id: this.id }
        }).catch((response) => {
            console.error(`Failed to delete saved_query: ${response.atlasErrorText}`);
            throw response;
        });
    },

    async saveCopy(newTitle) {
        let title = newTitle;
        if (Ember.isEmpty(newTitle)) {
            title = `${this.title} (copy)`;
        }
        return new Promise(async (resolve, reject) => {
            let response = await AtlasAuth.Post({
                url: 'api/v3/user_queries.json',
                data: {
                    title,
                    source_id: this.id
                }
            });

            let newSavedQuery = SavedQuery.create();
            newSavedQuery.loadJSON(response.output[0]);
            resolve(newSavedQuery);
        });
    },

    loadJSON:function(jsonData){
        if(!Ember.isEmpty(jsonData["entity_query_parts"])){
            jsonData["entity_query_parts"] = JSON.parse(jsonData["entity_query_parts"] || "");
            let entityQueryParts = this.entityQueryParts;
            entityQueryParts.loadJSON(jsonData["entity_query_parts"]);
            delete jsonData["entity_query_parts"];
        }
        this._super(jsonData);
    }
});

export default SavedQuery;
