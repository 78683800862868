import Component from '@ember/component';
import { computed } from '@ember/object';
import classic from 'ember-classic-decorator';
import arrayUtil from 'infegy-frontend/utils/array-util';

@classic
export default class PostEmbeds extends Component {
    queries = [];
    sortKey = 'none';
    postFilterQuery = '';
    postFilters = {};
    pageSize = 10;

    @computed('queries', 'queries.length', 'queries.@each.updated')
    get embedQueries() {
        // Add embeds parameter to all queries
        const embededQueries = this.queries.map(query => {
            let newQuery = query.copy();
                newQuery.queryInfo.addAdditionalAPIParameters({
                    embeds: 1,
                    sub_query: this.postFilterQuery,
                    sort: this.sortKey,
                    limit: 250,
                    ...this.postFilters
                });
            return newQuery;
        });

        return embededQueries;
    }

    @computed('embedQueries', 'embedQueries.length', 'embedQueries.@each.updated')
    get embeds() {
        // Combine embeds from all queries together and filter the empty strings.
        const embeddedPosts = this.embedQueries.reduce((memo, query) => {
            if (query.embeds.data) {
                const queryEmbeds = query.embeds.data.filter(item => item !== "");
                memo = memo.concat(queryEmbeds);
            }
            // Shuffle the posts if the sort key is set to none. This will mix posts when using multiple queries.
            return this.sortKey === "none" ? arrayUtil.shuffle(memo) : memo;
        }, []);

        return embeddedPosts;
    }
}