import Ember from 'ember';
import DataSeriesComputedFieldPercentage from "infegy-frontend/models/data_series/data_series_computed_field_percentage";
import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";
import Languages from "infegy-frontend/static_data/query/query_filters/languages";

var availableLanguageCodes = Languages.activeLanguageCodes,
    languagesBaseFields = {
        "date": "datestring",
        "documents": "number",
    };

export default QueryAPIResults.extend({
    title: "languages",
    availableLanguages: [],
    customEmptyCheckFunction: function(dataSeries) {
        var stats = dataSeries && dataSeries.get("stats");
        return !stats || !stats.get("documents") || !stats.get("documents.sum");
    },
    description: DataSeriesDescription.load(languagesBaseFields),
    preload: function() {
        var rawResponse = this.rawResponseRow,
            output = rawResponse && rawResponse.output || {},
            baseFields = Object.assign({}, languagesBaseFields),
            summary = {},
            languages = [],
            computedFields = [];

        summary = output.reduce(function(memo, row) {
            for (var field in row) {
                if (row.hasOwnProperty(field) && field && !memo[field]) {
                    memo[field] = true;
                }
            }
            return memo;
        }, {});

        for (var langCode in summary) {
            if (summary.hasOwnProperty(langCode) && availableLanguageCodes.includes(langCode)) {
                langCode = "" + langCode;
                languages.push(langCode);
                baseFields[langCode] = "number";
                computedFields.push(DataSeriesComputedFieldPercentage.fieldSetup(
                            [langCode, "DocumentsDistribution"].join(""),
                            "documents",
                            langCode));
            }
        }

        var description = DataSeriesDescription.load(baseFields);
        description.addComputedFields(computedFields);
        this.set("availableLanguages", languages);
        this.set("description", description);
    },
});
