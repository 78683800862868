import Ember from "ember";
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { run } from '@ember/runloop';
import FullFieldSelectorBase from "infegy-frontend/components/field-selector/selector-base/component";

export default class TrendableFieldSelectorSingle extends FullFieldSelectorBase {
    classNameBindings = ["displayAsDropdown:inline-field-select:toggle-field-select"];

    selectedQueryId = null;

    selectedField = null;

    selectedColorString = null;

    showColorPicker = false;

    showQueryPicker = true;

    @computed("selectedColorString", "selectedField.color")
    get colorString() {
        if (this.selectedField && this.selectedField.color) {
            return this.selectedField.color.colorString;
        }
        return this.selectedColorString;
    }
    set colorString(colorString) {
        if (this.selectedColorString !== colorString) {
            this.set("selectedColorString", colorString);
        }
        if (this.selectedField) {
            if (this.selectedField.selectedColor !== colorString) {
                let field = this.selectedField;
                Ember.set(field, "selectedColor", colorString);
            }
            colorString = this.selectedField.color.colorString;
        }
        return colorString;
    }

    @computed("selectedField", "selectedSegment", "selectedSubsegment", "selectedFields",
        "innerQueries", "selectedQueryId", "query", "queries", "innerQueries.@each.lookupId")
    get isValidatingFields() {
        let selectedField = this.selectedField,
            availableQIDs = this.innerQueries && this.innerQueries.mapBy("lookupId") || [];
        if (selectedField && (selectedField.segment !== this.selectedSegment ||
                selectedField.subsegment !== this.selectedSubsegment ||
                selectedField.fieldComponent !== this.selectedFieldComponent ||
                (this.get("innerQueries.length") && selectedField.queryId !== this.selectedQueryId) ||
                !availableQIDs.includes(this.selectedQueryId) || !availableQIDs.includes(selectedField.queryId))) {
            run.next(()=>{
                if (this && !this.isDestroyed && !this.isDestroying) {
                    this.set("selectedSegment", selectedField.segment);
                    this.set("selectedSubsegment", selectedField.subsegment);
                    this.set("selectedFieldComponent", selectedField.fieldComponent);
                    let selectedQueryId = this.selectedQueryId;
                    if (!availableQIDs.includes(selectedQueryId)) {
                        selectedQueryId = this.innerQueries && this.innerQueries[0] && this.innerQueries[0].lookupId;
                        this.set("selectedQueryId", selectedQueryId);
                    }
                    if (this.get("innerQueries.length") && selectedField.queryId !== selectedQueryId) {
                        if (this.innerQueries.length > 1 && this.innerQueries.find(query => query.lookupId === selectedField.queryId)) {
                            this.set("selectedQueryId", selectedField.queryId);
                        } else {
                            this.set("selectedField.query", this.innerQueries[0]);
                            this.set("selectedQueryId", selectedField.queryId);
                        }
                    }
                }
            });
            return true;
        }
    }

    findQueryById(queryLookupId) {
        if (!Ember.isEmpty(queryLookupId) && this.innerQueries && this.get("innerQueries.length")
                && this.get("innerQueries.length") >= 1) {
            let query = this.innerQueries.find((query, index) => {
                return query && ((query.lookupId === queryLookupId) || index === queryLookupId);
            })
            if (query) {
                return query;
            }
        }
    };

    @computed("selectedQueryId", "innerQueries", "firstQuery")
    get selectedQuery() {
        if (this.get("queries.length") === 1) {
            if (this.selectedQueryId !== this.firstQuery.lookupId) {
                run.next(()=>{
                    this.queryIdChanged(this.firstQuery.lookupId);
                });
            }
            return this.firstQuery;
        }
        let selectedQuery = this.findQueryById(this.selectedQueryId);
        return selectedQuery;
    }

    // Internal use only
    @computed("selectedField")
    get selectedFields() {
        return this.selectedField ? [this.selectedField] : null;
    };
    set selectedFields(fields) {
        let field = null;
        if (fields && Ember.isArray(fields) && fields.length) {
            field = fields[0];
        }
        this.fieldChanging(field);
        return [this.selectedField];
    };

    @computed("selectedFields", "selectedFields.[]", "selectedFields.@each.query")
    get firstSelectedField() {
        return this.selectedField;
    }

    fieldChanging(selectedField) {
        if (selectedField && this.selectedColorString && selectedField.selectedColor != this.selectedColorString) {
            run.next(()=>{
                Ember.set(selectedField, "selectedColor", this.selectedColorString);
                this.fieldChanged(selectedField);
            });
        }
        this.fieldChanged(selectedField);
    }

    fieldChanged(selectedField) { /* EVENT */ };

    @computed("innerQueries", "innerQueries.[]", "selectedField", "selectedField.query")
    get fieldQueries() {
        if (this.selectedField?.query) {
            return [this.selectedField.query];
        }
        return this.innerQueries;
    }

    @action
    updateFields(selectedFields) {
        if (selectedFields && Ember.isArray(selectedFields) && selectedFields.length) {
            let field = selectedFields[0];
            if (field.query !== this.selectedQuery) {
                field.set("query", this.selectedQuery);
            }
            this.fieldChanging(selectedFields[0]);
        } else {
            this.fieldChanging(null);
        }
    };

    @action
    queryIdChanged(selectedQueryId) {
        if (!Ember.isEmpty(selectedQueryId) && this && !this.isDestroyed && !this.isDestroying) {
            let newQuery = this.findQueryById(selectedQueryId);
            if (newQuery && this.selectedField && this.selectedField.query !== newQuery) {
                this.selectedField.set("query", newQuery);
                run.next(()=>{
                    this.updateFields(this.selectedFields);
                });
            }
        }
        if (selectedQueryId !== this.selectedQueryId) {
            this.set("selectedQueryId", selectedQueryId);
        }
    };

    @action
    colorChanged(colorString) {
        if (this.selectedColorString !== colorString) {
            this.set("colorString", colorString);
            run.next(()=>{
                this.updateFields(this.selectedFields);
            });
        }
    }
};
