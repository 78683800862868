import Ember from "ember";
import SavedQuery from "infegy-frontend/models/saved_queries/saved_query";
import ArrayBase from "infegy-frontend/models/array_compat";
import SavedQueriesApi from "infegy-frontend/models/saved_queries/saved_queries_api";
import QueryUtil from "infegy-frontend/models/queries/query_util";

export default ArrayBase.extend({
    isLoadingFavorited: false,
    isLoadingUnFavorited: false,
    isLoadingSnippets: false,
    isLoading: Ember.computed.or("isLoadingFavorited", "isLoadingUnFavorited"),

    favoritedQueries: Ember.computed("content", "[]", "@each.isSaved", function() {
        return this.filter(function(queryMeta) {
            return queryMeta.get("isSaved") && !queryMeta.get("deleted");
        });
    }),

    unFavoritedQueries: Ember.computed("content", "[]", "@each.isSaved", "@each.deleted", function() {
        return this.filter(function(queryMeta) {
            return !queryMeta.get("isSaved") && !queryMeta.get("isSnippet") && !queryMeta.get("deleted");
        });
    }),

    querySnippets: Ember.computed("content", "[]", "@each.isSnippet", "@each.deleted", function() {
        return this.filter(function(queryMeta) {
            return queryMeta.get("isSnippet") && !queryMeta.get("deleted");
        });
    }),

    fetchFavorites: function() {
        this.set("isLoadingFavorited", true);
        SavedQueriesApi.fetchFavorites().then(data => {
            this.parseApiResponse(data);
        }).finally(() => {
            this.set("isLoadingFavorited", false);
        });
    },

    fetchUnfavorites: function(limit) {
        this.set("isLoadingUnfavorited", false);
        SavedQueriesApi.fetchUnfavorites(limit).then(data => {
            this.parseApiResponse(data);
        }).finally(() => {
            this.set("isLoadingUnfavorited", false);
        });
    },

    fetchSnippets: function(limit) {
        this.set("isLoadingSnippets", true);
        SavedQueriesApi.fetchSnippets(limit).then(data => {
            this.parseApiResponse(data);
        }).finally(() => {
            this.set("isLoadingSnippets", false);
        });
    },
    
    fetchAll: function() {
        this.fetchFavorites();
        this.fetchUnfavorites();
        this.fetchSnippets();
    },

    indexedByHashAndID: Ember.computed("[]", function() {
        var queries = {ids: [], hashes: []};

        this.reduce(function(memo, query) {
            var hashKey = query.get("hashKey"),
                id = query.get("id");
            memo.hashes[hashKey] = query;
            if (id) {
                memo.ids[id] = query;
            }
            return memo;
        }, queries);
        return queries;
    }),
    
    parseApiResponse: function(response) {
        var indexedQueries = this.indexedByHashAndID,
            apiResults = (response && response.output) || [],
            newQueries = [];

        apiResults.forEach(function(rawQuery) {
            var hashKey = rawQuery.hash_key,
                qid = rawQuery.id,
                existingHashQuery = indexedQueries.hashes[hashKey],
                existingIdQuery = indexedQueries.ids[qid];
            if (existingIdQuery) {
                existingIdQuery.toJSON(rawQuery);
            } else if (existingHashQuery && !existingHashQuery.get("id")) {
                existingHashQuery.toJSON(rawQuery);
            } else {
                var newQueryMeta = SavedQuery.create();
                newQueryMeta.loadJSON(rawQuery);
                newQueries[newQueries.length] = newQueryMeta;
            }
        }, this);

        newQueries = newQueries.concat(this.content).filter(function(queryObj) {
            return !queryObj.get("deleted") && queryObj.get('id');
        });

        this.set("content", newQueries);
        this.unFavoritedQueries;
        this.favoritedQueries;
    },

    saveAtlasQuery: function(query) {
        return this.saveAtlasQueries([query]);
    },
    
    saveAtlasQueries: function(queries) {
        var queryJSONs = (queries || []).map(query => {
            return query.get("savedQueryJSON");
        });
        this.saveQueryJSONs(queryJSONs);
    },
    
    saveAndToggleQueryFavorite: function(query, isSaved) {
        var queryJSON = query.get("savedQueryJSON"),
            savedQuery = this.findMatchingQuery(query);
        if (savedQuery) {
            queryJSON = savedQuery.get("atlasQueryJSON");
            if(Ember.isEmpty(isSaved)){
                savedQuery.toggleProperty("isSaved");
            } else {
                savedQuery.set("isSaved",!isSaved);
            }
            queryJSON.is_saved = savedQuery.get("isSaved");
        }else {
            queryJSON.is_saved = !queryJSON.is_saved;    
        }
        
        this.saveQueryJSONs([queryJSON]);
    },

    saveQueryJSONs: function(queryJSONs) {
        if (Ember.isEmpty(queryJSONs)) {
            return;
        }
        queryJSONs = queryJSONs.map(queryJSON => {
            var existingQuery = this.findMatchingQuery(queryJSON);
            if (existingQuery) {
                existingQuery.loadJSON(queryJSON);
                queryJSON = existingQuery.get("atlasQueryJSON");
            }
            return queryJSON;
        });
        var request = SavedQueriesApi.saveQueryJSONs(queryJSONs);
        if (request) {
            request.then((response) => {
                this.parseApiResponse(response);
            });
        }
    },

    findMatchingQuery: function(query) {
        return this.find((savedQuery) => {
            return QueryUtil.checkIfQueriesMatch(query,savedQuery);
        });
    },

    addQueryObj: function(queryObj) {
        if (!queryObj) {
            return;
        }
        var indexedQueries = this.indexedByHashAndID,
            hashKey = queryObj.get("hashKey"),
            qid = queryObj.get("id"),
            existingHashQuery = indexedQueries.hashes[hashKey],
            existingIdQuery = indexedQueries.ids[qid];

        if (existingIdQuery) {
            if (existingIdQuery.get("hashKey") === hashKey) {
                existingIdQuery.loadJSON(queryObj.toJSON());
                return existingIdQuery;
            } else {
                queryObj.set("id", null);
                this.addObject(queryObj);
                return queryObj;
            }
        }

        if (existingHashQuery) {
            existingHashQuery.loadJSON(queryObj.toJSON());
            return existingHashQuery;
        } else {
            this.addObject(queryObj);
            return queryObj;
        }
    },

    removeQueryObj: function(queryMeta) {
        if (!queryMeta) {
            return;
        }
        var indexedQueries = this.indexedByHashAndID,
            hashKey = queryMeta.get("hashKey"),
            qid = queryMeta.get("id"),
            existingHashQuery = indexedQueries.hashes[hashKey],
            existingIdQuery = indexedQueries.ids[qid];
        if (existingIdQuery) {
            this.removeObject(existingIdQuery);
        } else if (existingHashQuery) {
            this.removeObject(existingHashQuery);
        }
        this.removeObject(queryMeta);
    }
});
