import Ember from "ember";
import QueryURL from "infegy-frontend/models/queries/query_url";
import BrowserStore from "infegy-frontend/utils/browser-store";
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { computed } from '@ember/object';
import QueryUtil from "infegy-frontend/models/queries/query_util";

export default class extends Ember.Controller{
    @Ember.inject.controller() atlas;
    @Ember.inject.controller() application;
    @Ember.inject.controller("atlas/dashboard") editor;
    @computed.alias("editor.showEditor") showEditor;
    @service() router;
    @computed.readOnly("router.currentRouteName") routeName;
    @computed.alias("application.user") user;
    @computed.readOnly("editor.queries") queries;

    inAppQueryEditorQueries = [];
    usedDeprecatedURL = false;

    @computed.alias("user.savedQueries") savedQueries;
    showEditorToggle = false;

    @computed("selectedQueryObjects")
    get dayGroupedQueries(){
        return this.selectedQueryObjects.map((query) => {
            var q = query.copy();
            q.queryInfo.set("selectedGrouping","day");
            return q;
        });
    }

    @computed("dayGroupedQueries","selectedQueryIndices")
    get query(){
        var queries = this.dayGroupedQueries ?? [];
        return queries[0];
    }

    @computed("queries","queries.@each.updated")
    get queryOptions(){
        var queries = this.queries || [];
        return queries.map(function(item, index) {
            var ret =  Ember.Object.create({
                value: index+'',
                label: item.get("title"),
                colorClass: item.get("colors.class")
            });
            return ret;
        });
    }

    @BrowserStore.Attr("selected-query-indicies", { defaultValue: [0] }) _selectedQueryIndices;
    @computed("_selectedQueryIndices", "queries.length")
    get selectedQueryIndices(){
        let queryIndicies = this.limitQueryIndices(this._selectedQueryIndices);
        this.set("_selectedQueryIndices",queryIndicies);
        return queryIndicies;
    }
    set selectedQueryIndices(value){
        let queryIndicies = this.limitQueryIndices(value);
        this.set("_selectedQueryIndices",queryIndicies);
    }

    @computed("selectedQueryIndices","selectedQueryIndices.[]", "queries")
    get selectedQueryObjects(){
        let selectedQueries = this.selectedQueryIndices,
            queries = this.queries.filter((q,idx) => selectedQueries.includes(idx));
        return queries.map(query => {
            return query.copy();
        });
    }

    @computed("selectedQueryObjects", "selectedQueryObjects.@each.datasetQueryInfo")
    get availableQueryFields() {
        const result = QueryUtil.getAvailableQueryFields(this.selectedQueryObjects);
        return result;
    }

    @computed("selectedQueryObjects", "selectedQueryObjects.[]")
    get hasOnlyCustomDatasets(){
        return (this.selectedQueryObjects || []).find(q => q.queryInfo?.customDatasetId);
    }

    @computed("selectedQueryObjects", "selectedQueryObjects.[]")
    get hasQuerySets(){
        var queries = this.selectedQueryObjects;
        return queries && queries.find((q) => {
            return q.get("queryInfo.subquerySets.length");
        });
    }

    limitQueryIndices(selectedQueryIndices){
        var numQueries = this.get("queries.length");
        selectedQueryIndices = selectedQueryIndices.filter((idx)=> idx < numQueries);
        if(Ember.isEmpty(selectedQueryIndices)){
            selectedQueryIndices = [0];
        }
        return selectedQueryIndices;
    }

    @computed("dayGroupedQueries", "dayGroupedQueries.[]", "dayGroupedQueries.@each.updated")
    get isLowVolume(){
        var query = this.get("dayGroupedQueries.firstObject"),
            resultCount = query.get("volume.meta.totalResults"),
            dayCount  = query.get("volume.daily.data.length"),
            grouping = query.get("volume.grouping"),
            threshold = dayCount * 1.5;
        if(Ember.isEmpty(resultCount) || Ember.isEmpty(dayCount)) {
            return false;
        }

        if (grouping === "yearly") {
            return resultCount < Math.min(Math.max((threshold / 365), 30), 1000);
        } else if (grouping === "monthly") {
            return resultCount < Math.min(Math.max((threshold / 31), 30), 1000);
        } else if (grouping === "weekly") {
            return resultCount < Math.min(Math.max((threshold / 7), 30), 1000);
        } else {
            return resultCount < Math.min(Math.max((threshold), 30), 1000);
        }
    }

    cloneQueries(queries) {
        return queries.map((query) => {
            return query.copy();
        });
    }

    @action
    selectedQueriesChanged(selectedQueries){
        this.set("selectedQueryIndices", selectedQueries.slice());
        this.send("buildQueryTitle");
    }

    @action
    submitQueries(queries){
        //Update the model
        this.transitionToRoute(this.routeName,QueryURL.queryArrayToUrl(queries));
        //Force the model to refresh
        this.send("invalidateModel");
    }

    @action
    displaySupportRequest(){
        this.atlas.toggleProperty('isDisplayingSupportRequest');
    }

    @action
    redirectToLogin(){
        this.transitionToRoute("login");
    }

    @action
    cancelQueryEditing() {
        this.setProperties({
            inAppQueryEditorQueries: this.cloneQueries(this.queries)
        });
    }

    @action
    setGrouping(grouping){
        let newQueries = this.queries.map(query => {
            let newQuery = query.copy();
            newQuery.set("queryInfo.selectedGrouping", grouping);
            return newQuery;
        });
        this.submitQueries(newQueries);
    }
}
