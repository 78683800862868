import Ember from "ember";

export default Ember.Component.extend({
    tagName: "tr",
    classNameBindings: [":data-table-row", "isEven:data-table-row-even"],
    viewField: "DocumentsDistribution",

    emotion: null,
    query: null,
    emotionData: Ember.computed("sortedEmotionData", "query", function () {
        var data = this.sortedEmotionData,
            query = this.query;
        data = data.map(emotion => {
            var queryEmotion = (Ember.get(emotion, "queriesData") || []).find(qData => {
                return Ember.get(qData, "query") === query;
            });
            return queryEmotion;
        });
        return data;
    }),
});
