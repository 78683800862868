import Ember from "ember";
import Component from '@ember/component';
import {computed, action} from "@ember/object";
import classic from "ember-classic-decorator";

@classic
export default class extends Component {
    trendFields = null;
    trendChartMeta = null;
    interactedEventData = null;

    @computed("interactedEventData")
    get interactedHashtags() {
        let eventData = this.interactedEventData.events || [];
        return eventData.filter(row => row.attributeType === "hashtag");
    }

    @computed("interactedEventData")
    get interactedShares() {
        let eventData = this.interactedEventData.events || [];
        return eventData.filter(row => row.attributeType === "share");
    }

    @computed("interactedHashtags", "interactedShares")
    get hasEvents() {
        return (this.interactedHashtags && this.interactedHashtags.length) ||
            (this.interactedShares && this.interactedShares.length);
    }

}
