import Ember from "ember";
import JsonStore from 'infegy-frontend/json-store/model';
import Prop from 'infegy-frontend/json-store/properties/model';
import FieldProp from 'infegy-frontend/json-store/properties/fields/model';
import QueryDateAttr from "infegy-frontend/models/queries/filters/query_date";
import classic from "ember-classic-decorator";

@classic
export default class CustomTrendField extends JsonStore {
    @FieldProp.Field({defaultField: {
            "fieldClass": "trend-field",
            "api_name": "volume",
            "segment": "Posts",
            "subsegment": "Universe",
            "field_component": "field-selector/parts/fields/posts",
            "field_name": "postsNormalizedUniverse",
            "title": "Universe",
            "value": "volume.postsNormalizedUniverse",
            "field_type": "trend",
            "query_id": 0
        }}) field;


    @Prop.Boolean() usesCustomDates;
    @Prop.Object(QueryDateAttr) customStartDate;
    @Prop.Object(QueryDateAttr) customEndDate;
};
