import Ember from "ember";

export default Ember.Component.extend({
    classNames: ["icon-view-selector"],

    hasMoreThanOneQuery: false,
    topicsViewType: "cloud",

    isMultiCloud: Ember.computed.equal("topicsViewType", "multi-cloud"),
    isCloud: Ember.computed.equal("topicsViewType", "cloud"),
    isChange: Ember.computed.equal("topicsViewType", "change"),
    isTable: Ember.computed.equal("topicsViewType", "table"),
    isTopicCloud: Ember.computed.equal("topicsViewType", "topic-cluster"),

    changed: () => {},
    actions: {
        topicsViewSelected: function (viewType) {
            this.changed(viewType);
        }
    }
});
