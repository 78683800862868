import Component from "@ember/component";
import { action, computed } from "@ember/object";
import { isEmpty, isNone } from "@ember/utils";
import classic from "ember-classic-decorator";

const contentTypeMap = {
    boolean: { label: "Boolean", value: "boolean", uniqueKey: "boolean" },
    decimal: { label: "Number", value: "decimal", uniqueKey: "number" },
    integer: { label: "Number", value: "integer", uniqueKey: "number" },
    keywords: { label: "Keywords", value: "keywords", uniqueKey: "keywords" },
    keyword: { label: "Keyword", value: "keyword", uniqueKey: "keyword" },
    latitude_longitude: { label: "Geolocation", value: "latitude_longitude", uniqueKey: "geolocation" },
    text: { label: "Text", value: "text", uniqueKey: "text" },
    timestamp: { label: "Date", value: "timestamp", uniqueKey: "date" }
}

// in the future the widget "type" may be used to create a unique widget type
const contentTypeTraits = {
    decimal: [
        { alwaysVisible: true, name: "unit", label: "Unit", type: "const_string", value: "none" }
    ],
    keywords: [
        { alwaysVisible: true, name: "normalize", label: "Normalize", type: "boolean", value: true, options: [{label: "True", value: true}, {label: "False", value: false}] }
    ],
    keyword: [
        { alwaysVisible: true, name: "normalize", label: "Normalize", type: "boolean", value: true, options: [{label: "True", value: true}, {label: "False", value: false}] },
        { alwaysVisible: true, name: "contains", label: "Contains", type: "enum", value: "none", options: [{label: "None", value: "none"}, {label: "Name", value: "name"}, {label: "URL", value: "uri"}, {label: "Gender", value: "gender"}, {label: "Location", value: "location"}, {label: "US State", value: "us_state"}, {label: "Zipcode", value: "zip_code"}, {label: "Country", value: "country"}] }
    ],
    text: [
        { alwaysVisible: true, name: "analyze", label: "Analyze", type: "boolean", value: true, options: [{label: "True", value: true}, {label: "False", value: false}] },
        { alwaysVisible: true, name: "contains", label: "Contains", type: "enum", value: "none", options: [{label: "None", value: "none"}, {label: "Name", value: "name"}, {label: "URL", value: "uri"}, {label: "Gender", value: "gender"}, {label: "Location", value: "location"}, {label: "US State", value: "us_state"}, {label: "Zipcode", value: "zip_code"}, {label: "Country", value: "country"}] }        
    ]
}

@classic
export default class extends Component {
    classNameBindings = [":custom-descriptor-column"];
    // Input
    column = null;

    contentTypeOptionsMap = contentTypeMap;
    contentTypeTraitConfigs = contentTypeTraits;

    isDisplayingTraits = false;
    isEditingName = false;
    newColumnName = "";

    /*
    To make the UI simpler for the user, some content types are "squished" into a single, 
    easier to understand, option.  A good example of this being `decimal` and `integer` 
    being displayed to the user as "Number".  In this case, the underlying type of this 
    simplified option should be the most likely possible type presented by the API.  A 
    `uniqueKey` is used to identify multiple content types that should be "squished" together.
    */
    @computed("column.possibleTypes")
    get contentTypeOptions() {
        let options = [];
        for (const type of this.column.possibleTypes) {
            const possibleOption = this.contentTypeOptionsMap[type];
            // The API sends possible options in priority order, so the first one found 
            // is the one the UI should be using.
            const found = options.find((option) => option.uniqueKey === possibleOption.uniqueKey);
            if (isEmpty(found)) {
                options.push(possibleOption);
            }
        }
        return options;
    }

    @computed.alias("column.type") selectedContentType;

    @computed("selectedContentType")
    get contentTypeTraits() {
        const traitConfigs = this.contentTypeTraitConfigs[this.selectedContentType];
        const traits = (traitConfigs || []).map((config) => {
            const trait = Object.assign({}, config);
            // It is possible the API has detected probable trait default values.  If this 
            // is the case, the default selected value should be set based on the API 
            // suggestion instead of the UI default.
            if (this.column.probableTraits?.hasOwnProperty(config.name)) {
                trait.value = this.column.probableTraits[config.name];
            }
            return trait;
        });
        this.set("column.traits", traits);
        return traits;
    }

    @computed.gt("contentTypeTraits.length", 0) hasTraits;

    @computed("contentTypeTraits")
    get hasHiddenTraits() {
        const found = this.contentTypeTraits.find((trait) => trait.alwaysVisible ? false : true );
        return !isNone(found);
    }

    @action onContentTypeChange(value) {
        this.set("selectedContentType", value);
        this.set("column.type", value);
    }

    @action onToggleTraitsDisplay() {
        this.toggleProperty("isDisplayingTraits");
    }

    @action onToggleIgnoreColumn() {
        this.toggleProperty("column.exclude");
        this.set("isEditingName", false);
    }

    @action onEditTitle() {
        if (this.column.exclude) {
            return;
        }
        this.setProperties({
            isEditingName: true,
            newColumnName: this.column.name,
            placeholderName: this.column.name
        });
    }

    @action onCancelEditTitle() {
        this.set("isEditingName", false);
    }

    @action onSaveTitle() {
        this.column.name = this.newColumnName;
        this.set("isEditingName", false);
    }
}
