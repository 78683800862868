import Controller, { inject as controller } from '@ember/controller';
import { action, computed } from "@ember/object";
import { isEmpty } from "@ember/utils";
import { readOnly } from "@ember/object/computed";

export default class extends Controller {
    @controller application;
    @readOnly("application.user") user;

    @controller("atlas.tools.custom-datasets.append") appendController;
    @readOnly("appendController.document") document;
    @readOnly("appendController.customDataset") customDataset;
    @readOnly("document.sheets") sheets;

    ignoreErrors = false;
    submitError = "";
    isSubmitting = false;

    @computed.notEmpty("submitError") hasSubmitError;

    init() {
        super.init(...arguments);
        if (isEmpty(this.get("document.file"))) {
            this.transitionToRoute("atlas.tools.custom-datasets");
        }
    }

    reset() {
        this.setProperties({
            isSubmitting: false,
            submitError: "",
            loaded: 0,
            total: 0
        })
    }

    @action onCancel() {
        this.transitionToRoute("atlas.tools.custom-datasets");
    }

    @action async onSubmit() {
        if (!this.document.hasSheet) {
            this.set("submitError", "At least one sheet must be included in the custom dataset.");
            return;
        }
        if (!this.document.hasColumn) {
            this.set("submitError", "At least one column must be included in the custom dataset.");
            return;
        }
        if (!this.user.hasDataStorageForDocument(this.document)) {
            this.set("submitError", `${this.document.name} exceeds the remaining available data amount.`);
            return;
        }
        if (!this.user.hasPostStorageForDocument(this.document)) {
            this.set("submitError", `${this.document.name} exceeds the remaining available post amount.`);
            return;
        }
        this.set("submitError", "");

        try {
            this.set("isSubmitting", true);
            const onprogress = (event) => {
                this.setProperties({
                    loaded: event.loaded,
                    total: event.total
                });
            }
            await this.customDataset.saveAsFileUpload(this.document, "", false, false, onprogress);
            this.set("isSubmitting", false);
        }
        catch (error) {
            this.setProperties({
                isSubmitting: false,
                submitError: error.atlasErrorText
            });
            return;
        }

        this.set("sheetIndex", 0);
        this.user.loadCustomDatasets();
        this.transitionToRoute("atlas.tools.custom-datasets");
    }
}
