import Ember from "ember";

export default Ember.Component.extend({
    classNameBindings: [":menu-selector", "fillWidth:full-width"],

    options: null,
    selectedValue: null,

    menuOpen: false,
    titleCase: false,
    fillWidth: false,
    showDescription: true,

    titleField: "title",
    valueField: "value",

    // Options can be in a few formats for easier use in diferent situations:
    // Comma separated (good for defining in templates):
    //     "sample1,sample2,sample3"
    // Array of strings (preferred form):
    //     ["sample1","sample2","sample3"]
    // Array of objects (when the value and display string need to be different):
    //     [{title: "sample1", value:"val1"},{title: "sample2", value:"val2"}]
    innerOptions: Ember.computed("options", "options.[]", "valueField", "titleField", function () {
        var options = (this.options || []),
            titleField = this.titleField,
            valueField = this.valueField;
        if (typeof(options) === "string") {
            options = options.split(",");
        }
        return options.map(function(option) {
            if (typeof(option) === "string") {
                return {
                    title: option,
                    value: option,
                    _source: option
                };
            } else if (Ember.isArray(option)) {

            } else if (typeof(option) === "object") {
                var optTitle = Ember.get(option, titleField) || Ember.get(option, "title"),
                    optLabel = Ember.get(option, "label"),
                    optValue = Ember.get(option, valueField) || Ember.get(option, "value");
                return {
                    title: optTitle || optLabel || optValue,
                    value: Ember.isEmpty(optValue) ? optTitle : optValue,
                    description: option.description,
                    _source: option
                };
            }
        }).filter(function(option) { return option && !Ember.isEmpty(option.value); });
    }),

    selectedOption: Ember.computed("innerOptions", "innerOptions.[]", "selectedValue", function () {
        var selectedValue = this.selectedValue,
            options = this.innerOptions || [],
            selectedOption = this.innerOptions.findBy("value", selectedValue);
        if (Ember.isEmpty(selectedOption) && options && options.length) {
            selectedOption = options[0];
            if (selectedOption) {
                Ember.run.scheduleOnce("afterRender", this, () => {
                    this.send("selectedValueChanged", selectedOption && selectedOption.value);
                });
            }
        }
        return selectedOption;
    }),

    changed: () => {},
    actions: {
        openMenu: function() {
            this.toggleProperty("editorOpen");
        },
        closeMenu: function () {
            this.set("editorOpen", false);
        },
        selectedValueChanged: function(selection) {
            // Called on schedule, so could potentially happen after the component is gone.
            if (this && !this.isDestroyed && !this.isDestroying) {
                this.changed(selection);
                this.set("editorOpen", false);
            }
        }
    }
});
