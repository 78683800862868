import Component from '@ember/component';
import {computed, action} from "@ember/object";
import classic from "ember-classic-decorator";

@classic
export default class FieldArcChart extends Component {
    tagName = "span";

    field = null;

    isFaded = false;

    color = null;
    backgroundColor = null;

    innerRadius = 37;
    outerRadius = 50;
};
