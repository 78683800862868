import Ember from 'ember';
import QueryAlert from "infegy-frontend/models/users/query-alert";
import Languages from "infegy-frontend/static_data/query/query_filters/languages";

export default Ember.Component.extend({
    queryAlert: null,
    editingAlert: null,

    emailInput: '',

    errorMessage:'',
    invalidEmail:false,

    filterOptions: ["languages"],
    activeFilters: [],
    queryStringError: false,

    languageOptions: Languages.allLanguages,
    languagesFilterActive: Ember.computed("activeFilters", function(){
        let active = this.activeFilters;
        return active.includes("languages");
    }),
    innerLanguageFilterValue: Ember.computed("editingAlert.language",function(){
        let activeLanguages = this.get("editingAlert.language") || "",
            languageArray = Languages.allLanguages.filter((lang) => {
                return activeLanguages.indexOf(lang.value) > -1;
            }).map((cv) => cv.value);
        return languageArray || [];
    }),
    languageFilterValue: Ember.computed.oneWay("innerLanguageFilterValue"),

    sensitivityOptions:[{
		label: "Viral",
		value: 1000
	},{
        label:"High",
        value:25
    },{
        label:"Medium",
        value:50
    },{
        label:"Low",
        value:75
    }],

    setup: Ember.on("init",function(){
        let alert = this.queryAlert,
            newAlert = QueryAlert.create();
        newAlert.loadJSON(alert.toJSON());
        this.set("editingAlert",newAlert);
        if((Ember.get(newAlert,"language") || "").length > 0){
            this.set("activeFilters",["languages"]);
        }
    }),

    addValidEmail:function(emailToAdd){
        var emails = this.get("editingAlert.emails") || [];

        if(!Ember.isEmpty(emailToAdd.match(/.+@.+\..+/i))) {

            this.set("editingAlert.emails",emails.concat(emailToAdd));
            this.setProperties({
                emailInput: "",
                invalidEmail: false,
            });
            return true;
        } else {
            this.set("invalidEmail",true);
            return false;
        }
    },

    submit: () => {},
    cancel: () => {},
    
    actions: {
        submit () {
            if(!Ember.isEmpty(this.emailInput)){
                if(!this.addValidEmail(this.emailInput)){
                    return false;
                }
            }
            this.submit(this.editingAlert);
        },
        cancel() {
            this.cancel();
        },
        emailsAdded(emails){
            emails.forEach((email)=>{
                this.addValidEmail(email);
            });
        },
        emailsRemoved(emails){
            let emailArray = this.get("editingAlert.emails");
            emails.forEach((email)=>{
                emailArray.removeObject(email);
            });
        },
        volumeTriggerChanged(value){
            this.set("editingAlert.volumeTrigger",value);
        },
        sentimentTriggerChanged(value){
            this.set("editingAlert.sentimentTrigger",value);
        },
        activeFiltersChanged(values){
            this.set("activeFilters",values);
        },
        languageFilterChanged(values){
            this.set("editingAlert.language",values.join(","));
        },
        closeError() {
            this.set('errorMessage', null);
        },
    }
});
