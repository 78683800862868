import Ember from "ember";
import DataSeries from "infegy-frontend/models/data_series/data_series";

export default Ember.Component.extend({
    query: null,

    allInterests: Ember.computed("query", "query.postInterests.data", function(){
        var data = this.get("query.postInterests.data") || [];
        return data && data.sortBy("distribution").reverse().splice(0,5);
    }),
});
