import Component from "@ember/component";
import { action } from '@ember/object';
import classic from "ember-classic-decorator";

@classic export default class SelectPill extends Component {
    tagName = 'span';

    @action itemClicked(item) {
        this.listItemClicked(item);
    }

    @action onRemoveItem(item) {
        this.removeListItem(item);
    }
}