import Ember from "ember";
import Component from '@ember/component';
import {computed, action} from "@ember/object";
import QueryAPIs from "infegy-frontend/models/queries/query_apis";

export default class extends Component {
    classNames = ['no-print'];
    apiKey = null;
    user = null;

    query = null;
    firstQuery = Ember.computed.alias("queries.firstObject");
    queries = null;
    apis = [];
    showExport = false;
    pageName = null;

    activeAPI = null;
    hoveredAPI = null;
    activeFormat = "xlsx";
    activeQueryId = 0;

    apiOptions = null;
    view = "";

    @computed("queries", "activeQueryId")
    get activeQuery() {
        let queries = this.queries,
            activeQueryId = this.activeQueryId;
        return queries.objectAt(activeQueryId);
    }

    @computed("pageName")
    get pageExportTitle() {
        return this.pageName || "Page Export";
    }

    @computed("user.apiEnabled")
    get formatOptions(){
        let apiEnabled = this.get("user.apiEnabled"),
            options = [Ember.Object.create({
                title: "Excel 97-2004",
                value: "xls"
            }),Ember.Object.create({
                title: "Excel",
                value: "xlsx"
            })];

        if(apiEnabled){
            options = options.concat(
                [Ember.Object.create({
                    title: "JSON",
                    value: "json"
                }), Ember.Object.create({
                    title: "XML",
                    value: "xml"
                })]
            );
        }
        return options;
    }

    @computed("activeAPI")
    get availableFormatOptions() {
        var apiInfo = QueryAPIs.findBy("name", this.activeAPI),
            apiExportOptions = (apiInfo && apiInfo.exportFormats) || ["json", "xml", "xls", "xlsx"];
        if (!apiInfo || !apiExportOptions) {
            return [];
        }

        return this.formatOptions.reduce(function(pv, item) {
            if (apiExportOptions.indexOf(item.get('value')) !== -1) {
                pv.push(item);
            }
            return pv;
        }, []);
    }

    @computed("apis", "query")
    get apiChoices() {
        this.send('activeAPIChanged', [this.activeAPI]);
        return (this.apis || ["volume"]).map(function(api) {
            var label = QueryAPIs.findBy("name", api) && QueryAPIs.findBy("name", api).label;

            return Ember.Object.create({
                title: label || api,
                value: api
            });
        });
    }

    @computed("apiOptions", "apiOptions.[]", "apiOptions.@each.selectedValue")
    get apiOptionValues() {
        var apiOptions = this.apiOptions || [],
            active = apiOptions.filterBy("selectedValue").reduce(function(memo, option) {
                if (!Ember.isEmpty(option.parameter) && !Ember.isEmpty(option.selectedValue)) {
                    memo[option.parameter] = option.selectedValue;
                }
                return memo;
            }, {});
        return active;
    }

    @computed("queries")
    get queryOptions(){
        var queries = this.queries;

        return queries.map((query,idx) => {
            return {
                title:query.get("title"),
                value:idx
            };
        });
    }

    @action showExport() {
        if (this.showExport) {
            this.set('showExport', false);
        } else {
            this.set('showExport', true);
        }
    }

    @action apiOptionsChanged(value){
        if (this.apiOptions !== value) {
            this.set("apiOptions", value);
        }
    }

    @action activeAPIChanged(values) {
        var value = values && values[0];
        if (!value) {
            var apis = this.apis;
            value = apis && apis[0];
        }
        this.set("activeAPI", value);
    }

    @action activeFormatChanged(format){
        this.set("activeFormat", format);
    }

    @action activeQueryChanged(query){
        this.set("activeQueryId", query);
    }
}
