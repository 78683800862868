import Ember from "ember";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { computed, action } from '@ember/object';
import QueryUtil from "infegy-frontend/models/queries/query_util";
import AtlasAuth from 'infegy-frontend/utils/atlas-auth';
import { actionArgument, argument } from "infegy-frontend/decorators/arguments";
/** @typedef {import("infegy-frontend/models/queries/single_request_query").default} Query */

export default class extends Component {
    constructor(){
        super(...arguments);
        this.booleanQueryToggled = !Ember.isEmpty(this.queryString);
        this.entityQueryToggled = !Ember.isEmpty(this.entityQuery);
    }

    showQueryFields = true;

    atlasAuth = AtlasAuth;

    /** @type {Query|null} */
    @argument(null) query;
    @argument(true) showSnippets;
    @argument(false) isAudienceQuery;
    @actionArgument submitQuery;
    @actionArgument saveSnippet;
    @actionArgument updateSnippet;
    @actionArgument datasetChanged;

    @tracked booleanQueryToggled = false;
    @tracked entityQueryToggled = false;

    @computed.oneWay("atlasAuth.user.customDatasets") customDatasets;
    @computed.oneWay("query.queryInfo.queryString") queryString;
    @computed.oneWay("query.queryInfo.entityQuery") entityQuery;
    @computed.oneWay("atlasAuth.user") user;
    @computed.alias("query.queryInfo.sourceQuery.value") sourceQuery;
    @computed.alias("query.title") queryTitle;

    get entityQueryParts(){
        return this.query?.queryInfo?.entityQueryParts?.toJSON() || [];
    }

    get formHasBooleanQuery(){
        return !Ember.isEmpty(this.queryString) || this.booleanQueryToggled;
    }
    set formHasBooleanQuery(value){
        this.booleanQueryToggled = value;
    }

    get formHasEntityQuery(){
        return !Ember.isEmpty(this.entityQuery) || this.entityQueryToggled;
    }
    set formHasEntityQuery(value) {
        this.entityQueryToggled = value;
    }

    get areCustomDatasetsEnabled(){
        var customDatasets = this.customDatasets?.content || [],
            isAudienceQuery = this.isAudienceQuery;

        return (customDatasets && customDatasets.length > 0) && !isAudienceQuery;
    }

    get hasNoQueries(){
        return !this.formHasBooleanQuery && !this.formHasEntityQuery;
    }

    get autoTitle(){
        return QueryUtil.queryToTitle(this.queryString, this.entityQueryParts, this.customDatasetParts, null, 30, 3);
    }

    get title(){
        var autoTitle = this.autoTitle,
            queryTitle = this.queryTitle,
            queryString = this.queryString;
        if (queryTitle && !queryString) {
            this.queryTitle = null;
        }
        return queryTitle || autoTitle;
    }
    set title(value) {
        var autoTitle = this.autoTitle;
        value = value.trim();
        if (value === autoTitle) {
            value = null;
        }
        this.queryTitle = value;
    }

    @computed("query.queryInfo.datasetQueryInfo", "user.socialQueryInfo")
    get queryFieldInfo() {
        let activeDatasets = this.activeDataset || [],
            queryInfo = this.query?.queryInfo?.datasetQueryInfo,
            allOptions = {
                defaults: queryInfo?.default_query_fields || [],
                options: queryInfo?.available_query_fields || [],
                highlighterOptionValues: (queryInfo?.available_query_operator_fields || []).mapBy("id")
            };


        if (!allOptions.options?.length) {
            return allOptions;
        }

        allOptions.options = allOptions.options.map(queryField => {
                if (!queryField) {
                    return;
                }
                return Object.assign({
                    label: queryField?.name || queryField?.id,
                    value: queryField?.id
                }, queryField);
            }).filter(opt => opt);
        allOptions.defaults = allOptions.defaults.mapBy("id");

        return allOptions;
    }

    @computed.alias("queryFieldInfo.options") queryFieldOptions;

    @computed("queryFieldInfo", "queryFieldInfo.defaults")
    get queryFieldsValue(){
        let value = this.query?.queryInfo?.queryFields;
        if (value === undefined) {
            value = this.queryFieldInfo?.defaults;
        }
        return value;
    }
    set queryFieldsValue(values){
        let opts = this.queryFieldInfo.options;
        if (!Ember.isEmpty(opts) && !Ember.isEmpty(values)) {
            values = values.filter(val => {
                return opts.findBy("value", val);
            });
        }
        this.query.queryInfo.queryFields = values;
    }

    @computed("queryFieldInfo", "queryFieldInfo.options", "queryFieldsValue")
    get queryFieldsDisplayValues() {
        let options = this.queryFieldInfo?.options;
        if (!options) {
            return this.queryFieldsValue;
        }
        let displayValues = (this.queryFieldsValue || []).map(value => {
                let option = options.findBy("id", value);
                return option ? option.label : value;
            });

        return displayValues;
    }

    @computed("queryFieldsDisplayValues", "queryFieldInfo", "queryFieldsValue", "queryFieldsValue.[]")
    get showQueryFieldsAlert() {
        return !this.queryFieldsValue || this.queryFieldsValue.length <= 0;
    }


    @action
    booleanErrorsUpdated(errorStatus){
        this.booleanError = errorStatus;
        Ember.set(this.query, "errors", errorStatus);
    }

    @action
    toggleOnlyCustomDatasets(){
        Ember.set(this.query.queryInfo, "onlyCustomDatasets", !this.query.queryInfo.onlyCustomDatasets);
    }

    @action
    booleanQuerySelected() {
        this.booleanQueryToggled = !this.booleanQueryToggled;
        this.valueChanged("");
    }

    @action
    entityQuerySelected() {
        this.entityQueryToggled = !this.entityQueryToggled;
        this.entityQueryChanged([]);
    }

    @action
    valueChanged(value){
        this.query?.queryInfo?.loadJSON({ query: value }, { allowEmptyValues:true });
    }

    @action
    entityQueryChanged(value){
        this.query?.queryInfo.entityQueryParts.clear();
        this.query?.queryInfo.loadJSON({
            "entityQueryParts": value
        });
    }

    @action
    sourceQueryChanged (value) {
        this.query?.queryInfo.sourceQuery.loadJSON(value);
    }

    @action
    applySnippet(snippet){
        var loadData = {};
        if(!Ember.isEmpty(snippet.query)) {
            loadData.queryString = (this.query?.queryString || '') + ' ' + snippet.query;
        }
        this.query?.queryInfo.loadJSON(loadData);
    }

    @action
    doSaveSnippet(snippetName){
        this.saveSnippet(snippetName);
        this.showSaveSnippet = false;
    }

}
