import Ember from "ember";
import Controller from "@ember/controller";
import { action } from "@ember/object";

export default class SnippetController extends Controller {
    @Ember.inject.controller() application;

    declineSharingId = 0;
    deletingSnippet = null;
    showDeclineSharingError = false;
    showDeleteError = false;

    @Ember.computed.alias("application.user") user;

    @Ember.computed.alias("user.snippets") snippets;

    @action
    onCreate() {
        this.transitionToRoute("atlas.tools.snippets.edit", 0);
    }

    @action
    async onConfirmDeclineSharing(snippet) {
        this.snippets.removeObject(snippet);
    }

    @action
    async onConfirmDelete(snippet) {
        try {
            await snippet.delete();
        }
        catch(error) {
            this.set("showDeleteError", true);
            Ember.run.later(()=>{
                this.set("showDeleteError", false);
            }, 5000);
            return;
        }

        this.snippets.removeObject(snippet);
        this.set("deletingSnippet", null);
    }

    @action
    onEdit(snippet) {
        this.transitionToRoute("atlas.tools.snippets.edit", snippet.id);
    }

    @action
    onSharingDeclined(snippet) {
        this.snippets.removeObject(snippet);
    }
}
