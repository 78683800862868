import Component from "@ember/component";
import { computed } from "@ember/object";
import classic from "ember-classic-decorator";

import Queries from "infegy-frontend/models/users/queries";

@classic
export default class extends Component {
    queries = Queries.create();
    user = null;

    selectedQuery = null;
    sortFieldOptions = Queries.SORT_FIELD_OPTIONS;

    @computed.oneWay("queries.search") searchText;
    @computed.oneWay("queries.sortField") sortField;
    @computed.oneWay("queries.sortDirection") sortDirection;
    
    search(options) {
        options["page_index"] = 0;
        this.queries.getPage(options);
    }
}