import Ember from "ember";
import ArrayUtil from "infegy-frontend/utils/array-util";
import colorizeFields from "infegy-frontend/models/fields/trend-chart-field-colorizer";

export default Ember.Component.extend({
    classNames: ["trend-chart-legend"],

    availableFields: null,
    activeFields: null,
    isMultiple: false,

    theme: "legend",
    colorize: true,

    activeTheme: Ember.computed("theme", {
        get: function(key) {
            return this.theme || "legend";
        }
    }),

    activeFieldValues: Ember.computed("activeFields", {
        get: function(key) {
            return (this.activeFields || []).mapBy("value");
        }
    }),

    _cachedAvailableFields: null,
    innerAvailableFields: Ember.computed("availableFields", "availableFields.[]", {
        get: function(key) {
            var availableFields = this.availableFields,
                cachedAvailableFields = this._cachedAvailableFields;

            if (this.isMultiple && cachedAvailableFields && availableFields) {
                Ember.run.schedule('afterRender', this, () => {
                    this.send("activeFieldsChanged", null);
                });

                if (availableFields[0] && availableFields[0].query) {
                    return availableFields;
                }

                return this.activeFields;
            }

            if (!cachedAvailableFields || !ArrayUtil.arraysMatch(cachedAvailableFields, availableFields)) {
                cachedAvailableFields = availableFields;
                this.set("_cachedAvailableFields", availableFields);

                Ember.run.schedule('afterRender', this, () => {
                    this.send("activeFieldsChanged", null);
                });
            }

            return cachedAvailableFields;
        }
    }),

    areFieldsLoaded: Ember.computed("innerAvailableFields", {
        get: function(key) {
            var availableFields = this.innerAvailableFields || [],
                unloadedFields = availableFields.filter(field => {
                    return !field.get("dataSeries");
                });
            return unloadedFields && unloadedFields.length > 0;
        }
    }),

    activeFieldsChanged: () => {},
    actions: {
        activeFieldsChanged: function(selectedFields) {
            selectedFields = selectedFields || [];
            var activeFields = this.activeFields || [],
                availableFields = this.availableFields || [];

            if (Ember.isEmpty(selectedFields) && availableFields) {
                selectedFields = availableFields.filter(function(field) {
                    return !Ember.get(field, "inactiveByDefault");
                }).slice(0,6);
            }
            if (!ArrayUtil.arraysMatch(activeFields, selectedFields)) {
                if (this.colorize) {
                    selectedFields = colorizeFields(selectedFields || [], "colorClass", "suggestedColorClass");
                }
                this.activeFieldsChanged(selectedFields);
            }
        },
        optionHovered: function(hoveredFieldValue) {

        }
    }
});
