import Ember from "ember";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";

export default Ember.Route.extend({
	currentPage:'',
	afterModel: function(model, transition) {
        if (transition.targetName === "atlas.admin.demos.index" || transition.targetName === "atlas.admin.demos") {
            this.transitionTo("atlas.admin.demos.live-linguistics");
        } else {
            var path = transition.targetName.split("."),
                page = path[path.indexOf("demos") + 1];
            this.set("currentPage", page);
        }
    },
    setupController: function(controller, model) {
        var currentPage = this.currentPage;

        if (currentPage) {
            controller.set("currentPage", currentPage);
        }
    }
});