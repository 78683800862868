import Ember from "ember";
import { isEmpty } from '@ember/utils';
import { camelize } from '@ember/string';
import { computed } from '@ember/object';
import { isArray } from '@ember/array';
import { alias } from '@ember/object/computed';
import DataSeries from "infegy-frontend/models/data_series/data_series";
import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import QueryAPIResult from "infegy-frontend/models/queries/query_api_result";
import DataSeriesComputedFieldDistribution from "infegy-frontend/models/data_series/data_series_computed_field_distribution";
import classic from "ember-classic-decorator";
import DataSeriesComputedFieldSum from "infegy-frontend/models/data_series/data_series_computed_field_sum";
import DataSeriesComputedFieldDifference from "infegy-frontend/models/data_series/data_series_computed_field_difference";

const averageToCombineFields = ["age"];
const clusterAnalyzedFields = ["positiveDocuments", "negativeDocuments"];
const narrativeDataSeriesDescription = DataSeriesDescription.load({
    "timestamp": "timestamp",

    totalDocuments: "number",
    totalSentences: "number",
    totalUniverse: "number",
    totalWords: "number",

    documents: "number",
    sentences: "number",
    universe: "number",
    words: "number",

    clusterPositiveDocuments: "number",
    clusterNegativeDocuments: "number",

    positiveDocuments: "number",
    negativeDocuments: "number",
    neutralDocuments: "number",

    followers: "number",
    following: "number",
    impressions: "number",
    reach: "number",

    age: "average",
    male: "number",
    female: "number",
}, [
    DataSeriesComputedFieldDifference.fieldSetup("externalDocuments","totalDocuments","documents"),
    DataSeriesComputedFieldDifference.fieldSetup("externalUniverse","totalUniverse","universe"),
    DataSeriesComputedFieldSum.fieldSetup("sentimentalDocuments", ["positiveDocuments","negativeDocuments"]),
    DataSeriesComputedFieldDistribution.fieldSetup(["universe", "externalUniverse"]),
    DataSeriesComputedFieldDistribution.fieldSetup(["documents", "externalDocuments"]),
    DataSeriesComputedFieldDistribution.fieldSetup(["positiveDocuments", "negativeDocuments", "neutralDocuments", "externalDocuments"]),
    DataSeriesComputedFieldDistribution.fieldSetup(["clusterPositiveDocuments", "clusterNegativeDocuments"]),
    DataSeriesComputedFieldDistribution.fieldSetup(["female", "male"]),
    DataSeriesComputedFieldDistribution.fieldSetup(["femaleUniverse", "maleUniverse"]),
]);

@classic
export class ClusterDataObject extends Ember.Object{
    name = "";
    query = "";
    _sourceIndex = null;
    _sourceQuery = null;

    rawData = [];
    /** @type {DataSeries} */
    dataSeries = {};

    @alias("dataSeries.stats.documentsDistribution.average") documentsDistribution;
    @alias("dataSeries.stats.universeDistribution.average") universeDistribution;
    @alias("dataSeries.stats.maleDistribution.average") maleDistribution;
    @alias("dataSeries.stats.femaleDistribution.average") femaleDistribution;
    @alias("dataSeries.stats.positiveDocumentsDistribution.average") positiveDocumentsDistribution;
    @alias("dataSeries.stats.negativeDocumentsDistribution.average") negativeDocumentsDistribution;
    @alias("dataSeries.stats.clusterPositiveDocumentsDistribution.average") clusterPositiveDocumentsDistribution;
    @alias("dataSeries.stats.clusterNegativeDocumentsDistribution.average") clusterNegativeDocumentsDistribution;
    @alias("dataSeries.stats.totalDocumentsDistribution.average") totalDocumentsDistribution;

    @alias("dataSeries.stats.female.sum") female;
    @alias("dataSeries.stats.male.sum") male;

    @computed("male", "female")
    get genderFemaleDistribution () {
        return this.female / (this.male + this.female);
    }

    @computed("male", "female")
    get genderMaleDistribution() {
        return this.male / (this.male + this.female);
    }


    @alias("dataSeries.stats.reach.sum") reach;
    @alias("dataSeries.stats.impressions.sum") impressions;

    @alias("dataSeries.stats.following.sum") following;
    @alias("dataSeries.stats.followers.sum") followers;

    @alias("dataSeries.stats.words.sum") words;
    @alias("dataSeries.stats.universe.sum") universe;
    @alias("dataSeries.stats.sentences.sum") sentences;
    @alias("dataSeries.stats.documents.sum") documents;

    @alias("dataSeries.stats.positiveDocuments.sum") positiveDocuments;
    @alias("dataSeries.stats.negativeDocuments.sum") negativeDocuments;
    @computed("dataSeries.data")
    get sentimentalDocuments(){
        return this.positiveDocuments + this.negativeDocuments;
    }

    @computed("male", "female", "documents")
    get genderedPercentage(){
        return (this.male + this.female) / this.documents;
    }

    @computed("dataSeries.data")
    get age () {
        var data = this.get("dataSeries.data") || [],
            total = this.get("dataSeries.stats.age.sum");
        return total / (data.filterBy("age").length || 1);
    }
}

/**
 * @typedef {Object} ClusterResponseItem
 * @property {number} id
 * @property {string} query
 * @property {number[]} documents
 * @property {number[]} universe
 * @property {number[]} words
 * @property {number[]} setences
 * @property {number[]} positive_documents
 * @property {number[]} negative_documents
 * @property {number[]} neutral_documents
 * @property {number[]} followers
 * @property {number[]} following
 * @property {number[]} reach
 * @property {number[]} impressions
 * @property {number[]} male
 * @property {number[]} female
 * @property {number[]} age
 */

export default class NarrativeAPIResult extends QueryAPIResult{
    title = "narratives";
    dates = [];
    totals = [];

    /** @type { ClusterDataObject[] } */
    narrativeData = [];
    narrativeDataSeriesDescription = narrativeDataSeriesDescription;

    @computed("narrativeData.@each.name")
    get narrativeNames(){
        return this.narrativeData.map(({ name }) => name);
    }

    removeCluster(clusterKey){
        this.set("narrativeData", this.narrativeData.filter((cluster,idx)=> cluster.key !== clusterKey ));
        this.rebuildSourceIndexes();
    }

    renameCluster(clusterIdx, name){
        this.narrativeData[clusterIdx].set("name", name);
    }

    rebuildSourceIndexes(){
        this.narrativeData.forEach((d,idx)=>{
            Ember.set(d,"_sourceIndex", idx);
        });
    }

    preload(){
        var rawResponse = this.get("rawResults") || {},
            dates = rawResponse.dates,
            totals = rawResponse.totals,
            clusterNames = [],
            fieldNames = [];

        this.totals = dates.map((date, idx) => {
            let splitDate = date.split(/[^0-9]/);
            splitDate[1]--;
            return {
                timestamp: Date.UTC(...splitDate),
                totalDocuments: totals.documents[idx],
                totalUniverse: totals.universe[idx],
            };
        });

        // Get all the clusters
        for (let key in rawResponse) {
            if (Object.hasOwnProperty.apply(rawResponse,[ key ]) && key !== "dates" && key !== "totals") {
                clusterNames.push(key);
            }
        }

        if (Ember.isEmpty(clusterNames)) {
            this.set("isEmpty", true);
            return;
        }

        // Get all the fields
        var firstcluster = rawResponse[clusterNames[0]];
        for (let key in firstcluster) {
            if (Object.hasOwnProperty.apply(firstcluster, [ key ])) {
                fieldNames.push(key);
            }
        }

        // build cluster data
        var clustersData = clusterNames.map((clusterName, index) => {
            /** @type { clusterResponseItem } */
            var clusterSource = rawResponse[clusterName],
                data = dates.map((date, idx) => {
                    let row = Object.assign({}, this.totals[idx]);
                    fieldNames.forEach(fieldName => {
                        row[fieldName.camelize()] = clusterSource[fieldName][idx];
                    });
                    // We need separate definitions for sentiment distribution in relation to the query and to the cluster
                    // cluster values will be prefixed with the word "cluster"
                    // other values will be calculated in relation to the query as a whole
                    // The raw values of the fields will be the same, but their distributions calculated differently
                    clusterAnalyzedFields.forEach(field => {
                        row[`cluster${field.capitalize()}`] = row[field];
                    });
                    return row;
                });
            var clusterData = ClusterDataObject.create();

            clusterData.setProperties({
                query: clusterSource.query,
                key: clusterSource.key,
                id: clusterSource.id,
                dataSeries: DataSeries.load(data, narrativeDataSeriesDescription),
                name: clusterName,
                rawData: data,
                _sourceIndex: index,
                _sourceQuery: this.query
            });
            return clusterData;
        });

        this.setProperties({
            narrativeData: clustersData,
            dates
        });
    }

    description = DataSeriesDescription.load({
        "dates": "array",
        "totals": "array"
    })
}
