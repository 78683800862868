import Ember from "ember";

export default Ember.Component.extend({
    classNames: ["api-exporter-option"],

    option: null,

    changed: () => {},
    actions: {
        optionValueChanged: function(value) {
            var option = this.option;
            Ember.set(option,"selectedValue", value);
            this.changed(option);
        }
    }
});
