import Ember from "ember";
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { run } from '@ember/runloop';

let subsegmentOptions = {
    "Posts" : {
        fieldComponent: "field-selector/parts/fields/posts",
        fieldTypes: ["trend", "scalar", "portion"],
        options: [
            { value: "Universe"},
            { value: "Count" },
            { value: "WordNormalized" },
            { value: "PostsPerBillion" },
            { value: "UniqueAuthors", fieldTypes: ["scalar"] },
            { value: "UniverseUniqueAuthors", fieldTypes: ["scalar"] },
        ],
    },
    "Engagements" : {
        fieldComponent: "field-selector/parts/fields/engagements",
        fieldTypes: ["trend", "scalar"],
        options: [
            { value: "EngagementsUniverse" },
            { value: "LikesUniverse" },
            { value: "CommentsUniverse" },
            { value: "SharesUniverse" },
            { value: "Engagements" },
            { value: "Likes" },
            { value: "Comments" },
            { value: "Shares" },
            { value: "Impressions", fieldTypes: ["scalar"]},
            { value: "Reach", fieldTypes: ["scalar"]},
        ],
    },
    "Sentiment" : {
        fieldComponent: "field-selector/parts/fields/sentiment",
        fieldTypes: ["trend", "scalar"],
        options: [
            { value: "DocumentsDistribution", fieldTypes: ["trend", "scalar", "portion"] },
            { value: "SubjectSentencesDistribution", fieldTypes: ["trend", "scalar", "portion"] },
            { value: "SentencesDistribution", fieldTypes: ["trend", "scalar", "portion"] },
            { value: "SubjectSentences" },
            { value: "Sentences" },
            { value: "Documents" },
            { value: "Universe" },
        ],
    },
    "Passion" : {
        fieldComponent: "field-selector/parts/fields/passion",
        fieldTypes: ["trend", "scalar", "portion"],
        options: [
            { value: "Passion" },
            { value: "Intensity" },
            { value: "SentimentalSentences" },
            { value: "SentimentalSubjectSentences" },
        ],
    },
    "Gender" : {
        fieldComponent: "field-selector/parts/fields/gender",
        fieldTypes: ["trend", "scalar", "portion"],
        options: [
            { value: "UniverseDistribution" },
            { value: "Universe", fieldTypes: ["trend", "portion"] },
            { value: "CountDistribution" },
            { value: "Count", fieldTypes: ["trend", "portion"] },
        ],
    },
    "Age" : {
        fieldComponent: "field-selector/parts/fields/age",
        fieldTypes: ["scalar", "portion"],
        options: [ { value: "Age"} ],
    },
    "US Demographics" : {
        fieldComponent: "field-selector/parts/fields/us-demographics",
        fieldTypes: ["scalar", "portion"],
        options: [
            {
                value: "Totals",
                fieldComponent: "field-selector/parts/fields/us-demographics-meta",
                fieldTypes: ["scalar"],
            },
            { value: "Education"},
            { value: "Income"},
            { value: "Home Ownership"},
            { value: "Household Value"},
        ],
    },
    "Geography" : {
        fieldComponent: "field-selector/parts/fields/geography",
        fieldTypes: ["scalar"],
        options: [
            { value: "US States"},
            { value: "DMAs"},
            { value: "Canadian Provinces"},
            { value: "Countries"},
        ],
    },
    "Channels" : {
        fieldComponent: "field-selector/parts/fields/channels",
        fieldTypes: ["trend", "scalar", "portion"],
        options: [
            { value: "UniverseDistribution" },
            { value: "CountDistribution" },
            { value: "Universe", fieldTypes: ["trend", "scalar"] },
            { value: "Count", fieldTypes: ["trend", "scalar"] },
        ],
    },
    "Emotions" : {
        fieldComponent: "field-selector/parts/fields/emotions",
        fieldTypes: ["trend", "scalar"],
        options: [
            { value: "DocumentsDistribution", fieldTypes: ["trend", "scalar", "portion"] },
            { value: "Appearances" },
            { value: "Documents" },
        ],
    },
    "Themes" : {
        fieldComponent: "field-selector/parts/fields/themes",
        fieldTypes: ["trend", "scalar", "portion"],
        options: [
            { value: "DocumentsDistribution" },
            { value: "PositiveDocuments", fieldTypes: ["trend", "scalar"] },
            { value: "NegativeDocuments", fieldTypes: ["trend", "scalar"] },
            { value: "PositiveDocumentsDistribution" },
            { value: "NegativeDocumentsDistribution" },
            { value: "PositiveSentimentalDocumentsDistribution" },
            { value: "NegativeSentimentalDocumentsDistribution" },
            { value: "Documents", fieldTypes: ["trend", "scalar"] },
            { value: "PositiveAppearances", fieldTypes: ["trend", "scalar"] },
            { value: "NegativeAppearances", fieldTypes: ["trend", "scalar"] },
            { value: "Appearances", fieldTypes: ["trend", "scalar"] },
        ],
    },
    "Languages" : {
        fieldComponent: "field-selector/parts/fields/languages",
        fieldTypes: ["trend", "scalar", "portion"],
        options: [
            { value: "DocumentsDistribution" },
            { value: "Documents", fieldTypes: ["trend", "scalar"] },
        ],
    },
    "Influencers" : {
        fieldComponent: "field-selector/parts/fields/influencers",
        fieldTypes: ["scalar"],
        options: [
            { value: "Followers"},
            { value: "Following"}
        ]
    }
};

for (let sectionName in subsegmentOptions) {
    if (subsegmentOptions.hasOwnProperty(sectionName)) {
        let section = subsegmentOptions[sectionName];
        section.options = section.options.map(opt => {
            if (!opt || !opt.value) {
                console.error(opt);
            }
            return {
                fieldComponent : opt.fieldComponent || section.fieldComponent,
                fieldTypes : opt.fieldTypes || section.fieldTypes,
                value: opt.value
            };
        })
    }
}

export default class FieldsSubsegmentSelector extends Component {
    classNameBindings = ["displayAsDropdown:inline-field-select:toggle-field-select"];

    queries = null;

    selectedSegment = "Posts";
    selectedSubsegment = null;
    selectedFieldComponent = null;

    datasetSegment = null;
    defaultSubsegments = subsegmentOptions;

    displayAsDropdown = false;

    fieldType = "trend";

    @computed("datasetSegment", "defaultSubsegments", "datasetSegment.options.[]")
    get availableSubsegments() {
        let availableSubsegments = Object.assign(this.defaultSubsegments, {});
        if (!Ember.isEmpty(this.datasetSegment)) {
            availableSubsegments["Dataset"] = this.datasetSegment;
        }
        return availableSubsegments;
    }

    @computed("datasetSegment", "defaultSubsegments", "fieldType", "selectedSegment",
        "availableSubsegments", "selectedSubsegment", "selectedFieldComponent")
    get subsegmentOptions() {
        if (!this.selectedSegment || !(this.availableSubsegments &&
                this.availableSubsegments.hasOwnProperty(this.selectedSegment))) {
            return;
        }
        let subsegmentDetails = this.availableSubsegments[this.selectedSegment],
            selectedSubsegment = this.selectedSubsegment,
            subsegmentOptions = subsegmentDetails && subsegmentDetails.options || [],
            fieldType = this.fieldType;

        subsegmentOptions = subsegmentOptions.filter(subSeg => {
            return subSeg.fieldTypes.includes(fieldType);
        });

        if (selectedSubsegment) {
            if (subsegmentOptions && !subsegmentOptions.find(opt => opt.value === selectedSubsegment)) {
                selectedSubsegment = null;
            }
        }

        if (selectedSubsegment && !this.selectedFieldComponent && subsegmentOptions) {
            let subsegmentDetails = subsegmentOptions.find(option => option.value === selectedSubsegment);
            if (subsegmentDetails && subsegmentDetails.fieldComponent) {
                run.next(()=>{
                    this.fieldComponentChanged(subsegmentDetails.fieldComponent);
                });
            }
        }

        if (!selectedSubsegment && subsegmentOptions) {
            run.next(()=>{
                if (subsegmentOptions && subsegmentOptions[0]) {
                    this.selectionChanged(subsegmentOptions[0].value);
                }
            });
        }
        subsegmentOptions.forEach(row => {
            if (!row.title) {
                row.title = row.value.replace(/([a-z])([A-Z])/g, '$1 $2');
            }
        });

        return subsegmentOptions;
    };

    subsegmentDetailsChanged(subsegmentDetails) { /* Empty for Action */ };

    @action
    selectionChanged(subsegmentValue) {
        if (subsegmentValue !== (this.selectedSubsegment && this.selectedSubsegment) && this.subsegmentOptions) {
            let subsegmentDetails = this.subsegmentOptions.find(option => option.value === subsegmentValue);
            this.subsegmentDetailsChanged(subsegmentDetails);
        }
    };
};
