import Ember from "ember";
import d3 from "d3";

export default Ember.Component.extend({
    classNameBindings: ["isFaded:arc-potion-faded-chart"],

    parts: null,
    isFaded: false,

    highlightedPartName: null,
    hidePercentage: false,

    maxHeight: 200,

    innerRadius: 33,
    outerRadius: 45,

    backgroundColorClass: "atlas-text-color-lighter",

    highlightedPart: Ember.computed("highlightedPartName", "parts", "parts.[]", function () {
        var parts = this.get("parts");
        var highlightedPartName = this.get("highlightedPartName");
        if (parts && highlightedPartName) {
            return parts.findBy("name", highlightedPartName);
        }
    }),

    highlightedPercentage: Ember.computed("highlightedPart", "partsSum", function () {
        var part = this.get("highlightedPart"),
            partValue = part && parseFloat(Ember.get(part, "value")),
            sum = this.get("partsSum");
        return (partValue && sum) ? partValue/sum : 0;
    }),

    partsSum: Ember.computed("parts", "parts.[]", function () {
        return (this.get("parts") || []).reduce(function (memo, item) {
            return memo + parseFloat(Ember.get(item, "value"));
        }, 0);
    }),

    displayParts: Ember.computed("parts", "parts.[]", "partsSum", "highlightedPartName", function () {
        var parts = this.get("parts") || [],
            partsSum = this.get("partsSum"),
            highlightedPartName = this.get("highlightedPartName"),
            backgroundColorClass = this.get("backgroundColorClass"),
            arc = d3.arc()
                .innerRadius(this.innerRadius || 1)
                .outerRadius(this.outerRadius || 1),
            datum = {endAngle: 0, startAngle: 0};

        if (partsSum) {
            return parts.map(function(item) {
                var value = parseFloat(Ember.get(item, "value"));
                datum.startAngle = datum.endAngle;
                datum.endAngle += (value / partsSum) * 2 * Math.PI; // Tau

                return Ember.Object.create({
                    name: Ember.get(item, "name"),
                    colorClass: Ember.get(item, "colorClass") || backgroundColorClass,
                    path: arc(datum),
                    lightClass: (Ember.get(item, "name") !== highlightedPartName) ? "light" : ""
                });
            });
        }
    }),

    backgroundPath: Ember.computed("innerRadius","outerRadius", function () {
        var innerRadius = this.get("innerRadius"),
            outerRadius = this.get("outerRadius"),
            arc = d3.arc()
                .innerRadius(innerRadius)
                .outerRadius(outerRadius);

        return arc({endAngle: 360, startAngle:0 });
    })
});
