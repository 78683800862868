/* jshint -W100 */

var Languages = {
    activeLanguages: [],
    languageLabels: {},
    activeLanguageCodes: [
        "$$","af","an","ar","az","be","bg","bn","br","bs",
        "ca","ce","co","cs","cy","da","de","el","en","eo",
        "es","et","eu","fa","fi","fo","fr","fy","ga","gl",
        "ha","he","hi","hr","ht","hu","hy","id","is","it",
        "ja","jv","ka","kk","kl","km","ko","ku","ky","la",
        "lb","li","lt","lv","mg","mi","mk","mn","ms","mt",
        "my","nb","nl","nn","no","nv","pa","pl","ps","pt",
        "qu","rm","ro","ru","sc","se","si","sk","sl","so",
        "sq","sr","su","sv","sw","ta","th","tk","tl","tr",
        "ug","uk","ur","uz","vi","wa","yi","yo","zh","zu"
    ],
    allLanguages: [{
            "value":"ab",
            "label":"Abkhaz",
            "alternates":["аҧсуа"]
        },{
            "value":"aa",
            "label":"Afar",
            "alternates":["Afaraf"]
        },{
            "value":"af",
            "label":"Afrikaans",
            "alternates":["Afrikaans"]
        },{
            "value":"ak",
            "label":"Akan",
            "alternates":["Akan"]
        },{
            "value":"sq",
            "label":"Albanian",
            "alternates":["Shqip"]
        },{
            "value":"am",
            "label":"Amharic",
            "alternates":["አማርኛ"]
        },{
            "value":"ar",
            "label":"Arabic",
            "alternates":["العربية"]
        },{
            "value":"an",
            "label":"Aragonese",
            "alternates":["Aragonés"]
        },{
            "value":"hy",
            "label":"Armenian",
            "alternates":["Հայերեն"]
        },{
            "value":"as",
            "label":"Assamese",
            "alternates":["অসমীয়া"]
        },{
            "value":"av",
            "label":"Avaric",
            "alternates":["авар мацӀ", "магӀарул мацӀ"]
        },{
            "value":"ae",
            "label":"Avestan",
            "alternates":["avesta"]
        },{
            "value":"ay",
            "label":"Aymara",
            "alternates":["aymar aru"]
        },{
            "value":"az",
            "label":"Azerbaijani",
            "alternates":["azərbaycan dili"]
        },{
            "value":"bm",
            "label":"Bambara",
            "alternates":["bamanankan"]
        },{
            "value":"ba",
            "label":"Bashkir",
            "alternates":["башҡорт теле"]
        },{
            "value":"eu",
            "label":"Basque",
            "alternates":["euskara", "euskera"]
        },{
            "value":"be",
            "label":"Belarusian",
            "alternates":["Беларуская"]
        },{
            "value":"bn",
            "label":"Bengali",
            "alternates":["বাংলা"]
        },{
            "value":"bh",
            "label":"Bihari",
            "alternates":["भोजपुरी"]
        },{
            "value":"bi",
            "label":"Bislama",
            "alternates":["Bislama"]
        },{
            "value":"bs",
            "label":"Bosnian",
            "alternates":["bosanski jezik"]
        },{
            "value":"br",
            "label":"Breton",
            "alternates":["brezhoneg"]
        },{
            "value":"bg",
            "label":"Bulgarian",
            "alternates":["български език"]
        },{
            "value":"my",
            "label":"Burmese",
            "alternates":["ဗမာစာ"]
        },{
            "value":"ca",
            "label":"Catalan",
            "alternates":["Valencian", "Català"]
        },{
            "value":"ch",
            "label":"Chamorro",
            "alternates":["Chamoru"]
        },{
            "value":"ce",
            "label":"Chechen",
            "alternates":["нохчийн мотт"]
        },{
            "value":"ny",
            "label":"Chichewa",
            "alternates":["Chewa", "Nyanja", "chiCheŵa", "chinyanja"]
        },{
            "value":"zh",
            "label":"Chinese",
            "alternates":["中文 (Zhōngwén)", "汉语", "漢語"]
        },{
            "value":"cv",
            "label":"Chuvash",
            "alternates":["чӑваш чӗлхи"]
        },{
            "value":"kw",
            "label":"Cornish",
            "alternates":["Kernewek"]
        },{
            "value":"co",
            "label":"Corsican",
            "alternates":["corsu", "lingua corsa"]
        },{
            "value":"cr",
            "label":"Cree",
            "alternates":["ᓀᐦᐃᔭᐍᐏᐣ"]
        },{
            "value":"hr",
            "label":"Croatian",
            "alternates":["hrvatski"]
        },{
            "value":"cs",
            "label":"Czech",
            "alternates":["česky", "čeština"]
        },{
            "value":"da",
            "label":"Danish",
            "alternates":["dansk"]
        },{
            "value":"dv",
            "label":"Divehi",
            "alternates":["Dhivehi", "Maldivian", "ދިވެހި"]
        },{
            "value":"nl",
            "label":"Dutch",
            "alternates":["Nederlands", "Vlaams"]
        },{
            "value":"en",
            "label":"English",
            "alternates":["English"]
        },{
            "value":"eo",
            "label":"Esperanto",
            "alternates":["Esperanto"]
        },{
            "value":"et",
            "label":"Estonian",
            "alternates":["eesti", "eesti keel"]
        },{
            "value":"ee",
            "label":"Ewe",
            "alternates":["Eʋegbe"]
        },{
            "value":"fo",
            "label":"Faroese",
            "alternates":["føroyskt"]
        },{
            "value":"fj",
            "label":"Fijian",
            "alternates":["vosa Vakaviti"]
        },{
            "value":"$$",
            "label":"Financial (English)",
            "alternates":["financial", "finance", "stock market", "stocks"]
        },{
            "value":"fi",
            "label":"Finnish",
            "alternates":["suomi", "suomen kieli"]
        },{
            "value":"fr",
            "label":"French",
            "alternates":["français", "langue française"]
        },{
            "value":"ff",
            "label":"Fula",
            "alternates":["Fulah", "Fulfulde", "Pulaar", "Pular"]
        },{
            "value":"gl",
            "label":"Galician",
            "alternates":["Galego"]
        },{
            "value":"ka",
            "label":"Georgian",
            "alternates":["ქართული"]
        },{
            "value":"de",
            "label":"German",
            "alternates":["Deutsch"]
        },{
            "value":"el",
            "label":"Greek",
            "alternates":["Modern Greek", "Ελληνικά"]
        },{
            "value":"gn",
            "label":"Guaraní",
            "alternates":["Avañeẽ"]
        },{
            "value":"gu",
            "label":"Gujarati",
            "alternates":["ગુજરાતી"]
        },{
            "value":"ht",
            "label":"Haitian",
            "alternates":["Haitian Creole", "Kreyòl ayisyen"]
        },{
            "value":"ha",
            "label":"Hausa",
            "alternates":["Hausa", "هَوُسَ"]
        },{
            "value":"he",
            "label":"Hebrew",
            "alternates":["Modern Hebrew", "עברית"]
        },{
            "value":"hz",
            "label":"Herero",
            "alternates":["Otjiherero"]
        },{
            "value":"hi",
            "label":"Hindi",
            "alternates":["हिन्दी", "हिंदी"]
        },{
            "value":"ho",
            "label":"Hiri Motu",
            "alternates":["Hiri Motu"]
        },{
            "value":"hu",
            "label":"Hungarian",
            "alternates":["Magyar"]
        },{
            "value":"ia",
            "label":"Interlingua",
            "alternates":["Interlingua"]
        },{
            "value":"id",
            "label":"Indonesian",
            "alternates":["Bahasa Indonesia"]
        },{
            "value":"ie",
            "label":"Interlingue",
            "alternates":["Occidental"]
        },{
            "value":"ga",
            "label":"Irish",
            "alternates":["Gaeilge"]
        },{
            "value":"ig",
            "label":"Igbo",
            "alternates":["Asụsụ Igbo"]
        },{
            "value":"ik",
            "label":"Inupiaq",
            "alternates":["Iñupiaq", "Iñupiatun"]
        },{
            "value":"io",
            "label":"Ido",
            "alternates":["Ido"]
        },{
            "value":"is",
            "label":"Icelandic",
            "alternates":["Íslenska"]
        },{
            "value":"it",
            "label":"Italian",
            "alternates":["Italiano"]
        },{
            "value":"iu",
            "label":"Inuktitut",
            "alternates":["ᐃᓄᒃᑎᑐᑦ"]
        },{
            "value":"ja",
            "label":"Japanese",
            "alternates":["日本語 (にほんご／にっぽんご)"]
        },{
            "value":"jv",
            "label":"Javanese",
            "alternates":["basa Jawa"]
        },{
            "value":"kl",
            "label":"Kalaallisut",
            "alternates":["Greenlandic", "kalaallit oqaasii"]
        },{
            "value":"kn",
            "label":"Kannada",
            "alternates":["ಕನ್ನಡ"]
        },{
            "value":"kr",
            "label":"Kanuri",
            "alternates":["Kanuri"]
        },{
            "value":"ks",
            "label":"Kashmiri",
            "alternates":["कश्मीरी", "كشميري‎"]
        },{
            "value":"kk",
            "label":"Kazakh",
            "alternates":["Қазақ тілі"]
        },{
            "value":"km",
            "label":"Khmer",
            "alternates":["ភាសាខ្មែរ"]
        },{
            "value":"ki",
            "label":"Kikuyu, Gikuyu",
            "alternates":["Gĩkũyũ"]
        },{
            "value":"rw",
            "label":"Kinyarwanda",
            "alternates":["Ikinyarwanda"]
        },{
            "value":"ky",
            "label":"Kirghiz",
            "alternates":["Kyrgyz", "кыргыз тили"]
        },{
            "value":"kv",
            "label":"Komi",
            "alternates":["коми кыв"]
        },{
            "value":"kg",
            "label":"Kongo",
            "alternates":["KiKongo"]
        },{
            "value":"ko",
            "label":"Korean",
            "alternates":["한국어 (韓國語)", "조선말 (朝鮮語)"]
        },{
            "value":"ku",
            "label":"Kurdish",
            "alternates":["Kurdî", "كوردی‎"]
        },{
            "value":"kj",
            "label":"Kwanyama",
            "alternates":["Kuanyama"]
        },{
            "value":"la",
            "label":"Latin",
            "alternates":["Latine", "Lingua latina"]
        },{
            "value":"lb",
            "label":"Luxembourgish",
            "alternates":["Lëtzebuergesch", "Letzeburgesch"]
        },{
            "value":"lg",
            "label":"Luganda",
            "alternates":["Luganda"]
        },{
            "value":"li",
            "label":"Limburgish",
            "alternates":["Limburgs", "Limburgan", "Limburger"]
        },{
            "value":"ln",
            "label":"Lingala",
            "alternates":["Lingála"]
        },{
            "value":"lo",
            "label":"Lao",
            "alternates":["ພາສາລາວ"]
        },{
            "value":"lt",
            "label":"Lithuanian",
            "alternates":["lietuvių kalba"]
        },{
            "value":"lu",
            "label":"Luba-Katanga",
            "alternates":[""]
        },{
            "value":"lv",
            "label":"Latvian",
            "alternates":["latviešu valoda"]
        },{
            "value":"gv",
            "label":"Manx",
            "alternates":["Gaelg", "Gailck"]
        },{
            "value":"mk",
            "label":"Macedonian",
            "alternates":["македонски јазик"]
        },{
            "value":"mg",
            "label":"Malagasy",
            "alternates":["Malagasy fiteny"]
        },{
            "value":"ms",
            "label":"Malay",
            "alternates":["bahasa Melayu", "بهاس ملايو‎"]
        },{
            "value":"ml",
            "label":"Malayalam",
            "alternates":["മലയാളം"]
        },{
            "value":"mt",
            "label":"Maltese",
            "alternates":["Malti"]
        },{
            "value":"mi",
            "label":"Māori",
            "alternates":["te reo Māori"]
        },{
            "value":"mr",
            "label":"Marathi",
            "alternates":["Marāṭhī", "मराठी"]
        },{
            "value":"mh",
            "label":"Marshallese",
            "alternates":["Kajin M̧ajeļ"]
        },{
            "value":"mn",
            "label":"Mongolian",
            "alternates":["монгол"]
        },{
            "value":"na",
            "label":"Nauru",
            "alternates":["Ekakairũ Naoero"]
        },{
            "value":"nv",
            "label":"Navajo",
            "alternates":["Navaho", "Diné bizaad, Dinékʼehǰí"]
        },{
            "value":"nb",
            "label":"Norwegian Bokmål",
            "alternates":["Norsk bokmål"]
        },{
            "value":"nd",
            "label":"North Ndebele",
            "alternates":["isiNdebele"]
        },{
            "value":"ne",
            "label":"Nepali",
            "alternates":["नेपाली"]
        },{
            "value":"ng",
            "label":"Ndonga",
            "alternates":["Owambo"]
        },{
            "value":"nn",
            "label":"Norwegian Nynorsk",
            "alternates":["Norsk nynorsk"]
        },{
            "value":"no",
            "label":"Norwegian",
            "alternates":["Norsk"]
        },{
            "value":"ii",
            "label":"Nuosu",
            "alternates":["ꆈꌠ꒿ Nuosuhxop"]
        },{
            "value":"nr",
            "label":"South Ndebele",
            "alternates":["isiNdebele"]
        },{
            "value":"oc",
            "label":"Occitan",
            "alternates":["Occitan"]
        },{
            "value":"oj",
            "label":"Ojibwe",
            "alternates":["Ojibwa", "ᐊᓂᔑᓈᐯᒧᐎᓐ"]
        },{
            "value":"cu",
            "label":"Old Church Slavonic",
            "alternates":["ѩзыкъ словѣньскъ", "Church Slavic", "Church Slavonic", "Old Bulgarian", "Old Slavonic"]
        },{
            "value":"om",
            "label":"Oromo",
            "alternates":["Afaan Oromoo"]
        },{
            "value":"or",
            "label":"Oriya",
            "alternates":["ଓଡ଼ିଆ"]
        },{
            "value":"os",
            "label":"Ossetian",
            "alternates":["ирон æвзаг", "Ossetic"]
        },{
            "value":"pa",
            "label":"Panjabi",
            "alternates":["Punjabi", "ਪੰਜਾਬੀ", "پنجابی‎"]
        },{
            "value":"pi",
            "label":"Pāli",
            "alternates":["पाऴि"]
        },{
            "value":"fa",
            "label":"Persian",
            "alternates":["فارسی"]
        },{
            "value":"pl",
            "label":"Polish",
            "alternates":["polski"]
        },{
            "value":"ps",
            "label":"Pashto",
            "alternates":["Pushto", "پښتو"]
        },{
            "value":"pt",
            "label":"Portuguese",
            "alternates":["Português"]
        },{
            "value":"qu",
            "label":"Quechua",
            "alternates":["Runa Simi", "Kichwa"]
        },{
            "value":"rm",
            "label":"Romansh",
            "alternates":["rumantsch grischun"]
        },{
            "value":"rn",
            "label":"Kirundi",
            "alternates":["kiRundi"]
        },{
            "value":"ro",
            "label":"Romanian",
            "alternates":["Moldavian", "Moldovan", "română"]
        },{
            "value":"ru",
            "label":"Russian",
            "alternates":["русский язык"]
        },{
            "value":"sa",
            "label":"Sanskrit",
            "alternates":["Saṁskṛta", "संस्कृतम्"]
        },{
            "value":"sc",
            "label":"Sardinian",
            "alternates":["sardu"]
        },{
            "value":"sd",
            "label":"Sindhi",
            "alternates":["सिन्धी", "سنڌي، سندھی‎"]
        },{
            "value":"se",
            "label":"Northern Sami",
            "alternates":["Davvisámegiella"]
        },{
            "value":"sm",
            "label":"Samoan",
            "alternates":["gagana faa Samoa"]
        },{
            "value":"sg",
            "label":"Sango",
            "alternates":["yângâ tî sängö"]
        },{
            "value":"sr",
            "label":"Serbian",
            "alternates":["српски језик"]
        },{
            "value":"gd",
            "label":"Gaelic",
            "alternates":["Scottish Gaelic", "Gàidhlig"]
        },{
            "value":"sn",
            "label":"Shona",
            "alternates":["chiShona"]
        },{
            "value":"si",
            "label":"Sinhala",
            "alternates":["Sinhalese", "සිංහල"]
        },{
            "value":"sk",
            "label":"Slovak",
            "alternates":["slovenčina"]
        },{
            "value":"sl",
            "label":"Slovene",
            "alternates":["slovenščina"]
        },{
            "value":"so",
            "label":"Somali",
            "alternates":["Soomaaliga", "af Soomaali"]
        },{
            "value":"st",
            "label":"Southern Sotho",
            "alternates":["Sesotho"]
        },{
            "value":"es",
            "label":"Spanish",
            "alternates":["español", "Castilian", "castellano"]
        },{
            "value":"su",
            "label":"Sundanese",
            "alternates":["Basa Sunda"]
        },{
            "value":"sw",
            "label":"Swahili",
            "alternates":["Kiswahili"]
        },{
            "value":"ss",
            "label":"Swati",
            "alternates":["SiSwati"]
        },{
            "value":"sv",
            "label":"Swedish",
            "alternates":["svenska"]
        },{
            "value":"ta",
            "label":"Tamil",
            "alternates":["தமிழ்"]
        },{
            "value":"te",
            "label":"Telugu",
            "alternates":["తెలుగు"]
        },{
            "value":"tg",
            "label":"Tajik",
            "alternates":["тоҷикӣ", "toğikī", "تاجیکی‎"]
        },{
            "value":"th",
            "label":"Thai",
            "alternates":["ไทย"]
        },{
            "value":"ti",
            "label":"Tigrinya",
            "alternates":["ትግርኛ"]
        },{
            "value":"bo",
            "label":"Tibetan",
            "alternates":["Tibetan Standard", "བོད་ཡིག"]
        },{
            "value":"tk",
            "label":"Turkmen",
            "alternates":["Türkmen", "Түркмен"]
        },{
            "value":"tl",
            "label":"Tagalog",
            "alternates":["Wikang Tagalog", "ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔"]
        },{
            "value":"tn",
            "label":"Tswana",
            "alternates":["Setswana"]
        },{
            "value":"to",
            "label":"Tonga",
            "alternates":["faka Tonga"]
        },{
            "value":"tr",
            "label":"Turkish",
            "alternates":["Türkçe"]
        },{
            "value":"ts",
            "label":"Tsonga",
            "alternates":["Xitsonga"]
        },{
            "value":"tt",
            "label":"Tatar",
            "alternates":["татарча", "tatarça", "تاتارچا‎"]
        },{
            "value":"tw",
            "label":"Twi",
            "alternates":["Twi"]
        },{
            "value":"ty",
            "label":"Tahitian",
            "alternates":["Reo Tahiti"]
        },{
            "value":"ug",
            "label":"Uighur",
            "alternates":["Uyghur", "Uyƣurqə", "ئۇيغۇرچە‎"]
        },{
            "value":"uk",
            "label":"Ukrainian",
            "alternates":["українська"]
        },{
            "value":"ur",
            "label":"Urdu",
            "alternates":["اردو"]
        },{
            "value":"uz",
            "label":"Uzbek",
            "alternates":["zbek", "Ўзбек", "أۇزبېك‎"]
        },{
            "value":"ve",
            "label":"Venda",
            "alternates":["Tshivenḓa"]
        },{
            "value":"vi",
            "label":"Vietnamese",
            "alternates":["Tiếng Việt"]
        },{
            "value":"vo",
            "label":"Volapük",
            "alternates":["Volapük"]
        },{
            "value":"wa",
            "label":"Walloon",
            "alternates":["Walon"]
        },{
            "value":"cy",
            "label":"Welsh",
            "alternates":["Cymraeg"]
        },{
            "value":"wo",
            "label":"Wolof",
            "alternates":["Wollof"]
        },{
            "value":"fy",
            "label":"Western Frisian",
            "alternates":["Frysk"]
        },{
            "value":"xh",
            "label":"Xhosa",
            "alternates":["isiXhosa"]
        },{
            "value":"yi",
            "label":"Yiddish",
            "alternates":["ייִדיש"]
        },{
            "value":"yo",
            "label":"Yoruba",
            "alternates":["Yorùbá"]
        },{
            "value":"za",
            "label":"Zhuang",
            "alternates":["Chuang", "Saɯ cueŋƅ", "Saw cuengh"]
        },{
            "value":"zu",
            "label":"Zulu",
            "alternates":["isiZulu"]
        },{
            "value": "dz",
            "label": "Dzongkha",
            "alternates": []
          },
          {
            "value": "sh",
            "label": "Serbo-Croatian",
            "alternates": []
          }],
};

Languages.languageLabels = (function() {
    var languages = Languages.allLanguages;
    var labels = {};
    var i = languages.length;
    while (i--) {
        labels[languages[i].value] = languages[i].label;
    }
    labels["00"] = "Unknown";
    return labels;
})();

export default Languages;
