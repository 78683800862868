import TrendsSegments from "infegy-frontend/static_data/trends-segments";
import Ember from "ember";

export default Ember.Component.extend({
    tagName: "span",
    classNames: ["trends-segment-selectors"],

    segmentDefinition: null,

    selectedSegment: "Posts",
    selectedSubSegment: null,

    displayAsDropdown: false,

    innerSelectedSubSegment: Ember.computed.oneWay("selectedSubSegment"),

    subSegmentOptions: Ember.computed("selectedSegment", "selectedSubSegment",
            "segmentDefinition", function () {
        var selectedSegment = this.selectedSegment,
            selectedSubSegment = this.selectedSubSegment,
            segmentDefinition = this.segmentDefinition,
            subSegmentOptionTypes = segmentDefinition && segmentDefinition.subSegmentOptions,
            subSegmentOptions = (subSegmentOptionTypes && subSegmentOptionTypes[selectedSegment]) || [],
            subSegmentValues = subSegmentOptions || [],
            defaultFieldValues = TrendsSegments.defaultFieldValues;

        if (!subSegmentOptions) {
            return [];
        }

        if (!selectedSubSegment || (subSegmentValues && !subSegmentValues.includes(selectedSubSegment))) {
            this.send("selectionChanged", subSegmentValues[0]);
        }

        subSegmentOptions = subSegmentOptions.map(option => defaultFieldValues[option]);
        this.send("titleChanged", selectedSubSegment);
        return subSegmentOptions;
    }),

    hasMultipleSubSectionOptions: Ember.computed("subSegmentOptions", function () {
        var subSegmentOptions = this.subSegmentOptions;
        return subSegmentOptions && subSegmentOptions.length > 1;
    }),

    chartTitleChanged: () => {},
    changed: () => {},
    actions: {
        titleChanged: function (selectedSubSegment) {
            var defaultFieldValues = TrendsSegments.defaultFieldValues,
                selectedSegment = this.selectedSegment,
                subSegment = selectedSubSegment && defaultFieldValues[selectedSubSegment],
                subSegmentTitle = subSegment && subSegment.title;
            if (subSegment && subSegment.chartTitle) {
                subSegmentTitle = subSegment.chartTitle;
            } else if (subSegmentTitle !== selectedSegment) {
                subSegmentTitle = [selectedSegment, subSegmentTitle].join(" ");
            }
            this.chartTitleChanged(subSegmentTitle);
        },
        selectionChanged: function (selectedSubSegment) {
            this.send("titleChanged", selectedSubSegment);
            this.changed(selectedSubSegment);
        },
    }

});
