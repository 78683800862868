import Ember from 'ember';
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import JsonStore from "infegy-frontend/json-store/model";
import Prop from "infegy-frontend/json-store/properties/model";

export default JsonStore.extend({
    createdDate: Prop.String(),
    updatedDate: Prop.String(),
    id: Prop.Int(),
    query: Prop.String(),
    isActive: Prop.Boolean(),
    title: Prop.String({default:"New Alert"}),
    userId: Prop.Int(),
    volumeTrigger: Prop.Number({min:0, default: 50}),
    sentimentTrigger: Prop.Number({min:0, default: 50}),
    language: Prop.String(),
    emails: [],

    loadJSON: function(jsonData){
        if(jsonData && typeof(jsonData.emails) === "string"){
            jsonData.emails = JSON.parse(jsonData.emails);
        }
        this._super(jsonData);
    },

    toJSON: function(){
        let json = this._super();
        if(json){
            json.emails = JSON.stringify(this.emails);
        }
        return json;
    }
});