import Ember from "ember";
import JsonStore from "infegy-frontend/json-store/model";
import Prop from "infegy-frontend/json-store/properties/model";
import QueryDateAttr from "infegy-frontend/models/queries/filters/query_date";
import AtlasConfig from "infegy-frontend/config/infegy-app-config";

export default JsonStore.extend({
    apiEnabled: Prop.Boolean(),
    canvasEnabled: Prop.Boolean(),
    customDatasetsEnabled: Prop.Boolean(),
    customDictionariesEnabled: Prop.Boolean(),
    dataProcessorEnabled: Prop.Boolean(),
    historicalAccess: Prop.String(),
    maxQueries: Prop.Int(),
    queryAlertsEnabled: Prop.Boolean(),
    concurrentUsers: Prop.Int(),
    maxCustomDatasets: Prop.Int(),
    customDatasetStorage: Prop.Int(),
    customDatasetMaxDocuments: Prop.Int(),
    socialDataAccess: Prop.Boolean(),

    historicalAccessTimestamp: Ember.computed("historicalAccess", function(){
        if (!this.historicalAccess) {
            return AtlasConfig.EarliestAtlasTimestamp;
        };

        let queryDateObj = QueryDateAttr.create();
        queryDateObj.loadJSON(this.historicalAccess, true);
        let historicalAccessTimestamp = queryDateObj.timestamp;

        return historicalAccessTimestamp;
    }),

    // deprecated, use original value for more clear naming
    earliestTimestamp: Ember.computed.alias('historicalAccessTimestamp'),

    hasUnlimitedDataStorage: Ember.computed.lt("customDatasetStorage", 0),
    hasUnlimitedPostStorage: Ember.computed.lt("customDatasetMaxDocuments", 0),

    /*
        The api has a limit of 128 MB minus overhead. To allow a safe overhead the user 
        should be restricted to 127ish MB. To make this more consumable for the user, 
        this is rounded down to 100 MB, or 104857600 bytes.
        
        The max storage size is the smaller value between the user permissions and the 
        api limitation.
    */
    customDatasetUploadLimit: Ember.computed("customDatasetStorage", function() {
        if (this.hasUnlimitedDataStorage)
            return 104857600;
        return Math.min(this.customDatasetStorage | 0, 104857600);
    })
});
