import Ember from 'ember';

export function percentageFormat(params, namedArgs) {
    var number = (params[0] || 0.0);

    if (!namedArgs.wholePercentage) {
        number *= 100.0;
    }
    var hidePercentSymbol = namedArgs.hidePercentSymbol;
    var abs = namedArgs.abs;
    var digits = namedArgs.digits;
    var maxDecimals = namedArgs.maxDecimals;
    var maxValue = namedArgs.maxValue || 1;

    if (isFinite(maxValue) && maxValue > 0) {
        number /= maxValue;
    }

    if (isFinite(digits) && !isNaN(digits)) {
        number = number.toFixed(digits);
    } else if (number < 1.0 && number > -1.0) {
        if (maxDecimals) {
            number = number.toFixed(maxDecimals);
        } else {
            number = number.toPrecision(digits || 2);
        }
    } else if (number < 9.95 && number > -9.95) {
        number = number.toFixed(1);
    } else {
        number = Math.round(number);
    }

    if (abs) {
        number = Math.abs(number);
    }
    number = number.toString();

    if (!hidePercentSymbol) {
        number += '%';
    }

    return number;
}

export default Ember.Helper.helper(percentageFormat);
