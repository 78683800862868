import Ember from "ember";
import Component from "@ember/component";
import { action, computed } from '@ember/object';

import Snippets from "infegy-frontend/models/users/snippets";

export default class SnippetsList extends Component {
    declineSharingId = 0;
    copyingToSnippet = null;
    deletingSnippet = null;
    snippets = [];
    user = null;

    @computed.oneWay("snippets.search") searchText;
    @computed.oneWay("snippets.sortField") sortField;
    @computed.oneWay("snippets.sortDirection") sortDirection;
    pageSize = 10;
    sortField = "lastDate";

    filterFieldOptions = [
        {
            label: "All",
            value: "all"
        },
        {
            label: "Title",
            value: "title"
        },
        {
            label: "Query",
            value: "query"
        }
    ];

    sortFieldOptions = Snippets.SORT_FIELD_OPTIONS;

    @computed.readOnly("snippets.isLoading") isLoading;

    // event handlers
    beginDelete(snippet) {};
    cancelDeclineSharing() {};
    cancelDelete() {};
    create() {};
    confirmDeclineSharing(snippet) {};
    confirmDelete(snippet) {};
    edit(snippet) {};
    sharingDeclined(snippet) {};

    @action
    onCreate() {
        this.create();
    }

    @action 
    onSearch(options) {
        options["page_index"] = 0;
        this.snippets.getPage(options);
    }

    @action
    onConfirmDeclineSharing(snippet) {
        this.confirmDeclineSharing(snippet);
    }

    @action
    onConfirmDelete(snippet) {
        this.confirmDelete(snippet);
    }

    @action
    onEdit(snippet) {
        this.edit(snippet);
    }

    @action
    onSharingDeclined(snippet) {
        this.sharingDeclined(snippet)
    }
}