import Ember from "ember";
import {computed, action} from "@ember/object";
import { tracked } from '@glimmer/tracking';
import JsonStore from 'infegy-frontend/json-store/model';
import Prop from 'infegy-frontend/json-store/properties/model';
import classic from "ember-classic-decorator";

@classic
export default class TrendChartMeta extends JsonStore {
    @Prop.String({default: "line"}) chartType;

    @Prop.Boolean() normalizeStartTimes;

    @Prop.String() selectedGrouping;

    @Prop.Boolean() hideEvents;

    @computed.not("hideEvents") showEvents;

    groupingOptions = [];

    // For non standard API results or grouping
    // such as for timeOfDay API or weekday grouping
    // Overrides how smoe trend chart features funtion and
    // disables some features.
    groupingOverride = null;
    groupingOptionsOverride = null;

    mouseIsDown = false;

    // Interaction
    isHovered = false;
    isBrushed = false;
    isSelected = false;
    eventsSelected = false;

    // Mouse Hover
    hoveredField = null;
    hoveredXPct = 0;
    hoveredYPct = 0;

    @computed("hoveredXPct")
    get hoveredXPctWhole() { return this.hoveredXPct * 100; }

    @computed("hoveredYPct")
    get hoveredYPctWhole() { return this.hoveredYPct * 100; }

    @computed("hoveredYPctWhole")
    get hoveredYPctWholeInv() { return 100 - this.hoveredYPctWhole; }

    // Selections
    selectedField = null;
    selectedXPct = 0;

    @computed("selectedXPct")
    get selectedXPctWhole() { return this.selectedXPct * 100; }

    // Brushing
    brushStartXPct = 0;
    brushEndXPct = 0;

    @computed("brushStartXPct")
    get brushStartXPctWhole() { return this.brushStartXPct * 100; }

    @computed("brushEndXPct")
    get brushEndXPctWhole() { return this.brushEndXPct * 100; }

    @computed("groupingOverride", "selectedGrouping", "defaultGrouping")
    get grouping() {
        return this.groupingOverride || this.selectedGrouping || this.defaultGrouping;
    }
    set grouping(grouping) {
        this.set("selectedGrouping", grouping);
    }

    @computed("selectedField", "hoveredField")
    get interactedField() {
        return this.hoveredField || this.selectedField;
    }

    findClosestRowsFromPct(fields, xPct) {
        if (Ember.isEmpty(fields) || !xPct) {
            return;
        }
        let closestRows = fields.map(trendField => {
            return trendField.findRowFromXPct(xPct);
        });
        return {
            rows: closestRows,
            closest: closestRows.slice().sortBy("distance").firstObject
        };
    }

    findRowsFromXPctRange(fields, xPctStart, xPctEnd) {
        if (Ember.isEmpty(fields) || !xPctStart || !xPctEnd) {
            return;
        }
        let closestRows = fields.map(trendField => {
            return {
                rows: trendField.findRowsFromXPctRange(xPctStart, xPctEnd),
                field: trendField
            }
        });
        return closestRows;
    }
}
