import Ember from 'ember';

function hasItem(params, namedArgs) {
    var arr = params[0],
        toFind = params[1];
        
    if(!arr || !toFind)
        return;
    
    if(namedArgs.findBy){
        return arr.find((item) => item[namedArgs.findBy] === toFind);
    } else {
        return toFind in arr;
    }
}

export default Ember.Helper.helper(hasItem);
