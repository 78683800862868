import Ember from "ember";
import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import DataSeriesComputedFieldPercentage from "infegy-frontend/models/data_series/data_series_computed_field_percentage";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";
import QueryAPITrendsEndpoints from "infegy-frontend/models/queries/query_api_trends_endpoints";
import RawTrendsField from "infegy-frontend/models/trends/raw_trends_field";
import Languages from "infegy-frontend/static_data/query/query_filters/languages";

var allLanguages = Languages.activeLanguageCodes;

var languagesBaseFields = {
        "datestamp": "datestring",
        "timestamp": "timestamp",
        "documents": "number",
        "subjectSentences": "number",
        "sentences": "number"
    };

var languagesAPIDescription = allLanguages.reduce(function(memo, code) {
    memo[code] = "number";
    return memo;
}, {});

var languagesAPIComputedFields = allLanguages.map(function(code) {
    var originalFieldName = code;
    var newFieldName = [code, "DocumentsDistribution"].join("");
    return DataSeriesComputedFieldPercentage.fieldSetup(newFieldName, "documents", originalFieldName);
});

export default QueryAPIResults.extend({
    title : "languageTrend",
    description : DataSeriesDescription.load(
       Object.assign(languagesBaseFields, languagesAPIDescription),
        languagesAPIComputedFields
    )
});

var languageTrendFieldDefaults = allLanguages.map(function(code) {
    return {
        apiName: "languageTrend",
        title: Languages.languageLabels[code],
        field: code
    };
});

var makeLanguageFields = function (suffix, axisGroup, fixedMaxY) {
    return languageTrendFieldDefaults.map(function (defaultField) {
        return RawTrendsField.make(Object.assign({}, defaultField, {
            title: defaultField.title,
            field: defaultField.field + suffix,
            fixedMaxY: fixedMaxY,
            axisGroup: axisGroup
        }));
    });
};

QueryAPITrendsEndpoints.base.push({
    title: "Languages",
    value: "Languages",
    maxAvailableFields: 10,
    fieldSets: [{
        title: "Document Distribution",
        value: "LanguageDocumentsDistribution",
        fields: makeLanguageFields("DocumentsDistribution", "percentage", 1.0)
    }, {
        title: "Posts",
        value: "LanguageDocuments",
        fields: makeLanguageFields("", "LanguageDocuments")
    }]
});
