import Component from "@ember/component";
import classic from "ember-classic-decorator";

import RecentQueries from "infegy-frontend/models/users/recent_queries";

@classic
export default class extends Component {
    queryFormQueries = [];
    queries = RecentQueries.create();
    user = null;

    selectedQuery = null;
    sortFieldOptions = [];

    search() {
        this.queries.getPage();
    }
}