import DataSeriesComputedFieldBase from "infegy-frontend/models/data_series/data_series_computed_fields";

// Takes in a Timeline array and outputs the percentage of change of the timeline.
let DataSeriesComputedFieldTimelineGrowth = DataSeriesComputedFieldBase.extend({
    destinationField: "",
    timelineField: "",
    type: "average",

    addFieldsTo: function(fields) {
        let destinationField = this.destinationField,
            type = this.type;

            if (destinationField && type) {
                fields[destinationField] = type;
            }

        return fields;
    },

    rowCompute: function(row) {
        var timelineField = this.timelineField,
            timeline = row[timelineField],
            timelineLength = timeline.length,
            halfLength = timelineLength - Math.ceil(timelineLength / 2),
            firstHalf = timeline.slice(0, halfLength),
            secondHalf = timeline.slice(-halfLength),
            firstSum = firstHalf.reduce((m,n) => m+n, 0),
            secondSum = secondHalf.reduce((m,n) => m+n, 0);

        row[this.destinationField] = (secondSum - firstSum) / (firstSum);

        return row;
    }
});

DataSeriesComputedFieldTimelineGrowth.reopenClass({
   fieldSetup: function(destinationField, timelineField) {
       return DataSeriesComputedFieldTimelineGrowth.create({
          destinationField: destinationField,
          timelineField: timelineField
       });
   }
});

export default DataSeriesComputedFieldTimelineGrowth;
