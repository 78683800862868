import Ember from "ember";
import { computed } from '@ember/object';
import classic from "ember-classic-decorator";
import JsonStore from 'infegy-frontend/json-store/model';
import Prop from 'infegy-frontend/json-store/properties/model';

@classic
export default class extends JsonStore {
    filterClass = "list-filter";

    isActive = true;

    isDisabled = false;
    isExclude = false;

    value = null;

    toJSON() {
        if (this.isDisabled || !this.isActive ||
            !this.value || !this.value.length) {
            return null;
        }

        var val = "";
        if (this.isExclude) {
            val += "e";
        }

        this.value.forEach(function (item) {
            if (val.length) {
                val += ",";
            }

            val += item.toString();
        });
        return val;
    }

    loadJSON(value) {
        if (typeof(value) === "object") {
            super.loadJSON(...arguments);
        }
        if (Ember.isEmpty(value) || typeof(value) !== "string") {
            return;
        }
        var array = value.split(",");
        if (!array) {
            return;
        }

        var newProperties = {
                isActive: false,
                isExclude: false,
                value: []
            };

        if (array[0].trim().toLowerCase() === "e") {
            newProperties.isExclude = true;
            array = array.slice(1);
        }

        array = array.map(function(item) {
            return item.trim();
        });

        newProperties.value = array;

        if (!array.length) {
            newProperties.isExclude = false;
        } else {
            newProperties.isActive = true;
        }
        this.setProperties(newProperties);
    }
}
