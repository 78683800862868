import Ember from "ember";
import ChartColors from "infegy-frontend/static_data/fixtures/chart_colors";

export default Ember.Component.extend({
    classNames: ["custom-trends-field-editor-color-view"],

    colorClass: "",

    editorOpen: false,

    colorClasses: Ember.computed({
        get: function(key) {
            return ChartColors.get("indexedColorClasses");
        },
    }),

    actions: {
        editColor: function() {
            this.toggleProperty("editorOpen");
        },
        setColor: function(colorClass) {
            this.set("colorClass", colorClass);
            this.set("editorOpen", false);
        }
    }
});
