import Ember from "ember";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import SubquerySet from "infegy-frontend/models/users/subquery_set";
import classic from "ember-classic-decorator";
import * as Sentry from "@sentry/browser";

@classic
class SubquerySets extends Ember.ArrayProxy {
    /** @type SubquerySet[] */
    content = Ember.A();
    isLoading = true;

    constructor(){
        super(...arguments);
        this.setup();
    }

    async setup() {
        try{ 
            this.set("isLoading", true);
            var response = await AtlasAuth.Get("api/v3/query-sets");
        } catch(error) {
            if(AtlasAuth.isLoggedIn){
                Sentry && Sentry.captureMessage("Could not load subquery sets.");
            }
            return;
        }
        let subquerySets = [];
        response.output.forEach((entryJSON)=>{
            var entry = SubquerySet.create();
            entry.loadJSON(entryJSON);
            subquerySets.push(entry);
        });
        this.setProperties({
            content: subquerySets,
            isLoading: false
        });
    }

    addNewQuerySet(querySetJson) {
        let newQuerySet = SubquerySet.create();
        newQuerySet.loadJSON(querySetJson);
        this.content.pushObject(newQuerySet);
        return newQuerySet;
    }
}

export default SubquerySets;