// import Ember from "ember";
import { computed } from '@ember/object';

import QueryURL from "infegy-frontend/models/queries/query_url";
import JsonStore from 'infegy-frontend/json-store/model';
import Prop from 'infegy-frontend/json-store/properties/model';
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import classic from "ember-classic-decorator";

@classic
export default class Workspace extends JsonStore {
    @Prop.Int() id;
    @Prop.Int() userId;
    @Prop.String() title;
    @Prop.String() query;
    @Prop.Attr() created; // date as string
    @Prop.Attr() lastUsed; // date as string
    @Prop.Int() runCount;
    @Prop.Boolean() removed;
    @Prop.Boolean() deleted;
    @Prop.Boolean() saved;
    @Prop.Attr() folderId;
    @Prop.Boolean() isShared;

    @computed.alias("id") value;

    @computed("lastUsed")
    get lastUsedDate() {
        return new Date(this.lastUsed);
    }

    @computed("created")
    get createdDate() {
        return new Date(this.created);
    }

    @computed("lastUsedDate")
    get lastUsedTimestamp() {
        return this.lastUsedDate.valueOf();
    }

    @computed("createdDate")
    get createdTimestamp() {
        return this.createdDate.valueOf();
    }

    @computed("query")
    get queryObject() {
        var queryString = this.query,
            queryArray = [];
        if (queryString) {
            try {
                queryArray = QueryURL.urlToQueryArray(queryString);
            } catch (e) {
                console.warn(`Could not parse queries for workspace: ${queryString}`)
            }
        }
        return queryArray;
    }

    save(){
        var workspaceJSON = this.toJSON();
        return AtlasAuth.Post({
            url: "api/v3/workspace.json",
            contentType: 'application/json',
            data: JSON.stringify({
                workspaces: [workspaceJSON]
            })
        }).catch((error) => {
            console.error("Failed to save workspace", error);
        });
    }

    getById(workspaceId) {
        return AtlasAuth.Get({
            url: `api/v3/workspace?id=${workspaceId}`,
        }).then((response) => {
            this.loadJSON(response.output[0]);
        });
    }

    delete(userId) {
        return AtlasAuth.ImmediateAjax({
            url: "api/v3/workspace_management",
            method: "DELETE",
            data: {
                workspace_id: this.id,
                user_id: userId
            }
        });
    }
}
