import Ember from "ember";
import D3SVGContentArea from "infegy-frontend/components/charting/d3-base/d3-svg-content-area/component";
import d3 from "d3";

export default Ember.Component.extend({
    classNames: ["svg_wrapper"],

    preserveAspectRatio: false,
    aspectRatio: 2.7777778,
    marginLeft: 17,
    marginRight: 17,
    marginTop: 17,
    marginBottom: 17,
    width: 0,
    height: null,
    contentArea: null,
    setupOnInit: Ember.on("init",function() {
        this.set("contentArea", D3SVGContentArea.create());
    }),

    safeWidth: Ember.computed("width", {
        get: function(key) {
            return this.width > 0 ? this.width : 1;
        }
    }),
    safeHeight: Ember.computed("height", {
        get: function(key) {
            return this.height > 0 ? this.height : 1;
        }
    }),

    displayStyleSafe: Ember.computed("isEmpty", "isLoading", {
        get: function(key) {
            var style = "";
            if (this.isEmpty || this.isLoading) {
                style += "display:none;";
            }
            return new Ember.String.htmlSafe(style);
        },
    }),

    updateContentArea: function() {
        var marginLeft = this.marginLeft,
            marginRight = this.marginRight,
            marginTop = this.marginTop,
            marginBottom = this.marginBottom,
            width = this.width,
            height = this.height;

        var trueWidth = width - marginLeft - marginRight;
        var trueHeight = height - marginTop - marginBottom;

        if (trueHeight < 1) {
            trueHeight = 1;
        }
        if (trueWidth < 1) {
            trueWidth = 1;
        }

        this.contentArea.setProperties({
            "x": marginLeft,
            "y": marginTop,
            "width": trueWidth,
            "height": trueHeight
        });
    },

    update: Ember.on("init",Ember.observer("marginTop", "marginLeft", "marginRight", "marginBottom", "width", "height", function() {
        Ember.run.once(this, "updateContentArea");
    })),

    resize: function() {
        if (!this.element || Ember.isEmpty(this.element)) {
            return;
        }
        if (this.preserveAspectRatio) {
            var computed = window.getComputedStyle(this.element);
            this.set("height", Math.ceil(computed.width / this.aspectRatio));
        } else if (!this.height || this.height <= 0) {
            this.set("height", 100);
        }
        this.updateContentArea();
    },

    teardownSVG: Ember.on("willDestroyElement",function() {
        if (this.resizeFunction) {
            window.removeEventListener("resize",this.resizeFunction)
        }
    }),
    setupSVG: Ember.on("init",function() {
        Ember.run.schedule('afterRender', this, function() {
            var computed = window.getComputedStyle(this.element);
            this.set("width", computed.width);
            this.resize();

            var timer_id;
            this.set("el", d3.select("#" + this.element.id).select("svg"));

            var resizeFunction = () => {
                Ember.run.throttle(()=>{
                    if (this.element) {
                        var computed = window.getComputedStyle(this.element);
                        this.set("width", computed.width);
                        this.resize();
                    }
                }, 250);
            };

            this.resize();
            this.set("resizeFunction", resizeFunction);
            window.addEventListener("resize", resizeFunction)
        });
    })
});
