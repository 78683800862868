import Component from "@ember/component";
import { action, computed } from "@ember/object";

export default class DictionariesList extends Component {
    dictionaries = [];
    dictionariesPerPage = 10;
    currentPage = 0;
    creatingDictionaryName = "";
    user = null;

    copyingToDictionary = null;

    sortField = "modified";
    sortFieldOptions = [{
        label: "Name",
        value: "name"
    }, {
        label: "Last Update",
        value: "modified"
    }, {
        label: "Creation Date",
        value: "created"
    }];

    pageSize = 10;
    filterFieldOptions = [
        {
            label: "Name",
            value: "name",
        }
    ];

    editDictionary = null;
    createDictionary = null;

    declineSharingId = 0;
    deletingDictionary = null;

    @computed("dictionaries", "sortField", "dictionaries.[]")
    get sortedDictionaries () {
        this.set("currentPage", 0);
        var sortedItems = (this.dictionaries || []).sortBy(this.sortField);
        if (["modified", "created"].includes(this.sortField)) {
            sortedItems.reverse();
        }
        return sortedItems;
    }

    @computed("sortedDictionaries.[]", "currentPage")
    get displayDictionaries() {
        var idx = this.currentPage * this.dictionariesPerPage;
        return this.sortedDictionaries.slice(idx, idx + this.dictionariesPerPage);
    }

    confirmDelete(dictionary) {};
    sharingDeclined(dictionary) {};

    @action
    onConfirmDeleteAction(dictionary) {
        this.confirmDelete(dictionary);
    }

    @action
    onSharingDeclined(dictionary) {
        this.sharingDeclined(dictionary);
    }

}