import Ember from "ember";
import Component from '@ember/component';
import { computed } from '@ember/object';
import FieldSelectorBase from "infegy-frontend/components/field-selector/parts/fields/row-value-field-base/component";
import layout from "infegy-frontend/components/field-selector/parts/fields/row-value-selector-layout/template";

let apis = {
    "Education": "education",
    "Income": "income",
    "Home Ownership": "homeOwnership",
    "Household Value": "householdValue"
};

let rowProperties = {
    "Education": [{
        rowProperty: "vsExpected",
        rowPropertyTitle: "Vs. Expected",
        fixedPortionWhole: 1.0,
        isPercentageType: true,
        fieldTypes: ["scalar", "portion"]
    },{
        rowProperty: "probability",
        rowPropertyTitle: "Probability",
        fixedPortionWhole: 100.0,
        isPercentageType: true,
        fieldTypes: ["scalar", "portion"]
    }],
    "Income": [{
        rowProperty: "vsExpected",
        rowPropertyTitle: "Vs. Expected",
        fixedPortionWhole: 1.0,
        isPercentageType: true,
        fieldTypes: ["scalar", "portion"]
    },{
        rowProperty: "count",
        rowPropertyTitle: "Posts",
        fieldTypes: ["scalar"]
    },{
        rowProperty: "probability",
        rowPropertyTitle: "Probability",
        fixedPortionWhole: 100.0,
        isPercentageType: true,
        fieldTypes: ["scalar", "portion"]
    }],
    "Home Ownership": [{
        rowProperty: "vsExpected",
        rowPropertyTitle: "Vs. Expected",
        fixedPortionWhole: 1.0,
        isPercentageType: true,
        fieldTypes: ["scalar", "portion"]
    },{
        rowProperty: "count",
        rowPropertyTitle: "Posts",
        fieldTypes: ["scalar"]
    },{
        rowProperty: "probability",
        rowPropertyTitle: "Probability",
        fixedPortionWhole: 100.0,
        isPercentageType: true,
        fieldTypes: ["scalar", "portion"]
    }],
    "Household Value": [{
        rowProperty: "vsExpected",
        rowPropertyTitle: "Vs. Expected",
        fixedPortionWhole: 1.0,
        isPercentageType: true,
        fieldTypes: ["scalar", "portion"]
    },{
        rowProperty: "count",
        rowPropertyTitle: "Posts",
        fieldTypes: ["scalar"]
    },{
        rowProperty: "probability",
        rowPropertyTitle: "Probability",
        fixedPortionWhole: 100.0,
        isPercentageType: true,
        fieldTypes: ["scalar", "portion"]
    }],
};

let allLookupProperties = {
    "Education": {
        lookupProperty: "id",
        lookupTitleProperty: "displayName",
    },
    "Income": {
        lookupProperty: "id",
        lookupTitleProperty: "displayName",
    },
    "Home Ownership": {
        lookupProperty: "id",
        lookupTitleProperty: "displayName",
    },
    "Household Value": {
        lookupProperty: "id",
        lookupTitleProperty: "displayName",
    }
};

export default class FieldSelectorAge extends FieldSelectorBase {
    layout = layout;

    allAvailableRowFields = rowProperties;

    @computed("selectedSubsegment")
    get apiName() {
        return apis[this.selectedSubsegment];
    }

    @computed("apiName", "selectedSubsegment", "allQueries", "allQueries.[]", "allQueries.@each.ages", "allQueries.@each.updated")
    get availableLookupValues() {
        let allQueries = this.allQueries || [],
            lookupProperties = allLookupProperties[this.selectedSubsegment],
            availableValues = [],
            apiName = apis[this.selectedSubsegment];

        if (!apiName || !lookupProperties) {
            return;
        }

        allQueries.forEach(query => {
            let api = query.get(apiName),
                rowValues = ((api && api.get("dataSeries.data")) || []).map(age => {
                    return {
                        apiName: apiName,
                        rowLookupProperty: lookupProperties.lookupProperty,
                        rowLookupValue: Ember.get(age, lookupProperties.lookupProperty),
                        rowLookupTitle: Ember.get(age, lookupProperties.lookupTitleProperty),
                    }
                });
            availableValues = availableValues.concat(rowValues || []).uniqBy("rowLookupValue");
        });
        return availableValues;
    };
};
