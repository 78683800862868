import Ember from "ember";
import jsonTools from "infegy-frontend/utils/json-tools";

export default Ember.Object.extend({
    query: null,
    isNew: true,
    isLoading: false,
    isLoaded: false,
    isEmpty: false,
    updated: 0,
    params: null,

    exportFormats: ["json", "xml", "xls", "xlsx"],

    description: {},
    meta: {},

    rawResults: null,

    data: null,

    build: function() {
        var raw_data = this.rawResults,
            data = jsonTools.flattenJSON(raw_data);
        this.set("data", data);
    },

    beginLoading: function() {
        this.set("isNew", false);
        this.set("isLoaded", false);
        this.set("isLoading", true);
    },

    loadResponse: function(responseRow, fullResponse) {
        Ember.beginPropertyChanges();
        this.set("rawResponse", fullResponse);
        this.set("rawResponseRow", responseRow);
        this.set("rawResults", JSON.parse(JSON.stringify(responseRow.output)));
        this.set("meta", jsonTools.flattenJSON(responseRow.query_meta));
        this.set("isNew", false);

        if (this.preload) {
            this.preload();
        }

        if (this.build) {
            this.build();
        }

        if (this.postload) {
            this.postload();
        }

        this.set("isLoading", false);
        this.set("isLoaded", true);
        this.incrementProperty("updated");
        Ember.endPropertyChanges();
    }
});
