
import { helper } from '@ember/component/helper';
import { isArray } from '@ember/array';
import { isNone } from '@ember/utils';

/**
 * @param {array} params 
 *      items, [offset, [maxItems]] 
 * 
 *      Where `items` is the array of items you want to limit,
 *      `offset` is the beginning of the specified portion,
 *      `maxItems` is the count of items to return. 
 */
function limit ([items, offset, maxItems]) {
    if (!isArray(items)){
        console.error("limit-items requires an array as the first parameter");
    }
    if (isNone(maxItems)) {
        maxItems = offset;
        offset = 0;
    }

    return items.slice(offset,maxItems);
}
export default helper(limit);
