import Ember from "ember";

export default Ember.Component.extend({
    classNameBindings: [":sparkline", "interactive:clickable"],
    data: [],

    // Null or 0 uses supplied data length
    numberOfDisplayPoints: 10,

    // Null or 0 first uses supplied numberOfDisplayPoints, then falls back to supplied data length
    numberOfInteractionPoints: null,

    height: 60,

    hoveredIndex: null,
    averagePoints: true,
    showArea: false,
    showLine: true,
    yAtZero: false,

    interactive: true,

    color: null,

    totalInteractionPoints: Ember.computed("interactive", "numberOfDisplayPoints",
            "numberOfInteractionPoints", "data.length", function () {
        return this.numberOfInteractionPoints || this.numberOfDisplayPoints || this.get("data.length");
    }),

    groupedData: Ember.computed("data", "numberOfDisplayPoints", "averagePoints", function () {
        var data = this.data || [0],
            length = data.length,
            groups = this.numberOfDisplayPoints || null;
        if (length === 1) {
            data.push(data[0]);
        }
        if (!groups || length <= groups) {
            return data.map(r => [r]);
        }
        var perGroup = Math.floor(length / groups),
            extras = length % groups,
            offset = Math.floor((groups - extras) / 2),
            remainingInGroup = 0,
            groupNum = -1,
            grouped = data.reduce((memo, row) => {
                if (!remainingInGroup) {
                    remainingInGroup = perGroup;
                    if (extras && groupNum >= offset) {
                        remainingInGroup++;
                        extras--;
                    }
                    groupNum++;
                    memo[groupNum] = [];
                }
                memo[groupNum].push(row);
                remainingInGroup--;
                return memo;
            }, []);
        return grouped;
    }),

    combinedData: Ember.computed("groupedData", function () {
        var grouped = this.groupedData,
            averagePoints = this.averagePoints;
        return grouped.reduce((memo, row, idx) => {
            memo[idx] = row.reduce((sum, r) => {return sum + r;}, 0);
            if (averagePoints) {
                memo[idx] = memo[idx] / row.length;
            }
            return memo;
        }, []);
    }),

    hoveredIndexChanged: () => {},
});
