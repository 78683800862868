import Ember from "ember";
import {computed} from "@ember/object";
import JsonStore from 'infegy-frontend/json-store/model';
import Prop from 'infegy-frontend/json-store/properties/model';
import AtlasColors from "infegy-frontend/utils/atlas-colors";
import QueryColor from "infegy-frontend/models/queries/query_color";
import loader from "infegy-frontend/models/field-definitions/loader";
import classic from "ember-classic-decorator";

@classic
export default class BaseField extends JsonStore {
    _staticProperties = ["fieldClass"];

    // Determines which field class to load
    fieldClass = "base-field";

    // Required - These properties (along with the query) are what makes the field work,
    // and are required.
    @Prop.String() apiName;
    @Prop.String() fieldName;

    // For custom dataset fields
    @Prop.String() customDataField;
    @Prop.String() customDataKeyword;
    @Prop.String() customDataSubField;

    // Following should be css color strings
    @Prop.String() defaultColor;
    @Prop.String() selectedColor;

    // Type of field, this allows for filtering which fields are available.
    @Prop.String() fieldType;

    // For selectors only
    @Prop.String() segment;
    @Prop.String() subsegment;
    // Component for the field selector type
    @Prop.String() fieldComponent;

    // This is unfortunately a selector value rather than the field's scalar Value.
    // Changing would require chaging how the selectors work (like toggle-list)
    @Prop.String() value;
    @Prop.Attr() queryId;

    // Properties for specific field selectors that are not generalizable. An
    // example is "includeNeutral" for some of the sentiment fields
    @Prop.Attr() additionalSelectorProperties;

    // Optional - Can often be computed from above
    @Prop.String() title;
    @Prop.String() chartTitleOverride;
    @Prop.String() units;
    @Prop.String() axisGroup;

    // Optional - specific to some types of fields
    @Prop.Number() fixedMaxY;
    @Prop.Boolean() isRangelessPercentage;

    // Use the following for most field name label output, should be used with
    // the title-case helper, since it may be camel-cased if generated
    @computed("title", "fieldName", "queries")
    get displayName() {
        let displayName = this.title || this.fieldName;
        if (this.queries?.length && this.query.title) {
            return `${this.query.title} ${displayName}`;
        }
        return displayName;
    }

    @computed("chartTitleOverride", "segment", "subsegment")
    get chartTitle() {
        if (this.chartTitleOverride) {
            return this.chartTitleOverride;
        }
        let title = this.segment;
        if (this.subsegment && title !== this.subsegment && !this.subsegment.startsWith(title)) {
            title += " " + this.subsegment;
        } else if (this.subsegment && this.subsegment.startsWith(title)) {
            title = this.subsegment;
        } else if (!this.subsegment && this.selectedFields?.length > 1) {
            title += " " + this.title;
        }
        return title;
    }

    // Query Info is not stored - must be loaded on use
    // Set queries array to have field lookup query by queryId
    queries = null;
    // Set this to override the queryId lookup and manually set a query
    // (without overwriting the internal queryId)
    _query = null;

    @computed("queries", "_query")
    get query() {
        if (this._query) {
            return this._query;
        } else if (!Ember.isEmpty(this.queryId) && !Ember.isEmpty(this.queries)) {
            let query = this.queries.find((query, idx) => {
                return query && ((query.lookupId === this.queryId) || idx === this.queryId);
            });
            return query;
        }
    }
    set query(query) {
        this.set("_query", query);
        this.set("queryId", query && query.lookupId);
        return query;
    }

    @computed.alias("defaultColor.colorString") defaultColorString;

    @computed("selectedColor", "defaultColor", "query.color")
    get color() {
        let colorValue = this.selectedColor || this.defaultColor || (this.query && this.query.color.colorString),
            color = QueryColor.create();
        if (!colorValue) {
            colorValue = AtlasColors.getColorfromClassName("atlas-chart-1");
        }
        color.set("colorString", colorValue);
        return color;
    }
    set color(newColor) {
        let color = QueryColor.create();
        if (typeof(newColor) === "string") {
            this.set("selectedColor", newColor);
        } else if (typeof(newColor) === "object" && newColor.colorString) {
            this.set("selectedColor", newColor.colorString);
        }
    }

    @computed.alias("color.colorString") colorString;

    @computed("query", "apiName", "customDataField", "customDataSubField")
    get queryApi() {
        let query = this.query;
        if (this.apiName === "topKeywords" ) {
            let topKeywordsAPI = query.fetchTopKeywords(this.customDataField, this.customDataSubField);
            return topKeywordsAPI?.apiModel;
        } if (this.apiName === "numericStats") {
            let numericStatsAPI = query.fetchNumericStats(this.customDataField);
            return numericStatsAPI?.apiModel;
        }
        return this.get("query." + this.apiName);
    };

    dataSeriesOverride = null;

    @computed("apiName", "queryApi", "queryApi.dataSeries", "dataSeriesOverride", "fieldClass", "customDataKeyword")
    get dataSeries() {
        if (this.dataSeriesOverride) {
            return this.dataSeriesOverride;
        }
        if (this.apiName === "topKeywords" && this.customDataKeyword && this.queryApi) {
            return this.queryApi.getDataSeriesForKeyword(this.customDataKeyword);
        }
        if (this.fieldClass === "object-field") {
            return this.queryApi;
        }
        return this.get("queryApi.dataSeries");
    }
    set dataSeries(dataSeries) {
        this.set("dataSeriesOverride", dataSeries);
    }

    @computed.alias("query.updated") updated;

    @computed("queryApi", "updated", "dataSeriesOverride")
    get isLoaded() {
        if (this.dataSeriesOverride) {
            return true;
        }
        return this.queryApi && this.queryApi.isLoaded;
    };

    copy(query, queries) {
        let fieldCopy = loader.loadField(this.toJSON(), queries || this.queries);
        if (query) {
            fieldCopy.set("query", query);
        } else {
            fieldCopy.set("_query", this._query);
        }
        return fieldCopy;
    };

    @computed("scalarValue", "portionWhole")
    get portionPercentage() {
        return (this.scalarValue || 0.0) / (this.portionWhole || 1.0);
    };

    @computed("isPercentageType", "statsType")
    get isAveragedType() {
        if (this.scalarField === "percentChange") {
            return false;
        }

        return this.statsType !== "portion" &&
            (this.isPercentageType || this.statsType === "average");
    };

    loadJSONObject(json) {
        return loader.loadField(json);
    }
};
