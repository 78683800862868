import Ember from "ember";
import DataSeriesComputedFieldBase from "infegy-frontend/models/data_series/data_series_computed_fields";
import NumberStats from "infegy-frontend/models/data_series/number_stats";

// Creates a computed numeric quotient field based on two source fields:
//      A "divisor" and a "dividend"
//
// The output is calculated by "divisor" / "dividend"
//
// Output is groupable using weighted averages. (Recomputed from grouped data).
var DataSeriesComputedFieldQuotient = DataSeriesComputedFieldBase.extend({
    destinationField: "",
    divisorField: null,
    dividendField: null,
    type: "average",

    addFieldsTo: function(fields) {
        var destinationField = this.destinationField,
        type = this.type;

        if (destinationField && type) {
            fields[destinationField] = type;
        }
        return fields;
    },
    rowCompute: function(row) {
        var destinationField = this.destinationField,
            dividendField = this.dividendField,
            divisorField = this.divisorField,
            dividend, divisor;

        if (dividendField && row[dividendField] && divisorField && row[divisorField]) {
            dividend = +row[dividendField] || 0.0;
            divisor = +row[divisorField] || 0.0;
        } else {
            row[destinationField] = 0.0;
            return row;
        }

        if (Ember.isEmpty(divisor) || dividend === 0) {
            row[destinationField] = 0.0;
            return row;
        }

        row[destinationField] = divisor / dividend;
        return row;
    },
    statsCompute: function(stats, data) {
        var destinationField = this.destinationField,
            dividendField = this.dividendField,
            divisorField = this.divisorField,
            type = this.type,
            dividendStats, divisorStats,
            destinationStats = NumberStats.create();

        if (dividendField  && stats[dividendField] && divisorField && stats[divisorField]) {
            dividendStats = stats[dividendField];
            divisorStats = stats[divisorField];
        } else {
            return stats;
        }

        stats[destinationField] = destinationStats;

        var dividend = (+dividendStats.sum || 0.0),
            divisor = (+divisorStats.sum || 0.0);

        var maxIndex = 0,
            minIndex = 0,
            max = -Infinity,
            min = Infinity,
            dataIdx = data && data.length;

        while (dataIdx--) {
            var val = (data && data[dataIdx] && data[dataIdx][destinationField]) || 0;

            if (max <= val) {
                max = val;
                maxIndex = dataIdx;
            }
            if (min >= val) {
                min = val;
                minIndex = dataIdx;
            }
        }

        destinationStats.max = max;
        destinationStats.min = min;
        destinationStats.maxIndex = maxIndex;
        destinationStats.minIndex = minIndex;

        var dividendFirstHalfSum = +(dividendStats.firstHalfSum) || 0.0,
            dividendSecondHalfSum = +(dividendStats.secondHalfSum) || 0.0,
            divisorFirstHalfSum = +(divisorStats.firstHalfSum) || 0.0,
            divisorSecondHalfSum = +(divisorStats.secondHalfSum) || 0.0;

        if (dividend === 0) {
            destinationStats.sum = 0.0;
            destinationStats.average = 0.0;
            destinationStats.percentChange = 0;
        } else {
            destinationStats.sum = divisor / dividend;
            destinationStats.average = divisor / dividend;
            destinationStats.firstHalfSum = dividendFirstHalfSum && divisorFirstHalfSum / dividendFirstHalfSum;
            destinationStats.secondHalfSum = dividendSecondHalfSum && divisorSecondHalfSum / dividendSecondHalfSum;
            if (destinationStats.firstHalfSum) {
                destinationStats.percentChange = ((destinationStats.secondHalfSum - destinationStats.firstHalfSum) /
                    destinationStats.firstHalfSum) * 100.0;
            } else {
                destinationStats.percentChange = 0;
            }
        }
        destinationStats.type = type;

        return stats;
    }
});

DataSeriesComputedFieldQuotient.reopenClass({
    fieldSetup: function(destinationField, divisorField, dividendField) {
        return DataSeriesComputedFieldQuotient.create({
            destinationField: destinationField,
            dividendField: dividendField,
            divisorField: divisorField
        });
    }
});

export default DataSeriesComputedFieldQuotient;
