import Ember from "ember";
import { isEmpty } from "@ember/utils";
import JsonStore from "infegy-frontend/json-store/model";
import Prop from "infegy-frontend/json-store/properties/model";
import Sheet from "infegy-frontend/models/custom-datasets/sheet";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";

export default JsonStore.extend({
    headerRowCount: Prop.Int(),
    sheets: Prop.Collection(Sheet),
    cacheKey: Prop.String(),

    file: null,
    isLoading: true,
    errorMessage: "",

    hasError: Ember.computed.notEmpty("errorMessage"),

    get hasSheet() {
        const found = (this.sheets || []).find((sheet) => !sheet.exclude);
        return !isEmpty(found);
    },
    get hasColumn() {
        const found = (this.sheets || []).find((sheet) => !sheet.exclude && sheet.hasColumn);
        return !isEmpty(found);
    },

    loadJSON(json) {
        this._super(json);
        // assign the sheets numbers
        const sheets = this.sheets;
        sheets.forEach((sheet, index) => {
            sheet.value = index;
        });
    },

    toJSON() {
        const sheets = this.sheets.map((sheet) => {
            return sheet.toJSON();
        });
        return sheets;
    },
    
    async load(file, headerRowCount, onprogress) {
        this.setProperties({
            isLoading: true,
            file,
            errorMessage: ""
        });
        const response = await AtlasAuth.Post({
            url: `api/v3/spreadsheet-fields?header_row_count=${headerRowCount}`,
            data: file
        }, onprogress);
        this.loadJSON(response.output);
        this.set("isLoading", false);
    },

    customDatasetSize: Ember.computed("sheets.@each.activeBindingsSize", function() {
        const sheets = this.sheets;
        const totalBytes = sheets.content.reduce((total, item) => item.activeBindingsSize + total, 0);
        return totalBytes;
    }),

    customDatasetPosts: Ember.computed("", function() {
        const sheets = this.sheets;
        const totalDocuments = sheets.content.reduce((total, item) => item.rowCount + total, 0);
        return totalDocuments;
    })
});