import Component from "@ember/component";
import { action, computed } from "@ember/object";
import { isEmpty, isNone } from "@ember/utils";
import { camelize } from "@ember/string";
import classic from "ember-classic-decorator";

import SharedResources from "infegy-frontend/models/users/shared_resources";
import Workspace from "infegy-frontend/models/users/workspace";

@classic
export default class extends Component {
    classNameBindings = ["componentClassName"];

    // Input
    /**
     * @type {"run_workspace"|"edit_or_view"|"view_sharing_details"|"change_folder"|"delete"|"remove_folder"|"select_workspace"|"select_queries"|"toggle_save"}
     */
     actionBarButtons = [];
     /**
      * @type {Query[]}
      */
     sortField = "";
     user = null;
     workspace = Workspace.create();

     /**
      * @event changeFolder Fired when the user clicks the "Change Folder" action button.
      */
     /**
      * @event deleteWorkspace Fired when the user clicks the "Delete" action button.
      */
     /**
      * @event editWorkspace Fired when the user clicks the "Edit/View" action button.
      * @param {Workspace}
      */
     /**
      * @event loadWorkspace Fired when the user clicks the "Load Workspace" action button.
      * @param {Workspace}
      */
     /**
      * @event selectQueries Fired when the user clicks the "Select Queries" action button.
      */
     /**
      * @event removeFolder Fired when the user clicks the "Remove From Folder" action button.
      */
     /**
      * @event runWorkspace Fired when the user clicks the "Run Workspace" action button.
      * @param {Workspace}
      */

    // Internal Parameters
    isShowingFullDetails = false;
    isShowingSharingDetails = false;
    sharedResources = SharedResources.create();
    viewState = "";

    @computed.lte("workspace.queries.length", 6) canLoadWorkspace;
    @computed.readOnly("workspace.isShared") isShared;
    @computed.gt("remainingQueryLimit", 0) canAddQueries;

    @computed("workspace", "sortField")
    get statusValue() {
        return this.workspace[camelize(this.sortField)];
    }
    
    @computed("user.workspaceFolders.length", "workspace.workspaceFolderId")
    get workspaceFolder() {
        return this.user?.workspaceFolders.find((folder) => folder.id === this.workspace.workspaceFolderId);
    }

    @computed("workspaceFolder", "workspace.workspaceFolderId")
    get hasWorkspaceFolder() { 
        return !isNone(this.workspaceFolder);
    }

    @computed("actionBarButtons", "actionBarButtons.length")
    get showRunWorkspace() {
        return this.actionBarButtons.includes("run_workspace");
    }
    @computed("actionBarButtons", "actionBarButtons.length")
    get showEditOrView() {
        return this.actionBarButtons.includes("edit_or_view");
    }
    @computed("actionBarButtons", "actionBarButtons.length")
    get showViewSharingDetails() {
        return this.workspace.isShared && this.actionBarButtons.includes("view_sharing_details");
    }
    @computed("actionBarButtons", "actionBarButtons.length")
    get showChangeFolder() {
        return !this.workspace.isShared && this.actionBarButtons.includes("change_folder");
    }
    @computed("actionBarButtons", "actionBarButtons.length")
    get showRemoveFolder() {
        return this.actionBarButtons.includes("remove_folder");
    }
    @computed("actionBarButtons", "actionBarButtons.length")
    get showDelete() {
        return !this.workspace.isShared && this.actionBarButtons.includes("delete");
    }
    @computed("actionBarButtons", "actionBarButtons.length")
    get showSelectWorkspace() {
        return this.actionBarButtons.includes("select_workspace");
    }
    @computed("showSelectWorkspace")
    get componentClassName() {
        return "workspaces-list-workspace-hoverable";
    }
    @computed("actionBarButtons", "actionBarButtons.length")
    get showSelectQueries() {
        return this.actionBarButtons.includes("select_queries");
    }
    @computed("actionBarButtons", "actionBarButtons.length")
    get showToggleSave() {
        return !this.workspace.isShared && this.actionBarButtons.includes("toggle_save");
    }
    @computed("workspace", "workspace.createdOn")
    get createdOnDateTimeFormat() {
        let date = new Date(this.workspace.createdOn).toISOString().substring(0, 10);
        let fullTime = new Date(this.workspace.createdOn).toLocaleTimeString();
        let time = fullTime.substring(0,5);

        if (time.charAt(time.length - 1) === ':') {
            time = time.slice(0,-1);
        }
        
        time += fullTime.slice(-3);
        date = date.replaceAll('-', '/');

        return `${date} ${time}`
    }

    sharingDeclined(workspace) {}

    @action onRunWorkspace() {
        return this.workspace.queries.length > 6 ? this.selectQueries() : this.runWorkspace(this.workspace);
    }

    @action onToggleShowDetails() {
        this.toggleProperty("isShowingFullDetails");
    }

    @action onChangeFolder() {
        this.changeFolder();
    }

    @action onLoadWorkspace() {
        if (!this.canLoadWorkspace)
            return;
        this.loadWorkspace(this.workspace);
    }

    @action onSelectQueries() {
        if(this.remainingQueryLimit > 0)
            this.selectQueries();
    }

    @action async onToggleSaveWorkspace() {
        this.toggleSaveWorkspace(this.workspace);
    }

    @action onEditWorkspace() {
        this.editWorkspace(this.workspace);
    }

    @action async onRemoveFromFolder() {
        this.workspace.workspaceFolderId = 0;
        await this.workspace.save();
        this.removeFolder();
    }

    @action onDeleteWorkspace() {
        this.deleteWorkspace();
    }

    @action onToggleShowSharingDetails() {
        if (isEmpty(this.sharedResources)) {
            this.sharedResources.getForResource("workspace", this.workspace.id);
        }
        this.toggleProperty("isShowingSharingDetails");
    }

    click() {
        if (this.clickToRun) {
            this.onRunWorkspace();
            return;
        }
        
        if (this.canLoadWorkspace) {
            this.loadWorkspace(this.workspace);
        } else {
            if(this.remainingQueryLimit > 0) {
                this.selectQueries();
            }
        }
    }

    @action disableClickThrough(event) {}

    @action onSharingDeclined() {
        this.sharingDeclined(this.workspace);
        this.set("isShowingSharingDetails", false);
    }
}
