export default [{
    label: "Entities",
    value: "sub_entity_id",
    rawValue:true
}, {
    label: "Interests",
    value: "sub_post_interests",
    rawValue:true
}, {
    label: "Emotions",
    value: "sub_emotions"
}, {
    label: "Themes",
    value: "sub_themes"
}, {
    label: "Sentiment",
    value: "sub_sentiment"
}, {
    label: "Channels",
    value: "sub_channels"
}];