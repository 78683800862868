import Ember from "ember";
export default Ember.Controller.extend({
    searchString: null,

    sourceFields: null,
    sources: [],

    maxNgramLength: 2,

    breakChars: [
        "\\s",
        "\\(",
        "\\)",
        "\\|",
        "\\\"",
        "\\@",
        "\\#",
        "\\-"
    ],

    breakRegEx: Ember.computed("breakChars", {
        get: function(key) {
            var breakChars = this.breakChars || ["\\s"],
                breakRegEx = new RegExp(["[", breakChars.join(""), "]+"].join(""), "gi");
            return breakRegEx;
        },
    }),

    tokenRegEx: Ember.computed("breakChars", {
        get: function(key) {
            var breakChars = this.breakChars || ["\\s"],
                breakRegEx = new RegExp(["[^", breakChars.join(""), "]+"].join(""), "gi");
            return breakRegEx;
        },
    }),

    tokenizeString: function(searchString) {
        var breakRegEx = this.breakRegEx || /[\s]+/;

        searchString = searchString || "";

        var rawSearchTokens = searchString.split(breakRegEx),
            searchTokens = rawSearchTokens.reduce(function(memo, token) {
                token = token && token.trim().toLowerCase();
                if (token && token.length && !memo.includes(token)) {
                    memo.push(token);
                }
                return memo;
            }, []);

        return searchTokens || [];
    },

    searchTokens: Ember.computed("searchString", "breakRegEx", {
        get: function(key) {
            return this.tokenizeString(this.searchString) || [];
        },
    }),

    indexStr: function(input, sourceIdx, tokenRegEx, maxNgramLength) {
        tokenRegEx = tokenRegEx || this.tokenRegEx || /[\S]+/;
        maxNgramLength = maxNgramLength || this.maxNgramLength || 2;
        var index = {},
            match, token, start, indexKey;

        while ((match = tokenRegEx.exec(input)) !== null) {
            token = match[0].toLowerCase();
            start = match.index;
            indexKey = token.substr(0, maxNgramLength);
            while (indexKey.length > 0) {
                if (!index[indexKey]) {
                    index[indexKey] = [sourceIdx];
                } else {
                    index[indexKey].push(sourceIdx);
                }
                indexKey = indexKey.substr(0, indexKey.length - 1);
            }
        }
        return index;
    },

    buildFieldIndex: function(sources, field, maxNgramLength) {
        var fieldIndex = {},
            tokens, indexKey;
        sources.forEach(function(source, sourceIdx) {
            tokens = this.tokenizeString(source.get(field) || "");
            tokens.forEach(function(token) {
                indexKey = token.substr(0, maxNgramLength);
                while (indexKey.length > 0) {
                    if (!fieldIndex[indexKey]) {
                        fieldIndex[indexKey] = [sourceIdx];
                    } else if (!fieldIndex[indexKey].includes(sourceIdx)) {
                        fieldIndex[indexKey].push(sourceIdx);
                    }
                    indexKey = indexKey.substr(0, indexKey.length - 1);
                }
            }, this);
        }, this);
        return fieldIndex;
    },

    buildIndex: function(sources, sourceFields, maxNgramLength) {
        sourceFields = sourceFields || this.sourceFields || [];
        sources = sources || this.sources || [];
        maxNgramLength = maxNgramLength || this.maxNgramLength || 2;
        if (typeof(sourceFields) === "string") {
            sourceFields = [sourceFields];
        }

        var fieldIndexes = {};
        sourceFields.forEach(function(sourceField) {
            fieldIndexes[sourceField] = this.buildFieldIndex(sources, sourceField, maxNgramLength);
        }, this);
        return fieldIndexes;
    },

    index: Ember.computed("sources", "sources.[]", "sourceFields",
        "breakChars", {
            get: function(key) {
                return this.buildIndex();
            },
        }),

    searchFieldIndexFor: function(searchTokens, options) {
        options = options || {};
        var sources = options.sources || this.sources || [],
            field = options.field || 0,
            fieldIndex = options.fieldIndex || (this.index && this.index[field]),
            maxNgramLength = maxNgramLength || this.maxNgramLength || 1,
            fieldResults = options.results || {};

        if (!fieldIndex) {
            return {};
        }

        searchTokens.forEach(function(searchToken) {
            var searchTokenLen = searchToken.length;
            var indexKey = searchToken.slice(0, maxNgramLength);
            var matches = fieldIndex[indexKey] || [];
            if (searchToken.length > maxNgramLength) {
                matches = matches.filter(function(sourceKey) {
                    var source = sources[sourceKey],
                        sourceStr = source && source.get(field);
                    if (!sourceStr || searchTokenLen > sourceStr.length) {
                        return false;
                    }
                    return sourceStr.toLowerCase().indexOf(searchToken) > -1;
                }, this);
            }
            matches.forEach(function(sourceKey) {
                var source = sources[sourceKey];
                if (!fieldResults[sourceKey]) {
                    fieldResults[sourceKey] = {
                        source: source,
                        index: sourceKey,
                        fields: {},
                        tokens: {},
                        matches: 1,
                        uniqueMatches: 1
                    };
                    fieldResults[sourceKey].fields[field] = 1;
                    fieldResults[sourceKey].tokens[searchToken] = 1;
                } else {
                    var result = fieldResults[sourceKey];
                    result.matches++;
                    result.fields[field] = (result.fields[field] || 0) + 1;
                    if (!result.tokens[searchToken]) {
                        result.tokens[searchToken] = 1;
                        result.uniqueMatches++;
                    } else {
                        result.tokens[searchToken]++;
                    }
                }
            }, this);
        }, this);
    },

    searchBy: function(searchString, sourceFields) {
        var searchTokens = this.tokenizeString(searchString);
        sourceFields = sourceFields || this.sourceFields || [0];
        if (typeof(sourceFields) === "string") {
            sourceFields = [sourceFields];
        }

        var maxNgramLength = this.maxNgramLength,
            numSearchTokens = searchTokens.length,
            fieldIndexes = this.index || {},
            sources = this.sources;

        if (!numSearchTokens) {
            return [];
        }

        var allResults = {};
        sourceFields.forEach(function(sourceField) {
            this.searchFieldIndexFor(searchTokens, {
                sources: sources,
                field: sourceField,
                fieldIndex: fieldIndexes[sourceField],
                maxNgramLength: maxNgramLength,
                results: allResults
            });
        }, this);
        var finalResults = [];
        for (var sourceKey in allResults) {
            var sourceResults = allResults[sourceKey];
            sourceResults.pctMatched = sourceResults.uniqueMatches / numSearchTokens;
            finalResults.push(sourceResults);
        }

        return finalResults;
    },
    searchResults: Ember.computed("searchString", "index", "sourceFields", "breakRegEx", {
        get: function(key) {
            return this.searchBy(this.searchString);
        },
    }),
});