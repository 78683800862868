import AtlasMath from "infegy-frontend/utils/atlas-math";
import DateProcessing from "infegy-frontend/utils/date-processing";

var QueryDataGrouping = {
    minDaysForWeekly: 15 * 7, // 15 weeks
    minDaysForMonthly: 20 * 30, // ~ 20 months
    minDaysForYearly: 7 * 365, // 7 years
    minHoursForDay: 4 * 24 + 1,
    minHoursForHour: 3,

    maxDaysForDaily: 15 * 7, // 15 weeks
    maxDaysForWeekly: 15 * 7, // 15 weeks
    maxDaysForMonthly: 20 * 30, // ~ 20 months

    maxDataPoints: 370,
    minDataPoints: 3,

    timeSeriesKeys: [
            "timestamp",
            "_daysSinceEpoch", "_weeksSinceEpoch",
            "_monthsSinceZero", "_year"
        ],
    allDateKeys: [
            "timestamp",
            "_daysSinceEpoch", "_weeksSinceEpoch", "_monthsSinceZero",
            "_year", "_month",
            "_dayOfWeek"
        ],
    timestampFields: [
        "_daysSinceEpoch",
        "_weeksSinceEpoch",
        "_monthsSinceZero",
        "_year"
    ],
    groupingKeys: {
        "minute": "_minutesSinceEpoch",
        "hour": "_hoursSinceEpoch",
        "all": "_daysSinceEpoch",
        "day": "_daysSinceEpoch",
        "daily": "_daysSinceEpoch",
        "weekly": "_weeksSinceEpoch",
        "week": "_weeksSinceEpoch",
        "monthly": "_monthsSinceZero",
        "month": "_monthsSinceZero",
        "yearly": "_year",
        "year": "_year",

        "dayOfWeek": "_dayOfWeek"
    },
    groupingUnits: {
        "all": "day",
        "daily": "day",
        "weekly": "week",
        "monthly": "month",
        "yearly": "year",

        "dayOfWeek": "weekday"
    },
    apiGroupings: {
        "day": "daily",
        "week": "weekly",
        "month": "monthly",
        "quarter": "monthly", // Trends does not support quarterly grouping
        "year": "yearly"
    },
    keyGroupings: {
        "timestamp": "all",
        "_daysSinceEpoch": "daily",
        "_weeksSinceEpoch": "weekly",
        "_monthsSinceZero": "monthly",
        "_year": "yearly",

        "_dayOfWeek": "dayOfWeek"
    },
    groupingValues: {
        "_dayOfWeek": [0, 1, 2, 3, 4, 5, 6]
    },
    findGroupingFromQueryTimes: function(queryStartTime, queryEndTime) {

    },
    findGrouping: function(numDays) {
        numDays = AtlasMath.toInt(numDays) || 0;
        if (numDays >= QueryDataGrouping.minDaysForMonthly) {
            return "monthly";
        } else if (numDays >= QueryDataGrouping.minDaysForWeekly) {
            return "weekly";
        }
        return "daily";
    },
    findAPIGrouping: function(timeSpanMillis) {
        let numMinutes = AtlasMath.toInt((timeSpanMillis || 0) / (1000 * 60)),
            numHours = AtlasMath.toInt(numMinutes / 60),
            numDays = AtlasMath.toInt(numHours / 24);

        if (numDays >= QueryDataGrouping.minDaysForYearly) {
            return "year";
        } else if (numDays >= QueryDataGrouping.minDaysForMonthly) {
            return "month";
        } else if (numDays >= QueryDataGrouping.minDaysForWeekly) {
            return "week";
        } else if (!timeSpanMillis || numHours >= QueryDataGrouping.minHoursForDay) {
            return "day";
        } else if (numHours >= QueryDataGrouping.minHoursForHour) {
            return "hour";
        } else {
            return "minute";
        }
    },
    findGroupKeyByDataLength: function(numDays) {
        var grouping = QueryDataGrouping.findGrouping(numDays);
        return QueryDataGrouping.groupingKeys[grouping] || "_daysSinceEpoch";
    },
    availableGroupingOptions: function(timeSpanMillis) {
        let numMinutes = AtlasMath.toInt((timeSpanMillis || 0) / (1000 * 60)),
            numHours = AtlasMath.toInt(numMinutes / 60),
            numDays = AtlasMath.toInt(numHours / 24),
            numWeeks = AtlasMath.toInt(numDays / 7),
            numMonths = AtlasMath.toInt(numDays / 30),
            numYears = AtlasMath.toInt(numDays / 365);

        var maxDataPoints = QueryDataGrouping.maxDataPoints,
            minDataPoints = QueryDataGrouping.minDataPoints,
            groupingOptions = [];

        if (numDays <= maxDataPoints) {
            groupingOptions.push({ title: "Daily", value: "_daysSinceEpoch" });
        }
        if (numWeeks <= maxDataPoints && numWeeks >= minDataPoints) {
            groupingOptions.push({ title: "Weekly", value: "_weeksSinceEpoch" });
        }
        if (numMonths <= maxDataPoints && numMonths >= minDataPoints) {
            groupingOptions.push({ title: "Monthly", value: "_monthsSinceZero" });
        }
        if (numYears <= maxDataPoints && numYears >= minDataPoints) {
            groupingOptions.push({ title: "Yearly", value: "_year" });
        }

        return groupingOptions;
    },
    availableAPIGroupingOptions: function(timeSpanMillis) {
        let numMinutes = AtlasMath.toInt((timeSpanMillis || 0) / (1000 * 60)),
            numHours = AtlasMath.toInt(numMinutes / 60),
            numDays = AtlasMath.toInt(numHours / 24),
            numWeeks = AtlasMath.toInt(numDays / 7),
            numMonths = AtlasMath.toInt(numDays / 30),
            numYears = AtlasMath.toInt(numDays / 365);

        var maxDataPoints = QueryDataGrouping.maxDataPoints,
            minDataPoints = QueryDataGrouping.minDataPoints,
            groupingOptions = [];

        if (numMinutes <= maxDataPoints) {
            groupingOptions.push({ title: "Minute", value: "minute" });
        }
        if (numHours <= maxDataPoints && numHours >= minDataPoints) {
            groupingOptions.push({ title: "Hourly", value: "hour" });
        }
        if (numDays <= maxDataPoints && numDays >= minDataPoints) {
            groupingOptions.push({ title: "Daily", value: "day" });
        }
        if (numWeeks <= maxDataPoints && numWeeks >= minDataPoints) {
            groupingOptions.push({ title: "Weekly", value: "week" });
        }
        if (numMonths <= maxDataPoints && numMonths >= minDataPoints) {
            groupingOptions.push({ title: "Monthly", value: "month" });
        }
        if (numYears <= maxDataPoints && numYears >= minDataPoints) {
            groupingOptions.push({ title: "Yearly", value: "year" });
        }

        return groupingOptions;
    },
    findGroupingKey: function(grouping) {
        return QueryDataGrouping.groupingKeys[grouping] || "_daysSinceEpoch";
    },
    timeSeriesFieldTypes: {
        "timestamp": "timestamp",
        "_daysSinceEpoch": "index",
        "_weeksSinceEpoch": "index",
        "_monthsSinceZero": "index",
        "_year": "index",
        "_month": "index",
        "_dayOfWeek": "index"
    },
    dateGroupingKeyValueToTimestamps: function(groupingKey, value) {
        var startTimestamp, endTimestamp;
        switch (groupingKey) {
            case "_daysSinceEpoch":
                startTimestamp = DateProcessing.daysSinceEpochToTimestamp(value);
                return {start: startTimestamp, end: startTimestamp};
            case "_weeksSinceEpoch":
                startTimestamp = DateProcessing.weeksSinceEpochToTimestamp(value);
                endTimestamp = moment.utc(startTimestamp).add(6, "days").valueOf();
                return {start: startTimestamp, end: endTimestamp};
            case "_monthsSinceZero":
                startTimestamp = DateProcessing.monthsSinceZeroToTimestamp(value);
                endTimestamp = moment.utc(startTimestamp).add(1, "months").subtract(1, "days").valueOf();
                return {start: startTimestamp, end: endTimestamp};
            case "_year":
                startTimestamp = DateProcessing.yearToTimestamp(value);
                endTimestamp = moment.utc(startTimestamp).add(1, "years").subtract(1, "days").valueOf();
                return {start: startTimestamp, end: endTimestamp};
        }
    }

};

export default QueryDataGrouping;
