import Ember from "ember";
import Controller, { inject as controller } from "@ember/controller";
import { action, computed } from "@ember/object";

export default class QuerySetsEditor extends Controller {
    @controller application;
    @computed.alias("application.user") user;
    @computed.alias("user.subquerySets") querySets;
    
    querySet = null;
    sharedResources = null;

    // searching/sorting
    searchText = "";
    searchBy = "tag";
    sortAscending = true;

    // updating the query_set
    isSavingQuerySetName = false;
    querySetName = "";
    saveQuerySetNameErrorMessage = "";
    showNameSaveSuccess = false;

    // adding a query_set_entry
    isAddingNewEntry = false;

    showSharingOptions = true;
    
    @computed.alias("querySet.isShared") isShared;

    @computed("querySet.entries", "querySet.entries.length", "searchText", "searchBy", "sortAscending")
    get sortedEntries() {
        const entries = this.querySet.entries;

        let filteredEntries = entries.filter((entry) => {
            let value = entry[this.searchBy].toLowerCase();
            return value.includes(this.searchText.toLowerCase());
        });

        filteredEntries = filteredEntries.sort((a, b) => {
            if (a[this.searchBy] > b[this.searchBy]) return 1
            else if (a[this.searchBy] < b[this.searchBy]) return -1
            else return 0;
        });

        if (this.sortAscending == false) {
            filteredEntries = filteredEntries.reverse();
        }

        return filteredEntries;
    }

    reset() {
        this.setProperties({
            isSavingQuerySetName: false,
            querySetName: "",
            saveQuerySetNameErrorMessage: "",
            showNameSaveSuccess: false,
            isAddingNewEntry: false
        });
    }

    @action
    querySetTitleFieldChange() {
        this.set("saveQuerySetNameErrorMessage", "");
    }

    @action
    async copyQuerySet(querySet) {
        let newSubquerySet = null;
        try {
            newSubquerySet = await querySet.saveCopy();
        }
        catch(error) {
            this.set("showCopyError", true);
            Ember.run.later(()=>{
                this.set("showCopyError", false);
            }, 5000);
            return;
        }

        this.querySets.pushObject(newSubquerySet);
        this.transitionToRoute("atlas.tools.query-sets.edit", newSubquerySet.id);
    }

    // query_set handlers

    @action 
    cancelSaveQuerySetName() {
        this.setProperties({
            querySetName: this.querySet.name,
            saveQuerySetNameErrorMessage: ""
        });
    }

    @action
    async updateQuerySetName(querySetName) {
        this.set("isSavingQuerySetName", true);

        try {
            await this.querySet.update(querySetName);
        }
        catch(error) {
            this.setProperties({
                isSavingQuerySetName: false,
                saveQuerySetNameErrorMessage: response.atlasErrorText
            });
            return;
        }

        this.set("isSavingQuerySetName", false);
        this.user.loadQuerySets();
        this.set("showNameSaveSuccess", true);
        Ember.run.later(()=>{
            this.set("showNameSaveSuccess", false);
        }, 5000);
    }
    
    // query_set_entry handlers

    @action
    deleteSubqueries(ids) {
        return this.querySet.deleteSubqueriesById(ids);
    }

    @action
    deleteQuerySetEntry(entryId) {
        return this.querySet.deleteSubqueriesById([entryId]);
    }

    @action
    saveQuerySetEntry(tag, query) {
        return this.querySet.createEntry(tag, query).then((response) => {
            this.set("isAddingNewEntry", false);
        });
    }

    @action
    updateQuerySetEntry(entryId, newTag, newQuery) {
        return this.querySet.updateEntryById(entryId, newTag, newQuery);
    }
};