import Ember from "ember";
import Actions from "infegy-frontend/utils/common-actions";

export default Ember.Component.extend({
    queries: null,
    themeData: null,
    availableThemes: [],

    selectedTheme: null,
    selectedSubTheme: null,
    showCustomThemes: false,

    viewField: "appearances",

    displayedThemes: Ember.computed("themeData", "showCustomThemes", function() {
        if (this.showCustomThemes) { // ONLY show custom themes
            return this.themeData.filter((theme) => theme.customTheme === true);
        }
        return this.themeData;
    }),

    themeSelected: () => { /* action */ },
    actions: {
        themeSelected: Actions.passUp("themeSelected"),
    }
});
