import Ember from "ember";
import {computed} from "@ember/object";

export default class TopLanguagesItem extends Ember.Component {
    classNameBindings = ["isActive:active"]

    querySelected = false;
    selectedLanguage = null;

    language = null;
    query = null;

    selectLanguage = () => { /* action */ }

    @computed("querySelected","selectedLanguage")
    get isActive() {
        return this.querySelected && this.selectedLanguage === this.language.code;
    }

    click() {
        this.selectLanguage(this.query, this.language.code);
    }
}
