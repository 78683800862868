import Component from "@ember/component";
import { action, computed } from "@ember/object";

export default class InfluencersList extends Component {
    classNames = ["table-overflow-scroll"]
    influencers = null;

    isMultiQuery = false;

    sortField = 'rank';
    sortAscending = true;

    influencersPerPage = 20;
    currentPage = 0;

    selectedInfluencer = null;

    @computed("influencers","influencersPerPage","currentPage")
    get displayInfluencers() {
        let data = this.influencers.data.sortBy(this.sortField).reverse(),
            influencersPerPage = this.influencersPerPage,
            currentPage = this.currentPage,
            currentIndex = currentPage * influencersPerPage;
        return (data || []).slice(currentIndex,currentIndex + influencersPerPage);
    }

    @action
    influencerSelected(influencer) {
        if (this.selectedInfluencer !== influencer) {
            this.set("selectedInfluencer", influencer);
        } else {
            this.set("selectedInfluencer", null);
        }
    }
}