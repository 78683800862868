import Ember from "ember";

export default Ember.Component.extend({
    themeData: [],

    availableThemes: [],

    viewField: "Documents",

    sortAscending: true,
    sortField: "queryTitle",

    fieldSuffixes: [{
        value: "Documents",
        label: "Posts"
    }, {
        value: "DocumentsDistribution",
        label: "Posts Distribution"
    }, {
        value: "Appearances",
        label: "Appearances"
    }],

    sortClassSuffix: Ember.computed("sortAscending", function () {
        return this.sortAscending ? "sorted-asc" : "sorted-desc";
    }),

    valueThemeData: Ember.computed("themeData", "viewField", function () {
        var data = this.themeData || [],
            viewField = this.viewField;
        return data.map(row => {
            var newQueriesData = row.queriesData.map(qd => {
                var pct = ((qd.DocumentsDistribution || 0) * 100.0).toFixed(0) || 50;
                return Object.assign({}, qd, {
                    value: qd[viewField],
                    queryTitle: Ember.get(qd, "query.title"),
                    distributionPercentage: pct,
                    distributionWidthStyleSafe: new Ember.String.htmlSafe(["width:", pct, "%;"].join(""))
                });
            });
            return Object.assign({}, row, {
                queriesData: newQueriesData
            });
        });
    }),


    sortedQueries: Ember.computed("queries", "queries.[]", "queries.updated",
        "sortAscending", "sortField", "viewField", "fieldSuffixes", function () {
        var queries = this.queries || [],
            asc = this.sortAscending,
            sortField = this.sortField,
            viewField = this.viewField;
        return queries.sort((queryA, queryB) => {
            var valA, valB;
            if (sortField === "queryTitle") {
                valA = Ember.get(queryA, "title");
                valB = Ember.get(queryB, "title");
            } else {
                valA = Ember.get(queryA, "themes.dataSeries.stats." + sortField + viewField + ".sum") || 0;
                valB = Ember.get(queryB, "themes.dataSeries.stats." + sortField + viewField + ".sum") || 0;
            }
            if (asc) {
                let temp = valB;
                valB = valA;
                valA = temp;
            }
            if (typeof(valA) === "string") {
                return valA.localeCompare(valB);
            }
            return valA - valB;
        });
    }),

    actions: {
        sortBy: function(newSortField) {
            var sortField = this.sortField;
            if (newSortField === sortField) {
                this.toggleProperty("sortAscending");
            } else {
                this.setProperties({
                    sortField: newSortField,
                    sortAscending: newSortField === "theme"
                });
            }
        },
        viewFieldChanged: function(viewField) {
            this.set("viewField", viewField);
        }
    }
});
