import Ember from "ember";
import QueryAPIMixin from "infegy-frontend/components/query-api/base/query-api-mixin/component";

export default Ember.Component.extend(QueryAPIMixin, {
    apis: ["gender", "volume"],

    query: null,
    options: null,
    selected: null,

    height: 200,

    updateGenderTrendTooltip: () => {},
    actions: {
        updateGenderTrendTooltip: function(hoveredValue) {
            this.updateGenderTrendTooltip(hoveredValue);
        }
    }
});
