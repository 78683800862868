import Controller, { inject as controller } from '@ember/controller';
import { action, computed } from "@ember/object";
import { oneWay, readOnly } from "@ember/object/computed";
import { isEmpty } from "@ember/utils";
import classic from "ember-classic-decorator";

import CustomDataset from "infegy-frontend/models/users/custom-dataset";

@classic
export default class extends Controller {
    @controller application;
    @readOnly("application.user") user;
    @readOnly("user.customDatasets") customDatasets;

    @controller("atlas.tools.custom-datasets.create") createController;
    @readOnly("createController.document") document;
    @readOnly("document.sheets") sheets;
    @oneWay("document.headerRowCount") headerRowCount;

    newDatasetTitle = "";
    newDatasetDescription = "";
    ignoreErrors = false;
    isFormNameInvalid = true;
    
    isCanceling = false;
    showIgnoreErrorHelp = false;
    submitError = "";
    isSubmitting = false;
    loaded = 0;
    total = 0;

    // page selection
    sheetIndex = 0;

    @computed.notEmpty("submitError") hasSubmitError;

    @computed.equal("sheetIndex", 0) isFirstSheet;

    @computed("sheetIndex", "sheets", "sheets.length")
    get hasNextSheet() {
        return this.sheetIndex < this.get("sheets.length") - 1;
    }

    @computed("sheetIndex", "document.sheets.length")
    get isLastSheet() {
        return this.sheetIndex === this.get("sheets.length") - 1;
    }

    @computed("sheets", "sheets.length", "sheetIndex")
    get selectedSheet() {
        if (isEmpty(this.sheets)) {
            return null;
        }
        return this.sheets.objectAt(this.sheetIndex);
    }

    init() {
        super.init(...arguments);
        if (isEmpty(this.get("document.file"))) {
            this.transitionToRoute("atlas.tools.custom-datasets.create");
        }
    }

    reset() {
        this.setProperties({
            isCanceling: false,
            isFormNameInvalid: false,
            newDatasetTitle: "",
            newDatasetDescription: "",
            isSubmitting: false,
            submitError: "",
            loaded: 0,
            total: 0
        });
    }

    validateName() {
        const title = this.newDatasetTitle.trim();
        if (title.length === 0) {
            this.set("isFormNameInvalid", true);
            let errorMessage = "The new custom dataset requires a name.";
            return [errorMessage];
        }
        else {
            this.set("isFormNameInvalid", false);
            return [];
        }
    }

    @action cancelCreateDataset() {
        this.transitionToRoute("atlas.tools.custom-datasets");
    }

    @action onCancel() {
        this.set("isCanceling", true);
    }

    @action onContinue() {
        this.set("isCanceling", false);
    }

    @action onConfirmCancel() {
        this.transitionToRoute("atlas.tools.custom-datasets");
    }
    
    @action async onSubmit() {
        const title = this.newDatasetTitle.trim();
        if (title.length === 0) {
            this.set("isFormNameInvalid", true);
            return;
        }
        this.set("isFormNameInvalid", false);

        if (!this.document.hasSheet) {
            this.set("submitError", "At least one sheet must be included in the custom dataset.");
            return;
        }
        if (!this.document.hasColumn) {
            this.set("submitError", "At least one column must be included in the custom dataset.");
            return;
        }
        if (!this.user.hasDataStorageForDocument(this.document)) {
            this.set("submitError", `${this.document.name} exceeds the remaining available data amount.`);
            return;
        }
        if (!this.user.hasPostStorageForDocument(this.document)) {
            this.set("submitError", `${this.document.name} exceeds the remaining available post amount.`);
            return;
        }
        this.set("submitError", "");

        let customDataset = CustomDataset.create();
        try {
            this.set("isSubmitting", true);
            const onprogress = (event) => {
                this.setProperties({
                    loaded: event.loaded,
                    total: event.total
                });
            }
            await customDataset.saveAsFileUpload(this.document, this.newDatasetTitle, this.newDatasetDescription, this.ignoreErrors, false, onprogress);
            this.set("isSubmitting", false);
        }
        catch (error) {
            this.setProperties({
                isSubmitting: false,
                submitError: error.atlasErrorText
            });
            return;
        }

        this.set("sheetIndex", 0);
        this.user.loadCustomDatasets();
        this.transitionToRoute("atlas.tools.custom-datasets");
    }

    @action onIgnoreErrorsChanged(isChecked) {
        this.set("ignoreErrors", isChecked);
    }

    @action onPreviousSheet() {
        this.decrementProperty("sheetIndex");
    }

    @action onNextSheet() {
        this.incrementProperty("sheetIndex");
    }

    @action onToggleIgnoreErrorHelp() {
        this.toggleProperty("showIgnoreErrorHelp");
    }
}
