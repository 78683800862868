import Ember from "ember";

export default Ember.Component.extend({
    searchString: "",

    sortingType: "auto",
    filterType: "all",

    sortingTypeOptions: [{
        label: "Auto",
        value: "auto"
    }, {
        label: "Recently Used",
        value: "last_date"
    }, {
        label: "Recently Created",
        value: "created_date"
    }, {
        label: "Title",
        value: "title"
    }, {
        label: "Query String",
        value: "query"
    }],

    filterTypeOptions: [{
        label: "All",
        value: "all"
    }, {
        label: "Title",
        value: "title"
    }, {
        label: "Query String",
        value: "query"
    }],

    filterTypeChanged: () => {},
    sortingTypeChanged: () => {},
    actions: {
        filterTypeChanged: function(filterType) {
            this.filterTypeChanged(filterType);
        },
        sortingTypeChanged: function(sortingType) {
            this.sortingTypeChanged(sortingType);
        },
    }
});
