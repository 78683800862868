import BaseField from "infegy-frontend/models/field-definitions/base-field";
import RowValueScalarField from "infegy-frontend/models/field-definitions/row-value-scalar-field";
import TrendField from "infegy-frontend/models/field-definitions/trend-field";
import ObjectField from "infegy-frontend/models/field-definitions/object-field";
import DataSeries from "infegy-frontend/models/data_series/data_series";

export default {
    loadField(fieldJSON, queries) {
        let field,
            fieldClass = fieldJSON.fieldClass || fieldJSON.field_class;
        if (fieldClass === "row-value-scalar-field") {
            field = RowValueScalarField.create();
        } else if (fieldClass === "object-field") {
            field = ObjectField.create();
        } else if (fieldClass === "trend-field") {
            field = TrendField.create();
        } else {
            field = BaseField.create();
        }
        field.loadJSON(fieldJSON);

        if (Ember.isArray(queries) && !Ember.isEmpty(queries)) {
            if (queries.length === 1) {
                field.set("_query", queries[0]);
            } else {
                field.set("queries", queries);
            }
        } else if (!Ember.isArray(queries) && !Ember.isEmpty(queries)) {
            field.set("_query", queries);
        }

        return field;
    },

    combineMultiQueryFields(fields) {
        if (!fields || !fields.length || fields.length === 1 || !fields[0]) {
            return fields;
        }
        let firstField = fields[0],
            firstFieldClass = firstField.fieldClass || firstField_fieldClass,
            combinedField = firstField.copy(),
            dataSeriesList = fields.mapBy("dataSeries").filter(dataSeries => !!dataSeries);

        combinedField.setProperties({ query: null, queries: null, _query: null, dataSeriesOverride: null });

        if (firstFieldClass === "trend-field") {
            let combinedDataSeries = DataSeries.stackBy(dataSeriesList, "timestamp");
            combinedField.set("dataSeriesOverride", combinedDataSeries);
        } else if (firstFieldClass === "row-value-scalar-field") {
            let combinedDataSeries = DataSeries.stackBy(dataSeriesList, firstField.rowLookupProperty || firstField.row_lookup_property);
            combinedField.set("dataSeriesOverride", combinedDataSeries);
        } else if (firstFieldClass === "object-field") {
            let total = fields.reduce((memo, field) => {
                return memo + (field.scalarValue || field.scalar_value);
            }, 0);
            if (firstField.isAveragedType || firstField.is_averaged_type) {
                total = total / fields.length;
            }
            combinedField.set("scalarValueOverride", total);
        }
        return combinedField;
    }
};
