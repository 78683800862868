import Ember from 'ember';
import { inject as controller } from "@ember/controller";
import {computed, action} from "@ember/object";
import ControllerBase from "infegy-frontend/atlas/dashboard/content/base/controller";
import BrowserStore from "infegy-frontend/utils/browser-store";
import CustomTrendField from "infegy-frontend/models/trends/custom-trend-field";
import loader from "infegy-frontend/models/field-definitions/loader";
import colorizeFields from "infegy-frontend/utils/fields-colorizer";

export default class TrendsController extends ControllerBase {
    @BrowserStore.Attr("trendsUseCustomFieldsSingle") useCustomFieldsSingle;
    @BrowserStore.Attr("trendsUseCustomFieldsMulti") useCustomFieldsMulti;

    @BrowserStore.Attr("trendsNormalizeStartTimes", {defaultValue: "true"}) normalizeStartTimes;
    @BrowserStore.Attr("trendsChartType", {defaultValue: "line"}) chartType;

    @BrowserStore.Attr("trendsSingleQueryFieldsJSON") singleQueryFieldsJSON;
    @BrowserStore.Attr("trendsMultiQueryFieldJSON") multiQueryFieldJSON;

    grouping = null;
    singleQueryFields = null;
    multiQueryField = null;

    @BrowserStore.Attr("trendsCustomSingleQueryFieldsJSON") customSingleQueryFieldsJSON;
    @BrowserStore.Attr("trendsCustomMultiQueryFieldsJSON") customMultiQueryFieldsJSON;

    customSingleQueryFields = null;
    customMultiQueryFields = null;

    @computed("queries", "queries.length")
    get isMultiQuery() {
        return this.queries?.length > 1;
    }

    @computed("queries", "queries.length")
    get indexedQueries() {
        let indexedQueries = this.queries?.map((query, idx) => {
            query.queryInfo.lookupId = idx;
            return query;
        }) || [];
        return indexedQueries;
    }

    @computed("useCustomFieldsSingle", "useCustomFieldsMulti", "isMultiQuery")
    get useCustomFields() {
        return this.isMultiQuery ? this.useCustomFieldsMulti : this.useCustomFieldsSingle;
    }
    set useCustomFields(value) {
        if (this.isMultiQuery) {
            this.set("useCustomFieldsMulti", value);
        } else {
            this.set("useCustomFieldsSingle", value);
        }
    }

    constructor(){
        super(...arguments);
        if (this.customSingleQueryFieldsJSON) {
            let newFields = this.customSingleQueryFieldsJSON.map(fieldJSON => {
                let newField = CustomTrendField.create();
                newField.loadJSON(fieldJSON);
                return newField;
            });
            this.set("customSingleQueryFields", newFields);
        }
        if (this.customMultiQueryFieldsJSON) {
            let newFields = this.customMultiQueryFieldsJSON.map(fieldJSON => {
                let newField = CustomTrendField.create();
                newField.loadJSON(fieldJSON);
                return newField;
            });
            this.set("customMultiQueryFields", newFields);
        }
        if (this.singleQueryFieldsJSON) {
            let newFields = this.singleQueryFieldsJSON.map(fieldJSON => {
                let newField = loader.loadField(fieldJSON);
                return newField;
            });
            this.set("singleQueryFields", newFields);
        }
        if (this.multiQueryFieldJSON) {
            let newField = loader.loadField(this.multiQueryFieldJSON);
            this.set("multiQueryField", newField);
        }
    }

    @computed("isMultiQuery", "multiQueryField", "queries", "queries.[]", "queries.@each.updated")
    get multiQueryFields() {
        if (!this.isMultiQuery || !this.multiQueryField) {
            return;
        }
        let fields = this.queries.map(query => {
            let newField = this.multiQueryField.copy(query, null);
            newField.set("color", query.color);
            newField.set("title", query.title);
            return newField;
        });
        return fields;
    }

    @computed("customSingleQueryFields", "queries", "queries.@each.updated")
    get customSQFields() {
        let fields = this.customSingleQueryFields,
            query = this.queries  && this.queries[0];
        if (Ember.isEmpty(fields)) {
            let newField = CustomTrendField.create();
            newField.customStartDate.loadJSON(query.filters.startDate.toJSON());
            newField.customEndDate.loadJSON(query.filters.endDate.toJSON());
            fields = [newField];
        }
        fields.forEach(field => {
            field?.field?.set("query", query);
        });
        return fields;
    }

    @computed("customMultiQueryFields", "queries", "queries.@each.updated")
    get customMQFields() {
        let fields = this.customMultiQueryFields,
            query = this.queries  && this.queries[0];
        if (Ember.isEmpty(fields)) {
            let newField = CustomTrendField.create();
            newField.customStartDate.loadJSON(query.filters.startDate.toJSON());
            newField.customEndDate.loadJSON(query.filters.endDate.toJSON());
            fields = [newField];
        }
        fields.forEach(field => {
            field?.field?.set("queries", this.queries);
        });
        return fields;
    }

    @computed("singleQueryFields", "useCustomFields", "isMultiQuery",
        "multiQueryField", "customSQFields", "customMQFields", "queries",
        "queries.@each.updated", "query")
    get selectedFields() {
        if (this.useCustomFields) {
            let fields = (this.isMultiQuery ? this.customMQFields : this.customSQFields) || [];
            fields = fields.filter(trendField => trendField.field).map(trendField => {
                if (!trendField.usesCustomDates) {
                    return trendField.field.copy(trendField.field.query);
                }
                let fieldQuery = trendField.field.query.copy(),
                    originalStart = fieldQuery.queryInfo.timestamp,
                    originalEnd = fieldQuery.queryInfo.timestamp,
                    fieldStart = trendField.customStartDate.timestamp,
                    fieldEnd = trendField.customEndDate.timestamp;
                if (originalStart != fieldStart && originalEnd != fieldEnd) {
                    fieldQuery.queryInfo.set("startDate", trendField.customStartDate);
                    fieldQuery.queryInfo.set("endDate", trendField.customEndDate);
                }
                return trendField.field.copy(fieldQuery);
            });
            return fields;
        }
        let fields = this.isMultiQuery ? this.multiQueryFields : this.singleQueryFields;
        if (fields && !this.isMultiQuery) {
            let query = this.query;
            fields = fields.map(field => {
                field.set("query", query);
                return field;
            });
        }
        colorizeFields(fields)
        return fields;
    }

    @computed("selectedFields")
    get fieldApis() {
        let selectedFields = this.selectedFields,
            apis = selectedFields ? selectedFields.mapBy("apiName").uniq() : ["volume"];
        return apis;
    }

    @computed("useCustomFields", "selectedFields.firstObject.chartTitle")
    get chartTitle() {
        if (this.useCustomFields) {
            return "Custom Trends";
        }
        let chartTitle = this.selectedFields?.firstObject?.chartTitle;
        return chartTitle || "Trends";
    }

    @computed("chartTitle")
    get imageExportTitle() {
        return `${this.chartTitle} Trends`;
    }

    @action
    toggleCustomFields() {
        this.set("useCustomFields", !this.useCustomFields);
    }

    @action
    singleQueryFieldsChanged(singleQueryFields) {
        if (singleQueryFields) {
            let fieldsJSON = singleQueryFields.map(field => field.toJSON());
            this.set("singleQueryFieldsJSON", fieldsJSON);
        }
        this.set("singleQueryFields", singleQueryFields);
    }

    @action
    multiQueryFieldChanged(multiQueryField) {
        if (multiQueryField) {
            let fieldJSON = multiQueryField.toJSON();
            this.set("multiQueryFieldJSON", fieldJSON);
        }
        this.set("multiQueryField", multiQueryField);
    }

    @action
    customMultiQueryFieldsChanged(customMultiQueryFields) {
        if (customMultiQueryFields) {
            let fieldsJSON = customMultiQueryFields.map(field => field.toJSON());
            this.set("customMultiQueryFieldsJSON", fieldsJSON);
        }
        this.set("customMultiQueryFields", customMultiQueryFields);
    }

    @action
    customSingleQueryFieldsChanged(customSingleQueryFields) {
        if (customSingleQueryFields) {
            let fieldsJSON = customSingleQueryFields.map(field => field.toJSON());
            this.set("customSingleQueryFieldsJSON", fieldsJSON);
        }
        this.set("customSingleQueryFields", customSingleQueryFields);
    }

    @action
    groupingChanged(value) {
        this.set("grouping", value);
    }

    @action
    chartTypeChanged(value) {
        this.set("chartType", value);
    }

    @action
    normalizeStartTimesChanged(value) {
        this.set("normalizeStartTimes", value);
    }
};
