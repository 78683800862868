import Component from '@ember/component';
import {computed, action} from "@ember/object";
import FieldDefinitionLoader from "infegy-frontend/models/field-definitions/loader";
import TrendChartMeta from "infegy-frontend/models/trends/trend-chart-meta";
import classic from "ember-classic-decorator";

let existingQueries = {}; //key/value map of created queries to avoid duplicating api calls

@classic
export default class extends Component {
    queries = null;

    fields = null;
    combineQueries = false;

    grouping = "weekday";
    trendChartMeta = null;

    @computed("trendChartMeta", "grouping")
    get innerTrendChartMeta() {
        var trendChartMeta = this.trendChartMeta;
        if (!trendChartMeta) {
            trendChartMeta = TrendChartMeta.create();
        }
        trendChartMeta.groupingOverride = this.grouping;
        return trendChartMeta;
    }

    @computed("queries", "queries.[]")
    get isMultiQuery() {
        return this.queries?.length > 1;
    }

    @computed("isMultiQuery", "combineQueries")
    get isCombined() {
        return this.isMultiQuery ? this.combineQueries : false;
    }

    @computed("grouping", "queries", "queries.[]", "fields", "queries.@each.updated", "fields.@each.isLoaded", "isCombined")
    get collatedFields() {
        let grouping = this.grouping || "weekday",
            fields = (this.fields || []).map(field => {
                let apiString = field.query.toAPIQueryString(),
                    newFieldQuery = existingQueries[apiString];
                if (!newFieldQuery || newFieldQuery.queryInfo.selectedGrouping !== grouping) {
                    let newFieldQuery = field.query.copy();
                    newFieldQuery.queryInfo.selectedGrouping = grouping;
                    existingQueries[apiString] = newFieldQuery;
                } else {
                    // It is possible the user has updated the query and only changed the color 
                    // using the in-app-query-editor.  If this is the case we want to make sure we 
                    // are loading this new color to keep the chart colors in sync.
                    newFieldQuery.queryInfo.color.loadJSON(field.query.queryInfo.color.toJSON());
                }
                let newField = field.copy(newFieldQuery);
                newField.xAxisField = "_dayOfWeek";
                return newField;
            });

        if (this.isCombined) {
            fields = [combineMultiQueryFields(fields)];
        }
        return fields;
    }
};
