import Ember from "ember";
import Component from '@ember/component';
import TrendFieldDefinition from "infegy-frontend/models/field-definitions/trend-field";
import FieldBase from "infegy-frontend/components/field-selector/parts/fields/field-base/component";
import { computed, action } from '@ember/object';
import { run } from '@ember/runloop';

export default class FieldSelectorBase extends FieldBase {
    fieldType = "trend";

    makeFieldDefinition(options) {
        let fullOptions = Object.assign({
                apiName: this.apiName,
                segment: this.selectedSegment,
                subsegment: this.selectedSubsegment,
                fieldComponent: this.selectedFieldComponent
            }, options);

        if (!fullOptions.title) {
            fullOptions.title = fullOptions.fieldName || "";
            fullOptions.title = fullOptions.title.replace(/[A-Z]/g, function (letter) {
                return " " + letter;
            });
            fullOptions.title = fullOptions.title.charAt(0).toUpperCase() + fullOptions.title.substr(1);
        }
        if (!fullOptions.value) {
            fullOptions.value = [fullOptions.apiName, fullOptions.fieldName].join(".");
        }
        return fullOptions;
    };

    @computed("fieldType")
    get isScalarSelector() { return this.fieldType !== "trend"; }

    @computed("fieldType", "allAvailableFields", "selectedSegment", "selectedSubsegment", "selectedFields", "selectedFields.@each.colorString")
    get availableFields() {
        if (!this.selectedSubsegment || !(this.allAvailableFields &&
                this.allAvailableFields.hasOwnProperty(this.selectedSubsegment))) {
            return;
        }
        let selectedFields = this.selectedFields || [];
        let availableFieldDefinitions = this.allAvailableFields[this.selectedSubsegment] || [],
            availableFields = availableFieldDefinitions.map(def => {
                let newField = TrendFieldDefinition.create();
                newField.loadJSON(def);
                newField.set("fieldType", this.fieldType);
                let matchedField = selectedFields.find(field => field?.value === newField.value);
                // Set Color
                if (matchedField) {
                    newField.color = matchedField.color;
                }
                return newField;
            });

        if (availableFields && !this.selectedFields) {
            run.next(()=>{
                this.selectionsChanged(this.makeDefaultFieldSelection(availableFields));
            });
        }
        return availableFields;
    };

    @computed("selectedFields")
    get selectedValues() {
        let values = (this.selectedFields || []).map(opt => {
            return (opt && opt.value) || opt;
        });
        if (this.queries && this.queries.length > 1) {
            return values && [values[0]];
        }
        return values;
    }

    @computed("selectedValues")
    get selectedValue() {
        if (Ember.isArray(this.selectedValues)) {
            return this.selectedValues.length && this.selectedValues[0];
        }
    };

    @action
    selectionsChanged(selectedOptions) {
        this.fieldsChanged(selectedOptions)
    };

    @action
    selectionChanged(selectedValue) {
        let opt = (this.availableFields || []).find(opt => {
            return opt && opt.value === selectedValue;
        });
        if (opt) {
            this.fieldsChanged([opt]);
        }
    };

    @action
    scalarFieldChanged(scalarField) {
        if (!this.selectedFields || !scalarField) {
            return;
        }
        let newFields = this.selectedFields.map(field => {
            let newField = field.copy();
            newField.set("scalarField", scalarField);
            return newField;
        });
        this.fieldsChanged(newFields);
    };
};
