import Ember from "ember";
export default Ember.Component.extend({
    tagName: 'button',
    attributeBindings: ["type", "disabled"],
    classNames: ["speed-test-button"],
    type: 'button',
    disabled: null,

    testNumber: 0,

    buttonText: "Run Test",
    testFunctions: ["runTest"],

    testRepetitions: 500,

    runTest: function() {

    },

    click: function(event) {
        var reps = this.testRepetitions;
        var testNum = this.testNumber;
        var out = null;
        this.testFunctions.forEach(function(functionName) {
            var profileName = functionName + " " + testNum;
            console.groupCollapsed("Running", reps, "repetitions of", profileName);
            console.profile(profileName);
            var t1 = performance.now();
            // console.time(profileName);
            for (var i = 0; i < reps; i++) {
                out = this[functionName]();
            }
            // console.timeEnd(profileName);
            var t2 = performance.now();
            console.profileEnd();
            console.debug(profileName, " last output:");
            console.debug(out);
            console.groupEnd();
            var tdiff = (t2-t1),
                avg = tdiff/reps;
            console.debug(profileName, "runtime:", tdiff.toFixed(3),
                "ms over", reps, "runs, avg:",
                (avg).toFixed(3), "ms per run, capable of",
                (1000.0/(avg)).toFixed(3), "calls per second.");
        }, this);
        this.incrementProperty("testNumber");
        event.preventDefault();
        return false;
    },
});
