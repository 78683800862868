import Ember from "ember";

var States = {
    RegionCodes: Ember.Object.create({
        'NE': 'Northeast',
        'MW': 'Midwest',
        'SU': 'South',
        'WS': 'West'
    }),

    Regions: [
        ["WS", "HI", "Hawaii"],
        ["WS", "AK", "Alaska"],
        ["WS", "WA", "Washington"],
        ["WS", "OR", "Oregon"],
        ["WS", "CA", "California"],
        ["WS", "ID", "Idaho"],
        ["WS", "MT", "Montana"],
        ["WS", "WY", "Wyoming"],
        ["WS", "NV", "Nevada"],
        ["WS", "UT", "Utah"],
        ["WS", "AZ", "Arizona"],
        ["WS", "NM", "New Mexico"],
        ["WS", "CO", "Colorado"],
        ["MW", "ND", "North Dakota"],
        ["MW", "SD", "South Dakota"],
        ["MW", "MN", "Minnesota"],
        ["MW", "WI", "Wisconsin"],
        ["MW", "MI", "Michigan"],
        ["MW", "NE", "Nebraska"],
        ["MW", "KS", "Kansas"],
        ["MW", "MO", "Missouri"],
        ["MW", "IA", "Iowa"],
        ["MW", "IL", "Illinois"],
        ["MW", "IN", "Indiana"],
        ["MW", "OH", "Ohio"],
        ["SU", "OK", "Oklahoma"],
        ["SU", "TX", "Texas"],
        ["SU", "AR", "Arkansas"],
        ["SU", "LA", "Louisiana"],
        ["SU", "MS", "Mississippi"],
        ["SU", "AL", "Alabama"],
        ["SU", "TN", "Tennessee"],
        ["SU", "KY", "Kentucky"],
        ["SU", "WV", "West Virginia"],
        ["SU", "GA", "Georgia"],
        ["SU", "FL", "Florida"],
        ["SU", "SC", "South Carolina"],
        ["SU", "NC", "North Carolina"],
        ["SU", "VA", "Virginia"],
        ["SU", "MD", "Maryland"],
        ["SU", "DE", "Delaware"],
        ["NE", "PA", "Pennsylvania"],
        ["NE", "NY", "New York"],
        ["NE", "VT", "Vermont"],
        ["NE", "NJ", "New Jersey"],
        ["NE", "RI", "Rhode Island"],
        ["NE", "CT", "Connecticut"],
        ["NE", "MA", "Massachusetts"],
        ["NE", "NH", "New Hampshire"],
        ["NE", "ME", "Maine"],
        ["NE", "DC", "District of Columbia"]
    ],

    StatesByRegion: Ember.Object.create(),
    RegionByStates: Ember.Object.create(),

    RegionGetter: null
};

States.Regions.forEach(function(item) {
    var region = item[0],
        state = item[1],
        fixture = States.StatesByRegion;

    if (!fixture.get(region)) {
        fixture.set(region, []);
    }
    fixture.get(region).push(state);
});

States.Regions.forEach(function(item) {
    var region = item[0],
        state = item[1],
        fixture = States.RegionByStates;

    fixture.set(state, region);
});

States.RegionGetter = Ember.Object.create({
    statesByRegion: States.StatesByRegion,
    regionByStates: States.RegionByStates,
    regionCodes: States.RegionCodes,
    regionKeys: ['NE', 'MW', 'SU', 'WS'],

    getRegion: function(code) {
        var regions = this.regionByStates;
        if (!Ember.isArray(code) || !regions.get(code)) {
            return '';
        }
        return regions.get(code);
    },

    getStates: function(code) {
        var states = this.statesByRegion;
        if (!Ember.isArray(code) || !states.get(code)) {
            return [];
        }
        return states.get(code);
    },

    regionNameByCode: function(code) {
        var codeMap = this.regionCodes;
        if (!Ember.isArray(code) || !codeMap.get(code)) {
            return '';
        }
        return codeMap.get(code);
    },
});

export default States;
