import Ember from "ember";
import { computed, action } from "@ember/object";
import SearchSelect from "infegy-frontend/components/forms/search-select/component";
import KeyCodes from "infegy-frontend/static_data/keycodes";
import { actionArgument, argument } from "infegy-frontend/decorators/arguments";
import { tracked } from "@glimmer/tracking";

export default class SearchSelectSingle extends SearchSelect {
    @argument() selectedOption;
    @argument() selectedValue;
    @argument("label") labelField;
    @argument("value") valueField;
    @argument("") placeholder;
    @argument(false) fillWidth;
    @argument(false) disableSearch;
    @argument(false) allowEmptySelection;
    @actionArgument selectedValueChanged;

    @tracked isLoading = false;
    @tracked isCleared = false;
    @computed.notEmpty("innerSelectedOption") selectedOptionNotEmpty;

    @action
    valueChanged(value){
        if (this.selectedValueChanged) {
            this.selectedValueChanged(value);
        }
    }

    get innerSelectedOption () {
        if(Ember.isNone(this.selectedValue) && Ember.isNone(this.selectedOption)){
            if (!Ember.isEmpty(this.options) && !this.allowEmptySelection) {
                Ember.run.schedule("afterRender", ()=>{
                    this.valueChanged(this.options[0][this.valueField]);
                });
            }
            return null;
        }

        var foundOption = this.selectedOption;

        if (Ember.isNone(foundOption)) {
            foundOption = this.options.find((option)=>{
                var optValue = Ember.get(option, this.valueField);
                return this.compareValues(optValue, this.selectedValue);
            });
        }

        if(!Ember.isNone(foundOption)){
            return foundOption;
        } else if(!Ember.isEmpty(this.options) && !this.allowEmptySelection) {
            Ember.run.schedule("afterRender", ()=>{
                this.valueChanged(this.options[0][this.valueField]);
            });
        }
        return null;
    }

    get showSelectedOption(){
        return !this.isCleared && !this.searchString?.length;
    }

    get displayItems() {
        return this.filteredItems.slice(0, this.numToDisplay);
    }

    @action
    toggleShowList() {
        this.showList = !this.showList;
    }

    @action
    itemSelected(option) {
        this.isFocused = false;
        this.searchString = "";
        this.isCleared = false;
        this.selectedValueChanged(Ember.get(option, this.valueField), option);
    }

    @action
    searchKeyPress(e, value) {
        switch (e.keyCode) {
            case KeyCodes.Up:
                if (this.activeIndex > 0) {
                    this.activeIndex -= 1;
                }
                break;
            case KeyCodes.Down:
                if (this.activeIndex + 1 < this.filteredItems.length) {
                    this.activeIndex += 1;
                }
                break;
            case KeyCodes.Enter:
                if (this.filteredItems.length > this.activeIndex) {
                    this.itemSelected(this.filteredItems[this.activeIndex]);
                }
                break;
            case KeyCodes.Backspace:
            case KeyCodes.Delete:
                if (Ember.isEmpty(this.searchString)) {
                    this.isCleared = true;
                }
                break;
            case KeyCodes.Tab:
            case KeyCodes.Escape:
                this.searchFocusOut();
                break;
            default: // normal keypress
                this.searchString = value;
                this.showList = true;
                break;
        }
    }
}
