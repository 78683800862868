

// This version of the component allows for multi-selection of datasets on the
// Query form. This is currently disabled, so this should not be used unless that
// feature is re-added.


import Ember from "ember";
import {computed, action} from "@ember/object";
import Component from '@ember/component';

export default class extends Component {
    user = null;
    query = null;

    @computed.alias("user.customDatasets") customDatasets;

    @computed.notEmpty("query.queryInfo.customDatasetParts") datasetSelected;

    @computed("datasetSelected","user.permissions.socialDataAccess")
    showDatasetDropdown() {
        var datasetSelected = this.datasetSelected,
            hasSocialAccess = this.get("user.permissions.socialDataAccess");
        return !datasetSelected && hasSocialAccess;
    }

    @computed("datasetSelected", "formCustomDatasets", "formCustomDatasets.[]")
    get selectedDataset() {
        const selectedDatasets = this.formCustomDatasets;
        if (selectedDatasets && selectedDatasets.length > 0) {
            return selectedDatasets[0];
        }
        return "_infegy-social";
    }

    @computed("query.queryInfo.customDatasetParts", "query.queryInfo.customDatasetParts.[]", "customDatasets")
    get formCustomDatasets() {
        return this.get("query.queryInfo.customDatasetParts").toJSON().mapBy("id");
    }
    set formCustomDatasets(value) {
        var customDatasetParts = this.get("query.queryInfo.customDatasetParts"),
            customDatasets = this.customDatasets;
        customDatasetParts.clear();
        customDatasetParts.loadJSON(value.map((id) => {
            var dataset = customDatasets.findBy("id", id);
            return dataset.toJSON({fieldGroups: "url"});
        }));

        return value;
    }

    @computed("customDatasets", "customDatasets.[]")
    get datasetsOptions() {
        var customDatasets = this.customDatasets || [],
            usableDatasets = [];
        customDatasets.forEach((dataset)=>{
            if(Ember.get(dataset,"dataFullySearchable") && Ember.get(dataset,"totalPosts") > 0) {
                usableDatasets.push(dataset.toJSON({fieldGroups: "url"}));
            }
        });
        return usableDatasets.sortBy("title");
    }

    @computed("datasetOptions", "user.permissions.socialDataAccess")
    get dropDownOptions() {
        var datasetsOptions = this.datasetsOptions,
            hasSocialAccess = this.get("user.permissions.socialDataAccess");
        if(hasSocialAccess){
            return [{
                "title": "Infegy Social Data",
                "id": "_infegy-social"
            }].concat(datasetsOptions);
        }
        return customDatasets;
    }

    @action
    datasetChanged(datasetId){
        if (datasetId !== "_infegy-social") {
            this.setProperties({
                "formCustomDatasets": [datasetId]
            });
        }
    }
}
