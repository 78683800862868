import Ember from "ember";
import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";
import DataSeries from "infegy-frontend/models/data_series/data_series";
import fieldTimelineGrowth from "infegy-frontend/models/data_series/data_series_computed_field_growth";

var provinceResults = QueryAPIResults.extend({
    title : "caProvinces",
    preload: function(){
        var provinces = this.get("rawResults");
        provinces.forEach((province)=>{
            province.key = province.code;
        });
    },
    customEmptyCheckFunction: function(dataSeries){
        var data = Ember.get(dataSeries,"data");
        return data.reduce((sum, current) => sum + Ember.get(current,"sources"), 0) == 0;
    }
});

var description = DataSeriesDescription.load({
    "id": "string",
    "code": "string",
    "name": "string",
    "key": "string",

    "matches": "number",
    "sources": "number",
    "score": "number",
    "positiveMatches": "number",
    "negativeMatches": "number",
    "neutralMatches": "number",
    "positivity": "number",
    "timeline": "array"
}, [
    fieldTimelineGrowth.fieldSetup("documentsGrowthPct", "timeline")
]);

provinceResults.reopen({
    description: description
});

provinceResults.reopenClass({
    // This funtion combines geo objects into one by averaging their values
    // It assumes you are passing in an array of matching geo province, and so
    //   preserves the first province's string fields
    // The original province will be contained in _sourceGeos
    combineMatchingGeos: function(geoArray) {
        var filteredGeos = geoArray.filter(geo => typeof(geo) === "object");

        if (Ember.isEmpty(filteredGeos)) {
            return {};
        }

        var numGeos = filteredGeos.length,
            firstGeo = filteredGeos.shift();

        if (numGeos === 1) {
            return Object.assign({}, firstGeo, {
                _sourceGeos: [firstGeo]
            });
        }

        var tempDataSeries = DataSeries.create(),
            newGeo = Object.assign({}, firstGeo, {
                _sourceGeos: [firstGeo]
            });

        newGeo = filteredGeos.reduce((memo, geo) => {
            memo.score += geo.score;

            memo.matches += geo.matches;
            memo.sources += geo.sources;
            memo.totalSources += geo.totalSources;

            memo._sourceGeos.push(geo);
            return memo;
        }, newGeo);

        newGeo.score /= numGeos;

        tempDataSeries.buildComputed([newGeo], description);
        return newGeo;
    }
});

export default provinceResults;
