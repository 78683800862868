import Ember from "ember";

var computedSortField = function(_fieldName) {
    var fieldName = _fieldName;
    return Ember.computed("sortAscending", "sortField", function () {
        if (this.sortField === fieldName) {
            return this.sortAscending ? "sorted-asc" : "sorted-desc";
        }
    });
};

export default Ember.Component.extend({
    classNames: ["interests-data-table", "table-overflow-scroll"],

    stackedData:null,
    interests: Ember.computed.alias("stackedData.data"),
    api: "interests",
    sortField: "universe",
    sortAscending: false,

    isPaginated: true,
    currentPage:0,
    interestsPerPage: 20,
    selectedLastTier: null,
    expandable: true,
    selectedField: null,

    removeInterest: ()=>{},

    sortedInterests: Ember.computed("interests","sortField","sortAscending", function(){
        var interests = this.interests || [],
            sortField = this.sortField,
            sortAscending = this.sortAscending;

        return interests.sort((a,b)=>{
            let valueA = Ember.get(a,sortField),
                valueB = Ember.get(b,sortField);
            if(typeof valueA === "string"){
                return sortAscending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
            }
            return sortAscending ? valueA - valueB : valueB - valueA;
        });
    }),
    displayInterests: Ember.computed("sortedInterests", "interestsPerPage","currentPage", function(){
        let interests = this.sortedInterests,
            perPage = this.interestsPerPage,
            currentPage = this.currentPage,
            isPaginated = this.isPaginated;

        if(isPaginated){
            return interests.slice(currentPage * perPage, (currentPage * perPage) + perPage);
        }

        return interests;
    }),

    lastTierSort: computedSortField("lastTier"),
    distributionSort: computedSortField("distribution"),
    ratioSort: computedSortField("ratio"),
    sourcesSort: computedSortField("sources"),
    postsSort: computedSortField("documents"),
    timelineSort: computedSortField("documentsGrowthPct"),
    positivitySort: computedSortField("positivity"),
    universeSort: computedSortField("universe"),

    drilldownQueries: Ember.computed("queries", "selectedInterest",function() {
        const drilldownQueries = this.queries.map((query) => {
            let drilldownQuery = query.copy();
            drilldownQuery.queryInfo.addFilter({
                id: this.api === "interests" ? "source_interests" : "taxonomies",
                values: [this.selectedInterest]
            })
            return drilldownQuery;
        });
        return drilldownQueries;
    }),

    actions: {
        sortBy(newSortField) {
            var sortField = this.sortField;
            if (newSortField === sortField) {
                this.toggleProperty("sortAscending");
            } else {
                this.setProperties({
                    sortField: newSortField
                });
            }
        },
        showDrilldown(interest){
            if(this.selectedInterest === interest){
                this.set("selectedInterest", null);
            } else {
                this.set("selectedInterest", interest);
            }
        }
    }
});
