import Ember from "ember";
import QueryURL from "infegy-frontend/models/queries/query_url";
import QueryFilters from "infegy-frontend/models/queries/query_filters";
import QueryUtil from "infegy-frontend/models/queries/query_util";
import * as Sentry from "@sentry/browser";

import Workspace from "infegy-frontend/models/users/workspace2";
import GlobalFilters from 'infegy-frontend/models/queries/query_global_filters';

export default Ember.Route.extend({
    queryTitle: "",
    isUpdatingQueries: false,

    model: async function(params,transition) {
        var queryArray;
        var usedDeprecatedURL = QueryURL.isURLDeprecated(params.query_url) || transition.data.usedDeprecatedURL;
        try {
            queryArray = QueryURL.urlToQueryArray(params.query_url);
        } catch(e) {
            transition.abort();
            var nextTransition = this.transitionTo("atlas.dashboard.index");
            nextTransition.data = { urlIsCorrupt: true };
            return;
        }
        await GlobalFilters.setDefaultDateRange(queryArray, true);

        // catch for old single/:queryIndex/featureName and all/featureName routes
        // transition to new URL if user navigates to old URL
        var path = params.path || '';
        var isOldSingleOrAllRoute = path.indexOf("single/") > -1 || path.indexOf("all/") > -1;
        if (transition.targetName === "atlas.dashboard.content" && isOldSingleOrAllRoute) {
            var splitPath = path.split("/"),
                destinationPath = splitPath.pop();

            this.transitionTo(`atlas.dashboard.content.${destinationPath}`);
        }
        Sentry && Sentry.setContext("queries", {
            "queries": queryArray.map(q => q.queryInfo.toJSON())
        });

        queryArray.forEach((query, idx) => {
            query.set("queryInfo.lookupId", idx);
        });

        return {
                usedDeprecatedURL,
                queries: queryArray
            };
    },
    afterModel: async function(model, transition) {
        // Make sure the current workspace run is being tracked.  This should
        // handle loading the page from the "Run Workspaces" button as well as
        // direct URL navigation.
        const workspace = Workspace.create();
        workspace.setQueries(model.queries);
        try {
          await workspace.run();
        } catch (e) { }

        // Update the user workspace lists to pick up the workspace that is
        // being run now.
        const applicationController = this.controllerFor("application");
        applicationController?.user?.ownedWorkspaces?.getPage();

        if (transition.targetName === "atlas.dashboard.content" || transition.targetName === "atlas.dashboard.content.index") {
            this.transitionTo("atlas.dashboard.content.overview");
        }

        if (model.usedDeprecatedURL) {
            // if using the old URL style rewrite the URL and put up a warning
            var newUrl = QueryURL.queryArrayToUrl(model.queries);
            var nextTransition = this.transitionTo(transition.targetName, newUrl);
            nextTransition.data = {usedDeprecatedURL: true}
        }
    },
    serialize: function(model) {
        if (model.queries) {
            return { query_url: QueryURL.urlToQueryArray(model.queries) };
        }
    },
    setupController: function(controller, model) {
        this._super();

        if (model.queries) {
            // Clear out any additional filters that may have been saved to the query.
            // This shouldn't happen, but has before, so this code will clean up any
            // of those instances to prevent issues.
            model.queries.forEach((query, idx) => {
                query.queryInfo.set("lookupId", idx);
                query.queryInfo.set("additionalAPIParameters", {});
                query.queryInfo.set("selectedGrouping", null);
            })

            let editor = this.controllerFor("atlas/dashboard");
            editor.workspace.setQueries(model.queries);
            // Make sure we don't reset the query selection if the user is performing an "Update Queries" from the in-app-query-editor.
            if (!this.isUpdatingQueries) {
                controller.set("selectedQueryIndices", [0]);
            }
            this.set("isUpdatingQueries", false);
            // Prevent old queries from trying to use the query builder
            var canUseQueryBuilder = model.queries.reduce((pv, query) => pv && !!Ember.get(query,"queryInfo.isUsingQueryBuilder"),true);
            if(editor.get("selectedQueryFormType") === "builder" && !canUseQueryBuilder){
                editor.set("selectedQueryFormType","advanced");
            }

            controller.setProperties({
                inAppQueryEditorQueries: controller.cloneQueries(model.queries)
            });
        }

        controller.set("usedDeprecatedURL",model.usedDeprecatedURL);

        this.buildQueryTitle();
    },

    buildQueryTitle(){
        if (this.controller.selectedQueryIndices.length > 1) {
            this.set("queryTitle", `${this.currentModel.queries.length} Queries`);
        } else {
            this.set("queryTitle", this.currentModel.queries[this.controller.selectedQueryIndices[0]].title);
        }
    },

    actions: {
        invalidateModel(){
            // Transitioning to any content route will keep the model it was initialized with
            // If you wish to update the queries, you must also send 'invalidateModel' to call those hooks
            this.set("isUpdatingQueries", true);
            this.refresh();
        },
        buildQueryTitle(){
            this.buildQueryTitle();
            this.send("updateDocumentTitle",{});
        }
    }
});
