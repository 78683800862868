import Component from "@ember/component";
import classic from "ember-classic-decorator";
import { computed } from "@ember/object";

import { MouseInteractionWrapper } from "infegy-frontend/components/charting/mouse-interaction-mixin/component";

@classic
export default class PostsListPost extends MouseInteractionWrapper(Component) {
    classNames = ["single-post"];

    post = null;
    query = null;

    defaultSocialView = ""; // detailed|sample
    showQuery = false;

    // POJO with highlighted html element, initial trigger event, and entity id
    highlightBoxDetails = null;
    highlightBoxVisible = false;

    @computed("query")
    get isDataset() {
        return !!this.query.queryInfo.customDatasetId;
    }

    @computed("query")
    get postView() {
        return this.defaultSocialView || "sample";
    }

    @computed("post.fields")
    get fields() {
        let fields = this.post.fields;
        if (typeof fields === "string") {
            return JSON.parse(fields);
        } else {
            return fields;
        }
    }

    @computed.readOnly("fields.author") author;
    @computed.readOnly("query.posts.descriptorColumns") descriptorColumns;
    @computed.equal("channel", "twitter") isTwitter;
    @computed.readOnly("fields.link") link;
    @computed.readOnly("post.summary") summary;

    @computed("post.sourceChannel")
    get channel() {
        const source = this.post.sourceChannel;

        const sourceMap = {
            "twitter": "twitter",
            "facebook": "facebook",
            "instagram": "instagram",
            "pinterest": "pinterest",
            "reddit": "reddit",
            "tumblr": "tumblr",
            "youtube": "youtube",
            "tiktok": "tiktok",
            "news": "newspaper",
            "lexisnexis": "newspaper",
            "forums": "rss",
            "blog": "rss",
            "blogs": "rss",
            "images": "file-image",
            "": "web"
        };
    
        if (source in sourceMap) {
            return sourceMap[source];
        } else if (source === "uncategorized") {
            const sourceFeedUrl = this.post.sourceFeedUrl;
            if (sourceFeedUrl) {
                if (sourceFeedUrl.includes("linkedin.com")) {
                    return "linkedin";
                } else if (sourceFeedUrl.includes("threads.net")) {
                    return "threads";
                } else if (sourceFeedUrl.includes("mastodon.social")) {
                    return "mastodon";
                }
            }
        } else if (source === undefined) {
            return "document";
        }
        
        return source;
    }
    @computed("channel")
    get hasIcon() {
        if (this.post.sourceChannel === "uncategorized") {
            const sourceFeedUrl = this.post.sourceFeedUrl;
            if (sourceFeedUrl) {
                if (sourceFeedUrl.includes("linkedin.com")) {
                    return true;
                } else if (sourceFeedUrl.includes("threads.net")) {
                    return true;
                } else if (sourceFeedUrl.includes("mastodon.social")) {
                    return true;
                }
            }
        }

        return !(this.post.sourceChannel === "uncategorized");
    }

    @computed("channel", "hasIcon", "post.fields.link")
    get isRedditComment() {
        if (this.hasIcon && this.channel === "reddit") {
            let regex = /\/comments\/[^/]+\/[^/]+\/[^/]+/;
            return regex.test(this.post.fields.link);
        } else {
            return false;
        }
    }

    @computed("fields.body", "post.summary")
    get content() {
        let body = this.fields.body;
        return body ? Ember.String.htmlSafe(body) : this.get("post.summary");
    }

    @computed("query", "query.posts.dateField")
    get date() {
        const dateField = this.query.posts?.dateField;
        return this.get(`fields.${dateField}`);
    }

    @computed("author")
    get handle() {
        let handle = "";
        if(this.channel === "twitter" || this.channel === "pinterest") {
            handle = this.author.substring(this.author.lastIndexOf('(') + 1, this.author.lastIndexOf(')'));
        }
        return handle;
    }

    @computed("post.userSentiment") 
    get hasUserSentiment() {
        return this.get("post.userSentiment") && this.get("post.userSentiment") !== "none";
    }
    
    @computed("descriptorColumns", "descriptorColumns.length", "fields")
    get ratings() {
        const columns = this.query.posts.descriptorColumns || [];
        const ratingsObjects = columns.filter(item => {
            return item.name?.toLowerCase().includes("rating");
        }).map(filteredItem => {
            const delimiters = ["_", "-"];
            delimiters.forEach(delimiterItem => {
                if (filteredItem.name.includes(delimiterItem)) {
                    filteredItem.name = filteredItem.name.replace(delimiterItem, ' ');
                }
            });

            if (this.fields[filteredItem.id]) {
                filteredItem.value = this.fields[filteredItem.id];
            }
            
            return filteredItem;
        });

        return ratingsObjects;
    }

    @computed("post", "post.sentiment", "post.userSentiment")
    get sentiment() {
        if (this.get("post.userSentiment") && this.get("post.userSentiment") !== "none") {
            return this.get("post.userSentiment");
        }
        return this.get("post.sentiment");
    }

    @computed("sentiment")
    get sentimentColorClass() {
        return this.sentiment && ["chart", this.sentiment].join("-");
    }

    @computed("fields.title", "fields.link")
    get title() {
        return this.get("fields.title") ||
               this.get("fields.link") || "&nbsp";
    }

    @computed("highlightBoxDetails.entity")
    get keyValuePairs() {
        let entity = this.get("highlightBoxDetails.entity"),
            keys = entity.availableAttributes || [],
            keyValuePairs = [];

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i],
                value = entity[keys[i]];

            if (key === "tradedAs") {
                value = value[0][Object.keys(value[0])[0]];
            } else if (key === "taxonomy") {
                continue;
            }

            keyValuePairs.push({ key: key, value: value });
        }
        return keyValuePairs;
    }

    onMouseLeave() {
        this.set("highlightBoxVisible", false);
    }

    onMouseMove(event) {
        let element = event && event.target,
            postEntities = this.get("post.entities") || [],
            currentHighlight = this.highlightBoxDetails,
            entityInfo = Ember.Object.create({
                triggerEvent: event,
                sourceElement: element,
            }),
            matchFound = false;

        if (!event) {
            return;
        }

        if (currentHighlight &&
            currentHighlight.sourceElement === element) {
            if (!this.highlightBoxVisible) {
                this.set("highlightBoxDetails.triggerEvent", event);
                this.set("highlightBoxVisible", true);
            }
            return;
        }

        if (currentHighlight && element.classList.contains("post-highlighter-box-info")) {
            if (!this.highlightBoxVisible) {
                this.set("highlightBoxVisible", true);
            }
            return;
        }

        do {
            if (element.classList.contains("post-highlighter-query-match")) {
                entityInfo.set("query", this.get("post.query") || true);
                matchFound = true;
            } else if (element.classList.contains("post-highlighter-entity")) {
                let entityID = element.attributes.getNamedItem("data-entity-id").value;
                entityInfo.set("entity", postEntities.findBy("id", entityID));
                matchFound = true;
            } else if (element.classList.contains("post-highlighter-sentiment-negative")) {
                entityInfo.set("sentiment", "negative");
                matchFound = true;
            } else if (element.classList.contains("post-highlighter-sentiment-positive")) {
                entityInfo.set("sentiment", "positive");
                matchFound = true;
            }
            element = element.parentElement;
        } while (matchFound && element && element.classList && !element.classList.contains("single-post"));

        if (!matchFound) {
            this.set("highlightBoxVisible", false);
            return;
        }
        this.set("highlightBoxDetails", entityInfo);
        this.set("highlightBoxVisible", true);
    }
}
