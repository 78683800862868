import Ember from "ember";
import BaseFieldSelections from "infegy-frontend/models/fields/field-selections/base";

export default BaseFieldSelections.extend({
    apiName: "sentiment",
    defaultSubSegment: "Passion",

    makeSentimentFields: function (baseFields, suffix) {
        var that = this;
        return baseFields.map(function (baseField) {
            return that.makeFieldDefinition({
                title: baseField,
                fieldName: baseField + suffix,
                suggestedColorClass: "atlas-sentiment-" + baseField,
                inactiveByDefault: baseField === "neutral"
            });
        });
    },

    allAvailableFields: Ember.computed({
        get: function(key) {
            var baseFields = ["positive", "negative"],
                allAvailableFields = {};

            allAvailableFields["Sentiment.SubjectSentencesDistribution"] =
                    this.makeSentimentFields(baseFields, "SubjectSentencesDistribution");
            allAvailableFields["Sentiment.SentencesDistribution"] =
                    this.makeSentimentFields(baseFields, "SentencesDistribution");
            allAvailableFields["Sentiment.DocumentsDistribution"] =
                    this.makeSentimentFields(baseFields, "DocumentsDistribution");

            baseFields = ["positive", "negative", "neutral"];

            allAvailableFields["Sentiment.SubjectSentences"] =
                    this.makeSentimentFields(baseFields, "SubjectSentences");
            allAvailableFields["Sentiment.Sentences"] =
                    this.makeSentimentFields(baseFields, "Sentences");
            allAvailableFields["Sentiment.Documents"] =
                    this.makeSentimentFields(baseFields, "Documents");
            allAvailableFields["Sentiment.Universe"] =
                    this.makeSentimentFields(baseFields, "DocumentsUniverse");

            return allAvailableFields;
        }

    }),
});
