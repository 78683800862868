import Ember from "ember";
import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import fieldPortion from "infegy-frontend/models/data_series/data_series_computed_field_portion";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";
import fieldTimelineGrowth from "infegy-frontend/models/data_series/data_series_computed_field_growth";

export default QueryAPIResults.extend({
    title : "ages",
    dateField: null,
    rangeMeta: {},
    distributions: [{
        id: "documents",
        label: "Documents", 
        fields: [
            { label: "Count", value: "countPortion" }
        ] 
    }],
    preload: function(){
        var rawResponse = this.rawResults;
        this.set("rangeMeta",{
            "median": Ember.get(rawResponse, "median"),
            "mean": Ember.get(rawResponse, "mean")
        });
        
        this.set("rawResults", Ember.get(rawResponse,"ranges"));
    },

    description: DataSeriesDescription.load({
        "displayName": "string",
        "id": "string",

        "dbDistribution": "number",
        "count": "number",
        "probability": "percent",
        "vsExpected": "percent",
        "rangeStart": "number",
        "rangeEnd": "number",
        "timeline": "array"
    }, [
        fieldPortion.fieldSetup("countPortion", "count"),
        fieldTimelineGrowth.fieldSetup("documentsGrowthPct", "timeline")
    ])
});
