import Ember from 'ember';

export function timePassed(params, hash) {
    var value = params[0],
        date = new Date(value),
        elapsedDays = (Date.now() - value) / (1000 * 60 * 60 * 24);

    if (elapsedDays > 7) {
        var format = hash.format || "MMM Do, YYYY";
        return moment.utc(date).format(format);
    } else if (value && window.moment) {
        return moment(date).fromNow();

    } else {
        // moment.js not available. return data as is.
        return value;
    }
}

export default Ember.Helper.helper(timePassed);
