import Ember from "ember";
import Actions from "infegy-frontend/utils/common-actions";

export default Ember.Component.extend({
    emotionData: null,

    selectedEmotion: null,
    availableEmotions: [],

    viewField: "appearances",

    sortedEmotionData: Ember.computed("emotionData", function () {
        var emotionData = this.emotionData || [];
        return emotionData.sortBy("combined.Documents").reverse();
    }),

    actions: {
        emotionSelected: Actions.passUp("emotionSelected"),
    }
});
