import Component from "@ember/component";
import { action } from "@ember/object";
import { isEmpty } from "@ember/utils";
import classic from "ember-classic-decorator";

@classic
export default class extends Component {
    searchText = "";
    filterField = "";
    showListFilter = true;
    sortDirection = "DESC";
    sortField = "";

    /*
    filterFieldOptions supports an "all" value which will default to matching 
    against any filter field in this list.  This will behave as a logical OR, 
    meaning the list item will be included if any of the fields match the 
    searchInputText.
    */
    filterFieldOptions = [];

    sortFieldOptions = [];

    sortDirectionOptions = [
        {
            label: "Asc.",
            value: "ASC"
        },
        {
            label: "Desc.",
            value: "DESC"
        }
    ];

    // event handlers
    search() {};

    @action
    searchTextChanged(searchText) {
        this.set("searchText", searchText);
    }

    @action
    filterFieldChanged(filterField) {
        this.set("filterField", filterField);
        this.onSearch();
    }

    @action
    sortDirectionChanged(sortDirection) {
        this.set("sortDirection", sortDirection);
        this.onSearch();
    }

    @action
    sortFieldChanged(sortField) {
        this.set("sortField", sortField);
        this.onSearch();
    }

    @action
    onSearch() {
        let options = {};
        if (!isEmpty(this.searchText)) {
            options["search"] = this.searchText;
        } else {
            options["search"] = ""
        }
        if (!isEmpty(this.sortDirection))
            options["sort_direction"] = this.sortDirection;
        if (!isEmpty(this.sortField))
            options["sort_field"] = this.sortField;
        if (!isEmpty(this.filterField))
            options["filter_field"] = this.filterField;
        this.search(options);
    }
}