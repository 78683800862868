import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import fieldQuotient from "infegy-frontend/models/data_series/data_series_computed_field_quotient";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";
import DateProcessing from "infegy-frontend/utils/date-processing";
import DataSeries from "infegy-frontend/models/data_series/data_series";

export default QueryAPIResults.extend({
    title : "topKeywords",

    seenValues: [],
    seenValuesTotals: {},

    keywordDataSeriesCache: null,

    checkIfEmpty: function (dataSeries) {
        return !dataSeries || !dataSeries.get("stats") ||
            !dataSeries.get("stats.count") ||
            !dataSeries.get("stats.count.sum");
    },
    preload: function() {
        var rawResponse = this.rawResults,
            seenValues = rawResponse?.seen_values || [],
            trendData = rawResponse?.trend || [],
            seenValuesTotals = {};

        seenValues.forEach(kw => {
            seenValuesTotals[kw] = 0;
        });

        trendData.forEach(row => {
            row.top.forEach(trow => {
                seenValuesTotals[trow.value] += trow.count;
            });
        });

        let sortableSeenValues = seenValues.map(kw => {
            return {
                value: kw,
                count: seenValuesTotals[kw] || 0
            };
        });

        this.set("seenValuesTotals", seenValuesTotals);
        this.set("sortableSeenValues", sortableSeenValues);
        this.set("rawResults", trendData);
        this.set("seenValues", seenValues);
    },
    description: DataSeriesDescription.load({
        date: "datestring",
        timestamp: "timestamp",
        otherCount: "number",
        totalCount: "number",
        top: "array"
    }),
    getDataSeriesForKeyword(keyword) {
        let cache = this.keywordDataSeriesCache,
            data = this.data || [];

        if (!this.isLoaded || Ember.isEmpty(this.data)) {
            return null;
        }

        if (!cache) {
            cache = {};
            this.set("keywordDataSeriesCache", cache);
        } else if (cache[keyword]) {
            return cache[keyword];
        }

        let kwData = this.data.map(row => {
            let rowKeywords = row.top || [],
                rowKeywordData = rowKeywords.find(rowTop => rowTop.value === keyword);
            let newRow = {
                    date: row.date,
                    groupName: row.groupName,
                    count: rowKeywordData ? rowKeywordData.count : 0
                };
            if (rowKeywordData && rowKeywordData.subField) {
                newRow.subFieldCount = rowKeywordData.subField.count || 0;
                newRow.subFieldMin = rowKeywordData.subField.min || 0;
                newRow.subFieldMax = rowKeywordData.subField.max || 0;
                newRow.subFieldMean = rowKeywordData.subField.mean || 0;
                newRow.subFieldSum = rowKeywordData.subField.sum || 0;
            };
            return newRow;
        });

        let topKeywordsKWFields = DataSeriesDescription.load({
                date: "datestring",
                timestamp: "timestamp",
                groupName: "string",

                count: "number",

                subFieldSum: "number",
                subFieldMean: "average",
                subFieldMin: "average",
                subFieldMax: "average",
                subFieldCount: "number",
            }, [
                fieldQuotient.fieldSetup("average", "sum", "count"),
            ]);


        let newDateFields = DateProcessing.fixApiDates(kwData, this.dateField);
        topKeywordsKWFields.addFields(newDateFields);

        let dataSeries = DataSeries.load(kwData, topKeywordsKWFields);
        return dataSeries;
    }
});
