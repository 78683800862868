import Ember from "ember";
export default [
    Ember.Object.create({
        label: 'Discovery',
        value: 'overview'
    }),
    Ember.Object.create({
        label: 'Trends',
        value: 'trends'
    }),
    Ember.Object.create({
        label: 'Interests',
        value: 'interests'
    }),
    Ember.Object.create({
        label: 'Linguistics',
        value: 'linguistics'
    }),
    Ember.Object.create({
        label: 'Geography',
        value: 'geography'
    }),
    Ember.Object.create({
        label: 'Demographics',
        value: 'demographics'
    }),
    Ember.Object.create({
        label: 'Influencers',
        value: 'influencers'
    }),
    Ember.Object.create({
        label: 'Content',
        value: 'content'
    }),
    Ember.Object.create({
        label: 'Exports',
        value: 'export'
    }),
];
