import Ember from "ember";
import DataSeries from "infegy-frontend/models/data_series/data_series";

export default Ember.Component.extend({
    queries:[],

    allEntities: Ember.computed("queries","queries.@each.updated","queries.@each.entities",function(){
        var queries = this.queries || [],
            mappedDataSeries = queries.mapBy("entities.dataSeries") || [],
            stacked = DataSeries.stackBy(mappedDataSeries, "id");
        return stacked && stacked.get("data").sortBy("appearances").reverse().splice(0,7);
    }),
});
