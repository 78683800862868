import Ember from "ember";
import Controller, { inject as controller } from "@ember/controller";
import { action, computed } from "@ember/object";

import Query from "infegy-frontend/models/users/query";
import Workspace from "infegy-frontend/models/users/workspace2";
import GlobalFilters from "infegy-frontend/models/queries/query_global_filters";

export default class CustomDatasetController extends Controller {
    @controller application;
    @controller("atlas/dashboard") dashboardController;
    @computed.alias("application.user") user;
    @computed.alias("user.companyUsers") companyUsers;
    @computed.alias("user.customDatasets") customDatasets;

    sortAscending = false;
    sortField = "updatedTimestamp";

    sortFields = [
        {title: "Title", value: "title"},
        {title: "File Size", value: "totalBytes"},
        {title: "Document Count", value: "totalPosts"},
        {title: "Last Update", value: "updatedTimestamp"},
        {title: "Creation Date", value: "createdTimestamp"}
    ];

    @computed("customDatasets")
    get newAndProcessingDatasets() {
        var customDatasets = this.customDatasets || [];
        return customDatasets.filter((row) => {
            return !row.get("dataFullySearchable") || !row.get("totalPosts");
        });
    }

    @computed("newAndProcessingDatasets")
    get newAndProcessingDatasetsTitle() {
        var npDatasets = this.newAndProcessingDatasets || [],
            hasNewDS = npDatasets.find(r => !r.get("totalPosts")) ? "New" : "",
            hasProcessingDS = npDatasets.find(r => !r.get("dataFullySearchable")) ? "Processing" : "";

        return hasNewDS + ((hasNewDS && hasProcessingDS) ? " and " : "") + hasProcessingDS;
    }

    @computed("newAndProcessingDatasets.length")
    get sortable() {
        return this.get("newAndProcessingDatasets.length") > 5;
    }

    @computed("customDatasets")
    get sharedDatasets() {
        var customDatasets = this.customDatasets || [];
        return customDatasets.filter((row) => {
            return row.get("dataFullySearchable") && !row.get("writeAccess") && row.get("totalPosts");
        });
    }

    @computed("customDatasets")
    get ownedDatasets() {
        var customDatasets = this.customDatasets || [];
        return customDatasets.filter((row) => {
            return row.get("dataFullySearchable") && row.get("writeAccess") && row.get("totalPosts");
        });
    }

    @action
    async saveCustomDataset(customDataset){
        try {
            await customDataset.save(customDataset.title, customDataset.description);
            this.send("showSuccess", `${customDataset.title} was successfully saved`);
        }
        catch(error) {
            this.send("showError", Ember.get(error,"atlasErrorText"));
        }
    }

    @action
    async deleteCustomDataset(customDataset){
        try {
            await customDataset.delete();
        }
        catch {
            this.send("showError", Ember.get(res,"atlasErrorText"));
            return;
        }
        this.send("reloadDatasets");
        this.send("showSuccess", `${customDataset.title} was successfully deleted`);
    }

    @action
    reloadDatasets(){
        var user = this.user;
        user.loadCustomDatasets();
    }

    @action
    showSuccess(successMessage){
        this.set("showResponseSuccess", successMessage);
        Ember.run.later(()=>{
            this.set("showResponseSuccess", false);
        }, 3500);
    }

    @action
    showError(error){
        this.set("showResponseError", error);
        Ember.run.later(()=>{
            this.set("showResponseError", false);
        }, 3500);
    }

    @action
    appendToDataset(dataset){
        this.transitionToRoute("atlas.tools.custom-datasets.append", Ember.get(dataset,"id"));
    }

    @action
    useCustomDataset(dataset) {
        let query = Query.create();
        query.queryInfo.customDatasetId = dataset.id;

        let workspace = Workspace.create();
        workspace.setQueries([query]);
        this.dashboardController.set("workspace", workspace);
        GlobalFilters.setDefaultDateRange(workspace.queries);

        this.transitionToRoute("atlas.dashboard");
    }

    @action
    onSortFieldChanged(newSortField) {
        var sortField = this.sortField;
        if (newSortField !== sortField) {
            this.setProperties({
                sortField: newSortField,
                sortAscending: "title" === newSortField
            });
        }
    }
}
