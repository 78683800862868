import Component from "@ember/component";
import classic from "ember-classic-decorator";
import { action } from "@ember/object";

@classic
export default class InfluencersListSettings extends Component {
    columnOptions = [];
    columnsFixed = [];
    columnsSelected = [];
    showSettings = false;

    tempSelection = [];

    init() {
        super.init(...arguments);
        let copy = JSON.stringify(this.columnsSelected);
        this.tempSelection = JSON.parse(copy);
    }

    // events
    selectColumns(columnSelection) {}

    @action
    onApply() {
        this.selectColumns(this.tempSelection);
        this.set("showSettings", false);
    }

    @action
    onCancel() {
        this.set("showSettings", false);
    }
}