import Ember from "ember";
import TrendInteraction from "infegy-frontend/models/fields/trend-chart-interaction";

export default Ember.Component.extend({
    fields: [],

    selectable: false,
    interactionInfo: {},

    innerInteractionInfo: Ember.computed("interactionInfo", function () {
        var interactionInfo = this.interactionInfo;
        if (interactionInfo && interactionInfo.setProperties) {
            return interactionInfo;
        } else {
            return TrendInteraction.create();
        }
    }),

    originalFirstField: Ember.computed.or("fields.firstObject.sourceField", "fields.firstObject"),
    isSingleDayQuery: Ember.computed.equal("originalFirstField.data.length", 1),

    interactionUpdated: () => {},
    fieldSelected: () => {},
    fieldClicked: () => {},
    actions: {
        fieldHovered: function(field) {
            if (this.get("innerInteractionInfo.hoveredField") !== field) {
                this.set("innerInteractionInfo.hoveredField", field);
                this.set("innerInteractionInfo.isHovered", false);
                this.interactionUpdated(this.innerInteractionInfo);
            }
        },
    },
});
