import Ember from "ember";
import ControllerBase from "infegy-frontend/atlas/dashboard/content/base/controller";

export default ControllerBase.extend({
    apis: ['posts'],

    minTableView: 50,
    postFilterQuery: '',
    sortKey: 'none',

    postFilters: {},
    
    channels: [],
    emotions: [],
    interests: [],
    sentiment: [],
    themes: [],

    displayOptions: ['Default', 'Embeds'],
    selectedDisplay: 'Default',
    isDisplayEmbeds: false,

    postQueries: Ember.computed('queries', 'sortKey', 'postFilterQuery', 'postFilters', function() {
        return this.queries.map(query => {
            let newQuery = query.copy();

            newQuery.queryInfo.addAdditionalAPIParameters({
                sort: this.sortKey,
                sub_query: this.postFilterQuery,
                ...this.postFilters
            });

            return newQuery;
        });
    }),

    sortOptions: Ember.computed('query', 'query.posts', 'query.posts.sortableFields', function() {
        let query = this.query,
            sortableFields = query?.posts?.sortableFields || [],
            options = ['none'];

        sortableFields.map(option => { options.addObject(option.id, option.name); });

        return options.filter((v, index) => {
            return options.indexOf(v) === index; // Filter out the duplicates, leave only uniques
        });
    }),

    displayReset: Ember.observer('queries', function() {
        if (this.isDisplayEmbeds) {
            this.set('selectedDisplay', 'Default');
            this.toggleProperty('isDisplayEmbeds');
        }
    }),

    actions: {
        sortKeyChanged(key) {
            if (this.sortKey !== key) {
                this.set('sortKey', key);
            }
        },
        filterPosts(value) {
            this.set('postFilterQuery', value);
        },
        displayChanged(display) {
            this.set('selectedDisplay', display);
            this.toggleProperty('isDisplayEmbeds');
        }
    }
});
