import Ember from 'ember';
import DateProcessing from "infegy-frontend/utils/date-processing";

export function dateFormat(value, hash) {
    if (Ember.typeOf(value) === "array") {
        value = value[0];
    }

    const date = DateProcessing.getUTCDate(value);
    const elapsedDays = (Date.now() - DateProcessing.datestringToDayTimestamp(value)) / (1000 * 60 * 60 * 24);

    function getMonthAbbreviation(monthNumberParam) {
        let monthName;
        for (let prop in DateProcessing.constants.monthNameToNumber) {
            if (monthNumberParam === DateProcessing.constants.monthNameToNumber[prop]) {
                monthName = prop;
            }
        }

        monthName = monthName ? monthName.slice(0,3) : "Unknown";
        return monthName;
    }

    const month = getMonthAbbreviation(date.getMonth());

    if (elapsedDays < 1) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'pm' : 'am';

        hours = date.getHours() % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;

        return `${month} ${date.getDate()}, ${date.getFullYear()} ${hours}:${minutes} ${ampm}`;
    } else {
        return `${month} ${date.getDate()}, ${date.getFullYear()}`;
    }
}

export default Ember.Helper.helper(dateFormat);
