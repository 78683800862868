import Ember from "ember";
import CSSTools from "infegy-frontend/utils/css-tools";

export default class ColorPreview extends Ember.Component {
    // Arguments
    query = null;
    queries = [];
    color = null;
    contrastWarningThreshold = 0.05;


    @Ember.computed.notEmpty("contrastingColors") showContrastWarning;

    @Ember.computed("color.hex", "queries.@each.color")
    get contrastingColors(){
        if(!this.get("color.hex")){
            return false;
        }
        var colors = [{
            label: "Background Color",
            hex:"#FFFFFF"
        }];
        this.queries.map((query)=>{
            if (this.query !== query) {
                colors.push({
                    label: Ember.get(query,"title"),
                    hex: Ember.get(query,"color.hex")
                });
            }
        });
        return colors.filter((color)=>{
            var contrast = CSSTools.contrast(color.hex, this.color.hex);
            return contrast <= this.contrastWarningThreshold;
        });
    }

    get sparkPreviewData(){
        var points = [];
        for(let idx=0; idx<(2*Math.PI * 10); idx++) {
            points.push(Math.sin(idx / 10));
        }
        return points;
    }

    @Ember.computed("color","color.darkColorString", "color.lightColorString")
    get gradientStyle(){
        return CSSTools.buildStringFromObjectSafe({
            background: `linear-gradient(to right, ${this.color.lightColorString}, ${this.color.hex}, ${this.color.darkColorString})`
        });
    }
}