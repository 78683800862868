import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { ComparisonTableField, ComparisonTableFields } from "infegy-frontend/components/field-components/comparison-table/table-field";
import CSSTools from "infegy-frontend/utils/css-tools";
import tinycolor from "infegy-frontend/utils/tinycolor";
import d3 from "d3";
import classic from "ember-classic-decorator";

@classic
export default class ComparisonTableCell extends Component {
    tagName = "td";
    attributeBindings = ["heatmapStyle:style"];
    classNameBindings = ["tableCellClass", "isTotalCell:data-table-value-total"];

    fieldRow = null;
    tableField = null;
    queries = null;

    isQueryColumn = false;
    isTotalCell = false;

    @computed("tableField.field.isLoaded", "tableField.displayType", "isQueryColumn")
    get tableCellClass() {
        let cellClass = "data-table-value";
        if (!this.tableField || !this.tableField.field || !this.tableField.field.isLoaded) {
            cellClass = "data-table-value";
        } else if (this.isQueryColumn) {
            if (this.tableField.displayType === "barchart") {
                cellClass = "data-table-fixed-numeric";
            }
            cellClass = "data-table-value data-table-fixed-numeric";
        } else if (this.tableField.displayType === "barchart") {
            cellClass = "data-table-fixed-numeric";
        } else if (this.tableField.displayType === "sparkline") {
            cellClass = "data-table-sparkline";
        }
        return cellClass;
    }

    @computed("fieldRow.rangeScale", "tableField.field.scalarValue", "fieldRow.originalField.field.color")
    get heatmapStyle() {
        if (!this.get("fieldRow.rangeScale") || Ember.isEmpty(this.get("tableField.field.scalarValue")) ||
                !this.get("fieldRow.originalField.field.color") || this.tableField.displayType !== "heatmap") {
            return;
        }
        let color = this.fieldRow.queryFields.firstObject.field.colorString;

        let value = this.tableField.field.scalarValue,
            scale = this.fieldRow.rangeScale,
            scaledValue = scale(value),
            colorRange = d3.interpolateRgb("rgb(255,255,255)", color),
            backgroundColor = colorRange(scaledValue),
            colorObj = tinycolor(backgroundColor),
            luminance = colorObj.getLuminance();

        let textColor = luminance >= 0.4 ? "#404e6a" : "#FFF";
        return CSSTools.buildStringFromObjectSafe({
            backgroundColor: backgroundColor,
            color: textColor
        });
    }

    @computed("this.tableField.field.queryApi")
    get apiModels() {
        return this.isTotalCell ? null : [this.tableField?.field?.queryApi];
    }
};
