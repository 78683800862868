import Ember from 'ember';
import DateFilters from "infegy-frontend/static_data/query/date_filters";
import AtlasConfig from "infegy-frontend/config/infegy-app-config";

export default Ember.Component.extend({
    date: null,

    isNowAllowed: false,

    earliestTimestamp: AtlasConfig.EarliestAtlasTimestamp,
    latestTimestamp: null,

    isNow: Ember.computed("isNowAllowed", "date", "date.relative.isNow", {
        get: function(key) {
            return this.date && this.get("date.relative.isNow");
        },
    }),

    relativeUnitsOptions: Ember.computed("earliestTimestamp", function () {
        var earliestTimestamp = this.earliestTimestamp ?? AtlasConfig.EarliestAtlasTimestamp,
            currentTimestamp = Date.now();

        return DateFilters.RelativeUnits.filter(option => {
            var checkTime = currentTimestamp - option.multiplier - 1;
            return earliestTimestamp < checkTime
        });
    }),

    relativeModifierOptions: DateFilters.RelativeModifier,

    valueChanged: Ember.observer("date.relative.value",function(){
        this.send("valueChanged");
    }),

    dateChanged: () => {},
    
    actions: {
        valueChanged: function() {
            var date = this.date;
            date = date && date.limitValue(this.earliestTimestamp);
            this.dateChanged(date);
            return date;
        },
        focusOutInput: function() {
            var date = this.date;
            if(!this.get('date.relative.value')) {
                if(this.get('date.relative.units') === "days"){
                    this.set('date.relative.value', 30);
                } else {
                    this.set('date.relative.value', 1);
                }
            }
            return date && date.limitValue(this.earliestTimestamp);
        },
        unitsChanged: function(units){
            this.set("date.relative.units",units);
            this.send("valueChanged");
        }
    }
});
