import Ember from "ember";
import DataSeries from "infegy-frontend/models/data_series/data_series";
import d3 from "d3";

export default Ember.Component.extend({
    contentArea: null,
    rows: [],
    dataSeriesArray: Ember.computed.mapBy("rows", "dataSeries"),

    dataSeries: Ember.computed.alias("dataSeriesArray.firstObject"),

    yAxisField: "countPortion",
    displayNameField: "displayName",

    groupingField: "displayName",

    averagingDataSeries: null,
    averagingYAxisField: "probability",

    renderAveragedArea: true,
    averagingMax: 1,

    drawCircles: true,

    hoveredIndex: null,
    selectedIndex: null,

    isHovered: Ember.computed.notEmpty("hoveredIndex"),
    isSelected: Ember.computed.notEmpty("selectedIndex"),

    drawArea: Ember.computed("dataSeriesArray.length", function () {
        return this.get("dataSeriesArray.length") < 2;
    }),

    combinedDataSeries: Ember.computed("dataSeriesArray", "dataSeriesArray.[]", function () {
        var dataSeriesArray = this.dataSeriesArray || [],
            groupingField = this.groupingField;
        if (Ember.isEmpty(dataSeriesArray) || Ember.isEmpty(groupingField)) {
            return;
        }
        var activeDataSeries = dataSeriesArray.filter(function (dataSeries) {
            return dataSeries;
        });
        this.setProperties({
            hoveredIndex: null,
            selectedIndex: null
        });
        return DataSeries.stackBy(activeDataSeries, groupingField);
    }),

    maxY: Ember.computed("dataSeriesArray", "dataSeriesArray.[]", "yAxisField", function () {
        var dataSeriesArray = this.dataSeriesArray || [],
            yAxisField = this.yAxisField,
            maxY = 1.0;
        if (!Ember.isEmpty(dataSeriesArray) && !Ember.isEmpty(yAxisField)) {
            maxY = dataSeriesArray.filterBy(["stats", yAxisField, "max"].join("."))
                .mapBy(["stats", yAxisField, "max"].join("."));
            maxY = Math.max.apply(Math, maxY);
        }
        return maxY;
    }),

    averagingMaxY: Ember.computed("averagingDataSeries", "averagingYAxisField", function () {
        var stats = this.get("averagingDataSeries.stats"),
            yAxisField = this.averagingYAxisField,
            yAxisStats = stats && yAxisField && stats.get(yAxisField);
        return yAxisStats ? yAxisStats.get("max") : 1.0;
    }),

    labels: Ember.computed("combinedDataSeries", "combinedDataSeries.data",
            "displayNameField", function () {
        var displayNameField = this.displayNameField,
            innerData = this.combinedDataSeries && this.get("combinedDataSeries.data");
        if (displayNameField && innerData) {
            return innerData.map(function(d) { return d[displayNameField]; });
        }
    }),

    xRange: Ember.computed("dataSeries.data.length", "contentArea.width", function () {
        return d3.scaleLinear()
            .domain([0, this.get("dataSeries.data.length")])
            .range([0, this.get("contentArea.width")]);
    }),

    xRangePct: Ember.computed("dataSeries.data.length", "contentArea.width", function () {
        return d3.scaleLinear()
            .domain([0, this.get("dataSeries.data.length")])
            .range([0, 1.0]);
    }),

    hoveredLineX: Ember.computed("xRange", "hoveredIndex", function () {
        var xRange = this.xRange || [],
            index = this.hoveredIndex || 0;
        return xRange(index + 0.5);
    }),

    selectedLineX: Ember.computed("xRange", "selectedIndex", function () {
        var xRange = this.xRange || [],
            index = this.selectedIndex || 0;
        return xRange(index + 0.5);
    }),

    wasHovered() { /* action placeholder */ },
    wasSelected() { /* action placeholder */ },

    actions: {
        removeHoveredIndex(){
            this.set("hoveredIndex",null);
        },
        wasHovered(hoverInfo) {
            var mouseX = hoverInfo.mouseXPct || 0,
                data = this.get("dataSeries.data") || [],
                xRangePct = this.xRangePct,
                index = Math.floor(mouseX * data.length) || 0;

            this.wasHovered({
                "xPct": hoverInfo.isHovered ? xRangePct(index + 0.5) : null,
                "index": hoverInfo.isHovered ? index : null
            });
        },
        wasSelected(clickInfo) {
            var mouseX = clickInfo.mouseXPct || 0,
                data = this.get("dataSeries.data") || [],
                xRangePct = this.xRangePct,
                index = Math.floor(mouseX * data.length) || 0,
                selectedIndex = this.selectedIndex;

            this.wasSelected({
                "xPct": selectedIndex === index ? xRangePct(index + 0.5) : null,
                "index": selectedIndex === index ? null : index
            });
        },
    },
});
