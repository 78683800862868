import Ember from "ember";
import Component from '@glimmer/component';
import { actionArgument, argument } from "infegy-frontend/decorators/arguments";
import { tracked } from "@glimmer/tracking";
import { computed, action } from '@ember/object';

export default class extends Component {
    @argument(null) filtersets;
    @argument(null) filterOptions;
    @argument(null) modifierOptions;
    @argument(false) isCompact;
    @argument([]) appliedFilters;

    @actionArgument loadFilterset;
    @actionArgument appendQueryFilter;
    @actionArgument appendModifier;
    @actionArgument removeAllFilters;
    @tracked isLoadingFilterset = false;

    @computed("filtersets")
    get alphabetizedFiltersets() {
        return this.filtersets.content.sort((a, b) => {
            const aLowerCaseTitle = a.title.toLowerCase();
            const bLowerCaseTitle = b.title.toLowerCase();

            if (aLowerCaseTitle > bLowerCaseTitle) {
                return 1;
            }
            if (aLowerCaseTitle < bLowerCaseTitle) {
                return -1;
            }
        })
    }

    @action
    queryFilterChanged (value) {
        if(value){
            this.appendQueryFilter(value);
        }
    }

    @action
    toggleShowLoadFilterset(){
        this.isLoadingFilterset = !this.isLoadingFilterset;
    }

    @action
    filtersetSelected(id) {
        var filterset = this.filtersets?.findBy("id",id);
        if (filterset) {
            this.loadFilterset(filterset);
            this.isLoadingFilterset = false;
        }
    }

    @action
    modifierSelected(modifier) {
        if (modifier) {
            this.appendModifier(modifier);
        }
    }

    @action
    onRemoveAllFilters() {
        this.removeAllFilters();
    }
}
