import Ember from "ember";
export default Ember.Component.extend({
    user: null,
    alerts: Ember.computed.alias("user.alerts"),
    
    showCustomDatasetAlert: Ember.computed("user.customDatasets",function(){
        var customDatasets = this.get("user.customDatasets") || [];
        return customDatasets.findBy("isExpiring",true);
    }),
    
    showUnsupportedBrowserAlert: Ember.computed(function() {
        /* 
            Using the User agent to detect browser type
            https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/User-Agent 
            Some ideas taken from:
            https://stackoverflow.com/questions/5916900/how-can-you-detect-the-version-of-a-browser
        */
        if(!window.navigator || !window.navigator.userAgent) {
            return true;
        }

        var isUnsupportedBrowser = false;
        // Searches for browser/version returns [browser name, version]
        var userAgent = window.navigator.userAgent,
            res = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))/i) || [],
            browser = res[1] && res[1].toLowerCase(); 
        
        if(browser === 'chrome') {
            // Check if this is actually opera
            var check = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
            if(check !== null) {
                // Opera
                isUnsupportedBrowser = true;
            }
            // using Chrome
        } else if (browser !== "firefox" && browser !== "safari") {
            isUnsupportedBrowser = true;
        } 
    
        return isUnsupportedBrowser;
    }),
    
    versionOutdated: false,
    actions: {
        reload:function(){
            window.location.reload(true);
        }
    }
});
