import classic from "ember-classic-decorator";

import JsonStore from "infegy-frontend/json-store/model";
import AtlasConfig from "infegy-frontend/config/infegy-app-config";

@classic
export default class GlobalFilters extends JsonStore {
    static async synchronize(queries=[], onlySetRange=false) {
        if (queries.content) {
            queries = queries.content;
        }
        const [min, max] = await this.getTimestampRange(queries);
        for (let i=1; i<queries.length; i++) {
            if (i === 0) return;
            const query = queries[i];
            if (!onlySetRange) {
                query.queryInfo.startDate.loadJSON(queries[0].queryInfo.startDate.toJSON());
                query.queryInfo.endDate.loadJSON(queries[0].queryInfo.endDate.toJSON());
            }
            query.queryInfo.queryWithin = queries[0].queryInfo.queryWithin;
            query.queryInfo.set("startRangeTimestamp", min);
            query.queryInfo.set("endRangeTimestamp", max);
        }
    }

    static async loadDatasetQueryInfo(queries=[]) {
        // Get a list of datasets
        const datasetIds = queries.reduce((memo, query) => {
            if (query.hasCustomDataset) {
                memo.push(query.queryInfo.customDatasetId);
            }
            return memo;
        }, []);

        // Make sure custom datasets have been loaded
        const user = queries.firstObject.queryInfo.user;
        await user.customDatasetsPromise;
        // Custom datasets have been loaded... BUT the datasets still need to load `queryInfo` that contains the `available_timestamp_fields` with the actual min/max times used by `query.earliestTimestamp` and `query.latestTimestamp`
        let promises = user.customDatasets.reduce((memo, dataset) => {
            if (datasetIds.includes(dataset.id) && !dataset.queryInfo) {
                memo.push(dataset.queryInfoPromise);
            }
            return memo;
        }, []);
        // Wait for all datasets to load `queryInfo`.
        await Promise.all(promises);

        return datasetIds;
    }

    static setDefaultSocialDateRange(queries=[], changeQueryWithin=false, onlySetRange=false) {
        if (queries.content) {
            queries = queries.content;
        }
        for (const query of queries) {
            const startRangeTimestamp = query.queryInfo.user?.permissions?.historicalAccessTimestamp || AtlasConfig.EarliestAtlasTimestamp;
            if (!onlySetRange) {
                query.queryInfo.startDate.loadJSON("3 Months Ago");
                query.queryInfo.endDate.loadJSON("Now");
            }
            query.queryInfo.set("startRangeTimestamp", startRangeTimestamp);
            query.queryInfo.set("endRangeTimestamp", Date.now());
            if (changeQueryWithin) {
                query.queryInfo.queryWithin = "";
            }
        }
    }

    static getCustomDatasetTimestampRange(queries=[]) {
        let start = Infinity;
        let end = -Infinity;
        for (const query of queries) {
            if (!query.hasCustomDataset) {
                // we only care about the date range of custom datasets
                continue;
            }
            const queryStart = query.earliestTimestamp.getTime();
            if (queryStart < start) {
                start = queryStart;
            }
            const queryEnd = query.latestTimestamp.getTime();
            if (queryEnd > end) {
                end = queryEnd;
            }
        }
        return [start, end];
    }

    static async setDefaultDateRange(queries=[], onlySetRange=false) {
        if (queries.content) {
            queries = queries.content;
        }

        // Make a list of custom datasets.
        const customDatasetIds = queries.reduce((acc, query) => {
            if (query.queryInfo.customDatasetId) {
                acc.push(query.queryInfo.customDatasetId);
            }
            return acc;
        }, []);

        if (customDatasetIds.length === 0) {
            // We only have social data, default to "3 Months Ago" to "Now".
            this.setDefaultSocialDateRange(queries, false, onlySetRange);
            return;
        }

        // We have at least one custom dataset.  Make sure all datasets' `queryInfo` have time to load before we attempt to determine dates.
        await this.loadDatasetQueryInfo(queries);

        // Start by determining a min and max timestamp for the custom datasets.
        const [min, max] = this.getCustomDatasetTimestampRange(queries);

        if (customDatasetIds.length === queries.length) {
            // Custom datasets only.  Use the min and max dates from the custom datasets.
            for (const query of queries) {
                if (!onlySetRange) {
                    query.queryInfo.startDate.set("isRelative", false);
                    query.queryInfo.startDate.fixed.fromTimestamp(min);
                    query.queryInfo.startDate.set("isDirty", false);

                    query.queryInfo.endDate.set("isRelative", false);
                    query.queryInfo.endDate.fixed.fromTimestamp(max);
                    query.queryInfo.endDate.set("isDirty", false);
                }
                query.queryInfo.set("startRangeTimestamp", min);
                query.queryInfo.set("endRangeTimestamp", max);
            }
        } else {
            // Mixed custom and social datasets.
            // The start time cannot circumvent the permissions for the historical access timestamp.
            const start = Math.max(min, queries[0].queryInfo.user?.permissions?.historicalAccessTimestamp || AtlasConfig.EarliestAtlasTimestamp);
            for (const query of queries) {
                if (!onlySetRange) {
                    query.queryInfo.startDate.set("isRelative", false);
                    query.queryInfo.startDate.fixed.fromTimestamp(start);
                    query.queryInfo.startDate.set("isDirty", false);
                }
                query.queryInfo.set("startRangeTimestamp", queries[0].queryInfo.user?.permissions?.historicalAccessTimestamp || AtlasConfig.EarliestAtlasTimestamp);
                
                if (max < start && !onlySetRange) {
                    // The custom dataset is outside of the social dataset range.  The end date 
                    // should be expanded to include social data.
                    query.queryInfo.endDate.set("isRelative", true);
                    query.queryInfo.endDate.loadJSON("Now");
                } else if (!onlySetRange) {
                    // The custom dataset is inside the social dataset range.  The end date should 
                    // be based on the custom dataset end.
                    const maxDate = new Date(max);
                    const daysDiff = Date.now() - maxDate;
                    const isSameDay = Math.abs(daysDiff) < (1000 * 60 * 60 * 24);
                    if (isSameDay) {
                        // If the end date is within a day of "Now", just use "Now".
                        query.queryInfo.endDate.set("isRelative", true);
                        query.queryInfo.endDate.loadJSON("Now");
                    } else {
                        // Otherwise, use the max custom dataset explicitly.
                        query.queryInfo.endDate.set("isRelative", false);
                        query.queryInfo.endDate.fixed.fromTimestamp(max);
                    }
                }
                // Allow the user to expand the end date to "Now" if they want to see more social 
                // data past to their custom dataset.
                query.queryInfo.set("endRangeTimestamp", Date.now());
            }
        }
    }

    // Used to determine the min and max dates selectable in the query date selector.
    static async getTimestampRange(queries=[]) {
        if (queries.content) {
            queries = queries.content;
        }

        const customDatasetCount = queries.reduce((acc, query) => {
            return query.queryInfo.customDatasetId ? acc+1 : acc;
        }, 0);

        if (customDatasetCount === 0) {
            // social only
            return [
                queries[0].queryInfo.user?.permissions?.historicalAccessTimestamp || AtlasConfig.EarliestAtlasTimestamp,
                Date.now()
            ]
        }
        
        // We have at least one custom dataset.  Make sure all datasets' `queryInfo` have time to load before we attempt to determine dates.
        await this.loadDatasetQueryInfo(queries);

        if (customDatasetCount === queries.length) {
            // Custom datasets only.
            return this.getCustomDatasetTimestampRange(queries);
        } else {
            // Mixed datasets.
            return [
                queries[0].queryInfo.user?.permissions?.historicalAccessTimestamp || AtlasConfig.EarliestAtlasTimestamp,
                Date.now()
            ]
        }
    }
}