import Ember from "ember";

export default Ember.Component.extend({
    queries: null,
    api: "themes",

    combinedSampleInfo: Ember.computed("queries", "queries.@each.updated", function () {
        var queries = this.queries || [],
            api = this.api || "themes",
            isSingleQuery = this.get("queries.length") === 1,
            isLoaded = !queries.find(q => !q.get(`${api}.isLoaded`));

        return isLoaded && queries.reduce((memo, query)=> {
            memo.count += query.get(`${api}.meta.linguisticsCount`) || 0;
            memo.pct += (isSingleQuery && query.get(`${api}.meta.linguisticsSample`)) || 0;
            return memo;
        }, { count: 0, pct: 0 });
    })
});
