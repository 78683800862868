import Ember from 'ember';
import Component from "@ember/component";
import { computed, action } from '@ember/object';
import HelpStrings from "infegy-frontend/static_data/help-strings";
import { tracked } from '@glimmer/tracking';
export default class extends Component {
    classNameBindings = [":query-builder-filter-row"];

    filter = null;
    query = null;
    allFilterOptions = null;

    datasetId = null;

    showApplyAll = false;
    isCompact = false;

    opts = [];

    @tracked showRangeOptions = true;

    booleanOptions = [{
            label: "True, Yes, Positive, Enabled, 1",
            value: true
        }, {
            label: "False, No, Negative, Disabled, 0",
            value: false
        }, {
            label: "Empty, Unanswered, Null, Undefined",
            value: "null"
        }];

    @computed("filter.rangeOptions")
    get rangeFilterRangeOptions() {
        let formattedRangeOptions;
        if (this.filter.rangeOptions) {
            formattedRangeOptions = this.filter.rangeOptions.map(option => (
                {
                    title: option.name,
                    value: `${option.min}-${option.max}`,
                    min: option.min,
                    max: option.max
                }
            ));
        }
        return formattedRangeOptions;
    }

    @computed("filter.min", "filter.max")
    get selectedRangeOption() {
        return `${this.filter.min}-${this.filter.max}`
    }

    @computed("filter.booleanValue")
    get booleanValue() {
        return this.filter.booleanValue === null ? "null" : this.filter.booleanValue;
    }

    @computed("filter.upgradable", "knownValues")
    get isEmptyAndUpgradable() {
        return this.filter?.upgradable && !this.knownValues?.length;
    }

    @computed("filter.id", "allFilterOptions", "allFilterOptions.[]")
    get filterInfo() {
        let filterID = this.filter?.id,
            allFilterOptions = this.allFilterOptions || [];
        return allFilterOptions.findBy("id", filterID);
    }

    @computed("filter.type", "knownValues")
    get isListFilter() {
        return this.knownValues && (this.filter.type === "keyword" || this.filter.type === "keywords");
    }

    @computed("filter.type", "knownValues")
    get isKeywordListFilter() {
        return !this.datasetId && !this.knownValues && this.filter.id !== 'audience_segment' 
            && (this.filter.type === "keyword" || this.filter.type === "keywords");
    }

    @computed("filter.type")
    get isBooleanFilter() {
        return this.filter.type === "boolean";
    }

    @computed("isListFilter", "knownValues")
    get isAutoCompleteFilter() {
        return this.datasetId && !this.knownValues && (this.filter.type === "keyword" || this.filter.type === "keywords");
    }

    @computed("filter.type")
    get isRangeFilter() {
        let filterType = this.filter.type;
        return filterType === "integer" || filterType === "decimal";
    }

    @computed("filter.rangeOptions")
    get hasRangeOptions() {
        return this.filter.rangeOptions ? true : false;
    }

    @computed("filter.type")
    get isTextFilter() {
        let filterType = this.filter.type;
        return filterType === "text";
    }

    @computed("filterInfo.min", "filterInfo.max")
    get numericRange() {
        let min = this.filterInfo?.min,
            max = this.filterInfo?.max;

        return {
            min: Ember.isEmpty(min) ? -Infinity : min,
            max: Ember.isEmpty(max) ? Infinity : max
        };
    }

    @computed("filter.id", "filterInfo.knownValues", "filterInfo.allowsNull")
    get knownValues(){
        let knownOptions = this.filterInfo && (this.filterInfo.knownValues || this.filterInfo.options),
            allowsNull = this.filterInfo?.allowsNull || this.filterInfo?.allows_null;
        if (Ember.isEmpty(knownOptions)) {
            return;
        }
        let options = knownOptions.map(value => {
            if (typeof(value) === "string") {
                return { label: value, value: value };
            } else if (typeof(value) === "object") {
                return { label: value.name, value: value.id };
            } else {
                console.error("unknown value format");
            }
        });

        if (Ember.isEmpty(options)) {
            return null;
        }

        if (allowsNull) {
            options.push({label:"Null or Unknown", value: this.filter.nullString})
        }

        return options;
    }

    @computed("filter.values")
    get selectedValues() {
        let values = this.filter?.values || [];
        values = values.map(v => { return v === null ? this.filter.nullString : v; });
        return values;
    }

    @computed("filter.values")
    get termValues() {
        let values = this.filter?.values || [];
        values = values.map(v => { return v === null ? this.filter.nullString : v; });
        return values;
    }

    @computed("selectedValues")
    get filterOptions() {
        return (this.selectedValues || []).map(value => {
            return { label: value, value: value };
        });
    }

    @computed("selectedFilter", "selectedFitler.canExclude")
    get canExclude() {
        var selectedFilter = this.selectedFilter;
        return selectedFilter && selectedFilter.canExclude;
    }

    @computed("selectedFilter.filter.isExclude", "selectedFilterType")
    get selectedExcludeValue() {
        return this.selectedFilter?.filter?.isExclude;
    }
    set selectedExcludeValue(value) {
        value = value ? true : null;
        this.set("selectedFilter.filter.isExclude", value);
        return value;
    }

    @computed("selectedExcludeValue")
    get selectedExcludeOption() {
        return this.selectedExcludeValue ? "exclude" : "include";
    }

    @computed("selectedFilterType")
    get description() {
        var selectedFilterType = this.selectedFilterType;
        return HelpStrings.filters[selectedFilterType];
    }

    @computed("selectedFilterType")
    get unknownLabel() {
        const singularForms = {
            dictionaries: "Dictionary",
            audienceSegments: "Audience Segment",
            subquerySets: "Subquery Set"
        };
        return `Unknown ${singularForms[this.selectedFilterType]}`;
    }

    removeFilter() { /* empty for action */ }
    applyFilterToAll() { /* empty for action */ }

    @action
    booleanValueChanged(value) {
        this.set("filter.booleanValue", value === "null" ? null : value);
    }

    @action
    willRemoveFilter() {
        this.removeFilter(this.filter);
    }

    @action
    toggleExclude(value) {
        this.set("filter.isExcluded", value);
    }

    @action
    selectedOptionsChanged(options) {
        let values = options.mapBy("value");
        this.set("filter.values", values);
    }

    @action
    selectedValuesChanged(values) {
        this.set("filter.values", values);
    }

    @action
    selectedTermsChanged(terms) {
        const trimmedTerms = terms.map(t => t.trim())
        this.set("filter.values", trimmedTerms);
    }

    @action
    textValueChanged(value) {
        this.set("filter.textValue", value);
    }

    @action
    onApplyFilterToAll() {
        this.applyFilterToAll(this.filter, this.query);
    }

    @action
    rangeDropdownChanged(value) {
        const matchedRangeOption = this.rangeFilterRangeOptions.find(range => {
            return value === range.value;
        });

        this.set("filter.min", matchedRangeOption.min);
        this.set("filter.max", matchedRangeOption.max);
    }
}
