import Component from '@ember/component';
import {computed, action} from "@ember/object";
import classic from "ember-classic-decorator";

@classic
export default class OverviewComparisons extends Component {
    user = null;
    queries = null;

    viewType = "table";

    axisChartFields = null;
    tableFields = null;

    @action
    viewTypeChanging(viewType) {
        this.viewTypeChanged(viewType);
    };
    viewTypeChanged() { /* action */ };

    @action
    axisChartFieldsChanging(fields) {
        this.axisChartFieldsChanged(fields);
    }
    axisChartFieldsChanged() { /* action */ }

    @action
    tableFieldsChanging(fields) {
        this.tableFieldsChanged(fields);
    }
    tableFieldsChanged() { /* action */ }
};
