import Component from "@ember/component";
import { action, computed } from "@ember/object";
import { alias } from '@ember/object/computed';
import classic from "ember-classic-decorator";

import SharedResources from "infegy-frontend/models/users/shared_resources";

@classic
export default class FiltersetComponent extends Component {
  @alias("filterset.isDeleted") isDeleted;

  user = null;

  filterset = null;

  isShowingDetails = false;
  isShowingSharingDetails = false;

  deleteFilterset() {};
  undoDeleteFilterset() {};

  _sharedResources = null;
  @computed("filterset")
  get sharedResources() {
    if (!this._sharedResources) {
      this._sharedResources = SharedResources.create();
      this._sharedResources.getForResource("filterset", this.filterset.id);
    }
    return this._sharedResources;
  }

  @computed.readOnly("filterset.isShared") isShared;

  @computed("filterset.queryInfo.filter.length")
  get filtersets_and_modifiers() {
    let filtersetJSON = this.filterset.queryInfo.toJSON({fieldGroups: "filterset"});
    if (!filtersetJSON)
      return [];
    
    let filters = [];
    if (filtersetJSON.filters && filtersetJSON.filters.length > 0) {
      filters = filtersetJSON.filters;
      delete filtersetJSON.filters;
      delete filtersetJSON.filter;
      delete filtersetJSON.exclude;
    }
    for (const key in filtersetJSON) {
      filters.push({
        name: key.replaceAll("_", " "),
        values: filtersetJSON[key]
      });
    }
    return filters;
  }

  @computed("filterset.queryInfo")
  get filterParts() {
    return this.get("filterset.queryInfo.filters");
  }

  @computed("filterset.queryInfo")
  get filterOptions() {
    return this.get("filterset.queryInfo.datasetQueryInfo.filters");
  }

  @action
  onDeleteFilterset() {
    this.filterset.set('isDeleted', true);
    this.filterset.delete();
  }

  @action
  onUndoDeleteFilterset() {
    this.filterset.set('isDeleted', false);
    this.filterset.undoDelete();
  }

  @action
  onShowOptions() {
    this.toggleProperty("isShowingDetails");
  }

  @action
  onToggleShowSharingDetails() {
    this.toggleProperty("isShowingSharingDetails");
  }

  @action
  onSharingDeclined() {
    this.user.loadFiltersets();
  }
}
