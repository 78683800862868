import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import JsonStore from "infegy-frontend/json-store/model";
import Prop from "infegy-frontend/json-store/properties/model";

export default class DictionaryEntry extends JsonStore {
    @Prop.Int() id;
    @Prop.Int() dictionaryId;
    @Prop.String() word;
    @Prop.Number() sentiment;
    @Prop.String() theme;
    @Prop.String() emotion;
    @Prop.Int() exact;
    @Prop.String() language;
    @Prop.String() created;
    @Prop.Int() deleted;

    async create() {
        let body = this._buildRequestBody();

        return AtlasAuth.Post({
            url: "api/v3/dictionaries/entries.json",
            data: body
        }).then((response) => {
            this.loadJSON(response.output[0]);
        });
    }

    async update(entryData) {
        let body = this._buildRequestBody();
        Object.assign(body, entryData);

        return AtlasAuth.Put({
            url: "api/v3/dictionaries/entries.json",
            data: body
        }).then((response) => {
            this.loadJSON(response.output[0]);
            // `loadJSON` ignores empty string values, so set them manually if applicable
            if (entryData.theme === "") {
                this.set("theme", "");
            }
            if (entryData.emotion === "") {
                this.set("emotion", "");
            }
        });
    }

    async delete() {
        return AtlasAuth.Delete("api/v3/dictionaries/entries.json"
            + `?dictionary_id=${this.dictionaryId}`
            + `&word=${encodeURIComponent(this.word)}`);
    }

    _buildRequestBody() {
        let body = {
            dictionary_id: this.dictionaryId,
            exact: this.exact,
            language: this.language,
            sentiment: this.sentiment,
            word: this.word
        };

        if (this.emotion) {
            body.emotion = this.emotion;
        }
        if (this.id) {
            body.id = this.id;
        }
        if (this.theme) {
            body.theme = this.theme;
        }

        return body;
    }
}