import Component from '@glimmer/component';
import { action } from '@ember/object';
import { actionArgument, argument } from "infegy-frontend/decorators/arguments";
import { tracked } from '@glimmer/tracking';

export default class extends Component {
    @argument([]) snippetOptions;
    @actionArgument return;
    @actionArgument applySnippet;

    @tracked selectedSnippet = null;

    @action
    applySelectedSnippet(){
        let snippet = this.snippetOptions.find((option) => option.id === this.selectedSnippet)
        this.applySnippet(snippet);
        this.return();
    }
}