import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";
import DataSeriesComputedFieldDistribution from "infegy-frontend/models/data_series/data_series_computed_field_distribution";

export default QueryAPIResults.extend({
    title: "gender",
    distributions: [{
        id: "count",
        label: "Count",
        fields: [
            { label: "Female", value: "femaleCountDistribution" },
            { label: "Male", value: "maleCountDistribution" },
            { label: "Non-binary", value: "nonBinaryCountDistribution" },
        ]
    }, {
        id: "universe",
        label: "Universe",
        fields: [
            { label: "Female", value: "femaleUniverseDistribution" },
            { label: "Male", value: "maleUniverseDistribution" },
            { label: "Non-binary", value: "nonBinaryUniverseDistribution" },
        ]
    }],
    description: DataSeriesDescription.load({
        "date": "datestring",
        "timestamp": "timestamp",

        "femaleCount": "number",
        "maleCount": "number",
        "nonBinaryCount": "number",

        "femaleUniverse": "number",
        "maleUniverse": "number",
        "nonBinaryUniverse": "number",

        "maleRaw": "number",
        "femaleRaw": "number",
        "nonBinaryRaw": "number",
    }, [
        DataSeriesComputedFieldDistribution.fieldSetup(["femaleCount", "maleCount", "nonBinaryCount"]),
        DataSeriesComputedFieldDistribution.fieldSetup(["femaleUniverse", "maleUniverse", "nonBinaryUniverse"]),
    ])
});
