import Ember from 'ember';
import Component from '@ember/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import QueryUtil from 'infegy-frontend/models/queries/query_util';
/*
    This is gross, if you don't import this file, it won't setup the atlas mode in codemirror and nothing will syntax highlight
*/
import CM from "infegy-frontend/utils/codemirror_query";

export default class extends Component {
    classNameBindings = [":cm-s-atlas", ":cm-atlas-viewer", ":cm-atlas-container"];
    tagName = 'textarea';
    valueChanged = () => { /* action */ };
    cursorChanged = () => { /* action */ };

    // options = {};

    @service() codeMirror;

    didInsertElement() {
        this._super(...arguments);

        this._codeMirror = this.codeMirror.fromTextArea(this.elementId, this.element);
        this._codeMirror.setOption("extraKeys", {
            Tab: false,
            Enter:()=>{ this.enterPressed(); }
        }); // Don't insert tabs, use default browser behavior

        // Send a "valueChanged" action when CodeMirror triggers a "change" event.
        this.setupCodeMirrorEventHandler('change', this, this.scheduleValueChangedAction);
        this.setupCodeMirrorEventHandler('cursorActivity', this, this.scheduleCursorChangedAction);
        this.setupCodeMirrorEventHandler('beforeChange', this, this.preventNewline);
    }

    preventNewline(instance, change){
        // Prevent newlines and tabs
        var newtext = QueryUtil.getQuerySafeString(change.text.join(""));

        if (typeof change.update !== "undefined") {
            change.update(change.from, change.to, [newtext]);
        }

        return true;
    }

    didRender() {
        this._super(...arguments);
        Ember.run.scheduleOnce('afterRender', ()=>{
            this.updateCodeMirrorOptions();
            this.updateCodeMirrorValue();
        }, 5);

    }

    scheduleValueChangedAction(codeMirror, changeObj) {
        Ember.run.once(this, this.sendValueChangedAction, codeMirror.getValue(), codeMirror, changeObj);
    }

    scheduleCursorChangedAction(...args) {
        Ember.run.once(this, this.sendCursorChangedAction, ...args);
    }

    setupCodeMirrorEventHandler(event, target, method) {
        const callback = Ember.run.bind(target, method);

        this._codeMirror.on(event, callback);

        this.one('willDestroyElement', this, function() {
            this._codeMirror.off(event, callback);
        });
    }

    sendValueChangedAction(...args) {
        this.send('valueChanged', ...args);
    }

    sendCursorChangedAction(...args) {
        this.send('cursorChanged', this._codeMirror.getCursor());
    }

    toggleVisibility() {
        const isVisible = this.isVisible;

        if (this._wasVisible === isVisible) {
            return;
        }

        this._wasVisible = isVisible;

        if (isVisible) {
            // Force a refresh when becoming visible, since CodeMirror won't render
            // itself onto a hidden element.
            this._codeMirror.refresh();
        }
    }

    updateCodeMirrorOption(option, value) {
        if (this._codeMirror && this._codeMirror.getOption(option) !== value) {
            this._codeMirror.setOption(option, value);
        }
    }

    updateCodeMirrorOptions() {
        let options = this.options;
        if (options) {
            Object.keys(options).forEach(function(option) {
                this.updateCodeMirrorOption(option, options[option]);
            }, this);
        }
    }

    updateCodeMirrorValue() {
        const value = this.value;

        if (this._codeMirror && value !== this._codeMirror.getValue()) {
            this._codeMirror.setValue(value || '');
        }
    }

    willDestroyElement() {
        this._super(...arguments);
        // Remove the editor and restore the original textarea.
        this._codeMirror.toTextArea();

        this.codeMirror.unregisterInstance(this.elementId);

        delete this._codeMirror;
    }

    @action
    valueChanged(...args){
        this.valueChanged(...args);
    }

    @action
    cursorChanged(cursor){
        this.cursorChanged(cursor);
    }
}
