import JsonStore from 'infegy-frontend/json-store/model';
import Prop from 'infegy-frontend/json-store/properties/model';

var AdminUserErrorModel = JsonStore.extend({
    endpoint: Prop.Attr(),
    time: Prop.Attr(),
    format: Prop.Attr(),
    ip: Prop.Attr(),
    error: Prop.Attr(),
    request: Prop.Attr(),
    referer: Prop.Attr(),
    queries: Prop.Attr(),
    errorCode: Prop.Attr(),
    id: Prop.Attr(),
    apiVersion: Prop.Attr(),
    userAgent: Prop.Attr(),

    user: Prop.Attr()
});

export default AdminUserErrorModel;
