import SingleRequestQuery from "infegy-frontend/models/queries/single_request_query";
import Ember from "ember";

export default {
    Query: function (options) {
        return Ember.computed({
            get: function(key) {
                var data = this._data;
                var object = data[key];
                if (!object) {
                    object = SingleRequestQuery.create();
                    object.set("parentObject", this);
                    data[key] = object;
                }
                return object;
            },
            set: function (key, value) {
                console.error("Cannot set JsonStore Object property.");
            }
        });
    }
};
