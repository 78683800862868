import Ember from "ember";

export default Ember.Component.extend({
    classNames: ["atlas-loader"],
    showFullError: true,
    query: null,
    queries: null,
    api: null,
    apis: null,
    apiModelsOverride: null,

    isCompact: false,
    isInline: false,

    erroredQueries: Ember.computed.alias("apiErrors.errorQueries"),
    noDataQueries: Ember.computed.alias("apiErrors.noDataQueries"),

    innerQueries: Ember.computed("query", "queries", function() {
        if(!this.queries){
            var query = this.query;
            return query && [query] || [];
        } else {
            return this.queries;
        }
    }),

    innerApis: Ember.computed("api", "apis", function() {
        if(!this.apis){
            var api = this.api;
            return api && [api] || [];
        } else {
            return this.apis;
        }
    }),

    _mappedAPIs: null,
    mappedAPIs: Ember.computed("apiModelsOverride", "innerApis","innerQueries","_mappedAPIs",{
        get() {
            if (this.apiModelsOverride) {
                return this.apiModelsOverride;
            }
            if(!Ember.isNone(this._mappedAPIs))
                return this._mappedAPIs;
            var apis = this.innerApis,
                queries = this.innerQueries,
                mappedAPIs = [];
            if (!apis || !queries) {
              return;
            }
            apis.filter(api => api).forEach((api)=>{
                mappedAPIs = mappedAPIs.concat(queries.map((q) => Ember.get(q,api) ));
            });
            return mappedAPIs;
        }, set(key,customMap) {
            this.set("_mappedAPIs", customMap);
            return customMap;
        }
    }),

    // template flags
    isInline: false,
    isLoading: Ember.computed("remainingToLoad", "hasErrors", function() {
        return this.remainingToLoad > 0 && !this.hasErrors;
    }),

    isEmpty: null,
    _isEmpty: Ember.computed("apiErrors.noDataQueries", function(){
        return !Ember.isEmpty(this.get("apiErrors.noDataQueries"));
    }),
    showIsEmptyWarning: Ember.computed("_isEmpty","isEmpty", function(){
        if(!Ember.isEmpty(this.isEmpty)){
            return this.isEmpty;
        }
        return this._isEmpty;
    }),

    hasErrors: Ember.computed.notEmpty("apiErrors.errorQueries"),

    isLoaded: Ember.computed("isLoading", "isEmpty", "hasErrors", "remainingToLoad",
        "innerQueries", "innerQueries.[]", "mappedAPIs", function() {
        var isLoading = this.isLoading,
            remainingToLoad = this.remainingToLoad;

        return !this.get("innerQueries.length") || !this.get("mappedAPIs.length") || (remainingToLoad === 0 && !isLoading);
    }),

    numLoaded: Ember.computed("mappedAPIs", "mappedApis.@each.isLoaded", "mappedAPIs.@each.updated", function() {
        let mappedAPIs = this.mappedAPIs || [];

        // iterate each api, checking if it is loaded for each query
        return mappedAPIs.reduce((pv,currentAPI)=>{
            if(currentAPI && Ember.get(currentAPI, "isLoaded")){
                pv++;
            }
            return pv;
        },0);
    }),

    remainingToLoad: Ember.computed("mappedAPIs.length", "numLoaded", function() {
        let numMappedAPIs = this.get("mappedAPIs.length"),
            numLoaded = this.numLoaded;
        return numMappedAPIs - numLoaded;
    }),

    apiErrors: Ember.computed("mappedAPIs.@each.hasError","mappedAPIs.@each.isEmpty", function() {
        let mappedAPIs = this.mappedAPIs || [],
            errorQueries = [],
            noDataQueries = [];

        mappedAPIs.forEach((api) => {
            var apiQuery = api && api.get("query");

            if (api && api.get("hasError")) {
                errorQueries.push(apiQuery);
            } else if (api && api.get("isEmpty")) {
                noDataQueries.push(apiQuery);
            }
        });

        return {
            errorQueries: errorQueries.filter(q => q?.title).uniqBy("title"),
            noDataQueries: noDataQueries.filter(q => q?.title).uniqBy("title")
        };
    }),

    uniqueQueryErrors: Ember.computed("erroredQueries.[]", "erroredQueries.@each.errors", function() {
        var queries = this.erroredQueries ?? [];

        return queries.reduce((uniqueErrors, query) => {
            if(Ember.isArray(query.errors)){
                query.errors.forEach((error) => {
                    var isUnique = !uniqueErrors.find((uniqueError) => {
                        return uniqueError.code === error.code || uniqueError.errorText === error.errorText;
                    });
                    if(isUnique) {
                        uniqueErrors.push(error);
                    }
                });
            }
            return uniqueErrors;
        },[]);
    }),

    actions: {
        toggleShowError() {
            this.toggleProperty("showFullError");
        }
    }
});
