import Ember from "ember";
import {computed, action} from "@ember/object";
import QueryColor from "infegy-frontend/models/queries/query_color";


export default class QueryColorSelector extends Ember.Component {
    // Arguments
    query = null; // the query whose color we're setting
    queries = null; // all queries to check for duplicate colors
    close = () => { /* action */ }
    submit = (queryColor)  => { /* action */ };

    // Properties
    isConfirmingColor = false;
    unconfirmedColor = null;
    matchingQuery = null;
    innerSelectedColor = QueryColor.create();

    init(){
        super.init(...arguments);
        this.innerSelectedColor.loadJSON(this.get("query.color").toJSON());
    }

    // Actions
    @action
    updateInnerSelectedColor(newColor) {
        this.innerSelectedColor.loadJSON(newColor);
    }

    @action
    confirmQueryColor() {
        var foundQuery = this.queries.findBy("color.hex", this.innerSelectedColor.hex);
        if (foundQuery && foundQuery !== this.query) {
            // show confirm dialog
            this.setProperties({
                isConfirmingColor: true,
                matchingQuery: foundQuery
            });
        } else {
            // No matching queries, just go ahead and set
            this.submit(this.innerSelectedColor.toJSON());
            this.close();
        }
    }

    @action
    swapQueryColors() {
        var matchingQueryColor = this.get("matchingQuery.color"),
            matchingColorJSON = matchingQueryColor.toJSON(),
            currentQueryColor = this.get("query.color");
        // assign matching query the color previously assigned to this query
        matchingQueryColor.loadJSON(currentQueryColor.toJSON());
        // assign current query the selected color
        currentQueryColor.loadJSON(matchingColorJSON);
        this.close();
    }

    @action
    cancel() {
        this.close();
    }
}