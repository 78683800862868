import Ember from "ember";

export default {
    clean: function (queryString) {
        var newQueryString = queryString.replace(/[\n\r\t\0]/g, " ");
        newQueryString = newQueryString.replace(/[\u201C\u201D\u201E\u201F\u2033\u2036]/g, '"');
        newQueryString = newQueryString.replace(/[\u2018\u2019\u201A\u201B\u2032\u2035]/g, "'");
        newQueryString = newQueryString.trim();
        return newQueryString;
    },
    validate: function(queryString) {
        if (queryString.length > 2) {
            return true;
        }
    }
};
