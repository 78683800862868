import Ember from "ember";
import Component from "@ember/component";
import { action, computed } from "@ember/object";

export default class QuerySetList extends Component {
    querySets = [];
    isAdmin = false;

    copyingQuerySet = null;
    isCreatingQuerySet = false;

    newQuerySetName = "";

    pageSize = 10;

    sortField = "modified";

    filterFieldOptions = [
        {
            label: "Name",
            value: "name"
        }
    ];

    sortFieldOptions = [
        {
            label: "Name",
            value: "name"
        },
        {
            label: "Recently Used",
            value: "modified"
        },
        {
            label: "Created Date",
            value: "created"
        }
    ];

    @computed("querySets", "querySets.isLoading")
    get isLoading() {
        if (Ember.isNone(this.querySets)) {
            return true;
        }
        return this.querySets.isLoading;
    }

    @computed("newQuerySetName")
    get isNewQuerySetNameBlank() {
        return Ember.isEmpty(this.newQuerySetName);
    }

    // events
    copyQuerySet = (querySet, newName) => {};
    createQuerySet = (name) => {};
    declineSharing = (querySet) => {};
    deleteQuerySet = (querySet) => {};
    editQuerySet = (querySet) => {};
    sharingDeclined = (querySet) => {};

    @action
    onCancelCreateQuerySet() {
        this.setProperties({
            isCreatingQuerySet: false,
            newQuerySetName: ""
        });
    }

    @action
    onCopyQuerySet(querySet, newName) {
        this.copyQuerySet(querySet, newName);
    }

    @action
    onCreateQuerySet(name) {
        this.createQuerySet(name);
    }

    @action
    onDeleteQuerySet(querySet) {
        this.deleteQuerySet(querySet);
    }

    @action
    onEditQuerySet(querySet) {
        this.editQuerySet(querySet);
    }

    @action
    onSharingDeclined(querySet) {
        this.sharingDeclined(querySet);
    }
};