import Ember from "ember";
import DefaultErrorDefinitions from "infegy-frontend/static_data/query/default_error_definitions";

export default Ember.Component.extend({
    errorInfo: null,
    apiError: true,
    errorDefinitions: DefaultErrorDefinitions,
    classNameBindings: ["queryLink:api-query-error",
        "supporLink:api-server-error"
    ],

    error: Ember.computed("errorInfo", "errorInfo.code", function() {
        var errorDefinitions = this.errorDefinitions,
            errorInfoCode = this.get("errorInfo.code");

        var errorDefinition = errorDefinitions.findBy("code", errorInfoCode);

        return errorDefinition;
    }),

    errorType: Ember.computed("errorInfo", {
        get: function(key) {
            var errorDefinitions = this.errorDefinitions,
                message = "An error has occured.";

            errorDefinitions.forEach((definition) => {
                if (this.get("errorInfo.code") === definition.code) {
                    message = definition.title;
                }
            });

            return message;
        },
    }),

    errorResponse: Ember.computed.alias("errorInfo.response"),
    errorText: Ember.computed.alias("errorInfo.errorText"),
    errorMessage: Ember.computed("errorText", "errorResponse", {
        get: function() {
            var errorText = this.errorText,
                errorResponse = this.errorResponse;

            return errorText ? errorText : errorResponse;
        }
    }),
    setup: Ember.on("init",function() {
        var errorCode = this.get("errorInfo.code");

        if (errorCode && errorCode === 403) {
            this.redirectToLogin();
        }
    }),

    displaySupportRequest: () => {},
    redirectToLogin: () => {},
    actions: {
        displaySupportRequest: function() {
            this.displaySupportRequest();
        },
        redirectToLogin: function() {
            this.redirectToLogin();
        }
    }
});
