import Ember from 'ember';
import DateFilters from "infegy-frontend/static_data/query/date_filters";
import AtlasConfig from "infegy-frontend/config/infegy-app-config";

export default Ember.Component.extend({
    classNames: ["row", "query-date-range"],

    earliestTimestamp: AtlasConfig.EarliestAtlasTimestamp,
    latestTimestamp: null, // if no latest date is set, the current date should be generated as late as possible

    endDate: null,
    startDate: null,

    startDateOptions: DateFilters.startDateOptions,
    endDateOptions: DateFilters.endDateOptions,

    startDateType: Ember.computed("startDate", "startDate.isRelative", "startDate.relative.isNow", {
        get: function(key) {
            var startDate = this.startDate;
            return (startDate && startDate.get("isRelative")) ? "relative" : "fixed";
        },
        set: function(key, value) {
            var startDate = this.startDate;
            if (value && startDate) {
               startDate.set("isRelative", (value !== "fixed"));
            }
            return (startDate && startDate.get("isRelative")) ? "relative" : "fixed";
        }
    }),

    endDateType: Ember.computed("endDate", "endDate.isRelative", "endDate.relative.isNow", {
        get: function(key) {
            var endDate = this.endDate;
            if (endDate && endDate.get("isRelative")) {
                return (endDate.get("relative.isNow")) ? "now" : "relative";
            }
            return "fixed";
        },
        set: function(key, value) {
            var endDate = this.endDate;
            if (value && endDate) {
                endDate.set("isRelative", (value !== "fixed"));
                endDate.set("relative.isNow", (value === "now"));
            }
            if (endDate && endDate.get("isRelative")) {
                return (endDate.get("relative.isNow")) ? "now" : "relative";
            }
            return "fixed";
        }
    }),

    datesChanged: () => {},
    actions: {
      startDateOptionsChanged(startDateType){
        this.set("startDateType", startDateType);
        this.send("dateChanged");
      },
      endDateOptionsChanged(endDateType){
        this.set("endDateType", endDateType);
        this.send("dateChanged");
      },
      dateChanged(){
          this.datesChanged(this.startDate, this.endDate);
      },
    }
});
