import Ember from 'ember';
import CSSTools from "infegy-frontend/utils/css-tools";

/**
 * Helper to modify color values and produce a stringsafe style
 */
export function colorStyle(params, namedArgs) {
    var { color, darken, lighten, property } = namedArgs;
    color = color || params[0];
    property = property || 'color';
    if (color) {
        var colorString;
        if (Ember.typeOf(color) === 'string'){
            colorString = color;
        } else {
            colorString = color.colorString;
            if (darken) {
                colorString = color.darkColorString;
            } else if (lighten) {
                colorString = color.lightColorString;
            }
        }
        var fieldMap = {};
        fieldMap[property] = colorString;

        return CSSTools.buildStringFromObjectSafe(fieldMap);
    }
    return Ember.String.htmlSafe("");
}

export default Ember.Helper.helper(colorStyle);
