import Ember from "ember";

export default Ember.Component.extend({
    classNameBindings:[":query-builder-item","itemClass","isActive:active"],
    item:null,
    index:null,
    isActive:false,
    canRemove:false,
    itemClass: Ember.computed("item.type",function(){
        var itemType = this.get("item.type");
        return Ember.String.htmlSafe(`query-builder-${itemType}-item`);
    }),
    helpTextId: Ember.computed("item.type",function(){
        var type = this.get("item.iconType");
        return `queryBuilder.${type}`;
    }),

    click:function(event){
        this.toggleProperty("isActive");
        event.stopImmediatePropagation();
    },

    removeItem: () => {},
    actions: {
        removeItem(){
            this.removeItem(this.index);
        }
    }
});
