import Component from "@ember/component";
import { computed, action } from '@ember/object';
import classic from "ember-classic-decorator";

@classic
export default class extends Component {
    row = null;
    column = null;
    value = null;

    @computed()
    get field() {

    }
}