import Component from "@ember/component";
import classic from "ember-classic-decorator";
import EmberObject, { action, computed } from "@ember/object";

import ChartColors from "infegy-frontend/static_data/fixtures/chart_colors";

@classic
export default class extends Component {
    classNames = ["workspace-main"];
    workspace = null;
    remainingQueryLimit = 6;
    selectedQueryOptions = [];
    isRunningQueries = true;

    @computed("selectedQueryOptions.length")
    get isDisabled() {
        return this.selectedQueryOptions.length === 0;
    }

    @computed("workspace.queries")
    get queryOptions() {
        const options = (this.get("workspace.queries") || []).map((query, index) => {
            return EmberObject.create({
                data: query,
                title: query.title,
                value: index
            });
        });
        return options;
    }

    selectQueries(queries) {};
    cancel() {};

    @action
    onSelectedQueriesChanged(selectedQueryOptions) {
        const queryOptions = this.queryOptions || [],
              selectedOptions = [];
        selectedQueryOptions.sort().forEach((queryIndex) => {
            if (queryOptions[queryIndex]) {
                queryOptions[queryIndex].set("colorClass", ChartColors.colorsForIndex(queryIndex).className);
                selectedOptions.addObject(queryOptions[queryIndex]);
            }
        });
        this.set("selectedQueryOptions", selectedOptions);
    }

    @action
    onSelectQueries() {
        const queryOptions = this.queryOptions || [],
              selectedQueryOptions = this.selectedQueryOptions || [];

        let selectedQueries = selectedQueryOptions.sort().map((queryIndex) => {
                return queryOptions[queryIndex.value] && Ember.get(queryOptions[queryIndex.value], "data");
            });

        this.selectQueries(selectedQueries);
    }

    onCancel() {
        this.cancel();
    }
}
