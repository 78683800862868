import Component from "@ember/component";
import classic from "ember-classic-decorator";
import { action, computed } from "@ember/object";

@classic
export default class ApiPaginationPage extends Component {
    classNameBindings = [":paginator-page-item", "isCurrent:active"];

    pageIndex = null;
    activePageIndex = null;
    
    @computed("pageIndex", "activePageIndex")
    get isCurrent() {
        return this.pageIndex === this.activePageIndex;
    }

    @computed("pageIndex")
    get pageNumber() {
        return this.pageIndex + 1;
    }

    // event handlers
    selected() {}

    @action
    onSelected() {
        this.selected(this.pageIndex);
    }
}