import Ember from 'ember';

export function truncateString(params, namedArgs) {
  var elipse = namedArgs.elipse || '...';
    var len = namedArgs.length || 25;
    var stringToModify = params[0] || '';
    stringToModify = stringToModify.replace(/^\s+|\s+$/g, "");
    stringToModify = stringToModify.replace(/(<([^>]+)>)/ig," ");
    stringToModify = stringToModify.trim(stringToModify);
    if(stringToModify.length > len + 3) {
        stringToModify = (stringToModify.substring(0, len)).trim() + elipse;
    }
    return stringToModify;
}

export default Ember.Helper.helper(truncateString);
