import Component from '@ember/component';
import { computed } from '@ember/object';
import AtlasAuth from 'infegy-frontend/utils/atlas-auth';
import QueryAPIRequestString from 'infegy-frontend/models/queries/query_api_request_string';

export default class extends Component {
    query = null;

    lastCheckedQueryURL = null;
    _queryError = null;

    @computed("_queryError", "query.updated", "query.queryString", "query.entityQuery")
    get queryError(){
        const params = QueryAPIRequestString.build(this.query, 'query-test');
        let queryURL = `api/v3/query-test?${params}`;
        if (queryURL !== this.lastCheckedQueryURL) {
            // test the query and store the result
            (async () => {
                var did_fail = false;
                try {
                    await AtlasAuth.Get(queryURL);
                } catch (e) {
                    did_fail = true;
                    if (!this.isDestroyed && !this.isDestroying) {
                        if (e.status === "error") {
                            this.set("_queryError", e.status_message);
                        } else {
                            // could be anything
                            console.error(e);
                        }
                    }
                }
                if (!this.isDestroyed && !this.isDestroying) {
                    if (!did_fail) {
                        this.set("_queryError", null);
                    }
                    this.set("lastCheckedQueryURL", queryURL);
                }
            })();
        }
        return this._queryError;
    }
}
