
import Component from '@ember/component';
import { action } from '@ember/object';
import { computed } from '@ember/object';
/**
 * @typedef ClusterTableRowArgs
 * @property {Query[]} queries
 */
export default class NarrativesTable extends Component{
    // args
    queries = [];

    clusterData;
    
    isInChart; 

    selectedClusterKeys;

    // properties
    hoveredIndex = null;
    selectedCluster = null;
    sortKey = "universe";
    sortAscending = false;
    selectedClusters=[];
    mergeClusters() {/* action */}
    
    onRemoveCluster() {/* action */}
    onRenameCluster() {/* action */}

    @computed("queries.length")
    get tableHeaders(){
        let headers = [{
            label: "Cluster Name",
            key: "name"
        }];
        if(this.queries.length > 1){
            headers.push({
                label: "Query",
                key: "_sourceQuery.queryInfo.title"
            });
        }
        return headers.concat([{
            label: "Trend",
            key: "universe"
        },{
            label: "Universe",
            key: "universe"
        },{
            label: "Reach",
            key: "reach"
        },{
            label: "Sentiment",
            key: "clusterPositiveDocumentsDistribution"
        },{
            label: "Age",
            key: "age"
        },{
            label: "Gender",
            key: "femaleDistribution"
        }]);
    }
    @computed.alias("queries.firstObject.narratives.dates") dates;
    @computed.alias("queries.firstObject.activeGrouping") grouping;

    onRemoveFromChart;
    onAddToChart;
    canAddToChart;

    @computed("tableHeaders.length", "queries.length")
    get drillDownColSpan() {
        let cols = this.tableHeaders.length + 1;
        return (this.queries?.length > 1) ? cols + 1 : cols;
    }
    
    @computed("clusterData")
    get allClusters() {
        return this.clusterData; //?.mapBy("source");
    }

    @computed("allClusters", "sortKey", "sortAscending")
    get sortedClusters() {
        if (!this.allClusters) {
            return null;
        }
        
        const sorted = this.allClusters.sort((clusterA, clusterB) => {
            let valA = clusterA.source.get(this.sortKey),
                valB = clusterB.source.get(this.sortKey);
            if(typeof valA === "string") {
                return valA.localeCompare(valB);
            }
            return valB - valA;
        });
        if(this.sortAscending){
            sorted.reverse();
        }
        return sorted;
    }

    @action sortBy(key){
        if (key === this.sortKey){
            this.set("sortAscending", !this.sortAscending);
        } else {
            this.setProperties({
                sortKey: key,
                sortAscending: false
            });
        }
    }

    @action toggleSelectedCluster(cluster){
        if (this.selectedCluster === cluster){
            this.set("selectedCluster", null);
        } else {
            this.set("selectedCluster", cluster);
        }
    }

    @action onSelectCluster(cluster){
        if (this.selectedCluster === cluster) {
            this.set("selectedCluster", null);
        } else {
            this.set("selectedCluster", cluster);
        }        
    }
}
