import Ember from "ember";
import d3 from "d3";

export default Ember.Component.extend({
    tagName: "svg",
    attributeBindings: ["x", "y", "width", "height", "viewBox"],

    viewBox: null,
    contentArea: null,

    x: Ember.computed.alias("contentArea.x"),
    y: Ember.computed.alias("contentArea.y"),


    width: Ember.computed.alias("safeWidth"),
    height: Ember.computed.alias("safeHeight"),

    safeWidth: Ember.computed("contentArea.width", function () {
        return this.get("contentArea.width") > 0 ? this.get("contentArea.width") : 1;
    }),
    safeHeight: Ember.computed("contentArea.height", function () {
        return this.get("contentArea.height") > 0 ? this.get("contentArea.height") : 1;
    }),

    offsetWidth: Ember.computed("width", "x", function () {
        return this.width + this.x;
    }),

    offsetHeight: Ember.computed("height", "x", function () {
        return this.height + this.y;
    }),

    didInsertElement: function() {
        this._super();
        this.set("el", d3.select("#" + this.element.id));
    }
});
