import Ember from "ember";
import QueryAPIs from "infegy-frontend/models/queries/query_apis";

// TODO: These should be moved out into more appropriate locations
var QueryAPIUtils = {
    byName: function(apiName) {
        return QueryAPIs && QueryAPIs.findBy("name", apiName);
    },

    pathByName: function(apiName) {
        var api = QueryAPIUtils.byName(apiName);
        return api && (api.apiPath || api.get("apiPath"));
    },

    loader: function(apiName) {
        var internalName = "_" + apiName;
        return Ember.observer("@each." + internalName, function() {
            var controller = this.get(apiName + "APIController");
            if (controller && controller.run) {
                controller.run();
            }
        });
    },

    // QueryArray
    getter: function(resultName, resultApiClass) {
        var internalName = "_" + resultName;
        var resultClass = resultApiClass;
        return Ember.computed(internalName, {
            get: function(key) {
                if (Ember.typeOf(resultClass) === 'string') {
                    console.warn("resultClass = App[resultClass.split('.')[1]];");
                }

                var apiResult = this.get(internalName);

                if (!apiResult) {
                    apiResult = resultClass.create();
                    this.set(internalName, apiResult);
                    apiResult.set("query", this);
                }
                return apiResult;
            },
        });
    },

    // QueryArray
    unimportantGetter: function(resultName) {
        var internalName = "_" + resultName;
        return Ember.computed(internalName, {
            get: function (key) {
                var apiResult = this.get(internalName);
                return apiResult;
            }
        });
    },

    // QueryApiBase
    verifyAPIFields: function (apiName, data, fields){
        // Disable verification in production builds.
        if (!data || !data.length) {
            return;
        }

        var knownFields = Object.assign({}, fields);

        var dataFields = data.reduce(function(newFields, dataRow) {
            for (var dataRowKey in dataRow) {
                if (dataRow.hasOwnProperty(dataRowKey)) {
                    if (!fields[dataRowKey]) {
                        newFields[dataRowKey] = typeof dataRow[dataRowKey];
                    }
                    if (knownFields[dataRowKey]) {
                        delete knownFields[dataRowKey];
                    }
                }
            }
            return newFields;
        }, {});

        var unrecognizedFields = [];
        for (var dataFieldsKey in dataFields) {
            if (dataFields.hasOwnProperty(dataFieldsKey)) {
                unrecognizedFields[unrecognizedFields.length] = [dataFieldsKey, dataFields[dataFieldsKey]];
            }
        }

        var unusedFields = [];
        for (var knownFieldsKey in knownFields) {
            if (knownFields.hasOwnProperty(knownFieldsKey) && knownFieldsKey !== "date") {
                unusedFields[unusedFields.length] = [knownFieldsKey, knownFields[knownFieldsKey]];
            }
        }

        if (!unrecognizedFields.length && !unusedFields.length) {
            if (window.VERBOSE) {
                console.info("No inconsistencies found for", apiName);
            }
            return;
        }

        console.groupCollapsed("Api Verification Results");

        if (unrecognizedFields.length) {
            console.info("Unrecognized fields found for ", apiName, ":");
            console.info(JSON.stringify(unrecognizedFields));
        }

        if (unusedFields.length) {
            console.info("Unused fields found for ", apiName, ":");
            console.info(JSON.stringify(unusedFields));
        }

        console.groupEnd();
    }
};

export default QueryAPIUtils;
