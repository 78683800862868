import JsonStore from "infegy-frontend/json-store/model";
import Prop from "infegy-frontend/json-store/properties/model";
import classic from "ember-classic-decorator";

@classic
export default class QualtricsSurveyQuestion extends JsonStore {
    @Prop.String() id;
    @Prop.String() type;
    @Prop.String() name;
    @Prop.String() text;
    @Prop.Collection() choiceTexts;
    @Prop.Collection() questionNumbers;
    @Prop.Collection() subquestions;
}