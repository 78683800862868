import Ember from "ember";
export default Ember.Component.extend({
    classNameBindings: ["alertClass","display:atlas-alert-container"],
    display: true,
    alert:null,
    
    alertClass: Ember.computed("alert.status",{
        get:function(){
            var status = this.get("alert.status") || "success";
            return "alert-"+status;    
        }
    }),

    actions: {
        remove: function () {
            this.set('display', false);

            var alertsSeen = JSON.parse(window.localStorage.getItem('alerts')) || [];
            alertsSeen.push(this.get('alert.id'));
            window.localStorage.setItem('alerts', JSON.stringify(alertsSeen));
        }
    },

    initRunner: Ember.on("init",function() {
        var alertsSeen = JSON.parse(window.localStorage.getItem('alerts')) || [];
        if (alertsSeen && alertsSeen.indexOf(this.get('alert.id')) !== -1) {
            this.set('display', false);
        }
    }),
});
