import Ember from "ember";
import Component from '@ember/component';
import { computed, action } from '@ember/object';

export default class FieldsScalarSelector extends Component {
    classNameBindings = ["displayAsDropdown:inline-field-select:toggle-field-select"];

    availableFields = null;

    valueField = "value";
    labelField = "title";

    selectedValue = null;
    selectedValues = null;

    displayAsDropdown = false;
    maxFieldSelection = 6;

    selectionChanged() {/* action */};
    @action
    selectionChanging(scalarField) {
        this.selectionChanged(scalarField);
    }

    selectionsChanged() {/* action */};
    @action
    selectionsChanging(scalarField) {
        this.selectionsChanged(scalarField);
    }
};
