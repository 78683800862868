import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";
import fieldTimelineGrowth from "infegy-frontend/models/data_series/data_series_computed_field_growth";

export default QueryAPIResults.extend({
    title : "postInterests",
    dateField: null,
    preload:function(){
        var data = this.get("rawResults");
        data.forEach((interest)=>{
            let parts = interest.name.split("|");

            interest.lastTier = parts[parts.length - 1];
            if (parts.length > 1) {
                var i = parts.length - 2,
                    newParts = [];

                do {
                    newParts.push(parts[i]);
                    i--;
                } while (i >= 0);

                interest.tiers = newParts;
            }
        });
    },
    description: DataSeriesDescription.load({
        "name": "string",
        "tiers": "array",
        "lastTier": "string",

        "documents": "number",
        "universe": "number",
        "score": "number",
        "distribution": "number"
    }, [
        fieldTimelineGrowth.fieldSetup("documentsGrowthPct", "timeline")
    ])
});
