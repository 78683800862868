import Ember from "ember";

export default Ember.Component.extend({
    classNames: ["gender-area-svg"],
    apis: ["gender", "volume"],

    query: null,
    options: null,
    selected: null,

    field: null,
    grouping: null,

    hoveredIndex: null,
    hoveredValue: null,
    hasInteraction: false,
    mouseXPct: 0,

    timestamps: Ember.computed.alias("query.gender.grouped.stats.timestamp"),

    rawDataGender: Ember.computed.alias("query.gender.rawResults"),
    rawDataVolume: Ember.computed.alias("query.volume.rawResults"),

    colors: {
        male: 'skyblue',
        female: 'pink'
    },

    dataSeriesSource: Ember.computed.alias("query.volume.grouped"),

    hasCustomDataset: Ember.computed.readOnly("query.hasCustomDataset"),

    dataSeries: Ember.computed('dataSeriesSource', {
        get: function() {
            if (!this.dataSeriesSource) {
                return;
            }

            var count = this.get("dataSeriesSource.data").length;
            if (count >= 700) {
                return this.dataSeriesSource.groupBy("datestampInfo.monthAndYear");
            } else if (count >= 180) {
                return this.dataSeriesSource.groupBy("datestampInfo.weekAndYear");
            }
            this.set("grouping", "day");
            return this.dataSeriesSource;
        }
    }),

    startDate: Ember.computed("timestamps", "query.gender.isLoaded", {
        get: function(key) {
            var ts = this.timestamps;
            return ts && moment.unix(ts.get('min')).format('MMM DDD, YYYY');
        },
    }),

    endDate: Ember.computed("timestamps", "query.gender.isLoaded", {
        get: function(key) {
            var ts = this.timestamps;
            return ts && moment.unix(ts.get('max')).format('MMM DDD, YYYY');
        },
    }),

    data: Ember.computed("query.gender.isLoaded", "query.volume.isLoaded", {
        get: function(key) {
            if (!this.get('query.gender.isLoaded') || !this.get('query.volume.isLoaded')) {
                return;
            }
            const field = this.hasCustomDataset ? "Count" : "Universe";
            var gender = this.get('query.gender.grouped.data'),
                genderStats = this.get("query.gender.grouped.stats"),
                maleUniverseMax = genderStats && genderStats.get(`male${field}.max`) || 0,
                femaleUniverseMax = genderStats && genderStats.get(`female${field}.max`) || 0,
                max = Math.max(maleUniverseMax, femaleUniverseMax);

            if (!gender) {
                return;
            }

            return gender.map((item) => {
                var male = Ember.get(item, `male${field}`) / max || 0;
                var female = Ember.get(item, `female${field}`) / max || 0;
                return {
                    date: item.timestamp,
                    male: male,
                    female: female
                };
            });
        },
    }),

    stats: Ember.computed("data", "data.[]", {
        get: function(key) {
            var data = this.data;

            if (!data || Ember.isEmpty(data)) {
                return {};
            }

            return data.reduce(function(pv, item) {
                pv.maleMax = Math.max(pv.maleMax, item.male);
                pv.maleMin = Math.min(pv.maleMin, item.male);
                pv.femaleMax = Math.max(pv.femaleMax, item.female);
                pv.femaleMin = Math.min(pv.femaleMin, item.female);
                pv.maleTotal += item.male;
                pv.femaleTotal += item.female;
                return pv;
            }, {
                maleMax: 0,
                femaleMax: 0,
                maleMin: Infinity,
                femaleMin: Infinity,
                maleTotal: 0,
                femaleTotal: 0
            });
        },
    }),

    stack: Ember.computed("stats", "query.gender.isLoaded", {
        get: function(key) {
            var stats = this.stats,
                loaded = this.get('query.gender.isLoaded');

            if (!stats || !loaded) {
                return {
                    top: 'male',
                    bottom: 'female'
                };
            }

            var m = stats.maleTotal,
                f = stats.femaleTotal;

            return m > f ? {
                top: 'male',
                bottom: 'female'
            } : {
                top: 'female',
                bottom: 'male'
            };
        },
    }),

    getHoveredIndex(mouseXPct) {
        var data = this.data;
        if (!data) {
            return 0;
        }
        return Math.min( Math.max( Math.round(mouseXPct * data.length), 0), data.length - 1);
    },

    isHoveredValueNull: Ember.computed("hoveredValue", {
        get: function() {
            var isRatioZero = this.get('hoveredValue.ratio') === "0.0";

            if (isRatioZero) {
                this.updateGenderTrendTooltip();
            }
            return isRatioZero;
        }
    }),
    getHoveredValue(hoveredIndex) {
        var data = this.data || [];
        hoveredIndex = hoveredIndex || 0;

        if (data.length > 0) {
            var duration = "",
                grouping = this.get("query.gender.grouping"),
                selectedItem = data[hoveredIndex];

            switch (grouping) {
                case "daily":
                    duration = "Day";
                    break;
                case "weekly":
                    duration = "Week";
                    break;
                case "monthly":
                    duration = "Month";
                    break;
                case "yearly":
                    duration = "Year";
                    break;
                default:
                    duration = "Period";
                    break;
            }

            if (!selectedItem) {
                selectedItem = data[data.length - 1];
            }

            var ratio = selectedItem.male > selectedItem.female ? (selectedItem.male / selectedItem.female || 0).toFixed(2) : (selectedItem.female / selectedItem.male || 0).toFixed(2);
            var most = selectedItem.male > selectedItem.female ? "male" : "female";
            var least = selectedItem.male > selectedItem.female ? "female" : "male";

            if (ratio < 1.01 || selectedItem.male === selectedItem.female) {
                ratio = "Equal";
                most = "";
            } else if (ratio === "Infinity") {
                ratio = "Only";
            } else {
                ratio += "x";
            }

            return {
                duration: duration,
                date: selectedItem.date,
                most: most,
                least: least,
                ratio: ratio
            };
        }
    },

    updateGenderTrendTooltip() {},

    actions: {
        mouseXChanged: function(xPct) {
            if (Ember.isEmpty(xPct)) {
                this.set('hasInteraction', false);
                this.updateGenderTrendTooltip(null);
                this.set('hoveredIndex', null);
                this.set('hoveredValue', null);
                return;
            }

            this.set('hasInteraction', true);
            let hoveredIndex = this.getHoveredIndex(xPct),
                hoveredValue = this.getHoveredValue(hoveredIndex);
            this.set('hoveredIndex', hoveredIndex);
            this.set('hoveredValue', hoveredValue);
            this.updateGenderTrendTooltip(hoveredValue);
        },
    }
});
