import Ember from "ember";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import Dictionary from "infegy-frontend/models/users/dictionary";
import * as Sentry from "@sentry/browser";

var Dictionaries = Ember.ArrayProxy.extend({
    content: Ember.A(),
    setup: Ember.on("init",async function() {
        try {
            var data = await AtlasAuth.Get("api/v3/dictionaries");
        } catch(err) {
            if(AtlasAuth.isLoggedIn){
                Sentry && Sentry.captureException("Could not load Dictionaries", err);
            }
            return
        }
        if (data.output) {
            var dictionaries = [];

            data.output.map((item) => {
                var dictionary = Dictionary.create();
                dictionary.loadJSON(item);
                dictionaries.push(dictionary);
            });

            this.set("content", dictionaries);
        }
    })
});

export default Dictionaries;