import Ember from "ember";
import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";
import QueryAPIEntities from "infegy-frontend/models/query-api/entities";
import PostsAPIDescription from "infegy-frontend/models/query-api/posts_api_description";
import AtlasConfig from "infegy-frontend/config/infegy-app-config";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";

var QueryAPILinguisticsPosts = QueryAPIResults.extend({
    title: "linguisticsPosts",
    dateField: "published",

    preload: function() {
        var rawResponse = this.rawResults;

        // The fields property is a special case in the API that is keyed with
        // invalid property names - (they have '-' characters and numerals that
        // need to be accessed as is.) so it breaks the camelizer/flattener. To
        // solve the issue, the object is strigified before being passed to the
        // parser/flattener and then parsed back into a raw object after being
        // processed.
        rawResponse.results = rawResponse.results.map(row => {
            row.fields = JSON.stringify(row.fields);
            return row;
        });

        this.setProperties({
            "rawResults": rawResponse.results,
            "descriptor": rawResponse.descriptor,
            "descriptorColumns": rawResponse.descriptor?.columns
        });
    },

    postload: function() {
        var queryAPIEntities = QueryAPIEntities.create(),
            data = this.data;

        data.forEach((post) => {
            if (!Ember.isEmpty(post.entities)) {
                post.entities.forEach((entity) => {
                    queryAPIEntities.preloadEntity(entity);
                    queryAPIEntities.postloadEntity(entity);
                });
            }
        });
    },

    description: DataSeriesDescription.load(PostsAPIDescription),

    linguisticsPostsAjax: function(urlQueryString) {

        this.beginLoading();
        var that = this;
        var req = AtlasAuth.Get({
            url: AtlasConfig.queryAPI.linguisticsPostsURLBase + urlQueryString
        }).then(function(data) {
            that.loadResponse(data, [data]);
        }).catch(()=>{
            this.set("hasError",true);
        }).finally(() => {this.set("isLoading", false);});

        return req;
    },
    to_snake_case: function(word) {
        return word.replace(/([A-Z])/g, "_$1").trim().toLowerCase();
    },
    loadFor: function(query, postAttr, postValue, shouldParse = true) {
        var queryString = query && query.toAPIQueryString(),
            qsValue = shouldParse ? postValue.replace(/([A-Z])/g, "_$1").trim().toLowerCase() : postValue,
            urlQueryString = [queryString, "&", postAttr, "=", qsValue, "&limit=250"].join("");

        return this.linguisticsPostsAjax(urlQueryString);
    },
    loadForCustom: function(query, postFilters, sortKey, limit) {
        var queryString = query && query.toAPIQueryString(),
            postFilterArray = [];

        (postFilters || []).forEach(filterItem => {
            if (filterItem.get('selectedValues').length === 0) {
                return;
            }

            var key = this.to_snake_case(filterItem.name);
            if (key === "emotions" || key === "themes") {
                key = key.substring(0, key.length - 1);
            }

            var selectedValues = filterItem.get('selectedValues');
            if (selectedValues.length !== 0 && !filterItem.rawValue) {
                selectedValues = selectedValues.map(value => {
                    return this.to_snake_case(value);
                });
            }
            if(selectedValues.length !== 0 && filterItem.rawValue){
                selectedValues = selectedValues.map(value => {
                    return encodeURIComponent(value);
                });
            }
            selectedValues = selectedValues.join(",");

            postFilterArray.push(key + "=" + selectedValues);
        });

        if (sortKey && sortKey !== "none") {
            postFilterArray.push(`sort=${sortKey}`);
        }

        limit = limit || 250;
        postFilterArray.push(`limit=${limit}`);

        var urlQueryString = [queryString, "&", postFilterArray.join("&")].join("");

        return this.linguisticsPostsAjax(urlQueryString);
    }
});

QueryAPILinguisticsPosts.reopenClass({
    loadPositivePosts: function(query) {
        var posts = QueryAPILinguisticsPosts.create({
            title: "linguisticsPositivePosts",
            query: query
        });
        posts.loadFor(query, "sub_sentiment", "positive");
        return posts;
    },
    loadNegativePosts: function(query) {
        var posts = QueryAPILinguisticsPosts.create({
            title: "linguisticsNegativePosts",
            query: query
        });
        posts.loadFor(query, "sub_sentiment", "negative");
        return posts;
    },
    loadNeutralPosts: function(query) {
        var posts = QueryAPILinguisticsPosts.create({
            title: "linguisticsNeutralPosts",
            query: query
        });
        posts.loadFor(query, "sub_sentiment", "neutral");
        return posts;
    },
    loadThemePosts: function(query, theme) {
        var title = ["linguisticsTheme", theme, "Posts"].join("_").camelize();
        var posts = QueryAPILinguisticsPosts.create({
            title: title,
            query: query
        });
        posts.loadFor(query, "sub_themes", theme, false);
        return posts;
    },
    loadEmotionPosts: function(query, emotion) {
        var title = ["linguisticsEmotion", emotion, "Posts"].join("_").camelize();
        var posts = QueryAPILinguisticsPosts.create({
            title: title,
            query: query
        });
        posts.loadFor(query, "sub_emotions", emotion);
        return posts;
    },
    loadInterestsPosts: function(query, interest) {
        var posts = QueryAPILinguisticsPosts.create({
            title: "interestsRelatedPosts",
            query: query
        });
        posts.loadFor(query, "sub_post_interests", interest, false);
        return posts;
    },

    loadPostsFor: function(query, segment, subSegment, shouldParse) {
        if (!query || !segment) {
            return null;
        }
        var posts = QueryAPILinguisticsPosts.create({
            title: "interestsRelatedPosts",
            query: query
        });
        posts.loadFor(query, segment, subSegment, shouldParse);
        return posts;
    },

    loadCustomPosts: function(query, postFilters, sortKey) {
        var posts = QueryAPILinguisticsPosts.create({
            title: "customPosts",
            query: query
        });
        posts.loadForCustom(query, postFilters, sortKey);
        return posts;
    }
});

export default QueryAPILinguisticsPosts;
