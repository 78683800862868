import Ember from "ember";
import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";
import QueryAPIEntities from "infegy-frontend/models/query-api/entities";
import PostsAPIDescription from "infegy-frontend/models/query-api/posts_api_description";

export default QueryAPIResults.extend({
    title : "posts",
    dateField: false,

    descriptor: null,
    descriptorColumns: null,

    preload: function() {
        var rawResponse = this.rawResults;

        // The fields property is a special case in the API that is keyed with
        // invalid property names - (they have '-' characters and numerals that
        // need to be accessed as is.) so it breaks the camelizer/flattener. To
        // solve the issue, the object is strigified before being passed to the
        // parser/flattener and then parsed back into a raw object after being
        // processed.
        rawResponse.results = rawResponse.results.map(row => {
            row.fields = JSON.stringify(row.fields);
            return row;
        });

        this.setProperties({
            "rawResults": rawResponse.results,
            "descriptor": rawResponse.descriptor,
            "descriptorColumns": rawResponse.descriptor?.columns,
            "sortableFields": rawResponse.sortable_fields,
            "dateField": this.rawResponse[0].query_info.group_on
        });
    },

    postload: function() {
        var queryAPIEntities = QueryAPIEntities.create(),
            data = this.data;

        data = data.map(row => {
            row.fields = JSON.parse(row.fields);
            // Make the date more accessible on the model.
            row.dateValue = row.fields[this.dateField];
            return row;
        });

        data.forEach((post) => {
            if (!Ember.isEmpty(post.entities)) {
                post.entities.forEach((entity) => {
                    queryAPIEntities.preloadEntity(entity);
                    queryAPIEntities.postloadEntity(entity);
                });
            }
        });
    },

    description: DataSeriesDescription.load(PostsAPIDescription)
});
