import Component from "@ember/component";
import classic from "ember-classic-decorator";
import { computed } from "@ember/object";

@classic
export default class FiltersetsList extends Component {
  user = null;
  filtersets = [];
  pageSize = 10;

  @computed("filtersets", "filtersets.length")
  get ownedFiltersets() {
    return this.filtersets.filter((f) => !f.isShared);
  }

  @computed("filtersets", "filtersets.length")
  get sharedFiltersets() {
    return this.filtersets.filter((f) => f.isShared);
  }
}
