import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { ComparisonTableField, ComparisonTableFields } from "infegy-frontend/components/field-components/comparison-table/table-field";
import classic from "ember-classic-decorator";

@classic
export default class ComparisonTableRow extends Component {
    tagName = "tr";
    classNameBindings = [":data-table-row", "isEven:data-table-row-even", "isEditing:data-table-editing-row"];

    queries = null;
    fieldIdx = null;
    tableFields = null;
    comparisonTableRow = null;
    editingField = null;

    @computed("editingField", "fieldIdx")
    get isEditing() {
        return !Ember.isEmpty(this.editingField) && this.editingField === this.fieldIdx;
    }

    @action
    willEditField(fieldIdx) { this.editField(fieldIdx); }
    editField(fieldIdx) {/* action */ }
};
