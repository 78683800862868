export default {
    passUp: function(eventName) {
        return function (...params) {
            this[eventName](...params);
        };
    },
    setProperty: function(propertyName) {
        return function (param) {
            if (this.get(propertyName) !== param) {
                this.set(propertyName, param);
            }
        };
    },
    setOrToggleProperty: function(propertyName) {
        return function (param) {
            var currentValue = this.get(propertyName);
            if (currentValue !== param) {
                this.set(propertyName, param);
            } else if (currentValue !== null) {
                this.set(propertyName, null);
            }
        };
    }
};
