export default {
    "overview":{
        "mainTitle": {
            text: "Once you run your query, you will be taken to the Overview tab to see the initial results of your research. Your query results are based on a sampling of the overall conversation online about your query. Here you can get an understanding, from a high level, of the volume of conversation, sentiment and passion, who is posting, and what the most common entities and interests are in the conversation. You will also find some sample posts.",
            documentation: "articles/6082547-the-overview-tab"
        },
    },

    "trends":{
        "mainTitle": {
            text: "The Trends tab allows you to see how metrics within your query results have trended over your selected time period. There are a number of ways you can begin to understand data in Atlas, including isolating time periods to examine peaks in conversation, comparing more than one query at a time, and exporting data and images to use in your reporting.",
            documentation: "articles/6082554-the-trends-tab"
        },
    },

    "linguistics":{
        "topics": {
            text: "Atlas’ Linguistic suite provides data around Topics, Sentiment , Themes, and Emotions. This is done by a sophisticated, proprietary language analysis application developed in-house by Infegy, specifically for Atlas. Linguistic sentiment analysis is industry-leading, able to recognize complex grammatical structures and parts of speech, and adjusts for things like negation (“not good”), resolving pronouns and much more.",
            documentation: "articles/6122520-the-linguistics-tab"
        },
        "themes": {
            text: "In addition to running sentiment analysis for queried content, Atlas Linguistics will also run a theme analysis, which are found on the Trends tab. Themes are categorizations which are used for easily grouping well known conversation topics. For broad queries, several of these themes may be a trigger. In addition to using topics, themes can help you understand what conversations associated with your query are about.",
            documentation: "articles/6122531-themes-trends-tab"
        }
    },

    "sentiment":{
        "mainTitle": {
            text: "Sentiment allows users to quickly understand whether their research topic is referenced more positively or negatively across the web. Drill down into the positive or negative arc charts to review post content falling into either category. Sentiment is analyzed on three levels: the document, the sentence, and the subject sentence.",
            documentation: "articles/6122495-the-sentiment-tab"
        },
        "topics": {
            text: "Sentimental topics showcases the words and phrases authors are using to express their positivity or negativity towards your research topic.",
            documentation: "articles/6122495-the-sentiment-tab"
        },
        "emotions": {
            text: "Emotions allows for deeper linguistic analysis of the text by automatically categorizing posts based on the internationally recognized set of eight polar human emotions. Drill down into the Emotions arc charts to review post content falling into each emotion category.",
            documentation: "articles/6122717-emotions-trends-tab"
        }
    },

    "narratives":{
        "mainTitle": {
            text: "The Narratives tab uses advanced AI to automatically identify, group, and name thematic conversations over time. It’s a powerful new way to diagram and compare the types of themes being discussed, so you can easily spot key trends and understand the narratives around your search topic.",
            documentation: "articles/6122543-the-narratives-tab"
        }
    },

    "posts":{
        "mainTitle": {
            text: "Within the Posts tab in Atlas, we have the ability to view all posts available in our database for our given topic. We have the capability to filter post content by Entities, Interests, Emotions, Themes, Sentiment, Subject Sentiment, and Channels. The “Filter By” field supports full Boolean query capabilities. The “Sort By” dropdown menu allows for other sorting options as well.",
            documentation: "articles/6122554-the-posts-tab"
        },
        "tableColumns": {
            text: "These column headers were detected in the file uploaded and have value in their fields. Click a column name to add it to the table view below."
        }
    },

    "entities":{
        "mainTitle": {
            text: "By cross-referencing the linguistics of your query results with the Wikipedia database, Atlas intelligently calls out notable people, products, and companies being referenced within the larger conversation around your research topic. There are three ways to view the entities - in a sortable list, via word cloud, or in blocks. ",
            documentation: "articles/6122571-the-entities-tab"
        }
    },

    "interests":{
        "mainTitle": {
            text: "By cross referencing the historical content generated by the authors discussing your research topic with more than 600 industry standardized IAB interest taxonomies, Atlas derives which areas the author group is most interested in. Atlas Interests is especially valuable for those working to better engage with, message, and target specific audiences.",
            documentation: "articles/6122595-the-interests-tab"
        },
    },

    "demographics":{
        "mainTitle": {
            text: "The Demographics tab shows many important factors relating to the authors discussing your research topic online including gender, sentiment by gender, age, and language. This tab is broken out into four main sections: gender, topics, age, language, and US demographics. These sections will provide insight into who is having the conversation and the difference in conversation among groups.",
            documentation: "articles/6122727-the-demographics-tab"
        },
        "ages": {
            text: "Age Distribution shows which age groups are most engaged online with your research topic.",
            documentation: "articles/6122746-age-demographics-tab"
        },
        "languages": {
            text: "Language distribution provides a percentage breakdown of which languages are most prominent within the larger conversation related to your research topic.",
            documentation: "articles/6122727-the-demographics-tab"
        },
        "usDemographics": {
            text: "Providing U.S. Demographics information is something that Atlas is able to provide by connecting U.S. Census data, geolocation tagging, and the queried content.",
            documentation: "articles/6122769-u-s-census-demographics-demographics-tab"
        }
    },

    "geography":{
        "mainTitle": {
            text: "Using a normalized approach which takes into account factors such as broadband penetration and population density, Atlas provides an accurate read on where interest in your research topic lies across the globe or within the United States.",
            documentation: "articles/6122821-the-geography-tab"
        }
    },

    "influencers":{
        "mainTitle": {
            text: "Every author in the Atlas database is considered an influencer. This tab ranks the top 100 most influential sources online discussing your research topic. Influence ranking is calculated by considering the overall influence of a particular source based on inbound link activity in addition to the number of posts that source has generated related to your research topic.",
            documentation: "articles/6122856-the-influencers-tab"
        }
    },

    "exports":{
        "mainTitle": {
            text: "Everything within Infegy Atlas is exportable, including the raw data. Dive deeper into our API documentation to understand the raw data behind your exports.",
            documentation: "articles/6152511-infegy-atlas-api-documentation"
        },
        "apiPreview": {
            text: "API Preview gives an example request based on your query parameters and the Atlas output being requested.",
        },
        "apiAccessUrls": {
            text: "These URLs provide direct access to the API.",
        }
    },

    "filters": {
        "ages": {
            text: "Filter to posts by sources within an age range"
        },
        "audienceSegments": {
            text: "Audience segments are built in-house by our talented team on a per request basis"
        },
        "channels": {
            text: "The channels filter offers the capability to filter content to different channels such as blogs, microblogs, forums, news sites etc"
        },
        "dictionaries": {
            text: "Apply a dictionary to returned posts"
        },
        "dayOfWeek": {
            text: "The Day of Week filter allows you to gather content around a topic during specific days of the week"
        },
        "gender": {
            text: "The Gender filter allows you to gather content from exclusively male or female audiences"
        },
        "geography": {
            text: "The geography filter supports countries and allows you to sort all of your results depending on where the content was generated"
        },
        "influence": {
            text: "The Influence filter allows you to refine your search to only return findings from sources of varying influence levels"
        },
        "languages": {
            text: "Atlas has the ability to search for 157 languages and can process 35 of them for sentiment"
        },
        "sourceQuery": {
            text: "Filter sources using a boolean query"
        },
        "postInterests": {
            text: "Filter to posts tagged with IAB Taxonomies"
        },
        "sourceInterests": {
            text: "Filter to posts from sources with tagged with IAB Taxonomies"
        },
        "timeOfDay": {
            text: "The Time of Day filter allows you to gather content around your topic during a very specific time of day"
        },
        "sentiment": {
            text: "Filter to posts based on whether they are positive, negative, or neutral"
        },
        "themes": {
            text: "Filter to posts based on the themes such as cost, confidence, or purchase intent"
        },
        "emotions": {
            text: "Filter to posts that show emotions such as trust, anger, or joy"
        },
        "shares": {
            text: "Filter to include, exclude, or exclusively show shares"
        },
        "replies": {
            text: "Filter to include, exclude, or exclusively show replies"
        },
        "rating": {
            text: "Filter to posts with a rating within a range"
        },
        "nps": {
            text: "Filter to posts with a Net Promoter Score within a range"
        },
        "followers": {
            text: "Filter to sources with a follower count within a range"
        },
        "subquerySets": {
            text: "Run additional queries within the results of the parent query"
        },
        "totalComments": {
            text: "Filter to posts with a comment count within a range"
        },
        "totalLikes": {
            text: "Filter to posts with a like count within a range"
        },
        "totalShares": {
            text: "Filter to posts with a share count within a range"
        }
    },

    "queryBuilder": {
        "company": {
            text: "Matches mentions related to a particular business entity. For example, \"Uber\" will match posts referencing the ride hailing company Uber Technologies.",
        },
        "person": {
            text: "Matches mentions of prominent people including politicians, musicians, athletes etc. For example, \"Tim Cook\" will match posts referencing the Apple CEO.",
        },
        "sports_team": {
            text: "Matches mentions of a sports team. For example, \"Yankees\" will match posts referencing the distinguished New York MLB baseball team.",
        },
        "brand": {
            text: "Matches mentions of a brand. For example, \"Xbox\" will match posts related to the video gaming brand.",
        },
        "media": {
            text: "Matches mentions of broadcasted content including music, movies and television. For example, \"U2\" will match posts mentioning the famous alternative rock band.",
        },
        "product": {
            text: "Matches mentions related to a specific product. For example, \"Diet Coke\" will match posts related to the sugar-free soft drink.",
        },
        "text": {
            text: "Matches posts containing these exact words / phrases. For example, \"Brexit\" will match posts containing this exact word",
        }
    },

    "customDatasets": {
        "ignoreErrors": {
            text: "This disables strict data checks (e.g., if a continent name is uploaded under the bound Country column).<br />We will skip over errors found and continue processing your document's data so that the rest of the document is used.<br />One exception: Date parsing errors will not be skipped."
        }
    }
};
