import Ember from "ember";

export default Ember.Component.extend({
    classNames: ["icon-view-selector"],

    viewOptions: [],
    selectedView: null,

    selectionChanged: () => {},
    actions: {
        selectView: function (viewType) {
            this.selectionChanged(viewType);
        }
    }
});
