import Component from '@ember/component';
import { computed, action } from '@ember/object';
import classic from "ember-classic-decorator";

@classic
export default class ComparisonFieldColumnTable extends Component {
    tagName = "table";
    classNameBindings = [":data-table-highlightable", ":data-table-fixed"];

    fieldRows = null;
    queries = null;
    tableFields = null;
    editingField = null;

    @action
    editingField(fieldIdx) { this.editField(fieldIdx); }
    editField(fieldIdx) {/* action */ }
};
