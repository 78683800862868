import { A } from "@ember/array";
import ArrayProxy from "@ember/array/proxy";
import classic from "ember-classic-decorator";

import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import WorkspaceFolder from "infegy-frontend/models/users/workspace_folder";

@classic
export default class WorkspaceFolders extends ArrayProxy {
    content = A();
    url = "api/v3/workspace_folders";

    isLoading = false;

    async load() {
        this.set("isLoading", true);
        const response = await AtlasAuth.Get(this.url);
        const workspaceFolders = response.output.map((record) => {
            let workspaceFolder = WorkspaceFolder.create();
            workspaceFolder.loadJSON(record);
            return workspaceFolder;
        });
        this.clear();
        this.pushObjects(workspaceFolders);
        this.set("isLoading", false);
    }
}