import Ember from "ember";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import JsonStore from 'infegy-frontend/json-store/model';
import Prop from 'infegy-frontend/json-store/properties/model';

export default JsonStore.extend({
    id: Prop.Attr(),
    value: Ember.computed.alias("id"),

    created: Prop.Attr(),
    name: Prop.String(),
    label: Ember.computed.alias("name"),

    entryCount: Prop.Attr(),

    shared: Prop.Boolean(),
    userId: Prop.Int(),

    save: function() {
        return AtlasAuth.Put({
            url: "api/v3/watchlist_management.json",
            data: {
                watchlist_id: this.id,
                name: this.name
            }
        });
    },
    copy: function(userId) {
        return AtlasAuth.Post({
            url: "api/v3/watchlist_management.json",
            data: {
                to_user_id: userId,
                watchlist_id: this.id,
                is_copy: true,
            }
        });
    },
    delete: function() {
        return AtlasAuth.Delete({
            url: "api/v3/watchlist_management.json",
            data: {
                watchlist_id: this.id,
                deleted: 1
            }
        });
    }
});
