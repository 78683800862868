 import Ember from "ember";
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { run } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import virtualProperty from 'infegy-frontend/decorators/virtual-property';

export default class FullFieldSelectorBase extends Component {
    query = null;
    queries = null;

    // Used for determining available custom dataset fields
    user = null;

    selectedSegment = null;
    selectedSubsegment = null;
    selectedFieldComponent = null;

    // For single-selector only
    selectedQueryId = null;

    displayAsDropdown = false;
    fieldType = "trend";

    @virtualProperty() selectedFields;

    @computed("queries", "queries.[]", "queries.@each.lookupId", "query", "selectedFields")
    get innerQueries() {
        let queries = this.query ? [this.query] : this.queries;
        if (!queries) {
            return [];
        }

        (this.selectedFields || []).forEach(field => {
            let matchedQuery = queries.find(query => Ember.get(query, "lookupId") === field.queryId);
            if (matchedQuery && field.query !== matchedQuery) {
                field.set("query", matchedQuery);
            }
        });
        return queries;
    }

    @computed("selectedFields", "selectedFields.[]", "selectedFields.@each.query")
    get firstSelectedField() {
        return (this.get("selectedFields.length")) ? this.selectedFields[0] : null;
    }

    @computed("queries", "queries.[]", "queries.@each.lookupId", "query")
    get firstQuery() {
        if (this.query) {
            return this.query;
        } else {
            return this.get("queries.length") ? this.queries[0] : null;
        }
    }

    @computed("firstSelectedField", "selectedSegment", "selectedSubsegment", "selectedFields", "innerQueries", "selectedQueryId")
    get isValidatingFields() {
        let firstSelectedField = this.firstSelectedField;
        if (firstSelectedField && (firstSelectedField.segment !== this.selectedSegment ||
                firstSelectedField.subsegment !== this.selectedSubsegment ||
                firstSelectedField.fieldComponent !== this.selectedFieldComponent ||
                (this.get("innerQueries.length") > 1 && firstSelectedField.queryId !== this.selectedQueryId))) {
            run.next(()=>{
                this.set("selectedSegment", firstSelectedField.segment);
                this.set("selectedSubsegment", firstSelectedField.subsegment);
                this.set("selectedFieldComponent", firstSelectedField.fieldComponent);
                if (this.get("innerQueries.length") > 1 && firstSelectedField.queryId !== this.selectedQueryId) {
                    this.set("selectedQueryId", firstSelectedField.queryId);
                }
            });
            return true;
        }
    }

    @computed("user", "user.customDatasets", "fieldQueries", "innerQueries", "fieldQueries.[]",
        "innerQueries.[]", "innerQueries.@each.availableDynamicTrendsFields")
    get datasetFields() {
        let customDatasets = this.user?.customDatasets,
            queries = this.fieldQueries || this.innerQueries;
        if (!customDatasets || !queries) {
            return [];
        }
        let allCustomDatasetFields = queries.reduce((memo, query) => {
            if (!Ember.isEmpty(query?.availableDynamicTrendsFields)) {
                memo = memo.concat(query?.availableDynamicTrendsFields);
            }
            return memo;
        }, []).uniqBy("id");

        return allCustomDatasetFields;
    }

    @computed("datasetFields", "datasetFields.[]")
    get datasetSegment() {
        let allCustomDatasetFields = this.datasetFields || [],
            customDatasetFields = allCustomDatasetFields.filter(field => {
                return field.id.includes("-");
            }).map(field => {
                let option = {
                    fieldTypes: ["trend","scalar"],
                    value: field.id,
                    title: field.name
                };
                if (field.type === "integer" || field.type === "decimal") {
                    option.fieldComponent = "field-selector/parts/fields/numeric-stats";
                } else if (field.type === "keywords" || field.type === "keyword") {
                    option.fieldComponent = "field-selector/parts/fields/top-keywords";
                }
                return option;
            });

        if (customDatasetFields.length) {
            return {
                value: "Dataset",
                fieldTypes: ["trend", "scalar"],
                options: customDatasetFields
            };
        }
    }

    @action
    segmentChanged(selectedSegment) {
        if (this.selectedSegment !== selectedSegment) {
            this.set("selectedSegment", selectedSegment);
            this.set("selectedSubsegment", null);
            this.set("selectedFieldComponent", null);
            this.updateFields(null);
        }
    };

    @action
    subsegmentDetailsChanged(selectedSubsegment) {
        if (this.selectedFieldComponent !== selectedSubsegment?.fieldComponent) {
            this.set("selectedFieldComponent", selectedSubsegment?.fieldComponent);
        }

        if (this.selectedSubsegment !== selectedSubsegment.value) {
            this.set("selectedSubsegment", selectedSubsegment.value);
            this.updateFields(null);
        }
    };

    @action
    fieldComponentChanged(selectedFieldComponent) {
        if (this.selectedFieldComponent !== selectedFieldComponent) {
            this.set("selectedFieldComponent", selectedFieldComponent);
        }
    };

    toggleCustom() {}
    @action
    togglingCustom() {this.toggleCustom();}
};
