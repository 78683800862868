import Ember from 'ember';
import Component from "@ember/component";
import { readOnly } from "@ember/object/computed";
import { computed, action } from '@ember/object';

import GlobalFilters from 'infegy-frontend/models/queries/query_global_filters';

export default class extends Component {
    classNames = ["query-form"];

    // input
    hideActionArea = false;
    hideQueryFormTypeSelector = false;
    maxQueries = 6;
    selectedQueryFormType = "";
    nextQueryFormType = "";
    user = null;
    workspace = null;

    isDateSetFromDataset = false;

    @readOnly("user.customDatasets") customDatasets;
    @readOnly("user.ownedWorkspaces.content") ownedWorkspaces;
    @readOnly("workspace.queries") queries;
    @readOnly("queries.firstObject") firstQuery;

    @computed("maxQueries", "queries", "queries.length")
    get singleQuery(){
       return this.maxQueries < 2;
    }

    @computed("editingWorkspace", "maxQueries", "queries", "queries.length")
    get canAdd(){
        return this.editingWorkspace ? true : this.get("queries.length") < this.maxQueries;
    }

    @computed.gt("queries.length",1) canRemove;

    @computed("user.permissions.customDatasetsEnabled")
    get canUseCustomDatasets(){
        return this.get("user.permissions.customDatasetsEnabled") && !this.hideQueryFormTypeSelector;
    }

    @computed("workspace.isRunnable")
    get submitDisabled() {
        if (this.workspace.isRunnable) {
            return false;
        }
        return true;
    }

    copyQuery = ()=>{/* action */};

    editingWorkspace = false;

    @computed("queries.@each.queryInfo", "queries.@each.updated", "customDatasets")
    get allCustomDatasets(){
        var queries = this.queries,
            customDatasets = this.customDatasets,
            allDatasets = [];
        queries.forEach((query)=>{
            var queryCustomDatasets = Ember.get(query,"queryInfo.customDatasets");
            if(!Ember.isArray(queryCustomDatasets)){
                return;
            }
            queryCustomDatasets.forEach((queryDataset)=>{
                var datasetId = Ember.get(queryDataset,"id"),
                    dataset = customDatasets.findBy("id",datasetId);

                if (dataset) {
                    allDatasets.push(dataset);
                }
            });
        });
        return allDatasets;
    }

    @computed("queries", "queries.@each.isDatasetLoaded")
    get canShowDateRange() {
        const queriesLoadingDatasets = this.queries.filter((query) => {
            // has a custom dataset that is still loading
            return query.hasCustomDataset && !query.isDatasetLoaded;
        });
        return queriesLoadingDatasets.length === 0;
    }

    @readOnly("workspace.isBlank") isFormBlank;

    submitQuery = () => {/* action */};
    loadWorkspace = () => {/* action */};
    uploadData = () => {/* action */};
    startNewWorkspace = () => {/* action */}

    @action onSubmitQuery() {
        if (this.submitDisabled)
            return;
        this.submitQuery();
    }

    @action onRemoveQuery(query) {
        this.workspace.removeQuery(query);
        GlobalFilters.setDefaultDateRange(this.workspace.queries);
    }

    @action onLoadWorkspace (workspace) {
        if (workspace) {
            this.loadWorkspace(workspace);
        }
    }

    @action datasetChanged(datasetId, query) {
        let queryInfo = query?.queryInfo;
        if (!queryInfo) {
            return;
        }

        let hasDataset = !!datasetId;
        if (!hasDataset) {
            const found = this.queries.content.find((q) => q.queryInfo.customDatasetId);
            hasDataset = !!found;
        }
        this.set("isDateSetFromDataset", hasDataset);

        GlobalFilters.setDefaultDateRange(this.queries);
    }

    @action onDatesChanged(startDate, endDate) {
        this.set("isDateSetFromDataset", false);
        GlobalFilters.synchronize(this.queries);
    }

    @action selectedQueryFormTypeChanged(type) {
        this.set('nextQueryFormType', type);

        if (this.isFormBlank) {
            this.confirmQueryTypeChange();
        } else {
            this.set("showQueryFormTypeConfirmation", true);
        }
    }

    @action confirmQueryTypeChange() {
        this.startNewWorkspace(this.nextQueryFormType);
        this.set("isDateSetFromDataset", false);
        this.setProperties({
            selectedQueryFormType: this.nextQueryFormType,
            showQueryFormTypeConfirmation: false
        });
    }

    @action rejectQueryTypeChange() {
        this.set("showQueryFormTypeConfirmation", false);
    }

    @action globalQueryWithinChanged(value){
        GlobalFilters.synchronize(this.queries);
    }

    @action
    applyModifierToAll(modifier, value, sourceQuery) {
        this.queries.forEach((query) => {
            if (query != sourceQuery) {
                if (modifier === "dictionaries") {
                    query.queryFilters.set("dictionaries", value);
                }
                if (modifier === "subquerySets") {
                    query.queryFilters.set("subquerySets", value);
                }
                if (modifier === "analyzeFields") {
                    query.queryFilters.set("analyzeFields", value);
                }
                if (modifier === "timestampField") {
                    query.queryFilters.set("timestampField", value);
                }
            }
        });
    }

    @action
    applyFilterToAll(filterToAdd, sourceQuery) {
        this.queries.forEach((query) => {
            // Filters being copied and applied to all queries shouldn't re-add 
            // to the source query as it would create a duplicate filter.
            if (query != sourceQuery) {
                const found = query.queryInfo.filters.content.find(currentFilter => {
                    const matches = JSON.stringify(currentFilter.toAPIJSON()) === JSON.stringify(filterToAdd.toAPIJSON()) 
                        && !!currentFilter.isExcluded === !!filterToAdd.isExcluded;
                    return matches;
                });
                if (!found) {
                    query.queryInfo.addFilter(filterToAdd.toJSON());
                }
            }
        });
    }

    @action
    toggleEditingWorkspace(value) {
        this.set("editingWorkspace", value);
    }
}
