import Ember from "ember";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import AtlasConfig from "infegy-frontend/config/infegy-app-config";

export default Ember.Controller.extend({
    query:'',
    meta:null,
    entities: null,
    topics: null,
    gender: null,
    income: null,
    ages:null,
    states:null,
    demographics: null,
    dateString:'3 Months Ago',

    loading:false,

    targetGender: Ember.computed("gender",function(){
        var genderData = this.gender || [],
            male = 0,
            female = 0;
        genderData.forEach((day)=>{
            male += day.male.count;
            female += day.female.count;
        });
        var gender = '',
            gender_dist = 0;
            
        if (male+female >= 10.0){
            if (female > male*1.5){
                gender = 'Females';
                gender_dist = female/(male+female);
            } else if(male > female*1.5){
                gender = 'Males';
                gender_dist = male/(male+female);
            }
            else {
                return null;
            }
        }

        return {
            gender: gender,
            percent: gender_dist
        }
    }),

    ageStats: Ember.computed("ages",function(){
        var ages = this.ages,
            ranges = ages && ages.ranges || [],
            max_expected = 0;

        ranges.forEach((range)=>{
            if(!max_expected || range.vs_expected > max_expected.vs_expected)
                max_expected = range;
        });

        return {
            primary: max_expected.display_name,
            median: ages && ages.median
        };
    }),

    incomeStats: Ember.computed("income",function(){
        var income = this.income || [],
            max_probability = 0,
            max_expected = 0;

        income.forEach((range)=>{
            if(!max_probability || range.probability > max_probability.probability)
                max_probability = range;
            if(!max_expected || range.vs_expected > max_expected.vs_expected)
                max_expected = range;
        });

        return {
            common: max_probability.display_name,
            distinct: max_expected.display_name
        };
    }),

    displayInterests: Ember.computed("interests",function(){
        var interests = this.interests || [];

        interests = interests.splice(0,10);
        
        interests.forEach((interest)=>{
            let parts = interest.name.split("|");
            interest.category = parts[0];
            interest.title = parts[1];
        });

        return interests;
    }),

    displayEntities: Ember.computed("entities",function(){
        var entities = this.entities || [];

        return entities.slice(0,10);
    }),

    displayTopics: Ember.computed("topics",function(){
        var topics = this.topics || [];
        return topics.slice(0,10);
    }),


    stateStats: Ember.computed("states",function(){
        var states = this.states||[];
        var possibleStates = states.filter((state)=> state.sources >= 30);
        return {
            interest: possibleStates.sort((a,b)=> b.score - a.score).slice(0,5),
            population: possibleStates.sort((a,b)=> {
                return ((b.sources/b.total_sources)*b.population) - ((a.sources/a.total_sources)*a.population);
            }).slice(0,5),
        }
    }),


    
    actions: {
        updateResponses(){
            let query = this.query;
            this.set("loading", true)
            var num_finished = 0,
                dateString = this.dateString,
                data = {
                    query:query,
                    start_date: dateString
                };

            // Gender entities topics demographics
            AtlasAuth.Get({ url:AtlasConfig.baseAPIDomain + 'entities', data:data }).then((res)=>{ 
                this.set("entities",res.output); 
                this.set("meta",res.query_meta);
                update();
            });
            AtlasAuth.Get({ url:AtlasConfig.baseAPIDomain + 'topics', data:data }).then((res)=>{ 
                this.set("topics",res.output); 
                update();
            });
            AtlasAuth.Get({ url:AtlasConfig.baseAPIDomain + 'gender', data:data }).then((res)=>{ 
                this.set("gender",res.output); 
                update();
            });
            AtlasAuth.Get({ url:AtlasConfig.baseAPIDomain + 'income', data:data }).then((res)=>{ 
                this.set("income",res.output.ranges); 
                update();
            });
            AtlasAuth.Get({ url:AtlasConfig.baseAPIDomain + 'ages', data:data }).then((res)=>{ 
                this.set("ages",res.output); 
                update();
            });
            AtlasAuth.Get({ url:AtlasConfig.baseAPIDomain + 'interests', data:data }).then((res)=>{ 
                this.set("interests",res.output); 
                update();
            });
            AtlasAuth.Get({ url:AtlasConfig.baseAPIDomain + 'states', data:data }).then((res)=>{ 
                this.set("states",res.output); 
                update();
            });
            AtlasAuth.Get({ url:AtlasConfig.baseAPIDomain + 'demographics', data:data }).then((res)=>{ 
                this.set("demographics",res.output); 
                update();
            });
            var update = ()=>{
                num_finished++;
                if (num_finished > 7){
                    this.set("loading",false);
                }
            }
        }
    }
});