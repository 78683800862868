import Ember from 'ember';
import { dictionarySentimentOptions } from "infegy-frontend/static_data/sentiment";

export function sentimentLabel(params, namedArgs) {
  var foundValue = dictionarySentimentOptions.findBy("value", params[0]);

  return foundValue && foundValue.label;
}

export default Ember.Helper.helper(sentimentLabel);
