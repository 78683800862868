import Ember from "ember";

export default Ember.Component.extend({
    emotionData: [],
    availableEmotions: [],

    viewField: "appearances",
    selectedField: null,
    sortAscending: true,
    sortField: "emotion",

    sortClassSuffix: Ember.computed("sortAscending", function () {
        return this.sortAscending ? "sorted-asc" : "sorted-desc";
    }),

    sortedEmotionData: Ember.computed("emotionData", "sortAscending", "sortField", function () {
        var emotions = this.emotionData || [],
            asc = this.sortAscending,
            sortField = this.sortField;
        return emotions.sort((topicA, topicB) => {
            var a = asc ? Ember.get(topicA, sortField) : Ember.get(topicB, sortField),
                b = asc ? Ember.get(topicB, sortField) : Ember.get(topicA, sortField);
            if (typeof(a) === "string") {
                return a.localeCompare(b);
            }
            return a - b;
        });
    }),

    postSort: Ember.computed("selectedField", function() {
       return this.selectedField === "universe" ? "combined.Universe" : "combined.Documents";
    }),

    actions: {
        sortBy: function(newSortField) {
            var sortField = this.sortField;
            if (newSortField === sortField) {
                this.toggleProperty("sortAscending");
            } else {
                this.setProperties({
                    sortField: newSortField,
                    sortAscending: newSortField === "emotion"
                });
            }
        }
    }
});
