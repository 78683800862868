/* eslint-disable no-undefined */
import DataSeriesComputedFieldBase from "infegy-frontend/models/data_series/data_series_computed_fields";

// Creates a computed percentage field based on two source fields:
//      A "minuend" and a "subtrahend" - both should be numeric fields.
//      If either is missing, it will be treated as a "0"
//
// The output is calculated by "minuend" - "subtrahend"
//
// Output is groupable using standard numeric grouping, provided the
// two inputs are standard numbers.
var DataSeriesComputedFieldDifference = DataSeriesComputedFieldBase.extend({
    destinationField: "",
    minuendField: "",
    subtrahendField: "",

    type: "number",

    addFieldsTo: function(fields) {
        var destinationField = this.destinationField,
        type = this.type;

        if (destinationField && type) {
            fields[destinationField] = type;
        }
        return fields;
    },
    rowCompute: function(row) {
        var destinationField = this.destinationField,
            minuendField = this.minuendField,
            subtrahendField = this.subtrahendField,
            minuend = row[minuendField],
            subtrahend = row[subtrahendField];

        if ((minuend === null || minuend === undefined) &&
            (subtrahend === null || subtrahend === undefined)) {
            return row;
        }

        minuend = (+minuend || 0.0);
        subtrahend = (+subtrahend || 0.0);

        row[destinationField] = minuend - subtrahend;
        return row;
    }
});

DataSeriesComputedFieldDifference.reopenClass({
    fieldSetup: function(destinationField, minuendField, subtrahendField) {
        return DataSeriesComputedFieldDifference.create({
            destinationField: destinationField,
            minuendField: minuendField,
            subtrahendField: subtrahendField,
        });
    }
});

export default DataSeriesComputedFieldDifference;
