import Ember from 'ember';

export function ordinalSuffixed(params, namedArgs) {
  var mod = parseInt(params[0]) % 10;

    if (mod === 1 && params[0] !== 11) {
        return params[0] + "st";
    } else if (mod === 2 && params[0] !== 12) {
        return params[0] + "nd";
    } else if (mod === 3 && params[0] !== 13) {
        return params[0] + "rd";
    }
    return params[0] + "th";
}

export default Ember.Helper.helper(ordinalSuffixed);
