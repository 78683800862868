import Component from '@ember/component';
import {computed, action} from "@ember/object";
import TrendChartInteractiveField from "infegy-frontend/models/trends/trend-chart-interactive-field";
import classic from "ember-classic-decorator";

@classic
export default class extends Component {
    fields = null;

    // TrendChartMeta instance from "shared/models/trends/trend-chart-meta"
    trendChartMeta = null;
    allowOptions = false;

    hideDetails = false;
    inlineDetails = true;

    skipQueryGrouping = false;
    isMultiQuery = false;

    isZoomable = false;

    chartHeight = 200;

    selectedViewOption = "Field Stats";

    showEventIcons = false;
    selectedEvent = null;

    hideFilterdown = false;

    chartType = "line";

    groupingOptions = [];

    chartTypeOptions = [{
        icon: "chart-line",
        value: "line"
    },{
        icon: "chart-bar-stacked",
        value: "bars"
    },{
        icon: "chart-line-stacked",
        value: "area"
    },{
        icon: "elevation-rise",
        value: "stream"
    }];

    isCanvasChart = false;
    @computed("isCanvasChart")
    get paddingOverride() {
        return this.isCanvasChart ? "padding: 0;".htmlSafe() : "";
    }

    @computed('trendChartMeta.normalizeStartTimes', 'hasMixedTimespans')
    get normalizeStartTimes() {
        return this.hasMixedTimespans && this.trendChartMeta?.normalizeStartTimes;
    }

    @computed.or("isCustomTrends", "isMultiQuery") hideExtras;

    @computed("hideExtras")
    get viewOptions() {
        if (this.hideExtras) {
            return ["Field Stats", "Table"];
        }
        return ["Field Stats", "Table", "Filtered Posts"];
    }


    @computed("selectedViewOption", "hideExtras", "viewOptions")
    get activeViewOption() {
        if (!this.selectedViewOption || !this.viewOptions.includes(this.selectedViewOption)) {
            return this.viewOptions[0];
        }
        return this.selectedViewOption;
    }

    @Ember.computed.equal("activeViewOption", "Filtered Posts") filteredPostsViewSelected;
    @Ember.computed.equal("activeViewOption", "Field Stats") fieldStatsViewSelected;
    @Ember.computed.equal("activeViewOption", "Table") tableViewSelected;

    @computed("fields", "fields.[]", "fields.@each.data", "fields.@each.isLoaded")
    get fieldsAreLoaded() {
        let fields = this.fields;
        if (!fields || !fields.length) {
          return false;
        }
        let unloaded = this.fields.find(field => {
            return !(field?.isLoaded || !Ember.isEmpty(field.data));
        });
        return !unloaded;
    }

    @computed("fields", "fields.[]", "fields.@each.data", "fields.@each.isLoaded",
              "trendChartMeta", "trendChartMeta.chartType", "trendChartMeta.groupingOverride",
              "trendChartMeta.grouping", "fields.@each.chartMaxY", "defaultGrouping",
              "normalizeStartTimes", "fieldsAreLoaded", "chartType")
    get allFields() {
        let trendChartMeta = this.trendChartMeta,
            normalizeStartTimes = this.normalizeStartTimes;
        if (!trendChartMeta || Ember.isEmpty(this.fields) || !this.fieldsAreLoaded) {
            return [];
        }
        let chartType = this.chartType;

        // Update fields axis info
        this.findAxisMaxYs(this.fields);

        let stats = this.fields.reduce((memo, row) => {
            memo.maxX = memo.maxX < row.maxX ? row.maxX : memo.maxX;
            memo.minX = memo.minX > row.minX ? row.minX : memo.minX;
            memo.maxCount = memo.maxCount < row.rowCount ? row.rowCount : memo.maxCount;
            return memo;
        }, {maxCount: 0, minX: Infinity, maxX: -Infinity});

        let trendFields = (this.fields || []).map(field => {
                let newInteractedField = TrendChartInteractiveField.create();
                newInteractedField.set("field", field);
                newInteractedField.set("trendChartMeta", trendChartMeta);
                newInteractedField.set("maxFieldRowCount", stats.maxCount);
                newInteractedField.set("maxFieldXValue", stats.maxX);
                newInteractedField.set("minFieldXValue", stats.minX);
                newInteractedField.set("chartType", chartType);
                newInteractedField.set("normalizeStartTimes", normalizeStartTimes)
                return newInteractedField;
            });
        let rowMap = {};

        (trendFields || []).forEach((field, fieldIndex) => {
            let chartData = field.chartData || [];
            chartData.forEach(dataRow => {
                if (!rowMap[dataRow.stackingValue]) {
                    rowMap[dataRow.stackingValue] = {
                      x: dataRow.x,
                      xPct: dataRow.xPct,
                      stackValue: dataRow.stackingValue,
                      fieldRows: {}
                    };
                }
                let resultRow = rowMap[dataRow.stackingValue];
                resultRow.x = Math.min(resultRow.x, dataRow.x);
                resultRow.fieldRows[fieldIndex] = dataRow;
            });
        });
        let index = 0,
            stackedFields = Object.values(rowMap).sortBy("x");
        stackedFields.forEach((row, idx) => row.index = idx);
        rowMap = {};

        trendFields.forEach((trendField, fieldIndex) => {
            let firstRow = stackedFields.find(row => {
                return Object.values(row?.fieldRows)?.find(innerField => innerField.field === trendField);
            })
            trendField.set("allFieldsRowCount", stackedFields.length);
            trendField.set("fieldStartIndex", firstRow?.index || 0);

            // Now that we have stacked stats, rebuild
            let chartData = (trendField.chartData || []).sortBy("x");

            chartData.forEach(dataRow => {
                if (!rowMap[dataRow.stackingValue]) {
                    rowMap[dataRow.stackingValue] = {
                      x: dataRow.x,
                      xPct: dataRow.xPct,
                      stackValue: dataRow.stackingValue,
                      fieldRows: {}
                    };
                }
                let resultRow = rowMap[dataRow.stackingValue];
                resultRow.x = Math.min(resultRow.x, dataRow.x);
                resultRow.fieldRows[fieldIndex] = dataRow;
            });
        });
        index = 0;
        stackedFields = Object.values(rowMap).sortBy("x");
        stackedFields.forEach((row, idx) => row.index = idx);

        return {
            stackedFields: stackedFields,
            trendFields: trendFields
        }
    }

    @computed("allFields", "allFields.[]")
    get trendFields() {
        return this.allFields?.trendFields || [];
    }

    @computed("allFields", "allFields.[]")
    get stackedFields() {
        return this.allFields?.stackedFields || [];
    }

    @computed("trendFields", "trendFields.[]")
    get zoomedFields() {
        let timespan = dateRange.shortestRange,
            grouping = QueryDataGrouping.findAPIGrouping(timespan);
    }

    findAxisMaxYs(fields) {
        let axisMaxYs = {};

        fields.forEach(field => {
            let axisGroup = field.axisGroup || field.fieldName;
            if (!axisMaxYs[axisGroup] || field.maxY > axisMaxYs[axisGroup]) {
                axisMaxYs[axisGroup] = field.maxY;
            }
        });

        fields.forEach(field => {
            let axisGroup = field.axisGroup || field.fieldName;
            field.set("axisMaxY", axisMaxYs[axisGroup]);
        });
    }

    @computed("trendFields", "trendFields.@each.startX", "trendFields.@each.endX")
    get xRange() {
        let fields = this.trendFields || [],
            range = fields.reduce((memo, field) => {
                memo.start = Math.min(memo.start, field.startX || 0);
                memo.end = Math.max(memo.end, field.endX || 1);
                return memo;
            }, {start: Infinity, end: -Infinity});
        return range;
    }

    @computed("xRange", "trendChartMeta.brushStartXPct", "trendChartMeta.brushEndXPct")
    get brushedXRange() {
        let fields = this.trendFields || [],
            range = fields.reduce((memo, field) => {
                memo.start = Math.min(memo.start, field.startX || 0);
                memo.end = Math.max(memo.end, field.endX || 1);
                return memo;
            }, {start: Infinity, end: -Infinity});
        return range;
    }

    @computed("fields", "fields.[]")
    get hasMixedTimespans() {
        if (!this.fields || this.fields.length < 2) {
            return false;
        }
        let firstQuery = this.fields[0]?.query?.queryInfo,
            earliest = firstQuery?.startDate?.timestamp,
            latest = firstQuery?.endDate?.timestamp,
            hasMixed = !!this.fields.slice(1).find(field => {
                let fieldQuery = field?.query?.queryInfo;
                return earliest !== fieldQuery?.startDate?.timestamp ||
                  latest !== fieldQuery?.endDate?.timestamp;
            });
        return hasMixed;
    }

    @computed("showEventIcons", "isMultiQuery", "trendFields.firstObject.field.query")
    get eventsQuery() {
        let query = this.trendFields?.firstObject?.field?.query;
        if (!this.isMultiQuery && this.showEventIcons) {
            return query;
        }
    }

    @computed("showEvents", "eventsQuery.events")
    get atlasEvents() {
        return this.showEvents && this.eventsQuery.events;
    }

    @computed("eventsQuery", "hasMixedTimespans", "showEventIcons", "trendChartMeta.showEvents", "isMultiQuery")
    get showEvents() {
        return this.showEventIcons && !this.hasMixedTimespans &&
          !this.isMultiQuery && this.trendChartMeta?.showEvents && this.eventsQuery;
    }

    @computed("trendChartMeta.isSelected", "trendChartMeta.isBrushed")
    get showDetailedEvents() {
        let meta = this.trendChartMeta;
        return this.showEvents && (meta.isSelected || meta.isBrushed);
    }

    @computed("showEvents", "eventsQuery.updated", "atlasEvents.data.dataSeries")
    get displayedEvents() {
        if (!this.showEvents) {
            return;
        }
        const events = this.atlasEvents?.data || [];
        const displayedEvents = events.filter((event) => {
            return event.attributeType == "share" || event.attributeType == "hashtag";
        });
        return displayedEvents;
    }

    getEventDataRange(data, field, min, max) {
        if (Ember.isEmpty(min) || Ember.isEmpty(max) || Ember.isEmpty(field) || max < min) {
            return data || [];
        }
        var rangeValue;
        var eventDataRange = (data || []).filter(function(topic) {
            rangeValue = Ember.get(topic, field);
            return rangeValue <= max && rangeValue >= min;
        }).sortBy("score").reverse();

        return eventDataRange;
    }

    filterAllEvents(data, filterField, min, max) {
        data = data || {};
        return Ember.Object.create({
            events: this.getEventDataRange(data, filterField, min, max),
        });
    }


    @computed("innerGroupByField", "displayedEvents",
            "trendChartMeta.isSelected", "trendChartMeta.isHovered",
            "trendFields.firstObject.selectedDateRange",
            "trendFields.firstObject.hoveredInfo")
    get interactedEventData() {
        let trendField = this.trendFields?.firstObject,
            data = this.atlasEvents?.data || [],
            field = "timestamp",
            filterArgs = trendField?.selectedDateRange,
            hoveredInfo = trendField?.hoveredInfo;

        if (this.trendChartMeta.isHovered && hoveredInfo) {
            data = this.filterAllEvents(data, field, hoveredInfo.xValue, hoveredInfo.xValue);
        } else if (filterArgs && this.trendChartMeta.isSelected) {
            data = this.filterAllEvents(data, field, filterArgs.start, filterArgs.start);
        } else if (filterArgs) {
            data = this.filterAllEvents(data, field, filterArgs.start, filterArgs.end);
        }
        return data;
    }

    @computed("fields", "fields.@each.query", "fields.@each.apiName")
    get fieldLoaderInfo() {
        let trendFields = this.fields || [],
            fieldAPIModels = trendFields.map(field => {
                return field?.queryApi;
            }).uniq();
        return fieldAPIModels;
    }

    chartTypeChanged() { /* empty for action */ }

    trendChartMetaUpdated() { /* empty for action */ }

    @action
    trendChartMetaUpdating(meta) {
        this.trendChartMetaUpdated(meta);
    }

    chartTypeChanged() { /* empty for action */ }
    @action
    chartTypeChanging(chartType) {
        if (this.chartType !== chartType) {
            this.trendChartMeta.chartType = chartType;
            this.chartTypeChanged(chartType);
        }
    }

    trendChartMetaUpdated() { /* empty for action */ }

    normalizeStartTimesChanged() { /* empty for action */ }
    groupingChanged() { /* empty for action */ }

    @action
    toggleNormalizeStartTimes(toggle) {
        this.normalizeStartTimesChanged(toggle);
    }

    @action
    toggleOptions() {
        this.set("optionsAreOpen", !this.optionsAreOpen);
    }

    @action
    trendEventClicked(event) {
        this.set("clickedEvent", event); // (null | event)
    }
};
