 export default {
    "householdSize": {
        min: 2.355,
        max: 3.0
    },
    "medianHouseValue": {
        min: 47476,
        max: 260979
    },
    "medianHouseholdIncome": {
        min: 31756,
        max: 68912
    },
    "medianDisposableIncome": {
        min: 10969,
        max: 38221
    },
    "medianEducation": {
        min: 0.314,
        max: 0.658
    },
};
