import Ember from "ember";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import Query from "infegy-frontend/models/users/query";

export default Ember.Controller.extend({
    application: Ember.inject.controller(),
    atlas: Ember.inject.controller(),

    activeQuery: null,
    queryInfo: Ember.computed.alias("activeQuery.queryInfo"),
    audienceSegments: Ember.computed.alias('application.user.audienceSegments'),
    user: Ember.computed.alias('application.user'),

    minHits: null,
    maxHits: null,
    maxResults: 10000,

    subQuery: null,
    allowPosts: false,
    isRunning: false,
    isDone: false,
    errorMessage: "",
    warningMessage: "",
    warningDebounce: null,

    resultsFound: 0,
    resultsPassed: 0,

    showPosts: Ember.computed("allowPosts", "subQuery", {
        get: function() {
            var allowPosts = this.allowPosts;
            var subQuery = this.subQuery;

            if (!allowPosts || !subQuery) {
                return false;
            }
            return true;
        },
    }),

    postQueries: Ember.computed("subQuery", function() {
        return [this.subQuery];
    }),

    numPostsPerPage: 10,

    sortingActive: 'matches',
    sortingOptions: [
        Ember.Object.create({
            label: 'Matches',
            value: 'matches'
        }),
        Ember.Object.create({
            label: 'Influence',
            value: 'influence'
        }),
        Ember.Object.create({
            label: 'Algorithm',
            value: 'algorithm'
        })
    ],

    createWatchlistName: null,
    audienceSegmentActive: -1,
    availableAudienceSegments: Ember.computed("audienceSegments",
        "audienceSegments.[]", {
            get: function() {
                var audienceSegments = this.audienceSegments;
                if (!audienceSegments) {
                    return [];
                }

                var options = audienceSegments.map(function(item) {
                    return Ember.Object.create({
                        label: item.get('name'),
                        value: item.get('id')
                    });
                });
                options.push(Ember.Object.create({
                    label: "None",
                    value: -1
                }));

                return options;
            },
        }),

    createSubQuery: function() {
        let query = Query.create();
        query.loadJSON(this.activeQuery.toJSON({fieldGroups: "api"}));
        this.set("subQuery", query);
    },

    runBigList: function(watchlistId, watchlistName) {
        var requestParams = {
                "watchlist_id" : watchlistId
            };

        if (watchlistId < 0) {
            if (Ember.isEmpty(watchlistName)) {
                this.setWarningMessage("Please select a watchlist or enter a new watchlist name.");
                return;
            } else {
                requestParams["watchlist_name"] = watchlistName;
            }
        }

        if(!Ember.isEmpty(this.maxHits)){
            requestParams["max_hits"] = this.maxHits;
        }

        if(!Ember.isEmpty(this.minHits)){
            requestParams["min_hits"] = this.minHits;
        }

        var bigListURL = 'api/v3/biglist.json?' + this.activeQuery.toAPIQueryString();

        this.set('isRunning', true);
        var dfd = AtlasAuth.Post({
            url: bigListURL,
            context: this,
            data: requestParams
        });

        dfd.catch(function(error) {
            if(error.status === 403){
                this.transitionToRoute('login');
            }

            this.setProperties({
                isRunning: false,
                isDone: false,
                resultsPassed: 0,
                resultsFound: 0,
                errorMessage: error.atlasErrorText
            });

            Ember.run.later(this, function() {
                console.warn('Unable to utilize biglist endpoint.');
            }, 1000);
            Ember.run.later(this, function() {
                this.set("errorMessage", "");
            }, 10000)
        });

        dfd.then((data) => {
            this.setProperties({
                isDone: true,
                resultsPassed: data.output.passed,
                resultsFound: data.output.found
            });

            this.audienceSegments.load();
            Ember.run.later(this, function() {
                this.setProperties({
                    isRunning: false,
                    isDone: false,
                    resultsPassed: 0,
                    resultsFound: 0
                });
            }, 5000);
        });
    },

    setup: Ember.on("init", function(){
        let newQuery = Query.create();
        newQuery.queryInfo.loadJSON({
            queryType: "advanced",
            startDate: '3 months ago',
            endDate: 'now'
        });
        this.set("activeQuery", newQuery);
    }),

    setWarningMessage(message) {
        if (this.warningDebounce) {
            Ember.run.cancel(this.warningDebounce);
        }
        const warningDebounce = Ember.run.debounce(() => {
            this.set("warningMessage", "");
        }, 10000);
        this.setProperties({
            warningMessage: message,
            warningDebounce
        });
    },

    actions: {
        selectedAudienceSegmentChanged: function(value) {
            this.set("audienceSegmentActive", value);
        },
        submit: async function() {
            this.set("warningMessage", "");
            await this.activeQuery._doValidate(true);
            if (this.activeQuery.isRunnable) {
                this.runBigList(this.audienceSegmentActive, this.createWatchlistName);
            } else {
                this.setWarningMessage("Unable to run Biglist.  Please check your query for possible errors and try again.");
            }
        },

        closeModal: function() {
            this.set("isRunning", false);
        },

        showPosts: function() {
            Ember.beginPropertyChanges();
            this.createSubQuery();
            this.set('allowPosts', true);
            Ember.endPropertyChanges();
        },

        hidePosts: function() {
            this.setProperties({
                'allowPosts': false,
                'subQuery': null
            });
        },
        activeFormFilterNamesChanged: function(activeFormFilterNames){
          this.set("queryInfo.activeFormFilterNames",activeFormFilterNames);
        }
    },
});
