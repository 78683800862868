import Ember from "ember";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import QueryAlert from "infegy-frontend/models/users/query-alert";

export default Ember.Controller.extend({
    application: Ember.inject.controller(),
    user: Ember.computed.alias("application.user"),
    
    blankAlert: null,
    errorMessage: null,
    
    createQueryAlert: function(queryAlert) {
        let alerts = this.get("user.queryAlerts");
        AtlasAuth.Post({
            url: "api/v3/alert_query",
            data: queryAlert.toJSON()
        }).catch((res)=>{
            if(res.status_message){
                this.set("errorMessage", res.status_message);
            } else {
                this.set("errorMessage", "There was an error saving your query alert");
            }
        }).then((res) => {
            if(res) {
                let newQueryAlert = QueryAlert.create();
                newQueryAlert.loadJSON(res.output[0]);
                alerts.pushObject(newQueryAlert);
                this.set("isCreating",false);
                this.transitionToRoute("atlas.tools.alerts");
            }
        });
    },

    setup: Ember.on("init",function(){
        let newAlert = QueryAlert.create(),
            email = this.get("user.email");
        if(email){
            newAlert.emails.push(email);
        }
        this.set("blankAlert", QueryAlert.create());

    }),

    addEmail: Ember.on("didInsertElement",function(){
        let alert = this.blankAlert,
            email = this.get("user.email");
        if(email && alert){
            alert.emails.pushObject(email);
        }
    }),

    actions: {
        deleteAlert(alert) {
            this.deleteAlert(alert);    
        },
        cancel() {
            this.transitionToRoute("atlas.tools.alerts");
        },
        submit(queryAlert) {
            this.createQueryAlert(queryAlert);
        }
    }
});
