/* jshint maxlen: false */
// var CountriesJSONUncategorized =
// { "title": "Uncategorized", "code": "00", "alternates": ["Uncategorized"] };

var CountriesJSON = [
    { "title": "Afghanistan", "code": "AF", "region": "AS", "alternates": ["Afġānistān"] },
    { "title": "Åland Islands", "code": "AX", "region": "EU", "alternates": ["Aaland", "Aland"] },
    { "title": "Albania", "code": "AL", "region": "EU", "alternates": ["Shqipëri", "Shqipëria", "Shqipnia"] },
    { "title": "Algeria", "code": "DZ", "region": "AF", "alternates": ["al-Jazā'ir"] },
    { "title": "American Samoa", "code": "AS", "region": "OC", "alternates": ["Amerika Sāmoa"] },
    { "title": "Andorra", "code": "AD", "region": "EU" },
    { "title": "Angola", "code": "AO", "region": "AF" },
    { "title": "Anguilla", "code": "AI", "region": "NA" },
    { "title": "Antarctica", "code": "AQ", "region": "AN" },
    { "title": "Antigua and Barbuda", "code": "AG", "region": "NA" },
    { "title": "Argentina", "code": "AR", "region": "SA" },
    { "title": "Armenia", "code": "AM", "region": "AS", "alternates": ["Hayastan"] },
    { "title": "Aruba", "code": "AW", "region": "NA" },
    { "title": "Australia", "code": "AU", "region": "OC" },
    { "title": "Austria", "code": "AT", "region": "EU", "alternates": ["Österreich", "Osterreich", "Oesterreich"] },
    { "title": "Azerbaijan", "code": "AZ", "region": "AS" },
    { "title": "Bahamas", "code": "BS", "region": "NA" },
    { "title": "Bahrain", "code": "BH", "region": "AS" },
    { "title": "Bangladesh", "code": "BD", "region": "AS" },
    { "title": "Barbados", "code": "BB", "region": "NA" },
    { "title": "Belarus", "code": "BY", "region": "EU" },
    { "title": "Belgium", "code": "BE", "region": "EU", "alternates": ["België", "Belgie", "Belgien", "Belgique"] },
    { "title": "Belize", "code": "BZ", "region": "NA" },
    { "title": "Benin", "code": "BJ", "region": "AF" },
    { "title": "Bermuda", "code": "BM", "region": "NA" },
    { "title": "Bhutan", "code": "BT", "region": "AS" },
    { "title": "Bolivia", "code": "BO", "region": "SA" },
    { "title": "Bosnia and Herzegovina", "code": "BA", "region": "EU" },
    { "title": "Botswana", "code": "BW", "region": "AF" },
    { "title": "Bouvet Island", "code": "BV", "region": "AN", "alternates": ["Bouvetøya"] },
    { "title": "Brazil", "code": "BR", "region": "SA", "alternates": ["Brasil"] },
    { "title": "British Indian Ocean Territory", "code": "IO", "region": "AS" },
    { "title": "Brunei Darussalam", "code": "BN", "region": "AS" },
    { "title": "Bulgaria", "code": "BG", "region": "EU" },
    { "title": "Burkina Faso", "code": "BF", "region": "AF" },
    { "title": "Burundi", "code": "BI", "region": "AF" },
    { "title": "Cambodia", "code": "KH", "region": "AS" },
    { "title": "Cameroon", "code": "CM", "region": "AF" },
    { "title": "Canada", "code": "CA", "region": "NA" },
    { "title": "Cape Verde", "code": "CV", "region": "AF", "alternates": ["Cabo"] },
    { "title": "Cayman Islands", "code": "KY", "region": "NA" },
    { "title": "Central African Republic", "code": "CF", "region": "AF" },
    { "title": "Chad", "code": "TD", "region": "AF", "alternates": ["Tchad"] },
    { "title": "Chile", "code": "CL", "region": "SA" },
    { "title": "China", "code": "CN", "region": "AS", "alternates": ["Zhongguo", "Zhonghua", "Peoples Republic"] },
    { "title": "Christmas Island", "code": "CX", "region": "AS" },
    { "title": "Cocos (Keeling) Islands", "code": "CC", "region": "AS" },
    { "title": "Colombia", "code": "CO", "region": "SA" },
    { "title": "Comoros", "code": "KM", "region": "AF" },
    { "title": "Republic of the Congo", "code": "CG", "region": "AF" },
    { "title": "Democratic Republic of the Congo", "code": "CD", "region": "AF", "alternates": ["Congo-Brazzaville", "Repubilika ya Kongo"] },
    { "title": "Cook Islands", "code": "CK", "region": "OC" },
    { "title": "Costa Rica", "code": "CR", "region": "NA" },
    { "title": "Côte d'Ivoire", "code": "CI", "region": "AF", "alternates": ["Cote", "dIvoire"] },
    { "title": "Croatia", "code": "HR", "region": "EU", "alternates": ["Hrvatska"] },
    { "title": "Cuba", "code": "CU", "region": "NA" },
    { "title": "Cyprus", "code": "CY", "region": "AS", "alternates": ["Kýpros"] },
    { "title": "Czech Republic", "code": "CZ", "region": "EU", "alternates": ["Ceska"] },
    { "title": "Denmark", "code": "DK", "region": "EU", "alternates": ["Danmark"] },
    { "title": "Djibouti", "code": "DJ", "region": "AF", "alternates": ["Jabuuti", "Gabuuti"] },
    { "title": "Dominica", "code": "DM", "region": "NA", "alternates": ["Dominique"] },
    { "title": "Dominican Republic", "code": "DO", "region": "NA" },
    { "title": "Ecuador", "code": "EC", "region": "SA" },
    { "title": "Egypt", "code": "EG", "region": "AF" },
    { "title": "El Salvador", "code": "SV", "region": "NA" },
    { "title": "Equatorial Guinea", "code": "GQ", "region": "AF" },
    { "title": "Eritrea", "code": "ER", "region": "AF" },
    { "title": "Estonia", "code": "EE", "region": "EU", "alternates": ["Eesti"] },
    { "title": "Ethiopia", "code": "ET", "region": "AF" },
    { "title": "Falkland Islands (Malvinas)", "code": "FK", "region": "SA" },
    { "title": "Faroe Islands", "code": "FO", "region": "EU", "alternates": ["Føroyar", "Færøerne"] },
    { "title": "Fiji", "code": "FJ", "region": "OC", "alternates": ["Viti"] },
    { "title": "Finland", "code": "FI", "region": "EU", "alternates": ["Suomi"] },
    { "title": "France", "code": "FR", "region": "EU", "alternates": ["République française"] },
    { "title": "French Guiana", "code": "GF", "region": "SA" },
    { "title": "French Polynesia", "code": "PF", "region": "OC", "alternates": ["Polynésie française"] },
    { "title": "French Southern Territories", "code": "TF", "region": "AN" },
    { "title": "Gabon", "code": "GA", "region": "AF", "alternates": ["République Gabonaise"] },
    { "title": "Gambia", "code": "GM", "region": "AF" },
    { "title": "Georgia", "code": "GE", "region": "AS" },
    { "title": "Germany", "code": "DE", "region": "EU", "alternates": ["Bundesrepublik", "Deutschland"] },
    { "title": "Ghana", "code": "GH", "region": "AF" },
    { "title": "Gibraltar", "code": "GI", "region": "EU" },
    { "title": "Greece", "code": "GR", "region": "EU" },
    { "title": "Greenland", "code": "GL", "region": "NA", "alternates": ["grønland"] },
    { "title": "Grenada", "code": "GD", "region": "NA" },
    { "title": "Guadeloupe", "code": "GP", "region": "NA" },
    { "title": "Guam", "code": "GU", "region": "OC" },
    { "title": "Guatemala", "code": "GT", "region": "NA" },
    { "title": "Guernsey", "code": "GG", "region": "EU" },
    { "title": "Guinea", "code": "GN", "region": "AF" },
    { "title": "Guinea-Bissau", "code": "GW", "region": "AF" },
    { "title": "Guyana", "code": "GY", "region": "SA" },
    { "title": "Haiti", "code": "HT", "region": "NA" },
    { "title": "Heard Island and McDonald Islands", "code": "HM", "region": "AN" },
    { "title": "Vatican City", "code": "VA", "region": "EU" },
    { "title": "Honduras", "code": "HN", "region": "NA" },
    { "title": "Hong Kong", "code": "HK", "region": "AS" },
    { "title": "Hungary", "code": "HU", "region": "EU", "alternates": ["Magyarország"] },
    { "title": "Iceland", "code": "IS", "region": "EU", "alternates": ["Island"] },
    { "title": "India", "code": "IN", "region": "AS", "alternates": ["Hindustan"] },
    { "title": "Indonesia", "code": "ID", "region": "AS" },
    { "title": "Iran", "code": "IR", "region": "AS" },
    { "title": "Iraq", "code": "IQ", "region": "AS" },
    { "title": "Ireland", "code": "IE", "region": "EU", "alternates": ["Éire"] },
    { "title": "Isle of Man", "code": "IM", "region": "EU" },
    { "title": "Israel", "code": "IL", "region": "AS" },
    { "title": "Italy", "code": "IT", "region": "EU", "alternates": ["Italia"] },
    { "title": "Jamaica", "code": "JM", "region": "NA" },
    { "title": "Japan", "code": "JP", "region": "AS", "alternates": ["Nippon", "Nihon"] },
    { "title": "Jersey", "code": "JE", "region": "EU" },
    { "title": "Jordan", "code": "JO", "region": "AS" },
    { "title": "Kazakhstan", "code": "KZ", "region": "AS" },
    { "title": "Kenya", "code": "KE", "region": "AF" },
    { "title": "Kiribati", "code": "KI", "region": "OC" },
    { "title": "Kosovo", "code": "XK", "region": "EU" },
    { "title": "Kuwait", "code": "KW", "region": "AS" },
    { "title": "Kyrgyzstan", "code": "KG", "region": "AS" },
    { "title": "Laos", "code": "LA", "region": "AS" },
    { "title": "Latvia", "code": "LV", "region": "EU", "alternates": ["Latvija"] },
    { "title": "Lebanon", "code": "LB", "region": "AS" },
    { "title": "Lesotho", "code": "LS", "region": "AF" },
    { "title": "Liberia", "code": "LR", "region": "AF" },
    { "title": "Libya", "code": "LY", "region": "AF" },
    { "title": "Liechtenstein", "code": "LI", "region": "EU" },
    { "title": "Lithuania", "code": "LT", "region": "EU", "alternates": ["Lietuva"] },
    { "title": "Luxembourg", "code": "LU", "region": "EU" },
    { "title": "Macao", "code": "MO", "region": "AS" },
    { "title": "Macedonia", "code": "MK", "region": "EU" },
    { "title": "Madagascar", "code": "MG", "region": "AF", "alternates": ["Madagasikara"] },
    { "title": "Malawi", "code": "MW", "region": "AF" },
    { "title": "Malaysia", "code": "MY", "region": "AS" },
    { "title": "Maldives", "code": "MV", "region": "AS" },
    { "title": "Mali", "code": "ML", "region": "AF" },
    { "title": "Malta", "code": "MT", "region": "EU" },
    { "title": "Marshall Islands", "code": "MH", "region": "OC" },
    { "title": "Martinique", "code": "MQ", "region": "NA" },
    { "title": "Mauritania", "code": "MR", "region": "AF" },
    { "title": "Mauritius", "code": "MU", "region": "AF" },
    { "title": "Mayotte", "code": "YT", "region": "AF" },
    { "title": "Mexico", "code": "MX", "region": "NA", "alternates": ["Mexicanos"] },
    { "title": "Micronesia", "code": "FM", "region": "OC" },
    { "title": "Moldova", "code": "MD", "region": "EU" },
    { "title": "Monaco", "code": "MC", "region": "EU" },
    { "title": "Mongolia", "code": "MN", "region": "AS" },
    { "title": "Montenegro", "code": "ME", "region": "EU" },
    { "title": "Montserrat", "code": "MS", "region": "NA" },
    { "title": "Morocco", "code": "MA", "region": "AF" },
    { "title": "Mozambique", "code": "MZ", "region": "AF", "alternates": ["Moçambique"] },
    { "title": "Myanmar (Burma)", "code": "MM", "region": "AS" },
    { "title": "Namibia", "code": "NA", "region": "AF", "alternates": ["Namibië"] },
    { "title": "Nauru", "code": "NR", "region": "OC", "alternates": ["Naoero"] },
    { "title": "Nepal", "code": "NP", "region": "AS" },
    { "title": "Netherlands", "code": "NL", "region": "EU", "alternates": ["Holland", "Nederland"] },
    { "title": "New Caledonia", "code": "NC", "region": "OC" },
    { "title": "New Zealand", "code": "NZ", "region": "OC", "alternates": ["Aotearoa"] },
    { "title": "Nicaragua", "code": "NI", "region": "NA" },
    { "title": "Niger", "code": "NE", "region": "AF", "alternates": ["Nijar"] },
    { "title": "Nigeria", "code": "NG", "region": "AF", "alternates": ["Nijeriya", "Naíjíríà"] },
    { "title": "Niue", "code": "NU", "region": "OC" },
    { "title": "Norfolk Island", "code": "NF", "region": "OC" },
    { "title": "North Korea", "code": "KP", "region": "AS" },
    { "title": "Northern Mariana Islands", "code": "MP", "region": "OC" },
    { "title": "Norway", "code": "NO", "region": "EU", "alternates": ["Norge", "Noreg"] },
    { "title": "Oman", "code": "OM", "region": "AS" },
    { "title": "Pakistan", "code": "PK", "region": "AS" },
    { "title": "Palau", "code": "PW", "region": "OC" },
    { "title": "Palestinian Territory", "code": "PS", "region": "AS" },
    { "title": "Panama", "code": "PA", "region": "NA" },
    { "title": "Papua New Guinea", "code": "PG", "region": "OC" },
    { "title": "Paraguay", "code": "PY", "region": "SA" },
    { "title": "Peru", "code": "PE", "region": "SA" },
    { "title": "Philippines", "code": "PH", "region": "AS", "alternates": ["Pilipinas"] },
    { "title": "Pitcairn", "code": "PN", "region": "OC" },
    { "title": "Poland", "code": "PL", "region": "EU", "alternates": ["Polska"] },
    { "title": "Portugal", "code": "PT", "region": "EU", "alternates": ["Portuguesa"] },
    { "title": "Puerto Rico", "code": "PR", "region": "NA" },
    { "title": "Qatar", "code": "QA", "region": "AS" },
    { "title": "Réunion", "code": "RE", "region": "AF", "alternates": ["Reunion"] },
    { "title": "Romania", "code": "RO", "region": "EU", "alternates": ["Rumania", "Roumania", "România"] },
    { "title": "Russian Federation", "code": "RU", "region": "EU", "alternates": ["Rossiya"] },
    { "title": "Rwanda", "code": "RW", "region": "AF" },
    { "title": "Saint Barthélemy", "code": "BL", "region": "NA", "alternates": ["St. Barthelemy"] },
    { "title": "Saint Helena", "code": "SH", "region": "AF", "alternates": ["St."] },
    { "title": "Saint Kitts and Nevis", "code": "KN", "region": "NA", "alternates": ["St."] },
    { "title": "Saint Lucia", "code": "LC", "region": "NA", "alternates": ["St."] },
    { "title": "Saint Martin", "code": "MF", "region": "NA", "alternates": ["St."] },
    { "title": "Saint Pierre and Miquelon", "code": "PM", "region": "NA", "alternates": ["St."] },
    { "title": "Saint Vincent and the Grenadines", "code": "VC", "region": "NA", "alternates": ["St."] },
    { "title": "Samoa", "code": "WS", "region": "OC" },
    { "title": "San Marino", "code": "SM", "region": "EU" },
    { "title": "São Tomé and Príncipe", "code": "ST", "region": "AF" },
    { "title": "Saudi Arabia", "code": "SA", "region": "AS" },
    { "title": "Senegal", "code": "SN", "region": "AF", "alternates": ["Sénégal"] },
    { "title": "Serbia", "code": "RS", "region": "EU", "alternates": ["Srbija"] },
    { "title": "Seychelles", "code": "SC", "region": "AF" },
    { "title": "Sierra Leone", "code": "SL", "region": "AF" },
    { "title": "Singapore", "code": "SG", "region": "AS", "alternates": ["Singapura"] },
    { "title": "Slovakia", "code": "SK", "region": "EU", "alternates": ["Slovenská", "Slovensko"] },
    { "title": "Slovenia", "code": "SI", "region": "EU", "alternates": ["Slovenija"] },
    { "title": "Solomon Islands", "code": "SB", "region": "OC" },
    { "title": "Somalia", "code": "SO", "region": "AF" },
    { "title": "South Africa", "code": "ZA", "region": "AF", "alternates": ["RSA", "Suid-Afrika"] },
    { "title": "South Georgia", "code": "GS", "region": "AN" },
    { "title": "South Korea", "code": "KR", "region": "AS" },
    { "title": "South Sudan", "code": "SS", "region": "AF" },
    { "title": "Spain", "code": "ES", "region": "EU", "alternates": ["España"] },
    { "title": "Sri Lanka", "code": "LK", "region": "AS" },
    { "title": "Sudan", "code": "SD", "region": "AF" },
    { "title": "Suriname", "code": "SR", "region": "SA", "alternates": ["Sarnam", "Sranangron"] },
    { "title": "Svalbard and Jan Mayen", "code": "SJ", "region": "EU" },
    { "title": "Swaziland", "code": "SZ", "region": "AF", "alternates": ["weSwatini", "Swatini", "Ngwane"] },
    { "title": "Sweden", "code": "SE", "region": "EU", "alternates": ["Sverige"] },
    { "title": "Switzerland", "code": "CH", "region": "EU", "alternates": ["Swiss Confederation", "Schweiz", "Suisse", "Svizzera", "Svizra"] },
    { "title": "Syrian Arab Republic", "code": "SY", "region": "AS", "alternates": ["Syria"] },
    { "title": "Taiwan", "code": "TW", "region": "AS" },
    { "title": "Tajikistan", "code": "TJ", "region": "AS", "alternates": ["Toçikiston"] },
    { "title": "Tanzania", "code": "TZ", "region": "AF" },
    { "title": "Thailand", "code": "TH", "region": "AS", "alternates": ["Prathet", "Thai"] },
    { "title": "Timor-Leste", "code": "TL", "region": "AS" },
    { "title": "Togo", "code": "TG", "region": "AF", "alternates": ["Togolese"] },
    { "title": "Tokelau", "code": "TK", "region": "OC" },
    { "title": "Tonga", "code": "TO", "region": "OC" },
    { "title": "Trinidad and Tobago", "code": "TT", "region": "NA" },
    { "title": "Tunisia", "code": "TN", "region": "AF" },
    { "title": "Turkey", "code": "TR", "region": "AS", "alternates": ["Türkiye", "Turkiye"] },
    { "title": "Turkmenistan", "code": "TM", "region": "AS", "alternates": ["Türkmenistan"] },
    { "title": "Turks and Caicos Islands", "code": "TC", "region": "NA" },
    { "title": "Tuvalu", "code": "TV", "region": "OC" },
    { "title": "Uganda", "code": "UG", "region": "AF" },
    { "title": "Ukraine", "code": "UA", "region": "EU", "alternates": ["Ukrayina"] },
    { "title": "United Arab Emirates", "code": "AE", "region": "AS", "alternates": ["UAE"] },
    { "title": "United Kingdom", "code": "GB", "region": "EU", "alternates": ["Great Britain", "England", "UK", "Wales", "Scotland", "Northern Ireland"] },
    { "title": "United States", "code": "US", "region": "NA", "alternates": ["USA", "United States of America"] },
    { "title": "United States Minor Outlying Islands", "code": "UM", "region": "OC" },
    { "title": "Uruguay", "code": "UY", "region": "SA" },
    { "title": "Uzbekistan", "code": "UZ", "region": "AS", "alternates": ["O'zbekstan", "O‘zbekiston"] },
    { "title": "Vanuatu", "code": "VU", "region": "OC" },
    { "title": "Venezuela", "code": "VE", "region": "SA" },
    { "title": "Vietnam", "code": "VN", "region": "AS", "alternates": ["Viet", "Nam"] },
    { "title": "Virgin Islands (British)", "code": "VG", "region": "NA" },
    { "title": "Virgin Islands (U.S.)", "code": "VI", "region": "NA" },
    { "title": "Wallis and Futuna", "code": "WF", "region": "OC" },
    { "title": "Western Sahara", "code": "EH", "region": "AF" },
    { "title": "Yemen", "code": "YE", "region": "AS" },
    { "title": "Zambia", "code": "ZM", "region": "AF" },
    { "title": "Zimbabwe", "code": "ZW", "region": "AF" }
];

export default CountriesJSON;
