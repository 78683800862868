import Ember from "ember";
export default Ember.Component.extend({
    top: null,
    left: null,
    bottom: null,
    right: null,
    width: null,
    height: null,

    classNameBindings: [":tooltip", "tooltipHidden"],

    attributeBindings: ["style"],

    tooltipHidden: Ember.computed("tooltipIsVisible", "isOpened", {
        get: function(key) {
            return !this.tooltipIsVisible || !this.isOpened;
        },
    }),


    xPos: Ember.computed.alias("left"),
    yPos: Ember.computed.alias("top"),
    isOpened: true,
    tooltipIsVisible: null,

    style: Ember.computed("tooltipIsVisible", "tooltipHidden", "left", function() {
        if(this.tooltipHidden) {
          return new Ember.String.htmlSafe("display:none;");
        }

        var attrs = ["top", "left", "bottom", "right", "width", "height"];
        var style = attrs.reduce((memo, item) => {
            if (!Ember.isEmpty(this.get(item))) {
                var notNum = /[A-Za-z%]+/g;
                var value = this.get(item);
                if (notNum.test(value)) {
                    memo = [memo, item, ": ", value, "; "].join("");
                } else {
                    memo = [memo, item, ": ", value, "px; "].join("");
                }

            }
            return memo;
        }, "");

        return new Ember.String.htmlSafe(style);
    }),
});
