import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";

export default QueryAPIResults.extend({
    title : "linguisticsStats",
    description: DataSeriesDescription.load({
        "date": "datestring",
        "documents": "number",
        "subjectDocuments": "number",
        "wordCount": "number",
        "wordBytes": "number",
        "characterCount": "number",
        "sentences": "number",
        "subjectHits": "number",
        "subjectSentences": "number"
    })
});
