import Ember from "ember";
import CSSTools from 'infegy-frontend/utils/css-tools';

export default Ember.Component.extend({
    classNameBindings: [":query-item-small"],
    tagName: "span",

    query: null,

    queryTitle: Ember.computed("query", "query.title", "query.queryFilters.title", function () {
        return this.get("query.title") || this.get("query.queryFilters.title");
    }),

});
