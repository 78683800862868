import Ember from "ember";
import Component from '@ember/component';
import { computed } from '@ember/object';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import {dateGroupingAPIOptions} from "infegy-frontend/models/queries/query_apis";
import AtlasColors from "infegy-frontend/utils/atlas-colors";

export default class NarrativesQueriesContainer extends Component {
    // arguments
    queries = [];
    isLoaded = false;
    removedClusters;
    grouping = null;

    chartType = "line";
    selectedChartMetric = "universe";

    onRemoveCluster() {/* action */}
    onRenameCluster() {/* action */}
    onMergeActiveClusters() {/* action */}
    groupingChanged() { /* action */}

    // properties

    metricOptions = [{
        label: 'Universe',
        value: 'universe'
    },{
        label: 'Universe Distribution',
        value: 'universeDistribution'
    },{
        label: 'Posts',
        value: 'documents'
    }, {
        label: "Positive Distribution",
        value: "clusterPositiveDocumentsDistribution"
    }, {
        label: "Negative Distribution",
        value: "clusterNegativeDocumentsDistribution"
    }];

    selectedClusterIdx = null;
    hoveredClusterIdx = null;
    selectedDateIdx = null;
    selectedClusterKeys = [];
    selectedClusterColors = [];
    
    mergeClustersOpen = false;
    newClusterName = "";

    @action toggleMergeActiveClusters() {
        this.set("newClusterName", "");
        this.set("mergeClustersOpen", !this.mergeClustersOpen);
    }

    @action mergeActiveClusters() {
        if (this.selectedClusterKeys && this.newClusterName) {
            this.onMergeActiveClusters(this.selectedClusterKeys, this.newClusterName);
            this.set("mergeClustersOpen", false);
        }
    }

    onSelectedChartMetricChanged() { /* action */ }
    onChartTypeChanged() { /* action */ }

    @computed.oneWay("queries.firstObject.queryInfo.activeGrouping") selectedGrouping;

    @computed("queries.firstObject")
    get dateGroupingOptions(){
        return dateGroupingAPIOptions.findAvailableOptionsForQuery(this.queries.firstObject)
    }

    @computed("metricOptions", "selectedChartMetric") 
    get chartLabel() {
        let option = this.metricOptions.findBy("value", this.selectedChartMetric);
        return option?.label;
    }

    @computed("queries.[]")
    get query() {
        this.set("mergeClustersOpen", false);
        return this.queries[0];
    }

    @computed.mapBy("queries","narratives") narrativeAPIs;
    @computed.mapBy("narrativeAPIs", "narrativeData") narrativeDataArrays;

    @computed("selectedClusterKeys")
    get canAddToChart() {
        return !this.selectedClusterKeys || this.selectedClusterKeys?.length < 10;
    }

    @computed("queries", "narrativeDataArrays.@each.[]", "removedClusters")
    get allClusters(){
        let removedClusters = this.removedClusters || [];

        this.queries.forEach((query, queryIndex) => {
            let data = query?.narratives?.narrativeData || [];
            data.forEach(row => {
                if (row) {
                    row.set("_sourceQuery", query);
                    row.set("_sourceQueryIndex", queryIndex);
                }
            });
        });

        let allClusters = this.narrativeDataArrays.reduce((allClusters, narrativeData) => {
            return allClusters.concat(narrativeData);
        }, []);

        let hasMoreThanOneQuery = this.queries.length > 1;

        allClusters = allClusters.filter(cluster => {
            return !removedClusters.includes(cluster.key);
        });

        allClusters = allClusters.sortBy("dataSeries.stats.universe.sum").reverse().map((cluster) => {
            let clusterKey = cluster.key;
            if (hasMoreThanOneQuery) {
                clusterKey = `${cluster._sourceQueryIndex}-${clusterKey}`;  
            }
            return {
                key: clusterKey,
                source: cluster,
                data: cluster.dataSeries.data,
                dataSeries: cluster.dataSeries
            };
        });

        // pick a default selection of up to 6 clusters
        let allKeys = allClusters.mapBy("key"),
            selectedClusterKeys = this.selectedClusterKeys;

        // If the available selection does not include keys available in this query, pick the top 6 as a default
        if (!selectedClusterKeys || selectedClusterKeys.find(key => !allKeys.includes(key))) {
            selectedClusterKeys = allKeys.slice(0,6);
        }
        
        Ember.run.next(() => {
            this.set("selectedClusterKeys", selectedClusterKeys);
            this.set("isLoaded", true);
        });
        
        return allClusters;
    }

    @computed("selectedClusterKeys")
    get hasChartSelection() {
        return this.selectedClusterKeys?.length;
    }

    @computed("selectedClusterKeys", "allClusters")
    get selectedClusterData() {
        var clusters = this.allClusters,
            keys = this.selectedClusterKeys;

        return keys && clusters.filter((cluster)=>{
                return keys.includes(cluster.key);
            }).map((cluster, color_idx) => {
                return Object.assign({
                    color: AtlasColors.indexedBaseColors[color_idx]
                 }, cluster);
            });
    }

    @computed("selectedClusterIdx", "selectedClusterKeys")
    get selectedCluster(){
        if(!this.selectedClusterKeys.includes( this.selectedClusterIdx)){
            if(!isEmpty(this.selectedClusterIdx)){
                this.set("selectedClusterIdx", null);
            }
            return null;
        }
        return this.query.narratives.narrativeData[this.selectedClusterIdx];
    }

    @computed("selectedClusterIdx", "selectedClusterKeys")
    get selectedClusterColor(){
        let colorIdx = this.selectedClusterKeys.indexOf(this.selectedClusterIdx);
        return this.selectedClusterColors[colorIdx] || null;
    }

    @computed("hoveredClusterIdx", "selectedClusterKeys")
    get hoveredCluster(){
        if(!this.selectedClusterKeys.includes( this.hoveredClusterIdx)){
            if(!isEmpty(this.hoveredClusterIdx)){
                this.set("hoveredClusterIdx", null);
            }
            return null;
        }
        return Object.assign({ color: this.selectedClusterColors[this.selectedClusterKeys.indexOf(this.hoveredClusterIdx)] }, this.query.narratives.narrativeData[this.hoveredClusterIdx]);
    }

    selectedClusterKeysChanged() {};

    @action
    onRemoveFromChart(key) {
        this.selectedClusterKeysChanged((this.selectedClusterKeys || []).without(key));
    }
    
    @action
    onAddToChart(key) {
        this.selectedClusterKeysChanged((this.selectedClusterKeys || []).concat(key));
    }

}
