import Ember from "ember";
export default Ember.Component.extend({
    classNames: ["row", "topics-cloud-legend"],

    queries: null,
    colorOptions: null,
    sizeOptions: null,
    numClusters: 0,

    sizeField: "appearances",
    colorField: "clusterId",

    colorKeyType: "cluster",

    colorIsPositiveIntensity: Ember.computed.equal("colorKeyType", "positiveIntensity"),
    colorIsNegativeIntensity: Ember.computed.equal("colorKeyType", "negativeIntensity"),
    colorIsPassion: Ember.computed.equal("colorKeyType", "passion"),
    colorIsSentimental: Ember.computed.equal("colorKeyType", "sentiment"),
    colorIsNumeric: Ember.computed.equal("colorKeyType", "number"),
    colorIsByQuery: Ember.computed.equal("colorKeyType", "query"),
    colorIsByCluster: Ember.computed.equal("colorKeyType", "cluster"),

    sizeIsSentimental: Ember.computed.equal("sizeKeyType", "sentiment"),

    hasColorOptions: Ember.computed.gt("colorOptions.length", 1),
    hasSizeOptions: Ember.computed.gt("sizeOptions.length", 1),

    sizeFieldChanged: () => {},
    colorFieldChanged: () => {},
    actions: {
        activeQueryIndiciesChanged: function(activeQueryIndicies) {
            this.set("activeQueryIndicies", activeQueryIndicies);
        },
        sizeFieldChanged: function(sizeField) {
            this.sizeFieldChanged(sizeField);
        },
        colorFieldChanged: function(colorField) {
            this.colorFieldChanged(colorField);
        },
    }
});
