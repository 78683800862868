import Ember from 'ember';

export function abbrNumber(inputValues, namedArgs) {
    var decPlaces = namedArgs.decPlaces || 0,
        value = inputValues,
        sigFigs = namedArgs.sigFigs || 3,
        roundToInteger = namedArgs.roundToInteger || false,
        truncate = namedArgs.truncate || false;

    if (Ember.typeOf(inputValues) === "array") {
        value = inputValues[0];
        if (inputValues.length === 2) {
            decPlaces = inputValues[1];
        }
    }

    // TODO: This is an easy patch for the overview-story (Learn More) template.
    if (Ember.isEmpty(value)) {
        return;
    }

    let valueString = value.toString();
    if (valueString === 'Infinity') {
        return value;
    }

    if (roundToInteger) {
        value = Math.round(value);
    }

    if (truncate) {
        value = Math.trunc(value);
    }

    // 2 decimal places => 100, 3 => 1000, etc
    let decimalModifier = Math.pow(10, decPlaces);

    // Enumerate value abbreviations
    var abbrev = ["k", "m", "b", "t"];

    // Go through the array backwards, so we do the largest first
    for (var i = abbrev.length - 1; i >= 0; i--) {

        // Convert array index to "1000", "1000000", etc
        var size = Math.pow(10, (i + 1) * 3);

        // If the value is bigger or equal do the abbreviation
        if (size <= value) {
            // decPlaces should be modified for sig figs parameter
            if (sigFigs) {
                let rounded = Math.floor(value / size),
                    figs = rounded.toString().length || 1;
                decPlaces = (figs < sigFigs) ? sigFigs - figs : 0;
                decimalModifier = Math.pow(10, decPlaces);
            }

            // Here, we multiply by hash.decPlaces, round, and then divide by hash.decPlaces.
            // This gives us nice rounding to a particular decimal place.
            value = Math.round(value * decimalModifier / size);
            value = value / decimalModifier;

            // Handle special case where we round up to the next abbreviation
            if ((value === 1000) && (i < abbrev.length - 1)) {
                value = 1;
                i++;
            }

            if (decPlaces > 0) {
                value = value.toPrecision(sigFigs);
            }
            // Add the letter for the abbreviation
            value += abbrev[i];

            // We are done... stop
            break;
        }
    }
    return value;
}

export default Ember.Helper.helper(abbrNumber);
