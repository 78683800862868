import { titleCase } from "infegy-frontend/helpers/title-case";
var Emotions = {
    universalEmotions: [
        "anticipation",
        "joy",
        "disgust",
        "anger",
        "sadness",
        "surprise",
        "fear",
        "trust",
        "love",
        "hate"
    ],
    subfields: [
        "appearances",
        "documents"
    ]
};

Emotions.emotionOptions = Emotions.universalEmotions.map((emotion) => {
    return {
        label: titleCase(emotion),
        value: emotion
    }
});

export default Emotions;