import Ember from "ember";
import Component from '@ember/component';
import { computed } from '@ember/object';
import FieldSelectorBase from "infegy-frontend/components/field-selector/parts/fields/row-value-field-base/component";
import layout from "infegy-frontend/components/field-selector/parts/fields/row-value-selector-layout/template";

let apis = {
    "US States": "states",
    "DMAs": "dmas",
    "Canadian Provinces": "caProvinces",
    "Countries": "countries",
};

let rowProperties = {
    "Canadian Provinces": [{
        rowProperty: "matches",
        rowPropertyTitle: "Posts",
        fieldTypes: ["scalar"]
    },{
        rowProperty: "sources",
        rowPropertyTitle: "Sources",
        fieldTypes: ["scalar"]
    }],
    "US States": [{
        rowProperty: "matches",
        rowPropertyTitle: "Posts",
        fieldTypes: ["scalar"]
    },{
        rowProperty: "sources",
        rowPropertyTitle: "Sources",
        fieldTypes: ["scalar"]
    }],
    "DMAs": [{
        rowProperty: "matches",
        rowPropertyTitle: "Posts",
        fieldTypes: ["scalar"]
    },{
        rowProperty: "sources",
        rowPropertyTitle: "Sources",
        fieldTypes: ["scalar"]
    }],
    "Countries": [{
        rowProperty: "matches",
        rowPropertyTitle: "Posts",
        fieldTypes: ["scalar"]
    },{
        rowProperty: "sources",
        rowPropertyTitle: "Sources",
        fieldTypes: ["scalar"]
    }],
};

let allLookupProperties = {
    "Canadian Provinces": {
        lookupProperty: "code",
        lookupTitleProperty: "name",
    },
    "US States": {
        lookupProperty: "code",
        lookupTitleProperty: "name",
    },
    "DMAs": {
        lookupProperty: "name",
        lookupTitleProperty: "name",
    },
    "Countries": {
        lookupProperty: "iso2",
        lookupTitleProperty: "name",
    }
};

export default class FieldSelectorAge extends FieldSelectorBase {
    layout = layout;

    allAvailableRowFields = rowProperties;

    @computed("selectedSubsegment")
    get apiName() {
        return apis[this.selectedSubsegment];
    }

    @computed("apiName", "selectedSubsegment", "allQueries", "allQueries.[]", "allQueries.@each.ages", "allQueries.@each.updated")
    get availableLookupValues() {
        let allQueries = this.allQueries || [],
            lookupProperties = allLookupProperties[this.selectedSubsegment],
            availableValues = [],
            apiName = apis[this.selectedSubsegment];

        if (!apiName || !lookupProperties) {
            return;
        }

        allQueries.forEach(query => {
            let api = query.get(apiName),
                rowValues = ((api && api.get("dataSeries.data")) || []).map(age => {
                    return {
                        apiName: apiName,
                        rowLookupProperty: lookupProperties.lookupProperty,
                        rowLookupValue: Ember.get(age, lookupProperties.lookupProperty),
                        rowLookupTitle: Ember.get(age, lookupProperties.lookupTitleProperty),
                    }
                });
            availableValues = availableValues.concat(rowValues || []).uniqBy("rowLookupValue");
        });
        return availableValues;
    };
};
