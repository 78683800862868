import Component from "@ember/component";
import { action, computed } from '@ember/object';
import { isEmpty } from "@ember/utils";

import SharedResources from "infegy-frontend/models/users/shared_resources";

export default class SnippetItem extends Component {
    declineSharingId = 0;
    deletingSnippet = null;
    snippet = null;
    user = null;

    showSharingDetails = false;

    sharedResources = SharedResources.create();

    @computed("deletingSnippet", "snippet")
    get isDeleting() {
        return this.deletingSnippet == this.snippet;
    }

    @computed.alias("snippet.title") title;

    @computed.alias("snippet.queryInfo.query") query;

    @computed.alias("snippet.updatedOn") lastUpdated;

    @computed.alias("snippet.isShared") isShared;

    @computed.alias("adminUsers.listOptions") userListOptions;

    // event handlers
    beginDelete(snippet) {};
    confirmDeclineSharing(snippet) {};
    confirmDelete(snippet) {};
    edit(snippet) {};
    sharingDeclined(snippet) {};

    @action
    onBeginDelete(snippet) {
        this.set("deletingSnippet", snippet);
    }

    @action
    onCancelDelete() {
        this.set("deletingSnippet", null);
    }

    @action 
    onConfirmDelete(snippet) {
        this.confirmDelete(snippet);
    }

    @action
    onEditSnippet(snippet) {
        this.edit(snippet);
    }

    @action
    onSharingDeclined() {
        this.sharingDeclined(this.snippet);
    }

    @action
    onToggleShareDetails(snippet) {
        if (isEmpty(this.sharedResources)) {
            this.sharedResources.getForResource("snippet", this.snippet.id);
        }

        this.toggleProperty("showSharingDetails");
    }
}