import Ember from "ember";
import Component from '@ember/component';
import { computed } from '@ember/object';

export default class ValidationList extends Component {
    password = "";

    @computed('password')
    get validCharacters() {
        let checkPassword = this.password ?? "";
        return checkPassword.length >= 8 && checkPassword.length <= 65;
    }

    @computed('password')
    get validUpper() {
        let checkPassword = this.password ?? "";
        return checkPassword.match('[A-Z]') !== null;
    }

    @computed('password')
    get validLower() {
        let checkPassword = this.password ?? "";
        return checkPassword.match('[a-z]') !== null;
    }

    @computed('password')
    get validDigit() {
        let checkPassword = this.password ?? "";
        return checkPassword.match('[0-9]') !== null;
    }

    @computed('password')
    get validSymbol() {
        let checkPassword = this.password ?? "",
            symbols = '[~!@#\\$%\\^&\\*\\-\\_=\\+\\.\\?]';

        return checkPassword.match(symbols) !== null;
    }

}
