import Ember from "ember";
import TrendsSegments from "infegy-frontend/static_data/trends-segments";

export default Ember.Component.extend({
    queries: null,

    chartHeight: 200,
    availableFields: null,
    activeFields: null,
    activeField: null,

    segmentTypes: "drilldown",
    selectedTrendsSegment: "Posts",
    customSegmentDefinition: null,
    selectedTrendsSubSegment: null,

    segmentDefinition: Ember.computed("segmentTypes", "customSegmentDefinition", function () {
        if (this.customSegmentDefinition) {
            return this.customSegmentDefinition;
        }
        var segmentTypes = this.segmentTypes || "drilldown";
        return TrendsSegments[segmentTypes];
    }),

    innerSelectedTrendsSubSegment: Ember.computed("segmentDefinition",
            "selectedTrendsSubSegment", "selectedTrendsSegment", function() {
        var segmentDefinition = this.segmentDefinition,
            selectedTrendsSubSegment = this.selectedTrendsSubSegment,
            selectedTrendsSegment = this.selectedTrendsSegment;

        if (!segmentDefinition || !Ember.get(segmentDefinition, "subSegmentOptions") ||
                !segmentDefinition.subSegmentOptions[selectedTrendsSegment]) {
            return;
        }
        var subSegmentOptions = segmentDefinition.subSegmentOptions[selectedTrendsSegment];

        if (selectedTrendsSubSegment && subSegmentOptions.includes(selectedTrendsSubSegment)) {
            return selectedTrendsSubSegment;
        } else {
            return subSegmentOptions[0];
        }
    }),

    fieldSelected: () => {},
    actions: {
        availableFieldsChanged: function(availableFields) {
            Ember.run.later(this, () => {
                this.set("availableFields", availableFields);
            });
        },
        activeFieldsChanged: function(activeFields) {
            Ember.run.later(this, () => {
                this.set("activeFields", activeFields);
            });
        },
        activeFieldChanged: function(field) {
            Ember.run.later(this, () => {
                this.set("activeField", field);
            });
        },
        selectedTrendsSegmentChanged: function(selectedTrendsSegment) {
            Ember.run.later(this, () => {
                this.set("selectedTrendsSegment", selectedTrendsSegment);
            });
        },
        selectedTrendsSubSegmentChanged: function(selectedTrendsSubSegment) {
            Ember.run.later(this, () => {
                this.set("selectedTrendsSubSegment", selectedTrendsSubSegment);
            });
        },
        fieldSelected: function(field) {
            this.fieldSelected(field);
        }
    },
});
