import Route from "@ember/routing/route";
import { action } from "@ember/object";

import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import SubquerySet from "infegy-frontend/models/users/subquery_set";
import SharedResources from "infegy-frontend/models/users/shared_resources";

export default class SubquerySetEditRoute extends Route {
    title = "Editing Query Set";
    query_set_id = "";

    model(params) {
        const id = params.query_set_id;

        return AtlasAuth.Get({
            url: "api/v3/query-sets",
            data: {
                id
            }
        });
    }

    setupController(controller, model) {
        if (model.status === "OK" && model.output.length > 0) {
            const querySet = SubquerySet.create();
            querySet.loadJSON(model.output[0]);

            const sharedResources = SharedResources.create();
            sharedResources.getForResource("query-set", querySet.id);
            
            controller.setProperties({
                querySet,
                querySetName: querySet.name,
                sharedResources
            });
        } 
        else {
            this.transitionTo("atlas.tools.query-sets.index");
        }
    }
    
    @action
    willTransition(transition) {
        const controller = this.get("controller");
        controller.reset();
    }
}