import Ember from "ember";
import CSSTools from "infegy-frontend/utils/css-tools";
import QueryQrouping from "infegy-frontend/models/queries/query_data_grouping";

export default Ember.Component.extend({
    fields: [],

    chartHeight: 200,
    optionsVisible: false,
    showButtons: false,

    eventData: null,
    showEvents: true,
    groupByField: "timestamp",
    availableGroupKeyOptions: null,
    showEventIcons: true,

    interactionInfo: {},

    firstField: Ember.computed.alias("fields.firstObject"),

    chartType: "line",

    displayedEvents: [],

    showTooltip: Ember.computed("interactionInfo.isHovered", "this.interactionInfo.isSelected", function() {
        return !!(this.interactionInfo.isHovered || this.interactionInfo.isSelected);
    }),

    findXValue: function(xAxisField, data, index) {
        if (!data || !data.length || index < 0 || index >= data.length) {
            return 0;
        }
        var timestampFields = QueryQrouping.timestampFields;
        if (timestampFields.includes(xAxisField)) {
            xAxisField = "timestamp";
        }
        var row = data[index],
            xAxisVal = row && Ember.get(row, xAxisField);
        return xAxisVal || 0;
    },

    showYAxis: Ember.computed("chartType", function () {
        var type = this.chartType;
        if (type !== "stream" && type !== "high-low") {
            return true;
        }
    }),

    xValues: Ember.computed("firstField", "firstField.xAxisField", "firstField.data",
            "interactionInfo.hoveredXIdx", "interactionInfo.selectedXIdx",
            "interactionInfo.brushStartXIdx", "interactionInfo.brushEndXIdx", function () {
        var data = this.get("firstField.data"),
            xAxisField = this.get("firstField.xAxisField");
        return {
            start: this.findXValue(xAxisField, data, 0),
            end: this.findXValue(xAxisField, data, data && (data.length - 1)),
            hovered: this.findXValue(xAxisField, data, this.get("interactionInfo.hoveredXIdx")),
            selected: this.findXValue(xAxisField, data, this.get("interactionInfo.selectedXIdx")),
            brushStart: this.findXValue(xAxisField, data, this.get("interactionInfo.brushStartXIdx")),
            brushEnd: this.findXValue(xAxisField, data, this.get("interactionInfo.brushEndXIdx"))
        };
    }),

    startXValue: Ember.computed("firstField", "firstField.xAxisField", "firstField.data", function () {
        var data = this.get("firstField.data");
        return data && data.length && Ember.get(data[0], "timestamp");
    }),

    endXValue: Ember.computed("firstField", "firstField.xAxisField", "firstField.data", function () {
        var data = this.get("firstField.data");
        return data && data.length && Ember.get(data[data.length - 1], "timestamp");
    }),

    hoveredXValue: Ember.computed("firstField", "interactionInfo.hoveredXIdx", "firstField.data", function () {
        var data = this.get("firstField.data"),
            hoveredXIdx = this.get("interactionInfo.hoveredXIdx") || 0;
        if (data && data.length > hoveredXIdx && hoveredXIdx >= 0) {
            return Ember.get(data[hoveredXIdx], "timestamp");
        }
    }),

    getLabelDisplayInfo: function(xPct) {
        var cssClass = "trend-chart-x-axis-label-middle",
            styleCss = { left: (xPct || 0) + "%" };
        if (xPct < 10) {
            cssClass = "trend-chart-x-axis-label-start";
        } else if (xPct > 90) {
            cssClass = "trend-chart-x-axis-label-end";
            styleCss = { right: (100.0 - (xPct || 0)) + "%" };
        }
        return {
            cssClass: cssClass,
            style: CSSTools.buildStringFromObject(styleCss).htmlSafe()
        };
    },

    hoveredXLabelDisplayInfo: Ember.computed("interactionInfo.hoveredXPct", function () {
        return this.getLabelDisplayInfo(this.get("interactionInfo.hoveredXPct"));
    }),

    selectedXLabelDisplayInfo: Ember.computed("interactionInfo.selectedXPct", function () {
        return this.getLabelDisplayInfo(this.get("interactionInfo.selectedXPct"));
    }),

    brushedLabelDisplayInfos: Ember.computed("interactionInfo.brushStartXPct", "interactionInfo.brushEndXPct", function () {
        var startXPct = this.get("interactionInfo.brushStartXPct"),
            endXPct = this.get("interactionInfo.brushEndXPct"),
            results = {};

        if (startXPct > 10) {
            results.start = {
                cssClass: "trend-chart-x-axis-label-end",
                style: CSSTools.buildStringFromObject({ right: (100.0 - (startXPct || 0)) + "%" }).htmlSafe()
            };
        } else {
            results.start = {
                cssClass: "trend-chart-x-axis-label-start",
                style: CSSTools.buildStringFromObject({ left: "0%" }).htmlSafe()
            };
        }
        if (endXPct < 90) {
            results.end = {
                cssClass: "trend-chart-x-axis-label-start",
                style: CSSTools.buildStringFromObject({ left: (endXPct || 0) + "%" }).htmlSafe()
            };
        } else {
            results.end = {
                cssClass: "trend-chart-x-axis-label-end",
                style: CSSTools.buildStringFromObject({ right: "0%" }).htmlSafe()
            };
        }
        if ((startXPct <= 10 && endXPct < 15) || (startXPct > 85 && endXPct >= 90)) {
            results.end.cssClass += " trend-chart-x-axis-label-end-dropped";
        }

        return results;
    }),

    groupKeyChanged: () => {},
    fieldSelected: (field) => {},
    fieldHovered: () => {},
    interactionUpdated: () => {},
    eventClicked: () => {},
    eventHovered: () => {},
    actions: {
        sendGroupKeyChange: function(selectedGroupKey) {
            this.groupKeyChanged(selectedGroupKey);
        },
        toggleOptions: function() {
            this.toggleProperty("optionsVisible");
        },
        fieldSelected: function (field) {
            this.fieldSelected(field);
        },
        fieldHovered: function (field) {
            this.fieldHovered(field);
        },
        blockOptions: function() {
            //intentionally blank
        },
        interactionUpdated(interactionInfo) {
            this.set("interactionInfo", interactionInfo);
            this.set("hoveredXPercent", interactionInfo.hoveredXPct/100);
        }
    }
});
