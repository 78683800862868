import Ember from 'ember';

var DateFilters = {};

DateFilters.Years = (function() {
    var min_year = 2007;
    var max_year = new Date().getFullYear();
    var years = [];
    for (var year = min_year; year <= max_year; year++) {
        years.push({
            "label": year,
            "value": year,
            "alternates": [year % 100, year % 1000],
            "hideDescription": true
        });
    }
    return years;
})();


DateFilters.DaysInMonth = function(month,year) {
    //Month is 1 based
    var days = new Date(year, month, "0").getDate();
    return days;
};

DateFilters.DaysInMonthOptions = function(month,year) {
    var daySuffix = function (day) {
        var d = day % 10;
        return (d > 3 || (day < 14 && day > 3)) ? 'th' : (['th', 'st', 'nd', 'rd'][d]);
    };

    var days = DateFilters.DaysInMonth(month,year);
    var options = [];
    for (var day = 1; day <= days; day++) {
        options.push({
            "label": day + daySuffix(day),
            "value": day,
            "alternates": [day, ("00"+day).slice(-2)],
            "hideDescription": true
        });
    }
    return options;
};

var max_vals = 1000;
DateFilters.RelativeDayValues = (function() {
    var vals = [];
    for (var i = 1; i <= max_vals; i++) {
        vals.push({
            "label": i,
            "value": i
        });
    }
    return vals;
})();

var max_vals = 100;
DateFilters.RelativeMonthValues =
DateFilters.RelativeWeekValues = (function() {
    var vals = [];
    for (var i = 1; i <= max_vals; i++) {
        vals.push({
            "label": i,
            "value": i
        });
    }
    return vals;
})();

var max_vals = 10;
DateFilters.RelativeYearValues = (function() {
    var vals = [];
    for (var i = 1; i <= max_vals; i++) {
        vals.push({
            "label": i,
            "value": i
        });
    }
    return vals;
})();

DateFilters.RelativeValuesForUnits = function(units, earliestTimestamp) {
    var values = [];
    if (units === "years") {
        values = DateFilters.RelativeYearValues;
        return values;
    } else if (units === "months") {
        return DateFilters.RelativeMonthValues;
    } else if (units === "weeks") {
        return DateFilters.RelativeWeekValues;
    }
    return DateFilters.RelativeDayValues;
};

DateFilters.RelativeModifier = [{
        "label": "Ago",
        "value": "ago"
    },{
        "label": "Before",
        "value": "before"
    },{
        "label": "After",
        "value": "after"
    }];

DateFilters.RelativeUnits = [{
        "label": "Days",
        "value": "days",
        "multiplier": 86400000
    },{
        "label": "Weeks",
        "value": "weeks",
        "multiplier": 86400000 * 7
    },{
        "label": "Months",
        "value": "months",
        "multiplier": 86400000 * 30
    },{
        "label": "Years",
        "value": "years",
        "multiplier": 86400000 * 365
    }];

DateFilters.RelativeUnitsExtended = [{
        "group": "start",
        "label": "Years - From Beggining",
        "value": "yearstarts"
    },{
        "group": "start",
        "label": "Month starts",
        "value": "monthstarts"
    },{
        "group": "start",
        "label": "Week starts",
        "value": "weekstarts"
    }];

DateFilters.Months = [{
    "label": "January",
    "value": 1,
    "alternates": ["jan", "01", 1],
    "hideDescription": true
},{
    "label": "February",
    "value": 2,
    "alternates": ["feb", "02", 2],
    "hideDescription": true
},{
    "label": "March",
    "value": 3,
    "alternates": ["mar", "03", 3],
    "hideDescription": true
},{
    "label": "April",
    "value": 4,
    "alternates": ["apr", "04", 4],
    "hideDescription": true
},{
    "label": "May",
    "value": 5,
    "alternates": ["may", "05", 5],
    "hideDescription": true
},{
    "label": "June",
    "value": 6,
    "alternates": ["jun", "06", 6],
    "hideDescription": true
},{
    "label": "July",
    "value": 7,
    "alternates": ["jul", "07", 7],
    "hideDescription": true
},{
    "label": "August",
    "value": 8,
    "alternates": ["aug", "08", 8],
    "hideDescription": true
},{
    "label": "September",
    "value": 9,
    "alternates": ["sep", "09", 9],
    "hideDescription": true
},{
    "label": "October",
    "value": 10,
    "alternates": ["oct", 10],
    "hideDescription": true
},{
    "label": "November",
    "value": 11,
    "alternates": ["nov", 11],
    "hideDescription": true
},{
    "label": "December",
    "value": 12,
    "alternates": ["dec", 12],
    "hideDescription": true
}];

DateFilters.startDateOptions = [
        Ember.Object.create({ value: "relative" }),
        Ember.Object.create({ value: "fixed" })
    ];

DateFilters.endDateOptions = [
        Ember.Object.create({ value: "relative" }),
        Ember.Object.create({ value: "fixed" }),
        Ember.Object.create({ value: "now" })
    ];

export default DateFilters;
