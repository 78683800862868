import Ember from "ember";
import Component from '@ember/component';
import { computed } from '@ember/object';
import FieldSelectorBase from "infegy-frontend/components/field-selector/parts/fields/object-field-base/component";
import layout from "infegy-frontend/components/field-selector/parts/fields/object-selector-layout/template";

let demoProps = [
    {fieldName: "averageHouseholdSize", title: "Average Household Size"},
    {fieldName: "medianHouseholdIncome", title: "Median Household Income"},
    {fieldName: "medianDisposableIncome", title: "Median Disposable Income"},
    {fieldName: "medianHouseValue", title: "Median House Value"},
    {fieldName: "educationAnyCollege", title: "College Educated"},
];

export default class FieldSelectorAge extends FieldSelectorBase {
    layout = layout;

    apiName = "demographicsMeta";

    @computed("fieldType", "selectedSegment", "selectedSubsegment", "isLoaded")
    get allAvailableFields() {
        let fields = demoProps.map(demoProp => {
            return this.makeFieldDefinition({
                fieldName: demoProp.fieldName,
                title: demoProp.title,
                statsType: "average"
            })
        });
        return { "Totals" : fields };
    };
};
