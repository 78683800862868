import Route from "@ember/routing/route";

export default class extends Route {
    title = "Custom Datasets";

    model({dataset_id}) {
        return dataset_id;
    }

    setupController(controller, dataset_id) {
        controller.set("datasetId", dataset_id);
    }
}
