export default {
    segment: "Time Of Day",
    subSegment: "Portion",

    apiName: "timeOfDay",
    fieldName: "portionOfTotal",
    xAxisField: "hour",

    title: "Portion of Daily Volume",
    value: "TimeOfDay.portionOfTotal",
    units: "",
};
