import Ember from "ember";
import { computed } from '@ember/object';
import classic from "ember-classic-decorator";
import * as Sentry from "@sentry/browser";
import JsonStore from "infegy-frontend/json-store/model";
import Prop from "infegy-frontend/json-store/properties/model";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";

@classic
export default class QueryInfo extends JsonStore {
}
