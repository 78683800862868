import Ember from "ember";
import CSSTools from "infegy-frontend/utils/css-tools";
import Component from '@ember/component';
import QueryGrouping from "infegy-frontend/models/queries/query_data_grouping";
import {computed, action} from "@ember/object";

export default class extends Component {
    trendFields = [];
    stackedFields = null;

    chartType = "line";
    isZoomable = false;
    chartHeight = 200;

    trendChartMeta = {};

    showEvents = true;
    showEventIcons = true;
    eventData = null;
    interactedEventData = null;
    displayedEvents = [];

    normalizeStartTimes = false;

    xRange = null;

    isCanvasChart = false;
    @computed("isCanvasChart")
    get paddingOverride() {
        return this.isCanvasChart ? "padding: 0;".htmlSafe() : "";
    }

    @computed.alias("trendFields.firstObject") firstField;

    @computed("trendChartMeta.isHovered", "trendChartMeta.isSelected")
    get showTooltip() {
        return !!(this.trendChartMeta.isHovered || this.trendChartMeta.isSelected);
    }

    @computed("isZoomable")
    get isZoomed() {
        return false; //this.isZoomable;
    }

    @computed("chartType")
    get isLineChart() {
        return this.chartType === "line";
    }

    @computed("trendChartMeta", "trendChartMeta.chartType")
    get showYAxis() {
        if (this.isLineChart && !this.isCanvasChart) {
            return true;
        }
    }

    @computed("trendChartMeta.interactedField", "firstField")
    get activeYField() {
        return this.trendChartMeta.interactedField || this.firstField;
    }

    indexFromXPct(xPct, array) {
        let allFieldsRowCount = this.trendFields?.[0]?.allFieldsRowCount || 1,
            barWidth = 1 / allFieldsRowCount,
            index = Math.round((((barWidth * 0.5) - xPct) / barWidth));
        index = Math.abs(index);
        index = Math.max(0, index);
        index = array?.length ? Math.min(index, array?.length) : index;
        return index;
    }

    @computed("trendFields", "xRange", "trendChartMeta.selectedXPct", "trendChartMeta.hoveredXPct",
            "trendChartMeta.brushStartXPct", "trendChartMeta.brushEndXPct", "chartType")
    get xValues() {
        let range = Object.assign({}, this.xRange || {start: 0, end: 1}),
            stackedFields = this.stackedFields;

        if (this.chartType !== "bars") {
            range.span = range.end - range.start;
            range.hovered = (this.trendChartMeta.hoveredXPct * range.span) + range.start;
            range.selected = (this.trendChartMeta.selectedXPct * range.span) + range.start;
            range.brushStart = (this.trendChartMeta.brushStartXPct * range.span) + range.start;
            range.brushEnd = (this.trendChartMeta.brushEndXPct * range.span) + range.start;
        } else if (stackedFields?.length) {
            range.span = range.end - range.start;
            range.hovered = stackedFields[this.indexFromXPct(this.trendChartMeta.hoveredXPct, stackedFields)]?.x;
            range.selected = stackedFields[this.indexFromXPct(this.trendChartMeta.selectedXPct, stackedFields)]?.x;
            range.brushStart = stackedFields[this.indexFromXPct(this.trendChartMeta.brushStartXPct, stackedFields)]?.x;
            range.brushEnd = stackedFields[this.indexFromXPct(this.trendChartMeta.brushEndXPct, stackedFields)]?.x;
        }
        return range;
    }

    @computed("firstField", "trendChartMeta.hoveredXIdx", "firstField.data")
    get hoveredXValue() {
        var data = this.get("firstField.data"),
            hoveredXIdx = this.get("trendChartMeta.hoveredXIdx") || 0;
        if (data && data.length > hoveredXIdx && hoveredXIdx >= 0) {
            return Ember.get(data[hoveredXIdx], "timestamp");
        }
    }

    getLabelDisplayInfo(xPct) {
        var cssClass = "trend-chart-x-axis-label-middle",
            styleCss = { left: (xPct || 0) * 100 + "%" };
        if (xPct < 0.10) {
            cssClass = "trend-chart-x-axis-label-start";
        } else if (xPct > 0.90) {
            cssClass = "trend-chart-x-axis-label-end";
            styleCss = { right: (100.0 - ((xPct || 0) * 100)) + "%" };
        }
        return {
            cssClass: cssClass,
            style: CSSTools.buildStringFromObject(styleCss).htmlSafe()
        };
    }

    @computed("trendChartMeta.hoveredYPct", "trendChartMeta.hoveredYPctWholeInv")
    get hoveredYLabelStyle() {
        let yPct = this.trendChartMeta.hoveredYPct,
            isHovered = this.trendChartMeta.isHovered,
            yPctWholeInv = this.trendChartMeta.hoveredYPctWholeInv,
            cssClass = "trend-chart-y-axis-label-end",
            styleCss = null;
        if (isHovered && yPct < 0.15) {
            cssClass = "trend-chart-y-axis-label-start";
        } else if (isHovered && yPct < 0.85) {
            cssClass = "trend-chart-y-axis-label-middle";
            styleCss = { top: yPctWholeInv + "%" };
        }
        return {
            cssClass: cssClass,
            style: styleCss ? CSSTools.buildStringFromObject(styleCss).htmlSafe() : null
        };
    }

    @computed("trendChartMeta.hoveredXPct")
    get hoveredXLabelDisplayInfo() {
        return this.getLabelDisplayInfo(this.get("trendChartMeta.hoveredXPct"));
    }

    @computed("trendChartMeta.selectedXPct")
    get selectedXLabelDisplayInfo() {
        return this.getLabelDisplayInfo(this.get("trendChartMeta.selectedXPct"));
    }

    @computed("trendChartMeta.brushStartXPct", "trendChartMeta.brushEndXPct")
    get brushedLabelDisplayInfos() {
        var startXPct = this.get("trendChartMeta.brushStartXPct") * 100,
            endXPct = this.get("trendChartMeta.brushEndXPct") * 100,
            results = {};

        if (startXPct > 10) {
            results.start = {
                cssClass: "trend-chart-x-axis-label-end",
                style: CSSTools.buildStringFromObject({ right: (100.0 - (startXPct || 0)) + "%" }).htmlSafe()
            };
        } else {
            results.start = {
                cssClass: "trend-chart-x-axis-label-start",
                style: CSSTools.buildStringFromObject({ left: "0%" }).htmlSafe()
            };
        }
        if (endXPct < 90) {
            results.end = {
                cssClass: "trend-chart-x-axis-label-start",
                style: CSSTools.buildStringFromObject({ left: (endXPct || 0) + "%" }).htmlSafe()
            };
        } else {
            results.end = {
                cssClass: "trend-chart-x-axis-label-end",
                style: CSSTools.buildStringFromObject({ right: "0%" }).htmlSafe()
            };
        }
        if ((startXPct <= 10 && endXPct < 15) || (startXPct > 85 && endXPct >= 90)) {
            results.end.cssClass += " trend-chart-x-axis-label-end-dropped";
        }

        return results;
    }

    groupKeyChanged() {}
    fieldSelected(field) {}
    fieldHovered() {}
    trendChartMetaUpdated() {}
    eventClicked() {}
    eventHovered() {}

    @action
    selectingField (field) {
        this.fieldSelected(field);
    }

    @action
    fieldHovered (field) {
        this.fieldHovered(field);
    }

    @action
    trendChartMetaUpdated(trendChartMeta) {
        if (trendChartMeta) {
            this.set("hoveredXPercent", trendChartMeta.hoveredXPct/100);
        }
    }
};
