import Ember from "ember";
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import ChartColors from "infegy-frontend/static_data/fixtures/chart_colors";

export default class SentimentCombinedBase extends Component {
    query = null;
    genderQueries = null;
    activeView = "list";

    hoveredTopic = null;
    selectedTopic = null;

    viewOptions = [
        { icon: "format-align-center", value: "list" },
        { icon: "blur", value: "cloud" }
    ];

    @computed("activeView")
    get componentPath() {
        return `demographics/gender-topics/${this.activeView}`;
    }

    @computed.alias("genderTopics.maleUnique") maleTopics;
    @computed.alias("genderTopics.femaleUnique") femaleTopics;
    @computed.alias("genderTopics.intersect") sharedTopics;

    indexGenderData = function(genderData, gender) {
        var colors = ChartColors.sentimentGradientD3();
        return genderData.reduce(function(memo, topic) {
            memo[topic.key] = Object.assign({
                color: colors(topic.netSentimentAppearancesPercent),
                gender: gender
            }, topic);
            return memo;
        }, {});
    };

    @computed("genderQueries.male", "genderQueries.male.topics.isLoaded",
        "genderQueries.female", "genderQueries.female.topics.isLoaded")
    get genderTopics() {
        var femaleLookup = this.indexGenderData(this.get("genderQueries.female.topics.data", "f") || []),
            maleLookup = this.indexGenderData(this.get("genderQueries.male.topics.data", "m") || []);

        maleLookup = Object.assign({}, maleLookup);
        femaleLookup = Object.assign({}, femaleLookup);

        var intersect = [],
            maleUnique = [],
            femaleUnique = [];

        var key, maleTopic, femaleTopic;
        for (key in maleLookup) {
            if (maleLookup.hasOwnProperty(key)) {
                if (maleLookup[key] && femaleLookup[key]) {
                    maleTopic = maleLookup[key];
                    femaleTopic = femaleLookup[key];
                    intersect[intersect.length] = Object.assign({}, maleTopic, femaleTopic,
                    {
                        maleFirstSeen: maleTopic.firstSeen,
                        maleLastSeen: maleTopic.lastSeen,
                        femaleFirstSeen: femaleTopic.firstSeen,
                        femaleLastSeen: femaleTopic.lastSeen,
                        gender: "b"
                    });
                    delete maleLookup[key];
                    delete femaleLookup[key];
                } else {
                    maleTopic = maleLookup[key];
                    maleUnique[maleUnique.length] = Object.assign({}, maleLookup[key],
                    {
                        maleFirstSeen: maleTopic.firstSeen,
                        maleLastSeen: maleTopic.lastSeen,
                        gender: "m"
                    });
                    delete maleLookup[key];
                }
            }
        }

        for (key in femaleLookup) {
            if (femaleLookup.hasOwnProperty(key)) {
                femaleTopic = femaleLookup[key];
                femaleUnique[femaleUnique.length] = Object.assign({}, femaleLookup[key],
                    {
                        femaleFirstSeen: femaleTopic.firstSeen,
                        femaleLastSeen: femaleTopic.lastSeen,
                        gender: "f"
                    });
                delete femaleLookup[key];
            }
        }

        maleUnique = maleUnique.sortBy("score").reverse();
        femaleUnique = femaleUnique.sortBy("score").reverse();
        intersect = intersect.sortBy("score").reverse();

        return Ember.Object.create({
            maleUnique: maleUnique,
            femaleUnique: femaleUnique,
            intersect: intersect
        });
    }

    @computed("query", "query.updated", "selectedTopic")
    get drilldownTopicQuery() {
        var query = this.query || [],
            topic = this.selectedTopic,
            withinQueryString = topic.query,
            withinQueryLabel = topic.topic;

        if (!query || !withinQueryString) {
            return query;
        }

        if (!withinQueryLabel) {
            withinQueryLabel = withinQueryString;
        }

        let drilldownQuery = query.toDrilldownQuery({
            queryString: withinQueryString,
            queryLabel: withinQueryLabel
        });
        if (topic.gender !== 'b') {
            drilldownQuery.queryInfo.addFilter({
                id: "gender",
                values: [topic.gender]
            });
        }
        return drilldownQuery;
    }

    @computed("genderQueries.male", "genderQueries.female")
    get genderQueriesArray() {
        return [this.genderQueries.male, this.genderQueries.female];
    }

    @action
    activeViewChanged(view) {
        this.set("activeView", view);
        this.set("hoveredTopic", null);
        this.set("selectedTopic", null);
    }

    @action
    topicHovered(topic) {
        this.set("hoveredTopic",topic);
    }

    @action
    topicSelected(topic) {
        if (topic && topic.topic === this.get("selectedTopic.topic")) {
            topic = null;
        }

        this.set("selectedTopic",topic);
    }
}
