var Influence = [{
        "label": "Low",
        "value": "low",
        "sortField": 1,
        "description": "Include only sources with smaller audiences and low connectivity."
    },{
        "label": "Medium",
        "value": "medium",
        "sortField": 2,
        "description": "Include only sources with moderately sized audiences and medium connectivity."
    },{
        "label": "High",
        "value": "high",
        "sortField": 3,
        "description": "Include only sources with larger audiences and high connectivity."
    },{
        "label": "Very High",
        "value": "veryhigh",
        "sortField": 4,
        "description": "Include only sources with larger audiences and Very high connectivity."
    }];

export default Influence;
