var sixMonthsAgo = moment().subtract(6, "months");

export default {
    // Earliest allowed Atlas API date
    EarliestAtlasTimestamp: Date.UTC(2007, 0, 1),

    authenticationHeartbeatEndpoint: 'api/v3/usage',
    authenticationHeartbeatFrequency: 60000,

    versionHeartbeatEndpoint: 'api/v3/version',
    versionHeartbeatFrequency: 300000,

    userPermissions: {
        admin: {},
        lite: {
            maxQueries: 1,
            earliestTimestamp: Date.UTC(sixMonthsAgo.year(), sixMonthsAgo.month(), sixMonthsAgo.date()),
        },
        all: {
            maxQueries: 6,
            earliestTimestamp: Date.UTC(2007, 0, 1),
        }
    },

    queryAPI: {
        // Max number of queries sent per request
        maxQueryBatchSize: 1,

        // Api Request Delay in microseconds
        apiRequestDelay: 0,

        linguisticsPostsURLBase: "api/v3/posts?"
    },
    trends: {
        // Total number of fields that can be chosen from at a time in legend
        maxAvailableFields: 20,
        // Maximum number of displayed fields in trends
        maxSelectedFields: 6
    },
    linguistics: {

        // Don't show themes with less than this percentage of documents (0.0-1.0)
        themesDocumentCutoffThreshold: 0.03,
        // Number of active selected themes to display
        themesMaxDisplayRows: 8
    }
};
