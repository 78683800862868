import Ember from 'ember';
import classic from 'ember-classic-decorator';

@classic
export default class SearchSelectInput extends Ember.TextField {
    classNameBindings = [":search-select-text-field", "invisible:search-select-invisible-text-field", "isFocused:focus"];
    attributeBindings = ["autocomplete", "value","placeholder","readOnly:readonly"];
    placeholder = '';
    autocomplete = "off";
    onClick = () => { /* action */ };
    onFocusIn = () => { /* action */ };

    innerFocused = false;

    _value = "";
    get value(){
        return this._value;
    }
    set value(value){
        this._value = value;
        if (this.element)
            this.element.value = value;
    }

    get isFocused() {
        return this.innerFocused;
    }

    set isFocused(value) {
        if (this.element) {
            if (value) {
                this.element.focus();
            } else {
                this.element.blur();
            }
        }
        this.set("innerFocused", value);
    }

    keyDown(e) {
        this.onKeyDown(e, this.value);
    }

    focusIn() {
        this.onFocusIn();
    }

    didInsertElement(){
        if (this.onDidInsert) {
            this.onDidInsert(this.element);
        }
    }

    click() {
        this.onClick();
    }

}
