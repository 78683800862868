import Ember from 'ember';

export default Ember.TextField.extend({
    classNames:["multi-item-input-item-inner-input"],
    value: '',

    keyDown:function(e){
        this.onKeyDown(e, this.value);
    },
    
    keyUp:function(e){
        this.onKeyUp(e, this.value);
    },

    focusIn: function(){
        this.focusChanged(true);
    },
    
    focusOut: function(){
        this.focusChanged(false);
    },
});