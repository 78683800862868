import Ember from "ember";

export default Ember.Component.extend({
    document: null,
    fileInfo: null,
    user: null,

    customDatasets: Ember.computed.alias("user.customDatasets"),

    storageInfos: Ember.computed("dataStorage", "postStorage", function() {
        const dataStorage = this.dataStorage;
        const postStorage = this.postStorage;
        return [dataStorage, postStorage];
    }),
    
    /*
        DATA STORAGE
    */

    hasUnlimitedData: Ember.computed.lt("user.permissions.customDatasetStorage", 0),

    dataStorage: Ember.computed("dataLimit", "dataUsage", "dataAvailable", "fileDataUsage", function() {
        const hasUnlimitedData = this.hasUnlimitedData;
        const dataUsage = this.dataUsage;
        const fileDataUsage = this.fileDataUsage;
        let dataStorage;
        if (hasUnlimitedData) {
            dataStorage = this.createStorageInfo("Data", Infinity, dataUsage, Infinity, fileDataUsage);
        }
        else {
            const dataLimit = this.dataLimit;
            const dataAvailable = this.dataAvailable;
            dataStorage = this.createStorageInfo("Data", dataLimit, dataUsage, dataAvailable, fileDataUsage);
        }
        
        return dataStorage;
    }),

    dataLimit: Ember.computed("user.permissions.customDatasetStorage", function() {
        const dataLimit = this.get("user.permissions.customDatasetStorage") || 0;
        return dataLimit;
    }),

    dataUsage: Ember.computed.readOnly("user.usage.datasets.data"),

    dataAvailable: Ember.computed("dataLimit", "dataUsage", function() {
        const dataLimit = this.dataLimit;
        const dataUsage = this.dataUsage;
        const dataAvailable = dataLimit - dataUsage;
        return dataAvailable;
    }),

    fileDataUsage: Ember.computed("document.customDatasetSize", function() {
        const fileDataUsage = this.get("document.customDatasetSize") || 0;
        return fileDataUsage;
    }),

    /*
        POST STORAGE
    */

    hasUnlimitedPosts: Ember.computed.lt("user.permissions.customDatasetMaxDocuments", 0),

    postStorage: Ember.computed("postLimit", "postUsage", "postsAvailable", "filePostUsage", function() {
        const hasUnlimitedPosts = this.hasUnlimitedPosts;
        const postUsage = this.postUsage;
        const filePostUsage = this.filePostUsage;
        let postStorage;
        if (hasUnlimitedPosts) {
            postStorage = this.createStorageInfo("Posts", Infinity, postUsage, Infinity, filePostUsage);
        }
        else {
            const postLimit = this.postLimit;
            const postsAvailable = this.postsAvailable;
            postStorage = this.createStorageInfo("Posts", postLimit, postUsage, postsAvailable, filePostUsage);
        }
        
        return postStorage;
    }),

    postLimit: Ember.computed("user.permissions.customDatasetMaxDocuments", function() {
        const postLimit = this.get("user.permissions.customDatasetMaxDocuments") || 0;
        return postLimit;
    }),

    postUsage: Ember.computed.readOnly("user.usage.datasets.documents"),

    postsAvailable: Ember.computed("postLimit", "postUsage", function() {
        const postLimit = this.postLimit;
        const postUsage = this.postUsage;
        const postsAvailable = postLimit - postUsage;
        return postsAvailable;
    }),

    filePostUsage: Ember.computed("document.customDatasetPosts", function() {
        const filePostUsage = this.get("document.customDatasetPosts") || 0;
        return filePostUsage;
    }),

    createStorageInfo(storageType, storageLimit, storageUsage, storageAvailable, fileUsage) {
        return {
            name: storageType,
            limit: storageLimit,
            usage: storageUsage,
            available: storageAvailable,
            fileUsage
        }
    }
});
