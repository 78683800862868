import CustomComputed from "infegy-frontend/utils/custom_computed";
import Ember from "ember";
import { MouseInteractionWrapper } from "infegy-frontend/components/charting/mouse-interaction-mixin/component";
export default class extends MouseInteractionWrapper(Ember.Mixin) {
    hoveredIndex = null;
    hoveredValue = null;
    hoveredData = null;
    hoveredPct = null;
    hoveredYPct = null;

    hoveredTheme = null;

    absoluteHoveredValue = null;

    dataSeries = null;
    valueField = "timestamp";

    stats = CustomComputed.dataSeriesStatsFor("dataSeries", "valueField");

    update() {
        if (!this.isHovered) {
            this.set("hoveredIndex", null);
            this.set("hoveredValue", null);
            return;
        }

        var mouseXPct = this.mouseXPct || 0,
            stats = this.stats,
            data = this.dataSeries,
            field = this.valueField;

        if (!stats || !data) {
            return;
        }

        var range = stats.get("max") - stats.get("min");
        var mouseVal = (mouseXPct * range) + stats.get("min");
        mouseVal = mouseVal || 0;
        this.set("absoluteHoveredValue", mouseVal);

        var closest = Infinity;
        var hoveredData = null;
        var closestIdx = null;
        data.get("data").forEach(function(row, index) {
            var val = row[field] || 0;
            if (Math.abs(val - mouseVal) < closest) {
                closestIdx = index;
                closest = Math.abs(val - mouseVal);
                hoveredData = row;
            }
        });

        closest = data.get("data")[closestIdx][field];
        this.set("hoveredIndex", closestIdx);
        this.set("hoveredValue", closest);
        this.set("hoveredPct", (closest - stats.get("min")) / (range || 1));
        this.set("hoveredData", hoveredData);
    }

    @Ember.observer("hoveredPct", "mouseXPct", "stats", "isHovered", function() {
        Ember.run.once(this, "update");
    }) runUpdate;


    onMouseLeave(event) {
        this.set("mouseX", 0);
        this.set("mouseY", 0);
        this.set("mouseXPct", 0);
        this.set("mouseYPct", 0);
        this.set("isHovered", false);
        return true;
    }

    onMouseMove(event) {
        var x = event && event.offsetX,
            y = event && event.offsetY,
            width = this.width,
            height = this.height;

        this.set("mouseX", x);
        this.set("mouseY", y);
        this.set("isHovered", true);

        if (width) {
            this.set("mouseXPct", x / width);
        } else {
            this.set("mouseXPct", 0);
        }

        if (height) {
            this.set("mouseYPct", x / height);
        } else {
            this.set("mouseYPct", 0);
        }

        return true;
    }
}
