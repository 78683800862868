import JsonStore from "infegy-frontend/json-store/model";
import Prop from "infegy-frontend/json-store/properties/model";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import classic from "ember-classic-decorator";

@classic
export default class Subquery extends JsonStore {
    @Prop.Int() id;
    @Prop.String() query;
    @Prop.String() tag;
    @Prop.String() created;
    
    update(query_set_id, tag, query) {
        const url = "api/v3/query-sets/entries";
        const queryParams = {
            id: this.id,
            query,
            query_set_id,
            tag
        };

        return AtlasAuth.Put({
            url,
            data: queryParams
        }).catch((response) => {
            console.warn(`Failed to update query_set_entry: ${response.atlasErrorText}`);
            throw response;
        });
    }
}