import Ember from "ember";
import Controller, { inject as controller } from "@ember/controller";
import { action, computed } from "@ember/object";

export default class DictionaryController extends Controller {
    @controller application;
    @computed.alias("application.user") user;
    @computed.alias("user.dictionaries") dictionaries;
    @computed.alias("user.companyUsers") companyUsers;
    sharedResources = null;

    dictionary_id = null;
    editingDictionaryName = null;

    showDictionarySaveSuccess = false;
    didTransitionToCopiedDictionary = false;

    @computed.alias("dictionary.isShared") isShared;

    @computed("dictionary_id", "dictionaries.[]", "user")
    get dictionary() {
        var dictionaries = this.dictionaries ?? [],
            dictionary = dictionaries.find((dict)=> dict.id === this.dictionary_id );
        if (!dictionary)
            return null;
        
        Ember.run.once(()=>{
            this.set("editingDictionaryName", dictionary.name);
        });
        dictionary.loadEntries();
        return dictionary;
    }

    @action
    cancelEditingDictionary() {
        this.transitionToRoute("atlas.tools.dictionaries");
    }

    @action
    saveDictionaryName() {
        if (!this.dictionary) {
            console.error("No dictionary available to delete");
            return;
        }
        this.dictionary.update(this.editingDictionaryName).then(() => {
            this.set("showDictionarySaveSuccess", true);
            Ember.run.later(()=>{
                this.set("showDictionarySaveSuccess", false);
            }, 5000);
        }).catch((error) => {
            if(error.status === 403) {
                this.transitionToRoute('login');
            }

            this.showDatasetDeletionError = true;
            Ember.run.later(()=>{
                this.showDatasetDeletionError = false;
            }, 5000);
        });
    }

    @action
    async cloneDictionary() {
        let newDictionary = null;
        try {
            newDictionary = await this.dictionary.saveCopy();
        }
        catch(error) {
            if(error.status === 403) {
                this.transitionToRoute('login');
            }

            this.showSaveError = true;
            Ember.run.later(()=>{
                this.showSaveError = false;
            }, 5000);

            return;
        }

        this.dictionaries.addObject(newDictionary);
        this.transitionToRoute("atlas.tools.dictionaries.edit", newDictionary.id).then((transition) => {
            const controller = transition.controller;
            controller.set("didTransitionToCopiedDictionary", true);
        });
    }

    @action
    cancelSaveDictionaryName() {
        this.set("editingDictionaryName", this.get("dictionary.name"));
    }

    @action
    editEntries(entries) {
        this.dictionary.editEntries(entries);
    }

    @action
    deleteEntry(entry) {
        this.dictionary.deleteEntries([entry]);
    }

    @action
    createEntry(entry) {
        this.dictionary.createEntry(entry);
    }

    @action
    hideTransitionMessage() {
        this.set("didTransitionToCopiedDictionary", false);
    }

    @action
    async createDictionaryEntry(entryData) {
        try {
            await this.dictionary.createEntry(entryData);
        }
        catch(error) {
            console.warn(error.atlasErrorText);
        }
    }
}