import Ember from "ember";
import D3SVGContainerComponent from "infegy-frontend/components/charting/d3-base/d3-svg-container/component";
import d3 from "d3";

export default D3SVGContainerComponent.extend({
    dataSeries: null,

    xAttr: null,
    positiveField: null,
    negativeField: null,

    highlightedIndex: null,

    data: Ember.computed("dataSeries", {
        get: function(key) {
            return this.dataSeries && this.get("dataSeries.data");
        },
    }),

    stats: Ember.computed("dataSeries", {
        get: function(key) {
            return this.dataSeries && this.get("dataSeries.stats");
        },
    }),

    update: function() {
        var el = this.el,
            data = this.data,
            stats = this.stats,
            xAttr = this.xAttr,
            positiveField = this.positiveField,
            negativeField = this.negativeField,
            xAttrStats = stats && xAttr && stats.get(xAttr),
            positiveFieldStats = stats && positiveField && stats.get(positiveField),
            negativeFieldStats = stats && negativeField && stats.get(negativeField),
            width = this.width,
            height = this.height,
            positiveLine = this.positiveLine,
            negativeLine = this.negativeLine,
            positiveArea = this.positiveArea,
            negativeArea = this.negativeArea,
            axis = this.axis;

        if (!el) {
            return;
        }

        if (!xAttr || !negativeField || !positiveField || !Ember.isArray(data) ||
         !stats || !xAttrStats || !positiveFieldStats || !negativeFieldStats) {
            this.element.style.display = 'none';
            return;
        }
        this.element.style.display = 'block';

        var x = d3.scaleLinear()
            .domain([xAttrStats.get("min") || 0, xAttrStats.get("max") || 10])
            .range([0, width])
            .curve(d3.interpolateRound);

        var positiveChartData = [],
            negativeChartData = [],
            positiveIndex = 0,
            negativeIndex = 0,
            dataLen = data.length,
            sourceRow, newRow,
            max = -Infinity,
            min = Infinity,
            positiveValue = 0,
            negativeValue = 0,
            differenceValue = 0,
            xValue = 0,
            prevDifferenceValue,
            prevXValue,
            first = (data[0][positiveField] || 0) - (data[0][negativeField] || 0),
            lastWasPositive = (first >= 0);

        for (var i = 0; i < dataLen; i++) {
            prevDifferenceValue = differenceValue;
            prevXValue = xValue;

            sourceRow = data[i];
            positiveValue = sourceRow[positiveField] || 0;
            negativeValue = sourceRow[negativeField] || 0;
            differenceValue = (sourceRow[positiveField] || 0) - (sourceRow[negativeField] || 0);
            xValue = sourceRow[xAttr];

            max = Math.max(differenceValue, max);
            min = Math.min(differenceValue, min);

            if ((lastWasPositive && differenceValue < 0) ||
                (!lastWasPositive && differenceValue > 0)) {
                var denom = (differenceValue - prevDifferenceValue);
                if (denom) {
                    newRow = {
                        xValue: (differenceValue * prevXValue - prevDifferenceValue * xValue) / denom,
                        yValue: 0
                    };
                } else {
                    newRow = {
                        xValue: 0.5 * (xValue - prevXValue) + prevXValue,
                        yValue: 0
                    };
                }

                positiveChartData[positiveIndex++] = newRow;
                negativeChartData[negativeIndex++] = newRow;

                lastWasPositive = !lastWasPositive;
            }

            newRow = {
                xValue: xValue,
                yValue: differenceValue
            };

            positiveChartData[positiveIndex++] = newRow;
            negativeChartData[negativeIndex++] = newRow;
        }

        max = Math.max(0, max);
        min = Math.min(0, min);
        
        // Buffer to insure paths are within bounds
        max = max * 1.05;
        min = min * 1.05;

        var y = d3.scaleLinear()
            .domain([max, min])
            .range([0, height])
            .curve(d3.interpolateRound);

        var path = d3.line()
                    .x(function(d) { return x(d.xValue || 0); })
                    .y(function(d) { return y(d.yValue || 0); })
                    .defined(function(d) { return d.yValue >= 0; })
                    .curve(d3.curveMonotoneX);

        var areaPath = d3.area()
                    .x(function(d) { return x(d.xValue || 0); })
                    .y0(y(0))
                    .y1(function(d) { return y(d.yValue || 0); })
                    .defined(function(d) { return d.yValue >= 0; })
                    .curve(d3.curveMonotoneX);

        positiveLine.attr("d", path(positiveChartData) || 'M 0 0');
        positiveArea.attr("d", areaPath(positiveChartData) || 'M 0 0');

        path = d3.line()
                    .x(function(d) { return x(d.xValue || 0); })
                    .y(function(d) { return y(d.yValue || 0); })
                    .defined(function(d) { return d.yValue <= 0; })
                    .curve("basis");

        areaPath = d3.area()
                    .x(function(d) { return x(d.xValue || 0); })
                    .y0(y(0))
                    .y1(function(d) { return y(d.yValue || 0); })
                    .defined(function(d) { return d.yValue <= 0;  })
                    .curve("basis");

        negativeLine.attr("d", path(negativeChartData) || 'M 0 0');
        negativeArea.attr("d", areaPath(negativeChartData) || 'M 0 0');

        axis.attr("x1", 0);
        axis.attr("x2", width);
        axis.attr("y1", y(0));
        axis.attr("y2", y(0));
    },

    runUpdate: Ember.observer("data", "stats",
        "positiveField", "negativeField",
        "contentArea", "contentArea.width", "contentArea.height", function() {
        Ember.run.once(this, 'update');
    }),

    didInsertElement: function() {
        this.beginPropertyChanges();
        this._super();
        var el = this.el;

        el.append('svg:path').attr('class', 'positiveArea area chart-positive background-color light');
        el.append('svg:path').attr('class', 'negativeArea area chart-negative background-color light');
        el.append('svg:path').attr('class', 'positiveLine line chart-positive');
        el.append('svg:path').attr('class', 'negativeLine line chart-negative');
        el.append('svg:line').attr('class', 'highLowAxis axis chart-neutral');

        this.set("positiveLine", el.selectAll(".positiveLine"));
        this.set("negativeLine", el.selectAll(".negativeLine"));
        this.set("positiveArea", el.selectAll(".positiveArea"));
        this.set("negativeArea", el.selectAll(".negativeArea"));
        this.set("axis", el.selectAll(".highLowAxis"));

        this.update();
        this.endPropertyChanges();
    }
});
