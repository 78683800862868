import Ember from "ember";
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { run } from '@ember/runloop';

export default class FieldsQuerySelector extends Component {
    classNameBindings = ["displayAsDropdown:inline-field-select"];

    queries = null;
    selectedQueryId = null;

    displayAsDropdown = false;

    @computed("queries")
    get queryOptions() {
        let queryOptions = this.queries && this.queries.map((query, idx) => {
            return {
                title: query.title,
                label: query.title,
                value: query.lookupId || idx
            };
        });

        if (Ember.isEmpty(this.selectedQueryId) && queryOptions && queryOptions[0]) {
            run.next(()=>{
                this.queryIdChanged(queryOptions[0].value);
            });
        }
        return queryOptions;
    };

    @computed("selectedQueryId")
    get selectedQueryValue() {
        let query = this.queries && this.queries.find((query, idx) => {
            return query.filters.qid === this.selectedQueryId || idx === this.selectedQueryId;
        });
        return query.filters.qid || this.selectedQueryId;
    }


    queryIdChanged(queryId) {
        // Empty for Action //
    };

    @action
    selectionChanged(queryId) {
        if (queryId !== this.selectedQueryId) {
            this.queryIdChanged(queryId);
        }
    };

};
