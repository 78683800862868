import Ember from "ember";
export default Ember.Component.extend({
    classNameBindings: [":paginator-page-item", "isCurrent:active"],

    pageNum: 0,
    currentPage: 0,

    isCurrent: Ember.computed("pageNum", "currentPage", {
        get: function(key) {
            return this.pageNum === this.currentPage;
        },
    }),

    displayNum: Ember.computed("pageNum", {
        get: function(key) {
            return (this.pageNum || 0) + 1;
        },
    }),

    actions: {
      goToPageDisplayNum: function(pageNum) {
        this.set("currentPage", pageNum);
      }
    }
});
