import D3SVGContainerComponent from "infegy-frontend/components/charting/d3-base/d3-svg-container/component";
import { MouseInteractionWrapper } from "infegy-frontend/components/charting/mouse-interaction-mixin/component";
import classic from "ember-classic-decorator";

@classic
export default class extends MouseInteractionWrapper(D3SVGContainerComponent) {
    classNames = ["svg-interaction-overlay"];
    tagName = 'rect';

    mouseX = 0;
    mouseY = 0;

    mouseXPct = 0;
    mouseYPct = 0;

    isHovered = false;

    onMouseMove(event) {
        var x = event && event.offsetX,
            y = event && event.offsetY,
            width = this.width,
            height = this.height;

        var hoverInfo = {
            mouseX: x,
            mouseY: y,
            mouseXPct: width ? x / width : 0,
            mouseYPct: height ? y / height : 0,
            isHovered: true,
        };

        this.wasHovered(hoverInfo);
        this.setProperties(hoverInfo);
    }

    onMouseLeave() {
        var hoverInfo = {
            mouseX: 0,
            mouseY: 0,
            mouseXPct: 0,
            mouseYPct: 0,
            isHovered: false
        };

        this.wasHovered(hoverInfo);
        this.setProperties(hoverInfo);
    }

    click() {
        var x = event && event.offsetX,
            y = event && event.offsetY,
            width = this.width,
            height = this.height;

        this.wasClicked({
            mouseX: x,
            mouseY: y,
            mouseXPct: width ? x / width : 0,
            mouseYPct: height ? y / height : 0
        });
    }

    // Actions
    wasHovered = function () { /* action */ };
    wasClicked = function() { /* action */ };

}
