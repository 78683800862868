import Ember from "ember";
import { computed, action } from '@ember/object';
import { MouseInteractionWrapper } from "infegy-frontend/components/charting/mouse-interaction-mixin/component";

export default class extends MouseInteractionWrapper(Ember.Component) {
    queries = null;
    @Ember.computed.alias("queries.firstObject") query;

    @Ember.computed.alias("query.ages.all") dataSeries;

    yAxisField = "countPortion";
    displayNameField = "displayName";

    averagingYAxisField = "dbDistribution";
    groupingField = "displayName";

    showLabels = false;

    selectedIndex = null;
    hoveredIndex = null;

    hoveredXPct = 0;
    selectedXPct = 0;

    @Ember.computed.notEmpty("hoveredIndex") isHovered;

    @computed('rows')
    get hasData() {
        var rows = this.rows;
        // Has data if at least one row in one query has a count > 0
        return rows.find(row => {
            var data = row.get('dataSeries.data') || [];
            return data && data.filter(item => item.count !== 0).length;
        });
    }

    @computed("queries", "queries.[]", "queries.@each.updated")
    get rows() {
        var queries = this.queries || [];
        return queries.filterBy("ages.all").map((query, index) => {
            return Ember.Object.create({
                index: index,
                query: query,
                dataSeries: query.get("ages.all"),
                color: query.get("queryInfo.color")
            });
        });
    }

    @computed("rows.length")
    get showAveragedArea() {
        return this.get("rows.length") > 1;
    }

    @computed("rows")
    get totalAgePosts() {
        var rows = (this.rows || []).mapBy("dataSeries.stats.count.sum");
        return rows.reduce((memo, val) => memo + val);
    }

    @computed("ageLabels", "hoveredIndex")
    get tooltipData() {
        var rows = this.rows || [],
            yAxisField = this.yAxisField,
            hoveredIndex = this.hoveredIndex;
        var values = rows.map(row => {
            var data = row.get("dataSeries.data") || [],
                dataRow = data[hoveredIndex],
                query = row.get("query.title"),
                color = row.get("query.queryInfo.color");
            return {
                title: query,
                value: dataRow && dataRow[yAxisField],
                color: color
            };
        });
        return {
            index: hoveredIndex,
            values: values
        };
    }

    @computed("ageLabels")
    get ageColumnsClass() {
        var labelCount = this.ageLabels && this.ageLabels.length;
        return new Ember.String.htmlSafe("even-columns-" + labelCount);
    }

    @computed("hoveredIndex", "dataSeries.data")
    get hoveredData() {
        var hoveredIndex = this.hoveredIndex,
            data = this.get("dataSeries.data") || [];

        return !Ember.isEmpty(hoveredIndex) && data && Ember.get(data, hoveredIndex);
    }

    @computed("hoveredData.countPortion")
    get hoveredEngagement() {
        return this.get("hoveredData.countPortion") || 0;
    }

    @computed("hoveredData.count")
    get hoveredCount() {
        return this.get("hoveredData.count") || 0;
    }

    @computed("selectedIndex", "dataSeries.data")
    get selectedData() {
        var selectedIndex = this.selectedIndex,
            data = this.get("dataSeries.data") || [];

        return !Ember.isEmpty(selectedIndex) && data && Ember.get(data, selectedIndex);
    }

    @computed("selectedData.countPortion")
    get selectedEngagement() {
        return this.get("selectedData.countPortion") || 0;
    }

    @computed("selectedData.count")
    get selectedCount() {
        return this.get("selectedData.count") || 0;
    }

    @computed("selectedData", "queries")
    get drilldownQueries() {
        var queries = this.queries || [],
            start = this.get("selectedData.rangeStart"),
            end = this.get("selectedData.rangeEnd");

        return queries.map((query) => {
            var newQuery = query.copy(),
                queryInfo = newQuery.queryInfo;

            queryInfo.addFilter({
                id: "source_age",
                min: start,
                max: end
            });

            newQuery.set("colors", query.get("colors"));
            return newQuery;
        });
    }

    @computed("rows", "rows.[]")
    get ageLabels() {
        var rows = this.rows || [],
            displayNameField = this.displayNameField || "",
            yAxisField = this.yAxisField;
        if (Ember.isEmpty(rows) || Ember.isEmpty(displayNameField)) {
            return;
        }
        var displayNames = rows[0].dataSeries.get("data").mapBy(displayNameField);
        return displayNames.map(displayName => {
            var maxRowInfo = rows.reduce((memo, row) => {
                var data = row.get("dataSeries.data") || [],
                    dataRow = data.findBy(displayNameField, displayName),
                    dataVal = dataRow && dataRow[yAxisField];

                if (!memo || memo.maxVal <= dataVal) {
                    memo = {
                        row: row,
                        maxVal: dataVal || 0,
                        positivity: dataRow.positivity
                    };
                }
                return memo;
            }, 0);
            return {
                label: displayName,
                row: maxRowInfo.row,
                maxVal: maxRowInfo.maxVal,
                positivity: maxRowInfo.positivity * 100,
                negativity: 100 - (maxRowInfo.positivity * 100),
            };
        });
    }

    onMouseLeave() {
        this.setProperties({
            "hoveredXPct": null,
            "hoveredIndex": null
        });
    }

    @action
    wasHovered(hoverInfo) {
        this.setProperties({
            "hoveredXPct": hoverInfo.xPct,
            "hoveredIndex": hoverInfo.index
        });
    }

    @action
    wasSelected(clickInfo) {
        this.setProperties({
            "selectedXPct": clickInfo.xPct,
            "selectedIndex": clickInfo.index
        });
    }
}
