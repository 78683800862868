import Ember from "ember";
import { computed, action } from "@ember/object";
import Languages from "infegy-frontend/static_data/query/query_filters/languages";

export default class TopLanguages extends Ember.Component {
    queries = []

    drillDownQueryLanguage = null
    selectedQuery = null
    selectedLanguage = null
    displayDrilldown = ()=>{ /* action */ };

    @computed("queries.@each.languages")
    get topLanguages (){
        var res =  this.queries.map(query => {
            // sort language codes by document distribution for this query
            var topCodes = Languages.activeLanguageCodes.filter(code=>!Ember.isNone(Ember.get(query,`languages.stats.${code}DocumentsDistribution.average`))).sort((a,b)=>{
                var valueA = Ember.get(query,`languages.stats.${a}DocumentsDistribution.average`) || 0,
                    valueB = Ember.get(query,`languages.stats.${b}DocumentsDistribution.average`) || 0;
                return valueB - valueA;
            }).slice(0,5); // return the 5 with the highest distribution

            var queryLanguages = topCodes.map(code => ({
                code: code,
                distribution: Ember.get(query,`languages.stats.${code}DocumentsDistribution.average`) || 0
            }));
            return {
                query: query,
                languages: queryLanguages
            };
        });
        return res;
    }

    @action
    selectedLanguageChanged(query, language) {
        this.displayDrilldown(query,language);
    }
}