import CSSTools from "infegy-frontend/utils/css-tools";
var elem = document.createElement("div");
document.body.appendChild(elem);

var defaultColorDefinitions = [{ // teal
    label: "Chart 1",
    className: "atlas-chart-1",
    alternateClasses: ["atlas-tiktok"],
    hex: CSSTools.getComputedStyleFromClassName("atlas-chart-1", elem).color//"#5ac4b4",
}, { // purple
    label: "Chart 2",
    className: "atlas-chart-2",
    alternateClasses: ["atlas-passion", "atlas-facebook"],
    hex: CSSTools.getComputedStyleFromClassName("atlas-chart-2", elem).color//"#8870cd",
}, { // yellow
    label: "Chart 3",
    className: "atlas-chart-3",
    alternateClasses: ["atlas-sentiment-mixed"],
    hex: CSSTools.getComputedStyleFromClassName("atlas-chart-3", elem).color//"#facc00",
}, { // orange
    label: "Chart 4",
    className: "atlas-chart-4",
    alternateClasses: ["atlas-blogs", "atlas-tumblr", "atlas-reddit"],
    hex: CSSTools.getComputedStyleFromClassName("atlas-chart-4", elem).color//"#f18137",
}, { // pink
    label: "Chart 5",
    className: "atlas-chart-5",
    alternateClasses: ["atlas-female", "atlas-instagram"],
    hex: CSSTools.getComputedStyleFromClassName("atlas-chart-5", elem).color//"#f04293",
}, { // beige
    label: "Chart 6",
    className: "atlas-chart-6",
    alternateClasses: ["atlas-beige", "atlas-news"],
    hex: CSSTools.getComputedStyleFromClassName("atlas-chart-6", elem).color//"#c4aa87",
}, { // green
    label: "Positive",
    className: "atlas-chart-7",
    alternateClasses: ["atlas-sentiment-positive", "atlas-forums"],
    hex: CSSTools.getComputedStyleFromClassName("atlas-chart-7", elem).color//"#99ed07",
}, { // red
    label: "Negative",
    className: "atlas-chart-8",
    alternateClasses: ["atlas-sentiment-negative", "atlas-images", "atlas-pinterest", "atlas-weibo"],
    hex: CSSTools.getComputedStyleFromClassName("atlas-chart-8", elem).color//"#ed0749",
}, { // blue
    label: "Infegy Blue",
    className: "atlas-chart-9",
    alternateClasses: ["atlas-ui", "atlas-microblogs", "atlas-twitter", "atlas-male", "atlas-vk"],
    hex: CSSTools.getComputedStyleFromClassName("atlas-chart-9", elem).color//"#4ea4d0",
}, { // gray
    label: "Gray",
    className: "atlas-chart-10",
    alternateClasses: [
        "atlas-neutral", "atlas-text-color",
        "atlas-uncategorized", "atlas-sentiment-neutral"
    ],
    hex: CSSTools.getComputedStyleFromClassName("atlas-chart-10", elem).color//"#8f9cb3",
}];

document.body.removeChild(elem);

export default defaultColorDefinitions;
