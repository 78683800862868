import Ember from 'ember';
import {numberFormat} from "infegy-frontend/helpers/number-format";

export function sizeByDigits(params, namedArgs) {
    var value = params[0] || 0,
        digits = value.toString().length,
        maxSize = namedArgs.maxSize || 1.0,
        minSize = namedArgs.minSize || 0.4,
        maxDigits = namedArgs.maxDigits || 19,
        minDigits = namedArgs.minDigits || 6,
        size = maxSize;

    if (!namedArgs.unFormatted) {
        value = numberFormat(params, namedArgs) || value.toString();
        digits = value.length;
    }

    if (!digits || digits < minDigits) {
        size = maxSize;
    } else if (digits > maxDigits) {
        size = minSize;
    } else {
        size = maxSize - (((digits - minDigits) / (maxDigits - minDigits)) * (maxSize - minSize));
    }

    return Ember.String.htmlSafe(`font-size:${size}em;`);
}

export default Ember.Helper.helper(sizeByDigits);
