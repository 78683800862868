import Component from "@ember/component";
import { readOnly } from "@ember/object/computed";
import { action, computed } from "@ember/object";
import { notEmpty } from "@ember/object/computed";
import classic from "ember-classic-decorator";

@classic
export default class extends Component {
    list = null;
    maxDisplayedPages = 10;

    @readOnly("list.pageIndex") pageIndex;
    @readOnly("list.pageCount") pageCount;
    
    @readOnly("list.hasFirst") hasFirst;
    @readOnly("list.hasPrevious") hasPrevious;
    @readOnly("list.hasNext") hasNext;
    @readOnly("list.hasLast") hasLast;

    @computed("pageIndex", "pageCount")
    get pageIndicies() {
        const half = Math.floor(this.maxDisplayedPages / 2);
        let start = Math.min(this.pageIndex, Math.max(this.pageIndex - half, 0));
        let end = Math.min(this.pageCount, start + this.maxDisplayedPages);
        const endRemainder = this.maxDisplayedPages - (end - start);
        start = Math.max(start - endRemainder, 0);
        
        let pageIndicies = [];
        for ( let i = start; i < end; i++) {
            pageIndicies.push(i);
        }
        return pageIndicies;
    }

    @action getFirstPage() {
        this.list.getFirstPage();
    }
    
    @action getPreviousPage() {
        this.list.getPreviousPage();
    }

    @action getNextPage() {
        this.list.getNextPage();
    }

    @action getLastPage() {
        this.list.getLastPage();
    }

    @action onPageSelected(pageIndex) {
        this.list.set("pageIndex", pageIndex);
        this.list.getPage();
    }
}