import Ember from "ember";
import {computed, action} from "@ember/object";
import TrendsInformation from "infegy-frontend/components/charting/trend-chart/trends-information/component";
import classic from "ember-classic-decorator";

@classic
export default class extends TrendsInformation {
    classNames = ["trends-field-information-inline"];

    @computed("trendFields", "trendFields.[]", "interactedValueType")
    get summaryColumnLabel() {
        if (this.interactedValueType) {
            return this.interactedValueType;
        }
        let hasAverage = false,
            hasSum = false;
        this.trendFields.forEach(field => {
            if (field?.field?.isAveragedType) {
               hasAverage = true;
            } else {
                hasSum = true;
            }
        });
        if (hasAverage && hasSum) {
            return "Sum or Average";
        }
        return hasAverage ? "Average" : "Sum";
    }
}
