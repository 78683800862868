import Ember from "ember";
import Controller, { inject as controller } from "@ember/controller";
import { action, computed } from "@ember/object";

import Dictionary from "infegy-frontend/models/users/dictionary";

export default class DictionaryList extends Controller {
    @controller application;
    @computed.alias("application.user") user;
    @computed.alias("user.dictionaries") dictionaries;

    creatingDictionaryName = "";
    showCreateDictionary = false;

    showSaveError = false;
    showDeleteError = false;

    declineSharingId = 0;
    deletingDictionary = null;

    @action
    onEditDictionary(id) {
        this.transitionToRoute("atlas.tools.dictionaries.edit", id);
    }

    @action
    async onCreateNewDictionary(creatingDictionaryName) {
        let newDictionary = Dictionary.create();
        try {
            await newDictionary.save(creatingDictionaryName);
        }
        catch(error) {
            if(error.status === 403) {
                this.transitionToRoute('login');
            }

            this.set("showSaveError", true);
            Ember.run.later(()=>{
                this.set("showSaveError", false);
            }, 5000);
        }

        this.dictionaries.addObject(newDictionary);
        this.setProperties({
            showCreateDictionary: false,
            creatingDictionaryName: ""
        });
        this.transitionToRoute("atlas.tools.dictionaries.edit", newDictionary.id);
    }

    @action
    async onConfirmDeleteAction(dictionary) {
        try {
            await dictionary.delete();
        }
        catch(error) {
            if(error.status === 403) {
                this.transitionToRoute('login');
            }

            this.showDeleteError = true;
            Ember.run.later(()=>{
                this.showDeleteError = false;
            }, 5000);
            return;
        }

        this.dictionaries.removeObject(dictionary);
    }

    @action
    onSharingDeclined(dictionary) {
        this.dictionaries.removeObject(dictionary);
    }
}