// Stats for a DataSeries object field. Calculated for any specified fields
// within a DataSeries array.
import Ember from "ember";

export default Ember.Object.extend({
    field: "unknown",
    type: "number", //"percent", "timestamp", "distribution", "average", "index", "portion"

    // Prevents standard grouping math for computed fields
    isComputed: false,

    max: -Infinity,
    maxIndex: null,

    min: Infinity,
    minIndex: null,

    count: 0,

    percentChange: 0,
    sum: 0,
    average: 0,
    deviation: 0,

    collatedValue: Ember.computed("type", "average", function () {
        if (this.type === "percent" ||
                this.type === "distribution" ||
                this.type === "average") {
            return this.average;
        } else if (this.type === "number" || this.type === "portion") {
            return this.sum;
        } else {
            return this.max;
        }
    }),

    isPercentage: Ember.computed("type", function () {
        return this.type === "percent" || this.type === "distribution";
    }),
});