import Ember from "ember";
import WorkspaceFolder from "infegy-frontend/models/users/workspace_folder";

export default Ember.Component.extend({
    // passed in
    workspace: null,
    user: null,
    return: () => {},

    // local state
    moveToFolderId: null,
    moveToFolderObject: Ember.computed("user.folder", "workspaceFolders.[]", function() {
        return this.workspaceFolders.findBy("id", "moveToFolderId");
    }),
    createFolderName: '',
    userHasFolders: Ember.computed.notEmpty("workspaceFolders"),
    workspaceFolders: Ember.computed("user.workspaceFolders.[]", "user.workspaceFolders.@each.resourceType", function() {        
        return this.user?.workspaceFolders ?? [];
    }),
    
    isCreatingFolder: false,
    moveDisabled: Ember.computed("isCreatingFolder", "createFolderName", function() {
        return this.isCreatingFolder && this.createFolderName.length < 1;
    }),
    isCreatingAndHasFolders: Ember.computed("userHasFolders", "workspaceFolders.length", "isCreatingFolder", function() {
        if (this.workspaceFolders.length < 1 || this.isCreatingFolder === true)
            return true
    }),

    folderOptions: Ember.computed("workspaceFolders", function() {
        return this.workspaceFolders.map(folder => {
            return {
                title: folder.title,
                value: folder.title,
                id: folder.id
            }
        })
    }),

    init(){
        this._super(...arguments);
        this.set("isCreatingFolder", Ember.isEmpty(this.workspaceFolders));
    },
    workspaceChangedFolder() {},
    actions: {
        cancel() {
            this.setProperties({
                moveToFolderId: null,
                createFolderName: ''
            });
            this.return();
        },
        async moveToFolder() {
            if (this.isCreatingAndHasFolders) {
                let folder = WorkspaceFolder.create();
                await folder.save(this.createFolderName);
                this.workspace.set("workspaceFolderId", folder.id);
                await this.workspace.save();
                this.user.workspaceFolders.load();
                this.set("isMovingToFolder", false);
                this.return();
            } else {
                this.workspace.set("workspaceFolderId", this.moveToFolderId);
                await this.workspace.save();
                this.user.workspaceFolders.load();
                this.user.ownedWorkspaces.getPage();
                this.set("isMovingToFolder", false);
                this.return();
            }
            this.workspaceChangedFolder(this.workspace);
        },
        toggleCreateFolder() {
            this.set("isCreatingFolder", !this.isCreatingFolder);
        }
    }
});