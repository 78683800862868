import Ember from "ember";

export default Ember.Controller.extend({
	pageOptions: [
        {
            label: 'Linguistics Live',
            value: 'live-linguistics'
        },{
            label: 'Ad Targeting',
            value: 'ad-targeting'
        }
    ],
    currentPage: 'live-linguistics',

    actions: {
        currentPageChanged(selectedPage) {
            var currentPage = this.currentPage;
            if(selectedPage !== currentPage){
                this.set("currentPage", selectedPage);
                this.transitionToRoute('atlas.admin.demos.'+selectedPage);
            }
        },
    }
});