import Controller, { inject as controller } from "@ember/controller";
import { action, computed } from "@ember/object";

import Document from "infegy-frontend/models/custom-datasets/document";

export default class extends Controller {
    @controller application;
    @computed.readOnly("application.user") user;

    @controller("atlas.tools.custom-datasets.create") createController;

    isImportingFromQualtrics = false;
    @computed.or("isImportingFromQualtrics") hasSelectedType;
    
    errorText = "";
    headerRowCount = 1;
    selectedFile = null;
    isLoading = false;
    loaded = 0;
    total = 0;

    @computed.notEmpty("selectedFile") hasFileSelected;
    @computed.notEmpty("errorText") hasError;

    @computed("user.permissions.customDatasetUploadLimit")
    get maxStorageSize() {
        return this.user.permissions.customDatasetUploadLimit;
    }

    reset() {
        this.setProperties({
            isLoading: false,
            selectedFile: null,
            loaded: 0,
            total: 0
        });
    }

    @action cancel() {
        let user = this.user;
        user.loadCustomDatasets();

        this.transitionToRoute("atlas.tools.custom-datasets");
    }

    @action onSelectSpreadsheet(files) {
        this.setProperties({
            selectedFile: files[0],
            errorText: ""
        });
    }

    @action async onUploadSpreadsheet() {
        let document = Document.create();
        try {
            this.set("isLoading", true);
            const onprogress = (event) => {
                this.setProperties({
                    loaded: event.loaded,
                    total: event.total
                });
            }
            await document.load(this.selectedFile, parseInt(this.headerRowCount), onprogress);
            this.setProperties({
                errorText: "",
                isLoading: false
            });
        }
        catch(error) {
            this.setProperties({
                errorText: error.atlasErrorText,
                isLoading: false
            });
            return;
        }
        this.set("headerRowCount", 1);
        this.createController.set("document", document);
        this.transitionToRoute("atlas.tools.custom-datasets.create.verify-columns");
    }

    @action onCancelUpload() {
        this.setProperties({
            headerRowCount: 1,
            selectedFile: null
        });
    }

    @action onChangeFile(file) {
        this.set("selectedFile", file);
    }
    
    @action onImportFromQualtrics() {
        this.transitionToRoute("atlas.tools.custom-datasets.create.qualtrics-import");
    }
}
