import Component from "@ember/component";
import { action, computed } from "@ember/object";
import { isEmpty } from "@ember/utils";
import classic from "ember-classic-decorator";

import QueryUtil from "infegy-frontend/models/queries/query_util";

@classic
export default class extends Component {
    queryFormQueries = [];
    query = null;
    hoveredQuery = null;

    showDetails = false;

    didInsertElement() {
        super.didInsertElement(...arguments);
        // https://deprecations.emberjs.com/v3.x/#toc_component-mouseenter-leave-move
        this.element.addEventListener("mouseenter", this.onMouseEnter.bind(this));
        this.element.addEventListener("mouseleave", this.onMouseLeave.bind(this));
    }

    @computed("query", "hoveredQuery")
    get isHovered() {
        return this.query === this.hoveredQuery;
    }

    @computed("query", "queryFormQueries.length")
    get isOnDashboard() {
        const found = this.queryFormQueries.find((formQuery) => {
            return QueryUtil.checkIfQueriesMatch(Ember.get(formQuery, "filters"), this.query.filters);
        });
        return !isEmpty(found);
    }

    @computed("query", "queryFormQueries.length")
    get canAddQuery() {
        return this.queryFormQueries.length < 6;
    }

    click() {
        this.toggleProperty("showDetails");
    }

    addQueryToDashboard(query) {};

    onMouseEnter() {
        this.set("hoveredQuery", this.query);
    }

    onMouseLeave() {
        this.set("hoveredQuery", null);
    }

    @action
    onToggleDetails() {
        this.toggleProperty("showDetails");
    }

    @action
    onAddQuery() {
        if (this.isOnDashboard || !this.canAddQuery) {
            return;
        }
        this.addQueryToDashboard(this.query);
    }

    @action
    disableClickThrough() {}
}
