import Ember from "ember";
import AtlasConfig from "infegy-frontend/config/infegy-app-config";

export default Ember.Component.extend({
    classNames: ["query-date-picker"],
    changed: () => { /* action */},

    selectedDate: null,
    selectedTimestamp: Ember.computed("selectedDate", "selectedDate.day", "selectedDate.month", "selectedDate.year", "selectedDate.timestamp", function(){
        if (this.selectedDate) {
            return new Date(this.selectedDate.year, this.selectedDate.month, this.selectedDate.day);
        } else {
            return new Date();
        }
    }),
    earliestTimestamp: null,
    latestTimestamp: null,

    minDate: Ember.computed("earliestTimestamp",function(){
        return this.earliestTimestamp || AtlasConfig.EarliestAtlasTimestamp;
    }),

    maxDate: Ember.computed("latestTimestamp",function(){
        const endDate = this.latestTimestamp || new Date()
        return endDate;
    }),

    actions: {
        updateDate({ date }){
            this.changed({
                date: date.getDate(),
                month: date.getMonth() + 1,
                year: date.getFullYear()
            });
        }
    }
});