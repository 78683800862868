import Ember from "ember";
import QueryAPIRequestError from "infegy-frontend/models/queries/query_api_request_error";
import AtlasConfig from "infegy-frontend/config/infegy-app-config";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";

let requestCache = {};

class QueryAPIResultInfo {
    apiModel = null;
    errors = null;
    promise = null;
    requestedTime = null;
}

export default {
requestCache: requestCache,

clearCache() {
    this.requestCache = requestCache = {};
},

fetchQueryAPIResponse(queryApiRequestString, apiModelClass) {
    let results = new QueryAPIResultInfo();

    // Check the cache, if we want to add timeouts can clear old cached
    // items here using the results.requestedTime
    if (requestCache[queryApiRequestString]) {
        return requestCache[queryApiRequestString];
    }

    if (!apiModelClass) {
        return;
    }

    results.apiModel = apiModelClass.create();
    results.requestedTime = Date.now();
    results.promise = new Promise((resolve, reject) => {
        let apiResponsePromise = AtlasAuth.Get(queryApiRequestString);
        apiResponsePromise.catch((jqXHR) => {
            let apiNameMatch = queryApiRequestString.match(/v[0-9]\/([a-zA-Z\-]+)\.[a-zA-Z]+?/),
                apiName = (apiNameMatch && apiNameMatch.length > 1 && apiNameMatch[1]) || queryApiRequestString;
            let errorInfo = QueryAPIRequestError.create({
                    queryApiRequestString: queryApiRequestString,
                    apiName: apiName,
                    code: jqXHR.status || 0,
                    response: (jqXHR.responseJSON && jqXHR.responseJSON.error) || "Server connectivity error",
                    type: jqXHR.statusText || "Server connectivity error",
                    errorText: jqXHR.atlasErrorText
                });
            results.apiModel.setProperties({
                "rawResponse": [],
                "rawResponseRow": {},
                "rawResults": [],
                "isEmpty": true,
                "isLoading": false,
                "isLoaded": false,
                "isNew": false,
                "hasError": true,
                "errorInfo": errorInfo,
                "errorCode": jqXHR.status,
                "errorString": jqXHR.responseText,
                "errorType": jqXHR.statusText,
            });
            results.errors = errorInfo;
            reject(errorInfo);
        });
        apiResponsePromise.then(apiResponse => {
            if(typeof(apiResponse) === "string"){
                apiResponse = JSON.parse(apiResponse);
            }
            if (Ember.isEmpty(apiResponse)){
                response.errors = "Data was null with type " + (typeof apiResponse);
                reject(results);
            }
            results.apiModel.loadResponse(apiResponse, [apiResponse]);
            resolve(results);
        });
    });
    requestCache[queryApiRequestString] = results;
    return results;
}
};
