import Ember from "ember";
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import SentimentCombinedBase from "infegy-frontend/components/sentiment/combined-base/component";

export default class SentimentComparisonTable extends SentimentCombinedBase {
    sortAscending = true;
    sortField = "queryTitle";

    tableType = "chart";
    tableTypeOptions = ["chart", "numbers"];

    selectedQueryRow = null;

    @computed("sortAscending")
    get sortClassSuffix() {
        return this.sortAscending ? "sorted-asc" : "sorted-desc"
    }

    @computed("queries", "queries.updated", "sortAscending", "sortField", "sentimentField")
    get sortedQueries() {
        var queries = this.queries || [],
            asc = this.sortAscending,
            sortField = this.sortField;
        return queries.sort((queryA, queryB) => {
            var valA, valB;
            if (sortField === "queryTitle") {
                valA = Ember.get(queryA, "title");
                valB = Ember.get(queryB, "title");
            } else {
                valA = Ember.get(queryA, "sentiment.dataSeries.stats." + sortField + ".average") || 0;
                valB = Ember.get(queryB, "sentiment.dataSeries.stats." + sortField + ".average") || 0;
            }
            if (!asc) {
                let temp = valB;
                valB = valA;
                valA = temp;
            }
            if (typeof(valA) === "string") {
                return valA.localeCompare(valB);
            }
            return valA - valB;
        });
    };

    @computed("sortedQueries")
    get sortedQueriesArrays() {
        var queries = this.sortedQueries || [];
        return queries.map(q => { return { query: q, arr: [q] };});
    }

    @computed("activeFields", "combinedDataSeries.stats")
    get maxNumericalValues() {
        let statsArray = this.get("queries").mapBy("sentiment.dataSeries.stats");
        return {
            positive: Math.max(... statsArray.mapBy(`${this.activeFields.positive}.sum`)) || 0,
            negative: Math.max(... statsArray.mapBy(`${this.activeFields.negative}.sum`)) || 0,
            neutral: Math.max(... statsArray.mapBy(`${this.activeFields.neutral}.sum`)) || 0,
        };
    };

    @computed("selectedQueryRow")
    get selectedQueryArray() {
        return this.selectedQueryRow ? [this.selectedQueryRow] : null;
    }

    @action
    sortBy(newSortField) {
        if (newSortField === this.sortField) {
            this.toggleProperty("sortAscending");
        } else {
            this.setProperties({
                sortField: newSortField,
                sortAscending: newSortField === "queryTitle"
            });
        }
    };

    @action
    hoveredIndexWasChanged(hoveredIndex) {
        this.set("hoveredIndex", hoveredIndex);
    };

    @action
    tableTypeChanged(type) {
        this.set("tableType", type);
    }

    @action
    querySelected(query) {
        this.set("selectedQueryRow", (this.selectedQueryRow === query) ? null : query);
    }
};
