import { CAProvinces } from "infegy-frontend/static_data/geography/ca_provinces";

export default CAProvinces.map(function(row) {
    return {
        title: row.title || row.label,
        label: row.label || row.title,
        alternates: row.alternates,
        value: row.code
    };
});
