import Ember from 'ember';

export function positiveNegative(params, namedArgs) {
  var medianValue = namedArgs.medianValue || 0.0;
    var titleCase = namedArgs.titleCase || false;
    if (params[0] >= medianValue) {
        return titleCase ? "Positive" : "positive";
    } else {
        return titleCase ? "Negative" : "negative";
    }
}

export default Ember.Helper.helper(positiveNegative);
