import Ember from "ember";
import DataSeriesComputedFieldBase from "infegy-frontend/models/data_series/data_series_computed_fields";
import NumberStats from "infegy-frontend/models/data_series/number_stats";

// Creates a summed field based on a provided array of source fields:
var DataSeriesComputedFieldPercentage = DataSeriesComputedFieldBase.extend({
    destinationField: "",
    sourceFields: [],
    type: "number",

    addFieldsTo: function(fields) {
        var destinationField = this.destinationField,
        type = this.type;

        if (destinationField && type) {
            fields[destinationField] = type;
        }
        return fields;
    },
    rowCompute: function(row) {
        var destinationField = this.destinationField,
            sourceFields = this.sourceFields,
            total = 0;

        if (sourceFields && Ember.isArray(sourceFields)) {
            total = sourceFields.reduce((memo, field) => { return memo + (row[field] || 0);}, 0); 
        }

        row[destinationField] = total || 0;
        return row;
    },
    
    statsCompute: function(stats, data) {
        var destinationField = this.destinationField,
            sourceFields = this.sourceFields,
            type = this.type,
            destinationStats = NumberStats.create();

        if (!Ember.isArray(sourceFields)) {
            return stats;
        }
        var totalStats = sourceFields.reduce((memo, field) => {
            if (!stats[field]) {
                return memo;
            }
            var fieldStats = stats[field];
            memo["sum"] += fieldStats.sum || 0;
            memo["firstHalfSum"] += fieldStats.firstHalfSum || 0;
            memo["secondHalfSum"] += fieldStats.secondHalfSum || 0;
            return memo;
        }, { sum: 0, firstHalfSum: 0, secondHalfSum: 0 }); 
        
        stats[destinationField] = destinationStats;

        var maxIndex = 0,
            minIndex = 0,
            max = -Infinity,
            min = Infinity,
            dataIdx = data && data.length;

        while (dataIdx--) {
            var val = (data && data[dataIdx] && data[dataIdx][destinationField]) || 0;

            if (max <= val) {
                max = val;
                maxIndex = dataIdx;
            }
            if (min >= val) {
                min = val;
                minIndex = dataIdx;
            }
        }

        destinationStats.max = max;
        destinationStats.min = min;
        destinationStats.maxIndex = maxIndex;
        destinationStats.minIndex = minIndex;

        destinationStats.sum = totalStats.sum;
        destinationStats.firstHalfSum = totalStats.firstHalfSum;
        destinationStats.secondHalfSum = totalStats.secondHalfSum;

        destinationStats.average = destinationStats.sum / data.length;

        if (destinationStats.firstHalfSum) {
            destinationStats.percentChange = ((destinationStats.secondHalfSum - destinationStats.firstHalfSum) /
                destinationStats.firstHalfSum) * 100.0;
        } else {
            destinationStats.percentChange = 0;
        }

        destinationStats.type = type;

        return stats;
    }
});

DataSeriesComputedFieldPercentage.reopenClass({
    fieldSetup: function(destinationField, sourceFields) {
        return DataSeriesComputedFieldPercentage.create({
            destinationField: destinationField,
            sourceFields: sourceFields
        });
    },
});

export default DataSeriesComputedFieldPercentage;
