import Ember from "ember";
import FieldDefinition from "infegy-frontend/models/fields/trend-field-definition";

export default Ember.Object.extend({
    tagName: "span",

    apiName: null,

    queries: null,
    query: null,

    defaultSubSegment: null,

    allAvailableFields: [],

    makeFieldDefinition: function (options) {
        var newField = FieldDefinition.create(),
            fullOptions = Object.assign({
                apiName: this.apiName
            }, options);

        if (!fullOptions.title) {
            fullOptions.title = fullOptions.fieldName || "";
            fullOptions.title = fullOptions.title.replace(/[A-Z]/g, function (letter) {
                return " " + letter;
            });
            fullOptions.title = fullOptions.title.charAt(0).toUpperCase() + fullOptions.title.substr(1);
        }
        if (!fullOptions.value) {
            fullOptions.value = [fullOptions.apiName, fullOptions.fieldName].join(".");
        }
        newField.loadJSON(fullOptions);
        return newField;
    },

    allQueries: Ember.computed("apiName", "queries", "queries.[]", "query", "queries.@each.updated", "query.updated", {
        get: function(key) {
            var queries = this.queries || [],
                query = this.query;
            if (query) {
                queries = queries.concat(query);
            }
            queries = queries.uniq();
            return queries;
        }
    }),
});
