import Ember from "ember";
import Component from '@ember/component';
import {computed, action} from "@ember/object";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import AtlasConfig from "infegy-frontend/config/infegy-app-config";

export default class extends Component {
    tagName = "span";
    classNames = ["no-print"];

    queries = null;
    filename = "Atlas Export";
    exportElementID = null;
    exportFormat = "png";
    formatOptions = ["png", "jpg", "pdf"];

    marginX = 150;
    marginY = 150;

    optionWindowIsOpen = false;
    isExporting = false;
    exportFailureReason = null;

    filenameOverride = null;

    makeTransparent = true;
    view = "";

    @computed("filename", "queries", "filenameOverride")
    get innerFilename() {
        if (this.filenameOverride) {
            return this.filenameOverride;
        }
        let filename = this.filename;
        if (this.queries && this.queries.length) {
            let queryTitle = "Multi-Query";
            if (this.queries.length === 1) {
                queryTitle = this.queries[0].title;
                // Clear out bad chars for a filename
                queryTitle = queryTitle.replace(/[^\w\- ]+/gi, '').replace(/\s+/gi, " ");
            }
            filename = `${queryTitle} - ${filename}`;
        }
        return filename;
    }
    set innerFilename(value) {
        this.set("filenameOverride", value);
    }

    @computed()
    get userPermissions() {
        return AtlasAuth.user.permissions;
    }

    @computed("exportFormat")
    get isPNG() {
        return this.exportFormat === "png";
    }

    @computed("view")
    get isExportable() {
        return !(this.view === "force-graph" || this.view === "node");
    }

    @computed("makeTransparent", "isPNG")
    get backgroundColor() {
        let backgroundColor = "background-color:transparent";
        if (!this.isPNG || !this.makeTransparent) {
            backgroundColor = "background-color: #fff";
        }
        return backgroundColor;
    }

    requestDownload() {
        var exportElementID = this.exportElementID,
            element = document.getElementById(exportElementID);
        if (!element || !element.serializeWithStyles) {
            this.set("isExporting", false);
            this.set("exportFailureReason", "Element " + exportElementID + " not found.");
            return;
        }

        element.classList.add('print');

        var marginX = this.marginX || 0,
            marginY = this.marginY || 0,
            width = (element.offsetWidth || 1920) + marginX,
            height = (element.offsetHeight || 1080) + marginY,
            filename = this.innerFilename || "export",
            exportFormat = this.exportFormat || "png",
            fullFilename = [filename, exportFormat].join("."),
            result;

        result = `<html>
        <head>
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Roboto:300,400,500,700">
            <meta charset="utf-8">
            <style>*{ font-family: "Roboto", "Helvetica Neue", "Helvetica", Helvetica, Arial, "icons", "NotoColorEmoji", sans-serif; }</style>
        </head>
        <body style="${this.backgroundColor};margin:0; height:${height}px;width:${width}px;">
        <div style="margin:${ Math.round(marginX / 2) }px ${Math.round(marginY / 2) }px 0;">
        ${element.serializeWithStyles()}
        </div>
        </body></html>`;

        element.classList.remove('print');

        var url = AtlasConfig.baseAPIDomain + "phantom";
        url += "?filename=" + filename;
        url += "&width=" + width;
        url += "&height=" + height;
        url += "&format=" + exportFormat;

        AtlasAuth.downloadPOST(url, result, fullFilename).then(() => {
            this.set("isExporting", false);
            this.set("exportFailureReason", null);
            this.send("closeOptions");
        }).catch(() => {
            this.set("isExporting", false);
            this.set("exportFailureReason", "Could not save output.");
        });
    }

    @action startExport() {
        this.set("exportFailureReason", null);
        this.set("isExporting", true);
        this.requestDownload();
    }

    @action closeOptions() {
        this.set('optionWindowIsOpen', false);
    }

    @action openOptions() {
        this.set('optionWindowIsOpen', true);
    }

    @action formatChanged(format) {
        this.set("exportFormat", format || "png");
    }

    @action filenameChanged(filename) {
        this.set("filenameOverride", filename);
    }

    @action toggleMakeTransparent(value) {
        this.set("makeTransparent", value);
    }
}
