import Route from "@ember/routing/route";
import { action } from "@ember/object";
import { isEmpty } from "@ember/utils";
import Workspaces from "infegy-frontend/models/users/workspaces";
import GlobalFilters from "infegy-frontend/models/queries/query_global_filters";

export default class extends Route {
    showEditor = false;
    urlWasCorrupt = false;

    beforeModel(transition) {
        if (transition.targetName === "atlas.dashboard" || transition.targetName === "atlas.dashboard.index") {
            this.set("showEditor",true);
        } else {
            this.set("showEditor", false);
        }
        this.set("urlWasCorrupt", transition.data.urlIsCorrupt || false);
    }

    // model = queries
    setupController(controller, model) {
        let queries = model || controller.queries;

        if (queries.length < 1) {
            if (!isEmpty(controller.cachedWorkspace)) {
                controller.workspace.loadJSON(JSON.parse(controller.cachedWorkspace));
                GlobalFilters.setDefaultDateRange(controller.workspace.queries, true);
            }
            if (queries.length < 1) {
                controller.addBlankQuery();
                GlobalFilters.setDefaultSocialDateRange(controller.workspace.queries);
            }
        }

        controller.setProperties({
            showEditor: this.showEditor,
            urlWasCorrupt: this.urlWasCorrupt
        });

        controller.savedWorkspaces = Workspaces.create({searchType: "saved"});
        controller.savedWorkspaces.getPage();
        controller.recentWorkspaces.getPage();
    }

    @action didTransition() {
        this.controller.pollQueries();
    }

    @action willTransition(transition){
        var controller = this.controller;
        var queries = controller.queries;

        var intentIsQueryForm = transition.intent.name === "atlas.dashboard";
        const queriesHaveErrors = queries.find((query) => !isEmpty(query.get("errors")));

        if (intentIsQueryForm && queriesHaveErrors) {
            queries.forEach((query) => {
                if (!Ember.isEmpty(query.get("errors"))) {
                    query.clearAPIResults();
                }
            });
        }

        // Catches blank queries when transitioning to dashboard
        if (intentIsQueryForm) {
            queries.forEach((query) => {
                if (query.isEmpty) {
                    controller.workspace.removeQuery(query);
                }
            });
        }

        // Check where we are headed, if it's the editor, make sure it displays
        if (transition.targetName === "atlas.dashboard" || transition.targetName === "atlas.dashboard.index") {
            this.controller.set("showEditor",true);
        } else {
            this.controller.set("showEditor", false);
        }
        controller.setProperties({
            cachedWorkspace: "",
            urlWasCorrupt: false
        });
    }
}
