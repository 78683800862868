import ChartColors from "infegy-frontend/static_data/fixtures/chart_colors";

var singleQueryFieldOptions = [{
        label: "Sentiment by Appearance",
        value: "positiveAppearancesDistribution",
        colorRange: ChartColors.get("sentimentGradientCSSColors"),
        sentimentThresholdKey: "appearances",
        keyType: "sentiment"
    }, {
        label: "Sentiment by Post",
        value: "positiveDocumentsDistribution",
        colorRange: ChartColors.get("sentimentGradientCSSColors"),
        sentimentThresholdKey: "documents",
        keyType: "sentiment"
    }, {
        label: "Number of Appearances",
        value: "appearances",
        colorRange: ChartColors.get("genericDataGradientColors"),
        keyType: "number"
    }, {
        label: "Number of Posts",
        value: "documents",
        colorRange: ChartColors.get("genericDataGradientColors"),
        keyType: "number"
    }];

var multiQueryFieldOptions = singleQueryFieldOptions.concat([{
        label: "Query",
        value: "_originalQueryIdx",
        keyType: "query"
    }]);

var fieldLookups = multiQueryFieldOptions.reduce((memo, row) => {
        memo[row.value] = row;
        return memo;
    }, {});

export default {
    singleQueryFieldOptions: singleQueryFieldOptions,
    multiQueryFieldOptions: multiQueryFieldOptions,
    fieldLookups: fieldLookups
};
