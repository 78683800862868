import Ember from "ember";
import { MouseInteractionWrapper } from "infegy-frontend/components/charting/mouse-interaction-mixin/component";

export default class extends MouseInteractionWrapper(Ember.Component) {
    tagName = 'rect';
    attributeBindings = ["x","y","width","height","style"];
    @Ember.computed.alias("contentArea.width") width;
    @Ember.computed.alias("contentArea.height") height;
    x = 0;
    y = 0;
    contentArea = null;
    projectionData = null;

    style = "fill:none;stroke:none;";

    _isDragging = false;
    initialDragPoint = null;

    onMouseLeave(evt) {
        if (this._isDragging) {
            this.set("_isDragging", false);
        }
    }

    mouseDown(evt){
        this.initialDragPointChanged({x: evt.offsetX, y:evt.offsetY});
    }
    onMouseMove(evt){
        Ember.run.debounce(()=>{
            if(this._isDragging){
                this.currentEndPointChanged({x: evt.offsetX, y:evt.offsetY});
            }
        },100);
    }
    mouseUp(evt){
        if(this._isDragging){
            var initPoint = this.initialDragPoint;

            this.set("_isDragging",false);
            this.sendEndpoint("dragEnded");
        }
    }

    initialDragPointChanged = () => {};
    currentEndPointChanged = () => {};
}
