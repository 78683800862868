import Component from '@ember/component';
import QueryURL from 'infegy-frontend/models/queries/query_url';
import { computed } from '@ember/object';

export default class extends Component {
    workspace = null;

    @computed("workspace.query")
    get queries(){
        return this.workspace.query && QueryURL.urlToQueryArray(this.workspace.query) || [];
    }
}