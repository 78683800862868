import Ember from "ember";

export default Ember.Mixin.create({
    query: null,
    apis: [],

    hideInnerView: Ember.computed.or("isEmpty", "isLoading"),
    prefetchQueryApis: Ember.on("init",function() {
        var query = this.query,
            apis = this.apis || [];

        if (query && typeof(query) !== "string" && apis && Ember.isArray(apis)) {
            apis.forEach(function(apiName) {
                query.get(apiName);
            }, this);
        }
    }),
    runPrefetchQueryApis: Ember.observer("query", "apis", "apis.[]",function() {
        Ember.run.once(this, "prefetchQueryApis");
    }),

    isLoading: Ember.computed.not('isLoaded'),
    isLoaded: Ember.computed("query", "apis", "apis.[]", "query.updated",
            "isEmpty", {
        get: function(key) {
            var apis = this.apis,
                query = this.query;

            if (!query || !Ember.isArray(apis)) {
                return false;
            }

            var loaded = apis.every(function (api_name) {
                return query.get(api_name) && query.get(api_name).get("isLoaded") &&
                    !query.get(api_name).get("isEmpty");
            }, this);
            return loaded || this.isEmpty;
        },
    }),
    isEmpty: Ember.computed("query", "apis", "apis.[]", "query.updated", {
        get: function(key) {
            var apis = this.apis,
                query = this.query;

            if (!query || !Ember.isArray(apis)) {
                return false;
            }
            var loaded = apis.every(function (api_name) {
                    return query.get(api_name) && query.get(api_name).get("isEmpty");
                }, this);

            return loaded;
        },
    }),
});
