import Ember from "ember";
import BaseFieldSelections from "infegy-frontend/models/fields/field-selections/base";

export default BaseFieldSelections.extend({
    apiName: "gender",
    defaultSubSegment: "Count Distribution",

    allAvailableFields: Ember.computed({
        get: function(key) {
            return {
                "Gender.CountDistribution" : [
                    this.makeFieldDefinition({
                        title: "Female Count Distribution",
                        fieldName: "femaleCountDistribution",
                        suggestedColorClass: "atlas-female",
                    }),
                    this.makeFieldDefinition({
                        title: "Male Count Distribution",
                        fieldName: "maleCountDistribution",
                        suggestedColorClass: "atlas-male",
                    })
                ],
                "Gender.Count" : [
                    this.makeFieldDefinition({
                        title: "Female Count",
                        fieldName: "femaleCount",
                        suggestedColorClass: "atlas-female",
                    }),
                    this.makeFieldDefinition({
                        title: "Male Count",
                        fieldName: "maleCount",
                        suggestedColorClass: "atlas-male",
                    })
                ],
                "Gender.UniverseDistribution" : [
                    this.makeFieldDefinition({
                        title: "Female Universe Distribution",
                        fieldName: "femaleUniverseDistribution",
                        suggestedColorClass: "atlas-female",
                    }),
                    this.makeFieldDefinition({
                        title: "Male Universe Distribution",
                        fieldName: "maleUniverseDistribution",
                        suggestedColorClass: "atlas-male",
                    })
                ],
                "Gender.Universe" : [
                    this.makeFieldDefinition({
                        title: "Female Universe",
                        fieldName: "femaleUniverse",
                        suggestedColorClass: "atlas-female",
                    }),
                    this.makeFieldDefinition({
                        title: "Male Universe",
                        fieldName: "maleUniverse",
                        suggestedColorClass: "atlas-male",
                    })
                ]
            };
        }
    }),

});
