import Ember from "ember";
import Component from '@ember/component';
import {computed, action} from "@ember/object";
import classic from "ember-classic-decorator";

@classic
export default class extends Component {
    trendFields = null;
    trendChartMeta = null;

    selectable = false;

    hasMixedTimespans = false;

    @computed("trendFields.@each.isSinglePoint")
    get isSinglePoint() {
        return !((this.trendFields || []).find(field => {
            return !field.isSinglePoint;
        }));
    }

    trendChartMetaUpdated() {};
    fieldSelected() {};
    fieldClicked() {};

    @action
    fieldHovered(field) {
        if (this.trendChartMeta && this.trendChartMeta.hoveredField !== field) {
            this.set("trendChartMeta.hoveredField", field);
            this.set("trendChartMeta.isHovered", false);
            this.trendChartMetaUpdated(this.trendChartMeta);
        }
    }

    @computed("trendChartMeta.isHovered", "trendChartMeta.isSelected", "trendChartMeta.isBrushed")
    get interactedValueType() {
        if (this.trendChartMeta?.isHovered) {
            return "Hovered";
        } else if (this.trendChartMeta?.isSelected) {
            return "Selected";
        } else if (this.trendChartMeta?.isBrushed) {
            return "Highlighted";
        }
    }
}
