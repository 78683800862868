import Ember from "ember";
import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";
import States from "infegy-frontend/static_data/geography/states";
import DataSeries from "infegy-frontend/models/data_series/data_series";
import fieldTimelineGrowth from "infegy-frontend/models/data_series/data_series_computed_field_growth";

var stateResults = QueryAPIResults.extend({
    title : "states",
    preload: function(){
        var states = this.get("rawResults");
        states.forEach((state)=>{
            state.region = States.RegionByStates[state.code];
            state.regionName = States.RegionCodes[state.region];
            state.key = state.code;
        });
    },
    customEmptyCheckFunction: function(dataSeries){
        var data = Ember.get(dataSeries,"data");
        return data.reduce((sum, current) => sum + Ember.get(current,"sources"), 0) == 0;
    }
});

var description = DataSeriesDescription.load({
    "id": "string",
    "code": "string",
    "name": "string",

    "region": "string",
    "regionName": "string",
    "key": "string",

    "broadbandUsers": "number",
    "population": "number",
    "totalSources": "number",

    "matches": "number",
    "sources": "number",
    "score": "number",

    "positiveMatches": "number",
    "negativeMatches": "number",
    "neutralMatches": "number",
    "positivity": "number"
}, [
    fieldTimelineGrowth.fieldSetup("documentsGrowthPct", "timeline")
]);

stateResults.reopen({
    description: description
});

stateResults.reopenClass({
    // This funtion combines geo objects into one by averaging their values
    // It assumes you are passing in an array of matching geo states, and so
    //   preserves the first state's string fields
    // The original states will be contained in _sourceGeos
    combineMatchingGeos: function(geoArray) {
        var filteredGeos = geoArray.filter(geo => typeof(geo) === "object");

        if (Ember.isEmpty(filteredGeos)) {
            return {};
        }

        var numGeos = filteredGeos.length,
            firstGeo = filteredGeos.shift();

        if (numGeos === 1) {
            return Object.assign({}, firstGeo, {
                _sourceGeos: [firstGeo]
            });
        }

        var tempDataSeries = DataSeries.create(),
            newGeo = Object.assign({}, firstGeo, {
                _sourceGeos: [firstGeo]
            });

        newGeo = filteredGeos.reduce((memo, geo) => {
            memo.score += geo.score;

            memo.matches += geo.matches;
            memo.sources += geo.sources;
            memo.totalSources += geo.totalSources;

            memo._sourceGeos.push(geo);
            return memo;
        }, newGeo);

        newGeo.score /= numGeos;

        tempDataSeries.buildComputed([newGeo], description);
        return newGeo;
    }
});

export default stateResults;
