import Ember from "ember";
import SpeedTesterComponent from "infegy-frontend/components/devel/speed-tester/component";
import QueryAPIMixin from "infegy-frontend/components/query-api/base/query-api-mixin/component";

export default SpeedTesterComponent.extend(QueryAPIMixin,{
    apis: [],

    disabled: Ember.computed("query", "query.updated", function() {
        if (Ember.isEmpty(this.apis) || Ember.isEmpty(this.query)) {
            this.set("buttonText", "No API or Query");
            return "disabled";
        }
        var loaded = this.apis.every(function(apiName) {
            return this.query.get(apiName).get("isLoaded");
        }, this);
        if (!loaded) {
            this.set("buttonText", "Loading...");
            return "disabled";
        }
        this.set("buttonText", "Run Test");
    })
});
