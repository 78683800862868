import Ember from "ember";

export default Ember.Component.extend({
    value:null,

    warningMessage:null,

    queryFieldOptions: null,

    errorsUpdated: () => {},
    valueChanged: () => {},
    submitQuery: () => {},
    actions: {
        warningsUpdated(warning){
            this.set("warningMessage",warning);
        },
        valueChanged(...args){
            this.valueChanged(...args);
        },
        submitQuery(...args){
            this.submitQuery(...args);
        }
    }
});
