import Component from "@ember/component";
import { action, computed } from "@ember/object";
import WorkspaceFolder from "infegy-frontend/models/users/workspace_folder";
import classic from "ember-classic-decorator";

@classic
export default class extends Component {
    queryFormQueries = [];
    user = null;

    newWorkspaceFolderTitle = "";
    isCreatingNewFolder = false;
    searchText = "";

    sortField = "updatedOn";
    sortFieldOptions = [
        {
            label: "Created",
            value: "createdOn"
        },
        {
            label: "Title",
            value: "title"
        },
        {
            label: "Updated",
            value: "updatedOn"
        },
    ];
    sortDirection = "ASC";
    sortDirectionOptions = [
        {
            label: "Asc.",
            value: "ASC"
        },
        {
            label: "Desc.",
            value: "DESC"
        }
    ];

    @computed.empty("newWorkspaceFolderTitle") isNewWorkspaceFolderTitleBlank;
    @computed.alias("user.workspaceFolders") workspaceFolders;

    @computed("workspaceFolders", "workspaceFolders.length", "searchText", "sortField", "sortDirection")
    get displayedWorkspaceFolders() {
        let result = this.workspaceFolders.filter((workspaceFolder) => {
            return workspaceFolder.title.toLowerCase().includes(this.searchText.toLowerCase());
        });
        result = result.sort((a, b) => {
            if (a[this.sortField] > b[this.sortField]) return 1;
            if (a[this.sortField] < b[this.sortField]) return -1;
            return 1;
        });
        if (this.sortDirection === "ASC") result.reverse();
        return result;
    }

    async createFolder() {
        let workspaceFolder = WorkspaceFolder.create();
        await workspaceFolder.save(this.newWorkspaceFolderTitle);
        this.workspaceFolders.load();
        this.set("isCreatingNewFolder", false);
    }

    @action async onRemoveFolder() {
        this.removeFromFolder();
        this.workspaceFolders.load();
    }

    @action sortDirectionChanged(direction) {
        this.set("sortDirection", direction);
    }
}