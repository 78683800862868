import { computed } from '@ember/object';
import { inject as controller } from '@ember/controller';
import Controller from '@ember/controller';

export default class BaseRoute extends Controller {
    @controller() application;
    @controller("atlas/dashboard/content") content;
    @computed.alias('application.user') user;

    /** @type {Query[]}
     *  These queries will change their grouping based on the time window of the
     *  query and can be assigned a grouping. Eventually this should be the new
     *  "queries" */
    @computed.alias("content.selectedQueryObjects") groupedQueries;

    /** @type {Query[]}
     *  These queries will always be assigned a "day" grouping which
     *  historically was the assumption. Prefer "groupedQueries" for new
     *  components. */
    @computed.alias("content.dayGroupedQueries") queries;

    /** @type {Query}
     *  Uses the standard "day" grouping */
    @computed.alias("queries.firstObject") query;


    /** @type bool
     *  All active queries use custom datasets */
    @computed.alias('content.hasOnlyCustomDatasets') hasOnlyCustomDatasets;
}
