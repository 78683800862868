export default {
    Enter: 13,
    Escape: 27,
    Backspace: 8,
    Tab: 9,
    Delete: 46,

    PageUp: 33,
    PageDown: 34,
    End: 35,
    Home: 36,
    
    Left: 37,
    Up: 38,
    Right: 39,
    Down:40,
};