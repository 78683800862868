import Ember from "ember";
import Component from '@glimmer/component';
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import { run } from '@ember/runloop';
import { computed, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { actionArgument, argument } from "infegy-frontend/decorators/arguments";

export default class extends Component {
    @argument("") filterFieldId;
    @argument(null) datasetId;
    @argument(null) selectedOptions;

    @tracked autocompleteOptions = null;

    @actionArgument selectedOptionsChanged;

    @computed("autocompleteOptions", "selectedValues")
    get options() {
        // let selected = (this.selectedOptions || []).map(val => {
        //     return { label: val, value: val };
        // });
        return [...(this.autocompleteOptions || []), ...(this.selectedOptions || [])];
    }

    @action
    searchStringChanged(searchString) {
        let isEntity = this.filterFieldId === "entity" || this.filterFieldId === "entities";
        if (searchString.length < 2 || !this.filterFieldId || (!isEntity && !this.datasetId)) {
            run.next(()=>{
                this.autocompleteOptions = [];
            });
            return;
        }

        let url = isEntity ? "query_autocomplete" : "filter-autocomplete",
            data = {
                prefix: searchString,
                limit: 30
            };
        if (!isEntity) {
            data.filter = this.filterFieldId;
            data.dataset_id = this.datasetId;
        }

        AtlasAuth.Get({
            url: `api/v3/${url}`,
            data: data
        }).then(response => {
            run.next(()=>{
                let output = isEntity ? response?.output?.entities : response?.output,
                    opts = output.map(opt => {
                        if (isEntity) {
                            return { label: opt.name, value: opt.id };
                        } else if (typeof(opt) === "object" && opt.term) {
                            opt = opt.term;
                        }
                        return { label: opt, value: opt };
                    });
                this.autocompleteOptions = opts;
            });
        });
    }
}
