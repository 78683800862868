import Ember from "ember";
import {computed} from "@ember/object";
import Prop from 'infegy-frontend/json-store/properties/model';
import BaseFieldDefinition from "infegy-frontend/models/field-definitions/base-field";

export default class TrendField extends BaseFieldDefinition {
    // Determines which field class to load
    fieldClass = "trend-field";

    // Externally set for comparing multiple fields using same Y axis,
    // use chartMaxY for reading
    axisMaxY = null;

    isMeta = false;

    @Prop.String({default: "timestamp"}) xAxisField;

    // For portion fields, the "whole" value the portion is part of (when that
    // portion has a fixed "whole" amount)
    // i.e. the sentiment fixedPortionWhole is usually 1.0, for passion it's 100
    // this does not need to be set for fields where this can be computed.
    @Prop.Number({required: false}) fixedPortionWhole;

    // The scalar value type from the stats to use: "sum, min, max, average, etc"
    @Prop.String({default: "sum"}) scalarField;

    // Trend grouping override, for display purposes. This is normally computed
    // using the query and accessible with the "grouping" property
    @Prop.String({default: "average"}) groupingOverride;

    // Override for stacking trend line points - by default uses the xAxisField,
    // but can be overridden with this value
    @Prop.String() stackingFieldOveride;

    // For scalar only field usage this is pretty much all that is needed....
    @computed("summaryValue", "scalarField", "stats", "min", "max", "sum", "average", "percentChange")
    get scalarValue() {
        if (!this.scalarField) {
            return this.summaryValue;
        }
        return this.get(this.scalarField);
    }

    @computed("fixedPortionWhole", "statsType", "isPercentageType")
    get portionWhole() {
        if (!Ember.isEmpty(this.fixedPortionWhole)) {
            return this.fixedPortionWhole;
        }
        if (this.isPercentageType) {
            return 1.0;
        }
    }

    @computed("portionWhole", "scalarField", "isPercentageType")
    get maxScalarValue() {
        if (this.scalarField === "sum") {
            return this.scalarValue;
        }
        if (this.portionWhole) {
            return this.portionWhole;
        }
        if (!this.isPercentageType && (this.scalarField === "max" || this.scalarField === "min")) {
            return this.max;
        }
        return this.scalarValue;
    }

    @computed("portionWhole", "scalarField")
    get minScalarValue() {
      return 0;
    }

    @computed.readOnly("dataSeries.data") data;
    @computed.readOnly("dataSeries.stats") stats;

    @computed("fieldName", "stats")
    get fieldStats() {
        var fieldName = this.fieldName,
            stats = this.stats;
        return stats && fieldName && Ember.get(stats, fieldName);
    }

    @computed("stackingFieldOveride", "xAxisField")
    get stackingField() {
        return this.stackingFieldOveride || this.xAxisField;
    }


    @computed.readOnly("fieldStats.type") statsType;
    @computed.readOnly("fieldStats.sum") sum;
    @computed.readOnly("fieldStats.min") min;
    @computed.readOnly("fieldStats.max") max;
    @computed.readOnly("data.length") rowCount;
    @computed.readOnly("fieldStats.average") average;
    @computed.readOnly("fieldStats.percentChange") percentChange;

    @computed("statsType")
    get isPercentageType() {
        if (this.scalarField === "percentChange") {
            return true;
        }

        let statsType = this.statsType;
        return (statsType === "percentage") ||
            (statsType === "percent") ||
            (statsType === "distribution") ||
            (statsType === "portion");
    }

    @computed("isPercentageType", "fixedPortionWhole")
    get isPortionable() {
        return !!this.fixedPortionWhole || this.isPercentageType;
    }

    @computed("statsType", "isAveragedType", "sum", "average")
    get summaryValue() {
        if (this.isAveragedType) {
            return this.average;
        }
        return this.sum;
    }

    @computed("data", "fieldName")
    get values() {
        var data = this.data || [],
            fieldName = this.fieldName;
        return data.mapBy(fieldName);
    }

    getDataSeriesRow(index) {
        return this.dataSeries && this.get("dataSeries.data")[index] || {};
    }

    getDataSeriesRowFieldValue(index) {
        var row = this.getDataSeriesRow(index),
            field = this.fieldName;
        return (row && field && row[field]) || 0;
    }

    @computed("xAxisField", "stats")
    get xFieldStats() {
        var fieldName = this.xAxisField,
            stats = this.stats;
        return stats && fieldName && fieldName && Ember.get(stats, fieldName);
    }

    @computed.readOnly("xFieldStats.min") minX;
    @computed.readOnly("xFieldStats.max") maxX;

    @computed("data", "fieldStats", "fieldStats.max", "fixedMaxY", "isPercentage", "isRangelessPercentage")
    get maxY() {
        if (this.fixedMaxY) {
            return this.fixedMaxY;
        } else if (!this.isRangelessPercentage && this.isPercentage) {
            return 1.0;
        }
        return (this.fieldStats && this.fieldStats.max) || 1;
    }

    @computed("maxY", "axisMaxY", "data")
    get chartMaxY() {
        return Ember.isEmpty(this.axisMaxY) ? this.maxY : this.axisMaxY;
    }

    @computed("data", "xAxisField")
    get xValues() {
        var data = this.data || [],
            xAxisField = this.xAxisField;
        return data.mapBy(xAxisField);
    }

    @computed("min", "fieldStats", "dataSeries", "dataSeries.data", "fieldStats.minIndex")
    get minRow() {
        var fieldStats = this.fieldStats,
            minIdx = fieldStats && fieldStats.get("minIndex") || 0;
        return this.getDataSeriesRow(minIdx);
    }

    @computed("max", "fieldStats", "dataSeries", "dataSeries.data", "fieldStats.maxIndex")
    get maxRow() {
        var fieldStats = this.fieldStats,
            maxIdx = fieldStats && fieldStats.get("maxIndex") || 0;
        return this.getDataSeriesRow(maxIdx);
    }

    @computed.oneWay("minRow.timestamp") minValueTimestamp;
    @computed.oneWay("maxRow.timestamp") maxValueTimestamp;
};
