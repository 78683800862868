import Ember from "ember";
import Component from '@ember/component';
import { computed } from '@ember/object';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import BrowserStore from "infegy-frontend/utils/browser-store";

export default class ClusterMerger extends Component {
    // arguments
    selectedClusterIndices=[];
    query=null;

    // properties
    newClusterName = "";
    showMergeForm = false;

    @action submit() {
        this.mergeClusters(this.newClusterName);
        this.setProperties({
            newClusterName: "",
            showMergeForm: false
        });
    }

}
