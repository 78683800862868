import Ember from "ember";
import SelfContainedTrends from "infegy-frontend/components/trends/self-contained-trends/component";

export default SelfContainedTrends.extend({
    segmentTypes: "drilldown",
    selectedTrendsSegment: "Posts",

    query: null,
    queries: null, 

    innerQueries: Ember.computed("query", "queries", function () {
        if(Ember.isNone(this.queries)){
            if (this.query) {
                return [this.query];
            }
        } else {
            return this.queries;
        }
    }),
    fieldApis: Ember.computed("activeFields", "activeFields.[]",
            "activeFields.@each.apiName", function () {
        return (this.activeFields || []).mapBy("apiName").uniq() || ["volume"];
    }),
});
