import Ember from "ember";
import { computed } from '@ember/object';
import * as Sentry from "@sentry/browser";

import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import JsonStore from "infegy-frontend/json-store/model";
import Prop from "infegy-frontend/json-store/properties/model";
import AudienceSegments from "infegy-frontend/static_data/query/query_filters/audience_segments";
import Dictionaries from "infegy-frontend/static_data/query/query_filters/dictionaries";
import Alert from "infegy-frontend/models/users/alert";
import Usage from "infegy-frontend/models/users/usage";
import QueryAlert from "infegy-frontend/models/users/query-alert";
import CollectionStore from "infegy-frontend/json-store/collection/model";
import Permissions from "infegy-frontend/models/users/permissions";
import CustomDataset from "infegy-frontend/models/users/custom-dataset";

import SharedResource from "infegy-frontend/models/users/shared_resource";
import SubquerySet from "infegy-frontend/models/users/subquery_set";
import Filterset from "infegy-frontend/models/users/filterset";
import SubquerySets from "infegy-frontend/static_data/query/query_filters/subquery_sets";
import CompanyUsers from "infegy-frontend/models/company_users";

import Workspaces from "infegy-frontend/models/users/workspaces";
import Queries from "infegy-frontend/models/users/queries";
import Snippets from "infegy-frontend/models/users/snippets";
import WorkspaceFolders from "infegy-frontend/models/users/workspace_folders";

const buildExternalLoadFunction = (url, itemClass, propertyKey) => {
    return async function () {
        try {
            await this.loadExternalCollection(url, itemClass, propertyKey);
        } catch(error) {
            if (error.status !== 403) {
                console.error(error.atlasErrorText); // eslint-disable-line no-console
                Sentry.captureMessage(`User could not load ${propertyKey}`);
            }
        }
    };
};

const createWhenAccessed = (type) => {
    return function(obj, propertyName) {
        var innerKey = `_${propertyName}`;
        return Ember.computed()(obj, propertyName, {
            get() {
                if (Ember.isEmpty(Ember.get(this, innerKey))){
                    Ember.set(this, innerKey, type.create());
                }
                return Ember.get(this, innerKey);
            },
            set(value) {
                Ember.set(this, innerKey, value);
            }
        });
    };
};

export default class User extends JsonStore {
    @Prop.Int() id;

    @Prop.String() username;
    @Prop.String() email;
    @Prop.String() name;

    @Prop.Int({default: 0}) type;

    @Prop.String() timeZone;

    @Prop.String() apiKey;
    @Prop.String() apiMonthlyLimit;
    @Prop.Int() freeReports;

    @Prop.Attr() company;

    @Ember.computed.equal("type",18) isLite;
    @Ember.computed.gte("type",100) isAdmin;
    @Prop.Object(Permissions) permissions;

    alerts = [];
    queryAlerts = [];
    customDatasets = [];
    customDatasetsPromise = null;
    sharedResources = [];

    filtersets = [];
    usage = {};

    savedQueries = Queries.create({onlySaved: true});
    ownedWorkspaces = Workspaces.create({searchType: "owned"});
    sharedWorkspaces = Workspaces.create({searchType: "shared"});
    snippets = Snippets.create();
    workspaceFolders = WorkspaceFolders.create();

    loadQueryAlerts = buildExternalLoadFunction("api/v3/alert_query",QueryAlert,"queryAlerts");
    loadAlerts = buildExternalLoadFunction("api/v3/notifications",Alert,"alerts");
    loadFiltersets = buildExternalLoadFunction("api/v3/filtersets", Filterset, "filtersets");
    loadSharedResources = buildExternalLoadFunction("api/v3/shared-resource/list", SharedResource, "sharedResources");
    loadQuerySets = buildExternalLoadFunction("api/v3/query-sets", SubquerySet, "subquerySets");
    loadCustomDatasets = async () => {
        let promise = AtlasAuth.ImmediateAjax({
            url: 'api/v3/custom-dataset/list.json',
            context: this,
            type: "GET"
        });
        this.set("customDatasetsPromise", promise);
        promise.then((data) => {
            var customDatasets = CollectionStore.create();
            customDatasets.set("itemClass", CustomDataset);
            customDatasets.loadJSON(data.output);
            for (let dataset of customDatasets.content) {
                dataset.pollForContent();
            }
            this.set("customDatasets", customDatasets);
        }).catch((err) => {
            Sentry.captureMessage("Could not load custom datasets");
        });
        return promise;
    }

    @createWhenAccessed(Dictionaries) dictionaries;
    @createWhenAccessed(SubquerySets) subquerySets;
    @createWhenAccessed(CompanyUsers) companyUsers;
    @createWhenAccessed(AudienceSegments) audienceSegments;

    loadSocialQueryInfo() {
        if (AtlasAuth.isLoggedIn) {
            return AtlasAuth.Get({
                url: "api/v3/query-info",
                context: this
            }).then((data) => {
                this.set("rawSocialQueryInfoIsLoading", false);
                this.set("rawSocialQueryInfo", data?.output);
            });
        } else {
            return Promise.resolve();
        }
    }

    rawSocialQueryInfo = null;
    rawSocialQueryInfoIsLoading = false;
    socialQueryInfoPromise = null;

    @computed("rawSocialQueryInfo", "rawSocialQueryInfoIsLoading")
    get socialQueryInfo() {
        let rawSocialQueryInfo = this.rawSocialQueryInfo;
        if (!rawSocialQueryInfo && !this.rawSocialQueryInfoIsLoading) {
            this.set("rawSocialQueryInfoIsLoading", true);
            let promise = this.loadSocialQueryInfo();
            this.set("socialQueryInfoPromise", promise);
        }
        return rawSocialQueryInfo;
    }

    /** Loads external collection data into a property on the user class
     * @param {string} url The API endpoint to request data from
     * @param {JSONStore} itemClass JSONStore class representing each item in the collection
     * @param {string} propertyKey The property key on the user class to store the data in
     */
    async loadExternalCollection(url, itemClass, propertyKey) {
        const data = await AtlasAuth.Get(url);

        if('output' in data) {
            let collection = CollectionStore.create();
            Ember.set(collection, "itemClass", itemClass);
            collection.loadJSON(data.output);
            Ember.set(this, propertyKey, collection);
        }
    }

    async loadUsage() {
        const data = await AtlasAuth.Get('api/v3/usage');
        var usage = Usage.create();
        usage.loadJSON(data.output);
        this.set("usage", usage);
    }

    async loadAll(data) {
        if (data) {
            this.loadJSON(data);
        }

        let resourcesToLoad = [
            this.loadUsage(),
            this.loadAlerts(),
            this.loadCustomDatasets(),
            this.loadQuerySets(),
            this.loadFiltersets(),
            this.loadSharedResources(),
            this.loadSocialQueryInfo(),
            this.workspaceFolders.load(),
            this.savedQueries.getPage(),
            this.ownedWorkspaces.getPage(),
            this.sharedWorkspaces.getPage(),
            this.snippets.getPage()
        ];
        if (this.permissions.queryAlertsEnabled) {
            resourcesToLoad.push(this.loadQueryAlerts());
        }

        // Load user resources in parallel.  This will setup any promises that may be awaited on elsewhere.
        await Promise.all(resourcesToLoad);
    }

    async load() {
        const response = await AtlasAuth.Get({
            url: "api/v3/user",
            timeout: 5000
        });
        this.loadJSON(response.output);
    }
    
    hasDataStorageForDocument(document) {
        if (Ember.get(this.permissions, "hasUnlimitedDataStorage"))
            return true;
        const dataLimit = this.get("permissions.customDatasetStorage") || 0;
        const dataUsage = this.get("usage.customDatasets.totalDataSize") || 0;
        const dataAvailable = dataLimit - dataUsage;
        const fileDataUsage = document.customDatasetSize;
        return fileDataUsage < dataAvailable;
    }

    hasPostStorageForDocument(document) {
        if (Ember.get(this.permissions, "hasUnlimitedPostStorage"))
            return true;
        const postLimit = this.get("permissions.customDatasetMaxDocuments") || 0;
        const postUsage = this.get("usage.customDatasets.totalPostCount") || 0;
        const postsAvailable = postLimit - postUsage;
        const filePostUsage = document.customDatasetPosts;
        return filePostUsage < postsAvailable;
    }
}
