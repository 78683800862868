import Route from "@ember/routing/route";

export default class CustomDatasetsRoute extends Route {
    title = "Creating Custom Dataset";

    activate() {
        this._super();
        window.scrollTo(0, 0);
    }
}
