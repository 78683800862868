import Ember from "ember";
import Component from '@ember/component';
import { computed } from '@ember/object';
import FieldSelectorBase from "infegy-frontend/components/field-selector/parts/fields/trendable-field-base/component";
import layout from "infegy-frontend/components/field-selector/parts/fields/trendable-selector-layout/template";

export default class FieldSelectorPosts extends FieldSelectorBase {
    layout = layout;
    apiName = "volume";

    @computed("fieldType", "selectedSegment", "selectedSubsegment", "isLoaded")
    get allAvailableFields() {
        return {
            "Universe" : [this.makeFieldDefinition({
                fieldName: "postsNormalizedUniverse",
                title: "Universe",
                color: this.get("query.queryInfo.color"),
                axisGroup: "postsNormalizedUniverse"
            })],
            "WordNormalized" : [this.makeFieldDefinition({
                fieldName: "postsWordNormalizedComputed",
                title: "Normalized",
                isRangelessPercentage: true,
                color: this.get("query.queryInfo.color"),
                axisGroup: "postsWordNormalizedComputed"
            })],
            "PostsPerBillion" : [this.makeFieldDefinition({
                fieldName: "postsPerBillion",
                title: "Per Billion",
                color: this.get("query.queryInfo.color"),
                axisGroup: "postsPerBillion"
            })],
            "Count" : [this.makeFieldDefinition({
                fieldName: "postsCount",
                title: "Posts",
                color: this.get("query.queryInfo.color"),
                axisGroup: "postsCount"
            })],
            "UniqueAuthors" : [this.makeFieldDefinition({
                fieldName: "metaSourcesEstimate",
                title: "Unique Authors",
                color: this.get("query.queryInfo.color"),
                axisGroup: "metaSourcesEstimate",
                isMeta: true
            })],
            "UniverseUniqueAuthors" : [this.makeFieldDefinition({
                fieldName: "metaSourcesUniverse",
                title: "Universe Unique Authors",
                color: this.get("query.queryInfo.color"),
                axisGroup: "metaSourcesUniverse",
                isMeta: true
            })],
        };
    };

};
