import Ember from "ember";
import CountriesJSON from "infegy-frontend/static_data/geography/countries_json";

var Countries = {
    CountriesJSON: CountriesJSON,

    ContinentCodes: Ember.Object.create({
        'AF': 'Africa',
        'AS': 'Asia',
        'EU': 'Europe',
        'NA': 'North America',
        'SA': 'South America',
        'OC': 'Oceania',
        'AN': 'Antarctica'
    }),

// Removed:
// ["NA", "AN", "ANT", 530, "Netherlands Antilles"],
// ["OC", "XX", "XX_null", null, "Disputed Territory"],
// ["AS", "XE", "XE_null", null, "Iraq-Saudi Arabia Neutral Zone"],
// ["AS", "XD", "XD_null", null, "United Nations Neutral Zone"],
// ["AS", "XS", "XS_null", null, "Spratly Islands"],

    Continents: CountriesJSON.map(function(row) {
        // [Continent, CountryCode2, CountryCode3, ISO-Numeric, Name];
        return [
            row.region,
            row.code,
            null,
            null,
            (row.title || row.label)
        ];
    }),

// TODO: Clean up
    CountriesByContinent: Ember.Object.create(),
    ContinentByCountries: Ember.Object.create(),

    ContinentGetter: {},

    Uncategorized: { "title": "Uncategorized", "code": "00", "alternates": ["Uncategorized"], "optgroup": "Countries" }
};

Countries.ContinentGetter = Ember.Object.create({
    countriesByContinent: Countries.CountriesByContinent,
    continentByCountries: Countries.ContinentByCountries,
    continentCodes: Countries.ContinentCodes,

    getContinent: function(code) {
        var continents = this.continentByCountries;
        if (!Ember.isArray(code) || !continents.get(code)) {
            return '';
        }
        return continents.get(code);
    },

    getCountries: function(code) {
        var countries = this.countriesByContinent;
        if (!Ember.isArray(code) || !countries.get(code)) {
            return [];
        }
        return countries.get(code);
    },

    continentNameByCode: function(code) {
        var codeMap = this.continentCodes;
        if (!Ember.isArray(code) || !codeMap.get(code)) {
            return '';
        }
        return codeMap.get(code);
    },
});

Countries.Continents.forEach(function(item) {
    var continent = item[0],
        country = item[1],
        fixture = Countries.CountriesByContinent;

    if (!fixture.get(continent)) {
        fixture.set(continent, []);
    }
    fixture.get(continent).push(country);
});

Countries.Continents.forEach(function(item) {
    var continent = item[0],
        country = item[1],
        fixture = Countries.ContinentByCountries;

    fixture.set(country, continent);
});

var continents = [{ "title": "North America", "abbreviation": "NA", "code": [], "alternates": [], hideDescription: true, "optgroup": "Continents" },
    { "title": "South America", "abbreviation": "SA", "code": [], "alternates": [], hideDescription: true, "optgroup": "Continents" },
    { "title": "Europe", "abbreviation": "EU", "code": [], "alternates": [], hideDescription: true, "optgroup": "Continents" },
    { "title": "Africa", "abbreviation": "AF", "code": [], "alternates": [], hideDescription: true, "optgroup": "Continents" },
    { "title": "Asia", "abbreviation": "AS", "code": [], "alternates": [], hideDescription: true, "optgroup": "Continents" },
    { "title": "Oceania", "abbreviation": "OC", "code": [], "alternates": [], hideDescription: true, "optgroup": "Continents" },
    { "title": "Antarctica", "abbreviation": "AN", "code": [], "alternates": [], hideDescription: true, "optgroup": "Continents" }
];

for (var j = 0; j < Countries.CountriesJSON.length; j++) {
    var regionAbbreviation = Countries.CountriesJSON[j]['region'];
    var regionObj = continents.findBy('abbreviation', regionAbbreviation);
    regionObj.code.push(Countries.CountriesJSON[j]['code']);
    regionObj.alternates.push(Countries.CountriesJSON[j]['title']);
}

Countries.CountriesJSON.forEach(country => {
    country.optgroup = "Countries";
});

for (var k = 0; k < continents.length; k++) {
    Countries.CountriesJSON.unshift(continents[k]);
}


export default Countries;
