import Ember from "ember";
import { computed } from "@ember/object";

import JsonStore from "infegy-frontend/json-store/model";
import Prop from "infegy-frontend/json-store/properties/model";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import DictionaryEntry from "infegy-frontend/models/users/dictionary_entry";
import classic from "ember-classic-decorator";

@classic
export default class Dictionary extends JsonStore {
    @Prop.Int() id;
    @Prop.String() userId;
    @Prop.Boolean() locked;
    @Prop.String() name;
    @Prop.String() modified;
    @Prop.Boolean() isShared;

    // entries = [];
    @Prop.Collection(DictionaryEntry) entries;

    @computed.alias("id") value;
    @computed.alias("name") label;

    @Ember.computed("modified")
    get modifiedDate() {
        return new Date(this.modified);
    }

    loadEntries() {
        if (!this.id) {
            console.warn("Attempted to load entries for dictionary without ID");
        } else {
            return AtlasAuth.ImmediateAjax({
                url: 'api/v3/dictionaries/entries.json',
                type: "GET",
                data: {
                    dictionary_id: this.id
                }
            }).then((data) => {
                // If the dictionary has already loaded entries, clean out the old ones and load the new ones
                this.entries.clear();
                this.entries.loadJSON(data.output);
            }).catch((error) => {
                console.warn('Failed to get dictionary entries.');
                if (error.status === 403) {
                    window.location = "/login#previousLocation=" + window.location.pathname;
                }
            });
        }
    }

    async createEntry(entryData) {
        let data = Object.assign({dictionary_id: this.id}, entryData);
        let entry = DictionaryEntry.create();
        entry.loadJSON(data);
        try {
            await entry.create();
        }
        catch(error) {
            console.warn(`Failed to create entry: ${error}`);
        }

        this.entries.addObject(entry);
    }

    editEntries(entries) {
        if (!Ember.isArray(entries)) {
            entries = [entries];
        }

        entries.forEach((item) => {
            AtlasAuth.Post({
                url: 'api/v3/dictionaries/entries.json',
                data: Ember.assign({
                    dictionary_id: this.id,
                    lang: item.language
                }, item)
            }).then(() => {
                this.loadEntries();
            }).catch((error) => {
                if (error.status === 403) {
                    window.location = "/login#previousLocation=" + window.location.pathname;
                }
                console.warn('Failed to edit entry');
            });
        });
    }

    async deleteEntries(entries) {
        if (!Ember.isArray(entries)) {
            entries = [entries];
        }

        entries.forEach(async (entry) => {
            try {
                await entry.delete();
            }
            catch(error) {
                console.warn(`${error.atlasErrorText} ${entry.word}`);
                return;
            }

            this.entries.removeObject(entry);
        });
    }

    async save(name) {
        return AtlasAuth.Post({
            url: 'api/v3/dictionaries.json',
            data: { name }
        }).then((response) => {
            this.loadJSON(response.output[0]);
        });
    }

    delete() {
        return AtlasAuth.Delete({
            url: `api/v3/dictionaries.json?dictionary_id=${this.id}`,
        }).catch((error) => {
            if(error.status === 403){
                this.transitionToRoute('login');
            }
        });
    }

    saveCopy(newName) {
        let name = newName;
        if (Ember.isEmpty(newName)) {
            name = `${this.name} (copy)`;
        }
        return new Promise(async (resolve, reject) => {
            let response = await AtlasAuth.Post({
                url: "api/v3/dictionaries.json",
                data: {
                    name,
                    source_id: this.id
                }
            });

            const newDictionary = Dictionary.create();
            newDictionary.loadJSON(response.output[0]);
            resolve(newDictionary);
        });
    }

    update(name) {
        return AtlasAuth.Put({
            url: 'api/v3/dictionaries.json',
            data: {
                dictionary_id: this.id,
                name: name
            }
        }).then(()=>{
            this.loadJSON({ name });
        }).catch((error) => {
            console.warn('Failed to edit dictionary');
            if (error.status === 403) {
                window.location = "/login#previousLocation=" + window.location.pathname;
            }
        });
    }
}