import Ember from "ember";
import Languages from "infegy-frontend/static_data/query/query_filters/languages";

export function languageName(params, namedArgs) {
    var code = namedArgs.language || params[0],
        language = Languages.languageLabels[code];
    
    if (Ember.isEmpty(language)) {
        return "Unknown";
    }
    
    return language;
  }
  
  export default Ember.Helper.helper(languageName);