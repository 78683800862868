import Ember from "ember";
import Component from '@ember/component';
import {computed, action} from "@ember/object";
import EntityDrilldownBase from "infegy-frontend/components/entities/entity-drilldown-base/component";

export default class extends EntityDrilldownBase{
    isColorKeyCategorical = false;
    colorKey = "positiveAppearancesDistribution";
    entity = null;

    @computed.alias("entity.positiveAppearancesDistribution") sentiment;
    @computed.alias("entity.sampleSentences.length") numSentences;

    @computed.gte('numSentences', 3) hasThreeOrMoreSentences;
    @computed.gte('numSentences', 2) hasTwoOrMoreSentences;

    @computed("sentimentColorStyle")
    get sentimentColorStyleSafe() {
        return new Ember.String.htmlSafe(this.sentimentColorStyle || "");
    }

    @computed("matchedDrilldownQuery")
    get matchedPosts() {
        var matchedQueries = this.matchedDrilldownQuery || [];
        return matchedQueries.reduce((allPosts, query) => {
            var queryPosts = query && query.get("posts.data");

            if (!queryPosts || !query || !Ember.get(queryPosts, "length")) {
                return allPosts;
            }
            queryPosts.forEach(post => {
                Ember.set(post, "query", query);
            });
            return allPosts.concat(queryPosts);
        }, []);
    }
}
