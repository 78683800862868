import Ember from "ember";
import StatesAPI from "infegy-frontend/models/query-api/states";
import CountriesAPI from "infegy-frontend/models/query-api/countries";
import BrowserStore from "infegy-frontend/utils/browser-store";

var computedSortField = function(_fieldName) {
    var fieldName = _fieldName;
    return Ember.computed("sortAscending", "sortField", function() {
        if (this.sortField === fieldName) {
            return this.sortAscending ? "sorted-asc" : "sorted-desc";
        }
    });
};

export default Ember.Component.extend({
    classNames: ["table-overflow-scroll"],
    data: null,
    activePage: null,
    activeGrouping: null,
    hasQueryField: false,

    selectedField: null,
    availableFields: [],

    sortAscending: false,
    sortField: "universe",

    expandable: true,

    isPaginated: true,
    areasPerPage: 20,
    currentPage: BrowserStore.Attr("geo-data-table-current-page", { default: 0 }),

    hasPages: Ember.computed("isPaginated", "sortedAreas.length", "areasPerPage", function () {
        return this.isPaginated && this.get("sortedAreas.length") > this.areasPerPage;
    }),

    selectedGeo: null,
    selectedFilterdownGeo: null,

    cleanedData: Ember.computed("data", "availableFields", function() {
        var data = this.data,
            availableFields = this.availableFields;

        return data.filter(function(item) {
            return !!Ember.get(item,"name");
        }).map((original_item) => {
            var item = Object.assign({},original_item);
            availableFields.forEach((field)=>{
                if(item[field.value] && !Ember.isNone(item[field.value].sum)){
                    item[field.value] = item[field.value].sum;
                }
            });

            // If multiple queries & in overview tab
            if (item._sourceGeos.length > 1) {
                const multiPositivity = item._sourceGeos.map(sourceItem => {
                    const res = sourceItem.positiveMatches / sourceItem.matches;
                    return isNaN(res) ? 0 : res;
                });
                const multiReduced = multiPositivity.reduce((previous, current) => {
                    return previous + current;
                });
                item.positivity = (multiReduced / multiPositivity.length);
            }

            return item;
        });
    }),

    sortedAreas: Ember.computed("cleanedData", "sortAscending", "sortField", function() {
        var areas = this.cleanedData || [],
            asc = this.sortAscending,
            sortField = this.sortField;

        // If the data we paginate changes, we need to reset the page
        this.set("currentPage",0);
        return areas.sort((geoA, geoB) => {
            var a = asc ? Ember.get(geoA, sortField) : Ember.get(geoB, sortField),
                b = asc ? Ember.get(geoB, sortField) : Ember.get(geoA, sortField);

            if (typeof(a) === "string") {
                return a.localeCompare(b);
            }

            return a - b;
        });
    }),

    paginatedAreas: Ember.computed("sortedAreas", "currentPage", "areasPerPage", "hasPages", function () {
        var sortedAreas = this.sortedAreas || [],
            currentPage = this.currentPage || 0,
            areasPerPage = this.areasPerPage,
            currentIndex = currentPage * areasPerPage,
            paginatedAreas = sortedAreas.slice(currentIndex, currentIndex + areasPerPage);

        if (!this.hasPages) {
            return sortedAreas;
        }

        // Edge Case: Given limited results,
        // sessionStorage with stored page breaks the paginator.
        if (!paginatedAreas.length) {
            currentIndex = 0;
        }

        return sortedAreas.slice(currentIndex, currentIndex + areasPerPage);
    }),

    drilldownQueries: Ember.computed("selectedGeo", "queries", "queries.@each.updated", function() {
        return (this.queries || []).map((oldQuery) => {
            var newQuery = oldQuery.copy();
            newQuery.queryInfo.addFilter({
                id: this.activeMap.filterId,
                values: [this.selectedGeo?.id]
            });
            return newQuery;
        });
    }),

    highestScore: Ember.computed("sortedAreas", function() {
        let highestScore = 0;
        const scores = this.sortedAreas.map(area => {
            return area.score;
        })

        // So that the prepopulated valued of highestScore doesn't equal -Infinity
        highestScore = scores.length > 0 ? Math.max(...scores) : 0;
        return highestScore;
    }),

    titleSort: computedSortField("name"),
    querySort: computedSortField("_originalQuery.title"),
    populationSort: computedSortField("population"),
    scoreSort: computedSortField("score"),
    matchesSort: computedSortField("matches"),
    universeSort: computedSortField("universe"),
    sourcesSort: computedSortField("sources"),
    positivitySort: computedSortField("positivity"),
    timelineSort: computedSortField("documentsGrowthPct"),

    activeFieldChanged: () => {},
    actions: {
        geoSelected: function(geo) {
            if (this.selectedGeo !== geo) {
                this.set("selectedGeo", geo);
            } else {
                this.set("selectedGeo", null);
            }

            this.set("selectedFilterdownGeo", null);
        },
        sortBy: function(newSortField) {
            var sortField = this.sortField;

            if (newSortField === sortField) {
                this.toggleProperty("sortAscending");
            } else {
                this.setProperties({
                    sortField: newSortField,
                    sortAscending: newSortField === "name"
                });
            }

            if (newSortField !== "name" && newSortField !== "_originalQuery.title") {
                this.activeFieldChanged(newSortField);
            }
        }
    }
});
