import { A } from "@ember/array";
import ArrayProxy from "@ember/array/proxy";

import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import QualtricsSurvey from "infegy-frontend/models/qualtrics/survey";
import classic from "ember-classic-decorator";
import * as Sentry from "@sentry/browser";

@classic
export default class QualtricsSurveys extends ArrayProxy {
    /** @type QualtricsSurvey[] */
    content = A();
    isLoading = false;
    hasLoaded = false;

    async loadSurveyInfos(qualtricsToken) {
        let response = null;
        try {
            this.set("isLoading", true);
            response = await AtlasAuth.Get(`api/v3/qualtrics-survey/list?qualtrics_token=${qualtricsToken}`)
        }
        catch(error) {
            this.setProperties({
                isLoading: false,
                hasLoaded: true
            });
            Sentry.captureMessage("Could not get Qualtrics Survey Infos.");
            return;
        }

        let surveys = response.output.map((json) => {
            let survey = QualtricsSurvey.create();
            survey.loadJSON(json);
            return survey;
        });

        this.set("content", A(surveys));
        this.setProperties({
            isLoading: false,
            hasLoaded: true
        });
    }
}