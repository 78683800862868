import Ember from 'ember';

export default {
    arraysMatch: function(arrayA, arrayB) {
        if (!Ember.isArray(arrayA) || !Ember.isArray(arrayB)) {
            return false;
        } else if (Ember.isEmpty(arrayA) && Ember.isEmpty(arrayB)) {
            return true;
        } else if (Ember.get(arrayA, "length") === Ember.get(arrayB, "length")) {
            return !arrayA.find(rowA => {return !arrayB.includes(rowA);});
        }
        return false;
    },
    shuffle(array) {
        // Copy the original list of posts
        let shuffledArray = Array.from(array);
        // Fisher-Yates Shuffle
        // https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
        // https://bost.ocks.org/mike/shuffle/
        let currentIndex = shuffledArray.length;
        let temp;
        let randomIndex;
        while (currentIndex != 0) {
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex--);
            // And swap it with the current element.
            temp = shuffledArray[currentIndex];
            shuffledArray[currentIndex] = shuffledArray[randomIndex];
            shuffledArray[randomIndex] = temp;
        }
        return shuffledArray;
    }
};
