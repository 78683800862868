import Controller, { inject as controller } from "@ember/controller";
import { computed } from "@ember/object";

export default class extends Controller {
    @controller application;
    @computed.readOnly("application.user") user;

    document = null;
    customDataset = null;
}
