import Ember from "ember";
import DataSeries from "infegy-frontend/models/data_series/data_series";
import ControllerBase from "infegy-frontend/atlas/dashboard/content/base/controller";

export default ControllerBase.extend({
    apis: ['influencers', 'influenceDistribution'],

    hasMoreThanOneQuery: Ember.computed.gt("queries.length", 1),

    collatedInfluencers: Ember.computed("influencersQueries", "influencersQueries.@each.updated", function () {
        const queries = this.influencersQueries || [];
        const dataSeriesDescription = this.get("influencersQueries.firstObject.influencers.dataSeries.description");
        const influencers = queries.map((query, index) => {
            const influencersDataSeries = Ember.get(query, "influencers.dataSeries.data") || [];
            return influencersDataSeries.map(influencer => {
                return Object.assign({}, influencer, {
                    _originalQuery: this.queries[index],
                    _originalQueryIdx: index
                });
            });
        });

        const allInfluencers = [].concat(... influencers);
        return DataSeries.load(allInfluencers, dataSeriesDescription, true).groupBy("id", {sortBy: this.sortField});
    }),

    showSettings: false,

    defaultSortField: "score",
    sortField: "score",

    columnOptions: [
        { label: "Comments", value: "comments" },
        { label: "Followers", value: "followers" },
        { label: "Following", value: "following" },
        { label: "Influence", value: "influence" },
        { label: "Likes", value: "likes" },
        { label: "Matching Posts", value: "matches" },
        { label: "Negative Matches", value: "negative_matches" },
        { label: "Positive Matches", value: "positive_matches" },
        { label: "Score", value: "score" }, // API default
        { label: "Reach", value: "reach" },
        { label: "Shares", value: "shares" }
    ],

    // fixed columns (cannot be removed)
    columnsFixed: [
        "title",
        "query"
    ],

    // default selected columns
    columnsSelected: [
        "matches",
        "influence",
        "followers",
        "following",
        "score"
    ],

    columns: Ember.computed("columnsFixed", "columnsSelected", "columnsSelected.length", function() {
        const columns = this.columnsSelected.map((value) => {
            return this.columnOptions.find((column) => {
                return column.value === value;
            })
        });
        return columns;
    }),

    columnSortByOptions: Ember.computed("columns", "columns.length", function() {
        return this.columnOptions.filter((option) => {
            return this.columns.includes(option);
        }).sortBy("title");
    }),

    influencersQueries: Ember.computed("queries", "sortField", function() {
        let arr = [];
        this.queries.forEach((query) => {
            let copy = query.copy();
            copy.queryInfo.addAdditionalAPIParameters({"sort": this.sortField});
            arr.push(copy);
        });
        return arr;
    }),

    actions: {
        sortFieldChanged(value) {
            this.set("sortField", value);
        },
        toggleOptions() {
            this.toggleProperty("showSettings");
        },
        onSelectColumns(columnSelection) {
            if (!columnSelection.includes(this.sortField)) {
                // The prior selected `sortField` is no longer a displayed column.
                // A new `sortField` needs to be selected.
                if (columnSelection.length == 0) {
                    // If there are no columns, fall back to a default `sortField`
                    this.set("sortField", this.defaultSortField);
                }
                else {
                    // Otherwise, select the first column to get the user started.
                    this.set("sortField", columnSelection[0]);
                }
            }
            this.set("columnsSelected", columnSelection);
        }
    }
});
