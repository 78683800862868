import Component from "@ember/component";
import classic from "ember-classic-decorator";

import Workspace from "infegy-frontend/models/users/workspace";

@classic
export default class extends Component {
    classNameBindings = [":workspaces-list-workspace-select-queries"];

    // Input
    workspace = Workspace.create();

    /**
     * @event cancelQuerySelection Fired when canceling query selection.
     */
    /**
     * @event queriesSelected Fired after selecting queries.
     * @param {Query[]} queries The selected 
     */
}
