import Ember from "ember";

export default Ember.Component.extend({
    tagName: "tr",
    /*
    {
        name: storageType,
        limit: storageLimit,
        usage: storageUsage,
        available: storageAvailable,
        fileUsage
    }
    */
    storageInfo: null,

    isDataType: Ember.computed.equal("storageInfo.name", "Data"),

    showLimitAsData: Ember.computed("storageInfo.limit", function() {
        const limit = this.get("storageInfo.limit");
        const isDataType = this.isDataType;
        return isDataType && limit != Infinity;
    }),

    limitDisplayValue: Ember.computed("", function() {
        const limit = this.get("storageInfo.limit");
        return limit == Infinity ? "Unlimited" : limit;
    }),

    showAvailableAsData: Ember.computed("storageInfo.available", function() {
        const available = this.get("storageInfo.available");
        const isDataType = this.isDataType;
        return isDataType && available != Infinity;
    }),

    availableDisplayValue: Ember.computed("", function() {
        const available = this.get("storageInfo.available");
        return available == Infinity ? "Unlimited" : available;
    }),

    // limit: data, data && unlimited, other

    hasFileUsageError: Ember.computed("", function() {
        const available = this.get("storageInfo.available");
        const fileUsage = this.get("storageInfo.fileUsage");
        return fileUsage > available;
    })
});
