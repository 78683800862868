import Ember from "ember";
import ControllerBase from "infegy-frontend/atlas/dashboard/content/base/controller";
import BrowserStore from "infegy-frontend/utils/browser-store";
import Actions from "infegy-frontend/utils/common-actions";

export default ControllerBase.extend({
    application: Ember.inject.controller(),

    sentimentField: "documents",
    selectedEmotion: null,
    includeNeutral: false,

    exportApis: ["sentiment", "emotions", "positiveKeywords", "negativeKeywords"],
    keywordsApis: ["positiveKeywords", "negativeKeywords"],

    singleSentimentViewType: BrowserStore.Attr("emotions-active-view-type", {defaultValue: "stream"}),
    multiSentimentViewType: BrowserStore.Attr("emotions-active-view-type", {defaultValue: "table"}),
    emotionsViewType: BrowserStore.Attr("emotions-active-view-type", {defaultValue: "arcs"}),
    combineEmotions: BrowserStore.Bool("emotions-active-combined", {defaultValue: true}),

    sentimentFieldOptions: [{
            label: "Posts",
            value: "documents"
        },{
            label: "Universe",
            value: "documentsUniverse"
        },{
            label: "Subject Sentences",
            value: "subjectSentences"
        },{
            label: "Sentences",
            value: "sentences"
        },],

    emotionsField: "universe",
    emotionsFieldOptions: [{label: "Posts", value: "documents"}, {label: "Universe", value: "universe"}],

    isCombinedView: Ember.computed("sentimentViewType", "queries.length", function() {
        return this.get("queries.length") > 1 && this.get("sentimentViewType") !== "table";
    }),

    sentimentViewType: Ember.computed("singleSentimentViewType", "multiSentimentViewType", "queries.length", function () {
        if (this.queries.length > 1 && this.emotionsViewType === 'stream') {
            this.set("emotionsViewType", 'arcs');
            this.set("selectedEmotion", null);
        }
        return this.queries.length > 1 ? this.multiSentimentViewType : this.singleSentimentViewType;
    }),

    actions: {
        emotionsViewTypeChanged: function(emotionsViewType) {
            this.set("emotionsViewType", emotionsViewType);
            this.set("selectedEmotion", null);
        },
        sentimentFieldChanged: Actions.setProperty("sentimentField"),
        emotionSelected: Actions.setOrToggleProperty("selectedEmotion"),
        sentimentViewTypeChanged: function(viewType) {
            if (this.queries.length > 1) {
                this.set("multiSentimentViewType", viewType || "chart");
            } else {
                this.set("singleSentimentViewType", viewType || "stream");
            }
        },
        combineEmotionsChanged: Actions.setProperty("combineEmotions"),
        toggleNeutral: function() {
            this.toggleProperty("includeNeutral");
        },
       emotionsFieldChanged: function(emotionField) {
            this.set('emotionsField', emotionField);
        }
    }
});
