import Ember from "ember";
import Component from '@ember/component';
import D3SVGContentArea from "infegy-frontend/components/charting/d3-base/d3-svg-content-area/component";
import {computed, action} from "@ember/object";
import { isNone } from "@ember/utils";

export default class SVGStretchableWrapper extends Component {
    classNameBindings = ["defaultClassNameBindings", "hideOverflow:overflow-hidden"];
    attributeBindings = ["wrapperStyle:style"];

    width = null;
    height = null;

    preserveAspectRatio = null;
    aspectRatio = 1;

    hideOverflow =false;

    _innerContentArea = null;

    windowInnerWidth = 0;
    elementWidth = 1;
    svgEl = null;

    isCanvasChart = false;
    @computed("isCanvasChart")
    get defaultClassNameBindings() {
        return this.isCanvasChart ? "" : "svg-wrapper-component";
    }

    onElementCreated() {/* action */}
    onResize() {/* action */}

    get _preserveAspectRatio() {
        if (!isNone(this.preserveAspectRatio)) {
            return this.preserveAspectRatio;
        } else {
            return "xMidYMid";
        }
    }

    _viewBox = null;
    @computed("trueWidth", "trueHeight", "_viewBox")
    get viewBox() {
        if(this._viewBox)
            return this._viewBox
        var width  = this.trueWidth,
            height = this.trueHeight;
        return `0 0 ${width} ${height}`;
    }
    set viewBox(customViewBox) {
        this.set("_viewBox", customViewBox);
        return customViewBox
    }

    @computed("width", "elementWidth")
    get trueWidth() {
        var elementWidth = this.elementWidth,
            width = this.width;

        if (Ember.isEmpty(width) && elementWidth) {
            width = elementWidth - 1;
        }
        return width > 0 ? width : 1;
    }

    @computed("trueWidth", "height", "preserveAspectRatio", "aspectRatio")
    get trueHeight() {
        var trueHeight = parseInt(this.height) || 0;

        if (this.isCanvasWidget && this.preserveAspectRatio === "none") {
            return this.height;
        }

        if (this.preserveAspectRatio) {
            var trueWidth = parseInt(this.trueWidth) || 0;
            trueHeight = trueWidth / (parseInt(this.aspectRatio) || 0);
        }
        return trueHeight > 0 ? trueHeight : 1;
    }

    @computed("trueHeight")
    get wrapperStyle() {
        return `height:${this.trueHeight || 1}px;`.htmlSafe();
    }

    @computed("_innerContentArea", "trueWidth", "trueHeight")
    get contentArea() {
        var contentArea = this._innerContentArea,
            height = this.trueHeight,
            width = this.trueWidth;

        if (contentArea) {
            contentArea.setProperties({
            "width": width,
            "height": height
            });
        }
        return contentArea;
    }

    // Resizing magic - maybe make as a mixin?
    resizeFunction = null;
    stopResizer() {
        var resizeFunction = this.resizeFunction;
        if (resizeFunction) {
            this.set("resizeFunction", false);
            window.removeEventListener("resize", resizeFunction);
        }
    }

    willDestroyElement() {
        this.stopResizer();
    }

    didRender() {
        Ember.run.schedule('afterRender', this, function() {
            var el = this.element;
            Ember.run.once(this, function() {
                if (el && (this.elementWidth !== el.offsetWidth)) {
                    this.set("elementWidth", el.offsetWidth);
                    this.onResize(this.svgEl, this.contentArea);
                }
            });
        });
    }

    init() {
        super.init(...arguments);

        this.set("_innerContentArea", D3SVGContentArea.create());

        Ember.run.schedule('afterRender', this, function() {
            this.stopResizer();
            var el = this.element,
                timer_id;
            var resizeFunction = () => {
                clearTimeout(timer_id);
                timer_id = setTimeout(() => {
                    if (!this.isDestroyed && this.elementWidth !== el.offsetWidth) {
                        Ember.run.once(this, function() {
                            this.set("elementWidth", el.offsetWidth);
                        });
                        Ember.run.throttle(this.onResize, el, this.contentArea, 300);
                    }
                }, 200);
            };
            this.set("resizeFunction", resizeFunction);
            window.addEventListener("resize", resizeFunction);
            resizeFunction();
        });
    }

    @action
    svgElementCreated(el) {
        this.set("svgEl", el);
        this.onElementCreated(el, this.contentArea);
    }
}
