import Ember from "ember";
import {computed, action} from "@ember/object";
import TrendsFieldInformation from "infegy-frontend/components/charting/trend-chart/trends-information/trends-field-information/component";
import classic from "ember-classic-decorator";

@classic
export default class extends TrendsFieldInformation {
    tagName = "h4";
    classNames = ["trends-field-information-inline-field"];

    @computed("statsType", "primaryValue")
    get hideInformation() {
        return this.primaryValueType === "Total" &&
            this.statsType === "portion" && this.primaryValue >= 0.999999;
    }
}
