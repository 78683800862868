import Ember from "ember";
import { action, computed } from "@ember/object";

export default class TrendsChartTooltip extends Ember.Component {
    hoveredPct = null;
    interactedEventData = null;

    @Ember.computed("sentimentEvent", "volumeEvent", function() {
        return !!(this.sentimentEvent || this.volumeEvent);
    }) hasEvents;

    @Ember.computed.alias("interactedEventData.events") events;

    @Ember.computed.filterBy("events", "attributeType", "sentiment") sentimentEvents;
    @Ember.computed.alias("sentimentEvents.firstObject") sentimentEvent;

    @Ember.computed.filterBy("events", "attributeType", "volume") volumeEvents;
    @Ember.computed.alias("volumeEvents.firstObject") volumeEvent;
};