import Component from "@ember/component";
import { action, computed, get } from '@ember/object';
import { isEmpty } from "@ember/utils";

export default class ListFilterSort extends Component {
    list = [];
    minimumItemCount = 10;

    searchDisplayText = "Search For";

    searchInputText = "";
    filterField = "all";
    sortField = "title";
    sortDirection = "DESC";

    /*
    filterFieldOptions supports an "all" value which will default to matching 
    against any filter field in this list.  This will behave as a logical OR, 
    meaning the list item will be included if any of the fields match the 
    searchInputText.
    */
    filterFieldOptions = [];

    sortFieldOptions = [];
    
    sortDirectionOptions = [
        {
            label: "Asc.",
            value: "ASC"
        },
        {
            label: "Desc.",
            value: "DESC"
        }
    ];

    filter(list) {
        if (isEmpty(list)
            || isEmpty(this.filterField)
            || isEmpty(this.searchInputText)) {
            return list;
        }

        // If there is an "all" option, remove it from comparison checks
        let filterFieldOptions = this.filterFieldOptions.filter((option) => {
            return option.value != "all";
        });
        let filterFieldValues = filterFieldOptions.map((option) => option.value);

        let results = [];
        if (this.filterField == "all") {
            results = this.list.filter((item) => {
                // check to see if the current item contains any of the filter field options
                const fieldValues = filterFieldValues.reduce((accumulator, currentValue, currentIndex, arr) => {
                    const itemValue = get(item, currentValue);
                    if (!isEmpty(itemValue)) {
                        const isMatch = itemValue.toString().toLowerCase().includes(this.searchInputText.toLowerCase());
                        if (isMatch) {
                            accumulator.push(itemValue);
                        }
                    }
                    return accumulator;
                }, []);

                // if the item contains at least 1 match, it should be kept
                return fieldValues.length > 0;
            });
        }
        else {
            results = this.list.filter((item) => {
                const itemValue = get(item, this.filterField);
                if (!isEmpty(itemValue)) {
                    const isMatch = itemValue.toString().toLowerCase().includes(this.searchInputText.toLowerCase());
                    return isMatch;
                }
            });
        }

        return results;
    }

    sort(list) {
        const sortDirectionOptions = this.sortDirectionOptions.map((option) => {
            return option.value;
        });

        // if the collection is empty or there is no valid sort field or sort direction, do nothing
        if (isEmpty(list) 
            || isEmpty(this.sortField)
            || !sortDirectionOptions.includes(this.sortDirection)) {
            return list;
        }

        let results = list.sortBy(this.sortField);
        if (this.sortDirection == "DESC") {
            results = results.reverse();
        }

        return results;
    }

    @computed("list", "list.length", "minimumItemCount")
    get showListFilter() {
        const isVisible = this.list && this.list.length >= this.minimumItemCount;
        return isVisible;
    }

    @computed("list", "list.length", "searchInputText", "filterField", "sortField", "sortDirection")
    get results () {
        let list = this.list ?? [];
        list = this.filter(list);
        list = this.sort(list);
        return list;
    }

    @action
    filterFieldChanged(filterField) {
        this.set("filterField", filterField);
    }

    @action
    sortDirectionChanged(sortDirection) {
        this.set("sortDirection", sortDirection);
    }

    @action
    sortFieldChanged(sortField) {
        this.set("sortField", sortField);
    }
}