import Ember from 'ember';
import CSSTools from "infegy-frontend/utils/css-tools";

export default Ember.Component.extend({
    tagName: 'tr',
    classNames:["data-table-row"],
    dataColumn: null,

    rowColumns: Ember.computed("interestQueries", "queries", "dataColumn", function(){
        return this.queries.map((query,idx) => {
            return Object.assign({
                sourceQuery: query,
                noData: Ember.isNone(this.interestQueries[idx])
            }, this.interestQueries[idx])
        })
    }),
    
    // Finds the first entry to use for finding the tiers and other generic info
    interestData: Ember.computed("rowColumns", function(){
        return this.rowColumns.find(interest => interest.name);
    }),

    removeInterest: () => {},
    actions: {
        removeInterest(){
            this.removeInterest(this.get("interestData.name"));
        }
    }
});
