import Component from '@ember/component';
import TrendChartMeta from "infegy-frontend/models/trends/trend-chart-meta";
import QueryDataGrouping from "infegy-frontend/models/queries/query_data_grouping";
import {computed, action} from "@ember/object";
import classic from "ember-classic-decorator";

let existingQueries = {}; //key/value map of created queries to avoid duplicating api calls

@classic
export default class FullTrends extends Component {
    queries = null;
    fields = null;
    trendChartMeta = null;
    isMultiQuery = false;
    areCustomFields = false;
    chartType = "line";

    grouping = null;
    normalizeStartTimes = false;

    @computed("trendChartMeta", "grouping", "normalizeStartTimes")
    get innerTrendChartMeta() {
        var trendChartMeta = this.trendChartMeta;
        if (!trendChartMeta) {
            trendChartMeta = TrendChartMeta.create();
            trendChartMeta.normalizeStartTimes = this.normalizeStartTimes;
            trendChartMeta.grouping = this.grouping;
        }
        return trendChartMeta;
    }

    @computed("queries", "queries.[]", "fields", "fields.[]", "fields.@each.query")
    get fieldQueries() {
        let queries = this.fields && this.fields.mapBy("query").uniq();
        return queries || this.queries;
    }

    @computed("queries", "queries.[]", "grouping", "queries.@each.updated", "fieldQueries", "fieldQueries.[]", "fields", "fields.@each.query")
    get groupingInfo() {
        let dateRange = (this.fieldQueries || []).reduce((memo, query) => {
                let start = Math.min(memo.start, query.filters.startDate.timestamp || 0),
                    end = Math.max(memo.end, (query.filters.endDate.timestamp || 1) + (1000 * 60 * 60 * 24)),
                    range = end - start;

                memo.start = start;
                memo.end = end;
                memo.shortestRange = Math.min(range, memo.shortestRange);
                memo.longestRange = Math.max(range, memo.longestRange);

                return memo;
            }, {start: Infinity, end: -Infinity, shortestRange: Infinity, longestRange: -Infinity});
        let timespan = dateRange.shortestRange,
            groupingOptions = QueryDataGrouping.availableAPIGroupingOptions(timespan).map(opt => {
                return {
                    title: opt.title,
                    label: opt.title,
                    value: opt.value
                };
            }),
            availableGrouping = groupingOptions.mapBy("value");
        let grouping = undefined;
        if (this.fieldQueries) {
            grouping = this.grouping || QueryDataGrouping.findAPIGrouping(timespan);
        }
        
        if (!this.innerTrendChartMeta.grouping || (this.innerTrendChartMeta.grouping && !availableGrouping.includes(this.innerTrendChartMeta.grouping))) {
            this.innerTrendChartMeta.set("grouping", grouping);
        }
        this.innerTrendChartMeta.set("defaultGrouping", grouping);
        this.innerTrendChartMeta.set("groupingOptions", groupingOptions);

        return {
          grouping : grouping,
          groupingOptions: groupingOptions,
          availableGrouping: availableGrouping
        };
    }

    @computed("groupingInfo", "queries", "queries.[]", "fields", "queries.@each.updated",
        "fields.@each.isLoaded", "isCombined", "grouping",
        "innerTrendChartMeta.grouping", "groupingInfo")
    get innerFields() {
        let localGrouping = this.groupingInfo.grouping,
            grouping = this.innerTrendChartMeta.grouping || localGrouping;

        if (!localGrouping) {
            return this.fields;
        }

        let fields = (this.fields || []).map(field => {
            let collatedQuery = field.query.copy();
            collatedQuery.queryInfo.selectedGrouping = grouping;
            let apiString = collatedQuery.toAPIQueryString(),
                newFieldQuery = existingQueries[apiString];
            if (!newFieldQuery) {
                newFieldQuery = collatedQuery;
                existingQueries[apiString] = newFieldQuery;
            } else {
                // It is possible the user has updated the query and only changed the color 
                // using the in-app-query-editor.  If this is the case we want to make sure we 
                // are loading this new color to keep the chart colors in sync.
                newFieldQuery.queryInfo.color.loadJSON(field.query.queryInfo.color.toJSON());
            }
            let newField = field.copy(newFieldQuery);

            newField.stackingFieldOveride = "groupName";
            newField.xAxisField = "timestamp";

            return newField;
        });

        if (this.isCombined) {
            fields = [combineMultiQueryFields(fields)];
        }
        return fields;
    }

    chartTypeChanged() { /* empty for action */ }
    trendChartMetaUpdated() { /* empty for action */ }
    normalizeStartTimesChanged() { /* empty for action */ }
    groupingChanged() { /* empty for action */ }

    @action
    trendChartMetaUpdating(meta) {
        this.trendChartMetaUpdated(meta);
    }
};
