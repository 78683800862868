import Ember from "ember";
import Actions from "infegy-frontend/utils/common-actions";

export default Ember.Component.extend({
    classNames: ["icon-view-selector", "no-print"],
    emotionsViewType: "arcs",

    actions: {
        emotionsViewSelected: Actions.passUp("changed")
    }
});
