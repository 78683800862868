export const CAProvinces = [
  { title: "British Columbia", code: "BC" },
  { title: "Quebec", code: "QC" },
  { title: "Nunavut", code: "NU" },
  { title: "Prince Edward Island", code: "PE" },
  { title: "Saskatchewan", code: "SK" },
  { title: "Yukon", code: "YT" },
  { title: "Manitoba", code: "MB" },
  { title: "Ontario", code: "ON" },
  { title: "New Brunswick", code: "NB" },
  { title: "Northwest Territories", code: "NT" },
  { title: "Alberta", code: "AB" },
  { title: "Newfoundland and Labrador", code: "NL" },
  { title: "Nova Scotia", code: "NS" },
];
