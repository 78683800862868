import Ember from "ember";
import { action, computed } from "@ember/object";
import { tracked } from '@glimmer/tracking';

export default class TrendsEvents extends Ember.Component {
    // inputs
    events = null;
    interactionInfo = null;
    queryGroupBy = null;

    // members
    selectedEvent = null;

    // START
    @computed.or("interactionInfo.isSelected", "interactionInfo.isHovered") isSingleTimePeriod;

    @computed("events", "events.length")
    get eventData() {
        return this.events.map((item) => {
            if (item.attributeType == "hashtag") {
                const separatorIndex = item.attributeName.indexOf(" ");
                Ember.set(item, "displayName", item.attributeName.slice(0, separatorIndex));
            }
            if (item.attributeType == "share") {
                const separatorIndex = item.attributeName.indexOf(" ");
                Ember.set(item, "displayName", item.attributeName.slice(0, separatorIndex));
                Ember.set(item, "shareContent", item.attributeName.slice(separatorIndex + 1, item.attributeName.length));
            }
            return item;
        });
    }

    @computed.filterBy("eventData", "attributeType", "hashtag") hashtagEvents;

    @computed.sort("hashtagEvents", function(a,b) {
        // sort descending
        if (a.value > b.value) return -1;
        else if (a.value < b.value) return 1;
        else return 0;
    }) hashtagEventsByValue;

    @computed.uniqBy("hashtagEventsByValue", "attributeName") uniqueHashtagEvents;

    @computed.filterBy("eventData", "attributeType", "share") shareEvents;

    @computed.sort("shareEvents", function(a,b) {
        if (a.value > b.value) return -1;
        else if (a.value < b.value) return 1;
        else return 0;
    }) shareEventsByValue;

    @computed("selectedEvent", "selectedEvent.attributeType")
    get hasSelectedHashtag() {
        return this.selectedEvent && this.selectedEvent.attributeType === "hashtag";
    }

    sharePageIndex = 0;
    sharePageSize = 5;
    @computed("shareEventsByValue", "sharePageIndex")
    get displayedShareEvents() {
        let index = this.sharePageIndex * this.sharePageSize;

        return this.shareEventsByValue.slice(index, index + this.sharePageSize);
    }

    @computed("selectedEvent", "query")
    get selectedEventQueryString() {
        if (!this.selectedEvent) {
            return "";
        }

        const event = this.selectedEvent;
        let eventQuery = event.attributeName;

        // trim the share event to fit
        if (event.attributeType == "share") {
            let postSample = event.attributeName.split(" ");
            postSample = postSample.slice(0, Math.min(postSample.length, 5));
            // remove the author and only use the share content
            postSample.shift();
            eventQuery = '"' + postSample.join(" ") + '"';
        }
        return eventQuery;
    };

    makeAdditiveQuery(eventQuery, eventLabel) {
        let newQuery = this.query.copy();
        newQuery.addStringToQuery(eventQuery, eventLabel);
        return newQuery;
    };

    makeSubtractiveQuery(eventQuery, eventLabel) {
        let newQuery = this.query.copy();
        newQuery.removeStringFromQuery(eventQuery, eventLabel);
        return newQuery;
    };

    @computed("selectedEventQueryString")
    get drilldownQuery() {
        return this.makeAdditiveQuery(this.selectedEventQueryString);
    };

    // events
    addExclude(name) {};
    addInclude(name) {};
    eventClicked(event) {};

    // actions
    @action
    eventClickedAction(event, mouseEvent) {
        this.set("selectedEvent", this.selectedEvent === event ? null : event);
        this.eventClicked(this.selectedEvent);
    }

    @action
    includeEvent(event) {
        let query = this.makeAdditiveQuery(this.selectedEventQueryString);
        window.open("/atlas/dashboard/" + QueryURL.queryArrayToUrl([query]));
    }

    @action
    excludeEvent(event) {
        let query = this.makeSubtractiveQuery(this.selectedEventQueryString);
        window.open("/atlas/dashboard/" + QueryURL.queryArrayToUrl([query]));
    }

    @action
    includeAuthor(event) {
        let author = event.attributeName.split(": ")[0];
        let query = this.makeAdditiveQuery(author);
        window.open("/atlas/dashboard/" + QueryURL.queryArrayToUrl([query]));
    }

    @action
    excludeAuthor(event) {
        let author = event.attributeName.split(": ")[0];
        let query = this.makeSubtractiveQuery(author);
        window.open("/atlas/dashboard/" + QueryURL.queryArrayToUrl([query]));
    }
}
