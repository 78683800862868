import Ember from "ember";
import Component from "@ember/component";
import { action, computed } from "@ember/object";

export default class SubqueryTable extends Component {
    // inputs
    isReadOnly = false;
    pageSize = 10;
    subqueries = [];

    // parameters
    bulkDeleteErrorMessage = "";
    hideActions = false;
    hideSelection = true;
    selected = [];

    // searching/sorting
    searchText = "";
    searchBy = "tag";
    sortAscending = true;

    // UI state
    isBulkDeleting = false;
    isBulkDeleteCommitting = false;
    isCreating = false;

    @computed("isReadOnly", "subqueries", "subqueries.length")
    get showBulkDelete() {
        return !this.isReadOnly && this.subqueries.length > 1;
    }

    @computed("isBulkDeleting", "isCreating", "isReadOnly")
    get isDefaultState() {
        const value = (this.isBulkDeleting | this.isCreating) === 0 && this.isReadOnly == false;
        return value;
    }

    @computed.empty("selected") hasSelection;

    @computed("subqueries", "subqueries.length", "searchText", "searchBy", "sortAscending")
    get matchingSubqueries() {
        let filteredSubqueries = this.subqueries.filter((subquery) => {
            let value = subquery[this.searchBy].toLowerCase();
            return value.includes(this.searchText.toLowerCase());
        });

        filteredSubqueries = filteredSubqueries.sort((a, b) => {
            if (a[this.searchBy] > b[this.searchBy]) return 1
            else if (a[this.searchBy] < b[this.searchBy]) return -1
            else return 0;
        });

        if (this.sortAscending == false) {
            filteredSubqueries = filteredSubqueries.reverse();
        }

        return filteredSubqueries;
    }

    // events
    deleteQuerySetEntry = (subqueryId) => {};
    deleteSubqueries = (subqueryIds) => {};
    saveQuerySetEntry = (name, query) => {};
    updateQuerySetEntry = (subqueryId, name, query) => {};

    @action
    bulkDeleteBeginAction() {
        this.setProperties({
            bulkDeleteErrorMessage: "",
            hideActions: true,
            hideSelection: false,
            isBulkDeleting: true,
            isCreating: false
        });
    }
    @action
    bulkDeleteCancelAction() {
        this.selected.clear();
        this.setProperties({
            bulkDeleteErrorMessage: "",
            hideActions: false,
            hideSelection: true,
            isBulkDeleteCommitting: false,
            isBulkDeleting: false
        });
    }
    @action
    bulkDeleteCommitAction() {
        this.set("isBulkDeleteCommitting", true);
    }
    @action
    bulkDeleteConfirmAction() {
        return this.deleteSubqueries(this.selected).then((response) => {
            this.setProperties({
                bulkDeleteErrorMessage: "",
                hideActions: false,
                hideSelection: true,
                isBulkDeleteCommitting: false,
                isBulkDeleting: false
            });
        }).catch((response) => {
            this.set("bulkDeleteErrorMessage", response.atlasErrorText);
        });
    }

    @action
    clearSelectionAction() {
        this.selected.clear();
    }

    @action
    createBeginAction() {
        this.setProperties({
            isBulkDeleting: false,
            isCreating: true
        });
    }
    @action
    createCancelAction() {
        this.set("isCreating", false);
    }
    @action
    createSaveAction(name, query) {
        return this.saveQuerySetEntry(name, query).then((response) => {
            this.set("isCreating", false);
        });
    }

    @action
    deleteConfirmAction(subqueryId) {
        return this.deleteQuerySetEntry(subqueryId);
    }

    @action
    editSaveAction(subqueryId, newName, newQuery) {
        return this.updateQuerySetEntry(subqueryId, newName, newQuery);
    }

    @action
    selectAllAction() {
        if (this.pageSelection.length == 0 || this.pageSelection.length == this.pageSelectionModels.length) {
            // if everything is selected or nothing is selected, toggle the value
            this.pageSelectionModels.map((item) => {
                Ember.set(item, "isSelected", !item.isSelected);
            });
        }
        else {
            // else, toggle the remaining values to true
            this.pageSelectionModels.map((item) => {
                Ember.set(item, "isSelected", true);
            });
        }
    }
}
