import Ember from "ember";
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import FullFieldSelectorBase from "infegy-frontend/components/field-selector/selector-base/component";
import colorizeFields from "infegy-frontend/utils/fields-colorizer";

export default class TrendableFieldSelectorMulti extends FullFieldSelectorBase {
    fieldsChanged(selectedFields) { /* EVENT */ };

    @computed("firstSelectedField", "selectedSegment", "selectedSubsegment", "selectedFields", "innerQueries", "selectedQueryId")
    get isValidatingFields() {
        let firstSelectedField = this.firstSelectedField;
        if (firstSelectedField && (firstSelectedField.segment !== this.selectedSegment ||
                firstSelectedField.subsegment !== this.selectedSubsegment ||
                firstSelectedField.fieldComponent !== this.selectedFieldComponent ||
                (this.get("innerQueries.length") > 1 && firstSelectedField.queryId !== this.selectedQueryId))) {
            Ember.run.next(()=>{
                this.set("selectedSegment", firstSelectedField.segment);
                this.set("selectedSubsegment", firstSelectedField.subsegment);
                this.set("selectedFieldComponent", firstSelectedField.fieldComponent);
                if (this.get("innerQueries.length") > 1 && firstSelectedField.queryId !== this.selectedQueryId) {
                    this.set("selectedQueryId", firstSelectedField.queryId);
                }
            });
            return true;
        }
    }

    @action
    updateFields(selectedFields) {
        if (this?.queries?.length > 1 && selectedFields && selectedFields[0]) {
            // For multi-query selection, we chose one field and let the parent
            // component make copies for each selected query
            let field = selectedFields[0];
            field.set("query", this?.firstQuery);
            selectedFields = [field];
        } else if (this?.queries?.length === 1 && selectedFields) {
            selectedFields.forEach(field => {
                if (field.query !== this.firstQuery) {
                    field.set("query", this.firstQuery);
                }
            });
        }
        if (colorizeFields(selectedFields)) {
            selectedFields = selectedFields.slice();
        }
        this.fieldsChanged(selectedFields);
    };
};
