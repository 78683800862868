import Ember from "ember";
import SelfContainedTrends from "infegy-frontend/components/trends/self-contained-trends/component";

export default SelfContainedTrends.extend({
    segmentTypes: "emotions",
    selectedTrendsSegment: "Emotions",

    query: null,
    queries: null,
    emotionData: null,

    innerQueries: Ember.computed("query", "queries", function () {
        if(Ember.isNone(this.queries)){
            if (this.query) {
                return [this.query];
            }
        } else {
            return this.queries;
        }
    }),

    emotionSelected: () => {},
    actions: {
        fieldSelected: function (field) {
            if (!field || !field.get("fieldName")) {
                this.emotionSelected(null);
                return;
            }
            var emotionData = this.emotionData,
                fieldEmotion = /[a-z]+/.exec(field.get("fieldName"));
            if (!emotionData || !fieldEmotion) {
                return;
            }
            fieldEmotion = fieldEmotion && fieldEmotion[0];
            var selectedEmotion = emotionData.find(emo => {
                    return Ember.get(emo, "emotion") === fieldEmotion;
                });
            this.emotionSelected(selectedEmotion);
        }
    }
});
