import Ember from "ember";

export default Ember.Component.extend({
    classNames: ["icon-view-selector", "no-print"],
    viewType: "stream",

    viewTypeChanged: () => {},
    actions: {
        themesViewTypeChanged: function(view) {
            this.viewTypeChanged(view);
        }
    }
});
