import Ember from "ember";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";

function sentiment_color(percent){
		var color1 = percent >= 0.5 ?  [248,204,0] : [239,8,73],
			color2 =  percent >= 0.5 ? [127,196,10] : [248,204,0];
		percent = percent >= 0.5 ? (percent - 0.5) * 2 : percent * 2;
		return [(color1[0]+(color2[0]-color1[0])*percent).toFixed(0),
				(color1[1]+(color2[1]-color1[1])*percent).toFixed(0),
				(color1[2]+(color2[2]-color1[2])*percent).toFixed(0)];
	}

export default Ember.Controller.extend({
	linguisticsInput:'',
	lastInput: '',
	websocket:null,
	response:null,
	interval:null,

	positivity: Ember.computed("response",function(){
		let response = this.response;
		if(response)
			return response.sentiment.positive_score / (response.sentiment.positive_score + Math.abs(response.sentiment.negative_score));
		return 0;
	}),
	negativity: Ember.computed("response",function(){
		let response = this.response;
		if(response)
			return Math.abs(response.sentiment.negative_score) / (response.sentiment.positive_score + Math.abs(response.sentiment.negative_score));
		return 0;
	}),

	taxonomy: Ember.computed("response",function(){
		var response = this.response,
			taxonomy = response && response.taxonomy || [];
		taxonomy = taxonomy.map((tx)=>{
			let parts = tx.name.split("|");
			tx.name = parts[1];
			tx.category = parts[0];
			return tx;
		});

		return taxonomy;
	}),

	themes: Ember.computed("response","response.themes", function(){
		let themes = this.get("response.themes") || [];
		themes.forEach((theme)=>{
			theme.color = sentiment_color(theme.positive_appearances / (theme.positive_appearances + theme.negative_appearances));
			theme.style = new Ember.String.htmlSafe(`color:rgb(${theme.color}`);
		});
		return themes;
	}),

	emotions: Ember.computed("response","response.emotions", function(){
		var emotions = this.get("response.emotions") || [];
		return emotions;
	}),

	entities: Ember.computed("response","response.entities",function(){
		let entities = this.get("response.entities") || [];
		entities.forEach((entity)=>{
			entity = setProperties(entity);
			let color = sentiment_color(entity.positive_appearances / (entity.positive_appearances + entity.negative_appearances));
			entity.style = new Ember.String.htmlSafe(`color:rgb(${color.join(',')})`);
			entity.class = new Ember.String.htmlSafe(`entity entity-${entity.entity_type}`);
			if(entity.referenced_entities){
				entity.referenced_entities.forEach((ren)=>{
					ren = setProperties(ren);
				})
			}
		});
		return entities;
		function setProperties(en){
			let keys = Object.keys(en);
			en.properties = [];
			keys.forEach((key)=>{
				if(key === "traded_as"){
					en.properties.push({
						key:key,
						value: en[key].reduce((pv, ta)=> {
							return pv.concat(Object.keys(ta).map((ta_key)=> {
								return {
									"exchange": ta_key,
									"symbol": ta[ta_key]
								};
							}));
						},[])
					});
					return;
				}
				if(["id", "referenced_entities", "entity_type"].includes(key) || 
						!(["string", "number"].includes(typeof en[key])) || 
						key.includes("score") || key.includes("appearances"))
						return;
				en.properties.push({
					key:key,
					value:en[key]
				});
			});
			return en;
		}
	}),

	haveResponse: Ember.computed.alias("response.total_bytes"),

	setup: Ember.on("init", function() {
		var url = "wss://atlas_staging.infegy.com/api/v3/linguistics-socket?session-token=" + window.localStorage.getItem('atlasToken');
		var websocket = new WebSocket(url);
		var interval = setInterval(this.poll.bind(this), 250);
		websocket.onmessage = (response)=>{ this.set("response",JSON.parse(response.data)); }
		this.set("websocket",websocket);
		this.set("interval",interval);
	}),

	poll:function(){
		let input = this.linguisticsInput,
			last_input = this.lastInput,
			websocket = this.websocket;
		if(input !== last_input){
			websocket.send(input);
			this.set("lastInput",input);
		}
	},

	actions: {
	}
});
