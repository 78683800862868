import Ember from 'ember';
/**
 * @param string            | Source string to be truncated
 * @param frontLength       | Number of characters in the front to keep
 * @param backLength        | Number of characters in the back to keep
 * @returns {string|*}      | ex: google.com/...last-bit-of-url-string
 */
export function urlFormat(string, frontLength, backLength) {
    let strLen = string.length || 0,
        frontLen = frontLength || 15,
        backLen = backLength || 20,
        truncateStr = `\u2026`;

    if (frontLen === 0 && backLen === 0 || frontLen >= strLen || backLen >= strLen || (frontLen + backLen >= strLen)) {
        return string;
    } else if (backLen === 0) {
        return string.slice(0, frontLen) + truncateStr;
    } else {
        return string.slice(0, frontLen) + truncateStr + string.slice(strLen - backLen);
    }
}

export default Ember.Helper.helper(urlFormat);
