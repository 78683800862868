import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
// import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";
import QueryAPIResult from "infegy-frontend/models/queries/query_api_result";

export default QueryAPIResult.extend({
    title : "postClusterNodes",
    preload: function() {
      var rawResponse = this.rawResults;
    },
    description: DataSeriesDescription.load({
        "clusters": "array",
        "links": "array",
        "nodes": "array"
    })
});
