import Ember from "ember";
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { ComparisonTableField, ComparisonTableFields } from "infegy-frontend/components/field-components/comparison-table/table-field";
import FieldDefaults from "infegy-frontend/components/field-components/comparison-table/defaults";
import FieldRow from "infegy-frontend/components/field-components/comparison-table/field-row";
import classic from "ember-classic-decorator";

@classic
export default class ComparisonTable extends Component {
    user = null;
    queries = null;
    tableFields = null;

    showButtons = true;

    editingField = null;
    showOptions = false;

    displayTypeOptions = [
        { title: "Trend", value: "sparkline" },
        { title: "Bar", value: "barchart" },
        { title: "Heatmap", value: "heatmap" },
        { title: "Numeric", value: "numeric" } ];

    @computed.equal("innerTableFields.selectedLayout", "queryColumn") isQueryColumn;

    @computed("editingField")
    get hasEditingField() {
        return !Ember.isEmpty(this.editingField);
    }

    @computed("tableFields", "tableFields.updates")
    get innerTableFields() {
        if (this.tableFields) {
            return this.tableFields;
        }
        let fields = ComparisonTableFields.create();
        fields.loadJSON(FieldDefaults);
        return fields;
    }

    @computed("editingField", "hasEditingField", "innerTableFields", "innerTableFields.[]",
            "innerTableFields.@each.field", "innerTableFields.@each.displayType")
    get selectedField() {
        if (this.innerTableFields && this.hasEditingField) {
            return this.innerTableFields.fields.objectAt(this.editingField);
        }
    }

    @computed("queries", "innerTableFields", "innerTableFields.fields.[]", "innerTableFields.fields.@each.field")
    get fieldRows() {
        if (!this.queries || !this.innerTableFields || !this.innerTableFields.fields) {
            return [];
        }

        let fieldRows = this.innerTableFields.fields.map(field => {
            let fieldRow = FieldRow.create();
            let queryFields = this.queries.map(query => {
                    return {
                        field: field.field && field.field.copy(query),
                        originalField: field,
                        displayType: field.displayType,
                        fieldRow: fieldRow
                    }
                });

            fieldRow.setProperties({
                    originalField: field,
                    queryFields: queryFields,
                    queries: this.queries,
                });
            return fieldRow;
        });

        return fieldRows;
    }

    @action
    editField(index) {
        if (this.editingField === index) {
            index = null;
        }
        this.set("editingField", index);
        if (!Ember.isEmpty(index) && this.showOptions) {
            this.set("showOptions", false);
        }
    }

    @action
    removeField(index) {
        let fields = this.innerTableFields && this.innerTableFields.fields;
        if (fields && fields.length > index) {
            fields.removeAt(index);
        }
        this.set("editingField", null);
        this.tableFieldsChanging(this.innerTableFields);
    }

    @action
    addColumn() {
        this.editField(null);
        Ember.run.next(() => {
            let newRow = ComparisonTableField.create();
            this.innerTableFields.fields.pushObject(newRow);
            this.editField(this.innerTableFields.fields.length - 1);
            this.tableFieldsChanging(this.innerTableFields);
        });
    }

    @action
    columnDisplayTypeChanged(type) {
        if (this.selectedField) {
            this.selectedField.set("displayType", type);
            this.selectedField.set("field", null);
        }
    }

    @action
    selectedFieldChanged(field) {
        if (this.selectedField) {
            this.selectedField.set("field", field);
            this.tableFieldsChanging(this.innerTableFields);
        }
    }
    tableFieldsChanging(fields) {
        this.tableFields && this.tableFields.update();
        fields && fields.update();
        this.tableFieldsChanged(fields);
    };
    tableFieldsChanged() { /* action */ };

    @action
    toggleOptions() {
        if (!this.showOptions && !Ember.isEmpty(this.editingField)) {
            this.set("editingField", null);
        }
        this.toggleProperty("showOptions");
    }

    @action
    toggleShowCombinedTotals(field) {
        this.toggleProperty("innerTableFields.showCombinedTotals");
        this.tableFieldsChanging(this.innerTableFields);
    }

    @action
    toggleShowHighestFields(field) {
        this.toggleProperty("innerTableFields.showHighestFields");
        this.tableFieldsChanging(this.innerTableFields);
    }

    @action
    toggleShowLowestFields(field) {
        this.toggleProperty("innerTableFields.showLowestFields");
        this.tableFieldsChanging(this.innerTableFields);
    }

    @action
    layoutChanged(layout) {
        this.set("innerTableFields.selectedLayout", layout);
        this.tableFieldsChanging(this.innerTableFields);
    }

    @action
    reset() {
        this.setProperties({
            editingField: null,
            showOptions: false,
        });

        this.tableFieldsChanging(null);
    }
};
