import Query from "infegy-frontend/models/users/query";

var QueryURL = {
    valueDelimiter: ":",
    array_delimiters : ":,;",
    object_delimiters : "[&]",
    item_delimiters : "=",
    reserved_reg: /[:;=&\[\]{}@,]/,
    partial_reg: /[:;=&\[\]@]/,
    character_map: {
        '+': '_',
        '$': '*'
    },

    compress: function(input) {
        var compressed = LZString.compressToEncodedURIComponent(input);
        compressed = Object.keys(this.character_map).reduce((str, k) => {
            return str.split(k).join(this.character_map[k]);
        },compressed);
        return compressed;
    },

    decompress: function(compressed) {
        compressed = Object.keys(this.character_map).reduce((str, k) => {
            return str.split(this.character_map[k]).join(k);
        },compressed);
        return LZString.decompressFromEncodedURIComponent(compressed);
    },

    safeDecodeURIComponent: function(str) {
        // This fixes an issue where users including a % sign in queries breaks
        // Atlas since JS interprets it as an encoded character. Regex encodes
        // % signs not followed by 2 number characters as an encoded % (%25)
        // before passing it through the decoder.
        // The alpha chars also take into account the set | : /
        str = str.replace(/\%(?=[^0-9A-F]|[0-9A-F][^0-9A-F])/g, "%25");

        // Keep raw "+"s as an encoded %2B, so it does not get converted to a " " later.
        str = str.replace(/%2B/g, "%252B");
        str = decodeURIComponent(str);
        return str;
    },

    stringify: function(input, isProperty=false) {
        // Null
        if (input === null) {
            return QueryURL.valueDelimiter;
        }
        // Number or Boolean
        if (typeof input === 'number' || input === true || input === false) {
            return QueryURL.valueDelimiter + input;
        }
        // Array
        if (input instanceof Array) {
            var arrayRes = input.map(function(item) {
                return QueryURL.stringify(item);
            });
            if(isProperty){
                return QueryURL.valueDelimiter + '@' + arrayRes.join('-') + ';';
            } else {
                return '@' + arrayRes.join('-') + ';';
            }

        }
        // Object
        if (input instanceof Object) {
            var objRes = Object.keys(input).map(function(key) {
                var out = encodeURIComponent(key) + QueryURL.stringify(input[key],true);
                return out;
            });
            if(isProperty){
                return QueryURL.valueDelimiter + '[' + objRes.join('&&') + ']';
            }else{
                return '[' + objRes.join('&&') + ']';
            }
        }

        // String or undefined
        var str = (input || "").replace(/\'/g, "\\'");
        str = encodeURIComponent(str);

        str = str.replace(/%20/g, "+");
        str = str.replace(/%2C/g, ",");
        return isProperty ? QueryURL.valueDelimiter + "'" + str + "'" : str;
    },

    removeEmpty: function (json_input) {
        if(!json_input)
            {return;}
        Object.keys(json_input).forEach(function(key) {
            if (json_input[key] instanceof Object) {
                if (!Object.keys(json_input[key]).length && typeof json_input[key].getMonth !== 'function') {
                    delete json_input[key];
                } else {
                    QueryURL.removeEmpty(json_input[key]);
                }
            } else if (json_input[key] === null || typeof json_input[key] === "undefined") {
                delete json_input[key];
            }
        });
        return json_input;
    },

    serialize: function (input) {
        input = QueryURL.removeEmpty(input);

        if (!(typeof input === 'string') && !(input instanceof String)) {
            input = JSON.stringify(input);
        }

        return this.compress(input);
    },

    deserialize: function (str) {
        var deserialized = "";
        if(str[0] !== "@" ) {
            if(str[0] === "[") {
                str = `@${str};`;
            } else {
                // clean
                str = str.replace(/\s+/g,"");
                // decompress
                var decompressed = this.decompress(str);
                if(Ember.isNone(decompressed)){
                    throw "Could not decompress";
                }
                return JSON.parse(decompressed);
            }
        }

        // Deprecated old deserialization
        str = QueryURL.safeDecodeURIComponent(str);
        str = str.replace(/\+/g, " ");
        str = str.replace(/%2B/g, "+");
        str = str.replace(/\]-\[/g, "],[");
        str = str.replace(/\[/g, "{");
        str = str.replace(/\&&/g, ",");
        str = str.replace(/\]/g, "}");
        str = str.replace(/@/g, "[");
        str = str.replace(/;/g, "]");

        var new_string = "";
        var start = str.search(QueryURL.reserved_reg);

        while (start >= 0) {
            var start_char = str.charAt(start);
            new_string = new_string + start_char;

            var remaining = str.substr(start + 1);
            var end = remaining.search(QueryURL.reserved_reg);
            if (end === 0) {
                start += 1;
                continue;
            }
            if (str.charAt(start) === ":") {
                if (str.charAt(start + 1) === "'") {
                    remaining = remaining.substr(1);
                    if (remaining.charAt(0) === "'") {
                        new_string = new_string + "\"\"";
                        end = 2;
                    } else {
                        end = remaining.search(/[^\\]'/) + 1;
                        remaining = remaining.substring(0, end);
                        remaining = remaining.replace(/\\'/g, "'");
                        remaining = remaining.replace(/\\/g, "\\\\");
                        remaining = remaining.replace(/\"/g, "\\\"");
                        remaining = remaining.replace(/\[/g, "@");
                        new_string = new_string + "\"" + remaining + "\"";
                        end = end + 2;
                    }
                } else {
                    new_string = new_string + remaining.substring(0, end);
                }
            } else {
                remaining = remaining.substring(0, end);
                if (remaining.length) {
                    new_string = new_string + "\"" + QueryURL.safeDecodeURIComponent(remaining) + "\"";
                }
            }

            if (end < 0) {
                break;
            }
            start = start + end + 1;
        }

        new_string = new_string.replace(/,\s*]/g, "]");
        try {
            deserialized = JSON.parse(new_string);
        } catch (e) {
            console.error("Error parsing query: ", new_string);
            // Raven.captureMessage("Error parsing query", {
            //     extra: {
            //         JSONString: new_string,
            //         query: str
            //     }
            // });
        }

        return deserialized;
    },

    queryArrayToUrl(queries){
        let startDate = null,
            endDate = null,
            minStart = Infinity,
            maxEnd = 0;
        let arr = queries.map(function (query) {
            var queryInfo = query.get("queryInfo"),
                queryInfoJSON = queryInfo.toJSON({fieldGroups:"api"});
            if (!queryInfoJSON) {
                return;
            }
            if (!queryInfoJSON.source_query && queryInfoJSON.source_query_exclude) {
                delete queryInfoJSON.source_query_exclude;
            }
            let query_start = queryInfo.get("startDate"),
                query_end = queryInfo.get("endDate"),
                query_start_timestamp = query_start.get("timestamp"),
                query_end_timestamp = query_end.get("timestamp");

            if(minStart > query_start_timestamp) {
                startDate = query_start.toJSON();
                minStart = query_start_timestamp;
            }

            if(maxEnd < query_end_timestamp) {
                endDate = query_end.toJSON();
                maxEnd = query_end_timestamp;
            }

            delete queryInfoJSON.deleted;
            delete queryInfoJSON.user_id;
            delete queryInfoJSON.id;
            delete queryInfoJSON.hash_key;
            return queryInfoJSON;
        }).filter(row => !!row);

        arr.unshift({
                    startDate:startDate,
                    endDate:endDate
                });

        var url = QueryURL.serialize(arr.toArray());
        return url;
    },

    urlToQueryArray(url){
        url = QueryURL.safeDecodeURIComponent(url);

        var query_jsons = QueryURL.deserialize(url);

        if (!query_jsons) {
            return [];
        }

        var globalFiltersData = query_jsons.shift();
        return query_jsons.map((query_json, idx) => {
            Object.keys(globalFiltersData).forEach((key) => {
                query_json[key] = globalFiltersData[key];
            });
            let newQuery = Query.create();
            newQuery.queryFilters.loadJSON(query_json);
            newQuery.color.loadDefaultColorForIndex(idx);
            return newQuery;
        });
    },

    isURLDeprecated(url) {
        return url.includes('@');
    }
};
window.QueryURL = QueryURL;
export default QueryURL;
