import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import Component from "@glimmer/component";
import { argument } from 'infegy-frontend/decorators/arguments';

export default class SearchSelectList extends Component {
    @argument(false) isVisible;
    @argument("") additionalClassNames;

    @tracked defaultNumItemsToDisplay = 50;
    @tracked showAll = false;

    get displayItems(){
        if(this.showAll) {
            return this.args.filteredItems;
        }
        return this.args.filteredItems.slice(0,this.defaultNumItemsToDisplay);
    }

    @action
    toggleShowAll(){
        this.showAll = true;
    }

    @action
    itemSelected(item, event) {
        event.stopPropagation();
        this.args.itemSelected(item);
    }
}