import Ember from 'ember';

export function numberFormat(params, namedArgs) {
  if (!params[0]) {
        return '0';
    }

    var decimals = namedArgs.decimals || 0;
    var dec_point = namedArgs.decimals || '.';
    var thousands_sep = namedArgs.thousands_sep || ',';
    var abs = namedArgs.abs;

    if (Math.abs(params[0]) >= 10) {
        decimals = 0;
    } else if (Math.abs(params[0]) - Math.floor(params[0]) === 0) {
        decimals = 0;
    } else if (Math.abs(params[0]) === 0) {
        decimals = 0;
    } else if (Math.abs(params[0]) >= 1) {
        decimals = 1;
    } else if (Math.abs(params[0]) < 0.000001) {
        decimals = 8;
    } else if (Math.abs(params[0]) < 0.00001) {
        decimals = 7;
    } else if (Math.abs(params[0]) < 0.0001) {
        decimals = 6;
    } else if (Math.abs(params[0]) < 0.001) {
        decimals = 5;
    } else if (Math.abs(params[0]) < 0.01) {
        decimals = 4;
    } else if (Math.abs(params[0]) < 0.1) {
        decimals = 3;
    } else if (Math.abs(params[0]) < 1) {
        decimals = 2;
    } else {
        decimals = 0;
    }

    var value = params[0];
    if (abs) {
        value = Math.abs(value);
    }

    var stringifiedValue = (value + '').replace(',', '').replace(' ', '');

    var n = !isFinite(+stringifiedValue) ? 0 : +stringifiedValue,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function(n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }

    const simplifyWholeNumbers = namedArgs.simplifyWholeNumbers || false;
    let result = s.join(dec).trim(),
        decimalIndex = result.indexOf(".");

    if (simplifyWholeNumbers && (result % 1 === 0) && (decimalIndex > 0)) {
        result = result.slice(0, decimalIndex);
    }

    return result;
}

export default Ember.Helper.helper(numberFormat);
