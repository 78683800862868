import ChartColors from "infegy-frontend/static_data/fixtures/chart_colors";

var sourceQueryFieldOptions = [{
        label: "Subject Relevance",
        value: "score",
        colorRange: ChartColors.get("genericDataGradientColors"),
        keyType: "number"
    }, {
        label: "Number of Appearances",
        value: "appearances",
        colorRange: ChartColors.get("genericDataGradientColors"),
        keyType: "number"
    }, {
        label: "Topic Cluster",
        value: "clusterId",
        keyType: "cluster",
        exclusivelyColorOption: true
    }]

var singleQueryFieldOptions = sourceQueryFieldOptions.concat([{
        label: "Sentiment by Appearance",
        value: "positiveAppearancesDistribution",
        colorRange: ChartColors.get("sentimentGradientCSSColors"),
        sentimentThresholdKey: "appearances",
        keyType: "sentiment"
    }, {
        label: "Sentiment by Post",
        value: "positiveDocumentsDistribution",
        colorRange: ChartColors.get("sentimentGradientCSSColors"),
        sentimentThresholdKey: "documents",
        keyType: "sentiment"
    }, {
        label: "Passion",
        value: "passion",
        colorRange: ChartColors.get("passionGradientColors"),
        keyType: "passion",
        keyRange: [0,50,100]
    }, {
        label: "Intensity",
        value: "intensity",
        colorRange: ChartColors.get("passionGradientColors"),
        keyType: "passion",
        keyRange: [0,50,100]
    }, {
        label: "Positive Intensity",
        value: "positiveIntensity",
        colorRange: ChartColors.get("positiveGradientColors"),
        keyType: "positiveIntensity",
        keyRange: [0,50,100]
    }, {
        label: "Negative Intensity",
        value: "negativeIntensity",
        colorRange: ChartColors.get("negativeGradientColors"),
        keyType: "negativeIntensity",
        keyRange: [0,50,100]
    }]);

var queryOption = {
        label: "Query",
        value: "_originalQueryIdx",
        keyType: "query"
    };
var multiQueryFieldOptions = singleQueryFieldOptions.concat([queryOption]);
var multiSourceQueryFieldOptions = sourceQueryFieldOptions.concat([queryOption]);

var fieldLookups = multiQueryFieldOptions.reduce((memo, row) => {
        memo[row.value] = row;
        return memo;
    }, {});

export default {
    sourceQueryFieldOptions,
    multiSourceQueryFieldOptions,
    singleQueryFieldOptions: singleQueryFieldOptions,
    multiQueryFieldOptions: multiQueryFieldOptions,
    fieldLookups: fieldLookups
};
