import Route from "@ember/routing/route";

export default class LoginRoute extends Route {
    title = "Log In";

    setupController(controller, model) {
        var previousPage = window.location.pathname,
            hash = location.hash.substring(window.location.hash.indexOf("previousLocation=") + 17);

        if(hash.length > 0){
            previousPage = hash;
        }

        if (previousPage !== "/") {
            controller.set('previousPage', "previousLocation=" + previousPage);
        }

        if (previousPage.indexOf('dashboard') > -1 ) {
            controller.set('loginError', 'Your session has expired. Please log back in to continue.');
        }
    }
}