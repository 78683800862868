import Ember from "ember";
import Component from '@ember/component';
import AtlasColors from "infegy-frontend/utils/atlas-colors";
import tinycolor from "infegy-frontend/utils/tinycolor";
import { run } from '@ember/runloop';
import { computed, action } from '@ember/object';

export default class ColorPicker extends Component {
    queries = null;

    classNames = ["inline-field-select"];

    selectedField = null;

    @computed("selectedField.color")
    get selectedColor() {
        if (this.selectedField && this.selectedField.color) {
            return this.selectedField.color.colorString;
        }
    }
    set selectedColor(color) {
        if (this.selectedField && this.selectedField.color) {
            return this.selectedField.color.colorString;
        }
    }

    commonSwatches = [
        AtlasColors.getColorfromClassName("atlas-chart-1"),
        AtlasColors.getColorfromClassName("atlas-chart-2"),
        AtlasColors.getColorfromClassName("atlas-chart-3"),
        AtlasColors.getColorfromClassName("atlas-chart-4"),
        AtlasColors.getColorfromClassName("atlas-chart-5"),
        AtlasColors.getColorfromClassName("atlas-chart-6"),
        AtlasColors.getColorfromClassName("atlas-chart-7"),
        AtlasColors.getColorfromClassName("atlas-chart-8"),
        AtlasColors.getColorfromClassName("atlas-chart-9"),
        AtlasColors.getColorfromClassName("atlas-chart-10"),
    ];

    componentSettings = {
        palette: true, // Will be overwritten with true if preview, opacity or hue are true
        // preview: true,
        opacity: false,
        hue: true,
    };

    // Utilizing TinyColor to parse out the Alpha in the rgba() color value to compare like elements.
    @computed("commonSwatches", "queries")
    get swatches() {
        let swatches = this.commonSwatches || [],
            allColors = [];

        swatches.forEach(swatch => { allColors.unshift(tinycolor(swatch).toRgbString()); });

        if (this.queries) {
            this.queries.forEach(query => { allColors.push(tinycolor(query.color.colorString).toRgbString()); });
        }

        return [...new Set(allColors)];
    };

    colorSaved() { /* action */ }

    @action
    colorSaving(colorString) {
        if (colorString && typeof(colorString) === "object") {
            let rgba = colorString.toRGBA();
            rgba[0] = Math.round(rgba[0]);
            rgba[1] = Math.round(rgba[1]);
            rgba[2] = Math.round(rgba[2]);
            rgba[3] = Math.round(rgba[3]);
            colorString = rgba.toString();
        }
        Ember.run.later(() => {
            if (this.selectedColor !== colorString) {
                    this.colorSaved(colorString);
            }
        });
    }
};
