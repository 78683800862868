import Ember from "ember";
import JsonStore from 'infegy-frontend/json-store/model';
import AdminUser from "infegy-frontend/models/admin/admin_user";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import CollectionStore from "infegy-frontend/json-store/collection/model";

export default JsonStore.extend({
    list: [],
    listOptions: Ember.computed("list", "list.[]", "list.length", function() {
        return this.list.reduce((p, user) => {
            if (user.isActive) {
                p.push({
                    name: (user.name || user.username).trim(),
                    title: (user.name || user.username).trim(),
                    id: user.id,
                    label: (user.name || user.username).trim(),
                    value: user.id,
                    alternates: [user.id, user.email, user.username, user.company.name],
                    details: user.username
                });
            }

            return p;
        }, []);
    }),

    fetchUsers: function() {
        var dfd = AtlasAuth.Get("api/v3/user/list.json?with_stats=true");

        dfd.catch((error) => {
            console.error("Failed to get users list", error);
        })

        dfd.then((data) => {
            if ("output" in data) {
                let users = CollectionStore.create();
                users.set("itemClass", AdminUser);
                users.loadJSON(data.output);
                this.set("list", users.content);
            }
        });
    },
});
