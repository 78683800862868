import Ember from "ember";
import Component from '@ember/component';
import { computed } from '@ember/object';
import FieldSelectorBase from "infegy-frontend/components/field-selector/parts/fields/trendable-field-base/component";
import AtlasColors from "infegy-frontend/utils/atlas-colors";
import layout from "infegy-frontend/components/field-selector/parts/fields/trendable-selector-layout/template";

export default class FieldSelectorGender extends FieldSelectorBase {
    layout = layout;
    apiName = "gender";

    @computed("fieldType", "selectedSegment", "selectedSubsegment")
    get allAvailableFields() {
        return {
            "CountDistribution" : [
                this.makeFieldDefinition({
                    title: "Female Count Distribution",
                    fieldName: "femaleCountDistribution",
                    defaultColor: AtlasColors.getColorfromClassName("atlas-female"),
                    axisGroup: "percentage"
                }),
                this.makeFieldDefinition({
                    title: "Male Count Distribution",
                    fieldName: "maleCountDistribution",
                    defaultColor: AtlasColors.getColorfromClassName("atlas-male"),
                    axisGroup: "percentage"
                }),
                this.makeFieldDefinition({
                    title: "Non-binary Distribution",
                    fieldName: "nonBinaryCountDistribution",
                    defaultColor: AtlasColors.getColorfromClassName("atlas-nonbinary"),
                    axisGroup: "percentage"
                })
            ],
            "Count" : [
                this.makeFieldDefinition({
                    title: "Female Count",
                    fieldName: "femaleCount",
                    defaultColor: AtlasColors.getColorfromClassName("atlas-female"),
                    axisGroup: "Gender.Count"
                }),
                this.makeFieldDefinition({
                    title: "Male Count",
                    fieldName: "maleCount",
                    defaultColor: AtlasColors.getColorfromClassName("atlas-male"),
                    axisGroup: "Gender.Count"
                }),
                this.makeFieldDefinition({
                    title: "Non-binary Count",
                    fieldName: "nonBinaryCount",
                    defaultColor: AtlasColors.getColorfromClassName("atlas-nonbinary"),
                    axisGroup: "Gender.Count"
                })
            ],
            "UniverseDistribution" : [
                this.makeFieldDefinition({
                    title: "Female Universe Distribution",
                    fieldName: "femaleUniverseDistribution",
                    defaultColor: AtlasColors.getColorfromClassName("atlas-female"),
                    axisGroup: "percentage"
                }),
                this.makeFieldDefinition({
                    title: "Male Universe Distribution",
                    fieldName: "maleUniverseDistribution",
                    defaultColor: AtlasColors.getColorfromClassName("atlas-male"),
                    axisGroup: "percentage"
                }),
                this.makeFieldDefinition({
                    title: "Non-binary Universe Distribution",
                    fieldName: "nonBinaryUniverseDistribution",
                    defaultColor: AtlasColors.getColorfromClassName("atlas-nonbinary"),
                    axisGroup: "percentage"
                })
            ],
            "Universe" : [
                this.makeFieldDefinition({
                    title: "Female Universe",
                    fieldName: "femaleUniverse",
                    defaultColor: AtlasColors.getColorfromClassName("atlas-female"),
                    axisGroup: "Gender.Universe"
                }),
                this.makeFieldDefinition({
                    title: "Male Universe",
                    fieldName: "maleUniverse",
                    defaultColor: AtlasColors.getColorfromClassName("atlas-male"),
                    axisGroup: "Gender.Universe"
                }),
                this.makeFieldDefinition({
                    title: "Non-binary Universe",
                    fieldName: "nonBinaryUniverse",
                    defaultColor: AtlasColors.getColorfromClassName("atlas-nonbinary"),
                    axisGroup: "Gender.Universe"
                })
            ]
        };
    };
};
