import Ember from "ember";
import TrendChartSVG from "infegy-frontend/components/trends/trend-chart/trend-chart-svg/component";
import { isEmpty } from '@ember/utils';
import d3 from "d3";

export default TrendChartSVG.extend({

    streamHoveredField: null,

    d3Stack: d3.stack()
        .offset(d3.stackOffsetWiggle),

    fieldMap: Ember.computed("fields", "fields.[]", "fields.@each.fieldName", function(){
        return this.fields.reduce((fieldMap, field)=>{
            fieldMap[field.fieldName] = field;
            return fieldMap
        }, {});
    }),

    stackData: Ember.computed("fields", "fields.[]", "fields.@each.fieldName", function () {
        let fields = this.fields || [];
        let filteredFields = fields.filter(field => field.max - field.min);
        let fieldData = filteredFields[0] && filteredFields[0].data;
        if(!fieldData){
            return;
        }
        let values = fieldData.reduce((values, _, idx)=>{
            let idxVals = { };
            idxVals = filteredFields.reduce((idxVals, field)=>{
                if(isEmpty(idxVals.x)){ 
                    idxVals.x = field.data[idx][field.xAxisField];
                }
                idxVals[field.fieldName] = Ember.get(field.data[idx], field.fieldName) || 0;
                return idxVals;
            }, idxVals);
            values.push(idxVals);
            return values;
        }, []);
        let data= this.d3Stack.keys(filteredFields.mapBy("fieldName"))(values);
        return data
    }),

    hoveredStackData: Ember.computed("stackData", "streamHoveredField", function () {
        var stackData = this.stackData,
            hovered = this.streamHoveredField;
        return hovered && stackData && stackData.find(row => Ember.get(row, "field") === hovered);
    }),

    findHoveredField: function() {
        return this.streamHoveredField;
    },

    yExtents: Ember.computed("stackData", "stackData.[]", "stackData.@each.values", function () {
        var stackData = this.stackData || 0,
            minY = Infinity,
            maxY = -Infinity;

        stackData.forEach(row => {
            var rowMax = Math.max.apply(null, row.map(vrow => vrow[1])),
                rowMin = Math.min.apply(null, row.map(vrow => Math.min(vrow[1], vrow[0])));
            maxY = Math.max(maxY, rowMax);
            minY = Math.min(minY, rowMin);
        });
        return {
            min: minY || 0,
            max: maxY || 0
        };
    }),
    actions: {
        streamHoveredFieldChanged: function (field) {
            this.set("streamHoveredField", field);
        }
    }
});
