export default [{
    code: 400,
    title: "Please Review Your Query String",
    message: "",
    queryLink: true,
    supportLink: false,
    signInLink: false,
}, {
    code: 402,
    title: "Your account has run out of requests",
    message: "If you'd like, you can submit a support request " +
        "or contact us to request an increase to this limit.",
    queryLink: false,
    supportLink: true,
    signInLink: false,
}, {
    code: 403,
    title: "Your Session Has Expired",
    message: "Please Login.",
    queryLink: false,
    supportLink: false,
    signInLink: true,
}, {
    code: 409,
    title: "Too many requests",
    message: "You've sent too many requests in the last few minutes. " +
        "Please wait a couple of minutes and resubmit your request.",
    queryLink: true,
    supportLink: false,
    signInLink: false,
}, {
    code: 413,
    title: "Your Query took too long to process and timed out",
    message: "Please wait a couple of minutes and resubmit your request.",
    queryLink: true,
    supportLink: false,
    signInLink: false,
}, {
    code: 500,
    title: "The server has encountered an error",
    message: "If you'd like you can submit a support request describing " +
        "this issue. Please include the error code.",
    queryLink: false,
    supportLink: true,
    signInLink: false,
}, {
    code: 502,
    title: "The data API is currently unavailable.",
    message: "Our systems are down for temporary maintenance. " +
        "Please wait a few minutes and retry your request.",
    queryLink: false,
    supportLink: false,
    signInLink: false,
}, {
    code: 504,
    title: "Your Query took too long to process and timed out",
    message: "Please wait a couple of minutes and resubmit your request.",
    queryLink: true,
    supportLink: false,
    signInLink: false,
}];