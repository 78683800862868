import Component from "@ember/component";
import { action, computed } from "@ember/object";
import { isEmpty } from "@ember/utils";

import Emotions from "infegy-frontend/static_data/emotions";
import LinguisticsLanguages from "infegy-frontend/static_data/linguistics-languages";
import { dictionarySentimentOptions } from "infegy-frontend/static_data/sentiment";
import Themes from "infegy-frontend/static_data/themes";

export default class DictionaryEntryEditor extends Component {
    classNameBindings = [":dictionaries-entry-editor-form"];
    maxWords = 10;
    maxLength = 100;
    errorText = "";
    canSubmit = true;

    // component inputs
    entries = [];
    editingEntriesIds = [];
    isNew = true;   // "creating" or "editing"

    // component events
    create(entryData) {}
    editEntry(entryData) {}
    updated() {}

    init() {
        super.init(...arguments);

        if (this.isNew) {
            // use component defaults
            return;
        }

        // Editing one or more entries
        if (this.isEditingSingleEntry) {
            // If single entry, initialize the appropriate inputs
            const entry = this.entries.find((entry) => entry.id === this.editingEntriesIds[0])
            // Set required fields
            this.setProperties({
                language: entry.language,
                sentiment: entry.sentiment,
                word: entry.word
            });
            // Set optional fields, if they exist
            if (entry.emotion) {
                this.set("emotion", entry.emotion);
            }
            if (entry.theme) {
                this.set("theme", entry.theme);
            }
        }
        else {
            // When multiple entries are being edited, default all lists to "unchanged"
            const optionsList = [
                this.languageOptions,
                this.sentimentOptions,
                this.themesOptions,
                this.emotionsOptions,
                this.exactMatchOptions
            ];
            optionsList.forEach((options) => {
                options.unshift({
                    label: "Unchanged",
                    value: "unchanged"
                })
            });
            this.setProperties({
                emotion: "unchanged",
                exact: "unchanged",
                language: "unchanged",
                sentiment: "unchanged",
                theme: "unchanged"
            });
        }
    }

    // UI inputs
    emotion = "none";
    exact = false;
    language = "all";
    sentiment = 0;
    theme = "none";
    word = "";
    creatingTheme = "";

    isSaving = false;

    languageOptions = ([{ label: "All", value: "all" }].concat(LinguisticsLanguages));

    sentimentOptions = dictionarySentimentOptions;

    emotionsOptions = [{
        title: "None",
        value: "none"
    }].concat(Emotions.universalEmotions.map(function(item) {
        return {
            title: item.capitalize(),
            value: item
        };
    }));

    exactMatchOptions = [{
        title: "True",
        value: true
    }, {
        title: "False",
        value: false
    }];

    @computed("entries", "entries.length")
    get themesOptions() {
        let themes = Themes.universalThemes;

        // Add user defined custom themes
        (this.entries || []).forEach((entry) => {
            // `null` or `undefined` values are not valid
            if (isEmpty(entry.theme)) 
                return;

            if (!themes.includes(entry.theme)) {
                themes.push(entry.theme);
            }
        });

        const defaultThemeOptions = [{
            label: "None",
            value: "none"
        }, {
            label: "Create New",
            value: "new"
        }];

        const themesOptions = themes.map((name) => {
            return {
                label: name,
                value: name
            };
        });

        return defaultThemeOptions.concat(themesOptions);
    }

    @computed("entries", "entries.length", "editingEntriesIds", "editingEntriesIds.length")
    get editingEntries() {
        const result = this.entries.filter((entry) => {
            return this.editingEntriesIds.includes(entry.id);
        });
        return result;
    }

    @computed.equal("editingEntries.length", 1) isEditingSingleEntry;
    @computed.or("isNew", "isEditingSingleEntry") showWordField;

    @computed("word")
    get splitWords() {
        if (!this.word) {
            return [];
        }
        return this.word.split(/[,;]/).map((word) => word.trim());
    }

    @action
    onSave() {
        this.validate();
        if (this.canSubmit) {
            this.set("isSaving", true);
        }
    }

    @action
    async onSaveConfirm() {
        let entryData = {};
        const entryFields = ["emotion", "exact", "language", "sentiment", "theme"];
        entryFields.forEach((field) => {
            const value = this.get(field);
            // Some fields are optional and can be set to "none".
            // When editing multiple entries, "unchanged" prevents the field value from being modified.
            if (value === "none") {
                entryData[field] = "";
            }
            else if (field === "theme" && this.theme === "new") {
                entryData[field] = this.creatingTheme;
            }
            else if (value != "unchanged") {
                entryData[field] = value;
            }
        });

        if (this.isNew) {
            // Create a new entry for each word
            this.splitWords.forEach((word) => {
                entryData.word = word;
                this.create(entryData);
            });
        }
        else {
            if (this.isEditingSingleEntry) {
                entryData.word = this.word;
            }

            this.entries.forEach((entry) => {
                if (!this.editingEntriesIds.includes(entry.id))
                    return;

                entry.update(entryData);
            });

            this.updated();
        }
    }

    @action
    validate() {
        if (this.isNew || this.isEditingSingleEntry) {
            var numWords = this.splitWords.length;

            if (this.isNew || this.isEditingSingleEntry) {
                if (isEmpty(this.word)) {
                    this.setProperties({
                        canSubmit: false,
                        errorText: "Must have at least 1 word"
                    });
                    return;
                }

                if (numWords > this.maxWords) {
                    this.setProperties({
                        canSubmit: false,
                        errorText: `Must use less than ${this.maxWords} words, ${numWords} detected`
                    });
                    return;
                }

                for (let i = 0; i < this.splitWords.length; i++) {
                    const word = this.splitWords[i];
                    if (word.length > this.maxLength) {
                        this.setProperties({
                            canSubmit: false,
                            errorText: `Must use less than ${this.maxLength} characters, ${word.length} detected`
                        });
                        return;
                    }
                }

            }

            if (this.theme === "new" && isEmpty(this.creatingTheme)) {
                this.setProperties({
                    canSubmit: false,
                    errorText: "Please insert a name for the new theme or select a different option"
                });
                return;
            }
        }

        this.setProperties({
            canSubmit: true,
            errorText: ""
        });
    }
}