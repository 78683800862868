import Ember from "ember";
import TrendChartField from "infegy-frontend/components/trends/trend-chart/trend-chart-svg/trend-chart-field/component";
import CSSTools from "infegy-frontend/utils/css-tools";
import d3 from "d3";
import { computed } from '@ember/object';
import { MouseInteractionWrapper } from "infegy-frontend/components/charting/mouse-interaction-mixin/component";

export default class extends MouseInteractionWrapper(TrendChartField) {

    stackValues = null;

    yExtents = {};

    streamHoveredField = null;

    @computed("field.colorValue", "isHighlighted", "isFaded")
    get activeStyle() {
        var color = this.get("field.colorValue"),
            lightColor = this.get("field.lightColorValue"),
            darkColor = this.get("field.darkColorValue");
        if (!color || !lightColor) {
            return;
        }
        if (this.isHighlighted) {
            lightColor = color;
            color = darkColor;
        } else if (this.isFaded) {
            color = lightColor;
        }
        return CSSTools.buildStringFromObjectSafe({
            fill: lightColor,
            stroke: color
        });
    }

    @computed("isHighlighted", "isFaded")
    get hoveredFadedClass() {
        if (this.isHighlighted) {
            return "stream-chart-highlighted-path";
        } else if (this.isFaded) {
            return "stream-chart-faded-path";
        }
    }

    @computed("height", "yExtents")
    get yScale() {
        var yExtents = this.yExtents;
        return d3.scaleLinear()
            .domain([yExtents.min, yExtents.max])
            .range([this.height, 0]);
    }

    @computed("xScale", "yScale")
    get area() {
        var x = this.xScale,
            y = this.yScale;

        return d3.area()
            .x((d) => x(d.data.x) || 0 )
            .y0((d) => y(d[0]) || 0 )
            .y1((d) => y(d[1] || 0)) 
            .curve(d3.curveMonotoneX);
    }

    onMouseLeave() {
        if (this.streamHoveredField) {
            this.streamHoveredFieldChanged(null);
        }
        return true;
    }

    onMouseEnter() {
        if (!this.streamHoveredField) {
            this.streamHoveredFieldChanged(this.field);
        }
        return true;
    }

    onMouseMove() {
        return true;
    }

    mouseDown() {
        return true;
    }

    mouseUp() {
        return true;
    }

    click() {
        this.send("fieldSelected", this.field);
    }

    @computed("stackValues", "area")
    get displayAreaPath() {
        var values = this.stackValues,
            area = this.area;
        return area && values && area(values);
    }
}
