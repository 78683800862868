import Component from '@ember/component';
import { computed, action } from '@ember/object';
import classic from "ember-classic-decorator";

@classic
export default class ComparisonFieldColumnTable extends Component {
    tagName = "table";
    classNameBindings = [":data-table-highlightable", ":data-table-fixed"];

    queries = null;
    fieldRows = null;
    tableFields = null;
    editingField = null;


    @computed("fieldRows", "fieldRows.[]", "fieldRows.@each.field", "queries")
    get queryRows() {
        let fieldRows = this.fieldRows || [],
            queries = this.queries || [];

        let queryRows = queries.map((query, qidx) => {
            let queryFields = fieldRows.map(fieldRow => {
                return {
                    queryField: fieldRow.queryFields[qidx],
                    fieldRow: fieldRow
                }
            });

            return {
                query: query,
                queryFields: queryFields
            }
        });

        return queryRows;
    }

    editField(fieldIdx) {/* action */ }
};
