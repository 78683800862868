import Component from "@ember/component";
import { action } from "@ember/object";
import { isEmpty } from "@ember/utils";
import classic from "ember-classic-decorator";

import AdminUsers from "infegy-frontend/models/admin/admin_users";
import Workspace from "infegy-frontend/models/users/workspace";

@classic
export default class extends Component {
    // Input
    /**
     * @type {"run_workspace"|"edit_or_view"|"view_sharing_details"|"change_folder"|"delete"|"select_workspace"|"select_queries"|"toggle_save"}
     */
    actionBarButtons = [];
    adminUsers = AdminUsers.create();
    /**
      * @type {Query[]}
      */
    sortField = "";
    workspace = Workspace.create();
    user = null;

    /**
     * @event editWorkspace Fired when the user clicks the "Edit/View" action button.
     * @param {Workspace}
     */
    /**
     * @event loadWorkspace Fired when the user clicks the "Load Workspace" action button.
     * @param {Workspace}
     */
    /**
     * @event queriesSelected Fired when the user clicks the "Select Queries" action button.
     * @param {Query[]}
     */
    /**
     * @event runWorkspace Fired when the user clicks the "Run Workspace" action button.
     * @param {Workspace}
     */

    // Internal Parameters
    viewState = "details";

    toggleSaveWorkspace(){}

    @action onBeginSelectQueries() {
        if (this.workspace.queries.length === 1) {
            this.queriesSelected(this.workspace.queries.content);
        }
        else {
            this.set("viewState", "select-queries");
        }
    }

    @action onCancelQuerySelection() {
        this.set("viewState", "details");
    }

    @action onChangeFolder() {
        this.set("viewState", "change_folder");
    }

    @action onDeleteWorkspace() {
        this.set("viewState", "delete");
    }

    @action onCancelDeleteWorkspace() {
        this.set("viewState", "details");
    }

    @action onConfirmDeleteWorkspace() {
        this.confirmDeleteWorkspace(this.workspace);
    }

    @action onQueriesSelected(queries) {
        this.queriesSelected(queries);
        this.set("viewState", "details");
    }
}
