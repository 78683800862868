import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import fieldPercentage from "infegy-frontend/models/data_series/data_series_computed_field_percentage";
import fieldSum from "infegy-frontend/models/data_series/data_series_computed_field_sum";
import fieldDistribution from "infegy-frontend/models/data_series/data_series_computed_field_distribution";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";
import fieldDifference from "infegy-frontend/models/data_series/data_series_computed_field_difference";

export default QueryAPIResults.extend({
    title : "sentiment",
    distributions: [{
        id: "documents",
        label: "Documents",
        fields: [
            { label: "Negative", value: "negativeDocumentsDistribution" },
            { label: "Positive", value: "positiveDocumentsDistribution" }
        ],
        alt_fields: [
            { label: "Negative", value: "negativeDocumentsAllDistribution" },
            { label: "Neutral", value: "neutralDocumentsAllDistribution" },
            { label: "Positive", value: "positiveDocumentsAllDistribution" }
        ]
    }, {
        id: "documents_universe",
        label: "Documents Universe",
        fields: [
            { label: "Negative", value: "negativeDocumentsUniverseDistribution" },
            { label: "Positive", value: "positiveDocumentsUniverseDistribution" }
        ],
        alt_fields: [
            { label: "Negative", value: "negativeDocumentsUniverseAllDistribution"},
            { label: "Neutral", value: "neutralDocumentsUniverseAllDistribution" },
            { label: "Positive", value: "positiveDocumentsUniverseAllDistribution" }
        ]
    }, {
        id: "sentences",
        label: "Sentences",
        fields: [
            { label: "Negative", value: "negativeSentencesDistribution" },
            { label: "Positive", value: "positiveSentencesDistribution" }
        ],
        alt_fields: [
            { label: "Negative", value: "negativeSentencesAllDistribution" },
            { label: "Neutral", value: "neutralSentencesAllDistribution" },
            { label: "Positive", value: "positiveSentencesAllDistribution" }
        ]
    }, {
        id: "subject_sentences",
        label: "Subject Sentences",
        fields: [
            { label: "Negative", value: "negativeSubjectSentencesDistribution" },
            { label: "Positive", value: "positiveSubjectSentencesDistribution" }
        ],
        alt_fields: [
            { label: "Negative", value: "negativeSubjectSentencesAllDistribution" },
            { label: "Neutral", value: "neutralSubjectSentencesAllDistribution" },
            { label: "Positive", value: "positiveSubjectSentencesAllDistribution" }
        ]
    }],
    customEmptyCheckFunction: function(dataSeries) {
        var stats = dataSeries && dataSeries.get("stats");
        return !stats || !stats.get("documents") || !stats.get("documents.sum");
    },
    description: DataSeriesDescription.load({
        "date": "datestring",
        "timestamp": "timestamp",

        "score": "number",
        "negativeScore": "number",
        "positiveScore": "number",

        "passion": "average",
        "intensity": "average",
        "positiveIntensity": "average",
        "negativeIntensity": "average",

        "subjectScore": "number",

        "documents": "number",

        "words": "number",

        "positiveDocuments": "number",
        "neutralDocuments": "number",
        "negativeDocuments": "number",
        "unknownDocuments": "number",

        "subjectSentences": "number",

        "positiveSubjectSentences": "number",
        "negativeSubjectSentences": "number",
        "neutralSubjectSentences": "number",

        "sentences": "number",

        "positiveSentences": "number",
        "negativeSentences": "number",
        "neutralSentences": "number",

        // Based on documents
        "netSentiment": "number",

        "positiveDocumentsStored": "number",
        "negativeDocumentsStored": "number",
        "neutralDocumentsStored": "number",
        "unknownDocumentsStored": "number",

        "positiveDocumentsUniverse": "number",
        "negativeDocumentsUniverse": "number",
        "neutralDocumentsUniverse": "number",
        "unknownDocumentsUniverse": "number",

        // Ignore?
        "positiveSubjectDocuments": "number",
        "neutralSubjectDocuments": "number",
        "negativeSubjectDocuments": "number",
        "unknownSubjectDocuments": "number",

        // Ignore?
        "positiveWords": "number",
        "negativeWords": "number",
        "neutralWords": "number"

    }, [
        fieldDistribution.fieldSetup(["positiveDocuments", "negativeDocuments"]),
        fieldDistribution.fieldSetup(["positiveDocumentsUniverse", "negativeDocumentsUniverse"]),
        fieldDistribution.fieldSetup(["positiveSentences", "negativeSentences"]),
        fieldDistribution.fieldSetup(["positiveSubjectSentences", "negativeSubjectSentences"]),

        fieldSum.fieldSetup("sentimentalScoredDocuments", ["neutralDocuments", "positiveDocuments", "negativeDocuments"]),
        fieldSum.fieldSetup("sentimentalScoredDocumentsStored", ["neutralDocumentsStored", "positiveDocumentsStored", "negativeDocumentsStored"]),
        fieldSum.fieldSetup("sentimentalScoredDocumentsUniverse", ["neutralDocumentsUniverse", "positiveDocumentsUniverse", "negativeDocumentsUniverse"]),
        fieldSum.fieldSetup("sentimentalScoredSentences", ["neutralSentences", "positiveSentences", "negativeSentences"]),
        fieldSum.fieldSetup("sentimentalScoredSubjectSentences", ["neutralSubjectSentences", "positiveSubjectSentences", "negativeSubjectSentences"]),

        fieldPercentage.fieldSetup("positiveDocumentsAllDistribution", "sentimentalScoredDocuments", "positiveDocuments"),
        fieldPercentage.fieldSetup("negativeDocumentsAllDistribution", "sentimentalScoredDocuments", "negativeDocuments"),
        fieldPercentage.fieldSetup("neutralDocumentsAllDistribution", "sentimentalScoredDocuments", "neutralDocuments"),

        fieldPercentage.fieldSetup("positiveDocumentsUniverseAllDistribution", "sentimentalScoredDocumentsUniverse", "positiveDocumentsUniverse"),
        fieldPercentage.fieldSetup("negativeDocumentsUniverseAllDistribution", "sentimentalScoredDocumentsUniverse", "negativeDocumentsUniverse"),
        fieldPercentage.fieldSetup("neutralDocumentsUniverseAllDistribution", "sentimentalScoredDocumentsUniverse", "neutralDocumentsUniverse"),

        fieldPercentage.fieldSetup("positiveSentencesAllDistribution", "sentimentalScoredSentences", "positiveSentences"),
        fieldPercentage.fieldSetup("negativeSentencesAllDistribution", "sentimentalScoredSentences", "negativeSentences"),
        fieldPercentage.fieldSetup("neutralSentencesAllDistribution", "sentimentalScoredSentences", "neutralSentences"),

        fieldPercentage.fieldSetup("positiveSubjectSentencesAllDistribution", "sentimentalScoredSubjectSentences", "positiveSubjectSentences"),
        fieldPercentage.fieldSetup("negativeSubjectSentencesAllDistribution", "sentimentalScoredSubjectSentences", "negativeSubjectSentences"),
        fieldPercentage.fieldSetup("neutralSubjectSentencesAllDistribution", "sentimentalScoredSubjectSentences", "neutralSubjectSentences"),

        fieldSum.fieldSetup("sentimentalDocuments", ["positiveDocuments", "negativeDocuments"]),
        fieldSum.fieldSetup("sentimentalDocumentsStored", ["positiveDocumentsStored", "negativeDocumentsStored"]),
        fieldSum.fieldSetup("sentimentalDocumentsUniverse", ["positiveDocumentsUniverse", "negativeDocumentsUniverse"]),
        fieldSum.fieldSetup("sentimentalSentences", ["positiveSentences", "negativeSentences"]),
        fieldSum.fieldSetup("sentimentalSubjectSentences", ["positiveSubjectSentences", "negativeSubjectSentences"]),

        fieldDistribution.fieldSetup(["neutralDocuments", "sentimentalDocuments"]),
        fieldDistribution.fieldSetup(["neutralDocumentsUniverse", "sentimentalDocumentsUniverse"]),
        fieldDistribution.fieldSetup(["neutralSentences", "sentimentalSentences"]),
        fieldDistribution.fieldSetup(["neutralSubjectSentences", "sentimentalSubjectSentences"]),

        fieldPercentage.multiFieldSetup("sentimentalDocumentsPercent", "documents", ["positiveDocuments", "negativeDocuments"]),

        fieldPercentage.fieldSetup("sentimentalSentences", "sentences", "neutralSentences", true),
        fieldPercentage.fieldSetup("sentimentalSubjectSentences", "subjectSentences", "neutralSubjectSentences", true),

        fieldDifference.fieldSetup("netSentimentDocuments", "positiveDocuments", "negativeDocuments"),
        fieldDifference.fieldSetup("netSentimentSubjectSentences", "positiveSubjectSentences", "negativeSubjectSentences"),
        fieldDifference.fieldSetup("netSentimentSentences", "positiveSentences", "negativeSentences"),

        fieldPercentage.fieldSetup("netSentimentDocumentsPercent", "documents", "netSentimentDocuments"),
        fieldPercentage.fieldSetup("netSentimentSubjectSentencesPercent", "subjectSentences", "netSentimentSubjectSentences"),
        fieldPercentage.fieldSetup("netSentimentSentencesPercent", "sentences", "netSentimentSentences")

    ])
});
