import Ember from "ember";
import DefaultColorDefinitions from "infegy-frontend/static_data/fixtures/default_color_definitions";
import d3 from "d3";

var chartColors;

var ChartColors = Ember.Object.extend({
    colorMap: {},
    baseColorClasses: [],
    indexedColors: [],
    indexedColorClasses: [],

    "1": {},
    "2": {},
    "3": {},
    "4": {},
    "5": {},
    "6": {},
    "gray": {},
    "positive": {},
    "negative": {},
    "neutral": {},
    "ui": {},

    addOldColorAttrs: function() {
        var oldColorAttrs = ["1", "2", "3", "4", "5", "6", "positive", "negative", "ui", "gray"],
            indexedColors = this.indexedColors;

        for (var colorIdx = 0; colorIdx < oldColorAttrs.length; colorIdx++) {
            if (indexedColors[colorIdx]) {
                this.set(oldColorAttrs[colorIdx], indexedColors[colorIdx]);
            }
        }
    },

    findColorsByClassName: function(className) {
        var elem = document.createElement("div");
        document.body.appendChild(elem);

        var colorDef = {
            className: className,
            lightClassName: [className, "light"].join("-"),
            darkClassName: [className, "dark"].join("-"),
            bgClassName: [className, "bg"].join("-"),
            bgLightClassName: [className, "light", "bg"].join("-"),
            bgDarkClassName: [className, "dark", "bg"].join("-")
        };

        function getColorFromClass(className){
            elem.classList = className;
            var computed = window.getComputedStyle(elem);
            return computed.color;
        }

        colorDef.base = getColorFromClass(colorDef.className);
        colorDef.light = getColorFromClass(colorDef.lightClassName);
        colorDef.dark = getColorFromClass(colorDef.darkClassName);

        document.body.removeChild(elem);

        return colorDef;
    },

    loadColorDefinitions: function(colorDefs) {
        var colorMap = {},
            baseColorClasses = [],
            indexedColors = [];

        for (var colorIdx = 0; colorIdx < colorDefs.length; colorIdx++) {
            var colorInfo = colorDefs[colorIdx],
                allColorClassNames = (colorInfo.alternateClasses || []).slice();

            allColorClassNames.push(colorInfo.className);
            for (var classIdx = 0; classIdx < allColorClassNames.length; classIdx++) {
                var className = allColorClassNames[classIdx],
                    colorDef = this.findColorsByClassName(className);

                colorMap[className] = Object.assign(colorDef, {
                    index: colorIdx,
                    class: className,
                    classAliases: allColorClassNames
                });
                // Add a camelized version of the class name without the "atlas-" prefix for easy lookups in templates.
                colorMap[className.replace("atlas-", "").camelize()] = colorMap[className];
            }

            indexedColors[colorIdx] = colorMap[colorInfo.className];
            baseColorClasses = baseColorClasses.concat(allColorClassNames);
        }
        this.set("colorMap", colorMap);
        this.set("baseColorClasses", baseColorClasses.uniq());
        this.set("indexedColors", indexedColors);
        this.set("indexedColorClasses", indexedColors.mapBy("className"));

        this.addOldColorAttrs();
    },
    sentimentGradientClasses: ["atlas-sentiment-negative", "atlas-sentiment-mixed", "atlas-sentiment-positive"],
    // TODO valueForClass(class, field), colorValueForClass(class),
    // valueForClasses(class, field), colorValuesForClasses(array)
    // makes a lot of conversions easier
    sentimentGradientCSSColors: Ember.computed("colorMap", function () {
        var colorMap = chartColors.get("colorMap"),
            classes = chartColors.get("sentimentGradientClasses") || [];
        return classes.filter(function(className) {
            return !!colorMap[className];
        }).map(function(className) {
            return colorMap[className].base;
        });
    }),

    genericDataGradientClasses: ["atlas-ui", "atlas-ui-light", "atlas-ui-dark"],
    genericDataGradientColors: Ember.computed("colorMap", function () {
        var colorMap = chartColors.get("colorMap"),
            value = colorMap["atlas-ui"];
        return [value.light, value.base, value.dark];
    }),
    passionGradientColors: Ember.computed("colorMap", function () {
        var colorMap = chartColors.get("colorMap"),
            value = colorMap["atlas-passion"];
        return [value.light, value.base, value.dark];
    }),
    positiveGradientColors: Ember.computed("colorMap", function () {
        var colorMap = chartColors.get("colorMap"),
            value = colorMap["atlas-sentiment-positive"];
        return [value.light, value.base, value.dark];
    }),
    negativeGradientColors: Ember.computed("colorMap", function () {
        var colorMap = chartColors.get("colorMap"),
            value = colorMap["atlas-sentiment-negative"];
        return [value.light, value.base, value.dark];
    }),
    sentimentGradientD3: function(domain) {
        domain = domain || [-1.0, 0.0, 1.0];
        return d3.scaleLinear()
            .domain(domain)
            .range(chartColors.get("sentimentGradientCSSColors"));
    },
    sentimentOf100GradientD3: function() {
        return this.sentimentGradientD3([-100.0, 0.0, 100.0]);
    },
    sentimentZeroToOneGradientD3: function() {
        return chartColors.sentimentGradientD3([0.0, 0.5, 1.0]);
    },
    percentChangeGradientCSSColors: Ember.computed("colorMap", function () {
        var colorMap = chartColors.get("colorMap");
        return [
                colorMap["atlas-sentiment-negative"].dark,
                colorMap["atlas-sentiment-negative"].base,
                colorMap["atlas-sentiment-mixed"].base,
                colorMap["atlas-sentiment-positive"].base,
                colorMap["atlas-sentiment-positive"].dark,
            ];
    }),

    percentChangeGradientD3: function() {
        return d3.scaleLinear()
            .domain([Number.MIN_SAFE_INTEGER, -100, 0, 100, Number.MAX_SAFE_INTEGER])
            .range(chartColors.get("percentChangeGradientCSSColors"));
    },
    ordinalColorsD3Named: function(fieldName) {
        fieldName = fieldName || "className";
        var colorOptions = (this.indexedColors || []).mapBy(fieldName);
        return d3.scaleOrdinal()
            .domain(d3.range(colorOptions.length))
            .range(colorOptions);
    },
    ordinalColorsD3: function() {
        return this.ordinalColorsD3Named("base");
    },
    ordinalColorsD3Light: function() {
        return this.ordinalColorsD3Named("light");
    },
    ordinalColorsD3All: function(){
        let mappedColors = this.indexedColors.reduce((all, {base,dark,light}) => {
            all.base.push(base);
            all.dark.push(dark);
            all.light.push(light);
            return all
        },{base:[], dark:[], light: []})
        let range = [].concat.apply(mappedColors.base, mappedColors.light, mappedColors.dark);
        return d3.scaleOrdinal().domain(d3.range(range.length)).range(range)
    },
    colorsForIndex: function(index) {
        var indexedColors = this.indexedColors || [];
        index = (parseInt(index, 10) || 0) % indexedColors.length;
        return indexedColors[index];
    }
});

chartColors = ChartColors.create();
chartColors.loadColorDefinitions(DefaultColorDefinitions);

export default chartColors;
