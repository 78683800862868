import Ember from "ember";
import AtlasConfig from "infegy-frontend/config/infegy-app-config";
import Query from "infegy-frontend/models/users/query";
import { inject as service } from '@ember/service';
import AtlasAuth from "infegy-frontend/utils/atlas-auth";

export default Ember.Controller.extend({
    application: Ember.inject.controller(),
    atlasAuth: AtlasAuth,
    notLoggedIn: Ember.computed.equal("atlasAuth.isLoggedIn",false),
    user: Ember.computed.alias("application.user"),
    router: service(),
    currentPath: Ember.computed.readOnly("router.currentRouteName"),
    currentYear: Ember.computed.alias('application.currentYear'),
    applicationLogoName: null,

    maxQueries: Ember.computed.alias("user.permissions.maxQueries"),

    currentVersion: null,
    versionOutdated: false,

    adminMenuOptions: [{
        link: "atlas.admin.biglist",
        label: "Biglist Editor"
    }, {
        link: "atlas.admin.linkadder",
        label: "Link Adder"
    }, {
        link: "atlas.admin.demos",
        label: "Demos"
    }, {
        link: "atlas.admin.designguide",
        label: "Design Reference"
    }],

    helpOptions: Ember.computed( function(){
        // Necessary to be computed to provide context for the support request action
        return [{
            link: "https://help.infegy.com/",
            label: "Documentation",
            type: "url",
            iconClass: "ii-file-document-box-outline"
        },{
            label: "Support Request",
            link: "mailto:support@infegy.com",
            customID: "atlas_support_request",
            type: "mail",
            iconClass: "ii-lifebuoy"
        }];
    }),

    accountOptions: Ember.computed("user.permissions.customDatasetsEnabled", "user.permissions.maxQueries", function () {
        var customDatasetsEnabled = this.get("user.permissions.customDatasetsEnabled"),
            dataProcessorEnabled = this.get("user.permissions.dataProcessorEnabled"),
            maxQueries = this.get("user.permissions.maxQueries"),
            options = [{
                link: "atlas.user-preferences",
                label: "Account Preferences",
                iconClass: "ii-settings",
            }, {
                link: "atlas.tools.dictionaries",
                label: "My Dictionaries",
                iconClass: "ii-book-open",
            }, {
                link: "atlas.tools.alerts",
                label: "My Query Alerts",
                iconClass: "ii-email-alert",
            }, {
                link: "atlas.tools.query-sets",
                label: "My Subquery Sets",
                iconClass: "ii-file-tree"
            }, {
                link: "atlas.tools.snippets",
                label: "My Query Snippets",
                iconClass: "ii-format-quote-close"
            }, {
                link: "atlas.tools.filtersets",
                label: "My Filter Sets",
                iconClass: "ii-filter"
            }
          ];

        if (maxQueries > 1) {
            options.push({
                link: "atlas.tools.workspace-management.list",
                label: "My Workspaces",
                iconClass: "ii-card-bulleted-outline",
            });
        }

        if (customDatasetsEnabled) {
            options.push({
                link: "atlas.tools.custom-datasets",
                label: "My Datasets",
                iconClass: "ii-database",
            });
        }

        if (dataProcessorEnabled) {
            options.push({
                link: "atlas.tools.dataprocessor",
                label: "Data Processor",
                iconClass: "ii-file-download-outline",
            });
        }

        options.push({
            label: "Logout",
            type: "action",
            iconClass: "ii-logout-variant",
            action: () => { this.send("logout") }
        })

        return options;
    }),
    userOptions: null,

    isAdmin: Ember.computed.alias('user.isAdmin'),
    savedQueries: Ember.computed.alias("user.savedQueries"),
    lastDashboardPage: AtlasConfig.defaultDashboardPage,

    lastQueryIndex: 0,

    resetActiveQueries: function () {
        this.set("activeQueries", [Query.create()]);
    },
    isDisplayingSupportRequest: false,
    actions: {
        displaySupportRequest: function () {
            this.toggleProperty('isDisplayingSupportRequest');
        },
        redirectToLogin(){
            window.location = "/login#previousLocation=" + window.location.pathname;
        }
    },

    intercomOptions: Ember.computed("user.name", function () {
      if (this.user?.name) {
        window.intercomSettings = {
          api_base: "https://api-iam.intercom.io",
          app_id: "tr9asm4d",
          name: this.user.name,
          email: this.user.email,
          user_hash: this.user.intercomUser,
          custom_launcher_selector: '#atlas_support_request',
          created_at: (new Date(this.user.created)).getTime() / 1000
        };
        return true;
      }

    })
});
