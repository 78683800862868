import Ember from "ember";

export default Ember.Object.extend({
    type: "unknown",

    // Optional: run to get meta data used by row compute
    preCompute: function(data) {
        return data;
    },
    // Run on each row,
    rowCompute: function(row, preComputeResults) {
        return row;
    },
    statsCompute: function(stats, data) {
        return stats;
    },
    addFieldsTo: function(fields) {
        return fields;
    },
});
