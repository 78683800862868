import Ember from "ember";
import Component from '@ember/component';
import {computed, action} from "@ember/object";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
// TODO : this could be WAY better using JS downloads and data urls
//     Would allow for better debuging, actual status and progress indicators,
//     and feedback to the server response. Allow passing a dataURL to allow
//     for client-generated downloads.
//
// TODO : extract generic download button component, pass in bound url instead
//     of computing it here - making this reusable for any download needs
//     such as reporting/dashboards, images, etc.
export default class extends Component {
    query = null;
    queries = null;
    format = "json";
    apiName = "volume";
    useQueryTitle = true;

    @computed.empty("href") isDisabled;

    apiOptionValues = {};

    @computed("apiName", "query", "query.title", "useQueryTitle")
    get filename() {
        let filename = this.apiName || "download";

        if (this.useQueryTitle && this.query?.title) {
            let queryTitle = this.query.title;
            queryTitle = queryTitle.replace(/[^\w\- ]+/gi, '').replace(/\s+/gi, " ");
            filename = `${queryTitle} - ${filename}`;
        }
        return filename;
    }

    click(e) {
        if (!this.isDisabled) {
            this.set('isDownloaded', true);
            Ember.run.later(this, function() {
                if (this && !this.isDestroyed && !this.isDestroying) {
                    this.set('isDownloaded', false);
                }
            }, 6000);
            return true;
        }
    }

    @computed("query", "apiName", "filename", "format", "apiOptionValues")
    get href() {
        let query = this.query,
            apiOptionValues = this.apiOptionValues,
            filename = this.filename || this.apiName,
            apiKey = Ember.get(AtlasAuth,"user.api_key") || "";

        if (query) {
            var requestURL = query.toAPIRequestURL(
                    this.apiName || "volume", {
                        format: this.format,
                        additionalAPIParameters: apiOptionValues
                    });
            return `${requestURL}&api_key=${apiKey}&download=true&filename=${filename}`;
        }
    }
}
