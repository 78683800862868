import Ember from "ember";

import JsonStore from "infegy-frontend/json-store/model";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import SavedQuery from "infegy-frontend/models/saved_queries/saved_query";
import CollectionStore from "infegy-frontend/json-store/collection/model";

export default JsonStore.extend({
  list: [],

  // NOTE: This endpoint does not return builder queries.
  fetchQueryHistory: function (userId) {
    var dfd = AtlasAuth.Get({
      url: "api/v3/user_queries.json",
      data: {
        user_id: userId,
      },
    });

    dfd.catch((error) => {
      console.error("Could not load user query history", error);
    });

    dfd.then((data) => {
      if ("output" in data) {
        let queries = CollectionStore.create();
        queries.set("itemClass", SavedQuery);
        queries.loadJSON(data.output);
        this.set("list", queries.content);
      }
    });
  },
});
