import Ember from "ember";
import LinguisticsLanguages from "infegy-frontend/static_data/linguistics-languages";

export default Ember.Component.extend({
    tagName: "tr",
    classNameBindings: [":data-table-row"],
    dictionaryEntries: null,
    entry: null,
    selectEntry: null,

    isEditing: false,
    editingEntry: null,
    deleteEntry: null,
    creatingTheme: null,

    allowEditing: false,

    selectedEntries: [],

    entryIds: Ember.computed("entry", function() {
        return [this.entry.id];
    }),

    isChecked: Ember.computed("selectedEntries", "selectedEntries.length", function() {
        const selectedEntries = this.get("selectedEntries");
        const entry = this.get("entry");
        return selectedEntries.includes(entry.id);
    }),

    displayLanguage: Ember.computed("entry.language", function() {
        var foundLanguage = LinguisticsLanguages.findBy("value", this.get("entry.language"));
        return (foundLanguage && foundLanguage.label) || this.get("entry.language");
    }),

    actions: {
        showEditEntry() {
            this.setProperties({
                editingEntry: Ember.assign({}, this.entry),
                isEditing: true
            });
        },
        updated() {
            this.set("isEditing", false);
        }
    }

});