import Ember from "ember";
export default Ember.Component.extend({
    tagName: "tr",
    classNameBindings: [":data-table-row"],

    query: null,
    influencersRaw: Ember.computed.alias('query.influencers.rawResults'),

    avgInfluence: Ember.computed("query", "query.influencers", "query.influencers.isLoaded", "influencersRaw", {
        get: function(key) {
            var influencersRaw = this.influencersRaw || [];

            var influenceArray = influencersRaw.map((item, index) => {
                return item.influence;
            });

            var totalInfluence = influenceArray.reduce((a, b) => a + b, 0);


            return totalInfluence / influencersRaw.length;
        },
    }),

    avgPosts: Ember.computed("query", "query.influencers", "query.influencers.isLoaded", "influencersRaw", {
        get: function(key) {
            var influencersRaw = this.influencersRaw;

            var influenceArray = influencersRaw.map((item, index) => {
                return item.matches;
            });

            var totalPosts = influenceArray.reduce((a, b) => a + b, 0);

            return totalPosts / influencersRaw.length;
        },
    }),
});
