import Ember from "ember";
import Component from '@ember/component';
import { computed } from '@ember/object';
import FieldSelectorBase from "infegy-frontend/components/field-selector/parts/fields/trendable-field-base/component";
import layout from "infegy-frontend/components/field-selector/parts/fields/trendable-selector-layout/template";

export default class FieldSelectorEmotions extends FieldSelectorBase {
    layout = layout;
    apiName = "emotions";

    availableEmotions = ["anticipation", "disgust", "joy", "anger", "sadness", "surprise", "fear", "trust", "love", "hate"];

    makeEmotionsFields(baseFields, suffix, axisGroup) {
        let fields = baseFields.map(baseField => {
            return this.makeFieldDefinition({
                title: baseField,
                fieldName: baseField + suffix,
                axisGroup: axisGroup
            });
        });
        return fields;
    };

    @computed("availableEmotions", "allQueries", "allQueries.[]", "allQueries.@each.emotions", "allQueries.@each.updated")
    get sortedEmotions() {
        // Sort By Documents - Ensure most important ones are first.
        let allQueries = this.allQueries || [],
            emotions = (this.availableEmotions || []).map(emotion => {
                return {
                    emotion: emotion,
                    count: allQueries.reduce(function(memo, query) {
                            var emotionResults = query.get("emotions.stats." + emotion + "Documents.sum") || 0;
                            return memo - emotionResults;
                        }, 0)
                };
            }).sortBy("count");
        return emotions.mapBy("emotion");
    }

    @computed("fieldType", "selectedSegment", "selectedSubsegment", "sortedEmotions")
    get allAvailableFields() {
        let emotions = this.sortedEmotions || this.availableEmotions,
            fields = {
                "DocumentsDistribution": this.makeEmotionsFields(emotions, "DocumentsDistribution", "percentage"),
                "Documents": this.makeEmotionsFields(emotions, "Documents", "EmotionsDocuments"),
                "Appearances": this.makeEmotionsFields(emotions, "Appearances", "EmotionsAppearances"),
            };
        return fields;
    }
};
