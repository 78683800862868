import Component from '@ember/component';
import {computed} from "@ember/object";
import classic from "ember-classic-decorator";

@classic
export default class FieldTitle extends Component {
    tagName = "span";
    attributeBindings = ['style'];

    colorize = true;

    @computed("colorize", "field.color")
    get style() {
        if (this.colorize) {
            return this.field?.color?.textStyle;
        }
    }
};
