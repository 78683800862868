import Component from "@ember/component";
import { action, computed } from "@ember/object";
import { isEmpty, isNone } from "@ember/utils";
import d3 from "d3";

export default class AtlasForceGraph extends Component {
    // Inputs
    linkKey = "relatedTopics";
    colorScaleType = ""; // "linear" || "ordinal"
    colorField = "";
    colorDomain = [];
    colorRange = [];
    darkMode = false;
    displayLinks = true;
    linkHighlightStyle = "clusterId"; // "clusterId" || "relatedTopics"
    sizeField = "";
    keepCenteredOnRebuild = true;
    
    // Internal properties
    canvasElement = null;
    isCentering = false;
    aspectRatio = 2; // 2:1

    // Tracking graph data
    nodeIndexByKey = {};
    activeId = null;
    hoveredId = null;
    selectedId = null;
    
    _isDataDirty = false;
    _data = [];
    get data() {
        return this._data;
    }
    set data(value) {
        if (this._data !== value) {
            this.set("_isDataDirty", true);
            this._data = value;
        }
    }

    activeNodeChanged(id, node) { /* action handler */ }
    hoveredNodeChanged(id, node) { /* action handler */ }
    selectedNodeChanged(id, node) { /* action handler */ }

    init() {
        super.init(...arguments);
        this.addObserver("sizeField", function() {
            window._topicsForceGraph.d3ReheatSimulation();
            this.set("isCentering", true);
        });
        this._onWindowResizeFn = this.onWindowResize.bind(this);
        window.addEventListener("resize", this._onWindowResizeFn);
    }

    willDestroyElement() {
        window.removeEventListener("resize", this._onWindowResizeFn);
    }

    getCachedNode(id) {
        const nodeIndex = this.nodeIndexByKey[id];
        const node = this.graphData.nodes[nodeIndex];
        return node;
    }

    _onWindowResizeFn = null;
    onWindowResize() {
        if (window._topicsForceGraph) {
            const rect = this.canvasElement.getBoundingClientRect();
            window._topicsForceGraph.width(rect.width);
            window._topicsForceGraph.height(rect.width / this.aspectRatio);
            this.set("isCentering", true);
        }
    }

    @computed("selectedId", "hoveredId")
    get activeNodeId() {
        return this.selectedId || this.hoveredId;
    }

    @computed("selectedId", "hoveredId")
    get activeNode() {
        const node = this.getCachedNode(this.activeNodeId);
        return node;
    }
    
    @computed("colorScaleType", "colorDomain", "colorRange")
    get colorScaleFunction() {
        let scale = null;
        if (this.colorScaleType === "linear") {
            scale = d3.scaleLinear().domain(this.colorDomain).range(this.colorRange);
        } else if (this.colorScaleType === "ordinal") {
            scale = d3.scaleOrdinal().domain(this.colorDomain).range(this.colorRange);
        }
        return scale;
    }

    @computed("canvasElement", "data", "sizeField", "colorScaleFunction")
    get graphData() {
        let graphData = {
            nodes: [],
            links: []
        };

        if (!this.canvasElement)
            return graphData;

        graphData.nodes = this.buildNodes();
        graphData.links = this.buildLinks(graphData.nodes);

        return graphData;
    }

    @computed("canvasElement", "graphData", "darkMode")
    get graph() {
        if (!this.canvasElement || isEmpty(this.graphData.nodes))
            return null;

        if (!this._isDataDirty) {
            // We are just changing properties
            window._topicsForceGraph.backgroundColor(this.darkMode ? '#000' : '#fff');
            return window._topicsForceGraph;
        }
        
        if (this.keepCenteredOnRebuild) {
            this.set("isCentering", true);
        }

        if (window._topicsForceGraph) {
            this.setProperties({
                hoveredId: null,
                hoveredNode: null,
                selectedId: null,
                selectedNode: null
            });
            window._topicsForceGraph.graphData({nodes: [], links: []});
            window._topicsForceGraph.autoPauseRedraw(true);
            window._topicsForceGraph.pauseAnimation();
            delete window._topicsForceGraph;
        }

        const rect = this.canvasElement.getBoundingClientRect();
        const graph = ForceGraph().graphData(this.graphData);
        graph.width(rect.width);
        graph.height(rect.width / this.aspectRatio);
        graph.backgroundColor(this.darkMode ? '#000' : '#fff');
        graph.warmupTicks(50);
        graph.autoPauseRedraw(false); // keep redrawing after engine has stopped
        graph.nodeLabel(null);
        graph.d3AlphaDecay(0.004);
        graph.d3Force('charge', d3.forceManyBody().strength(-70));
        graph.d3Force('gravity-x', d3.forceX(rect.width / 2).strength(0.2 / this.aspectRatio));
        graph.d3Force('gravity-y', d3.forceY(rect.width / 2).strength(0.2));

        graph.nodeVal((node) => {
            const cachedNode = this.getCachedNode(node.id);
            return cachedNode.val;
        });

        graph.onEngineStop(() => {
            if (this.isDestroyed || this.isDestroying)
                return;
            this.set("isCentering", false);
            graph.zoomToFit(400);
        });

        graph.onNodeHover(node => {
            this.set("hoveredId", node?.id);
            const hoveredNode = this.getCachedNode(this.hoveredId);
            this.set("hoveredNode", hoveredNode);
            this.hoveredNodeChanged(this.hoveredId, node);
            this.activeNodeChanged(this.activeNodeId, this.activeNode);
        });
        graph.onNodeClick(node => {
            if (node.id === this.selectedId) {
                this.set("selectedId", null);
                this.selectedNodeChanged(this.selectedId, null);
                this.activeNodeChanged(this.activeNodeId, null);
                return;
            }

            this.set("selectedId", node?.id);
            const selectedNode = this.getCachedNode(this.selectedId);
            this.set("selectedNode", selectedNode);
            this.selectedNodeChanged(this.selectedId, node);
            this.activeNodeChanged(this.activeNodeId, this.activeNode);
        });
        graph.onBackgroundClick(() => {
            this.setProperties({
                selectedId: null,
                hoveredId: null
            });
            this.hoveredNodeChanged(null, null);
            this.selectedNodeChanged(null, null);
            this.activeNodeChanged(this.activeNodeId, this.activeNode);
        });
        
        graph.onEngineTick(() => {
            if (this.keepCenteredOnRebuild && this.isCentering) {
                window._topicsForceGraph.zoomToFit(0, 25);
            }
        });

        document.body.onkeydown = function(e) {
            const kc = String.fromCharCode(e.keyCode);
            if(kc === 'C') {
                graph.zoomToFit(400);
            } else if(kc === 'R') {
                graph.d3ReheatSimulation();
            }
        };
    
        graph.nodeCanvasObject(this.renderNodeCanvasObject.bind(this));
        graph.linkCanvasObject(this.renderLinkCanvasObject.bind(this));
        graph.onRenderFramePost(this.renderFramePost.bind(this));

        graph(this.canvasElement);
        window._topicsForceGraph = graph;
        this.set("_isDataDirty", false);

        setTimeout(() => {
            graph.zoomToFit(400, 25);
        }, 100);

        return window._topicsForceGraph;
    }

    buildNodes() {
        let nodes = [];
        this.nodeIndexByKey = {};

        let scoreMax = 0.001;
        for (const item of this.data) {
            if (item[this.sizeField] > scoreMax)
                scoreMax = item[this.sizeField];
        }

        for (const item of this.data) {
            // Create a mapping for the current item.
            this.nodeIndexByKey[item.key] = nodes.length;

            // Create a node for the current item.
            const node = {
                id: item.key,
                name: item.name,
                val: 5 + ((item[this.sizeField]/scoreMax) * 10),
                score: item.score,
                linkCount: item[this.linkKey].reduce((memo, link) => memo + link.score, 0),
                clusterId: item.clusterId,
                alwaysLabel: false,
                fillColor: this.colorScaleFunction(item[this.colorField])
            };
            
            nodes.push(node);
        }

        // Build temporary clusters to set node visibility by cluster
        let clusters = [];

        for (const node of nodes) {
            if (typeof node.clusterId !== "undefined") {
                while(clusters.length <= node.clusterId) {
                    clusters.push([]);
                }
                clusters[node.clusterId].push(node);
            }
        }

        for (const cluster of clusters) {
            if(cluster.length < 2)
                continue;
            
            const scoreMax = cluster.reduce((memo, node) => Math.max(memo, node.score), 0);

            cluster.sort((a, b) => {
                return a.linkCount * Math.log(a.score) < b.linkCount * Math.log(b.score);
            });

            const targetLabelCount = Math.min(Math.max(Math.ceil(cluster.length / 6), 1), 3);

            for(let i=0; i<targetLabelCount; i++) {
                cluster[i].alwaysLabel = true;
            }

            // Ensure highest-scoring node in the cluster is labeled
            for(let i=0; i<cluster.length; i++) {
                if(cluster[i].score * 1.000001 >= scoreMax) {
                    cluster[i].alwaysLabel = true;
                }
            }
        }

        return nodes;
    }

    buildLinks(nodes) {
        let links = [];
        // Used as a lookup to prevent duplicate links
        let linkIndex = {};

        // Build links
        for (const item of this.data) {
            for (const relatedItem of item[this.linkKey]) {
                if (relatedItem.key in this.nodeIndexByKey) {
                    const uniqueId = `${item.key}-${relatedItem.key}`;
                    if (linkIndex[uniqueId]) {
                        continue;
                    }

                    let link = {
                        source: item.key,
                        target: relatedItem.key,
                        score: relatedItem.score,
                        matchingDocuments: relatedItem.matchingDocuments,
                        distance: 30 * relatedItem.score,
                    };
                    linkIndex[uniqueId] = 1;
                    links.push(link);
                }
            }
        }

        for (const link of links) {
            const a = nodes[this.nodeIndexByKey[link.source]];
            const b = nodes[this.nodeIndexByKey[link.target]];
            if (!a.neighbors)
                a.neighbors = [];
            if (!b.neighbors)
                b.neighbors = [];
            a.neighbors.push(b);
            b.neighbors.push(a);

            if (!a.links)
                a.links = [];
            if (!b.links)
                b.links = [];
            a.links.push(link);
            b.links.push(link);
        }

        return links;
    }

    isNodeHighlighted(node) {
        // If a node has been interactive with we need to make sure only the correct related or 
        // clustered nodes are also highlighted.
        if (this.activeNode) {
            if (node.id === this.selectedId || node.id === this.hoveredId) {
                return true;
            }

            const clickedNode = this.getCachedNode(this.selectedId);
            const hoveredNode = this.getCachedNode(this.hoveredId);

            if (this.linkHighlightStyle === "clusterId") {
                if (isNone(node.clusterId)) {
                    return node.id === this.selectedId || node.id === this.hoveredId;
                }
                return node.clusterId === clickedNode?.clusterId || node.clusterId === hoveredNode?.clusterId;
            } else if (this.linkHighlightStyle === "relatedTopics") {
                const allLinks = (clickedNode?.links || []).concat(hoveredNode?.links || []);
                const found = allLinks.find((link) => {
                    return (link.source?.id || link.source) === node.id || (link.target?.id || link.target) === node.id;
                });
                return !!found;
            }
        }
        return true;
    }
    
    renderNodeCanvasObject(node, ctx, globalScale) {
        const isHighlighted = this.isNodeHighlighted(node);

        // Lookup the data associated with this node.
        const alpha = isHighlighted ? 1.0 : 0.25;
        const n = this.getCachedNode(node.id);
        // Cache some values on the node so that they can be reused for labels and links.
        n.isHighlighted = isHighlighted;
        n.alpha = alpha;
        n.x = node.x;
        n.y = node.y;

        if (node.id === this.hoveredId && node.id === this.selectedId)
            return;

        ctx.beginPath();
        ctx.globalCompositeOperation = 'source-over';
        ctx.globalAlpha = n.alpha;
        ctx.fillStyle = n.fillColor;
        // Draw the node ...
        ctx.arc(node.x, node.y, n.val, 0, 2 * Math.PI);
        ctx.fill();
    }

    renderLinkCanvasObject(link, ctx, globalScale) {
        if (!this.displayLinks)
            return;

        ctx.globalCompositeOperation = 'source-over';

        try {
            var grad = ctx.createLinearGradient(link.source.x, link.source.y, link.target.x, link.target.y);
        } catch (err) {
            return;
        }

        // Lookup the data associated with this link.
        const sourceNode = this.getCachedNode(link.source.id);
        const targetNode = this.getCachedNode(link.target.id);

        const sourceRGB = sourceNode.fillColor.replace(/[^\d,]/g, '').split(',');
        const sourceRGBA = `rgba(${sourceRGB[0]}, ${sourceRGB[1]}, ${sourceRGB[2]}, ${link.source.alpha})`;
        grad.addColorStop(0, sourceRGBA);
        const targetRGB = targetNode.fillColor.replace(/[^\d,]/g, '').split(',');
        const targetRGBA = `rgba(${targetRGB[0]}, ${targetRGB[1]}, ${targetRGB[2]}, ${link.target.alpha})`;
        grad.addColorStop(1, targetRGBA);

        ctx.beginPath();
        ctx.globalAlpha = 1; // leave alpha to the gradient
        ctx.strokeStyle = grad;
        ctx.lineWidth = 2/globalScale;
        ctx.moveTo(link.source.x, link.source.y);
        ctx.lineTo(link.target.x, link.target.y);
        ctx.stroke();
    }

    // CanvasRenderingContext2D
    renderNodeLabel(ctx, globalScale, node) {
        // Calculate values for labeling the node
        const label = node.name;
        const fontSize = 12/globalScale;
        ctx.font = `${fontSize}px Sans-Serif`;
        const textWidth = ctx.measureText(label).width;
        const padding = 0.3;
        const bckgDimensions = [textWidth, fontSize].map(n => n + fontSize * padding); // some padding
        const borderDimensions = [textWidth, fontSize].map(n => n + fontSize * padding * 2); // some padding

        ctx.beginPath();
        ctx.globalCompositeOperation = 'source-over';
        ctx.globalAlpha = node.alpha;
        ctx.fillStyle = node.fillColor;
        // Draw the label border (padding) ...
        ctx.moveTo(node.x - borderDimensions[0] / 2, node.y - borderDimensions[1] / 2);
        ctx.rect(node.x - borderDimensions[0] / 2, node.y - borderDimensions[1] / 2, borderDimensions[0], borderDimensions[1]);
        // Draw the rounded ends of the label border (padding) ...
        ctx.moveTo(node.x - borderDimensions[0] / 2, node.y);
        ctx.arc(node.x - borderDimensions[0] / 2, node.y, borderDimensions[1] / 2, 0, 2 * Math.PI);
        ctx.moveTo(node.x + borderDimensions[0] / 2, node.y);
        ctx.arc(node.x + borderDimensions[0] / 2, node.y, borderDimensions[1] / 2, 0, 2 * Math.PI);
        ctx.fill();

        if (node.isHighlighted) {
            // Skip drawing the white background box if the node is not highlighted.  This is because the transparency with the white background doesn't look good.
            ctx.beginPath();
            ctx.fillStyle = "rgba(255, 255, 255, 1";
            // Draw the label background ...
            ctx.rect(node.x - bckgDimensions[0] / 2, node.y - bckgDimensions[1] / 2, bckgDimensions[0], bckgDimensions[1]);
            // Draw the rounded ends of the label background ...
            ctx.moveTo(node.x - bckgDimensions[0] / 2, node.y);
            ctx.arc(node.x - bckgDimensions[0] / 2, node.y, bckgDimensions[1] / 2, 0, 2 * Math.PI);
            ctx.moveTo(node.x + bckgDimensions[0] / 2, node.y);
            ctx.arc(node.x + bckgDimensions[0] / 2, node.y, bckgDimensions[1] / 2, 0, 2 * Math.PI);
            ctx.fill();
        }

        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = 'black';
        ctx.fillText(label, node.x, node.y);
    }

    renderFramePost(ctx, globalScale) {
        let priorityNodes = [];
        // Draw labels on top of each node.
        for (const node of this.graphData.nodes) {
            if (node.id === this.hoveredId) {
                priorityNodes.push(node);
                continue;
            }
            if (node.id === this.selectedId) {
                priorityNodes.unshift(node);
                continue;
            }
            this.renderNodeLabel(ctx, globalScale, node);
        }
        for (const node of priorityNodes) {
            const n = this.getCachedNode(node.id);

            if (node.id === this.selectedId) {
                // render the node highlight
                ctx.beginPath();
                ctx.globalCompositeOperation = 'source-over';
                ctx.globalAlpha = 0.6;
                ctx.fillStyle = this.darkMode ? "rgb(255, 255, 255)" : "rgb(0, 0, 0)";
                ctx.arc(node.x, node.y, n.val + 2, 0, 2 * Math.PI);

                // render the label highlight
                const label = node.name;
                const fontSize = 12/globalScale;
                ctx.font = `${fontSize}px Sans-Serif`;
                const textWidth = ctx.measureText(label).width;
                const padding = 0.3;
                const borderDimensions = [textWidth, fontSize].map(n => n + fontSize * padding * 2); // some padding

                ctx.rect(node.x - borderDimensions[0] / 2, node.y - 2 - borderDimensions[1] / 2, borderDimensions[0], borderDimensions[1] + 4);
                ctx.moveTo(node.x - borderDimensions[0] / 2, node.y);
                ctx.arc(node.x - borderDimensions[0] / 2, node.y, borderDimensions[1] / 2 + 2, 0, 2 * Math.PI);
                ctx.moveTo(node.x + borderDimensions[0] / 2, node.y);
                ctx.arc(node.x + borderDimensions[0] / 2, node.y, borderDimensions[1] / 2 + 2, 0, 2 * Math.PI);
                ctx.fill();
            }

            // render the node
            ctx.beginPath();
            ctx.globalCompositeOperation = 'source-over';
            ctx.globalAlpha = n.alpha;
            ctx.fillStyle = n.fillColor;
            ctx.arc(node.x, node.y, n.val, 0, 2 * Math.PI);
            ctx.fill();

            // render the label
            this.renderNodeLabel(ctx, globalScale, node);
        }
    }

    @action
    canvasCreated(element) {
        this.set("canvasElement", element);
    }

    @action
    zoomFit() {
        this.set("isCentering", false);
        this.graph.zoomToFit(400);
    }

    @action
    zoomIn() {
        this.set("isCentering", false);
        let currentZoom = this.graph.zoom();
        this.graph.zoom(Math.max(currentZoom / 1.5, this.graph.minZoom()), 400);
    }

    @action
    zoomOut() {
        this.set("isCentering", false);
        let currentZoom = this.graph.zoom();
        this.graph.zoom(Math.min(currentZoom * 1.5, this.graph.maxZoom()), 400);
    }
}