import Route from "@ember/routing/route";

import AdminUsers from "infegy-frontend/models/admin/admin_users";

export default class WorkspacesListRoute extends Route {
    title = "Workspaces";
    setupController(controller, model) {
        controller.workspaces.getPage();
        controller.shared.getPage();
        const adminUsers = AdminUsers.create();
        controller.set("adminUsers", adminUsers);
    }
}
