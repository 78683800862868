import Ember from "ember";
import JsonStore from "infegy-frontend/json-store/model";
import Prop from "infegy-frontend/json-store/properties/model";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";

export default JsonStore.extend({
    id: Prop.Int(),
    userIdFrom: Prop.Int(),
    userIdTo: Prop.Int(),
    nameTo: Prop.String(),
    userNameTo: Prop.String(),
    companyIdTo: Prop.Int(),
    created: Prop.String(),
    // deleted: Prop.Int(), // not sent
    resourceType: Prop.String(),
    resourceId: Prop.Int(),

    url: "api/v3/shared-resource",

    saveUserShare(resourceType, resourceId, userId) {
        return AtlasAuth.Post({
            data: {
                resource_id: resourceId,
                type: resourceType,
                user_id_to: userId
            },
            url: this.url
        }).then((response) => {
            this.loadJSON(response.output);
        });
    },

    saveCompanyShare(resourceType, resourceId, companyId) {
        return AtlasAuth.Post({
            data: {
                resource_id: resourceId,
                type: resourceType,
                company_id_to: companyId
            },
            url: this.url
        }).then((response) => {
            this.loadJSON(response.output);
        });
    },

    delete() {
        return AtlasAuth.Delete({
            url: `${this.url}?id=${this.id}`
        });
    }
});
