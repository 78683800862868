
import Ember from 'ember';
import { dictionarySentimentOptions } from "infegy-frontend/static_data/sentiment";
import ChartColors from 'infegy-frontend/static_data/fixtures/chart_colors';

export function sentimentLabel(params, namedArgs) {
    var positivityPercent = params[0] || namedArgs.positivity,
        colorValues = ChartColors.sentimentZeroToOneGradientD3();

  return colorValues(positivityPercent);
}

export default Ember.Helper.helper(sentimentLabel);
