import Ember from "ember";
import { computed, action } from '@ember/object';
import SentimentCombinedBase from "infegy-frontend/components/sentiment/combined-base/component";

export default class SentimentComparisonTableRow extends SentimentCombinedBase {
    tagName = "tr";
    classNameBindings = [":data-table-row", "isEven:data-table-row-even"];

    query = null;
    sentimentField = "documents";
    viewField = "DocumentsDistribution";

    hoveredIndex = null;

    maxNumericalValues = null;

    @computed("activeFields", "query.sentiment.dataSeries.stats")
    get activeValues() {
        let stats = this.get("query.sentiment.dataSeries.stats");

        return {
            positive: stats.get(`${this.activeFields.positive}.sum`) || 0,
            negative: stats.get(`${this.activeFields.negative}.sum`) || 0,
            neutral: stats.get(`${this.activeFields.neutral}.sum`) || 0,
        };
    };

    @computed("activeValues", "maxNumericalValues")
    get barWidths() {
        let maxes = this.maxNumericalValues,
            portions = this.activeValues,
            values = {
                positive: ((portions.positive / maxes.positive) * 100.0).toFixed(0),
                negative: ((portions.negative / maxes.negative) * 100.0).toFixed(0),
                neutral: ((portions.neutral / maxes.neutral) * 100.0).toFixed(0),
            };
        return {
            positive: `width:${values.positive}%`.htmlSafe(),
            negative: `width:${values.negative}%`.htmlSafe(),
            neutral: `width:${values.neutral}%`.htmlSafe(),
        };
    };

    querySelected() { /* action placeholder */ };

    click() {
        this.querySelected(this.query);
    };
};
