import Ember from 'ember';

export function formatDate(params, namedArgs) {
    var val = Number(params[0]) || 0,
        max = namedArgs.max || params[1] || 100,
        min = namedArgs.min || -max,
        clamped = Math.min(Math.max(val, min), max),
        prefix = val > max ? ">" : (val < min ? "<" : "");
    return prefix + clamped;
}

export default Ember.Helper.helper(formatDate);
