import Component from '@glimmer/component';
import { run } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isNone } from '@ember/utils';

export default class DeferredRender extends Component {
    constructor(){
        super(...arguments);
        if (!isNone( this.args.delay )){
            this.hasTimer = true;
            run.later(()=>{
                this.canRender = true;
            },this.args.delay)
        }
    }

    @tracked canRender = false;
    hasTimer = false;

    @action
    insertSignal(el){
        run.next(()=>{
            if(!this.hasTimer){
                this.canRender = true;
            }
        });
    }
}