import { isEmpty } from "@ember/utils";
import classic from "ember-classic-decorator";

import Workspace from "infegy-frontend/models/users/workspace2";
import OffsetPagination from "infegy-frontend/utils/offset_pagination";

@classic
export default class Workspaces extends OffsetPagination {
    static SORT_FIELD_OPTIONS = [
        {
            label: "Created Date",
            value: "created_on"
        },
        {
            label: "Recently Updated",
            value: "updated_on"
        },
        {
            label: "Recently Used",
            value: "last_used_on"
        },
        {
            label: "Title",
            value: "title"
        }
    ];

    // base class params
    itemClass = Workspace;
    url = "api/v3/workspaces";

    /**
     * searchType
     * @type {"", "owned", "saved", "shared"}
     */
    searchType = "";

    // additional query parameters
    search = "";
    sortDirection = "DESC";
    sortField = "last_used_on";

    buildQueryParams() {
        let queryParams = {
            sort_field: this.sortField,
            sort_direction: this.sortDirection
        };
        if (!isEmpty(this.searchType)) {
            queryParams.search_type = this.searchType;
        }
        if (!isEmpty(this.search)) {
            queryParams.search = this.search;
        }
        return queryParams;
    }

    updateOptions(options={}) {
        if (!isEmpty(options["search"])) {
            this.search = options["search"];
        } else {
            this.search = "";
        }
        if (!isEmpty(options["sort_direction"]))
            this.sortDirection = options["sort_direction"];
        if (!isEmpty(options["sort_field"]))
            this.sortField = options["sort_field"];
    }

    async getPage(options={}) {
        let queryParams = this.buildQueryParams();
        queryParams = Object.assign(queryParams, options);
        super.getPage(queryParams);
        this.updateOptions(options);
    }

    async getFirstPage() {
        let queryParams = this.buildQueryParams();
        super.getFirstPage(queryParams);
    }

    async getPreviousPage() {
        let queryParams = this.buildQueryParams();
        super.getPreviousPage(queryParams);
    }

    async getNextPage() {
        let queryParams = this.buildQueryParams();
        super.getNextPage(queryParams);
    }

    async getLastPage() {
        let queryParams = this.buildQueryParams();
        super.getLastPage(queryParams);
    }
}