import Ember from "ember";
import Component from '@ember/component';
import {computed, action} from "@ember/object";
import ChartColors from "infegy-frontend/static_data/fixtures/chart_colors";

export default class SubquerySetsTableRow extends Component {
    tagName = "tr";
    classNameBindings = [":data-table-row", ":topics-table-expandable"];

    tag = null;

    hasQueryField = false;

    linguisticsUniverse = 0;

    @computed.lt("tag._sourceTopics.length", 2) hasNoSourceTopics;
    @computed.mapBy("tag.dataSeries.data", "universe") documentsTimeline;

    click() {
        this.tagSelected(this.get("tag"));
    }
    tagSelected() { /* click action */ }

    @computed("tag.dataSeries.stats.universe.percentChange")
    get change() {
        let pct = this.tag?.dataSeries?.stats?.universe?.percentChange || 0;
        return pct.toFixed(0);
    }

    @computed("linguisticsUniverse", "tag.universe")
    get shareOfVoice() {
        let tagUniverse = this.tag.universe,
            linguisticsUniverse = this.linguisticsUniverse,
            portion = (linguisticsUniverse && tagUniverse) ? tagUniverse / linguisticsUniverse : 0;
        return (portion * 100).toPrecision(3);
    }

    @computed("tag.positiveDocumentsDistribution")
    get sentimentPercentage() {
        let pct = (this.tag?.positiveDocumentsDistribution || 0) * 100.0;
        return pct.toFixed(0);
    }

    @computed("tag.positiveDocumentsDistribution")
    get sentimentStyleSafe() {
        let sentiment = this.tag?.positiveDocumentsDistribution || 0.5,
            colorValues = ChartColors.sentimentZeroToOneGradientD3();

        let style = `color:${colorValues(sentiment)};`;
        return new Ember.String.htmlSafe(style);
    }

    @computed("sentimentPercentage")
    get sentimentWidthStyleSafe() {
        let percentage = this.sentimentPercentage || 50;
        return new Ember.String.htmlSafe(`width:${percentage}%;`);
    }

    @computed("tag.femaleDistribution")
    get genderFemalePercentage() {
        let pct = (this.tag?.femaleDistribution || 0) * 100.0;
        return pct.toFixed(0);
    }

    @computed("tag.maleDistribution")
    get genderMalePercentage() {
        let pct = (this.tag?.maleDistribution || 0) * 100.0;
        return pct.toFixed(0);
    }

    @computed("genderFemalePercentage")
    get genderWidthStyleSafe() {
        let percentage = this.genderFemalePercentage || 50;
        return new Ember.String.htmlSafe(`width:${percentage}%;`);
    }
}
