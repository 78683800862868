import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import Ember from "ember";

export default Ember.Controller.extend({
    linkData: [],
    updated: null,
    received: false,

    actions: {
        submitLinks: function (linkData) {
            AtlasAuth.Post({
                url: 'api/v3/crawl_links',
                data: linkData
            }).then((response) => {
                this.set("updated", response.output.urls_added);
                this.set("received", true);
            });
        }
    }

});
