import Ember from 'ember';

export default Ember.Component.extend({
    classNames: ["interests-combined-table"],
    queries: null,

    selectedApi: "interests",
    activeQueryIndex: 0,
    sortByAll: false,
    dataColumn: "distribution",
    sortAscending: false,
    removeInterest: ()=>{},

    isPaginated: true,
    currentPage:0,
    interestsPerPage: 20,

    sortClassSuffix: Ember.computed("sortAscending", function () {
        return this.sortAscending ? "sorted-asc" : "sorted-desc";
    }),

    /** InterestQueries are a list of values for an interest indexed by the query that returned the value
     * An individual interest will contain values at each query index or that query index will be
     * undefined indicating the query did not return a value for this interest
     */
    allInterestQueries: Ember.computed("queries","queries.@each.interests", "queries.@each.updated", function(){
        var queries = this.queries;

        var allInterestQueries = queries.reduce((interestMap, query, query_idx) => {
            var interests = query.get(`${this.selectedApi}.data`) ?? [];
            interests.forEach(interest => {
                if (!interestMap[interest.name]) {
                    interestMap[interest.name] = {};
                }
                interestMap[interest.name][query_idx] = interest;
            });
            return interestMap;
        },{});
        return Object.values(allInterestQueries);
    }),

    sortedInterestQueries: Ember.computed("allInterestQueries", "activeQueryIndex", "sortAscending", function() {
        
        var sortedInterestQueries = this.allInterestQueries.sort((interestA, interestB) => {
            var interestAData = interestA[this.activeQueryIndex],
                interestBData = interestB[this.activeQueryIndex];
            if (!interestBData)
                return -1;
            return (interestBData && interestBData[this.dataColumn]) - (interestAData && interestAData[this.dataColumn]);
        });

        if(this.sortAscending){
            return sortedInterestQueries.reverse()
        }

        return sortedInterestQueries;
    }),

    displayInterestQueries: Ember.computed("sortedInterestQueries", "interestsPerPage", "currentPage", function(){
        var startIdx = this.interestsPerPage * this.currentPage;
        return this.sortedInterestQueries.slice(startIdx, startIdx + this.interestsPerPage);
    }),

    actions: {
        changeSelectedQuery: function (index) {
            var activeQuery = this.activeQueryIndex;
            if (index === activeQuery) {
                this.toggleProperty("sortAscending");
            } else {
                this.set("activeQueryIndex", index);
                this.set("sortAscending", false);
            }
        },
    }
});
