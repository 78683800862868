import Ember from "ember";
import Component from '@ember/component';
import {computed, action} from "@ember/object";

export default class extends Component {
    classNames = ["entity-query-filterdown"];

    query = null;
    queries = null;

    entity = null;
    isCollated = false;

    makeDrilldownQuery(query, entity) {
        let drilldownQuery = query.copy();
        if (query.queryInfo.isUsingQueryBuilder) {
            drilldownQuery.queryInfo.queryBuilderDetail.andItems.loadJSON({
                type: "entity",
                iconType: entity.entityType,
                label: entity.name,
                value: entity.key
            });
        } else {
            drilldownQuery.queryInfo.loadJSON({
                entityQueryParts: {
                    label: entity.name,
                    value: entity.key,
                    matched: entity.name
                }
            });
        }
        return drilldownQuery;
    }

    @computed("queries", "queries.length", "queries.@each.updated", "query", "query.updated")
    get innerQueries() {
        return this.queries || [this.query];
    }

    @computed("innerQueries", "innerQueries.@each.updated", "entity")
    get matchedQueries() {
        var innerQueries = this.innerQueries,
            entity = this.entity || this.linkedEntity,
            entityQueryIndicies = [];

        if (this.query) {
            return innerQueries;
        }

        if (entity._sourceTopics) {
            entity._sourceTopics.forEach((sourceTopic) => {
                entityQueryIndicies.push(sourceTopic._originalQueryIdx);
            });
        } else if (entity["_originalQueryIdx"]) {
            entityQueryIndicies.push(entity["_originalQueryIdx"]);
        }

        return innerQueries.filter((query, queryIndex) => {
            return entityQueryIndicies.includes(queryIndex);
        });
    }

    @computed("entity", "entity.availableAttributes")
    get keyValuePairs() {
        let entity = this.entity || this.linkedEntity || [],
            keys = entity.availableAttributes.filter(key => key !== "universe") || [];

        let keyValuePairs = keys.map(key => {
            let value = entity[key];

            if (key === "tradedAs") {
                value = value[0][Object.keys(value[0])[0]];
            }

            return {
                key: key,
                value: value
            };
        });

        return keyValuePairs;
    }

    @computed("innerQueries", "innerQueries.@each.updated", "entity", "linkedEntity")
    get drilldownQueries() {
        const entity = this.entity || this.linkedEntity;
        return this.matchedQueries.map((query) => {
            return this.makeDrilldownQuery(query, entity);
        });
    }
}
