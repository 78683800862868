import { action, computed } from "@ember/object";
import BrowserStore from "infegy-frontend/utils/browser-store";
import topicsTypeOptions from "infegy-frontend/models/linguistics/topics-type-options";
import ControllerBase from "infegy-frontend/atlas/dashboard/content/base/controller";

export default class LinguisticsController extends ControllerBase {
    themesController = null;

    apis = ['themes', 'topics'];
    exportedApis = ['themes','topics','positiveTopics','negativeTopics','hashtags','emoji','sourceBioTopics','mentions','domains','links'];

    selectedTheme = null;
    selectedSubTheme = null;
    hiddenTopics = [];
    colorField = "positiveAppearancesDistribution";

    selectedField = "universe";
    selectedFieldOptions = [{label: "Universe", value: "universe"}, {label: "Posts", value: "documents"}];

    get topicsTypeOptions() {
        const results = topicsTypeOptions.filter((option) => {
            const hidden = this.content.availableQueryFields.hasOwnProperty(option.id)
                && this.content.availableQueryFields[option.id] === false;
            return !hidden;
        });
        return results;
    }

    @BrowserStore.Attr("linguistics-topics-active-topic-display", { defaultValue: "topics" }) topicsType;
    @BrowserStore.Attr("linguistics-topics-active-view-type", { defaultValue: "cloud" }) singleTopicsViewType;
    @BrowserStore.Attr("linguistics-topics-active-view-type", { defaultValue: "multi-cloud" }) multiTopicsViewType;
    @BrowserStore.Attr("linguistics-themes-active-view-type", { defaultValue: "arcs" }) themesViewType;
    @BrowserStore.Attr("themes-active-combined", { defaultValue: "true" }) combineThemes;
    @BrowserStore.Attr("topics-cloud-display-count", { defaultValue: 50 }) displayTopicsCount;

    @computed("multiTopicsViewType", "singleTopicsViewType", "queries.length")
    get topicsViewType() {
        return (this.queries?.length === 1) ? this.singleTopicsViewType : this.multiTopicsViewType;
    }

    @computed("topicsViewType", "queries", "queries.[]")
    get innerTopicsViewType() {
        var topicsViewType = this.topicsViewType;
        if (topicsViewType === "multi-cloud" && this.get("queries.length") === 1) {
            return "cloud";
        }
        return topicsViewType;
    }

    @action
    activeTopicDisplayChanged(activeTopicDisplay) {
        this.set("topicsController.activeTopicDisplay", activeTopicDisplay);
    }

    @action
    topicsViewTypeChanged(topicsViewType) {
        if (this.queries?.length > 1) {
            if (this.multiTopicsViewType !== topicsViewType) {
                this.set("multiTopicsViewType", topicsViewType);
            }
        } else {
            if (this.singleTopicsViewType !== topicsViewType) {
                this.set("singleTopicsViewType", topicsViewType);
            }
        }
    }

    @action
    themesViewTypeChanged(viewType) {
        if (this.themesViewType !== viewType) {
            this.set("themesViewType", viewType);
            this.set("selectedTheme", null);
        }
    }

    @action
    themeSelected(selectedTheme) {
        var currentValue = this.selectedTheme;
        if (currentValue !== selectedTheme) {
            this.set("selectedTheme", selectedTheme);
        } else if (currentValue !== null) {
            this.set("selectedTheme", null);
        }
        this.set("selectedSubTheme", null);
    }

    @action
    subThemeSelected(value) {
        this.set("selectedSubTheme", value);
    }

    @action
    combineThemesChanged(value) {
        this.set("combineThemes", value);
    }

    @action
    topicsTypeChanged(topicsType) {
        if (this.topicsType !== topicsType) {
            this.set("topicsType", topicsType);

            if (topicsType === "links" || topicsType === "domains") {
                this.set("singleTopicsViewType", "table");
            }
        }
    }

    @action
    replaceTopic(topic_obj){
        var queries = this.queries;
        queries.forEach((query) => {
            var topics = Ember.get(query,`${topic_obj._api}.dataSeries.data`);
            topics.pushObject(topic_obj);
            this.hiddenTopics.forEach((hidden_topic_obj, index) => {
                if (hidden_topic_obj.topic === topic_obj.topic) {
                    this.hiddenTopics.removeAt(index,1);
                }
            });
            query.incrementProperty("updated");
        });
    }

    @action
    removeTopic(topic, api){
        var queries = this.queries;
        queries.forEach((query) => {
            var topics = Ember.get(query,`${api}.dataSeries.data`);
            topics.forEach((topic_obj, index) => {
                if (topic_obj.topic === topic) {
                    topic_obj._api = api;
                    this.hiddenTopics.pushObject(topic_obj);
                    topics.removeAt(index,1);
                }
            });
            query.incrementProperty("updated");
        });
    }

    @action
    selectedFieldChanged(field) {
        if (this.selectedField !== field) {
            this.set("selectedField", field);
        }
    }
}
