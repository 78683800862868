var AllOptions = [{
    label: "Positive",
    value: "positive"
},{
    label: "Negative",
    value: "negative"
},{
    label: "Neutral",
    value: "neutral"
}];

var dictionarySentimentOptions = [{
        label: "Very Negative",
        value: -3
    },{
        label: "Negative",
        value: -2
    },{
        label: "Slightly Negative",
        value: -1
    },{
        label: "Neutral",
        value: -0
    },{
        label: "Slightly Positive",
        value: 1
    },{
        label: "Positive",
        value: 2
    },{
        label: "Very Positive",
        value: 3
    }];

export { dictionarySentimentOptions };
export default AllOptions;
