import Ember from "ember";
import JsonStore from 'infegy-frontend/json-store/model';
import Prop from 'infegy-frontend/json-store/properties/model';
import ChartColors from "infegy-frontend/utils/atlas-colors";
import QueryColor from "infegy-frontend/models/queries/query_color";

export default JsonStore.extend({
    //Required
    segment: Prop.String(),
    subSegment: Prop.String(),

    apiName: Prop.String(),
    fieldName: Prop.String(),

    queryIndex: Prop.Number(),

    color: Prop.Object(QueryColor),
    colorClass: Prop.String(),

    colorValues: Ember.computed("colorClass", "color.isEmpty", function () {
        var colorClass = this.colorClass || "atlas-chart-1";
        return this.get("color.isEmpty") ? ChartColors.findColorsByClassName(colorClass) : this.color;
    }),

    colorValue: Ember.computed.alias("colorValues.base"),
    lightColorValue: Ember.computed.alias("colorValues.light"),
    darkColorValue: Ember.computed.alias("colorValues.dark"),

    // Can be computed from above
    title: Prop.String(),
    units: Prop.String(),
    axisGroup: Prop.String(),

    //Optional
    suggestedColorClass: Prop.String(),
    fixedMaxY: Prop.Number(),
    rangelessPercentage: Prop.String(),

    // For use in selectors
    value: Prop.String(),

    query: null,
    queries: null,

    originalQueryApi: Ember.computed("query", "apiName", function () {
        return this.get("query." + this.apiName);
    }),

    updated: Ember.computed.alias("query.updated"),
});
