import Ember from "ember";
import { action } from '@ember/object';
import { computed } from '@ember/object';
import QueryURL from "infegy-frontend/models/queries/query_url";
import SingleRequestQuery from "infegy-frontend/models/queries/single_request_query";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import DateProcessing from "infegy-frontend/utils/date-processing";
import Query from "infegy-frontend/models/users/query";
import GlobalFilters from 'infegy-frontend/models/queries/query_global_filters';

export default class InAppQueryEditor extends Ember.Component {
    classNames = ["in-app-query-editor"];

    constructor(){
        super(...arguments);
        this.pollQueries();
    }

    atlasAuth = AtlasAuth;
    @computed.oneWay("atlasAuth.user") user;
    @computed.alias("user.audienceSegments") audienceSegments;
    @computed.alias("user.dictionaries") dictionaries;
    @computed.alias("user.customDatasets") customDatasets;
    @computed.alias("selectedQueryIndices.firstObject") activeQueryIndex;

    queries = [];
    @computed.readOnly("queries.firstObject") firstQuery;
    savedQueries = null;
    showFilters = false;
    showGlobalFilters = false;

    @Ember.computed("queries.[]")
    get queriesCache(){
        return QueryURL.queryArrayToUrl(this.queries);
    }
    lastRunCache = null;
    lastMultiSelectQueries = null;
    lastSingleQuery =  null;
    selectedQueryIndices = [0];

    showQueryEditor = false;
    showFilterEditor = false;

    @computed("activeQuery.queryInfo.queryType")
    get hasNoQueryType() {
        const queryType = Ember.get(this, "activeQuery.queryInfo.queryType");
        // Some queries were saved before this bug fix with a `queryType` of "boolean" which needs to be accounted for here
        return (queryType !== "builder" && queryType !== "advanced" && queryType !== "boolean");
    }

    @computed.or("showQueryEditor", "showFilterEditor") showEditor;

    @computed("queries.[]", "activeQueryIndex")
    get activeQuery(){
        var queries = this.queries || [],
            index = this.activeQueryIndex;
        return queries[index];
    }

    @computed("queries.[]")
    get hasAudienceQueries(){
        return (this.queries ?? []).find(query=> Ember.get(query,"queryInfo.queryType") === "audiences");
    }

    @computed("queries","queries.@each.color")
    get multiQueryPreviewItems() {
        var itemWidth = 96 / this.queries.length;
        return this.queries.map((query,idx) => ({
                color: query.get("queryInfo.color"),
                width: itemWidth,
                x: itemWidth * idx
            }));
    }

    @computed("user.permissions.maxQueries","queries.length")
    get canAddQuery() {
        return this.get("queries.length") < this.get("user.permissions.maxQueries");
    }


    @computed("activeQuery.queryInfo.isAudienceQuery", "activeQuery.queryInfo.isUsingQueryBuilder")
    get editorType() {
        var isUsingQueryBuilder = this.get("activeQuery.queryInfo.isUsingQueryBuilder"),
            isAudienceQuery = this.get("activeQuery.queryInfo.isAudienceQuery");

        if (isUsingQueryBuilder) {
            return "builder";
        }

        if (isAudienceQuery) {
            return "audiences";
        }

        return "advanced";
    }

    @computed("selectedQueryIndices", "selectedQueryIndices.[]","selectedQueryIndices.length")
    get multiSelect() {
        return this.get("selectedQueryIndices.length") > 1;
    }

    @computed("activeQuery.earliestTimestamp", "activeQuery.latestTimestamp",
            "user.permissions.historicalAccessTimestamp")
    get availableTimeWindow() {
        return DateProcessing.findAvailableDateRangeForQueries([this.activeQuery], this.user);
    }

    cloneQueries(queries) {
        return queries.map((query) => {
            let newQuery = SingleRequestQuery.create();
            let queryJSON = query.get("queryInfo").toJSON();
            let newQueryInfo = newQuery.get("queryInfo");
            newQueryInfo.loadJSON(queryJSON);
            return newQuery;
        });
    }

    pollQueries () {
        Ember.run.later(() => {
            if (this && !this.isDestroyed && !this.isDestroying) {
                if (this.queries) {
                    var savequeries = this.combineQueries(this.queries);
                    let query_url = QueryURL.queryArrayToUrl(savequeries);
                    this.setProperties({
                        "lastRunCache": query_url,
                    });
                }
                this.pollQueries();
            }
        }, 5000);
    }

    @computed("showEditor", "hasNoQueryType")
    get showEditingLink() {
        if (this.hasNoQueryType)
            return false;
        else
            return !this.showEditor;
    }

    combineQueries(queries){
        let queriesCopy = (queries || []).map((query) => {
            return query.copy();
        });
        return queriesCopy;
    }

    @Ember.computed("lastRunCache", "queriesCache")
    get editorHasChanges(){
        return !(Ember.isEmpty(this.lastRunCache) || (this.lastRunCache === this.queriesCache));
    }

    @computed("selectedQueryIndices","queries","selectedQueryIndices.[]","queries.[]", "multiSelect")
    get queryOptions(){
        let selectedQueries = this.selectedQueryIndices || [0];
        return this.queries.map((query,idx)=> {
                return {
                    isActive: selectedQueries.includes(idx) && !this.multiSelect,
                    query: query,
                    index:idx,
                    label: Ember.get(query,"title"),
                    value: idx,
                    color: Ember.get(query,"queryInfo.color")
                };
            });
    }

    @computed("queries", "queries.[]", "queries.@each.isBlank", "queries.@each.hasErrors",
        "queries.@each.queryString", "user.permissions.socialDataAccess")
    get submitDisabled() {
            var hasSocialAccess = this.get("user.permissions.socialDataAccess"),
                submitDisabled = false;

            this.queries.forEach(query => {
                var hasDatasets = Ember.get(query, "queryInfo.customDatasetId");

                if(query.isBlank || query.hasErrors || (!hasSocialAccess && !hasDatasets)) {
                    submitDisabled = true;
                }
            });

        return submitDisabled;
    }

    submitAndFocusLastQuery(queries, showEditor=true) {
        queries.forEach((q,idx)=>{
            var colors = q.get("queryInfo.color");
            colors.loadDefaultColorForIndex(idx, queries);
        });
        let savequeries = this.combineQueries(queries);
        let query_url = QueryURL.queryArrayToUrl(savequeries);
        this.set("lastRunCache", query_url)
        this.submitQueries(this.combineQueries(queries));
        Ember.run.later(this, ()=>{
            this.setProperties({
                showQueryEditor: false,
                showFilterEditor: false,
                selectedQueryIndices: [this.queries.length - 1]
            });
        }, 300);
    }

    submitQueries = () => {};
    cancelQueryEditing = () => {};
    cancelFilterEditing = () => {};

    @action
    setQueryType(type){
        this.set("activeQuery.queryInfo.queryType",type);
        this.saveAndSubmitQueries();
        this.set("showQueryEditor", true);
    }
    @action
    toggleShowFilters(){
        this.toggleProperty("showFilters");
    }
    @action
    selectedQueryIndicesChanged(indicies){
        this.selectedQueriesChanged(indicies);
        return false;
    }
    @action
    querySelected(q){
        if (this.multiSelect) {
            this.set("lastMultiSelectQueries", [].concat(this.selectedQueryIndices || []));
        }
        this.selectedQueriesChanged([q]);
    }
    @action
    activateMultiSelect(){
        var queryOptions = this.queryOptions;

        if (this.multiSelect) {
            return;
        }

        this.set("lastSingleQuery",Ember.assign([], this.selectedQueryIndices));
        this.selectedQueriesChanged(this.lastMultiSelectQueries || queryOptions.mapBy("index"));
    }
    @action
    toggleShowEditor(){
        this.toggleProperty("showQueryEditor");
    }
    @action
    toggleShowGlobalFilters(){
        this.toggleProperty("showFilterEditor");
        if(!this.showFilterEditor){
            this.cancelFilterEditing();
        }
    }
    @action
    saveAndSubmitQueries(){
        if (this.submitDisabled) {
            return;
        }
        var savequeries = this.combineQueries(this.queries);
        let query_url = QueryURL.queryArrayToUrl(savequeries);
        this.setProperties({
            lastRunCache: query_url,
            showQueryEditor: false,
            showFilterEditor: false
        });
        this.submitQueries(savequeries);
    }
    @action
    selectedGlobalFiltersChanged (filterValues) {
        this.set("globalQueryInfo.activeFormFilterNames", filterValues);
    }

    @action
    applyFilterToAll(filterToAdd, sourceQuery){
        this.queries.forEach((query) => {
            // Filters being copied and applied to all queries shouldn't re-add 
            // to the source query as it would create a duplicate filter.
            if (query != sourceQuery) {
                const found = query.queryInfo.filters.content.find(currentFilter => {
                    const matches = JSON.stringify(currentFilter.toAPIJSON()) === JSON.stringify(filterToAdd.toAPIJSON()) 
                        && !!currentFilter.isExcluded === !!filterToAdd.isExcluded;
                    return matches;
                });
                if (!found) {
                    query.queryInfo.addFilter(filterToAdd.toJSON());
                }
            }
            query.incrementProperty("updated");
        });
    }
    @action
    applyModifierToAll(modifier, value, sourceQuery) {
        this.queries.forEach((query) => {
            if (query != sourceQuery) {
                if (modifier === "dictionaries") {
                    query.queryFilters.set("dictionaries", value);
                }
                if (modifier === "subquerySets") {
                    query.queryFilters.set("subquerySets", value);
                }
                if (modifier === "analyzeFields") {
                    query.queryFilters.set("analyzeFields", value);
                }
                if (modifier === "timestampField") {
                    query.queryFilters.set("timestampField", value);
                }
            }
        });
    }
    @action
    globalDatesChanged(startDate, endDate){
        GlobalFilters.synchronize(this.queries);
    }
    @action
    async addQuery(){
        let query = Query.create();
        query.set("queryType", "undefined");
        this.queries.push(query);
        await GlobalFilters.synchronize(this.queries, true);
        this.submitAndFocusLastQuery(this.queries);
        this.setProperties({
            showQueryEditor: false,
            showFilterEditor: false
        });
    }
    @action
    async removeQuery(query){
        let new_queries = Array.from(this.queries);
        new_queries.removeObject(query)
        await GlobalFilters.setDefaultDateRange(new_queries);
        this.submitAndFocusLastQuery(new_queries, false);
    }
    @action
    copyQuery(query) {
        var newQuery = SingleRequestQuery.create(),
            newQueryInfo = newQuery.get("queryInfo"),
            queryJSON = query.get("queryInfo").toJSON(),
            new_queries = Array.from(this.queries);

        queryJSON.color = null;
        newQueryInfo.loadJSON(queryJSON);
        new_queries.pushObject(newQuery);
        this.submitAndFocusLastQuery(new_queries);
    }

    @action
    cancel() {
        this.cancelQueryEditing();
        this.setProperties({
            showQueryEditor: false,
            showFilterEditor: false
        });
    }

    @action
    onDatasetChanged(datasetId) {
        let queryInfo = this.activeQuery?.queryInfo;
        if (!queryInfo) {
            return;
        }
        GlobalFilters.setDefaultDateRange(this.queries);
    }

    @action
    onSearchWithinChanged() {
        GlobalFilters.synchronize(this.queries);
    }
}
