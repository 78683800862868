import Ember from 'ember';
import States from "infegy-frontend/static_data/geography/us_states_json";

export function stateName(params, namedArgs) {
  var code = params[0];
  var state = States.findBy("code",code);
  
  if(Ember.isEmpty(state) || Ember.isEmpty(state.title)) {
      return "";
  }
  
  return state.title;
}

export default Ember.Helper.helper(stateName);

