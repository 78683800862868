var timeOfDay = [{
        "label": "12 AM",
        "value": "0",
        "title": "0:00",
        "description": "Posted between 0:00 and 1:00 UTC"
    },{
        "label": "1 AM",
        "value": "1",
        "title": "1:00",
        "description": "Posted between 1:00 and 2:00 UTC"
    },{
        "label": "2 AM",
        "value": "2",
        "title": "2:00",
        "description": "Posted between 2:00 and 3:00 UTC"
    },{
        "label": "3 AM",
        "value": "3",
        "title": "3:00",
        "description": "Posted between 3:00 and 4:00 UTC"
    },{
        "label": "4 AM",
        "value": "4",
        "title": "4:00",
        "description": "Posted between 4:00 and 5:00 UTC"
    },{
        "label": "5 AM",
        "value": "5",
        "title": "5:00",
        "description": "Posted between 5:00 and 6:00 UTC"
    },{
        "label": "6 AM",
        "value": "6",
        "title": "6:00",
        "description": "Posted between 6:00 and 7:00 UTC"
    },{
        "label": "7 AM",
        "value": "7",
        "title": "7:00",
        "description": "Posted between 7:00 and 8:00 UTC"
    },{
        "label": "8 AM",
        "value": "8",
        "title": "8:00",
        "description": "Posted between 8:00 and 9:00 UTC"
    },{
        "label": "9 AM",
        "value": "9",
        "title": "9:00",
        "description": "Posted between 9:00 and 10:00 UTC"
    },{
        "label": "10 AM",
        "value": "10",
        "title": "10:00",
        "description": "Posted between 10:00 and 11:00 UTC"
    },{
        "label": "11 AM",
        "value": "11",
        "title": "11:00",
        "description": "Posted between 11:00 and 12:00 UTC"
    },{
        "label": "12 PM",
        "value": "12",
        "title": "12:00",
        "description": "Posted between 12:00 and 13:00 UTC"
    },{
        "label": "1 PM",
        "value": "13",
        "title": "13:00",
        "description": "Posted between 13:00 and 14:00 UTC"
    },{
        "label": "2 PM",
        "value": "14",
        "title": "14:00",
        "description": "Posted between 14:00 and 15:00 UTC"
    },{
        "label": "3 PM",
        "value": "15",
        "title": "15:00",
        "description": "Posted between 15:00 and 16:00 UTC"
    },{
        "label": "4 PM",
        "value": "16",
        "title": "16:00",
        "description": "Posted between 16:00 and 17:00 UTC"
    },{
        "label": "5 PM",
        "value": "17",
        "title": "17:00",
        "description": "Posted between 17:00 and 18:00 UTC"
    },{
        "label": "6 PM",
        "value": "18",
        "title": "18:00",
        "description": "Posted between 18:00 and 19:00 UTC"
    },{
        "label": "7 PM",
        "value": "19",
        "title": "19:00",
        "description": "Posted between 19:00 and 20:00 UTC"
    },{
        "label": "8 PM",
        "value": "20",
        "title": "20:00",
        "description": "Posted between 20:00 and 21:00 UTC"
    },{
        "label": "9 PM",
        "value": "21",
        "title": "21:00",
        "description": "Posted between 21:00 and 22:00 UTC"
    },{
        "label": "10 PM",
        "value": "22",
        "title": "22:00",
        "description": "Posted between 22:00 and 23:00 UTC"
    },{
        "label": "11 PM",
        "value": "23",
        "title": "23:00",
        "description": "Posted between 23:00 and 24:00 UTC"
    }];

export default timeOfDay;
