import Controller, { inject as controller } from "@ember/controller";
import { computed, action } from "@ember/object";
import { readOnly } from "@ember/object/computed";
import { isEmpty } from "@ember/utils";
import { later } from "@ember/runloop";

import AdminUsers from "infegy-frontend/models/admin/admin_users";
import BrowserStore from "infegy-frontend/utils/browser-store";
import Workspace from "infegy-frontend/models/users/workspace2";
import Workspaces from "infegy-frontend/models/users/workspaces";
import QueryURL from "infegy-frontend/models/queries/query_url";
import GlobalFilters from "infegy-frontend/models/queries/query_global_filters";

export default class extends Controller {
    // inputs
    urlWasCorrupt = false;
    showEditor = false;
    submitError = null;
    lastErroredState = "";

    // members
    adminUsers = AdminUsers.create();
    workspace = Workspace.create();
    savedWorkspaces = Workspaces.create();
    hasValidated = false;

    @BrowserStore.Attr("cachedWorkspace") cachedWorkspace;

    @controller() application;
    @readOnly("application.user") user;
    @readOnly("user.permissions.maxQueries") maxQueries;
    @readOnly("user.ownedWorkspaces") recentWorkspaces;
    @readOnly("user.sharedWorkspaces") sharedWorkspaces;
    @readOnly("workspace.queries") queries;

    pollQueries() {
        later(() => {
            if (this && !this.isDestroyed && !this.isDestroying && this.showEditor) {
                const workspaceJSON = this.workspace.toJSON({fieldGroups: "api"});
                const workspaceString = JSON.stringify(workspaceJSON);

                // If there has been a workspace change, validate queries.  It is possible that 
                // the dashboard is reloading with a cached workspace.  In that case, make sure 
                // the dashboard always validates queries on load so the initial state is valid.
                if (!this.hasValidated || workspaceString !== this.cachedWorkspace) {
                    for (const query of this.workspace.queries.content) {
                        query.validate();
                    }
                    this.set("hasValidated", true);
                }

                this.set("cachedWorkspace", workspaceString);
                // The idential workspace error message persists until user closes it
                // or until any change to a query occurs. This block manages the check
                // on any query change.
                if (isEmpty(this.lastErroredState) && !isEmpty(this.submitError)) {
                    this.set("lastErroredState", this.cachedWorkspace);
                } else if (!isEmpty(this.submitError) && this.lastErroredState !== this.cachedWorkspace) {
                    this.setProperties({
                        submitError: null,
                        lastErroredState: ""
                    })
                }

                this.pollQueries();
            }
        }, 300);
    }

    addBlankQuery(queryType) {
        this.workspace.addBlankQuery(queryType);
        GlobalFilters.synchronize(this.queries);
    }

    addSavedQuery(query) {
        const newWorkspace = Workspace.create();
        let currentQueries = this.workspace.queries.content.reduce((previousValue, currentValue) => {
            if (!currentValue.isBlank) {
                previousValue.push(currentValue);
            }
            return previousValue;
        }, []);

        newWorkspace.addQueries([...currentQueries, query]);
        this.set("workspace", newWorkspace);
        GlobalFilters.setDefaultDateRange(this.queries, true);
    }

    @computed('queries.length', 'maxQueries')
    get remainingQueryLimit() {
        const queryCount = this.queries.reduce((count, query) => {
            if (query.isEmpty)
                return count;
            return ++count;
        }, 0);

        return this.maxQueries - queryCount;
    }

    @action
    workspaceChangedFolder(theWorkspace) {
        let foundRecent = this.recentWorkspaces.find(workspace => theWorkspace.id === workspace.id),
            foundShared = this.sharedWorkspaces.find(workspace => theWorkspace.id === workspace.id),
            foundSaved = this.savedWorkspaces.find(workspace => theWorkspace.id === workspace.id)

        if (foundRecent) foundRecent.workspaceFolderId = theWorkspace.workspaceFolderId;
        if (foundShared) foundShared.workspaceFolderId = theWorkspace.workspaceFolderId;
        if (foundSaved) foundSaved.workspaceFolderId = theWorkspace.workspaceFolderId;
    }

    @action onStartNewWorkspace(queryType) {
        const newWorkspace = Workspace.create();
        newWorkspace.addBlankQuery(queryType);
        this.set("workspace", newWorkspace);
        GlobalFilters.setDefaultSocialDateRange(this.queries, true);
    }

    @action onLoadWorkspace(workspace) {
        if (this.workspace.id === workspace.id) {
            return;
        }
        this.set("workspace", workspace.copy());
        GlobalFilters.setDefaultDateRange(this.workspace.queries, true);
        window.scroll({top: 0, behavior: "smooth"});
    }

    @action onAddQueries(selectedQueries) {
        if (isEmpty(selectedQueries))
            return;
        const newWorkspace = Workspace.create();
        let currentQueries = this.workspace.queries.content.reduce((previousValue, currentValue) => {
            if (!currentValue.isBlank) {
                previousValue.push(currentValue);
            }
            return previousValue;
        }, []);
        newWorkspace.addQueries([...currentQueries, ...selectedQueries]);
        newWorkspace.title = newWorkspace.queries.mapBy("title").join(", ");
        this.set("workspace", newWorkspace);
        GlobalFilters.setDefaultDateRange(this.queries);
    }

    @action async onRunWorkspace() {
        this.transitionToRoute("atlas.dashboard.content.overview", QueryURL.queryArrayToUrl(this.queries));
        this.set("showEditor",false);
    }

    @action onClearWorkspace() {
        const newWorkspace = Workspace.create();
        newWorkspace.addBlankQuery();
        this.set("workspace", newWorkspace);
    }

    @action onCopyQuery(query) {
        let newQuery = query.copy();
        newQuery.filters.color.colorString = null;
        newQuery.filters.color.loadDefaultColorForIndex(this.workspace.queries.length-1, this.workspace.queries);
        this.workspace.addQueries([newQuery]);
        GlobalFilters.synchronize(this.queries, true);
    }

    @action onUploadData(){
        this.transitionToRoute("atlas.tools.custom-datasets.create");
    }

    @action async updateWorkspace(selectedWorkspace) {
        this.workspace.id = selectedWorkspace.id;
        this.workspace.title = selectedWorkspace.title;
        this.workspace.isSaved = true;
        try {
            await this.workspace.update();
            this.savedWorkspaces.getPage();
            this.recentWorkspaces.getPage();
            this.user.workspaceFolders.load();
        } catch (error) {
            if (error.status === 409) {
                this.set("submitError", error.atlasErrorText)
            }
        }
    }

    @action clearSubmitError() {
        this.set("submitError", null);
    }

    @action async createWorkspace(title) {
        this.workspace.id = "";
        this.workspace.title = title;
        this.workspace.isSaved = true;
        await this.workspace.save();
        this.savedWorkspaces.getPage();
        this.recentWorkspaces.getPage();
        this.user.workspaceFolders.load();
    }

    @action async toggleSaveWorkspace(workspaceToToggle) {
        workspaceToToggle.toggleProperty("isSaved");
        await workspaceToToggle.save();
        this.savedWorkspaces.getPage();
        this.recentWorkspaces.getPage();
    }

    @action async removeFromFolder() {
        this.savedWorkspaces.getPage();
        this.recentWorkspaces.getPage();
    }
}
