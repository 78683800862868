import Ember from "ember";
import Component from "@ember/component";
import { action, computed } from '@ember/object';

import SharedResources from "infegy-frontend/models/users/shared_resources";

export default class ShareDetails extends Component {
    tagName = "div";
    classNames = ["share-details"];

    /**
     * input parameters
     */
    // The active user
    user = null;
    // The name of the resource type displayed to the user
    resourceTypeDisplayName = "";
    // The type of resource (e.g. workspace) being viewed/edited
    resourceType = null;
    // The id of the resource being viewed/edited
    resourceId = null;
    // Enables the ability to decline sharing of the resource
    enableDeclineSharing = true;
    // A list of shared_resource models
    sharedResources = SharedResources.create();
    // The id of the resource pending a decline sharing operation
    declineSharingId = null;
    // True if the resource is shared, otherwise False
    isShared = true;

    @computed.alias("user.id") userId;

    @computed.alias("sharedResources.isLoading") isLoading;

    @computed("declineSharingId")
    get isDecliningSharing() {
        return !Ember.isEmpty(this.resourceId) && this.resourceId === this.declineSharingId;
    }

    /**
     * True if the resource is shared with the user's company, otherwise False.
     */
    @computed("sharedResources.content")
    get isSharedWithCompany() {
        let found = this.sharedResources.content.find((sharedResource) => {
            // not shared with a user, therefore it must be shared with the company
            return sharedResource.userIdTo == 0;
        });

        return !Ember.isEmpty(found);
    }

    /**
     * The number of specific users the resource is shared with.
     */
    @computed("sharedResources.content")
    get sharedWithUserCount() {
        const sharedResources = this.sharedResources || [];
        const count = sharedResources.reduce((total, sharedResource) => {
            if (sharedResource.userIdTo != 0) {
                return total += 1;
            }
            return total;
        }, 0);

        return count;
    }

    // event handlers
    sharingDeclined = () => {}

    @action
    onBeginDeclineSharing() {
        this.set("declineSharingId", this.resourceId);
    }

    @action
    onCancelDeclineSharing() {
        this.set("declineSharingId", null);
    }

    @action
    async onConfirmDeclineSharing() {
        const sharedResource = this.sharedResources.firstObject;
        try {
            await sharedResource.delete();
            this.sharedResources.removeObject(sharedResource);
        }
        catch(error) {
            this.set("showDeclineSharingError", true);
            Ember.run.later(()=>{
                this.set("showDeclineSharingError", false);
            }, 5000);
            return;
        }

        this.sharingDeclined();            
    }
}