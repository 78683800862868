import Ember from "ember";
import { action, computed } from "@ember/object";

export default class SubqueryListRow extends Ember.Component {
    classNames = ["subquery-set-edit-table-row-striped"];

    // inputs
    deleting = null;
    editing = null;
    hideActions = false;
    hideSelection = false;
    isReadOnly = true;
    selected = [];
    subquery = null;

    // properties
    errorMessage = "";
    name = "";
    query = "";

    @Ember.computed("deleting")
    get isDeleting() {
        return this.deleting == this.subquery.id;
    }

    @Ember.computed("editing")
    get isEditing() {
        let isEditing = this.editing == this.subquery.id;
        if (isEditing) {
            this.setProperties({
                name: this.subquery.tag,
                query: this.subquery.query
            });
        }
        return isEditing;
    }
    
    @Ember.computed("selected", "selected.length")
    get isSelected() {
        let value = this.selected.includes(this.subquery.id);
        return value;
    }
    
    // events
    deleteBegin = (subquery) => {};
    deleteCancel = (subquery) => {};
    deleteConfirm = (subquery) => {};
    editBegin = (subquery) => {};
    editCancel = (subquery) => {};
    editSave = (subquery) => {};
    toggleSelection = (subquery) => {};

    // actions
    @action
    deleteBeginAction() {
        this.set("errorMessage", "");
        this.deleteBegin(this.subquery);
    }
    @action
    deleteCancelAction() {
        this.deleteCancel(this.subquery);
    }
    @action
    deleteConfirmAction() {
        this.deleteConfirm(this.subquery).catch((response) => {
            this.set("errorMessage", response.atlasErrorText);
        });
    }
    @action
    editBeginAction() {
        this.editBegin(this.subquery);
    }
    @action
    editCancelAction() {
        this.editCancel(this.subquery);
    }
    @action
    editSaveAction() {
        this.editSave(this.subquery.id, this.name, this.query).catch((response) => {
            this.set("errorMessage", response.atlasErrorText);
        });
    }
    @action
    selectAction() {
        this.toggleSelection(this.subquery);
    }
}