import Component from '@ember/component';
import { computed, action } from '@ember/object';
import AxisChartFields from "infegy-frontend/components/field-components/axis-comparison-chart/axis-chart-fields";
import FieldDefinitionLoader from "infegy-frontend/models/field-definitions/loader";
import CSSTools from "infegy-frontend/utils/css-tools";
import { MouseInteractionWrapper } from 'infegy-frontend/components/charting/mouse-interaction-mixin/component';
import classic from "ember-classic-decorator";

@classic
export default class AxisComparisonChart extends MouseInteractionWrapper(Component) {
    tagName = "circle";

    attributeBindings = ["cx", "cy", "r", "style"];
    classNameBindings = ["hovered:axis-comparison-bubble-hovered:axis-comparison-bubble"];

    queryRow = null;
    hovered = false;

    @computed.alias("queryRow.x") cx;
    @computed.alias("queryRow.y") cy;
    @computed.alias("queryRow.radiusPct") r;
    @computed.alias("queryRow.colorStyle") style;

    bubbleHovered() { /* action */ };

    onMouseEnter() {
        this.bubbleHovered(this.queryRow);
    }

    onMouseLeave() {
        this.bubbleHovered(null);
    }
};
