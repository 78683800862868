import Ember from "ember";
import ChartColors from "infegy-frontend/static_data/fixtures/chart_colors";

export default Ember.Component.extend({
    tagName: "tr",
    classNameBindings: [":data-table-row", "hasQueryField:topics-table-expandable"],

    area: null,
    index: null,
    selectedGeo: null,
    hasQueryField: false,
    expandable: true,
    highestScore: null,

    sentimentPercentage: Ember.computed("area", function () {
        let pct = (this.get("area.positivity") || 0) * 100.0;
        return pct.toFixed(0);
    }),

    sentimentWidthStyleSafe: Ember.computed("sentimentPercentage", function() {
        let percentage = this.sentimentPercentage || 50;
        return new Ember.String.htmlSafe(["width:", percentage, "%;"].join(""));
    }),

    sentimentStyleSafe: Ember.computed("area", function() {
        let sentiment = this.get("area.positivity") || 0.5,
            colorValues = ChartColors.sentimentZeroToOneGradientD3();
        let style = ["color:", colorValues(sentiment), ";"].join("");
        return new Ember.String.htmlSafe(style);
    }),

    scoreWidthStyleSafe: Ember.computed("area", "highestScore", function() {
        let highestScore = this.get("highestScore") || 0;
        let scoreWidth = (this.area.score / highestScore) * 100.0;
        return new Ember.String.htmlSafe(["width:", scoreWidth, "%;"].join(""));
    }),

    changePercentage: Ember.computed("area", function() {
       let pct = (this.get("area.documentsGrowthPct") || 0) * 100;
       return pct.toFixed(0);
    }),

    click: function () {
        this.send("geoSelected");
    },

    geoSelected: () => {},
    actions: {
        geoSelected: function() {
            var area = this.area,
                selected = this.selectedGeo;

            this.geoSelected((area === selected) ? null : area);
        },
    }
});
