import Ember from "ember";
import ChartColors from "infegy-frontend/static_data/fixtures/chart_colors";
import d3 from "d3";

export default Ember.Component.extend({
    width: 1000,
    height: 500,

    activeMap: null,
    combinedData:null,
    layoutData: Ember.computed.alias("activeMap.svgData"),
    activeMetric: "sum",
    selectedMetric: Ember.computed("activeMetric", "queries.length", "activeField", function() {
        if (this.activeField === "positivity" && (this.activeMetric === "avg" || this.get("queries.length") === 1)) {
            return "avg";
        }
        return this.get("queries.length") > 1 ? this.activeMetric : "sum";
    }),
    activeField: "score",
    fieldOptions: [],
    isTooltipVisible: Ember.computed.or("hoveredArea", "selectedArea"),
    canHaveDrilldown: true,
    hoveredPct: 0,
    hoveredArea: null,
    selectedArea: null,
    activeArea: Ember.computed.or("selectedArea", "hoveredArea"),

    initialDragPoint: null,
    currentEndPoint: null,

    interactionRect:Ember.computed("initialDragPoint","currentEndPoint",function(){
        var init = this.initialDragPoint,
            end = this.currentEndPoint;
        if(init && end) {
            return {
                x: init.x < end.x ? init.x : end.x,
                y: init.y < end.y ? init.y : end.y,
                width: Math.abs(init.x - end.x),
                height: Math.abs(init.y - end.y),
            }
        }
    }),

    filledMapData: Ember.computed("combinedData", "activeField", "selectedMetric", function() {
        var mapData = this.combinedData,
            activeField = this.activeField,
            activeMetric = this.selectedMetric,
            colorFunction = this.colorFunction;

        // if map data changes, deselect everything
        this.send("selectedAreaChanged",null);
        this.send("hoveredAreaChanged",null);

        return mapData.map((areaData) => {
            var fill = colorFunction();
            if (!Ember.isEmpty(areaData) && !Ember.isEmpty(areaData[activeField])) {
                var fieldData = areaData[activeField],
                    metricData = fieldData && fieldData[activeMetric],
                    fillData = Ember.isNone(metricData) ? fieldData : metricData;
                fill = colorFunction(fillData);
            }

            return Object.assign({
                fill: fill
            }, areaData);
        });

    }),

    colorFunction: Ember.computed("combinedData", "selectedMetric", "activeField", function() {
        var activeMetric = this.selectedMetric;

        if (activeMetric === "sum") {
            var combinedData = this.combinedData,
                activeMetric = this.selectedMetric,
                activeField = this.activeField,
                queries = this.queries,
                colorDomain,
                colorRange;

            // Domain
            var max = 0,
                min = Infinity;

            combinedData.forEach((d) => {
                let fieldData = Ember.get(d, activeField),
                    test = Ember.get(d,`${activeField}.${activeMetric}`);
                if(Ember.isEmpty(test))
                    test = fieldData;
                if (Ember.isEmpty(test))
                    return;
                max = Math.max(test, max);
                min = test > 0 ? Math.min(test, min) : min;
            });
            colorDomain = [min, max]

            // Range
            if (queries.length == 1) {
                let colors = Ember.get(queries, "firstObject.queryInfo.color");

                colorRange = [d3.rgb(colors.lightColorString), d3.rgb(colors.darkColorString)];
            } else {
                colorRange = ChartColors.get("genericDataGradientColors");
            }

            // Color Function
            return (d) => {
                if (Ember.isEmpty(d) || d == 0) {
                    return ChartColors.gray.light;
                }
                return d3.scaleLinear().domain(colorDomain).range(colorRange)(d);
            };
        }
        else if (activeMetric === "avg") {
            // Color Function
            return (d) => {
                if (Ember.isEmpty(d) || d == 0) {
                    return ChartColors.gray.light;
                }

                // This math exaggerates the positivity value before sending it to the color function
                // This is done as many searches have very consistent sentiment across geo regions
                // This will push values away from 0.5 towards 0/1, making the colors on the chart more intense
                // The change is not dramatic, just keeps maps from being too... yellow
                if(d > 0.51) {
                    d = Math.atan(d)/Math.atan(1.0);
                } else if(d < 0.49) {
                    d = Math.atan(0.5+d)/Math.atan(0.9) - 0.59;
                }

                return ChartColors.sentimentZeroToOneGradientD3()(d);
            };
        }
        else {
            return (arr) => {
                if (Ember.isEmpty(arr) || arr[0].score === 0) {
                    return ChartColors.gray.light;
                } else {
                    return Ember.get(arr[0]["_originalQuery"], "queryInfo.color.colorString");
                }
            };
        }
    }),

    drilldownQueries: Ember.computed("activeMap", "selectedArea", "queries", "queries.@each.updated", function() {
        var selectedArea = this.selectedArea,
            activeFilterId = this.get("activeMap.filterId");

        return (this.queries || []).map((oldQuery) => {
            var newQuery = oldQuery.copy();
            newQuery.queryInfo.addFilter({
                id: activeFilterId,
                values: [selectedArea.id]
            });
            return newQuery;
        });
    }),

    actions: {
        selectedAreaChanged(area) {
            this.set("selectedArea", area);
        },
        hoveredAreaChanged(area, event, mapWidth) {
            var hoveredPct = 0;
            if (event && mapWidth) {
                hoveredPct = event.offsetX / mapWidth;
                this.set("hoveredPct", hoveredPct);
            }
            this.set("hoveredArea", area);
        },
        mapChanged(maps) {
            this.set("activeMap", maps[0]);
        },
        activeFieldChanged(value) {
            this.set("activeField", value);
        },
        selectedMetricChanged(value) {
            this.set("selectedMetric", value);
        },
        mouseOver(event){
            var mapWidth = this.width;
            this.set("hoveredPct", event.x / mapWidth );
        },
        pointHovered(){},
        initialDragPointChanged(value){
            this.set("initialDragPoint", value);
        },
        currentEndPointChanged(value){
            this.set("currentEndPoint", value);
        },
        dragEnded(value){
            this.set("dr", value);
        },
    }
});
