import Ember from 'ember';
import { computed, action } from '@ember/object';
import { MouseInteractionWrapper } from 'infegy-frontend/components/charting/mouse-interaction-mixin/component';

export default class extends MouseInteractionWrapper(Ember.Component) {
    tagName = "circle";
    classNameBindings = ["classSafe"];
    attributeBindings = ["r", "cx", "cy", "style"];

    data = null;
    r =null;
    hoveredInterest = null;
    selectedInterest = null;
    @Ember.computed.or("hoveredInterest", "selectedInterest") activeInterest;

    @Ember.computed.alias("data.x") cx;
    @Ember.computed.alias("data.y") cy;
    @Ember.computed.alias("data.style") style;

    @computed("data", "activeInterest")
    get isActive() {
        var data = this.data,
            activeInterest = this.activeInterest;

        return data === activeInterest;
    }

    @computed("isActive")
    get classSafe() {
       var isActive = this.isActive ? "active" : "";

        return ("interests-scatterplot-point " + isActive).htmlSafe();
    }

    click() {
        this.selectedInterestChanged(this.data);
    }

    onMouseEnter(event) {
        this.hoveredInterestChanged(this.data);
    }

    onMouseLeave(event) {
        this.hoveredInterestChanged(null);
    }

    hoveredInterestChanged = () => {};
    selectedInterestChanged = () => {};

    @action
    hoveredInterestChanged(interest) {
        this.hoveredInterestChanged(interest);
    }

    @action
    selectedInterestChanged(interest) {
        this.selectedInterestChanged(interest);
    }
}
