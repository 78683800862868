import Ember from "ember";
import D3SVGContainerComponent from "infegy-frontend/components/charting/d3-base/d3-svg-container/component";

export default D3SVGContainerComponent.extend({
    classNameBindings:[":svg-axis-indicator", "isFixed:svg-axis-indicator-fixed"],

    hasX:true,
    xPct: 0,

    hasY:true,
    yPct: 0,

    colorClass: "atlas-text-color-light",

    isVisible: true,
    isFixed: false,


    xPos: Ember.computed("xPct", "width", "hasX", {
        get: function(key) {
            if (!this.hasX) {
                return -1000000;
            }
            return (this.xPct * this.width) || 0;
        },
    }),

    yPos: Ember.computed("yPct", "height", "hasY", {
        get: function(key) {
            if (!this.hasY) {
                return -1000000;
            }
            var height = this.height || 0;
            return (height - (this.yPct * height)) || 0;
        },
    }),
});
