import Component from "@ember/component";
import classic from "ember-classic-decorator";
import { action, computed } from "@ember/object";

@classic
export default class extends Component {
    queryFormQueries = [];
    user = null;
    isRunningQueries = false;

    actionBarButtons = ["change_folder", "select_workspace", "select_queries", "toggle_save", "view_sharing_details"];
    displayOptions = [
        {
            label: "Workspace History",
            value: "recent_workspaces",
            anchor: "intercom-tour-workspace-history"
        },
        {
            label: "Saved Workspaces",
            value: "saved_workspaces",
            anchor: "intercom-tour-workspace-saved"
        },
        {
            label: "Shared Workspaces",
            value: "shared_workspaces",
            anchor: "intercom-tour-workspace-shared"
        },
        {
            label: "Saved Queries",
            value: "saved_queries",
            anchor: "intercom-tour-saved-queries"
        },
        {
            label: "Workspace Folders",
            value: "workspace_folders",
            anchor: "intercom-tour-workspace-folders"
        }
    ];

    _selectedOption = null;

    @computed("_selectedOption", "savedWorkspaces.length")
    get selectedOption() {
        if (this._selectedOption === null) {
            return this.savedWorkspaces.length > 0 ? 'saved_workspaces' : 'recent_workspaces';
        }
        return this._selectedOption;
    }

    @action
    onViewChanged(selectedOption) {
        this.set("_selectedOption", selectedOption);
    }
}
