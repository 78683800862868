import Controller, { inject as controller } from "@ember/controller";
import { action, computed } from "@ember/object";

import Document from "infegy-frontend/models/custom-datasets/document";

export default class extends Controller {
    @controller application;
    @controller("atlas.tools.custom-datasets.append") appendController;
    @computed.readOnly("application.user") user;

    selectedFile = null;
    errorText = "";
    headerRowCount = 1;
    isLoading = false;
    loaded = 0;
    total = 0;

    @computed.notEmpty("selectedFile") hasFileSelected;
    @computed.notEmpty("errorText") hasError;

    @computed("user", "user.customDatasets")
    get customDataset() {
        if (this.user && this.user.customDatasets.length > 0) {
            const found = this.user.customDatasets.find((customDataset) => customDataset.id === this.model);
            return found
        }
        return null;
    }

    @computed("user.permissions.customDatasetUploadLimit")
    get maxStorageSize() {
        return this.user.permissions.customDatasetUploadLimit;
    }

    reset() {
        this.setProperties({
            isLoading: false,
            selectedFile: null,
            errorText: "",
            loaded: 0,
            total: 0
        });
    }

    @action onSelectSpreadsheet(files) {
        this.setProperties({
            selectedFile: files[0],
            errorText: ""
        });
    }

    @action onCancelUpload() {
        this.setProperties({
            headerRowCount: 1,
            selectedFile: null
        });
    }

    @action async onUploadSpreadsheet() {
        let document = Document.create();
        try {
            this.set("isLoading", true);
            const onprogress = (event) => {
                this.setProperties({
                    loaded: event.loaded,
                    total: event.total
                });
            }
            await document.load(this.selectedFile, parseInt(this.headerRowCount), onprogress);
            this.setProperties({
                errorText: "",
                isLoading: false
            });
        }
        catch(error) {
            this.setProperties({
                errorText: error.atlasErrorText,
                isLoading: false
            });
            return;
        }
        this.set("headerRowCount", 1);
        this.appendController.setProperties({
            document,
            customDataset: this.customDataset
        })
        this.transitionToRoute("atlas.tools.custom-datasets.append.verify-columns");
    }
}