import Ember from "ember";

import Component from '@ember/component';
import { computed } from '@ember/object';

export default class SentimentBar extends Component {
    positiveValue = 0;
    negativeValue = 0;

    @computed("positiveValue", "negativeValue")
    get sentimentPercentage() {
        return this.positiveValue / (this.positiveValue + this.negativeValue);
    };

    @computed("sentimentPercentage")
    get sentimentWidthStyle() {
        let percentage = this.sentimentPercentage * 100.0;
        return new Ember.String.htmlSafe(`width:${percentage}%`);
    };
}
