import { A } from "@ember/array";
import ArrayProxy from "@ember/array/proxy";
import { readOnly } from "@ember/object/computed";
import { isEmpty } from "@ember/utils";
import classic from "ember-classic-decorator";

import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import Query from "infegy-frontend/models/users/query";
import OffsetPagination from "infegy-frontend/utils/offset_pagination";

@classic
export default class RecentQueries extends ArrayProxy {
    url = "api/v3/queries/recent";

    content = A();
    isLoading = false;
    pagination = OffsetPagination.create();

    @readOnly("pagination.links") links;

    // api parameters
    pageIndex = 0;
    pageSize = 10;
    search = "";
    sortDirection = "DESC";

    buildParams() {
        let params = {
            page_index: this.pageIndex,
            page_size: this.pageSize,
            sort_direction: this.sortDirection
        };
        if (!isEmpty(this.search)) {
            params.search = this.search;
        }
        return params;
    }

    async getPage() {
        this.set("isLoading", true);
        const params = this.buildParams();
        const response = await AtlasAuth.Get({url: this.url, data: params});
        this.parseResponse(response);
        this.set("isLoading", false);
    }

    parseResponse(response) {
        this.pagination.parseResponse(response);

        const recentQueries = response.output.map((queryGroup) => {
            const queries = queryGroup.map((record) => {
                let query = Query.create();
                query.loadJSON(record);
                return query;
            });
            return queries;
        });

        this.set("content", A(recentQueries));
    }

    async getFirstPage() {
        const response = await AtlasAuth.Get(this.links.first);
        this.parseResponse(response);
    }

    async getPreviousPage() {
        const response = await AtlasAuth.Get(this.links.previous);
        this.parseResponse(response);
    }

    async getNextPage() {
        const response = await AtlasAuth.Get(this.links.next);
        this.parseResponse(response);
    }

    async getLastPage() {
        const response = await AtlasAuth.Get(this.links.last);
        this.parseResponse(response);
    }
}
