import Route from "@ember/routing/route";
import SharedResources from "infegy-frontend/models/users/shared_resources";
import Snippet from "infegy-frontend/models/users/snippet";

export default class SnippetsRoute extends Route {
    title = "Editing Snippet";

    async model(params) {
        const snippetId = parseInt(params.snippet_id, 10);

        let snippet = Snippet.create();
        if (snippetId !== 0) {
            await snippet.getById(snippetId);
        }

        return snippet;
    }

    setupController(controller, model) {
        const sharedResources = SharedResources.create();
        sharedResources.getForResource("snippet", model.id);
        
        controller.setProperties({
            sharedResources,
            snippet: model
        });
    }
}