import Ember from "ember";
import BaseFieldSelections from "infegy-frontend/models/fields/field-selections/base";

export default BaseFieldSelections.extend({
    apiName: "volume",
    defaultSubSegment: "Normalized",

    allAvailableFields: Ember.computed("isLoaded", function () {
        return {
            "Posts.PostsNormalizedUniverse" : [this.makeFieldDefinition({
                fieldName: "postsNormalizedUniverse",
                title: "Universe",
                color: this.get("query.queryInfo.color")
            })],
            "Posts.WordNormalized" : [this.makeFieldDefinition({
                fieldName: "postsWordNormalizedComputed",
                title: "Normalized",
                rangelessPercentage: true,
                color: this.get("query.queryInfo.color")
            })],
            "Posts.PostsPerBillion" : [this.makeFieldDefinition({
                fieldName: "postsPerBillion",
                title: "Per Billion",
                color: this.get("query.queryInfo.color")
            })],
            "Posts.Count" : [this.makeFieldDefinition({
                fieldName: "postsCount",
                title: "Posts",
                color: this.get("query.queryInfo.color")
            })]
        };
    }),
});
