import Ember from "ember";
import DateFilters from "infegy-frontend/static_data/query/date_filters";
import AtlasConfig from "infegy-frontend/config/infegy-app-config";

export default Ember.Component.extend({
    classNames: ["row", "query-date-range"],

    earliestTimestamp: AtlasConfig.EarliestAtlasTimestamp,
    latestTimestamp: null, // if no latest date is set, the current date should be generated as late as possible

    date: null,

    startDateOptions: DateFilters.startDateOptions,

    startDateType: Ember.computed("date", "date.isRelative", "date.relative.isNow", {
        get: function(key) {
            return (this.date && this.date.get("isRelative")) ? "relative" : "fixed";
        },
        set: function(key, value) {
            if (value && this.date) {
               this.date.set("isRelative", (value !== "fixed"));
            }
            return (this.date && this.date.get("isRelative")) ? "relative" : "fixed";
        }
    }),

    datesChanged: () => {},
    actions: {
      startDateOptionsChanged(startDateType){
        this.set("startDateType", startDateType);
        this.send("dateChanged");
      },
      dateChanged(){
          this.datesChanged(this.date);
      },
    }
});
