import Ember from "ember";

export default Ember.Component.extend({
    tagName: "span",
    classNames: ["trends-segment-selectors"],

    showCustomButton: true,
    selectedSegment: "Posts",

    // Also can be "drilldown", others may be added later,
    // See: static_data/trends-segments.js
    segmentTypes: "all",

    segmentDefinition: null,

    displayAsDropdown: false,

    segmentOptions: Ember.computed("segmentDefinition", function () {
        var segmentOptions = this.get("segmentDefinition.segmentOptions") || [],
            selectedSegment = this.selectedSegment,
            options = segmentOptions.mapBy("value"),
            hasIt = options.includes(selectedSegment);
        if (!this.selectedSegment || (options && !hasIt)) {
            this.send("selectionChanged", segmentOptions[0] && segmentOptions[0].value);
        }
        return segmentOptions;
    }),

    changed: () => {},
    toggleCustom: () => {},
    actions: {
        selectionChanged: function (selectedValue) {
            this.changed(selectedValue);
        },
        toggleCustom: function() {
            this.toggleCustom();
        }
    }
});
