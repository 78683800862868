import Ember from "ember";

export default Ember.Component.extend({
    tagName: "tr",
    classNameBindings: [":data-table-row", ":topics-table-expandable"],

    influencer: null,

    isMultiQuery: false,

    numPostsPerPage: 5,

    click: function () {
        this.send("influencerSelected");
    },

    hasFollowers: Ember.computed.notEmpty("influencer.followers"),
    hasFollowing: Ember.computed.notEmpty("influencer.following"),

    influencerSelected: () => { /* action */ },
    actions: {
        influencerSelected: function() {
            this.influencerSelected(this.influencer);
        },
    }
});
