import Ember from "ember";
import { computed, action } from '@ember/object';
import { MouseInteractionWrapper } from "infegy-frontend/components/charting/mouse-interaction-mixin/component";
import CSSTools from "infegy-frontend/utils/css-tools";

export default class extends MouseInteractionWrapper(Ember.Component) {
    classNameBindings = [":toggle-list-option", "colorClass",
        "option.isDisabled:toggle-list-option-disabled",
        "isSelected:toggle-list-option-active:toggle-list-option-inactive"];

    attributeBindings = ["style"];

    option = null;
    selectedValues = null;
    titleCase = false;
    isDragging = false;
    colorDeselected = true;
    colorValueField = null;

    @computed("isSelected", "colorDeselected", "option.colorClass")
    get colorClass() {
        if (this.isSelected && this.colorDeselected) {
            return this.option && Ember.get(this.option, "colorClass");
        }
    }

    @computed("option.title", "option.label", "option.value")
    get title() {
        return this.get("option.title") || this.get("option.label") ||
            this.get("option.value");
    }

    @computed("option.value", "option.title", "option.lab")
    get value() {
        var value = this.get("option.value");
        if(!Ember.isEmpty(value)){
            return value;
        } else {
            return this.get("option.title") || this.get("option.label");
        }
    }

    @computed("option", "value", "selectedValues", "selectedValues.[]")
    get isSelected() {
        var selectedValues = this.selectedValues || [],
            optionValue = this.value;
        return !Ember.isEmpty(optionValue) && selectedValues.includes(optionValue);
    }

    @computed("option.color", "option.colorValue", "colorValueField")
    get style(){
        let colorValueField = this.colorValueField,
            colorString = null;
        if (this.isSelected && colorValueField && this.option) {
            colorString = Ember.get(this.option, colorValueField);
        }
        if (colorString) {
            return CSSTools.buildStringFromObjectSafe({
                "background-color": colorString
            });
        }
    }

    @computed("option.color", "option.colorValue", "colorValueField")
    get color() {
        let colorValueField = this.colorValueField;
        if (colorValueField && this.option) {
            return Ember.get(this.option, colorValueField);
        }
        return this.option?.color;
    }

    optionActivated = () => {};
    optionHovered = () => {};
    mouseDown() {
        if (!this.get("option.isDisabled")) {
            this.optionActivated(this.value);
            return false;
        }
    }

    onMouseEnter() {
        if (!this.get("option.isDisabled") && !this.isDragging) {
            this.optionHovered(this.value);
        }
    }

    onMouseLeave() {
        if (!this.get("option.isDisabled")) {
            this.optionHovered(null);
        }
    }
}
