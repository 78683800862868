import EmberObject from "@ember/object";

import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import fieldPercentage from "infegy-frontend/models/data_series/data_series_computed_field_percentage";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";

export default QueryAPIResults.extend({
    title : "volume",
    checkIfEmpty: function (dataSeries) {
        return !dataSeries || !dataSeries.get("stats") ||
            !dataSeries.get("stats.postsCount") ||
            !dataSeries.get("stats.postsCount.sum");
    },
    description: DataSeriesDescription.load({
        "date": "datestring",
        "timestamp": "timestamp",
        "groupName": "string",

        "postsWordNormalized": "percent",
        "postsNormalized": "percent",
        "postsNormalizedUniverse": "number",
        "postsCount": "number",
        "postsUniverse": "number",
        "postsPerBillion": "average",
        "postsNormalizedDiv": "number",
        "postsWordDiv": "number",
    }, [
        fieldPercentage.fieldSetup("postsNormalizedComputed", "postsNormalizedDiv", "postsCount"),
        fieldPercentage.fieldSetup("postsWordNormalizedComputed", "postsWordDiv", "postsCount"),
    ]),
    postload: function() {
        this.set("stats.metaSourcesEstimate", EmberObject.create({
            isPercentage: false,
            sum: this.meta.totalSourcesEst,
            type: "number"
        }));
        this.set("stats.metaSourcesUniverse", EmberObject.create({
            isPercentage: false,
            sum: this.meta.sourcesUniverse,
            type: "number"
        }));
    }
});
