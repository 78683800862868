import Ember from "ember";
import DataSeries from "infegy-frontend/models/data_series/data_series";

export default Ember.Component.extend({
    queries: [],

    interestsApi: "postInterests",

    allInterests: Ember.computed("queries", "queries.@each.updated", "interestsApi", function(){
        var queries = this.queries || [],
            mappedDataSeries = queries.mapBy("postInterests.dataSeries"),
            stacked = DataSeries.stackBy(mappedDataSeries, "name") || [];
        return stacked.get("data").sortBy("documents").reverse().splice(0,7);
    })
});
