import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";

export default QueryAPIResults.extend({
    title: "embeds",
    dateField: null,
    description: DataSeriesDescription.load({
        // "html": "string"
    }),

    preload: function() {
        let rawResponse = this.rawResults

        this.setProperties({
            "rawResults": rawResponse.results,
            "descriptor": rawResponse.descriptor,
            "sortableFields": rawResponse.sortable_fields,
        });
    }
});
