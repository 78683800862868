import { isEmpty } from "@ember/utils";
import classic from "ember-classic-decorator";

import Query from "infegy-frontend/models/users/query";
import OffsetPagination from "infegy-frontend/utils/offset_pagination";

@classic
export default class Queries extends OffsetPagination {
    static SORT_FIELD_OPTIONS = [
        {
            label: "Recently Used",
            value: "last_used_on"
        },
        {
            label: "Recently Created",
            value: "created_on"
        }
    ];

    itemClass = Query;
    url = "api/v3/queries";

    // additional query parameters
    search = "";
    sortField = "last_used_on";
    sortDirection = "DESC";
    onlySaved = true;

    buildQueryParams() {
        let queryParams = {
            sort_field: this.sortField,
            sort_direction: this.sortDirection,
            only_saved: this.onlySaved
        };
        if (!isEmpty(this.search)) {
            queryParams.search = this.search;
        }
        return queryParams;
    }

    updateOptions(options={}) {
        if (!isEmpty(options["search"]))
            this.searchText = options["search"];
        if (!isEmpty(options["sort_direction"]))
            this.sortDirection = options["sort_direction"];
        if (!isEmpty(options["sort_field"]))
            this.sortField = options["sort_field"];
    }

    async getPage(options={}) {
        let queryParams = this.buildQueryParams();
        queryParams = Object.assign(queryParams, options);
        super.getPage(queryParams);
        this.updateOptions(options);
    }

    async getFirstPage(options={}) {
        let queryParams = this.buildQueryParams();
        queryParams = Object.assign(queryParams, options);
        super.getFirstPage(queryParams);
        this.updateOptions(options);
    }

    async getPreviousPage(options={}) {
        let queryParams = this.buildQueryParams();
        queryParams = Object.assign(queryParams, options);
        super.getPreviousPage(queryParams);
        this.updateOptions(options);
    }

    async getNextPage(options={}) {
        let queryParams = this.buildQueryParams();
        queryParams = Object.assign(queryParams, options);
        super.getNextPage(queryParams);
        this.updateOptions(options);
    }

    async getLastPage(options={}) {
        let queryParams = this.buildQueryParams();
        queryParams = Object.assign(queryParams, options);
        super.getLastPage(queryParams);
        this.updateOptions(options);
    }
}
