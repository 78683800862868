import Ember from "ember";

export default Ember.Object.extend({
    apiField: null,
    canHaveMultipleColumns: null,
    columns: null,
    description: null,
    isActive: null,
    isRequired: null,
    maxColumns: null, // Infinity => number
    rowCount: null,
    title: null,
    value: null, // string[]

    size: Ember.computed("value", "value.[]", function() {
        const values = this.value;
        const columns = this.columns;
        const rowCount = this.rowCount;

        let size = 0;
        values.forEach((value, index) => {
            const matchingColumn = columns.content.find((column) => {
                return column.name == value;
            });

            if (matchingColumn) {
                size += matchingColumn.size;

                if (index > 0) {
                    size += rowCount;
                }
            }
        });

        return size;
    })
});
