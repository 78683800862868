import Ember from "ember";
import Component from "@ember/component";
import { action, computed } from "@ember/object";
import { later } from "@ember/runloop";

import AtlasAuth from "infegy-frontend/utils/atlas-auth";

export default class extends Component {
    classNameBindings = [":query-builder-query","isActive:active"];
    audienceSegments = null;
    isActive = false;
    hideColor = false;
    query = null;
    atlasAuth = AtlasAuth;

    @computed.notEmpty("query.queryInfo.filters") hasFilters;
    isSavingFilterSets = false;
    newFiltersetName = "";
    saveFiltersError = "";
    showSaveFiltersSuccess = "";

    @computed.readOnly("atlasAuth.user") user;
    @computed.alias("user.customDatasets") customDatasets;
    @computed.oneWay("user.filtersets") filtersets;

    @computed("query.queryInfo.queryBuilderDetail.andItems.content")
    get andItems() {
        var items = this.get("query.queryInfo.queryBuilderDetail.andItems");
        return items.toJSON({decamelizeKeys:false});
    }
    
    @computed("query.queryInfo.queryBuilderDetail.orItems.content")
    get orItems() {
        var items = this.get("query.queryInfo.queryBuilderDetail.orItems");
        return items.toJSON({decamelizeKeys:false});
    }

    @computed("query.queryInfo.queryBuilderDetail.notItems.content")
    get notItems() {
        var items = this.get("query.queryInfo.queryBuilderDetail.notItems");
        return items.toJSON({decamelizeKeys:false});
    }

    @computed("andItems", "andItems.length", "orItems", "orItems.length",
    "notItems", "notItems.length")
    get shouldHideNotItems() {
        return this.get("andItems.length") === 0 &&
            this.get("orItems.length") === 0 &&
            this.get("notItems.length") === 0;
    }

    @computed("andItems", "andItems.length", "orItems", "orItems.length",
    "notItems", "notItems.length")
    get shouldDisplayAndItemWarning() {
        return (this.get("andItems.length") === 0 && this.get("orItems.length") === 0) && this.get("notItems.length") > 0;
    }

    @computed("andItems.[]","orItems.[]","notItems.[]")
    get showQueryFields() {
        return [...this.orItems, ...this.andItems, ...this.notItems].find(d=>d.type === "text");
    }

    @computed("query.queryInfo.datasetQueryInfo")
    get queryFieldInfo() {
        let queryInfo = this.query?.queryInfo?.datasetQueryInfo,
            allOptions = {
                defaults: queryInfo?.default_query_fields || [],
                options: queryInfo?.available_query_fields || [],
                highlighterOptionValues: (queryInfo?.available_query_operator_fields || []).mapBy("id")
            };

        if (!allOptions.options?.length) {
            return allOptions;
        }

        allOptions.options = allOptions.options.map(queryField => {
            if (!queryField) {
                return;
            }
            return Object.assign({
                label: queryField?.name || queryField?.id,
                value: queryField?.id
            }, queryField);
        }).filter(opt => opt);

        allOptions.defaults = allOptions.defaults.mapBy("id");

        return allOptions;
    }

    @computed.alias("queryFieldInfo.options") queryFieldOptions;

    @computed("queryFieldInfo", "queryFieldInfo.defaults")
    get queryFieldsValue(){
        let value = this.query?.queryInfo?.queryFields;
        if (value === undefined) {
            value = this.queryFieldInfo?.defaults;
        }
        return value;
    }
    set queryFieldsValue(values){
        let opts = this.queryFieldInfo.options;
        if (!Ember.isEmpty(opts) && !Ember.isEmpty(values)) {
            values = values.filter(val => {
                return opts.findBy("value", val);
            });
        }
        this.query.queryInfo.queryFields = values;
    }

    setFilterAppliedInfo(filterName) {
        this.setProperties({
            confirmFilterApplied: true,
            appliedFilter: filterName
        });
        // Hide the info box after some time passes
        later(() => {
            this.setProperties({
                confirmFilterApplied: false,
                appliedFilter: null
            })
        }, 6000);
    }

    submitQuery() {};
    removeQuery() {};
    applyFilterToAll() {};
    datasetChanged() {};

    @action
    onApplyFilterToAll(filter, sourceQuery) {
        this.setFilterAppliedInfo(filter.id);
        this.applyFilterToAll(filter, sourceQuery);
    }

    @action
    onApplyModifierToAll(modifier, value, sourceQuery) {
        this.setFilterAppliedInfo(modifier);

        this.applyModifierToAll(modifier, value, sourceQuery);
    }

    @action
    andItemsChanged(items){
        let query = this.get("query.queryInfo"),
            andItems = query.get("queryBuilderDetail.andItems");
        andItems.clear();
        andItems.loadJSON(items);
    }

    @action
    orItemsChanged(items){
        let query = this.get("query.queryInfo"),
            orItems = query.get("queryBuilderDetail.orItems");
        orItems.clear();
        orItems.loadJSON(items);
    }

    @action
    notItemsChanged(items){
        let query = this.get("query.queryInfo"),
            notItems = query.get("queryBuilderDetail.notItems");
        notItems.clear();
        notItems.loadJSON(items);
    }

    @action
    onSubmitQuery(){
        this.submitQuery();
    }

    @action
    onRemoveQuery(query){
        this.removeQuery(query);
    }

    @action
    toggleIsActive(){
        this.toggleProperty("isActive");
    }

    @action
    removeDrillIn(drillInItem) {
        var drillInItems = this.get("query.queryInfo.queryBuilderDetail.drillInItems");
        if (drillInItem && drillInItems) {
            drillInItems.removeObject(drillInItem);
        }
    }

    @action
    onCopyQuery(){
        if(this.canCopy){
            this.copyQuery(this.query);
        }
    }

    @action
    setQueryColor(newColor) {
        var color = this.get("query.color");
        color.loadJSON(newColor)
        this.set("isEditingColor",false);
    }

    @action
    onLoadFilterset(filterset) {
        this.query.queryInfo.loadFilterset(filterset);
    }

    @action
    onBeginSaveFilterset() {
        this.set("isSavingFilterSets", true);
    }

    @action
    onCancelSaveFilterset() {
        this.set("isSavingFilterSets", false);
    }

    @action
    async onSaveFilterset() {
        this.user.loadFiltersets();

        this.setProperties({
            isSavingFilterSets: false,
            filtersetSaveSuccess: true
        });

        later(() => {
            this.set("filtersetSaveSuccess", false);
        }, 4000);
    }
}
