import Ember from "ember";
import {computed} from "@ember/object";
import JsonStore from "infegy-frontend/json-store/model";
import Prop from "infegy-frontend/json-store/properties/model";
import DefaultColorDefinitions from "infegy-frontend/static_data/fixtures/default_color_definitions";
import AtlasColors from "infegy-frontend/utils/atlas-colors";
import CSSTools from "infegy-frontend/utils/css-tools";
import tinycolor from "infegy-frontend/utils/tinycolor";
import classic from "ember-classic-decorator";

@classic
export default class QueryColor extends JsonStore {
    @Prop.String({default: "Custom"}) label;

    @Prop.Number({required: false, integer: true, min: 0, max: 255}) r;
    @Prop.Number({required: false, integer: true, min: 0, max: 255}) g;
    @Prop.Number({required: false, integer: true, min: 0, max: 255}) b;
    @Prop.Number({required: false, min: 0.0, max: 1.0}) a;

    @computed.alias("colorString") base;
    @computed.alias("darkColorString") dark;
    @computed.alias("lightColorString") light;

    @computed()
    get class(){
        console.groupCollapsed("Query Color Class");
        console.warn("Do not use classes for query colors");
        console.trace()
        console.groupEnd();
        return "";
    }

    @computed("r", "g", "b")
    get isEmpty(){
        return Ember.isEmpty(this.r) || Ember.isEmpty(this.g) || Ember.isEmpty(this.b);
    }

    @computed("a")
    get hasAlpha(){
        return !Ember.isEmpty(this.a);
    }

    @computed("r", "g", "b", "a")
    get colorString(){
        if (this.isEmpty) {
            return "";
        } else if (this.hasAlpha) {
            return `rgba(${this.r},${this.g},${this.b},${this.a})`;
        } else {
            return `rgba(${this.r},${this.g},${this.b})`;
        }
    }
    set colorString(value) {
        if (Ember.isEmpty(value)) {
            this.setProperties({ r: null, g: null, b: null, a: null });
            return;
        }
        let color = tinycolor(value);
        this.set("tinycolorObj", color);
        return color.toRgbString();
    }

    @computed("r", "g", "b", "a")
    get tinycolorObj() {
        return tinycolor({ r: this.r, g: this.g, b: this.b, a: this.a });
    }
    set tinycolorObj(tinycolorObj) {
        if (tinycolorObj && tinycolorObj.toRgb) {
            let rgb = tinycolorObj.toRgb();
            this.setProperties({
                r: rgb.r,
                g: rgb.g,
                b: rgb.b,
                a: rgb.a,
            });
        }
        return tinycolorObj;
    }

    @computed("tinycolorObj")
    get hex() {
        if (!this.isEmpty) {
            let color = this.tinycolorObj;
            return color.toHexString();
        }
    }
    set hex(value) {
        if (Ember.isEmpty(value)) {
            this.setProperties({ r: null, g: null, b: null, a: null });
            return;
        }
        let color = tinycolor(value);
        this.set("tinycolorObj", color);
        return color.toHexString();
    }

    @computed("tinycolorObj")
    get alternateColorString() {
        if (!this.isEmpty) {
            let color = this.tinycolorObj,
                luminance = color.getLuminance();
            return luminance > 0.5 ? this.darkColorString : this.lightColorString;
        }
    }

    @computed("tinycolorObj")
    get darkColorString(){
        if (!this.isEmpty) {
            let color = this.tinycolorObj,
                hsl = color.toHsl();
            hsl.l = hsl.l * 0.6;
            hsl.s = hsl.s * 1.4;
            return `hsl(${hsl.h},${hsl.s*100}%,${hsl.l*100}%)`;
        }
    }

    @computed("tinycolorObj")
    get lightColorString(){
        if (!this.isEmpty) {
            let color = this.tinycolorObj,
                hsl = color.toHsl();
            hsl.l = Math.max(hsl.l, 0.40) * 1.4;
            hsl.s = hsl.s * 0.8;
            return `hsl(${hsl.h},${hsl.s*100}%,${hsl.l*100}%)`;
        }
    }

    /** Takes in the index to assign a query and optionally queries
     *  to ensure a unique query color
     */
    loadDefaultColorForIndex(idx, queries, overrideEmptyCheck){
        idx = idx || 0;
        if(overrideEmptyCheck || this.isEmpty){
            let color = DefaultColorDefinitions[idx % DefaultColorDefinitions.length];
            if (Ember.isEmpty(queries)) {
                this.set("colorString", color.hex);
                return;
            }

            let compareTC = AtlasColors.compareTinyColor,
                matchedQuery = queries.find((query) => {
                    let queryTC = Ember.get(query,"color.tinycolorObj"),
                        colorTC = tinycolor(color.hex);
                    return compareTC(queryTC, colorTC);
                });
            if (matchedQuery){
                // if a query already has this color, search for a new one
                let matchedColor = DefaultColorDefinitions.find(colorDef => {
                    let matchedQuery = queries.find((query) => {
                        let queryTC = Ember.get(query,"color.tinycolorObj"),
                            colorTC = tinycolor(colorDef.hex);
                        return compareTC(queryTC, colorTC);
                    });
                    if (!matchedQuery) {
                        return colorDef;
                    }
                });
                // if no new color can be found, allow the original to be set (cases of queries.length > DefaultColorDefinitions.length)
                color = matchedColor || color || DefaultColorDefinitions[0];
            }
            this.set("colorString", color.hex);
        }
    }

    // Easy computed styles for use in templates
    @computed("colorString")
    get textStyle(){
        return CSSTools.buildStringFromObjectSafe({
            color: this.colorString
        });
    }

    @computed("colorString")
    get backgroundStyle(){
        return CSSTools.buildStringFromObjectSafe({
            backgroundColor: this.colorString
        });
    }

    @computed("colorString")
    get strokeStyle(){
        return CSSTools.buildStringFromObjectSafe({
            stroke: this.colorString
        });
    }

    @computed("colorString")
    get fillStyle(){
        return CSSTools.buildStringFromObjectSafe({
            fill: this.colorString
        });
    }
}
