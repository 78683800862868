import Ember from "ember";

export default Ember.Component.extend({
    classNames: ["workspace-editor"],

    workspaces: [],
    workspaceTitle: "",
    selectedWorkspaceId: null,

    editorOpen: false,
    updatingWorkspace: false,

    /*
    It is possible the query form's initial state does not have a workspace.
    In this case, selecting a workspace to update will also set the
    workspace on the query form.  In the case a user cancels, the workspace
    needs to be cleaned up to return the query form to its original state.
    */
    workspaceAdded: false,

    queryFormEmpty: false,
    saveDisabled: Ember.computed("workspaceTitle", "updatingWorkspace", function() {
        var title = this.workspaceTitle;
        if(this.updatingWorkspace)
            return false;
        return !title || title.length < 3;
    }),

    toggleEditingWorkspace: () => {},
    updateWorkspace() {},
    createWorkspace() {},

    actions: {
        selectWorkspace(id) {
            const selectedWorkspace = this.workspaces.findBy("id", id);
            this.setProperties({
                selectedWorkspaceId: id,
                workspaceTitle: selectedWorkspace.title
            });
        },
        saveWorkspace: function() {
            this.set("editorOpen", false);
            this.toggleEditingWorkspace(false);
            if (this.updatingWorkspace) {
                const selectedWorkspace = this.workspaces.findBy("id", this.selectedWorkspaceId);
                selectedWorkspace.title = this.workspaceTitle;
                this.updateWorkspace(selectedWorkspace);
            }
            else {
                this.createWorkspace(this.workspaceTitle);
            }
        },
        openEditor: function() {
            if(!this.queryFormEmpty){
                this.setProperties({
                    editorOpen: true,
                    workspaceTitle: ""
                });
                this.toggleEditingWorkspace(true);
            }
        },
        closeEditor: function() {
            this.setProperties({
                editorOpen: false,
                updatingWorkspace: false,
                selectedWorkspaceId: 0
            });
            this.toggleEditingWorkspace(false);
            if (this.workspaceAdded) {
                this.set("selectedWorkspace", null);
            }
        },
        onStartUpdateWorkspace() {
            this.set("updatingWorkspace", true);
            if (this.selectedWorkspaceId) {
                const selectedWorkspace = this.workspaces.findBy("id", this.selectedWorkspaceId);
                this.set("workspaceTitle", selectedWorkspace.title);
            }
        },
        onStartSaveWorkspace() {
            this.setProperties({
                updatingWorkspace: false,
                workspaceTitle: ""
            });
        }
    }
});
