import Component from "@ember/component";
import { action, computed } from "@ember/object";
import classic from "ember-classic-decorator";

@classic
export default class extends Component {
    // Input
    sheet = null;

    pageSize = 20;

    @computed.readOnly("sheet.columns") columns;
}
