import Ember from "ember";
import DataSeries from "infegy-frontend/models/data_series/data_series";
import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import QueryAPIResult from "infegy-frontend/models/queries/query_api_result";
import DataSeriesComputedFieldDistribution from "infegy-frontend/models/data_series/data_series_computed_field_distribution";

var tagDataSeriesDescription = DataSeriesDescription.load({
    "timestamp": "timestamp",

    totalDocuments: "number",
    totalSentences: "number",
    totalUniverse: "number",
    totalWords: "number",

    documents: "number",
    sentences: "number",
    universe: "number",
    words: "number",

    positiveDocuments: "number",
    negativeDocuments: "number",
    neutralDocuments: "number",

    followers: "number",
    following: "number",
    impressions: "number",
    reach: "number",

    age: "average",
    male: "number",
    female: "number",
}, [
    DataSeriesComputedFieldDistribution.fieldSetup(["documents", "totalDocuments"]),
    DataSeriesComputedFieldDistribution.fieldSetup(["universe", "totalUniverse"]),
    DataSeriesComputedFieldDistribution.fieldSetup(["positiveDocuments", "negativeDocuments"]),
    DataSeriesComputedFieldDistribution.fieldSetup(["female", "male"]),
    DataSeriesComputedFieldDistribution.fieldSetup(["femaleUniverse", "maleUniverse"]),
]);

var TagDataObject = Ember.Object.extend({
    tagName: "",
    querySetId: null,
    query: "",

    rawData: [],
    dataSeries: {},

    documentsDistribution: Ember.computed.alias("dataSeries.stats.documentsDistribution.average"),
    universeDistribution: Ember.computed.alias("dataSeries.stats.universeDistribution.average"),
    maleDistribution: Ember.computed.alias("dataSeries.stats.maleDistribution.average"),
    femaleDistribution: Ember.computed.alias("dataSeries.stats.femaleDistribution.average"),
    positiveDocumentsDistribution: Ember.computed.alias("dataSeries.stats.positiveDocumentsDistribution.average"),
    totalDocumentsDistribution: Ember.computed.alias("dataSeries.stats.totalDocumentsDistribution.average"),

    female: Ember.computed.alias("dataSeries.stats.female.sum"),
    male: Ember.computed.alias("dataSeries.stats.male.sum"),

    genderFemaleDistribution:Ember.computed("female", "male", function () {
        let male = this.get("male"),
            female = this.get("female");
        return female / (male + female);
    }),
    genderMaleDistribution:Ember.computed("female", "male", function () {
        let male = this.get("male"),
            female = this.get("female");
        return male / (male + female);
    }),

    reach: Ember.computed.alias("dataSeries.stats.reach.sum"),
    impressions: Ember.computed.alias("dataSeries.stats.impressions.sum"),

    following: Ember.computed.alias("dataSeries.stats.following.sum"),
    followers: Ember.computed.alias("dataSeries.stats.followers.sum"),

    words: Ember.computed.alias("dataSeries.stats.words.sum"),
    universe: Ember.computed.alias("dataSeries.stats.universe.sum"),
    sentences: Ember.computed.alias("dataSeries.stats.sentences.sum"),
    documents: Ember.computed.alias("dataSeries.stats.documents.sum"),

    age: Ember.computed("dataSeries.data", function () {
        var data = this.get("dataSeries.data") || [],
            total = this.get("dataSeries.stats.age.sum");
        return total / (data.filterBy("age").length || 1);
    }),
});


export default QueryAPIResult.extend({
    title : "subquerySets",

    tagNames: [],

    tagData: {},

    tagDataSeriesDescription: tagDataSeriesDescription,

    preload() {
        var rawResponse = this.get("rawResults") || {},
            dates = rawResponse.dates,
            totals = rawResponse.totals,
            tagNames = [],
            fieldNames = [];

        var rowTotals = dates.map((date, idx) => {
            return {
                timestamp: Date.UTC(...date.split(/[^0-9]/)),
                totalDocuments: totals.documents[idx],
                totalSentences: totals.sentences[idx],
                totalUniverse: totals.universe[idx],
                totalWords: totals.words[idx],
            };
        });

        // Get all the subquerySets
        for (let key in rawResponse) {
            if (rawResponse.hasOwnProperty(key) && key !== "dates" && key !== "totals") {
                tagNames.push(key);
            }
        }

        if (Ember.isEmpty(tagNames)) {
            this.set("isEmpty", true);
            return;
        }

        // Get all the fields
        var firstTag = rawResponse[tagNames[0]];
        for (let key in firstTag) {
            if (firstTag.hasOwnProperty(key)) {
                fieldNames.push(key);
            }
        }

        // build tag data
        var subquerySetsData = tagNames.map(tagName => {
            var tagSource = rawResponse[tagName],
                data = dates.map((date, idx) => {
                    let row = Object.assign({}, rowTotals[idx]);
                    fieldNames.forEach(fieldName => {
                        row[fieldName.camelize()] = tagSource[fieldName][idx];
                    });
                    return row;
                });
            var tagData = TagDataObject.create();

            tagData.setProperties({
                querySetId: tagSource.query_set_id,
                query: tagSource.query,
                rawData: data,
                dataSeries: DataSeries.load(data, tagDataSeriesDescription),
                tagName: tagName
            });
            return tagData;
        });

        this.setProperties({
            tagNames: tagNames,
            tagData: subquerySetsData,
        });
    },

    description: DataSeriesDescription.load({
        "dates": "array",
        "totals": "array",
    })
});
