import Component from "@ember/component";
import { action, computed } from "@ember/object";
import { isEmpty } from "@ember/utils";
import { later } from "@ember/runloop";

import Filterset from "infegy-frontend/models/users/filterset";

export default class FiltersetSaver extends Component {
    // Input
    filtersets = [];
    query = null;

    // Internal
    newFiltersetTitle = "";
    selectedFiltersetId = 0;
    isUpdatingFilterset = false;
    saveErrorMessage = "";

    cancel() {}
    save() {}

    @computed("selectedFiltersetId", "filtersets", "filtersets.length")
    get selectedFilterset() {
        const found = this.filtersets.find((filterset) => filterset.id === this.selectedFiltersetId);
        return found;
    }

    @computed("isUpdatingFilterset", "newFiltersetTitle")
    get isSaveDisabled() {
        if (this.isUpdatingFilterset)
            return false;
        return isEmpty(this.newFiltersetTitle);
    }

    @action
    onCancel() {
        this.cancel();
    }

    @action
    async onSave() {
        // jjw TODO: What do outside components need to know?
        // Save - reload the list
        // That's it?
        try {
            if (!this.isUpdatingFilterset) {
                let newFilterset = Filterset.create();
                await newFilterset.save({
                    queryInfo: this.query.queryInfo,
                    title: this.newFiltersetTitle
                });
            } else {
                await this.selectedFilterset.save({
                    queryInfo: this.query.queryInfo
                });
            }
        } catch (error) {
            this.set("saveErrorMessage", error.atlasErrorText);
            later(() => {
                if (!this.isDestroyed || !this.isDestroying) {
                    this.set("saveErrorMessage", "");
                }
            });
        }

        this.save();
    }
};
