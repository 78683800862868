import Component from "@ember/component";
import { action } from "@ember/object";

export default class extends Component {
    // Inputs
    sheet = null;
    file = null;

    @action onToggleExcludeSheet() {
        this.toggleProperty("sheet.exclude");
    }
}