import Ember from "ember";
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { run } from '@ember/runloop';

export default class FieldBase extends Component {
    classNameBindings = ["selectorClass"];

    apiName = null;
    queries = null;

    selectedFields = null;

    selectedSegment = null;
    selectedSubsegment = null;
    selectedFieldComponent = null;

    displayAsDropdown = false;

    minSelection = 1;
    maxSelection = 6;

    @computed("displayAsDropdown")
    get selectorClass() {
        return this.displayAsDropdown ? "inline-field-select" : "toggle-field-select";
    };

    @computed("apiName", "queries", "queries.[]", "query", "queries.@each.updated", "query.updated")
    get allQueries() {
        return this.queries;
    };

    @computed("maxSelection", "allQueries")
    get maxFieldSelection() {
        if (this.isScalarSelector || (this.allQueries && this.allQueries.length > 1)) {
            return 1;
        }
        return this.maxSelection;
    };

    @computed("selectedFields", "selectedFields.[]", "selectedFields.@each.value")
    get firstSelectedField() {
        return this.selectedFields && this.selectedFields[0];
    }

    @computed("firstSelectedField", "firstSelectedField.additionalSelectorProperties")
    get firstSelectedFieldAdditionalSelectorProperties() {
        return this.firstSelectedField && this.firstSelectedField.additionalSelectorProperties || {};
    }

    makeDefaultFieldSelection(availableFields) {
        let selectionSize = this.maxSelection || 1;
        if (this.displayAsDropdown) {
            selectionSize = 1;
        }
        return availableFields && availableFields.length && availableFields.slice(0, selectionSize);
    }

    fieldsChanged(selectedFields) { /* Empty for Action */ };
};
