import Ember from "ember";

export default Ember.Component.extend({
    classNameBindings: [":post-highlighter-box-info", "hoverBoxIsVisible:post-highlighter-box-info-visible"],
    attributeBindings: ["style"],

    hoverBoxIsVisible: false,

    hoveredElement: null,
    containerElement: null,
    hoverClientX: 0,
    hoverClientY: 0,

    _lastPosition: {},

    position:  Ember.computed("hoveredElement", "containerElement",
            "hoverClientX", "hoverClientY", function() {
        var hoveredElement = this.hoveredElement,
            containerElement = this.containerElement,
            hoverClientX = this.hoverClientX,
            hoverClientY = this.hoverClientY;



        if (!hoveredElement || !containerElement ||
            Ember.isEmpty(hoverClientX) || Ember.isEmpty(hoverClientY)) {
            return this._lastPosition;
        }
        var elBounds = hoveredElement.getBoundingClientRect(),
            contBounds = containerElement.getBoundingClientRect(),
            position = {};
        if (hoverClientY > (window.innerHeight / 2.0)) {
            position.bottom = contBounds.bottom - elBounds.y;
        } else {
            position.top = (elBounds.y - contBounds.y) + elBounds.height;
        }

        if (hoverClientX > (window.innerWidth / 2.0)) {
            position.right = contBounds.right - hoverClientX;
        } else {
            position.left = hoverClientX - contBounds.x;
        }

        this.set("_lastPosition");
        return position;
    }),

    style: Ember.computed("position", function() {
        var pos = this.position || {},
            posString = "";
        if (!Ember.isEmpty(pos.top)) {
            posString += `top:${pos.top}px;`;
        } else if (!Ember.isEmpty(pos.bottom)) {
            posString += `bottom:${pos.bottom}px;`;
        }
        if (!Ember.isEmpty(pos.left)) {
            posString += `left:${pos.left}px`;
        } else if (!Ember.isEmpty(pos.right)) {
            posString += `right:${pos.right}px`;
        }
        return Ember.String.htmlSafe(posString);
    }),
});
