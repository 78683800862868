import Ember from 'ember';
import DateProcessing from "infegy-frontend/utils/date-processing";

export function groupingDate(params, namedArgs) {
    if (!window.moment) {
        return params[0];
    }
    let grouping = namedArgs.grouping || "daily",
        value = namedArgs.timestamp || params[0] || 0,
        isNormalized = namedArgs.normalized,
        result = "",
        incrementName = "Day";

    if (grouping === "dayOfWeek" || grouping === "weekday") {
        incrementName = "Weekday";
        result = DateProcessing.constants.numberToWeekday[value];
    } else if (grouping === "weekly" || grouping === "week") {
        result = moment.utc(value).format("YYYY/M/D");
        result = "Week of " + result;
        incrementName = "Week";
    } else if (grouping === "monthly" || grouping === "month") {
        result = moment.utc(value).format("MMMM, YYYY");
        incrementName = "Month";
    } else if (grouping === "yearly" || grouping === "year") {
        result = moment.utc(value).format("YYYY");
        incrementName = "Year";
    } else if (grouping === "quarterly" || grouping === "quarter") {
        result = "Q" + moment.utc(value).quarter() + " " + moment.utc(value).format("YYYY");
        incrementName = "Quarter";
    } else if (grouping === "hour" || grouping === "timeOfDay") {
        if (value <= 24) {
            result = `${Math.round(value)}:00`;
        } else {
            result = moment.utc(value).format("YYYY/M/D h:00 A");
        }
        incrementName = "Hour";
    } else if (grouping === "minute") {
        result = moment.utc(value).format("YYYY/M/D h:mm A");
        incrementName = "Minute";
    } else {
        result = moment.utc(value).format("MMM Do, YYYY");
    }

    if (isNormalized) {
        if (value != 1 && incrementName) {
            incrementName += "s";
        }
        return `${value || 0} ${incrementName}`;
    }

    return result;
}

export default Ember.Helper.helper(groupingDate);
