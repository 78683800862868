import Ember from "ember";
import D3SVGContainerComponent from "infegy-frontend/components/charting/d3-base/d3-svg-container/component";
import d3 from "d3";

export default D3SVGContainerComponent.extend({
    tagName: "g",

    dataSeries: null,

    xAttr: "timestamp",
    upperField: null,
    lowerField: null,
    upperColorClass: "chart-positive",
    lowerColorClass: "chart-negative",

    drawArea: true,
    drawLine: true,
    drawCircles: true,

    hoveredIndex: null,
    selectedIndex: null,

    isHovered: Ember.computed.notEmpty("hoveredIndex"),
    isSelected: Ember.computed.notEmpty("selectedIndex"),

    chartData: Ember.computed("dataSeries.data", "upperField", "lowerField", "xAttr",
            "upperColorClass", "lowerColorClass", function () {
        var data = this.get("dataSeries.data") || [],
            upperField = this.get("upperField"),
            lowerField = this.get("lowerField"),
            upperColorClass = this.get("upperColorClass"),
            lowerColorClass = this.get("lowerColorClass"),
            xAttr = this.get("xAttr"),
            lastRow = null,
            hasPositive = false,
            hasNegative = false,
            xBounds = {max: -Infinity, min: Infinity },
            yBounds = {max: -Infinity, min: Infinity },
            pointValues = [],
            lineValues = [];

        data.forEach(row => {
            var netValue = (row[upperField] || 0) - (row[lowerField] || 0),
                xValue = row[xAttr] || 0,
                innerXValue = row[xAttr];

            // Bounds for chart data, faster this way than separate Math.max(...array) calls
            xBounds.max = Math.max(xBounds.max, xValue);
            xBounds.min = Math.min(xBounds.min, xValue);
            yBounds.max = Math.max(yBounds.max, netValue);
            yBounds.min = Math.min(yBounds.min, netValue);

            // Check if there is negative or positive chart data
            if (netValue >= 0) {
                hasPositive = true;
            }
            if (netValue < 0) {
                hasNegative = true;
            }

            // If line crosses the axis, add an intermediate point at
            // y = 0 and interpolated x to the lineValues
            if (!Ember.isEmpty(lastRow) && lastRow.y && !Ember.isEmpty(lastRow.x)) {
                if ((lastRow.y > 0 && netValue < 0) ||
                    (lastRow.y <= 0 && netValue > 0)) {
                    var denom = (netValue - lastRow.y);
                    if (denom) {
                        innerXValue = (netValue * lastRow.x - lastRow.y * xValue) / denom;
                    } else {
                        innerXValue = 0.5 * (xValue - lastRow.x) + lastRow.x;
                    }
                    lineValues.push({ y: 0, x: innerXValue });
                }
            }

            lastRow = {
                y: netValue,
                x: xValue,
                colorClass: (netValue < 0) ? lowerColorClass : upperColorClass
            };

            lineValues.push(lastRow);
            pointValues.push(lastRow);
        }, []);

        yBounds.min = (yBounds.min > 0) ? 0 : yBounds.min;
        yBounds.max = (yBounds.max < 0) ? 0 : yBounds.max;

        return {
            lineValues: lineValues,
            pointValues: pointValues,
            xBounds: xBounds,
            yBounds: yBounds,
            hasPositive: hasPositive,
            hasNegative: hasNegative
        };
    }),

    xScale: Ember.computed("contentArea.width", "chartData.xBounds", function () {
        var xBounds = this.get("chartData.xBounds");
        return d3.scaleLinear()
            .domain([xBounds.min || 0, xBounds.max || 10])
            .range([0, this.get("contentArea.width")]);
    }),

    yScale: Ember.computed("contentArea.height", "chartData.yBounds", function () {
        var height = this.get("contentArea.height"),
            max = this.get("chartData.yBounds.max") || 0,
            min = this.get("chartData.yBounds.min") || 0;

        if (max === 0 && min === 0) {
            max = 1.0;
            min = -1.0;
        }

        return d3.scaleLinear()
                .domain([max, min])
                .range([0, height]);
    }),

    axisAreaHeight: Ember.computed("yScale", function () {
        var yScale = this.get("yScale");
        return yScale(0);
    }),

    pathData: Ember.computed("chartData.lineValues", "xScale", "yScale", function () {
        var xScale = this.get("xScale"),
            yScale = this.get("yScale"),
            lineValues = this.get("chartData.lineValues") || [],
            paths = {};

        var line = d3.line()
                    .x(function(d) { return xScale(d.x || 0); })
                    .y(function(d) { return yScale(d.y || 0); })
                    .defined(function(d) { return d.y >= 0; })
                    .curve(d3.curveMonotoneX);

        var area = d3.area()
                    .x(function(d) { return xScale(d.x || 0); })
                    .y0(yScale(0))
                    .y1(function(d) { return yScale(d.y || 0); })
                    .defined(function(d) { return d.y >= 0; })
                    .curve(d3.curveMonotoneX);

        paths.upperPath = line(lineValues) || 'M 0 0';
        paths.upperArea = area(lineValues) || 'M 0 0';

        line.defined(function(d) { return d.y <= 0; });
        area.defined(function(d) { return d.y <= 0; });

        paths.lowerPath = line(lineValues) || 'M 0 0';
        paths.lowerArea = area(lineValues) || 'M 0 0';

        return paths;
    }),

    pointCircles: Ember.computed("chartData.pointValues", "xScale", "yScale", function () {
        var data = this.get("chartData.pointValues") || [],
            xScale = this.get("xScale"),
            yScale = this.get("yScale");

        return data.map(row => {
            return {
                y: yScale(row.y),
                x: xScale(row.x),
                colorClass: row.colorClass
            };
        }, []);
    }),

    hoveredLineX: Ember.computed("xScale", "hoveredIndex", function () {
        var xScale = this.get("xScale") || [],
            data = this.get("chartData.pointValues") || [],
            index = this.get("hoveredIndex") || 0,
            xVal = !Ember.isEmpty(index) && data[index] && data[index].x || 0;
        return xScale(xVal);
    }),

    selectedLineX: Ember.computed("xScale", "selectedIndex", function () {
        var xScale = this.get("xScale") || [],
            data = this.get("chartData.pointValues") || [],
            index = this.get("selectedIndex") || 0,
            xVal = !Ember.isEmpty(index) && data[index] && data[index].x || 0;
        return xScale(xVal);
    }),

    getInteractionInfo(mouseX) {
        var info = {
            index: null,
            netValue: null,
            dominantField: null
        };
        if (!Ember.isEmpty(mouseX)) {
            let dataRange = (this.get("dataSeries.data.length") || 0) - 1,
                pointValues = this.get("chartData.pointValues") || [];
            info.index = Math.floor((mouseX * dataRange) + 0.5) || 0;
            info.netValue = pointValues.hasOwnProperty(info.index) &&
                pointValues[info.index] || 0;
            info.dominantField = info.netValue >= 0 ? this.get("upperField") : this.get("lowerField");
        }
        return info;
    },

    // Sends up detailed interaction information including index, value, and dominantField
    wasHovered() { /* action placeholder */ },
    wasSelected() { /* action placeholder */ },

    actions: {
        wasHovered(hoverInfo) {
            var interactionInfo = this.getInteractionInfo(hoverInfo.mouseXPct || 0);
            this.wasHovered(hoverInfo.isHovered ? interactionInfo : null);
        },
        wasSelected(clickInfo) {
            var selectedIndex = this.get("selectedIndex"),
                interactionInfo = this.getInteractionInfo(clickInfo.mouseXPct || 0);
            this.wasSelected(selectedIndex !== interactionInfo.index ? interactionInfo : null);
        },
    }
});
