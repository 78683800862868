import Ember from "ember";
import ChartColors from "infegy-frontend/static_data/fixtures/chart_colors";

export default function(fields, colorProperty, defaultColorProperty) {
    colorProperty = colorProperty || "colorClass";
    defaultColorProperty = defaultColorProperty || "defaultColorClass";
    if (!fields || !fields.length) {
        return;
    }

    var availableColors = ChartColors.get("indexedColorClasses").slice(),
        unusedColorClasses = ChartColors.get("baseColorClasses").slice(),
        colorMap = ChartColors.get("colorMap"),
        colorAliases = [];

    var ensureUniqueFieldColor = function(field, colorClass, skipReplacement) {
        colorClass = colorClass || (field && Ember.get(field, colorProperty));
        if (!field) {
            return;
        }
        // Use the field's existing color if possible.
        if ((!colorClass || !unusedColorClasses.includes(colorClass)) && availableColors.length) {
            colorClass = skipReplacement ? null : availableColors.shift();
        }
        Ember.set(field, colorProperty, colorClass);
        var colorObj = colorMap[colorClass];
        if (colorObj) {
            colorAliases = colorObj.classAliases || [];
            unusedColorClasses = unusedColorClasses.filter(function(unusedClass) {
                return !colorAliases.includes(unusedClass);
            });
            availableColors = availableColors.filter(function(unusedClass) {
                return !colorAliases.includes(unusedClass);
            });
        }
    };

    // First maintain previously set colors if possible (prevent changing user selections)
    fields.forEach(function(field) {
        var colorClass = field && Ember.get(field, colorProperty);
        if (colorClass && colorMap[colorClass]) {
            ensureUniqueFieldColor(field, colorClass, true);
        }
    });

    // Next set colors for fields with preferred values -- they get first pick.
    fields.forEach(function(field) {
        var colorClass = field && Ember.get(field, defaultColorProperty);
        if (field && !Ember.get(field, colorProperty) && colorClass && colorMap[colorClass]) {
            ensureUniqueFieldColor(field, colorClass);
        }
    });

    // Finally set colors for all unassigned fields
    fields.forEach(function(field) {
        var colorClass = field && Ember.get(field, colorProperty);
        if (!colorClass) {
            ensureUniqueFieldColor(field);
        }
    });

    return fields;
}
