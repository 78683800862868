import Ember from "ember";
import { computed } from '@ember/object';
import ValidationList from "infegy-frontend/components/forms/password-validation/validation-list/component";

export default class ValidationButton extends ValidationList {
    newPassword = '';
    confirmPassword = '';

    @computed("validCharacters", "validUpper", "validLower", "validDigit", "validSymbol", "newPassword", "confirmPassword")
    get isValidPassword() {
        // When all validation conditions return True, Enable submit button on UI.
        if (!(this.newPassword === this.confirmPassword)) {
            return !(this.validCharacters && this.validUpper && this.validLower && this.validDigit && this.validSymbol);
        }
    }

}
