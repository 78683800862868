import Ember from 'ember';
import Component from "@ember/component";
import AtlasAuth from 'infegy-frontend/utils/atlas-auth';
import { action, computed } from '@ember/object';
import { isEmpty } from "@ember/utils";
import { tracked } from '@glimmer/tracking';
import { later } from "@ember/runloop";
/** @typedef {import("infegy-frontend/models/queries/single_request_query").default} Query */

import Snippet from "infegy-frontend/models/users/snippet";
import Filterset from "infegy-frontend/models/users/filterset";

export default class extends Component {
    classNameBindings = [":query-part", "showColorPicker:show-color-editor"];

    canRemove = true;
    // filterset = Filterset.create()
    @tracked isSavingQuery = false;

    get user(){
        return AtlasAuth.user;
    }
    @computed.oneWay("user.audienceSegments") audienceSegments;
    @computed.oneWay("user.customDatasets") customDatasets;
    @computed.oneWay("user.dictionaries") dictionaries;
    @computed.oneWay("user.filtersets") filtersets;
    @computed.readOnly("user.savedQueries") savedQueries;

    /** @type {Query|null} */
    query = null;
    isAudienceQuery = false;
    queries = null;
    showColorPicker = false;
    confirmFilterApplied = false;
    appliedFilter = "";

    showSnippets = false;

    previewShown = false;
    @computed.alias("query.isBlank") previewDisabled;
    canCopy = false;
    copyQuery = ()=>{/* action */};

    hasErrors = null;

    @computed("this.parentView.canvasQueryId")
    get hideColor() {
        if (this?.parentView && this?.parentView?.canvasQueryId) {
            return true;
        }
        return false;
    }

    @computed("query.queryInfo.analyzeFields.length", "query.queryInfo.dictionaries.length", "query.queryInfo.filters.length", "query.queryInfo.subquerySets.length")
    get hasFilters() {
        const hasFilter = this.query?.queryInfo?.filters?.length > 0;
        const hasAnalyzeFields = !isEmpty(this.query?.queryInfo?.analyzeFields);
        const hasDictionaries = !isEmpty(this.query?.queryInfo?.dictionaries);
        const hasSubquerySets = !isEmpty(this.query?.queryInfo?.subquerySets);
        return hasFilter || hasAnalyzeFields || hasDictionaries || hasSubquerySets;
    }

    setFilterAppliedInfo(filterName) {
        this.setProperties({
            confirmFilterApplied: true,
            appliedFilter: filterName
        });
        // Hide the info box after some time passes
        later(() => {
            this.setProperties({
                confirmFilterApplied: false,
                appliedFilter: null
            })
        }, 6000);
    }

    submitQuery = () => {/* action */};
    removeQuery = () => {/* action */};
    applyFilterToAll = (filterName, sourceQuery) => {/* action */};
    applyModifierToAll = (modifier, value, sourceQuery) => { /* action */ }
    datasetChanged = () => {/* action */};

    @action
    onApplyFilterToAll(filter, sourceQuery) {
        this.setFilterAppliedInfo(filter.id);
        this.applyFilterToAll(filter, sourceQuery);
    }

    @action
    onApplyModifierToAll(modifier, value, sourceQuery) {
        this.setFilterAppliedInfo(modifier);

        this.applyModifierToAll(modifier, value, sourceQuery);
    }

    @action
    togglePreview() {
        if (!this.previewDisabled) {
            this.toggleProperty('previewShown');
        } else {
            this.set('previewShown', false);
        }
    }
    @action async onToggleSaveQuery() {
        this.toggleProperty("query.isSaved");
        await this.query.save();
        this.savedQueries.getPage();
    }
    @action async saveQuery() {
        this.isSavingQuery = true;
        this.set("query.isSaved", true);
        await this.query.save();
        this.savedQueries.getPage();

        Ember.run.later(() => {
            this.isSavingQuery = false;
        }, 5000);
    }
    @action
    removeQuery(query) {
        if (this.canRemove) {
            this.removeQuery(query);
        }
    }
    @action
    selectedFiltersChanged(filterValues) {
        var isAudienceQuery = this.isAudienceQuery;
        if (isAudienceQuery) {
            filterValues.push("sourceQuery");
        }
        this.set("query.queryFilters.activeFormFilterNames", filterValues);
    }
    @action
    errorsUpdated(errorStatus) {
        this.set("query.hasErrors",errorStatus);
    }
    @action
    updateBooleanQuery(queryString) {
        this.set("query.queryString",queryString);
    }
    @action
    copyQuery(){
        if (this.canCopy) {
            this.copyQuery(this.query);
        }
    }
    @action
    loadFilterset(filterset) {
        this.query.queryInfo.loadFilterset(filterset);
    }
    @action
    async saveSnippet(snippetName){
        let newSnippet = Snippet.create();
        newSnippet.title = snippetName;
        newSnippet.queryInfo.loadJSON(this.query.filters.toJSON());
        await newSnippet.save();
        this.user.snippets.getPage();
    }
    @action
    async updateSnippet(snippet){
        snippet.queryInfo.loadJSON(this.query.filters.toJSON());
        await snippet.save();
        this.user.snippets.getPage();
    }
    @action
    setQueryColor(newColor){
        var color = this.get("query.color");
        color.loadJSON(newColor);
        this.set("isEditingColor",false);
    }

    @action
    onBeginSaveFilterset() {
        this.set("isSavingFilterSets", true);
    }

    @action
    onCancelSaveFilterset() {
        this.set("isSavingFilterSets", false);
    }

    @action
    async onSaveFilterset() {
        this.user.loadFiltersets();

        this.setProperties({
            isSavingFilterSets: false,
            filtersetSaveSuccess: true
        });

        later(() => {
            this.set("filtersetSaveSuccess", false);
        }, 4000);
    }
}
