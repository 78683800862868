import Ember from "ember";
import InfegyAppConfig from "infegy-frontend/config/infegy-app-config";

export default Ember.Component.extend({
    classNameBindings: [":custom-dataset-table-row","editingItem:is-editing"],
    dataset: null,
    tagName: "tr",
    editingItem:false,

    itemSelected: () => {},
    itemChecked: () => {},
    toggleEditingItem: () => {},
    appendToDataset: () => {},
    deleteCustomDataset: () => {},
    actions: {
        itemSelected(val){
            this.itemSelected(val);
        },
        itemChecked(){
            this.itemChecked(this.get("dataset.id"));
        },
        toggleEditingItem(){
            this.toggleEditingItem(this.dataset);
        },
        toggleShowDeleteConfirmation(){
            this.toggleProperty("showDeleteConfirmation");
        },
        appendToDataset(){
            this.appendToDataset(this.dataset);
        },
        deleteCustomDataset(){
            this.deleteCustomDataset(this.dataset);
        }
    }
});
