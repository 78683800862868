import Component from '@ember/component';
import {computed, action} from "@ember/object";
import classic from "ember-classic-decorator";

@classic
export default class TrendsFieldSelector extends Component {
    user = null;
    queries = null;

    useCustomFields = false;

    singleQueryFields = null;
    multiQueryField = null;

    customSingleQueryFields = null;
    customMultiQueryFields = null;

    @computed("queries.length")
    get isMultiQuery() {
        return this.queries?.length > 1;
    }

    @computed("singleQueryFields", "isMultiQuery", "multiQueryField", "queries",
        "useCustomFields", "customSingleQueryFields", "customMultiQueryFields")
    get selectedFields() {
        if (this.useCustomFields) {
            return this.isMultiQuery ? this.customMultiQueryFields : this.customSingleQueryFields;
        }
        if (this.isMultiQuery) {
            return this.multiQueryField ? [this.multiQueryField] : null;
        }
        return this.singleQueryFields;
    }

    singleQueryFieldsChanged() { /* action */ }
    multiQueryFieldChanged() { /* action */ }
    customSingleQueryFieldsChanged() { /* action */ }
    customMultiQueryFieldsChanged() { /* action */ }

    colorize(fields) {

    }

    @action
    fieldsChanged(selectedFields) {
        if (this.isMultiQuery) {
            this.multiQueryFieldChanged(selectedFields ? selectedFields[0] : null);
        } else {
            this.singleQueryFieldsChanged(selectedFields);
        }
    };

    @action
    customFieldsChanged(selectedFields) {
        if (this.isMultiQuery) {
            this.customMultiQueryFieldsChanged(selectedFields);
        } else {
            this.customSingleQueryFieldsChanged(selectedFields);
        }
    };

    toggleCustomFields() { /* action */ }
};
