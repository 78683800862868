import CodeMirrorComponent from "infegy-frontend/components/design/codemirror-input/component";
import { computed, action } from '@ember/object';

var QueryStringCodeMirrorDefaultOptions = {
    theme: "atlas",
    lineWrapping: true,
    autoCloseBrackets: '()""'
};

export default class extends CodeMirrorComponent {
    classNames =  ["cm-atlas-container"];

    @computed.alias("value") queryString;

    mode = "atlas_query";

    valueChanged = () => { /* action */};
    errorsUpdated = () => { /* action */};
    warningsUpdated = () => { /* action */};

    searchFields = null;

    // options = QueryStringCodeMirrorDefaultOptions;

    @computed("searchFields", "searchFields.[]")
    get options() {
        let searchFields = this.searchFields,
            options = Object.assign({}, QueryStringCodeMirrorDefaultOptions);
        if (searchFields) {
            options.atlasSearchFields = searchFields;
        }
        return options;
    }

    checkSyntax(){
        var cm = this._codeMirror,
            tokens = cm && cm.getLineTokens(0) || [],
            hasErrors = false,
            hasWarnings = false;


        hasErrors = tokens.reduce((pvTokens, token) => {
            var types = token.type || '';
            return pvTokens || types.split(' ').reduce((pvType, type) => pvType || type === 'error', false);
        }, false);

        hasWarnings = tokens.reduce((pvTokens, token) => {
            var types = token.type || '';
            return pvTokens || types.split(' ').reduce((pvType, type) => pvType || type === 'warning', false);
        }, false);

        if(hasWarnings){
            this.send("warningsUpdated", { type:"syntax" });
        } else {
            this.send("warningsUpdated",null);
        }
        if(hasErrors){
            this.send("errorsUpdated", { type:"syntax" });
        } else {
            this.send("errorsUpdated",null);
        }
    }

    @action
    valueChanged(value){
        this.checkSyntax();
        this.set("value",value);
        this.valueChanged(value);
    }

    @action
    errorsUpdated(error){
        this.errorsUpdated(error);
    }

    @action
    warningsUpdated(warning){
        this.warningsUpdated(warning);
    }
}
