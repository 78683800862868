import { A } from "@ember/array";
import ArrayProxy from "@ember/array/proxy";

import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import SharedResource from "infegy-frontend/models/users/shared_resource";
import classic from "ember-classic-decorator";
import * as Sentry from "@sentry/browser";

@classic
class SharedResources extends ArrayProxy {
    /** @type SharedResource[] */
    content = A();
    isLoading = false;

    async getForResource(resourceType, resourceId) {
        let response = null;
        try {
            this.set("isLoading", true);
            response = await AtlasAuth.Get({
                data: {
                    type: resourceType,
                    resource_id: resourceId
                },
                url: "api/v3/shared-resource/list"
            });
        }
        catch(error) {
            Sentry.captureMessage(`Failed to retrieve shared-resources for ${JSON.stringify(data)}`);
            this.set("isLoading", false);
            return;
        }

        this.fromJSON(response.output);
        this.set("isLoading", false);
    }

    fromJSON(jsonOutput) {
        const sharedResources = jsonOutput.map((json) => {
            let sharedResource = SharedResource.create();
            sharedResource.loadJSON(json);
            return sharedResource;
        });

        this.set("content", A(sharedResources));
    }
}

export default SharedResources;