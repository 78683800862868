import Ember from "ember";
import { action } from '@ember/object';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { MouseInteractionWrapper } from "infegy-frontend/components/charting/mouse-interaction-mixin/component";

export default class ToggleList extends MouseInteractionWrapper(Component) {
    classNameBindings = [":toggle-list", "themeClass", ":no-print"];
    //arguments
    options = null;
    minSelected = 1;
    maxSelected = 1;
    initiallySelected = 1;

    selectedValues = null;
    @computed.alias("selectedValues") selectedValue;

    titleCase = true;
    theme = null;
    colorDeselected = true;
    colorField = null;
    colorValueField = null;
    allowPrint = false;

    selectedValuesChanged = () => { /* action */ };
    selectedValueChanged = () => { /* action */ };
    selectedOptionsChanged = () => { /* action */ };
    hoveredValueChanged = () => { /* action */ };

    //properties
    _isDragging = false;
    _draggingActivates = false;

    @computed("selectedValues", "selectedValue", "minSelected",
            "maxSelected", "optionValues", "options", "options.[]", "initiallySelected")
    get innerSelectedValues(){
        var selectedValues = this.selectedValues,
            minSelected = this.minSelected,
            maxSelected = this.maxSelected,
            optionValues = this.optionValues || [],
            options = this.options,
            initiallySelected = this.initiallySelected,
            numToSelect,
            suggestedValues;
        if (typeof(selectedValues) === "string" || typeof(selectedValues) === "number") {
            selectedValues = [selectedValues];
        } else if (Ember.isArray(selectedValues)) {
            selectedValues = selectedValues.map(function(value) {
                return this.getOptionValue(value);
            }, this);
        } else {
            selectedValues = [];
        }
        selectedValues = selectedValues.filter(value => optionValues.includes(value));
        if (!selectedValues.length && initiallySelected && initiallySelected > minSelected) {
            numToSelect = initiallySelected;
            suggestedValues = options.filter(function(value) {
                if (!Ember.get(value, "inactiveByDefault") && numToSelect) {
                    numToSelect--;
                    return true;
                }
            }).map(option => {return this.getOptionValue(option);});
            Ember.run.scheduleOnce('afterRender',this,()=>this.updateSelection(suggestedValues));
        } else if (minSelected && selectedValues.length < minSelected) {
            numToSelect = minSelected - selectedValues.length;
            suggestedValues = optionValues.filter(function(value) {
                if (selectedValues.includes(value)) {
                    return value;
                } else if (numToSelect > 0) {
                    numToSelect--;
                    return value;
                }
            });
            Ember.run.scheduleOnce('afterRender',this,()=>this.updateSelection(suggestedValues));
        } else if (maxSelected && selectedValues.length > maxSelected) {
            Ember.run.scheduleOnce('afterRender',this,()=>this.updateSelection(selectedValues.slice(0, maxSelected)));
        }
        return selectedValues;
    }

    @computed("innerOptions", "innerOptions.[]")
    get optionValues(){
        return (this.innerOptions || []).map(option => {
            return this.getOptionValue(option);
        });
    }

    getOptionValue(option) {
        if (typeof(option) === "string" || typeof(option) === "number") {
            return option;
        } else if (!option || typeof(option) !== "object") {
            return;
        } else {
            var value = Ember.get(option, "value");
            if (Ember.isEmpty(value)) {
                 value = Ember.get(option, "title") ||
                    Ember.get(option, "label");
            }
            return value;
        }
    }

    @computed("theme")
    get themeClass(){
        var theme = this.theme || "default";
        return ["toggle-list-theme", theme].join("-");
    }

    @computed("options", "options.[]", "colorField")
    get innerOptions(){
        var innerOptions = this.options;
        if (typeof(innerOptions) === "string") {
            innerOptions = innerOptions.split(",").map(option => {return {value: option};});
        } else if (!Ember.isArray(innerOptions)) {
            innerOptions = [];
        } else if (innerOptions.find(option => typeof(option) === "string")) {
            innerOptions = innerOptions.map(function(option) {
                if (typeof(option) === "string" || typeof(option) === "number") {
                    return {value: option};
                }
                return option;
            }).filter(option => {return option && typeof(option === "object");});
        }
        if (!Ember.isNone(this.colorField)) {
            innerOptions.forEach(option=>{
                Ember.set(option,"color", option[this.colorField] ?? null);
            });
        }
        return innerOptions;
    }


    onMouseLeave() {
        if (this._isDragging) {
            this.set("_isDragging", false);
        }
    }
    mouseUp() {
        if (this._isDragging) {
            this.set("_isDragging", false);
        }
    }

    addToSelection(optionValue) {
        var innerSelectedValues = this.innerSelectedValues || [],
            maxSelected = this.maxSelected;
        if (Ember.isEmpty(optionValue) || (innerSelectedValues).includes(optionValue)) {
            return;
        }
        if (maxSelected >= 1 && innerSelectedValues.length >= maxSelected) {
            innerSelectedValues = innerSelectedValues.slice(0, maxSelected - 1);
        }
        innerSelectedValues.unshift(optionValue);
        this.updateSelection(innerSelectedValues);
    }

    removeFromSelection(optionValue) {
        var innerSelectedValues = this.innerSelectedValues || [],
            minSelected = this.minSelected;
        if (Ember.isEmpty(optionValue) || !(innerSelectedValues).includes(optionValue)) {
            return;
        }
        if (innerSelectedValues.length - 1 >= (minSelected || 0)) {
            innerSelectedValues = innerSelectedValues.without(optionValue);
            this.updateSelection(innerSelectedValues);
        }
    }

    getSelectedOptions(innerSelectedValues) {
        var options = this.innerOptions.filter(option => {
            return innerSelectedValues.includes(this.getOptionValue(option));
        }, this);
        return options;
    }


    @action
    updateSelection(selectedValues) {
        this.selectedValuesChanged(selectedValues);
        this.selectedValueChanged(selectedValues && selectedValues[0]);
        this.selectedOptionsChanged(this.getSelectedOptions(selectedValues));
    }

    @action
    optionActivated(optionValue) {
        var isSelected = (this.innerSelectedValues || []).includes(optionValue),
            isDragging = this.isDragging,
            draggingActivates = this._draggingActivates;
        if (!isDragging) {
            draggingActivates = !isSelected;
            this.setProperties({
                "_isDragging": true,
                "_draggingActivates": !isSelected,
            });
        }
        if (isSelected && !draggingActivates) {
            this.removeFromSelection(optionValue);
        } else if (!isSelected && draggingActivates){
            this.addToSelection(optionValue);
        }
    }

    @action
    optionHovered(optionValue) {
        if (!Ember.isEmpty(optionValue) && !this._isDragging) {
            this.hoveredValueChanged(optionValue);
        }
        this.hoveredValueChanged(null);
    }
}
