import Component from "@ember/component";
import { action } from "@ember/object";

export default class extends Component {
    // Input
    headerRowCount = 1;

    file = null;

    // Events
    cancel() {}
    changeFile(file) {}
    uploadSpreadsheet() {}

    @action onCancelUpload() {
        this.cancel();
    }

    @action async onUploadSpreadsheet() {
        this.uploadSpreadsheet();
    }

    @action onChangeFile(files) {
        this.changeFile(files[0]);
    }
}