import Ember from 'ember';

var JsonTools = {
    makeJsonProp: function (propVal, options) {
        options = Object.assign({
            shallow: false,
            decamelizeKeys: false
        }, options);
        var dataType = Ember.typeOf(propVal);
        if (dataType === "instance" && propVal.toJSON) {
            return options.shallow ? null : propVal.toJSON(options);
        } else if (dataType === "object") {
            if (options.shallow) {
                return null;
            }
            var jsonData = {};
            for (var key in propVal) {
                if (propVal.hasOwnProperty(key) && !Ember.isEmpty(propVal[key])) {
                    var newKey = options.decamelizeKeys ? key.decamelize() : key;
                    jsonData[newKey] = JsonTools.makeJsonProp(propVal[key], options);
                }
            }
            return jsonData;
        } else if (dataType === "array") {
            if (options.shallow) {
                return null;
            }
            return propVal.map(function (rowVal) {
                return JsonTools.makeJsonProp(rowVal, options);
            });
        } else if (dataType === "string" || dataType === "number" ||
                dataType === "boolean" || dataType === "null" || dataType === "undefined") {
            return propVal;
        }
        console.warn("unknownType!", dataType, propVal);
        console.trace();
        return null;
    },
    cleanObject: function(obj, recursive) {
        if (obj === null || obj === undefined || typeof obj !== "object") {
            return obj;
        }
        var cleanObj = {};
        for (var name in obj) {
            if (obj.hasOwnProperty(name) && !Ember.isEmpty(obj[name])) {
                var val = obj[name];
                if (typeof val === "object" && recursive) {
                    val = JsonTools.cleanObject(val, recursive);
                }
                if (!Ember.isEmpty(val) && !Ember.isArray(obj)) {
                    cleanObj[name] = val;
                }
            }
        }
        return cleanObj;
    },
    camelizeObject: function(obj, recursive) {
        if (obj === null || obj === undefined || typeof obj !== "object") {
            return obj;
        }
        var camelObj = {};
        for (var name in obj) {
            if (obj.hasOwnProperty(name)) {
                var val = obj[name];
                if (typeof name === "string") {
                    name = name.camelize();
                }
                if (val !== null && val !== undefined &&
                    typeof val === "object" && recursive) {
                    val = JsonTools.camelizeObject(val, recursive);
                }

                camelObj[name] = val;
            }
        }
        return camelObj;
    },

    flattenRecurse: function(result, cur, prop) {
        var newProp = prop.split(".").map(function(item) {return item.camelize();}).join("."),
            camelProp = newProp.camelize();
        if (Object(cur) !== cur) {
            result[camelProp] = cur;
        } else if (Array.isArray(cur)) {
            result[camelProp] = JsonTools.flattenJSONArray(cur);
        } else {
            for (var p in cur) {
                if (cur.hasOwnProperty(p)) {
                    JsonTools.flattenRecurse(result, cur[p], prop ? prop+"."+p : p);
                }
            }
        }
    },

    flattenJSONArray: function(JSONArray) {
        if (!Ember.isArray(JSONArray)) {
            return null;
        }

        var new_results = JSONArray.map(function (item) {
            var type = typeof(item);
            if (type === "string" || type === "number" || item === null || item === undefined) {
                return item;
            }
            var result = {};
            JsonTools.flattenRecurse(result, item, "");
            return result;
        });
        return new_results;
    },

    flattenJSONObject: function(JSONObject) {
        if (Ember.isEmpty(JSONObject)) {
            return null;
        }

        var result = {};
        JsonTools.flattenRecurse(result, JSONObject, "");
        return result;
    },

    flattenJSON: function(JSONData) {
        if (Ember.isEmpty(JSONData)) {
            return null;
        }
        if (Ember.isArray(JSONData)) {
            return JsonTools.flattenJSONArray(JSONData);
        }
        return JsonTools.flattenJSONObject(JSONData);
    },

};

export default JsonTools;
