import Ember from "ember";
import {computed, action} from "@ember/object";
import CSSTools from "infegy-frontend/utils/css-tools";
import TrendChartField from "infegy-frontend/components/charting/trend-chart/trend-chart-svg/trend-chart-field/component";
import d3 from "d3";

export default class extends TrendChartField {
    yRange = null;
    stackedData = null;
    trendFieldIdx = 0;

    isCanvasChart = false;

    @computed("field.colorValues", "isHighlighted")
    get style() {
        let strokeColor = this.get("field.color.colorString");
        if(this.isHighlighted){
            strokeColor = this.get("field.color.darkColorString");
        }
        strokeColor = strokeColor || "#00f";
        return CSSTools.buildStringFromObjectSafe({
           stroke: strokeColor,
           fill: strokeColor
        });
    }

    @computed("hideDots", "chartType")
    get dotPadding() {
        if (this.chartType === "bars") {
            return 0;
        }
        let padding = this.isCanvasChart ? 5 : 0;
        return padding;
    }

    @computed("yRange", "yRange.max", "yRange.min", "height")
    get yScale() {
        return d3.scaleLinear()
            .domain([this.yRange?.min, this.yRange?.max || 10])
            .range([this.height - this.dotPadding || 1, this.dotPadding]);
    }

    @computed("stackedData", "xScale", "yScale", "height")
    get dataPoints() {
        let data = this.stackedData || [],
            xScale = this.xScale,
            yScale = this.yScale;

        if (!data || !xScale || !yScale) {
            return [];
        }

        let dataPoints = data.map(function(row) {
            return {
                x: xScale(row.data.x || 0),
                y0: yScale(row[0] || 0),
                y: yScale(row[1] || 0),
                xPct: row.data.xPct
            };
        });
        return dataPoints;
    }

    @computed("dataPoints", "trendChartMeta.isBrushed")
    get brushedDataPoints() {
        let data = this.trendField?.brushedTrendField?.chartData,
            xScale = this.xScale,
            yScale = this.yScale;

        if (!this.trendChartMeta.isBrushed || !data || !xScale || !yScale) {
            return [];
        }

        let dataPoints = data.map(function(row) {
            return {
                x: xScale(row.x),
                y: yScale(row.y)
            };
        });
        return dataPoints;
    }

    @computed("dataPoints")
    get activePath() {
        let path = d3.line()
                .x(d => { return d.x || 0; })
                .y(d => { return d.y || 0; })
                .curve(d3.curveMonotoneX);
        return path(this.dataPoints || []);
    }

    @computed("dataPoints", "chartType", "trendFieldIdx")
    get secondaryPath() {
        // Only shown on first field for stream charts
        if (this.trendFieldIdx || this.chartType !== "stream") {
            return;
        }
        let path = d3.line()
                .x(d => { return d.x || 0; })
                .y(d => { return d.y0 || 0; })
                .curve(d3.curveMonotoneX);
        return path(this.dataPoints || []);
    }

}
