import SingleRequestQuery from "infegy-frontend/models/queries/single_request_query";
import ChartColors from "infegy-frontend/static_data/fixtures/chart_colors";
import Ember from "ember";

import AtlasColors from "infegy-frontend/utils/atlas-colors";

export default Ember.Component.extend({
    classNames: ["query-preview-area"],
    query: null,

    hoveredTopic: null,

    lastQueries: null,
    activeTopics: null,
    updatedQueries: [],

    selectedFilters: null,

    selectionOperatorOptions: Ember.computed("newQueryPreview",function(){
        var options = [ {title: "Focus (AND)", value: "focus"} ];

        if(!Ember.isEmpty(this.newQueryPreview)){
            options = options.concat([{title: "Exclude (NOT)", value: "exclude"},
                                    {title: "Broaden (OR)", value: "broaden"}]);
        }

        return options;
    }),
    selectionOperator: "exclude",

    baseColor: AtlasColors.getColorfromClassName(ChartColors.ui.className),

    updateIsDisabled: Ember.computed("query", "newQueryPreview", function() {
        return this.query.queryString === this.newQueryPreview;
    }),

    revertIsHidden: Ember.computed("updatedQueries.length", "updatedQueries.[]", function() {
        return this.updatedQueries?.length < 1 ? 'hide' : '';
    }),

    previewQuery: Ember.computed("query.query", "query.entityQuery", function () {
        return SingleRequestQuery.createFromFilters(this.query.toJSON());
    }),

    volumeDataSeries: Ember.computed.alias("previewQuery.volume.dataSeries"),
    lowAverageVolume: Ember.computed.lt("volumeDataSeries.stats.postsCount.average", 2),
    lowMaxVolume: Ember.computed.lt("volumeDataSeries.stats.postsCount.max", 30),
    volumeWarning: Ember.computed.or("lowAverageVolume", "lowMaxVolume"),

    newQueryPreview: Ember.computed("query.query", "activeTopics", "activeTopics.[]",
        "selectionOperator", function () {
            var topics = this.activeTopics || [],
                selectedFilters = this.selectedFilters || [],
                selectionOperator = this.selectionOperator,
                operationLists = {
                    exclude: [],
                    focus: [],
                    broaden: []
                };

            if (selectionOperator === "broaden") {
                selectionOperator = "and";
            }

            var objects = topics.filter(r=>r).map(function(item) {
                var itemQuery = Ember.get(item, "query"),
                    matchingQuery = selectedFilters.findBy("query", itemQuery);
                if (!Ember.isEmpty(matchingQuery)) {
                    return matchingQuery;
                }
                return { query: itemQuery, action: selectionOperator };
            });
            objects.forEach(function(item) {
                if (item.action === "exclude") {
                    operationLists.exclude.push(item.query);
                } else if (item.action === "focus") {
                    operationLists.focus.push(item.query);
                } else {
                    operationLists.broaden.push(item.query);
                }
            });

            var exclusions = "";
            var focus = "";
            var broaden = "";
            if (!Ember.isEmpty(operationLists.exclude)) {
                exclusions += " NOT (";
                operationLists.exclude.forEach(function(item, index) {
                    exclusions += item;
                    if (index !== operationLists.exclude.length - 1) {
                        exclusions += " OR ";
                    }
                });
                exclusions += ")";
            }
            if (!Ember.isEmpty(operationLists.focus)) {
                focus += "(";
                operationLists.focus.forEach(function(item, index) {
                    focus += item;
                    if (index !== operationLists.focus.length - 1) {
                        focus += " OR ";
                    }
                });
                focus += ")";
            }
            if (!Ember.isEmpty(operationLists.broaden)) {
                broaden += "(" + this.get("query.query") + " OR ";
                operationLists.broaden.forEach(function(item, index) {
                    broaden += item;
                    if (index !== operationLists.broaden.length - 1) {
                        broaden += " OR ";
                    }
                });
                broaden += ")";
            } else if(this.get("query.query.length") > 0) {
                broaden = this.get("query.query");
            }
            this.set("selectedFilters", objects);

            if(broaden || focus || exclusions){
                return broaden + focus + exclusions;
            } else {
                return null;
            }

    }),

    updateQuery: () => {},

    actions: {
        updatePreview: function() {
            var queryCopy = this.get("query.query") || '',
                lastQueries = this.lastQueries || [];
            this.updateQuery(this.newQueryPreview);
            this.setProperties({
                "lastQueries": lastQueries.concat([queryCopy]),
                "activeTopics": []
            });
            this.updatedQueries.pushObject(this.newQueryPreview);
        },
        removeTopic: function(topic) {
            var activeTopics = this.activeTopics;
            if (activeTopics) {
                activeTopics.removeObject(topic);
            }
        },
        topicSelected: function(topic) {
            var activeTopics = this.activeTopics;
            if (!activeTopics) {
                this.set("activeTopics", [topic]);
                return;
            } else if (topic) {
                if (activeTopics.includes(topic)) {
                    activeTopics.removeObject(topic);
                } else {
                    activeTopics.pushObject(topic);
                }
            }
            // this.set("activeTopics", activeTopics.uniq());
        },
        topicHovered: function(topic) {
            this.set("hoveredTopic", topic);
        },
        revert: function() {
            Ember.beginPropertyChanges();
            var lastQueries = this.lastQueries,
                lastRevision = lastQueries && lastQueries.pop();
            this.updateQuery(lastRevision);
            this.set("activeTopics", []);
            this.updatedQueries.removeObject(this.updatedQueries[this.updatedQueries.length-1]);
            Ember.endPropertyChanges();
        },
        selectionOperatorChanged: function(selectionOperator) {
            this.set("selectionOperator", selectionOperator);
        }
    }
});
