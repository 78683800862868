import AtlasMath from "infegy-frontend/utils/atlas-math";
import AtlasConfig from "infegy-frontend/config/infegy-app-config";
import Ember from "ember";

var DateProcessing = {};
DateProcessing.constants = {};

var monthNameToNumber = {"January": 0, "February": 1, "March": 2,
    "April": 3, "May": 4, "June": 5, "July": 6, "August": 7,
    "September": 8, "October": 9, "November": 10, "December": 11 };

var weekDayToNumber = {"Sun,": 0, "Mon,": 1, "Tue,": 2, "Wed,": 3,
    "Thu,": 4, "Fri,": 5, "Sat,": 6};
var numberToWeekday = {0: "Sun", 1: "Mon", 2: "Tue", 3: "Wed",
    4: "Thu", 5: "Fri", 6: "Sat"};

var millisInMinute = 1000 * 60;
var millisInHour = millisInMinute * 60;
var millisInDay = millisInHour * 24;
var firstSunday = millisInDay * 3;
var millisInWeek = millisInDay * 7;
var millisInYear = millisInDay * 7;

// var oneJanByYear = {};
// var getDayOfYear = function(year, timestamp) {
//     oneJanByYear[year] = oneJanByYear[year] || (Date.UTC(year,0,1)).valueOf();
//     return Math.ceil((timestamp - oneJanByYear[year]) / millisInDay);
// };

var getWeeksSinceEpoch = function(timestamp) {
    return AtlasMath.toInt((timestamp - firstSunday) / millisInWeek);
};


DateProcessing.constants.monthNameToNumber = monthNameToNumber;
DateProcessing.constants.weekDayToNumber = weekDayToNumber;
DateProcessing.constants.numberToWeekday = numberToWeekday;
DateProcessing.constants.millisInDay = millisInDay;
DateProcessing.constants.firstSunday = firstSunday;
DateProcessing.constants.millisInWeek = millisInWeek;

DateProcessing.timezoneOffset = (new Date().getTimezoneOffset()) * 60 * 1000;

DateProcessing.datestringToTimestamp = function(datestring) {
    // if (!datestring) {
    //     return 0;
    // }
    // let components = datestring.split(/[-Tt:Zz]/).map(parseFloat),
    //     d = new Date(datestring);
    // d.setUTCHours(components[3],components[4],0,0);
    return new Date(datestring).getTime();
};

/**
 * Create a Date that is based on UTC time, not the default Date local time zone.
 * @param {int|string} value An integer or valid date string.
 * @returns A new Date representing the UTC date.
 */
DateProcessing.getUTCDate = function(value) {
    let date = new Date(value);

    if (typeof(value) === "string") {
        let splitDate = value.split(/[\-:A-Za-z]+/);
        // Handle dates in format YYYY-MM-DD
        if (splitDate.length === 3 && splitDate[0].length === 4) {
            return new Date(splitDate[0], splitDate[1] - 1, splitDate[2]);
        }
    }

    if (!(typeof(value) === "string" && !value.endsWith("Z"))) {
        // Unless the `value` is a string and not specifying a time zone
        // (e.g. "2019-01-01T00:00:00"), the date will need to be manually
        // converted back to UTC.
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    }
    return date;
}

DateProcessing.datestringToDayTimestamp = function(datestring) {
    var d = DateProcessing.getUTCDate(datestring);
    d.setUTCHours(0,0,0,0);
    return d.getTime();
};

DateProcessing.timestampToDateObject = function(timestamp) {
    var date = new Date(timestamp);

    var year = date.getUTCFullYear(),
        month = date.getUTCMonth(),
        day = date.getUTCDate();

    var week = getWeeksSinceEpoch(timestamp);

    var row = {
        "timestamp": timestamp,
        "_dayOfWeek": date.getUTCDay(),
        "_month": month,
        "_monthsSinceZero": (year * 12) + month,
        "_minutesSinceEpoch": AtlasMath.toInt(timestamp / millisInMinute),
        "_hoursSinceEpoch": AtlasMath.toInt(timestamp / millisInHour),
        "_daysSinceEpoch": AtlasMath.toInt(timestamp / millisInDay),
        "_weeksSinceEpoch": week,
        "_year": year
    };
    return row;
};

DateProcessing.isConvertableDateFieldValue = function(dateField) {
    var functionName = [dateField.slice(1), "ToTimestamp"].join("");
    return !!DateProcessing[functionName];
};

DateProcessing.dateFieldValueToTimestamp = function(dateField, value) {
    var functionName = [dateField.slice(1), "ToTimestamp"].join("");

    if (DateProcessing[functionName]) {
        return DateProcessing[functionName](value);
    }
};


DateProcessing.dateFieldValueToDateObject = function(dateField, value) {
    var ts = DateProcessing.dateFieldValueToTimestamp(dateField, value);
    return DateProcessing.timestampToDateObject(ts);
};

DateProcessing.daysSinceEpochToTimestamp = function(days) {
    return AtlasMath.toInt(days) * millisInDay;
};

DateProcessing.weeksSinceEpochToTimestamp = function(weeks) {
    return (AtlasMath.toInt(weeks) * millisInWeek) + firstSunday;
};

DateProcessing.monthsSinceZeroToTimestamp = function(months) {
    months = AtlasMath.toInt(months);
    var year = AtlasMath.toInt(months / 12),
        month = AtlasMath.toInt(months % 12);

    return Date.UTC(year, month, 1, 0, 0, 0).valueOf();
};

DateProcessing.yearToTimestamp = function(year) {
    return Date.UTC(AtlasMath.toInt(year), 0, 1, 0, 0, 0).valueOf();
};

var datestringToTimestamp = DateProcessing.datestringToTimestamp,
    timestampToDateObject = DateProcessing.timestampToDateObject;

DateProcessing.fixApiDates = function(data, dateField) {
    var dataLen = data.length,
        row, dateObj,
        found = 0;

    while (dataLen--) {
        row = data[dataLen];
        if (row[dateField]) {
            found++;
            var timestamp = datestringToTimestamp(row[dateField]);
            dateObj = timestampToDateObject(timestamp);
            data[dataLen] = Object.assign(row, dateObj);
        }
    }

    if (found) {
        return {
            "timestamp": "timestamp",
            "_dayOfWeek": "index",
            "_month": "index",
            "_minutesSinceEpoch": "index",
            "_hoursSinceEpoch": "index",
            "_daysSinceEpoch": "index",
            "_monthsSinceZero": "index",
            "_weeksSinceEpoch": "index",
            "_year": "index"
        };
    }
};

DateProcessing.copyDatesFromObject = function(row) {
    return {
        "timestamp": row.timestamp,
        "_dayOfWeek": row._dayOfWeek,
        "_month": row._month,
        "_minutesSinceEpoch": row._minutesSinceEpoch,
        "_hoursSinceEpoch": row._hoursSinceEpoch,
        "_daysSinceEpoch": row._daysSinceEpoch,
        "_monthsSinceZero": row._monthsSinceZero,
        "_weeksSinceEpoch": row._weeksSinceEpoch,
        "_year": row._year
    };
};

DateProcessing.copyDatesIntoObject = function(source, destination) {
    destination = destination || {};
    destination.timestamp = source.timestamp;
    destination._dayOfWeek = source._dayOfWeek;
    destination._month = source._month;
    destination._minutesSinceEpoch = source._minutesSinceEpoch;
    destination._hoursSinceEpoch = source._hoursSinceEpoch;
    destination._daysSinceEpoch = source._daysSinceEpoch;
    destination._monthsSinceZero = source._monthsSinceZero;
    destination._weeksSinceEpoch = source._weeksSinceEpoch;
    destination._year = source._year;
    return destination;
};

DateProcessing.isDateField = {
        "timestamp": true,
        "_dayOfWeek": true,
        "_month": true,
        "_minutesSinceEpoch": true,
        "_hoursSinceEpoch": true,
        "_daysSinceEpoch": true,
        "_monthsSinceZero": true,
        "_weeksSinceEpoch": true,
        "_year": true
    };

DateProcessing.findAvailableDateRangeForQueries = function(queries, user) {
    var totalCustomDatasets = 0,
        earliestTimestamp = Infinity,
        latestTimestamp = -Infinity,
        earliestSocialTimestampForUser = user?.permissions?.historicalAccessTimestamp || AtlasConfig.EarliestAtlasTimestamp,
        earliestSocialTimeForUser = new Date(earliestSocialTimestampForUser);

    if (Ember.isEmpty(queries)) {
        return {
            earliestTimestamp: earliestSocialTimeForUser,
            latestTimestamp: null
        };
    }

    queries.forEach((query) => {
        var current_earliest = Ember.get(query,"queryInfo.earliestTimestamp"),
            current_latest = Ember.get(query,"queryInfo.latestTimestamp"),
            customDatasetId = Ember.get(query,"queryInfo.customDatasetId");

        if (customDatasetId) {
            totalCustomDatasets += 1;
        } else {
            current_earliest = earliestSocialTimeForUser;
        }

        if (current_earliest < earliestTimestamp) {
            earliestTimestamp = current_earliest;
        }

        if (current_latest > latestTimestamp) {
            latestTimestamp = current_latest;
        }
    });

    if (!isFinite(earliestTimestamp)) {
        earliestTimestamp = earliestSocialTimeForUser;
    }
    if (!isFinite(latestTimestamp)) {
        latestTimestamp = null;
    }

    return {
        earliestTimestamp: earliestTimestamp,
        latestTimestamp: latestTimestamp,
        totalCustomDatasets: totalCustomDatasets
    };
}

export default DateProcessing;
