import Ember from "ember";
import topicsFields from "infegy-frontend/models/entities/topics-fields";
import d3 from "d3";

export default Ember.Component.extend({
    classNames: ["entities-topics"],
    queries: null,
    queryTopics: null,
    selectedEntities: null,
    hasMoreThanOneQuery: Ember.computed.gt("queries.length", 1),
    showMultiCloud: false,
    isFilterdownCollated: Ember.computed.not("showMultiCloud"),
    removeEntityByTopic: ()=>{},
    displayTopicsCountChanged: ()=>{},
    hiddenEntities: [],
    replaceEntity: ()=>{},

    displayTopicsCount: 50,

    isLoading: Ember.computed("queries.[]", "queries.@each.updated", function () {
        var queries = this.queries || [];

        var isLoading = queries.find(query => {
            var entities = Ember.get(query, "entities");
            return !entities || !Ember.get(entities, "isLoaded");
        });

        return isLoading;
    }),

    showLegend: true,

    fieldOptions: Ember.computed("hasMoreThanOneQuery", function () {
        if (this.hasMoreThanOneQuery) {
            return topicsFields.multiQueryFieldOptions;
        }

        return topicsFields.singleQueryFieldOptions;
    }),

    topicField: "name",
    sizeField: "appearances",
    colorField: "positiveAppearancesDistribution",
    fixedColor: null,
    selectedTopic: null,
    hoveredTopic: null,
    hoveredTimelineIndex: null,

    topicsType: "entities",

    allTopics: Ember.computed("queryTopics", "topicsType", function () {
        var queryTopics = this.queryTopics || [],
            topicsType = this.topicsType;

        this.setProperties({
            selectedTopic: null,
            hoveredTopic: null
        });

        return [].concat.apply([], queryTopics.mapBy(topicsType));
    }),

    colorFieldStats: Ember.computed("allTopics", "colorField", function () {
        var colorField = this.colorField,
            allTopics = this.allTopics || [],
            values = allTopics.mapBy(colorField);

        return {
            min: Math.min.apply(null, values),
            max: Math.max.apply(null, values),
        };
    }),

    colorDomain: Ember.computed("colorField", "allTopics",
            "colorFieldStats", "queries.[]", function () {
        var colorFieldInfo = this.colorFieldInfo,
            colorFieldStats = this.colorFieldStats;

        if (!colorFieldInfo || !colorFieldStats || colorFieldInfo.keyType === "sentiment") {
            return [0, 0.5, 1.0];
        }

        var min = Ember.get(colorFieldStats, "min") || 0,
            max = Ember.get(colorFieldStats, "max") || 1.0;

        return [min, ((max - min) / 2) + min, max];
    }),

    colorFieldInfo: Ember.computed("colorField", function () {
        return topicsFields.fieldLookups[this.colorField] || topicsFields.fieldLookups["score"] || topicsFields[0];
    }),

    colorFunction: Ember.computed("colorDomain", "fixedColor", "colorFieldInfo",
            "queries.[]", function () {
        var colorDomain = this.colorDomain,
            colorRange = this.get("colorFieldInfo.colorRange"),
            keyType = this.get("colorFieldInfo.keyType"),
            queries = this.queries || [];

        if (keyType === "query") {
            return d3.scaleOrdinal()
                .domain(queries.map((q,idx)=> idx))
                .range(queries.mapBy("queryInfo.color.colorString"));
        }

        if (colorRange) {
            return d3.scaleLinear()
                .domain(colorDomain)
                .range(colorRange);
        }
    }),

    actions: {
        cloudOptionChanged: function(topic) {
            this.set("selectedOption", topic);
        },
        topicHovered: function (topic) {
            if (this.hoveredTopic !== topic) {
                this.set("hoveredTopic", topic);
            }
        },
        topicSelected: function (topic) {
            if (this.selectedTopic !== topic) {
                this.set("selectedTopic", topic);
            }
        },
        hoveredIndexChanged: function (hoveredIndex) {
            this.set("hoveredTimelineIndex", hoveredIndex);
        },
        sizeFieldChanged: function (sizeField) {
            if (this.selectedTopic !== null) {
                this.set("selectedTopic", null);
            }
            this.set("sizeField", sizeField);
        },
        colorFieldChanged: function (colorField) {
            if (this.selectedTopic !== null) {
                this.set("selectedTopic", null);
            }

            this.set("colorField", colorField);
        },
        removeEntityByTopic: function(topic) {
            this.removeEntityByTopic(topic);
        },
        toggleShowDisplaySettings(){
            this.toggleProperty("showDisplaySettings");
        }
    },
});
