import Ember from "ember";
import classic from "ember-classic-decorator";

/** Ember no longer supports mouseEnter, mouseMove and mouseLeave in actions or components
 * This adds that support back. Handlers must exist on the component class when the constructor runs.
 * keys are: onMouseEnter, onMouseLeave, onMouseMove
 * */
// @classic
export const MouseInteractionWrapper = (C) => class extends C {
    constructor(){
        super(...arguments);
        this.on("didInsertElement",function(){
            ["MouseEnter", "MouseMove", "MouseLeave"].forEach(key => {
                if(!Ember.isNone(this[`on${key}`])){
                    this[`_${key}Listener`] = (event)=>{
                        this[`on${key}`].bind(this)(event);
                    };
                    this.element.addEventListener(key.toLowerCase(), this[`_${key}Listener`]);
                }
            });
        });
        this.on("willDestroyElement",function(){
            ["MouseEnter", "MouseMove", "MouseLeave"].forEach(key => {
                if(!Ember.isNone(this[`_${key}Listener`])){
                    this.element.removeEventListener(key.toLowerCase(), this[`_${key}Listener`]);
                }
            });
        });
    }
};
