import Ember from "ember";
import Component from '@ember/component';
import {computed, action} from "@ember/object";

export default class SVGViewboxWrapper extends Component {
    classNames = ["svg-viewbox-wrapper-surround"];

    viewBoxX = 0;
    viewBoxY = 0;
    viewBoxWidth = 100;
    viewBoxHeight = 100;

    maxHeight = null;

    aspectRatio = null;

    @computed("viewBoxWidth")
    get trueViewBoxWidth() {
        return this.viewBoxWidth > 0 ? this.viewBoxWidth : 1;
    }

    @computed("viewBoxHeight")
    get trueViewBoxHeight() {
        return this.viewBoxHeight > 0 ? this.viewBoxHeight : 1;
    }

    @computed("viewBoxWidth", "viewBoxHeight", "aspectRatio")
    get aspectRatioPctStyleSafe() {
        let viewBoxHeight = this.viewBoxHeight,
            viewBoxWidth = this.viewBoxWidth,
            aspectRatio = this.aspectRatio || (viewBoxWidth / viewBoxHeight) || 1,
            style = (1.0 / aspectRatio) * 100;
        return new Ember.String.htmlSafe(`padding-top:${style}%`);
    }

    @computed("maxHeight")
    get svgMaxHeightStyleSafe() {
        let maxHeight = this.maxHeight,
            style = maxHeight ? `${maxHeight}px` : "none";
        return new Ember.String.htmlSafe("max-width:" + style);
    }
}
