import { A } from "@ember/array";
import ArrayProxy from "@ember/array/proxy";

import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import QualtricsDataPusher from "infegy-frontend/models/qualtrics/data_pusher";
import classic from "ember-classic-decorator";
import * as Sentry from "@sentry/browser";

@classic
export default class QualtricsDataPushers extends ArrayProxy {
    /** @type QualtricsDataPusher[] */
    content = A();
    isLoading = false;

    async load() {
        let response = null;
        try {
            this.set("isLoading", true);
            response = await AtlasAuth.Get("api/v3/qualtrics-push/list");
        }
        catch(error) {
            this.set("isLoading", false);
            console.error(error.atlasErrorText);
            Sentry.captureMessage("Could not get Qualtrics Data Pushers.");
            return;
        }

        let dataPushers = response.output.map((json) => {
            let dataPusher = QualtricsDataPusher.create();
            dataPusher.loadJSON(json);
            return dataPusher;
        });

        this.setProperties({
            content: A(dataPushers),
            isLoading: false
        });
    }
}