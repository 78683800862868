import Ember from "ember";
import D3SVGContainerComponent from "infegy-frontend/components/charting/d3-base/d3-svg-container/component";
import d3 from "d3";

export default D3SVGContainerComponent.extend({
    tagName: "g",

    contentArea: null,
    dataSeries: Ember.Object.create({}),

    stats: Ember.computed.alias("dataSeries.stats"),
    data: Ember.computed.alias("dataSeries.data"),

    colorClass: "atlas-neutral",
    yAxisField: "countPortion",

    maxY: null,

    drawArea: true,
    drawLine: true,
    drawCircles: true,

    innerMaxY: Ember.computed("stats", "yAxisField", "maxY", function () {
        var yAxisField = this.yAxisField;
        return this.maxY || this.get(`stats.${yAxisField}.max`);
    }),

    xRange: Ember.computed("data", "contentArea.width", function () {
        return d3.scaleLinear()
            .domain([0, this.get("data.length")])
            .range([0, this.get("contentArea.width")]);
    }),
    yRange: Ember.computed("contentArea.height", "innerMaxY", function () {
        return d3.scaleLinear()
            .domain([0, this.innerMaxY])
            .range([0, this.get("contentArea.height")]);
    }),

    areaData: Ember.computed("xRange", "yRange", "drawableSegments", "contentArea.height", function () {
        var xRange = this.xRange,
            yRange = this.yRange,
            height = this.get("contentArea.height"),
            drawableSegments = this.drawableSegments || [],
            areaSegment = d3.area()
                .x(function (d) {
                    return xRange(d.x);
                })
                .y0(height)
                .y1(function (d) {
                    return height - yRange(d.y);
                })
                .curve(d3.curveMonotoneX);
        return areaSegment(drawableSegments);
    }),

    lineData: Ember.computed("xRange", "yRange", "drawableSegments", "contentArea.height", function () {
        var xRange = this.xRange,
            yRange = this.yRange,
            height = this.get("contentArea.height"),
            drawableSegments = this.drawableSegments || [],
            lineSegment = d3.line()
                .x(function (d) {
                    return xRange(d.x);
                })
                .y(function (d) {
                    return height - yRange(d.y);
                }).curve(d3.curveMonotoneX);
        return lineSegment(drawableSegments);
    }),

    pointCircles: Ember.computed("xRange", "yRange", "contentArea.height", "drawableSegments", function () {
        var xRange = this.xRange,
            yRange = this.yRange,
            height = this.get("contentArea.height"),
            drawableSegments = this.drawableSegments;

        if (!drawableSegments || drawableSegments.length < 3) {
            return [];
        }

        return drawableSegments.slice(1, -1).map((coords) => {
            return {
                cx: xRange(coords.x),
                cy: height - yRange(coords.y)
            };
        });
    }),

    drawableSegments: Ember.computed("data", "data.[]", "yAxisField", function () {
        var innerData = this.data,
            yAxisField = this.yAxisField;

        if (!innerData || !yAxisField) {
            return null;
        }

        var segments = innerData.map((row, idx) => {
            return {
                x: idx + 0.5,
                y: row[yAxisField] || 0
            };
        });

        // Add the start and end points at 0 to complete the distribution area
        segments.unshift({ x: 0, y: 0 });
        segments.push({ x: innerData.length, y: 0 });
        return segments;
    }),
});
