import Component from "@ember/component";
import { computed } from "@ember/object";
import { htmlSafe} from "@ember/template";

export default class extends Component {
    label = "";
    loaded = 0;
    total = 0;
    
    @computed("loaded", "total")
    get percent() {
        return (this.loaded / this.total) || 0;
    }

    @computed("loaded", "total")
    get myStyle() {
        let intPercent = Number.parseInt(this.percent * 100);
        return htmlSafe(`width: ${intPercent}%; height: 30px;`);
    }
}