import CM from "infegy-frontend/components/design/codemirror-input/component";
import { computed } from '@ember/object';

export default class extends CM {
    @computed.alias("value") queryString;
    options = {
        "theme": "atlas",
        "lineWrapping": true,
        "readOnly": true,
        "autoCloseBrackets": '()""',
        "cursorHeight":0
    }
}
