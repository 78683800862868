import Ember from 'ember';
import * as Sentry from "@sentry/browser";
import User from "infegy-frontend/models/users/user";

import AtlasConfig from "infegy-frontend/config/infegy-app-config";

var AtlasAuth = {
    baseUri: AtlasConfig.baseDomain,
    user: User.create(),
    isLoggedIn: null,

    get sessionToken() {
        return window.localStorage.getItem('atlasToken');
    },
    set sessionToken(value) {
        if (!Ember.isEmpty(value)) {
            window.localStorage.setItem('atlasToken', value);
        } else {
            window.localStorage.removeItem('atlasToken');
        }
    },

    async login(username, password) {
        if (!username || !password) {
            return false;
        }

        const response = await AtlasAuth.Post({
            url: "api/v3/login",
            data: {username, password}
        });
        this.sessionToken = response.output.token;

        await this.user.load();
        Ember.set(this, "isLoggedIn", true);
        const userJSON = this.user.toJSON();
        Sentry.setUser(userJSON);
        ga && ga('set', 'userId', this.user.id);
    },

    async logout() {
        await AtlasAuth.Get("api/v3/logout");
        this.sessionToken = "";
        Ember.set(this, "isLoggedIn", false);
    },

    /** @typedef {Object} AtlasAuthOptions Options which modify the behavior of an AtlasAuth request
     * @property {string} type The HTTP verb to be sent with the request
     * @property {string} url The URL to request
     * @property {string | Object} [data] The request body to be sent. Objects are serialized to field data
     * @property {number} [timeout] In milliseconds, how long before the request times out
     * @property {Object} [headers] Hash of headers to be set on the XHR object. 'session-token' is added by default
     * @property {boolean} [allowRelativeURL=false] Relative URLs will be prepended unless this flag is set to true
     * @property {AbortSignal} [abortSignal] When fired the XHR will be aborted
     * @property {Object} [xhrFields] Fields to be set on the XHR object
     * @property {XMLHttpRequest} [xhr] XHR object to be used instead of generating one inside this function
     */

    /** ImmediateAjax constructs an XHR object and returns a promise that resolves or rejects based on the response of that XHR.
     * @param {AtlasAuthOptions} options
     * @returns {Promise<Object>} Promise that resolves to the parsed JSON object received from the server
     */
    ImmediateAjax(options, onprogress) {
        if (typeof options === "string") {
            options = { url: options };
        }

        let headers = Object.assign({}, {
            "session-token": this.sessionToken,
            "X-Requested-With": "XMLHttpRequest"
        }, options.headers);
        delete options.headers;

        options = Object.assign({}, {
            type: "GET",
            timeout: 180000,
            xhrFields: { withCredentials: true },
            headers
        }, options);

        if (!options.allowRelativeURL && options.url.slice(0, 4) !== "http") {
            options.url = AtlasAuth.baseUri + options.url;
        }

        if (options.headers["Content-Type"] === "application/json") {
            options.data = JSON.stringify(options.data);
        }
        else {
            if (options.data &&
                Ember.typeOf(options.data) !== "string" &&
                !(options.data instanceof File)) {
                options.data = Object.entries(options.data)
                    .reduce((all, [key, value]) => {
                        all.push(`${key}=${encodeURIComponent(value)}`);
                        return all;
                    }, []).join('&');
            }
            if (options.data && options.type.toLowerCase() === "get") {
                if(options.url.indexOf('?') > -1){
                    options.url = `${options.url}${options.data}`;
                } else {
                    options.url = `${options.url}?${options.data}`;
                }
                options.data = null;
            }
        }
        
        var xhr = options.xhr || new XMLHttpRequest(options.xhrFields);
        return new Promise((resolve, reject) => {
            xhr.timeout = options.timeout;
            xhr.open(options.type, options.url);
            xhr.onload = () => {
                if (xhr.status >= 400) {
                    if(xhr.status === 403){
                        Ember.set(this, "isLoggedIn", false);
                    }
                    return reject();
                }
                var contentType = xhr.getResponseHeader("Content-Type");

                if(contentType.includes('json')){
                    let response = xhr.responseText;
                    response = JSON.parse(xhr.responseText);
                    return resolve(response);
                }
                return resolve(xhr.response);
            };
            xhr.onerror = () => { reject(); };
            if (!Ember.isBlank(options.headers)) {
                Object.entries(options.headers).forEach(([key, value]) => {
                    xhr.setRequestHeader(key, value);
                });
            }
            if (onprogress) {
                if (options.type.toLowerCase() === "get") {
                    xhr.onprogress = onprogress;
                }
                else {
                    xhr.upload.onprogress = onprogress;
                }
            }

            if (options.abortSignal) {
                options.abortSignal.addEventListener('abort', () => {
                    xhr.abort();
                });
            }

            xhr.send(options.data);
        }).catch(() => {
            let data = {};
            try {
                data = JSON.parse(xhr.responseText) || {};
            }
            catch(error) {
                console.error('Could not parse json', xhr, error);
            }
            data.atlasErrorText = AtlasAuth.parseErrorMessage(xhr, options);
            data.status = xhr.status;
            return Promise.reject(data);
        });
    },

    /** Sends an HTTP GET request
     * @param {(AtlasAuthOptions|String)} options
     * @returns {Promise<Object>} Promise that resolves to JSON parsed response
     */
    Get(options, onprogress) {
        options = this.normalizeAtlasAuthOptions(options);
        options.type = "GET";
        return AtlasAuth.ImmediateAjax(options, onprogress);
    },

    /** (Sends|String) an HTTP POST request
     * @param {AtlasAuthOptions} options
     * @returns {Promise<Object>} Promise that resolves to JSON parsed response
     */
    Post(options, onprogress) {
        options = this.normalizeAtlasAuthOptions(options);
        options.type = "POST";
        return AtlasAuth.ImmediateAjax(options, onprogress);
    },

    /** Sends an HTTP PUT request
     * @param {(AtlasAuthOptions|String)} options
     * @returns {Promise<Object>} Promise that resolves to JSON parsed response
     */
    Put(options, onprogress) {
        options = this.normalizeAtlasAuthOptions(options);
        options.type = "PUT";
        return AtlasAuth.ImmediateAjax(options, onprogress);
    },

    /** Sends an HTTP DELETE request
     * @param {(AtlasAuthOptions|String)} options
     * @returns {Promise<Object>} Promise that resolves to JSON parsed response
     */
    Delete(options) {
        options = this.normalizeAtlasAuthOptions(options);
        options.type = "DELETE";
        return AtlasAuth.ImmediateAjax(options);
    },

    /**
     * POST to `url` with a body of `formData`
     * @param {string} url The URL to POST
     * @param {FormData} formData The data to POST to the server
     * @param {string} filename Filename to download response as
     * @returns {Promise<any>} Promise. Resolves or rejects with successful XHR
     */
    async downloadPOST(url, formData, filename) {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'arraybuffer';
        await this.Post({
            data: formData,
            xhr,
            url
        });

        if (!filename) {
            filename = "";
            var disposition = xhr.getResponseHeader('Content-Disposition');
            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
                    matches = filenameRegex.exec(disposition);
                if (matches !== null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
        }
        var type = xhr.getResponseHeader('Content-Type'),
            blob = new Blob([xhr.response], { type }),
            URL = window.URL || window.webkitURL,
            downloadUrl = URL.createObjectURL(blob);

        if (filename) {
            // use HTML5 a[download] attribute to specify filename
            var a = document.createElement("a");
            // safari doesn't support this yet
            a.href = downloadUrl;
            a.download = filename;
            a.target = "_blank";
            document.body.appendChild(a);
            a.click();
        } else {
            // window.location = downloadUrl;
        }

        setTimeout(() => { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
    },

    /** Utility method for coercing AtlasAuth params to AtlasAuthOptions
     * @param {(AtlasAuthOptions|String)} options Options passed into the AtlasAuth Call
     * @returns {AtlasAuthOptions} Options object with url assigned
     */
    normalizeAtlasAuthOptions(options) {
        if (Ember.typeOf(options) === "string") {
            options = { url: options };
        }
        return options;
    },

    parseErrorMessage(xhr, options) {
        var errorText = xhr && xhr.responseText,
            errorMessage = "";

        if (Ember.isEmpty(errorText)) {
            // if no response is received build one from the XHR status
            switch (xhr.statusText) {
                case "timeout":
                    errorMessage = "Response from server timed out.";
                    break;
                case "abort":
                    errorMessage = "Response from server was aborted.";
                    break;
                case "parseError":
                    errorMessage = "Error parsing response from server.";
                    break;
                default:
                    errorMessage = "No response from server.";
                    break;
            }
        } else {
            // if we've received a response, try and parse it
            try {
                var errorObject = JSON.parse(errorText);
                errorMessage = (errorObject && errorObject.status_message) || "";
            } catch (parseError) {
                // we received a response that is not JSON (eg NGINX default error pages)
                Sentry.captureMessage('Non-JSON response from server', {
                    extra: {
                        error: parseError,
                        responseData: xhr,
                        requestData: options
                    }
                });
                errorMessage = "Connected to the server successfully, but the response was malformed";
            }
        }
        // if all else fails return a message like "403 Forbidden"
        return errorMessage || [xhr.status, xhr.statusText].join(" ");
    },
};

export default AtlasAuth;
