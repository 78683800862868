import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";
import fieldTimelineGrowth from "infegy-frontend/models/data_series/data_series_computed_field_growth";

export default QueryAPIResults.extend({
    title : "interests",
    dateField: null,
    preload:function(){
        let data = this.get("rawResults");
        data.forEach((interest)=>{
            let parts = interest.name.split("|");
            interest.lastTier = parts.pop();
            interest.tiers = parts;
            interest.tiersString = parts.join("▸");
        });
    },
    description: DataSeriesDescription.load({
        "name": "string",
        "tiers": "array",
        "lastTier": "string",

        "sources": "number",

        "ratio": "number",
        "distribution": "number"
    }, [
        fieldTimelineGrowth.fieldSetup("documentsGrowthPct", "timeline")
    ])
});
