import Ember from "ember";
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import DataSeries from "infegy-frontend/models/data_series/data_series";

export default class extends Component {
    queries = null;
    hoveredDate = null;
    drilldownQueryFilterValue = null;

    loadingApis = ["volume","gender"];

    @computed.alias("queries.firstObject") firstQuery;

    @computed("queries.@each.updated")
    get hasCustomDataset() {
        const found = this.queries.find((query) => query.hasCustomDataset);
        return !!found;
    }

    @computed("hasCustomDataset")
    get displayField() {
        return this.hasCustomDataset ? "" : "Universe";
    }

    @computed("queries.@each.updated")
    get noGenderData(){
        let queries = this.queries,
            endpoints = queries.mapBy("gender.dataSeries.data.length");
        return endpoints.some((length)=>!length);
    }

    @computed("queries", "queries.length", "queries.@each.updated")
    get genderDataSeries() {
        if (this.get("queries.length") === 1) {
            return this.get("firstQuery.gender.dataSeries");
        }
        let queries = this.queries,
            data = queries.map((query) => query.get("gender.dataSeries"));
        return DataSeries.stackTimeseries(data, "date", { sortBy: "date" });
    }

    @computed("queries", "queries.length", "queries.@each.updated")
    get volumeDataSeries() {
        if (this.get("queries.length") === 1) {
            return this.get("firstQuery.volume.dataSeries");
        }
        let queries = this.queries,
            data = queries.map((query) => query.get("volume.dataSeries"));
        return DataSeries.stackTimeseries(data, "date", { sortBy: "date" });
    }

    @computed.alias("genderQueries.female.sentiment.dataSeries") femaleDataSeries;
    @computed.alias("genderQueries.male.sentiment.dataSeries") maleDataSeries;

    @computed("queries", "queries.@each.updated", "queries.length", "drilldownQueryFilterValue")
    get drilldownArcQuery() {
        if (!this.firstQuery) {
            return;
        }

        var drilldownQuery = this.firstQuery.copy(),
            queryInfo = drilldownQuery.get("queryInfo"),
            drilldownQueryFilterValue = this.drilldownQueryFilterValue;

        queryInfo.addFilter({
            id: "gender",
            values: [drilldownQueryFilterValue]
        });

        return drilldownQuery;
    }

    @computed("queries", "queries.@each.updated", "queries.length", "drilldownQueryFilterValue")
    get drilldownArcQueries() {
        var queries = this.queries && Ember.assign([], this.queries),
            drilldownQueryFilterValue = this.drilldownQueryFilterValue;

        queries = queries.map((query) => {
            let queryCopy = query.copy();
            var queryInfo = queryCopy.get("queryInfo");
            queryInfo.addFilter({
                id: "gender",
                values: [drilldownQueryFilterValue]
            });
            return queryCopy;
        });

        return queries;
    }

    @computed("queries", "queries.@each.updated")
    get leadingQueryInfos() {
        var queries = this.queries;

        return {
            female: queries.map((query) => ({ value: query.get("gender.stats.femaleUniverseDistribution.average"), query: query })).sortBy("value").pop(),
            male: queries.map((query) => ({ value: query.get("gender.stats.maleUniverseDistribution.average"), query: query })).sortBy("value").pop(),
            nonBinary: queries.map((query) => ({ value: query.get("gender.stats.nonBinaryUniverseDistribution.average"), query: query })).sortBy("value").pop()
        };
    }

    @computed("firstQuery")
    get genderQueries() {
        if (!this.firstQuery) {
            return;
        }

        var maleQuery = this.firstQuery.copy(),
            femaleQuery = this.firstQuery.copy();

        maleQuery.queryInfo.addAdditionalAPIParameters({
            sample: 3500,
            skip_samples: 1,
            gender_within: "male"
        });

        femaleQuery.queryInfo.addAdditionalAPIParameters({
            sample: 3500,
            skip_samples: 1,
            gender_within: "female"
        });

        return {
            female: femaleQuery,
            male: maleQuery
        };
    }

    @computed("genderDataSeries", "hoveredTopic")
    get selectedDates() {
        let genderDataSeries = this.get("genderDataSeries.data"),
            topic = this.hoveredTopic || {},
            maleFirstSeen = topic.maleFirstSeen && (new Date(topic.maleFirstSeen)).getTime(),
            maleLastSeen = topic.maleLastSeen && (new Date(topic.maleLastSeen)).getTime(),
            femaleFirstSeen = topic.femaleFirstSeen && (new Date(topic.femaleFirstSeen)).getTime(),
            femaleLastSeen = topic.femaleLastSeen && (new Date(topic.femaleLastSeen)).getTime();

        return {
            maleStart: maleFirstSeen / 1000,
            maleEnd: maleLastSeen / 1000,
            femaleStart: femaleFirstSeen / 1000,
            femaleEnd: femaleLastSeen / 1000,
            allStart: Math.min(maleFirstSeen, femaleFirstSeen) || genderDataSeries[0].date,
            allEnd: Math.max(maleLastSeen, femaleLastSeen) || genderDataSeries[genderDataSeries.length - 1].date
        };
    }

    @computed("volumeDataSeries.stats.count.sum", "genderDataSeries.stats.femaleRaw.sum",
        "genderDataSeries.stats.maleRaw.sum")
    get genderedPostsPct() {
        let genderedTotal = this.get("genderDataSeries.stats.femaleRaw.sum") +
                            this.get("genderDataSeries.stats.maleRaw.sum");
        return genderedTotal / this.get("volumeDataSeries.stats.postsCount.sum");
    }

    @computed("selectedDates", "genderDataSeries", "maleDataSeries", "femaleDataSeries",
        "volumeDataSeries.stats.postsNormalizedUniverse.sum")
    get genderStats() {
        const valueField = this.hasCustomDataset ? "Count" : "Universe";
        let genderDataSeries = this.genderDataSeries || [];
        let genderDataSeriesData = this.get("genderDataSeries.data") || [];
        let maleValue = 0;
        let maleTopDays = 0;
        let femaleValue = 0;
        let nonBinaryValue = 0;
        let femaleTopDays = 0;

        if (!this.selectedDates.maleStart && !this.selectedDates.femaleStart) {
            maleValue = genderDataSeries.get(`stats.male${valueField}.sum`);
            femaleValue = genderDataSeries.get(`stats.female${valueField}.sum`);
        }

        genderDataSeriesData.forEach((day) => {
            maleTopDays += day.maleCount > day.femaleCount ? 1 : 0;
            femaleTopDays += day.femaleCount > day.maleCount ? 1 : 0;
        });

        maleValue = genderDataSeries.get(`stats.male${valueField}.sum`);
        femaleValue = genderDataSeries.get(`stats.female${valueField}.sum`);
        nonBinaryValue = genderDataSeries.get(`stats.nonBinary${valueField}.sum`);

        const maleChange = genderDataSeries.get(`stats.male${valueField}.percentChange`) / 100;
        const femaleChange = genderDataSeries.get(`stats.female${valueField}.percentChange`) / 100;
        const totalValue = maleValue + femaleValue + nonBinaryValue;

        return {
            maleValue,
            maleChange,
            femaleValue,
            nonBinaryValue,
            femaleChange,
            totalValue,
            maleTopDays: maleTopDays,
            femaleTopDays: femaleTopDays
        };
    }

    @computed("queries")
    get genderRowData() {
        return this.queries.map((query) => {
            const femalePercent = query.gender.all.stats.femaleUniverseDistribution.average;
            const malePercent = query.gender.all.stats.maleUniverseDistribution.average;
            const nonBinaryPercent = query.gender.all.stats.nonBinaryUniverseDistribution.average;
            return {
                title: query.title,
                femalePercent,
                malePercent,
                nonBinaryPercent,
                titleStyle: `${query.queryInfo.color.textStyle}; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; max-width: 350px;`.htmlSafe(),
                femaleStyle: `flex: ${femalePercent}; height: 100%`.htmlSafe(),
                maleStyle: `flex: ${malePercent}; height: 100%`.htmlSafe(),
                nonBinaryStyle: `flex: ${nonBinaryPercent}; height: 100%`.htmlSafe(),
            };
        });
    }

    @computed("genderStats.femaleChange")
    get isInfinityPercentFemaleChange() {
        var change = this.get("genderStats.femaleChange");
        return change === Infinity || change === -Infinity;
    }

    @computed("genderStats.maleChange")
    get isInfinityPercentMaleChange() {
        var change = this.get("genderStats.maleChange");
        return change === Infinity || change === -Infinity;
    }

    @action
    updateGenderTrendTooltip(hoveredValue) {
        this.set('hoveredDate', hoveredValue);
    }

    @action
    displayFilterdown(gender) {
        var drilldownQueryFilterValue = this.drilldownQueryFilterValue;

        if (gender === drilldownQueryFilterValue) {
            this.set("drilldownQueryFilterValue", null);
        } else {
            this.set("drilldownQueryFilterValue", gender);
        }
    }
}
