import Ember from "ember";

export default Ember.Component.extend({
    queries: null,
    activeArea: null,
    hoveredPct: null,
    isTooltipVisible: false,

    activePage: null,
    activeField: null,

    hoveredGeoData: Ember.computed("activeArea", "activeField", function() {
        var queries = this.queries,
            activeArea = this.activeArea,
            activeField = this.activeField,
            hoveredGeoData;

        if (activeArea && queries.length > 1) {
            
            hoveredGeoData = {
                name: activeArea.name,
                total: activeArea[activeField].sum,
                maxQueries: activeArea[activeField].max.mapBy("_originalQuery"),
                minQueries: activeArea[activeField].min.mapBy("_originalQuery"),
                colorClass: "atlas-ui"
            };
        } else {
            hoveredGeoData = Object.assign({
                color: this.get("activeArea._sourceGeos.firstObject._originalQuery.color")
            },activeArea);
        }

        return hoveredGeoData;
    }),
});
