import Ember from "ember";
import {computed} from "@ember/object";
import Prop from 'infegy-frontend/json-store/properties/model';
import BaseFieldDefinition from "infegy-frontend/models/field-definitions/base-field";

export default class RowValueScalarField extends BaseFieldDefinition {
    // Determines which field class to load
    fieldClass = "object-field";

    // For portion fields, the "whole" value the portion is part of (when that
    // portion has a fixed "whole" amount)
    // i.e. the sentiment fixedPortionWhole is usually 1.0, for passion it's 100
    // this does not need to be set for fields where this can be computed.
    @Prop.Number({required: false}) fixedPortionWhole;

    @Prop.String() statsType;
    @Prop.Boolean() isPercentageType;
    @Prop.Boolean() isPercentageType;

    // For scalar only field usage this is pretty much all that is needed....
    @computed("scalarValueOverride", "dataSeries", "dataSeries.data", "isLoaded", "fieldName")
    get scalarValue() {
        if (!Ember.isEmpty(this.scalarValueOverride)) {
            return this.scalarValueOverride;
        }
        if (!this.fieldName || !this.dataSeries || !this.dataSeries.data) {
            return;
        }
        let data = this.dataSeries.data;
        return data && data[this.fieldName];
    };
    // In case the field should just have a set scalarValue, use the below to override the computed value
    @Prop.Number({required: false}) scalarValueOverride;

    @computed.alias("scalarValue") summaryValue;

    @computed("fixedPortionWhole")
    get portionWhole() {
        if (this.fixedPortionWhole) {
            return this.fixedPortionWhole;
        }
        return 1.0;
    };

    @computed("isPercentageType", "fixedPortionWhole")
    get isPortionable() {
        return !!this.fixedPortionWhole || this.isPercentageType;
    }
}
