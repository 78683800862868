import Ember from "ember";
import BaseFieldSelections from "infegy-frontend/models/fields/field-selections/base";

export default BaseFieldSelections.extend({
    apiName: "engagements",
    defaultSubSegment: "Likes",

    allAvailableFields: Ember.computed("isLoaded", function () {
        return {
            "Engagements.Likes" : [this.makeFieldDefinition({
                fieldName: "likes",
                color: this.get("query.queryInfo.color")
            })],
            "Engagements.Comments" : [this.makeFieldDefinition({
                fieldName: "comments",
                color: this.get("query.queryInfo.color")
            })],
            "Engagements.Shares" : [this.makeFieldDefinition({
                fieldName: "shares",
                color: this.get("query.queryInfo.color")
            })],
            "Engagements.LikesUniverse" : [this.makeFieldDefinition({
                fieldName: "likesUniverse",
                title: "Likes Universe",
                color: this.get("query.queryInfo.color")
            })],
            "Engagements.CommentsUniverse" : [this.makeFieldDefinition({
                fieldName: "commentsUniverse",
                title: "Comments Universe",
                color: this.get("query.queryInfo.color")
            })],
            "Engagements.SharesUniverse" : [this.makeFieldDefinition({
                fieldName: "sharesUniverse",
                title: "Shares Universe",
                color: this.get("query.queryInfo.color")
            })],
            "Engagements.Engagements" : [this.makeFieldDefinition({
                fieldName: "engagements",
                color: this.get("query.queryInfo.color")
            })],
            "Engagements.EngagementsUniverse" : [this.makeFieldDefinition({
                fieldName: "engagementsUniverse",
                title: "Engagements Universe",
                color: this.get("query.queryInfo.color")
            })]
        };
    }),
});
