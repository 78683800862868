import Ember from "ember";
import Component from '@ember/component';
import {action, computed} from "@ember/object";
import classic from "ember-classic-decorator";

@classic
export default class extends Component {
    classNames = ["ages-data-table", "table-overflow-scroll"];

    queries = null;
    rows = null;
    selectedField = null;
    selectedAge = null;

    sortField = "displayName";
    sortAscending = true;

    @computed.alias("rows.data") ages;
    @computed.equal("sortField", "displayName") ageSort;
    @computed.equal("sortField", "documentsGrowthPct") timelineSort;
    @computed.equal("sortField", "documentsGrowthPct") changeSort;
    @computed.equal("sortField", "vsExpected") vsExpectedSort;
    @computed.equal("sortField", "dbDistribution") distributionSort;
    @computed.equal("sortField", "positivity") sentimentSort;
    @computed.equal("sortField", "count") postsSort;
    @computed.equal("sortField", "universe") universeSort;

    @computed("sortAscending")
    get sortedClass() {
        return this.sortAscending ? "sorted-asc" : "sorted-desc";
    }

    @computed("ages", "sortField", "sortAscending")
    get sortedAges() {
        let ages = this.ages || [],
            sortField = this.sortField,
            sortAscending = this.sortAscending;

        return ages.sort((ageA, ageB) => {
            let a = sortAscending ? Ember.get(ageA, sortField) : Ember.get(ageB, sortField),
                b = sortAscending ? Ember.get(ageB, sortField) : Ember.get(ageA, sortField);

                if (typeof(a) === "string") {
                    return a.localeCompare(b);
                }

            return a - b;
        });
    }

    @computed("queries", "selectedAge")
    get drilldownQuery() {
        const query = this.get("queries.firstObject");

        if (query) {
            const selectedAge = this.selectedAge;
            let ageQuery = query.copy(),
                queryInfo = ageQuery.get("queryInfo");

            queryInfo.addFilter({
                id: "source_age",
                min: selectedAge.rangeStart,
                max: selectedAge.rangeEnd
            });

            return [ageQuery];
        }

        return null;
    }

    @action
    sortBy(_sortField) {
        let sortField = this.sortField;
            if (sortField === _sortField) {
                this.toggleProperty("sortAscending");
            } else {
                this.setProperties({
                    sortField: _sortField
                });
            }
    }

    @action
    ageSelected(age) {
        if (this.selectedAge !== age) {
            this.set("selectedAge", age);
        } else {
            this.set("selectedAge", null);
        }
    }
}
