import Ember from "ember";

export default Ember.Component.extend({
    queries: [],

    showQuery: Ember.computed.gt("queries.length", 1),

    allPosts: Ember.computed("queries", "queries.length", "queries.@each.posts", "queries.@each.updated", function() {
        var queries = this.queries;

        var allPosts = queries.reduce((prev, query) => {
            var posts = query.get("posts.dataSeries.data");

            posts = posts.map((post) => {
                Ember.set(post, "query", query);
                return post;
            });

            prev.push(...posts);
            return prev;
        }, []).sortBy("score");

        return allPosts;
    }),
});
