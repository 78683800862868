import Ember from 'ember';
import Component from '@glimmer/component';
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import { action } from "@ember/object";
import { actionArgument, argument } from 'infegy-frontend/decorators/arguments';
import { tracked } from '@glimmer/tracking';
export default class EntityStringEditor extends Component {
    classNames = ["entity-string-editor"];
    @argument("entities") entityType;
    @argument([]) queryParts;
    @tracked suggestions;
    @argument(false) readOnly;
    @tracked lastSuggestionSearch = "";
    @actionArgument valueChanged;

    get outQuery() {
        let valueArray = this.queryParts;
        return valueArray.join(" ");
    }

    operatorOptions = [{
        label: '(',
        value: '(',
        description: 'Open a parenthesis block',
        type: 'operator',
        rank: 0
    }, {
        label: ')',
        value: ')',
        description: 'Close a parenthesis block',
        type: 'operator',
        rank: 0
    }, {
        label: 'AND',
        value: 'AND',
        description: 'AND operator',
        type: 'operator',
        rank: 0
    }, {
        label: 'OR',
        value: 'OR',
        description: 'OR operator',
        type: 'operator',
        rank: 0
    }, {
        label: 'NOT',
        value: 'NOT',
        description: 'NOT operator',
        type: 'operator',
        rank: 0
    }];

    get classedQueryParts() {
        var operatorValues = this.operatorOptions.mapBy("value");
        return this.queryParts.map((part) => {
            if (operatorValues.includes(part.value)) {
                part.className = "operator";
            }
            return part;
        });
    }

    @action
    queryPartsChanged(parts) {
        this.valueChanged(parts);
    }


    async retrieveSuggestions(search){
        if (search?.length < 3) {
            this.suggestions = [];
            return;
        }
        var res;
        try {
            res = await AtlasAuth.Get(`api/v3/query_autocomplete?prefix=${encodeURIComponent(search)}`);
        } catch(err) { 
            console.error(err); // eslint-disable-line no-console
            return;
        }
        this.lastSuggestionSearch = search;
        this.suggestions = res.output[this.entityType].map(entity => ({
            label: entity.name,
            value: entity.id,
            rank: entity.rank,
            type: entity.type
        }));
    }

    @action
    searchStringChanged(search) {
        if ((search || '').length > 1 && search !== this.lastSuggestionSearch) {
            Ember.run.throttle(this, this.retrieveSuggestions, search, 150);
        } else {
            this.suggestions = [];
        }
    }

}
