import Ember from "ember";
import {computed, action} from "@ember/object";
import Component from '@ember/component';

export default class SVGInnerWrapper extends Component {
    tagName = "svg";
    attributeBindings = ["x", "y", "widthAttr:width", "heightAttr:height", "viewBox", "style"];

    x = 0;
    y = 0;

    width = "100%";
    height = "100%";

    viewBox = null;

    @computed("width")
    get widthAttr() {
        let width = this.width;
        if (typeof(width) === "string") {
            return width;
        }
        width = width > 0 ? width : 1;
        return width + "px";
    }

    @computed("height")
    get heightAttr() {
        let height = this.height;
        if (typeof(height) === "string") {
            return height;
        }
        height = height > 0 ? height : 1;
        return height + "px";
    }

    @computed("width", "x")
    get offsetWidth() {
        return this.width + this.x;
    }

    @computed("height", "x")
    get offsetHeight() {
        return this.height + this.y;
    }
}
