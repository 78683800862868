import Ember from "ember";
import ChartColors from "infegy-frontend/static_data/fixtures/chart_colors";

export default Ember.Component.extend({
    tagName: "tr",
    classNameBindings: [":data-table-row", "clickable:topics-table-expandable"],

    topicIndex: 0,
    topic: null,
    topicsType: null,

    selectedTopic: null,

    hasQueryField: false,
    expandable: true,

    hasSourceTopics: Ember.computed.notEmpty("topic._sourceTopics"),

    clickable: Ember.computed("expandable", "hasSourceTopics",
            "topic._sourceTopics.length", "selectedTopic", function () {
        var expandable = this.expandable;
        if (this.hasSourceTopics && (!this.selectedTopic && this.get("topic._sourceTopics.length") < 2)) {
            return false;
        }
        return expandable;
    }),

    click: function () {
        var topic = this.clickable ? this.topic : null,
            selected = this.selectedTopic;
        if (!topic && !selected) {
            return;
        }
        if (this.hasSourceTopics && selected) {
            topic = null;
        }
        this.topicSelected((topic === selected) ? null : topic);
    },

    change: Ember.computed("topic.documentsGrowthPct", function () {
        var pct = (this.get("topic.documentsGrowthPct") || 0) * 100.0;
        return pct.toFixed(0);
    }),

    changeStyleSafe: Ember.computed("change", function() {
        var gradient = ChartColors.percentChangeGradientD3();
        return ["color:", gradient(this.change || 0), ";"].join("").htmlSafe();
    }),

    sentimentPercentage: Ember.computed("topic.positiveAppearancesDistribution", function () {
        var pct = (this.get("topic.positiveAppearancesDistribution") || 0) * 100.0;
        return pct.toFixed(0);
    }),

    sentimentStyleSafe: Ember.computed("topic.positiveAppearancesDistribution", function() {
        var sentiment = this.get("topic.positiveAppearancesDistribution") || 0.5,
            colorValues = ChartColors.sentimentZeroToOneGradientD3();
        var style = ["color:", colorValues(sentiment), ";"].join("");
        return new Ember.String.htmlSafe(style);
    }),

    sentimentWidthStyleSafe: Ember.computed("sentimentPercentage", function() {
        var percentage = this.sentimentPercentage || 50;
        return new Ember.String.htmlSafe(["width:", percentage, "%;"].join(""));
    }),

    passionPercentage: Ember.computed("topic.passion", function() {
        return (this.topic.passion || 0).toFixed(0);
    }),

    passionWidthStyleSafe: Ember.computed("passionPercentage", function() {
        let pct = this.get("passionPercentage") || 50;
        return new Ember.String.htmlSafe([`width: ${pct}%;`]);
    }),

    removeTopic: () => {},
    topicSelected: () => {},
    actions: {
        removeTopic: function(topic) {
            this.removeTopic(topic);
        }
    }
});
