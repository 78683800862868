import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";

export default QueryAPIResults.extend({
    title : "events",
    description: DataSeriesDescription.load({
        "date": "datestring",
        "attributeType": "string",
        "attributeName": "string",
        "value": "number",
        "raw": "number",
        "change": "number",
    })
});
