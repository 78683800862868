import Ember from "ember";
import DefaultColorDefinitions from "infegy-frontend/static_data/fixtures/default_color_definitions";
import { computed, action } from "@ember/object";


export default class ColorSelector extends Ember.Component {
    selectedColor = null;
    selectedColorChanged = () => { }

    defaultColorOptions = DefaultColorDefinitions;

    @action
    changeSelectedColor(color) {
        this.selectedColorChanged(color);
    }

    @action
    customColorChanged(ev) {
        this.selectedColorChanged({ label: "Custom", hex: ev.target.value });
    }
}