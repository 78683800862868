import Ember from "ember";
import SearchSelect from "infegy-frontend/components/forms/search-select/component";
import KeyCodes from "infegy-frontend/static_data/keycodes";
import { action } from "@ember/object";
import { tracked } from '@glimmer/tracking';
import { actionArgument, argument } from "infegy-frontend/decorators/arguments";

export default class SearchSelectMulti extends SearchSelect {
    @argument([]) selectedOptions;
    @argument([]) selectedValues;
    @argument(false) showRemoveButtons;
    @argument(false) allowDuplicateValues;
    @actionArgument selectedOptionsChanged;
    @actionArgument selectedValuesChanged;

    @tracked focusedOption = null;
    @tracked isLoading = false;

    /** @type {HTMLInputElement} */
    hiddenInput = null;

    filterItems(options) {
        if (this.allowDuplicateValues || Ember.isEmpty(this.innerSelectedOptions)) {
            return options;
        }
        return options.filter((option) => !this.innerSelectedOptions.find((innerOpt)=> this.compareItems(innerOpt,option)));
    }

    get innerSelectedOptions() {
        if (!Ember.isEmpty(this.selectedOptions)) {
            return this.selectedOptions;
        }
        if (!Ember.isEmpty(this.options)) {
            return this.selectedValues.map((value) => {
                let foundOption = this.options.find((innerOption)=>{
                    var innerValue = Ember.get(innerOption,this.valueField);
                    return this.compareValues(value, innerValue);
                });
                return foundOption || { label: this.unknownOptionLabel };
            });
        }
        return [];
    }

    @action
    optionClicked(option, event) {
        event.stopPropagation();
        this.focusedOption = option;
    }

    @action
    valueChangedAction(selectedOptions) {
        if (this.selectedValuesChanged) {
            this.selectedValuesChanged(selectedOptions.mapBy(this.valueField));
        }
        if (this.selectedOptionsChanged) {
            this.selectedOptionsChanged(selectedOptions);
        }
    }

    @action
    itemSelected(val) {
        this.searchString = "";
        let newSelection = [...(this.innerSelectedOptions || []), val];
        this.valueChangedAction(newSelection);
    }

    @action
    removeItem(item, event) {
        event.stopPropagation();
        var selectedOptions = Array.from(this.innerSelectedOptions);
        selectedOptions.removeObject(item);
        this.valueChangedAction(selectedOptions);
    }

    @action
    hiddenKeyPress(e) {
        if (this.innerSelectedOptions && this.focusedOption && (e.keyCode === KeyCodes.Delete || e.keyCode === KeyCodes.Backspace)) {
            var optionToRemove = this.focusedOption,
                selectionCopy = Array.from(this.innerSelectedOptions);
            selectionCopy.removeObject(optionToRemove);
            this.valueChangedAction(selectionCopy);
        }
        // keep hidden input clear, it does not use its value
        if( this.hiddenInput ){
            this.hiddenInput.value = '';
        }
    }

    @action
    searchKeyPress(e) {
        switch (e.keyCode) {
            case KeyCodes.Up:
                if (this.activeIndex > 0) {
                    this.activeIndex = this.activeIndex - 1;
                }
                break;
            case KeyCodes.Down:
                if (this.activeIndex + 1 < this.filteredItems?.length) {
                    this.activeIndex = this.activeIndex + 1;
                }
                break;
            case KeyCodes.Enter:
                if (this.filteredItems.length > this.activeIndex) {
                    this.itemSelected(this.filteredItems[this.activeIndex]);
                }
                break;
            case KeyCodes.Backspace:
            case KeyCodes.Delete:
                if (this.searchString?.length <= 0 && this.innerSelectedOptions) {
                    var tempOptions = Array.from(this.innerSelectedOptions);
                    tempOptions.removeObject(tempOptions.lastObject);
                    this.valueChangedAction(tempOptions);
                }
                break;
            case KeyCodes.Tab:
            case KeyCodes.Escape:
                this.searchFocusOut();
                break;
            default: // normal keypress
                break;
        }
    }
}
