import Ember from "ember";

var colorDemoMap = [{
    title: "General Use",
    families: [{
        title: "Backgrounds",
        baseKey: "atlas-background",
        variants: [""]
    },{
        title: "Contrast",
        baseKey: "atlas-contrast",
        variants: [""]
    },{
        title: "Neutrals",
        baseKey: "atlas-neutral",
        variants: ["-lightest", "-lighter", "-light", "", "-dark", "-darker"]
    },{
        title: "UI Elements",
        baseKey: "atlas-ui",
        variants: ["-lightest", "-lighter", "-light", "", "-dark"]
    },{
        title: "Text",
        baseKey: "atlas-text-color",
        variants: ["-lightest", "-lighter", "-light", "", "-dark"]
    }]
},{
    title: "General Charting",
    families: [{
        title: "Chart 1",
        baseKey: "atlas-chart-1",
        variants: ["-light", "", "-dark"]
    },{
        title: "Chart 2",
        baseKey: "atlas-chart-2",
        variants: ["-light", "", "-dark"]
    },{
        title: "Chart 3",
        baseKey: "atlas-chart-3",
        variants: ["-light", "", "-dark"]
    },{
        title: "Chart 4",
        baseKey: "atlas-chart-4",
        variants: ["-light", "", "-dark"]
    },{
        title: "Chart 5",
        baseKey: "atlas-chart-5",
        variants: ["-light", "", "-dark"]
    },{
        title: "Chart 6",
        baseKey: "atlas-chart-6",
        variants: ["-light", "", "-dark"]
    },{
        title: "Chart 7",
        baseKey: "atlas-chart-7",
        variants: ["-light", "", "-dark"]
    },{
        title: "Chart 8",
        baseKey: "atlas-chart-8",
        variants: ["-light", "", "-dark"]
    },{
        title: "Chart 9",
        baseKey: "atlas-chart-9",
        variants: ["-light", "", "-dark"]
    },{
        title: "Chart 10",
        baseKey: "atlas-chart-10",
        variants: ["-light", "", "-dark"]
    }]
},{
    title: "Gender",
    families: [{
        title: "male",
        baseKey: "atlas-male",
        variants: ["-light", "", "-dark"]
    },{
        title: "female",
        baseKey: "atlas-female",
        variants: ["-light", "", "-dark"]
    }]
},{
    title: "Channels",
    families: [{
        title: "microblogs",
        baseKey: "atlas-microblogs",
        variants: ["-light", "", "-dark"]
    },{
        title: "blogs",
        baseKey: "atlas-blogs",
        variants: ["-light", "", "-dark"]
    },{
        title: "images",
        baseKey: "atlas-images",
        variants: ["-light", "", "-dark"]
    },{
        title: "forums",
        baseKey: "atlas-forums",
        variants: ["-light", "", "-dark"]
    },{
        title: "twitter",
        baseKey: "atlas-twitter",
        variants: ["-light", "", "-dark"]
    },{
        title: "facebook",
        baseKey: "atlas-facebook",
        variants: ["-light", "", "-dark"]
    },{
        title: "uncategorized",
        baseKey: "atlas-uncategorized",
        variants: ["-light", "", "-dark"]
    }]
},{
    title: "Sentiment",
    families: [{
        title: "Positive",
        baseKey: "atlas-sentiment-positive",
        variants: ["-light", "", "-dark"]
    },{
        title: "Negative",
        baseKey: "atlas-sentiment-negative",
        variants: ["-light", "", "-dark"]
    },{
        title: "Neutral",
        baseKey: "atlas-sentiment-neutral",
        variants: ["-light", "", "-dark"]
    },{
        title: "Mixed",
        baseKey: "atlas-sentiment-mixed",
        variants: ["-light", "", "-dark"]
    },{
        title: "Passion",
        baseKey: "atlas-passion",
        variants: ["-light", "", "-dark"]
    }]
}];


export default Ember.Controller.extend({
    colorDemoMap: colorDemoMap,

    hideGroupTitles: false,
    hideFamilyTitles: false,
    showClassNames: false
});

