import Component from '@ember/component';
import {computed, action} from "@ember/object";
import CustomTrendField from "infegy-frontend/models/trends/custom-trend-field";
import classic from "ember-classic-decorator";

@classic
export default class TrendsFieldSelector extends Component {
    user = null;
    queries = null;

    fields = null;

    editFieldIndex = null;
    tempField = null;

    maxFields = 6;

    fieldsChanged() { /* action */ }

    @computed("fields", "fields.[]", "fields.length", "maxFields", "editFieldIndex")
    get canAdd() {
        return this.fields?.length < this.maxFields && Ember.isEmpty(this.editFieldIndex);
    }

    @computed("fields", "fields.[]", "fields.length", "maxFields")
    get canRemove() {
        return this.fields?.length > 1;
    }

    @action
    addField() {
        let query = this.queries  && this.queries[0],
            newField = CustomTrendField.create();
        newField.field.set("query", query);
        newField.customStartDate.loadJSON(query.filters.startDate.toJSON());
        newField.customEndDate.loadJSON(query.filters.endDate.toJSON());

        this.fieldsChanged([].concat(this.fields, [newField]));
    }

    @action
    updateCustomField(field, fieldIndex) {
        let newFields = [...this.fields];
        newFields[fieldIndex] = field;
        this.fieldsChanged(newFields);
    }

    @action
    deleteField(field, fieldIndex) {
        let newFields = [...this.fields];
        newFields.splice(fieldIndex, 1);
        this.fieldsChanged(newFields);
    }

    makeTempFieldFromIndex(index) {
        let fields = this.fields || [],
            editField = fields.objectAt(index);

        if (!editField) {
            return null;
        }

        let tempField = CustomTrendField.create(),
            fieldJSON = editField.toJSON(),
            fieldQuery = editField?.field?.query,
            originalStartTime = editField?.field?.query?.queryFilters?.startDate?.toJSON(),
            originalEndTime = editField?.field?.query?.queryFilters?.endDate?.toJSON();
        delete fieldJSON.field;
        tempField.loadJSON(fieldJSON);
        tempField.set("field", editField?.field?.copy(fieldQuery));
        if (!tempField.usesCustomDates) {
            tempField.customStartDate.loadJSON(originalStartTime);
            tempField.customEndDate.loadJSON(originalEndTime);
        }
        return tempField;
    }


    @action
    setEditFieldIndex(index) {
        let tempField = null;
        if (!Ember.isEmpty(index)) {
            tempField = this.makeTempFieldFromIndex(index);
        }
        this.set("tempField", tempField);
        this.set("editFieldIndex", tempField ? index : null);
    }
};
