// NOTE: These are generated by the selector, not manually entered. To get new defaults,
// select desired values in the UI and output the JSON dump to replace the below.
export default {
    "selected_layout": "fieldColumn",
    "fields": [{
        "display_type": "sparkline",
        "field": {
            "fieldClass": "trend-field",
            "api_name": "volume",
            "segment": "Posts",
            "subsegment": "Universe",
            "field_component": "field-selector/parts/fields/posts",
            "field_name": "postsNormalizedUniverse",
            "title": "Universe",
            "value": "volume.postsNormalizedUniverse",
            "field_type": "trend",
        },
    }, {
        "display_type": "heatmap",
        "field": {
            "fieldClass": "trend-field",
            "api_name": "volume",
            "segment": "Posts",
            "subsegment": "Universe",
            "field_component": "field-selector/parts/fields/posts",
            "field_name": "postsNormalizedUniverse",
            "title": "Universe",
            "value": "volume.postsNormalizedUniverse",
            "field_type": "scalar",
            "scalar_field": "sum"
        }
    }, {
        "display_type": "heatmap",
        "field": {
            "fieldClass": "trend-field",
            "api_name": "sentiment",
            "segment": "Sentiment",
            "subsegment": "DocumentsDistribution",
            "field_component": "field-selector/parts/fields/sentiment",
            "title": "positive",
            "field_name": "positiveDocumentsDistribution",
            "default_color": "rgb(127, 196, 10)",
            "inactive_by_default": false,
            "value": "sentiment.positiveDocumentsDistribution",
            "field_type": "scalar",
            "scalar_field": "sum"
        }
    }, {
        "display_type": "heatmap",
        "field": {
            "fieldClass": "trend-field",
            "api_name": "sentiment",
            "segment": "Sentiment",
            "subsegment": "DocumentsDistribution",
            "field_component": "field-selector/parts/fields/sentiment",
            "title": "negative",
            "field_name": "negativeDocumentsDistribution",
            "default_color": "rgb(239, 8, 73)",
            "inactive_by_default": false,
            "value": "sentiment.negativeDocumentsDistribution",
            "field_type": "scalar",
            "scalar_field": "sum",
            "selected_color": "rgba(239, 8, 73, 1)"
        }
    }],
};
