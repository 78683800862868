import Ember from "ember";
import DataSeries from "infegy-frontend/models/data_series/data_series";
import ChartColors from "infegy-frontend/static_data/fixtures/chart_colors";
import CalculateFilterRange from "infegy-frontend/models/fields/trend-filter-range-calculator";
import QueryDataGrouping from "infegy-frontend/models/queries/query_data_grouping";

import Component from '@ember/component';
import { computed } from '@ember/object';

export default class SentimentCombinedBase extends Component {
    queries = null;

    sentimentField = "documents";

    isSelected = false;
    selectedIndex = null;
    hoveredIndex = null;

    @computed.alias("queries.firstObject") firstQuery;
    @computed.alias("activeFields.positiveDistribution") positiveField;
    @computed.alias("activeFields.negativeDistribution") negativeField;

    colors = ChartColors.colorMap;

    @computed("firstQuery.activeGrouping")
    get groupKey() {
        const queryGrouping = this.firstQuery.activeGrouping;
        const apiGrouping = QueryDataGrouping.groupingKeys[queryGrouping];
        return apiGrouping;
    }

    @computed("sentimentField")
    get sentimentFieldUnits() {
        let field = this.sentimentField;
        if (field === "documents") {
            return "Posts";
        } else if (field === "documentsUniverse") {
            return "Universe Posts";
        } else if (field === "subjectSentences") {
            return "Subject Sentences";
        }
        return field.charAt(0).toUpperCase() + field.slice(1);
    };

    @computed("queries", "queries.length", "queries.@each.updated", "groupKey")
    get groupedDataSeries() {
        let queries = this.queries || [],
            groupKey = this.groupKey;

        return queries.map(query => {
            let sentiment = query.sentiment;
            return sentiment.groupBy(groupKey);
        });
    };

    @computed("groupKey", "groupedDataSeries")
    get combinedDataSeries() {
        if (Ember.isEmpty(this.groupedDataSeries)) {
            return {};
        }
        let groupedDataSeries = this.groupedDataSeries,
            combined = DataSeries.stackTimeseries(groupedDataSeries),
            data = combined.data || [];
        combined.set("data", data.sortBy("timestamp"));
        return combined;
    };

    @computed("queries", "combinedDataSeries", "selectedIndex")
    get filterDateRange() {
        let data = this.combinedDataSeries.data;
        if (!Ember.isEmpty(this.selectedIndex)) {
            return CalculateFilterRange.fromDataIndex(data, this.selectedIndex || 0, this.groupKey || "timestamp");
        }
    };

    @computed("sentimentField")
    get activeFields() {
        let sentimentField = this.sentimentField;
        return {
            positive: `positive_${sentimentField}`.camelize(),
            negative: `negative_${sentimentField}`.camelize(),
            neutral: `neutral_${sentimentField}`.camelize(),

            positiveDistribution: `positive_${sentimentField}_Distribution`.camelize(),
            negativeDistribution: `negative_${sentimentField}_Distribution`.camelize(),
        };
    };
};
