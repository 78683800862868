import Ember from 'ember';
import DateFilters from "infegy-frontend/static_data/query/date_filters";
import AtlasConfig from "infegy-frontend/config/infegy-app-config";

export default Ember.Component.extend({
    classNames: ["row", "query-date-range"],

    earliestTimestamp: AtlasConfig.EarliestAtlasTimestamp,
    latestTimestamp: null, // if no latest date is set, the current date should be generated as late as possible

    date: null,

    endDateOptions: DateFilters.endDateOptions,

    endDateType: Ember.computed("date", "date.isRelative", "date.relative.isNow", {
        get: function(key) {
            if (this.date && this.date.get("isRelative")) {
                return (this.date.get("relative.isNow")) ? "now" : "relative";
            }
            return "fixed";
        },
        set: function(key, value) {
            if (value && this.date) {
                this.date.set("isRelative", (value !== "fixed"));
                this.date.set("relative.isNow", (value === "now"));
            }
            if (this.date && this.date.get("isRelative")) {
                return (this.date.get("relative.isNow")) ? "now" : "relative";
            }
            return "fixed";
        }
    }),

    datesChanged: () => {},
    actions: {
      endDateOptionsChanged(endDateType){
        this.set("endDateType", endDateType);
        this.send("dateChanged");
      },
      dateChanged(){
          this.datesChanged(this.date);
      },
    }
});
