import Ember from "ember";
export default Ember.Mixin.create({
    queries: null,
    apis: [],

    hideInnerView: Ember.computed.or("isEmpty", "isLoading"),
    prefetchQueryApis: Ember.on("init",Ember.observer("queries", "queries.[]", "apis", "apis.[]", function() {
        var queries = this.queries,
            apis = this.apis;

        if (Ember.isArray(queries) && Ember.isArray(apis)) {
            queries.forEach(function(query) {
                apis.forEach(function(apiName) {
                    query.get(apiName);
                });
            }, this);
        }
    })),

    isLoading: Ember.computed.not('isLoaded'),
    isLoaded: Ember.computed("queries", "queries.[]", "apis", "apis.[]",
            "queries.@each.updated", "isEmpty", {
        get: function(key) {
            var queries = this.queries,
                apis = this.apis;

            if (!Ember.isArray(apis)) {
                return true;
            }

            if (!Ember.isArray(queries)) {
                return false;
            }

            return queries.every(function (query) {
                return apis.every(function (api_name) {
                    return query.get(api_name).get("isLoaded");
                });
            }, this) || this.isEmpty;
        },
    }),
    isEmpty: Ember.computed("queries", "queries.[]", "apis", "apis.[]",
            "queries.@each.updated", {
        get: function(key) {
            var queries = this.queries,
                apis = this.apis;

            if (!Ember.isArray(queries) || !Ember.isArray(apis)) {
                return false;
            }

            return queries.every(function (query) {
                return apis.every(function (api_name) {
                    return query.get(api_name).get("isEmpty");
                });
            }, this);
        },
    }),
});
