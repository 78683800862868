import JsonStore from "infegy-frontend/json-store/model";
import Prop from "infegy-frontend/json-store/properties/model";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import classic from "ember-classic-decorator";

@classic
export default class QualtricsDataPusher extends JsonStore {
    @Prop.Int() id;
    @Prop.String() name;
    @Prop.String() qualtricsToken;
    @Prop.String() qualtricsSurveyId;
    @Prop.String() qualtricsQuestionId;
    @Prop.String() queryParams;
    @Prop.String() created;
    @Prop.String() lastPush;
    @Prop.Int() totalPosts;
    @Prop.Int() totalBytes;
    @Prop.Int() totalSkipped;
    @Prop.Boolean() isPaused;

    async save(name, qualtrics_token, survey_id, question_id, query) {
        return AtlasAuth.Post(`api/v3/qualtrics-push?name=${name}&qualtrics_token=${qualtrics_token}&survey_id=${survey_id}&question_id=${question_id}&${query}`);
    }

    async getById(id) {
        return AtlasAuth.Get({
            url: "api/v3/qualtrics-push",
            data: {
                id
            }
        }).then((response) => {
            this.loadJSON(response.output);
        });
    }

    async delete() {
        return AtlasAuth.Delete(`api/v3/qualtrics-push?id=${this.id}`);
    }

    async pause() {
        return AtlasAuth.Put({
            url: "api/v3/qualtrics-push",
            data: {
                id: this.id,
                pause: true
            }
        }).then((response) => {
            this.loadJSON(response.output);
        });;
    }

    async resume() {
        return AtlasAuth.Put({
            url: "api/v3/qualtrics-push",
            data: {
                id: this.id,
                pause: false
            }
        }).then((response) => {
            this.loadJSON(response.output);
        });;
    }
}