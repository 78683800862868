import Ember from "ember";
import TrendsFieldInformation from "infegy-frontend/components/trends/trends-information/trends-field-information/component";

export default TrendsFieldInformation.extend({
    tagName: "h4",
    classNames: ["trends-field-information-inline-field"],

    hideInformation: Ember.computed("fieldType", "primaryValue", function () {
        return this.primaryValueType === "Total" && 
            this.fieldType === "portion" && this.primaryValue >= 0.999999;
    }),
});
