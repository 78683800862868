import Ember from "ember";
import Component from '@ember/component';
import {computed, action} from "@ember/object";
import BrowserStore from "infegy-frontend/utils/browser-store";
import QueryAPIEntities from "infegy-frontend/models/query-api/entities";

export default class extends Component {
    classNames = ["linked-entities"];

    queries = null;
    selectedEntities = null;
    hasMoreThanOneQuery = false;

    removeEntity() {};

    sortingOptions = [
        { title: "Name", value: "name" },
        { title: "Sentiment", value: "positiveAppearancesDistribution" },
        { title: "Posts", value: "universe" },
        { title: "Appearances", value: "appearances" }
    ];

    @BrowserStore.Attr("linked-entities-sortkey", { defaultValue: "documents" }) selectedSortingKey;
    @BrowserStore.Attr("linked-entities-sortorder", { defaultValue: "descending" }) selectedSortOrder;

    currentPage = 0;
    entitiesPerPage = 18;

    @computed("hasMoreThanOneQuery", "selectedEntities", "selectedEntities.[]", "selectedEntities.length")
    get dedupedEntities() {
        var hasMoreThanOneQuery = this.hasMoreThanOneQuery;
        var selectedEntities = this.selectedEntities;

        if (!hasMoreThanOneQuery) {
            return selectedEntities;
        } else {
            return QueryAPIEntities.combineMatchingEntities(selectedEntities);
        }
    }

    @computed("dedupedEntities")
    get collatedEntities() {
        var dedupedEntities = this.dedupedEntities;
        let entityMap = {};

        dedupedEntities = dedupedEntities.filter((entity) => {
            var hasNoPotentialDupes = true;

            if (entity.name.indexOf("(") > -1 && entity.name.indexOf(")") > -1){
                // if it has a potential dupe (indicated by parens),
                // put it in the map and remove it from dedupedEntities
                let entityBaseName = entity.name.substring(0, entity.name.indexOf("(") - 1);
                entityMap[entityBaseName] = entityMap[entityBaseName] || [];
                entityMap[entityBaseName].push(entity);

                hasNoPotentialDupes = false;
            }

            return hasNoPotentialDupes;
        });

        for (let key in entityMap) {
            if (entityMap[key].length > 1) {
                entityMap[key] = entityMap[key].sortBy("appearances");
                entityMap[key][0]["collatedEntities"] = entityMap[key].slice(1);

                dedupedEntities.push(entityMap[key][0]);
            }
        }

        return dedupedEntities;
    }

    @computed("collatedEntities", "selectedSortingKey", "selectedSortOrder")
    get sortedEntities() {
        var collatedEntities = this.collatedEntities,
            selectedSortingKey = this.selectedSortingKey,
            selectedSortOrder = this.selectedSortOrder;

        var sortedEntities = collatedEntities.sortBy(selectedSortingKey);

        if (selectedSortOrder === "descending") {
            sortedEntities = sortedEntities.reverse();
        }

        return sortedEntities;
    }

    @computed("entitiesPerPage", "currentPage", "sortedEntities")
    get displayedLinkedEntities() {
        let perPage = this.entitiesPerPage,
            currentPage = this.currentPage,
            currentIndex = currentPage * perPage,
            entities = this.sortedEntities,
            displayedLinkedEntities = entities.slice(currentIndex, currentIndex + perPage);

        // Edge Case: Given limited results,
        // sessionStorage with stored page breaks the paginator.
        if (!displayedLinkedEntities.length) {
            currentIndex = 0;
        }

        return entities.slice(currentIndex, currentIndex + perPage);
    }

    @action
    selectedSortingKeyChanged(selectedKey) {
        this.set("selectedSortingKey", selectedKey);
    }

    @action
    selectedSortOrderChanged(order) {
        this.set("selectedSortOrder", order);
    }
}
