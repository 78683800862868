import Ember from "ember";
import Component from '@ember/component';
import { computed } from '@ember/object';
import FieldSelectorBase from "infegy-frontend/components/field-selector/parts/fields/trendable-field-base/component";

export default class FieldSelectorPosts extends FieldSelectorBase {
    apiName = "numericStats";
    datasetFields = null;
    maxSelection  =  1;

    @computed("allQueries")
    get apiModels() {
        let queries = this.queries || [],
            field = this.selectedSubsegment;
        return this.allQueries?.map(q => {
            return q.fetchNumericStats(field);
        });
    }

    @computed("fieldType", "datasetFields", "selectedSegment", "selectedSubsegment", "isLoaded", "apiModels")
    get allAvailableFields() {
        let options = {},
            selectedSubsegment = this.selectedSubsegment,
            datasetFields = this.datasetFields || [],
            dsField = datasetFields.findBy("id", selectedSubsegment),
            datasetFieldName = dsField ? dsField.name : "Dataset";

        options[this.selectedSubsegment] = [
                this.makeFieldDefinition({
                    fieldName: "average",
                    customDataField: this.selectedSubsegment,
                    title: "Average",
                    chartTitleOverride: datasetFieldName + " Average",
                    axisGroup: "datasetAverage"
                }), this.makeFieldDefinition({
                    fieldName: "sum",
                    customDataField: this.selectedSubsegment,
                    title: "Sum",
                    chartTitleOverride: datasetFieldName + " Sum",
                    axisGroup: "datasetSum"
                }), this.makeFieldDefinition({
                    fieldName: "min",
                    customDataField: this.selectedSubsegment,
                    title: "Min",
                    chartTitleOverride: datasetFieldName + " Min",
                    axisGroup: "datasetAverage"
                }), this.makeFieldDefinition({
                    fieldName: "max",
                    customDataField: this.selectedSubsegment,
                    title: "Max",
                    chartTitleOverride: datasetFieldName + " Max",
                    axisGroup: "datasetAverage"
                }), this.makeFieldDefinition({
                    fieldName: "count",
                    customDataField: this.selectedSubsegment,
                    title: "Count",
                    chartTitleOverride: datasetFieldName + " Count",
                    axisGroup: "datasetCount"
                })
            ];
        return options;
    }
};
