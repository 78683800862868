import Component from "@ember/component";
import { action, computed } from "@ember/object";
import classic from "ember-classic-decorator";

import AdminUsers from "infegy-frontend/models/admin/admin_users";
import Workspaces from "infegy-frontend/models/users/workspaces";

@classic
export default class extends Component {
    /**
     * @type {"run_workspace"|"edit_or_view"|"view_sharing_details"|"change_folder"|"delete"|"select_workspace"|"select_queries"|"toggle_save"}
     */
    actionBarButtons = [];
    adminUsers = AdminUsers.create();
    /**
     * @type {Query[]}
     */
    queryFormQueries = [];
    user = null;
    workspaces = Workspaces.create();

    @computed.oneWay("workspaces.search") searchText;
    @computed.oneWay("workspaces.sortField") sortField;
    @computed.oneWay("workspaces.sortDirection") sortDirection;

    /**
     * @event editWorkspace Fired when the user clicks the "Edit/View" action button.
     * @param {Workspace}
     */
    /**
     * @event loadWorkspace Fired when the user clicks the "Load Workspace" action button.
     * @param {Workspace}
     */
    /**
     * @event queriesSelected Fired when the user clicks the "Select Queries" action button.
     * @param {Query[]}
     */
    /**
     * @event runWorkspace Fired when the user clicks the "Run Workspace" action button.
     * @param {Workspace}
     */

    // Internal Parameters
    sortFieldOptions = Workspaces.SORT_FIELD_OPTIONS;

    editWorkspace() {}
    loadWorkspace() {}
    queriesSelected() {}
    runWorkspace() {}
    toggleSaveWorkspace() {}
    workspaceChangedFolder() {}

    @action disableClickThrough() {}

    search(options) {
        options["page_index"] = 0;
        this.workspaces.getPage(options);
    }

    @action async onConfirmDeleteWorkspace(workspace) {
        await workspace.delete();
        this.workspaces.removeObject(workspace);
    }

    @action onSharingDeclined(workspace) {
        // Update the workspaces on the management list page
        this.workspaces.removeObject(workspace);
        // Update the workspaces list that gets displayed on the dashboard
        this.user.sharedWorkspaces.getPage();
    }
}