import Ember from "ember";
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import BrowserStore from "infegy-frontend/utils/browser-store";
import FieldDefinitionLoader from "infegy-frontend/models/field-definitions/loader";

export default class FieldsSelectorExamples extends Component {
    queries = null;
    selectedField = null;
    selectedFields = null;

    fieldTypeOptions = ["trend", "scalar", "portion"];
    fieldType = "scalar";

    viewOptions = ["single", "multi", "singleToggles"];
    viewType = "single";

    savedFields = BrowserStore.Attr("fields-selector-example-saved-fields", { default: 0 });
    savedField = BrowserStore.Attr("fields-selector-example-saved-fields", { default: 0 });

    @computed("viewType")
    get isMultiSelect() {
        return this.viewType === "multi";
    }

    @computed("isMultiSelect", "selectedField", "selectedFields")
    get displayFields() {
        return this.isMultiSelect ? this.selectedFields : [this.selectedField];
    }

    @computed("isMultiSelect", "viewType")
    get displayAsDropdown() {
        return !this.isMultiSelect && this.viewType === "single";
    }

    @action
    viewTypeChanged(viewType) {
        this.set("viewType", viewType);
        this.set("selectedFields", null);
    };

    @action
    fieldTypeChanged(fieldType) {
        this.set("fieldType", fieldType);
        this.set("selectedFields", null);
    };

    @action
    fieldsChanged(selectedFields) {
        this.set("selectedFields", selectedFields);
    };

    @action
    fieldChanged(selectedField) {
        this.set("selectedField", selectedField);
    };

    @action
    saveFields() {
        if (this.viewType === "multi" && this.selectedFields) {
            let fieldsJSON = this.selectedFields.map(field => {
                return field.toJSON();
            });
            this.set("savedFields", fieldsJSON);
        } else if (this.selectedField) {
            let fieldJSON = this.selectedField.toJSON();
            this.set("savedField", fieldJSON);
        }
    };

    @action
    loadFields() {
        if (this.viewType === "multi" && this.savedFields) {
            let fieldsJSON = this.savedFields,
                fields = fieldsJSON.map(fieldJSON => {
                    return FieldDefinitionLoader.loadField(fieldJSON, this.queries);
                });
            this.set("selectedFields", fields);
            this.set("selectedField", fields && fields[0]);
        } else if (this.savedField) {
            let field = FieldDefinitionLoader.loadField(this.savedField, this.queries);
            this.set("selectedFields", [field]);
            this.set("selectedField", field);
        }
    };
};
