import Ember from "ember";
import Channels from "infegy-frontend/static_data/query/query_filters/channels";
import Countries from "infegy-frontend/static_data/query/query_filters/countries";
import Gender from "infegy-frontend/static_data/query/query_filters/gender";
import Influence from "infegy-frontend/static_data/query/query_filters/influence";
import Languages from "infegy-frontend/static_data/query/query_filters/languages";
import TimeOfDay from "infegy-frontend/static_data/query/query_filters/time_of_day";
import UsStates from "infegy-frontend/static_data/query/query_filters/us_states";
import Interests from "infegy-frontend/static_data/query/query_filters/interests";
import Sentiment from "infegy-frontend/static_data/sentiment";
import Themes from "infegy-frontend/static_data/themes";
import Emotions from "infegy-frontend/static_data/emotions";
import Shares from "infegy-frontend/static_data/query/query_filters/shares";
import Replies from "infegy-frontend/static_data/query/query_filters/replies";
import CAProvinces from "infegy-frontend/static_data/query/query_filters/ca_provinces";

var queryFilters = Ember.Object.extend({
    Channels: Channels,
    Countries: Countries,
    CAProvinces: CAProvinces,
    Gender: Gender,
    Influence: Influence,
    Languages: Languages.allLanguages,
    TimeOfDay: TimeOfDay,
    UsStates: UsStates,
    Sentiment,
    Themes: Themes.themeOptions,
    Emotions: Emotions.emotionOptions,
    Shares: Shares,
    Replies: Replies,

    //* This request does not require auth and can be sent immediately
    PostInterests: Interests.create(),

    SourceInterests: Ember.computed(function() {
        /* Needs to return an assignable object that will update as 
            the postInterests response returns and then filter to appropriate interests */
        var SourceInterests = Ember.ArrayProxy.extend({
            interests: this.PostInterests,
            content: Ember.computed("interests.[]", function(){
                return (this.interests ?? []).filter(interest => interest.tier < 3);
            }),
        });

        return SourceInterests.create();
    }),

    Default: [{
        "name": "",
        "value": "",
        "view": "",
        "options": ""
    }]
});

export default queryFilters.create();
