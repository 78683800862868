import Ember from 'ember';
import DateProcessing from "infegy-frontend/utils/date-processing";

var millisInDay = DateProcessing.constants.millisInDay;

let calculateFilterRangeFromInteractionInfo = function(data, interactionInfo, groupByField) {
    var startRow, endRow, startTime, endTime;

    if (data && interactionInfo && Ember.get(interactionInfo, "isSelected")) {
        var xIndex = Ember.get(interactionInfo, "selectedXIdx") || 0;
        startRow = data[xIndex];
        endRow = startRow;
        startTime = startRow && (Ember.get(startRow, "timestamp") || Ember.get(startRow, "hour"));
        endTime = startTime;
    } else if (data && interactionInfo && Ember.get(interactionInfo, "isBrushed")) {
        startRow = data[0];
        endRow = data[data.length - 1];
        startTime = startRow && (Ember.get(startRow, "timestamp") || Ember.get(startRow, "hour"));
        endTime = endRow && (Ember.get(endRow, "timestamp") || Ember.get(endRow, "hour"));
    }

    if (endTime && groupByField === "_dayOfWeek") {
        startTime = new Date(startTime).getUTCDay();
        endTime = new Date(endTime).getUTCDay();
    } else if (endTime && groupByField === "_weeksSinceEpoch") {
        endTime += 6 * millisInDay;
    } else if (endTime && groupByField === "_monthsSinceZero") {
        endTime = Ember.get(endRow, "_monthsSinceZero") + 1;
        endTime = DateProcessing.monthsSinceZeroToTimestamp(endTime);
        endTime -= millisInDay;
    } else if (endTime && groupByField === "_year") {
        endTime = Ember.get(endRow, "_year") + 1;
        endTime = DateProcessing.yearToTimestamp(endTime);
        endTime -= millisInDay;
    }
    return startTime && endTime && { start: startTime, end: endTime };
};

export default {
    fromInteractionInfo: calculateFilterRangeFromInteractionInfo,

    fromDataIndex(data, index, groupByField) {
        let interactionInfo = {
            isSelected: true,
            selectedXIdx: index
        };
        return calculateFilterRangeFromInteractionInfo(data, interactionInfo, groupByField);
    }
};
