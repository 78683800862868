import Ember from "ember";

import JsonCollectionStore from 'infegy-frontend/json-store/collection/model';
import BasicTypes from 'infegy-frontend/json-store/properties/basic-types/model';

function generateUUID(){
    var d = Date.now();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c==='x' ? r : (r&0x3|0x8)).toString(16);
    });
    return uuid;
}

var properties = Object.assign(BasicTypes, {
    Attr: function (options) {
        var _options = Object.assign({
            default: null
        }, options);
        return Ember.computed({
            get: function(key) {
                var data = this._data;
                if (Ember.isEmpty(data[key]) && !Ember.isEmpty(_options.default)) {
                    data[key] = _options.default;
                }
                return data[key];
            },
            set: function(key, value) {
                var data = this._data;
                if (Ember.isEmpty(value) && !Ember.isEmpty(_options.default)) {
                    value = _options.default;
                }
                if (value === data[key]) {
                    return value;
                }
                if (Ember.isEmpty(value)) {
                    delete data[key];
                    return null;
                } else {
                    data[key] = value;
                }
                this.set("isDirty", true);
                return value;
            }
        });
    },

    GenId: function (options) {
        return Ember.computed({
            get: function(key) {
                var data = this._data;
                if (Ember.isEmpty(data[key])) {
                    data[key] = generateUUID();
                    this.set("isDirty", true);
                }
                return data[key];
            },
            set: function(key, value) {
                var data = this._data,
                    newValue = value;
                if (!value) {
                    if (!data[key]) {
                        newValue = generateUUID();
                    } else {
                        newValue = data[key];
                    }
                }
                if (newValue !== data[key]) {
                    data[key] = newValue;
                    this.set("isDirty", true);
                }
                return newValue;
            }
        });
    },

    Collection: function (itemClass, options) {
        return Ember.computed({
            get: function(key) {
                var data = this._data;
                var controller = data[key];
                if (!controller) {
                    controller = JsonCollectionStore.create();
                    if (itemClass) {
                        controller.set("itemClass", itemClass);
                    }
                    controller.set("parentObject", this);
                    data[key] = controller;
                }
                return controller;
            },
            set: function (key, value) {
                console.error("Cannot set JsonCollectionStore property.");
            },
        });
    },

    Object: function (objectClass, options) {
        var _options = Object.assign({
            defaultJSON: null,
        }, options);
        return Ember.computed({
            get: function(key) {
                var data = this._data;
                var object = data[key];
                if (Ember.typeOf(object) !== "instance") {
                    object = objectClass.create();
                    object.set("parentObject", this);
                    if (Ember.typeOf(object) === "object") {
                        object.loadJSON(object);
                    } else if (_options.defaultJSON) {
                        object.loadJSON(_options.defaultJSON);
                    }
                    data[key] = object;
                }
                return object;
            },
            set: function (key, value) {
                var data = this._data,
                    object = data[key];

                if (Ember.typeOf(value) === "instance") {
                    if (value !== object) {
                        data[key] = value;
                        value.set("parentObject", this);
                        this.set("isDirty", true);
                    }
                    object = value;
                } else {
                    object = objectClass.create();
                    object.set("parentObject", this);
                    if (value) {
                        object.loadJSON(value);
                    } else {
                        object.loadJSON(_options.defaultJSON || {});
                    }
                    this.set("isDirty", true);
                    data[key] = object;
                }
                return object;
            },
        });
    },
});

export default properties;
