import Ember from "ember";
import {computed, action} from "@ember/object";
import TrendsInformation from "infegy-frontend/components/charting/trend-chart/trends-information/component";
import classic from "ember-classic-decorator";

@classic
export default class extends TrendsInformation {
    @computed("trendFields", "trendFields.length", "isSinglePoint")
    get fieldDisplaySize() {
        var numFields = this.get("trendFields.length") || 1;
        if (!this.isSinglePoint) {
            if (numFields === 1) {
                return "wide";
            } else if (numFields <= 3) {
                return "medium";
            }
        }
        return "small";
    }

    @computed("trendFields.length")
    get evenColumnClass() {
        var trendFieldsLength = this.get('trendFields.length');
        if (trendFieldsLength === 1) {
            return;
        } else if (trendFieldsLength <= 3) {
            return "even-columns-3";
        }
        return "even-columns-6";
    }

    @computed("trendFields", "trendFields.[]", "trendFields.@each.summaryValue", "fieldDisplaySize")
    get fieldInfoComponents() {
        var trendFields = this.trendFields || [],
            size = this.fieldDisplaySize,
            maxUnfilteredPrimaryValue = 100;
            //maxUnfilteredPrimaryValue = Math.max(...trendFields.map(r => (Ember.get(r, "unfilteredSummaryValue") || 0)));

        var fieldInfos = trendFields.map(field => {
            return {
                size: size,
                componentPath: "charting/trend-chart/trends-information/trends-information-detailed/trends-field-information-" + size,
                trendField: field,
                maxUnfilteredPrimaryValue: maxUnfilteredPrimaryValue
            };
        });
        return fieldInfos;
    }
}
