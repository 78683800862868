import Ember from "ember";
import Component from '@ember/component';
import {computed, action} from "@ember/object";

export default class SubquerySetsSingleDrilldown extends Component {
    originalQueries = null;
    subquerySetsQueries = null;

    tag = null;

    showQuery = false;

    @computed("originalQueries", "tag")
    get drilldownQueries() {
        let originalQueries = this.originalQueries,
            tagQuery = this.tag?.query,
            tagLabel = this.tag?.tagName;

        if (!tagQuery || !originalQueries) {
            return;
        }

        return originalQueries.map(query => {
            let drilldownQuery = query.toDrilldownQuery({
                queryString: tagQuery,
                queryLabel: tagLabel
            });
            return drilldownQuery;
        });
    }

    @computed("tag.dataSeries.stats.universe.percentChange")
    get change() {
        let change = this.tag?.dataSeries?.stats.universe.percentChange || 0;
        return change.toFixed(0);
    }

    @computed("subquerySetsQueries", "tag")
    get postsQueries() {
        let queries = this.subquerySetsQueries,
            tagLabel = this.tag?.tagName;

        return queries.map(query => {
            let newQuery = query.copy();
            newQuery.filters.addAdditionalAPIParameters({"sub_tags": tagLabel});
            return newQuery;
        });
    }

    @computed("postsQueries", "postsQueries.@each.updated")
    get samplePosts() {
        var queries = this.postsQueries || [];
        return queries.reduce((allPosts, query) => {
            var queryPosts = query?.posts.data;

            if (!queryPosts || !query || !Ember.get(queryPosts, "length")) {
                return allPosts;
            }
            queryPosts.forEach(post => {
                Ember.set(post, "query", query);
            });
            return allPosts.concat(queryPosts);
        }, []);
    }
}
