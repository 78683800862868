import Component from '@ember/component';
import { computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import QueryDataGrouping from "infegy-frontend/models/queries/query_data_grouping";

export default class NarrativesSingleDrilldown extends Component {
    //args
    dates=[];
    grouping='day';
    cluster = null;

    onRemoveCluster() { /* action */ };
    onRenameCluster() { /* action */ };
    
    //properties
    sparklineHoveredIndex=null;
    sparklineMetric="documents";
    sparklineMetricOptions=[{
            label: "universe",
            color: "atlas-ui"
        }, {
            label: "documents",
            color: "atlas-ui"
        }, {
            label: "positiveDocuments",
            color: "chart-positive"
        }, {
            label: "negativeDocuments",
            color: "chart-negative"
        }];

    @computed("cluster", "cluster.source._sourceQuery")
    get drilldownQuery(){
        if (!this.query || !this.cluster?.source?.name || !this.cluster?.source?.query) {
            return [];
        }

        let drilldownQuery = this.query.toDrilldownQuery({
            queryString: this.cluster.source.query,
            queryLabel: this.cluster.source.name
        });
        return [drilldownQuery];
    }

    @computed("drilldownQuery.@each.updated")
    get drilldownDisabled() {
        const found = this.drilldownQuery.find((query) => {
            return !query.posts.isLoaded || (query.posts.isLoaded && query.posts.isEmpty);
        });
        return !!found;
    }

    @computed("drilldownQuery.@each.updated")
    get showWarning() {
        const found = this.drilldownQuery.find((query) => {
            return query.posts.hasError;
        });
        return !!found;
    }

    @computed("drilldownQuery.activeGrouping")
    get trendsGroupingKey(){
        if (!this.drilldownQuery || !this.QueryDataGrouping) {
            return null;
        }
        return QueryDataGrouping.groupingKeys[
            QueryDataGrouping.apiGroupings[this.drilldownQuery.activeGrouping]
        ];
    }

    @computed("cluster", "sparklineMetric")
    get sparklineData(){
        return this.cluster.dataSeries.data.map((group) => {
            return group[this.sparklineMetric];
        });
    }

    @computed("dates", "sparklineHoveredIndex")
    get hoveredSparklineDate(){
        if (isEmpty(this.sparklineHoveredIndex)) {
            return null;
        }
        return this.dates[this.sparklineHoveredIndex];
    }

    @computed("sparklineHoveredIndex", "sparklineMetric")
    get hoveredValue(){
        if(isEmpty(this.sparklineHoveredIndex) || isEmpty( this.sparklineMetric )){
            return null;
        }
        return this.cluster.dataSeries.data[this.sparklineHoveredIndex][this.sparklineMetric];
    }

    @computed("sparklineHoveredIndex", "sparklineMetric")
    get hoveredPositivity(){
        return this.cluster.dataSeries.data[this.sparklineHoveredIndex].positiveDocumentsDistribution;
    }
}
