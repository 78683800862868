import Ember from "ember";
import {computed} from "@ember/object";
import JsonStore from 'infegy-frontend/json-store/model';
import Prop from 'infegy-frontend/json-store/properties/model';
import FieldProp from 'infegy-frontend/json-store/properties/fields/model';
import classic from "ember-classic-decorator";

@classic
export default class AxisChartFields extends JsonStore {
    @Prop.Boolean() useDefaultFieldRangesX;
    @Prop.Boolean() useDefaultFieldRangesY;

    @FieldProp.Field({defaultField: {
            "fieldClass": "trend-field",
            "field_component": "field-selector/parts/fields/sentiment",
            "api_name": "sentiment",
            "segment": "Sentiment",
            "subsegment": "DocumentsDistribution",
            "title": "positive",
            "field_name": "positiveDocumentsDistribution",
            "default_color": "rgb(127, 196, 10)",
            "inactive_by_default": false,
            "value": "sentiment.positiveDocumentsDistribution",
            "field_type": "scalar",
            "scalar_field": "average"
        }}) x;

    @FieldProp.Field({defaultField: {
            "fieldClass": "trend-field",
            "api_name": "sentiment",
            "segment": "Passion",
            "subsegment": "Passion",
            "field_component": "field-selector/parts/fields/passion",
            "field_name": "passion",
            "fixed_portion_whole": 100,
            "default_color": "rgb(136, 110, 204)",
            "title": "Passion",
            "value": "sentiment.passion",
            "field_type": "scalar",
            "query_id": 0,
            "scalar_field": "average"
        }}) y;

    @FieldProp.Field({defaultField: {
            "fieldClass": "trend-field",
            "api_name": "volume",
            "segment": "Posts",
            "subsegment": "Universe",
            "field_component": "field-selector/parts/fields/posts",
            "field_name": "postsNormalizedUniverse",
            "title": "Universe",
            "value": "volume.postsNormalizedUniverse",
            "field_type": "trend",
            "query_id": 0,
            "scalar_field": "sum"
        }}) radius;
};
