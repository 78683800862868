import Ember from "ember";
import { computed } from '@ember/object';
import classic from "ember-classic-decorator";
import * as Sentry from "@sentry/browser";

import JsonStore from "infegy-frontend/json-store/model";
import Prop from "infegy-frontend/json-store/properties/model";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";

@classic
class DatasetColumn extends JsonStore {
    @Prop.String() id;
    @Prop.String() name;
    @Prop.String() type;

    @computed("id", "name", "type")
    get filterClass() {

    }
}

@classic
class ContentDescriptor extends JsonStore {
    @Prop.Collection(DatasetColumn) columns;
}

@classic
export default class CustomDataset extends JsonStore {
    @Prop.String() id;
    @Prop.Int() createdByUserId;
    @Prop.String() type;
    @Prop.String() title;
    @Prop.String() createdDate;
    @Prop.String() updatedDate;
    @Prop.Int() totalPosts;
    @Prop.Int() totalBytes;
    @Prop.Boolean() dataFullySearchable;
    @Prop.Number() pctContentAvailable;
    @Prop.Attr() fields;
    @Prop.Attr() minPublished;
    @Prop.Attr() maxPublished;

    @computed.alias("id") value;
    @computed.alias("title") label;

    @Prop.Object(ContentDescriptor) contentDescriptor;

    isLoading = false;
    errorMessage = "";

    @computed.notEmpty("errorMessage") hasError;

    url = "api/v3/custom-dataset";

    @computed("minPublished")
    get earliestTimestamp() {
        let dateStr = this.minPublished;
        return dateStr ? new Date(dateStr) : null;
    }

    @computed("maxPublished")
    get latestTimestamp() {
        let dateStr = this.maxPublished;
        return dateStr ? new Date(dateStr) : null;
    }

    @computed("updatedDate", "createdTimestamp")
    get updatedTimestamp() {
        let dateStr = this.updatedDate;
        return (dateStr && new Date(dateStr).valueOf()) || this.createdTimestamp;
    }

    @computed("createdDate")
    get createdTimestamp() {
        let dateStr = this.createdDate;
        return dateStr && new Date(dateStr).valueOf();
    }

    _fieldGroups = {
        url: {
            includes: ["id","title","minPublished","maxPublished"]
        }
    }

    queryInfoPromise = null;

    loadQueryInfo() {
        let url = `{"dataset_id":"${this.id}"}`;
        url = `api/v3/query-info?q=${encodeURIComponent(url)}`;
        return AtlasAuth.Get({
            url: url,
            context: this
        }).then((data) => {
            this.set("rawQueryInfo", data?.output);
            this.set("queryInfoIsLoading", false);
        }).catch((error) => {
            if (error.status === 403) {
                controller.transitionToRoute("login");
            }
            console.error("err");
        });
    }

    rawQueryInfo = null;
    queryInfoIsLoading = false;

    @computed("rawQueryInfo", "queryInfoIsLoading")
    get queryInfo() {
        let rawQueryInfo = this.rawQueryInfo;
        if (!rawQueryInfo && !this.queryInfoIsLoading) {
            this.set("queryInfoIsLoading", true);
            let promise = this.loadQueryInfo();
            this.set("queryInfoPromise", promise);
        }
        return rawQueryInfo;
    }

    pollAttemptsRemaining = 3;
    pollForContent() {
        if (!this.dataFullySearchable && this.pollAttemptsRemaining > 0) {
            setTimeout(async () => {
                const currentProgress = this.pctContentAvailable;
                await this.reload();
                if (currentProgress === this.pctContentAvailable)
                    this.pollAttemptsRemaining -= 1;
                else
                    this.pollAttemptsRemaining = 3;
                this.pollForContent();
            }, 2000);
        }
    }

    async reload() {
        const response = await AtlasAuth.Get({
            url: "api/v3/custom-dataset",
            data: {
                id: this.id
            }
        });
        this.loadJSON(response.output);
    }

    async save(title, description="") {
        let data = {
            id: this.id,
            title,
            description
        };
        const response = await AtlasAuth.Put({ url: this.url, data });
        this.loadJSON(response.output[0]);
    }

    async saveAsFileUpload(document, title="", description, ignoreErrors=false, dryRun=false, onprogress=null) {
        const contentDescriptor = document.toJSON();
        const mapping = encodeURIComponent(JSON.stringify(contentDescriptor));
        const queryParams = [`mapping=${mapping}`, `ignore_errors=${ignoreErrors ? "1" : "0"}`, `dry_run=${dryRun ? "1" : "0"}`];
        if (this.id) {
            queryParams.unshift(`id=${this.id}`);
        }
        if (title) {
            queryParams.push(`title=${title}`);
        }
        if (description) {
            queryParams.push(`description=${description}`);
        }

        let url = "api/v3/custom-dataset/content";
        this.set("isLoading", true);
        try {
            let queryParamsCopy = JSON.parse(JSON.stringify(queryParams));
            queryParamsCopy.push(`cache_key=${document.cacheKey}`);
            const queryString = queryParamsCopy.join("&");
            const cacheKeyUrl = `${url}?${queryString}`;
            const response = await AtlasAuth.Post({ url: cacheKeyUrl }, onprogress);
            this.loadJSON(response.output);
        }
        catch (error) {
            if (error.status === 404) {
                const queryString = queryParams.join("&");
                url = `${url}?${queryString}`;
                const response = await AtlasAuth.Post({
                    url,
                    data: document.file
                }, onprogress);
                this.loadJSON(response.output);
            }
            else {
                throw error;
            }
        }
        this.set("isLoading", false);
    }

    async saveAsQualtricsImport(title, qualtricsToken, qualtricsSurveyId, qualtricsQuestionId) {
        let response = null;
        try {
            this.setProperties({
                isLoading: true,
                errorMessage: ""
            });
            response = await AtlasAuth.Post({
                url: "api/v3/custom-dataset",
                data: {
                    title,
                    type: "qualtrics_import",
                    qualtrics_token: qualtricsToken,
                    survey_id: qualtricsSurveyId,
                    question_id: qualtricsQuestionId
                }
            });
        }
        catch(error) {
            this.setProperties({
                isLoading: false,
                errorMessage: error.atlasErrorText
            });
            console.error(error.atlasErrorText);
            Sentry.captureMessage("Could not save Custom Dataset Qualtrics Import.");
            return;
        }

        this.loadJSON(response.output);
        this.set("isLoading", false);
    }

    async delete() {
        await AtlasAuth.Delete(`api/v3/custom-dataset?id=${this.id}`);
    }
}
