import Ember from "ember";
import QueriesComputed from "infegy-frontend/utils/queries-computed";
import Actions from "infegy-frontend/utils/common-actions";
import QueryAPILinguisticsPosts from "infegy-frontend/models/query-api/linguistics_posts";
import DataSeries from "infegy-frontend/models/data_series/data_series";

export default Ember.Component.extend({
    classNames: ["table-overflow-scroll"],
    viewType: "arcs",

    queries: null,
    selectedTheme: null,
    selectedSubTheme: null,
    showCustomThemes: false,

    isEmpty: QueriesComputed.apiEmptyForAllQueries("themes"),
    allLoaded: QueriesComputed.apiLoadedForAllQueries("themes"),
    isLoading: QueriesComputed.apiLoadingForAnyQuery("themes"),

    combineThemes: true,
    multiQueryViewType: Ember.computed("combineThemes", function () {
        return this.combineThemes ? "combine" : "compare";
    }),
    hasMoreThanOneQuery: Ember.computed.gt("queries.length", 1),

    currentMultiQueryViewType: Ember.computed("multiQueryViewType", "hasMoreThanOneQuery", function () {
        var multiQueryViewType = this.multiQueryViewType || "combined";
        return this.hasMoreThanOneQuery ? multiQueryViewType : "combined";
    }),

    firstQuery: Ember.computed("queries", "queries.[]", function () {
        return this.get("queries.length") ? this.queries[0] : null;
    }),

    isCompareView: Ember.computed("currentMultiQueryViewType", function() {
        return this.currentMultiQueryViewType === "compare";
    }),

    combinedQueryThemes: Ember.computed("allAvailableThemes", "hasMoreThanOneQuery",
            "queries.[]", "queries.@each.updated", function () {
        if (this.get("queries.length") < 2) {
            return this.get("firstQuery.themes.dataSeries");
        }

        var queries = this.queries || [],
            allData = queries.mapBy("themes.dataSeries.data") || [],
            combinedData = [].concat.apply([], allData),
            description = queries[0].get("themes.dataSeries.description");

        if (description && combinedData) {
            var dataSeries = DataSeries.load(combinedData, description);
            return dataSeries;
        }
    }),

    allAvailableThemes: Ember.computed("queries.[]", "queries.@each.updated", function () {
        var queries = this.queries || [],
            queryThemes = queries.reduce((memo, query) => {
                var valueMap = query.themes?.rawNameMap ?? {},
                    themes = (Ember.get(query, "themes.availableThemes") || [])
                                .map((themeName) => ({
                                    name: themeName,
                                    rawName: valueMap[themeName]
                                }));
                return memo.concat(themes);
            }, []),
            allAvailableThemes = queryThemes.uniqBy("name");

        return allAvailableThemes;
    }),

    allRootThemes: Ember.computed("queries.[]", "queries.@each.updated", function () {
        var queries = this.queries || [],
            queryThemes = queries.reduce((rootThemeMap, query) => {
                var valueMap = query.themes?.rawNameMap ?? {},
                    rootThemes = (Ember.get(query, "themes.rootThemes") || []);
                rootThemes.forEach(rootTheme => {
                    if (!rootThemeMap[rootTheme.theme]) {
                        rootThemeMap[rootTheme.theme] = {
                                name: rootTheme.theme,
                                rawName: valueMap[rootTheme.theme],
                                subthemes: [],
                                customTheme: rootTheme.customTheme
                            };
                    }
                    rootThemeMap[rootTheme.theme].subthemes = rootThemeMap[rootTheme.theme].subthemes.concat(rootTheme.subthemes || []).uniq();
                });
                return rootThemeMap;
            }, {});
        var allRootThemes = [];
        for (let rootTheme in queryThemes) {
            if (queryThemes.hasOwnProperty(rootTheme)) {
                allRootThemes.push(queryThemes[rootTheme]);
            }
        }
        return allRootThemes;
    }),

    drilldownPostsByQuery: Ember.computed("selectedTheme", "queries", function () {
        var selectedTheme = this.get("selectedTheme.rawName"),
            queries = this.queries || [];
        if (selectedTheme) {
            return queries.map(query => {
                return {
                    posts: QueryAPILinguisticsPosts.loadThemePosts(query, selectedTheme),
                    query: query
                };
            });
        }
    }),

    themeData: Ember.computed("allAvailableThemes", "queries", "queries.[]",
        "queries.@each.updated", "combinedQueryThemes", function () {
        var themes = this.allAvailableThemes,
            queries = this.queries,
            combinedQueryThemes = this.combinedQueryThemes;
        if (!Ember.isArray(themes) || !Ember.isArray(queries)) {
            return [];
        }

        var fieldSuffixes = ["Documents", "DocumentsDistribution", "Appearances",
            "PositiveDocuments", "NegativeDocuments",
            "PositiveAppearances", "NegativeAppearances",
            "Universe", "AppearancesPerPost"];

        var out = themes.map(themeNameObj => {
            var eName = themeNameObj.name;
            var themeQueries = queries.map(q => {
                return fieldSuffixes.reduce((memo, suffix) => {
                    memo[suffix] = Ember.get(q, "themes.stats." + eName + suffix + ".sum");
                    memo["totalDocuments"] = Ember.get(q, "themes.stats.documents.sum");
                    return memo;
                }, {query: q});
            });
            var combined = themeQueries.reduce((memo, q) => {
                memo.totalDocuments += q.totalDocuments || 0;
                memo.Documents += q.Documents || 0;
                memo.Appearances += q.Appearances || 0;
                memo.PositiveDocuments += q.PositiveDocuments || 0;
                memo.NegativeDocuments += q.NegativeDocuments || 0;
                memo.PositiveAppearances += q.PositiveAppearances || 0;
                memo.NegativeAppearances += q.NegativeAppearances || 0;
                memo.Universe += q.Universe || 0;
                memo.AppearancesPerPost += q.AppearancesPerPost || 0;
                return memo;
            }, {
                totalDocuments: 0, Documents: 0, Appearances: 0,
                PositiveDocuments: 0, NegativeDocuments: 0,
                PositiveAppearances: 0, NegativeAppearances: 0,
                Universe: 0, AppearancesPerPost: 0
            });

            if (!combined.PositiveAppearances && !combined.NegativeAppearances) {
                combined.Sentiment = 0.5;
            } else {
                combined.Sentiment = combined.PositiveAppearances / (combined.PositiveAppearances + combined.NegativeAppearances);
            }

            combined.DocumentsDistribution = combined.Documents / combined.totalDocuments;

            return {
                theme: eName,
                rawName: themeNameObj.rawName,
                queriesData: themeQueries,
                combined: combined,
                customTheme: false
            };
        });

        return out;
    }),

    sortedThemeData: Ember.computed("themeData", function () {
        var themeData = this.themeData || [];
        return themeData.sortBy("combined.Documents").reverse();
    }),

    rootThemeData: Ember.computed("allRootThemes", "sortedThemeData", function () {
        let allRootThemes = this.allRootThemes,
            allThemes = this.sortedThemeData,
            rootThemeNames = allRootThemes.mapBy("name") || [];

        if (!allRootThemes) {
            return allThemes;
        }

        allThemes.forEach(theme => {
           allRootThemes.forEach(rootTheme => {
               if (theme.theme === rootTheme.name) {
                   theme.customTheme = rootTheme.customTheme;
               }
           });
        });

        return allThemes.filter(theme => rootThemeNames.includes(theme.theme));
    }),

    hasCustomThemes: Ember.computed("rootThemeData", function() {
        return this.rootThemeData.some(theme => theme.customTheme === true);
    }),

    subThemeData: Ember.computed("allRootThemes", "selectedTheme", "sortedThemeData", "showCustomThemes", function () {
        var allRootThemes = this.allRootThemes,
            selectedTheme = this.selectedTheme,
            selectedThemeName = selectedTheme && Ember.get(selectedTheme, "theme"),
            allThemes = this.sortedThemeData;

        if (!selectedTheme || !selectedThemeName || (this.showCustomThemes && !this.selectedSubTheme)) {
            return [];
        }
        var rootTheme = allRootThemes.findBy("name", selectedThemeName),
            subthemes = rootTheme && rootTheme.subthemes || [];
        return allThemes.filter(theme => subthemes.includes(theme.theme));
    }),

    combineThemesChanged: () => {},
    themesViewTypeChanged: () => {},
    themeSelected: () => {},
    subThemeSelected: () => {},
    selectedFieldChanged: () => {},

    actions: {
        combineThemesChanged: function (type) {
            this.combineThemesChanged(type === "combine");
        },
        toggleCustomThemes: function () {
            this.setProperties({
                selectedTheme: null,
                selectedSubTheme: null
            });
            this.toggleProperty("showCustomThemes");
        }
    }
});
