import Ember from "ember";
import QueryAPIUtils from "infegy-frontend/models/queries/query_api_utils";
import ArrayCompat from "infegy-frontend/models/array_compat";

export default Ember.Component.extend({
    apiName: null,
    query: null,

    apiOptions: Ember.computed("apiName", "query", function () {
        var apiDefinition = QueryAPIUtils.byName(this.apiName) || {},
            apiOptions = apiDefinition.apiOptions || [],
            query = this.query;

        if (!apiOptions || Ember.isEmpty(apiOptions)) {
            return;
        }

        var options = ArrayCompat.create();
        apiOptions.forEach(function(option) {
            var fieldOptions = option.options;
            if (option.findAvailableOptionsForQuery) {
                fieldOptions = option.findAvailableOptionsForQuery(query);
            }
            options.pushObject(Ember.Object.create(Object.assign({}, option, {
                selectedValue: option.defaultSelection,
                options: fieldOptions
            })));
        });
        return options;
    }),

    valueChanged: () => {},
    actions: {
        apiOptionValueChanged: function() {
            this.valueChanged(this.apiOptions);
        }
    }
});
