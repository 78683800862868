import { isEmpty } from "@ember/utils";
import classic from "ember-classic-decorator";

import Snippet from "infegy-frontend/models/users/snippet";
import OffsetPagination from "infegy-frontend/utils/offset_pagination";

@classic
export default class Snippets extends OffsetPagination {
    static SORT_FIELD_OPTIONS = [
        {
            label: "Created",
            value: "created_on"
        },
        {
            label: "Title",
            value: "title"
        },
        {
            label: "Updated",
            value: "updated_on"
        }
    ];

    pageSize = 9999;

    // base class params
    itemClass = Snippet;
    url = "api/v3/snippets";

    // additional query parameters
    search = "";
    sortField = "updated_on";
    sortDirection = "DESC";

    buildQueryParams() {
        let queryParams = {
            sort_field: this.sortField,
            sort_direction: this.sortDirection
        };
        if (!isEmpty(this.search)) {
            queryParams.search = this.search;
        }
        return queryParams;
    }

    updateOptions(options={}) {
        if (!isEmpty(options["search"]))
            this.searchText = options["search"];
        if (!isEmpty(options["sort_direction"]))
            this.sortDirection = options["sort_direction"];
        if (!isEmpty(options["sort_field"]))
            this.sortField = options["sort_field"];
    }

    async getPage(options={}) {
        let queryParams = this.buildQueryParams();
        queryParams = Object.assign(queryParams, options);
        super.getPage(queryParams);
        this.updateOptions(options);
    }

    async getFirstPage(options={}) {
        let queryParams = this.buildQueryParams();
        queryParams = Object.assign(queryParams, options);
        super.getFirstPage(queryParams);
        this.updateOptions(options);
    }

    async getPreviousPage(options={}) {
        let queryParams = this.buildQueryParams();
        queryParams = Object.assign(queryParams, options);
        super.getPreviousPage(queryParams);
        this.updateOptions(options);
    }

    async getNextPage(options={}) {
        let queryParams = this.buildQueryParams();
        queryParams = Object.assign(queryParams, options);
        super.getNextPage(queryParams);
        this.updateOptions(options);
    }

    async getLastPage(options={}) {
        let queryParams = this.buildQueryParams();
        queryParams = Object.assign(queryParams, options);
        super.getLastPage(queryParams);
        this.updateOptions(options);
    }
}