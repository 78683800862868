import Ember from 'ember';
import {percentageFormat} from "infegy-frontend/helpers/percentage-format";
import {numberFormat} from "infegy-frontend/helpers/number-format";
import {dollarFormat} from "infegy-frontend/helpers/dollar-format";
import {abbrNumber} from "infegy-frontend/helpers/abbr-number";

export function valueFormat(params, namedArgs) {
  var isPercent = namedArgs.isPercent || false,
        isDollar = namedArgs.isDollar || false,
        abbreviate = namedArgs.abbreviate || false,
        type = namedArgs.type || false,
        field = namedArgs.field;

    if (field) {
        if (field.isPercentageType) {
            isPercent = true;
        } else if (params[0] >= 1000) {
            abbreviate = true;
        }
    }

    if (isPercent || type === "percent" || type === "portion" ||
            type === "percentage" || type === "distribution") {
        return percentageFormat(params, namedArgs);
    } else if (isDollar || type === "dollar" || type === "$") {
        return dollarFormat(params, namedArgs);
    } else if (abbreviate) {
        return abbrNumber(params, namedArgs);
    } else {
        return numberFormat(params, namedArgs);
    }
}

export default Ember.Helper.helper(valueFormat);
