import Ember from "ember";
import QueryDataGrouping from "infegy-frontend/models/queries/query_data_grouping";
import ControllerBase from "infegy-frontend/atlas/dashboard/content/base/controller";
import BrowserStore from "infegy-frontend/utils/browser-store";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import SubquerySet from "infegy-frontend/models/users/subquery_set";

export default ControllerBase.extend({
    application: Ember.inject.controller(),

    exportApis: ['subquerySets'],

    viewType: BrowserStore.Attr("emotions-active-view-type", {defaultValue: "arcs"}),

    querySets: null,

    subquerySetsPerPage: Ember.computed("queries.length", function () {
        return (this.get("queries.length") > 1) ? 10 : 30;
    }),

    hasSubqueries: Ember.computed("queryPairs.length", function() {
        return this.queryPairs.length > 0;
    }),

    queryPairs: Ember.computed("queries", function () {
        let querySetIDs = [];
        let subquerySetsQueries = [];
        let queries = this.get("queries") || [];

        queries.forEach((query) => {
            let tempQuery = query.copy(),
                queryInfo = tempQuery.get("queryInfo"),
                querySets = tempQuery.get("queryInfo.subquerySets") || [],
                grouping = QueryDataGrouping.findGrouping(queryInfo.get("queryTimespanDays") || 0);

            if (querySets.length == 0) return;

            querySetIDs = querySetIDs.concat(querySets);

            queryInfo.set("selectedGrouping", QueryDataGrouping.groupingUnits[grouping] || "day");

            subquerySetsQueries.push({
                originalQuery: [query],
                subquerySetsQuery: [tempQuery],
            });
        });

        querySetIDs.uniq().forEach(qsID => this.loadQuerySetByID(qsID));

        return subquerySetsQueries;
    }),

    loadQuerySetByID(id) {
        var querySets = this.get("querySets");
        if (!querySets) {
            querySets = Ember.Object.create();
            this.set("querySets", querySets);
        }
        if (querySets.get(id)) {
            return;
        }

        AtlasAuth.Get({
            url: "api/v3/query-sets",
            data: { id }
        }).then(response => {
            let data = response && response.output || [],
                querySet = SubquerySet.create();
            querySet.loadJSON(data[0]);
            querySets.set(querySet.get("id"), querySet);
        });
    },

    actions: {
        viewTypeChanged: function(viewType) {
            this.set("viewType", viewType);
        },
    }
});
