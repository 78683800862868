import UsStatesJSON from "infegy-frontend/static_data/geography/us_states_json";

var UsStates = UsStatesJSON.map(function(row) {
    return {
        title: row.title || row.label,
        label: row.label || row.title,
        alternates: row.alternates,
        value: row.value
    };
});

export default UsStates;
