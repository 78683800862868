import Ember from "ember";
import TooltipComponent from "infegy-frontend/components/design/tooltips/tool-tip/component";

export default TooltipComponent.extend({
    wasLeft: false,

    leftThreshold: 0.4,
    rightThreshold: 0.6,

    // Offset position of mouse as percentage of chart display width
    mousePct: 0,

    isTooltipLeft: Ember.computed("mousePct", "leftThreshold", "rightThreshold", function() {
        var wasLeft = this.wasLeft;
        var isLeft = wasLeft;
        var mousePct = this.mousePct;
        if (Ember.isEmpty(mousePct)) {
            return wasLeft;
        }

        if (wasLeft && mousePct < this.leftThreshold) {
            this.set("wasLeft", false);
            isLeft = false;
        } else if (!wasLeft && mousePct > this.rightThreshold) {
            this.set("wasLeft", true);
            isLeft = true;
        }
        return isLeft;
    }),

    right: Ember.computed("isTooltipLeft", {
        get: function(key) {
            return this.isTooltipLeft ? null : 0;
        },
    }),

    left: Ember.computed("isTooltipLeft", {
        get: function(key) {
            return this.isTooltipLeft ? 0 : null;
        },
    }),
});
