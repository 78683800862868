import Ember from "ember";
import Component from '@ember/component';
import { computed } from '@ember/object';
import Languages from "infegy-frontend/static_data/query/query_filters/languages";
import FieldSelectorBase from "infegy-frontend/components/field-selector/parts/fields/trendable-field-base/component";
import layout from "infegy-frontend/components/field-selector/parts/fields/trendable-selector-layout/template";

export default class FieldSelectorLanguages extends FieldSelectorBase {
    layout = layout;
    apiName = "languages";

    maxAvailableLanguages = 10;

    @computed("fieldType", "selectedSegment", "selectedSubsegment", "maxAvailableLanguages", "allQueries", "allQueries.[]", "allQueries.@each.languages", "allQueries.@each.updated")
    get availableLanguages() {
        var allQueries = this.allQueries || [],
            maxAvailableLanguages = this.maxAvailableLanguages || 10,
            languages = [];
        allQueries.forEach(function(query) {
            languages = languages.concat(query.get("languages.availableLanguages") || []).uniq();
        });
        // Sort By Appearances - Ensure most important ones are first.
        languages = languages.map(language => {
            return {
                language: language,
                count: allQueries.reduce((memo, query) => {
                        var languageResults = query.get("languages.stats." + language + ".sum") || 0;
                        return memo - languageResults;
                    }, 0)
            };
        }).sortBy("count").slice(0, maxAvailableLanguages);

        return languages.mapBy("language");
    };

    makeLanguagesFields(baseFields, suffix, axisGroup) {
        var languageLabels = Languages.allLanguages;

        return baseFields.map(baseField => {
            var value = languageLabels.findBy('value', baseField),
                title = value && value.label;
            return this.makeFieldDefinition({
                title: title,
                fieldName: baseField + suffix,
                axisGroup: axisGroup
            });
        });
    };

    @computed("availableLanguages")
    get allAvailableFields() {
        var baseFields = this.availableLanguages;
        return {
            "DocumentsDistribution": this.makeLanguagesFields(baseFields, "DocumentsDistribution", "percentage"),
            "Documents": this.makeLanguagesFields(baseFields, "", "Languages.Count"),
        };
    };
};
