import Component from "@ember/component";
import { computed, action } from "@ember/object";
import { mapBy, notEmpty, readOnly } from "@ember/object/computed";
import classic from "ember-classic-decorator";

@classic
export default class extends Component {
    classNameBindings = [":queries-list-query", "isHoverable:queries-list-query-hoverable"];

    // input
    hideTitle = false;
    query = null;
    user = null;

    @notEmpty("query.filters.query") hasBooleanQuery;
    @notEmpty("query.filters.entityQueryParts") hasEntityQuery;
    @notEmpty("query.filters.sourceQueryString") hasSourceQuery;
    @readOnly("query.isUsingQueryBuilder") hasBuilderQuery;
    @notEmpty("query.filters.queryBuilderDetail.andItems") hasAndItems;
    @notEmpty("query.filters.queryBuilderDetail.orItems") hasOrItems;
    @notEmpty("query.filters.queryBuilderDetail.notItems") hasNotItems;

    @action disableClickThrough(event) {}
    
    click() {
        this.addSavedQuery(this.query);
    }

    @action async toggleSaveQuery() {
        this.query.toggleProperty('isSaved');
        await this.query.save();
        this.user.savedQueries.getPage();
    }
}
