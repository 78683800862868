import JsonStore from "infegy-frontend/json-store/model";
import Prop from "infegy-frontend/json-store/properties/model";

export default JsonStore.extend({
    id: Prop.Int(),
    isActive: Prop.Boolean(),

    message: Prop.String(),
    status: Prop.String(),
    date: Prop.String(),
    type: Prop.String(),
});
