import Ember from 'ember';
import DayOfWeek from "infegy-frontend/static_data/day_of_week";

export function formatDate(params, namedArgs) {
    if (params[0] && moment) {
        let precision = namedArgs.precision || "day",
            format = namedArgs.format || "MMM Do, YYYY",
            utc = moment.utc(new Date(params[0])),
            result = "";

        if (precision === "_dayOfWeek") {
            result = DayOfWeek.findBy('value', params[0].toString()).label;
        } else if (precision === "hour") {
            let hour = utc.hour();
            if (hour < 12) {
                hour = hour === 0 ? 12 : hour;
                result = `${hour} AM `;
            } else {
                hour = hour - 12;
                hour = hour === 0 ? 12 : hour;

                result = `${hour} PM `;
            }
            result += utc.format("YYYY/M/D")
        } else if (precision === "week" || precision === "weekly" || precision === "_weeksSinceEpoch") {
            result = utc.format("YYYY/M/D");
            result = "Week of " + result;
        } else if (precision === "month" || precision === "monthly" || precision === "_monthsSinceZero") {
            result = utc.format("MMMM, YYYY");
        } else if (precision === "quarter" || precision === "quarterly") {
            result = `Q${utc.quarter()} ${utc.format("YYYY")}`;
        } else if (precision === "year" || precision === "yearly" || precision === "_year") {
            result = utc.format("YYYY");
        } else {
            result = utc.format(format);
        }
        return result;
    } else {
        // moment.js not available. return data as is.
        return params[0];
    }
    return params;
}

export default Ember.Helper.helper(formatDate);
