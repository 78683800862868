import Ember from "ember";
import ControllerBase from "infegy-frontend/atlas/dashboard/content/base/controller";
import QueryAPIs from "infegy-frontend/models/queries/query_apis";

export default ControllerBase.extend({
    exportApis: QueryAPIs,
    apiOptions: Ember.computed("exportApis", "query", {
        get: function(key) {
            const hasSubqueries = !Ember.isEmpty(this.query.queryInfo.subquerySets);
            return QueryAPIs.filterBy('notExportable', undefined).reduce(function(results, api) {
                if (api.name === "tags" && !hasSubqueries) return results;
                
                results.push(Ember.Object.create({
                    title: api.name,
                    value: api.name
                }));

                return results;
            }, []);
        },
    }),

    selectedQuery:0,
    queryOptions: Ember.computed("queries",function(){
        var queries = this.queries;
        return queries.map((query,idx)=> ({
            label: query.get("title"),
            value: idx
        }));
    }),

    query: Ember.computed("queries", "queries.@each.updated","selectedQuery",function(){
        var queries = this.queries,
            selectedQuery = this.selectedQuery;
        return queries[selectedQuery];
    }),

    formatOptions: [Ember.Object.create({
        title: "JSON",
        value: "json"
    }), Ember.Object.create({
        title: "XML",
        value: "xml"
    }), Ember.Object.create({
        title: "Excel 97-2004",
        value: "xls"
    }), Ember.Object.create({
        title: "Excel",
        value: "xlsx"
    })],

    activeFormat: "json",
    activeAPI: "volume",
    hoveredAPI: null,
    
    fullyEncodedURLs: true,

    isXML: Ember.computed.equal("activeFormat", "xml"),
    isJSON: Ember.computed.equal("activeFormat", "json"),

    canCopyToClipboard: false,

    activeAPIInfo: Ember.computed("query", "activeAPI", "fullyEncodedURLs", 'user.apiKey', {
        get: function(key) {
            var apiName = this.activeAPI,
                queryAPI = this.get(`query.${apiName}`),
                query = this.query,
                keyString = this.get('user.apiKey') || "";

            // Test if we can enable the copy to clipboard buttons
            navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
                if (result.state === "granted" || result.state === "prompt") {
                    this.set("canCopyToClipboard", true);
                }
            });

            if (!query || !apiName || !queryAPI) {
                return;
            }

            return Ember.Object.create({
                requestString: query.toAPIQueryString(),
                hrefAPI: query.toAPIRequestURL(apiName, {format: "json"}, this.fullyEncodedURLs) + '&api_key=' + keyString,
                hrefJSON: query.toAPIRequestURL(apiName, {format: "json"}, this.fullyEncodedURLs) + '&api_key=' + keyString,
                hrefXML: query.toAPIRequestURL(apiName, {format:  "xml"}, this.fullyEncodedURLs) + '&api_key=' + keyString,
                hrefXLS: query.toAPIRequestURL(apiName, {format:  "xls"}, this.fullyEncodedURLs) + '&api_key=' + keyString,
                hrefXLSX: query.toAPIRequestURL(apiName, {format:  "xlsx"}, this.fullyEncodedURLs) + '&api_key=' + keyString,
                queryAPI: queryAPI
            });
        }
    }),
    actions: {
      activeAPIChanged(activeAPI){
        this.set("activeAPI",activeAPI);
      },
      hoveredAPIChanged(hoveredAPI){
        this.set("hoveredAPI",hoveredAPI);
      },
      activeFormatChanged(activeFormat){
        this.set("activeFormat",activeFormat);
      },
      selectedQueryChanged(selectedQuery){
          this.set("selectedQuery",selectedQuery);
      },
      copyToClipboard(outputString) {
        if (outputString && window.isSecureContext && navigator?.clipboard?.writeText) {
            navigator.clipboard.writeText(outputString);
        }
      }
    }
});
