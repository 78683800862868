import Controller, { inject as controller } from "@ember/controller";
import { action, computed } from "@ember/object";

import SubquerySet from "infegy-frontend/models/users/subquery_set";

export default class QuerySetsController extends Controller {
    @controller application;

    copyingQuerySet = null;
    isCreatingQuerySet = false;
    newQuerySetName = "";

    declineSharingId = null;
    deletingQuerySet = null;
    showCopyError = false;
    showDeclineSharingError = false;
    showDeleteError = false;

    @computed.alias("application.user") user;
    @computed.alias("user.subquerySets") querySets;

    @action
    async onCopyQuerySet(subquerySet, newName) {
        let newSubquerySet = null;
        try {
            newSubquerySet = await subquerySet.saveCopy(newName);
            newSubquerySet.set("isShared", false);
        }
        catch(error) {
            this.set("showCopyError", true);
            Ember.run.later(()=>{
                this.set("showCopyError", false);
            }, 5000);
            return;
        }

        this.querySets.pushObject(newSubquerySet);
        this.set("copyingQuerySet", null);
        this.transitionToRoute("atlas.tools.query-sets.edit", newSubquerySet.id);
    }

    @action
    async onCreateQuerySet(name) {
        let newQuerySet = SubquerySet.create();
        try {
            await newQuerySet.save(name);;
        }
        catch(error) {
            this.set("showSaveError", true);
            Ember.run.later(()=>{
                this.set("showSaveError", false);
            }, 5000);
            return;
        }

        this.querySets.pushObject(newQuerySet);
        this.setProperties({
            isCreatingQuerySet: false,
            newQuerySetName: ""
        });
        this.transitionToRoute("atlas.tools.query-sets.edit", newQuerySet.id);
    }

    @action
    onSharingDeclined(querySet) {
        this.querySets.removeObject(querySet);
    }

    @action
    async onDeleteQuerySet(querySet) {
        try {
            await querySet.delete();
        }
        catch(error) {
            this.set("showDeleteError", true);
            Ember.run.later(()=>{
                this.set("showDeleteError", false);
            }, 5000);
            return;
        }

        const querySetIndex = this.querySets.indexOf(querySet);
        this.querySets.removeAt(querySetIndex);
    }

    @action
    onEditQuerySet(querySet) {
        this.transitionToRoute("atlas.tools.query-sets.edit", querySet.id);
    }
}