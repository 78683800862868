export default [{
    "label": "Include",
    "value": -1,
    "description": "Include shares in post results.",
    "sort": 1
},{
    "label": "Exclude",
    "value": 0,
    "description": "Exclude shares from post results.",
    "sort": 2
},{
    "label": "Exclusively Shares",
    "value": 1,
    "description": "Exclusively return shared posts.",
    "sort": 3
}];