import JsonStore from "infegy-frontend/json-store/model";
import Prop from "infegy-frontend/json-store/properties/model";

import CustomDatasetUsage from "infegy-frontend/models/users/custom-dataset-usage";
export default class extends JsonStore {
    @Prop.Int() hourlyUsed;
    @Prop.Int() monthlyUsed;
    @Prop.Int() monthlyLimit;
    @Prop.String() monthlyReset;
    @Prop.Object(CustomDatasetUsage) datasets;
}