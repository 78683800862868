import Ember from "ember";
import Route from "@ember/routing/route";
import { action } from "@ember/object";

import Workspace from "infegy-frontend/models/users/workspace2";
import SharedResources from "infegy-frontend/models/users/shared_resources";
import GlobalFilters from "infegy-frontend/models/queries/query_global_filters";

export default class WorkspaceEditorRoute extends Route {
    title = "Editing Workspace";
    async model(params) {
        const workspaceId = parseInt(params.workspace_id);

        let workspace = Workspace.create();
        if (workspaceId !== 0) {
            await workspace.getById(workspaceId);
        }
        return workspace;
    }

    setupController(controller, workspace) {
        const sharedResources = SharedResources.create();

        if (!Ember.isEmpty(workspace.id)) {
            // editing or viewing an existing workspace
            sharedResources.getForResource("workspace", workspace.id);
            GlobalFilters.setDefaultDateRange(workspace.queries, true);
        } else {
            workspace.addBlankQuery();
            GlobalFilters.setDefaultDateRange(workspace.queries);
        }

        controller.setProperties({
            workspace: workspace,
            sharedResources
        });
    }

    @action
    didTransition() {
        /*
        When a workspace is copied, this page is reloaded by transitioning
        to the new workspace id.  After the transition is complete, the 
        "didTransitionToCopiedWorkspace" flag is set to true.  This flag 
        needs to be reset on subsequent visits to this page.
        */
        const controller = this.controller;
        controller.set("didTransitionToCopiedWorkspace", false);
    }
}