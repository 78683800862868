import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";
import Ember from "ember";

export default QueryAPIResults.extend({
    title : "householdValue",
    rangeMeta:{},

    preload: function(){
        var rawResponse = this.rawResults;
        this.set("rangeMeta",{
            "median": Ember.get(rawResponse, "median"),
            "mean": Ember.get(rawResponse, "mean")
        });
        this.set("rawResults", Ember.get(rawResponse,"ranges"));
    },
    description: DataSeriesDescription.load({
        "id": "string",
        "rangeStart": "number",
        "rangeEnd": "number",
        "probability": "percent",
        "vsExpected": "percent",
        "dbDistribution": "percent",
        "displayName": "string",
        "count": "number"
    })
});
