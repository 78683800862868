import Component from "@ember/component";
import { action, computed } from "@ember/object";
import classic from "ember-classic-decorator";

@classic
export default class extends Component {
    classNameBindings = [":atlas-background-bg", ":workspace-folder"];

    /**
     * @event removeFolder Fired when a workspace has been removed from its folder.
     */

    // Input
    queryFormQueries = [];
    user = null;
    workspaceFolder = null;

    // Internal
    isEditingTitle = false;
    isShowingFullDetails = false;
    newWorkspaceFolderName = "";
    workspaceActionBarButtons = ["change_folder", "remove_folder", "select_workspace", "select_queries"];

    @computed.alias("workspaceFolder.workspaces") workspaces;
    @computed.notEmpty("workspaces") hasWorkspaces;

    @computed("workspaces")
    get orderedWorkspaces() {
        const result = this.workspaces.content.sort((a, b) => {
            if (a.title > b.title) return 1;
            if (a.title < b.title) return -1;
            return 0;
        });
        return result;
    }

    @action async onDeleteWorkspaceFolder() {
        await this.workspaceFolder.delete();
        this.user.workspaceFolders.load();
    }

    @action onCancelEditTitle() {
        this.set("newWorkspaceFolderName", "");
        this.toggleProperty("isEditingTitle");
    }

    @action onEditTitle() {
        this.set("newWorkspaceFolderName", this.workspaceFolder.title);
        this.toggleProperty("isEditingTitle");
    }

    @action async onSaveTitle() {
        await this.workspaceFolder.save(this.newWorkspaceFolderName);
        this.set("isEditingTitle", false);
    }

    @action onToggleShowDetails() {
        this.toggleProperty("isShowingFullDetails");
    }

    @action onRemoveFolder() {
        this.removeFolder();
    }
}