import Ember from "ember";
import {computed, action} from "@ember/object";
import Component from '@ember/component';

export default class extends Component {
    user = null;
    query = null;

    @computed.alias("user.customDatasets") customDatasets;

    @computed.notEmpty("query.queryInfo.customDatasetId") datasetSelected;

    @computed("user.permissions.socialDataAccess")
    get showDatasetDropdown() {
        return this.get("user.permissions.socialDataAccess");
    }

    @computed("query.queryInfo.customDatasetId")
    get selectedDataset() {
        return this.query?.queryInfo?.customDatasetId || "_infegy-social";
    }

    @computed("user.customDatasets", "user.customDatasets.[]")
    get datasetsOptions() {
        var customDatasets = this.user?.customDatasets || [],
            usableDatasets = [];
        customDatasets.forEach((dataset)=>{
            if(Ember.get(dataset,"dataFullySearchable") && Ember.get(dataset,"totalPosts") > 0) {
                usableDatasets.push(dataset.toJSON({fieldGroups: "url"}));
            }
        });
        return usableDatasets.sortBy("title");
    }

    @computed("datasetOptions", "user.permissions.socialDataAccess")
    get dropDownOptions() {
        var datasetsOptions = this.datasetsOptions,
            hasSocialAccess = this.get("user.permissions.socialDataAccess");
        if(hasSocialAccess){
            return [{
                "title": "Infegy Social Data",
                "id": "_infegy-social"
            }].concat(datasetsOptions);
        }
        return customDatasets;
    }

    datasetChanged() { /*empty for action */ }

    @action
    datasetChanging(datasetId){
        if (!datasetId || datasetId === "_infegy-social") {
            datasetId = null;
        }
        else {
            let customDataset = this.customDatasets.find((ds) => ds.id === datasetId);
            this.query.queryInfo.userTitle = customDataset.title;
        }
        this.set("query.queryInfo.customDatasetId", datasetId);
        this.query.queryInfo.clearInvalidFilters();
        this.datasetChanged(datasetId, this.query);
    }
}
