import {action, computed} from "@ember/object";
import ControllerBase from "infegy-frontend/atlas/dashboard/content/base/controller";
import BrowserStore from "infegy-frontend/utils/browser-store";
import DataSeries from "infegy-frontend/models/data_series/data_series";

export default class extends ControllerBase {
    apis = ["gender", "languages", "demographicsMeta", "ages", "sentiment", "topics", "volume"];
    exportApis = ["gender", "languages", "demographicsMeta", "ages"];

    viewField = "universe";
    drilldownLanguage = null;
    drilldownLanguageQuery = null;

    @BrowserStore.Attr("ages-active-view-type", {defaultValue: "chart"}) agesViewType;
    @computed.equal("agesViewType", "chart") showChart;
    @computed.equal("agesViewType", "table") showTable;
    @computed.gt("queries.length", 1) showMultiView;

    @computed("viewField", "showMultiView")
    get fieldOptions() {
        let showMultiView = this.showMultiView,
            options = [
                {label: "Universe", value: "universe"},
                {label: "Posts", value: "count"}
            ];

        if (showMultiView) {
            options.push(
                { label: "Change", value: "documentsGrowthPct" },
                { label: "Sentiment", value: "positivity" },
                { label: "Distribution", value: "probability" },
                { label: "Timeline", value: "timeline" }
            );
        }

        return options;
    }

    @computed("content.availableQueryFields.gender")
    get queryHasGender() {
        return this.content.availableQueryFields.gender;
    }
    @computed("content.availableQueryFields.language")
    get queryHasLanguage() {
        return this.content.availableQueryFields.language;
    }

    @computed("drilldownLanguage")
    get drilldownLanguageFilterQuery() {
        var language = this.get('drilldownLanguage');
        if (!language) {
            return null;
        }

        var queryCopy = this.drilldownLanguageQuery && this.drilldownLanguageQuery.copy(),
            subQueryInfo = queryCopy?.queryInfo;

        subQueryInfo.addFilter({
            id: "language",
            values: [language]
        });

        return queryCopy;
    }

    @computed("queries", "queries.@each.updated")
    get agesData() {
        let queries = this.queries || [],
            ages = queries.mapBy('ages.dataSeries');
        return !ages[0] ? null : DataSeries.stackBy(ages, "id");
    }

    @computed("agesData")
    get hasAgesData() {
        let found = (this.agesData?.data || []).find((item) => item.count !== 0);
        return !!found;
    }

    @action
    displayLanguageDrilldown(query, language) {
        if (language === this.drilldownLanguage) {
            this.set("drilldownLanguageQuery", null);
            this.set("drilldownLanguage", null);
        } else {
            this.set("drilldownLanguageQuery", query);
            this.set("drilldownLanguage", language);
        }
    }

    @action
    agesViewTypeChanged(agesViewType) {
        this.set("agesViewType", agesViewType);
    }

    @action
    viewFieldChanged(viewField) {
        this.set("viewField", viewField);
    }
}
