import Ember from "ember";
import {computed} from "@ember/object";
import Prop from 'infegy-frontend/json-store/properties/model';
import BaseFieldDefinition from "infegy-frontend/models/field-definitions/base-field";

export default class RowValueScalarField extends BaseFieldDefinition {
    // Determines which field class to load
    fieldClass = "row-value-scalar-field";

    // Property used to find the selected row - this is usually consistent per API
    @Prop.String() rowLookupProperty;

    // Value to lookup given row, using the rowLookupProperty. This is "Attr"
    // since different apis might use different lookup values
    @Prop.Attr() rowLookupValue;

    // User readable title for the rowLookupValue
    @Prop.Attr() rowLookupTitle;

    // Once a row has been looked up, this is the actual property to use
    @Prop.String() rowProperty;

    // Display Name for the rowProperty
    @Prop.String() rowPropertyTitle;

    // For portion fields, the "whole" value the portion is part of (when that
    // portion has a fixed "whole" amount)
    // i.e. the sentiment fixedPortionWhole is usually 1.0, for passion it's 100
    // this does not need to be set for fields where this can be computed.
    @Prop.Number({required: false}) fixedPortionWhole;

    @Prop.Boolean() isPercentageType;

    @computed("rowLookupProperty", "rowLookupValue", "dataSeries")
    get activeRow() {
        if (!this.rowLookupProperty || !this.rowLookupValue || !this.dataSeries || !this.dataSeries.data) {
            return [];
        }
        let activeRow = this.dataSeries.data.findBy(this.rowLookupProperty, this.rowLookupValue);
        return activeRow;
    }

    // For scalar only field usage this is pretty much all that is needed....
    @computed("activeRow", "rowProperty")
    get scalarValue() {
        return this.activeRow && this.activeRow[this.rowProperty];
    };
    @computed.alias("scalarValue") summaryValue;

    @computed("fixedPortionWhole")
    get portionWhole() {
        if (this.fixedPortionWhole) {
            return this.fixedPortionWhole;
        }
        return 1.0;
    };

    @computed("isPercentageType", "fixedPortionWhole")
    get isPortionable() {
        return !!this.fixedPortionWhole || this.isPercentageType;
    }
}
