import ControllerBase from "infegy-frontend/atlas/dashboard/content/base/controller";
import { inject as controller } from '@ember/controller';
import { action, computed } from '@ember/object';
import Query from "infegy-frontend/models/users/query";
import BrowserStore from "infegy-frontend/utils/browser-store";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import QueryAPILoader from "infegy-frontend/models/queries/query_api_loader";

export default class NarrativesController extends ControllerBase{
    apis = ["narratives"];
    @controller("atlas.dashboard.content") content;
    @controller("atlas.dashboard") editor;

    grouping = "auto";
    updateCount = 0;

    @BrowserStore.Attr("narratives-active-view-type",{defaultValue: "bars"}) selectedViewOption;
    @BrowserStore.Attr("narratives-node-count", { defaultValue: null }) nodeCount;
    @BrowserStore.Attr("narratives-selected-keys", { defaultValue: null }) selectedClusterKeys;

    @BrowserStore.Attr("narratives-nodeCount") nodeCount;
    @BrowserStore.Attr("narratives-displayLinks", {defaultValue: true}) displayLinks;
    @BrowserStore.Attr("narratives-colorBySentiment", {defaultValue: false}) colorBySentiment;
    @BrowserStore.Attr("narratives-darkMode", {defaultValue: false}) darkMode;
    @BrowserStore.Attr("narratives-clusterLabelType", {defaultValue: 1}) clusterLabelType;

    @BrowserStore.Attr("narratives-active-chart-metric",{defaultValue: "universe"}) selectedChartMetric;
    @BrowserStore.Attr("narratives-chart-type",{defaultValue: "line"}) chartType;

    viewOptions=[{
        icon: "graphql",
        value: "force-graph"
    },{
        icon: "chart-bar-stacked",
        value: "bars"
    }];
    
    @computed("queries.length", "selectedViewOption")
    get activeViewOption(){
        if (this.queries.length > 1) {
            return "table";
        }
        return this.selectedViewOption;
    }
    set activeViewOption(val){
        this.set("selectedViewOption", val);
    }

    @computed("activeViewOption") 
    get forceGraphSelected() {
        if (this.queries.length > 1) {
            return false;
        }
        return this.activeViewOption === "force-graph";
    }

    @computed("queries", "grouping", "updateCount")
    get narrativeQueries() {
        let updateCount = this.updateCount,
            newQueries = this.queries.map(q => q.copy());
        if (newQueries.firstObject?.queryInfo?.selectedGrouping !== this.grouping) {
            newQueries = this.queries.map(query => {
                var newQuery = query.copy()
                newQuery.set("queryInfo.selectedGrouping", this.grouping);
                return newQuery;
            });
        }
        return newQueries;
    }

    @computed("narrativeQueries", "narrativeQueries.@each.updated")
    get emptyNarratives() {
        if (this.narrativeQueries.length === 1) {
            return this.narrativeQueries.firstObject.narratives.isEmpty;
        } else {
            // Display "no data" message if all multi-queries narratives are empty.
            let narrativeQueries = this.narrativeQueries.filter(query => {
               return !!query.narratives.isEmpty;
            });
            return narrativeQueries.length === this.narrativeQueries.length;
        }
    }

    @action
    setGrouping(grouping){
        this.set("grouping", grouping);
    }

    @action
    onNodeCountChanged(count) {
        this.set("nodeCount", count);
    }

    @action
    onDisplayLinksChanged(state) { 
        this.set("displayLinks", state);
    };
    
    @action
    onColorBySentimentChanged(state) { 
        this.set("colorBySentiment", state);
    };
    
    @action
    onDarkModeChanged(state) { 
        this.set("darkMode", state);
    };
    
    @action
    onClusterLabelTypeChanged(state) { 
        this.set("clusterLabelType", state);
    };

    @action
    onSelectedChartMetricChanged(state) {
        this.set("selectedChartMetric", state);
    }

    @action
    onChartTypeChanged(state) {
        this.set("chartType", state);
    }

    @action
    selectedClusterKeysChanged(keys) {
        this.set("selectedClusterKeys", keys);
    }

    removedClusters;

    @action
    onRemoveCluster(cluster) {
        let removedClusters = this.removedClusters;
        if (!removedClusters) {
            removedClusters = [];
        }
        removedClusters = removedClusters.concat(cluster.key);
        this.set("removedClusters", removedClusters);
        QueryAPILoader.clearCache();
        this.incrementProperty("updateCount");
    }
    
    @action
    onRenameCluster(cluster, newName) {
        var request = AtlasAuth.Post({
            url: "api/v3/post-clusters.json",
            headers: {
                "Content-Type": "application/json"
            },    
            data: {
                name: newName,
                key: cluster.key
            }
        });

        request.catch(function(data) {
            console.error("Unable rename cluster.", data);
        });

        request.then((data) => {
            // Clear cache and reset queries so we get a fresh narratives request with renamed / merged clusters
            QueryAPILoader.clearCache();
            this.incrementProperty("updateCount");
            // this.set("editor.workspace.queries", this.queries.map(q => q.copy()));
        });
    }

    @action
    onMergeActiveClusters(clusterKeys, newName) {
        let numToRemove = clusterKeys.length;
        clusterKeys.forEach(clusterKey => {
            var request = AtlasAuth.Post({
                url: "api/v3/post-clusters.json",
                headers: {
                    "Content-Type": "application/json"
                },    
                data: {
                    name: newName,
                    key: clusterKey
                }
            });

            request.catch(function(data) {
                console.error("Unable rename cluster.", data);
            });

            request.then((data) => {
                numToRemove--;
                if (!numToRemove) {
                    // Clear cache and reset queries so we get a fresh narratives request with renamed / merged clusters
                    QueryAPILoader.clearCache();
                    this.incrementProperty("updateCount");
                }
            });
        });
    }
}
