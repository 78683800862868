import Component from '@ember/component';
import {computed, action} from "@ember/object";
import TrendChartInteractiveField from "infegy-frontend/models/trends/trend-chart-interactive-field";
import classic from "ember-classic-decorator";

@classic
export default class FullTrends extends Component {
    trendFields = null;

    // TrendChartMeta instance from "shared/models/trends/trend-chart-meta"
    trendChartMeta = null;

    groupingOptions = null;

    hideExtras = false;

    hasMixedTimespans = false;
    isMultiQuery = false;

    //internal
    optionsAreOpen = false;

    @computed.oneWay('trendChartMeta.normalizeStartTimes') normalizeStartTimes;
    @computed.oneWay('trendChartMeta.hideEvents') hideEvents;

    @computed("hideExtras", "hasMixedTimespans", "isMultiQuery")
    get showEventsOptions() {
      return !this.hideExtras && !this.hasMixedTimespans && !this.isMultiQuery;
    }

    trendChartMetaUpdated() { /* empty for action */ }

    normalizeStartTimesChanged() { /* empty for action */ }

    @action
    toggleNormalizeStartTimes(toggle) {
        this.normalizeStartTimesChanged(toggle);
    }

    @action
    toggleEvents(toggle) {
        this.trendChartMeta.set("hideEvents", toggle);
        this.trendChartMetaUpdated(this.trendChartMeta);
    }

    @action
    toggleOptions() {
        this.set("optionsAreOpen", !this.optionsAreOpen);
    }

    groupingChanged() { /* empty for action */ }
    @action
    groupingChanging(grouping) {
        if (grouping && this.trendChartMeta.grouping != grouping) {
            this.groupingChanged(grouping);
        }
    }
};
