import Ember from "ember";
import Component from '@ember/component';
import { action } from '@ember/object';
import { computed } from '@ember/object';

export default class TopicsSettings extends Component{
    classNameBindings = [":topics-cloud-settings"];

    displayTopicsCountChanged = ()=>{};
    replaceTopic = ()=>{};
    displayTopicsCount= 50;
    queryTopics = 150;
    showClusterSettings = false;

    @computed("showChange") 
    get displayTopicsCountOptions() {
        var options = [{
            label: "10",
            value: 10
        }, {
            label: "25",
            value: 25
        }];
        
        if (!this.showChange) {
            options.push({
                label: "50",
                value: 50
            });
        }

        return options;
    }
}