import Ember from "ember";
import ChartColors from "infegy-frontend/static_data/fixtures/chart_colors";
import Component from '@ember/component';
import {computed, action} from "@ember/object";

export default class extends Component {
    tagName = "tr";
    classNameBindings = [":data-table-row", ":topics-table-expandable"];

    entity = null;

    hasQueryField = false;
    expandable = true;

    @computed.lt("entity._sourceTopics.length", 2) hasNoSourceTopics;

    @computed("entity.documentsGrowthPct")
    get change() {
        var pct = (this.get("entity.documentsGrowthPct") || 0) * 100.0;
        return pct.toFixed(0);
    }

    @computed("entity.positiveAppearancesDistribution")
    get sentimentPercentage() {
        var pct = (this.get("entity.positiveAppearancesDistribution") || 0) * 100.0;
        return pct.toFixed(0);
    }

    @computed("entity.positiveAppearancesDistribution")
    get sentimentStyleSafe() {
        var sentiment = this.get("entity.positiveAppearancesDistribution") || 0.5,
            colorValues = ChartColors.sentimentZeroToOneGradientD3(),
            style = ["color:", colorValues(sentiment), ";"].join("");
        return new Ember.String.htmlSafe(style);
    }

    @computed("change")
    get changeStyleSafe() {
        var gradient = ChartColors.percentChangeGradientD3();
        return ["color:", gradient(this.change || 0), ";"].join("").htmlSafe();
    }

    @computed("sentimentPercentage")
    get sentimentWidthStyleSafe() {
        var percentage = this.sentimentPercentage || 50;
        return new Ember.String.htmlSafe(["width:", percentage, "%;"].join(""));
    }

    click() {
        this.send("selectingEntity");
    }

    // function placeholder for action
    removeEntity = null;
    entitySelected = null;

    @action
    selectingEntity() {
        if (this.entitySelected) {
            this.entitySelected(this.entity);
        }
    }

    @action
    removingEntity() {
        if (this.removeEntity) {
            this.removeEntity(this.get("entity.id"));
        }
    }
}
