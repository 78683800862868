import Ember from "ember";
import {computed, action} from "@ember/object";
import CSSTools from "infegy-frontend/utils/css-tools";
import TrendChartField from "infegy-frontend/components/charting/trend-chart/trend-chart-stacked-svg/trend-chart-field/component";
import d3 from "d3";

export default class extends TrendChartField {
    @computed("field.colorValues", "isHighlighted")
    get barStyle() {
        let fillColor = this.get("field.color.colorString");
        if(this.isHighlighted){
            fillColor = this.get("field.color.darkColorString");
        }
        return CSSTools.buildStringFromObjectSafe({
           stroke: "transparent",
           fill: fillColor
        });
    }

    @computed("field.colorValues")
    get highlightedBarStyle() {
        return CSSTools.buildStringFromObjectSafe({
           stroke: "transparent",
           fill: this.get("field.color.darkColorString")
        });
    }

    closestBar(hPct) {
        let closestDist = Infinity,
            closestBar = 0;
        this.bars?.forEach(bar => {
            let dist = Math.abs(bar.xPct - hPct);
            if (dist < closestDist) {
                closestBar = bar;
                closestDist = dist;
            }
        });
        return closestBar;
    }

    @computed("trendChartMeta.hoveredXPct", "trendChartMeta.isHovered", "trendFields.firstObject", "bars")
    get hoveredBar() {
        let field = this.trendField,
            hPct = this.trendChartMeta.hoveredXPct;
        if (this.trendChartMeta.isHovered && field?.xValueFromPct && this.trendChartMeta.isHovered) {
            return this.closestBar(hPct);
        }
    }

    @computed("trendChartMeta.selectedXPct", "trendChartMeta.isSelected", "trendFields.firstObject", "bars")
    get selectedBar() {
        let field = this.trendField,
            hPct = this.trendChartMeta.selectedXPct;
        if (this.trendChartMeta.isSelected && field?.xValueFromPct && this.trendChartMeta.isSelected) {
            return this.closestBar(hPct);
        }
    }

    @computed("hoveredBar", "selectedBar")
    get hoveredOrSelectedBar() {
        return this.hoveredBar || this.selectedBar;
    }

    @computed("xBarScale", "dataPoints", "height")
    get bars() {
        let xBarScale = this.xBarScale,
            bandwidth = xBarScale.bandwidth(),
            height = this.height;

        return (this.dataPoints || []).map(point => {
            return {
                x: xBarScale(point.x || 0),
                xPct: point.xPct,
                origX: point.x,
                y: point.y,
                width: bandwidth,
                height: Math.abs(point.y - point.y0)
            };
        });
    }
}
