import { isEmpty, isNone } from "@ember/utils";
import { computed } from "@ember/object";

import AtlasConfig from "infegy-frontend/config/infegy-app-config";
import JsonStore from "infegy-frontend/json-store/model";
import Prop from "infegy-frontend/json-store/properties/model";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import Query from "infegy-frontend/models/users/query";

export default class Workspace extends JsonStore {
    @Prop.Int() id;
    // @Prop.Int() userId;
    @Prop.String() title;
    @Prop.Int() workspaceFolderId;
    // @Prop.Int() runCount;
    @Prop.Boolean() isSaved;
    @Prop.String() lastUsedOn;
    @Prop.String() createdOn;
    @Prop.String() updatedOn;
    // @Prop.Boolean({default: false}) isDeleted;

    @Prop.Boolean() isShared;
    @Prop.Collection(Query) queries;

    // Special rules for exluding / including info in serialization - see JSONStore
    _fieldGroups = {
        api: {
           includes: ["id", "isSaved", "queries", "title", "workspaceFolderId"]
        }
    }

    sortField = "";
    url = "api/v3/workspaces";

    @computed("queries.@each.hasCustomDataset")
    get hasCustomDataset() {
        return !isNone(this.queries.find((query) => query.hasCustomDataset));
    }

    @computed("queries", "queries.length", "queries.@each.isRunnable")
    get isRunnable() {
        // All queries must be runnable for the workspace to be runnable
        const notRunnable = this.queries.find((query) => {
            return !query.isRunnable;
        });
        return isNone(notRunnable);
    }

    @computed("sortField")
    get statusValue() {
        return this[this.sortField];
    }

    @computed("queries", "queries.length", "queries.@each.isBlank")
    get isBlank() {
        if (this.queries.length === 0)
            return true;

        for (const query of this.queries.content) {
            if (!query.isBlank) {
                return false;
            }
        }
        return true;
    }

    @computed("queries", "queries.length", "queries.@each.isBlank")
    get hasBlank() {
        if (this.queries.length === 0)
            return true;

        for (const query of this.queries.content) {
            if (query.isBlank) {
                return true;
            }
        }
        return false;
    }

    @computed("queries", "queries.length", "queries.@each.hasErrors")
    get hasQueryErrors() {
        const hasErrors = this.queries.any((query) => query.hasErrors);
        return hasErrors;
    }

    @computed("queries.@each.earliestTimestamp")
    get earliestTimestamp() {
        let earliestTimestamp = Infinity;
        for (const query of this.queries.content) {
            if (query.earliestTimestamp < earliestTimestamp) {
                earliestTimestamp = query.earliestTimestamp;
            }
        }
        if (!isFinite(earliestTimestamp)) {
            return AtlasConfig.EarliestAtlasTimestamp;
        }
        return earliestTimestamp;
    }

    @computed("queries.@each.latestTimestamp")
    get latestTimestamp() {
        let latestTimestamp = -Infinity;
        for (const query of this.queries.content) {
            if (query.latestTimestamp > latestTimestamp) {
                latestTimestamp = query.latestTimestamp;
            }
        }
        if (!isFinite(latestTimestamp)) {
            return null;
        }
        return latestTimestamp;
    }

    loadJSON(jsonObj, options) {
        options = Object.assign({}, options, {
            clearDirty: true,
            dirtiesParent: true,
            parentObject: this
        });
        super.loadJSON(jsonObj, options);
        // Backwards compatibility: Previously only "builder" queries saved a
        // query color.  Now that "builder" and "advanced" queries are just
        // treated as a "query", this sets a default query if one doesn't exist.
        for (let i=0; i<this.queries.length; i++) {
            const query = this.queries.objectAt(i);
            if (query.color.isEmpty) {
                query.color.loadDefaultColorForIndex(i, this.queries);
            }
        }
    }

    toJSON(options) {
        let jsonData = super.toJSON(options) || {};
        if (!jsonData.hasOwnProperty("workspace_folder_id")) {
            jsonData.workspace_folder_id = 0;
        }
        if (!jsonData.hasOwnProperty("is_saved")) {
            jsonData.is_saved = false;
        }
        jsonData.title = this.title || this.queries.mapBy("title").join(", ");
        return jsonData;
    }

    copy() {
        const workspaceCopy = Workspace.create();
        workspaceCopy.loadJSON(this.toJSON());
        return workspaceCopy;
    }

    async run() {
        const data = this.toJSON({fieldGroups: "api"});
        const response = await AtlasAuth.Post({
            url: "api/v3/workspace_run",
            headers: {
                "Content-Type": "application/json"
            },
            data
        });
        this.queries.clear();
        this.loadJSON(response.output[0]);
    }

    async save() {
        const data = this.toJSON({fieldGroups: "api"});
        const response = await AtlasAuth.Post({
            url: "api/v3/workspaces",
            headers: {
                "Content-Type": "application/json"
            },
            data
        });
        this.queries.clear();
        this.loadJSON(response.output[0]);
    }

    async update() {
        const data = this.toJSON({fieldGroups: "api"});
        const response = await AtlasAuth.Put({
            url: "api/v3/workspaces",
            headers: {
                "Content-Type": "application/json"
            },
            data
        });
        this.queries.clear();
        this.loadJSON(response.output[0]);
    }

    delete() {
        return AtlasAuth.Delete({
            url: this.url,
            data: { id: this.id }
        });
    }

    getById(workspaceId) {
        return AtlasAuth.Get({
            url: `${this.url}?id=${workspaceId}`
        }).then((response) => {
            this.loadJSON(response.output[0]);
        });
    }

    addBlankQuery(queryType="builder") {
        const newQuery = Query.create();
        newQuery.queryType = queryType;
        newQuery.filters.color.loadDefaultColorForIndex(this.queries.length, this.queries);
        this.queries.pushObject(newQuery);
    }

    addQueries(queries) {
        const queriesToAdd = queries.reduce((accumulator, currentValue, currentIndex, array) => {
            const queryCopy = currentValue.copy();
            accumulator.push(queryCopy);
            return accumulator;
        }, []);
        for (let i = 0; i < queriesToAdd.length; i++) {
            const query = queriesToAdd[i];
            query.filters.color.loadDefaultColorForIndex(i, queriesToAdd);
        }
        this.queries.pushObjects(queriesToAdd);
    }

    setQueries(queries) {
        this.queries.clear();
        this.addQueries(queries);
    }

    removeQuery(query) {
        this.queries.removeObject(query);
    }
}
