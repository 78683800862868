import Component from "@ember/component";
import { action, computed } from "@ember/object";

import ComputedSortField from "infegy-frontend/utils/computed-sort-field";
import CustomDataset from "infegy-frontend/models/users/custom-dataset";

export default class CustomDatasetList extends Component {
    datasets = [];
    sortAscending = false;
    sortField = "updatedTimestamp";
    currentPage = 0;
    datasetsPerPage = 8;
    user = null;
    companyUsers = [];

    selectedItemId = null;
    checkedItemIds = [];
    editingItem = false;

    titleSort = ComputedSortField("title");
    totalPostsSort = ComputedSortField("totalPosts");
    totalBytesSort = ComputedSortField("totalBytes");
    createdDateSort = ComputedSortField("createdDate");
    updatedDateSort = ComputedSortField("updatedDate");
    statusSort = ComputedSortField("pctContentAvailable");

    @computed("datasets", "datasets.length", "sortField", "sortAscending")
    get sortedDatasets() {
        var datasets = this.datasets,
            sortField = this.sortField,
            sortAscending = this.sortAscending;
        if(sortAscending){
            return datasets.sortBy(sortField);
        } else {
            return datasets.sortBy(sortField).reverse();
        }
    }

    @computed("sortedDatasets", "currentPage", "datasetsPerPage")
    get paginatedDatasets() {
        var datasets = this.sortedDatasets,
            currentPage = this.currentPage,
            datasetsPerPage = this.datasetsPerPage,
            currentIndex = currentPage * datasetsPerPage;
        return datasets.slice(currentIndex, currentIndex + datasetsPerPage);
    }

    @computed.filterBy("datasets", "isSelected") selectedDatasets;
    @computed.filterBy("datasets", "isSelected") selectedDatasets;

    saveCustomDataset() {};
    deleteCustomDataset(customDataset) {};
    appendToDataset() {};
    useCustomDataset(dataset) {};
    deleteMultipleDatasets() {};
    cancelDelete() {};

    sortBy(newSortField) {
        var sortField = this.sortField;
        if (newSortField === sortField) {
            this.toggleProperty("sortAscending");
        } else {
            this.setProperties({
                sortField: newSortField,
                sortAscending: newSortField === "title"
            });
        }
    }

    @action
    onSortFieldChanged(newSortField) {
        var sortField = this.sortField;
        if (newSortField !== sortField) {
            this.setProperties({
                sortField: newSortField,
                sortAscending: "title" === newSortField
            });
        }
    }

    @action
    onToggleEditingItem(dataset){
        var newDataset = CustomDataset.create();
        newDataset.loadJSON(dataset.toJSON());
        this.set("editingItem", newDataset);
    }

    @action
    onSaveItem(){
        this.saveCustomDataset(this.editingItem);
        this.set("editingItem", null);
    }

    @action
    onCancelEditingItem(){
        this.set("editingItem",null);
    }

    @action
    onDeleteCustomDataset(customDataset){
        this.deleteCustomDataset(customDataset);
        this.set("editingItem", null);
    }

    @action
    onAppendToDataset(dataset){
        this.appendToDataset(dataset);
    }

    @action
    onUseCustomDataset(dataset) {
        this.useCustomDataset(dataset);
    }

    @action
    onSharingDeclined(dataset) {
        this.datasets.removeObject(dataset);
    }
}