import Ember from "ember";
import Component from '@ember/component';
import {computed, action} from "@ember/object";

export default class extends Component {
    classNames = ["entities-table", "table-overflow-scroll"];

    queries = null;
    selectedEntities = null;
    hasQueryField = false;

    sortAscending = false;
    sortField = "universe";

    selectedEntity = null;

    collate = true;
    expandable = true;

    isPaginated = true;
    entitiesPerPage = 50;
    currentPage = 0;

    removeEntity = null;
    documentField = null;

    @computed("isPaginated", "sortedEntities.length", "entitiesPerPage")
    get hasPages() {
        return this.isPaginated && this.get("sortedEntities.length") > this.entitiesPerPage;
    }

    @computed("sortAscending")
    get sortedHeaderClass() {
        return this.sortAscending ? "sorted-asc" : "sorted-desc";
    }

    @computed("selectedEntities", "sortAscending", "sortField")
    get sortedEntities() {
        var entities = this.selectedEntities ?? [],
            asc = this.sortAscending,
            sortField = this.sortField;

        return entities.sort((itemA, itemB) => {
            var a = asc ? Ember.get(itemA, sortField) : Ember.get(itemB, sortField),
                b = asc ? Ember.get(itemB, sortField) : Ember.get(itemA, sortField);

            if (typeof(a) === "string") {
                return a.localeCompare(b);
            }

            return a - b;
        });
    }

    @computed("sortedEntities", "currentPage", "entitiesPerPage", "hasPages")
    get paginatedEntities() {
        var sortedEntities = this.sortedEntities || [],
            currentPage = this.currentPage || 0,
            entitiesPerPage = this.entitiesPerPage,
            currentIndex = currentPage * entitiesPerPage,
            paginatedEntities = sortedEntities.slice(currentIndex, currentIndex + entitiesPerPage);

        if (!this.hasPages) {
            return sortedEntities;
        }

        // Edge Case: Given limited results,
        // sessionStorage with stored page breaks the paginator.
        if (!paginatedEntities.length) {
            currentIndex = 0;
        }

        return sortedEntities.slice(currentIndex, currentIndex + entitiesPerPage);
    }

    @computed.equal("sortField", "topic") nameSort;
    @computed.equal("sortField", "_originalQuery.title") querySort;
    @computed.equal("sortField", "documentsGrowthPct") trendSort;
    @computed.equal("sortField", "positiveAppearancesDistribution") sentimentSort;
    @computed.equal("sortField", "documents") documentsSort;
    @computed.equal("sortField", "universe") universeSort;
    @computed.equal("sortField", "appearancesPerPost") appearancesSort;

    @action
    entitySelected(entity) {
        if (this.selectedEntity !== entity) {
            this.set("selectedEntity", entity);
        } else {
            this.set("selectedEntity", null);
        }
    }

    @action
    sortBy(newSortField) {
        var sortField = this.sortField;
        if (newSortField === sortField) {
            this.toggleProperty("sortAscending");
        } else {
            this.setProperties({
                sortField: newSortField,
                sortAscending: newSortField === "topic" || newSortField === "_originalQuery.title"
            });
        }
    }
}
