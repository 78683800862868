import Component from "@ember/component";
import { action } from "@ember/object"

export default class SubqueryTableCreate extends Component {
    errorMessage = "";
    name = "";
    query = "";

    // events
    cancel = () => {};
    save = (name, query) => {};

    @action
    cancelAction() {
        this.cancel();
    }
    @action
    saveAction() {
        this.save(this.name, this.query).catch((response) => {
            this.set("errorMessage", response.atlasErrorText);
        });
    }
}