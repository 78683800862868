export default [{
    "label": "Include",
    "value": -1,
    "description": "Include replies in post results.",
    "sort": 1
},{
    "label": "Exclude",
    "value": 0,
    "description": "Exclude replies from post results.",
    "sort": 2
},{
    "label": "Exclusively Replies",
    "value": 1,
    "description": "Exclusively return reply posts.",
    "sort": 3
}];