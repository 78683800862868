import Ember from "ember";
import FieldDefinition from "infegy-frontend/models/fields/trend-field-definition";
import TrendsFieldsSelectorBase from "infegy-frontend/components/trends/fields/segment-fields/fields-selector-base";

export default TrendsFieldsSelectorBase.extend({
    queries: null,

    displayAsDropdown: false,

    innerAvailableFields: Ember.computed("availableFields", "queries",
            "queries.[]", "activeField", function () {
        var availableFields = this.availableFields || [],
            queries = this.queries || [],
            numQueries = this.get("queries.length"),
            numFields = this.get("availableFields.length"),
            activeField = this.activeField;

        if (activeField && numQueries && numQueries !== numFields) {
            this.activeFieldsChanged(this.makeAvailableFields(queries, activeField));
        }
        return availableFields;
    }),

    makeAvailableFields: function (queries, activeField) {
        if (!queries || !activeField) {
            return null;
        }
        var availableFields = queries && queries.map(function(query, queryIndex) {
            var newField = FieldDefinition.create();
            newField.loadJSON(activeField.toJSON());
            newField.setProperties({
                queryIndex: queryIndex,
                query: query,
                title: Ember.get(query, "title"),
                value: "query" + queryIndex,
                inactiveByDefault: false,
                color: Ember.get(query, "queryInfo.color")
            });

            query.get(newField.get("apiName"));
            return newField;
        });
        return availableFields;
    },

    activeFieldChanged: () => {},
    activeFieldsChanged: () => {},
    actions: {
        activeFieldChanged: function(selectedOption) {
            var queries = this.queries || [];

            var availableFields = this.makeAvailableFields(queries, selectedOption);
            this.activeFieldChanged(selectedOption);
            this.activeFieldsChanged(availableFields);
        },
        activeFieldValueChanged: function(activeFieldValue) {
            var availableFields = this.innerAvailableFields;
            this.send("activeFieldChanged", availableFields.findBy("value", activeFieldValue));
        }
    }
});
