import { computed } from "@ember/object";
import { isEmpty } from "@ember/utils";

import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import JsonStore from 'infegy-frontend/json-store/model';
import Prop from 'infegy-frontend/json-store/properties/model';
import QueryFilters from "infegy-frontend/models/queries/query_filters";

export default class Snippet extends JsonStore {
    @Prop.Int() id;
    @Prop.String() title;
    @Prop.Object(QueryFilters) queryInfo;
    @Prop.Attr() createdOn;
    @Prop.Attr() updatedOn;
    @Prop.Boolean() isShared;

    url = "api/v3/snippets";

    @computed.alias("queryInfo") filters;
    @computed.alias("queryInfo.query") query;

    loadJSON(jsonData, options) {
        if (jsonData.hasOwnProperty("query_info") && typeof(jsonData.query_info) === "string") {
            jsonData.query_info = JSON.parse(jsonData.query_info);
        }
        super.loadJSON(jsonData, options);
    }

    copy() {
        let newSnippet = Snippet.create();
        newSnippet.loadJSON({
            id: this.id,
            title: this.title,
            query_info: this.filters.toJSON({fieldGroups: "api"}),
            created_on: this.createdOn,
            updated_on: this.updatedOn
        });
        return newSnippet;
    }

    getById(snippetId) {
        return AtlasAuth.Get({
            url: `api/v3/snippets?id=${snippetId}`,
        }).then((response) => {
            this.loadJSON(response.output[0]);
        });
    }

    async save() {
        let data = {
            title: this.title,
            query_info: this.filters.toJSON({fieldGroups: "api"})
        };

        if (isEmpty(this.id)) {
            await AtlasAuth.Post({
                url: this.url,
                headers: {
                    "Content-Type": "application/json"
                },
                data
            }).then((response) => {
                this.loadJSON(response.output[0]);
            });
        }
        else {
            data.id = this.id;
            await AtlasAuth.Post({
                url: this.url,
                headers: {
                    "Content-Type": "application/json"
                },
                data
            }).then((response) => {
                this.loadJSON(response.output[0]);
            });
        }
    }

    async saveCopy() {
        let data = {
            title: `${this.title} (copy)`,
            query_info: this.filters.toJSON({fieldGroups: "api"})
        };

        await AtlasAuth.Post({
            url: this.url,
            headers: {
                "Content-Type": "application/json"
            },
            data
        }).then((response) => {
            this.loadJSON(response.output[0]);
        });
    }

    async delete() {
        return AtlasAuth.Delete({
            url: this.url,
            data: { id: this.id }
        });
    }
}