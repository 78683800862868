import Ember from 'ember';

var getStorageByType = function(storageType) {
    if (!storageType || !["sessionStorage", "localStorage"].includes(storageType)) {
        storageType = "sessionStorage";
    }
    return window[storageType];
};

export default {
    Attr: function(storageKey, options) {
        var _opt = options || {},
            _storageKey = storageKey || _opt.storageKey,
            _storage = getStorageByType(_opt.storageType),
            _default = _opt.defaultValue;

        return Ember.computed({
            get: function(key) {
                var value = _storage.getItem(_storageKey || key);
                value = value && JSON.parse(value);
                if (!value || (typeof value === "object" && !Object.keys(value).length)) {
                    _storage.removeItem(_storageKey || key);
                    return _default;
                }
                return value;
            },
            set: function(key, value) {
                if (Ember.isEmpty(value) || (typeof value === "object" && !(Object.keys(value).length))) {
                    _storage.removeItem(_storageKey);
                    return null;
                } else {
                    _storage.setItem(_storageKey, JSON.stringify(value));
                    return value;
                }
            }
        });
    },
    Bool: function(storageKey, options) {
        var _storageKey = storageKey || (options && options.storageKey),
            _storage = getStorageByType(options && options.storageType);

        return Ember.computed({
            get: function(key) {
                var value = _storage.getItem(_storageKey || key) === "true";
                if (!value) {
                    _storage.removeItem(_storageKey || key);
                }
                return value;
            },
            set: function(key, value) {
                if (!value) {
                    _storage.removeItem(_storageKey);
                    return false;
                }
                _storage.setItem(_storageKey, "true");
                return true;
            }
        });
    },
};
