import DmaNames from "infegy-frontend/static_data/geography/dma_names";
import Countries from "infegy-frontend/static_data/geography/countries_json";
import {CAProvinces} from "infegy-frontend/static_data/geography/ca_provinces";
import States from "infegy-frontend/static_data/geography/us_states_json";

const StateByKey = function(code){
    return States.findBy("code",code.toUpperCase());
};

const CountryByKey = function(code){
    return Countries.findBy("code",code.toUpperCase());
};

const DMAByKey = function(code){
    return { "name": DmaNames[code] };
};

const CAProvincesByKey = function(code){
    return CAProvinces.findBy("code", code.toUpperCase());
};

export default [{
    label: "All Countries",
    value: "countries",
    api: "countries",
    filterId: "country",
    fixtureByKey: CountryByKey
}, {
    label: "US States",
    value: "states",
    api: "states",
    filterId: "us_state",
    fixtureByKey: StateByKey,
    id: "us_states"
}, {
    label: "US Marketing Areas",
    value: "dma",
    api: "dmas",
    filterId: "us_dma",
    fixtureByKey: DMAByKey,
    id: "us_dma"
}, {
    label: "Canadian Provinces",
    value: "caProvinces",
    api: "caProvinces",
    filterId: "ca_province",
    fixtureByKey: CAProvincesByKey,
}, {
    label: "Europe",
    value: "europe",
    api: "countries",
    filterId: "country",
    fixtureByKey: CountryByKey,
    id: "country"
}, {
    label: "Latin America",
    value: "latin-america",
    api: "countries",
    filterId: "country",
    fixtureByKey: CountryByKey,
    id: "country"
}, {
    label: "Africa",
    value: "africa",
    api: "countries",
    filterId: "country",
    fixtureByKey: CountryByKey,
    id: "country"
}, {
    label: "Continental Asia",
    value: "asia-continental",
    api: "countries",
    filterId: "country",
    fixtureByKey: CountryByKey,
    id: "country"
}, {
    label: "Southeast Asia / Oceania",
    value: "oceania-sea",
    api: "countries",
    filterId: "country",
    fixtureByKey: CountryByKey,
    id: "country"
}];
