import Component from '@ember/component';
import classic from "ember-classic-decorator";

@classic
export default class ComparisonTableRow extends Component {
    tagName = "tr";
    classNameBindings = [":data-table-row"];

    queries = null;
    comparisonTableRow = null;
};
