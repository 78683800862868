export default {
    "casper riverton wy": "Casper-Riverton, WY",
    "las vegas nv": "Las Vegas, NV",
    "springfield holyoke ma": "Springfield-Holyoke, MA",
    "lima oh": "Lima, OH",
    "palm springs ca": "Palm Springs, CA",
    "north platte ne": "North Platte, NE",
    "new orleans la": "New Orleans, LA",
    "sacramento stockton-modesto ca": "Sacramento-Stockton-Modesto, CA",
    "yuma az el centro ca": "Yuma, AZ-El Centro, CA",
    "reno nv": "Reno, NV",
    "st joseph mo": "St. Joseph, MO",
    "idaho falls pocatello id": "Idaho Falls-Pocatello, ID",
    "richmond petersburg va": "Richmond-Petersburg, VA",
    "rockford il": "Rockford, IL",
    "sioux city ia": "Sioux City, IA",
    "st louis mo": "St. Louis, MO",
    "wilkes barre scranton pa": "Wilkes Barre-Scranton, PA",
    "hattiesburg laurel ms": "Hattiesburg-Laurel, MS",
    "rochester mn mason city ia-austin mn": "Rochester, MN-Mason City, IA-Austin, MN",
    "ft wayne in": "Ft. Wayne, IN",
    "zanesville oh": "Zanesville, OH",
    "lincoln hastings kearney ne": "Lincoln & Hastings-Kearney, NE",
    "evansville in": "Evansville, IN",
    "lansing mi": "Lansing, MI",
    "binghamton ny": "Binghamton, NY",
    "pittsburgh pa": "Pittsburgh, PA",
    "elmira ny": "Elmira, NY",
    "great falls mt": "Great Falls, MT",
    "cleveland akron canton oh": "Cleveland-Akron (Canton), OH",
    "beaumont port arthur tx": "Beaumont-Port Arthur, TX",
    "alpena mi": "Alpena, MI",
    "springfield mo": "Springfield, MO",
    "columbia sc": "Columbia, SC",
    "albuquerque santa fe nm": "Albuquerque-Santa Fe, NM",
    "biloxi gulfport ms": "Biloxi-Gulfport, MS",
    "birmingham anniston and tuscaloosa al": "Birmingham (Anniston and Tuscaloosa), AL",
    "lubbock tx": "Lubbock, TX",
    "parkersburg wv": "Parkersburg, WV",
    "jonesboro ar": "Jonesboro, AR",
    "memphis tn": "Memphis, TN",
    "lexington ky": "Lexington, KY",
    "greenwood greenville ms": "Greenwood-Greenville, MS",
    "des moines ames ia": "Des Moines-Ames, IA",
    "fresno visalia ca": "Fresno-Visalia, CA",
    "tucson sierra vista az": "Tucson (Sierra Vista), AZ",
    "erie pa": "Erie, PA",
    "providence ri new bedford ma": "Providence, RI-New Bedford, MA",
    "milwaukee wi": "Milwaukee, WI",
    "miami fort lauderdale fl": "Miami-Fort Lauderdale, FL",
    "south bend elkhart in": "South Bend-Elkhart, IN",
    "helena mt": "Helena, MT",
    "cheyenne wy scottsbluff ne": "Cheyenne, WY-Scottsbluff, NE",
    "fargo valley city nd": "Fargo-Valley City, ND",
    "odessa midland tx": "Odessa-Midland, TX",
    "joplin mo pittsburg ks": "Joplin, MO-Pittsburg, KS",
    "ottumwa ia kirksville mo": "Ottumwa, IA-Kirksville, MO",
    "albany ga": "Albany, GA",
    "el paso tx": "El Paso, TX",
    "victoria tx": "Victoria, TX",
    "youngstown oh": "Youngstown, OH",
    "monterey salinas ca": "Monterey-Salinas, CA",
    "washington dc hagerstown md": "Washington, DC (Hagerstown, MD)",
    "minneapolis st paul mn": "Minneapolis-St. Paul, MN",
    "denver co": "Denver, CO",
    "orlando daytona beach-melbourne fl": "Orlando-Daytona Beach-Melbourne, FL",
    "norfolk portsmouth-newport news va": "Norfolk-Portsmouth-Newport News, VA",
    "mankato mn": "Mankato, MN",
    "dallas ft worth tx": "Dallas-Ft. Worth, TX",
    "charlottesville va": "Charlottesville, VA",
    "albany schenectady-troy ny": "Albany-Schenectady-Troy, NY",
    "grand rapids kalamazoo-battle creek mi": "Grand Rapids-Kalamazoo-Battle Creek, MI",
    "topeka ks": "Topeka, KS",
    "waco temple-bryan tx": "Waco-Temple-Bryan, TX",
    "panama city fl": "Panama City, FL",
    "dothan al": "Dothan, AL",
    "tri cities tn-va": "Tri-Cities, TN-VA",
    "san diego ca": "San Diego, CA",
    "baltimore md": "Baltimore, MD",
    "madison wi": "Madison, WI",
    "san francisco oakland-san jose ca": "San Francisco-Oakland-San Jose, CA",
    "harrisburg lancaster-lebanon-york pa": "Harrisburg-Lancaster-Lebanon-York, PA",
    "santa barbara santa maria-san luis obispo ca": "Santa Barbara-Santa Maria-San Luis Obispo, CA",
    "lafayette in": "Lafayette, IN",
    "bakersfield ca": "Bakersfield, CA",
    "green bay appleton wi": "Green Bay-Appleton, WI",
    "wausau rhinelander wi": "Wausau-Rhinelander, WI",
    "salt lake city ut": "Salt Lake City, UT",
    "los angeles ca": "Los Angeles, CA",
    "colorado springs pueblo co": "Colorado Springs-Pueblo, CO",
    "rochester ny": "Rochester, NY",
    "burlington vt plattsburgh ny": "Burlington, VT-Plattsburgh, NY",
    "hartford new haven ct": "Hartford & New Haven, CT",
    "philadelphia pa": "Philadelphia, PA",
    "phoenix az": "Phoenix, AZ",
    "jackson tn": "Jackson, TN",
    "chico redding ca": "Chico-Redding, CA",
    "boise id": "Boise, ID",
    "yakima pasco-richland-kennewick wa": "Yakima-Pasco-Richland-Kennewick, WA",
    "seattle tacoma wa": "Seattle-Tacoma, WA",
    "ft myers naples fl": "Ft. Myers-Naples, FL",
    "twin falls id": "Twin Falls, ID",
    "sherman tx ada ok": "Sherman, TX-Ada, OK",
    "west palm beach ft pierce fl": "West Palm Beach-Ft. Pierce, FL",
    "billings mt": "Billings, MT",
    "omaha ne": "Omaha, NE",
    "salisbury md": "Salisbury, MD",
    "sioux falls mitchell sd": "Sioux Falls (Mitchell), SD",
    "chicago il": "Chicago, IL",
    "tampa st petersburg sarasota fl": "Tampa-St. Petersburg (Sarasota), FL",
    "austin tx": "Austin, TX",
    "boston ma manchester nh": "Boston, MA (Manchester, NH)",
    "grand junction montrose co": "Grand Junction-Montrose, CO",
    "cedar rapids waterloo-iowa city dubuque ia": "Cedar Rapids-Waterloo-Iowa City & Dubuque, IA",
    "presque isle me": "Presque Isle, ME",
    "butte bozeman mt": "Butte-Bozeman, MT",
    "la crosse eau claire wi": "La Crosse-Eau Claire, WI",
    "wichita hutchinson ks plus": "Wichita-Hutchinson, KS Plus",
    "atlanta ga": "Atlanta, GA",
    "flint saginaw-bay city mi": "Flint-Saginaw-Bay City, MI",
    "champaign springfield decatur il": "Champaign & Springfield-Decatur, IL",
    "huntsville decatur florence al": "Huntsville-Decatur (Florence), AL",
    "utica ny": "Utica, NY",
    "syracuse ny": "Syracuse, NY",
    "portland auburn me": "Portland-Auburn, ME",
    "toledo oh": "Toledo, OH",
    "harrisonburg va": "Harrisonburg, VA",
    "portland or": "Portland, OR",
    "peoria bloomington il": "Peoria-Bloomington, IL",
    "indianapolis in": "Indianapolis, IN",
    "new york ny": "New York, NY",
    "kansas city mo": "Kansas City, MO",
    "chattanooga tn": "Chattanooga, TN",
    "houston tx": "Houston, TX",
    "davenport ia rock island-moline il": "Davenport, IA-Rock Island-Moline, IL",
    "dayton oh": "Dayton, OH",
    "columbus oh": "Columbus, OH",
    "duluth mn superior wi": "Duluth, MN-Superior, WI",
    "ft smith fayetteville-springdale-rogers ar": "Ft. Smith-Fayetteville-Springdale-Rogers, AR",
    "columbus tupelo-west point ms": "Columbus-Tupelo-West Point, MS",
    "rapid city sd": "Rapid City, SD",
    "minot bismarck-dickinsonwilliston nd": "Minot-Bismarck-Dickinson(Williston), ND",
    "johnstown altoona pa": "Johnstown-Altoona, PA",
    "bend or": "Bend, OR",
    "quincy il hannibal mo-keokuk ia": "Quincy, IL-Hannibal, MO-Keokuk, IA",
    "detroit mi": "Detroit, MI",
    "spokane wa": "Spokane, WA",
    "cincinnati oh": "Cincinnati, OH",
    "louisville ky": "Louisville, KY",
    "watertown ny": "Watertown, NY",
    "columbia jefferson city mo": "Columbia-Jefferson City, MO",
    "glendive mt": "Glendive, MT",
    "medford klamath falls or": "Medford-Klamath Falls, OR",
    "buffalo ny": "Buffalo, NY",
    "eugene or": "Eugene, OR",
    "eureka ca": "Eureka, CA",
    "traverse city cadillac mi": "Traverse City-Cadillac, MI",
    "missoula mt": "Missoula, MT",
    "jacksonville fl": "Jacksonville, FL",
    "charlotte nc": "Charlotte, NC",
    "abilene sweetwater tx": "Abilene-Sweetwater, TX",
    "bangor me": "Bangor, ME",
    "amarillo tx": "Amarillo, TX",
    "greensboro high point-winston salem nc": "Greensboro-High Point-Winston Salem, NC",
    "oklahoma city ok": "Oklahoma City, OK",
    "marquette mi": "Marquette, MI",
    "nashville tn": "Nashville, TN",
    "san antonio tx": "San Antonio, TX",
    "montgomery selma al": "Montgomery-Selma, AL",
    "tulsa ok": "Tulsa, OK",
    "wheeling wv steubenville oh": "Wheeling, WV-Steubenville, OH",
    "wichita falls tx lawton ok": "Wichita Falls, TX-Lawton, OK",
    "greenville spartanburg sc-asheville nc-andersonsc": "Greenville-Spartanburg, SC-Asheville, NC-Anderson,SC",
    "mobile al pensacola ft walton beach fl": "Mobile, AL-Pensacola (Ft. Walton Beach), FL",
    "charleston sc": "Charleston, SC",
    "roanoke lynchburg va": "Roanoke-Lynchburg, VA",
    "bowling green ky": "Bowling Green, KY",
    "raleigh durham fayetteville nc": "Raleigh-Durham (Fayetteville), NC",
    "lake charles la": "Lake Charles, LA",
    "baton rouge la": "Baton Rouge, LA",
    "gainesville fl": "Gainesville, FL",
    "san angelo tx": "San Angelo, TX",
    "little rock pine bluff ar": "Little Rock-Pine Bluff, AR",
    "lafayette la": "Lafayette, LA",
    "wilmington nc": "Wilmington, NC",
    "tyler longviewlufkin nacogdoches tx": "Tyler-Longview(Lufkin & Nacogdoches), TX",
    "terre haute in": "Terre Haute, IN",
    "savannah ga": "Savannah, GA",
    "laredo tx": "Laredo, TX",
    "myrtle beach florence sc": "Myrtle Beach-Florence, SC",
    "paducah ky cape girardeau mo-harrisburg il": "Paducah, KY-Cape Girardeau, MO-Harrisburg, IL",
    "augusta ga": "Augusta, GA",
    "bluefield beckley-oak hill wv": "Bluefield-Beckley-Oak Hill, WV",
    "shreveport la": "Shreveport, LA",
    "tallahassee fl thomasville ga": "Tallahassee, FL-Thomasville, GA",
    "charleston huntington wv": "Charleston-Huntington, WV",
    "macon ga": "Macon, GA",
    "columbus ga": "Columbus, GA",
    "alexandria la": "Alexandria, LA",
    "greenville new bern-washington nc": "Greenville-New Bern-Washington, NC",
    "clarksburg weston wv": "Clarksburg-Weston, WV",
    "knoxville tn": "Knoxville, TN",
    "jackson ms": "Jackson, MS",
    "corpus christi tx": "Corpus Christi, TX",
    "monroe la el dorado ar": "Monroe, LA-El Dorado, AR",
    "meridian ms": "Meridian, MS",
    "harlingen weslaco-brownsville-mcallen tx": "Harlingen-Weslaco-Brownsville-McAllen, TX",
    "juneau ak": "Juneau, AK",
    "honolulu hi": "Honolulu, HI",
    "fairbanks ak": "Fairbanks, AK",
    "anchorage ak": "Anchorage, AK"
};