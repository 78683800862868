import Ember from "ember";

export default Ember.Component.extend({
    classNames: ["query-details"],

    query: null,

    dictionaries: null,
    audienceSegments: null,

    filterParts: Ember.computed("query.queryFilters", function() {
        return this.get("query.queryFilters.filters");
    }),

    filterOptions: Ember.computed("query.queryFilters", function() {
        return this.get("query.queryFilters.datasetQueryInfo.filters");
    }),

    queryFilters: Ember.computed.alias("query.queryFilters"),

    hasAndItems: Ember.computed.gt("query.queryInfo.queryBuilderDetail.andItems.length", 0),
    hasOrItems: Ember.computed.gt("query.queryInfo.queryBuilderDetail.orItems.length", 0),
    hasNotItems: Ember.computed.gt("query.queryInfo.queryBuilderDetail.notItems.length", 0),

    isBuilderQuery: Ember.computed.or("hasAndItems", "hasOrItems")
});
