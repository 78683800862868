import DataSeriesComputedFieldBase from "infegy-frontend/models/data_series/data_series_computed_fields";

// Creates computed distributions based on a list of provided tuples
// in the following format:
//
//      [[sourceField, destinationField], [sourceField, destinationField], ...]
//
// Alternately, a simple list of strings may be provided with the sourceFields,
//  And the destination fields will be given generated names in the form:
//  "sourceField" + "Distribution".
//  e.g. positivePosts would become postivePostsDistribution
//
// This alternate list should be in the form:
//
//  ["sourceField", "sourceField", ...]
//
// Outputs percentages of the sum of provided fields, is groupable using
// weighted averages. (Recomputed from grouped data).
var DataSeriesComputedFieldDistribution = DataSeriesComputedFieldBase.extend({
    fieldTuples: [],
    defaultVal: 0.0,

    type: "distribution",

    addFieldsTo: function(fields) {
        fields = fields || {};
        var tuples = this.fieldTuples,
            type = this.type,
            len = tuples.length,
            destination, idx;

        for (idx = 0; idx < len; idx++) {
            destination = tuples[idx][1];
            fields[destination] = type;
        }
        return fields;
    },
    rowCompute: function(row) {
        var tuples = this.fieldTuples,
            defaultVal = this.defaultVal,
            len = tuples.length,
            sum = 0,
            nullCount = 0,
            source, destination, idx;

        for (idx = 0; idx < len; idx++) {
            source = row[tuples[idx][0]];
            if (source === null || source === undefined) {
                nullCount++;
            }
            sum += +source || 0.0;
        }
        if (nullCount === len) {
            return row;
        }

        for (idx = 0; idx < len; idx++) {
            source = row[tuples[idx][0]];
            destination = tuples[idx][1];
            if (!sum || source === null || source === undefined) {
                row[destination] = defaultVal;
            } else {
                row[destination] = (+source || 0.0) / sum;
            }
        }
        return row;
    },
    statsCompute: function(stats, data) {
        var tuples = this.fieldTuples,
            defaultVal = this.defaultVal,
            len = tuples.length,
            source, destination, idx,
            sourceStats, destinationStats,
            sums = 0, firstHalfSums = 0, secondHalfSums = 0;

        for (idx = 0; idx < len; idx++) {
            sourceStats = stats[tuples[idx][0]];
            if (!sourceStats) {
                continue;
            }
            sums += +(sourceStats.sum) || 0.0;
            firstHalfSums += +(sourceStats.firstHalfSum) || 0.0;
            secondHalfSums += +(sourceStats.secondHalfSum) || 0.0;
        }

        for (idx = 0; idx < len; idx++) {
            source = tuples[idx][0];
            destination = tuples[idx][1];
            sourceStats = stats[source];
            destinationStats = stats[destination];
            if (!sourceStats || !destinationStats) {
                continue;
            }
            if (sums === 0) {
                destinationStats.sum = defaultVal;
                destinationStats.average = defaultVal;
                destinationStats.percentChange = 0;
            } else {
                destinationStats.sum = sourceStats.sum / sums;
                destinationStats.average = sourceStats.sum / sums;
                destinationStats.firstHalfSum = firstHalfSums && sourceStats.firstHalfSum / firstHalfSums;
                destinationStats.secondHalfSum = secondHalfSums && sourceStats.secondHalfSum / secondHalfSums;
                if (destinationStats.firstHalfSum) {
                    destinationStats.percentChange = ((destinationStats.secondHalfSum - destinationStats.firstHalfSum) /
                        destinationStats.firstHalfSum) * 100.0;
                } else {
                    destinationStats.percentChange = 0;
                }
            }
            destinationStats.type = "distribution";
        }
        return stats;
    }
});

DataSeriesComputedFieldDistribution.reopenClass({
    fieldSetup: function(sourceFields, defaultValue) {
        var fieldTuples = [],
            len = sourceFields.length,
            defaultVal = defaultValue || 0,
            field, dest, idx;

        for (idx = 0; idx < len; idx++) {
            if (typeof sourceFields[idx] === "string") {
                field = sourceFields[idx];
                dest = [field, "Distribution"].join("");
                fieldTuples.push([field, dest]);
            } else {
                throw("Invalid field list passed to distribution definition");
            }
        }

        return DataSeriesComputedFieldDistribution.create({
            defaultVal: defaultVal,
            fieldTuples: fieldTuples
        });
    }
});

export default DataSeriesComputedFieldDistribution;
