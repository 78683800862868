import Ember from 'ember';

let submitKeys = [
    13, //enter
    9, //tab
];

export default Ember.Component.extend({
    classNameBindings:[":multi-item-input","isFocused:focus"],
    innerValue: "",
    value: "",
    splitCharacters: ",; ",
    activeObjects: [],
    isFocused:false,

    separatorRegex: Ember.computed("splitCharacters",{
        get(){
            //split anything that isn't a split character followed by a split character
            let splitCharacters = this.splitCharacters;
            if(Ember.isArray(splitCharacters)){
                splitCharacters = splitCharacters.join("");
            }
            return new RegExp(`((?:[^${splitCharacters}]+){1,})(?=[${splitCharacters}])`, 'g');
        }
    }),

    click:function(){
        this.element.querySelector(".multi-item-input-item-inner-input").focus();
    },

    itemsAdded: () => {},
    itemsRemoved: () => {},
    actions: {
        inputKeyDown(e, value) {
            let selectedValues = this.activeObjects;
            if(submitKeys.indexOf(e.keyCode) > -1) {
                e.preventDefault();
                // submit value
                let value = this.value;
                this.itemsAdded([value]);
            } else if (e.keyCode === 8 && value.length === 0){
                //remove from list if backspacing while form is empty
                let removedObject = Ember.get(selectedValues,"lastObject");
                this.itemsRemoved([removedObject]);
            }
        },
        inputKeyUp(e, value) {
            value += e.key;
            let splitCharacters = this.splitCharacters,
                splits = value.match(new RegExp(`([${splitCharacters}])`,'g'));
            if (splits) {
                let splitObjects = value.match(new RegExp(`([^${splitCharacters}]+)`,'g'));
                this.itemsAdded(splitObjects);
            }
        },
        removeItem(item){
            this.itemsRemoved([item]);
        },
        inputFocusChanged(isFocused){
            this.set("isFocused",isFocused);
        }
    }
});
