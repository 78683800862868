import Component from "@ember/component";
import classic from "ember-classic-decorator";

@classic
export default class SubqueryTableSearch extends Component {
    searchText = "";
    searchBy = "tag";
    sortAscending = true;

    searchByOptions = [
        {
            label: "Name",
            value: "tag"
        },
        {
            label: "Query",
            value: "query"
        }
    ];

    sortDirectionOptions = [
        {
            label: "Ascending",
            value: true
        },
        {
            label: "Descending",
            value: false
        }
    ];
}