import DataSeriesComputedFieldBase from "infegy-frontend/models/data_series/data_series_computed_fields";
import NumberStats from "infegy-frontend/models/data_series/number_stats";

// Creates a computed percentage field based on the portion of a numeric field
//  and it's overall sum:
//      A "portionField" which represents a numeric Field
//
// The output is calculated by "portionField" / the sum of that field
var DataSeriesComputedFieldPortion = DataSeriesComputedFieldBase.extend({
    destinationField: "",
    portionField: "",
    type: "portion",

    addFieldsTo: function(fields) {
        var destinationField = this.destinationField,
        type = this.type;

        if (destinationField && type) {
            fields[destinationField] = type;
        }
        return fields;
    },
    preCompute: function(data) {
        var portionField = this.portionField;
        var sum = data.reduce(function(memo, row) {
            if (row && row[portionField]) {
                memo += row[portionField];
            }
            return memo;
        }, 0);
        return {"sum": sum};
    },
    rowCompute: function(row, preComputeResults) {
        var destinationField = this.destinationField,
            portionField = this.portionField,
            sum = preComputeResults.sum,
            portion = row[portionField];

        if (sum) {
            row[destinationField] = (+portion || 0.0) / sum;
        }

        return row;
    },
    statsCompute: function(stats, data) {
        var destinationField = this.destinationField,
            portionField = this.portionField,
            portionStats = stats[portionField],
            type = this.type,
            destinationStats = NumberStats.create();

        var values = data.mapBy(destinationField),
            results = {
                field: destinationField,
                type: type,
                isComputed: false,
                sum: 1.0,
                average: 1.0 / values.length,
                firstHalfSum: portionStats.firstHalfSum / portionStats.sum,
                secondHalfSum: portionStats.secondHalfSum / portionStats.sum,
                max: Math.max.apply(Math, values),
                maxIndex: portionStats.maxIndex,
                min: Math.min.apply(Math, values),
                minIndex: portionStats.minIndex,
                count: values.length,
                percentChange: portionStats.percentChange
            };

        destinationStats.setProperties(results);
        stats[destinationField] = destinationStats;
        return stats;
    }
});

DataSeriesComputedFieldPortion.reopenClass({
    fieldSetup: function(destinationField, portionField) {
        return DataSeriesComputedFieldPortion.create({
            destinationField: destinationField,
            portionField: portionField,
        });
    }
});

export default DataSeriesComputedFieldPortion;
