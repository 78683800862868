import Controller, { inject as controller } from "@ember/controller";
import { isEmpty } from "@ember/utils";
import { action, computed } from "@ember/object";

import BrowserStore from "infegy-frontend/utils/browser-store";
import QualtricsSurveys from "infegy-frontend/models/qualtrics/surveys";
import CustomDataset from "infegy-frontend/models/users/custom-dataset";

export default class CustomDatasetsQualtricsImport extends Controller {
    newDatasetTitle = "";

    @controller application;
    @computed.alias("application.user") user;
    @BrowserStore.Attr("qualtricsToken", { defaultValue: "" }) qualtricsToken;

    qualtricsSurveyId = 0;
    qualtricsQuestionId = 0;
    qualtricsSurveys = QualtricsSurveys.create();

    reset() {
        this.setProperties({
            newDatasetTitle: "",
            qualtricsSurveyId: 0,
            qualtricsQuestionId: 0,
            qualtricsSurveys: QualtricsSurveys.create()
        });
    }

    @computed("newDatasetTitle", "qualtricsToken", "qualtricsSurveyId", "qualtricsQuestionId")
    get saveDisabled() {
        return isEmpty(this.newDatasetTitle)
            || isEmpty(this.qualtricsToken) 
            || isEmpty(this.qualtricsSurveyId)
            || isEmpty(this.qualtricsQuestionId);
    }

    async loadSurveys() {
        if (!isEmpty(this.qualtricsToken)) {
            this.set("loadOnInit", true);
            await this.qualtricsSurveys.loadSurveyInfos(this.qualtricsToken);
        }
    }

    @action
    async onSave() {
        const customDataset = CustomDataset.create();
        await customDataset.saveAsQualtricsImport(this.newDatasetTitle, 
            this.qualtricsToken, 
            this.qualtricsSurveyId, 
            this.qualtricsQuestionId);

        if (customDataset.hasError) {
            this.set("serverErrorMessage", customDataset.errorMessage);
        }
        else {
            this.user.loadCustomDatasets();
            this.transitionToRoute("atlas.tools.custom-datasets");
        }
    }
}