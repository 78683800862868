import Ember from "ember";
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import Emotions from "infegy-frontend/static_data/emotions";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";

export default class PostsLinguisticsEditor extends Component {
    // Can be "sentiment", "emotions", "themes"
    post = null;

    linguisticsType = "sentiment";

    sentiment = null;

    sentimentOptions = [{
            value: "positive",
            colorClass: "chart-positive"
        }, {
            value: "neutral",
            colorClass: "chart-neutral"
        }, {
            value: "negative",
            colorClass: "chart-negative"
        }];


    @computed("linguisticsType")
    get isSentimentEditor() { return this.linguisticsType === "sentiment"; };

    @computed("linguisticsType")
    get isEmotionsEditor() { return this.linguisticsType === "emotions"; };

    @computed("linguisticsType")
    get isThemesEditor() { return this.linguisticsType === "themes"; };

    _selectedEmotions = null;
    @computed("_selectedEmotions", "post.emotions")
    get selectedEmotions() {
        if (this._selectedEmotions === "null") {
            return this.get("post.emotions") || [];
        }
        return this._selectedEmotions || [];
    }
    set selectedEmotions(emotions) {
        this.set("_selectedEmotions", emotions);
        return emotions || [];
    }

    _selectedThemes = null;
    @computed("_selectedThemes", "post.themes")
    get selectedThemes() {
        if (this._selectedThemes === "null") {
            return this.get("post.themes") || [];
        }
        return this._selectedThemes || [];
    }
    set selectedThemes(themes) {
        this.set("_selectedThemes", themes);
        return themes || [];
    }

    @computed("post", "post.sentiment", "post.userSentiment")
    get postSentiment() {
        if (this.get("post.userSentiment") && this.get("post.userSentiment") !== "none") {
            return this.get("post.userSentiment");
        }
        return this.get("post.sentiment");
    };

    _selectedSentiment = null;
    @computed("_selectedSentiment", "postSentiment")
    get selectedSentiment() {
        return this._selectedSentiment || this.postSentiment;
    }
    set selectedSentiment(sentiment) {
        this.set("_selectedSentiment", sentiment);
        return sentiment || [];
    }

    @computed("post.query.updated")
    get themesOptions() {
        let themes = this.get("post.query.themes.availableThemes");
        if (!Ember.isEmpty(themes)) {
            return themes.map(theme => {
                return {label: theme, value: theme};
            });
        }
    };

    @computed("post.query.updated")
    get emotionsOptions() {
        return Emotions.emotionOptions;
    };

    @computed("selectedSentiment", "postSentiment")
    get sentimentChanged() {
        return this.selectedSentiment !== this.postSentiment;
    };

    @computed("selectedThemes", "post.themes")
    get themesChanged() {
        let selectedThemes = this.selectedThemes || [],
            postThemes = this.get("post.themes") || [];
        return selectedThemes.length && ((selectedThemes.length !== postThemes.lenth) || (JSON.stringify(selectedThemes) !== JSON.stringify(postThemes)));
    };

    @computed("selectedEmotions", "post.emotions")
    get emotionsChanged() {
        let selectedEmotions = this.selectedEmotions || [],
            postEmotions = this.get("post.emotions") || [];
        return selectedEmotions.length && ((selectedEmotions.length !== postEmotions.lenth) || (JSON.stringify(selectedEmotions) !== JSON.stringify(postEmotions)));
    };

    @computed("themesChanged", "emotionsChanged", "sentimentChanged")
    get linguisticsChanged() {
        if (this.linguisticsType === "sentiment") {
            return this.sentimentChanged;
        } else if (this.linguisticsType === "themes") {
            return this.themesChanged;
        } else if (this.linguisticsType === "emotions") {
            return this.emotionsChanged;
        }
    };

    @computed.or("sendingUpdate", "updateSuccessful", "updateFail") processingChanges;

    @action
    sendUpdate() {
        var post = this.post;
        this.setProperties({
            "updateSuccessful": false,
            "updateFail": false,
            "sendingUpdate": true,
        });
        let updateData = { post_id: post.id };
        if (this.linguisticsType === "sentiment") {
            updateData.sentiment = this.selectedSentiment;
        } else if (this.linguisticsType === "themes") {
            updateData.themes = this.selectedThemes;
        } else if (this.linguisticsType === "emotions") {
            updateData.emotions = this.selectedEmotions;
        }

        AtlasAuth.Post({
            url: "api/v3/tagged_sentiment.json",
            contentType: 'application/json',
            data: JSON.stringify(updateData)
        }).then(() => {
            this.set("updateSuccessful", true);
            if (this.linguisticsType === "sentiment") {
                this.setProperties({
                    "post.userSentiment": this.selectedSentiment,
                    "post.sentiment": this.selectedSentiment
                });
            } else if (this.linguisticsType === "themes") {
                let postThemes = (this.selectedThemes || []).map(theme => {
                    return {
                        name: theme,
                        appearances:1,
                        positiveAppearances:0,
                        negativeAppearances:0,
                        positiveDocuments:0,
                        negativeDocuments:0,
                        userTheme:true
                    };
                });
                this.set("post.themes", postThemes);
            } else if (this.linguisticsType === "emotions") {
                let postEmotions = (this.selectedEmotions || []).map(emotion => {
                    return {
                        name:emotion,
                        appearances:1,
                        positiveDocuments:0,
                        negativeDocuments:0
                    };
                });
                this.set("post.emotions", postEmotions);
            }
        }).catch(() => {
            this.set("updateFail", true);
            this.set("updateSuccessful", false);
        }).finally(() => {
            this.set("sendingUpdate", false);
        });
    }

    @action
    closeEditor() {
        this.setProperties({
            "updateSuccessful": false,
            "updateFail": false,
            "sendingUpdate": false,
            "showSentimentEditor": false,
        });
    };

    @action
    selectedSentimentChanged(sentiment) {
        this.set("selectedSentiment",sentiment);
    };

    @action
    selectedThemesChanged(options) {
        this.set("selectedThemes", options);
    }

    @action
    selectedEmotionsChanged(options) {
        this.set("selectedEmotions", options);
    }
};
