import Component from '@glimmer/component';
import { argument } from 'infegy-frontend/decorators/arguments';
/** @typedef {import("infegy-frontend/models/users/filterset").default} Filterset */


export default class extends Component {
    /** @type {Filterset} */
    @argument(null) filterset;

    get allFilterNames() {
        if (this.filterset) {
            return this.filterset.queryInfo.filters.toJSON().map(filter => {
                return filter.name;
            });
        }
        return [];
    }

}