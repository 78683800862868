import Component from '@ember/component';
import { isNone } from '@ember/utils';
import { computed } from '@ember/object';
import { action } from '@ember/object';


export default class NarrativesTableRow extends Component{

    // arguments
    query=null;
    cluster = null;

    onRenameCluster() { /* action */ }
    onRemoveCluster() { /* action */ }

    //properties
    showConfirmRemoveWarning = false;
    isEditingClusterName = false;
    _editingClusterName = null;

    @computed("_editingClusterName", "cluster.name")
    get editingClusterName (){
        return isNone(this._editingClusterName) ? this.cluster.name : this._editingClusterName;
    }
    set editingClusterName(value){
       this.set("_editingClusterName",value);
    }

    @action
    changeClusterName(){
        // this.query.narratives.renameCluster(this.cluster.key, this.editingClusterName);
        this.onRenameCluster(this.cluster, this.editingClusterName);
        this.setProperties({
            isEditingClusterName: false,
            editingClusterName: null
        });
    }
    @action
    doRemoveCluster(){
        // this.query.narratives.removeCluster(this.cluster._sourceIndex);
        this.onRemoveCluster(this.cluster);
        this.setProperties({
            isEditingClusterName: false,
            showConfirmRemoveWarning: false
        });
    }

    @action
    disableClickThrough(){
        // do nothing (this is to prevent actions from firing when interacting with the name change form)
    }
}