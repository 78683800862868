import Ember from "ember";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import QueryAlert from "infegy-frontend/models/users/query-alert";

export default Ember.Controller.extend({
    application: Ember.inject.controller(),
    user: Ember.computed.alias("application.user"),

    blankAlert: null,
    isCreating: false,

    alertsPerPage: 10,
    currentPage:0,
    errorMessage: null,

    sortedAlerts: Ember.computed("user.queryAlerts.[]",{
        get(){
            let alerts = this.get("user.queryAlerts.content") || [];
            return alerts.sort(function(a,b){
                return (new Date(Ember.get(b,"updatedDate"))).getTime() - (new Date(Ember.get(a,"updatedDate"))).getTime();
            });
        }
    }),

    displayAlerts:Ember.computed("sortedAlerts", "alertsPerPage", "currentPage", {
        get(){
            let alerts = this.sortedAlerts,
                perPage = this.alertsPerPage,
                currentPage = this.currentPage,
                currentIndex = currentPage * perPage;
            return alerts.slice(currentIndex, currentIndex + perPage);
        }
    }),

    deleteAlert:function(queryAlert) {
        let alerts = this.get("user.queryAlerts");
        AtlasAuth.Delete({
            url: "api/v3/alert_query?id=" + queryAlert.get("id")
        }).then((res) => {
            alerts.removeObject(queryAlert);
        });
    },

    updateAlert: function (queryAlert) {
        let alerts = this.get("user.queryAlerts");
        AtlasAuth.Put({
            url: "api/v3/alert_query",
            data: queryAlert.toJSON(),
        }).catch((res)=>{
            if(res.status_message){
                this.set("errorMessage", res.status_message);
            } else {
                this.set("errorMessage", "There was an error saving your query alert");
            }
        }).then((res)=>{
            if(res) {
                let newQueryAlert = QueryAlert.create();
                newQueryAlert.loadJSON(res.output[0]);
                for(var x=0; x < Ember.get(alerts,"length"); x++){
                    let alert = alerts.objectAt(x);
                    if(Ember.get(alert,"id") === Ember.get(queryAlert,"id")){
                        alerts.removeObject(alert);
                        break;
                    }
                }
                alerts.pushObject(newQueryAlert);
            }
        });
    },

    actions: {
        alertUpdated(queryAlert) {
            this.set('errorMessage', null);
            this.updateAlert(queryAlert);
        },
        deleteAlert(queryAlert) {
            this.deleteAlert(queryAlert);
        }
    }
});
