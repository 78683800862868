import Ember from "ember";
import Component from '@ember/component';
import QueryUtil from "infegy-frontend/models/queries/query_util";
import { computed, action } from '@ember/object';
import { run } from '@ember/runloop';

let allSegments = [
    { value: "Posts", fieldTypes: ["trend", "scalar"] },
    { value: "Engagements", fieldTypes: ["trend", "scalar"] },
    { value: "Sentiment", fieldTypes: ["trend", "scalar", "portion"] },
    { value: "Passion", fieldTypes: ["trend", "scalar", "portion"] },
    { value: "Gender", fieldTypes: ["trend", "scalar", "portion"] },
    { value: "Channels", fieldTypes: ["trend", "scalar", "portion"] },
    { value: "Emotions", fieldTypes: ["trend", "scalar", "portion"] },
    { value: "Themes", fieldTypes: ["trend", "scalar", "portion"] },
    { value: "Languages", fieldTypes: ["trend", "scalar", "portion"] },
    { value: "Geography", fieldTypes: ["scalar"] },
    { value: "Age", fieldTypes: ["scalar", "portion"] },
    { value: "US Demographics", fieldTypes: ["scalar", "portion"] },
    { value: "Influencers", fieldTypes: ["scalar"] }
];
export default class FieldsSegmentSelector extends Component {
    classNameBindings = ["displayAsDropdown:inline-field-select:toggle-field-select"];

    queries = null;
    selectedSegment = null;

    displayAsDropdown = false;

    fieldType = "trend";

    datasetSegment = null;
    defaultSegments = allSegments;

    showCustomButton = false;

    @computed("queries", "queries.[]", "queries.@each.availableDynamicTrendsFields")
    get availableQueryFields() {
        let queries = this.queries || [],
            availableQueryFields = QueryUtil.getAvailableQueryFields(queries);
        return availableQueryFields;
    }

    @computed("availableQueryFields", "defaultSegments", "datasetSegment", "datasetSegment.options.[]")
    get segmentsList() {
        let segments = this.defaultSegments,
            availableQueryFields = this.availableQueryFields;
        if (!Ember.isEmpty(this.datasetSegment)) {
            segments = segments.slice();
            segments.splice(1, 0, this.datasetSegment);
        }
        segments = segments.filter(segment => {
            let name = segment.value;
            if (name === "Engagements" && !availableQueryFields.engagements) {
                return false;
            } else if (name === "Channels" && !availableQueryFields.channels) {
                return false;
            } else if (name === "US Demographics" && !availableQueryFields.demographics) {
                return false;
            } else if (name === "Languages" && !availableQueryFields.language) {
                return false;
            } else if (name === "Gender" && !availableQueryFields.gender) {
                return false;
            }
            return true;
        });

        return segments;
    }

    @computed("fieldType", "fieldSegment", "segmentsList")
    get segmentOptions() {
        let fieldType = this.fieldType,
            typedSegments = (this.segmentsList || []).filter(seg => {
                return seg.fieldTypes.includes(fieldType);
            });

        if (!this.selectedSegment && typedSegments && typedSegments[0]) {
            run.next(()=>{
                this.segmentChanged(typedSegments[0].value);
            });
        }
        return typedSegments;
    };

    segmentChanged(segment) {
        // Empty for Action //
    };

    @action
    selectionChanged(segment) {
        if (segment !== this.selectedSegment) {
            this.segmentChanged(segment);
        }
    };

    toggleCustom() {}
    @action
    togglingCustom() {this.toggleCustom();}
};
