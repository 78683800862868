import Ember from "ember";
import ChartColors from "infegy-frontend/static_data/fixtures/chart_colors";

export default Ember.Component.extend({
    tagName: 'tr',
    classNameBindings: [":data-table-row", "canDrilldown:clickable"],

    interest: null,
    api: "interests",
    canDrilldown: false,
    removeInterest: ()=>{},
    showDrilldown: ()=>{},

    distributionValue: Ember.computed("interest.distribution", function () {
        var dist = this.get("interest.distribution") || 0;
        return (dist*100).toFixed(1);
    }),

    distributionBarStyle: Ember.computed("interest.distribution", function () {
        var dist = this.get("interest.distribution") || 0;
        return ["width:",dist*100,"%;"].join("").htmlSafe();
    }),

    changePercentage: Ember.computed("interest", function() {
       var pct = (this.get("interest.documentsGrowthPct") || 0) * 100;
       return pct.toFixed(0);
    }),

    sentimentPercentage: Ember.computed("interest", function () {
        let pct = (this.get("interest.positivity") || 0) * 100.0;
        return pct.toFixed(0);
    }),

    sentimentWidthStyleSafe: Ember.computed("sentimentPercentage", function() {
        let percentage = this.sentimentPercentage || 50;
        return new Ember.String.htmlSafe(["width:", percentage, "%;"].join(""));
    }),

    sentimentStyleSafe: Ember.computed("interest", function() {
        let sentiment = this.get("interest.positivity") || 0.5,
            colorValues = ChartColors.sentimentZeroToOneGradientD3();
        let style = ["color:", colorValues(sentiment), ";"].join("");
        return new Ember.String.htmlSafe(style);
    }),

    click(){
        if(this.canDrilldown){
            this.showDrilldown(this.get("interest.name"));
        }
    },

    actions: {
        removeInterest(){
            this.removeInterest(this.get("interest.name"));
        }
    }
});
