import Ember from "ember";

var GeographyOptions = [
        Ember.Object.create({
            title: "Countries",
            value: "countries",
        }), Ember.Object.create({
            title: "US States",
            value: "usStates",
        })
    ];

export default GeographyOptions;
