import Ember from "ember";
import { computed, action } from '@ember/object';
import SVGInnerWrapper from "infegy-frontend/components/charting/svg/svg-inner-wrapper/component";
import d3 from "d3";
import { MouseInteractionWrapper } from "infegy-frontend/components/charting/mouse-interaction-mixin/component";

export default class extends MouseInteractionWrapper(SVGInnerWrapper) {
    classNameBindings = ["field.colorClass", "isFaded:trend-chart-field-faded", "isHighlighted:dark", "isHighlighted:trend-chart-field-highlighted"];

    eventData = null;
    groupByField = "timestamp";
    interactionInfo = null;

    maxIndicatorsPerPoint = 0.3;
    totalPoints = 0;
    showAllEvents = false;

    minX = null;
    maxX = null;

    typeNames = ["stories"];

    @computed("eventData", "groupByField", "minX", "maxX")
    get rawEventTimeline() {
        var eventData = this.eventData,
            groupByField = this.groupByField || "timestamp",
            minX = this.minX,
            maxX = this.maxX;

        if (!eventData || Ember.isEmpty(minX) || Ember.isEmpty(maxX) || !groupByField) {
            return [];
        }

        var eventMap = this.typeNames.reduce(function(memo, typeName) {
            var data = eventData.get(typeName);
            (data || []).filterBy(groupByField).forEach(function(dataRow) {
                if (typeName === "events") {
                    Ember.set(dataRow, "score", Ember.get(dataRow, "change"));
                }

                var groupValue = dataRow[groupByField] || 0,
                    rowInfo = memo.map[groupValue],
                    score = Ember.get(dataRow, "score");
                if (!rowInfo) {
                    rowInfo = {
                        groupValue: groupValue,
                        xPct: ((groupValue - minX) / (maxX - minX)) * 100.0,
                        totalScore: 0, itemCount: 0
                    };
                    memo.map[groupValue] = rowInfo;
                    memo.values.push(groupValue);
                }
                rowInfo.totalScore += score;
                rowInfo.itemCount += 1;
                var eventInfo = rowInfo[typeName];
                if (!eventInfo) {
                    eventInfo = { count: 0, totalScore: 0, rows: [] };
                    memo.map[groupValue][typeName] = eventInfo;
                }
                eventInfo.rows.push(dataRow);
                eventInfo.count += 1;
                eventInfo.totalScore += score;
            });
            return memo;
        }, {map: {}, values: []});
        return eventMap.values.sort().map(function(value) {
            return eventMap.map[value];
        });
    }

    @computed("rawEventTimeline", "rawEventTimeline.[]")
    get eventMetaData(){
            var eventTimeline = this.rawEventTimeline || [],
                typeNames = this.typeNames,
                eventMetaData = eventTimeline.reduce(function(memo, row) {
                    typeNames.filter(typeName => !!row[typeName]).forEach(function(typeName) {
                        var typeMemo = memo[typeName];
                        typeMemo.maxScore = Math.max(typeMemo.maxScore, row[typeName].score || 0);
                        typeMemo.maxCount = Math.max(typeMemo.maxCount, row[typeName].count || 0);
                    });
                    memo.maxTotalCount = Math.max(memo.maxTotalCount, row.itemCount || 0);
                    memo.maxTotalScore = Math.max(memo.maxTotalScore, row.totalScore || 0);
                    return memo;
                }, {
                    maxTotalScore: 0, maxTotalCount: 0,
                    events: {maxScore: 0, maxCount: 0},
                    topics: {maxScore: 0, maxCount: 0},
                    stories: {maxScore: 0, maxCount: 0}
                });
            return eventMetaData;
    }

    @computed("eventMetaData", "rawEventTimeline")
    get eventTimeline() {
        var eventTimeline = this.rawEventTimeline || [],
            eventMetaData = this.eventMetaData,
            typeNames = this.typeNames;

        var colorScale = d3.scaleLinear()
            .domain([0, 66, 100])
            .range(["#eef0f8", "#9fd5f0", "#4fa4d0"]);

        var results = eventMetaData && eventTimeline.map(function(row) {
            typeNames.forEach(function(typeName) {
                var typeInfo = row[typeName];
                if (typeInfo) {
                    typeInfo.averageScore = typeInfo.totalScore / typeInfo.count;
                    typeInfo.normalizedScore = typeInfo.averageScore / (eventMetaData[typeName].maxScore || 1);
                    typeInfo.normalizedCount = typeInfo.itemCount / (eventMetaData[typeName].maxCount || 1);
                    row.totalScore += typeInfo.normalizedScore;
                }
            });
            row.averageScore = row.totalScore / typeNames.length;
            row.normalizedScore = row.totalScore / eventMetaData.maxTotalScore;
            row.normalizedCount = row.itemCount / eventMetaData.maxTotalCount;
            row.aggregateScore = row.normalizedScore * 0.30 + row.normalizedCount * 0.70;
            row.color = colorScale(row.aggregateScore * 100.0);
            row.indicatorSize = row.aggregateScore * 15 + 5;
            row.indicatorSize = row.indicatorSize > 0 ? row.indicatorSize : 0.1;
            row.style = [
                    "stroke:" + row.color + ";",
                    "fill:" + row.color + ";",
                    "stroke-width:" + Math.ceil(row.aggregateScore * 3) + ";"
                ].join("").htmlSafe();
            return row;
        });
        return results;
    }

    @computed("maxIndicatorsPerPoint", "showAllEvents", "eventTimeline", "eventTimeline.[]", "totalPoints")
    get topEventsTimeline() {
        var eventTimeline = this.eventTimeline;
        if (this.showAllEvents) {
            return eventTimeline;
        }
        var maxIndicatorsPerPoint = this.maxIndicatorsPerPoint,
            totalPoints = this.totalPoints;
        return (eventTimeline || [])
                    .sort((a, b) => b.aggregateScore - a.aggregateScore)
                    .slice(0, Math.ceil(maxIndicatorsPerPoint * totalPoints));
    }

    @computed("topEventsTimeline", "topEventsTimeline.[]", "interactionInfo.brushStartXValue", "interactionInfo.brushEndXValue", "interactionInfo.isBrushed")
    get brushedEventTimeline() {
        var eventTimeline = this.topEventsTimeline,
            brushStart = this.get("interactionInfo.brushStartXValue"),
            brushEnd = this.get("interactionInfo.brushEndXValue");
        if (!this.get("interactionInfo.isBrushed")) {
            return eventTimeline;
        }
        return eventTimeline.filter(row => (row.groupValue <= brushEnd &&
                    row.groupValue >= brushStart));
    }

    @Ember.computed.or("interactionInfo.isSelected", "interactionInfo.isHovered") selectedOrHovered;

    onMouseEnter(event) {
        var lastInteractedEvent = this.lastInteractedEvent,
            interactedEvent = this.interactedEvent;
        if (interactedEvent !== lastInteractedEvent) {
            this.set("lastInteractedEvent", interactedEvent);
            this.eventHovered(!!interactedEvent);
        }
    }

    onMouseLeave(event) {
        var lastInteractedEvent = this.lastInteractedEvent;
        if (lastInteractedEvent) {
            this.set("lastInteractedEvent", null);
            this.eventHovered(false);
        }
    }

    click(event) {
        this.eventClicked(true);
    }

    lastInteractedEvent = null;
    @computed("interactionInfo.isHovered", "interactionInfo.isSelected", "interactionInfo.hoveredXValue", "interactionInfo.selectedXValue")
    get interactedEvent() {
        var brushedEventTimeline = this.brushedEventTimeline || [],
            isHovered = this.get("interactionInfo.isHovered"),
            isSelected = this.get("interactionInfo.isSelected"),
            hoveredXValue = this.get("interactionInfo.hoveredXValue"),
            selectedXValue = this.get("interactionInfo.selectedXValue"),
            xValue = isHovered ? hoveredXValue : selectedXValue,
            selectedRow;
        if (isSelected || isHovered) {
            selectedRow = brushedEventTimeline.find(row => {
                return row.groupValue === xValue;
            });
        }
        return selectedRow;
    }

    eventClicked = () => {};
    eventHovered = () => {};
    @action
    eventClicked(state) {
        this.eventClicked();
    }
}
