import Ember from "ember";

var validateNumber = function(value, options) {
    options = options || {};
    if (Ember.isEmpty(value)) {
        if (options.required || options.default) {
            return Number(options.default || 0);
        }
        return value;
    }

    var validated = Number(value) || 0;
    if (!Ember.isEmpty(options.max) && validated > options.max) {
        validated = options.max;
    }
    if (!Ember.isEmpty(options.min) && validated < options.min) {
        validated = options.min;
    }
    if (options.integer) {
        validated = Math.round(validated);
    }
    return validated;
};

export default {
    Number: function (options) {
        var _options = Object.assign({
            default: null,
            required: true,
            integer: false,
            min: null,
            max: null
        }, options);
        return Ember.computed({
            get: function(key) {
                var data = this._data,
                    value = data[key],
                    validated = validateNumber(value, _options);

                if (validated !== value) {
                    data[key] = validated;
                    this.set("isDirty", true);
                }

                return validated;
            },
            set: function(key, value) {
                var data = this._data,
                    existingValue = data[key],
                    validated = validateNumber(value, _options);

                if (validated !== existingValue) {
                    data[key] = validated;
                    this.set("isDirty", true);
                }

                if (Ember.isEmpty(data[key])) {
                    delete data[key];
                }

                return validated;
            }
        });
    },
    Int: function (options) {
        var _options = Object.assign({
            default: null
        }, options);
        return Ember.computed({
            get: function(key) {
                var data = this._data;
                return data[key];
            },
            set: function(key, value) {
                var data = this._data;
                if (value === null || value === undefined) {
                    value = _options.default;
                }

                if (value === data[key]) {
                    return value;
                }
                if (value === null || value === undefined) {
                    delete data[key];
                } else {
                    data[key] = value;
                }
                this.set("isDirty", true);
                return value;
            }
        });
    },
    String: function (options) {
        var _options = Object.assign({
            default: null
        }, options);
        return Ember.computed({
            get: function(key) {
                var data = this._data,
                    value = data[key];
                if (!value && _options.default) {
                    value = _options.default;
                    data[key] = value;
                }
                return data[key];
            },
            set: function(key, value) {
                var data = this._data;
                if (!value && _options.default) {
                    value = _options.default;
                }
                if (value === data[key]) {
                    return value;
                }
                if (!value) {
                    delete data[key];
                } else {
                    data[key] = value;
                }
                this.set("isDirty", true);
                return value;
            }
        });
    },
    Enum: function(options) {
        var _options = Object.assign({
            default: null
        }, options);
        if (Ember.isNone(_options.values) || Ember.isEmpty(_options.values)) {
            console.warn("Creating an Enum property with no values");
        }
        return Ember.computed({
            get(key) {
                var data = this._data,
                    value = data[key];
                if (!value && _options.default) {
                    value = _options.default;
                    data[key] = value;
                }
                return data[key];
            },
            set(key, value) {
                var data = this._data;
                if (!value && _options.default) {
                    value = _options.default;
                }

                if (value === data[key]) {
                    return value;
                } else if (!value) {
                    delete data[key];
                } else {
                    if(options.values.indexOf(value) > -1){
                        data[key] = value;
                    } else {
                        console.warn(`Attempted to set ${key} with invalid value ${value}`);
                        return data[key];
                    }
                }
                this.set("isDirty", true);
                return value;
            }
        });
    },
    Boolean: function (options) {
        var _options = Object.assign({
            serializeAsInt: false,
            falseIsNull: false,
            default: null
        }, options);
        return Ember.computed({
            get: function(key) {
                var data = this._data,
                    value = data[key];
                if (value === null || value === undefined) {
                    value = _options.default;
                }
                if (!value && _options.falseIsNull) {
                    return null;
                }
                if (_options.serializeAsInt) {
                    return value ? 1 : 0;
                }
                return value;
            },
            set: function(key, value) {
                var data = this._data;
                if (value === null || value === undefined) {
                    value = _options.default;
                }

                value = !!value;
                if (_options.serializeAsInt) {
                    value = value ? 1 : 0;
                }
                if (_options.falseIsNull && !value) {
                    value = null;
                }

                if (value === data[key]) {
                    return value;
                }
                if (value === null || value === undefined) {
                    delete data[key];
                } else {
                    data[key] = value;
                }
                this.set("isDirty", true);
                return value;
            }
        });
    },
};
