import JsonStore from 'infegy-frontend/json-store/model';
import Prop from 'infegy-frontend/json-store/properties/model';

export default JsonStore.extend({
    trendsActiveAPI: Prop.Attr(),
    trendsActiveField: Prop.Attr(),
    volumeHoverField: Prop.Attr(),

    usStatesActiveField: Prop.Attr(),
    countriesActiveField: Prop.Attr(),
    usStatesActiveTrend: Prop.Attr(),
    countriesActiveTrend: Prop.Attr(),
});
