import Ember from "ember";

export default Ember.Component.extend({
    classNames: ["icon-view-selector", "no-print"],

    selectedEntitiesView: null,
    hasMoreThanOneQuery: false,

    isMultiCloud: Ember.computed.equal("selectedEntitiesView", "multi-cloud"),
    isLinked: Ember.computed.equal("selectedEntitiesView", "linked"),
    isCloud: Ember.computed.equal("selectedEntitiesView", "cloud"),
    isNode: Ember.computed.equal("selectedEntitiesView", "node"),
    isTable: Ember.computed.equal("selectedEntitiesView", "table"),

    changed: () => {},
    actions: {
        entitiesViewSelected: function (viewType) {
            this.changed(viewType);
        }
    }
});
