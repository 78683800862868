export default [{
    title: "Top Topics",
    value: "topics"
}, {
    title: "Positive Topics",
    value: "positiveTopics"
}, {
    title: "Negative Topics",
    value: "negativeTopics"
}, {
    title: "Hashtags",
    value: "hashtags",
    id: "engagements"
}, {
    title: "Source Bio",
    value: "sourceBioTopics",
    id: "sourceBios"
}, {
    title: "Mentions",
    value: "mentions",
    id: "engagements"
}, {
    title: "Emoji",
    value: "emoji"
}, {
   title: "Links",
   value: "links"
}, {
    title: "Linked Domains",
    value: "domains"
}];
