import Ember from "ember";
import { action, computed } from '@ember/object';
import Component from '@ember/component';

export default class SentimentCombinedTableSentimentRow extends Component {
    tagName = "tr";
    classNameBindings = [":data-table-row", "colorClass"];

    colorClass = "atlas-sentiment-positive";
    sentimentBarClass = "data-table-sentiment-bar";
    color = null;
    fieldName = "positive";
    sentimentFieldUnits = "Posts";
    activeFields = [];

    includeNeutral = false;

    dataSeries = null;

    hoveredIndex = null;

    @computed("activeFields", "fieldName")
    get activeField() {
        return Ember.get(this.activeFields, this.fieldName);
    }

    @computed("activeField", "dataSeries")
    get percentage() {
        let ds = this.dataSeries.data;
        return ds && this.activeField && ds.mapBy(this.activeField);
    };

    @computed("activeFields", "dataSeries", "hoveredIndex")
    get counts() {
        let data = this.dataSeries.data || [],
            stats = this.dataSeries.stats,
            result = {};

        if (!Ember.isEmpty(this.hoveredIndex) && data.hasOwnProperty(this.hoveredIndex)) {
            let row = data[this.hoveredIndex];
            result.positive = Ember.get(row, this.activeFields.positive);
            result.negative = Ember.get(row, this.activeFields.negative);
            result.neutral = Ember.get(row, this.activeFields.neutral);
        } else {
            result.positive = stats.get(`${this.activeFields.positive}.sum`);
            result.negative = stats.get(`${this.activeFields.negative}.sum`);
            result.neutral = stats.get(`${this.activeFields.neutral}.sum`);
        }
        return result;
    }

    @computed("includeNeutral", "counts")
    get percentages() {
        let total = this.counts.positive + this.counts.negative,
            percentages = {neutral: 0};
        if (this.includeNeutral) {
            total += this.counts.neutral;
            percentages.neutral = this.counts.neutral / total;
        }
        percentages.positive = this.counts.positive / total;
        percentages.negative = this.counts.negative / total;
        return percentages;
    }

    @computed("activeField", "fieldName", "counts", "percentages")
    get values() {
        let data = this.dataSeries.data || [];
        return {
            timeline: data.mapBy(this.activeField),
            total: Ember.get(this.counts, this.fieldName),
            percentage: Ember.get(this.percentages, this.fieldName)
        };
    };

    @computed("values")
    get sentimentPercentage() {
        return ((this.values.percentage || 0) * 100.0).toFixed(0);
    };

    @computed("sentimentPercentage")
    get sentimentWidthStyleSafe() {
        let percentage = this.get("sentimentPercentage") || 50;
        return new Ember.String.htmlSafe(["width:", percentage, "%;"].join(""));
    };

    hoveredIndexChanged() { /* action placeholder */ };
    selected() { /* action placeholder */ };

    click() {
        this.selected(this.fieldName);
    };

    @action
    wasHovered(index) {
        this.hoveredIndexChanged(index);
    };
};
