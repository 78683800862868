var Gender = [{
        "label": "Male",
        "value": "m",
        "description": "Posts from sources with a stated gender of male or with a traditionally male name."
    },{
        "label": "Female",
        "value": "f",
        "description": "Posts from sources with a stated gender of female or with a traditionally female name."
    }];

export default Gender;
