import Ember from 'ember';
import DateFormat from "infegy-frontend/helpers/date-format";
import QueryQrouping from "infegy-frontend/models/queries/query_data_grouping";
import DateProcessing from "infegy-frontend/utils/date-processing";

export function formatDate(params, namedArgs) {
    var timeField = namedArgs.precision,
        timestampFields = QueryQrouping.timestampFields,
        result = params[0] || 0;
    if (timestampFields.includes(timeField)) {
        return DateFormat.compute(params, namedArgs);
    } else if (timeField === "_dayOfWeek") {
        return DateProcessing.constants.numberToWeekday[result];
    } else if (timeField === "hour") {
        return result + ":00";
    }
    return "" + result;
}

export default Ember.Helper.helper(formatDate);
