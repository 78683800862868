import Ember from 'ember';
import Countries from "infegy-frontend/static_data/geography/countries_json";

export function iso2Name(params, namedArgs) {
  var iso2 = params[0];
  var country = Countries.findBy("code",iso2);
  
  if(Ember.isEmpty(country) || Ember.isEmpty(country.title)) {
      return "";
  }
  
  return country.title;
}

export default Ember.Helper.helper(iso2Name);