import Ember from "ember";
import DemographicsFields from "infegy-frontend/static_data/demographics/demographics_fields";

export default Ember.Component.extend({
    classNames: ["us-demographics-stats"],

    queries: null,
    query: Ember.computed.alias("queries.firstObject"),

    user: null,

    demographicsFieldOptions: DemographicsFields,
    selectedField: "medianHouseholdIncome",
    selectedFieldTitle: Ember.computed("selectedField", function () {
        var selectedField = this.selectedField;
        var selectedFieldObject = DemographicsFields.findBy("value", selectedField);

        return selectedFieldObject && selectedFieldObject["label"];
    }),

    totalSampleSize: Ember.computed("queries", "queries.@each.updated", function () {
        var queries = this.queries;
        return queries.reduce((pv, query) => {
            return pv + query.get("demographicsMeta.data.sampleSize");
        }, 0);
    }),

    combinedMetaData: Ember.computed("queries", "queries.@each.updated", "totalSampleSize", function (key) {
        var queries = this.queries,
            totalSampleSize = this.totalSampleSize;

        var demographicsData = DemographicsFields.map((fieldData) => {
            var isKeyEdu = fieldData.value === "educationAnyCollege";

            var mean = queries.reduce((prev, query) => {
                var demographicsMeta = query.get("demographicsMeta.data"),
                    dbMeanValue = demographicsMeta[fieldData.value];

                if (!isKeyEdu) {
                    return prev += dbMeanValue;
                } else if (isKeyEdu) {
                    return prev += (dbMeanValue / 100.0);
                }
            }, 0) / queries.length;

            var difference = queries.reduce((prev, query) => {
                var demographicsMeta = query.get("demographicsMeta.data"),
                    sampleSize = demographicsMeta["sampleSize"],
                    dbMeanValue = demographicsMeta[fieldData.value],
                    dbMedianValue = demographicsMeta[`db${fieldData.value.capitalize()}`],
                    differenceValue;

                if (!isKeyEdu) {
                    differenceValue = dbMeanValue - dbMedianValue;
                } else if (isKeyEdu) {
                    differenceValue = dbMeanValue / dbMedianValue - 1;
                }

                return prev + (sampleSize * differenceValue / totalSampleSize);
            }, 0);

            Ember.set(fieldData, "mean", mean);
            Ember.set(fieldData, "difference", difference);

            return fieldData;
        });

        return demographicsData;
    }),

    selectedDemographicData: Ember.computed("selectedDemographicsField", "combinedMetaData", function () {
        var metaData = this.combinedMetaData,
            field = this.selectedDemographicsField;

        return Ember.get(metaData, field);
    }),

    comparisonData: Ember.computed("queries", "queries.@each.updated", "selectedField", "totalSampleSize", function () {
        var queries = this.queries,
            field = this.selectedField,
            totalSampleSize = this.totalSampleSize,
            mean = queries.reduce((pv, query) => {
                var sampleSize = query.get("demographicsMeta.data.sampleSize"),
                    fieldValue = query.get(`demographicsMeta.data.${field}`);

                return pv + (sampleSize * fieldValue / totalSampleSize);
            }, 0);

        let demographicsData = queries.map((query, idx) => {
            var val = query.get(`demographicsMeta.data.${field}`);
            return {
                query: query,
                difference: val - mean,
                percentage: (val - mean) / mean
            };
        });
        return demographicsData;
    }),

    hasUSData: Ember.computed("query.queryInfo.geography.isUSExcluded", {
        get: function (key) {
            var queries = this.queries;
            return !queries.some((query) => {
                return query.get("queryInfo.geography.isUSExcluded") || (query.get("demographicsMeta.data.sampleSize") == 0)
            });
        },
    }),

    actions: {
        selectedFieldChanged(activeField) {
            this.set("selectedField", activeField);
        }
    }
});
