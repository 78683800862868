var AtlasMath = {
    isNumber: function(n, fallback) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    },
    toNumber: function(n, fallback) {
        n = parseFloat(n);
        if (!isNaN(n) && isFinite(n)) {
            return n;
        } else if (AtlasMath.isNumber(fallback)) {
            return AtlasMath.toNumber(fallback);
        }
        return 0;
    },
    toInt: function(n, fallback) {
        return Math.floor(AtlasMath.toNumber(n, fallback));
    },
    linearInterpolatePercent: function(min, max, pct) {
        min = AtlasMath.toNumber(min);
        max = AtlasMath.toNumber(max);
        pct = AtlasMath.toNumber(pct);

        return ((max - min) * pct) + min;
    },
    indexedLetters: function(index) {
        return String.fromCharCode('A'.charCodeAt() + AtlasMath.toInt(index));
    }
};

export default AtlasMath;