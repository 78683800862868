import Ember from "ember";
import Component from '@ember/component';
import { computed } from '@ember/object';
import FieldSelectorBase from "infegy-frontend/components/field-selector/parts/fields/row-value-field-base/component";
import layout from "infegy-frontend/components/field-selector/parts/fields/row-value-selector-layout/template";

let rowProperties = {
    "Age": [{
        rowProperty: "count",
        rowPropertyTitle: "Posts",
        fieldTypes: ["scalar"]
    },{
        rowProperty: "countPortion",
        rowPropertyTitle: "Posts Portion",
        fixedPortionWhole: 1.0,
        isPercentageType: true,
        fieldTypes: ["scalar", "portion"]
    },{
        rowProperty: "probability",
        rowPropertyTitle: "Probability",
        fixedPortionWhole: 1.0,
        isPercentageType: true,
        fieldTypes: ["scalar", "portion"]
    },{
        rowProperty: "vsExpected",
        rowPropertyTitle: "Vs Expected",
        fixedPortionWhole: 1.0,
        isPercentageType: true,
        fieldTypes: ["scalar", "portion"]
    }]
};

export default class FieldSelectorAge extends FieldSelectorBase {
    layout = layout;
    apiName = "ages";

    rowLookupProperty = "id";

    allAvailableRowFields = rowProperties;

    @computed("allQueries", "allQueries.[]", "allQueries.@each.ages", "allQueries.@each.updated")
    get availableLookupValues() {
        let allQueries = this.allQueries || [],
            ages = [];
        allQueries.forEach(query => {
            let queryAges = (query.get("ages.dataSeries.data") || []).map(age => {
                return {
                    rowLookupProperty: "id",
                    rowLookupValue: age.id,
                    rowLookupTitle: age.displayName,
                }
            });
            ages = ages.concat(queryAges || []).uniqBy("rowLookupValue");
        });
        return ages;
    };
};
