import Ember from "ember";
import {computed, action} from "@ember/object";
import JsonStore from 'infegy-frontend/json-store/model';
import Prop from 'infegy-frontend/json-store/properties/model';
import DataSeries from "infegy-frontend/models/data_series/data_series";
import classic from "ember-classic-decorator";

@classic
export default class TrendChartInteractiveField extends JsonStore {
    field = null;

    // TrendChartMeta instance from "shared/models/trends/trend-chart-meta"
    trendChartMeta = null;

    normalizeStartTimes = false;

    maxFieldRowCount = 0;
    allFieldsRowCount = 0;
    fieldStartIndex = 0;

    minFieldXValue = 0;
    maxFieldXValue = 1;

    minFieldStackingValue = 0;
    maxFieldStackingValue = 1;

    chartType = "line";

    @computed.alias("field.query.queryInfo.earliestTimestamp") earliestTimestamp;
    @computed.alias("field.query.queryInfo.latestTimestamp") latestTimestamp;

    @computed("field.data.length")
    get isSinglePoint() {
        return this.field?.data?.length === 1;
    }

    @computed("normalizeStartTimes", "minFieldXValue")
    get startX() {
        let normalize = this.normalizeStartTimes;
        return normalize ? 0 : (this.minFieldXValue || 0);
    }

    @computed("normalizeStartTimes", "maxFieldRowCount", "maxFieldXValue")
    get endX() {
        let normalize = this.normalizeStartTimes;
        return normalize ? (this.maxFieldRowCount - 1 || 1) : this.maxFieldXValue;
    }

    @computed("normalizeStartTimes", "minFieldStackingValue")
    get startStackingX() {
        let normalize = this.normalizeStartTimes;
        return normalize ? 0 : (this.minFieldStackingValue || 0);
    }

    @computed("normalizeStartTimes", "maxFieldRowCount", "maxFieldStackingValue")
    get endStackingX() {
        let normalize = this.normalizeStartTimes;
        return normalize ? (this.maxFieldRowCount - 1 || 1) : this.maxFieldStackingValue;
    }

    xValueFromPct(pct) {
        let start = this.startX,
            end = this.endX;
        return (pct * (end - start)) + start;
    }

    @computed("normalizeStartTimes", "field.data", "field.xAxisField",
        "field.fieldName", "minX",  "endX", "startX", "field.axisMaxY", "chartType",
        "allFieldsRowCount", "fieldStartIndex")
    get chartData() {
        let normalize = this.normalizeStartTimes,
            isBars = this.chartType === "bars",
            data = this.field.data || [],
            minX = this.field.minX,
            endX = this.endX,
            startX = this.startX,
            xProperty = this.field.xAxisField,
            stackingProperty = this.field.stackingField,
            yProperty = this.field.fieldName,
            axisMaxY = this.field.axisMaxY || 1,
            allFieldsRowCount = this.allFieldsRowCount,
            fieldStartIndex = this.fieldStartIndex;

        let chartData = data.sort((a, b) => {
            let xa = Ember.get(a, xProperty),
                xb = Ember.get(b, xProperty);
            return xa - xb;
        }).map((row, index) => {
            let x = (normalize ? index : Ember.get(row, xProperty)) || 0,
                y = Ember.get(row, yProperty) || 0,
                xPct = (x - startX) / (endX - startX);
            if (isBars) {
                let barWidth = 1 / allFieldsRowCount;
                xPct = ((index + fieldStartIndex) * barWidth) + (barWidth * 0.5);
            }
            return {
                fullRow: row,
                field: this,
                stackingValue: normalize ? index : (Ember.get(row, stackingProperty) || x),
                barsIndex: index + fieldStartIndex,
                x: x,
                xPct: xPct,
                y: y,
                yPct: y / axisMaxY,
            }
        }).sort((row1, row2) => {
            return row1.x - row2.x;
        });
        return chartData;
    }

    findPctFromXValue(xVal) {
        let endX = this.endX,
            startX = this.startX;
        return (x - startX) / (endX - startX);
    }

    findRowFromXPct(xPct) {
        let isBars = this.chartType === "bars",
            rowInfo = (this.chartData || []).reduce((memo, row, idx) => {
                let distance = Math.abs(xPct - row.xPct);
                if (memo.closestPct === null || distance < memo.distance) {
                    memo.closestPct = row.xPct;
                    memo.distance = distance;
                    memo.closestDataRow = row;
                    memo.closestIndex = idx;
                    memo.closestY = row.fullRow[this.field.fieldName];
                    memo.closestYPct = memo.closestY / this.field.chartMaxY;
                    memo.field = this;
                }
                return memo;
            }, {
                closestPct: null,
                distance: null,
                closestDataRow: null,
                closestIndex: null,
                closestY: null,
                closestYPct: null,
                field: null,
                isInBounds: (xPct <= this.maxXPct && xPct >= this.minXPct)
            });

        return rowInfo;
    }

    findRowsFromXPctRange(xPctStart, xPctEnd) {
        let rowInfo = (this.chartData || []).filter(row => {
            return row.xPct >= xPctStart && row.xPct <= xPctEnd;
        });
        return rowInfo;
    }

    yPctToValue(yPct) {
        let maxY = this.field.chartMaxY;
        return yPct * maxY;
    }

    findRowFromXValue(xVal) {
        return this.findRowFromXPct(this.findPctFromXValue(xVal));
    }

    @computed("chartData")
    get maxXPct() {
        let cd = this.chartData,
            max = 1.0;
        if (cd && cd.length > 0) {
            max = cd[cd.length - 1]?.xPct;
        }
        return max;
    }

    @computed("chartData")
    get minXPct() {
        let cd = this.chartData,
            min = 1.0;
        if (cd && cd.length) {
            min = cd[0]?.xPct;
        }
        return min;
    }

    @computed("trendChartMeta.brushStartXPct", "trendChartMeta.brushEndXPct", "field.dataSeries")
    get brushedData() {
        if (!this.trendChartMeta.isBrushed) {
            return;
        }

        let startXPct = this.trendChartMeta.brushStartXPct,
            endXPct = this.trendChartMeta.brushEndXPct;
        return this.findRowsFromXPctRange(startXPct, endXPct);
    }

    @computed("brushedData", "trendChartMeta.brushStartXPct", "trendChartMeta.brushEndXPct", "field.dataSeries")
    get brushedDataSeries() {
        if (!this.brushedData) {
            return;
        }

        let ds = this.field.dataSeries,
            chartRows = this.brushedData;

        if (!chartRows || !chartRows.length) {
            return null;
        }

        let brushedData = chartRows.mapBy("fullRow");

        let result = {
            chartRows: chartRows,
            dataSeries: DataSeries.load(brushedData, ds.description),
        }

        return result;
    }

    @computed("brushedDataSeries", "field.fieldName")
    get brushedField() {
        let brushedDS = this.brushedDataSeries;
        if (brushedDS) {
            let newField = this.field.copy();
            newField.set("dataSeries", brushedDS.dataSeries);
            return newField;
        }
    }

    @computed("brushedField")
    get brushedTrendField() {
        if (this.brushedField) {
            let newInteractedField = TrendChartInteractiveField.create();
            newInteractedField.set("field", this.brushedField);
            return newInteractedField;
        }
    }

    @computed("brushedDataSeries", "field.fieldName")
    get brushedSummaryValue() {
        if (!this.brushedDataSeries || !this.field.fieldName) {
            return;
        }
        let stats = Ember.get(this.brushedDataSeries.stats, this.field.fieldName);
        if (this.field.isAveragedType) {
            stats.average;
        }
        return stats.sum;
    }

    @computed("trendChartMeta.isHovered", "trendChartMeta.hoveredXPct")
    get hoveredInfo() {
        if (!this.trendChartMeta.isHovered) {
            return;
        }
        let rowInfo = this.findRowFromXPct(this.trendChartMeta.hoveredXPct);
        if (rowInfo) {
            return {
                xValue: rowInfo?.closestDataRow?.x,
                yValue: rowInfo?.closestDataRow?.y,
                rowInfo: rowInfo
            }
        }
    }

    @computed("trendChartMeta.isSelected", "trendChartMeta.selectedXPct")
    get selectedInfo() {
        if (!this.trendChartMeta.isSelected) {
            return;
        }
        let rowInfo = this.findRowFromXPct(this.trendChartMeta.selectedXPct);
        if (rowInfo) {
            return {
                xValue: rowInfo?.closestDataRow?.x,
                yValue: rowInfo?.closestDataRow?.y,
                rowInfo: rowInfo
            }
        }
    }

    @computed("trendChartMeta", "trendChartMeta.isSelected", "trendChartMeta.isBrushed",
        "trendChartMeta.brushStartXPct", "trendChartMeta.brushEndXPct",
        "trendChartMeta.grouping", "trendChartMeta.mouseIsDown", "brushedDataSeries")
    get selectedDateRange() {
        let meta = this.trendChartMeta,
            brushedDS = this.brushedDataSeries?.dataSeries,
            grouping = meta?.grouping || "day",
            millisInDay = 1000 * 60 * 60 * 24,
            start, end;
        if ((!meta?.isBrushed && !meta?.isSelected) || meta?.mouseIsDown) {
            return;
        }

        if (meta.isBrushed && brushedDS) {
            start = brushedDS.stats.timestamp.min;
            end = brushedDS.stats.timestamp.max;
        }
        if (meta.isSelected && this.selectedInfo) {
            start = this.selectedInfo.xValue;
            end = this.selectedInfo.xValue;
        }

        let groupingAdjustment = 0;
        if (grouping === "day") {
            groupingAdjustment = millisInDay;
        } else if (grouping === "week") {
            groupingAdjustment = millisInDay * 7;
        } else if (grouping === "month") {
            groupingAdjustment = millisInDay * 30;
        } else if (grouping === "year") {
            groupingAdjustment = millisInDay * 365;
        } else {
            console.debug("unknown grouping");
        }
        end += groupingAdjustment;

        return start && end && {
            start: start,
            end: end
        };
    }

}
