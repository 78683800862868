import JsonStore from 'infegy-frontend/json-store/model';
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import CollectionStore from "infegy-frontend/json-store/collection/model";
import AudienceSegment from "infegy-frontend/models/users/audience_segment";

export default JsonStore.extend({
    all: [],
    list: [],

    fetchAllAudienceSegments: function() {
        var dfd = AtlasAuth.Get("api/v3/watchlist/list.json");

        dfd.catch((error) => {
            console.error("Failed to load user audience segments.", error);
        });

        dfd.then((data) => {
            if ("output" in data) {
                let audienceSegments = CollectionStore.create();
                audienceSegments.set("itemClass", AudienceSegment);
                audienceSegments.loadJSON(data.output);
                this.set("all", audienceSegments.content);
            }
        });
    },

    loadUserAudienceSegments: function(userId) {
        var dfd = AtlasAuth.Get({
            url: "api/v3/watchlist/list.json",
            data: {
                user_id: userId
            }
        });

        dfd.then((data) => {
            if ('output' in data) {
                let audienceSegments = CollectionStore.create();
                audienceSegments.set("itemClass", AudienceSegment);
                audienceSegments.loadJSON(data.output);
                this.set("list", audienceSegments.content);
            }
        });

        dfd.catch((error) => {
            console.error("Failed to fetch audience segments", error);
        });
    },
});
