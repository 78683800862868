import Ember from "ember";

export default Ember.Component.extend({
    tagName: "pre",
    classNames: ["json-preview"],

    type: "json",

    jsonData: {},

    showFullOutput: false,

    indentString: function(indentLevel) {
        indentLevel = indentLevel || 0;

        var outputString = "";
        while(indentLevel--) {
            outputString += "<span class='json-preview-indent'> </span>";
        }
        return outputString;
    },

    formatBasicTypes: function(value, eltype) {
        var outputString = "";
        if (eltype === "number") {
            outputString += "<span class='json-preview-number'>" + value + "</span>";
        } else if (eltype === "string") {
            outputString += "<span class='json-preview-string'>";
            value = JSON.stringify(value);
            outputString += `${value.replace(/[^a-zA-Z0-9]/g, ch => ["&#", ch.charCodeAt(0), ";"].join(""))}`;
            outputString += "</span>";
        } else if (eltype === "boolean") {
            outputString += "<span class='json-preview-boolean'>" + value + "</span>";
        } else if (Ember.isEmpty(value)) {
            outputString += "<span class='json-preview-null'>null</span>";
        }
        return outputString;
    },

    jsonToDisplayJSON: function(jsonData, keyName, indentLevel) {
        indentLevel = indentLevel || 0;
        var outputString = "";

        if (keyName) {
            outputString += this.indentString(indentLevel);
            outputString += `<span class='json-preview-key'>"${keyName}"</span>: `;
        }

        var eltype = typeof jsonData;
        if (Ember.isArray(jsonData)) {
            var arrLen = jsonData.length;
            outputString += "[";
            for (var i = 0; i < arrLen; i++) {
                outputString += this.jsonToDisplayJSON(jsonData[i], false, indentLevel+1);
                if (i !== arrLen -1) {
                    outputString += ",";
                }
            }
            outputString += "]";
        } else if (eltype === "object") {
            outputString += "{";
            for (var key in jsonData) {
                if (jsonData.hasOwnProperty(key)) {
                    var subStr = this.jsonToDisplayJSON(jsonData[key], key, indentLevel+1);
                    if (subStr[0] === "{") {
                        outputString += subStr;
                    } else {
                        outputString += "\n" + subStr;
                    }
                    outputString += ",";
                }
            }
            outputString = outputString.slice(0, -1);
            outputString += "\n" + this.indentString(indentLevel) + "}";
        } else {
            outputString += this.formatBasicTypes(jsonData, eltype);
        }
        return outputString;
    },

    jsonToDisplayXML: function(jsonData, keyName, indentLevel) {
        indentLevel = indentLevel || 0;
        var outputString = "",
            arrayItemStart = "",
            arrayItemEnd = "",
            arrayItemKey = keyName;

        if (keyName) {
            outputString += this.indentString(indentLevel) + "<span class='xml-preview-key'>&lt;" + keyName + "&gt;</span>";
        }

        if (arrayItemKey) {
            if (arrayItemKey[arrayItemKey.length - 1] === "s") {
                arrayItemKey = arrayItemKey.slice(0,-1);
            }
            arrayItemStart = "<span class='xml-preview-key'>&lt;" + arrayItemKey + "&gt;</span>";
            arrayItemEnd = "<span class='xml-preview-key'>&lt;/" + arrayItemKey + "&gt;</span>";
        }

        var eltype = typeof jsonData;
        if (Ember.isArray(jsonData)) {
            var arrLen = jsonData.length;
            for (var i = 0; i < arrLen; i++) {
                outputString += "\n" + this.indentString(indentLevel + 1) + arrayItemStart;
                outputString += this.jsonToDisplayXML(jsonData[i], false, indentLevel+1);
                outputString += arrayItemEnd;
            }
            outputString += "\n" + this.indentString(indentLevel);
        } else if (eltype === "object") {
            for(var key in jsonData) {
                if (jsonData.hasOwnProperty(key)) {
                    var subStr = this.jsonToDisplayXML(jsonData[key], key, indentLevel+1);
                    if (subStr[0] !== "{") {
                        outputString += "\n";
                    }
                    outputString += subStr;
                }
            }
            outputString += "\n" + this.indentString(indentLevel);
        } else {
            outputString += this.formatBasicTypes(jsonData, eltype);
        }
        
        if (keyName) {
            outputString += "<span class='xml-preview-key'>&lt;/" + keyName + "&gt;</span>";
        }

        return outputString;
    },

    output: Ember.computed("jsonData", "type", function () {
        var data = this.jsonData || [];

        if (Ember.isEmpty(data)) {
            return "Loading Data...";
        }

        if (this.type === "xml") {
            return this.jsonToDisplayXML(data);
        } else {
            return this.jsonToDisplayJSON(data);
        }
    }),

    splitOutput: Ember.computed("output", function () {
        return (this.output || "").split("\n");
    }),
    
    shouldTruncateOutput: Ember.computed("splitOutput", "splitOutput.length", function () {
        return this.get("splitOutput.length") > 1100;
    }),
    
    truncatedOutput: Ember.computed("shouldTruncateOutput", "showFullOutput", "output", "splitOutput", function () {
        if (this.showFullOutput || !this.shouldTruncateOutput || Ember.isEmpty(this.splitOutput)) {
            return this.output;
        }
        return this.splitOutput.slice(0,1000).join("\n");
    }),

    actions: {
        toggleTruncated: function () {
            this.toggleProperty("showFullOutput");
        }
    }
});
