import ControllerBase from "infegy-frontend/atlas/dashboard/content/base/controller";
import { inject as controller } from '@ember/controller';
import { action, computed } from '@ember/object';
import BrowserStore from "infegy-frontend/utils/browser-store";
import SingleRequestQuery from "infegy-frontend/models/queries/single_request_query";

export default class PostClustersController extends ControllerBase{
    apis = ["postClusterNodes"];
    @controller("atlas.dashboard.content") content;

    @BrowserStore.Attr("connections-node-count", { defaultValue: null }) nodeCount;

    @action
    onNodeCountChanged(count) {
        this.set("nodeCount", count);
    }
}
