import Ember from "ember";
import Component from '@ember/component';
import RowValueFieldDefinition from "infegy-frontend/models/field-definitions/row-value-scalar-field";
import FieldBase from "infegy-frontend/components/field-selector/parts/fields/field-base/component";
import { computed, action } from '@ember/object';
import { run } from '@ember/runloop';

export default class RowValueFieldBase extends FieldBase {
    fieldType = "scalar";
    isScalarSelector = true;

    makeFieldDefinition(options) {
        let fullOptions = Object.assign({
                apiName: this.apiName,
                segment: this.selectedSegment,
                subsegment: this.selectedSubsegment,
                fieldComponent: this.selectedFieldComponent
            }, options);

        return fullOptions;
    };

    completeField(field) {
        Ember.set(field, "value", [field.apiName, field.rowLookupValue, field.rowProperty].join("."));
        Ember.set(field, "title", `${field.rowLookupTitle} ${field.rowPropertyTitle}`);
    }

    @computed("apiName", "fieldType", "allAvailableRowFields", "availableLookupValues", "selectedSegment", "selectedSubsegment")
    get availableRowFields() {
        if (!this.selectedSubsegment || !this.availableLookupValues || !this.availableLookupValues[0]
                || !(this.allAvailableRowFields && this.allAvailableRowFields.hasOwnProperty(this.selectedSubsegment))) {
            return;
        }

        let firstLookupField = this.availableLookupValues[0];

        let rowProperties = this.allAvailableRowFields[this.selectedSubsegment] || [],
            filteredRowFields = rowProperties.filter(rowProperty => {
                return rowProperty && rowProperty.fieldTypes && rowProperty.fieldTypes.includes(this.fieldType);
            }).map(def => {
                let fieldDef = this.makeFieldDefinition(Object.assign({
                    color: this.get("query.queryInfo.color"),
                    fieldType: this.fieldType
                }, firstLookupField, def));

                let newField = RowValueFieldDefinition.create();
                newField.loadJSON(fieldDef);
                this.completeField(newField);
                return newField;
            });

        return filteredRowFields;
    }

    makeAvailableFields(sourceField) {
        if (!this.selectedSubsegment || !sourceField || !this.availableLookupValues) {
            return;
        }

        let baseField = this.firstSelectedField || this.availableRowFields[0];

        let availableLookupValues = this.availableLookupValues || [],
            availableFields = availableLookupValues.map(lookupValueDef => {
                let newField = sourceField.copy();
                newField.loadJSON(lookupValueDef);
                if (!newField.rowLookupTitle) {
                    newField.set("rowLookupTitle", newField.rowLookupValue);
                }
                this.completeField(newField);
                return newField;
            });

        return availableFields;
    }


    @computed("fieldType", "availableRowFields", "availableLookupValues", "selectedSubsegment", "selectedFields", "firstSelectedField")
    get availableFields() {
        if (!this.selectedSubsegment || !this.availableRowFields || !this.availableRowFields[0] ||
                !this.availableLookupValues) {
            return;
        }

        let baseField = this.firstSelectedField || this.availableRowFields[0],
            availableFields = this.makeAvailableFields(baseField);

        if (availableFields && (!this.selectedFields || this.firstSelectedField.subsegment !== this.selectedSubsegment)) {
            run.next(()=>{
                this.rowLookupValuesChanged(this.makeDefaultFieldSelection(availableFields));
            });
        }

        return availableFields;
    };

    @computed("selectedFields")
    get selectedRowFieldValues() {
        let values = (this.selectedFields || []).map(opt => {
            return (opt && opt.rowProperty) || opt;
        });
        return values && [values[0]];
    }

    @computed("selectedRowFieldValues")
    get selectedRowFieldValue() {
        if (Ember.isArray(this.selectedRowFieldValues)) {
            return this.selectedRowFieldValues.length && this.selectedRowFieldValues[0];
        }
    };

    @computed("selectedFields")
    get selectedRowLookupValues() {
        let values = (this.selectedFields || []).map(opt => {
            return (opt && opt.rowLookupValue) || opt;
        });
        if (this.queries && this.queries.length > 1) {
            return values && [values[0]];
        }
        return values;
    }

    @computed("selectedRowLookupValues")
    get selectedRowLookupValue() {
        if (Ember.isArray(this.selectedRowLookupValues)) {
            return this.selectedRowLookupValues.length && this.selectedRowLookupValues[0];
        }
    };

    @action
    rowPropertyValueChanged(selectedValue) {
        let selectedField = (this.availableRowFields || []).find(selectedField => {
            return selectedField && selectedField.rowProperty === selectedValue;
        });
        if (selectedField) {
            let availableFields = this.makeAvailableFields(selectedField),
                selection = this.makeDefaultFieldSelection(availableFields);
            this.fieldsChanged(selection);
        }
    };

    @action
    rowPropertyValuesChanged(selectedOptions) {
        this.fieldsChanged(selectedOptions)
    };

    @action
    rowLookupValueChanged(selectedValue) {
        let selectedField = (this.availableFields || []).find(selectedField => {
            return selectedField && selectedField.rowLookupValue === selectedValue;
        });
        if (selectedField) {
            this.fieldsChanged([selectedField]);
        }
    };

    @action
    rowLookupValuesChanged(selectedOptions) {
        this.fieldsChanged(selectedOptions)
    };
};
