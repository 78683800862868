import Ember from "ember";
import Controller, { inject as controller } from "@ember/controller";
import { action, computed } from "@ember/object";
import { tracked } from '@glimmer/tracking';

import SharedResources from "infegy-frontend/models/users/shared_resources";

export default class SnippetsController extends Controller {
    sharedResources = SharedResources.create();

    @tracked showSaveError = false;
    @tracked showUpdateError = false;
    
    @controller application;

    @computed.alias("application.user") user;
    
    @computed.alias("user.snippets") snippets;

    @computed.equal("snippet.id", 0) isCreating;

    @computed("isCreating", "isShared")
    get actionText () {
        if (this.isCreating) return "Create"
        else if (!this.isShared) return "Edit"
        else return "View";
    }

    @computed("snippet.title", "snippet.query")
    get isSaveDisabled() {
        const title = this.get("snippet.title");
        const query = this.get("snippet.query");
        const isDisabled = Ember.isEmpty(title) || Ember.isEmpty(query);
        return isDisabled;
    }

    @computed.alias("snippet.isShared") isShared;

    @computed("isCreating", "isShared")
    get showSharingOptions() {
        return !this.isShared && !this.isCreating;
    }

    @action
    async onClone() {
        let newSnippet = null;
        try {
            newSnippet = await this.snippet.saveCopy();
        }
        catch(error) {
            if(error.status === 403) {
                this.transitionToRoute('login');
            }

            this.showSaveError = true;
            Ember.run.later(()=>{
                this.showSaveError = false;
            }, 6000);

            return;
        }

        this.snippets.addObject(newSnippet);
        this.transitionToRoute('atlas.tools.snippets.edit', newSnippet.id);
    }

    @action
    async onSave() {
        try {
            await this.snippet.save();
        }
        catch(error) {
            this.showSaveError = true;
            Ember.run.later(()=>{
                this.showSaveError = false;
            }, 5000);
            return;
        }

        await this.snippets.getPage();
        this.transitionToRoute("atlas.tools.snippets");
    }
}