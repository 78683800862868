import Component from "@ember/component";
import { action, computed } from "@ember/object";
import { isEmpty } from "@ember/utils";

export default class extends Component {
    // Input
    sheets = [];

    ignoreErrors = false;
    isCanceling = false;
    sheetIndex = 0;
    
    @computed.equal("sheetIndex", 0) isFirstSheet;

    // Events
    cancel() {};
    // submit() {}

    @computed("sheetIndex", "sheets", "sheets.length")
    get hasNextSheet() {
        return this.sheetIndex < this.get("sheets.length") - 1;
    }

    @computed("sheetIndex", "sheets", "sheets.length")
    get isLastSheet() {
        return this.sheetIndex === this.get("sheets.length") - 1;
    }

    @computed("sheets", "sheets.length", "sheetIndex")
    get selectedSheet() {
        if (isEmpty(this.sheets)) {
            return null;
        }
        return this.sheets.objectAt(this.sheetIndex);
    }

    @action onCancel() {
        this.set("isCanceling", true);
    }

    @action onContinue() {
        this.set("isCanceling", false);
    }

    @action onConfirmCancel() {
        this.cancel();
    }

    @action async onSubmit() {
        this.submit();
    }

    @action onPreviousSheet() {
        this.decrementProperty("sheetIndex");
    }

    @action onNextSheet() {
        this.incrementProperty("sheetIndex");
    }

    @action onIgnoreErrorsChanged(isChecked) {
        this.set("ignoreErrors", isChecked);
    }
}