import Ember from 'ember';
import Component from '@ember/component';
import { computed, action } from '@ember/object';

export default class Checkbox extends Component {
    tagName = 'div';
    classNameBindings = [':checkbox-outer', 'type', 'onClass'];

    // Can be: checkbox, toggle-switch, yes-no-switch
    type = 'checkbox';
    isChecked = false;

    title = null;

    @computed('type', 'isChecked')
    get onClass() {
        return this.isChecked ? `${this.type}-on` : `${this.type}-off`;
    }

    click(event) {
        event.stopPropagation();
        this.toggleOption(!this.isChecked, this.title, event);
    }

    toggleOption() { /* action */}
};
