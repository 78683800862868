import Ember from "ember";
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import classic from "ember-classic-decorator";

import ChartColors from "infegy-frontend/static_data/fixtures/chart_colors";

var colorMap = ChartColors.get("colorMap") || {};

@classic
export default class SentimentKeywordsContainer extends Component {
    queries = null;

    positiveSelectedTopic = null;
    positiveHoveredTopic = null;

    negativeSelectedTopic = null;
    negativeHoveredTopic = null;

    positiveTopicsColor = Ember.get(colorMap, "atlas-sentiment-positive.base");
    negativeTopicsColor = Ember.get(colorMap, "atlas-sentiment-negative.base");

    @computed.gt("queries.length", 1) hasMoreThanOneQuery;

    @computed("queries.[]", "queries.@each.updated")
    get isLoading() {
        return (this.queries || []).find(query => {
                return !Ember.get(query, "negativeKeywords.isLoaded") ||
                    !Ember.get(query, "positiveKeywords.isLoaded");
            });
    };

    @computed.or("positiveHoveredTopic", "positiveSelectedTopic") positiveActiveTopic;

    @computed("negativeHoveredTopic", "negativeSelectedTopic")
    get negativeActiveTopic() {
        return this.negativeHoveredTopic || this.negativeSelectedTopic;
    };

    combineKeywords(queries, apiProperty) {
        var indexedKeywords = {},
            combinedKeywords = [];
        queries.filterBy(apiProperty + ".data.length").forEach(query => {
            query.get(apiProperty + ".data").forEach(kw => {
                var key = Ember.get(kw, "word"),
                    keyword = Object.assign({}, kw, {
                        originalQuery: query
                    });
                if (!indexedKeywords[key]) {
                    indexedKeywords[key] = [];
                }
                indexedKeywords[key].push(keyword);
            });
        });
        for (let key in indexedKeywords) {
            if (indexedKeywords.hasOwnProperty(key)) {
                combinedKeywords.push({
                    word: key,
                    appearances: indexedKeywords[key].reduce((sum, kw) => kw.appearances + sum, 0),
                    originals: indexedKeywords[key]
                });
            }
        }
        return combinedKeywords;
    };

    @computed("queries.[]", "queries.@each.updated")
    get allNegativeKeywords() {
        var queries = this.queries;
        return queries && this.combineKeywords(queries || [], "negativeKeywords");
    };

    @computed("queries.[]", "queries.@each.updated")
    get allPositiveKeywords() {
        var queries = this.queries;
        return queries && this.combineKeywords(queries || [], "positiveKeywords");
    };

    @computed("queries", "queries.[]", "queries.length", "positiveDrilldownQuery")
    get hasPositiveDrilldownQuery() {
        return this.queries && this.queries.length === 1 && this.positiveDrilldownQuery;
    };

    @computed("queries", "queries.[]", "queries.length", "negativeDrilldownQuery")
    get hasNegativeDrilldownQuery() {
        return this.queries && this.queries.length === 1 && this.negativeDrilldownQuery;
    };

    makeDrilldownQuery(query, keywordObj) {
        var keyword = keywordObj && keywordObj.word;

        if (!query || !keyword) {
            return;
        }

        let drilldownQuery = query.toDrilldownQuery({
            queryString: keyword,
            queryLabel: keyword
        });
        return drilldownQuery;
    };

    @computed("queries", "queries.length", "queries.@each.updated", "positiveSelectedTopic")
    get positiveDrilldownQuery() {
        var queries = this.queries,
            query = queries && queries[0],
            keywordObj = this.positiveSelectedTopic;

        return this.makeDrilldownQuery(query, keywordObj);
    };

    @computed("queries", "queries.length", "queries.@each.updated", "negativeSelectedTopic")
    get negativeDrilldownQuery() {
        var queries = this.queries,
            query = queries && queries[0],
            keywordObj = this.negativeSelectedTopic;

        return this.makeDrilldownQuery(query, keywordObj);
    };

    @action
    positiveTopicHovered(topic) {
        if (this.positiveHoveredTopic !== topic) {
            this.set("positiveHoveredTopic", topic);
        }
    };

    @action
    positiveTopicSelected(topic) {
        if (this.positiveSelectedTopic !== topic) {
            this.set("positiveSelectedTopic", topic);
        }
    };

    @action
    negativeTopicHovered(topic) {
        if (this.negativeHoveredTopic !== topic) {
            this.set("negativeHoveredTopic", topic);
        }
    };

    @action
    negativeTopicSelected(topic) {
        if (this.negativeSelectedTopic !== topic) {
            this.set("negativeSelectedTopic", topic);
        }
    };

    @action
    positiveRemoveTopic(topic) {
        this.allPositiveKeywords.forEach((keyword, index) => {
           if (keyword.word === topic.word) {
               this.allPositiveKeywords.removeAt(index, 1);
           }
        });
        this.set("positiveSelectedTopic", null);
    }

    @action
    negativeRemoveTopic(topic) {
        this.allNegativeKeywords.forEach((keyword, index) => {
            if (keyword.word === topic.word) {
                this.allNegativeKeywords.removeAt(index, 1);
            }
        });
        this.set("negativeSelectedTopic", null);
    }
};
