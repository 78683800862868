import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import DataSeriesComputedFieldPercentage from "infegy-frontend/models/data_series/data_series_computed_field_percentage";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";
import DataSeriesComputedFieldSum from "infegy-frontend/models/data_series/data_series_computed_field_sum";
import fieldQuotient from "infegy-frontend/models/data_series/data_series_computed_field_quotient";
import DataSeriesComputedFieldDistribution from "infegy-frontend/models/data_series/data_series_computed_field_distribution";

var themesBaseFields = {
        "date": "datestring",
        "timestamp": "timestamp",
        "documents": "number",
        "universe": "number"
    };

export default QueryAPIResults.extend({
    title : "themes",
    availableThemes: [],
    rawNameMap: {},
    customEmptyCheckFunction: function(dataSeries) {
        var stats = dataSeries && dataSeries.get("stats");
        return !stats || !stats.get("documents") || !stats.get("documents.sum");
    },
    description: DataSeriesDescription.load(themesBaseFields),
    preload: function() {
        var rawResponse = this.rawResults,
            description = DataSeriesDescription.load(themesBaseFields),
            themeNames = [],
            themeTypes = [],
            rawNameMap = {},
            newFields,
            isFirstRow = true;

        // Flatten the Rows
        rawResponse.forEach((row) => {
            var rowThemes = {};
            row.themes.forEach(theme => {
                if(isFirstRow){
                    var themeName = theme.name.camelize();
                    themeNames.push(themeName);
                    rawNameMap[themeName] = theme.name;
                    themeTypes.pushObject({
                       theme: theme.name,
                       customTheme: theme.user_theme
                    });
                }
                rowThemes[theme.name] = theme;
                row[theme.name] = theme;
            });
            isFirstRow = false;
            row.themes = rowThemes;
            return row;
        });

        // Find Theme Hierachy (Themes with subthemes are separated by pipes: |)
        var rootThemes = [],
            themeMap = themeNames.reduce((memo, theme) => {
                var splitTheme = theme.split("|"),
                    rootTheme = (splitTheme && splitTheme[0]) || theme;
                if (!memo[rootTheme]) {
                    memo[rootTheme] = [];
                }
                memo[rootTheme].push(theme);
                return memo;
            }, {});

        // Build Array representing available tiered themes for reference
        for (let rt in themeMap) {
            if (themeMap.hasOwnProperty(rt)) {
                var themeInfo = { theme: rt, subthemes: [], customTheme: false };

                if (themeMap[rt].length > 1) {
                    themeInfo.subthemes = themeMap[rt].filter(name => name !== rt);

                } else if (themeMap[rt].length === 1) {
                    themeInfo.theme = themeMap[rt][0];
                }
                rootThemes.push(themeInfo);
            }
        }

        // Set the custom flag on rootThemes
        rootThemes.forEach(theme => {
            themeTypes.forEach(type => {
                if (theme.theme === type.theme.camelize()) {
                    theme.customTheme = type.customTheme;
                }
            });
        });

        themeNames.forEach((themeName) => {
            newFields = {};
            newFields[themeName + "Name"] = "string";
            newFields[themeName + "UserTheme"] = "boolean";
            newFields[themeName + "PositiveAppearances"] = "number";
            newFields[themeName + "NegativeAppearances"] = "number";
            newFields[themeName + "Appearances"] = "number";
            newFields[themeName + "PositiveDocuments"] = "number";
            newFields[themeName + "NegativeDocuments"] = "number";
            newFields[themeName + "Documents"] = "number";
            newFields[themeName + "Universe"] = "number";

            newFields["themes" + themeName.capitalize() + "Name"] = "string";
            newFields["themes" + themeName.capitalize() + "UserTheme"] = "boolean";
            newFields["themes" + themeName.capitalize() + "Appearances"] = "number";
            newFields["themes" + themeName.capitalize() + "PositiveAppearances"] = "number";
            newFields["themes" + themeName.capitalize() + "NegativeAppearances"] = "number";
            newFields["themes" + themeName.capitalize() + "PositiveDocuments"] = "number";
            newFields["themes" + themeName.capitalize() + "NegativeDocuments"] = "number";
            newFields["themes" + themeName.capitalize() + "Documents"] = "number";
            newFields["themes" + themeName.capitalize() + "Universe"] = "number";

            description.addFields(newFields, true);
            description.addComputedFields([
                DataSeriesComputedFieldSum.fieldSetup(themeName + "SentimentalDocuments",
                    [themeName + "PositiveDocuments", themeName + "NegativeDocuments"]),
                DataSeriesComputedFieldPercentage.fieldSetup(
                    themeName + "DocumentsDistribution",
                    "documents",
                    themeName + "Documents" ),
                DataSeriesComputedFieldPercentage.fieldSetup(
                    themeName + "UniverseDistribution",
                    "universe",
                    themeName + "Universe" ),
                DataSeriesComputedFieldPercentage.fieldSetup(
                    themeName + "PositiveDocumentsDistribution",
                    "documents",
                    themeName + "PositiveDocuments" ),
                DataSeriesComputedFieldPercentage.fieldSetup(
                    themeName + "NegativeDocumentsDistribution",
                    "documents",
                    themeName + "NegativeDocuments" ),
                DataSeriesComputedFieldPercentage.fieldSetup(
                    themeName + "PositiveSentimentalDocumentsDistribution",
                    themeName + "SentimentalDocuments",
                    themeName + "PositiveDocuments" ),
                DataSeriesComputedFieldPercentage.fieldSetup(
                    themeName + "NegativeSentimentalDocumentsDistribution",
                    themeName + "SentimentalDocuments",
                    themeName + "NegativeDocuments" ),
                DataSeriesComputedFieldPercentage.fieldSetup(
                    themeName + "PositiveAppearancesDistribution",
                    [themeName + "PositiveAppearances", themeName + "NegativeAppearances"],
                    themeName + "PositiveAppearances" ),
                DataSeriesComputedFieldPercentage.fieldSetup(
                    `${themeName}DocumentsDistribution`,
                    `documents`,
                    `${themeName}Documents`),
                fieldQuotient.fieldSetup(
                    `${themeName}AppearancesPerPost`,
                    `${themeName}Appearances`,
                    `${themeName}Documents`)
            ]);
        });

        this.set("allThemes", themeNames);
        this.set("themeTypes", themeTypes);
        this.set("themeMap", themeMap);
        this.set("rootThemes", rootThemes);

        this.set("availableThemes", themeNames);
        this.set("rawNameMap", rawNameMap);
        this.set("description", description);
    }
});
