import Ember from "ember";

var dateOptions = {
    EARLIEST_ATLAS_TIMESTAMP: Date.UTC(2007, 0, 1),
    RELATIVE_DAY_VALUES: function() {
        var maxVals = 1000;
        var vals = [];
        for (var i = 1; i <= maxVals; i++) {
            vals.push({
                "label": i,
                "value": i
            });
        }
        return vals;
    },
    RELATIVE_WEEK_VALUES: function() {
        var maxVals = 100;

        var vals = [];
        for (var i = 1; i <= maxVals; i++) {
            vals.push({
                "label": i,
                "value": i
            });
        }
        return vals;
    },
    RELATIVE_YEAR_VALUES: function() {
        var maxVals = 10;
        var vals = [];
        for (var i = 1; i <= maxVals; i++) {
            vals.push({
                "label": i,
                "value": i
            });
        }
        return vals;
    },
    RELATIVE_MODIFIER: [{
        "label": "Ago",
        "value": "ago"
    }, {
        "label": "Before",
        "value": "before"
    }, {
        "label": "After",
        "value": "after"
    }],
    RELATIVE_UNITS: [{
        "label": "Days",
        "value": "days"
    }, {
        "label": "Weeks",
        "value": "weeks"
    }, {
        "label": "Months",
        "value": "months"
    }, {
        "label": "Years",
        "value": "years"
    }],
    RELATIVE_UNITS_EXTENDED: [{
        "group": "start",
        "label": "Years - From Beggining",
        "value": "yearstarts"
    }, {
        "group": "start",
        "label": "Month starts",
        "value": "monthstarts"
    }, {
        "group": "start",
        "label": "Week starts",
        "value": "weekstarts"
    }],
    MONTHS: [{
        "label": "January",
        "value": 1,
        "alternates": ["jan", "01", 1],
        "hideDescription": true
    }, {
        "label": "February",
        "value": 2,
        "alternates": ["feb", "02", 2],
        "hideDescription": true
    }, {
        "label": "March",
        "value": 3,
        "alternates": ["mar", "03", 3],
        "hideDescription": true
    }, {
        "label": "April",
        "value": 4,
        "alternates": ["apr", "04", 4],
        "hideDescription": true
    }, {
        "label": "May",
        "value": 5,
        "alternates": ["may", "05", 5],
        "hideDescription": true
    }, {
        "label": "June",
        "value": 6,
        "alternates": ["jun", "06", 6],
        "hideDescription": true
    }, {
        "label": "July",
        "value": 7,
        "alternates": ["jul", "07", 7],
        "hideDescription": true
    }, {
        "label": "August",
        "value": 8,
        "alternates": ["aug", "08", 8],
        "hideDescription": true
    }, {
        "label": "September",
        "value": 9,
        "alternates": ["sep", "09", 9],
        "hideDescription": true
    }, {
        "label": "October",
        "value": 10,
        "alternates": ["oct", 10],
        "hideDescription": true
    }, {
        "label": "November",
        "value": 11,
        "alternates": ["nov", 11],
        "hideDescription": true
    }, {
        "label": "December",
        "value": 12,
        "alternates": ["dec", 12],
        "hideDescription": true
    }],
    START_DATE_OPTIONS: [
        Ember.Object.create({
            value: "relative"
        }),
        Ember.Object.create({
            value: "fixed"
        })
    ],
    END_DATE_OPTIONS: [
        Ember.Object.create({
            value: "relative"
        }),
        Ember.Object.create({
            value: "fixed"
        }),
        Ember.Object.create({
            value: "now"
        })
    ],
    years: function() {
        var min_year = 2007;
        var max_year = new Date().getFullYear();
        var years = [];
        for (var year = min_year; year <= max_year; year++) {
            years.push({
                "label": year,
                "value": year,
                "alternates": [year % 100, year % 1000],
                "hideDescription": true
            });
        }
        return years;
    },
    daysInMonth: function(month, year) {
        //Month is 1 based
        var days = new Date(year, month, "0").getDate();
        return days;
    },
    daysInMonthOptions: function(month, year) {
        var daySuffix = function(day) {
            var d = day % 10;
            return (d > 3 || (day < 14 && day > 3)) ? 'th' : (['th', 'st', 'nd', 'rd'][d]);
        };

        var days = this.daysInMonth(month, year);
        var options = [];
        for (var day = 1; day <= days; day++) {
            options.push({
                "label": day + daySuffix(day),
                "value": day,
                "alternates": [day, ("00" + day).slice(-2)],
                "hideDescription": true
            });
        }
        return options;
    },
    relativeValuesForUnits: function(units) {
        if (units === "years") {
            return this.relativeYearValues();
        } else if (units === "weeks") {
            this.relativeWeekValues();
        }

        return this.relativeDayValues();
    }
};

export default dateOptions;
