import Ember from "ember";
import BaseFieldSelections from "infegy-frontend/models/fields/field-selections/base";

export default BaseFieldSelections.extend({
    apiName: "sentiment",
    defaultSubSegment: "Subject Sentences Distribution",

    allAvailableFields: Ember.computed("subSegment", {
        get: function(key) {
            return {
                "Passion.Passion": [this.makeFieldDefinition({
                        fieldName: "passion",
                        suggestedColorClass: "atlas-passion",
                    })],
                "Passion.Intensity": [this.makeFieldDefinition({
                        fieldName: "intensity",
                        suggestedColorClass: "atlas-passion"
                    }), this.makeFieldDefinition({
                        fieldName: "positiveIntensity",
                        suggestedColorClass: "atlas-sentiment-positive"
                    }), this.makeFieldDefinition({
                        fieldName: "negativeIntensity",
                        suggestedColorClass: "atlas-sentiment-negative"
                    })],
                "Passion.SentimentalSentences": [this.makeFieldDefinition({
                        fieldName: "sentimentalSentences",
                    })],
                "Passion.SentimentalSubjectSentences": [this.makeFieldDefinition({
                        fieldName: "sentimentalSubjectSentences",
                    })],
            };
        }
    }),

});
