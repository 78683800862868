import Ember from "ember";
import defaultColorDefinitions from "infegy-frontend/static_data/fixtures/default_color_definitions"
import tinycolor from "infegy-frontend/utils/tinycolor";
import AtlasColors from "infegy-frontend/utils/atlas-colors";

// Compare colors based on rgb vector distance to make sure we aren't selecting too close of colors
let compareTinyColor = AtlasColors.compareTinyColor;

export default function(fields) {
    if (!fields || !fields.length) {
        return;
    }

    let allColors = defaultColorDefinitions.mapBy("hex").uniq(),
        availableColors = allColors.map(colorString => {
            return {
                original: colorString,
                tc: tinycolor(colorString)
            };
        }),
        usedColors = [],
        duplicateColorFields = fields.filter(field => {
            let fieldColorString = Ember.get(field, "color.colorString"),
                fieldColor = Ember.get(field, "color.tinycolorObj");
            if (!fieldColor) {
                return true;
            }
            let matchedColor = usedColors.find(usedColor => {
                return compareTinyColor(usedColor.tc, fieldColor);
            });
            if (matchedColor) {
                return true;
            }
            usedColors.push({
                original: fieldColorString,
                tc: fieldColor
            });
            availableColors = availableColors.filter(availColor => {
                return !compareTinyColor(availColor.tc, fieldColor);
            });
        });

    if (duplicateColorFields) {
        duplicateColorFields.forEach(field => {
            let newColor = availableColors.length ? availableColors.shift() : allColors.shift();
            Ember.set(field, "selectedColor", newColor.original);
        });
        return fields;
    }

    return null;
}
