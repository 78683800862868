import Ember from "ember";
import JsonStore from "infegy-frontend/json-store/model";
import Prop from "infegy-frontend/json-store/properties/model";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import Subquery from "infegy-frontend/models/users/subquery";
import classic from "ember-classic-decorator";

@classic
export default class SubquerySet extends JsonStore {
    @Prop.Int() id;
    @Prop.String() name;
    @Prop.String() created;
    @Prop.String() modified;
    @Prop.Collection(Subquery) entries;
    @Prop.Boolean() isShared;

    @Ember.computed.alias("name") label;
    @Ember.computed.alias("id") value;

    loadEntries() {
        const queryParams = {
            id: this.id
        };

        return AtlasAuth.Get({
            url: "api/v3/query-sets",
            data: queryParams
        }).then((response) => {
            this.entries.clear();
            this.entries.loadJSON(response.output[0].entries);
        }).catch((response) => {
            console.warn(`Failed to load query_set entries: ${response.atlasErrorText}`);
            throw response;
        });
    }

    createEntry(tagName, tagQuery) {
        const queryParams = {
            query_set_id: this.id,
            tag: tagName,
            query: tagQuery
        };

        return AtlasAuth.Post({
            url: "api/v3/query-sets/entries",
            data: queryParams
        }).then((response) => {
            const querySetEntry = Subquery.create();
            querySetEntry.loadJSON(response.output[0]);
            this.entries.addObject(querySetEntry);
        }).catch((response) => {
            console.warn(`Failed to create new query_set_entry: ${response.atlasErrorText}`);
            throw response;
        });
    }

    deleteSubqueriesById(ids) {
        return AtlasAuth.Delete({
            url: "api/v3/query-sets/entries",
            data: {
                ids,
                query_set_id: this.id
            }
        }).then((response) => {
            ids.forEach((id) => {
                const found = this.entries.findBy("id", id);
                this.entries.removeObject(found);
            });
        }).catch((response) => {
            console.warn(`Failed to create new query_set_entry: ${response.atlasErrorText}`);
            throw response;
        });
    }

    updateEntryById(entryId, newTag, newQuery) {
        const found = this.entries.findBy("id", entryId);
        return found.update(this.id, newTag, newQuery).then((response) => {
            found.loadJSON(response.output[0]);
        });
    }

    save(name) {
        return AtlasAuth.Post({
            data: { name },
            url: "api/v3/query-sets"
        }).then((response) => {
            this.loadJSON(response.output[0]);
        });
    }

    saveCopy(newName) {
        let name = newName;
        if (Ember.isEmpty(newName)) {
            name = `${this.name} (copy)`;
        }
        return new Promise(async (resolve, reject) => {
            let response = await AtlasAuth.Post({
                url: "api/v3/query-sets",
                data: {
                    name,
                    source_id: this.id
                }
            });

            const newSubquerySet = SubquerySet.create();
            newSubquerySet.loadJSON(response.output[0]);
            resolve(newSubquerySet);
        });
    }

    update(name) {
        const queryParams = {
            id: this.id,
            name
        };

        return AtlasAuth.Put({
            url: "api/v3/query-sets",
            data: queryParams
        }).then((response) => {
            this.loadJSON(response.output[0]);
        }).catch((response) => {
            console.error(`Failed to update query_set: ${response.atlasErrorText}`);
            throw response;
        });
    }

    delete() {
        const queryParams = {
            id: this.id
        };

        return AtlasAuth.Delete({
            url: "api/v3/query-sets",
            data: queryParams
        }).catch((response) => {
            console.error(`Failed to delete query_set: ${response.atlasErrorText}`);
            throw response;
        });
    }
}