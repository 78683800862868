import Ember from "ember";
import {computed, action} from "@ember/object";
import Component from '@ember/component';
import DateProcessing from "infegy-frontend/utils/date-processing";
import QueryURL from "infegy-frontend/models/queries/query_url";
import classic from "ember-classic-decorator";

var millisInDay = DateProcessing.constants.millisInDay;

@classic
export default class extends Component {
    trendFields = null;
    trendChartMeta = null;

    @computed.alias("trendFields.firstObject") trendField;

    @computed("trendChartMeta.grouping", "trendChartMeta.isSelected")
    get isSingleDay() {
        return this.trendChartMeta.isSelected && this.trendChartMeta.grouping === "day";
    }

    @computed("trendFields", "trendFields.[]", "trendFields.@each.query")
    get filterableQueries() {
        let allQueries = (this.trendFields || []).mapBy("field.query"),
            foundQueries = [];

        // Make sure we only have unique queries to avoid duplicates
        let filteredQueries = allQueries.filter(query => {
            let queryString = JSON.stringify(query.toJSON());
            if (foundQueries.includes(queryString)) {
                return false;
            }
            foundQueries.push(queryString);
            return true;
        });

        return filteredQueries;
    }

    @computed("trendField.selectedDateRange", "trendField.field.query")
    get filteredQueries() {
        let range = this.trendField?.selectedDateRange;
        if (!range || !this.filterableQueries || !this.filterableQueries.length) {
            return;
        }

        let newQueries = this.filterableQueries.map(originalQuery => {
            let newQuery = originalQuery.copy();

            let startDate = newQuery.queryFilters.startDate;
            startDate.set("isRelative", false);
            startDate.fixed.fromTimestamp(range.start);

            let endDate = newQuery.queryFilters.endDate;
            endDate.set("isRelative", false);
            // Subtract 1 millisecond from end time as smallest precision since ranges are inclusive
            endDate.fixed.fromTimestamp(range.end - 1);

            return newQuery;
        });

        return newQueries;
    }

    @computed("filteredQueries")
    get serializedQueries() {
        let filteredQueries = this.filteredQueries;
        if (!filteredQueries) {
            return;
        }

        let qUrl = QueryURL.queryArrayToUrl(filteredQueries),
            base_url = window.location.href.split("/atlas/dashboard/")[0],
            full_url = [base_url, "/atlas/dashboard/", qUrl, "/posts"].join("");

        return qUrl && full_url;
    }

    //
    //
    // filteredQueries: Ember.computed("groupByField", "isGroupedTrends", "filterRange", "filterQueries", {
    //     get: function(key) {
    //         var queries = this.filterQueries,
    //             range = this.filterRange;
    //
    //         if (!queries || !range) {
    //             return;
    //         }
    //
    //         var queryInfos = queries.map((query) => {
    //             var queryInfo = query.get("queryInfo").copy();
    //             return queryInfo;
    //         }, this);
    //
    //         if (this.isGroupedTrends) {
    //             key = this.groupByField === "hour" ? "timeOfDay" : "dayOfWeek";
    //
    //             queryInfos.forEach((queryInfo, index) => {
    //                 queryInfo.set(key, Array.apply(null, Array(range.end - range.start + 1)).map(function(curr, i, arr) {
    //                     return i + range.start;
    //                 }).toString());
    //
    //                 queries.objectAt(index).set('queryInfo', queryInfo);
    //             });
    //         } else {
    //             queryInfos.forEach((queryInfo, index) => {
    //                 var gDate = queryInfo.get("startDate");
    //                 gDate.set("isRelative", false);
    //                 gDate.get("fixed").fromTimestamp(range.start);
    //
    //                 gDate = queryInfo.get("endDate");
    //                 gDate.set("isRelative", false);
    //                 gDate.get("fixed").fromTimestamp(range.end);
    //
    //                 queries.objectAt(index).set('queryInfo', queryInfo);
    //             });
    //         }
    //
    //         return queries;
    //     }
    // }),
    //
};
