import Ember from "ember";
import { computed } from '@ember/object';
import TrendInteraction from "infegy-frontend/models/fields/trend-chart-interaction";
import CSSTools from "infegy-frontend/utils/css-tools";
import { MouseInteractionWrapper } from "infegy-frontend/components/charting/mouse-interaction-mixin/component";

var minimalPercentChangeThreshold = 3.0;

export default class extends MouseInteractionWrapper(Ember.Component).extend({
    classNameBindings: [":even-column", ":trends_field_information", "colorClass", "highlightedColorClass"],
    attributeBindings: ["style"]
}) {
    interactionInfo = null;
    @computed("interactionInfo")
    get innerInteractionInfo() {
        var interactionInfo = this.interactionInfo;
        if (interactionInfo && interactionInfo.setProperties) {
            return interactionInfo;
        } else {
            return TrendInteraction.create();
        }
    }

    size = null;
    field = null;

    maxUnfilteredPrimaryValue = 0;

    @Ember.computed.or("field.sourceField", "field") originalField;

    onMouseEnter() {
        this.fieldHovered(this.field);
    }

    onMouseLeave() {
        this.fieldHovered(null);
    }

    click() {
        this.fieldClicked(this.field);
    }

    @Ember.computed.oneWay("innerInteractionInfo.isHovered") isHovered;
    @Ember.computed.oneWay("innerInteractionInfo.isSelected") isSelected;
    @Ember.computed.or("isHovered", "isSelected") isHoveredOrSelected;

    @computed("field", "isHoveredOrSelected", "innerInteractionInfo.isBrushed")
    get isHidden() {
        return !(this.isHoveredOrSelected ||  this.get("innerInteractionInfo.isBrushed"));
    }

    @computed("isHovered", "isSelected", "innerInteractionInfo.isBrushed")
    get showSummaryValue() {
        return this.isHovered || this.isSelected ||
            this.get("innerInteractionInfo.isBrushed");
    }

    @Ember.computed.oneWay("field.summaryValue") summaryValue;
    @computed("isHovered", "isSelected", "innerInteractionInfo.hoveredXIdx", "innerInteractionInfo.selectedXIdx", "field")
    get highlightValue() {
        var idx;
        if (this.isHovered) {
            idx = this.get("innerInteractionInfo.hoveredXIdx");
        } else if (this.isSelected) {
            idx = this.get("innerInteractionInfo.selectedXIdx");
        } else {
            return;
        }
        return this.originalField && this.originalField.getDataSeriesRowFieldValue(idx);
    }

    @computed("averagesOnly", "isHoveredOrSelected", "highlightValue", "summaryValue")
    get primaryValue() {
        if (this.isHoveredOrSelected) {
            return this.highlightValue;
        }
        return this.summaryValue;
    }

    @computed("averagesOnly", "isHovered", "isSelected", "innerInteractionInfo.isBrushed")
    get primaryValueType() {
        if (this.isHovered) {
            return "Hovered";
        } else if (this.isSelected) {
            return "Selected";
        } else if (this.get("innerInteractionInfo.isBrushed")) {
            return "Highlighted";
        } else if (this.averagesOnly) {
            return "Average";
        }
        return "Total";
    }

    @computed("averagesOnly")
    get summaryValueType() {
        return this.averagesOnly ? "Average" : "Total";
    }

    @computed("field.fieldStats.percentChange")
    get percentChange() {
        var field = this.field,
            percentChange = field && field.get("fieldStats") && field.get("fieldStats.percentChange");
        return (percentChange || 0.0) / 100.0;
    }

    @Ember.computed.gt("percentChange", minimalPercentChangeThreshold) isPositivePercentChange;
    @Ember.computed.lt("percentChange", minimalPercentChangeThreshold) isNegativePercentChange;

    @computed("percentChange")
    get isMinimalPercentChange() {
        return Math.abs(this.percentChange) < this.minimalPercentChangeThreshold;
    }

    @Ember.computed.equal("percentChange", Infinity) isPositiveInfinityPercentChange;
    @Ember.computed.equal("percentChange", -Infinity) isNegativeInfinityPercentChange;
    @Ember.computed.or("isPositiveInfinityPercentChange", "isNegativeInfinityPercentChange") isInfinityPercentChange;

    @Ember.computed.alias("field.fieldType") fieldType;
    @computed("field.fixedMaxY", "isPercentage")
    get fieldMaxY() {
        var fixedMaxY = this.get("field.fixedMaxY");
        if (!fixedMaxY && this.isPercentage) {
            fixedMaxY = 1.0;
        }
        return fixedMaxY;
    }

    @Ember.computed.alias("field.isPercentageType") isPercentage;
    @Ember.computed.alias("field.isAveragedType") isAverage;
    @Ember.computed.alias("field.isAveragedType") averagesOnly;

    @Ember.computed.not("field.isPercentageType") hidePercentage;

    @computed("averagesOnly", "field.average", "fieldMaxY")
    get showArcs() {
        var rangelessPercentage = this.field && this.get("field.rangelessPercentage"),
            fieldMaxY = this.fieldMaxY;
        return fieldMaxY && this.averagesOnly && !rangelessPercentage;
    }

    @computed("isPercentage")
    get arcWhole() {
        return this.isPercentage ? 1.0 : this.fieldMaxY;
    }

    @Ember.computed.alias("innerInteractionInfo.selectedField") selectedField;

    @computed("innerInteractionInfo.hoveredField", "innerInteractionInfo.selectedField")
    get hasHighlight() {
        return this.get("innerInteractionInfo.hoveredField") || this.get("innerInteractionInfo.selectedField");
    }

    @computed("innerInteractionInfo.hoveredField", "innerInteractionInfo.selectedField", "originalField")
    get isHighlighted() {
        if (this.hasHighlight) {
            return this.get("innerInteractionInfo.hoveredField") === this.originalField ||
                this.get("innerInteractionInfo.selectedField") === this.originalField;
        }
    }

    @computed("hasHighlight","isHighlighted","field.colorValue")
    get style(){
        var textColor
        if (!this.hasHighlight) {
            textColor = this.get("field.colorValue");
        } else {
            textColor = this.isHighlighted ?  this.get("field.darkColorValue") : this.get("field.lightColorValue");
        }
        return CSSTools.buildStringFromObjectSafe({
            color: textColor
        });
    }

    @computed("field.colorClass", "isHighlighted", "hasHighlight")
    get colorClass() {
        var colorClass = this.get("field.colorClass");
        if (!this.hasHighlight) {
            return colorClass;
        }
        colorClass += this.isHighlighted ? " dark" : " light";
        return colorClass;
    }

    @computed("hasHighlight","isHighlighted","field.colorValue")
    get color() {
        if (!this.hasHighlight) {
            return this.get("field.colorValue");
        } else {
            return this.isHighlighted ?  this.get("field.darkColorValue") : this.get("field.lightColorValue");
        }
    }

    @computed("field.colorClass", "isHighlighted", "hasHighlight")
    get highlightedColorClass() {
        if (!this.hasHighlight) {
            return "";
        } else if (this.isHighlighted) {
            return " trends-field-information-highlighted";
        }
        return " trends-field-information-faded";
    }
}
