import Ember from "ember";
import Component from '@ember/component';
import { computed } from '@ember/object';

export default class QuerySelector extends Component {
    selectedField = null;

    availableStats = [
        "average", "lowest", "highest", "total", "percent change"
    ]

};
