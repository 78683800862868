import Ember from "ember";
import {computed, action} from "@ember/object";
import SVGInnerWrapper from "infegy-frontend/components/charting/svg/svg-inner-wrapper/component";
import { MouseInteractionWrapper } from "infegy-frontend/components/charting/mouse-interaction-mixin/component";

var last = 0;

export default class TrendChartSVG extends MouseInteractionWrapper(SVGInnerWrapper) {
    title = "";
    classNameBindings = [":trend-chart", "isHovered:trend-chart-hovered"];

    trendFields = [];
    stackedFields = null;

    chartType = "line";

    groupByField = "timestamp";

    eventData = null;
    showEvents = true;

    mouseStartXPct = 0;
    trendChartMeta = null;

    marginX = 60;
    marginY = 20;
    brushStartTime = 0;

    displayedEvents = [];

    isCanvasChart = false;

    @Ember.computed.alias("trendFields.firstObject") firstField;

    @computed("isCanvasChart", "height", "width")
    get xAxisPoints() {
        let points = {
            x1: this.isCanvasChart ? 5 : 0,
            x2: this.isCanvasChart ? this.width - 5 : this.width,
            y1: this.isCanvasChart ? this.height - 5 : this.height,
            y2: this.isCanvasChart ? this.height - 5 : this.height,
        };
        return points;
    }
    @computed("isCanvasChart", "height", "width")
    get yAxisPoints() {
        let points = {
            x1: this.isCanvasChart ? 5 : 0,
            x2: this.isCanvasChart ? 5 : 0,
            y1: this.isCanvasChart ? 5 : 0,
            y2: this.isCanvasChart ? this.height - 5 : this.height,
        };
        return points;
    }

    @computed("showEvents", "eventData")
    get includeEvents() {
        return this.showEvents && this.eventData;
    }

    @computed("width", "height", "trendChartMeta.mouseIsDown")
    get overlayRect() {
        var marginX = this.marginX || 0,
            marginY = this.marginY || 0;
        return {
            x: -marginX, y: -marginY,
            w: (this.width || 0) + (2 * marginX),
            h: (this.height || 0) + (2 * marginY) };
    }

    @computed("trendChartMeta.brushEndXPctWhole")
    get brushEndXWidth() {
        if (this.trendChartMeta) {
            return 100.0 - (this.trendChartMeta.brushEndXPctWhole || 0);
        }
    }

    // Events
    eventClicked = () => {};
    eventHovered = () => {};
    fieldSelected = () => {};
    trendChartMetaUpdated = () => {};

    // Mouse interactions

    onMouseEnter() {
        // do nothing.
    }

    findClosestRowsFromPct(trendFields, xPct) {
        return this.trendChartMeta.findClosestRowsFromPct(trendFields, xPct);
    }

    mouseDown(event) {
        let trendChartMeta = this.trendChartMeta;
        if (!trendChartMeta) {
            return;
        }

        let xPct = event.offsetX / this.width;
        xPct = Math.min(1.0, Math.max(0, xPct));

        let closestRows = this.findClosestRowsFromPct(this.trendFields, xPct);
        if (Ember.isEmpty(closestRows)) {
            return;
        }
        let trueXPct = closestRows.closest.closestPct;

        this.set("trendChartMeta.mouseIsDown", true);
        this.set("mouseStartXPct", xPct);
        this.set("brushStartTime", Date.now());
        if (trendChartMeta.isBrushed) {
            trendChartMeta.set("isSelected", false);
            trendChartMeta.set("isBrushed", false);
            this.trendChartMetaUpdated(trendChartMeta);
            return;
        }
        trendChartMeta.set("isSelected", !trendChartMeta.isSelected);
        trendChartMeta.set("isBrushed", false);
        trendChartMeta.set("selectedXPct", trueXPct);
        trendChartMeta.set("brushStartXPct", xPct);
        trendChartMeta.set("brushEndXPct", xPct);
        this.trendChartMetaUpdated(trendChartMeta);
    }

    mouseUp() {
        this.set("trendChartMeta.mouseIsDown", false);
        if (!this.trendChartMeta || !this.trendChartMeta.isBrushed) {
            return;
        }

        var trendChartMeta = this.trendChartMeta,
            startXPct = trendChartMeta.brushStartXPct,
            endXPct = trendChartMeta.brushEndXPct,
            selectedRows = this.trendFields.mapBy("brushedData").sort((r1, r2) => {
                return (r2 && r2.length) - (r1 && r1.length);
            }),
            longestRow = selectedRows?.firstObject,
            maxSelected = longestRow && longestRow.length;

        if (!maxSelected || startXPct > endXPct) {
            trendChartMeta.setProperties({
                "isSelected": false,
                "isBrushed": false
            });
        } else if (maxSelected === 1) {
            trendChartMeta.setProperties({
                "isSelected": true,
                "isBrushed": false,
                "selectedXPct": longestRow[0].xPct
            });
        }
        this.trendChartMetaUpdated(trendChartMeta);
    }

    findYFieldInfo(closestXRows, yPct) {
        if (Ember.isEmpty(closestXRows) || Ember.isEmpty(yPct) || !this.trendChartMeta) {
            return;
        }

        let closestFieldInfo = closestXRows.filter(row => {
            return row.isInBounds;
        }).reduce((memo, row) => {
            let distance = Math.abs(yPct - row.closestYPct);
            if (!memo.closestRow || distance < memo.closestYDistance) {
                memo.closestRow = row;
                memo.closestYDistance = distance;
            }
            return memo;
        }, { closestYDistance: null, closestRow: null });

        return closestFieldInfo && closestFieldInfo.closestRow;
    }

    @computed("chartType")
    get isLineChart() {
        return this.chartType === "line";
    }

    onMouseMove(event) {
        let trendChartMeta = this.trendChartMeta,
            trendFields = this.trendFields;

        if (!trendChartMeta || !trendFields) {
            return;
        }

        let xPct = event.offsetX / this.width,
            yPct = 1.0 - (event.offsetY / this.height);
        yPct = Math.min(1.0, Math.max(0, yPct));
        xPct = Math.min(1.0, Math.max(0, xPct));

        let closestRows = this.findClosestRowsFromPct(trendFields, xPct);

        if (Ember.isEmpty(closestRows)) {
            return;
        }

        let trueXPct = closestRows.closest.closestPct;

        if (!this.trendChartMeta.mouseIsDown) {
            if (trendChartMeta.isBrushed && (xPct < trendChartMeta.brushStartXPct ||
                xPct > trendChartMeta.brushEndXPct)) {
                trendChartMeta.set("isHovered", false);
                trendChartMeta.set("hoveredField", null);
                return;
            }

            let hoveredFieldInfo = this.findYFieldInfo(closestRows.rows, yPct);
            if (hoveredFieldInfo) {
                if (this.isLineChart) {
                    trendChartMeta.set("hoveredYValue", hoveredFieldInfo.closestY);
                    trendChartMeta.set("hoveredYPct", hoveredFieldInfo.closestYPct);
                    if (hoveredFieldInfo.field && hoveredFieldInfo.field !== trendChartMeta.hoveredField) {
                        trendChartMeta.set("hoveredField", hoveredFieldInfo.field);
                    }
                }
                if (!trendChartMeta.isHovered || trendChartMeta.hoveredXPct != trueXPct) {
                    trendChartMeta.set("isHovered", true);
                    trendChartMeta.set("hoveredXPct", trueXPct);
                }
            }
        } else {
            if (trendChartMeta.isSelected && this.brushStartTime + 100 > Date.now()) {
                return;
            }
            var startX = trendChartMeta.brushStartXPct,
                endX = trendChartMeta.brushEndXPct,
                firstX = this.mouseStartXPct;

            if (xPct < firstX) {
                endX = firstX;
                startX = xPct;
            } else {
                startX = firstX;
                endX = xPct;
            }

            trendChartMeta.set("isSelected", false);
            trendChartMeta.set("isHovered", false);
            trendChartMeta.set("hoveredField", null);
            trendChartMeta.set("isBrushed", true);
            trendChartMeta.set("brushStartXPct", (startX < 0) ? 0 : startX);
            trendChartMeta.set("brushEndXPct", (endX > 1) ? 1 : endX);
        }

        this.trendChartMetaUpdated(trendChartMeta);

        return false;
    }

    onMouseLeave() {
        if (this.trendChartMeta) {
            this.set("trendChartMeta.isHovered", false);
            this.set("trendChartMeta.hoveredField", null);
            this.set("trendChartMeta.mouseIsDown", false);
            this.trendChartMetaUpdated(this.trendChartMeta);
        }
    }
}
