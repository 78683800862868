import Ember from "ember";
import JsonStore from 'infegy-frontend/json-store/model';
import Prop from 'infegy-frontend/json-store/properties/model';
import AdminUser from "infegy-frontend/models/admin/admin_user";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import CollectionStore from "infegy-frontend/json-store/collection/model";

var AdminCompanyModel = JsonStore.extend({
    id: Prop.Int(),
    isActive: Prop.Boolean({default: true}),
    email: Prop.String(),
    name: Prop.String(),

    users: Prop.Collection(AdminUser),

    save: function() {
        var request = AtlasAuth.Put({
            url: "api/v3/company_management.json",
            data: {
                company_id: this.id,
                name: this.name,
                email: this.email,
                is_active: this.isActive
            }
        });

        request.then((data) => {
            console.debug("Successfully saved company", data);
        })

        request.catch((data) => {
            console.error("Unable to save company.", data);
        });
    }
});

var AdminCompanies = JsonStore.extend({
    list: [],

    listOptions: Ember.computed("list", "list.[]", "list.length", function() {
        var list = this.list || [];

        return list.map((company)=>{
            return {
                "label": company["name"],
                "value": company["id"],
                "description": company["isActive"] ? "" : "(Inactive)"
            };
        });
    }),

    loadCompanies: function() {
        var dfd = AtlasAuth.Get("api/v3/company/list.json");

        dfd.catch((error) => {
            console.error("Could not load companies list", error);
        });

        dfd.then((data) => {
            if ("output" in data) {
                let companiesList = CollectionStore.create();
                companiesList.set("itemClass", AdminCompanyModel);
                companiesList.loadJSON(data.output);
                this.set("list", companiesList.content);
            }
        });
    },

    create: function(name) {
        var request = AtlasAuth.Post({
            url: "api/v3/company_management.json",
            data: {
                name: name,
                is_active: true
            }
        });

        request.catch(function(data) {
            console.error("Unable to save company.");
        });

        request.then((data) => {
            var newCompany = data.output[0];
            newCompany.is_active = true;

            var companyModel = AdminCompanyModel.create();
            companyModel.loadJSON(newCompany);

            var companiesList = this.list;
            companiesList.unshiftObject(companyModel);
        });
    },
});

export default AdminCompanies;
