import Ember from "ember";
import Languages from "infegy-frontend/static_data/query/query_filters/languages";

export default Ember.Component.extend({
    showEntityData: false,
    maxDisplayEntities: 5,

    showThemesEditor: false,
    showEmotionsEditor: false,

    languageString: Ember.computed("post", "post.language", function () {
        var post = this.post,
            language = post && post.language;
        return language && Languages.languageLabels[language];
    }),

    entityHandler: Ember.computed('post.entities', 'showEntityData', function () {
       var entities = this.post.entities;
       return this.showEntityData ? entities : entities.slice(0, this.maxDisplayEntities);
    }),

    actions: {
        toggleShowEntityData(){
            this.toggleProperty("showEntityData");
        }
    }
});
