import Component from "@ember/component";
import { action, computed } from "@ember/object";
import classic from "ember-classic-decorator";

import topicsFields from "infegy-frontend/models/entities/topics-fields";

@classic
export default class EntitiesForceGraph extends Component {
    classNames = ["entities-force-graph"];
    darkMode = false;
    displayLinks = true;
    queries = [];
    topics = [];

    // internal fields
    colorField = "positiveAppearancesDistribution";
    sizeField = "appearances";
    topicsType = "entities";

    hoveredTopic = null;
    selectedTopic = null;
    currentNode = null;
    activeTopic = null;
    hoveredTimelineIndex = null;

    linkHighlightStyle = "clusterId";
    nodeHighlightFieldOptions = [{
        label: "Hover Clusters",
        value: "clusterId"
    }, {
        label: "Hover Related Entities",
        value: "relatedTopics"
    }];

    @computed("colorField")
    get colorFieldInfo() {
        return topicsFields.fieldLookups[this.colorField] || topicsFields.fieldLookups["score"] || topicsFields[0];
    }

    @computed("topics", "colorField")
    get colorFieldStats() {
        const values = this.topics.mapBy(this.colorField);
        return {
            min: Math.min.apply(null, values),
            max: Math.max.apply(null, values),
        };
    }

    @computed("colorFieldInfo", "colorFieldStats")
    get colorDomain() {
        if (!this.colorFieldInfo || !this.colorFieldStats || this.colorFieldInfo.keyType === "sentiment") {
            return [0, 0.5, 1.0];
        }
        const min = Ember.get(this.colorFieldStats, "min") || 0;
        const max = Ember.get(this.colorFieldStats, "max") || 1.0;
        return [min, ((max - min) / 2) + min, max];
    }

    @computed("colorFieldInfo.colorRange")
    get colorRange() {
        const colorFieldInfo = this.colorFieldInfo;
        if (colorFieldInfo.keyType === "query") {
            return this.queries.mapBy("queryInfo.color.colorString");
        }
        return colorFieldInfo.colorRange;
    }

    @computed("colorFieldInfo.keyType") 
    get colorScaleType() {
        const keyType = this.get("colorFieldInfo.keyType");
        if (keyType === "query" || keyType === "cluster") {
            return "ordinal";
        } else {
            return "linear";
        }
    }

    @computed("topicsFields")
    get fieldOptions() {
        return this.queries.length > 1 ? topicsFields.multiQueryFieldOptions : topicsFields.singleQueryFieldOptions;
    }

    @computed("currentNode.fillColor")
    get topicColorStyle() {
        const fillColor = this.currentNode?.fillColor || "inherit";
        return `color: ${fillColor};`.htmlSafe();
    }

    @action
    darkModeChanged() {
        this.toggleProperty("darkMode");
    }

    @action
    displayLinksChanged() {
        this.toggleProperty("displayLinks");
    }

    @action
    downloadImage() {
        const link = document.createElement('a');
        const queryTitles = this.queries.map((q) => q.title);
        const fileName = `Entities - ${queryTitles.join(" & ")}`;
        link.download = fileName;
        link.href = this.element.querySelector("#entities-force-graph").querySelector("canvas").toDataURL();
        link.click();
        link.delete;
    }

    @action
    sizeFieldChanged(value) {
        this.set("sizeField", value);
    }

    @action
    colorFieldChanged(value) {
        this.set("colorField", value);
    }

    @action
    activeNodeChanged(id, node) {
        const found = this.topics.find((entity) => entity.id === id);
        this.setProperties({
            activeTopic: found,
            currentNode: node
        });
    }

    @action
    selectedNodeChanged(id, node) {
        const found = this.topics.find((entity) => entity.id === id);
        this.setProperties({
            selectedTopic: found,
            currentNode: node
        });
    }

    @action
    hoveredIndexChanged(index) {
        this.set("hoveredTimelineIndex", index);
    }
}
