import Ember from "ember";
import CSSTools from "infegy-frontend/utils/css-tools";
import { MouseInteractionWrapper } from "infegy-frontend/components/charting/mouse-interaction-mixin/component";
import { computed } from '@ember/object';

export default class extends MouseInteractionWrapper(Ember.Component) {
    classNameBindings = [":gender-topics-list-item", "isActive:atlas-neutral-lightest-bg"];

    attributeBindings = ["style"];

    topic = null;
    activeTopic = null;

    @computed("topic", "topic.topic", "activeTopic", "activeTopic.topic")
    get isActive() {
        var topic = this.get("topic.topic"),
            activeTopic = this.get("activeTopic.topic");

        return topic === activeTopic;
    }

    @computed("topic", "topic.color")
    get style() {
        return CSSTools.buildStringFromObject({
            color: this.get("topic.color")
        }).htmlSafe();
    }

    click() {
        this.topicSelected(this.topic);
    }
    onMouseEnter() {
        this.topicHovered(this.topic);
    }
}
