import Ember from 'ember';

export function timeDurationWords(params, namedArgs) {
  var years = Math.floor(params[0] / 31536000);
  var days = Math.floor((params[0] % 31536000) / 86400); 
  var hours = Math.floor(((params[0] % 31536000) % 86400) / 3600);
  var minutes = Math.floor((((params[0] % 31536000) % 86400) % 3600) / 60);
  var seconds = (((params[0] % 31536000) % 86400) % 3600) % 60;

  return years + " years " +  
          days + " days " + 
          hours + " hours " + 
          minutes + " minutes " + 
          seconds + " seconds";
}

export default Ember.Helper.helper(timeDurationWords);
