import Ember from "ember";
export default Ember.Component.extend({
    apis: ["demographicsMeta"],

    query: null,

    data: Ember.computed.alias('query.demographicsMeta.data'),

    incomeData: Ember.computed.alias("query.income.data"),
    householdValueData: Ember.computed.alias("query.householdValue.data"),

    homeOwnershipData: Ember.computed.alias("query.homeOwnership.data"),
    homeOwnershipStats: Ember.computed.alias("query.homeOwnership.stats"),

    maxOwnership: Ember.computed("query.homeOwnership.isLoaded", {
        get: function(key) {
            var homeOwnershipData = this.homeOwnershipData,
                homeOwnershipStats = this.homeOwnershipStats;

            if (homeOwnershipData && homeOwnershipStats &&
                homeOwnershipStats.get("probability")) {
                return homeOwnershipData[homeOwnershipStats.get("probability.maxIndex")];
            }
        },
    }),

    getRangeObject: function(data) {
        var mostProbableRange;
        data.forEach((range) => {
            if (!mostProbableRange || range.probability > mostProbableRange.probability) {
                mostProbableRange = range;
            }
        });

        if (mostProbableRange.rangeEnd === 0) {
            return {
                prefix: "",
                value: "over $" + mostProbableRange.rangeStart.toLocaleString()
            };
        } else {
            return {
                prefix: "between",
                value: "$" + mostProbableRange.rangeStart.toLocaleString() + " - $" + mostProbableRange.rangeEnd.toLocaleString()
            };
        }
    },

    maxIncomeDataProbability: Ember.computed('incomeData', {
        get: function() {
            var incomeData = this.incomeData;

            if (incomeData) {
                return Math.max.apply(Math, incomeData.map(function(obj) {
                    return obj.probability;
                }));
            }

        }
    }),
    incomeRange: Ember.computed("incomeData", {
        get: function() {
            var data = this.incomeData;

            if (!data) {
                return;
            }

            return this.getRangeObject(data);
        }
    }),
    householdValueRange: Ember.computed("householdValueData", {
        get: function() {
            var data = this.householdValueData;
            if (!data) {
                return;
            }

            return this.getRangeObject(data);
        }
    })
});
