import Ember from "ember";
import Component from '@ember/component';
import {action, computed} from "@ember/object";
import classic from "ember-classic-decorator";

@classic
export default class extends Component {
    classNames = ["ages-combined-data-table", "table-overflow-scroll"];

    queries = null;
    selectedField = null;

    sortField = "displayName";
    sortAscending = false;

    @computed("sortAscending")
    get sortedClass() {
        return this.sortAscending ? "sorted-asc" : "sorted-desc";
    }

    @computed("queries", "sortField", "sortAscending")
    get sortedAgesCombined() {
        let ages = this.queries || [],
            sortField = this.sortField,
            sortAscending = this.sortAscending;

        return ages.sort((ageA, ageB) => {
            let a = sortAscending ? Ember.get(ageA, sortField) : Ember.get(ageB, sortField),
                b = sortAscending ? Ember.get(ageB, sortField) : Ember.get(ageA, sortField);

            if (typeof(a) === "string") {
                return a.localeCompare(b);
            }

            return a - b;
        });
    }

    @computed("sortedAgesCombined")
    get sortedAgesArrays() {
        let queries = this.sortedAgesCombined || [];
        return queries.map(q => { return { query: q, arr: [q] }; });
    }

    @computed("queries")
    get ageRanges() {
        let queries = this.queries.firstObject,
            ranges = queries.ages.data.map(range => {
                return {
                    id: range.id,
                    name: range.displayName
                };
            });
        ranges.unshift({id: "query", name: "Query"});
        return ranges;
    }
}
