import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";
import DataSeriesComputedFieldDistribution from "infegy-frontend/models/data_series/data_series_computed_field_distribution";

export default QueryAPIResults.extend({
    title : "channels",

    availableChannels: [],
    distributions: [{
        id: "count",
        label: "Count",
        fields: [
            { label: "Blogs", value: "blogsCountDistribution" }, 
            { label: "Facebook", value: "facebookCountDistribution" }, 
            { label: "Instagram", value: "instagramCountDistribution" }, 
            { label: "News", value: "lexisnexisCountDistribution" }, 
            { label: "Pinterest", value: "pinterestCountDistribution" }, 
            { label: "Reddit", value: "redditCountDistribution" }, 
            { label: "TikTok", value: "tiktokCountDistribution" }, 
            { label: "Twitter", value: "twitterCountDistribution" }, 
            { label: "VK", value: "vkCountDistribution" }, 
            { label: "Weibo", value: "weiboCountDistribution" },
            { label: "YouTube", value: "youtubeCountDistribution" }
        ]
    }, {
        id: "normalized_universe",
        label: "Normalized Universe",
        fields: [
            { label: "Blogs", value: "blogsNormalizedUniverseDistribution" }, 
            { label: "Facebook", value: "facebookNormalizedUniverseDistribution" }, 
            { label: "Instagram", value: "instagramNormalizedUniverseDistribution" }, 
            { label: "News", value: "lexisnexisNormalizedUniverseDistribution" }, 
            { label: "Pinterest", value: "pinterestNormalizedUniverseDistribution" }, 
            { label: "Reddit", value: "redditNormalizedUniverseDistribution" }, 
            { label: "TikTok", value: "tiktokNormalizedUniverseDistribution" }, 
            { label: "Twitter", value: "twitterNormalizedUniverseDistribution" }, 
            { label: "VK", value: "vkNormalizedUniverseDistribution" }, 
            { label: "Weibo", value: "weiboNormalizedUniverseDistribution" },
            { label: "YouTube", value: "youtubeNormalizedUniverseDistribution" },
        ]
    }, {
        id: "universe",
        label: "Universe",
        fields: [
            { label: "Blogs", value: "blogsUniverseDistribution" }, 
            { label: "Facebook", value: "facebookUniverseDistribution" }, 
            { label: "Instagram", value: "instagramUniverseDistribution" }, 
            { label: "News", value: "lexisnexisUniverseDistribution" }, 
            { label: "Pinterest", value: "pinterestUniverseDistribution" }, 
            { label: "Reddit", value: "redditUniverseDistribution" }, 
            { label: "TikTok", value: "tiktokUniverseDistribution" }, 
            { label: "Twitter", value: "twitterUniverseDistribution" }, 
            { label: "VK", value: "vkUniverseDistribution" }, 
            { label: "Weibo", value: "weiboUniverseDistribution" },
            { label: "YouTube", value: "youtubeUniverseDistribution" }
        ]
    }],
    postload: function() {
        var availableStats = this.get("dataSeries.availableStats") || [],
            availableChannels = [];

        availableChannels = availableStats.filter(function (statName) {
            return statName.match("Universe") && !statName.match("Distribution") && !statName.match("Normalized");
        }).map(function(statName) {
            return statName.replace("Universe", "");
        });
        this.set("availableChannels", availableChannels);
    },
    description: DataSeriesDescription.load({
        "date": "datestring",
        "timestamp": "timestamp",

        "blogsCount": "number",
        "blogsUniverse": "number",
        "blogsNormalizedUniverse": "number",
        "blogsNormalized": "percent",
        "blogsNormalizedDiv": "number",

        "microblogsCount": "number",
        "microblogsUniverse": "number",
        "microblogsNormalizedUniverse": "number",
        "microblogsNormalized": "percent",
        "microblogsNormalizedDiv": "number",

        "twitterCount": "number",
        "twitterUniverse": "number",
        "twitterNormalizedUniverse": "number",
        "twitterNormalized": "percent",
        "twitterNormalizedDiv": "number",

        "imagesCount": "number",
        "imagesUniverse": "number",
        "imagesNormalizedUniverse": "number",
        "imagesNormalized": "percent",
        "imagesNormalizedDiv": "number",

        "forumsCount": "number",
        "forumsUniverse": "number",
        "forumsNormalizedUniverse": "number",
        "forumsNormalized": "percent",
        "forumsNormalizedDiv": "number",

        "facebookCount": "number",
        "facebookUniverse": "number",
        "facebookNormalizedUniverse": "number",
        "facebookNormalized": "percent",
        "facebookNormalizedDiv": "number",

        "newsCount": "number",
        "newsUniverse": "number",
        "newsNormalizedUniverse": "number",
        "newsNormalized": "percent",
        "newsNormalizedDiv": "number",

        "tumblrCount": "number",
        "tumblrUniverse": "number",
        "tumblrNormalizedUniverse": "number",
        "tumblrNormalized": "percent",
        "tumblrNormalizedDiv": "number",

        "instagramCount": "number",
        "instagramUniverse": "number",
        "instagramNormalizedUniverse": "number",
        "instagramNormalized": "percent",
        "instagramNormalizedDiv": "number",

        "lexisnexisCount": "number",
        "lexisnexisUniverse": "number",
        "lexisnexisNormalizedUniverse": "number",
        "lexisnexisNormalized": "percent",
        "lexisnexisNormalizedDiv": "number",

        "pinterestCount": "number",
        "pinterestUniverse": "number",
        "pinterestNormalizedUniverse": "number",
        "pinterestNormalized": "percent",
        "pinterestNormalizedDiv": "number",

        "weiboCount": "number",
        "weiboUniverse": "number",
        "weiboNormalizedUniverse": "number",
        "weiboNormalized": "percent",
        "weiboNormalizedDiv": "number",

        "youtubeCount": "number",
        "youtubeUniverse": "number",
        "youtubeNormalizedUniverse": "number",
        "youtubeNormalized": "percent",
        "youtubeNormalizedDiv": "number",

        "redditCount": "number",
        "redditUniverse": "number",
        "redditNormalizedUniverse": "number",
        "redditNormalized": "percent",
        "redditNormalizedDiv": "number",

        "tiktokCount": "number",
        "tiktokUniverse": "number",
        "tiktokNormalizedUniverse": "number",
        "tiktokNormalized": "percent",
        "tiktokNormalizedDiv": "number",

        "vkCount": "number",
        "vkUniverse": "number",
        "vkNormalizedUniverse": "number",
        "vkNormalized": "percent",
        "vkNormalizedDiv": "number",

        "uncategorizedCount": "number",
        "uncategorizedUniverse": "number",
        "uncategorizedNormalizedUniverse": "number",
        "uncategorizedNormalized": "percent",
        "uncategorizedNormalizedDiv": "number",
    }, [
        DataSeriesComputedFieldDistribution.fieldSetup([
            "blogsCount",
            "microblogsCount",
            "twitterCount",
            "imagesCount",
            "forumsCount",
            "facebookCount",
            "newsCount",
            "tumblrCount",
            "instagramCount",
            "lexisnexisCount",
            "pinterestCount",
            "weiboCount",
            "youtubeCount",
            "redditCount",
            "tiktokCount",
            "vkCount",
            "uncategorizedCount"
        ]),
        DataSeriesComputedFieldDistribution.fieldSetup([
            "blogsNormalizedUniverse",
            "microblogsNormalizedUniverse",
            "twitterNormalizedUniverse",
            "imagesNormalizedUniverse",
            "forumsNormalizedUniverse",
            "facebookNormalizedUniverse",
            "newsNormalizedUniverse",
            "tumblrNormalizedUniverse",
            "instagramNormalizedUniverse",
            "lexisnexisNormalizedUniverse",
            "pinterestNormalizedUniverse",
            "weiboNormalizedUniverse",
            "youtubeNormalizedUniverse",
            "redditNormalizedUniverse",
            "tiktokNormalizedUniverse",
            "vkNormalizedUniverse",
            "uncategorizedNormalizedUniverse"
        ]),
        DataSeriesComputedFieldDistribution.fieldSetup([
            "blogsUniverse",
            "microblogsUniverse",
            "twitterUniverse",
            "imagesUniverse",
            "forumsUniverse",
            "facebookUniverse",
            "newsUniverse",
            "tumblrUniverse",
            "instagramUniverse",
            "lexisnexisUniverse",
            "pinterestUniverse",
            "weiboUniverse",
            "youtubeUniverse",
            "redditUniverse",
            "tiktokUniverse",
            "vkUniverse",
            "uncategorizedUniverse"
        ]),
    ])
});
