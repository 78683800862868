/* jshint maxlen: false */

// Search Fields - these are allowed tokens for searching text, for example:
//    bio: asdf OR body: qwerty
// They should be provided as an array of options, like:
// ["body", "author", "html_url", "feed_url", "title", "description", "handle", "site", "bio"];
// By default it is null, which permits any arbitrary search field
CodeMirror.defineOption("atlasSearchFields", []);

// "," sometimes or in others, warn if not in appropriate place
// "||" should be single, warn

// At least 2 letters long w/o whitespace

// Operators - can't double up, can't start with OR or AND
// NEAR/45 no spaces can be 0
var query_binary_operators = [
    /^(OR(\s|$))|(O$)/,
    /^(AND(\s|$))|((AN|A)$)/,
    /^(O?NEAR\/[0-9]+(?=\s|$))|((O?NEAR\/|NEAR|NEA|NE|N)$)/,
    /^\|(\s|$)/
];

var query_unary_operators = [
    /^(NOT(\s|$))|((NO|N)$)/,
    /\s\-(?=\S)/,
    /^(\?)(?![\W\s0-9])/
];

// ~ - at end, can have "0." precision following
// * - wildcard middle or end but not beginning
// ? - single character substitution
var mid_or_after_word_operators = [
    /^\*(?![0-9])/,
    /^\?/,
    /^~($|\s|0\.[0-9]+)/
];

var mid_or_after_word_errors = [
    /^~(0\.[0-9]+[^\s]|0\.[^0-9]+|0[^\.]|[^\s0]+)/
];

var operator_errors = [
    /^([\s\)\"]\?[\s\(\"]|\?[0-9]+|[0-9]+\?)/,
];

var lowercase_operators = [
    /^([Oo]r(?=\s|$))/,
    /^([Ww]ithin(?=\s|$))/,
    /^([Aa]nd(?=\s|$))/,
    /^([Nn]ot(?=\s|$))/,
    /^([Oo]?[Nn]ear[\/\\][1-9][0-9]*(?=\s|$))/,
    /^(NEAR\\[1-9][0-9]*(?=\s|$))/
];

var start_of_query_errors = [
    /\s*(AND|OR|\||O?NEAR*)/
];

// within: "*"~4
// "*" WITHIN needs spaces (0 is error)
// quorom: ""/435 no spaces

var after_string = [
    /^\s+(WITHIN\s+[1-9][0-9]*(?=\s|\)|$))|((WITHIN\s+|WITHIN|WITHI|WITH|WIT|WI|W)$)/,
    /^(\~[1-9]+[0-9]*(?=\s|\)|$))|(\~$)/,
    /^(\/[1-9]+[0-9]*(?=\s|\)|$))|(\/$)/,
    /^(\*)/
];

var after_string_errors = [
    /^((WITHIN\s*[^\s]*)|(\sWITHIN\s+[^1-9][^\s]*))/,
    /^((\s+\~[^\s]*)|(\~[^1-9][^\s]*))/,
    /^((\s+\/[^\s]*)|(\/[^1-9][^\s]*))/
];

// hash tags and @names #"asdgasgd" no
var query_twits = /^[#@]{1}[^\s^\:^\(^\"^\)]+/;

var countParens = function(stream) {
    var remainingParens = stream.match(/[\(\)]/g, false);
    var remainingParenCount = null;
    if (remainingParens) {
        remainingParens.forEach(function(paren) {
            if (remainingParenCount === 0) {
                return;
            } else if (paren === "(") {
                remainingParenCount++;
            } else if (paren === ")") {
                remainingParenCount--;
            }
        });
    }
    return remainingParenCount;
};

var matchAnyInArray = function(stream, array, consume) {
    for (var idx = 0; idx < array.length; idx++) {
        var key = stream.match(array[idx], consume);
        if (key) {
            return key;
        }
    }
    return false;
};

CodeMirror.defineMode("atlas_query", function() {
    return {
        startState: function() {
            return {
                inString: false,
                wasString: false,
                startOfLine: false,
                parenLevel: 0,
                wasWord: false,
                inError: false,
                types: [],
                lastTypes: []
            };
        },
        token: function(stream, state) {
            if (stream.sol() || state.startOfLine) {
                if (stream.match(/\s+/)) {
                    state.startOfLine = true;
                    return;
                }
                state.startOfLine = false;
                if (matchAnyInArray(stream, start_of_query_errors, true)) {
                    return "error";
                }
            }
            var types = [];

            if (state.wasWord) {
                state.wasWord = false;
                if (matchAnyInArray(stream, mid_or_after_word_errors, true)) {
                    return "error";
                }
                if (matchAnyInArray(stream, mid_or_after_word_operators, true)) {
                    return "operator";
                }
            }

            if (matchAnyInArray(stream, operator_errors, true)) {
                return "error";
            }

            if (!stream.match(/\s+/, false)) {
                state.lastTypes = state.types;
                state.types = types;
            }

            // Add inner parenthesis styling
            if (state.parenLevel > 0) {
                types.push("queryparen-" + Math.min(state.parenLevel, 5));
            }
            if (state.inError) {
                types.push("error");
            }

            // Stuff that comes after strings
            if (matchAnyInArray(stream, after_string, true)) {
                if (state.wasString) {
                    types.push("string");
                } else {
                    types.push("error");
                }
                state.wasString = false;
                return types.join(" ");
            }
            state.wasString = false;

            // Stuff that should come after strings, but is errored
            if (matchAnyInArray(stream, after_string_errors, true)) {
                types.push("error");
                return types.join(" ");
            }

            // Strings
            if (stream.peek() === '"') {
                stream.next();
                state.inString = true;
                types.push("operator");
                if (!stream.skipTo('"')) {
                    types.push("error");
                    stream.skipToEnd();
                }
                types.push("string");
                state.wasString = true;
                state.inString = false;
                stream.next();
                return types.join(" ");
            }

            // Numbers
            if (stream.peek().match(/[0-9]/)) {
                if (!stream.match(/[^0-9]/, true)) {
                    stream.next();
                }
                types.push("number");
                return types.join(" ");
            }


            // Parenthesis start
            // (and nesting with unique styles to 5 deep)
            if (stream.peek() === "(") {
                state.parenLevel++;

                var remainingParenCount = countParens(stream);
                if (remainingParenCount) {
                    types.unshift("error");
                    state.inError = true;
                    stream.next();
                    return types.join(" ");
                }

                stream.next();
                types.push("queryparen-" + Math.min(state.parenLevel, 5));
                types.push("operator");
                types.push("lparen");
                return types.join(" ");
            }


            // Parenthesis end
            if (stream.peek() === ")") {
                state.parenLevel--;
                if (state.parenLevel < 0) {
                    types.push("error");
                    stream.skipToEnd();
                    return types.join(" ");
                }

                stream.next();
                types.push("queryparen-" + Math.min(state.parenLevel, 5));
                types.push("operator");
                types.push("rparen");
                return types.join(" ");
            }

            // Fields
            let searchFields = stream.lineOracle.doc.cm.getOption("atlasSearchFields"),
                searchFieldsString = searchFields && searchFields.join("|"),
                searchFieldsRegExp = searchFields && new RegExp(`^(${searchFieldsString})\s*:`);
            if (stream.match(/^\S+:/, false)) {
                if (searchFields && stream.match(searchFieldsRegExp, true)) {
                    types.push("field");
                    return types.join(" ");
                } else if (stream.match(/^[\w\-\_]+\s*:/, true)) {
                    if (!Ember.isEmpty(searchFields)) {
                        types.push("error");
                    } else {
                        types.push("field");
                    }
                    return types.join(" ");
                }
            }


            // Twitter Hashtags and @ stuff
            var twitMatches = stream.match(query_twits, true);
            if (twitMatches) {
                types.push("twit");
                return types.join(" ");
            }

            // Operators
            if (matchAnyInArray(stream, query_unary_operators, true)) {
                types.push("operator");
                return types.join(" ");
            }
            if (matchAnyInArray(stream, lowercase_operators, true)) {
                types.push("warning");
                return types.join(" ");
            }

            // Error checking
            if (!stream.sol() && !state.startOfLine && matchAnyInArray(stream, query_binary_operators, true)) {
                if (!(state.lastTypes.includes("string") || state.lastTypes.includes("rparen")) &&
                    (state.lastTypes.includes("operator") || state.lastTypes.includes("field"))) {
                    types.push("error");
                    return types.join(" ");
                } else {
                    types.push("operator");
                    return types.join(" ");
                }
            }

            if (stream.string.slice(stream.pos).match(/^\w+[^\s\w]/) && state.inString === false) {
                state.wasWord = true;
                stream.eatWhile(/\w/);
                return types.join(" ");
            }

            if (stream.match(/[^\s\(\)\"]+/, true)) {
                return types.join(" ");
            }


            if (stream.match(/\s+/, true)) {
                return types.join(" ");
            }

            // Default
            stream.next();
            return types.join(" ");

        }
    };
});

var atlas_query_map = CodeMirror.keyMap.atlas_query = {
    fallthrough: "default"
};

atlas_query_map.Tab = function(cm) {
    return CodeMirror.pass;
};

var atlas_query_viewer_map = CodeMirror.keyMap.atlas_query_viewer = {
    fallthrough: false
};
atlas_query_viewer_map.Tab = function(cm) {
    return CodeMirror.pass;
};
