import Ember from "ember";
import {computed, action} from "@ember/object";
import TrendChartSVG from "infegy-frontend/components/charting/trend-chart/trend-chart-svg/component";
import d3 from "d3";

var last = 0;

export default class extends TrendChartSVG {
    isCanvasChart = false;

    @computed("chartType")
    get isBarChart() {
        return this.chartType === "bars";
    }

    @computed("chartType", "stackedFields", "trendFields")
    get stackedData() {
        let stackedFields = this.stackedFields || [],
            trendFields = this.trendFields || [],
            keys = new Array(trendFields.length).fill(null).map((row, idx) => idx),
            stack = d3.stack();
        if (this.chartType === "stream") {
            stack = stack.offset(d3.stackOffsetWiggle)
        }

        let stackedAccessor = stack.keys(keys).value((d, key) => {
                return d?.fieldRows[key]?.yPct || 0.0;
            }),
            stacked = stackedAccessor(stackedFields);

        let stackedFieldsData = trendFields.map((field, fieldIndex) => {
            let data = stacked[fieldIndex];
            return { field: field, data: data }
        });
        return stackedFieldsData;
    }

    @computed("hideDots", "isBarChart", "isCanvasChart")
    get dotPadding() {
        if (this.isBarChart) {
            return 0;
        }
        let padding = this.isCanvasChart ? 5 : 0;
        return padding;
    }

    @computed("isCanvasChart", "width", "height")
    get xAxisPoints() {
        let points = {
            x1: this.isCanvasChart ? 5 : 0,
            x2: this.isCanvasChart ? this.width - 5 : this.width,
            y1: this.height - this.dotPadding,
            y2: this.height - this.dotPadding
        };
        return points;
    }

    @computed("xRange", "width")
    get xScale() {
        let start = this.xRange?.start || 0,
            end = this.xRange?.end || 1;
        return d3.scaleLinear()
            .domain([start, end])
            .range([this.dotPadding, this.width - this.dotPadding || 1]);
    }

    @computed("stackedFields", "xScale")
    get xDataPoints() {
        let data = this.stackedFields || [],
            xScale = this.xScale;
        let dataPoints = xScale && data.map(function(row) {
            return {
                x: xScale(row.x || 0)
            };
        });
        return dataPoints;
    }

    @computed("width", "xDataPoints")
    get xBarScale() {
        return d3.scaleBand()
            .domain(this.xDataPoints.map(d => d.x))
            .range([0, this.width || 1])
            .paddingInner(0.2)
            .paddingOuter(0.2);
    }


    findClosestRowsFromPct(trendFields, xPct) {
        let closest = this.trendChartMeta.findClosestRowsFromPct(trendFields, xPct);
        return closest;
    }

    @computed("stackedData", "chartType")
    get yRange() {
        var stackData = this.stackedData || [],
            range = {
                min: Infinity,
                max: -Infinity
            };
        (this.stackedData || []).forEach(row => {
            let data = row.data,
                rowMax = Math.max.apply(null, data.map(innerRow => Math.max(innerRow[1], innerRow[0]))),
                rowMin = Math.min.apply(null, data.map(innerRow => Math.min(innerRow[1], innerRow[0])));
            range.max = Math.max(range.max, rowMax);
            range.min = Math.min(range.min, rowMin);
        });
        if (this.chartType !== "stream") {
            range.min = 0;
        }
        return range;
    }

}
