import Ember from "ember";

export default Ember.Component.extend({
    tagName: "tr",
    classNameBindings: [":data-table-row", "isEven:data-table-row-even"],
    viewField: "DocumentsDistribution",

    theme: null,
    query: null,
    themeData: Ember.computed("sortedThemeData", "query", function () {
        var data = this.sortedThemeData,
            query = this.query;
        data = data.map(theme => {
            var queryTheme = (Ember.get(theme, "queriesData") || []).find(qData => {
                return Ember.get(qData, "query") === query;
            });
            return queryTheme;
        });
        return data;
    }),
});
