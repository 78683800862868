import Ember from "ember";
import BaseFieldSelections from "infegy-frontend/models/fields/field-selections/base";

export default BaseFieldSelections.extend({
    apiName: "channels",
    defaultSubSegment: "Passion",

    availableChannels: Ember.computed("allQueries", "allQueries.[]", "allQueries.@each.channels", "allQueries.@each.updated", {
        get: function(key) {
            var allQueries = this.allQueries || [],
                channels = [],
                hasUncategorized = false;
            allQueries.forEach(function(query) {
                channels = channels.concat(query.get("channels.availableChannels"));
            });
            channels = channels.uniq().filter(function (channel) {
                if (channel === "uncategorized") {
                    hasUncategorized = true;
                    return false;
                }
                return true;
            }).map(function(channel) {
                return {
                    channel: channel,
                    count: allQueries.reduce(function(memo, query) {
                            var channelResults = query.get("channels.stats." + channel + "Universe.sum") || 0;
                            return memo - channelResults;
                        }, 0)
                };
            }).sortBy("count").mapBy("channel");

            if (hasUncategorized) {
                channels.push("uncategorized");
            }
            return channels;
        }
    }),

    makeChannelFields: function (baseFields, suffix) {
        var that = this;
        return baseFields.map(function (baseField) {
            return that.makeFieldDefinition({
                title: baseField,
                fieldName: baseField + suffix,
                suggestedColorClass: "atlas-" + baseField,
                inactiveByDefault: baseField === "uncategorized"
            });
        });
    },

    allAvailableFields: Ember.computed("availableChannels", {
        get: function(key) {
            var baseFields = this.availableChannels;
            return {
                "Channels.UniverseDistribution" : this.makeChannelFields(baseFields, "UniverseDistribution"),
                "Channels.CountDistribution" : this.makeChannelFields(baseFields, "CountDistribution"),
                "Channels.Universe" : this.makeChannelFields(baseFields, "Universe"),
                "Channels.Count" : this.makeChannelFields(baseFields, "Count"),
            };
        }

    })
});
