import Ember from "ember";
import d3 from "d3";
import { isEmpty } from '@ember/utils';

export default Ember.Component.extend({
    tagName: 'g',
    data:null,
    stats:null,
    field: null,

    stack:null,
    colors:null,
    selected:null,
    hoveredIndex: null,

    isHovered: false,
    hoveredXPct: null,

    height: Ember.computed.alias("contentArea.height"),
    width: Ember.computed.alias("contentArea.width"),

    hasSelected: Ember.computed.or("selectedTopRange", "selectedBottomRange"),

    selectedTopRange: Ember.computed("stack.top", "selected.maleStart", "selected.femaleStart",  "selected.maleEnd", "selected.femaleEnd", {
        get:function(){
            var selected = this.selected,
                top = this.get("stack.top");
            if(!selected || Ember.isEmpty(selected[top+"Start"]) && Ember.isEmpty(selected[top+"End"])){
                return null;
            }
            return {
                start: selected[top+"Start"],
                end: selected[top+"End"]
            };
        }
    }),

    selectedBottomRange: Ember.computed("stack.bottom", "selected", "selected.maleStart", "selected.femaleStart",  "selected.maleEnd", "selected.femaleEnd", {
        get:function(){
            var selected = this.selected,
                bottom = this.get("stack.bottom");
            if(!selected || Ember.isEmpty(selected[bottom+"Start"]) && Ember.isEmpty(selected[bottom+"End"])){
                return null;
            }
            return {
                start: selected[bottom+"Start"],
                end: selected[bottom+"End"]
            };
        }
    }),

    midHeight: Ember.computed("height", {
        get:function(){
            return (this.height || 0) / 2;
        }
     }),

    yMax: Ember.computed("stack",{
        get: function(){
            var stats = this.stats,
                stack = this.stack;

            if(!stack || !stats){
                return 0;
            }

            return Math.max(stats[stack.top + 'Max'], stats[stack.bottom + 'Max']);
        }
    }),

    xScale: Ember.computed("data", "width", {
        get:function(){
            var data = this.data || [],
                width = this.width || 0;

            return d3.scaleLinear()
                .domain(d3.extent(data, (d) => d.date ))
                .range([0, width]);
        }
    }),

    hoveredLineX: Ember.computed("xScale", "hoveredIndex", "data", function () {
        var data = this.data || [],
            index = this.hoveredIndex || 0,
            xVal = !Ember.isEmpty(index) && data[index] && data[index].date || 0;
        return this.xScale(xVal);
    }),

    selectedLineX: Ember.computed("xScale", "selectedIndex", "data", function () {
        var data = this.data || [],
            index = this.selectedIndex || 0,
            xVal = !Ember.isEmpty(index) && data[index] && data[index].date || 0;
        return this.xScale(xVal);
    }),

    yTopScale: Ember.computed("yMax", "midHeight", {
        get:function(){
            var max = this.yMax,
                midHeight = this.midHeight || 0;

            return d3.scaleLinear()
                .domain([0, max])
                .range([midHeight, 0]);
        }
    }),

    yBottomScale: Ember.computed("data", "width", "height", {
        get:function(){
            var height = this.height,
                midHeight = this.midHeight,
                max = this.yMax;

            return d3.scaleLinear()
                .domain([0, max])
                .range([midHeight, height]);
        }
    }),

    topAreaFunction: Ember.computed("data", "xScale", "yTopScale", "midHeight", "stack.top", {
        get:function() {
            var x = this.xScale,
                y = this.yTopScale,
                top = this.get("stack.top") || "",
                midHeight = this.midHeight || 0;

            return d3.area()
                .curve(d3.curveMonotoneX)
                .x( (d) => x(d.date) )
                .y0(midHeight)
                .y1( (d) => y(d[top]) );
        }
    }),

    bottomAreaFunction: Ember.computed("data", "xScale", "yBottomScale", "midHeight", "stack.bottom", {
        get:function(){
            var x = this.xScale,
                y = this.yBottomScale,
                bottom = this.get("stack.bottom") || "",
                midHeight = this.midHeight || 0;

            return d3.area()
                .curve(d3.curveMonotoneX)
                .x( (d) => x(d.date) )
                .y0(midHeight)
                .y1( (d) => y(d[bottom]) );
        }
    }),

    selectedTopArea: Ember.computed("data", "stack.top", "selectedTopRange", {
        get:function(){
            var data = this.data || [],
                area = this.topAreaFunction,
                colors = this.colors || [],
                top = this.get("stack.top") || "",
                range = this.selectedTopRange || {},
                selectedData = [];

            if(!range.start || !range.end){
                return null;
            }

            for (var i = 0; i < data.length; i++) {
                var date_ts = data[i].date / 1000;
                if (date_ts >= range.start && date_ts <= range.end) {
                    selectedData.push(data[i]);
                }
            }

            return {
                d:area(selectedData),
                fill: colors[top]
            };
        }
    }),

    selectedBottomArea: Ember.computed("data", "stack.bottom", "selectedBottomRange", {
        get:function(){
            var data = this.data || [],
                area = this.bottomAreaFunction,
                colors = this.colors || [],
                bottom = this.get("stack.bottom") || "",
                range = this.selectedBottomRange || {},
                selectedData = [];

            if(!range.start || !range.end){
                return null;
            }

            for (var i = 0; i < data.length; i++) {
                var date_ts = data[i].date / 1000;
                if (date_ts >= range.start && date_ts <= range.end) {
                    selectedData.push(data[i]);
                }
            }

            return {
                d:area(selectedData),
                fill: colors[bottom]
            };
        }
    }),

    topArea: Ember.computed("data", "stack.top", "colors", "topAreaFunction", "hasSelected", {
        get:function(){
            var data = this.data || [],
                colors = this.colors || [],
                top = this.get("stack.top") || "",
                area = this.topAreaFunction,
                hasSelected = this.hasSelected;

            return {
                d:area(data),
                fill: colors[top],
                opacity: hasSelected ? "0.4" : "0.6"
            };
        }
    }),

    bottomArea: Ember.computed("data", "stack.bottom", "colors", "bottomAreaFunction", "hasSelected", {
        get:function(){
            var data = this.data || [],
                bottom = this.get("stack.bottom") || "",
                colors = this.colors || [],
                area = this.bottomAreaFunction,
                hasSelected = this.hasSelected;

            return {
                d: area(data),
                fill: colors[bottom],
                opacity: hasSelected ? "0.4" : "0.6"
            };
        }
    }),

    topLine: Ember.computed("data", "xScale", "yTopScale", "colors", "stack.top", {
        get:function(){
            var data = this.data || [],
                x = this.xScale,
                y = this.yTopScale,
                top = this.get("stack.top"),
                colors = this.colors || {},
                line = d3.line()
                    .curve(d3.curveMonotoneX)
                    .x( (d) => x(d.date) )
                    .y( (d) =>  y(d[top]) );

            return {
                d: line(data),
                stroke: colors[top]
            };
        }
    }),

    bottomLine: Ember.computed("data", "xScale", "yBottomScale", "colors", "stack.bottom", {
        get:function(){
            var data = this.data || [],
                x = this.xScale,
                yBottom = this.yBottomScale,
                bottom = this.get("stack.bottom") || "",
                colors = this.colors || {},
                line = d3.line()
                    .curve(d3.curveMonotoneX)
                    .x( (d) => x(d.date) )
                    .y( (d) => yBottom(d[bottom]) );

            return {
                d: line(data),
                stroke: colors[bottom]
            };
        }
    }),

    showHoveredIndex: Ember.computed("isHovered", "hoveredIndex", function(){
        return this.isHovered || !isEmpty(this.hoveredIndex);
    }),

    mouseXChanged() { /* empty for eventing */ },

    actions: {
        wasHovered(info) {
            this.set("isHovered", info.isHovered);
            this.set("hoveredXPct", info.mouseXPct);
            this.mouseXChanged(info.isHovered ? info.mouseXPct : null);
        }
    }
});
