import Ember from 'ember';

var fullNames = [ "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December" ],
    shortNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug",
        "Sept", "Oct", "Nov", "Dec"];

export function iso2Name(params, namedArgs) {
    var monthId = (params[0] || 0) % 12;

    if (namedArgs.length === "short") {
        return shortNames[monthId];
    }
    return fullNames[monthId];
}

export default Ember.Helper.helper(iso2Name);
