import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import DataSeriesComputedFieldPercentage from "infegy-frontend/models/data_series/data_series_computed_field_percentage";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";
import fieldQuotient from "infegy-frontend/models/data_series/data_series_computed_field_quotient";

export default QueryAPIResults.extend({
    title: "emotions",
    preload: function() {
        var rawResponse = this.rawResults,
            description = DataSeriesDescription.load({
                "date": "datestring",
                "timestamp": "timestamp",
                "documents": "number",
                "universe": "number"
              }),
            emotionNames = [],
            newFields;

        rawResponse.map((row) => {
          var rowEmotions = {};

          row.emotions.forEach((emotion) => {
            if(!emotionNames.includes(emotion.name.camelize())){
              emotionNames.push(emotion.name.camelize());
            }
            rowEmotions[emotion.name] = emotion;
            row[emotion.name] = emotion;
          });
          row.emotions = rowEmotions;
          return row;
        });

        emotionNames.forEach((emotionName) => {
          newFields = {};
          newFields["emotions" + emotionName.capitalize() + "Appearances"] = "number";
          newFields["emotions" + emotionName.capitalize() + "Documents"] = "number";
          newFields["emotions" + emotionName.capitalize() + "Name"] = "string";
          newFields["emotions" + emotionName.capitalize() + "Universe"] = "number";

          newFields[emotionName + "Appearances"] = "number";
          newFields[emotionName + "Documents"] = "number";
          newFields[emotionName + "Name"] = "string";
          newFields[emotionName + "Universe"] = "number";

          description.addFields(newFields, true);
          description.addComputedFields([
              DataSeriesComputedFieldPercentage.fieldSetup(`${emotionName}DocumentsDistribution`, `documents`, `${emotionName}Documents`),
              fieldQuotient.fieldSetup(`${emotionName}AppearancesPerPost`, `${emotionName}Appearances`, `${emotionName}Documents`),
              DataSeriesComputedFieldPercentage.fieldSetup(`${emotionName}UniverseDistribution`, `universe`, `${emotionName}Universe`),
          ]);
        });

        var allEmotions = emotionNames;
        this.set("availableEmotions", allEmotions);
        this.set("description", description);
    }
});
