import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import fieldQuotient from "infegy-frontend/models/data_series/data_series_computed_field_quotient";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";

export default QueryAPIResults.extend({
    title : "numericStats",
    checkIfEmpty: function (dataSeries) {
        return !dataSeries || !dataSeries.get("stats") ||
            !dataSeries.get("stats.count") ||
            !dataSeries.get("stats.count.sum");
    },
    description: DataSeriesDescription.load({
        "date": "datestring",
        "timestamp": "timestamp",

        "groupName": "string",

        "sum": "number",
        "min": "average",
        "max": "average",
        "count": "number",
        "mean": "average",
    }, [
        fieldQuotient.fieldSetup("average", "sum", "count"),
    ])
});
