import Ember from "ember";
import AtlasConfig from "infegy-frontend/config/infegy-app-config";

var thirtyDaysAgo = moment.utc().subtract(30, "days");

export default Ember.Object.extend({

    year: thirtyDaysAgo.year(),
    month: thirtyDaysAgo.month(),
    day: thirtyDaysAgo.date(),

    daysInMonth: Ember.computed("year", "month", "day", function () {
        var days = new Date(this.year, this.month, "0").getDate();
        if (this.day > days) {
            this.set("day", days);
        }
        return days;
    }),

    fromMoment: function(moment) {
        this.set("year", moment.get("year"));
        this.set("month", moment.get("month"));
        this.set("day", moment.get("date"));
    },
    isOnOrAfter: function(refTimestamp) {
        var timestamp = Date.UTC(this.year,
                            this.month,
                            this.day);
        return timestamp >= refTimestamp;
    },
    toJSON: function() {
        return {
            "year": this.year,
            "month": this.month,
            "day": this.day
        };
    },
    toISOString: function(isEndDate) {
        let d = new Date();
        d.setUTCFullYear(this.year, this.month, this.day);
        if (isEndDate) {
            d.setUTCHours(23, 59, 59, 0);
        } else {
            d.setUTCHours(0, 0, 0, 0);
        }
        return d.toISOString();
    },

    toTimestamp: function(){
        var year = this.year,
            month = this.month,
            day = this.day;
        return Date.UTC(year, month, day);
    },
    fromTimestamp: function(timestamp) {
        var jsDate = new Date(timestamp);

        this.set("year", jsDate.getUTCFullYear());
        this.set("month", jsDate.getUTCMonth());
        this.set("day", jsDate.getUTCDate());
    },
    // Setter supports either YYYY/MM/DD or MM/DD/YYY
    string: Ember.computed("year", "month", "day", {
        get: function() {
            return [this.year, this.month + 1, this.day].join("-");
        },
        set: function(key, value) {
            var parts = value.trim().split(/[ ,/_-]+/),
                year, month, day;
            if (parseInt(parts[0], 10) > 31) {
                year = parseInt(parts[0], 10);
                month = parseInt(parts[1], 10) - 1;
                day = parseInt(parts[2], 10);
            } else {
                month = parseInt(parts[0], 10) - 1;
                day = parseInt(parts[1], 10);
                year = parseInt(parts[2], 10);
            }

            this.setProperties({
                    "year": year,
                    "month": month,
                    "day": day
                });

            return [year, month + 1, day].join("-");
        }
    }),
    timestamp: Ember.computed("year", "month", "day", function () {
        return this.toTimestamp();
    }),
    limitValue: function(earliestDate) {
        earliestDate = earliestDate || AtlasConfig.EarliestAtlasTimestamp;
        earliestDate = new Date(earliestDate);
        var latestDate = new Date();

        var year = this.year || 0,
            month = this.month || 0,
            day = this.day || 0;
        if (new Date(year, month, day).valueOf() <  earliestDate.valueOf()) {
            if (year < earliestDate.getUTCFullYear()) {
                this.set("year", earliestDate.getUTCFullYear());
            }
            if (month < earliestDate.getUTCMonth()) {
                this.set("month", earliestDate.getUTCMonth());
            }
            if (day < earliestDate.getUTCDate()) {
                this.set("day", earliestDate.getUTCDate());
            }
            return -1;
        } else if (new Date(year, month, day).valueOf() > latestDate.valueOf()) {
            if (year > latestDate.getUTCFullYear()) {
                this.set("year", latestDate.getUTCFullYear());
            }
            if (month > latestDate.getUTCMonth()) {
                this.set("month", latestDate.getUTCMonth());
            }
            if (day > latestDate.getUTCDate()) {
                this.set("day", latestDate.getUTCDate());
            }
            return 1;
        }
        return 0;
    }
});
