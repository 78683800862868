import Ember from "ember";
import ChartColors from "infegy-frontend/static_data/fixtures/chart_colors";

export default Ember.Component.extend({
    topicData: null,
    selectedTopic: null,
    hoveredTopic: null,

    // topic cloud settings
    aspectRatio: 1.5,
    sizeField: "score",
    topicField: "topic",
    keyField: "key",
    colorField: "netSentimentAppearancesPercent",

    sentimentColors: ChartColors.sentimentGradientD3(),

    topicHovered: () => {},
    topicSelected: () => {},
    actions: {
        topicHovered(topic) {
            this.topicHovered(topic);
        },
        topicSelected(topic) {
            this.topicSelected(topic);
        }
    }
});
