import Ember from 'ember';

export function formatPluralize(params, namedArgs) {
  var singular = ("" + namedArgs.singular) || "",
        plural = namedArgs.plural ? ("" + namedArgs.plural) : singular + "s";

    if (Math.abs(params[0]) === 1) {
        return singular;
    }
    return plural;
}

export default Ember.Helper.helper(formatPluralize);
