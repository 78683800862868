import Component from "@ember/component";
import { computed } from "@ember/object";

import { abbrNumber } from "infegy-frontend/helpers/abbr-number";

export default class AbbrNumberComponent extends Component {
    tagName = "span";
    attributeBindings = ["displayTooltip:title"];

    value = 0;

    decPlaces = undefined;
    roundToInteger = undefined;
    sigFigs = undefined;
    truncate = undefined;

    @computed("value", "decimalPlaces", "roundToInteger", "significantFigures")
    get displayValue() {
        const result = abbrNumber(this.value, {
            decPlaces: this.decPlaces,
            roundToInteger: this.roundToInteger,
            sigFigs: this.sigFigs,
            truncate: this.truncate
        });
        return result;
    }

    @computed("displayValue")
    get displayTooltip() {
        let value = this.value || 0;
        if (this.roundToInteger) {
            value = Math.round(value);
        }
        // Format the number based on the Locale (e.g. adding commas for larger numbers).
        return `${value.toLocaleString()}`;
    }
}
