import Ember from "ember";
import DataSeries from "infegy-frontend/models/data_series/data_series";
import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import fieldPercentage from "infegy-frontend/models/data_series/data_series_computed_field_percentage";
import fieldDifference from "infegy-frontend/models/data_series/data_series_computed_field_difference";
import fieldDistribution from "infegy-frontend/models/data_series/data_series_computed_field_distribution";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";
import fieldQuotient from "infegy-frontend/models/data_series/data_series_computed_field_quotient";
import fieldTimelineGrowth from "infegy-frontend/models/data_series/data_series_computed_field_growth";

var preloadTopicRow = function(topic) {
    var documents = Ember.get(topic, "documents"),
        timeline = Ember.get(topic, "timeline");
    timeline = timeline.length > 0 ? timeline : [100];
    var timelineLength = timeline.length,
        timelineSum = timeline.reduce((m,n) => m + n),
        documentsTimeline = timeline.map(n => {
            if (!timelineSum || !documents) {
                return 0;
            }
            return Math.round((n / timelineSum) * documents);
        }),
        timelineScales = timeline.map(n => {
            return timelineSum ? n / timelineSum : 0;
        }),
        halfLength = timelineLength - Math.ceil(timelineLength / 2),
        firstHalf = documentsTimeline.slice(0, halfLength),
        secondHalf = documentsTimeline.slice(-halfLength),
        firstSum = firstHalf.reduce((m,n) => m+n, 0),
        secondSum = secondHalf.reduce((m,n) => m+n, 0);

    topic.documentsTimeline = documentsTimeline;
    topic.timelineScales = timelineScales;
    topic.documentsGrowth = secondSum - firstSum;
    topic.documentsLoss = firstSum - secondSum;
    var growth = (secondSum - firstSum) / (firstSum);
    topic.cappedGrowthPct = Math.ceil(10000, Math.floor(-10000, growth));
    topic.topic = topic.topic || topic.brand || topic.word;
};

var description = DataSeriesDescription.load({
    "key": "string",
    "topic": "string",

    "query": "string",
    "score": "number",

    "appearances": "number",
    "documents": "number",

    "positiveDocuments": "number",
    "negativeDocuments": "number",

    "positiveAppearances": "number",
    "negativeAppearances": "number",

    "firstSeen": "dateString",
    "lastSeen": "dateString",
    "breakout": "dateString",

    "positiveScore": "number",
    "negativeScore": "number",

    "passion": "average",
    "intensity": "average",
    "positiveIntensity": "average",
    "negativeIntensity": "average",

    "sampleSentences": "array",
    "timeline": "array",

    "cappedGrowthPct": "average",
}, [
    fieldDistribution.fieldSetup(["positiveDocuments", "negativeDocuments"], 0.5),
    fieldDistribution.fieldSetup(["positiveAppearances", "negativeAppearances"], 0.5),
    fieldDifference.fieldSetup("netSentimentDocuments", "positiveDocuments", "negativeDocuments"),
    fieldDifference.fieldSetup("netSentimentAppearances", "positiveAppearances", "negativeAppearances"),
    fieldPercentage.fieldSetup("netSentimentDocumentsPercent", "documents", "netSentimentDocuments"),
    fieldPercentage.fieldSetup("netSentimentAppearancesPercent", "appearances", "netSentimentAppearances"),
    fieldQuotient.fieldSetup("appearancesPerPost", "appearances", "documents"),
    fieldTimelineGrowth.fieldSetup("documentsGrowthPct", "timeline")
]);

var topicsResults = QueryAPIResults.extend({
    title: "topics",
    dateField: "breakout",
    description: description,

    preload: function(response) {
        var rawResponse = response || this.rawResults;
        rawResponse.forEach((topic, i) => {
            preloadTopicRow(topic, i);
        });
    },
});

topicsResults.reopenClass({
    // This funtion combines topics into one by averaging their values
    // It assumes you are passing in an array of matching topics, and so
    //   preserves the first topic's string fields
    // The original topics will be contained in _combinedTopics
    combineMatchingTopics: function(topicsArray) {
        var filteredTopics = topicsArray.filter(topic => typeof(topic) === "object");
        if (Ember.isEmpty(filteredTopics)) {
            return {};
        }
        var numTopics = filteredTopics.length,
            firstTopic = filteredTopics.shift();
        if (numTopics === 1) {
            return Object.assign({}, firstTopic, {
                _sourceTopics: [firstTopic]
            });
        }
        var tempDataSeries = DataSeries.create(),
            newTopic = Object.assign({}, firstTopic, {
                _sourceTopics: [firstTopic]
            });
        newTopic = filteredTopics.reduce((memo, topic) => {
            memo.score += topic.score;
            memo.positiveScore += topic.positiveScore;
            memo.negativeScore += topic.negativeScore;

            memo.appearances += topic.appearances;
            memo.documents += topic.documents;

            memo.positiveDocuments += topic.positiveDocuments;
            memo.negativeDocuments += topic.negativeDocuments;

            memo.positiveAppearances += topic.positiveAppearances;
            memo.negativeAppearances += topic.negativeAppearances;

            memo.positiveIntensity += topic.positiveIntensity;
            memo.negativeIntensity += topic.negativeIntensity;

            topic.timeline.forEach((val, valIdx) => {
                memo.timeline[valIdx] += val;
            });

            memo._sourceTopics.push(topic);
            return memo;
        }, newTopic);
        newTopic.score /= numTopics;
        newTopic.positiveScore /= numTopics;
        newTopic.negativeScore /= numTopics;
        tempDataSeries.buildComputed([newTopic], description);
        return newTopic;
    }
});

export default topicsResults;
