import Ember from "ember";
import d3 from "d3";

export default Ember.Component.extend({
    classNames: ["interests-scatterplot"],

    queries: [],
    query: Ember.computed.alias("queries.firstObject"),

    hoveredInterest: null,
    selectedInterest: null,
    activeInterest: Ember.computed.or("hoveredInterest", "selectedInterest"),

    numberOfSourcesPoints: 20,

    isLoaded: Ember.computed.and("query.interests.isLoaded"),

    sourceInterestData: Ember.computed("query.interests.data", "isLoaded", "numberOfSourcesPoints", function() {
        var isLoaded = this.isLoaded;

        if (isLoaded) {
            return this.get("query.interests.data").sortBy("ratio").reverseObjects().slice(0, this.numberOfSourcesPoints);
        }
    }),

    sourceInterestDataExtents: Ember.computed("sourceInterestData", "sourceInterestData.length", function() {
        var data = this.sourceInterestData,
            distributionExtent = d3.extent(data, (item) => item.distribution),
            ratioExtent = d3.extent(data, (item) => item.ratio);

        return {
            distribution: distributionExtent,
            ratio: ratioExtent
        };
    }),
    mappedSourceInterestData: Ember.computed("sourceInterestData", "sourceInterestData.length", "sourceInterestDataExtents", function() {
        var data = this.sourceInterestData,
            extents = this.sourceInterestDataExtents;

        if (!extents) {
            return data;
        }

        var mappedData = data.map((item, index) => {
            item.score = (item.ratio / extents.ratio[1]) + (item.distribution / extents.distribution[1]);
            return item;
        });

        return mappedData;
    }),
    actions: {
        hoveredInterestChanged: function(interest) {
            this.set("hoveredInterest", interest);
        },
        selectedInterestChanged: function(interest) {
            if (interest === this.selectedInterest) {
                interest = null;
            }

            this.set("selectedInterest", interest);
        }
    }
});
