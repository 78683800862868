import Ember from 'ember';

// Modified from https://gist.github.com/davidpett/7258142
export function fileSize(value, params) {
    if (typeof value === 'undefined') {
      return null;
    }

    value = value[0];

    var numDecimals = params && params.numDecimals,
        units = ['B', 'KB', 'MB', 'GB', 'TB'],
        unit = "",
        fileSizeValue;

    if (params && !params.numDecimals) {
        numDecimals = 0;
    }

    for (var i = 0; i < units.length; i++) {
        if (Math.abs(value) < 1024) {
            value = parseFloat(value);
            unit = units[i];

            break;
        }

        value = value / 1024;
    }

    if (!value) {
        fileSizeValue = 0;
    } else if (unit !== 'B' && unit !== 'KB') {
        fileSizeValue = value.toFixed(numDecimals);
    } else {
        fileSizeValue = Math.floor(value);
    }

    return new Ember.String.htmlSafe(`<span class="filesize-value">${fileSizeValue}</span> <span class="filesize-units">${unit}</span>`);
}

export default Ember.Helper.helper(fileSize);
