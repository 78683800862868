import Ember from "ember";
import ComputedSortField from "infegy-frontend/utils/computed-sort-field";

export default Ember.Component.extend({
    classNameBindings: [":dictionaries-entry-editor"],
    entries: [],
    sortField: "word",
    sortAscending: false,
    selectedEntries: [],
    isEditingEntries: false,
    isCreatingEntry: false,

    editEntries: null,
    deleteEntry: null,
    createEntry: null,

    allowEditing: false,

    showEditor: Ember.computed.or("isEditingEntries", "isCreatingEntry"),

    currentPage: 0,
    entriesPerPage: 10,
    entriesPerPageOptions: Ember.computed("entries.[]","entries.length", function() {
        var numEntries = this.entries?.length ?? 0;

        if (numEntries < 10) {
            return [];
        }
        var allOptions = [10,25,50,150];

        var options = allOptions.filter((num) => numEntries > num).map((num) => ({
            label: num.toString(),
            value: num
        }));

        options.unshift({
            label: "All",
            value: -1
        });

        return options;
    }),

    sortedEntries: Ember.computed("entries", "entries.length", "sortField", "sortAscending", function() {
        var sorted = (this.entries || []).sortBy(this.sortField);

        if (this.sortAscending) {
            return sorted.reverse()
        }

        return sorted;
    }),

    displayEntries: Ember.computed("sortedEntries", "entriesPerPage", "currentPage", function() {
        if(!Ember.isEmpty(this.selectedEntries)){
            Ember.run.once(()=> {
                this.selectedEntries.clear();
            });
        }
        
        if (this.entriesPerPage === -1) {
            return this.sortedEntries;
        }
        
        var idx = this.currentPage * parseInt(this.entriesPerPage);
        return this.sortedEntries.slice(idx, idx + this.entriesPerPage);
    }),

    showEditButton: Ember.computed("showEditor", "selectedEntries.[]", "selectedEntries.length", function(){
        return !this.showEditor && this.get("selectedEntries.length") > 1;
    }),

    wordSort: ComputedSortField("word"),
    languageSort: ComputedSortField("language"),
    sentimentSort: ComputedSortField("sentiment"),
    themesSort: ComputedSortField("themes"),
    emotionSort: ComputedSortField("emotion"),
    exactSort: ComputedSortField("exact"),

    actions: {
        sortBy(newSortField) {
            var sortField = this.sortField;
            if (newSortField === sortField) {
                this.toggleProperty("sortAscending");
            } else {
                this.setProperties({
                    sortField: newSortField,
                    sortAscending: false
                });
            }
        },

        selectAll() {
            if(this.selectedEntries.length === this.displayEntries.length) {
                this.set("selectedEntries",[]);
            } else {
                this.set("selectedEntries", this.displayEntries.mapBy("id"));
            }
        },

        selectEntry(id) {
            var foundIDIndex = this.selectedEntries.indexOf(id);
            if (foundIDIndex !== -1) {
                this.selectedEntries.removeAt(foundIDIndex, 1);
            } else {
                this.selectedEntries.pushObject(id);
            }
        },

        entriesPerPageChanged(count) {
            this.set("entriesPerPage", count);
        },

        showCreateEntry() {
            this.setProperties({
                isCreatingEntry: true,
                isEditingEntries: false,
            });
        },

        showEditEntries() {
            this.setProperties({
                isCreatingEntry: false,
                isEditingEntries: true,
            });
        },

        cancelForm() {
            this.setProperties({
                isCreatingEntry: false,
                isEditingEntries: false,
            });
        },

        formSubmit(entries) {
            this.editEntries(entries);
            this.setProperties({
                isCreatingEntry: false,
                isEditingEntries: false,
                selectedEntries: []
            });
        },

        create(entryData) {
            this.create(entryData);
            this.setProperties({
                isCreatingEntry: false,
                isEditingEntries: false,
                selectedEntries: []
            });
        },

        clearSelectedEntries() {
            this.set("selectedEntries", []);
        },

        updated() {
            this.setProperties({
                isCreatingEntry: false,
                isEditingEntries: false,
                selectedEntries: []
            });
        }
    }
});