import CustomComputed from "infegy-frontend/utils/custom_computed";
import Ember from "ember";
import D3InteractionOverlayComponent from "infegy-frontend/components/charting/d3/d3-interaction-overlay/component";

export default D3InteractionOverlayComponent.extend({
    hoveredIndex: null,
    hoveredValue: null,
    hoveredData: null,
    hoveredPct: null,
    hoveredYPct: null,

    absoluteHoveredValue: null,

    dataSeries: null,
    valueField: "timestamp",

    stats: CustomComputed.dataSeriesStatsFor("dataSeries", "valueField"),

    update: function() {
        if (!this.isHovered) {
            this.set("hoveredIndex", null);
            this.set("hoveredValue", null);
            return;
        }

        var mouseXPct = this.mouseXPct || 0,
            stats = this.stats,
            data = this.dataSeries,
            field = this.valueField;

        if (!stats) {
            return;
        }

        var range = stats.get("max") - stats.get("min");
        var mouseVal = (mouseXPct * range) + stats.get("min");
        mouseVal = mouseVal || 0;
        this.set("absoluteHoveredValue", mouseVal);

        var closest = Infinity;
        var hoveredData = null;
        var closestIdx = null;
        data.get("data").forEach(function(row, index) {
            var val = row[field] || 0;
            if (Math.abs(val - mouseVal) < closest) {
                closestIdx = index;
                closest = Math.abs(val - mouseVal);
                hoveredData = row;
            }
        });

        closest = data.get("data")[closestIdx][field];
        this.set("hoveredIndex", closestIdx);
        this.set("hoveredValue", closest);
        this.set("hoveredPct", (closest - stats.get("min")) / (range || 1));
        this.set("hoveredData", hoveredData);
    },

    runUpdate: Ember.observer("hoveredPct", "mouseXPct", "stats", "isHovered", function() {
        Ember.run.once(this, "update");
    }),
});

