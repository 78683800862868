import Ember from "ember";
import {computed, action} from "@ember/object";
import TrendsFieldInformation from "infegy-frontend/components/charting/trend-chart/trends-information/trends-field-information/component";
import classic from "ember-classic-decorator";

@classic
export default class extends TrendsFieldInformation {
    tagName = "tr";
    classNameBindings = [":data-table-row"];

    @computed("primaryValue", "fieldMaxY", "trendField.field.color.colorString", "isOutOfBounds")
    get primaryBarStyle() {
        let primaryValue = ((this.primaryValue || 0.0) * 100.0),
            max = this.fieldMaxY || 1,
            percentage = this.isOutOfBounds ? 0 : (primaryValue / max).toFixed(1),
            color = this.trendField?.field?.color?.colorString;
        return new Ember.String.htmlSafe(`width:${percentage}%;background-color:${color};`);
    }

    @computed("field.max", "fieldMaxY", "trendField.field.color.colorString")
    get maxBarStyle() {
        let primaryValue = ((this.field?.max || 0.0) * 100.0),
            max = this.fieldMaxY || 1,
            percentage = (primaryValue / max).toFixed(1),
            color = this.trendField?.field?.color?.colorString;
        return new Ember.String.htmlSafe(`width:${percentage}%;background-color:${color};`);
    }

    @computed("field.min", "fieldMaxY", "trendField.field.color.colorString")
    get minBarStyle() {
        let primaryValue = ((this.field?.min || 0.0) * 100.0),
            max = this.fieldMaxY || 1,
            percentage = (primaryValue / max).toFixed(1),
            color = this.trendField?.field?.color?.colorString;
        return new Ember.String.htmlSafe(`width:${percentage}%;background-color:${color};`);
    }

}
