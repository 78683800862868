import Ember from "ember";
import DataSeriesComponent from "infegy-frontend/components/query-api/base/data-series/component";

export default Ember.Component.extend(DataSeriesComponent, {
    selectedGender: "female",
    dataSeries: null,

    maleStartDate: null,
    maleEndDate: null,
    femaleStartDate: null,
    femaleEndDate: null,

    hasMaleDates: Ember.computed.and("maleStartDate", "maleEndDate"),
    hasFemaleDates: Ember.computed.and("femaleStartDate", "femaleEndDate"),
    hasFilter: Ember.computed.or("hasMaleDates", "hasFemaleDates"),

    maleSum: Ember.computed("dataSeries", "maleStartDate", "maleEndDate", "hasFilter", {
        get: function(key) {
            var dataSeries = this.dataSeries;

            if (dataSeries && this.hasFilter) {
                if (!this.hasMaleDates) {
                    return 0;
                }
                dataSeries = dataSeries.filterByList([{
                    type: "range",
                    args: ["timestamp",
                        this.maleStartDate * 1000, this.maleEndDate * 1000
                    ]
                }]);
            }
            if (dataSeries) {
                return Ember.get(dataSeries, "stats.maleUniverse.sum");
            }
            return dataSeries;
        },
    }),

    femaleSum: Ember.computed("dataSeries", "femaleStartDate", "femaleEndDate", "hasFilter", {
        get: function(key) {
            var dataSeries = this.dataSeries;
            if (dataSeries && this.hasFilter) {
                if (!this.hasFemaleDates) {
                    return 0;
                }
                dataSeries = dataSeries.filterByList([{
                    type: "range",
                    args: ["timestamp",
                        this.femaleStartDate * 1000, this.femaleEndDate * 1000
                    ]
                }]);
            }
            if (dataSeries) {
                return Ember.get(dataSeries, "stats.femaleUniverse.sum");
            }
            return dataSeries;
        },
    }),

    totalSum: Ember.computed("maleSum", "femaleSum", {
        get: function(key) {
            return (this.maleSum || 0) + (this.femaleSum || 0);
        },
    }),

    isFemale: Ember.computed.equal("selectedGender", "female"),

    colorClass: Ember.computed("isFemale", {
        get: function(key) {
            return this.isFemale ? "atlas-female" : "atlas-male";
        },
    }),

    genderSum: Ember.computed("isFemale", "maleSum", "femaleSum", {
        get: function(key) {
            return this.isFemale ? this.femaleSum || 0 : this.maleSum || 0;
        },
    }),
});
