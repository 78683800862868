import Ember from 'ember';
import Component from "@ember/component";
import { computed, action } from '@ember/object';
import classic from "ember-classic-decorator";
import { argument } from "infegy-frontend/decorators/arguments";
import { tracked } from '@glimmer/tracking';
import KeyCodes from "infegy-frontend/static_data/keycodes";

@classic export default class TermList extends Component {
    @tracked focusedItem = null;
    @tracked emptyInputMessage = '';
    @tracked duplicateTermMessage = '';
    @tracked duplicateTermClass = '';

    onFocusInput() {}

    @computed("termValues", "termValues.length")
    get termListArray() {
        return this.termValues;
    }

    @action 
    listItemClicked(item) {
        this.focusedItem = item;
    }

    @action
    childInputClicked() {
        this.focusedItem = null;
    }

    @action
    addListItem(termValue) {
        if (termValue !== '') {
            if (this.termListArray.includes(termValue)) {
                this.duplicateTermMessage = `"${termValue}" already in list`;
                this.duplicateTermClass = 'duplicateTerm';

                Ember.run.later(() => {
                    this.duplicateTermMessage = '';
                    this.duplicateTermClass = '';
                }, 6000);
            } else {
                this.termListArray.pushObject(termValue);
                this.termsChangedAction(this.termListArray);
            }
        }
    }

    @action
    removeListItem(item) {
        this.termListArray.removeObject(item);
        this.termsChangedAction(this.termListArray);
    }

    @action
    termsChangedAction(currentTerms) {
        this.selectedTermsChanged(currentTerms);
    }

    @action
    inputKeyDown(e, termValue) {
        switch (e.keyCode) {
            case KeyCodes.Backspace:
                if (termValue.length <= 0 && this.termListArray) {
                    this.termListArray.removeObject(this.termListArray.lastObject);
                    this.termsChangedAction(this.termListArray);
                }
                break;
            default:
                break;
        }
    }

    @action
    onInputKeyPress(e, termValue) {
        switch (e.keyCode) {
            case KeyCodes.Enter:
                this.addListItem(termValue);
                break;
            default:
                break;
        }
    }

    @action
    onFocusOut(termValue) {
        this.addListItem(termValue);
    }
}