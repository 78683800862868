import Component from "@ember/component";
import Ember from "ember";
import {action, computed} from '@ember/object';
import classic from "ember-classic-decorator";

@classic
export default class extends Component {
    columns = null;
    posts = null;

    columnsVisible = [];

    numPostsPerPage = 25;
    currentPage = 0;

    @computed('columnsVisible.[]')
    get allRows() {
        return this.posts.map(post => {
            return this.columnsVisible.map(column => {
                return {
                    id: column.id,
                    data: post.fields[column.id]
                };
            });
        });
    }

    @computed()
    get allColumns() {
        let columns =  this.columns.map((column) => {
            return {
                id: column.id,
                label: column.name.replace('_', ' '),
                type: column.type,
                visible: false
            } || [];
        });

        // Setting the initial state of the column data on load.
        // This can change to something more robust in the future.
        if (this.columnsVisible.length === 0) {
            for(let i = 0; i < 5; i++) {
                Ember.set(columns[i], 'visible', true);
            }
            this.columnsVisible = columns.slice(0,5);
        }

        return columns;
    }

    @computed("columnsVisible.[]")
    get gridColumnsLength() {
        let allColumnsWithValue = [];
        this.columnsVisible.forEach(column => {
            allColumnsWithValue.push(column);
        });
        return Ember.String.htmlSafe(`grid-template-columns: repeat(${allColumnsWithValue.length}, minmax(19%, 1fr));`);
    }

    @computed('allRows')
    get sortedRows() {
        let allRows = this.allRows || [];
        // Filtering will go here
        return allRows;
    }

    @computed('sortedRows', 'numPostsPerPage', 'currentPage', 'allRows.[]')
    get displayedRows() {
        let sortedRows = this.sortedRows || [],
            perPage = this.numPostsPerPage || 25,
            currentPage = this.currentPage || 0;

        return sortedRows.slice(currentPage * perPage, perPage * currentPage + perPage);
    }

    @action
    addColumn(matchColumn) {
        this.columnsVisible.pushObject(matchColumn);
        this.allColumns.forEach(column => {
            if (column.id === matchColumn.id) {
                Ember.set(column, 'visible', true);
            }
        });

        // Scroll to new column
        let table = document.getElementById('custom-table'),
            x = table.scrollWidth;
        table.scrollTo({top: 0, left: x, behavior: 'smooth'});
    }

    @action
    removeColumn(matchColumn) {
        this.columnsVisible.removeObject(matchColumn);
        this.allColumns.forEach(column => {
            if (column.id === matchColumn.id) {
                Ember.set(column, 'visible', false);
            }
        });
    }
}
