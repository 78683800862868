import JsonStore from 'infegy-frontend/json-store/model';
import Prop from 'infegy-frontend/json-store/properties/model';

var AdminUserLoginHistoryModel = JsonStore.extend({
    origin: Prop.Attr(),
    user_id: Prop.Attr(),
    embedded: Prop.Attr(),
    ip: Prop.Attr(),
    company_id: Prop.Attr(),
    machine: Prop.Attr(),
    referer: Prop.Attr(),
    location: Prop.Attr(),
    time: Prop.Attr(),
    os: Prop.Attr(),
    id: Prop.Attr(),

    browser: Prop.Attr() // version/name
});

export default AdminUserLoginHistoryModel;
