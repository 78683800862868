import Ember from "ember";
import Component from '@ember/component';
import {computed, action} from "@ember/object";
import DataSeries from "infegy-frontend/models/data_series/data_series";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";

function frequencyCheck(mps) {
    var frequency = 0,
        title = '';
    if (mps > 1) {
        frequency = mps;
        title = 'Second';
    } else if (mps > 1 / 60) {
        frequency = mps * 60;
        title = 'Minute';
    } else if (mps > 1 / 3600) {
        frequency = mps * 3600;
        title = 'Hour';
    } else if (mps > 1 / 86400) {
        frequency = mps * 86400;
        title = 'Day';
    } else if (mps > 1 / 604800) {
        frequency = mps * 604800;
        title = 'Week';
    } else if (mps > 1 / 2419200) {
        frequency = mps * 2419200;
        title = 'Month';
    } else {
        frequency = mps * 29030400;
        title = 'Year';
    }

    return {
        title: 'Mentions per ' + title,
        value: frequency
    };
}

export default class extends Component {
    queries = [];
    options = null;

    hoveredTimelineIndex = null;

    @computed("queries", "queries.@each.updated")
    get mentionsPerSecond() {
        let total = this.queries.forEach((memo, query) => {
            memo += query.get('volume.dataSeries.stats.postsNormalizedUniverse.average') || 0;
            return memo;
        }, 0);
        return (total || 0) / (60 * 60 * 24);
    }

    @computed("queries")
    get color() {
        if (this.get("queries.length") === 1) {
            return this.get("queries.firstObject.colorValue");
        }
    }

    @computed("queries")
    get queryColor() {
        if (this.queries.length > 1) {
            return null;
        } else {
            return this.get("queries.firstObject.queryInfo.color");
        }
    }

    @computed("queries", "queries.length")
    get volumeDataSeries() {
        var queries = this.queries;
        if (queries?.length === 1) {
            return queries.get("firstObject.volume.dataSeries");
        }
        return DataSeries.stackTimeseries(queries.map((query)=> query.get("volume.dataSeries")));
    }

    @computed("volumeDataSeries")
    get postsUniverseVolumeData() {
        return this.volumeDataSeries?.data?.mapBy("postsNormalizedUniverse");
    }

    @computed.alias("queries.firstObject.activeGrouping") activeGrouping;

    @computed("hoveredTimelineIndex", "postsUniverseVolumeData", "volumeDataSeries", "activeGrouping")
    get activeTimelineDisplayValues() {
        var idx = this.hoveredTimelineIndex,
            volumeData = this.volumeDataSeries.data || [];
        if (Ember.isEmpty(idx) || !volumeData || idx >= volumeData.length) {
            return;
        }
        let dataRow = volumeData[idx];
        return {
            value: dataRow.postsNormalizedUniverse,
            dateValue: dataRow.timestamp
        };
    }

    @computed.mapBy("queries", "volume") volumeAPIs;

    @computed("queries", "queries.length", "volumeAPIs.@each.isLoaded")
    get reachAndImpressions() {
        return (this.queries || []).reduce((memo, query)=> {
            memo.reach += query.get(`volume.meta.reach`) || 0;
            memo.impressions += query.get(`volume.meta.impressions`) || 0;
            return memo;
        }, { reach: 0, impressions: 0 });
    }

    @computed("volumeDataSeries", "queries.firstObject")
    get combinedStats() {
        var volume = this.volumeDataSeries,
            firstQuery = this.queries?.firstObject;

        if (!volume || !firstQuery) {
            return {};
        }

        var mentionsPerSecond = volume.stats.postsNormalizedUniverse.sum / (firstQuery.queryInfo.queryTimespan / 1000),
            frequency = frequencyCheck(mentionsPerSecond);

        return {
            mpsFormatted: frequency.value,
            mpsTitle: frequency.title,
            highestVolumeDay: volume.data[volume.stats.postsNormalizedUniverse.maxIndex].date,
            min: volume.stats.postsNormalizedUniverse.min,
            max: volume.stats.postsNormalizedUniverse.max,
            average: volume.stats.postsNormalizedUniverse.average,
            sum: volume.stats.postsNormalizedUniverse.sum,
        };
    }

    @computed("combinedStats")
    get minRatio() {
        return this.combinedStats.min / this.combinedStats.max;
    }

    @computed("combinedStats")
    get avgRatio() {
        return this.combinedStats.average / this.combinedStats.max;
    }

    @action
    hoveredIndexChanged(hoveredIndex) {
        this.set("hoveredTimelineIndex", hoveredIndex);
    }
}
