import Ember from "ember";
import JsonStore from 'infegy-frontend/json-store/model';
import Prop from 'infegy-frontend/json-store/properties/model';
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import UserTypes from "infegy-frontend/static_data/fixtures/user_types";

export default JsonStore.extend({
    id: Prop.Int(),
    isReal: Ember.computed.not("id", 0),
    userId: Ember.computed.alias("id"),

    username: Prop.String(),

    name: Prop.String(),
    email: Prop.String(),
    company: Prop.Attr(), // { id, name, email }
    companyId: Ember.computed.alias("company.id"),

    timezone: Prop.String(),
    apiKey: Prop.String(),

    apiMonthlyLimit: Prop.Int(), // also known as usage.monthlyLimit
    hasUnlimitedMonthlyLimit: Ember.computed("isAdmin", "apiMonthlyLimit", function() {
        return this.isAdmin && this.apiMonthlyLimit === 0;
    }),

    lastLogout: Prop.String(), // date as string
    created: Prop.String(), // date as string

    lastLogin: Prop.Int(),
    jsLastLogin: Ember.computed(function() {
        return this.lastLogin * 1000;
    }),

    isDemo: Prop.Boolean(),

    demoStartDate: Prop.String(), // date as string
    demoStartDateAsFixed: Ember.computed("demoStartDate", function() {
        var jsDate = new Date(this.demoStartDate);

        return {
            month: jsDate.getMonth(),
            day: jsDate.getDate(),
            year: jsDate.getFullYear()
        }
    }),
    demoStartDateToPost: Ember.computed("demoStartDateAsFixed", function() {
        return new Date(this.demoStartDateAsFixed.year, this.demoStartDateAsFixed.month, this.demoStartDate.day).toISOString();
    }),

    demoExpirationInDays: Prop.Int(),

    demoLength: Ember.computed("isDemo", function() {
        return this.isDemo ? this.demoExpirationInDays : "N/A";
    }),

    isActive: Prop.Boolean(),
    type: Prop.Int(),
    isAdmin: Ember.computed.gte("type", 100),

    historicalAccessInWords: Ember.computed(function() {
        var historicalAccess = this.permissions.historicalAccess,
            thenYear = new Date(historicalAccess).getFullYear(),
            nowYear = new Date().getFullYear(),
            diff = Math.max(nowYear - thenYear, 1);

        if(thenYear <= 2007){
            return 'full';
        } else {
            return `${diff} year${diff > 1 ? 's' : '' } ago`;
        }
    }),
    userTypeInWords: Ember.computed(function() {
        let userType = this.type,
            userTypeObject = UserTypes.findBy("value", userType);

        return userTypeObject && userTypeObject.label;
    }),
    usageLeft: Ember.computed(function() {
        return this.usage.monthlyLimit - this.usage.monthlyUsed;
    }),
    hourlyUsed: Ember.computed(function() {
        return this.usage.hourlyUsed / 600;
    }),
    monthlyUsed: Ember.computed(function () {
        return this.usage.monthlyUsed / this.usage.monthlyLimit;
    }),

    logOutAllSessions: function() {
        return AtlasAuth.Post({
            url: "api/v3/logout_sessions",
            data: {
                user_id: this.id
            }
        });
    },

    saveNewRandomPassword: function(newPassword) {
        return AtlasAuth.Put({
            url: "api/v3/user_management.json",
            data: {
                "user_id": this.id,
                "password": newPassword,
                "password_reset": true
            }
        });
    },

    save: function() {
        var userJSON = this.toJSON({
            includeFields: this.fieldsToSave,
            decamelizeKeys: true
        });

        return AtlasAuth.Put({
            url: "api/v3/user_management.json",
            data: userJSON
        });
    }
});
