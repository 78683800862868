import Ember from "ember";
import Component from '@ember/component';
import { computed } from '@ember/object';
import FieldSelectorBase from "infegy-frontend/components/field-selector/parts/fields/trendable-field-base/component";
import layout from "infegy-frontend/components/field-selector/parts/fields/trendable-selector-layout/template";

export default class FieldSelectorThemes extends FieldSelectorBase {
    layout = layout;
    apiName = "themes";

    @computed("fieldType", "selectedSegment", "selectedSubsegment", "allQueries", "allQueries.[]", "allQueries.@each.themes", "allQueries.@each.updated")
    get availableThemes() {
        var allQueries = this.allQueries || [],
            themes = [];
        allQueries.forEach(function(query) {
            themes = themes.concat(query.get("themes.availableThemes") || []).uniq();
        });
        // Sort By Appearances - Ensure most important ones are first.
        themes = themes.map(function(theme) {
            return {
                theme: theme,
                count: allQueries.reduce(function(memo, query) {
                        var themeResults = query.get("themes.stats." + theme + "Appearances.sum") || 0;
                        return memo - themeResults;
                    }, 0)
            };
        }).sortBy("count");
        return themes.mapBy("theme");
    };

    makeThemesFields(baseFields, suffix, axisGroup) {
        var that = this;
        return baseFields.map(function (baseField) {
            return that.makeFieldDefinition({
                title: baseField,
                fieldName: baseField + suffix,
                axisGroup: axisGroup
            });
        });
    };

    @computed("selectedSegment", "selectedSubsegment", "availableThemes")
    get allAvailableFields() {
        var baseFields = this.availableThemes;
        return {
            "DocumentsDistribution":
                this.makeThemesFields(baseFields, "DocumentsDistribution", "percentage"),
            "PositiveDocumentsDistribution":
                this.makeThemesFields(baseFields, "PositiveDocumentsDistribution", "percentage"),
            "NegativeDocumentsDistribution":
                this.makeThemesFields(baseFields, "NegativeDocumentsDistribution", "percentage"),
            "PositiveSentimentalDocumentsDistribution":
                this.makeThemesFields(baseFields, "PositiveSentimentalDocumentsDistribution", "percentage"),
            "NegativeSentimentalDocumentsDistribution":
                this.makeThemesFields(baseFields, "NegativeSentimentalDocumentsDistribution", "percentage"),
            "PositiveDocuments":
                this.makeThemesFields(baseFields, "PositiveDocuments", "Themes.SentimentalDocuments"),
            "NegativeDocuments":
                this.makeThemesFields(baseFields, "NegativeDocuments", "Themes.SentimentalDocuments"),
            "Documents":
                this.makeThemesFields(baseFields, "Documents", "Themes.Documents"),
            "PositiveAppearancesDistribution":
                this.makeThemesFields(baseFields, "PositiveAppearancesDistribution", "percentage"),
            "NegativeAppearancesDistribution":
                this.makeThemesFields(baseFields, "NegativeAppearancesDistribution", "percentage"),
            "PositiveAppearances":
                this.makeThemesFields(baseFields, "PositiveAppearances", "Themes.PositiveAppearances"),
            "NegativeAppearances":
                this.makeThemesFields(baseFields, "NegativeAppearances", "Themes.NegativeAppearances"),
            "Appearances":
                this.makeThemesFields(baseFields, "Appearances", "Themes.Appearances"),
        };
    };
};
