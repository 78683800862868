import Ember from "ember";
import Component from '@ember/component';
import { computed, action } from '@ember/object';

export default class FieldsScalarSelector extends Component {
    classNameBindings = ["displayAsDropdown:inline-field-select:toggle-field-select"];

    firstSelectedField = null;
    fieldType = "scalar";
    displayAsDropdown = false;
    selectedValues = null;

    @computed("firstSelectedField", "fieldType", "firstSelectedField.isPortionable")
    get scalarTypeOptions() {
        if (this.firstSelectedField.isMeta) {
            return ["sum"];
        }
        let options = ["average", "max", "min"],
            field = this.firstSelectedField;
        if (this.fieldType === "portion") {
            return (field && field.isPortionable) ? ["average"] : [];
        }
        if (field && !field.isAveragedType) {
            options.unshift("sum");
            options.push("percentChange");
        }
        return options;
    }

    scalarFieldChanged() {/* action */};
    @action
    scalarFieldChanging(scalarField) {
        this.scalarFieldChanged(scalarField);
    }
};
