import Ember from "ember";
import DateProcessing from "infegy-frontend/utils/date-processing";
import ArrayBase from "infegy-frontend/models/array_compat";

// Dataseries Description Classes
//
// These classes define the fields belonging to a dataseries
//
// Fields can be one of several types:
//      "number" - Numeric values
//          Stats:
//          Grouping:
//              Grouped values collate into a sum
//
//      "percent" - Numeric values that describe a percentage (generally 0.0-1.0)
//          These are treated identically to number fields except for grouping.
//          Stats:
//              Same as number
//          Grouping:
//              Grouped values collate into an average.
//          WARNING:
//              Grouping percentage data based on varying source counts
//              requires a weighted average. The "distribution" computed
//              field type handles this situation.
//
//      "average" - Numeric values that describe an average value
//          These are treated identically to number fields except for grouping.
//          Stats:
//              Same as number
//          Grouping:
//              Grouped values collate into an average.
//          WARNING:
//              Grouping average data based on varying source counts
//              requires a weighted average. The "distribution" computed
//              field type handles this situation.
//
//      "distribution" - percentage of a total made up from the sum
//          of several source fields. This value is computed at build
//          time, and must be re-computed when grouped.
//          Stats:
//              Same as number,
//          Grouping:
//              Values are re-computed from their sources to account
//              for weighted averaging.
//
//      "datestring" - string values that describe a date
//          Grouping: Discarded
//
//      "string" - string values
//          Grouping: Discarded
//
//      "timestamp" - string values
//          Grouping: The lowest timestamp is used
//
//      "raw" - values without processing
//          Grouping: Discarded
var statsTypes = ["number", "percent", "timestamp", "distribution", "average", "index", "portion"];
var nonStatTypes = ["datestring", "string", "raw", "index", "array"];
var fieldTypes = statsTypes.concat(nonStatTypes);

var dateLookup = DateProcessing.isDateField;

var DataSeriesDescription = Ember.Object.extend({
    fields: null,
    originalFields: {},

    addFields: function(newFields, areOriginal) {
        newFields = newFields || {};
        this.set("fields", Object.assign({}, this.fields || {}, newFields));
        if (areOriginal) {
            this.set("originalFields", Object.assign({}, this.originalFields || {}, newFields));
        }
    },

    addComputedFields: function(newComputedFields) {
        var fields = this.fields || {},
            computedFields = this.computedFields || [];

        newComputedFields.forEach(function(newComputedField) {
            if (newComputedField && newComputedField.addFieldsTo) {
                fields = newComputedField.addFieldsTo(fields);
            }
            computedFields.push(newComputedField);
        });

        this.setProperties({
            "fields": fields,
            "computedFields": computedFields
        });
    },

    numberTypeFields: Ember.computed("fields", "fields.[]", function () {
        return this.indexFieldsByTypes({"number":1, "portion":1, "percent":1, "average":1});
    }),

    averagedTypeFields: Ember.computed("fields", "fields.[]", function () {
        return this.indexFieldsByTypes({"percent":1, "average":1});
    }),

    indexTypeFields: Ember.computed("fields", "fields.[]", function () {
        return this.indexFieldsByTypes({"timestamp":1, "index":1});
    }),

    arrayTypeFields: Ember.computed("fields", "fields.[]", function () {
        return this.indexFieldsByTypes({"array":1});
    }),

    indexFieldsByTypes: function(lookup) {
        var fields = this.fields,
            fieldName, result = {};
            lookup = lookup || {};
        for (fieldName in fields) {
            if (!dateLookup[fieldName] && lookup[fields[fieldName]]) {
                result[fieldName] = true;
            }
        }
        return result;
    },

    computedFields: null,

    statFields: Ember.computed("fields", "fields.[]", function() {
        var names = [],
            fields = this.fields;

        for (var name in fields) {
            if (fields.hasOwnProperty(name)) {
                var type = fields[name];
                if (statsTypes.includes(type)) {
                    names[names.length] = name;
                }
            }
        }
        return names;
    }),

    init: function() {
        this._super();
        this.set("fields", ArrayBase.create());
    }
});

DataSeriesDescription.reopenClass({
    fieldTypes: fieldTypes,
    load: function(fields, computedFields) {
        var description = DataSeriesDescription.create(),
            originalFields = Object.assign({}, fields);
            fields = fields || {};
            computedFields = computedFields || [];

        description.set("originalFields", originalFields);
        description.set("computedFields", computedFields);

        for (var i = 0; i < computedFields.length; i++) {
            if (computedFields[i] && computedFields[i].addFieldsTo) {
                fields = computedFields[i].addFieldsTo(fields);
            }
        }

        description.set("fields", fields);
        return description;
    }
});

export default DataSeriesDescription;
