var dayOfWeek = [{
        "label": "Sunday",
        "title": "Sunday",
        "value": "0",
        "description": "Sun, Posted on a Sunday in UTC"
    },{
        "label": "Monday",
        "title": "Monday",
        "value": "1",
        "description": "Mon, Posted on a Monday in UTC"
    },{
        "label": "Tuesday",
        "title": "Tuesday",
        "value": "2",
        "description": "Tues, Posted on a Tuesday in UTC"
    },{
        "label": "Wednesday",
        "title": "Wednesday",
        "value": "3",
        "description": "Wed, Posted on a Wednesday in UTC"
    },{
        "label": "Thursday",
        "title": "Thursday",
        "value": "4",
        "description": "Thurs, Posted on a Thursday in UTC"
    },{
        "label": "Friday",
        "title": "Friday",
        "value": "5",
        "description": "Fri, Posted on a Friday in UTC"
    },{
        "label": "Saturday",
        "title": "Saturday",
        "value": "6",
        "description": "Sat, Posted on a Saturday in UTC"
    }];

export default dayOfWeek;
