import Ember from "ember";
import Component from "@ember/component";
import DataSeries from "infegy-frontend/models/data_series/data_series";
import { computed, action } from '@ember/object';

export default class extends Component {
    queries = null;
    query = null;
    options = null;

    drilldownRangeStart = null;
    drilldownRangeEnd = null;
    drilldownLabel = null;

    @computed.alias('query.influenceDistribution.all') influenceDistributionDataSeries;

    @computed("drilldownRangeStart", "drilldownRangeEnd", "query")
    get drilldownInfluenceQueries() {
        if (!this.queries || Ember.isEmpty(this.drilldownRangeStart) || Ember.isEmpty(this.drilldownRangeEnd)) {
            return null;
        }

        return this.queries.map(query => {
            let newQuery = query.copy();
            newQuery.queryInfo.addFilter({
                id: "influence",
                min: this.drilldownRangeStart,
                max: this.drilldownRangeEnd
            });
            return newQuery;
        });
    }

    @computed("queries.@each.updated", "queries.@each.influenceDistribution")
    get combinedDataSeries() {
        let dataSeriesList = this.queries.mapBy("influenceDistribution.dataSeries");
        if (dataSeriesList.find(ds => !ds)) {
            return;
        }
        let temp = DataSeries.stackBy(dataSeriesList, "id");
        temp.data.sort((a, b) => {
            if (a.probability < b.probability) return 1;
            else if (a.probability > b.probability) return -1;
            else return 0;
        });
        return temp;
    }

    @computed("queries", "queries.length", "queries.@each.updated")
    get queryColor() {
        if (this.queries?.length === 1) {
            return this.query?.color;
        }
        return null;
    }

    @action
    drilldown(item) {
        if (this.drilldownRangeStart === item.rangeStart && this.drilldownRangeEnd === item.rangeEnd) {
            this.setProperties({
                drilldownRangeStart: null,
                drilldownRangeEnd: null,
                drilldownLabel: null
            });
            return;
        }
        this.setProperties({
            drilldownRangeStart: item.rangeStart,
            drilldownRangeEnd: item.rangeEnd,
            drilldownLabel: item.displayName
        });
    }
}
