import Ember from 'ember';

export default Ember.Component.extend({
    classNameBindings: [":query-alert-list-item", "isEditing"],
    alert:null,
    hasOnlyOneEmail: Ember.computed.equal("alert.emails.length",1),

    viewEmailList:false,
    isEditing:false,

    editingAlert: Ember.computed.oneWay("alert"),

    deleteAlert: () => {},
    submit: () => {},
    cancel: () => {},
    actions: {
        toggleViewEmailList(){
            this.toggleProperty("viewEmailList");
        },
        toggleIsEditing(){
            this.set('errorMessage', null);
            this.toggleProperty("isEditing");
        },
        deleteAlert(){
            this.deleteAlert(this.alert);
        },
        submit(queryAlert){
            this.submit(queryAlert);
        },
        cancel(){
            this.set("isEditing",false);
            this.cancel();
        }
    }
});
