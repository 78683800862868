import Ember from "ember";

export default Ember.Component.extend({
    classNames: ["gender-topics-list"],

    topicData: null,
    topicDataTopTen: Ember.computed("topicData", "topicData.[]", "topicData.length", function() {
        var topicData = this.topicData;
        return topicData.slice(0, 10);
    }),

    activeTopic: Ember.computed.or("selectedTopic", "hoveredTopic"),
    selectedTopic: null,
    hoveredTopic: null,

    topicHovered: () => {},
    topicSelected: () => {},
    actions: {
        topicHovered(topic) {
            this.topicHovered(topic);
        },
        topicSelected(topic) {
            this.topicSelected(topic);
        }
    }
});
