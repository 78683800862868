import Ember from "ember";
import topicsFields from "infegy-frontend/models/linguistics/topics-fields";
import TopicsAPI from "infegy-frontend/models/query-api/topics";
import ChartColors from "infegy-frontend/static_data/fixtures/chart_colors";
import { isNone } from "@ember/utils";
import d3 from "d3";
import classic from "ember-classic-decorator";

@classic
class ClusterChartSettings extends Ember.Object {
    hideLinks = false;
    hideLabels = false;

    textSizeScale = 1.0;
    nodeSizeScale = 1.0;
    maxLinksPerNode = 10;
}

export default Ember.Component.extend({
    queries: null,
    topicsType: "topics",
    topicsViewType: "node",

    topicField: "topic",
    sizeField: "score",
    colorField: null,
    nodeHighlightField: "clusterId",
    fixedColor: null,
    selectedField: null,
    selectedFieldOptions: null,

    nodeHighlightFieldOptions: [{
        label: "Hover Clusters",
        value: "clusterId"
    }, {
        label: "Hover Related Topics",
        value: "relatedTopics"
    }],

    aspectRatio: 3,

    activeTopic: null,
    activeNode: null,
    hoveredTopic: null,
    selectedDrilldownTopic: null,

    hoveredTimelineIndex: null,

    showLegend: true,
    relatedOverlap: 0.3,
    clusterOverlap: 0.2,
    numTopics: 150,
    darkMode: false,
    displayLinks: true,

    clusterChartSettings : ClusterChartSettings.create(),

    showNodeChart: Ember.computed.equal("topicsViewType", "node"),
    showCloud: Ember.computed.equal("topicsViewType", "cloud"),
    showMultiCloud: Ember.computed.equal("topicsViewType", "multi-cloud"),
    showChange: Ember.computed.equal("topicsViewType", "change"),
    showTable: Ember.computed.equal("topicsViewType", "table"),
    showTopicCluster: Ember.computed.equal("topicsViewType", "topic-cluster"),

    showSourceTopics: Ember.computed.equal("topicsType", "sourceBioTopics"),
    hasMoreThanOneQuery: Ember.computed.gt("queries.length", 1),

    maxCloudTopics: 300,
    displayTopicsCountChanged: () => { },
    displayTopicsCount: 50,
    hiddenTopics: [],
    replaceTopic: () => { },
    showDisplaySettings: false,
    trackedColorField: "",
    trackedTopicColorField: "clusterId",
    canvasElement: null,

    isEmpty: Ember.computed("isLoading", "allTopics", function () {
        return !this.isLoading && !"allTopics.length";
    }),

    volumeData: Ember.computed( "queries.[]", "queries.@each.updated", function () {
        var queries = this.queries || [],
            queryVolumeArrays = queries.map((q) => {
                return (Ember.get(q, "volume.data") || []).mapBy("postsNormalizedUniverse");
            });
        var result = queryVolumeArrays.reduce((memo, arr) => {
            arr.forEach((row, idx) => {
                memo[idx] = memo[idx] ? memo[idx] + row : row;
            });
            return memo;
        }, []);
        return result;
    }),

    fieldOptions: Ember.computed("hasMoreThanOneQuery", "showSourceTopics", function () {
        if (this.showSourceTopics) {
            return this.hasMoreThanOneQuery ? topicsFields.multiSourceQueryFieldOptions : topicsFields.sourceQueryFieldOptions;
        }
        if (this.hasMoreThanOneQuery) {
            return topicsFields.multiQueryFieldOptions;
        }
        return topicsFields.singleQueryFieldOptions;
    }),

    sizeFieldOptions: Ember.computed("fieldOptions", function () {
        return this.fieldOptions.filter((d) => !d.exclusivelyColorOption);
    }),

    timelineHovered: Ember.computed.notEmpty("hoveredTimelineIndex"),

    // Set defaults for scenarios such as multiple queries or the node chart
    innerColorField: Ember.computed("colorField", "timelineHovered", "topicsViewType", function () {
        let innerColorField = this.colorField;
        if (this.queries.length > 1) {
            innerColorField = innerColorField || "_originalQueryIdx";
        }
        if (this.topicsViewType === "node") {
            return this.trackedTopicColorField === this.trackedColorField ? innerColorField : this.trackedTopicColorField;
        }
        return this.timelineHovered ? "appearances" : innerColorField || "positiveAppearancesDistribution";
    }),

    innerSizeField: Ember.computed("sizeField", "timelineHovered", "topicsViewType", function () {
        if (this.topicsViewType === "node") {
            return this.sizeField;
        }
        return this.timelineHovered ? "appearances" : this.sizeField;
    }),

    colorFieldInfo: Ember.computed("innerColorField", function () {
        return (
            topicsFields.fieldLookups[this.innerColorField] ||
            topicsFields.fieldLookups["score"] ||
            topicsFields[0]
        );
    }),

    colorFieldStats: Ember.computed("allTopics", "innerColorField", function () {
        var colorField = this.innerColorField,
            allTopics = this.allTopics || [],
            values = allTopics.mapBy(colorField);
        return {
            min: Math.min.apply(null, values),
            max: Math.max.apply(null, values),
        };
    }),

    colorScaleType: Ember.computed("colorFieldInfo.keyType", function() {
        const keyType = this.get("colorFieldInfo.keyType");
        if (keyType === "query" || keyType === "cluster") {
            return "ordinal";
        } else {
            return "linear";
        }
    }),

    colorDomain: Ember.computed( "innerColorField", "allTopics", "colorFieldStats", "queries.[]", function() {
        var colorFieldInfo = this.colorFieldInfo,
            colorFieldStats = this.colorFieldStats;
        if (!colorFieldInfo || !colorFieldStats || colorFieldInfo.keyType === "sentiment") {
            return [0, 0.5, 1.0];
        } else if (colorFieldInfo.keyType === "query") {
            let domain = this.queries.map((query, index) => index );
            domain.unshift(-1);
            return domain;
        } else if (colorFieldInfo.keyType === "cluster") {
            // Use the total count of colors AND their variations (base, light, and dark).
            return d3.range(ChartColors.indexedColors.length * 3);
        } else if (colorFieldInfo.keyRange) {
            return colorFieldInfo.keyRange;
        }

        var min = Ember.get(colorFieldStats, "min") || 0,
            max = Ember.get(colorFieldStats, "max") || 1.0;
        return [min, (max - min) / 2 + min, max];
    }),

    colorRange: Ember.computed("colorFieldInfo.colorRange", function() {
        const keyType = this.get("colorFieldInfo.keyType");
        if (keyType === "query") {
            let range = this.queries.mapBy("queryInfo.color.colorString");
            range.unshift(ChartColors.gray.dark);
            return range;
        } else if (keyType === "cluster") {
            const colors = ChartColors.indexedColors.reduce((memo, { base, dark, light }) => {
                memo.base.push(base);
                memo.dark.push(dark);
                memo.light.push(light);
                return memo;
            }, { base: [], dark: [], light: [] });
            const range = [].concat(colors.base, colors.light, colors.dark);
            return range;
        } else {
            return this.get("colorFieldInfo.colorRange");
        }
    }),

    colorFunction: Ember.computed( "colorDomain", "fixedColor", "colorFieldInfo", "queries.[]", "indexedClusterIds", function () {
        if (!this || this.isDestroyed || this.isDestroying) {
            return;
        }
        var colorDomain = this.colorDomain,
            colorRange = this.get("colorFieldInfo.colorRange"),
            keyType = this.get("colorFieldInfo.keyType"),
            queries = this.queries || [];

        if (keyType === "query") {
            var domain = queries.map((q, idx) => idx),
                range = queries.mapBy("queryInfo.color.colorString");
            domain.unshift(-1);
            range.unshift(ChartColors.gray.dark);
            return d3.scaleOrdinal().domain(domain).range(range);
        } else if (keyType === "cluster") {
            const scale = ChartColors.ordinalColorsD3All();
            return (d) => {
                if (isNone(d)) {
                    return ChartColors.gray.dark;
                }
                return scale(this.indexedTopicClusterIds.indexOf(d));
            };
        }

        if (colorRange) {
            return d3.scaleLinear().domain(colorDomain).range(colorRange);
        }
    }),

    selectedTopicColorStyleSafe: Ember.computed( "selectedTopic", "innerColorField", "colorFunction", function () {
        if (this.activeNode) {
            return `color: ${this.activeNode.fillColor};`.htmlSafe();
        }
        var selectedTopic = this.selectedTopic,
            colorField = this.innerColorField,
            colorFunction = this.colorFunction,
            color = "inherit";
        if (selectedTopic && colorField && colorFunction) {
            color = colorFunction(Ember.get(selectedTopic, colorField));
        }
        return ["color:", color, ";"].join("").htmlSafe();
    }),

    topicsQueries: Ember.computed("queries.[]", "showNodeChart",
            "relatedOverlap", "clusterOverlap", "numTopics", function () {
        if (!this.showNodeChart) {
            return this.queries;
        }

        return this.queries.map((query) => {
            let newQuery = query.copy();
            newQuery.queryInfo.addAdditionalAPIParameters({
                limit: this.numTopics,
                // It may be beneficial to add in the option for "fewer", or "more" links between topics.  If so, use this `clustering_bias`
                // clustering_bias: 0.5
            });
            return newQuery;
        });
    }),

    isLoading: Ember.computed( "topicsQueries.[]", "topicsQueries.@each.updated", "topicsType", function () {
        var queries = this.topicsQueries || [],
            topicsType = this.topicsType;
        var queryTopics = queries.find((query) => {
            var topics = Ember.get(query, topicsType);
            return !topics || !Ember.get(topics, "isLoaded");
        });
        return !!queryTopics;
    }),

    queryTopics: Ember.computed("hiddenTopics", "topicsQueries.@each.updated", "topicsType", function () {
        let hiddenTopics = this.hiddenTopics || [],
            queryTopics = this.topicsQueries.map((query, idx) => {
                var topics = Ember.get(query, this.topicsType + ".dataSeries.data") || [];
                return {
                    query: query,
                    queryIndex: idx,
                    topics: topics
                        .filter((topic) => !hiddenTopics.find(hiddenTopic => hiddenTopic.topic === topic.topic) )
                        .map((topic) => {
                            return Object.assign({}, topic, {
                                _originalQuery: query,
                                _originalQueryIdx: idx,
                            });
                        }),
                };
            });
        return queryTopics;
    }),

    allTopics: Ember.computed("queryTopics", function () {
        var queryTopics = this.queryTopics || [];
        Ember.run.once(this, () => {
            this.setProperties({
                selectedTopic: null,
                hoveredTopic: null,
            });
        });
        return [].concat.apply([], queryTopics.mapBy("topics"));
    }),

    numClusters: Ember.computed("allTopics", function(){
        return this.allTopics.mapBy("clusterId").uniq().length
    }),

    cloudTopics: Ember.computed( "maxCloudTopics", "timelineScaledTopics", "innerSizeField", function () {
        var maxCloudTopics = this.maxCloudTopics,
            allTopics = this.timelineScaledTopics || [];
        if (!maxCloudTopics || Ember.get(allTopics, "length") < maxCloudTopics) {
            return allTopics;
        }
        var sizeField = this.innerSizeField,
            sortedTopics = allTopics.sort((topicA, topicB) => {
                var a = Ember.get(topicA, sizeField),
                    b = Ember.get(topicB, sizeField);
                return a - b;
            });
        return sortedTopics.slice(0, maxCloudTopics);
    }),

    timelineScaledTopics: Ember.computed( "hoveredTimelineIndex", "collatedTopics", function () {
        var idx = this.hoveredTimelineIndex,
            selectedTopics = this.collatedTopics;
        if (typeof idx !== "number" || !selectedTopics) {
            return selectedTopics;
        }
        return selectedTopics.map((topic) => {
            var scalar = topic.timelineScales[idx] || 1;
            return Object.assign({}, topic, {
              documents: topic.documents * scalar,
              appearances: topic.appearances * scalar,
              score: topic.score * scalar,
            });
        });
    }),

    collatedTopics: Ember.computed( "allTopics", "hasMoreThanOneQuery", function () {
        var topics = this.allTopics,
            keyMap,
            collatedTopics = [];
        if (!this.hasMoreThanOneQuery || Ember.isEmpty(topics)) {
            return topics;
        }
        keyMap = topics.reduce((map, topic, idx) => {
            var key = topic.key || idx;
            if (!map[key]) {
                map[key] = [];
            }
            map[key].push(topic);
            return map;
        }, {});
        for (let key in keyMap) {
            if (keyMap.hasOwnProperty(key)) {
                var collatedTopic = TopicsAPI.combineMatchingTopics(keyMap[key]);
                if (collatedTopic._sourceTopics.length > 1) {
                    collatedTopic._originalQueryIdx = -1;
                }
                collatedTopics.push(collatedTopic);
            }
        }
        return collatedTopics;
    }),

    /* Returns an array of all cluster Ids sorted for consistency (ids are ints) */
    indexedTopicClusterIds: Ember.computed("collatedTopics.[]", function () {
        return Array.from(
            this.collatedTopics.reduce((all, topic) => {
                all.add(topic.clusterId);
                return all;
            }, new Set())
        ).sort();
    }),

    risingFallingTopics: Ember.computed("collatedTopics", function () {
        var selectedTopics = this.collatedTopics || [],
            risingFalling = {};
        risingFalling = selectedTopics.reduce(
            (memo, topic) => {
                var change = Ember.get(topic, "documentsGrowthPct") || 0;
                if (change >= 0) {
                    memo.rising.push(topic);
                } else {
                    memo.falling.push(topic);
                }
                return memo;
            },
            { rising: [], falling: [], same: [] }
        );
        return risingFalling;
    }),

    topicsViewTypeChanged: () => { },
    selectedFieldChanged: () => { },
    actions: {
        topicHovered: function (topicKey) {
            if (topicKey === null) {
                this.set("hoveredTopic", topicKey);
                return;
            }

            const topic = this.allTopics.find((topic) => {
                if (typeof topicKey === "object") {
                    return topic.key === topicKey.key;
                } else {
                    return topic.key === topicKey;
                }
            });

            if (this.hoveredTopic !== topic) {
                this.set("hoveredTopic", topic);
            }
        },
        topicSelected: function (topicKey) {
            if (topicKey === null) {
                this.set("selectedTopic", topicKey);
                return;
            }
            
            const topic = this.allTopics.find((topic) => {
                if (typeof topicKey === "object") {
                    return topic.key === topicKey.key;
                } else {
                    return topic.key === topicKey;
                }
            });

            if (this.selectedTopic !== topic) {
                this.set("selectedTopic", topic);
            } else {
                this.set("selectedTopic", null);
            }
        },
        activeNodeChanged(topicId, node) {
            const topic = this.allTopics.find((topic) => topic.key === topicId);
            this.set("activeTopic", topic);
            this.set("activeNode", node);
        },
        sizeFieldChanged: function (sizeField) {
            if (this.timelineHovered) {
                return;
            }
            if (this.selectedTopic !== null) {
                this.set("selectedTopic", null);
                this.set("selectedDrilldownTopic", null);
            }
            this.set("sizeField", sizeField);
        },
        colorFieldChanged: function (colorField) {
            if (this.timelineHovered) {
                return;
            }
            if (this.selectedTopic !== null) {
                this.set("selectedTopic", null);
                this.set("selectedDrilldownTopic", null);
            }
            if (this.topicsViewType === "node") {
                this.set("trackedTopicColorField", colorField);
            }
            this.set("colorField", colorField);
        },
        hoveredIndexChanged: function (hoveredIndex) {
            this.set("hoveredTimelineIndex", hoveredIndex);
        },
        topicsViewTypeChanged: function (viewType) {
            this.setProperties({
                hoveredTimelineIndex: null,
                selectedTopic: null,
                hoveredTopic: null,
                selectedDrilldownTopic: null,
            });
            if (this.topicsViewType === "node") {
                this.set("trackedTopicColorField", "clusterId");
            }
            this.topicsViewTypeChanged(viewType);
        },
        drilldownTopicSelected: function (drilldownTopic) {
            var topic = this.selectedDrilldownTopic;
            this.set(
                "selectedDrilldownTopic",
                topic === drilldownTopic ? null : drilldownTopic
            );
        },
        removeTopic: function (topic) {
            //this.removeTopic(topic, this.topicsType);
            var queries = this.topicsQueries;
            queries.forEach((query) => {
                var topics = Ember.get(query,`${this.topicsType}.dataSeries.data`);
                topics.forEach((topic_obj, index) => {
                    if (topic_obj.topic === topic) {
                        topic_obj._api = this.topicsType;
                        this.hiddenTopics.pushObject(topic_obj);
                        topics.removeAt(index,1);
                    }
                });
                query.incrementProperty("updated");
            });
        },
        toggleShowDisplaySettings() {
            this.toggleProperty("showDisplaySettings");
        },
        downloadImage() {
            const link = document.createElement('a');
            const queryTitles = this.queries.map((q) => q.title);
            const fileName = `Topics - ${queryTitles.join(" & ")}`;
            link.download = fileName;
            link.href = this.element.querySelector("#topics-force-graph").querySelector("canvas").toDataURL();
            link.click();
            link.delete;
        },
        darkModeChanged() {
            this.toggleProperty("darkMode");
        },
        displayLinksChanged() {
            this.toggleProperty("displayLinks");
        }
    },
});
