import Ember from "ember";
import ArrayBase from "infegy-frontend/models/array_compat";
import QueryFilters from "infegy-frontend/models/queries/query_filters";
import Query from "infegy-frontend/models/users/query";

var QueryArray = ArrayBase.extend({
    globalFilters: null,

    hasMultiple: Ember.computed.gt("length", 1),

    copy: function() {
        var newQueryArray = QueryArray.create();
        newQueryArray.set("globalFilters", this.globalFilters.copy());

        this.forEach(function(query) {
            newQueryArray.pushObject(query.copy());
        });
        return newQueryArray;
    },

    setup: Ember.on("init",function() {
        if (!this.content) {
            this.set("content", Ember.A());
        }

        if (!this.globalFilters) {
            var globalFilters = QueryFilters.create();
            globalFilters.loadJSON({
                    title: null,
                    query: null,
                    start_date: "3 Months Ago",
                    end_date: "Now"
                });
            this.set("globalFilters", globalFilters);
        }

        this.updateIndicies();
    }),

    updateIndicies: function() {
        var globalFilters = this.globalFilters,
            content = this.content;
        if (!content) {
            return;
        }
        this.forEach(function (query, index) {
            query.set("id", index);
            query.set("globalFilters", globalFilters);
        });
        this.set("errors", null);
    },

    runUpdateIndicies: Ember.observer("[]",function() {
        Ember.run.once(this, "updateIndicies");
    }),
});

export default QueryArray;
