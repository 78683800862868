export default {
    trendsActiveAPI: "volume",
    trendsActiveField: "postsWordNormalized",
    trendsActiveFieldDescription: "",

    trendsHoverField: null,
    trendsHoverFieldDescription: "",

    usStatesActiveField: "score",
    usStatesActiveFieldDescription: "lol, im not going to do this.",

    countriesActiveField: "score",
    countriesActiveFieldDescription: "not this one either.",

    usStatesActiveTrend: "volume",
    usStatesActiveTrendDescription: "I've established a pattern.",

    countriesActiveTrend: "volume",
    countriesActiveTrendDescription: "seriously.",
};
