import Ember from "ember";
import AtlasMath from "infegy-frontend/utils/atlas-math";
var toInt = AtlasMath.toInt;

export default Ember.Component.extend({
    classNameBindings: [":paginator", "hidePrevPagers", "hideNextPagers", ":no-print"],

    totalItems: 0,
    itemsPerPage: 10,
    maxDisplayPages: 10,

    needsPagination: Ember.computed("totalItems", "itemsPerPage", {
        get: function(key) {
            return this.totalItems > this.itemsPerPage;
        },
    }),

    hasFirstButton: true,
    hasPrevButton: true,

    hasNextButton: true,
    hasLastButton: true,

    hidePrevPagers: Ember.computed("currentPage", "totalPages", {
        get: function(key) {
            return this.currentPage < 1 || this.totalPages < 2;
        },
    }),

    hideNextPagers: Ember.computed("currentPage", "totalPages", {
        get: function(key) {
            return this.currentPage >= (this.totalPages - 1) || this.totalPages < 2;
        },
    }),

    totalPages: Ember.computed("totalItems", "itemsPerPage", {
        get: function(key) {
            var perPage = this.itemsPerPage || 0,
                totalItems = this.totalItems || 0;
            if (perPage && totalItems) {
                return toInt((totalItems - 1) / perPage) + 1;
            }
            return 0;
        },
    }),

    currentPage: Ember.computed("totalPages", {
        get: function(key) {
            return 0;
        },
        set: function(key, value) {
            var totalPages = this.totalPages || 0;
            if (value && value > 0) {
                return (value < totalPages) ? value : totalPages;
            }
            return 0;
        }
    }),

    startIndex: Ember.computed("currentPage", "itemsPerPage", {
        get: function(key) {
            var perPage = this.itemsPerPage || 0,
                page = this.currentPage || 0;
            return (perPage * page);
        },
    }),

    endIndex: Ember.computed("currentPage", "itemsPerPage", "totalItems", {
        get: function(key) {
            var perPage = this.itemsPerPage || 0,
                page = this.currentPage || 0,
                totalItems = this.totalItems || 0,
                endIndex = (perPage * page) + perPage - 1;
            if (endIndex >= totalItems) {
                endIndex = totalItems - 1;
            }
            return endIndex;
        },
    }),

    displayPages: Ember.computed("maxDisplayPages", "totalPages", "currentPage", {
        get: function(key) {
            var maxDisplayPages = Math.max(this.maxDisplayPages, 3),
                totalPages = this.totalPages,
                currentPage = this.currentPage,
                start = 0,
                end = totalPages;

            var numAfter = toInt(maxDisplayPages / 2),
                numBefore = maxDisplayPages - numAfter;
            if (maxDisplayPages < totalPages) {
                if (currentPage - numBefore < 0) {
                    end = maxDisplayPages - 1;
                } else if (currentPage + numAfter >= totalPages) {
                    start = totalPages - maxDisplayPages;
                } else {
                    start = currentPage - numBefore;
                    end = currentPage + numAfter;
                }
            }
            if (end <= start) {
                return [];
            }
            var displayNums = [];
            for (var num = start; num < end; num++) {
                displayNums[displayNums.length] = num;
            }
            return displayNums;
        },
    }),

    startIsHidden: Ember.computed("displayPages", "displayPages.[]", {
        get: function(key) {
            var pages = this.displayPages;
            if (pages && pages.length && pages[0] !== 0) {
                return true;
            }
        },
    }),

    endIsHidden: Ember.computed("displayPages", "displayPages.[]", {
        get: function(key) {
            var pages = this.displayPages,
                totalPages = this.totalPages || 1;
            if (pages && pages.length && pages[pages.length - 1] !== totalPages) {
                return true;
            }
        },
    }),

    actions: {
        nextPage: function() {
            var currentPage = toInt(this.currentPage);
            this.set("currentPage", currentPage + 1);
        },
        previousPage: function() {
            var currentPage = toInt(this.currentPage);
            if (currentPage >= 1) {
                this.set("currentPage", currentPage - 1);
            }
        },
        firstPage: function() {
            this.set("currentPage", 0);
        },
        lastPage: function() {
            this.set("currentPage", this.totalPages - 1);
        }
    }
});
