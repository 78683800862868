import { A } from "@ember/array";
import ArrayProxy from "@ember/array/proxy"
import { computed } from "@ember/object";

import CompanyUser from "infegy-frontend/models/admin/admin_user";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import classic from "ember-classic-decorator";
import * as Sentry from "@sentry/browser";

@classic
export default class CompanyUsers extends ArrayProxy {
    content = A();
    isLoading = true;

    constructor(){
        super(...arguments);
        this.setup();
    }

    async setup() {
        try{ 
            this.set("isLoading", true);
            var response = await AtlasAuth.Get("api/v3/company_users");
        } catch(error) {
            console.error(error.atlasErrorText);
            Sentry.captureMessage("Could not load company users.");
        }

        this.fromJSON(response.output);
        this.set("isLoading", false);
    }

    fromJSON(jsonOutput) {
        const companyUsers = jsonOutput.map((json) => {
            let companyUser = CompanyUser.create();
            companyUser.loadJSON(json);
            return companyUser;
        });

        this.set("content", A(companyUsers));
    }

    @computed("content", "content.[]", "content.length")
    get listOptions() {
        return this.content.map((companyUser) => {
            return {
                label: companyUser.name,
                value: companyUser.id,
                alternates: [ companyUser.username ]
            };
        })
    }
}