import Controller, { inject as controller } from "@ember/controller";
import { action, computed } from "@ember/object";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import Filterset from "infegy-frontend/models/users/filterset";

export default class Filtersets extends Controller {
  @controller application;
  @computed.alias("application.user") user;
  @computed.alias("user.filtersets") filtersets;
  showDeleteError = false;
}
