import Ember from "ember";
import classic from "ember-classic-decorator";
import JsonStore from 'infegy-frontend/json-store/model';
import Prop from 'infegy-frontend/json-store/properties/model';
import { isEmpty, isNone } from "@ember/utils";

import QueryDateAttr from "infegy-frontend/models/queries/filters/query_date";
import QueryListFilter from "infegy-frontend/models/queries/filters/list_filter";

import JsonTools from "infegy-frontend/utils/json-tools";
import QueryUtil from "infegy-frontend/models/queries/query_util";
import QueryBuilderDetail from "infegy-frontend/models/queries/query_builder_detail";
import EntityObject from "infegy-frontend/models/entities/entity-item";
import QueryColor from "infegy-frontend/models/queries/query_color";
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import QueryDataGrouping from "infegy-frontend/models/queries/query_data_grouping";
import DateProcessing from "infegy-frontend/utils/date-processing";
import Filterset from "infegy-frontend/models/users/filterset";
import AtlasConfig from "infegy-frontend/config/infegy-app-config";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";

import APIFilter from "infegy-frontend/models/queries/filters/api_filter";

@classic
class QueryFilters extends JsonStore {
    @Prop.Attr() query;
    @alias("query") queryString;

    @Prop.String({default: "builder"}) queryType;
    @Prop.Object(QueryBuilderDetail) queryBuilderDetail;
    @Prop.Collection(EntityObject) entityQueryParts;

    @Prop.String() customDatasetId;
    @computed.alias("customDatasetId") hasCustomDataset;

    @Prop.String() selectedGrouping;
    @computed.or("selectedGrouping","autoGrouping") activeGrouping;

    maxAutoTitleLength = 30;
    minAutoTitleLength = 3;

    @Prop.Attr() userTitle;

    @Prop.Number() lookupId;

    @Prop.Object(QueryColor) color;

    @Prop.String() queryWithin;

    @Prop.Boolean({}) isSaved;
    @Prop.Boolean({}) isSnippet;

    @Prop.Object(QueryDateAttr) startDate;
    @Prop.Object(QueryDateAttr) endDate;

    @Prop.Int() startRangeTimestamp;
    @Prop.Int() endRangeTimestamp;

    @Prop.Attr() queryFields;
    @Prop.Attr() analyzeFields;

    @Prop.Attr() dictionaries;
    @Prop.Attr() subquerySets;

    @Prop.String({default: "ts-default"}) timestampField;

    @Prop.Collection(APIFilter) filters;

    //////////////////////////////////
    // Dataset
    //////////////////////////////////

    atlasAuth = AtlasAuth;
    @computed.oneWay("atlasAuth.user") user;

    @computed("customDatasetId", "atlasAuth.user", "user.customDatasets", "user.customDatasets.[]", "user.socialQueryInfo")
    get activeDataset() {
        let customDatasets = this.user?.customDatasets || [],
            socialQueryInfo = this.user?.socialQueryInfo,
            customDatasetId = this.customDatasetId;

        if (Ember.isEmpty(customDatasetId) && socialQueryInfo) {
            return {queryInfo: socialQueryInfo};
        }
        return customDatasets.findBy("id", customDatasetId);
    }

    @computed("activeDataset", "customDatasetId", "atlasAuth.user", "activeDataset.queryInfo", "activeDataset.queryInfoIsLoading", "user.socialQueryInfo")
    get datasetQueryInfo() {
        if (this.customDatasetId || this.activeDataset) {
            return this.activeDataset?.queryInfo;
        }
        return this.user?.socialQueryInfo;
    }

    @computed("activeDataset", "customDatasetId", "atlasAuth.user", "activeDataset.queryInfoPromise", "user.socialQueryInfoPromise")
    get datasetQueryInfoPromise() {
        if (this.customDatasetId || this.activeDataset) {
            return this.activeDataset?.queryInfoPromise;
        }
        return this.user?.socialQueryInfoPromise;
    }

    //////////////////////////////////
    // Filters
    //////////////////////////////////

    getFilterInfo(filterId) {
        let dsInfo = this.datasetQueryInfo,
            filters = dsInfo?.filters || [];
        return filters.findBy("id", filterId);
    }

    getFilterType(filterId) {
        let filterInfo = this.getFilterInfo(filterId);
        return filterInfo?.type;
    }

    getFilterName(filterId) {
        let filterInfo = this.getFilterInfo(filterId);
        return filterInfo?.name;
    }

    // Add a query filter, should be in the format based on the dataset description and the api_filter class
    //  * = required
    //
    // * id: the property/id of the filter passed to the API - e.g. "languages" or "start_date"
    // name: The display name/Label of the filter
    // type: One of "decimal", "integer", "keyword", "keywords", "boolean", "timestamp"
    // isExcluded: whether to exclude the supplied values
    //
    // * Chose the following based on the "type":
    //
    // values: For keyword and keywords filters
    // min && max: For Numeric Filters
    // minDate && maxDate: For Date Range Filters
    // booleanValue: For Boolean Filters
    addFilter(filterDescriptionObj) {
        if (filterDescriptionObj) {
            let newFilter = APIFilter.create(),
                modifiedDesc = Object.assign({}, filterDescriptionObj);
            delete modifiedDesc.options;

            modifiedDesc.name = modifiedDesc.name || this.getFilterName(modifiedDesc.id) || modifiedDesc.id;
            modifiedDesc.type = modifiedDesc.type || this.getFilterType(modifiedDesc.id);

            newFilter.loadJSON(modifiedDesc);
            this.filters.pushObject(newFilter);
            return newFilter;
        }
    }

    clearInvalidFilters() {
        let datasetFilters = this.datasetQueryInfo?.filters,
            filters = this.filters;
        if (datasetFilters && filters) {
            let datasetFilterIds = datasetFilters.mapBy("id"),
                invalidFilters = filters.filter(filter => {
                    return !datasetFilterIds.includes(filter?.id);
                });
            if (invalidFilters.length) {
                this.filters.removeObjects(invalidFilters);
            }
        }
    }

    clearBlankFilters() {
        // TODO
    }

    // Additional Filters and Values as a Native Object
    // These usually are applied GLOBAL to an API request.
    // These ARE NOT overrides for the built in filters
    @Prop.Attr({ default: {} }) additionalAPIParameters;

    // Parameters should be supplied as an object:
    // {
    //    param1: [vals...],
    //    param2: "value",
    // }
    addAdditionalAPIParameters(parameters) {
        var existing = this.additionalAPIParameters || {};
        this.set("additionalAPIParameters", Object.assign({}, existing, parameters));
    }

    // supply a property name and it will remove any values associated with it
    removeAdditionalAPIParameter(parameterName) {
        var existing = this.additionalAPIParameters || {};
        delete existing[parameterName];
    }

    // @computed.mapBy("activeFormFilters","filter") executableFormFilters;
    //

    //////////////////////////////////
    // Main Query Parts
    //////////////////////////////////

    @computed("sourceQuery")
    get sourceQueryString(){
        var sourceQuery = this.sourceQuery;
        return (sourceQuery && sourceQuery.toJSON()) || null;
    }

    @computed("queryBuilderDetail.isBlank")
    get isUsingQueryBuilder() {
        return this.queryBuilderDetail && !this.queryBuilderDetail.isBlank;
    }
    set isUsingQueryBuilder(isUsingQueryBuilder) {
        return isUsingQueryBuilder;
    }

    @computed("entityQueryParts", "entityQueryParts.[]")
    get entityQuery() {
        var entityParts = this.entityQueryParts;
        entityParts = entityParts && entityParts.toJSON();

        if (entityParts.length > 0) {
            return entityParts.mapBy("value").join(" ");
        }

        return '';
    }

    @computed("queryString", "entityQuery", "customDataset", "sourceQuery", "activeFormFilterNames.[]")
    get isAudienceQuery(){
        /* no longer a thing */
    }

    @computed("queryString", "entityQueryParts.[]", "isUsingQueryBuilder", "queryBuilderDetail.isBlank", "sourceQuery.value",
    "customDatasetParts.[]", "audienceSegments.value", "executableFormFilters.[]", "executableFormFilters.@each.value",
     "executableFormFilters.@each.isExclude", "filters.@each.hasValue", "filters.@each.isExcluded",
     "datasetQueryInfo")
    get isBlank() {
        let hasExecutableFilter = !isEmpty(this.filters) && !isNone(this.filters.content.find((filter) => {
            if (!this.datasetQueryInfo) {
                return false;
            }
            let foundFilter = this.datasetQueryInfo.filters.find((filterInfo) => { return filter.id === filterInfo.id });
            return foundFilter && foundFilter.executable ? true : false; 
        }));

        if (this.customDatasetId) {
            return false;
        }

        if (hasExecutableFilter) {
            return false;
        }

        return  Ember.isEmpty(this.queryString) &&
                Ember.isEmpty(this.entityQueryParts) &&
                Ember.isEmpty(this.customDatasetParts) &&
                (this.isUsingQueryBuilder ? this.get("queryBuilderDetail.isBlank") : true) &&
                (this.get("sourceQuery.isActive") ? Ember.isEmpty(this.get("sourceQuery.value")) : true) &&
                Ember.isEmpty(this.get("audienceSegments.value"))
    }

    @computed("customDatasetParts", "customDatasetParts.[]")
    get customDatasets() {
        var customDatasetParts = this.customDatasetParts;
        var fix = customDatasetParts.reduce((arr, current) => {
            arr.push(Ember.get(current, "id"));
            return arr;
        }, []).join(",");
        return fix;
    }

    //////////////////////////////////
    // Serialization / Deserialization
    //////////////////////////////////

    // Special rules for exluding / including info in serialization - see JSONStore
    _fieldGroups = {
        api: {
            includes: [
                "analyzeFields",
                    "color",
                "customDatasetId", // -> dataset_id
                "dictionaries", // -> dictionary_ids
                    "endDate",
                "entityQuery",
                    "entityQueryParts",
                "filters", // add filter, exclude
                    "isSaved",
                "query",
                "queryBuilderDetail", // -> query_builder
                "queryFields",
                    "queryType",
                "queryWithin",
                "selectedGrouping", // -> group_by
                    "startDate",
                "subquerySets", // -> query_set_ids
                    "timestampField",
                    "userTitle"
            ]
        },
        filterset: {
            includes: [
                "analyzeFields",
                "dictionaries",
                "filters",
                "subquerySets"
            ]
        }
     }

    loadJSON(jsonData_in, options) {
        // create a copy so the original is not modified
        let jsonData = JSON.parse(JSON.stringify(jsonData_in));

        // Transform fields with a different back-end name
        if (jsonData.hasOwnProperty("group_by") && !isEmpty(jsonData.group_by)) {
            jsonData.selected_grouping = jsonData.group_by;
            delete jsonData.group_by;
        }
        if (jsonData.hasOwnProperty("dataset_id") && !isEmpty(jsonData.dataset_id)) {
            jsonData.custom_dataset_id = jsonData.dataset_id;
            delete jsonData.dataset_id;
        }
        if (jsonData.hasOwnProperty("dictionary_ids") && !isEmpty(jsonData.dictionary_ids)) {
            jsonData.dictionaries = jsonData.dictionary_ids;
            delete jsonData.dictionary_ids;
        }
        if (jsonData.hasOwnProperty("query_builder") && !isEmpty(jsonData.query_builder)) {
            jsonData.query_builder_detail = jsonData.query_builder;
            delete jsonData.query_builder;
        }
        if (jsonData.hasOwnProperty("query_set_ids") && !isEmpty(jsonData.query_set_ids)) {
            jsonData.subquery_sets = jsonData.query_set_ids;
            delete jsonData.query_set_ids;
        }
        // Delete/remove back-end key names as they will have a valid front-end
        // counter-part (e.g. the front-end uses `filters` but the back end
        // splits it into `filter` and `exclude`).
        if (jsonData.hasOwnProperty("entity_query")) {
            delete jsonData.entity_query;
        }

        if (jsonData.hasOwnProperty("filter")) {
            delete jsonData.filter;
        }
        if (jsonData.hasOwnProperty("exclude")) {
            delete jsonData.exclude;
        }

        super.loadJSON(jsonData, options);
        // This is a backwards compatability fix for queries that have been
        // saved before the source query filter change to using "e," and "i,"
        // for exclude/include.
        // These prior queries will not have a prefix, but will have a
        // "source_query_exclude" bool that will need to be set on the filter.
        const hasExcludePrefix = !Ember.isNone(jsonData.source_query)
            && (jsonData.source_query.startsWith("e,") || jsonData.source_query.startsWith("i,"));
        const hasSourceQueryExclude = this.sourceQuery && !Ember.isNone(this.sourceQueryExclude);
        if (!hasExcludePrefix && hasSourceQueryExclude) {
            this.sourceQuery.set("isExclude", this.sourceQueryExclude);
        }
    }

    toJSON(options) {
        let jsonData = super.toJSON(options);

        // Transform fields with a different back-end name
        // Note: Fields are already decamelized as part of `toJSON()`.
        if (jsonData && jsonData.hasOwnProperty("selected_grouping")) {
            jsonData.group_by = jsonData.selected_grouping;
            delete jsonData.selected_grouping;
        }
        if (jsonData && jsonData.hasOwnProperty("custom_dataset_id")) {
            jsonData.dataset_id = jsonData.custom_dataset_id;
            delete jsonData.custom_dataset_id;
        }
        if (jsonData && jsonData.hasOwnProperty("dictionaries")) {
            jsonData.dictionary_ids = jsonData.dictionaries;
            delete jsonData.dictionaries;
        }
        if (jsonData && jsonData.hasOwnProperty("query_builder_detail")) {
            jsonData.query_builder = jsonData.query_builder_detail;
            delete jsonData.query_builder_detail;
        }
        if (jsonData && jsonData.hasOwnProperty("subquery_sets")) {
            jsonData.query_set_ids = jsonData.subquery_sets;
            delete jsonData.subquery_sets;
        }
        if (jsonData && jsonData.hasOwnProperty("filters")) {
            // The back-end expects filters in a different format than the front-end.  Filters need 
            // to be converted to the API JSON version for this.  This fixes the current issues with:
            // (1) the filter field `_values` should be `values` - used by keyword and keywords filters
            // (2) the filter field `textValue` should be `values` - used by text filters
            const included = [];
            const excluded = [];
            for (const filter of this.filters.content) {
                if (filter.isExcluded)
                    excluded.push(filter.toAPIJSON());
                else
                    included.push(filter.toAPIJSON());
            }
            if (included.length > 0)
                jsonData.filter = included;
            if (excluded.length > 0)
                jsonData.exclude = excluded;
        }

        if (this.entityQuery.length > 0) {
            jsonData.entity_query = this.entityQuery;
        }

        return jsonData;
    }

    toCleanJSON() {
        var nullFields = ["deleted", "is_saved"],
            jsonObj = this.toJSON();
        nullFields.forEach(field => {
            if (!jsonObj[field] && jsonObj[field] !== undefined) {
                delete jsonObj[field];
            }
        });
        return JsonTools.cleanObject(jsonObj);
    }

    copy() {
        var cleanJSON = Object.assign({}, this.toJSON()),
            newQuery = QueryFilters.create();
        newQuery.loadJSON(cleanJSON);
        return newQuery;
    }

    @computed("queryString", "entityQueryParts.[]", "sourceQuery", "customDatasetParts.[]", "title")
    get savedQueryJSON (){
        return {
            query: this.queryString,
            source_query: this.sourceQuery.toJSON(),
            entity_query: JSON.stringify(this.entityQueryParts.toJSON()),
            custom_dataset: JSON.stringify(this.customDatasetParts.toJSON()),
            title: this.title,
            is_saved: this.isSaved,
            is_snippet: this.isSnippet
        };
    }

    /**
     * Joins the filters in the passed-in filterset to the queries existing
     * filters. Duplicate filters will prefer the value from the filterset.
     * @param {Filterset} filterset
     */
    loadFilterset(filterset) {
        this.loadJSON(filterset.queryInfo.toJSON({fieldGroups: "filterset"}));
        this.set("activeFiltersetTitle", filterset.title);
    }

    ////////////////////////////////////////
    // Query Title Generation
    ////////////////////////////////////////

    @computed("queryString", "entityQueryParts.[]", "customDatasetParts.[]", "queryBuilderDetail.titleString", "sourceQuery.value")
    get autoTitle(){
        return QueryUtil.queryToTitle(this.get("queryBuilderDetail.titleString") || this.queryString,
            this.entityQueryParts,
            this.customDatasetParts,
            this.get("sourceQuery.value"),
            this.maxAutoTitleLength,
            this.minAutoTitleLength);
    }

    @computed("autoTitle", "queryString", "userTitle")
    get title(){
        var autoTitle = this.autoTitle,
            userTitle = this.userTitle;
        return userTitle || autoTitle;
    }
    set title(value){
        var autoTitle = this.autoTitle;
        value = value.trim();
        if (value === autoTitle) {
            value = null;
        }
        this.set("userTitle", value);
        if(value === null){
            return autoTitle;
        } else {
            return value;
        }
    }

    ////////////////////////////////////////
    // Query Dates and Grouping
    ////////////////////////////////////////

    @computed("startDate.timestamp", "endDate.timestamp")
    get queryTimespan() {
        return this.get("endDate.timestamp") - this.get("startDate.timestamp");
    }

    @computed("queryTimespan")
    get queryTimespanDays() {
        return this.queryTimespan / (1000 * 60 * 60 * 24);
    }

    @computed("startDate.timestamp", "endDate.timestamp")
    get autoGrouping(){
        let startDate = this.startDate,
            endDate = this.endDate;
        if (startDate && endDate){
            let timespan = endDate.timestamp - startDate.timestamp;
            return QueryDataGrouping.findAPIGrouping(timespan);
        }
        return 'day';
    }

    @computed("datasetId", "activeDataset", "datasetQueryInfo",
        "datasetQueryInfo.available_timestamp_fields", "user.permissions.historicalAccessTimestamp")
    get earliestTimestamp(){
        let tsFields = this.datasetQueryInfo?.available_timestamp_fields,
            historicalAccessTimestamp = this?.user?.permissions?.historicalAccessTimestamp,
            minTime = Infinity;

        if (!this.customDatasetId || Ember.isEmpty(tsFields)){
            return new Date(historicalAccessTimestamp || AtlasConfig.EarliestAtlasTimestamp);
        }

        tsFields.forEach(tsField => {
            let min = new Date(tsField.min);
            if (min < minTime) {
                minTime = min;
            }
        });
        return isFinite(minTime) ? minTime : new Date();
    }
    @computed("datasetId", "activeDataset", "datasetQueryInfo",
        "datasetQueryInfo.available_timestamp_fields")
    get latestTimestamp(){
        let tsFields = this.datasetQueryInfo?.available_timestamp_fields,
            maxTime = -Infinity;

        if (!this.customDatasetId || Ember.isEmpty(tsFields)){
            return new Date();
        }

        tsFields.forEach(tsField => {
            if (tsField.id === "fetched") {
                // We are looking for the latest, or most recent, timestamp field related to the query.
                // For custom datasets "fetched" is the upload date.  This doesn't make sense to use.
                // For social data "fetched" has no `max` so it won't do anything anyways.
                return;
            }
            let max = new Date(tsField.max);
            if (max > maxTime) {
                maxTime = max;
            }
        });
        return isFinite(maxTime) ? maxTime : new Date();
    }
}

QueryFilters.camelizeKeys = true;
export default QueryFilters;
