import Ember from "ember";

import AdminUsers from "infegy-frontend/models/admin/admin_users";

export default Ember.Route.extend({ 
    title: "Snippets",
    setupController(controller, model) {
        const adminUsers = AdminUsers.create();
        controller.set("adminUsers", adminUsers);
    }
});