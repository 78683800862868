import Controller, { inject as controller } from "@ember/controller";
import { action, computed } from "@ember/object";

import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import QueryURL from "infegy-frontend/models/queries/query_url";
import Workspaces from "infegy-frontend/models/users/workspaces";

export default class WorkspaceManagementList extends Controller {
    @controller application;

    queries = [];
    actionBarButtons = ["run_workspace", "edit_or_view", "view_sharing_details", "change_folder", "toggle_save"];
    isRunningQueries = true;

    @computed.alias("application.user") user;
    @computed.readOnly("user.permissions.maxQueries") maxQueries;

    workspaces = Workspaces.create({searchType: "saved"});
    shared = Workspaces.create({searchType: "shared"});

    @action 
    workspaceChangedFolder(updatedWorkspace) {
        // Update the cached workspace lists with the newly assigned `workspaceFolderId`
        [this.workspaces, this.shared, this.user.ownedWorkspaces, this.user.sharedWorkspaces].map((workspaces) => {
            let workspace = workspaces.findBy("id", updatedWorkspace.id);
            if (workspace) {
                workspace.workspaceFolderId = updatedWorkspace.workspaceFolderId;
            }
        });
    }

    @action
    async toggleSaveWorkspace(workspace) {
        workspace.toggleProperty("isSaved");
        await workspace.save();
        // Refresh the cached workspace lists
        this.workspaces.getPage();
        this.user.ownedWorkspaces.getPage();
    }

    @action
    onEditWorkspace(workspace) {
        this.transitionToRoute("atlas.tools.workspace-management.editor", workspace.get("id"));
    }

    @action
    createNewWorkspace() {
        this.transitionToRoute("atlas.tools.workspace-management.editor", 0);
    }

    @action
    toggleDeleteWorkspace(workspace) {
        AtlasAuth.Post({
            url: "api/v3/workspace.json",
            contentType: 'application/json',
            data: JSON.stringify({
                workspaces: [workspace]
            })
        }).catch((error) => {
            if(error.status === 403){
                this.transitionToRoute('login');
            }
        });
    }

    @action
    onSharingDeclined(workspace) {
        this.workspaces.removeObject(workspace);
    }

    @action
    onRunQueries(queryArray) {
        const queryUrl = QueryURL.queryArrayToUrl(queryArray);
        this.transitionToRoute("atlas.dashboard.content.overview", queryUrl);
    }

    @action onRunWorkspace(workspace) {
        const queryUrl = QueryURL.queryArrayToUrl(workspace.queries);
        this.transitionToRoute("atlas.dashboard.content.overview", queryUrl);
    }

    @action onQueriesSelected(queries) {
        const queryUrl = QueryURL.queryArrayToUrl(queries);
        this.transitionToRoute("atlas.dashboard.content.overview", queryUrl);
    }
}
