import Ember from "ember";
import CSSTools from "infegy-frontend/utils/css-tools";

export default Ember.Component.extend({
    // Properties
    value: 0.5,
    totalValue: 1.0,

    colorClass: "atlas-ui",
    color: null,
    backgroundColorClass: 'atlas-text-color-lighter',
    backgroundColor: null,
    height: 30,

    isFaded: false,

    showValueLabel: false,
    valueLabelClass: "atlas-text-color",

    showRemainderLabel: false,
    remainderLabelClass: "atlas-text-color",

    showLabelsAsPercentages: true,

    percentageWidthSafe: Ember.computed("percentage", {
        get: function(key) {
            var percentage = 100 * this.percentage || 0;

            return new Ember.String.htmlSafe("width:" + percentage + "%");
        }
    }),

    wrapperStyleSafe: Ember.computed("height", "isFaded", {
        get: function(key) {
          var css = {
            "height":(this.height || 30) + "px",
            "backgroundColor": this.backgroundColor
          };

            return new Ember.String.htmlSafe(CSSTools.buildStringFromObject(css));
        }
    }),

    // Internal
    remainderValue: Ember.computed("value", "totalValue", {
        get: function(key) {
            return this.totalValue - this.value;
        }
    }),

    percentage: Ember.computed("value", "totalValue", {
        get: function(key) {
            return this.value / this.totalValue;
        }
    }),

    remainderPercentage: Ember.computed("remainderValue", "totalValue", {
        get: function(key) {
            return this.remainderValue / this.totalValue;
        }
    }),

    wholePercentage: Ember.computed("percentage", {
        get: function(key) {
            return this.percentage * 100;
        },
    }),
});
