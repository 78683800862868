import Component from "@ember/component";
import { computed } from "@ember/object";

import ArrayUtil from "infegy-frontend/utils/array-util";

export default class PostsList extends Component {
    queries = [];
    pageNumber = 0;
    pageSize = 5;
    sortKey = "none";
    sortDirection = "ascending";

    defaultSocialView = ""; // "detailed|sample"

    viewOptions = ["Grid View", "Table View"];
    selectedView = "Grid View";

    @computed.gt("queries.length", 1) showQuery;

    @computed("canShowTableView", "selectedView")
    get isTableView() {
        return this.canShowTableView && this.selectedView === "Table View";
    }

    @computed("queries", "queries.length", "queries.@each.updated")
    get isLoadingPosts() {
        const found = this.queries.find((query) => {
            return !query.posts.isLoaded;
        });
        return !!found;
    }

    @computed("posts", "posts.length")
    get canShowTableView() {
        // The table view is only applicable when ALL queries are using datasets with the same 
        // column configuration.  This prevents showing a table with sparsely populated columns.
        if (this.queries.length == 0) {
            // Cannot display the view if there are no queries
            return false;
        }
        if (this.queries.length === 1) {
            // Cannot display the table if the only query is not a dataset
            return !!this.queries.firstObject.queryInfo.customDatasetId;
        }
        // If there are multiple queries, all queries 
        const columnConfig = JSON.stringify(this.queries.firstObject.datasetQueryInfo.filters);
        const found = this.queries.find((query, i) => {
            if (!query.queryInfo.customDatasetId) {
                // no dataset
                return true;
            } else if (JSON.stringify(query.datasetQueryInfo.filters) !== columnConfig) {
                // different column configuration
                return true;
            }
            // dataset with same column configuration
            return false;
        });
        return !found;
    }

    @computed("queries", "queries.length", "queries.@each.updated")
    get posts() {
        // Combine posts from all queries together and add a query reference onto each post.
        const allPosts = this.queries.reduce((memo, query) => {
            if (query.posts.data) {
                const queryPosts = query.posts.data.map((post) => {
                    post.query = query;
                    return post;
                });
                memo = memo.concat(queryPosts);
            }
            return memo;
        }, []);
        return allPosts;
    }

    @computed("posts", "posts.length")
    get sortedPosts() {
        let sortedPosts = this.posts;
        if (this.sortKey !== "none") {
            sortedPosts = sortedPosts.sortBy(this.sortKey);
            if (this.sortDirection === "descending") {
                sortedPosts = sortedPosts.reverse();
            }
        } else {
            // If there is no sorting, shuffle the posts.  This will mix posts when using multiple queries.
            sortedPosts = ArrayUtil.shuffle(this.posts);
        }
        return sortedPosts;
    }

    @computed("sortedPosts", "sortedPosts.length", "pageNumber", "pageSize")
    get postsPage() {
        const start = this.pageNumber * this.pageSize;
        const end = start + this.pageSize;
        const postsPage = this.sortedPosts.slice(start, end);
        return postsPage;
    }
}