import SearchSelectInput from "infegy-frontend/components/forms/search-select/inner-input/component";
import classic from 'ember-classic-decorator';
import { tracked } from '@glimmer/tracking';
import { computed } from '@ember/object';
import KeyCodes from "infegy-frontend/static_data/keycodes";

@classic export default class TermListInput extends SearchSelectInput {
    @tracked value = '';

    @computed("value")
    get inputValue() {
        return this.value === undefined ? '' : this.value;
    }

    onKeyDown = () => {/* action */}

    keyDown(e) {
        this.onInputKeyDown(e, this.inputValue);
    }

    keyUp(e) {
        if (this.termListArray.includes(this.inputValue)) {
            this.onInputKeyPress(e, this.inputValue);
        } else {
            this.onInputKeyPress(e, this.inputValue);
            if (e.keyCode === KeyCodes.Enter) {
                this.value = '';
            }
        }
    }

    focusOut() {
        if (this.termListArray.includes(this.inputValue)) {
            this.onFocusOut(this.inputValue);
        } else {
            this.onFocusOut(this.inputValue);
            this.value = '';
        }
    }

    click() {
        this.onChildInputClicked();
    }
}