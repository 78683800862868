import Component from '@ember/component';
import {computed, action} from "@ember/object";
import CustomTrendField from "infegy-frontend/models/trends/custom-trend-field";
import classic from "ember-classic-decorator";

@classic
export default class CustomField extends Component {
    tagName = "span";

    field = null;
    fieldIndex = 0;
    editFieldIndex = null;
    tempField = null;

    queries = null;
    canRemove = false;

    @computed("queries", "queries.length")
    get earliestTimestamp() {
        const timestamps = this.queries.map((query) => query.earliestTimestamp);
        return Math.min(...timestamps);
    }

    @computed("queries", "queries.length")
    get latestTimestamp() {
        const timestamps = this.queries.map((query) => query.latestTimestamp);
        return Math.max(...timestamps);
    }

    @computed("editFieldIndex", "fieldIndex", "tempField")
    get isEditing() {
        return this.editFieldIndex === this.fieldIndex && this.tempField;
    }

    @computed("isEditing", "tempField")
    get hideEditOptions() {
        return !Ember.isEmpty(this.editFieldIndex) && !this.isEditing;
    }

    @computed.gt("queries.length", 1) hasMoreThanOneQuery;


    @computed.alias("field.field.query.queryFilters.startDate") originalStartTime;
    @computed.alias("field.field.query.queryFilters.endDate") originalEndTime;

    @action
    editField() {
        this.setEditFieldIndex(this.fieldIndex);
    }

    @computed("tempField.customStartDate.timestamp", "tempField.customEndDate.timestamp", "originalStartTime",
        "originalEndTime")
    get datesMatch() {
        let newStart = this.tempField.customStartDate.timestamp,
            originalStart = this.originalStartTime?.timestamp,
            newEnd = this.tempField.customEndDate.timestamp,
            originalEnd = this.originalEndTime?.timestamp;
        return newStart === originalStart && newEnd === originalEnd;
    }

    @action
    saveField() {
        this.setEditFieldIndex(null);
        let newField = CustomTrendField.create(),
            tempFieldJSON = this.tempField.toJSON();
        if (this.datesMatch) {
            this.tempField.usesCustomDates = false;
        }
        if (!this.tempField.usesCustomDates) {
            delete tempFieldJSON.customStartDate;
            delete tempFieldJSON.customEndDate;
        }
        newField.loadJSON(tempFieldJSON);
        newField.set("field", this.tempField.field);
        this.updateCustomField(newField, this.fieldIndex);
    }
    updateCustomField() { /* action */}

    @action
    removeField() {
        this.deleteField(this.field, this.fieldIndex);
    }
    deleteField() { /* action */ }

    setEditFieldIndex() { /* action */ }

    @action
    cancelEditing() {
        this.setEditFieldIndex(null);
    }

    @action
    tempFieldChanged(field) {
        if (this.tempField) {
            this.tempField.set("field", field);
        }
    }

    @action
    toggleCustomDates() {
        this.toggleProperty("tempField.usesCustomDates");
    }
};
