export default function format(value, options) {
    let defaults = {
        abbreviate: false,
        decimalPlaces: 0,
        isPercentage: false,
        isPercentValue: false, // the value is a percent but represented as 50 instead of 0.50
        showPercentSymbol: true
    };
    options = Object.assign({}, defaults, options);

    value = value || 0;
    let style = options.isPercentage ? "percent" : "decimal";
    let symbol = "";

    if (style === "percent") {
        if (options.isPercentValue && options.showPercentSymbol) {
            // If the value is given as a percent value it has been multiplied by 100.
            value /= 100;
        }
        if (!options.showPercentSymbol) {
            // To remove the percent symbol we override the `style` to "decimal".
            style = "decimal";
            // To make sure the value displays the same (i.e. 17% as 17) we might need to multiply 
            // the value by 100.
            if (!options.isPercentValue) {
                value *= 100;
            }
        }
    } else if (style === "decimal" && options.abbreviate) {
        let factor = 0;
        if (value) {
            factor = Math.floor(Math.log(Math.abs(value)) / Math.log(1000));
        }
        if (factor) {
            const symbolOptions = ["k", "m", "b", "t"];
            symbol = symbolOptions[factor-1];
            value = value / Math.pow(1000, factor);
        }
    }

    const formatOptions = {
        style: style,
    };
    if (options.decimalPlaces || options.decimalPlaces === 0) {
        formatOptions.minimumFractionDigits = options.decimalPlaces;
        formatOptions.maximumFractionDigits = options.decimalPlaces;
    }
    const formatter = new Intl.NumberFormat(navigator.language, formatOptions);
    return formatter.format(value) + symbol;
}