import Ember from "ember";
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { isNone } from '@ember/utils';
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import { once } from '@ember/runloop';
import Workspace from "infegy-frontend/models/users/workspace";
import { tracked } from "@glimmer/tracking";
import { argument, actionArgument } from "infegy-frontend/decorators/arguments";

export default class extends Component {
    @argument(null) workspaces;
    @argument(null) selectedWorkspaceId;
    @actionArgument loadWorkspace;
    @tracked selectedWorkspace;
    @tracked _loadedWorkspaces = null;
    @tracked workspacesAreLoading = false;

    workspacesDisplayCount = 20;

    get workspaceOptions() {
            var workspaces = this.workspaces || [],
                workspacesDisplayCount = this.workspacesDisplayCount,
                workspaceOptions = workspaces.sort((a,b)=>{
                    return Ember.get(b, "lastUsedTimestamp") - Ember.get(a, "lastUsedTimestamp");
                }).slice(0, workspacesDisplayCount);
            
            // set loading, run the loader, return 

            return workspaceOptions;
    }

    get workspacesPlaceholder() {
        if (this.workspacesAreLoading){
            return "Loading Workspaces...";
        }
        if (this.loadedWorkspaces?.length > 0) {
            return "Recent Workspaces";
        } else {
            return "No Workspaces";
        }
    }

    get loadedWorkspaces(){
        if (isNone(this._loadedWorkspaces)) {
            // jjw TODO: why is custom workspace loading done here?
            // once(this, this.loadRecentWorkspaces);
        }
        return this._loadedWorkspaces ?? [];
    }

    async loadRecentWorkspaces(){
        this.workspacesAreLoading = true;
        var json_workspaces;
        try {
            var res = await AtlasAuth.Get({
                url:`api/v3/workspace`,
                data: {
                    limit:10,
                    sort_field: "last_used"
                }
            });
            json_workspaces = res.output;
        } catch (e) {
            console.error(e); // eslint-disable-line no-console
            return;
        }
        
        var workspaces = json_workspaces.map(json => {
            var workspace = Workspace.create();
            workspace.loadJSON(json);
            // call the getter
            workspace.queryObject;
            return workspace;
        });
        this._loadedWorkspaces = workspaces;
        this.workspacesAreLoading = false;
    }


    @action
    selectWorkspace(workspaceId) {
        var workspaces = this.workspaces || [],
            selectedWorkspace = workspaces.findBy("id", workspaceId);

        if (this.workspaces?.findBy("id", workspaceId)) {
            this.loadWorkspace(selectedWorkspace);
        }
    }
}