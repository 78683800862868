import Ember from "ember";
import { action, computed } from "@ember/object";

export default class SubqueryList extends Ember.Component {
    // inputs
    hideActions = false;
    hideSelection = false;
    isReadOnly = false;
    subqueries = [];

    editing = null;
    deleting = null;
    selected = [];

    pageSize = 10;
    pageIndex = 0;
    @Ember.computed("pageSize", "pageIndex", "subqueries", "subqueries.length", "subqueries.@each")
    get pageSubqueries() {
        let currentIndex = this.pageIndex * this.pageSize;
        if (this.subqueries.length < currentIndex) {
            let pageIndex = Math.floor(this.subqueries.length / this.pageSize);
            currentIndex = pageIndex * this.pageSize;
            this.set("pageIndex", pageIndex);
        }
        return this.subqueries.slice(currentIndex, currentIndex + this.pageSize);
    }

    @Ember.computed("pageIndex", "selected", "selected.length")
    get pageSelection() {
        return this.pageSubqueries.filter((item) => {
            return this.selected.includes(item.id);
        });
    }

    @Ember.computed("selected", "selected.length", "pageIndex", "pageSelection", "pageSelection.length")
    get isEverythingSelected() {
        return this.pageSelection.length > 0 && this.pageSubqueries.length == this.pageSelection.length;
    }

    // events
    deleteConfirm = (subquery) => {};
    editSave = (subquery) => {};

    // actions
    @action
    deleteBeginAction(subquery) {
        this.set("deleting", subquery.id);
    }
    @action
    deleteCancelAction(subquery) {
        this.set("deleting", null);
    }
    @action
    deleteConfirmAction(subquery) {
        return this.deleteConfirm(subquery.id);
    }

    @action
    editBeginAction(subquery) {
        this.set("editing", subquery.id);
    }
    @action
    editCancelAction(subquery) {
        this.set("editing", null);
    }
    @action
    editSaveAction(subqueryId, newName, newQuery) {
        return this.editSave(subqueryId, newName, newQuery).then((response) => {
            this.set("editing", null);
        });
    }
    @action
    selectAllAction() {
        if (this.pageSelection.length == 0 || this.pageSelection.length < this.pageSubqueries.length) {
            this.pageSubqueries.forEach((subquery) => {
                if (!this.selected.includes(subquery.id)) {
                    this.selected.addObject(subquery.id);
                }
            });
        }
        else {
            this.selected.removeObjects(this.pageSubqueries.map((subquery) => {
                return subquery.id;
            }));
        }
    }

    @action
    toggleSelection(subquery) {
        if (this.selected.includes(subquery.id)) {
            this.selected.removeObject(subquery.id);
        }
        else {
            this.selected.addObject(subquery.id);
        }
    }
}