import Ember from "ember";
import Query from "infegy-frontend/models/users/query";

export default Ember.Component.extend({
    isColorKeyCategorical: false,
    colorKey: "positiveAppearancesDistribution",

    query: null,
    topic: null,
    topicsType: null,
    isSourceQuery: false,

    topicGrowthPercent: Ember.computed("topic.documentsGrowthPct", function() {
        return ((this.topic.documentsGrowthPct || 0) * 100).toFixed(0);
    }),

    sentiment: Ember.computed.alias("topic.positiveAppearancesDistribution"),
    sentimentColorStyleSafe: Ember.computed("sentimentColorStyle", function () {
        return new Ember.String.htmlSafe(this.sentimentColorStyle || "");
    }),

    drilldownQuery: Ember.computed("query", "topic", "topicsType", function () {
        var query = this.query,
            topicQuery = this.get("topic.query"),
            topicLabel = this.get("topic.topic");

        if (!topicQuery || !query) {
            return;
        }

        if (this.topicsType === "links" || this.topicsType ===  "domains") {
            let drilldownQuery = this.query.copy(),
                queryInfo = drilldownQuery?.queryInfo;

            queryInfo.startDate.loadJSON(this.query.queryInfo.startDate.toJSON());
            queryInfo.endDate.loadJSON("Now");

            queryInfo.addFilter({
                id: "links_to",
                values: [topicLabel]
            });

            return drilldownQuery;
        } else {
            let drilldownQuery = query.toDrilldownQuery({
                queryString: topicQuery,
                queryLabel: topicLabel,
                isSourceBio: this.isSourceQuery
            });

            return drilldownQuery;
        }
    }),

    drilldownHasLinks: Ember.computed("topicsType", function() {
       return this.topicsType === "links" || this.topicsType === "domains";
    }),

    drilldownLink: Ember.computed("topic.topic", function() {
       return `https://${this.topic.topic}`;
    }),

    drilldownQueries: Ember.computed("drilldownQuery", function() {
        return this.drilldownQuery ? [this.drilldownQuery] : [];
    }),

    passionPercentage: Ember.computed("topic.passion", function() {
        return (this.topic.passion || 0).toFixed(0);
    }),

    showFeatureWarning: Ember.computed("topicsType", "drilldownHasLinks", "drilldownQueries", "drilldownQueries.@each.updated", function() {
        if (!this.drilldownHasLinks) {
            // Only applicable to Links and Linked Domains.
            return false;
        }
        const hasLoaded = this.drilldownQueries.find((query) => {
            return query.posts.isLoaded;
        });
        if (!hasLoaded) {
            // Wait for posts to load.
            return false;
        }
        if (!this.topic.documents) {
            // This warning only applies to queries that have documents but cannot load them.
            return false;
        }
        const hasPosts = this.drilldownQueries.find((query) => {
            return query.posts.data.length;
        });
        // So if we are here and have no posts, display the warning.
        return !hasPosts;
    }),

    numSentences: Ember.computed.alias("topic.sampleSentences.length"),
    hasThreeOrMoreSentences: Ember.computed.gte('numSentences', 3),
    hasTwoOrMoreSentences:  Ember.computed.gte('numSentences', 2)
});
