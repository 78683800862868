import Ember from "ember";
import BaseFieldSelections from "infegy-frontend/models/fields/field-selections/base";

export default BaseFieldSelections.extend({
    apiName: "emotions",
    defaultSubSegment: "Posts Distribution",

    availableEmotions: ["anticipation", "disgust", "joy", "anger", "sadness", "surprise", "fear", "trust", "love", "hate"],

    makeEmotionsFields: function (baseFields, suffix) {
        var that = this;
        return baseFields.map(function (baseField) {
            return that.makeFieldDefinition({
                title: baseField,
                fieldName: baseField + suffix,
            });
        });
    },

    allAvailableFields: Ember.computed("subSegment", "availableEmotions", {
        get: function(key) {
            var baseFields = this.availableEmotions;
            return {
                "Emotions.DocumentsDistribution": this.makeEmotionsFields(baseFields, "DocumentsDistribution"),
                "Emotions.Documents": this.makeEmotionsFields(baseFields, "Documents"),
                "Emotions.Appearances": this.makeEmotionsFields(baseFields, "Appearances"),
            };
        }
    }),
});
