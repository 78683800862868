export default [{
    "value": "en",
    "label": "English"
}, {
    "value": "fr",
    "label": "French"
}, {
    "value": "de",
    "label": "German"
}, {
    "value": "pt",
    "label": "Portuguese"
}, {
    "value": "es",
    "label": "Spanish"
}];