import Ember from "ember";
import { computed } from '@ember/object';
import QueryURL from "infegy-frontend/models/queries/query_url";

export default class extends Ember.Component {
    tagName = "a";
    classNames = ["filterdown-button", 'no-print'];
    classNameBindings = ["isDisabled:atlas-button-disabled:atlas-button"];
    attributeBindings = ["href", "rel", "target"];
    rel = "noopener";
    target = "_blank";

    // can also take a single query - will auto convert to a single item array
    queries = null;

    // should be the url verion of the route name, e.g. "sentiment"
    // If none is supplied, "overview" is used
    destinationPage = "posts";

    // This is an optional JSON hash of query filter properties to apply
    filterHash = null;

    customFilteredQueries = null;

    isDisabled = false;
    
    @computed("queries", "queries.[]", "queries.@each.queryWithin", "filterHash", "customFilteredQueries")
    get filteredQueries() {
        if (!Ember.isNone(this.customFilteredQueries)) {
            return this.customFilteredQueries;
        }
        let queries = this.queries || [],
            filterHash = this.filterHash;
        if (queries && !Ember.isArray(queries)) {
            queries = [queries];
        }
        if (!queries || !Ember.get(queries, "length") || !filterHash) {
            return queries;
        }
        if ("toJSON" in filterHash) {
            filterHash = filterHash.toJSON();
        }
        return queries.map(q => {
            let copy = q.copy();
            copy.get("filters").loadJSON(filterHash);
            return copy;
        });
    }
    set(customFilteredQueries) {
        this.set("customFilteredQueries", customFilteredQueries);
        return customFilteredQueries;
    }

    @computed("filteredQueries", "filteredQueries.[]")
    get serializedQueries() {
        return QueryURL.queryArrayToUrl(this.filteredQueries);
    }

    @computed("serializedQueries", "isDisabled")
    get href() {
        if (this.isDisabled) {
            return null;
        }
        var qUrl = this.serializedQueries,
            destinationPage = this.destinationPage || "posts",
            base_url = window.location.href.split("/atlas/dashboard/")[0],
            full_url = [base_url, "/atlas/dashboard/", qUrl, "/", destinationPage].join("");
        return qUrl && full_url;
    }
}
