import Ember from "ember";
import Component from '@ember/component';
import {computed} from "@ember/object";
import classic from "ember-classic-decorator";

@classic
export default class FieldScalarValue extends Component {
    tagName = "span";
    attributeBindings = ['style'];

    colorize = false;

    showPortionWhole = true;

    valueOverride = null;

    @computed("valueOverride")
    get displayValue() {
        if (!Ember.isEmpty(this.valueOverride)) {
            return this.valueOverride;
        }
        return this.field && this.field.scalarValue;
    };

    @computed("showPortionWhole", "field.fixedPortionWhole")
    get dispayPortionWhole() {
        return this.showPortionWhole && this.field && this.field.fixedPortionWhole;
    };

    @computed("colorize", "field.color")
    get style() {
        if (this.colorize) {
            return this.field?.color.textStyle;
        }
    }
};
