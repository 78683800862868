import Ember from "ember";
import CSSTools from "infegy-frontend/utils/css-tools";
import SVGInnerWrapper from "infegy-frontend/components/charting/svg/svg-inner-wrapper/component";
import d3 from "d3";

export default SVGInnerWrapper.extend({
    classNameBindings: ["field.colorClass","isFaded:trend-chart-field-faded",
        "isHighlighted:dark", "isHighlighted:trend-chart-field-highlighted"],
    attributeBindings: ["style"],

    field: null,
    interactionInfo: null,
    showEventIcons: true,

    isHighlighted: Ember.computed("interactionInfo.hoveredField", "interactionInfo.selectedField", "field", function () {
        return (this.get("interactionInfo.hoveredField") === this.field) ||
            (this.get("interactionInfo.selectedField") === this.field);
    }),

    isFaded: Ember.computed("interactionInfo.hoveredField", "interactionInfo.selectedField", "isHighlighted", function () {
        return (this.get("interactionInfo.hoveredField") || this.get("interactionInfo.selectedField")) && !this.isHighlighted;
    }),

    style: Ember.computed("field.colorValues", "isHighlighted", function(){
        var strokeColor = this.get("field.colorValues.base");
        if(this.isHighlighted){
            strokeColor = this.get("field.colorValues.dark");
        }
        return CSSTools.buildStringFromObjectSafe({
           stroke: strokeColor,
           fill: strokeColor
        });
    }),

    isActive: true,

    hoveredIndex: null,

    displayAsArea: false,

    circleSpacing: 8,

    allowCircles: true,

    yStats: Ember.computed.oneWay("field.stats"),
    xStats: Ember.computed.oneWay("field.xFieldStats"),

    hasHighlightSpot: false,
    highlightSpotValue: null,

    hasHighlightRange: false,
    highlightRangeStartValue: null,
    highlightRangeEndValue: null,

    displayedEvents: [],

    xScale: Ember.computed("field", "field.minX", "field.maxX", "width", function () {
        return d3.scaleLinear()
            .domain([this.get("field.minX") || 0, this.get("field.maxX") || 10])
            .range([0, this.width || 1]);
    }),

    yScale: Ember.computed("field", "field.maxY", "height", function () {
        return d3.scaleLinear()
            .domain([0, this.get("field.maxY") || 10])
            .range([this.height || 1, 0]);
    }),

    highlightedDot: Ember.computed("interactionInfo.isHovered", "interactionInfo.isSelected",
            "interactionInfo.hoveredXPct", "interactionInfo.hoveredYPct",
            "interactionInfo.selectedXPct", "interactionInfo.selectedYPct", function () {
        if (this.get("interactionInfo.isHovered")) {
            return {
                x: this.get("interactionInfo.hoveredXPct"),
                y: this.get("interactionInfo.hoveredYPct")
            };
        } else if (this.get("interactionInfo.isSelected")) {
            var data = this.get("field.data") || [],
                xIdx = this.get("interactionInfo.selectedXIdx"),
                yField = this.get("field.fieldName"),
                yVal = (data && data[xIdx] && data[xIdx][yField]) || 0,
                maxY = this.get("field.maxY");
            return {
                x: this.get("interactionInfo.selectedXPct"),
                y: (1.0 - (yVal / maxY)) * 100
            };
        }
    }),

    displayedEventsPoints: Ember.computed("xScale", "yScale", "filteredEventPoints",
            "filteredEventPoints.length", function() {
        const xField = this.get("field.xAxisField");
        const yField = this.get("field.fieldName");

        return this.filteredEventPoints.map((item) => {
            const test = this.field?.data?.find((item2) => {
                return item2[xField] == item[xField];
            });

            if (Ember.isNone(test)) {
                return;
            }

            return {
                x: this.xScale(Ember.get(item, xField) || 0),
                y: this.yScale(Ember.get(test, yField) || 0)
            }
        });
    }),

    filteredEventPoints: Ember.computed("displayedEvents", "displayedEvents.length", "interactionInfo.isBrushed", "interactionInfo.brushStartXIdx", "interactionInfo.brushEndXIdx", function() {
        if (this.interactionInfo.isBrushed) {
            const xField = this.get("field.xAxisField");
            const valueMin = this.get("interactionInfo.brushStartXValue");
            const valueMax = this.get("interactionInfo.brushEndXValue");
            let filteredEvents = this.displayedEvents.filter((event) => {
                const value = event[xField];
                return valueMin <= value && value <= valueMax;
            });

            return filteredEvents;
        }
        else {
            return this.displayedEvents;
        }
    }),

    dataPoints:  Ember.computed("field", "field.data", "field.data.[]",
            "field.xAxisField", "field.fieldName", "xScale", "yScale", function () {
        var data = this.get("field.data") || [],
            xScale = this.xScale,
            yScale = this.yScale,
            xField = this.get("field.xAxisField") || null,
            yField = this.get("field.fieldName") || null;

        if (!data || !xField || !yField) {
            return [];
        }
        return data.map(function(row) {
            return {
                x: xScale(Ember.get(row, xField)) || 0,
                y: yScale(Ember.get(row, yField) || 0) || 0
            };
        });
    }),

    activePath: Ember.computed("dataPoints", function () {
        var path = d3.line()
                .x(function(d) { return d.x || 0; })
                .y(function(d) { return d.y || 0; })
                .curve(d3.curveMonotoneX);
        return path(this.dataPoints || []);
    }),

    brushedDataPoints: Ember.computed("dataPoints", "interactionInfo.brushStartXIdx",
        "interactionInfo.brushEndXIdx", function () {
        var dataPoints = this.dataPoints || [],
            startIdx = this.get("interactionInfo.brushStartXIdx") || 0,
            endIdx = this.get("interactionInfo.brushEndXIdx") || 0;
        return dataPoints.slice(startIdx, endIdx + 1);
    }),

    brushMask: Ember.computed("field.data.length", "width",
            "interactionInfo.brushStartXIdx", "interactionInfo.brushEndXIdx", function () {
        var numIndicies = this.get("field.data.length") || 0,
            width = Math.abs(this.width),
            height = Math.abs(this.height),
            startIdx = this.get("interactionInfo.brushStartXIdx") || 0,
            endIdx = this.get("interactionInfo.brushEndXIdx") || 0,
            startPct = startIdx / (numIndicies - 1),
            endPct = endIdx / (numIndicies - 1),
            startX = startPct * width,
            endX = (endPct * width) - startX;
        endX = endX > 0 ? endX : 1;
        return {x: startX, width: endX, y: -10, height: height + 10};
    }),

    fieldSelected: () => {},
    actions: {
        fieldSelected: function (field) {
            this.fieldSelected(field);
        }
    }
});
