import Ember from "ember";
import { action, computed } from '@ember/object';
import SentimentCombinedBase from "infegy-frontend/components/sentiment/combined-base/component";

export default class SentimentCombinedTable extends SentimentCombinedBase {
    classNames = ["table-overflow-scroll"];
    attributeBindings = ["style"];
    selectedSentimentRow = null;
    includeNeutral = false;

    style = "display: inline-block; margin: 0px auto;".htmlSafe();

    @computed("combinedDataSeries.data", "activeFields")
    get timelines() {
        let ds = this.combinedDataSeries.data;
        return {
            positive: ds.mapBy(this.activeFields.positive),
            negative: ds.mapBy(this.activeFields.negative),
            neutral: ds.mapBy(this.activeFields.neutral),
            sentimentalContent: ds.mapBy("sentimentalDocuments"),
            passion: ds.mapBy("passion")
        }
    };

    @computed("combinedDataSeries.data", "hoveredIndex")
    get values() {
        let data = this.combinedDataSeries.data || [],
            stats = this.combinedDataSeries.stats,
            result = {};

        if (!Ember.isEmpty(this.hoveredIndex) && data.hasOwnProperty(this.hoveredIndex)) {
            let row = data[this.hoveredIndex];
            result.sentimentalContent = row.sentimentalDocumentsPercent || 0;
            result.passion = row.passion || 0;
        } else {
            result.sentimentalContent = stats.sentimentalDocumentsPercent.average;
            result.passion = stats.passion.average;
        }
        return result;
    };

    @computed("activeFields", "includeNeutral")
    get sentimentRows() {
        let rows = [{
            colorClass: "atlas-sentiment-positive",
            sentimentBarClass: "data-table-sentiment-bar",
            color: this.colors.sentimentPositive.base,
            fieldName: "positive",
            activeFields: this.activeFields,
        },{
            colorClass: "atlas-sentiment-negative",
            sentimentBarClass: "data-table-inverse-sentiment-bar",
            color: this.colors.sentimentNegative.base,
            fieldName: "negative",
            activeFields: this.activeFields,
        }];
        if (this.includeNeutral) {
            rows.push ({
                colorClass: "atlas-sentiment-neutral",
                sentimentBarClass: "data-table-neutral-bar",
                color: this.colors.sentimentNeutral.base,
                fieldName: "neutral",
                activeFields: this.activeFields,
            });
        }
        return rows;
    };

    @computed("values")
    get passionPercentage() {
        return (this.values.passion || 0).toFixed(0);
    };

    @computed("passionPercentage")
    get passionWidthStyleSafe() {
        let percentage = this.get("passionPercentage") || 50;
        return new Ember.String.htmlSafe(["width:", percentage, "%;"].join(""));
    };

    @computed("values")
    get sentimentalContentPercentage() {
        return ((this.values.sentimentalContent || 0) * 100.0).toFixed(0);
    };

    @computed("sentimentalContentPercentage")
    get sentimentalContentWidthStyleSafe() {
        let percentage = this.get("sentimentalContentPercentage") || 50;
        return new Ember.String.htmlSafe(["width:", percentage, "%;"].join(""));
    };

    @action
    hoveredIndexWasChanged(hoveredIndex) {
        let data = this.combinedDataSeries.data || [];
        if (!Ember.isEmpty(hoveredIndex) && data.hasOwnProperty(hoveredIndex)) {
            this.set("hoveredIndex", hoveredIndex);
        } else {
            this.set("hoveredIndex", null);
        }
    };

    @action
    sentimentRowWasSelected(sentimentRow) {
        if (this.selectedSentimentRow === sentimentRow) {
            this.set("selectedSentimentRow", null);
        } else {
            this.set("selectedSentimentRow", sentimentRow);
        }
    };
};
