import Ember from "ember";
import Component from '@ember/component';
import { action, computed } from '@ember/object';

export default class SentimentDetailsTrend extends Component {
    dataSeries = null;

    positiveField = "positiveDocumentsDistribution";
    negativeField = "negativeDocumentsDistribution";

    groupKey = "timestamp";

    isSelected = false;
    selectedIndex = null;
    hoveredIndex = null;

    @computed("selectedIndex", "hoveredIndex")
    get interactionLabel() {
        if (!Ember.isEmpty(this.hoveredIndex)) {
            return "hovered";
        } else if (!Ember.isEmpty(this.selectedIndex)) {
            return "selected";
        }
        return "overall";
    };

    @computed("selectedIndex", "hoveredIndex", "positiveField", "negativeField", "dataSeries")
    get activeValues() {
        let data = this.dataSeries.data || [],
            positiveField = this.positiveField,
            negativeField = this.negativeField,
            hoveredIndex = this.hoveredIndex,
            selectedIndex = this.selectedIndex,
            interactedIndex = !Ember.isEmpty(hoveredIndex) ? hoveredIndex : selectedIndex,
            result = {
                positive: 0,
                negative: 0,
                timestamp: null
            };

        if (!Ember.isEmpty(interactedIndex) && data.hasOwnProperty(interactedIndex)) {
            let row = data[interactedIndex];
            result.positive = Ember.get(row, positiveField);
            result.negative = Ember.get(row, negativeField);
            result.timestamp = Ember.get(row, "timestamp");
        } else {
            result.positive = this.get(`dataSeries.stats.${positiveField}.average`);
            result.negative = this.get(`dataSeries.stats.${negativeField}.average`);
        }
        return result;
    };

    @computed("activeValues", "sentimentField")
    get arcParts() {
        let activeValues = this.activeValues;

        return [{
            name: "Positive",
            colorClass: "chart-positive",
            value: activeValues.positive,
            displayAsPercent: true,
        },{
            name: "Negative",
            colorClass: "chart-negative",
            value: activeValues.negative,
            displayAsPercent: true,
        }];
    };

    hoveredIndexChanged() { /* action placeholder */ };
    selectedIndexChanged() { /* action placeholder */ };
    wasSelected() { /* action placeholder */ };

    @action
    sentimentClicked() {
        this.selectedIndexChanged(null);
        this.wasSelected(!this.isSelected);
    };

    @action
    selectedIndexWasChanged(index) {
        var selectedIndex = this.selectedIndex,
            isSelected = !Ember.isEmpty(index) && selectedIndex !== index;
        this.selectedIndexChanged(isSelected ? index : null);
        this.wasSelected(isSelected);
    };

    @action
    hoveredIndexWasChanged(index) {
        this.hoveredIndexChanged(index);
    };
};
