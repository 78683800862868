import Ember from "ember";
import DateProcessing from "infegy-frontend/utils/date-processing";
import QueryURL from "infegy-frontend/models/queries/query_url";

var millisInDay = DateProcessing.constants.millisInDay;

export default Ember.Component.extend({
    filterableQueries: null,

    interactionInfo: {},
    groupByField: null,
    isGroupedTrends: false,
    filterRange: null,

    moreThanOneDay: Ember.computed("isGroupedTrends", "filterRange", {
        get: function() {
            var range = this.filterRange,
                rangeSize = range && (range.end - range.start) || 0;

            if (this.isGroupedTrends) {
                return rangeSize >= 1;
            } else {
                return rangeSize > (millisInDay - 1);
            }
        }
    }),
    filterable: Ember.computed.or("interactionInfo.isBrushed", "interactionInfo.isSelected"),

    filterQueries: Ember.computed("filterableQueries", "filterableQueries.[]", function () {
        return (this.filterableQueries || []).map(q => q.copy());
    }),

    filteredQueries: Ember.computed("groupByField", "isGroupedTrends", "filterRange", "filterQueries", {
        get: function(key) {
            var queries = this.filterQueries,
                range = this.filterRange;

            if (!queries || !range) {
                return;
            }

            var queryInfos = queries.map((query) => {
                var queryInfo = query.get("queryInfo").copy();
                return queryInfo;
            }, this);

            if (this.isGroupedTrends) {
                key = this.groupByField === "hour" ? "timeOfDay" : "dayOfWeek";

                queryInfos.forEach((queryInfo, index) => {
                    queryInfo.set(key, Array.apply(null, Array(range.end - range.start + 1)).map(function(curr, i, arr) {
                        return i + range.start;
                    }).toString());

                    queries.objectAt(index).set('queryInfo', queryInfo);
                });
            } else {
                queryInfos.forEach((queryInfo, index) => {
                    var gDate = queryInfo.get("startDate");
                    gDate.set("isRelative", false);
                    gDate.get("fixed").fromTimestamp(range.start);

                    gDate = queryInfo.get("endDate");
                    gDate.set("isRelative", false);
                    gDate.get("fixed").fromTimestamp(range.end);

                    queries.objectAt(index).set('queryInfo', queryInfo);
                });
            }

            return queries;
        }
    }),

    serializedQueries: Ember.computed("filterRange", "filteredQueries", {
        get: function(key) {
            var filteredQueries = this.filteredQueries;

            if (!this.filterRange) {
                return false;
            }

            var qUrl = QueryURL.queryArrayToUrl(filteredQueries),
                base_url = window.location.href.split("/atlas/dashboard/")[0],
                full_url = [base_url, "/atlas/dashboard/", qUrl, "/posts"].join("");
            return qUrl && full_url;
        }
    }),
});
