import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import fieldQuotient from "infegy-frontend/models/data_series/data_series_computed_field_quotient";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";

export default QueryAPIResults.extend({
    title : "nps",
    checkIfEmpty: function (dataSeries) {
        return !dataSeries || !dataSeries.get("stats") ||
            !dataSeries.get("stats.postsCount") ||
            !dataSeries.get("stats.postsCount.sum");
    },
    description: DataSeriesDescription.load({
        "date": "datestring",
        "timestamp": "timestamp",
        "groupName": "string",

        npsCount: "number",
        npsMean: "average",
        npsSum: "number",

        ratingCount: "number",
        ratingMean: "average",
        ratingSum: "number",

    }, [
        fieldQuotient.fieldSetup("npsAverage", "npsSum", "npsCount"),
        fieldQuotient.fieldSetup("ratingAverage", "ratingSum", "ratingCount"),
    ])
});
