import Ember from "ember";
import DataSeries from "infegy-frontend/models/data_series/data_series";

export default Ember.Component.extend({
    queries: [],

    queryColorClass: "atlas-ui",

    sentimentField: "Documents",

    sentimentDataSeries: Ember.computed("queries","queries.length","queries.@each.updated",function(){
        var queries = this.queries;
        return DataSeries.stackTimeseries(queries.map((query)=> query.get(`sentiment.dataSeries`)));
    }),

    combinedStats: Ember.computed("queries", "queries.[]", "queries.@each.updated",
            "sentimentField", function() {
        var queries = this.queries,
            sentimentField = this.sentimentField,
            results = queries.reduce((memo, q) => {
                memo.positive += q.get(`sentiment.stats.positive${sentimentField}.sum`) || 0;
                memo.negative += q.get(`sentiment.stats.negative${sentimentField}.sum`) || 0;
                memo.sentimentalDocuments += q.get("sentiment.stats.sentimentalDocuments.sum") || 0;
                memo.totalScoredDocuments += q.get("sentiment.stats.sentimentalScoredDocuments.sum") || 0;
                memo.sentimentSum = memo.positive + memo.negative;
                return memo;
            }, {positive: 0, negative: 0, sentimentSum: 0, sentimentalDocuments: 0, totalScoredDocuments: 0});
        return {
            positivePercent: results.positive / results.sentimentSum,
            negativePercent: results.negative / results.sentimentSum,
            sentimentalDocumentsPercent: results.sentimentalDocuments / results.totalScoredDocuments
        };
    }),
});
