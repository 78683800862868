import Ember from "ember";
import Component from '@ember/component';
import {computed, action} from "@ember/object";
import ChartColors from "infegy-frontend/static_data/fixtures/chart_colors";
import EntityDrilldownBase from "infegy-frontend/components/entities/entity-drilldown-base/component";

export default class extends EntityDrilldownBase {
    classNames = ["linked-entity-item"];
    attributeBindings = ["canvasComponentStyle:style"];

    queries = null;
    linkedEntity = null;
    hasMoreThanOneQuery = false;
    isExpanded = false;

    @computed.gt("referencedEntities.length", 1) hasMoreThanOneChild;
    @computed.alias("linkedEntity") entity;

    @computed("isCanvasWidget", "secondaryColor")
    get canvasComponentStyle() {
        return this.isCanvasWidget && this.secondaryColor ? `background-color:${this.secondaryColor}; 
            border:1px solid ${this.secondaryColor};`.htmlSafe() : "";
    }

    @computed("isCanvasWidget", "primaryColor")
    get canvasTextColor() {
        return this.isCanvasWidget && this.primaryColor ? `color:${this.primaryColor}`.htmlSafe() : "";
    }

    @computed("linkedEntity.positiveAppearancesDistribution")
    get sentimentPercentage() {
        var pct = (this.get("linkedEntity.positiveAppearancesDistribution") || 0) * 100.0;
        return pct.toFixed(0);
    }

    @computed("sentimentPercentage")
    get sentimentWidthStyleSafe() {
        return new Ember.String.htmlSafe(`width:${this.sentimentPercentage}%;`);
    }

    @computed("linkedEntity.positiveAppearancesDistribution")
    get sentimentStyleSafe() {
        let sentiment = this.get("linkedEntity.positiveAppearancesDistribution"),
            colorValues = ChartColors.sentimentZeroToOneGradientD3();
        return new Ember.String.htmlSafe(`color:${colorValues(sentiment)};`);
    }

    @computed("linkedEntity")
    get referencedEntities() {
        let referencedEntities = this.linkedEntity && this.linkedEntity["referencedEntities"] || [];
        return referencedEntities.map(referencedEntity => ` ${referencedEntity.name}`);
    }

    @computed("linkedEntity")
    get collatedEntities() {
        let collatedEntities = this.linkedEntity && this.linkedEntity["collatedEntities"] || [];

        return collatedEntities.map(function(collatedEntity) {
            let startIndex = collatedEntity.name.indexOf("(") + 1,
                endIndex = collatedEntity.name.indexOf(")");
            return ` ${collatedEntity.name.substring(startIndex, endIndex)}`;
        });
    }

    @computed("keyValuePairs")
    get formattedKeyValuePairs() {
        let keyValuePairsCopy = this.keyValuePairs ? [...this.keyValuePairs] : null;
        let hasRelatedEntities = this.keyValuePairs.findIndex(item => {
            return item.key === 'relatedEntities' && item.value.length > 1;
        });

        if (this.keyValuePairs[hasRelatedEntities]?.value) {
            const relatedEntitiesNames = this.keyValuePairs[hasRelatedEntities]?.value
                .map(entity => entity.name).join(", ");

            // Changing the array of related entities objects to a string of 
            // just the names of the related entities
            keyValuePairsCopy[hasRelatedEntities].value = relatedEntitiesNames;
        } else {
            for (let i = 0; i <= keyValuePairsCopy.length - 1; i++) {
                // Don't show the property "relatedEntities" in the DOM
                // if there are no related entities to be shown
                if (keyValuePairsCopy[i].key === "relatedEntities") {
                    keyValuePairsCopy.splice(i, 1);
                }
            }
        }

        return keyValuePairsCopy;
    }

    // function placeholder for action
    removeEntity = null;

    @action
    expandCard() {
        this.toggleProperty("isExpanded");
    }

    @action
    removingEntity() {
        if (this.removeEntity) {
            this.removeEntity(this.get("linkedEntity.id"));
        }
    }
}
