import Ember from "ember";
import Component from '@ember/component';
import { computed } from '@ember/object';
import FieldSelectorBase from "infegy-frontend/components/field-selector/parts/fields/trendable-field-base/component";

export default class FieldSelectorTopKeywords extends FieldSelectorBase {
    apiName = "topKeywords";
    datasetFields = null;
    maxSelection  =  6;
    maxFieldButtons = 30;

    _subField = null;
    @computed("firstSelectedField", "firstSelectedField.subField", "_subField")
    get subField() {
        if (this.usingSubFields) {
            if (this.firstSelectedField) {
                return this.firstSelectedField.customDataSubField;
            }
            return this._subField;
        }
    }

    @computed("allQueries", "selectedSubsegment")
    get apiModels() {
        let queries = this.allQueries || [],
            field = this.selectedSubsegment;
        return this.allQueries?.map(q => {
            let resp = q.fetchTopKeywords(field);
            return resp?.apiModel;
        }).filter(resp => resp);
    }

    @computed("apiModels.@each.isLoaded")
    get isLoaded() {
        return !(this.apiModels || []).find(apiModel => !apiModel.isLoaded);
    }

    @computed("isLoaded", "apiModels.@each.isLoaded")
    get sortedSeenValues() {
        if (!this.isLoaded) {
            return null;
        }
        let allSeenValues = [],
            seenValuesTotals = {};
        this.apiModels.forEach(apiModel => {
            allSeenValues = allSeenValues.concat(apiModel.sortableSeenValues || []);
        });
        allSeenValues.forEach(val =>  {
            seenValuesTotals[val.value] = (seenValuesTotals[val.value] || 0) + val.count;
        });
        allSeenValues = allSeenValues.map(kw => {
            return {
                value: kw.value,
                count: seenValuesTotals[kw.value] || 0
            };
        }).sortBy("count").reverse().uniqBy("value").mapBy("value");
        return allSeenValues;
    }

    @computed("fieldType", "datasetFields", "selectedSegment", "selectedSubsegment", "isLoaded", "apiModels", "sortedSeenValues")
    get allAvailableFields() {
        if (!this.isLoaded || !this.sortedSeenValues) {
            return [];
        }

        let options = {},
            sortedSeenValues = this.sortedSeenValues || [],
            selectedSubsegment = this.selectedSubsegment,
            datasetFields = this.datasetFields || [],
            maxFieldButtons = this.maxFieldButtons,
            datasetFieldTitle = datasetFields.findBy("id", selectedSubsegment)?.name;

        if (!this.displayAsDropdown && sortedSeenValues.length > maxFieldButtons) {
            sortedSeenValues = sortedSeenValues.slice(0,maxFieldButtons);
        }

        options[selectedSubsegment] = sortedSeenValues.map(seenValue => {
            let title = seenValue,
                titleLength = 30;
            if (title.length > titleLength) {
                title = `${seenValue.slice(0, titleLength)}…`;
            }
            return this.makeFieldDefinition({
                fieldName: "count",
                customDataField: this.selectedSubsegment,
                customDataKeyword: seenValue,
                customDataSubField: this.subField,
                title: title,
                value: `${seenValue}${this.selectedSubsegment}${this.subField}`,
                chartTitleOverride: datasetFieldTitle || title,
                axisGroup: "datasetAverage"
            });
        });

        return options;
    }
};
