import Ember from "ember";
import QueryURL from "infegy-frontend/models/queries/query_url";
import FilterdownButton from "infegy-frontend/components/filterdown-button/component";

export default FilterdownButton.extend({
    // can also take a single query - will auto convert to a single item array
    queries: null,
    withinQueryType: "topic",
    withinQueryLabel: null,
    withinQueryString: null,

    filteredQueries: Ember.computed("queries", "withinQueryString", 
        "withinQueryType", "withinQueryLabel", function () {
        var queries = this.queries || [],
            withinQueryString = this.withinQueryString,
            withinQueryType = this.withinQueryType,
            withinQueryLabel = this.withinQueryLabel;

        if (queries && !Ember.isArray(queries)) {
            queries = [queries];
        }

        if (!queries || !Ember.get(queries, "length") || !withinQueryString) {
            return queries;
        }
        if (!withinQueryLabel) {
            withinQueryLabel = withinQueryString;
        }

        return queries.map(query => {
            var newQuery = query.copy();
            if (newQuery.get("isUsingQueryBuilder")) {
                newQuery.get("queryInfo.queryBuilderDetail.drillInItems").loadJSONRow({
                    label: withinQueryLabel,
                    query: withinQueryString,
                    type: withinQueryType
                });
            } else {
                newQuery.set("title", [withinQueryLabel, "within", query.get("title")].join(" "));
                let oldQueryWithin = newQuery.get("queryInfo.queryWithin"),
                    queryWithin = newQuery.get("queryString");
                if (oldQueryWithin) {
                    queryWithin = [queryWithin, "AND", oldQueryWithin].join(" ");
                }
                newQuery.set("queryInfo.queryWithin", queryWithin);
                newQuery.set("queryString", withinQueryString);
            }
            return newQuery;
        });
    }),
});


