import Ember from "ember";
import BaseFieldSelections from "infegy-frontend/models/fields/field-selections/base";
import Languages from "infegy-frontend/static_data/query/query_filters/languages";

export default BaseFieldSelections.extend({
    apiName: "languages",
    defaultSubSegment: "Passion",

    maxAvailableLanguages: 10,

    availableLanguages: Ember.computed("maxAvailableLanguages", "allQueries", "allQueries.[]", "allQueries.@each.languages", "allQueries.@each.updated", {
        get: function(key) {
            var allQueries = this.allQueries || [],
                maxAvailableLanguages = this.maxAvailableLanguages || 10,
                languages = [];
            allQueries.forEach(function(query) {
                languages = languages.concat(query.get("languages.availableLanguages") || []).uniq();
            });
            // Sort By Appearances - Ensure most important ones are first.
            languages = languages.map(function(language) {
                return {
                    language: language,
                    count: allQueries.reduce(function(memo, query) {
                            var languageResults = query.get("languages.stats." + language + ".sum") || 0;
                            return memo - languageResults;
                        }, 0)
                };
            }).sortBy("count").slice(0, maxAvailableLanguages);

            return languages.mapBy("language");
        }
    }),

    makeLanguagesFields: function (baseFields, suffix) {
        var that = this,
            languageLabels = Languages.allLanguages;
            
        var retVal = baseFields.map(function (baseField) {
            var title = languageLabels.findBy('value', baseField) && 
                            languageLabels.findBy('value', baseField).label;
            
            return that.makeFieldDefinition({
                title: title,
                fieldName: baseField + suffix,
            });
        });

        return retVal;
    },

    allAvailableFields: Ember.computed("availableLanguages", {
        get: function(key) {
            var baseFields = this.availableLanguages;
            var lol = {
                "Languages.DocumentsDistribution": this.makeLanguagesFields(baseFields, "DocumentsDistribution"),
                "Languages.Documents": this.makeLanguagesFields(baseFields, "", null),
            };

            return lol;
        }
    }),
});
