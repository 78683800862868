import { computed } from '@ember/object';

import FieldSelectorBase from "infegy-frontend/components/field-selector/parts/fields/trendable-field-base/component";
import layout from "infegy-frontend/components/field-selector/parts/fields/trendable-selector-layout/template";

export default class FieldSelectorInfluencers extends FieldSelectorBase {
    layout = layout;
    apiName = "influencers";

    @computed("fieldType", "selectedSegment", "selectedSubsegment", "isLoaded")
    get allAvailableFields() {
        return {
            "Followers" : [this.makeFieldDefinition({
                fieldName: "metaFollowers",
                title: "Followers",
                color: this.get("query.queryInfo.color"),
                axisGroup: "metaFollowers",
                isMeta: true
            })],
            "Following" : [this.makeFieldDefinition({
                fieldName: "metaFollowing",
                title: "Following",
                color: this.get("query.queryInfo.color"),
                axisGroup: "metaFollowing",
                isMeta: true
            })]
        };
    };
};
