import Ember from "ember";
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import AtlasColors from "infegy-frontend/utils/atlas-colors";
import FieldSelectorBase from "infegy-frontend/components/field-selector/parts/fields/trendable-field-base/component";

export default class FieldSelectorSentiment extends FieldSelectorBase {
    apiName = "sentiment";

    makeSentimentFields(baseFields, suffix, includeNeutral, axisGroup) {
        var that = this;
        return baseFields.map(function (baseField) {
            return that.makeFieldDefinition({
                title: baseField,
                fieldName: baseField + suffix,
                defaultColor: AtlasColors.getColorfromClassName("atlas-sentiment-" + baseField),
                inactiveByDefault: !includeNeutral && baseField === "neutral",
                additionalSelectorProperties: includeNeutral && {
                    include_neutral: includeNeutral
                },
                axisGroup: axisGroup || baseField
            });
        });
    };

    @computed("fieldType", "selectedSegment", "selectedSubsegment", "isLoaded", "includeNeutral")
    get allAvailableFields() {
        var baseFields = ["positive", "negative"],
            allAvailableFields = {};

        if (this.includeNeutral) {
            baseFields = ["positive", "negative", "neutral"];
            allAvailableFields["SubjectSentencesDistribution"] =
                    this.makeSentimentFields(baseFields, "SubjectSentencesAllDistribution", true, "percentage");
            allAvailableFields["SentencesDistribution"] =
                    this.makeSentimentFields(baseFields, "SentencesAllDistribution", true, "percentage");
            allAvailableFields["DocumentsDistribution"] =
                    this.makeSentimentFields(baseFields, "DocumentsAllDistribution", true, "percentage");
        } else {
            allAvailableFields["SubjectSentencesDistribution"] =
                    this.makeSentimentFields(baseFields, "SubjectSentencesDistribution", false, "percentage");
            allAvailableFields["SentencesDistribution"] =
                    this.makeSentimentFields(baseFields, "SentencesDistribution", false, "percentage");
            allAvailableFields["DocumentsDistribution"] =
                    this.makeSentimentFields(baseFields, "DocumentsDistribution", false, "percentage");
        }

        baseFields = ["positive", "negative", "neutral"];

        allAvailableFields["SubjectSentences"] =
                this.makeSentimentFields(baseFields, "SubjectSentences", false, "Sentiment.SubjectSentences");
        allAvailableFields["Sentences"] =
                this.makeSentimentFields(baseFields, "Sentences", false, "Sentiment.Sentences");
        allAvailableFields["Documents"] =
                this.makeSentimentFields(baseFields, "Documents", false, "Sentiment.Documents");
        allAvailableFields["Universe"] =
                this.makeSentimentFields(baseFields, "DocumentsUniverse", false, "Sentiment.DocumentsUniverse");

        return allAvailableFields;
    };

    @computed("selectedSubsegment")
    get hasIncludeNeutralOption() {
        let neutralSegments = ["SubjectSentencesDistribution", "SentencesDistribution", "DocumentsDistribution"];
        return neutralSegments.includes(this.selectedSubsegment);
    }

    _includeNeutral = false;
    @computed("firstSelectedFieldAdditionalSelectorProperties", "_includeNeutral")
    get includeNeutral() {
        if (this.hasIncludeNeutralOption) {
            if (this.firstSelectedField) {
                return this.firstSelectedFieldAdditionalSelectorProperties.include_neutral;
            }
            return this._includeNeutral;
        }
    }

    @action
    toggleIncludeNeutral() {
        this.set("_includeNeutral", !this.includeNeutral);
        this.fieldsChanged(null);
    }
};
