import Ember from "ember";
import { action, computed } from "@ember/object";
import { isEmpty, isNone } from "@ember/utils";
import ControllerBase from "infegy-frontend/atlas/dashboard/content/base/controller";
import DataSeries from "infegy-frontend/models/data_series/data_series";
import BrowserStore from "infegy-frontend/utils/browser-store";

export default class extends ControllerBase {
    apis = ['interests', 'postInterests'];
    interestField = "universe";
    interestFieldOptions = [{label: "Posts", value: "documents"}, {label: "Universe", value: "universe"}];

    @BrowserStore.Attr("interests-selected-api",{ defaultValue: "interests" }) selectedApi;

    @computed("selectedApi", "hasOnlyCustomDatasets")
    get activeApi() {
        if (this.hasOnlyCustomDatasets) {
            return "postInterests";
        }
        return this.selectedApi;
    }

    @computed("queries", "queries.@each.updated", "activeApi", "isLoaded")
    get apiOptions() {
        const result = [
            { title: 'Source Interests', value: 'interests' },
            { title: 'Content Subject', value: 'postInterests' }
        ].filter((option) => {
            // Only filter out if the stats are loaded with no results to try and prevent screen flicker
            const found = this.queries.find((query) => {
                if (isNone(query[option.value].dataLength))
                    return true;
                return query[option.value].dataLength > 0;
            });
            return !isNone(found);
        });
        return result;
    }

    @computed.gt("apiOptions.length", 0) hasInterests;

    @computed("content.availableQueryFields.interests", "hasInterests", "apiOptions")
    get areInterestsEmpty() {
        return !(this.content.availableQueryFields.interests && this.hasInterests);
    }

    @BrowserStore.Attr("interests-selected-column-option",{ defaultValue: "distribution" }) selectedInterestOptionView;

    interestsColumnOptions = [{
        title: "Distribution",
        value: "distribution"
    }, {
        title: "Ratio",
        value: "ratio"
    }]

    @computed("queries","queries.@each.updated","activeApi")
    get stackedData() {
        let queries=this.queries || [],
            api = this.activeApi;
        var dss = queries.mapBy(`${api}.dataSeries`);
        if (!dss[0]) {
            return null;
        }
        return DataSeries.stackBy(dss,"name");
    }

    hoveredInterest = null;
    selectedInterest = null;

    @computed.or("hoveredInterest", "selectedInterest") activeInterest;

    @BrowserStore.Attr("interests-is-scatterplot-hidden", { defaultValue: true }) isScatterplotHidden;

    @computed("activeApi", "isScatterplotHidden")
    get showScatterplot() {
        var activeApi = this.activeApi,
            isScatterplotHidden = this.isScatterplotHidden;

        return activeApi === "interests" && !isScatterplotHidden;
    }

    @computed("queries", "queries.[]")
    get customDatasetQueries() {
        return (this.queries || []).filter(q => q.queryInfo?.customDatasetId);
    }

    @computed("queries", "queries.[]", "activeApi")
    get activeQueries() {
        var queries = this.queries || [];
        if (this.activeApi === "interests") {
            return queries.filter(q => !q.queryInfo.customDatasetId );
        }
        return queries;
    }

    @action
    selectedApiChanged(api) {
        this.set("selectedApi", api);
        // postInterests does not return a "ratio" value in its response
        this.set("selectedInterestOptionView", "distribution");
        this.set("interestField", "universe");
    }

    @action
    hoveredInterestChanged(interest) {
        this.set("hoveredInterest", interest);
    }

    @action
    selectedInterestChanged(interest) {
        if (interest === this.selectedInterest) {
            interest = null;
        }

        this.set("selectedInterest", interest);
    }

    @action
    toggleScatterplot() {
        this.toggleProperty("isScatterplotHidden");
    }

    @action
    selectedInterestOptionViewChanged(value) {
        this.set("selectedInterestOptionView", value);
    }

    @action
    removeInterest(topicName) {
        var queries = this.queries,
            activeApi = this.activeApi;
        queries.forEach((query) => {
            var interests = Ember.get(query,`${activeApi}.dataSeries.data`);
            interests = interests.filter((interest) => interest.name !== topicName);
            Ember.set(query,`${activeApi}.dataSeries.data`, interests);
            query.incrementProperty("updated");
        });
    }

    @action
    interestFieldChanged(interestField) {
        this.set("interestField", interestField);
    }
}
