import Component from "@ember/component";
import { computed } from '@ember/object';

export default class Paginate extends Component {
    items = [];
    itemsPerPage = 10;
    currentPage = 0;

    @computed("items", "items.[]", "currentPage", "itemsPerPage")
    get results() {
        const items = this.items || [];
        const startIndex = this.currentPage * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        let results = items.slice(startIndex, endIndex);
        return results;
    }
}