import Ember from "ember";
import BaseFieldSelections from "infegy-frontend/models/fields/field-selections/base";

export default BaseFieldSelections.extend({
    apiName: "themes",
    defaultSubSegment: "Posts Distribution",

    availableThemes: Ember.computed("allQueries", "allQueries.[]",
            "allQueries.@each.themes", "allQueries.@each.updated", function () {
        var allQueries = this.allQueries || [],
            themes = [];
        allQueries.forEach(function(query) {
            themes = themes.concat(query.get("themes.availableThemes") || []).uniq();
        });
        // Sort By Appearances - Ensure most important ones are first.
        themes = themes.map(function(theme) {
            return {
                theme: theme,
                count: allQueries.reduce(function(memo, query) {
                        var themeResults = query.get("themes.stats." + theme + "Appearances.sum") || 0;
                        return memo - themeResults;
                    }, 0)
            };
        }).sortBy("count");
        return themes.mapBy("theme");
    }),

    makeThemesFields: function (baseFields, suffix) {
        var that = this;
        return baseFields.map(function (baseField) {
            return that.makeFieldDefinition({
                title: baseField,
                fieldName: baseField + suffix
            });
        });
    },

    allAvailableFields: Ember.computed("subSegment", "availableThemes", function () {
        var baseFields = this.availableThemes;
        return {
            "Themes.DocumentsDistribution":
                this.makeThemesFields(baseFields, "DocumentsDistribution"),
            "Themes.PositiveDocumentsDistribution":
                this.makeThemesFields(baseFields, "PositiveDocumentsDistribution"),
            "Themes.NegativeDocumentsDistribution":
                this.makeThemesFields(baseFields, "NegativeDocumentsDistribution"),
            "Themes.PositiveSentimentalDocumentsDistribution":
                this.makeThemesFields(baseFields, "PositiveSentimentalDocumentsDistribution"),
            "Themes.NegativeSentimentalDocumentsDistribution":
                this.makeThemesFields(baseFields, "NegativeSentimentalDocumentsDistribution"),
            "Themes.PositiveDocuments":
                this.makeThemesFields(baseFields, "PositiveDocuments"),
            "Themes.NegativeDocuments":
                this.makeThemesFields(baseFields, "NegativeDocuments"),
            "Themes.Documents":
                this.makeThemesFields(baseFields, "Documents"),
            "Themes.PositiveAppearancesDistribution":
                this.makeThemesFields(baseFields, "PositiveAppearancesDistribution"),
            "Themes.NegativeAppearancesDistribution":
                this.makeThemesFields(baseFields, "NegativeAppearancesDistribution"),
            "Themes.PositiveAppearances":
                this.makeThemesFields(baseFields, "PositiveAppearances"),
            "Themes.NegativeAppearances":
                this.makeThemesFields(baseFields, "NegativeAppearances"),
            "Themes.Appearances":
                this.makeThemesFields(baseFields, "Appearances"),
        };
    }),
});
