import Ember from "ember";

export default Ember.Mixin.create({
    dataSeries: null,

    stats: Ember.computed.readOnly("dataSeries.stats"),
    data: Ember.computed.readOnly("dataSeries.data"),

    isLoading: Ember.computed.not('isLoaded'),
    isLoaded: Ember.computed("dataSeries", "dataSeries.data", "isEmpty", {
        get: function(key) {
            return !Ember.isEmpty(this.dataSeries) || this.get("dataSeries.isEmpty");
        },
    }),
    isEmpty: Ember.computed("dataSeries", "dataSeries.data", {
        get: function(key) {
            return !Ember.isEmpty(this.dataSeries) && this.get("dataSeries.isEmpty");
        },
    }),
    hideInnerView: Ember.computed.or("isEmpty", "isLoading")
});
