import Ember from "ember";
import AtlasMath from "infegy-frontend/utils/atlas-math";
import QueryFixedDate from "infegy-frontend/models/queries/query_fixed_date";
import AtlasConfig from "infegy-frontend/config/infegy-app-config";

var queryRelativeToFixedDate = function(units, value, modifier, existingFixedDateObj) {
    var fixedMoment = moment.utc().subtract(value, units);

    var newDate = existingFixedDateObj;
    if (!existingFixedDateObj || !(existingFixedDateObj instanceof QueryFixedDate)) {
        newDate = QueryFixedDate.create();
    }
    newDate.fromMoment(fixedMoment);

    return newDate;
};

var queryRelativeToFixedTimestamp = function(units, value, modifier) {
    var fixedMoment = moment.utc().subtract(value, units);
    return fixedMoment.valueOf();
};

export default Ember.Object.extend({

    isNow: false,

    value: 3,
    units: "months",
    modifier: "ago",

    toFixed: function(existingFixedDateObj) {
        return queryRelativeToFixedDate(this.units,
            this.value,
            this.modifier,
            existingFixedDateObj);
    },
    toTimestamp: function() {
        if(this.isNow){
            return Date.now();
        } else {
            return queryRelativeToFixedTimestamp(this.units, this.value, this.modifier);
        }

    },
    isOnOrAfter: function(refTimestamp) {
        var newDate = this.toFixed();
        return newDate.isOnOrAfter(refTimestamp);
    },
    isValidAtlasDate: Ember.computed("value", "units", "modifier", {
        get: function(key) {
            return this.isOnOrAfter(AtlasConfig.EarliestAtlasTimestamp);
        },
    }),
    timestamp: Ember.computed("isNow", "units", "value", "modifier", function () {
        return this.toTimestamp();
    }),

    toJSON: function() {
        return {
            "modifier":this.modifier,
            "units":this.units,
            "value":this.value,
        };
    },
    string: Ember.computed("modifier", "units", "value", "isNow", {
        get: function(key) {
            if (this.isNow) {
                return "Now";
            }
            return [this.value,this.units,this.modifier].join(" ");
        },
        set: function(key, value) {
            var parts = value.trim().toLowerCase().split(/[ ,\/_\-]+/);
            if (parts.length === 1 && parts[0] === "now") {
                this.set("isNow", true);
            }
            if (parts.length > 1) {
                this.set("isNow",false);
                this.set("value", parts[0]);
                this.set("units", parts[1]);
            }
            if (parts.length > 2) {
                this.set("modifier", parts[2]);
            }
            return value;
        }
    }),

    limitValue: function(earliestDate) {
        earliestDate = earliestDate || AtlasConfig.EarliestAtlasTimestamp;
        var dateValue = this.value,
            dateUnits = this.units,
            dateModifier = this.modifier;

        if (!dateValue) {
            return;
        }

        var modValue = Math.abs(AtlasMath.toInt(dateValue)) || 1;

        var fixedTimestamp = queryRelativeToFixedTimestamp(dateUnits, modValue, dateModifier);

        if (fixedTimestamp < earliestDate) {
            // if Days, modValue = a.diff(b, 'days') where a is start date and b is now...
            // if Months
            var now = moment(),
                dataStart = moment(new Date(earliestDate));

            if (dateUnits === "days") {
                modValue = now.diff(dataStart, 'days');
            } else if (dateUnits === "weeks") {
                modValue = now.diff(dataStart, 'weeks', true);
            } else if (dateUnits === "months") {
                modValue = now.diff(dataStart, 'months', true);
            } else if (dateUnits === "years") {
                modValue = now.diff(dataStart, 'years', true);
            }

            /*
            If there is a remainder of time, the modValue must be reduced
            so that it will not contain dates outside the allowed date range.
            For example: if the difference between the start date and "now" is
            1 month and 15 days, the user must not be able to select "2 months ago",
            otherwise 15 days outside their allowed range will be included.
            */
            modValue = Math.floor(modValue);
        }

        if (modValue !== dateValue) {
            this.set("modValue", modValue);
            this.set("value", modValue);
        }
    },
    modValue: 30,
});
