import Ember from "ember";
import Prop from 'infegy-frontend/json-store/properties/model';
import TrendFieldDefinition from "infegy-frontend/models/fields/trend-field-definition";

export default TrendFieldDefinition.extend({
    maxY: Prop.Attr(),
    xAxisField: Prop.Attr(),

    dataSeries: null,
    unfilteredDataSeries: null,

    data: Ember.computed.readOnly("dataSeries.data"),
    stats: Ember.computed.readOnly("dataSeries.stats"),

    sourceField: null,

    fieldStats: Ember.computed("fieldName", "stats", function () {
        var fieldName = this.fieldName,
            stats = this.stats;
        return stats && fieldName && Ember.get(stats, fieldName);
    }),

    unfilteredFieldStats: Ember.computed("unfilteredDataSeries.stats", "fieldName", function () {
        var fieldName = this.fieldName,
            stats = this.get("unfilteredDataSeries.stats");
        if (!stats) {
            return this.fieldStats;
        }
        return stats && fieldName && Ember.get(stats, fieldName);
    }),

    xFieldStats: Ember.computed("xAxisField", "stats", function () {
        var fieldName = this.xAxisField,
            stats = this.stats;
        return stats && fieldName && fieldName && Ember.get(stats, fieldName);
    }),

    fieldType: Ember.computed.readOnly("fieldStats.type"),
    sum: Ember.computed.readOnly("fieldStats.sum"),
    min: Ember.computed.readOnly("fieldStats.min"),
    max: Ember.computed.readOnly("fieldStats.max"),
    average: Ember.computed.readOnly("fieldStats.average"),

    minX: Ember.computed.readOnly("xFieldStats.min"),
    maxX: Ember.computed.readOnly("xFieldStats.max"),

    xValues: Ember.computed("data", "xAxisField", function () {
        var data = this.data || [],
            xAxisField = this.xAxisField;
        return data.mapBy(xAxisField);
    }),

    isPercentageType: Ember.computed("fieldType", function () {
        var fieldType = this.fieldType;
        return (fieldType === "percentage") ||
            (fieldType === "percent") ||
            (fieldType === "distribution") ||
            (fieldType === "portion");
    }),

    isAveragedType: Ember.computed("isPercentageType", "fieldType", function () {
        return this.fieldType !== "portion" &&
            (this.isPercentageType || this.fieldType === "average");
    }),

    summaryValue: Ember.computed("fieldType", "isAveragedType", "sum",
            "average", function () {
        if (this.isAveragedType) {
            return this.average;
        }
        return this.sum;
    }),

    unfilteredSummaryValue: Ember.computed("fieldType", "isAveragedType",
            "unfilteredFieldStats", function () {
        if (this.isAveragedType) {
            return this.get("unfilteredFieldStats.average");
        }
        return this.get("unfilteredFieldStats.sum");
    }),

    minRow: Ember.computed("min", "fieldStats", "dataSeries", "dataSeries.data",
            "fieldStats.minIndex", function () {
        var fieldStats = this.fieldStats,
            minIdx = fieldStats && fieldStats.get("minIndex") || 0;
        return this.getDataSeriesRow(minIdx);
    }),

    maxRow: Ember.computed("max", "fieldStats", "dataSeries", "dataSeries.data",
            "fieldStats.maxIndex", function () {
        var fieldStats = this.fieldStats,
            maxIdx = fieldStats && fieldStats.get("maxIndex") || 0;
        return this.getDataSeriesRow(maxIdx);
    }),

    minValueTimestamp: Ember.computed.oneWay("minRow.timestamp"),
    maxValueTimestamp: Ember.computed.oneWay("maxRow.timestamp"),

    getDataSeriesRow: function(index) {
        return this.dataSeries && this.get("dataSeries.data")[index] || {};
    },

    getDataSeriesRowFieldValue: function(index) {
        var row = this.getDataSeriesRow(index),
            field = this.fieldName;
        return (row && field && row[field]) || 0;
    }
});
