export default {
    "id": "string",

    "language": "string",
    "sentiment": "string",
    "subjectSentiment": "string",
    "sentimentScore": "number",
    "sentimentWords": "number",

    "summary": "string",

    "themes": "object",
    "emotions": "object",
    "interests": "object",

    "source": "object",

    // Accessing field values should be done using bracket notation as follows:
    // post.fields["kw-0"]
    // They are parsed into this format using the posts endpoint preload and
    // postload functions.
    "fields": "object",

    //////////////////////////////////////////////////////////////////////////////
    // Old posts fields, included for reference.
    // This should be deleted once the dynamic fields update is complete.
    //////////////////////////////////////////////////////////////////////////////

    // "body": "string",
    // "published": "datestring",
    //
    // "userSentiment": "percent",
    // "subjectSentimentScore": "number",
    //
    // "title": "string",
    // "author": "string",
    // "htmlUrl": "string",
    // "feedTitle": "string",
    // "link": "string",
    // "feedUrl": "string",
    //
    // "queryTags": "object",
    //
    // "followers": "number",
    // "following": "number",
    // "likes": "number",
    // "comments": "number",
    // "shares": "number",
    //
    // "dma": "string",
    // "usState": "string",
    // "geohash": "string",
    // "latitude": "number",
    // "longitude": "number",
    //
    // "found": "string",
    // "influence": "number",
    // "subjectSentiment": "string",
    // "entities": "object"
};
