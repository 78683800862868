import Ember from "ember";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import AtlasConfig from "infegy-frontend/config/infegy-app-config";
import UserOptions from "infegy-frontend/models/user_options";
import UserOptionsDefaults from "infegy-frontend/models/user_options_defaults";

export default Ember.Route.extend({
    environmentName: Ember.computed(function() {
        if (AtlasConfig.environment == "development") return "(development)";
        if (AtlasConfig.environment === "staging") return "(staging)";
        return "";
    }),

    title: Ember.computed("environmentName", function() {
        let title = "Infegy Atlas";
        if (!Ember.isEmpty(this.environmentName)) {
            title += ` ${this.environmentName}`;
        }
        return title;
    }),

    setupController: function(controller) {
        var userOptions = UserOptions.create();
        userOptions.loadJSON(UserOptionsDefaults);
        controller.set("userOptions", userOptions);

        controller.setProperties({
            applicationLogoName: this.title,
            environmentName: this.environmentName
        });

        this.startHeartbeat(AtlasConfig.authenticationHeartbeatEndpoint,
            AtlasConfig.authenticationHeartbeatFrequency);
        this.startHeartbeat(AtlasConfig.versionHeartbeatEndpoint,
            AtlasConfig.versionHeartbeatFrequency,
            (newVersion) => {
                var currentVersion = controller.currentVersion;
                if(!Ember.isEmpty(currentVersion) && currentVersion.output.version !== newVersion.output.version) {
                    controller.set("versionOutdated", true);
                } else if (Ember.isEmpty(currentVersion)) {
                    controller.set("currentVersion", newVersion);
                }
            });
    },

    isHeartbeatRunning: false,
    stopHeartbeat: Ember.on("deactivate",function() {
        if (!this.isDestroyed && this.isHeartbeatRunning) {
            this.set("isHeartbeatRunning", false);
        }
    }),

    startHeartbeat: function(heartbeatEndpoint, heartbeatFrequency, callback) {
        var timer_id,
            innerCallback = typeof(callback) === "function" ? callback : function(){};
        var heartbeatFunction = () => {
            clearTimeout(timer_id);
            if (this.isHeartbeatRunning) {
                timer_id = setTimeout(async () => {
                    try {
                        var data = await AtlasAuth.ImmediateAjax({url: heartbeatEndpoint});
                        innerCallback(data);
                        heartbeatFunction();
                    } catch (error) {
                        // do nothing, AtlasAuth will update to show the login banner
                    }
                }, heartbeatFrequency);
            }
        };
        this.set("isHeartbeatRunning", true);
        heartbeatFunction();
    },

    actions: {
        logout: function () {
            AtlasAuth.logout().finally(function() {
                window.location = "/";
            });
        },
        reload: function () {
            location.reload();
        },

    },
});
