import Ember from "ember";

export default Ember.Component.extend({
    classNames: ['icon-view-selector', 'no-print'],

    selectedAgesView: "chart",
    hasMoreThanOneQuery: false,

    isChart: Ember.computed.equal('selectedAgesView', 'chart'),
    isTable: Ember.computed.equal('selectedAgesView', 'table'),

    changed: () => {},
    actions: {
        agesViewSelected: function(viewType) {
            this.changed(viewType);
        }
    }
});
