import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";

export default QueryAPIResults.extend({
    title: "keywords",
    dateField: null,
    description: DataSeriesDescription.load({
        "word": "string",
        "appearances": "number"
    })
});
