import { A } from "@ember/array";
import { computed } from "@ember/object";
import classic from "ember-classic-decorator";
import ArrayProxy from "@ember/array/proxy";

import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import JsonStore from 'infegy-frontend/json-store/model';
@classic
export default class OffsetPagination extends ArrayProxy {
    content = A();
    isLoading = false;
    itemClass = JsonStore;
    url = "api/v3/";

    pageIndex = 0;
    pageSize = 10;
    pageCount = 0;
    totalCount = 0;

    @computed.gt("pageIndex", 0) hasFirst;
    @computed.gt("pageIndex", 0) hasPrevious;
    @computed("pageIndex", "pageCount")
    get hasNext() {
        return this.pageIndex < this.pageCount-1;
    }
    @computed("pageIndex", "pageCount")
    get hasLast() {
        return this.pageIndex < this.pageCount-1;
    }

    async makeRequest(url, queryParams) {
        this.set("isLoading", true);
        const response = await AtlasAuth.Get({url, data: queryParams});
        this.parseResponse(response);
        this.set("isLoading", false);
    }

    parseResponse(response) {
        this.setProperties({
            pageIndex: response.pagination.page_index,
            pageSize: response.pagination.page_size,
            pageCount: response.pagination.page_count,
            totalCount: response.pagination.total_count,
        });
        const items = response.output.map((record) => {
            let item = this.itemClass.create();
            item.loadJSON(record);
            return item;
        });
        this.clear();
        this.pushObjects(items);
    }

    async getPage(additionalParams) {
        const queryParams = Object.assign({
            page_index: this.pageIndex,
            page_size: this.pageSize
        }, additionalParams);
        this.makeRequest(this.url, queryParams);
    }

    async getFirstPage(additionalParams) {
        const queryParams = Object.assign({
            page_index: 0,
            page_size: this.pageSize
        }, additionalParams);
        this.makeRequest(this.url, queryParams);
    }

    async getPreviousPage(additionalParams) {
        const queryParams = Object.assign({
            page_index: this.pageIndex-1,
            page_size: this.pageSize
        }, additionalParams);
        this.makeRequest(this.url, queryParams);
    }

    async getNextPage(additionalParams) {
        const queryParams = Object.assign({
            page_index: this.pageIndex+1,
            page_size: this.pageSize
        }, additionalParams);
        this.makeRequest(this.url, queryParams);
    }

    async getLastPage(additionalParams) {
        const queryParams = Object.assign({
            page_index: this.pageCount-1,
            page_size: this.pageSize
        }, additionalParams);
        this.makeRequest(this.url, queryParams);
    }
}