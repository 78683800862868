import Ember from "ember";
import ChartColors from "infegy-frontend/static_data/fixtures/chart_colors";

export default Ember.Component.extend({
    tagName: "tr",
    classNameBindings: [":data-table-row", "isEven:data-table-row-even"],

    theme: null,
    selectedField: null,

    distributionPercentage: Ember.computed("theme.combined.DocumentsDistribution", function () {
        var pct = (this.get("theme.combined.DocumentsDistribution") || 0) * 100.0;
        return pct.toFixed(1);
    }),

    distributionWidthStyleSafe: Ember.computed("distributionPercentage", function() {
        var percentage = this.distributionPercentage || 0;
        return new Ember.String.htmlSafe(["width:", percentage, "%;"].join(""));
    }),

    sentimentPercentage: Ember.computed("theme.combined.Sentiment", function () {
        return Math.round(this.get("theme.combined.Sentiment") * 100);
    }),

    sentimentStyleSafe: Ember.computed("theme.combined.Sentiment", function() {
        var sentiment = this.get("theme.combined.Sentiment"),
            colorValues = ChartColors.sentimentZeroToOneGradientD3();

        var style = ["color:", colorValues(sentiment), ";"].join("");

        return new Ember.String.htmlSafe(style);
    }),

    sentimentWidthStyleSafe: Ember.computed("sentimentPercentage", function() {
        var percentage = this.get("sentimentPercentage");
        return new Ember.String.htmlSafe(["width:", percentage, "%;"].join(""));
    }),

});
