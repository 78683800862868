import Component from '@ember/component';
import { computed, action } from '@ember/object';
import TrendFieldDefinition from "infegy-frontend/models/field-definitions/trend-field";
import TrendChartMeta from "infegy-frontend/models/trends/trend-chart-meta";

export default class NarrativesTrendChart extends Component{
    // arguments
    clusterData;

    groupingOptions;
    valueField = "documents";
    chartType = "line";
    grouping = null;

    @computed("trendChartMeta", "grouping")
    get innerTrendChartMeta() {
        var trendChartMeta = this.trendChartMeta;
        if (!trendChartMeta) {
            trendChartMeta = TrendChartMeta.create();
        }
        trendChartMeta.groupingOverride = this.grouping;
        return trendChartMeta;
    }

    @computed("clusterData", "valueField")
    get trendFields() {
        let clusterData = this.clusterData,
            valueField = this.valueField;

        let clusterFields = clusterData.map(cluster => {
            let newField = TrendFieldDefinition.create();

            newField.dataSeriesOverride = cluster.dataSeries;
            newField.fieldType = "trend";
            newField.fieldName = valueField;
            newField.title = cluster.source.name;
            newField.color = cluster.color;

            return newField;

        });

        return clusterFields;
    }

    groupingChanged() { /* action */ }
    onChartTypeChanged() { /* action */ }
    
    @action
    chartTypeChanged(type) {
        this.onChartTypeChanged(type);
    }

}