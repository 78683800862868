import Ember from "ember";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import QueryUtil from "infegy-frontend/models/queries/query_util";
import keycodes from "infegy-frontend/static_data/keycodes";

var disallowedFirstCharacters = ["?", "*"];

export default Ember.Component.extend({
    classNameBindings: [":query-builder-autocomplete"],
    textInput:"",
    displayError: Ember.computed("textInput", "textInput.length", function() {
        var textInput = this.textInput,
            displayError = false;

        for (var i = 0; i < disallowedFirstCharacters.length; i++) {
            if (textInput.startsWith(disallowedFirstCharacters[i])) {
                displayError = true;
                break;
            }
        }

        return displayError;
    }),

    entitySuggestions: [],
    selectedItems:[],
    selectedIndex:0,
    inputFocused:false,
    timer: null,

    async retrieveAutocompleteItems(){
        var textInput = this.textInput;
        try {
            var res = await AtlasAuth.Get(`api/v3/query_autocomplete?limit=5&prefix=${encodeURIComponent(textInput)}`);
        } catch (err) {
            console.error(err);
            return;
        }

        var runningIndex = 1; // 0 is rawText
        var entities = res.output.entities.map((entity) => {
                return {
                    "label": entity.name,
                    "value": entity.id,
                    "type": "entity",
                    "iconType": entity.type,
                    "index": runningIndex++
                };
            }) || [];
        this.set("entitySuggestions",entities);
    },

    filteredTextInput: Ember.computed("textInput",function(){
        var textInput = this.textInput;
        if (textInput.length > 1) {
            Ember.run.throttle(this, this.retrieveAutocompleteItems, 150);
        } else {
            this.set("entitySuggestions",[]);
        }

        // remove any nasty characters from copypasta
        textInput = QueryUtil.getQuerySafeString(textInput);
        return textInput;
    }),

    totalItems:Ember.computed("entitySuggestions.length",function(){
        return this.get("entitySuggestions.length");
    }),

    rawTextItem: Ember.computed("filteredTextInput",function(){
        let filteredTextInput = this.filteredTextInput,
            hasSpaces = filteredTextInput.match(/\s/g);
        return {
            type: "text",
            iconType: "text",
            label: filteredTextInput,
            value: hasSpaces ? `"${filteredTextInput}"` : filteredTextInput
        };
    }),

    showSelectionBox: Ember.computed("inputFocused", "filteredTextInput", function(){
        return this.inputFocused && (this.get("filteredTextInput.length") > 1);
    }),

    selectedItemsChanged: () => {},
    loseFocus(){
        this.setProperties({
            textInput: "",
            inputFocused: false
        });
    },

    actions: {
        addItem(item){
            var selectedItems = [].concat(this.selectedItems || []);
            selectedItems.push(item);
            this.selectedItemsChanged(selectedItems);
            this.setProperties({
                "textInput":"",
                "selectedIndex":0
            });
        },
        removeItem(index){
            var items = [].concat(this.selectedItems || []);
            items.splice(index,1);
            this.selectedItemsChanged(items);
        },
        keypress(e,value){
            if (e.keyCode === keycodes.Enter && value) { // Enter
                // Use the raw string
                let selectedIndex = this.selectedIndex;
                if(selectedIndex === 0){
                    this.send("addItem",this.rawTextItem);
                } else {
                    let entityItems = this.entitySuggestions;
                    if(selectedIndex < entityItems.length){
                        this.send("addItem",entityItems.find(item => Ember.get(item,"index") === selectedIndex));
                    }
                }
            } else if (e.keyCode === keycodes.Up){ // Up
                let selectedIndex = this.selectedIndex;
                selectedIndex = selectedIndex - 1 >= 0 ? selectedIndex - 1 : selectedIndex;
                this.set("selectedIndex", selectedIndex);
                e.stopPropagation();
            } else if (e.keyCode === keycodes.Down){ // Down
                let selectedIndex = this.selectedIndex;
                if(selectedIndex < this.totalItems){
                    this.set("selectedIndex", selectedIndex + 1);
                }
                e.stopPropagation();
            } else if(e.keyCode == keycodes.Tab) {
                this.loseFocus();
            }
        },
        loseFocus(){
            this.setProperties({
                textInput: "",
                inputFocused: false
            });
        },
    }
});
