import Ember from "ember";
import Component from '@ember/component';
import { computed, action } from '@ember/object';

export default class extends Component {
    influencer = null;

    subQueryApis = ["volume", "posts"];

    @computed("influencer")
    get queries() {
        var influencer = this.influencer;
        if (influencer._sources) {
            return influencer._sources.map(source => source._originalQuery);
        }
        return [influencer._originalQuery];
    }

    @computed("matchedDrilldownQuery")
    get matchedPosts() {
        var matchedQueries = this.matchedDrilldownQuery || [];
        return matchedQueries.reduce((allPosts, query) => {
            var queryPosts = query && query.get("posts.data");

            if (!queryPosts || !query || !Ember.get(queryPosts, "length")) {
                return allPosts;
            }
            queryPosts.forEach(post => {
                Ember.set(post, "query", query);
            });
            return allPosts.concat(queryPosts);
        }, []);
    }

    @computed("influencer.id", "queries")
    get influencerQueries() {
        var queries = this.queries || [],
            id = this.get("influencer.id");

        return queries.map((query)=>{
            var subQuery = query.copy();
            subQuery.set("colors", query.get("colors"));
            // sorting is not applicable when retrieving posts
            subQuery.queryInfo.removeAdditionalAPIParameter("sort");
            subQuery.queryInfo.addAdditionalAPIParameters({ "sources": id.toString() });

            return subQuery;
        });
    }

    @computed("influencerQueries", "influencerQueries.@each.updated", "influencerQueries.@each.volume")
    get subQueries() {
        return this.influencerQueries?.reduce((prev, query) => {
            if (!query.get("volume.isEmpty")) {
                prev.push(query);
            }
            return prev;
        }, []);
    }

    @computed("subQueries", "subQueries.length", "subQueries.@each.posts", "subQueries.@each.updated")
    get allPosts() {
        let queries = this.subQueries;
        return queries.reduce((prev, query) => {
            var posts = query.get("posts.dataSeries.data") || [];

            posts = posts.map((post) => {
                Ember.set(post, "query", query);
                return post;
            });

            prev.push(...posts);
            return prev;
        }, []).sortBy("score");
    }

    @computed("queries", "queries.@each.updated", "queries.length")
    get showQuery() {
        return this.get("queries.length") > 1 ? true : false;
    }
}
