import Ember from "ember";
import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";
import fieldPercentage from "infegy-frontend/models/data_series/data_series_computed_field_percentage";
import fieldDifference from "infegy-frontend/models/data_series/data_series_computed_field_difference";
import fieldDistribution from "infegy-frontend/models/data_series/data_series_computed_field_distribution";
import fieldQuotient from "infegy-frontend/models/data_series/data_series_computed_field_quotient";

var entitiesResults = QueryAPIResults.extend({
    title: "entities",

    preloadEntity: function(entity) {
        var documents = Ember.get(entity, "documents"),
            timeline = Ember.get(entity, "timeline") || [100],
            timelineLength = timeline.length,
            timelineSum = timeline.reduce((m, n) => m + n),
            documentsTimeline = timeline.map(n => {
                if (!timelineSum || !documents) {
                    return 0;
                }
                return Math.round((n / timelineSum) * documents);
            }),
            timelineScales = timeline.map(n => {
                return timelineSum ? n / timelineSum : 0;
            }),
            halfLength = timelineLength - Math.ceil(timelineLength / 2),
            firstHalf = documentsTimeline.slice(0, halfLength),
            secondHalf = documentsTimeline.slice(-halfLength),
            firstSum = firstHalf.reduce((m, n) => m + n, 0),
            secondSum = secondHalf.reduce((m, n) => m + n, 0);
        entity.topic = Ember.get(entity, "name");
        entity.key = Ember.get(entity, "id");
        entity.timeline = timeline;
        entity.documentsTimeline = documentsTimeline;
        entity.timelineScales = timelineScales;
        entity.documentsGrowth = secondSum - firstSum;
        entity.documentsLoss = firstSum - secondSum;
        entity.documentsGrowthPct = (secondSum - firstSum) / (firstSum);

        // camelCase sportsTeam so toggle-list's title-case doesn't display it as Sportsteam
        if (entity.entity_type === "sportsteam") {
            entity.entity_type = "sportsTeam";
        }

        return entity;
    },

    postloadEntity: function(entity) {
        var describedFields = Object.keys(this.get("description.fields")),
            keys = Object.keys(entity);
        entity["availableAttributes"] = [];

        keys.forEach((key) => {
            if (!describedFields.includes(key)) {
                entity.availableAttributes.push(key);
            }
        });

        return entity;
    },

    preload: function() {
        var rawResponse = this.rawResults;

        rawResponse.forEach((entity) => {
            this.preloadEntity(entity);
        });
    },

    postload: function() {
        let data = this.data;

        data.forEach((entity) => {
            this.postloadEntity(entity);
        });
    },

    description: DataSeriesDescription.load({
        "id": "string",
        "entityType": "string",
        "name": "string",
        "referencedEntities": "array",
        "collatedEntities": "array",
        "appearances": "number",
        "positiveAppearances": "number",
        "negativeAppearances": "number",
        "positiveScore": "number",
        "negativeScore": "number",
        "documents": "number",
        "positiveDocuments": "number",
        "negativeDocuments": "number",
        "firstSeen": "datestring",
        "lastSeen": "datestring",
        "breakout": "datestring",
        "timeline": "raw",
        "key": "string",
        "topic": "string",
        "taxonomy": "string",

        // Computed in Preload
        "timelineScales": "raw",
        "documentsTimeline": "raw",
        "documentsGrowth": "number",
        "documentsLoss": "number",
        "documentsGrowthPct": "number"
    }, [
        fieldDistribution.fieldSetup(["positiveDocuments", "negativeDocuments"], 0.5),
        fieldDistribution.fieldSetup(["positiveAppearances", "negativeAppearances"], 0.5),
        fieldDifference.fieldSetup("netSentimentDocuments", "positiveDocuments", "negativeDocuments"),
        fieldDifference.fieldSetup("netSentimentAppearances", "positiveAppearances", "negativeAppearances"),
        fieldPercentage.fieldSetup("netSentimentDocumentsPercent", "documents", "netSentimentDocuments"),
        fieldPercentage.fieldSetup("netSentimentAppearancesPercent", "appearances", "netSentimentAppearances"),
        fieldQuotient.fieldSetup("appearancesPerPost", "appearances", "documents")
    ]),
});

entitiesResults.reopenClass({
    combineMatchingEntities: function(linkedEntities) {
        var dedupedEntities = [],
            indiciesToRemove = [];

        linkedEntities.forEach((entity, index, array) => {
            var matchedEntities = array.filter(item => item.id === entity.id);

            if (matchedEntities.length > 1 && !indiciesToRemove.includes(index)) {
                var duplicateIndicies = matchedEntities.map(item => array.indexOf(item));
                indiciesToRemove.push(...duplicateIndicies);

                var entityQueries = matchedEntities.map((item) => {
                    return {
                        colorClass: item._originalQuery.get("colorClass"),
                        title: item._originalQuery.get("title")
                    };
                }),
                combinedValues = matchedEntities.reduce((prev, item) => {
                    prev.appearances += item.appearances;
                    prev.documents += item.documents;
                    prev.positiveAppearancesDistribution += item.positiveAppearancesDistribution;
                    return prev;
                }, {
                    appearances: 0,
                    documents: 0,
                    positiveAppearancesDistribution: 0
                });

                dedupedEntities.push(Object.assign(entity, {
                    appearances: combinedValues.appearances,
                    documents: combinedValues.documents,
                    queries: entityQueries,
                    positiveAppearancesDistribution: combinedValues.positiveAppearancesDistribution / matchedEntities.length
                }));
            }
        });

        linkedEntities.forEach((item, index) => {
            if (!indiciesToRemove.includes(index)) {
                dedupedEntities.push(item);
            }
        });

        return dedupedEntities;
    }
});

export default entitiesResults;
