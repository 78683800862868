import Ember from "ember";
import PostsFields from "infegy-frontend/models/fields/field-selections/posts";
import EngagementsFields from "infegy-frontend/models/fields/field-selections/engagements";
import SentimentFields from "infegy-frontend/models/fields/field-selections/sentiment";
import PassionFields from "infegy-frontend/models/fields/field-selections/passion";
import GenderFields from "infegy-frontend/models/fields/field-selections/gender";
import ChannelsFields from "infegy-frontend/models/fields/field-selections/channels";
import EmotionsFields from "infegy-frontend/models/fields/field-selections/emotions";
import ThemesFields from "infegy-frontend/models/fields/field-selections/themes";
import LanguagesFields from "infegy-frontend/models/fields/field-selections/languages";
import TrendsSegments from "infegy-frontend/static_data/trends-segments";

var fieldBuilders = {
    "Posts": PostsFields,
    "Engagements": EngagementsFields,
    "Sentiment": SentimentFields,
    "Passion": PassionFields,
    "Gender": GenderFields,
    "Channels": ChannelsFields,
    "Emotions": EmotionsFields,
    "Themes": ThemesFields,
    "Languages": LanguagesFields
};

export default Ember.Component.extend({
    classNames: ["trends-fields-button-selector"],

    selectedSegment: null,
    selectedSubSegment: null,

    activeFields: [],
    isCustomTrends: false,

    fieldBuilder: Ember.computed("selectedSegment", "queries", "queries.[]", "queries.@each.updated","query", {
        get: function(key) {
            var segment = this.selectedSegment,
                builder = segment && fieldBuilders[segment];
            builder = builder && builder.create({
                query: this.query,
                queries: this.queries,
            });
            return builder;
        }
    }),

    activeFieldValues: Ember.computed.mapBy("activeFields", "value"),

    availableFields: Ember.computed("fieldBuilder", "fieldBuilder.allAvailableFields",
            "selectedSubSegment", function () {
        var allAvailableFields = this.get("fieldBuilder.allAvailableFields"),
            segment = this.selectedSegment,
            query = this.query,
            subSegment = this.selectedSubSegment,
            defaultFieldValues = TrendsSegments.defaultFieldValues[subSegment],
            rawAvailableFields = allAvailableFields && allAvailableFields[subSegment] || [];

        rawAvailableFields.forEach(function(field) {
            field.set("segment", segment);
            field.set("subSegment", subSegment);
            if (defaultFieldValues) {
                if (!field.get("fixedMaxY") && defaultFieldValues.fixedMaxY) {
                    field.set("fixedMaxY", defaultFieldValues.fixedMaxY);
                }
                if (!field.get("units") && defaultFieldValues.units) {
                    field.set("units", defaultFieldValues.units);
                }
                if (!field.get("axisGroup")) {
                    field.set("axisGroup", defaultFieldValues.axisGroup);
                }
            }
            if (query && field.get("query") !== query) {
                field.set("query", query);
                field.get(["query", field.get("apiName")].join("."));
            }
        });

        return rawAvailableFields;
    }),

    hasMultipleAvailableFields: Ember.computed.gt("availableFields.length", 1),

    availableFieldsChanged: () => {},
    actions: {
        availableFieldsChanged: function(availableFields) {
            this.availableFieldsChanged(availableFields);
        },
    }
});
