import Ember from "ember";
import Component from '@ember/component';
import { computed } from '@ember/object';
import FieldSelectorBase from "infegy-frontend/components/field-selector/parts/fields/trendable-field-base/component";
import AtlasColors from "infegy-frontend/utils/atlas-colors";
import layout from "infegy-frontend/components/field-selector/parts/fields/trendable-selector-layout/template";

export default class FieldSelectorPassion extends FieldSelectorBase {
    layout = layout;
    apiName = "sentiment";

    @computed("fieldType", "selectedSegment", "selectedSubsegment")
    get allAvailableFields() {
        return {
            "Passion": [this.makeFieldDefinition({
                    fieldName: "passion",
                    fixedPortionWhole: 100,
                    defaultColor: AtlasColors.getColorfromClassName("atlas-passion"),
                    axisGroup: "Passion"
                })],
            "Intensity": [this.makeFieldDefinition({
                    fieldName: "intensity",
                    fixedPortionWhole: 100,
                    defaultColor: AtlasColors.getColorfromClassName("atlas-passion"),
                    axisGroup: "Passion"
                }), this.makeFieldDefinition({
                    fieldName: "positiveIntensity",
                    fixedPortionWhole: 100,
                    defaultColor: AtlasColors.getColorfromClassName("atlas-sentiment-positive"),
                    axisGroup: "Passion"
                }), this.makeFieldDefinition({
                    fieldName: "negativeIntensity",
                    fixedPortionWhole: 100,
                    defaultColor: AtlasColors.getColorfromClassName("atlas-sentiment-negative"),
                    axisGroup: "Passion"
                })],
            "SentimentalSentences": [this.makeFieldDefinition({
                    fieldName: "sentimentalSentencesDistribution",
                    axisGroup: "sentimentalSentencesDistribution"
                })],
            "SentimentalSubjectSentences": [this.makeFieldDefinition({
                    fieldName: "sentimentalSubjectSentencesDistribution",
                    axisGroup: "sentimentalSubjectSentencesDistribution"
                })],
        };
    };
};
