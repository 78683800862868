import Ember from "ember";
import d3 from "d3";
import AtlasColors from "infegy-frontend/utils/atlas-colors";

export default Ember.Component.extend({
    classNameBindings: ["isFaded:arc-potion-faded-chart"],

    whole: 1.0,
    portion: 0.0,

    maxHeight: 200,

    isFaded: false,
    hidePercentage: false,

    colorClass: "atlas-ui",
    backgroundColorClass: "atlas-text-color-lighter",

    color: null,
    backgroundColor: null,

    innerRadius: 37,
    outerRadius: 50,

    isNA: false,

    isTextFaded: Ember.computed("isFaded", "isNA", function () {
        return this.isFaded || this.isNA;
    }),

    innerBackgroundColor: Ember.computed("backgroundColorClass", function() {
        return this.backgroundColor || (this.backgroundColorClass && AtlasColors.getColorfromClassName(this.backgroundColorClass));
    }),

    innerColor: Ember.computed("color", "colorClass", function() {
        return this.color || AtlasColors.getColorfromClassName(this.colorClass || "atlas-ui");
    }),


    innerTextColor: Ember.computed("isNA", "color", "innerBackgroundColor", "innerColor", function() {
        return this.isNA ? this.innerBackgroundColor : this.innerColor;
    }),

    innerColorClass: Ember.computed("colorClass", function () {
        return this.colorClass || "atlas-ui";
    }),

    innerBackgroundColorClass: Ember.computed("backgroundColorClass", function () {
        return this.backgroundColorClass || "atlas-text-color-lighter";
    }),

    normalizedPortion: Ember.computed("whole", "portion", function () {
        var portion = this.portion,
            whole = this.whole;

        return (portion && whole) ? portion/whole : 0;
    }),

    path: Ember.computed("normalizedPortion", "innerRadius", "outerRadius", function () {
        var normalizedPortion = this.normalizedPortion,
            arc = d3.arc()
                .innerRadius(this.innerRadius || 1)
                .outerRadius(this.outerRadius || 1);

        return arc({startAngle: 0, endAngle: normalizedPortion * 2 * Math.PI});
    }),
});
