import Ember from 'ember';

export function valueLabelCase(params, namedArgs) {
  if (!params[0] || !params[0].replace) {
      return params[0];
    }

    var value = params[0].replace(/([A-Z])/g, ' $1')
                 .replace('gt_', '+')
                 .replace(/_/g, '-$')
                 .replace('1000k', '1M')
                 .replace(/^./g, function(str){ return '$' + str.toUpperCase(); });
    return value;
}

export default Ember.Helper.helper(valueLabelCase);
