import Ember from "ember";
import Actions from "infegy-frontend/utils/common-actions";

export default Ember.Component.extend({
    themeData: null,

    selectedTheme: null,
    availableThemes: [],

    viewField: "appearances",

    sortedThemeData: Ember.computed("themeData", function () {
        var themeData = this.themeData || [];
        return themeData.sortBy("combined.Documents").reverse();
    }),

    themeSelected: () => {},
    actions: {
        themeSelected: Actions.passUp("themeSelected"),
    }
});
