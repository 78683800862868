import Ember from "ember";
import { computed } from '@ember/object';
import classic from "ember-classic-decorator";

import HelpStrings from "infegy-frontend/static_data/help-strings";
import JsonStore from "infegy-frontend/json-store/model";
import Prop from "infegy-frontend/json-store/properties/model";
import QueryDateAttr from "infegy-frontend/models/queries/filters/query_date";

@classic
export default class extends JsonStore {
    // the property/id of the filter passed to the API - e.g. "languages" or "start_date"
    @Prop.String() id;
    // The display name/Label of the filter
    @Prop.String() name;
    // One of : "decimal", "integer", "keyword", "keywords", "boolean", "timestamp"
    @Prop.String() type;
    // A brief description of what the filter does
    @Prop.String() description;
    @Prop.Boolean() isExcluded;

    // For keyword and keywords filters
    @Prop.Attr() _values;


    @computed("_values")
    get values() {
        return this._values;
    }
    set values(values) {
        this.set("_values", (values || []).map(v => {
            return v === null ? this.nullString : v;
        }));
    }

    // For Text / Query filters
    @Prop.String() textValue;

    // For Numeric Filters
    @Prop.Attr() min;
    @Prop.Attr() max;

    // For Date Range Filters
    @Prop.Object(QueryDateAttr) minDate;
    @Prop.Object(QueryDateAttr) maxDate;

    // For Boolean Filters - can be true, false, or null
    @Prop.Attr() booleanValue;

    nullString = "__unknown__";

    @computed("type")
    get outputType() {
        let outputType = {};
        if (this.type === "decimal" || this.type === "integer") {
            outputType.isNumeric = true;
        } else if (this.type === "keyword" || this.type === "keywords") {
            outputType.isValues = true;
        } else if (this.type === "boolean") {
            outputType.isBoolean = true;
        } else if (this.type === "timestamp") {
            outputType.isTime = true;
        } else if (this.type === "text") {
            outputType.isText = true;
        }
        return outputType;
    }

    @computed("outputType", "min", "max", "values", "values.length", "booleanValue", "minDate", "maxDate", "textValue")
    get hasValue() {
        if (this.outputType.isNumeric) {
            // has a min or max that is not null, undefined, NaN, or an empty string
            return !!this.min || !!this.max;
        } else if (this.outputType.isValues) {
            // not empty
            if (Ember.isEmpty(this.values))
                return false;
            // has at least 1 string that isn't null, empty, or "__unknown__"
            if (!!this.values.find((value) => !!value && typeof value === "string" && value != "__unknown__"))
                return true;
            return false;
        } else if (this.outputType.isBoolean) {
            return this.booleanValue === true || this.booleanValue === false || this.booleanValue === null;
        } else if (this.outputType.isTime) {
            // not null, undefined, NaN, or an empty string
            return !!(this.minDate.timestamp && this.maxDate.timestamp);
        } else if (this.outputType.isText) {
            // not null, undefined, or an empty string
            return !!this.textValue;
        }
        return false;
    }

    toAPIJSON() {
        let apiJSON = { id: this.id };
        if (this.outputType.isNumeric) {
            apiJSON.min = this.min;
            apiJSON.max = this.max;
            if (!apiJSON.min && apiJSON.min !== 0) {
                apiJSON.min = null;
            }
            if (!apiJSON.max && apiJSON.max !== 0) {
                apiJSON.max = null;
            }
        } else if (this.outputType.isValues) {
            let values = (this.values || []).map(v => {
                return v === this.nullString ? null : v;
            });
            apiJSON.values = values;
        } else if (this.outputType.isBoolean) {
            apiJSON.value = this.booleanValue;
        } else if (this.outputType.isTime) {
            apiJSON.min = this.minDate.toJSON();
            apiJSON.max = this.maxDate.toJSON();
        } else if (this.outputType.isText) {
            apiJSON.value = this.textValue;
        }
        return apiJSON;
    }
}


