import Ember from "ember";

export default Ember.Component.extend({
    queries: [],
    selectedEmotion: null,

    emotionQueries: Ember.computed("queries", "selectedEmotion", function() {
        const emotionQueries = this.queries.map((query) => {
            let emotionQuery = query.copy();
            emotionQuery.queryInfo.addFilter({
                id: "emotions",
                values: [this.selectedEmotion.emotion]
            });
            return emotionQuery;
        });
        return emotionQueries;
    }),
});
