import Ember from "ember";
import QueryAPILinguisticsPosts from "infegy-frontend/models/query-api/linguistics_posts";
import PostFilters from "infegy-frontend/static_data/post_filters";

export default Ember.Component.extend({
    classNames: ["simple-posts-list"],

    query: null,
    filterRange: null,
    interactionInfo: null,

    brushStartXIdx: Ember.computed.alias("interactionInfo.brushStartXIdx"),
    brushEndXIdx: Ember.computed.alias("interactionInfo.brushEndXIdx"),

    weekFilter: null,
    timeOfDayFilter: null,

    postsPerPage: 2,
    currentPage: 0,

    createRange: function(filter) {
        var range = {
            start: filter.args[1],
            end: filter.args[2]
        };

        return Array.apply(null, Array(range.end - range.start + 1)).map(function(curr, i) {
            return i + range.start;
        }).toString();
    },

    filteredQuery: Ember.computed("query", "filterRange",
        "filterRange.start", "filterRange.end",
        "brushStartXIdx", "brushEndXIdx",
        "weekFilter", "timeOfDayFilter", function() {
        var query = this.query && this.query.copy(),
            filterRange = this.filterRange,
            dayOfWeekFilter = this.weekFilter,
            timeOfDayFilter = this.timeOfDayFilter,
            queryInfo = query && query.get("queryInfo");

        if (filterRange && filterRange.start && filterRange.end) {
            var gDate = queryInfo.get("startDate");
            gDate.set("isRelative", false);
            gDate.get("fixed").fromTimestamp(filterRange.start);

            gDate = queryInfo.get("endDate");
            gDate.set("isRelative", false);
            gDate.get("fixed").fromTimestamp(filterRange.end);
        }

        if (dayOfWeekFilter) {
            queryInfo.set("dayOfWeek", this.createRange(dayOfWeekFilter));
        }

        if (timeOfDayFilter) {
            queryInfo.set("timeOfDay", this.createRange(timeOfDayFilter));
        }

        return query;
    }),

    validFilters: PostFilters,
    postFilters: Ember.computed("validFilters", "fields", "fields.[]", function() {
        var validFilters = this.validFilters,
            selectedSegment = this.fields && this.get("fields.firstObject") && this.get("fields.firstObject.segment").toLowerCase(),

            fields = this.fields && this.fields.map(function(item) {
                return item.get("title");
            }),

            postFilters = [],
            isFilterValid = validFilters.includes(selectedSegment);

        if (isFilterValid) {
            postFilters = [Ember.Object.create({
                name: selectedSegment,
                selectedValues: fields
            })];
        }

        return postFilters;
    }),

    lastRun: null,
    _filteredPosts: null,
    filteredPosts: Ember.computed("filteredQuery", "postFilters", function() {
        var query = this.filteredQuery,
            postFilters = this.postFilters,
            debounce = new Date() - this.lastRun < 1500,
            posts;

        if (!debounce) {
            this.set("lastRun", new Date());
            posts = QueryAPILinguisticsPosts.loadCustomPosts(query, postFilters);
            this.set("_filteredPosts", posts);
        }

        return this._filteredPosts;
    }),

    displayedPosts: Ember.computed("filteredPosts.dataSeries.data", "postsPerPage",
        "currentPage", function() {
        var filteredPosts = this.get("filteredPosts.dataSeries.data") || [],
            postsPerPage = this.postsPerPage || 2,
            currentPage = this.currentPage || 0,
            currentIndex = currentPage * postsPerPage;

        return filteredPosts.slice(currentIndex, currentIndex + postsPerPage);
    }),
});
