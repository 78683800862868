import { computed } from "@ember/object";
import Component from '@ember/component';
import classic from "ember-classic-decorator";

@classic
export default class extends Component {
    classNames = ["simple-posts-list"];

    trendField = null;
    trendChartMeta = null;

    lastRun = 0;
    _filteredPosts = null;

    postsPerPage = 2;
    currentPage = 0;

    @computed("trendField.selectedDateRange", "trendField.field.query")
    get filteredQuery() {
        if (!this.trendField?.selectedDateRange || !this.trendField?.field?.query) {
            return;
        }
        let originalQuery = this.trendField?.field?.query,
            range = this.trendField?.selectedDateRange,
            newQuery = originalQuery.copy();

        let startDate = newQuery.queryFilters.startDate;
        startDate.set("isRelative", false);
        startDate.fixed.fromTimestamp(range.start);

        let endDate = newQuery.queryFilters.endDate;
        endDate.set("isRelative", false);
        endDate.fixed.fromTimestamp(range.end);

        return newQuery;
    }

    @computed("trendField.selectedDateRange", "trendField.field.query")
    get postQueries() {
        return this.filteredQuery ? [this.filteredQuery] : [this.trendField.field.query];
    }
};
