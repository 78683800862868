import Ember from "ember";
import Component from '@ember/component';
import { computed } from '@ember/object';
import FieldSelectorBase from "infegy-frontend/components/field-selector/parts/fields/trendable-field-base/component";
import layout from "infegy-frontend/components/field-selector/parts/fields/trendable-selector-layout/template";

export default class FieldSelectorEngagements extends FieldSelectorBase {
    layout = layout;
    apiName = "engagements";

    @computed("fieldType", "selectedSegment", "selectedSubsegment", "isLoaded")
    get allAvailableFields() {
        return {
            "Likes" : [this.makeFieldDefinition({
                fieldName: "likes",
                color: this.get("query.queryInfo.color"),
                axisGroup: "Engagements.Likes"
            })],
            "Comments" : [this.makeFieldDefinition({
                fieldName: "comments",
                color: this.get("query.queryInfo.color"),
                axisGroup: "Engagements.Comments"
            })],
            "Shares" : [this.makeFieldDefinition({
                fieldName: "shares",
                color: this.get("query.queryInfo.color"),
                axisGroup: "Engagements.Shares"
            })],
            "LikesUniverse" : [this.makeFieldDefinition({
                fieldName: "likesUniverse",
                title: "Likes Universe",
                color: this.get("query.queryInfo.color"),
                axisGroup: "Engagements.LikesUniverse"
            })],
            "CommentsUniverse" : [this.makeFieldDefinition({
                fieldName: "commentsUniverse",
                title: "Comments Universe",
                color: this.get("query.queryInfo.color"),
                axisGroup: "Engagements.CommentsUniverse"
            })],
            "SharesUniverse" : [this.makeFieldDefinition({
                fieldName: "sharesUniverse",
                title: "Shares Universe",
                color: this.get("query.queryInfo.color"),
                axisGroup: "Engagements.SharesUniverse"
            })],
            "Engagements" : [this.makeFieldDefinition({
                fieldName: "engagements",
                title: "Engagements",
                color: this.get("query.queryInfo.color"),
                axisGroup: "Engagements.Engagements"
            })],
            "EngagementsUniverse" : [this.makeFieldDefinition({
                fieldName: "engagementsUniverse",
                title: "Engagements Universe",
                color: this.get("query.queryInfo.color"),
                axisGroup: "Engagements.EngagementsUniverse"
            })],
            "Impressions" : [this.makeFieldDefinition({
                fieldName: "metaImpressions",
                title: "Impressions",
                color: this.get("query.queryInfo.color"),
                axisGroup: "metaImpressions",
                isMeta: true
            })],
            "Reach" : [this.makeFieldDefinition({
                fieldName: "metaReach",
                title: "Reach",
                color: this.get("query.queryInfo.color"),
                axisGroup: "metaReach",
                isMeta: true
            })]
        };
    };
};
