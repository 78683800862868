export default [{
    title: "Body",
    apiField: "body",
    description: "The main content of the document to be analyzed",
    canHaveMultipleColumns: true,
    maxColumns: Infinity,
    isRequired: true
},{
    title:"Date",
    apiField: "date",
    description: "The date the document was published or posted",
    defaultValueNotice: "If None is chosen, post dates will automatically be assigned today's date."
},{
    title: "Title",
    apiField: "title",
    description: "The title of the document",
    canHaveMultipleColumns: true,
    maxColumns: Infinity
},{
    title:"Author",
    apiField: "author",
    description: "The author of the document",
    canHaveMultipleColumns: true,
    maxColumns: Infinity
},
{
    title: "Link",
    apiField: "link",
    description: "The link to the document content"
},{
    title: "Age",
    apiField: "age",
    description: "The age of the document author"
},{
    title: "Birth Year",
    apiField: "birth_year",
    description: "The birth year of the document author"
},{
    title: "Gender",
    apiField: "gender",
    description: "The gender of the document author"
},{
    title: "Latitude",
    apiField: "latitude",
    description: "The latitude of the document source"
},{
    title: "Longitude",
    apiField: "longitude",
    description: "The longitude of the document source"
},{
    title: "Named Location",
    apiField: "named_location",
    description: "The named location of the document source"
},{
    title: "State",
    apiField: "us_state",
    description: "The U.S. state pertaining to the document source"
},{
    title: "Zipcode",
    apiField: "zipcode",
    description: "The U.S. zipcode pertaining to the document source"
},{
    title: "Country",
    apiField: "country",
    description: "The country pertaining to the document source"
},{
    title: "NPS",
    apiField: "nps",
    description: "Net Promoter Score"
},{
    title: "Rating",
    apiField: "rating",
    description: "Numerical ratings such as reviews, surveys, etc."
}];

// {
//     title: "Supplemental Identifiers",
//     apiField: "not_displayed", // TODO: Verify this is the correct field.
//     description: "This data won't be displayed on the platform (e.g., as keywords in topic clouds) but will still be analyzed"
// };
