import EmberObject from "@ember/object";
import DataSeriesDescription from "infegy-frontend/models/data_series/data_series_description";
import QueryAPIResults from "infegy-frontend/models/queries/query_api_results";

export default QueryAPIResults.extend({
    title : "engagements",
    preload: function(){
        var rows = this.rawResults;
        rows.forEach(row => {
            row.likes = parseInt(row.likes);
            row.shares = parseInt(row.shares);
            row.comments = parseInt(row.comments);
            row.likes_universe = parseInt(row.likes_universe);
            row.shares_universe = parseInt(row.shares_universe);
            row.comments_universe = parseInt(row.comments_universe);

            row.engagements = row.likes + row.shares + row.comments;
            row.engagements_universe = row.likes_universe + row.shares_universe + row.comments_universe;
        });
    },
    description: DataSeriesDescription.load({
        "date": "datestring",
        "timestamp": "timestamp",

        "likes": "number",
        "shares": "number",
        "comments": "number",

        "likesUniverse": "number",
        "sharesUniverse": "number",
        "commentsUniverse": "number",

        "engagements": "number",
        "engagementsUniverse": "number"
    }),

    postload() {
        this.set("stats.metaReach", EmberObject.create({
            isPercentage: false,
            sum: this.meta.reach,
            type: "number"
        }));
        this.set("stats.metaImpressions", EmberObject.create({
            isPercentage: false,
            sum: this.meta.impressions,
            type: "number"
        }));
    }
});
