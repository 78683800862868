import Component from "@ember/component";
import { computed, action } from '@ember/object';
import classic from "ember-classic-decorator";
import { tracked } from '@glimmer/tracking';
import AtlasColors from "infegy-frontend/utils/atlas-colors";

@classic
export default class extends Component {
    query = null;
    
    @tracked removedClusters;
    @tracked canvasElement = null;
    @tracked nodeCount = null;
    @tracked selectedNode = null;
    
    displayLinks = true;
    darkMode = false;
    colorBySentiment = false;
    clusterLabelType = 1;

    onRenameCluster() { /* action */ }
    onRemoveCluster() { /* action */ }

    labelOptions = [{
      title: "None",
      value: 0
    },{
      title: "Key Clusters",
      value: 1
    },{
      title: "All Clusters",
      value: 2
    }]

    nodeCountOptions = [{
        title: "auto",
        value: "auto",
      },{
        title: "500",
        value: 500,
      },{
        title: "1000",
        value: 1000,
      },{
        title: "2500",
        value: 2500,
      },{
        title: "5000",
        value: 5000,
      },{
        title: "10000",
        value: 10000,
        description: "⚠️ Caution - Performance may be limited on older machines"
      },{
        title: "25000",
        value: 20000,
        description: "⚠️ Caution - Performance may be limited on older machines"
      },{
        title: "50000",
        value: 50000,
        description: "⚠️ Caution - Performance may be limited even on fast modern machines"
      },{
        title: "100000",
        value: null,
        description: "⚠️ Caution - Performance may be limited even on fast modern machines"
      }];

    canvasHeight = 800;

    findCanvasWidth() {
        let canvasEl = this.canvasElement;
        if (!canvasEl) {
            return;
        }
        return canvasEl.offsetWidth;        
    }

    @computed("query", "nodeCount")
    get clusterQuery() {
        let query = this.query,
            nodeCount = this.nodeCount;
        if (!query || !nodeCount || nodeCount === "auto") {
            return query;
        }
        let newQuery = query.copy();
        newQuery.filters.addAdditionalAPIParameters({"limit": nodeCount});
        return newQuery;
    }

    @computed("query", "clusterQuery", "clusterQuery.postClusterNodes.isLoaded")
    get graphData() {
        return this.clusterQuery?.postClusterNodes?.rawResponseRow?.output;
    }

    @computed("query", "clusterQuery", "removedClusters", "removedClusters.[]", "graphData")
    get cleanedGraphData() {
        let graphData = this.graphData,
            removedClusters = this.removedClusters;
        if (!removedClusters || !removedClusters.length || !graphData) {
            return this.graphData;
        }
        let removedIndicies = graphData.clusters.reduce((memo, cluster, idx) => {
          if (removedClusters.includes(cluster.key)) {
              memo.push(idx);
          }
          return memo;
        }, []);

        let removedNodes = graphData.nodes.filter(node => removedIndicies.includes(node.c)).mapBy("id");

        let newGraphData = Object.assign({}, graphData);
        newGraphData.nodes = graphData.nodes.filter(node => !removedIndicies.includes(node.c));
        let newLinks = graphData.links.filter(link => {
            let hasSource = removedIndicies.includes(link.source?.c) || removedNodes.includes(link.source),
                hasTarget = removedIndicies.includes(link.target?.c) || removedNodes.includes(link.target);
            return !(hasSource || hasTarget);
        });
        newGraphData.links = newLinks;

        Ember.run.next(() => {
          this.set("clickedNode", null);
        });

        if (this._forceGraphCache) {
          this._forceGraphCache.graphData({ nodes: [], links: [] })
          this._forceGraphCache.autoPauseRedraw(true);
          this._forceGraphCache.pauseAnimation();
          this.set("_forceGraphCache", null);
        }

        return newGraphData;
    }

    _forceGraphCache = null;

    @computed("removedClusters", "cleanedGraphData", "graphData", "clusterQuery.postClusterNodes.isLoaded", "canvasElement")
    get graph() {
        let cleanedGraphData = this.cleanedGraphData;

        let canvasEl = this.canvasElement,
            canvasWidth = this.findCanvasWidth(),
            canvasHeight = this.canvasHeight;

        if (!cleanedGraphData || !canvasEl) {
            return null;
        }

        if (this._forceGraphCache) {
          this._forceGraphCache.graphData({ nodes: [], links: [] })
          this._forceGraphCache.autoPauseRedraw(true);
          this._forceGraphCache.pauseAnimation();
          this.set("_forceGraphCache", null);
        }
        document.body.onkeydown = null;
        this.canvasElement.innerHTML = "";

        // Create a force graph
        let graph = ForceGraph().graphData(cleanedGraphData);

        this.set("_forceGraphCache", graph);

        if (this.darkMode) {
          graph?.backgroundColor('#000');
        } else {
          graph?.backgroundColor('#fff');
        }

        // ... and set it up!
        //graph.nodeRelSize(4);
        graph.warmupTicks(20);
        //graph.cooldownTicks(2000);
        graph.autoPauseRedraw(false); // keep redrawing after engine has stopped
        graph.nodeAutoColorBy('c');
        graph.nodeLabel(null);

        graph.onEngineStop(() => {
          
        })
        
        let highlightedNode = null;

        graph.onNodeClick(node => {
          if(node) {
            this.set("clickedNode", node);
            highlightedNode = node;
          } else {
            this.set("clickedNode", null);
          }
        });

        graph.onBackgroundClick(() => {
          this.set("clickedNode", null);
          highlightedNode = null;
        });

        graph.onNodeHover(node => {
          if(node) {
            highlightedNode = node;
          } else {
            highlightedNode = null;
          }
        });

        graph.autoPauseRedraw(false)

        graph.nodeCanvasObject((node, ctx, globalScale) => {
        });
          
        graph.linkCanvasObject((link, ctx, globalScale) => {
        });


        graph.onRenderFramePost((ctx, globalScale) => { 
          if (this.displayLinks) {
            for(let link_idx = 0; link_idx < cleanedGraphData.links.length; link_idx++) {
              const link = cleanedGraphData.links[link_idx];
              let grad;
              
              //ctx.globalCompositeOperation='lighten';
              try {
                grad = ctx.createLinearGradient(link.source.x, link.source.y, link.target.x, link.target.y);
              } catch (err) {
                return;
              }

              //let is_highlighted = highlightLinks.has(link);

              //let opacity = Math.trunc(link.score * 128 + is_highlighted ? 128 : 16).toString(16);
              const opacity = '60';

              //ctx.lineWidth = is_highlighted ? 4/globalScale : 1/globalScale;
              ctx.lineWidth = 2;

              let highlightedCluster = null;
              if (highlightedNode) {
                highlightedCluster = highlightedNode?.c; 
              } else if (this.clickedNode) {
                highlightedCluster = this.clickedNode?.c;
              }

              if (highlightedCluster !== null) {
                if (link.source.c !== highlightedCluster && link.target.c !== highlightedCluster) {
                  ctx.globalAlpha = 0.2;
                  ctx.lineWidth = 2;
                } else {
                  ctx.lineWidth = 6;
                }
              }

              if(this.colorBySentiment) {
                ctx.strokeStyle = "rgba(200,200,200,0.3)";
              } else {
                if(link.source.color != link.target.color) {
                  //grad.addColorStop(0, link.source.color + '00');
                  grad.addColorStop(0.0, link.source.color + opacity);
                  grad.addColorStop(1.0, link.target.color + opacity);
                  //grad.addColorStop(1, link.target.color + '00');

                  ctx.strokeStyle = grad;
                } else {
                  ctx.strokeStyle = link.source.color + opacity;
                }
              }
              ctx.beginPath();
              ctx.moveTo(link.source.x, link.source.y);
              ctx.lineTo(link.target.x, link.target.y);
              ctx.stroke();

              if (highlightedCluster !== null) {
                ctx.globalAlpha = 1;
              }
            }
          }
          // Rembember nodes we need to label
          let label_nodes = [];

          // Remember the last key used to set colors
          let last_color_key = undefined;

          const sentiment_colors = ['rgb(143,155,178)', 'rgb(193,200,215)', 'rgb(127,196,10)', 'rgb(239,8,73)'];
          const atlasColors = AtlasColors.colorPalette,
                numColors = atlasColors.length;

          // Draw the nodes
          for(let node_idx = 0; node_idx < cleanedGraphData.nodes.length; node_idx++) {
            const node = cleanedGraphData.nodes[node_idx];
            if(this.colorBySentiment) {
              if(node.s != last_color_key) {
                ctx.fillStyle = sentiment_colors[node.s];
                last_color_key = node.s;
              }
            } else if(node.c != last_color_key) {
              // ctx.fillStyle = atlasColors[node.c % numColors];
              ctx.fillStyle = node.color;
              last_color_key = node.c;
            }
            
            let drawLabel = true;
            ctx.beginPath();
  
            let highlightedCluster = null;
            if (highlightedNode) {
              highlightedCluster = highlightedNode?.c; 
            } else if (this.clickedNode) {
              highlightedCluster = this.clickedNode?.c;
            }
            if (highlightedCluster !== null) {
              if (node.c === highlightedCluster) {
                if (!this.clickedNode || this.clickedNode === node) {
                  ctx.arc(node.x, node.y, (node.val + 5) * 1.6, 0, 2 * Math.PI, false);
                } else {
                  ctx.globalAlpha = 0.6;
                  ctx.arc(node.x, node.y, (node.val + 5) * 1.2, 0, 2 * Math.PI, false);
                }
              } else {
                drawLabel = false;
                ctx.globalAlpha = 0.2;
                ctx.arc(node.x, node.y, node.val + 5, 0, 2 * Math.PI, false);
              }
            } else {
              ctx.arc(node.x, node.y, node.val + 5, 0, 2 * Math.PI, false);
            }
            // ctx.arc(node.x, node.y, node.val + 5, 0, 2 * Math.PI, false);
            ctx.fill();
            ctx.globalAlpha = 1;
  
  
            if(node.l && drawLabel && this.clusterLabelType && node.l <= this.clusterLabelType) {
              label_nodes.push(node);
            }
          }

          label_nodes.forEach((node) => {
              // Draw label on this node
              ctx.globalCompositeOperation='source-over';
  
              const label = cleanedGraphData.clusters[node.c].name;
              const fontSize = 12/globalScale;
              ctx.font = `${fontSize}px Sans-Serif`;
              const textWidth = ctx.measureText(label).width;
              const padding = 0.3;
              const bckgDimensions = [textWidth, fontSize].map(n => n + fontSize * padding); // some padding
              const borderDimensions = [textWidth, fontSize].map(n => n + fontSize * padding * 2); // some padding
  
              // ctx.fillStyle = 'rgba(20, 28, 32, 0.4)';
              ctx.fillStyle = node.color;
              ctx.fillRect(node.x - borderDimensions[0] / 2, node.y - borderDimensions[1] / 2, ...borderDimensions);
              ctx.beginPath();
              ctx.arc(node.x - borderDimensions[0] / 2, node.y, borderDimensions[1] / 2, 0, 2 * Math.PI, false);
              ctx.arc(node.x + borderDimensions[0] / 2, node.y, borderDimensions[1] / 2, 0, 2 * Math.PI, false);
              ctx.fill();
  
              ctx.fillStyle = 'rgba(255, 255, 255, 1)';
              ctx.fillRect(node.x - bckgDimensions[0] / 2, node.y - bckgDimensions[1] / 2, ...bckgDimensions);
              ctx.beginPath();
              ctx.arc(node.x - bckgDimensions[0] / 2, node.y, bckgDimensions[1] / 2, 0, 2 * Math.PI, false);
              ctx.arc(node.x + bckgDimensions[0] / 2, node.y, bckgDimensions[1] / 2, 0, 2 * Math.PI, false);
              ctx.fill();
  
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              ctx.fillStyle = 'black';//node.color;
              ctx.fillText(label, node.x, node.y);
          });
        });

        graph.d3AlphaDecay(0.0114); // default: 0.0228
        graph.d3VelocityDecay(0.2); // default: 0.4

        graph.d3Force('charge', d3.forceManyBody().strength(-70));
        graph.d3Force('gravity-x', d3.forceX(canvasWidth / 2).strength(0.08));
        graph.d3Force('gravity-y', d3.forceY(canvasWidth / 2).strength(0.08));
        graph.d3Force('collide', d3.forceCollide((node, idx) => {
          return node.val - 3;
        })); // graph.nodeRelSize()


        document.body.onkeydown = function(e) {
            const kc = String.fromCharCode(e.keyCode);
            if(kc === 'C') {
              graph.zoomToFit(500, 30);
            } else if(kc === 'R') {
              graph.d3ReheatSimulation();
            }
        };

        graph.width(canvasWidth);
        graph.height(canvasWidth);

        graph(canvasEl);

        // graph.centerAt(canvasWidth/2, canvasWidth/2, 1200);
        // graph.zoomToFit(1000, 100)
        setTimeout(() => {
            graph.zoomToFit(1000, 100);
        }, 1500);
        setTimeout(() => {
            graph.zoomToFit(1000, 100);
        }, 2500);
        graph.onEngineStop(() => graph.zoomToFit(400));

        return graph;
    }

    @computed("clickedNode", "clusterQuery")
    get postQueries() {
      if (!this.clickedNode || !this.clusterQuery || !this.clickedNode?.id) {
        return null;
      }
      let newQuery = this.clusterQuery.copy();
      newQuery.filters.addAdditionalAPIParameters({"sub_ids": this.clickedNode.id});
      return [newQuery];
    }

    @computed("cleanedGraphData", "clickedNode", "clusterQuery")
    get selectedClusterQueries() {
      let nodeData = this.cleanedGraphData?.nodes;
      if (!this.clickedNode || !this.clusterQuery || !nodeData) {
        return null;
      }
      let c = this.clickedNode.c,
          nodeIds = nodeData.filter(node => node.c === c && node.id !== this.clickedNode.id ).map(node => node.id);
      let newQuery = this.clusterQuery.copy();
      newQuery.filters.addAdditionalAPIParameters({"sub_ids": nodeIds});
      return [newQuery];
    }

    @computed("cleanedGraphData", "clickedNode", "clusterQuery")
    get selectedCluster() {
      let clusterData = this.cleanedGraphData?.clusters;
      if (!this.clickedNode || !this.clusterQuery || !clusterData) {
        return null;
      }
      let c = this.clickedNode.c,
          cluster = clusterData[c];
      return cluster;
    }

    @computed("selectedCluster")
    get selectedSentimentDistribution() {
        let cluster = this.selectedCluster;
        if (!cluster || !cluster.negative_documents) {
            return 0;
        }
        return cluster.positive_documents / (cluster.negative_documents + cluster.positive_documents);
    }

    @computed("selectedCluster", "clusterQuery", "cluster.source._sourceQuery")
    get drilldownQuery(){
        if (!this.clusterQuery || !this.selectedCluster?.name || !this.selectedCluster?.query) {
            return [];
        }

        let drilldownQuery = this.clusterQuery.toDrilldownQuery({
            queryString: this.selectedCluster.query,
            queryLabel: this.selectedCluster.name
        });
        return [drilldownQuery];
    }

    @action
    canvasCreated(element) {
      let el = element;
      this.canvasElement = el;
    }

    resizeFunction = null;

    constructor(...args) {
        super(...args);
        var resizeFunction = () => {
            Ember.run.throttle(()=>{
                let canvasSize = this.findCanvasWidth(),
                graph = this.graph;
            
                if (!canvasSize || !graph) {
                    return;
                }
        
                graph.width(canvasSize);
                graph.height(canvasSize);
                graph.zoomToFit(400, 100);
            }, 250);
        };
        this.resizeFunction = resizeFunction;
        window.addEventListener("resize", resizeFunction)
    }

    willDestroyElement() {
       window.removeEventListener("resize", this.resizeFunction)
    }

    onNodeCountChanged() { /* action */ };
    onDisplayLinksChanged() { /* action */ };
    onColorBySentimentChanged() { /* action */ };
    onDarkModeChanged() { /* action */ };
    onClusterLabelTypeChanged() { /* action */ };

    @action
    nodeCountChanged(count) {
      this.onNodeCountChanged(count);
    }

    @action
    displayLinksChanged(state) {
      this.onDisplayLinksChanged(state);
    }

    @action
    colorBySentimentChanged(state) {
      this.onColorBySentimentChanged(state);
    }

    @action
    darkModeChanged(state) {
      this.onDarkModeChanged(state);
      this.set("darkMode", state);
      if (state) {
        this.graph?.backgroundColor('#000');
      } else {
        this.graph?.backgroundColor('#fff');
      }
    }

    @action
    clusterLabelTypeChanged(value) {
        this.onClusterLabelTypeChanged(value);
    }

    @action
    downloadImage() {
        const link = document.createElement('a');
        link.download = 'narratives.png';
        link.href = this.canvasElement.querySelector('canvas').toDataURL();
        link.click();
        link.delete;
    }

    @action
    zoomIn() {
        let graph = this.graph;
        if (!graph || !graph.zoom) {
            return;
        }
        let currentZoom = graph.zoom();
        graph.zoom(Math.max(currentZoom / 1.5, graph.minZoom()), 400);
    }

    @action
    zoomOut() {
        let graph = this.graph;
        if (!graph || !graph.zoom) {
            return;
        }
        let currentZoom = graph.zoom();
        graph.zoom(Math.min(currentZoom * 1.5, graph.maxZoom()), 400);
    }

    @action
    zoomFit() {
        let graph = this.graph;
        if (!graph || !graph.zoomToFit) {
            return;
        }
        graph.zoomToFit(400, 100);
    }

    @action
    renameCluster(cluster, newName) {
        this.set("clickedNode", null);
        this.onRenameCluster(cluster, newName);
    }

    @action
    removeCluster(cluster) {
        this.set("clickedNode", null);
        this.onRemoveCluster(cluster);
    }
}