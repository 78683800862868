import Ember from "ember";
export default Ember.Route.extend({
    title: "Geography",
    activePage: '',
    afterModel: function(model, transition) {
        if (transition.targetName === "atlas.dashboard.content.geography" || transition.targetName === "atlas.dashboard.content.geography.index") {
            this.set("activePage",'countries');
        } else {
            this.set("activePage",transition.targetName.split('.').pop());
        }
    },
    setupController: function(controller){
        this._super();
        controller.set("activePage",this.activePage);
    }
});
