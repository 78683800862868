import Ember from "ember";
import { computed } from '@ember/object';
import d3 from "d3";
import { MouseInteractionWrapper } from "infegy-frontend/components/charting/mouse-interaction-mixin/component";

export default class extends MouseInteractionWrapper(Ember.Component) {
    tagName = "g";
    data = [];

    contentArea = {};
    showArea = false;
    showLine = true;
    yAtZero = false;

    hoveredIndex = null;
    totalInteractionPoints = 10;

    height = 60;
    width = 100;
    dotRadius = 3;

    color = null;

    @computed("color")
    get colorStyles() {
        var color = this.color;
        return color ? {
            stroke: new Ember.String.htmlSafe(`stroke:${color}`),
            fill: new Ember.String.htmlSafe(`fill:${color}`),
        } : null;
    }

    @computed("data", "height", "yAtZero")
    get yScale() {
        var data = this.data;
        if (!data) {
            return;
        }
        var height = this.height || 100,
            minY = this.yAtZero ? 0 : Math.min.apply(Math, data),
            maxY = Math.max.apply(Math, data);
        return d3.scaleLinear().domain([minY, maxY]).rangeRound([height, 0]);
    }

    @computed("data", "width")
    get xScale() {
        var dataLength = this.get("data.length"),
            width = this.width || 100;
        return d3.scaleLinear()
                .domain([0, (dataLength - 1) || 1])
                .rangeRound([0, width]);
    }

    @computed("data", "yScale")
    get endpointYValues() {
        var data = this.data;
        if (!data || !data.length) {
            return;
        }
        var yScale = this.yScale;
        return {
            start: yScale(data[0]),
            end: yScale(data[data.length - 1]),
        };
    }

    @computed("hoveredIndex")
    get isHovered() {
        var hoveredIndex = this.hoveredIndex;
        return typeof(hoveredIndex) === "number";
    }

    @computed("isHovered", "hoveredIndex", "totalInteractionPoints", "width")
    get hoverIndicatorX() {
        if (!this.isHovered) {
            return;
        }
        var hoveredIndex = this.hoveredIndex,
            points = this.totalInteractionPoints || 2,
            width = this.width;
        return (hoveredIndex / (points - 1)) * width;
    }

    @computed("selectedIndex", "totalInteractionPoints", "width")
    get selectedIndicatorX() {
        var points = this.totalInteractionPoints || 2;
        return (this.selectedIndex / (points - 1)) * this.width;
    }

    @computed("data", "height", "width", "yAtZero")
    get svgData() {
        var data = this.data;
        if (!data) {
            return;
        }
        var yScale = this.yScale,
            xScale = this.xScale,
            height = this.height,
            area = d3.area()
                .curve(d3.curveMonotoneX)
                .x((d, i) => xScale(i)).y1(d => yScale(d)).y0(height),
            line = d3.line()
                .curve(d3.curveMonotoneX)
                .x((d, i) => xScale(i)).y(d => yScale(d)),
            svgData = {
                line: line(data),
                area: area(data)
            };
        return svgData;
    }

    onMouseLeave() {
        this.hoveredIndexChanged && this.hoveredIndexChanged(null);
        return true;
    }

    pctToIdx(portion, dataPoints) {
        if (!dataPoints) {
            return null;
        }
        if (dataPoints.length <= 1) {
            return 0;
        }
        return Math.round(portion * (dataPoints - 1)) || 0;
    }

    hoveredIndexChanged = () => {};

    onMouseMove(event) {
        var x = event && event.offsetX,
            width = this.width || 1,
            portion = x / width,
            groups = this.totalInteractionPoints,
            dataIdx = this.pctToIdx(portion, groups);
            this.hoveredIndexChanged && this.hoveredIndexChanged(dataIdx);
        return true;
    }

}
