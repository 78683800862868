import ToggleList from "infegy-frontend/components/forms/toggle-list/component";
import ToggleListLayout from "infegy-frontend/components/forms/toggle-list/template";
import { isEmpty } from '@ember/utils';
import { computed } from '@ember/object';
import { action } from '@ember/object';
import default_color_definitions from "infegy-frontend/static_data/fixtures/default_color_definitions";

export default class ClusterSelector extends ToggleList {
    layout = ToggleListLayout;
    // arguments
    maxSelected=6;
    initiallySelected=6;
    colorField="color";
    theme="colorful";
    selectedValues = [];
    allowPrint = true;
    selectedColorsChanged = ()=>{/* action */}

    // properties
    _indexedColors =[];
    allColors = default_color_definitions.mapBy("hex");


    @computed("selectedValues", "_indexedColors")
    get indexedColors(){
        if (this._indexedColors.length !== this.selectedValues.length){
            this.selectedValues.forEach((value, idx)=> {
                if (isEmpty(this._indexedColors[idx])){
                    this._indexedColors[idx] = this.getFirstUnusedColor();
                    this.updateSelectedColors();
                }
            });
        }
        return this._indexedColors;
    }

    set indexedColors(value){
        this.set("_indexedColors", value);
        this.updateSelectedColors();
    }

    @action
    updateSelectedColors(){
        this.selectedColorsChanged([].concat(this._indexedColors));
    }

    @computed("innerOptions.[]", "indexedColors.[]")
    get optionsWithColors(){
        return this.innerOptions.map((option) => {
            var selectionIdx = this.innerSelectedValues.findIndex(value => option.value === value);
            option.set("color", this.indexedColors[selectionIdx]);
            return option;
        });
    }

    getFirstUnusedColor(){
        return this.allColors[this.allColors.findIndex(val => !this._indexedColors.includes(val))];
    }

    addToSelection(optionValue){
        let innerSelectedValues = this.innerSelectedValues || [];
        if (isEmpty(optionValue) || (innerSelectedValues).includes(optionValue)) {
            return;
        }
        if (this.maxSelected >= 1 && innerSelectedValues.length >= this.maxSelected) {
            innerSelectedValues = innerSelectedValues.slice(0, this.maxSelected - 1);
            this.set("_indexedColors", this._indexedColors.slice(0,this.maxSelected - 1));
        }
        innerSelectedValues.push(optionValue);
        this.updateSelection(innerSelectedValues);
    }
    
    removeFromSelection(optionValue){
        if(isEmpty(optionValue) || !(this.innerSelectedValues.includes(optionValue))){
            return;
        }
        if (this.innerSelectedValues.length - 1 >= (this.minSelected || 0)){
            let valueIdx = this.innerSelectedValues.findIndex(val => val === optionValue),
                newValues = this.innerSelectedValues.without(optionValue);
            // remove value color
            this.set("indexedColors", this.indexedColors.filter((v) => v !== this.indexedColors[valueIdx]));
            this.updateSelection(newValues);
        }
    }
    
    @action
    updateSelection(selectedValues){
        this.selectedValuesChanged(selectedValues);
    }
}