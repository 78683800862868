import Ember from "ember";
import DataSeries from "infegy-frontend/models/data_series/data_series";
import Languages from "infegy-frontend/static_data/query/query_filters/languages";

export default Ember.Component.extend({
    queries: [],
    apis: ["gender","demographicsMeta","ages"],

    hasOnlyCustomDatasets: false,

    genderDataSeries: Ember.computed("queries","queries.length","queries.@each.updated",function(){
        let queries = this.queries;
        return DataSeries.stackTimeseries(queries.map((query)=> query.get(`gender.dataSeries`)));
    }),

    maleSum: Ember.computed.alias("genderDataSeries.stats.maleUniverse.sum"),
    femaleSum: Ember.computed.alias("genderDataSeries.stats.femaleUniverse.sum"),
    femaleMajority: Ember.computed.gte("femaleSum","maleSum"),
    totalSum: Ember.computed("maleSum","femaleSum", function(){
        return this.maleSum + this.femaleSum;
    }),

    queryColor: Ember.computed("queries",function(){
        if(this.queries.length > 1) {
            return null;
        } else {
            return this.get("queries.firstObject.queryInfo.color");
        }
    }),

    education: Ember.computed("queries","queries.@each.demographicsMeta",function(){
        var queries = this.queries || [];

        var mean = queries.reduce((prev, query) => {
            var dbMeanValue = query.get("demographicsMeta.data.educationAnyCollege");
            prev += (dbMeanValue / 100.0);
            return prev;
        }, 0) / queries.length;
        return mean;
    }),

    majorityGender: Ember.computed("maleSum", "femaleSum", function() {
        var male = this.maleSum,
            female = this.femaleSum,
            majorityGender = {};

        if (!male && !female) {
            majorityGender.class = "minus-circle-outline";
            majorityGender.gender = "N/A";
        } else if (female > male) {
            majorityGender.class = "gender-female";
            majorityGender.gender = "female";
        } else {
            majorityGender.class = "gender-male";
            majorityGender.gender = "male";
        }

        return majorityGender;
    }),

    mostCommonAgeRange: Ember.computed("queries","queries.length","queries.@each.updated", function() {
        var queries = this.queries || [],
            queriesAgeData = queries.map((query) => {
                var data = query.get("ages.all.data");
                return data;
            }),
            commonAgeObj = {};

        queriesAgeData.forEach((queryAgeObj) => {
            queryAgeObj.forEach((ageRangeItem) => {
                commonAgeObj[ageRangeItem.displayName] = ageRangeItem.count + (commonAgeObj[ageRangeItem.displayName] || 0);
            });
        });

        var numPosts = Object.values(commonAgeObj).reduce((a, b) => a + b, 0);
        if (!numPosts) {
            return "N/A";
        }

        return Object.keys(commonAgeObj).reduce((a, b) => {
            return commonAgeObj[a] > commonAgeObj[b] ? a : b;
        });
    }),

    medianHouseholdIncome: Ember.computed("queries","queries.length","queries.@each.updated",function(){
        var queries = this.queries,
            totalSampleSize = 0;

        return queries.reduce((prev, query) => {
            let median = query.get("demographicsMeta.data.medianHouseholdIncome"),
                querySampleSize = query.get("demographicsMeta.data.sampleSize");

            totalSampleSize += querySampleSize;

            return prev + (median * querySampleSize);
        }, 0) / totalSampleSize;
    }),

    topLanguage:Ember.computed("queries","queries.length","queries.@each.updated",function(){
        var queries = this.queries,
            lang_sums = {},
            total_documents = 0;
        queries.forEach((query)=>{
            var languages = query.get("languages.availableLanguages"),
                total_documents = query.get("languages.stats.documents.sum");
            languages.forEach((langCode)=>{
                let prob = query.get(`languages.stats.${langCode}.sum`) / total_documents;
                lang_sums[langCode] = (lang_sums[langCode] || 0) + prob;
            });

        });

        return Object.keys(lang_sums).map((lang)=>{
            return {
                title: Languages.allLanguages.findBy("value",lang).label,
                distribution: lang_sums[lang] / queries.length
            };
        }).sortBy("distribution").pop();
    })
});
