import Ember from 'ember';

export default Ember.Route.extend({
    redirect: function() {
        if(window.location.pathname.substr(0,5) === "/app/"){
            window.location = "/atlas/" + window.location.pathname.substr(5);
        } else {
            this.transitionTo('index');    
        }
    }
});
