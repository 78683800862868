import Ember from "ember";
import { computed } from '@ember/object';
import { MouseInteractionWrapper } from "infegy-frontend/components/charting/mouse-interaction-mixin/component";

export default class extends MouseInteractionWrapper(Ember.Component) {
    tagName = "path";
    classNameBindings = [":geo-map-area", "isHovered:geo-map-area-active"];
    attributeBindings = ["d", "stroke", "strokeWidth:stroke-width", "fill"];

    area = null;
    @Ember.computed.alias("area.d") d;
    stroke = "#fff";
    strokeWidth = "1px";
    @Ember.computed.alias("area.fill") fill;

    activeArea = null;
    isHoverable = true;

    @computed("activeArea", "area")
    get isHovered() {
        var activeKey = this.get("activeArea.key");

        return activeKey && (activeKey === this.get("area.key"));
    }

    click() {
        this.selectedAreaChanged(this.area);
    }

    onMouseEnter(event) {
        if (this.isHoverable) {
            this.hoveredAreaChanged(this.area, event, this.contentAreaWidth);
        }
    }

    onMouseLeave() {
        this.hoveredAreaChanged(null);
    }

    hoveredAreaChanged = () => {};
    selectedAreaChanged = () => {};
}
