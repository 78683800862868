import Ember from "ember";
import {computed} from "@ember/object";
import JsonStore from 'infegy-frontend/json-store/model';
import Prop from 'infegy-frontend/json-store/properties/model';
import FieldProp from 'infegy-frontend/json-store/properties/fields/model';
import CollectionStore from "infegy-frontend/json-store/collection/model";
import classic from "ember-classic-decorator";

@classic
export class ComparisonTableField extends JsonStore {
    // Type of display, can be:
    // sparkline, barchart, heatmap, numeric
    @Prop.String({default: "numeric"}) displayType;

    @computed("displayType")
    get fieldType() {
        if ("sparkline" === this.displayType) {
            return "trend";
        } else if ("barchart" === this.displayType) {
            return "portion";
        } else {
            return "scalar";
        }
    }

    @computed("displayType")
    get fieldHeaderStyle() {
        if ("sparkline" === this.displayType) {
            return "data-table-fixed-sparkline-header";
        } else if ("barchart" === this.displayType) {
            return "data-table-fixed-numeric";
        } else {
            return "data-table-fixed-numeric";
        }
    }

    @FieldProp.Field({defaultField: {
            "fieldClass": "trend-field",
            "field_component": "field-selector/parts/fields/sentiment",
            "api_name": "sentiment",
            "segment": "Sentiment",
            "subsegment": "DocumentsDistribution",
            "title": "positive",
            "field_name": "positiveDocumentsDistribution",
            "default_color": "rgb(127, 196, 10)",
            "inactive_by_default": false,
            "value": "sentiment.positiveDocumentsDistribution",
            "field_type": "scalar",
            "scalar_field": "average"
        }}) field;
};

@classic
export class ComparisonTableFields extends JsonStore {
    layoutOptions = ["fieldColumn", "queryColumn"];

    @Prop.String({default: "fieldColumn"}) selectedLayout;
    @Prop.Boolean() showHighestFields;
    @Prop.Boolean() showLowestFields;
    @Prop.Boolean({default: true}) showCombinedTotals;

    @Prop.Collection(ComparisonTableField) fields;

    updates = 0;
    update() { this.set("updates", this.updates + 1); }
};
