import Ember from "ember";
import Component from '@ember/component';
import {computed} from "@ember/object";
import classic from "ember-classic-decorator";

@classic
export default class FieldScalarValue extends Component {
    tagName = "span";
    attributeBindings = ['style'];

    colorize = false;

    showPortionWhole = true;

    valueOverride = null;

    abbreviateNumbers = true;

    @computed("valueOverride", "field", "field.scalarValue", "field.isPercentageType", "field.portionPercentage")
    get displayValue() {
        if (!Ember.isEmpty(this.valueOverride)) {
            return this.valueOverride;
        }
        if (this.field && this.field.isPercentageType && !Ember.isEmpty(this.field.portionPercentage)) {
            return this.field.portionPercentage;
        }
        return this.field && this.field.scalarValue;
    };

    @computed("showPortionWhole", "field.fixedPortionWhole")
    get dispayPortionWhole() {
        return this.showPortionWhole && this.field && this.field.fixedPortionWhole;
    };

    @computed("abbreviateNumbers", "displayValue")
    get useAbbreviatedNumbers() {
        return this.abbreviateNumbers && (this.displayValue > 1000 || this.displayValue < -1000);
    }

    @computed("colorize", "field.color", "field.color.textStyle")
    get style() {
        if (this.colorize) {
            return this.field?.color?.textStyle;
        }
    }
};
