import Ember from "ember";
import { isNone } from '@ember/utils';

export function limitArray(params, namedArgs) {
    var array = namedArgs.array ?? params[0],
        offset = namedArgs.offset ?? params[1],
        limit = namedArgs.limit ?? params[2];

    // if we only get one parameter, treat it as a limit
    if (!isNone(offset) && isNone(limit)) {
        limit = offset;
        return array.slice(0,limit);
    } else if(!isNone(limit)) {
        return array.slice(offset,limit);
    }
    // if we get neither a limit nor an offset, return the unchanged array
    return array;
}

export default Ember.Helper.helper(limitArray);
