import Ember from "ember";
import Actions from "infegy-frontend/utils/common-actions";

export default Ember.Component.extend({
    theme: null,

    themeSelected: () => { /* action */ },
    actions: {
        themeSelected: Actions.passUp("themeSelected"),
    }
});
