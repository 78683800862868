import Ember from 'ember';

export default Ember.Component.extend({
    isLoading: false,
    loginError: null,
    username: "",
    password: "",

    setupEl: Ember.on("init",function() {
        Ember.run.schedule('afterRender', this, function() {
            this.element.querySelector('#atlas_username').focus();
        });
    }),

    login: () => {},
    actions: {
        login: function() {
            var username = this.username;
            var password = this.password;

            this.login(username, password);
        }
    }
});
