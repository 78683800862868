import Component from "@ember/component";
import classic from "ember-classic-decorator";
import { computed } from "@ember/object";

@classic
export default class FilterItem extends Component {
    filter = {};
    apiFilters = [];

    @computed("filter", "apiFilters")
    get apiFilterOptions() {
      let filter = this.apiFilters?.find(option => this.filter.id === option.id);
      return filter?.options;
    }

    @computed("filter")
    get value() {
      let filter = this.filter,
          valueString = "",
          filterType = filter.outputType;

        if (filterType.isNumeric) {
          let min = filter.min,
              max = filter.max;
              if (!min && min !== 0) {
                min = "-Infinity";
              }
              if (!max && max !== 0) {
                max = "Infinity";
              }
              valueString = `${min} - ${max}`;
        } else if (filterType.isValues) {
          let values = (filter.values || []).map(v =>  v === "__unknown__" ? "unknown" : v);
              if (this.apiFilterOptions) {
                values = values.map(value => {
                  let foundFilterOption = this.apiFilterOptions.find(option => option.id === value);
                  return foundFilterOption ? foundFilterOption.name : value;
                });
              }
              valueString = values.join(", ");
        } else if (filterType.isBoolean) {
              valueString = filter.booleanValue;
        } else if (filterType.isTime) {
          let min = filter.minDate.dateString,
              max = filter.maxDate.dateString;
              valueString = `${min} - ${max}`;
        } else if (filterType.isText) {
              valueString = filter.textValue;
        }
        return valueString;
    }
}
