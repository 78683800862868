var Channels = [{
    label: "Microblogs",
    value: "microblogs",
    description:
        "Sources that focus on short-form blogging and status updates",
    examples: [{
            "title": "Engadget",
            "url": "http://www.engadget.com"
        }]
},{
    label: "Blogs",
    value: "blogs",
    description:
        "Sources with general feed content often including longer form articles, news, editorials and other posts"
},{
    label: "Forums",
    value: "forums",
    description:
        "Sources centered on discussion, conversation and debate generally around narrow topics or \"threads\""
},{
    label: "Images",
    value: "images",
    description:
        "Sources specializing in the posting, sharing, and discussion of image and photo content",
    examples: [{
            title: "Flickr",
            url: "http://www.flickr.com"
        }]
},{
    label: "News",
    value: "news",
    description:
        "Sources focusing on publishing of news content. Can overlap with other channels, such as Twitter."
},{
    label: "Twitter",
    value: "twitter",
    description:
        "Posts originating from Twitter only. This is a subset of the Microblogs channel."
},{
    label: "Facebook",
    value: "facebook",
    description:
        "Posts originating from Facebook only. This is a subset of the Microblogs channel."
},{
    label: "Tumblr",
    value: "tumblr",
    description:
        "Posts originating from Tumblr only. This is a subset of the Blogs channel."
},{
    label: "Instagram",
    value: "instagram",
    description:
        "Posts originating from Instagram only. This is a subset of the Images channel."
},{
    label: "Pinterest",
    value: "pinterest",
    description:
        "Posts originating from Pinterest only. This is a subset of the Images channel."
},{
    label: "Weibo",
    value: "weibo",
    description:
        "Posts originating from Weibo only. This is a subset of the Microblogs channel."
},{
    label: "Reddit",
    value: "reddit",
    description: "Posts originating from Reddit only. This is a subset of the Forums channel."
},{
    label: "TikTok",
    value: "tiktok",
    description: "Posts originating from TikTok only. This is a subset of the Images channel."
},{
    label: "VK",
    value: "vk",
    description: "Posts originating from VK only. This is a subset of the Microblogs channel."
},{
    label: "YouTube",
    value: "youtube",
    description:
        "Posts originating from YouTube only."
}];

export default Channels;
