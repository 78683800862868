import Component from "@ember/component";
import { action, computed } from "@ember/object";
import { isNone } from "@ember/utils";

import QualtricsDataPusher from "infegy-frontend/models/qualtrics/data_pusher";
import QualtricsSurvey from "infegy-frontend/models/qualtrics/survey";

export default class QualtricsDataPushersListItem extends Component {
    dataPusher = QualtricsDataPusher.create();
    survey = QualtricsSurvey.create();
    deletingDataPusher = null;
    pausingDataPusher = null;

    showDetails = false;

    showSaveError = false;

    @computed("deletingDataPusher")
    get isDeleting() {
        return this.deletingDataPusher === this.dataPusher;
    }

    @computed("pausingDataPusher")
    get isPausing() {
        return this.pausingDataPusher === this.dataPusher;
    }

    @computed.alias("survey.name") surveyName;
    
    @computed.alias("survey.questions") surveyQuestions;

    @computed("surveyQuestions", "surveyQuestions.length")
    get surveyQuestion() {
        const question = (this.surveyQuestions ?? []).findBy("id", this.dataPusher.qualtricsQuestionId);
        return question;
    }

    @computed("dataPusher.isPaused", "dataPusher.lastPush")
    get hasNeverPushed() {
        const lastPush = new Date(this.dataPusher.lastPush);
        return lastPush.getFullYear() < 2020;
    }

    @computed("dataPusher.isPaused")
    get statusText() {
        if (this.survey.active) return "Active";
        return "New";
    }

    @computed.alias("surveyQuestion.name") questionName;
    @computed.alias("surveyQuestion.text") questionText;

    // event handlers
    confirmDelete(dataPusher) {};

    @action
    onBeginDelete() {
        this.set("deletingDataPusher", this.dataPusher);
    }

    @action
    onCancelDelete() {
        this.setProperties({
            deletingDataPusher: null,
            showSaveError: false
        });
    }

    @action
    async onConfirmDelete() {
        try {
            await this.dataPusher.delete();
        }
        catch(error) {
            this.set("showSaveError", true);
            Ember.run.later(()=>{
                this.set("showSaveError", false);
            }, 5000);

            // return on error
            return;
        }

        this.confirmDelete(this.dataPusher);
    }

    @action
    onToggleShowDetails() {
        this.toggleProperty("showDetails");
        if (this.showDetails && isNone(this.survey.id) && this.survey.isLoading == false) {
            this.survey.load(this.dataPusher.qualtricsToken, this.dataPusher.qualtricsSurveyId);
        }
    }

    @action
    onBeginPause() {
        this.set("pausingDataPusher", this.dataPusher);
    }

    @action
    onCancelPause() {
        this.set("pausingDataPusher", null);
    }

    @action
    async onConfirmPause() {
        try {
            await this.dataPusher.pause();
        }
        catch(error) {
            this.set("showPauseError", true);
            Ember.run.later(()=>{
                this.set("showPauseError", false);
            }, 5000);

            // return on error
            return;
        }

        this.set("pausingDataPusher", null);
    }

    @action
    async onResume() {
        try {
            await this.dataPusher.resume();
        }
        catch(error) {
            this.set("showResumeError", true);
            Ember.run.later(()=>{
                this.set("showResumeError", false);
            }, 5000);

            // return on error
            return;
        }
    }
}