import Route from "@ember/routing/route";
import Ember from "ember";

import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import * as Sentry from "@sentry/browser";
import User from "infegy-frontend/models/users/user";

export default class ApplicationRoute extends Route {
    async afterModel() {
        try {
            Ember.set(AtlasAuth, "user", User.create());
            await AtlasAuth.user.load();
            if (AtlasAuth.user.isActive) {
                Ember.set(AtlasAuth, "isLoggedIn", true);
            }
        } catch (xhr) {
            if (xhr) {
                const controller = this.controllerFor("application");
                if (xhr.status >= 500 || xhr.status === 0) {
                    Sentry.captureException("API not responding",{
                        "extra":{
                            "responseStatus":xhr.status,
                            "responseText": xhr.responseText,
                        }
                    });
                    controller.set('isApiDown', true);
                }
                if (xhr.status === 0) {
                    controller.set('connectionLost', true);
                }
            }
            this.transitionTo("login");
            return;
        }
        AtlasAuth.user.loadAll();
        if (document.location.pathname.length < 2) {
            this.transitionTo('atlas.dashboard');
        }
    }
}
