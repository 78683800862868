import Ember from "ember";

export default Ember.Component.extend({
    classNames: ["even-columns-4"],
    
    queries: null,
    
    metaTotals: Ember.computed("queries","queries.length","queries.@each.updated",function(){
        var queries = this.queries || [];
        return queries.reduce((memo, query)=> {
            memo.reach += query.get(`interests.meta.reach`) || 0;
            memo.impressions += query.get(`interests.meta.impressions`) || 0;
            memo.total_sources_est += query.get(`interests.meta.totalSourcesEst`) || 0;
            memo.sources_universe += query.get(`interests.meta.sourcesUniverse`) || 0;
            return memo;
        }, { 
            reach: 0,
            impressions: 0,
            total_sources_est: 0,
            sources_universe: 0
        });
    }),
    
    color: Ember.computed("queries", "queries.length", "queries.@each.updated", function() {
        var queries = this.queries || [];
        if (Ember.get(queries, "length") === 1) {
            return this.get("queries.firstObject.color");
        } 
        return null;
    }),
});
