import Ember from "ember";
import { inject as controller } from "@ember/controller";
import {computed, action} from "@ember/object";
import ControllerBase from "infegy-frontend/atlas/dashboard/content/base/controller";
import BrowserStore from "infegy-frontend/utils/browser-store";
import AxisChartFields from "infegy-frontend/components/field-components/axis-comparison-chart/axis-chart-fields";
import { ComparisonTableFields } from "infegy-frontend/components/field-components/comparison-table/table-field";

export default class OverviewController extends ControllerBase {
    apis = ["volume", "sentiment", "emotions", "entities", "interests", "posts"];

    @BrowserStore.Attr("overviewComparisonViewType", { defaultValue: "table" }) comparisonViewType;

    @BrowserStore.Attr("overviewAxisChartFields") axisChartFieldsJSON;
    axisChartFields = null;

    @BrowserStore.Attr("overviewComparisonTableFields") comparisonTableFieldsJSON;
    comparisonTableFields = null;

    constructor(){
        super(...arguments);
        if (this.axisChartFieldsJSON) {
            let axisFields = AxisChartFields.create();
            axisFields.loadJSON(this.axisChartFieldsJSON);
            this.set("axisChartFields", axisFields);
        }
        if (this.comparisonTableFieldsJSON) {
            let tableFields = ComparisonTableFields.create();
            tableFields.loadJSON(this.comparisonTableFieldsJSON);
            this.set("comparisonTableFields", tableFields);
        }
    }

    @computed("hasOnlyCustomDatasets")
    get overviewInterestsApi() {
        return this.hasOnlyCustomDatasets ? "postInterests" : "interests";
    };

    @action
    comparisonViewTypeChanged(type) {
        this.set("comparisonViewType", type);
    }

    @action
    axisChartFieldsChanged(fields) {
        this.set("axisChartFields", fields);
        this.set("axisChartFieldsJSON", fields && fields.toJSON());
    }

    @action
    comparisonTableFieldsChanged(fields) {
        this.set("comparisonTableFields", fields);
        this.set("comparisonTableFieldsJSON", (fields && fields.toJSON()) || null);
    }

};
