import { isEmpty } from "@ember/utils";

import JsonStore from "infegy-frontend/json-store/model";
import Prop from "infegy-frontend/json-store/properties/model";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import Workspace from "infegy-frontend/models/users/workspace2";

export default class WorkspaceFolder extends JsonStore {
    @Prop.Int() id;
    @Prop.String() title;
    @Prop.String() lastUsedOn;
    @Prop.String() createdOn;
    @Prop.String() updatedOn;

    @Prop.Collection(Workspace) workspaces;

    url = "api/v3/workspace_folders";

    async save(title, isDeleted=false) {
        let data = { title };
        if (isEmpty(this.id)) {
            // create a new workspace folder
            const response = await AtlasAuth.Post({ url: this.url, data });
            this.workspaces.clear();
            this.loadJSON(response.output[0]);
        }
        else {
            // update an existing workspace folder
            data.id = this.id;
            data.is_deleted = isDeleted;
            const response = await AtlasAuth.Put({ 
                url: this.url, 
                data 
            });
            this.workspaces.clear();
            this.loadJSON(response.output[0]);
        }
    }

    async delete() {
        await AtlasAuth.Delete({ 
            url: this.url, 
            data: { id: this.id } 
        });
    }
}