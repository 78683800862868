import Ember from "ember";

export default Ember.Component.extend({
    tagName: "tr",
    classNameBindings: [":data-table-row", "isEven:data-table-row-even"],

    emotion: null,
    selectedField: null,

    distributionPercentage: Ember.computed("emotion.combined.DocumentsDistribution", function () {
        var pct = (this.get("emotion.combined.DocumentsDistribution") || 0) * 100.0;
        return pct.toFixed(1);
    }),

    distributionWidthStyleSafe: Ember.computed("distributionPercentage", function() {
        var percentage = this.distributionPercentage || 0;
        return new Ember.String.htmlSafe(["width:", percentage, "%;"].join(""));
    }),
});
