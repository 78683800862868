import Component from "@ember/component";
import classic from "ember-classic-decorator";
import { computed, action } from "@ember/object";
import Query from "infegy-frontend/models/users/query";

@classic
export default class extends Component{
    query = null;
    post = null;
    link = null;

    drilldownDisplayLinksTo = false;

    @computed()
    get drilldownLinksToQuery() {
        let query = Query.create();

        query.queryInfo.loadJSON({
            startDate: this.post.dateValue,
            endDate: "Now"
        })

        query.queryInfo.addFilter({
            id: "links_to",
            values: [this.link]
        })

        return [query];
    }

    // Collection of "link_to" Data began on 15SEP22
    // Posts before that date won't have data and should hide the drilldown from display.
    @computed()
    get drilldownValidDate() {
        const collectionDate = new Date("2022-08-15T00:00:00Z").getTime();
        let postDate = new Date(this.post.dateValue).getTime();

        return postDate > collectionDate;
    }

    // LexisNexis collection masks true URL links with ct.moreover.com causing display issues on links_to
    // Posts with filterOutLink in the URL will be disabled from post links_to drilldown
    @computed()
    get drilldownValidLink() {
        const filterOutLink = "https://ct.moreover.com";
        let postLink = this.post.fields.link;

        return !postLink.includes(filterOutLink);
    }

    @action
    drilldownToggle() {
        this.toggleProperty("drilldownDisplayLinksTo");
    }
}
