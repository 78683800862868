import Ember from "ember";

// Field Index for a DataSeries object field.
// Calculated for any specified fields within a DataSeries array.
var DataSeriesFieldIndex = Ember.Object.extend({
    field: "unknown",

    values: null,
    lookup: null,
    indexedData: null,

    valuesAsc: Ember.computed("values", {
        get: function(key) {
            return (this.values || []).sort(function(a,b){
                if (a > b) {
                    return 1;
                } else if (b > a) {
                    return -1;
                }
                return 0;
            });
        },
    }),

    valuesDesc: Ember.computed("values", {
        get: function(key) {
            return (this.values || []).sort(function(a,b){
                if (a < b) {
                    return 1;
                } else if (b < a) {
                    return -1;
                }
                return 0;
            });
        },
    }),
});

DataSeriesFieldIndex.reopenClass({
    buildFrom: function(data, field) {
        data = data || [];
        var len = data && data.length,
            lookup = {},
            values = [],
            indexedData = {},
            valuesLen = 0,
            lookupVal = null,
            val = null;

        if (!data || !len || !field) {
            return DataSeriesFieldIndex.create({
                field: field,
                values: [],
                lookup: {},
                indexedData: {}
            });
        }

        while (len--) {
            val = data[len][field];
            if (val === null || val === undefined) {
                continue;
            }

            lookupVal = lookup[val];
            if (!lookupVal) {
                lookup[val] = [len];
                indexedData[val] = [data[len]];
                values[valuesLen++] = val;
            } else {
                lookupVal[lookupVal.length] = len;
                (indexedData[val] || []).push(data[len]);
            }
        }

        var newIndex = DataSeriesFieldIndex.create({
            field: field,
            values: values,
            lookup: lookup,
            indexedData: indexedData
        });

        return newIndex;
    }
});

export default DataSeriesFieldIndex;
