import { action, computed } from "@ember/object";
import ControllerBase from "infegy-frontend/atlas/dashboard/content/base/controller";
import BrowserStore from "infegy-frontend/utils/browser-store";
import TopicsAPI from "infegy-frontend/models/query-api/topics";

export default class extends ControllerBase {
    apis = ["entities"];

    hiddenEntities = [];
    documentField = "universe";
    documentFieldOptions = [{label: "Universe", value: "universe"}, {label: "Posts", value: "documents"}];

    @BrowserStore.Attr("entities-cloud-display-count", { defaultValue: 50 }) displayTopicsCount;

    @computed("content.availableQueryFields.entities")
    get queryHasEntities() {
        return !!this.content.availableQueryFields.entities;
    }

    @computed("queries", "queries.[]", "queries.@each.updated", "entitiesViewType", "entitiesType")
    get entities() {
        var queries = this.queries || [],
            entities = queries.map((query, index) => {
                var entitiesDataSeries = Ember.get(query, "entities.dataSeries.data") || [];

                return {
                    query: query,
                    queryIndex: index,
                    entities: entitiesDataSeries.map(entity => {
                        return Object.assign({}, entity, {
                            _originalQuery: query,
                            _originalQueryIdx: index
                        });
                    })
                };
            });

        return entities;
    }

    @computed("queries", "queries.[]", "queries.@each.updated")
    get entityTypeOptions() {
        var queries = this.queries,
            entities = queries.map((query, index) => {
                return Ember.get(query, "entities.dataSeries.data") || [];
            }),
            headers = [{
                label: "All",
                value: "all"
            }];

        entities = [].concat.apply([], entities);

        if (entities) {
            entities.forEach(function(item, index, array) {
                var type = item["entityType"];

                if (type === "sportsteam") {
                    type = "sportsTeam";
                }

                if (!headers.findBy("value", type)) {
                    headers.push({
                        label: type,
                        value: type
                    });
                }
            });

            headers = headers.sortBy("value")
        }

        return headers;
    }

    @BrowserStore.Attr("entities-active-type", { defaultValue: "all"}) entitiesType;

    @BrowserStore.Attr("entities-active-view-type", {defaultValue: "table"}) entitiesViewType;

    @computed.equal("entitiesViewType", "linked") showLinked;
    @computed.or("showCloud", "showMultiCloud") showTopicCloud;
    @computed.equal("entitiesViewType", "cloud") showCloud;
    @computed.equal("entitiesViewType", "multi-cloud") showMultiCloud;
    @computed.equal("entitiesViewType", "table") showTable;
    @computed.equal("entitiesViewType", "node") showNodes;

    selectEntities(entities) {
        var entitiesType = this.entitiesType;

        if (entitiesType === "all") {
            return entities;
        }

        return entities.filter(function(sortedEntity) {
            return sortedEntity["entityType"] === entitiesType;
        });
    }

    @computed("queries", "queries.length", "queries.@each.updated", "entities", "entitiesViewType", "entitiesType")
    get combinedEntities() {
        var entities = this.entities || [],
            combinedEntities = [];

        entities.forEach((queryResult) => {
            var entitiesObj = this.selectEntities(queryResult.entities);

            entitiesObj.forEach(function(entity) {
                combinedEntities.push(entity);
            });
        });

        return combinedEntities;
    }

    @computed("queries", "queries.@each.updated", "queries.length", "combinedEntities", "hasQueryField", "collate")
    get collatedMatchingEntities() {
        var entities = this.combinedEntities,
            keyMap, collatedEntities = [];

        keyMap = entities.reduce((map, entity, idx) => {
            var key = entity.key || idx;

            if (!map[key]) {
                map[key] = [];
            }

            map[key].push(entity);

            return map;
        }, {});

        for (let key in keyMap) {
            if (keyMap.hasOwnProperty(key)) {
                var collatedEntity = TopicsAPI.combineMatchingTopics(keyMap[key]);
                collatedEntities.push(collatedEntity);
            }
        }

        return collatedEntities;
    }

    @computed("collatedMatchingEntities", "collatedMatchingEntities.[]","hiddenEntities.[]")
    get selectedEntitiesTopics() {
        var collatedMatchingEntities = this.collatedMatchingEntities,
            hiddenEntities = this.hiddenEntities;
        return collatedMatchingEntities.filter((entity) => {
                return !hiddenEntities.includes(entity.topic)
            }).sortBy("documents").reverse();
    }

    @computed("entities", "entities.[]", "entities.@each.entity", "hiddenEntities.[]")
    get entitiesQueryTopics() {
        // filter query entities to match the selected entity types and remove hidden entities
        let queries = this.entities.map((query) => {
            query.entities = query.entities.filter((entity) => {
                const isHidden = this.hiddenEntities.includes(entity.topic);
                const isActiveEntityType = this.entitiesType != "all" ? entity.entityType == this.entitiesType : true;
                return !isHidden && isActiveEntityType;
            });
            return query;
        });

        return queries;
    }

    @action
    entitiesTypeChanged(entitiesType) {
        if (this.entitiesType !== entitiesType) {
            this.set("entitiesType", entitiesType);
        }
    }

    @action
    entitiesViewTypeChanged(entitiesViewType) {
        this.set("entitiesViewType", entitiesViewType);
    }

    @action
    removeEntity(id) {
        var queries = this.queries;
        queries.forEach((query) => {
            var entities = Ember.get(query,"entities.dataSeries.data"),
                entity = entities.findBy("id", id);
            entities.removeObject(entity);
            this.hiddenEntities.pushObject(entity);
            Ember.set(query,"entities.dataSeries.data", entities);
            query.incrementProperty("updated");
        });
    }

    @action
    replaceEntity(topic_obj){
        var queries = this.queries;

        queries.forEach((query) => {
            var entities = Ember.get(query,`entities.dataSeries.data`);
            entities.pushObject(topic_obj);
            this.hiddenEntities.forEach((hidden_entity, index) => {
                if (hidden_entity.name === topic_obj.topic) {
                    this.hiddenEntities.removeAt(index,1);
                }
            })
            Ember.set(query,`entities.dataSeries.data`, entities);
            query.incrementProperty("updated");
        });
    }

    @action
    removeEntityByTopic(topic) {
        var queries = this.queries;
        queries.forEach((query) => {
            var entities = Ember.get(query,"entities.dataSeries.data");
            entities.forEach((entity, index) => {
                if (entity.name === topic) {
                    this.hiddenEntities.pushObject(entity);
                    entities.removeAt(index,1);
                }
            })
            Ember.set(query,`entities.dataSeries.data`, entities);
            query.incrementProperty("updated");
        });
    }

    @action
    documentFieldChanged(view) {
        this.set('documentField', view);
    }
}
