import Ember from "ember";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import AudienceSegment from "infegy-frontend/models/users/audience_segment";

var AudienceSegments = Ember.ArrayProxy.extend({
    content: Ember.A(),
    setup: Ember.on("init",function() {
        this.load();
    }),
    load() {
        AtlasAuth.Get("api/v3/watchlist").then((data) => {
            if (data.output) {
                var audienceSegments = [];

                data.output.map((item) => {
                    var audienceSegment = AudienceSegment.create();
                    audienceSegment.loadJSON(item);
                    audienceSegments.push(audienceSegment);
                });

                this.set("content", audienceSegments);
            }
        });
    }
});

export default AudienceSegments;
