import Ember from "ember";
import FieldDefinition from "infegy-frontend/models/fields/trend-field-definition";
import ChartColors from "infegy-frontend/utils/atlas-colors";

export default Ember.Component.extend({
    queries: null,
    query: Ember.computed.alias("queries.firstObject"),
    queryIndex: null,
    activeFields: [],

    maxFields: 6,

    canAdd: Ember.computed("maxFields", "activeFields", {
        get: function(key) {
            return (this.activeFields || []).length < this.maxFields;
        }
    }),

    canRemove: Ember.computed("activeFields", "activeFields.length", {
        get: function(key) {
            return (this.activeFields || []).length > 1;
        }
    }),

    innerActiveFields: Ember.computed("activeFields", "activeFields.[]",
        "query", "query.updated", "queries", "queries.[]","queries.@each.updated", {
        get: function(key) {
            var activeFields = this.activeFields;
            var querylessFields = activeFields && activeFields.filter(field => !field.get("query"));
            if (!activeFields || !activeFields.length) {
                this.send("addCustomField");
            } else if (querylessFields) {
                var changed = querylessFields.find(field => {
                    var newQuery = this.getQueryAt(field.get("queryIndex"));
                    if (newQuery) {
                        field.set("query", this.getQueryAt(field.get("queryIndex")));
                        return true;
                    }
                });
                if (changed) {
                    this.send("customFieldsChanged", activeFields);
                }
            }
            return activeFields;
        }
    }),

    availableColors: Ember.computed("activeFields", function () {
        var activeFields = this.activeFields || [],
            activeColorClasses = activeFields.mapBy("colorClass"),
            allChartColors = ChartColors.indexedColorClasses,
            availableColors = allChartColors.filter(function(colorClass) {
                return !activeColorClasses.includes(colorClass);
            });
        return availableColors.length ? availableColors : allChartColors;
    }),

    getQueryAt: function(queryIndex) {
        var query = this.query,
            queries = this.queries;
        if (queries && queryIndex < queries.length) {
            return queries.objectAt(queryIndex);
        } else if (query) {
            return query;
        }
    },

    customFieldsChanged: () => {},
    actions: {
        addCustomField: function() {
            var newField = FieldDefinition.create(),
                activeFields = this.activeFields || [],
                availableColors = this.availableColors || ["atlas-chart-1"];
            newField.loadJSON({
                    colorClass: availableColors[0] || "atlas-chart-1"
                });
            activeFields = activeFields.concat(newField);
            Ember.run.schedule('afterRender', () => {
                this.send("customFieldsChanged", activeFields);
            });
        },
        removeField: function(field) {
            var activeFields = this.activeFields || [];
            activeFields = activeFields.without(field);
            this.send("customFieldsChanged", activeFields);
        },
        fieldChanged: function() {
            var activeFields = (this.activeFields || []).slice();
            this.send("customFieldsChanged", activeFields);
        },
        customFieldsChanged: function(fields) {
            this.customFieldsChanged(fields);
        }
    }
});
