import Ember from 'ember';
import commonRegEx from "infegy-frontend/utils/common-regex";

var stripUnitsRegEx = /[^A-Za-z0-9\.]+|em|cm|mm|in|pt|px|pc|rem|vw|vh|vmin|vmax/g;

// Cache for the getComputedStyleFromClassName function below.
let cssProperyCache = {};

var CSSTools = {
    computed: {
        styleStringSafe: function(cssObjProperty, cssOptions) {
            var options = cssOptions || {};
            return Ember.computed(cssObjProperty, function () {
                return CSSTools.buildStringFromObjectSafe(this.cssObjProperty, options);
            });
        },
    },

    buildStringFromObject: function (styleObject, options) {
        if (Ember.isEmpty(styleObject)) {
            return "";
        }
        var _options = Object.assign({
                defaultUnits: "px"
            }, options),
            styleString = "";
        for (var name in styleObject) {
            if (Object.hasOwnProperty.apply(styleObject, [ name ]) && !Ember.isEmpty(styleObject[name])) {
                styleString += CSSTools.buildPropertyString(name, styleObject[name], _options.defaultUnits);
            }
        }
        return styleString;
    },
    buildStringFromObjectSafe: function (styleObject, options) {
        var styleString = CSSTools.buildStringFromObject(styleObject, options);
        return Ember.String.htmlSafe(styleString);
    },

    buildStringFromPaths: function(context, stylePathsObject, options) {
        if (Ember.isEmpty(stylePathsObject) || Ember.isEmpty(context)) {
            return "";
        }
        var styleObject = {};
        for (var name in stylePathsObject) {
            if (stylePathsObject.hasOwnProperty(name) &&
                    !Ember.isEmpty(stylePathsObject[name])) {
                var path = stylePathsObject[name],
                    value = Ember.get(context, path);
                if (!Ember.isEmpty(value)) {
                    styleObject[name] = value;
                }
            }
        }
        return CSSTools.buildStringFromObject(styleObject, options);
    },

    colorProperties: ['color', 'background-color', 'border-color'],

    unitProperties: ['background-position', 'background-size', 'border-width',
        'border-top', 'border-right', 'border-bottom', 'border-left',
        'border-top-width', 'border-right-width', 'border-bottom-width', 'border-left-width',
        'top', 'right', 'bottom', 'left', 'width', 'height',
        'font-size', 'line-height',
        'margin', 'margin-top', 'margin-bottom', 'margin-left', 'margin-right',
        'padding', 'padding-top', 'padding-bottom', 'padding-left', 'padding-right',
        'max-width', 'max-height', 'min-width', 'min-height',
        'outline-width', 'word-spacing'],

    buildPropertyString: function (property, value, defaultUnits) {
        if (!value || !property) {
            return "";
        }
        value = "" + value;
        var cssProp = commonRegEx.dasherize(property);
        if (defaultUnits && CSSTools.unitProperties.includes(cssProp)) {
            value = CSSTools.addMissingUnits(value, defaultUnits);
        }
        return [cssProp, ":", value, ";"].join("");
    },

    makeBackgroundGridObject: function (options) {
        var _options = Object.assign({
            backgroundColor: "transparent",
            gridColor: "#eef0f8",
            gridSize: "10"
        }, options);

        var gridGradientBase = [_options.gridColor, " 0px, ", _options.gridColor, " 1px, transparent 1px, transparent)"].join(""),
            gridGradient = ["linear-gradient(0deg, ", gridGradientBase, ", linear-gradient(90deg, ", gridGradientBase].join("");

        return {
            "background-color": options.backgroundColor,
            "background-image": gridGradient,
            "background-position": "0px 1px",
            "background-size": [options.gridSize, "px ", options.gridSize, "px"].join("")
        };
    },

    makeBackgroundGridString: function (options) {
        return CSSTools.buildPropertyString(CSSTools.makeBackgroundGridObject(options));
    },

    stripUnits: function (string) {
        return string.replace(stripUnitsRegEx, "");
    },

    addMissingUnits: function (string, defaultUnit) {
        return string.replace(/\s*([0-9\.]+)\s*(?!em|cm|mm|in|pt|px|pc|rem|vw|vh|vmin|vmax|deg|%)([^0-9\.]|$)/g,
            " $1" + defaultUnit + " ");
    },

    HSLToHex(h, s, l) {
        s = s / 100;
        l = l / 100;

        let c = (1 - Math.abs(2 * l - 1)) * s,
            x = c * (1 - Math.abs((h / 60) % 2 - 1)),
            m = l - c / 2,
            r = 0,
            g = 0,
            b = 0;

        if (0 <= h && h < 60) {
            r = c; g = x; b = 0;
        } else if (60 <= h && h < 120) {
            r = x; g = c; b = 0;
        } else if (120 <= h && h < 180) {
            r = 0; g = c; b = x;
        } else if (180 <= h && h < 240) {
            r = 0; g = x; b = c;
        } else if (240 <= h && h < 300) {
            r = x; g = 0; b = c;
        } else if (300 <= h && h < 360) {
            r = c; g = 0; b = x;
        }
        // Having obtained RGB, convert channels to hex
        r = Math.round((r + m) * 255).toString(16);
        g = Math.round((g + m) * 255).toString(16);
        b = Math.round((b + m) * 255).toString(16);

        // Prepend 0s, if necessary
        if (r.length === 1)
            r = "0" + r;
        if (g.length === 1)
            g = "0" + g;
        if (b.length === 1)
            b = "0" + b;

        return `#${r}${g}${b}`;
    },

    hexToHSL(H) {
        // Convert hex to RGB first
        let r = 0, g = 0, b = 0;
        if (H.length === 4) {
            r = "0x" + H[1] + H[1];
            g = "0x" + H[2] + H[2];
            b = "0x" + H[3] + H[3];
        } else if (H.length === 7) {
            r = "0x" + H[1] + H[2];
            g = "0x" + H[3] + H[4];
            b = "0x" + H[5] + H[6];
        }
        // Then to HSL
        r = r / 255;
        g = g / 255;
        b = b / 255;
        let cmin = Math.min(r, g, b),
            cmax = Math.max(r, g, b),
            delta = cmax - cmin,
            h = 0,
            s = 0,
            l = 0;

        if (delta === 0)
            h = 0;
        else if (cmax === r)
            h = ((g - b) / delta) % 6;
        else if (cmax === g)
            h = (b - r) / delta + 2;
        else
            h = (r - g) / delta + 4;

        h = Math.round(h * 60);

        if (h < 0)
            h += 360;

        l = (cmax + cmin) / 2;
        s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
        s = s * 100;
        l = l * 100;

        return { hue: h, saturation: s, lightness: l }
    },

    /** Assumes 6 character hex */
    hexToRGB(H) {
        H = H.replace('#','');
        var r = parseInt(H.substr(0,2), 16),
            g = parseInt(H.substr(2,2), 16),
            b = parseInt(H.substr(4,2), 16);
        return [ r, g, b ];
    },
    // based on LeaVerou's contrast-ratio: https://github.com/LeaVerou/contrast-ratio
    luminanace(r, g, b) {
        var a = [r, g, b].map(function (v) {
            v /= 255;
            return v <= 0.03928
                ? v / 12.92
                : Math.pow( (v + 0.055) / 1.055, 2.4 );
        });
        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    },
    contrast(hex1, hex2) {
        var rgb1 = CSSTools.hexToRGB(hex1),
            rgb2 = CSSTools.hexToRGB(hex2),
            { hue:hue1 } = CSSTools.hexToHSL(hex1),
            { hue:hue2 } = CSSTools.hexToHSL(hex2);
        var lowHue = Math.min(hue1,hue2),
            highHue = Math.max(hue1,hue2),
            hueDiff = Math.min(highHue - lowHue, 360 + lowHue - highHue),
            hueContrast = hueDiff / 360;
        var lumaContrast = (CSSTools.luminanace(rgb1[0], rgb1[1], rgb1[2]) + 0.05) / (CSSTools.luminanace(rgb2[0], rgb2[1], rgb2[2]) + 0.05);
        return (hueContrast + (lumaContrast/21)) / 2;
    },

    // This function returns the computed style for any css class, by adding,
    // inspecting, and removing an element from the DOM. It's not fast, but
    // caches results for repeat lookups in the file scoped cssProperyCache
    // property. It can be made a bit faster by supplying a DOM element to
    // the function - but be aware this will overwrite the class property
    // of that element.
    getComputedStyleFromClassName(className, domElement){
        let styleProperies = cssProperyCache[className],
            elem = domElement;
        if (!styleProperies) {
            if (!domElement) {
                elem = document.createElement("div");
                document.body.appendChild(elem);
            }
            elem.classList = className;
            styleProperies = {};
            const computedStyles = window.getComputedStyle(elem);
            for (var key of computedStyles){
                 styleProperies[key] = computedStyles[key];
            }
            cssProperyCache[className] = styleProperies;
            if (!domElement) {
                document.body.removeChild(elem);
            }
        }
        return styleProperies;
    },
};

export default CSSTools;
