import Ember from "ember";
import InfegyAppConfig from "infegy-frontend/config/infegy-app-config";
import helpStrings from "infegy-frontend/static_data/help-strings";

export default Ember.Component.extend({
    classNameBindings:[":atlas-help-text-container"],
    
    displayText: true,
    unstyled: false,
    helpId: "",

    setup: Ember.on("init",function(){
        var closedBoxes = JSON.parse(window.localStorage.getItem('atlas-help-boxes')) || [],
            helpId = this.helpId;
        
        if (closedBoxes.indexOf(helpId) > -1) {
            this.set('displayText', false);
        }
    }),

    helpInfo: Ember.computed("helpId",function(){
        var id = this.helpId || "";
        return Ember.get(helpStrings,id);
    }),

    helpText: Ember.computed.alias("helpInfo.text"),
    documentationLink: Ember.computed("helpInfo.documentation", function(){
        var link = this.get("helpInfo.documentation");
        if (link) {
            return `https://help.infegy.com/en/${link}`;
        }
    }),

    actions: {
        toggleDisplayText(){
            var displayText = this.displayText,
                closedBoxes = JSON.parse(window.localStorage.getItem('atlas-help-boxes')) || [],
                helpId = this.helpId;
            
            if(displayText) {
                // removing
                
                closedBoxes.push(helpId);
                window.localStorage.setItem('atlas-help-boxes', JSON.stringify(closedBoxes) );
            } else {
                // adding
                window.localStorage.setItem('atlas-help-boxes', JSON.stringify(closedBoxes.without(helpId)) );
            }
            this.toggleProperty("displayText");
        }
    }
});