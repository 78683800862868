import TrendsSegments from "infegy-frontend/static_data/trends-segments";
import Ember from "ember";

export default Ember.Component.extend({
    classNames: ["no-print"],

    queries: null,
    queryIndex: null,
    isCustomTrends: false,

    defaultColorClass: null,

    segmentTypes: "all",
    customSegmentDefinition: null,

    selectedSegment: null,
    selectedSubSegment: null,
    hasCustom: true,

    availableFields: [],

    activeFields: [],
    activeField: null,

    segmentDefinition: Ember.computed("segmentTypes", "customSegmentDefinition", function () {
        if (this.customSegmentDefinition) {
            return this.customSegmentDefinition;
        }

        var type = this.segmentTypes,
            segments = TrendsSegments[type];
        if (!segments || !segments.segmentOptions) {
            segments = TrendsSegments.all;
        }
        return segments;
    }),

    availableFieldsChanged: () => {},
    selectedSegmentChanged: () => {},
    selectedSubSegmentChanged: () => {},
    activeFieldChanged: () => {},
    titleChanged: () => {},
    activeFieldsChanged: () => {},
    toggleCustom: () => {},
    actions: {
        availableFieldsChanged: function(availableFields) {
            Ember.run.schedule("afterRender", () => {
                this.availableFieldsChanged(availableFields);
            });
        },
        segmentChanged: function(selection) {
            if (this.activeFields || this.availableFields) {
                this.activeFieldsChanged(null);
                this.availableFieldsChanged(null);
            }
            this.selectedSegmentChanged(selection);
        },
        subSegmentChanged: function(selection) {
            if (this.activeFields || this.availableFields) {
                this.activeFieldsChanged(null);
                this.availableFieldsChanged(null);
            }
            this.selectedSubSegmentChanged(selection);
        },
        activeFieldChanged: function(field) {
            this.activeFieldChanged(field);
        },
    }
});
