import Ember from "ember";
import CSSTools from "infegy-frontend/utils/css-tools";

export function styleStringSafe(params, namedArgs) {
    var cssObj = params[0],
        options = params[1] || {};
    return CSSTools.buildStringFromObjectSafe(cssObj, options);
}

export default Ember.Helper.helper(styleStringSafe);
