import Ember from "ember";
import TrendsInformation from "infegy-frontend/components/trends/trends-information/component";

export default TrendsInformation.extend({
    fieldDisplaySize: Ember.computed("fields", "fields.length", "isSingleDayQuery", function () {
        var numFields = this.get("fields.length") || 1;
        if (!this.isSingleDayQuery) {
            if (numFields === 1) {
                return "wide";
            } else if (numFields <= 3) {
                return "medium";
            }
        }
        return "small";
    }),

    evenColumnClass: Ember.computed("fields.length", function () {
        var fieldsLength = this.get('fields.length');
        if (fieldsLength === 1) {
            return "row";
        } else if (fieldsLength <= 3) {
            return "even-columns-3";
        }
        return "even-columns-6";
    }),

    fieldInfoComponents: Ember.computed("fields", "fields.[]",
        "fields.@each.summaryValue", "fieldDisplaySize", function () {
            var fields = this.fields || [],
                size = this.fieldDisplaySize,
                maxUnfilteredPrimaryValue = Math.max(...fields.map(r => (Ember.get(r, "unfilteredSummaryValue") || 0)));

            var fieldInfos = fields.map(function(field) {
                return {
                    size: size,
                    componentPath: "trends/trends-information/trends-information-detailed/trends-field-information-" + size,
                    field: field,
                    maxUnfilteredPrimaryValue: maxUnfilteredPrimaryValue
                };
            });
            return fieldInfos;
        }),
});
