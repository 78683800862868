import BaseAppConfig from 'infegy-frontend/config/base-app-config';
import ENV from 'infegy-frontend/config/environment';

// Environment based domains now set in config/environment.js
// This improves security since the domain is set at compile time, 
// so the other domains are not included in the build
BaseAppConfig.baseDomain = ENV.apiPaths.baseDomain;
BaseAppConfig.baseFrontendDomain = ENV.apiPaths.baseDomain;
BaseAppConfig.baseAPIDomain = `${ENV.apiPaths.baseDomain}api/v3/`;

BaseAppConfig.environment = ENV.environment;
BaseAppConfig.defaultDashboardPage = 'atlas.dashboard.content.overview';

export default BaseAppConfig;
