import Ember from "ember";

var flattenRecurse = function(result, cur, prop) {
    var newProp = prop.split(".").map(function(item) {
            return item.camelize();
        }).join("."),
        camelProp = newProp.camelize();
    if (Object(cur) !== cur) {
        result[camelProp] = cur;
    } else if (Array.isArray(cur)) {
        result[camelProp] = JSONMutation.flattenJSONArray(cur);
    } else {
        for (var p in cur) {
            if (cur.hasOwnProperty(p)) {
                flattenRecurse(result, cur[p], prop ? prop + "." + p : p);
            }
        }
    }
};

var JSONMutation = {
    flattenJSONArray: function(JSONArray) {
        if (!Ember.isArray(JSONArray)) {
            return null;
        }

        var new_results = JSONArray.map(function(item) {
            if (typeof(item) === "string") {
                return item;
            }
            var result = {};
            flattenRecurse(result, item, "");
            return result;
        });
        return new_results;
    },

    flattenJSONObject: function(JSONObject) {
        if (Ember.isEmpty(JSONObject)) {
            return null;
        }

        var result = {};
        flattenRecurse(result, JSONObject, "");
        return result;
    },

    flattenJSON: function(JSONData) {
        if (Ember.isEmpty(JSONData)) {
            return null;
        }
        if (Ember.isArray(JSONData)) {
            return this.flattenJSONArray(JSONData);
        }
        return this.flattenJSONObject(JSONData);
    }

};

export default JSONMutation;