import d3 from "d3";
import Ember from "ember";
import { computed, action } from '@ember/object';
import { ComparisonTableField, ComparisonTableFields } from "infegy-frontend/components/field-components/comparison-table/table-field";
import FieldDefinitionLoader from "infegy-frontend/models/field-definitions/loader";

export default class FieldRow extends Ember.Object {
    originalField = null;
    queryFields = null;
    queries = null;

    @computed("queries", "queries.@each.updated", "queryFields", "originalField")
    get isLoading() {
        let rowFields = (this.queryFields && this.queryFields.mapBy("field"));
        return rowFields && rowFields.find(field => !field || !field.isLoaded || !field.dataSeries);
    }

    @computed("isLoading", "queries", "queries.@each.updated", "queryFields", "originalField")
    get fieldMeta() {
        if (this.isLoading || !this.queryFields) {
            return;
        }
        let rowFields = this.queryFields.mapBy("field"),
            fieldRowJSON = this.originalField && this.originalField.toJSON(),
            combinedField = null,
            range = null,
            newFieldRow = null;

        combinedField = FieldDefinitionLoader.combineMultiQueryFields(rowFields);
        range = this.queryFields.reduce((topField, column) => {
            if (!topField.highestField.field || !topField.lowestField.field || !column.field) {
                return topField;
            }
            return {
                highestField: ((topField.highestField.field.scalarValue || 0) > (column.field.scalarValue || 0)) ? topField.highestField : column,
                lowestField: ((topField.lowestField.field.scalarValue || 0) <= (column.field.scalarValue || 0)) ? topField.lowestField : column
            }
        }, {
            highestField: this.queryFields[0],
            lowestField: this.queryFields[0]
        });

        newFieldRow = ComparisonTableField.create();
        newFieldRow.loadJSON(fieldRowJSON);
        newFieldRow.set("field", combinedField);

        return {
            combinedFieldRow: newFieldRow,
            range: range
        };
    }

    @computed("fieldMeta", "fieldMeta.range", "highestField", "lowestField")
    get rangeScale() {
        let max = this.get("highestField.field.scalarValue"),
            min = this.get("lowestField.field.scalarValue");

        if (Ember.isEmpty(min) || Ember.isEmpty(max)) {
            return;
        }

        return d3.scaleLinear()
          .domain([min, max])
          .range([0.1, 1.0]);
    }

    @computed.alias("fieldMeta.range.highestField") highestField;
    @computed.alias("fieldMeta.range.lowestField") lowestField;
    @computed.alias("fieldMeta.combinedFieldRow") combinedFieldRow;
}
