import JsonStore from "infegy-frontend/json-store/model";
import Prop from "infegy-frontend/json-store/properties/model";
import QualtricsSurveyQuestion from "infegy-frontend/models/qualtrics/survey_question";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";
import classic from "ember-classic-decorator";
import * as Sentry from "@sentry/browser";

@classic
export default class QualtricsSurvey extends JsonStore {
    @Prop.String() id;
    @Prop.String() type;
    @Prop.String() created;
    @Prop.String() modified;
    @Prop.Boolean() active;
    @Prop.Collection(QualtricsSurveyQuestion) questions;

    isLoading = false;

    async load(qualtricsToken, surveyId) {
        let response = null;
        this.set("isLoading", true);
        this.questions.clear();
        try {
            response = await AtlasAuth.Get(`api/v3/qualtrics-survey?qualtrics_token=${qualtricsToken}&survey_id=${surveyId}`);
        }
        catch(error) {
            this.set("isLoading", false);
            console.error(error.atlasErrorText);
            Sentry.captureMessage("Could not get Qualtrics Survey.");
            return;
        }

        this.loadJSON(response.output);
        this.set("isLoading", false);
    }

    async loadTextEntryQuestions(qualtricsToken) {
        let response = null;
        this.set("isLoading", true);
        this.questions.clear();
        try {
            response = await AtlasAuth.Get(`api/v3/qualtrics-survey/text-fields?qualtrics_token=${qualtricsToken}&survey_id=${this.id}`);
        }
        catch(error) {
            this.set("isLoading", false);
            console.error(error.atlasErrorText);
            Sentry.captureMessage("Could not get Qualtrics Text Entry Questions.");
            return;
        }

        this.questions.loadJSON(response.output);
        this.set("isLoading", false);
    }
}