import Ember from 'ember';
import JsonStore from 'infegy-frontend/json-store/model';
import Prop from 'infegy-frontend/json-store/properties/model';

var BuilderObject = JsonStore.extend({
    label: Prop.String(),
    value: Prop.String(),
    type: Prop.String(),
    iconType: Prop.String(),

    _fieldGroups: {
         api: {
             excludes: ["index"]
         }
     }
});

var BuilderDrillInObject = JsonStore.extend({
    label: Prop.String(),
    query: Prop.String(),
    type: Prop.String(), // Currently only "topic" - "headlines" previously an option but removed
});

export default JsonStore.extend({
    andItems: Prop.Collection(BuilderObject),
    orItems: Prop.Collection(BuilderObject),
    notItems: Prop.Collection(BuilderObject),

    drillInItems: Prop.Collection(BuilderDrillInObject),

    _fieldGroups: {
        
     },

    drillInQuery: Ember.computed("drillInItems", "drillInItems.[]", "drillInItems.@each.query", function () {
        return (this.drillInItems || []).reduce((memo, item) => {
            if (!memo) {
                return Ember.get(item, "query");
            }
            return memo + " AND " + Ember.get(item, "query");
        });
    }),

    titleString: Ember.computed("andItems", "andItems.[]", "andItems.@each.label",
        "orItems", "orItems.[]", "orItems.@each.label", function () {
        var ands = this.andItems,
            ors = this.orItems;
        if (ands && !Ember.isEmpty(ands)) {
            return ands.mapBy("label").join(" & ");
        }
        if (ors && !Ember.isEmpty(ors)) {
            return ors.mapBy("label").join(" or ");
        }
        return null;
    }),

    isBlank: Ember.computed("andItems.content","orItems.content",function(){
        return Ember.isEmpty(this.get("andItems.content")) && Ember.isEmpty(this.get("orItems.content"));
    }),

    isEqual(compare) {
        // shortcut
        if (this.andItems.length != compare.andItems.length
            || this.orItems.length != compare.orItems.length
            || this.notItems.length != compare.notItems.length) {
            return false;
        }
        // full check
        const keys = ["andItems", "orItems", "notItems"];
        for (const key of keys) {
            for (const item of this[key].content) {
                let found = false;
                for (const compareItem of compare[key].content) {
                    if (item.value === compareItem.value) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    return false;
                }
            }
        }
        return true;
    },
});
