import Ember from 'ember';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isArray } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import { actionArgument, argument } from 'infegy-frontend/decorators/arguments';
import { isEmpty } from '@ember/utils';


export default class SearchSelect extends Component {
    /** @typedef {Object} Option */
    @argument([]) options;
    @argument([]) filteredOptionsOverride;
    @argument([]) unfilteredOptions;

    @argument(20) itemsToDisplay;
    @argument(["label", "alternates"]) searchFields;
    @argument(false) readOnly;
    @argument("asc") sortDirection;
    @argument(false) titleCase;

    @argument('label') sortField;
    @argument("label") labelField;
    @argument("value") valueField;
    @argument(null) descriptionField;
    @argument(null) iconClassField;

    @argument(null) baseIconClass;
    @argument(false) mustMatchType;
    @argument("Unknown") unknownOptionLabel;
    @argument("") selectorClassNames;
    @argument("") listClassNames;
    @actionArgument searchStringChanged;

    @tracked activeIndex = 0;
    @tracked isFocused = false;
    @tracked innerSearchString = "";

    showList = false;
    preventFocusOut = false;

    /** @type {HTMLInputElement} */
    searchInput = null;

    get optionsNotEmpty(){
        return !isEmpty(this.options) || !isEmpty(this.filteredItems)|| !isEmpty(this.filteredOptionsOverride);
    }

    get listVisible(){
        return this.isFocused && this.optionsNotEmpty;
    }

    get innerSearchFields(){
        if(this.searchFields){
            if (isArray(this.searchFields)){
                return [this.labelField, ...this.searchFields];
            } else {
                return [this.labelField, this.searchFields];
            }
        }
        return [this.labelField];
    }

    get allOptions() {
        return [...(this.options || []), ...(this.unfilteredOptions || []), ...(this.filteredOptionsOverride || [])];
    }

    /** Abstract. Override to furhter filter items beyond the standard filtering
     * @param {Option[]} options list of options to be filtered
     * @returns {Option[]} filtered list of options
     */
    filterItems(options){
        // Abstract, override for subclass-specific filtering
        return options;
    }

    /** Helper function for comparing options objects for equality */
    compareItems(optA,optB){
        if (Ember.isNone(optA) || Ember.isNone(optB)){
            return false;
        }
        var valA = Ember.get(optA, this.valueField),
            valB = Ember.get(optB, this.valueField);
        return this.compareValues(valA,valB);
    }

    /** Helper function for comparing values for equality
     * Use this instead of direct comparison due to type differences
     */
    compareValues(valA,valB) {
        if(this.mustMatchType){
            return valA === valB;
        } else {
            return valA + '' === valB + '';
        }
    }

    get filteredItems() {
        if (!Ember.isEmpty(this.filteredOptionsOverride)) {
            return this.filteredOptionsOverride;
        }
        var searchTerm = (this.innerSearchString || "").toLowerCase(),
            searchRegEx = new RegExp(`(^|\\W)${searchTerm.replace(/[^a-z0-9]/gi, "\\$&")}`, "gi"),
            qualified = [];
        if (searchTerm.length > 0) {
            this.options.forEach((option)=> {
                var doesQualify = false;
                for (let searchField of this.innerSearchFields) {
                    var field = Ember.get(option, searchField);
                    if (typeof field === "object") {
                        if (Ember.isArray(field)) {
                            for (var idx = 0; idx < field.length; idx++) {
                                let testString = ((field[idx] || "") + '').toLowerCase();
                                if (testString.match(searchRegEx)) {
                                    doesQualify = true;
                                    Ember.set(option, "matched", field[idx]);
                                    break;
                                }
                            }
                        }
                    } else {
                        let testString = (field + '').toLowerCase();
                        if (testString.match(searchRegEx)) {
                            doesQualify = true;
                            Ember.set(option, "matched", field);
                            break;
                        }
                    }
                }
                if (doesQualify) {
                    qualified.push(option);
                }
            });
        } else {
            // make a copy of the options passed in, can be Ember Array, set, or other iterable
            if(this.options instanceof Ember.ArrayProxy){
                qualified = this.options.toArray();
            } else {
                qualified = Array.from(this.options || []);
            }
        }

        qualified = this.filterItems(qualified);

        if (Ember.isArray(this.unfilteredOptions)) {
            qualified = qualified.concat(this.unfilteredOptions);
        }

        //this.set("activeIndex",0)
        this.activeIndex;

        if (this.sortField) {
            qualified = qualified.sortBy(this.sortField);
            if (this.sortDirection === "desc") qualified.reverse();
        }

        return qualified;
    }

    get searchString() { return this.innerSearchString; }
    set searchString(value) {
        this.searchStringChanged && this.searchStringChanged(value);
        this.innerSearchString = value;
    }

    @action
    searchFocusOut() {
        this.searchString = "";
        this.isFocused = false;
    }

    @action
    searchFocusIn() {
        Ember.run.later(()=>{
            this.focusedOption = null;
            this.isFocused = true;
        });
    }

    @action
    click() {
        if (this.searchInput) {
            this.searchInput.focus();
            this.searchFocusIn();
        }
    }

    @action
    inputClick() {
        if (!this.isFocused) {
            this.isFocused = true;
        }
    }
}
