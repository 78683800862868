import Ember from "ember";
import Component from "@ember/component";
import { action, computed } from "@ember/object";

import Themes from "infegy-frontend/static_data/themes";
import SharedResources from "infegy-frontend/models/users/shared_resources";

export default class DictionariesListItem extends Component {
    classNameBindings = [":dictionary-list-item"];
    user = null;
    dictionary = null;
    editDictionary = null;
    declineSharingId = 0;
    deletingDictionary = null;
    isDeleting = false;


    detailsLoading = false;
    showDetails = false;
    showDeclineSharingError = false;
    sharedResources = SharedResources.create();

    @computed("dictionary.entries")
    get customThemes() {
        var entries = this.dictionary?.entries ?? [],
            themes = [];

        entries.forEach((entry) => {
            if (!Themes.universalThemes.includes(entry.theme) &&
                !themes.includes(entry.theme) &&
                !Ember.isEmpty(entry.theme)) {
                themes.push(entry.theme);
            }
        });

        return themes;
    }

    @computed.alias("dictionary.isShared") isShared;

    @computed("dictionary", "deletingDictionary")
    get isUserDeleting() {
        return this.dictionary === this.deletingDictionary;
    }

    // event handlers
    confirmDelete(dictionary) {};
    sharingDeclined(dictionary) {};

    @action
    onBeginDeleteDictionary(dictionary) {
        this.set("deletingDictionary", dictionary);
    }

    @action
    onCancelDelete() {
        this.set("deletingDictionary", null);
    }

    @action
    onConfirmDelete(dictionary) {
        this.confirmDelete(dictionary);
    }

    @action
    onSharingDeclined() {
        this.sharingDeclined(this.dictionary);
    }

    @action
    onToggleShareDetails(dictionary) {
        if (Ember.isEmpty(this.sharedResources)) {
            this.sharedResources.getForResource("dictionary", this.dictionary.id);
        }

        this.toggleProperty("showSharingDetails");
    }

    @action
    onToggleDetails(){
        if (this.showDetails) {
            this.set("showDetails", false);
        } else {
            this.set("detailsLoading", true);
            this.dictionary.loadEntries().then(()=>{
                this.setProperties({
                    detailsLoading: false,
                    showDetails: true
                })
            });
        }
    }
}
