import Ember from "ember";
import CSSTools from "infegy-frontend/utils/css-tools";
import d3 from "d3";
import D3Cloud from "d3-cloud";
import {MouseInteractionWrapper}  from "infegy-frontend/components/charting/mouse-interaction-mixin/component";
import { action, computed } from '@ember/object';
import {urlFormat} from "infegy-frontend/helpers/url-format";

export default class TopicCloud extends MouseInteractionWrapper(Ember.Component) {
    topicData = [];
    maxTopics = null;
    aspectRatio= 3;
    topicsType = null;
    topicField = "word";
    keyField = "key";
    sizeField = "appearances";
    colorField = "sentiment";

    /**  @type {("clusterId" | "relatedTopics")} */
    highlightField = "clusterId";

    fixedColor = null;
    colorFunction = null;

    textFont = "Roboto";
    fontWeight = "400";

    hoveredTopic = null;
    selectedTopic = null;

    multiSelectedTopics = null;
    multiSelectedTopicKeys = null;

    hoveredTopicKey = null;
    selectedTopicKey = null;

    layoutTopics = [];

    @computed("keyField", "topicField") 
    get innerKeyField () {
        return this.keyField || this.topicField || "topic";
    }

    @computed("hoveredTopic", "hoveredTopicKey", "innerKeyField") 
    get ownHoveredTopicKey() {
        var keyField = this.innerKeyField,
            hoveredTopic = this.hoveredTopic;
        return this.hoveredTopicKey || (hoveredTopic && Ember.get(hoveredTopic, keyField));
    }

    @computed("ownHoveredTopicKey", "keyedTopicMap") 
    get ownHoveredTopic() {
        var topicKey = this.ownHoveredTopicKey;
        return topicKey && this.keyedTopicMap[topicKey];
    }

    @computed("displayTopics") 
    get clusterMap(){
        const clusterMap = {};
        this.displayTopics.forEach(topic => {
            clusterMap[topic.clusterId] = clusterMap[topic.clusterId] ?? [];
            clusterMap[topic.clusterId].push(topic);
        });
        return clusterMap;
    }

    @computed("displayTopics") 
    get relatedMap(){
        const relatedMap = {};
        this.displayTopics.forEach(topic => {
            relatedMap[topic.key] = relatedMap[topic.key] ?? [];
            topic.relatedTopics.forEach((relatedTopic)=>{
                relatedMap[topic.key].push(this.displayTopics.findBy("key",relatedTopic.key));
            });
        });
        return relatedMap;
    }

    @computed.notEmpty("relatedTopics") hasRelatedTopics;

    @computed("ownSelectedTopic", "ownHoveredTopic") 
    get relatedTopics(){
        // Prioritize the selected topic over the hovered topic for highlighting related topics.
        let sourceTopic = this.ownSelectedTopic ?? this.ownHoveredTopic;
        if(!sourceTopic){
            return [];
        }

        if(this.highlightField === "clusterId") {
            return this.clusterMap[sourceTopic.clusterId] ?? [];
        } else {
            return this.relatedMap[sourceTopic.key] ?? [];
        }
    }

    @computed("selectedTopic", "selectedTopicKey", "innerKeyField") 
    get ownSelectedTopicKey(){
        var keyField = this.innerKeyField,
            selectedTopic = this.selectedTopic;
        return this.selectedTopicKey || (selectedTopic && Ember.get(selectedTopic, keyField));
    }

    @computed("ownSelectedTopicKey", "keyedTopicMap") 
    get ownSelectedTopic(){
        var topicKey = this.ownSelectedTopicKey;
        return topicKey && this.keyedTopicMap[topicKey];
    }

    @computed("multiSelectedTopics.[]", "multiSelectedTopicKeys.[]", "innerKeyField") 
    get ownMultiSelectedTopicKeys(){
        var keyField = this.innerKeyField,
            multiSelectedTopics = this.multiSelectedTopics || [];
        return this.multiSelectedTopicKeys || multiSelectedTopics.map(topic => {
            return topic && Ember.get(topic, keyField);
        });
    }

    @computed("ownMultiSelectedTopicKeys.[]", "keyedTopicMap") 
    get ownMultiSelectedTopics() {
        var topicKeys = this.ownMultiSelectedTopicKeys || [],
            keyedTopicMap = this.keyedTopicMap;
        return keyedTopicMap && topicKeys.map(topicKey => {
            return topicKey && keyedTopicMap[topicKey];
        });
    }

    @computed("hoveredTopic", "selectedTopic", "ownMultiSelectedTopics.[]") 
    get isFaded() {
        return this.hoveredTopic || this.selectedTopic || this.get("ownMultiSelectedTopics.length");
    }

    @computed("colorFunction", "fixedColor") 
    get colorFunctionFinal() {
        var colorFunction = this.colorFunction,
            fixedColor = this.fixedColor;
        if (typeof(colorFunction) !== "function" || fixedColor) {
            colorFunction = () => {return fixedColor || "red";};
        }
        return colorFunction;
    }

    @computed("topicData", "sizeField") 
    get sizeRange() {
        var data = this.topicData || [],
            sizeField = this.sizeField || "size",
            values = data.mapBy(sizeField);
        return {
            min: Math.min.apply(null, values),
            max: Math.max.apply(null, values)
        };
    }

    @computed("sizeRange.min", "sizeRange.max") 
    get sizeScale() {
        var minSize = this.get("sizeRange.min"),
            maxSize = this.get("sizeRange.max");
        return d3.scaleLinear()
            .clamp(true)
            .domain([-Infinity, minSize, maxSize, Infinity])
            .range([4, 4, 28, 28]);
    }

    @computed("topicData", "topicData.[]", "sizeField", "maxTopics") 
    get topicDataCopy() {
        var topicData = this.topicData || [],
            sizeField = this.sizeField || "size",
            maxTopics = this.maxTopics,
            topicDataCopy = topicData.map(row => {
                return Object.assign({}, row);
            });

        if (maxTopics) {
            topicDataCopy = topicDataCopy.sortBy(sizeField).reverse().slice(0, maxTopics);
        }

        return topicDataCopy;
    }

    @computed("topicDataCopy", "sizeScale", "aspectRatio", "textFont", "fontWeight", "topicField", "sizeField")
    get cloudLayout() {
        var topicData = this.topicDataCopy,
            topicField = this.topicField,
            sizeField = this.sizeField,
            sizeScale = this.sizeScale,
            aspectRatio = this.aspectRatio,
            fontWeight = this.fontWeight,
            textFont = this.textFont;

        if (!Ember.isArray(topicData)) {
            return [];
        }

        // The following is a workaround for this D3 library that will
        // cause it to have a repeatable layout on page refresh, a highly
        // requested feature before we had it.
        var seed = 1;
        var pseudoTrigRandom = function() {
            var x = Math.sin(seed++) * 10000;
            var val = (x - Math.floor(x)) * 0.01;
            return val ;
        };
        var baseSize = 6000,
            cloud = D3Cloud()
                .size([baseSize * aspectRatio, baseSize])
                .text(function(d) { return Ember.get(d, topicField); })
                .random(pseudoTrigRandom)
                .timeInterval(10)
                .rotate(0)
                .padding(1)
                .fontWeight(fontWeight)
                .font(textFont)
                .fontSize(function(d) {
                    return sizeScale(Ember.get(d, sizeField) || 0);
                })
                .on("end", (layoutTopics) => {
                    Ember.run.debounce(this, function () {
                        if (this && !this.isDestroyed && !this.isDestroying) {
                            this.send("setDraw", layoutTopics);
                        }
                    }, 150);
                })
                .words(topicData);
                Ember.run.debounce(this, function () {
                    if (this && !this.isDestroyed && !this.isDestroying) {
                        cloud.start();
                    }
                }, 100);
        return cloud;
    }

    @computed("layoutTopics", "cloudLayout", "topicField", "textFont", "colorFunctionFinal", "colorField")
    get displayTopics() {
        var layout = this.cloudLayout,
            textFont = this.textFont,
            colorField = this.colorField,
            colorFunction = this.colorFunctionFinal,
            layoutTopics = this.layoutTopics,
            topicsType = this.topicsType;
        if (layout) {
            layoutTopics = layoutTopics.map(function (word) {
                var x = Ember.get(word, "x"),
                    y = Ember.get(word, "y"),
                    width = Ember.get(word, "width"),
                    fontSize = Ember.get(word, "size"),
                    text = Ember.get(word, "text");
                // If no color field is set, pass the object to the user to process
                let colorValue = Ember.isEmpty(colorField) ? word : Ember.get(word, colorField) || 0;
                    var style = CSSTools.buildStringFromObject({
                            fill: colorFunction(colorValue),
                            fontSize: fontSize,
                            fontFamily: textFont,
                        }).htmlSafe();
                    if (topicsType === 'links') {
                        return Object.assign({}, word, {
                            left: x - width * 0.25,
                            right: x + width * 0.25,
                            top: y - (fontSize * 0.75),
                            bottom: y + (fontSize * 0.25),
                            text: urlFormat(text, 15, 20),
                            style: style
                        });
                    } else {
                        return Object.assign({}, word, {
                            left: x - width * 0.5,
                            right: x + width * 0.5,
                            top: y - (fontSize * 0.75),
                            bottom: y + (fontSize * 0.25),
                            style: style
                        });
                    }
            });
        }
        return layoutTopics;
    }

    @computed("innerKeyField", "displayTopics")
    get keyedTopicMap() {
        var keyField = this.innerKeyField;
        return (this.displayTopics || []).reduce((memo, topic) => {
            var key = Ember.get(topic, keyField);
            if (key) {
                memo[key] = topic;
            }
            return memo;
        }, {});
    }

    @computed("displayTopics", "displayTopics.[]")
    get fullViewBox() {
        var displayTopics = this.displayTopics,
            xMin = Math.min.apply(null, displayTopics.mapBy("left")),
            xMax = Math.max.apply(null, displayTopics.mapBy("right")),
            yMin = Math.min.apply(null, displayTopics.mapBy("top")),
            yMax = Math.max.apply(null, displayTopics.mapBy("bottom"));
        return {
            x: parseInt(xMin) || 1,
            y: parseInt(yMin) || 1,
            w: parseInt(xMax - xMin) || 1,
            h: parseInt(yMax - yMin) || 1
        };
    }

    click() {
        this.send("topicClicked", null);
    }

    onMouseEnter() {
        this.send("topicHovered", null);
    }

    topicHovered = () => {};
    topicSelected = () => {};
    @action
    setDraw(layoutTopics) {
        Ember.run.scheduleOnce("afterRender", this, function () {
            if (this && !this.isDestroyed && !this.isDestroying) {
                this.set("layoutTopics", layoutTopics);
            }
        });
    }
    @action
    topicHovered(topic) {
        if (this.hoveredTopic !== topic) {
            this.topicHovered(topic);
        }
    }
    @action
    topicClicked(topic) {
        var current = this.selectedTopic;
        this.topicSelected(topic === current ? null : topic);
    }
}
