import Ember from "ember";

import QueryAPIs from "infegy-frontend/models/queries/query_apis";

function parseQueryString(queryString){
    var parsed = queryString.replace(/[\^/~][0-9]+/g, " ");
    parsed = parsed.replace(/[\^"()|~<>$/#@]+/g, " ");
    parsed = parsed.replace(/\s+/g, " ").trim();
    return parsed.split(/\s+/);
}
export default {
    queryToTitle: function(queryString, entityQueryParts, customDatasetParts, sourceQuery, maxLen, minLen) {
        maxLen = maxLen || 50;
        minLen = minLen || 0;

        if (Ember.isEmpty(queryString) && Ember.isEmpty(entityQueryParts) && Ember.isEmpty(customDatasetParts) && Ember.isEmpty(sourceQuery)) {
            return "New Query";
        }
        var pieces = [], newPieces;
        if (!Ember.isEmpty(entityQueryParts)){
            newPieces = entityQueryParts.filterBy("label").mapBy("label");
            if (!Ember.isEmpty(newPieces)) {
                pieces = pieces.concat(newPieces);
            }
        }
        if (!Ember.isEmpty(customDatasetParts)){
            newPieces = customDatasetParts.filterBy("label").mapBy("label");
            if (!Ember.isEmpty(newPieces)) {
                if (!Ember.isEmpty(pieces)) {
                    pieces.push("&");
                }
                pieces = pieces.concat(newPieces);
            }
        }

        if (!Ember.isEmpty(queryString)){
            newPieces = parseQueryString(queryString);
            if (!Ember.isEmpty(newPieces)) {
                if (!Ember.isEmpty(pieces)) {
                    pieces.push("&");
                }
                pieces = pieces.concat(newPieces);
            }
        }

        // Only use source query if nothing else is available
        if(Ember.isEmpty(pieces) && !!sourceQuery){
            pieces = pieces.concat(parseQueryString(sourceQuery));
        }

        var keywords = ["NOT", "OR", "AND", "WITHIN", "&"],
            dropwords = ["WITHIN"];

        var wordMap = {},
            resultLen = -1,
            lastResultLen = -1,
            upperWord = "";
        var words = pieces.filter(function(word) {
                if (!word) return false;
                lastResultLen = resultLen;
                resultLen += word.length + 1;
                upperWord = word.toUpperCase();
                if (resultLen > maxLen) {
                    if (lastResultLen >= minLen) {
                        return false;
                    }
                } else if (dropwords.includes(word)) {
                    return false;
                } else if (keywords.includes(upperWord)) {
                    if (resultLen > maxLen && lastResultLen < maxLen) {
                        resultLen = lastResultLen;
                        return false;
                    }
                    return true;
                } else if(upperWord in wordMap) {
                    return false;
                }
                wordMap[upperWord] = true;
                return true;
            }).reduce((newArray, word, index, wordArray)=>{
                var previousWord = newArray[newArray.length - 1];
                if(index === wordArray.length - 1 && keywords.includes(word)) {
                    return newArray;
                }
                word = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                if (!previousWord || (previousWord !== word && previousWord.replace(/['|s]+$/, "") !== word.replace(/['|s]+$/, ""))) {
                    newArray.push(word);
                }
                return newArray;
            },[]);

        var result = words.join(" ");
        result = result.replace(/[\^/~][0-9]+/g, " ");
        result = result.replace(/[\^"()|~<>$/#@]+/g, " ");
        result = result.replace(/\s+/g, " ").trim();
        result = result.replace(/\s+(Not$|And$|Or$|Near$|Within$|\&$)/gi, "");

        return result || "New Query";
    },

    getCombinedQueryDates: function(queries){
        let startDate = null,
            endDate = null,
            minStart = Infinity,
            maxEnd = 0;
        queries.forEach(function(query) {
            let queryInfo = query.get("queryInfo"),
                query_start = queryInfo.get("startDate"),
                query_end = queryInfo.get("endDate"),
                query_start_timestamp = query_start.get("timestamp"),
                query_end_timestamp = query_end.get("timestamp");

            if(minStart > query_start_timestamp) {
                startDate = query_start.toJSON();
                minStart = query_start_timestamp;
            }

            if(maxEnd < query_end_timestamp) {
                endDate = query_end.toJSON();
                maxEnd = query_end_timestamp;
            }
        });

        return {
            startDate,
            endDate
        };
    },

    getQuerySafeString: function(input){
        var newtext = input.replace(/[\n\r\t\0]/g, "");

        newtext = newtext.replace(/[\u201C\u201D\u201E\u201F\u2033\u2036]/g, '"');
        newtext = newtext.replace(/[\u2018\u2019\u201A\u201B\u2032\u2035]/g, "'");
        return newtext;
    },

    // inputs are query filters
    checkIfQueriesMatch: function(queryA, queryB){
        const queryBuilderDetailMatches = queryA.queryBuilderDetail.isEqual(queryB.queryBuilderDetail);
        if (queryBuilderDetailMatches === false) {
            return false;
        }
        var matchFields = ["queryString", "entityQuery", "sourceQueryString", "title", "customDatasets", "isSnippet"];
        return matchFields.reduce((doMatch, field) => {
            return doMatch && (Ember.get(queryA, field) || Ember.get(queryA, field.decamelize()) || '') === (Ember.get(queryB, field) || Ember.get(queryB, field.decamelize()) || '');
        }, true);
    },

    addApiControllers(query) {
        QueryAPIs.forEach((apiInfo) => {
            query.set(apiInfo.name + "APIController", apiInfo.controller.create({
                queries: query,
                apiName: apiInfo.name,
                apiPath: apiInfo.apiPath || null
            }));
        });
    },

    getAvailableQueryFields(queries) {
        let atLeastOne = (filterFn, params) => {
            for (const query of queries) {
                const match = filterFn(query, params);
                if (match)
                    return true;
            }
            return false;
        };
        const isCustomDataset = (query) => {
            return query.hasCustomDataset;
        };
        const hasQueryFields = (query, filterIds) => {
            const found = query.get("datasetQueryInfo.filters")?.find((filter => {
                return filterIds.includes(filter.id);
            }));
            return !!found; // make it a boolean
        };
        const hasAvailableQueryFields = (query, filterIds) => {
            const found = query.get("datasetQueryInfo.available_query_operator_fields")?.find((filter => {
                return filterIds.includes(filter.id);
            }));
            return !!found; // make it a boolean
        }
        const result = {
            channels: atLeastOne(hasQueryFields, ["channels"]),
            engagements: !atLeastOne(isCustomDataset),
            entities: atLeastOne(hasAvailableQueryFields, ["entities"]),
            demographics: atLeastOne(hasQueryFields, ["gender"]),
            gender: atLeastOne(hasQueryFields, ["gender"]),
            geography: atLeastOne(hasQueryFields, ["country", "us_state", "us_dma", "ca_province"]),
            hashtags: !atLeastOne(isCustomDataset),
            interests: atLeastOne(hasQueryFields, ["taxonomies"]),
            language: atLeastOne(hasQueryFields, ["language"]),
            mentions: !atLeastOne(isCustomDataset),
            sourceBios: !atLeastOne(isCustomDataset)
        };
        return result;
    }
};
