import Ember from "ember";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";

export default Ember.Controller.extend({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    errorMessage: null,
    errorClass: null,
    submitDisabled: true,
    serverMessage: null,
    validPassword: null,

    application: Ember.inject.controller(),
    user: Ember.computed.alias("application.user"),

    actions: {
        focusOutCurrentPassword: function () {
            this.currentPassword;
            this.set('serverMessage', null);
        },
        focusOutNewPassword: function () {
            var newPassword = this.newPassword ?? "";
        },
        focusOutConfirmPassword: function () {
            var newPassword = this.newPassword ?? "",
                confirmPassword = this.confirmPassword ?? "";

            if(this.validPassword) {
                if(newPassword !== confirmPassword) {
                    this.setProperties({
                        'errorMessage': "Your confirmation did not match your New Password.",
                        'submitDisabled': true,
                        'errorClass': 'alert-danger',
                        'serverMessage': null,
                        'validPassword': false
                    });
                } else if (!newPassword){
                    this.setProperties({
                        'errorMessage': "A New Password is required to Submit this form.",
                        'submitDisabled': true,
                        'errorClass': 'alert-warning',
                        'validPassword': false
                    });
                } else {
                    this.setProperties({
                        'errorMessage': null,
                        'submitDisabled': false,
                        'errorClass': null,
                        'serverMessage': null,
                        'validPassword': true
                    });
                }
            }
        },
        changePassword: function () {
            var currentPassword = this.currentPassword ?? "",
                newPassword = this.newPassword ?? "",
                confirmPassword = this.confirmPassword ?? "";

            if (!this.get('user.id')) {
                console.warn('Unable to access user ID.');
                return;
            }

            if(newPassword !== confirmPassword){
                this.setProperties({
                    'errorClass': 'alert-danger',
                    'serverMessage': 'Passwords must match.'
                });
            }

            AtlasAuth.Put({
                url: "api/v3/user",
                data: {
                    old_password: currentPassword,
                    new_password: this.newPassword,
                    password_reset: true
                }
            })
            .then((message) => {
                this.setProperties({
                    'errorMessage': null,
                    'submittable': null,
                    'errorClass': 'alert-success',
                    'currentPassword': null,
                    'newPassword': null,
                    'confirmPassword': null,
                    'serverMessage': "Password Successfully Changed."
                });
            })
            .catch((message) => {
                this.setProperties({
                    'errorClass': 'alert-danger',
                    'serverMessage': "There was a problem saving your password."
                });
            });
        }
    }
});
