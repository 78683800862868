import Ember from "ember";
export default Ember.Component.extend({
    showWindow: false,
    label: null,
    options: null,
    alignRight: false,
    actions: {
        close(){
            this.set("showWindow", false);
        },
        itemClicked(option) {
            if (option.type === "action" && option.action) {
                option.action();
            } else if (option.type === "url") {
                window.open(`${option.link}`, "_blank");
                return;
            }
            this.set("showWindow", false);
        },
        toggle() {
            this.toggleProperty('showWindow');
        },
    },
});
