import Ember from 'ember';

export function firstWord(params, namedArgs) {
    if (!params[0] || !params[0].replace) {
        return params[0];
    }

    var values = params[0].replace(/([A-Z])/g, ' $1')
        .replace(/_/g, ' ')
        .replace(/^./g, function(str) {
            return str.toUpperCase(); 
        }).replace(/(\s+.)/g, function(str) {
            return str.toUpperCase(); 
        }).replace(/^\s\s*/, '')
        .replace(/\s\s*$/, '');


    values = values.split(" ");
    return values[0];
}

export default Ember.Helper.helper(firstWord);
