import Ember from "ember";
import { action, computed } from '@ember/object';
import { MouseInteractionWrapper } from "infegy-frontend/components/charting/mouse-interaction-mixin/component";

/*
    Simple tooltip class for displaying text in a box below the content when the content is hovered.
    
    This is intended for icon buttons, as this is an established pattern throughout the app,
    using this component allows the tooltip content to remain within the template
    as opposed to being stored in the stylesheet.

    Styles: 
        Neutral: icon is gray, hover is infegy blue
        Positive: Icon and hover are green
        Negative: Icon and hover are red
        Info: Icon and hover are Infegy Blue
*/
export default class extends MouseInteractionWrapper(Ember.Component) {
    classNameBindings = [":icon-button-tooltip", "styleClassName"];
    
    onClick = ()=>{};
    
    showTooltip = false;
    tooltipContent = null;
    tooltipClass = null;

    iconClass = "ii-pencil";

    style = "neutral";
    @computed("style")
    get styleClassName(){
        return Ember.String.htmlSafe(`icon-button-tooltip-${this.style}`);
    }

    hoverX = null;

    onMouseEnter(event) {
        this.setProperties({
            showTooltip: true,
            hoverX: event.clientX
        });
    }

    onMouseLeave(){
        this.set("showTooltip", false);
    }

    click(){
        this.onClick();
    }

    @computed("hoverX")
    get directionalClass() {
        var hoverX = this.hoverX,
            className = "";
        
        if (hoverX > (window.innerWidth / 2.0)) {
            className = "right";
        } else {
            className = "left";
        }

        return Ember.String.htmlSafe(className);
    }

    @action
    leaveIcon(){
        this.set("showTooltip", false);
    }
}