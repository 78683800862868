import Ember from "ember";
import { computed } from '@ember/object';
import classic from "ember-classic-decorator";
import JsonStore from 'infegy-frontend/json-store/model';
import QueryRelativeDate from "infegy-frontend/models/queries/query_relative_date";
import QueryFixedDate from "infegy-frontend/models/queries/query_fixed_date";

@classic
export default class extends JsonStore {
    fixed = null;
    relative = null;
    isRelative = false;
    @computed.not("isRelative") isNotRelative;

    constructor(){
        super(...arguments);
        this.set("fixed", QueryFixedDate.create());
        this.set("relative", QueryRelativeDate.create());
    }

    limitValue(earliestDate) {
        var fixed = this.fixed,
            relative = this.relative;
        if (fixed) {
            fixed.limitValue(earliestDate);
        }
        if (relative) {
            relative.limitValue(earliestDate);
        }
    }

    @computed("fixed.dateObj", "isRelative", "fixed.timestamp", "relative.timestamp")
    get timestamp() {
        if (this.isRelative) {
            return this.get("relative.timestamp");
        } else {
            return this.get("fixed.timestamp");
        }
    }

    @computed('isRelative')
    get dateString() {
      if (this.isRelative) {
        return this.relative.string;
      }
      return this.fixed.string;
    }

    toAPIString(isEndDate) {
        if (!this) {
            return null;
        }
        if (this.isRelative) {
            return this.get("relative.string");
        }
        return this.fixed.toISOString(isEndDate);
    }

    toJSON() {
        if (!this) {
            return null;
        }
        if (this.isRelative) {
            return this.get("relative.string");
        }
        return this.get("fixed.string");
    }

    loadJSON(string) {
        if (typeof(string) === "object" && string.toISOString) {
            string = string.toISOString();
        }
        if (!string || !string.length) {
            this.set("isActive", false);
            string = "";
        }
        this.set("isActive", true);

        var parts = string.trim().toLowerCase().split(/[ ,\/_\-]+/);

        if (parts.length <= 2) {
            this.set("isRelative", true);
            this.set("relative.string", string);
        } else if (parts.length > 2) {
            let lastChar = parts[2][parts[2].length -1];
            if (parts[2].length && (/[a-z]+/.test(parts[2]) && lastChar !== "z" && lastChar !== "Z")) {
                this.set("isRelative", true);
                this.set("relative.string", string);
            } else {
                this.set("isRelative", false);
                this.set("fixed.string", string);
            }
        }
        this.set("isDirty", false);
    }
}
