import JsonStore from 'infegy-frontend/json-store/model';
import Prop from 'infegy-frontend/json-store/properties/model';
import QueryFilters from "infegy-frontend/models/queries/query_filters";
import AtlasAuth from "infegy-frontend/utils/atlas-auth";

export default class Filterset extends JsonStore {
    @Prop.Attr() id;
    @Prop.String() title;
    @Prop.Object(QueryFilters) queryInfo;
    @Prop.Attr() createdOn;
    @Prop.Attr() updatedOn;
    @Prop.Attr() isDeleted;

    loadJSON(jsonData, options) {
        if (jsonData.hasOwnProperty("query_info") && typeof(jsonData.query_info) === "string") {
            jsonData.query_info = JSON.parse(jsonData.query_info);
        }
        super.loadJSON(jsonData, options);
    }

    toJSON(options) {
        // Filtersets should only save a subset of `queryInfo` fields.  If this 
        // list of fields needs to change, update the `_fieldGroups.filtersets`.
        let jsonData = super.toJSON({fieldGroups: "filterset"});
        return jsonData;
    }

    /**
     * @param {Object} properties 
     * @param {String} properties.title The title of the filterset.
     * @param {QueryFilters} properties.queryInfo The queryInfo of the filterset.
     */
    async save(properties={}) {
        let data = {
            title: properties.title ? properties.title : this.title,
            query_info: properties.queryInfo ? properties.queryInfo.toJSON({fieldGroups: "filterset"}) : this.queryInfo.toJSON({fieldGroups: "filterset"})
        };

        let response = null;
        if (!this.id) {
            response = await AtlasAuth.Post({
                url: "api/v3/filtersets.json",
                headers: {
                    "Content-Type": "application/json"
                },
                data
            });
        } else {
            data.id = this.id;
            response = await AtlasAuth.Put({
                url: "api/v3/filtersets.json",
                headers: {
                    "Content-Type": "application/json"
                },
                data
            });
        }

        this.loadJSON(response.output[0]);
    }

    delete() {
        AtlasAuth.Delete({
            url: "api/v3/filtersets.json",
            data: { id: this.id }
        }).catch((error) => {
            this.set("showDeleteError", true);
            Ember.run.later(()=>{
                this.set("showDeleteError", false);
            }, 5000);
            return;
        });
    }
    
    undoDelete() {
        let data = this.toJSON({ fieldGroup: "filterset" });
        data.is_deleted = false;
    
        AtlasAuth.Put({
          url: "api/v3/filtersets.json",
          headers: {
            "Content-Type": "application/json"
          },
          data
        }).catch((error) => {
          this.set("showDeleteError", true);
          Ember.run.later(()=>{
              this.set("showDeleteError", false);
          }, 5000);
          return;
        });
    }
}