import Component from "@ember/component";
import { action, computed } from "@ember/object";

import QualtricsDataPushers from "infegy-frontend/models/qualtrics/data_pushers";

export default class QualtricsDataPushersList extends Component {
    dataPushers = QualtricsDataPushers.create();

    deletingDataPusher = null;
    pausingDataPusher = null;

    pageSize = 10;

    filterFieldOptions = [
        {
            label: "Name",
            value: "name"
        }
    ];

    sortFieldOptions = [
        {
            label: "Name",
            value: "name"
        },
        {
            label: "Last Push",
            value: "lastPush"
        },
        {
            label: "Survey",
            value: "qualtrics_survey_id"
        }
    ];

    sortField = "";

    @computed.alias("dataPushers.isLoading") isLoading;

    // event handlers

    @action
    onConfirmDelete(dataPusher) {
        this.dataPushers.removeObject(dataPusher);
    }
}