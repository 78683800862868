import QueryAPIAges from "infegy-frontend/models/query-api/ages";
import QueryAPISubquerySets from "infegy-frontend/models/query-api/subquery_sets";
import QueryAPICAProvinces from "infegy-frontend/models/query-api/ca_provinces";
import QueryAPIChannels from "infegy-frontend/models/query-api/channels";
import QueryAPICountries from "infegy-frontend/models/query-api/countries";
import QueryAPIDemographicsMeta from "infegy-frontend/models/query-api/demographics_meta";
import QueryAPIDmas from "infegy-frontend/models/query-api/dmas";
import QueryAPIEducation from "infegy-frontend/models/query-api/education";
import QueryAPIEmbeds from "infegy-frontend/models/query-api/embeds";
import QueryAPIEmotions from "infegy-frontend/models/query-api/emotions";
import QueryAPIEngagements from "infegy-frontend/models/query-api/engagements";
import QueryAPIEntities from "infegy-frontend/models/query-api/entities";
import QueryAPIEvents from "infegy-frontend/models/query-api/events";
import QueryAPIGender from "infegy-frontend/models/query-api/gender";
import QueryAPIHomeOwnership from "infegy-frontend/models/query-api/home_ownership";
import QueryAPIHouseholdValue from "infegy-frontend/models/query-api/household_value";
import QueryAPIIncome from "infegy-frontend/models/query-api/income";
import QueryAPIInfluenceDistribution from "infegy-frontend/models/query-api/influence_distribution";
import QueryAPIInfluencers from "infegy-frontend/models/query-api/influencers";
import QueryAPIInterests from "infegy-frontend/models/query-api/interests";
import QueryAPILanguages from "infegy-frontend/models/query-api/languages";
import QueryAPILinguisticsStats from "infegy-frontend/models/query-api/linguistics_stats";
import QueryAPIKeywords from "infegy-frontend/models/query-api/keywords";
import QueryAPIPostInterests from "infegy-frontend/models/query-api/post_interests";
import QueryAPIPosts from "infegy-frontend/models/query-api/posts";
import QueryAPISentiment from "infegy-frontend/models/query-api/sentiment";
import QueryAPIStates from "infegy-frontend/models/query-api/states";
import QueryAPIStories from "infegy-frontend/models/query-api/stories";
import QueryAPIThemes from "infegy-frontend/models/query-api/themes";
import QueryAPITimeOfDay from "infegy-frontend/models/query-api/time_of_day";
import QueryAPITopics from "infegy-frontend/models/query-api/topics";
import QueryAPIVolume from "infegy-frontend/models/query-api/volume";
import QueryAPIPostClusterNodes from "infegy-frontend/models/query-api/post_cluster_nodes";
import QueryAPIRatings from "infegy-frontend/models/query-api/ratings";
import QueryNarratives from "infegy-frontend/models/query-api/post_clusters";


// TODO: this should be more ember-ified, less global-ified
// App\.QueryAPIs\.push\(\{(?s)(.*?)(?=\}\s*\);)\}\s*\);

var dateGroupingOptions = [{
    label: "Auto",
    value: "auto"
}, {
    label: "By Minute",
    value: "minute",
    maxDays: 7
}, {
    label: "Hourly",
    value: "hour",
    maxDays: 75
}, {
    label: "Daily",
    value: "day"
}, {
    label: "Weekly",
    value: "week",
    minDays: 7
}, {
    label: "Monthly",
    value: "month",
    minDays: 28
}, {
    label: "Yearly",
    value: "year",
    minDays: 365
}];

export const dateGroupingAPIOptions = {
    title: "Date Grouping",
    parameter: "group_by",
    defaultValue: null,
    defaultSelection: "day",
    options: dateGroupingOptions,
    findAvailableOptionsForQuery: function(query) {
        var queryTimespanDays = query && query.get("queryInfo.queryTimespanDays");
        return dateGroupingOptions.filter(row => {
            if ((row.maxDays && row.maxDays <= queryTimespanDays) ||
                (row.minDays && row.minDays >= queryTimespanDays)) {
                return false;
            }
            return row;
        });
    }
};

var QueryAPIs = [{
    name: "ages",
    model: QueryAPIAges,
    apiPath: "ages"
}, {
    name: "subquerySets",
    model: QueryAPISubquerySets,
    apiPath: "subquery-sets"
}, {
    name: "hashtags",
    model: QueryAPITopics,
    splitExcelExports: true,
    apiPath: 'hashtags',
}, {
    name: "sourceBioTopics",
    model: QueryAPITopics,
    splitExcelExports: true,
    apiPath: 'source-bio-topics',
}, {
    name: "emoji",
    model: QueryAPITopics,
    splitExcelExports: true,
    apiPath: 'emoji',
}, {
    name: "mentions",
    model: QueryAPITopics,
    splitExcelExports: true,
    apiPath: 'mentions',
}, {
    name: "channels",
    model: QueryAPIChannels,
    apiPath: 'channels',
    apiOptions: [dateGroupingAPIOptions]
}, {
    name: "countries",
    model: QueryAPICountries,
    apiPath: 'countries'
}, {
    name: "caProvinces",
    model: QueryAPICAProvinces,
    apiPath: 'ca-provinces'
}, {
    name: "demographicsMeta",
    model: QueryAPIDemographicsMeta,
    apiPath: 'demographics-meta'
}, {
    name: "dmas",
    model: QueryAPIDmas,
    apiPath: 'dma'
}, {
    name: "domains",
    model: QueryAPITopics,
    apiPath: "domains"
},{
    name: "links",
    model: QueryAPITopics,
    apiPath: "links"
}, {
    name: "education",
    model: QueryAPIEducation,
    apiPath: 'education'
}, {
    name: "embeds", 
    model: QueryAPIEmbeds,
    apiPath: "posts"
}, {
    name: "emotions",
    model: QueryAPIEmotions,
    apiPath: 'emotions',
    splitExcelExports: true,
    apiOptions: [dateGroupingAPIOptions]
}, {
    name: "engagements",
    model: QueryAPIEngagements,
    apiPath: 'engagements',
    apiOptions: [dateGroupingAPIOptions]
}, {
    name: "entities",
    model: QueryAPIEntities,
    apiPath: 'entities',
    apiOptions: [{
        title: "Number of Entities",
        parameter: "limit",
        defaultValue: 200,
        defaultSelection: 200,
        sortField: "value",
        options: [{
            label: "50",
            value: 50
        }, {
            label: "100",
            value: 100
        }, {
            label: "200",
            value: 200
        }, {
            label: "500",
            value: 500
        }, {
            label: "750",
            value: 750
        }, {
            label: "1000",
            value: 1000
        }, {
            label: "2500",
            value: 2500
        }, {
            label: "5000",
            value: 5000
        }, {
            label: "10000",
            value: 10000
        }]
    }]
}, {
    name: "events",
    model: QueryAPIEvents,
    apiPath: 'events',
    notExportable: true
}, {
    name: "gender",
    model: QueryAPIGender,
    apiPath: 'gender',
    apiOptions: [dateGroupingAPIOptions]
}, {
    name: "homeOwnership",
    model: QueryAPIHomeOwnership,
    apiPath: 'home-ownership'
}, {
    name: "householdValue",
    model: QueryAPIHouseholdValue,
    apiPath: 'household-value'
}, {
    name: "income",
    model: QueryAPIIncome,
    apiPath: 'income'
}, {
    name: "influenceDistribution",
    model: QueryAPIInfluenceDistribution,
    apiPath: 'influence-distribution'
}, {
    name: "influencers",
    model: QueryAPIInfluencers,
    apiPath: 'influencers',
    apiOptions: [{
        title: "Number of Sources",
        parameter: "limit",
        defaultValue: 1000,
        defaultSelection: 1000,
        sortField: "value",
        options: [{
            label: "100",
            value: 100
        }, {
            label: "250",
            value: 250
        }, {
            label: "500",
            value: 500
        }, {
            label: "1000",
            value: 1000
        }]
    }]
}, {
    name: "interests",
    label: "Source Interests",
    model: QueryAPIInterests,
    apiPath: 'interests',
}, {
    name: "languages",
    model: QueryAPILanguages,
    apiPath: 'languages'
}, {
    name: "linguisticsStats",
    model: QueryAPILinguisticsStats,
    apiPath: 'linguistics-stats',
    splitExcelExports: true,
    apiOptions: [dateGroupingAPIOptions]
}, {
    name: "topics",
    model: QueryAPITopics,
    splitExcelExports: true,
    apiPath: 'topics',
}, {
    name: "negativeTopics",
    model: QueryAPITopics,
    splitExcelExports: true,
    apiPath: 'negative-topics',
}, {
    name: "positiveTopics",
    model: QueryAPITopics,
    splitExcelExports: true,
    apiPath: 'positive-topics',
}, {
    name: "positiveKeywords",
    model: QueryAPIKeywords,
    apiPath: 'positive-keywords',
}, {
    name: "negativeKeywords",
    model: QueryAPIKeywords,
    apiPath: 'negative-keywords',
}, {
    name: "postInterests",
    model: QueryAPIPostInterests,
    apiPath: 'post-interests',
}, {
    name: "posts",
    model: QueryAPIPosts,
    apiPath: 'posts',
    apiOptions: [{
        title: "Number of Posts",
        parameter: "limit",
        defaultValue: 250,
        defaultSelection: 250,
        sortField: "value",
        options: [{
            label: "10",
            value: 10
        }, {
            label: "50",
            value: 50
        }, {
            label: "100",
            value: 100
        }, {
            label: "250",
            value: 250
        }, {
            label: "500",
            value: 500
        }, {
            label: "1000",
            value: 1000
        }, {
            label: "5000",
            value: 5000
        }, {
            label: "10000",
            value: 10000
        }]
    }, {
        title: "Sorting",
        parameter: "sort",
        defaultValue: null,
        defaultSelection: "none",
        options: [{
            label: "None",
            value: "none"
        }, {
            label: "Newest",
            value: "newest"
        }, {
            label: "Oldest",
            value: "oldest"
        }]
    }]
}, {
    name: "sentiment",
    model: QueryAPISentiment,
    splitExcelExports: true,
    apiPath: 'sentiment',
    apiOptions: [dateGroupingAPIOptions]
}, {
    name: "states",
    model: QueryAPIStates,
    apiPath: 'states'
}, {
    name: "stories",
    model: QueryAPIStories,
    apiPath: 'stories',
    notExportable: true
}, {
    name: "themes",
    model: QueryAPIThemes,
    apiPath: 'themes',
    splitExcelExports: true,
    apiOptions: [dateGroupingAPIOptions]
}, {
    name: "timeOfDay",
    model: QueryAPITimeOfDay,
    apiPath: 'timeofday'
},{
    name: "volume",
    model: QueryAPIVolume,
    apiPath: 'volume',
    apiOptions: [dateGroupingAPIOptions]
},{
    name: "narratives",
    model: QueryNarratives,
    apiPath: 'narratives',
    apiOptions: [dateGroupingAPIOptions]
},{
    name: "ratings",
    model: QueryAPIRatings,
    apiPath: 'ratings',
    apiOptions: [dateGroupingAPIOptions]
},{
    name: "postClusterNodes",
    model: QueryAPIPostClusterNodes,
    apiPath: "post-cluster-nodes"
}];
export default QueryAPIs;
