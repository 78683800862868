import Ember from "ember";
import Component from '@ember/component';
import {computed} from "@ember/object";
import classic from "ember-classic-decorator";

@classic
export default class extends Component {
    tagName = "tr";
    classNameBindings = [":data-table-row", "isEven:data-table-row-even"];

    query = null;
    selectedField = null;

    @computed("query", "selectedField")
    get agesData() {
        let query = this.query;
        return query.ages.dataSeries.data;
    }

    @computed("agesData", "selectedField")
    get agesField() {
        let data = this.agesData || [],
            selectedField = this.selectedField;
        return data.map(column => {
            return Ember.get(column, selectedField);
        });
    }
}
