import Component from "@ember/component";
import { computed } from "@ember/object";
import { htmlSafe } from "@ember/template";
import classic from "ember-classic-decorator";

@classic
export default class extends Component{
    @computed("ratings", "ratings.length")
    get ratingsData() {
        return this.ratings.map((rating) => {
            return {
                name: rating.name,
                value: rating.value,
                style: htmlSafe(`--rating: ${rating.value}`)
            };
        });
    }
}