import Ember from "ember";
import Component from '@ember/component';
import { computed } from '@ember/object';
import FieldSelectorBase from "infegy-frontend/components/field-selector/parts/fields/trendable-field-base/component";
import AtlasColors from "infegy-frontend/utils/atlas-colors";
import layout from "infegy-frontend/components/field-selector/parts/fields/trendable-selector-layout/template";

export default class FieldSelectorChannels extends FieldSelectorBase {
    layout = layout;
    apiName = "channels";

    @computed("fieldType", "selectedSegment", "selectedSubsegment", "allQueries",
        "allQueries.[]", "allQueries.@each.channels", "allQueries.@each.updated")
    get availableChannels() {
        var allQueries = this.allQueries || [],
            channels = [],
            hasUncategorized = false;
        allQueries.forEach(query => {
            channels = channels.concat(query.get("channels.availableChannels"));
        });
        channels = channels.uniq().filter(channel => {
            if (channel === "uncategorized") {
                hasUncategorized = true;
                return false;
            }
            return true;
        }).map(function(channel) {
            return {
                channel: channel,
                count: allQueries.reduce(function(memo, query) {
                        var channelResults = query.get("channels.stats." + channel + "NormalizedUniverse.sum") || 0;
                        return memo - channelResults;
                    }, 0)
            };
        }).sortBy("count").mapBy("channel");

        if (hasUncategorized) {
            channels.push("uncategorized");
        }
        return channels;
    };

    makeChannelFields(baseFields, suffix, axisGroup) {
        return baseFields.map(baseField => {
            let title = baseField;
            if (baseField === "lexisnexis") {
                title = "News (Print, Broadcast, & Online)";
            }
            let field = this.makeFieldDefinition({
                title: title,
                fieldName: baseField + suffix,
                defaultColor: AtlasColors.getColorfromClassName("atlas-" + baseField) || null,
                inactiveByDefault: baseField === "uncategorized",
                axisGroup: axisGroup
            });
            return field;
        });
    };

    @computed("availableChannels")
    get allAvailableFields() {
        var baseFields = this.availableChannels;
        return {
            "UniverseDistribution" : this.makeChannelFields(baseFields, "NormalizedUniverseDistribution", "percentage"),
            "CountDistribution" : this.makeChannelFields(baseFields, "CountDistribution", "percentage"),
            "Universe" : this.makeChannelFields(baseFields, "NormalizedUniverse", "Channels.UniverseDistribution"),
            "Count" : this.makeChannelFields(baseFields, "Count", "Channels.CountDistribution"),
        };
    };
};
