import Ember from 'ember';

// Used to get the stats object from a provided DataSeries object for a
// field defined by a provided value.
//
// Pass a path to a data series object and a path to a named field in that object
//
// Example:
//    currentFieldStats: CustomComputed.dataSeriesStatsFor("apiResults", "current_field");
//
// Will return the stats within the DataSeries in "apiResults" for the field
// specified in "current_field" or undefined if it does not exist.
var CustomComputed = {
    // TODO: The following block might need to be deleted.
    // // Used to check if a path in an Ember object is not accessible or has no value
    // // (defined by Ember.isEmpty)
    // //
    // // Pass an object and a path to a possible value in that object
    // isPathEmpty: function(object, path) {
    //     if (Ember.isEmpty(path) || Ember.typeOf(path) != "string" ||
    //         Ember.isEmpty(object) || !object.get) {
    //         return true;
    //     }

    //     var props = path.split(".");

    //     var prop_string = props[0];
    //     for (var index = 0; index < props.length; index++) {
    //         if (index > 0) {
    //             prop_string += "." + props[index];
    //         }

    //         if (Ember.isEmpty(Ember.get(object, prop_string))) {
    //             return true;
    //         }
    //     }

    //     return Ember.isEmpty(Ember.get(object, path));
    // },
    // // Used to access a path in an Ember object that may not fully exist, will
    // // return the value in default value if any object in the path does not pass
    // // Ember.isEmpty()
    // //
    // // Pass an object, a path to a value in that object and a default value to
    // // return on failure.
    // getOrDefault: function(object, path, defaultValue) {
    //     if (arguments.length === 2) {
    //         this.getOrDefault(this, arguments[0], arguments[1]);
    //     }

    //     if (!this.isPathEmpty(object, path)) {
    //         return Ember.get(object, path);
    //     }
    //     return defaultValue;
    // },

    // // Used to alias a path safely, and return an alternate value
    // // (or undefined if none supplied) if that path does not exist.
    // //
    // // Example:
    // //    data: App.computed.aliasOr("query.volume.all.data", []);
    // //
    // // Will return the value(s) at "query.volume.all.data" or [] if
    // // it does not exist.
    // aliasOrDefault: function(_aliasKey, _defaultValue) {
    //     var that = this;
    //     var aliasKey = _aliasKey,
    //         defaultValue = _defaultValue;

    //     return Ember.computed(aliasKey, function(key, value) {
    //         if (arguments.length > 1) {
    //             Ember.set(that, aliasKey, value);
    //             return value;
    //         } else {
    //             return that.getOrDefault(that, aliasKey, defaultValue);
    //         }
    //     }).property(aliasKey);
    // },
    // TODO: END DELETE BLOCK
    dataSeriesStatsFor: function(_dataSeriesKey, _statsKey) {
        var dataSeriesKey = _dataSeriesKey,
            statsKey = _statsKey;

        return Ember.computed(dataSeriesKey, statsKey,function() {
            var thisStatsKey = this.get(statsKey);
            var thisDataSeriesKey = this.get(dataSeriesKey);

            if (!thisStatsKey || !thisDataSeriesKey) {
                return;
            }

            return this.get(dataSeriesKey) &&
                this.get(dataSeriesKey).get("stats") &&
                this.get(dataSeriesKey).get("stats").get(thisStatsKey);
        });
    },
    htmlSafeAlias: function(_sourceProperty) {
        var sourceProperty = _sourceProperty;
        return Ember.computed(sourceProperty, function() {
            return new Ember.String.htmlSafe("" + this.get(_sourceProperty));
        });
    },

    // Used to check if a path in an Ember object is not accessible or has no value
    // (defined by Ember.isEmpty)
    //
    // Pass an object and a path to a possible value in that object
    isPathEmpty: function(object, path) {
        if (Ember.isEmpty(path) || Ember.typeOf(path) !== "string" ||
            Ember.isEmpty(object) || !object.get) {
            return true;
        }

        var props = path.split(".");

        var prop_string = props[0];
        for (var index = 0; index < props.length; index++) {
            if (index > 0) {
                prop_string += "." + props[index];
            }

            if (Ember.isEmpty(Ember.get(object, prop_string))) {
                return true;
            }
        }

        return Ember.isEmpty(Ember.get(object, path));
    },
    // Used to access a path in an Ember object that may not fully exist, will
    // return the value in default value if any object in the path does not pass
    // Ember.isEmpty()
    //
    // Pass an object, a path to a value in that object and a default value to
    // return on failure.
    getOrDefault: function(object, path, defaultValue) {
        if (arguments.length === 2) {
            this.getOrDefault(this, arguments[0], arguments[1]);
        }

        if (!this.isPathEmpty(object, path)) {
            return Ember.get(object, path);
        }
        return defaultValue;
    },

    // Used to alias a path safely, and return an alternate value
    // (or undefined if none supplied) if that path does not exist.
    //
    // Example:
    //    data: App.computed.aliasOr("query.volume.all.data", []);
    //
    // Will return the value(s) at "query.volume.all.data" or [] if
    // it does not exist.
    aliasOrDefault: function(_aliasKey, _defaultValue) {
        var that = this;
        var aliasKey = _aliasKey,
            defaultValue = _defaultValue;

        return Ember.computed(aliasKey, function(key, value) {
            if (arguments.length > 1) {
                Ember.set(that, aliasKey, value);
                return value;
            } else {
                return that.getOrDefault(that, aliasKey, defaultValue);
            }
        });
    },
};

export default CustomComputed;
