import Ember from 'ember';
import Base from 'infegy-frontend/json-store/base/model';

export default Ember.Object.extend(Base, Ember.Evented, {
    // Overload this if an object has a custom loader (useful for when it could result in multiple object types)
    loadJSONObject: null,

    loadJSON: function(jsonObj, options) {
        options = Object.assign({
            clearDirty: false,
            ignoreUnknownProperties: false,
            allowEmptyValues: false
        }, options);

        if (typeof(jsonObj) !== "object") {
            return;
        }
        var data = this._data,
            staticProperties = this._staticProperties || [];
        for (var key in jsonObj) {
            if (jsonObj.hasOwnProperty(key) && (!Ember.isEmpty(jsonObj[key]) || options.allowEmptyValues)
                    && !staticProperties.includes(key)) {
                var dataType = Ember.typeOf(jsonObj[key]),
                    innerKey = key.camelize(),
                    value = jsonObj[key];
                if (dataType === "array" || dataType === "object") {
                    var prop = this.get(innerKey);
                    if (Ember.typeOf(prop) === "instance" && prop.loadJSON) {
                        if (prop.loadJSONObject) {
                            this.set(innerKey, prop.loadJSONObject(value));
                        } else {
                            prop.loadJSON(value, options);
                        }
                    } else {
                        this.set(innerKey, value);
                    }
                } else {
                    this.set(innerKey, value);
                }
                if (!options.ignoreUnknownProperties) {
                    var dataVal = Ember.get(data, innerKey);
                    if (!dataVal || dataVal !== value) {
                        Ember.set(data, innerKey, value);
                    }
                }
            }
        }
        if (options.clearDirty) {
            this.set("isDirty", false);
        }
    },
});
