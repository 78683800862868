import Ember from "ember";

export default Ember.Object.extend({
    requestURL: null,
    apiName: null,

    // special codes :
    // 413 - timeout.
    // 409 - too much concurrency or volume.
    // 403 - Access/authentication
    // 400 - User needs to fix something
    // 504 - gateway timeout like 413, for different reason
    code: null,

    response: null,
    type: null,

    errorText: null,
});
