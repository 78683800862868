var UsStatesJSON = [
    { "title": "Alabama", "code": "AL", "region": "SU" },
    { "title": "Alaska", "code": "AK", "region": "WS" },
    { "title": "Arizona", "code": "AZ", "region": "WS" },
    { "title": "Arkansas", "code": "AR", "region": "SU" },
    { "title": "California", "code": "CA", "region": "WS" },
    { "title": "Colorado", "code": "CO", "region": "WS" },
    { "title": "Connecticut", "code": "CT", "region": "NE" },
    { "title": "Delaware", "code": "DE", "region": "SU" },
    { "title": "Washington DC", "code": "DC", "region": "NE", "alternates": ["District of Columbia"] },
    { "title": "Florida", "code": "FL", "region": "SU" },
    { "title": "Georgia", "code": "GA", "region": "SU" },
    { "title": "Hawaii", "code": "HI", "region": "WS" },
    { "title": "Idaho", "code": "ID", "region": "WS" },
    { "title": "Illinois", "code": "IL", "region": "MW" },
    { "title": "Indiana", "code": "IN", "region": "MW" },
    { "title": "Iowa", "code": "IA", "region": "MW" },
    { "title": "Kansas", "code": "KS", "region": "MW" },
    { "title": "Kentucky", "code": "KY", "region": "SU" },
    { "title": "Louisiana", "code": "LA", "region": "SU" },
    { "title": "Maine", "code": "ME", "region": "NE" },
    { "title": "Maryland", "code": "MD", "region": "SU" },
    { "title": "Massachusetts", "code": "MA", "region": "NE" },
    { "title": "Michigan", "code": "MI", "region": "MW" },
    { "title": "Minnesota", "code": "MN", "region": "MW" },
    { "title": "Mississippi", "code": "MS", "region": "SU" },
    { "title": "Missouri", "code": "MO", "region": "MW" },
    { "title": "Montana", "code": "MT", "region": "WS" },
    { "title": "Nebraska", "code": "NE", "region": "MW" },
    { "title": "Nevada", "code": "NV", "region": "WS" },
    { "title": "New Hampshire", "code": "NH", "region": "NE" },
    { "title": "New Jersey", "code": "NJ", "region": "NE" },
    { "title": "New Mexico", "code": "NM", "region": "WS" },
    { "title": "New York", "code": "NY", "region": "NE" },
    { "title": "North Carolina", "code": "NC", "region": "SU" },
    { "title": "North Dakota", "code": "ND", "region": "MW" },
    { "title": "Ohio", "code": "OH", "region": "MW" },
    { "title": "Oklahoma", "code": "OK", "region": "SU" },
    { "title": "Oregon", "code": "OR", "region": "WS" },
    { "title": "Pennsylvania", "code": "PA", "region": "NE" },
    { "title": "Rhode Island", "code": "RI", "region": "NE" },
    { "title": "South Carolina", "code": "SC", "region": "SU" },
    { "title": "South Dakota", "code": "SD", "region": "MW" },
    { "title": "Tennessee", "code": "TN", "region": "SU" },
    { "title": "Texas", "code": "TX", "region": "SU" },
    { "title": "Utah", "code": "UT", "region": "WS" },
    { "title": "Vermont", "code": "VT", "region": "NE" },
    { "title": "Virginia", "code": "VA", "region": "SU" },
    { "title": "Washington", "code": "WA", "region": "WS" },
    { "title": "West Virginia", "code": "WV", "region": "SU" },
    { "title": "Wisconsin", "code": "WI", "region": "MW" },
    { "title": "Wyoming", "code": "WY", "region": "WS" }
];

for (var h = 0; h < UsStatesJSON.length; h++) {
    UsStatesJSON[h]['description'] = UsStatesJSON[h]['code'];
}

var regions = [{ "title": "South", "abbreviation": "SU", "code": [], "alternates": [], hideDescription: true, "optgroup": "Regions" },
    { "title": "West", "abbreviation": "WS", "code": [], "alternates": [], hideDescription: true, "optgroup": "Regions" },
    { "title": "Northeast", "abbreviation": "NE", "code": [], "alternates": [], hideDescription: true, "optgroup": "Regions" },
    { "title": "Midwest", "abbreviation": "MW", "code": [], "alternates": [], hideDescription: true, "optgroup": "Regions" }
];

UsStatesJSON.forEach(state => {
    var regionAbbreviation = state['region'];
    var regionObj = regions.findBy('abbreviation', regionAbbreviation);
    state['optgroup'] = "States";
    regionObj.code.push(state['code']);
    regionObj.alternates.push(state['title']);
});

for (var j = 0; j < regions.length; j++) {
    UsStatesJSON.unshift(regions[j]);
}

export default UsStatesJSON;
